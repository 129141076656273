var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.disabled || _vm.imageUrl
    ? _c(
        "div",
        { staticClass: "upload-file-box" },
        [
          _c(
            "el-upload",
            {
              staticClass: "avatar-uploader",
              attrs: {
                action: "/oss/admin/oss/uploadImages",
                accept: _vm.fileType,
                "show-file-list": false,
                "http-request": _vm.customUpload,
                "before-upload": _vm.beforeUpload,
                disabled: _vm.disabled,
                requiredIcon: _vm.requiredIcon,
              },
            },
            [
              _vm.imageUrl
                ? _c("img", {
                    staticClass: "avatar",
                    attrs: { src: _vm.imageUrl },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.previewImg.apply(null, arguments)
                      },
                    },
                  })
                : _c("i", { staticClass: "el-icon-plus avatar-uploader-icon" }),
              _vm.imageUrl && !_vm.disabled
                ? _c("i", {
                    staticClass:
                      "delete-icon pointer iconfont grow-a-icon_tabbar_close_unselected",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.deleteImg.apply(null, arguments)
                      },
                    },
                  })
                : _vm._e(),
            ]
          ),
          _c("p", { staticClass: "flex" }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.requiredIcon,
                    expression: "requiredIcon",
                  },
                ],
                staticStyle: { color: "#e84c4c", margin: "8px 3px 0 0" },
              },
              [_vm._v("*")]
            ),
            _c("span", { staticClass: "img-title" }, [
              _vm._v(_vm._s(_vm.title)),
            ]),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }