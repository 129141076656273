<template>
  <el-popover
    ref="popover"
    popper-class="custom-popover"
    placement="bottom"
    width="50vw"
    trigger="manual"
    v-model="selectServicePopoverVisible"
  >
    <el-table
      v-loading="isLoading"
      max-height="300px"
      :data="serviceList"
      @row-click="selectServiceRow"
    >
      <el-table-column type="index" label="序号" width="60"></el-table-column>
      <el-table-column width="180" prop="name" label="服务项目">
        <template #default="{ row }">
          <el-tag
            v-if="row.isAgreePrice"
            type="info"
            size="mini"
            class="el-icon--left"
            >协议</el-tag
          >
          <span>{{ row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column
        width="110"
        prop="serviceTime"
        label="服务时效(H)"
      ></el-table-column>
      <el-table-column width="80" prop="price" label="售价"></el-table-column>
      <el-table-column label="最近服务" prop="lastUseDate" width="120">
      </el-table-column>
      <el-table-column
        min-width="120"
        prop="description"
        label="服务备注"
      ></el-table-column>
      <!-- <el-table-column
        width="100"
        prop="serviceItemCode"
        label="服务编码"
      ></el-table-column>
       <el-table-column
        width="80"
        prop="referencePrice"
        label="4S参考价"
      ></el-table-column>
      <el-table-column
        width="120"
        prop="categoryName"
        label="所属分类"
        show-overflow-tooltip
      ></el-table-column>
      -->
      <!-- <template slot="append">
        <div v-if="hasMore" class="load-more-btn" @click="getData(false, true)">
          加载更多数据
        </div>
        <div v-else class="all">已全部加载</div>
      </template> -->
    </el-table>
    <div style="height: 40px">
      <div v-if="hasMore" class="load-more-btn" @click="getData(false, true)">
        加载更多数据
      </div>
      <div v-else class="all">已全部加载</div>
    </div>
    <el-input
      ref="keyWordInput"
      slot="reference"
      :class="showErrorClass ? 'error' : ''"
      v-model="keyWord"
      clearable
      size="small"
      :placeholder="placeholder"
      @input="getData(true)"
      @focus="selectServicePopoverVisible = true"
      @blur="inputBlur"
    ></el-input>
  </el-popover>
</template>

<script>
import {
  searchServiceForCreateCarBill,
  searchServiceWithAgreementPriceForCreateCarBill,
} from '@/api/carBill';
import lodash from 'lodash';
import { mapState } from 'vuex';
export default {
  name: 'selectServicePopover',
  props: {
    carId: {
      type: [Number, String],
      default: '',
    },
    billId: {
      type: [String, Number],
      default: 0,
    },
    billType: [String, Number],
    clientAgreeId: [String, Number],
    billCompanyId: {
      type: Number,
      default: 0,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorPlaceholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      //选择服务弹出层可见性
      selectServicePopoverVisible: false,

      // 服务列表数据
      serviceList: [],

      // 搜索关键字
      keyWord: '',

      // 分页所在页数
      pageCurrent: 0,

      // 是否未加载完所有数据
      hasMore: true,

      // 是否正在加载
      isLoading: false,

      debounce: {},

      isFirstLoad: true,

      // 是否显示error样式
      showErrorClass: false,

      // 占位符
      placeholder: '搜索服务名称/编码',

      debounceGetData: () => {},
    };
  },
  watch: {
    error(val) {
      this.showErrorClass = val;
      this.placeholder = val ? this.errorPlaceholder : '搜索服务名称/编码';
    },
    clientAgreeId() {
      this.isFirstLoad = true;
      this.getData(true);
    },
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
    pagination() {
      return { size: 5, current: this.pageCurrent };
    },
  },
  created() {
    this.debounceGetData = this.$lodash.debounce(this.realRequestMethod, 3e2);
  },
  mounted() {
    this.getData();
    this.debounce = this.$lodash.debounce(this.handleDocumentScroll, 100, {
      leading: true,
    });
    document
      .getElementsByClassName('main')[0]
      .addEventListener('scroll', this.debounce);
    document.addEventListener('click', this.handleDocumentClick);
  },
  destroyed() {
    document
      .getElementsByClassName('main')[0]
      .removeEventListener('scroll', this.debounce);
    document.removeEventListener('click', this.handleDocumentClick);
  },
  methods: {
    /**
     * 输入框失焦事件
     */
    inputBlur() {
      // setTimeout(() => {
      //   !this.isLoading && (this.selectServicePopoverVisible = false);
      // }, 2e2);
    },
    /**
     * 加载数据
     */
    getData(reset, loadMore) {
      if (!this.isFirstLoad) {
        this.$nextTick().then(() => {
          this.$refs?.popover?.updatePopper();
        });
      }
      this.isLoading = true;
      this.debounceGetData(reset, loadMore);
    },

    /**
     * 实际请求方法
     */
    realRequestMethod(reset, loadMore) {
      // 重置分页，清空已有数据
      if (reset) {
        this.pageCurrent = 1;

        this.hasMore = true;
      } else {
        this.pageCurrent++;
      }

      var part = {};
      if (this.billType !== undefined) {
        part.billType = this.billType;
      }
      if (this.clientAgreeId) {
        part.clientAgreeId = this.clientAgreeId;
      }
      if (this.billCompanyId) {
        part.billCompanyId = this.billCompanyId;
      }

      var body = Object.assign(
        {},
        {
          ...this.pagination,
          serviceMessage: this.keyWord,
          companyId: this.companyId,
          carId: this.carId,
          billId: this.billId,
        },
        part
      );

      searchServiceWithAgreementPriceForCreateCarBill(body)
        .then((res) => {
          if (reset) this.serviceList = [];
          console.log(res);
          this.serviceList.push(...res.records);
          this.hasMore = this.pageCurrent < res.pages;

          if (!this.isFirstLoad) this.selectServicePopoverVisible = true;
          this.isFirstLoad = false;

          this.$nextTick(() => {
            this.$refs?.popover?.updatePopper();
          });
        })
        .finally(() => {
          this.isLoading = false;
          if (loadMore) this.$refs.keyWordInput.focus();
        });
    },

    /**
     * 选中行
     */
    selectServiceRow(val) {
      // this.selectServicePopoverVisible = false;
      this.keyWord = '';
      this.getData(true);
      this.$emit('select', val);
    },
    handleDocumentScroll(e) {
      this.$refs?.popover?.updatePopper();
    },
    handleDocumentClick(e) {
      if (
        !this.$el ||
        this.$el.contains(e.target) ||
        e.target.className.includes('el-icon-circle-close')
      ) {
        return;
      }
      this.selectServicePopoverVisible = false;
    },
  },
};
</script>
<style>
.custom-popover {
  padding: 10px 10px 0 !important;
}
.custom-popover .el-table__cell {
  padding: 5px 0;
}
.error > input {
  border-color: #ff2b00;
}
.error > .el-input__inner:focus {
  border-color: #ff2b00;
}
.error > input::-webkit-input-placeholder {
  color: #f84825;
}
.error > input::-moz-input-placeholder {
  color: #f84825;
}
.error > input::-ms-input-placeholder {
  color: #f84825;
}
</style>
<style lang="scss" scoped>
.load-more-btn {
  height: 40px;
  text-align: center;
  line-height: 50px;
  color: #3eb1e0;
  cursor: pointer;
  &:hover {
    color: #14a3db;
  }
}
.all {
  margin-top: 20px;
  text-align: center;
  color: #aaa;
}
</style>
