var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { staticClass: "left" }, [
        _c(
          "div",
          { staticClass: "content" },
          [
            _c(
              "el-form",
              {
                attrs: {
                  model: _vm.form,
                  "label-width": "100px",
                  size: "small",
                },
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      [
                        _c(
                          "el-form-item",
                          [
                            _c("template", { slot: "label" }, [
                              _c(
                                "div",
                                [
                                  _vm._v(" 操作门店 "),
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        effect: "light",
                                        content:
                                          "接车单的操作门店,查看范围(当前登录门店+管辖组)",
                                        placement: "bottom-start",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "iconfont grow-icon_doubt",
                                        staticStyle: { color: "#9a9a9a" },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("operateCompanyCascader", {
                              attrs: {
                                operateCompanyUrl: _vm.form.operateCompanyUrl,
                              },
                              on: {
                                "update:operateCompanyUrl": function ($event) {
                                  return _vm.$set(
                                    _vm.form,
                                    "operateCompanyUrl",
                                    $event
                                  )
                                },
                                "update:operate-company-url": function (
                                  $event
                                ) {
                                  return _vm.$set(
                                    _vm.form,
                                    "operateCompanyUrl",
                                    $event
                                  )
                                },
                                input: _vm.change,
                              },
                              model: {
                                value: _vm.form.operateCompanyId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "operateCompanyId", $$v)
                                },
                                expression: "form.operateCompanyId",
                              },
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "flex",
                staticStyle: { "margin-top": "10px", width: "100%" },
              },
              [
                _c(
                  "div",
                  { staticClass: "process-sty", staticStyle: { width: "84%" } },
                  [
                    _c("div", { staticClass: "flex index-path" }, [
                      _c("div", { staticClass: "path-item" }, [
                        _c(
                          "div",
                          { staticClass: "flex" },
                          [
                            _c("el-button", { staticClass: "editCar" }, [
                              _vm._v("开单"),
                            ]),
                            _c("div", { staticClass: "path-arr" }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "path-tip" }, [
                          _c("p", [
                            _vm._v(
                              " 今日进店： " +
                                _vm._s(_vm.allDetail.todayEnterSum) +
                                "台   " +
                                _vm._s(_vm.allDetail.todayBillSum) +
                                "单 "
                            ),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "path-item" }, [
                        _c(
                          "div",
                          { staticClass: "flex" },
                          [
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.goCarList(2)
                                  },
                                },
                              },
                              [_vm._v(" 报价")]
                            ),
                            _c("div", { staticClass: "path-arr" }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "path-tip" }, [
                          _c("p", { staticClass: "path-tip-left" }, [
                            _vm._v(
                              " 服务中：" +
                                _vm._s(_vm.allDetail.serviceSum) +
                                "   "
                            ),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "path-item" }, [
                        _c(
                          "div",
                          { staticClass: "flex" },
                          [
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.goCarList(3)
                                  },
                                },
                              },
                              [_vm._v(" 提交施工")]
                            ),
                            _c("div", { staticClass: "path-arr" }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "path-tip" }, [
                          _c("p", { staticClass: "path-tip-left" }, [
                            _vm._v(
                              " 待提交：" +
                                _vm._s(_vm.allDetail.noConstructionSum) +
                                " "
                            ),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "path-item" }, [
                        _c(
                          "div",
                          { staticClass: "flex" },
                          [
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.goCarList(4)
                                  },
                                },
                              },
                              [_vm._v("结账")]
                            ),
                            _c("div", { staticClass: "path-arr" }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "path-tip" }, [
                          _c("p", [
                            _vm._v(
                              " 待结账：" +
                                _vm._s(_vm.allDetail.toPaySum) +
                                "   提前提车：" +
                                _vm._s(_vm.allDetail.alreadyExtractSum) +
                                " "
                            ),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "path-item" }, [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.goCarList(5)
                                  },
                                },
                              },
                              [_vm._v("提车")]
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "path-tip" }, [
                          _c("p", { staticClass: "path-tip-left" }, [
                            _vm._v(
                              " 今日结账：" +
                                _vm._s(_vm.allDetail.todayFinishedSum) +
                                " "
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                    _vm._m(0),
                    _c("div", { staticClass: "flex index-path" }, [
                      _c("div", { staticClass: "path-item" }, [
                        _c(
                          "div",
                          { staticClass: "flex" },
                          [
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.goWorkshopBill(1)
                                  },
                                },
                              },
                              [_vm._v(" 派工 ")]
                            ),
                            _c("div", { staticClass: "path-arr" }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "path-tip" }, [
                          _c("p", { staticClass: "path-tip-left" }, [
                            _vm._v(
                              " 待派工：" +
                                _vm._s(_vm.constructionDetail.staySendSum) +
                                " "
                            ),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "path-item" }, [
                        _c(
                          "div",
                          { staticClass: "flex" },
                          [
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.goOldPage(1)
                                  },
                                },
                              },
                              [_vm._v(" 领料 ")]
                            ),
                            _c("div", { staticClass: "path-arr" }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "path-tip" }, [
                          _c("p", { staticClass: "path-tip-left" }, [
                            _vm._v(
                              " 待领料：" +
                                _vm._s(_vm.billOtherCount.waitPicking) +
                                " "
                            ),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "path-item" }, [
                        _c(
                          "div",
                          { staticClass: "flex" },
                          [
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.goWorkshopBill(3)
                                  },
                                },
                              },
                              [_vm._v(" 项目完工 ")]
                            ),
                            _c("div", { staticClass: "path-arr" }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "path-tip" }, [
                          _c("p", { staticClass: "path-tip-left" }, [
                            _vm._v(
                              " 施工中：" +
                                _vm._s(
                                  _vm.constructionDetail.inConstructionSum
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "path-item" }, [
                        _c(
                          "div",
                          { staticClass: "flex" },
                          [
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.goWorkshopBill(4)
                                  },
                                },
                              },
                              [_vm._v(" 质检 ")]
                            ),
                            _c("div", { staticClass: "path-arr" }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "path-tip" }, [
                          _c("p", { staticClass: "path-tip-left" }, [
                            _vm._v(
                              " 质检中: " +
                                _vm._s(_vm.constructionDetail.qualitySum) +
                                " "
                            ),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "path-item" }, [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.goWorkshopBill(5)
                                  },
                                },
                              },
                              [_vm._v(" 车间完工 ")]
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "path-tip" }, [
                          _c("p", { staticClass: "path-tip-left" }, [
                            _vm._v(
                              " 今日完工：" +
                                _vm._s(
                                  _vm.constructionDetail.todayCompletedSum
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                    _vm._m(1),
                    _c("div", { staticClass: "flex index-path" }, [
                      _c("div", { staticClass: "path-item" }, [
                        _c(
                          "div",
                          { staticClass: "flex" },
                          [
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.goOldPage(2)
                                  },
                                },
                              },
                              [_vm._v(" 采购 ")]
                            ),
                            _c("div", { staticClass: "path-arr" }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "path-tip" }, [
                          _c("p", { staticClass: "path-tip-left" }, [
                            _vm._v(
                              " 待采购： " +
                                _vm._s(_vm.billOtherCount.waitPurchase) +
                                " "
                            ),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "path-item" }, [
                        _c(
                          "div",
                          { staticClass: "flex" },
                          [
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.goOldPage(3)
                                  },
                                },
                              },
                              [_vm._v(" 入库 ")]
                            ),
                            _c("div", { staticClass: "path-arr" }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "path-tip" }, [
                          _c("p", { staticClass: "path-tip-left" }, [
                            _vm._v(
                              " 待入库： " +
                                _vm._s(_vm.billOtherCount.waitInventoryIn) +
                                " "
                            ),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "path-item" }, [
                        _c(
                          "div",
                          { staticClass: "flex" },
                          [
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.goOldPage(4)
                                  },
                                },
                              },
                              [_vm._v(" 库存 ")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticStyle: { width: "43%" } }),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "state-but", staticStyle: { width: "16%" } },
                  [
                    _c("div", [
                      _c(
                        "p",
                        {
                          staticClass: "btn-sty hover",
                          on: {
                            click: function ($event) {
                              return _vm.goCarList(6)
                            },
                          },
                        },
                        [
                          _vm._v(
                            " 已逾交车 " +
                              _vm._s(_vm.allDetail.overdueCarSum) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticClass: "btn-tip",
                          on: {
                            click: function ($event) {
                              return _vm.goCarList(7)
                            },
                          },
                        },
                        [
                          _vm._v(
                            " 今日预交 " +
                              _vm._s(_vm.allDetail.todayPaySum) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                    _c("div", [
                      _c(
                        "p",
                        {
                          staticClass: "btn-sty hover",
                          on: {
                            click: function ($event) {
                              return _vm.goPresentCar(1)
                            },
                          },
                        },
                        [
                          _vm._v(
                            " 今日在场 " +
                              _vm._s(_vm.todayPresent.todaySum) +
                              " "
                          ),
                        ]
                      ),
                      _c("p", { staticClass: "btn-tip" }, [
                        _vm._v(
                          " 滞留7天以上：" +
                            _vm._s(_vm.todayPresent.retentionSum) +
                            " "
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      {
                        staticStyle: { "line-height": "56px" },
                        on: {
                          click: function ($event) {
                            return _vm.goCarList(8)
                          },
                        },
                      },
                      [
                        _c("p", { staticClass: "hover" }, [
                          _vm._v(
                            "挂单中 " + _vm._s(_vm.allDetail.pendingOrderSum)
                          ),
                        ]),
                      ]
                    ),
                    _vm.isHeadquarters
                      ? _c("div", [
                          _c(
                            "p",
                            {
                              staticClass: "btn-sty hover",
                              on: {
                                click: function ($event) {
                                  return _vm.goAgreementList(1)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " 临期协议 " +
                                  _vm._s(_vm.agreementDetail.toBeDueCount) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "btn-tip",
                              on: {
                                click: function ($event) {
                                  return _vm.goAgreementList(2)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " 已过期协议：" +
                                  _vm._s(_vm.agreementDetail.hasDueCount) +
                                  " "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "turnoverTarget" }, [
          _c("div", { staticClass: "title flex-x-between" }, [
            _c("div", { staticClass: "flex" }, [
              _vm._v(" 营业额 "),
              _c("p", { staticClass: "tip-word color666" }, [
                _c("i", {
                  staticClass: "el-icon-warning orange",
                  staticStyle: { "font-size": "14px", "margin-right": "5px" },
                }),
                _vm._v(
                  " 数据更新于" +
                    _vm._s(new Date().Format("yyyy/MM/dd hh:mm:ss")) +
                    " "
                ),
              ]),
            ]),
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "auth",
                        rawName: "v-auth",
                        value: 537,
                        expression: "537",
                      },
                    ],
                    staticClass: "btn-view-target",
                    attrs: { type: "text" },
                    on: { click: _vm.viewTarget },
                  },
                  [_vm._v("查看目标 ")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "target-box" }, [
            _c("div", { staticClass: "target-list" }, [
              _c("div", { staticClass: "target-item" }, [
                _c("div", { staticClass: "top" }, [
                  _c("div", { staticClass: "label" }, [_vm._v("总营业额")]),
                  _c(
                    "div",
                    { staticClass: "flex-center" },
                    [
                      _c(
                        "div",
                        {
                          class: {
                            rate: true,
                            waring: _vm.turnoverData.totalTurnoverWarning,
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.turnoverData.totalPercentageComplete) +
                              "% "
                          ),
                        ]
                      ),
                      _c(
                        "circularProgressBar",
                        {
                          attrs: {
                            percentage: Math.min(
                              _vm.turnoverData.totalPercentageComplete,
                              100
                            ),
                            width: 28,
                            strokeWidth: 2,
                            color: _vm.turnoverData.totalTurnoverWarning
                              ? "#E84C4C"
                              : "#33AB79",
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src:
                                _vm.turnoverData.totalResponsiblePic ||
                                _vm.defaultAvatar,
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "money" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("toThousands")(_vm.turnoverData.totalTurnover)
                      ) +
                      " "
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "target-number" },
                  [
                    _vm._v(" 目标： "),
                    ![null, undefined].includes(_vm.turnoverData.totalTarget)
                      ? [
                          _vm._v(
                            _vm._s(
                              _vm._f("toThousands")(
                                _vm.turnoverData.totalTarget / 10000,
                                4
                              )
                            ) + "万元"
                          ),
                        ]
                      : [_vm._v("暂未设置")],
                  ],
                  2
                ),
                _c("div", { staticClass: "target-number" }, [
                  _vm._v(" 毛利率： "),
                  _c(
                    "span",
                    {
                      style: {
                        color: _vm.turnoverData.totalGrossProfitRateWarning
                          ? "#E84C4C"
                          : "",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.turnoverData.totalGrossProfitRate) + "%"
                      ),
                    ]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "target-item" }, [
                _c("div", { staticClass: "top" }, [
                  _c(
                    "div",
                    { staticClass: "label", staticStyle: { color: "#ee8f33" } },
                    [_vm._v("自费")]
                  ),
                  _c(
                    "div",
                    { staticClass: "flex-center" },
                    [
                      _c(
                        "div",
                        {
                          class: {
                            rate: true,
                            waring: _vm.turnoverData.selfPayTurnoverWarning,
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.turnoverData.selfPayPercentageComplete
                              ) +
                              "% "
                          ),
                        ]
                      ),
                      _c(
                        "circularProgressBar",
                        {
                          attrs: {
                            percentage: Math.min(
                              _vm.turnoverData.selfPayPercentageComplete,
                              100
                            ),
                            width: 28,
                            strokeWidth: 2,
                            color: _vm.turnoverData.selfPayTurnoverWarning
                              ? "#E84C4C"
                              : "#33AB79",
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src:
                                _vm.turnoverData.selfPayResponsiblePic ||
                                _vm.defaultAvatar,
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "money" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("toThousands")(_vm.turnoverData.selfPayTurnover)
                      ) +
                      " "
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "target-number" },
                  [
                    _vm._v(" 目标："),
                    ![null, undefined].includes(_vm.turnoverData.selfPayTarget)
                      ? [
                          _vm._v(
                            _vm._s(
                              _vm._f("toThousands")(
                                _vm.turnoverData.selfPayTarget / 10000,
                                4
                              )
                            ) + "万元"
                          ),
                        ]
                      : [_vm._v("暂未设置")],
                  ],
                  2
                ),
                _c("div", { staticClass: "target-number" }, [
                  _vm._v(" 毛利率： "),
                  _c(
                    "span",
                    {
                      style: {
                        color: _vm.turnoverData.selfPayGrossProfitRateWarning
                          ? "#E84C4C"
                          : "",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.turnoverData.selfPayGrossProfitRate) + "%"
                      ),
                    ]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "target-item" }, [
                _c("div", { staticClass: "top" }, [
                  _c(
                    "div",
                    { staticClass: "label", staticStyle: { color: "#13afc4" } },
                    [
                      _vm._v(" 包干 "),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "light",
                            content: "包干金额为分摊后实际产值金额，非单据金额",
                            placement: "bottom-start",
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "iconfont grow-icon_doubt",
                            staticStyle: { color: "#9a9a9a" },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "flex-center" },
                    [
                      _c(
                        "div",
                        {
                          class: {
                            rate: true,
                            waring: _vm.turnoverData.contractForTurnoverWarning,
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.turnoverData.contractForPercentageComplete
                              ) +
                              "% "
                          ),
                        ]
                      ),
                      _c(
                        "circularProgressBar",
                        {
                          attrs: {
                            percentage: Math.min(
                              _vm.turnoverData.contractForPercentageComplete,
                              100
                            ),
                            width: 28,
                            strokeWidth: 2,
                            color: _vm.turnoverData.contractForTurnoverWarning
                              ? "#E84C4C"
                              : "#33AB79",
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src:
                                _vm.turnoverData.contractForResponsiblePic ||
                                _vm.defaultAvatar,
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "money" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("toThousands")(
                          _vm.turnoverData.contractForTurnover
                        )
                      ) +
                      " "
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "target-number" },
                  [
                    _vm._v(" 目标："),
                    ![null, undefined].includes(
                      _vm.turnoverData.contractForTarget
                    )
                      ? [
                          _vm._v(
                            _vm._s(
                              _vm._f("toThousands")(
                                _vm.turnoverData.contractForTarget / 10000,
                                4
                              )
                            ) + "万元"
                          ),
                        ]
                      : [_vm._v("暂未设置")],
                  ],
                  2
                ),
                _c("div", { staticClass: "target-number" }, [
                  _vm._v(" 毛利率： "),
                  _c(
                    "span",
                    {
                      style: {
                        color: _vm.turnoverData
                          .contractForGrossProfitRateWarning
                          ? "#E84C4C"
                          : "",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.turnoverData.contractForGrossProfitRate) +
                          "%"
                      ),
                    ]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "target-item" }, [
                _c("div", { staticClass: "top" }, [
                  _c(
                    "div",
                    { staticClass: "label", staticStyle: { color: "#1e86eb" } },
                    [_vm._v("理赔")]
                  ),
                  _c(
                    "div",
                    { staticClass: "flex-center" },
                    [
                      _c(
                        "div",
                        {
                          class: {
                            rate: true,
                            waring:
                              _vm.turnoverData.claimSettlementTurnoverWarning,
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.turnoverData
                                  .claimSettlementPercentageComplete
                              ) +
                              "% "
                          ),
                        ]
                      ),
                      _c(
                        "circularProgressBar",
                        {
                          attrs: {
                            percentage: Math.min(
                              _vm.turnoverData
                                .claimSettlementPercentageComplete,
                              100
                            ),
                            width: 28,
                            strokeWidth: 2,
                            color: _vm.turnoverData
                              .claimSettlementTurnoverWarning
                              ? "#E84C4C"
                              : "#33AB79",
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src:
                                _vm.turnoverData
                                  .claimSettlementResponsiblePic ||
                                _vm.defaultAvatar,
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "money" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("toThousands")(
                          _vm.turnoverData.claimSettlementTurnover
                        )
                      ) +
                      " "
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "target-number" },
                  [
                    _vm._v(" 目标："),
                    ![null, undefined].includes(
                      _vm.turnoverData.claimSettlementTarget
                    )
                      ? [
                          _vm._v(
                            _vm._s(
                              _vm._f("toThousands")(
                                _vm.turnoverData.claimSettlementTarget / 10000,
                                4
                              )
                            ) + "万元"
                          ),
                        ]
                      : [_vm._v("暂未设置")],
                  ],
                  2
                ),
                _c("div", { staticClass: "target-number" }, [
                  _vm._v(" 毛利率： "),
                  _c(
                    "span",
                    {
                      style: {
                        color: _vm.turnoverData
                          .claimSettlementGrossProfitRateWarning
                          ? "#E84C4C"
                          : "",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.turnoverData.claimSettlementGrossProfitRate
                        ) + "%"
                      ),
                    ]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "target-item" }, [
                _c("div", { staticClass: "top" }, [
                  _c(
                    "div",
                    { staticClass: "label", staticStyle: { color: "#1c0d8e" } },
                    [_vm._v("索赔")]
                  ),
                  _c(
                    "div",
                    { staticClass: "flex-center" },
                    [
                      _c(
                        "div",
                        {
                          class: {
                            rate: true,
                            waring:
                              _vm.turnoverData
                                .claimForCompensationTurnoverWarning,
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.turnoverData
                                  .claimForCompensationPercentageComplete
                              ) +
                              "% "
                          ),
                        ]
                      ),
                      _c(
                        "circularProgressBar",
                        {
                          attrs: {
                            percentage: Math.min(
                              _vm.turnoverData
                                .claimForCompensationPercentageComplete,
                              100
                            ),
                            width: 28,
                            strokeWidth: 2,
                            color: _vm.turnoverData
                              .claimForCompensationTurnoverWarning
                              ? "#E84C4C"
                              : "#33AB79",
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src:
                                _vm.turnoverData
                                  .claimForCompensationResponsiblePic ||
                                _vm.defaultAvatar,
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "money" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("toThousands")(
                          _vm.turnoverData.claimForCompensationTurnover
                        )
                      ) +
                      " "
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "target-number" },
                  [
                    _vm._v(" 目标："),
                    ![null, undefined].includes(
                      _vm.turnoverData.claimForCompensationTarget
                    )
                      ? [
                          _vm._v(
                            _vm._s(
                              _vm._f("toThousands")(
                                _vm.turnoverData.claimForCompensationTarget /
                                  10000,
                                4
                              )
                            ) + "万元"
                          ),
                        ]
                      : [_vm._v("暂未设置")],
                  ],
                  2
                ),
                _c("div", { staticClass: "target-number" }, [
                  _vm._v(" 毛利率： "),
                  _c(
                    "span",
                    {
                      style: {
                        color: _vm.turnoverData
                          .claimForCompensationGrossProfitRateWarning
                          ? "#E84C4C"
                          : "",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.turnoverData.claimForCompensationGrossProfitRate
                        ) + "%"
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "right announcement" }, [
        _c("div", { staticClass: "common" }, [
          _c(
            "div",
            {
              staticClass: "operation-sty",
              staticStyle: { "min-height": "189px" },
            },
            [
              _c(
                "p",
                {
                  staticClass: "title",
                  staticStyle: { "text-indent": "16px" },
                },
                [_vm._v("常用操作")]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    margin: "10px 10px 0 18px",
                    display: "flex",
                    "flex-wrap": "wrap",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "commonly-btn",
                      on: {
                        click: function ($event) {
                          return _vm.editCarBill(1)
                        },
                      },
                    },
                    [_vm._v("自费开单")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "commonly-btn",
                      on: {
                        click: function ($event) {
                          return _vm.editCarBill(2)
                        },
                      },
                    },
                    [_vm._v("包干开单")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "commonly-btn",
                      on: {
                        click: function ($event) {
                          return _vm.editCarBill(3)
                        },
                      },
                    },
                    [_vm._v("理赔开单")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "commonly-btn",
                      on: {
                        click: function ($event) {
                          return _vm.editCarBill(4)
                        },
                      },
                    },
                    [_vm._v("索赔开单")]
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "operation-sty", staticStyle: { flex: "1" } },
            [
              _c("div", { staticClass: "flex-x-between title" }, [
                _c(
                  "p",
                  {
                    staticStyle: {
                      "text-indent": "16px",
                      "margin-top": "16px",
                    },
                  },
                  [_vm._v("定损单")]
                ),
                _c(
                  "p",
                  {
                    staticStyle: {
                      width: "55%",
                      "margin-right": "5px",
                      "margin-top": "16px",
                    },
                  },
                  [
                    _c("operateCompanyCascader", {
                      staticStyle: { width: "100% !important" },
                      attrs: { operateCompanyUrl: _vm.operateCompanyUrl },
                      on: {
                        "update:operateCompanyUrl": function ($event) {
                          _vm.operateCompanyUrl = $event
                        },
                        "update:operate-company-url": function ($event) {
                          _vm.operateCompanyUrl = $event
                        },
                        input: _vm.getCarLossPageStatistics,
                      },
                      model: {
                        value: _vm.operateCompanyId,
                        callback: function ($$v) {
                          _vm.operateCompanyId = $$v
                        },
                        expression: "operateCompanyId",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                {
                  staticStyle: {
                    margin: "20px 10px 0 18px",
                    display: "flex",
                    "flex-wrap": "wrap",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "stateNum",
                      on: {
                        click: function ($event) {
                          return _vm.goCarLossList(1)
                        },
                      },
                    },
                    [
                      _c("p", [
                        _vm._v(
                          _vm._s(_vm.carLossPageStates.overdueSubmissionSum)
                        ),
                      ]),
                      _c("p", [_vm._v("提报超期")]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "stateNum",
                      on: {
                        click: function ($event) {
                          return _vm.goCarLossList(2)
                        },
                      },
                    },
                    [
                      _c("p", [
                        _vm._v(_vm._s(_vm.carLossPageStates.overduePartsSum)),
                      ]),
                      _c("p", [_vm._v("对件超期")]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "stateNum",
                      on: {
                        click: function ($event) {
                          return _vm.goCarLossList(3)
                        },
                      },
                    },
                    [
                      _c("p", [
                        _vm._v(_vm._s(_vm.carLossPageStates.overdueLossSum)),
                      ]),
                      _c("p", [_vm._v("定损超期")]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "stateNum",
                      on: {
                        click: function ($event) {
                          return _vm.goCarLossList(4)
                        },
                      },
                    },
                    [
                      _c("p", [
                        _vm._v(
                          _vm._s(_vm.carLossPageStates.overdueCollectionSum)
                        ),
                      ]),
                      _c("p", [_vm._v("回款超期")]),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "follow-progress-box" },
                [
                  _vm._l(_vm.followUpStateList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: item.value,
                        staticClass: "follow-progress-item",
                        on: {
                          click: function ($event) {
                            return _vm.goCarLossListByStatus(index)
                          },
                        },
                      },
                      [
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: item.tooltipText,
                              placement: "top",
                            },
                          },
                          [
                            _c("div", { style: item.iconStyle }, [
                              _vm._v(_vm._s(item.iconText)),
                            ]),
                          ]
                        ),
                        _c(
                          "span",
                          {
                            style: {
                              color: item.iconStyle.background,
                              marginLeft: "5px",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.carLossPageStates[
                                  [
                                    "fastDisposeLossNum",
                                    "secondaryDisposeLossNum",
                                    "toBeFollowedLossNum",
                                    "insuranceCompanyToBeFollowedLossNum",
                                  ][index]
                                ]
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                  _c(
                    "div",
                    [
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "light",
                            content:
                              "当接车单状态为【已结账】对应定损单状态为【待报损、拍照对件、提交定损】则为已结未出损",
                            placement: "top-start",
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "iconfont grow-icon_doubt",
                            staticStyle: { color: "#9a9a9a" },
                          }),
                        ]
                      ),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-size": "14px",
                            "margin-left": "5px",
                          },
                        },
                        [_vm._v("已结未出损：")]
                      ),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "margin-left": "5px",
                            "font-size": "14px",
                            color: "#1e86eb",
                            cursor: "pointer",
                          },
                          on: { click: _vm.goCarLossListByStatus },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.carLossPageStates.finishedNoLossNum)
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ]
          ),
        ]),
      ]),
      _vm.setTurnoverDialog
        ? _c("set-turnover-target-dialog", {
            attrs: {
              visibility: _vm.setTurnoverDialog,
              companyId: _vm.companyId,
              companyName: _vm.companyName,
              isEdit: _vm.editTurnoverTarget,
              lastMonth: _vm.emptyTarget,
            },
            on: {
              "update:visibility": function ($event) {
                _vm.setTurnoverDialog = $event
              },
              change: _vm.changeTurnoverTarget,
            },
          })
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看营业额目标",
            visible: _vm.showNotSetDialog,
            width: "400",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showNotSetDialog = $event
            },
          },
        },
        [
          _c(
            "p",
            {
              staticStyle: {
                height: "100px",
                "text-align": "center",
                "padding-top": "16px",
              },
            },
            [_vm._v(" 当月目标暂未设置，请先设置 ")]
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.showNotSetDialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "auth",
                      rawName: "v-auth",
                      value: 538,
                      expression: "538",
                    },
                  ],
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleSetTarget },
                },
                [_vm._v("设置目标")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex", staticStyle: { width: "100%" } }, [
      _c(
        "div",
        {
          staticStyle: { width: "60%", "margin-left": "4.2%", height: "20px" },
        },
        [
          _c("p", { staticClass: "process-line-two" }),
          _c("p", { staticClass: "process-line-three" }),
          _c("p", { staticClass: "process-arrow" }),
          _c("p", { staticClass: "process-line-one" }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex" }, [
      _c("div", { staticClass: "flex", staticStyle: { width: "100%" } }, [
        _c("div", { staticStyle: { width: "20%", "margin-left": "24%" } }, [
          _c("p", {
            staticClass: "process-arrow1",
            staticStyle: { "margin-left": "-4px" },
          }),
          _c("p", {
            staticClass: "process-line-one",
            staticStyle: {
              height: "10px",
              "margin-left": "1px",
              "margin-top": "0px",
            },
          }),
          _c("p", {
            staticClass: "process-line-two",
            staticStyle: {
              "margin-left": "1px",
              width: "98%",
              "margin-top": "0px",
            },
          }),
          _c("p", {
            staticClass: "process-line-three",
            staticStyle: {
              "margin-left": "98%",
              height: "10px",
              "margin-top": "0px",
              width: "1.5px",
            },
          }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }