<template>
  <base-container ref="baseContainer" paddingTop>
    <div class="form-top">
      <el-form :model="form" label-width="100px" size="small">
        <el-row>
          <el-col :span="6">
            <el-form-item>
              <template slot="label">
                <div>
                  操作门店
                  <el-tooltip effect="light" placement="bottom-start">
                    <div slot="content">
                      调拨账款的操作门店，即调出应收的收款(调出)门店、调入应收的付款(调入)门店，<br />
                      要么只查当前登录门店，要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)
                    </div>
                    <i class="iconfont grow-icon_doubt"></i>
                  </el-tooltip>
                </div>
              </template>
              <operateCompanyCascader
                v-model="form.operateCompanyId"
              ></operateCompanyCascader>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="查询日期">
              <el-date-picker
                class="w100"
                v-model="form.times"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="截止时间"
                value-format="yyyy-MM-dd"
                :clearable="false"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <div class="el-form-item--small" style="margin-left: 20%">
              <el-button
                class="btn_search"
                type="primary"
                size="small"
                @click="query"
                >查询</el-button
              >
              <el-button class="btn_search" size="small" @click="nowDate"
                >今日</el-button
              >
              <el-button class="btn_search" size="small" @click="nowMonth"
                >本月</el-button
              >
              <el-button class="btn_search" size="small" @click="empty"
                >置空</el-button
              >
            </div>
          </el-col>
          <el-col :span="2">
            <div style="display: flex; justify-content: end">
              <el-button size="small" type="text" class="blue" @click="doExport"
                >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
              ></el-button>
              <!--  @click="showExportDialog = true" -->
            </div>
          </el-col>
        </el-row>
      </el-form>
      <div class="pay-type">
        <div class="pay-type-list">
          <div class="type-incon">
            营业总额
            <el-tooltip
              effect="light"
              placement="bottom-start"
              content="服务营业额合计+商品营业额合计-整单折扣"
            >
              <i class="iconfont grow-icon_doubt"></i>
            </el-tooltip>
          </div>
          <div class="type-word">
            <span>￥</span
            ><span>
              <!-- {{
                $lodash.get(businessList, 'turnover.totalTurnover', 0)
                  | toThousands
              }} -->
              {{ totalTurnover | toThousands }}
            </span>
            <p>
              整单折扣￥
              {{
                $lodash.get(businessList, 'turnover.totalDiscount', 0)
                  | toThousands
              }}
            </p>
          </div>
        </div>
        <div class="pay-type-list">
          <div class="type-incon">
            服务营业额
            <el-tooltip
              effect="light"
              placement="bottom-start"
              content="接车单中服务项目实收小计之和"
            >
              <i class="iconfont grow-icon_doubt"></i>
            </el-tooltip>
          </div>
          <div class="type-word">
            <span>￥</span
            ><span>{{
              $lodash.get(businessList, 'serviceCategory.total', 0)
                | toThousands
            }}</span>
            <p>
              {{
                $lodash.get(businessList, 'serviceCategory.num', 0)
                  | toThousands
              }}次
            </p>
          </div>
        </div>
        <div class="pay-type-list">
          <div class="type-incon">
            商品营业额
            <el-tooltip
              effect="light"
              placement="bottom-start"
              content="接车单中商品项目实收小计之和"
            >
              <i class="iconfont grow-icon_doubt"></i>
            </el-tooltip>
          </div>
          <div class="type-word">
            <span>￥</span
            ><span>{{
              $lodash.get(businessList, 'goodCategory.total', 0) | toThousands
            }}</span>
            <p>
              {{
                $lodash.get(businessList, 'goodCategory.num', 0) | toThousands
              }}次
            </p>
          </div>
        </div>
      </div>
      <div class="number-type-list">
        <div class="number-type">
          <div class="number-type-name">提车台次</div>
          <div class="number-type-number">
            {{
              $lodash.get(businessList, 'carHandleStatistics.takeCarNum', 0)
                | toThousands
            }}
          </div>
        </div>
        <div class="number-type">
          <div class="number-type-name">进店台次</div>
          <div class="number-type-number">
            {{
              $lodash.get(businessList, 'intoHandleStatistics.intoNum', 0)
                | toThousands
            }}
          </div>
        </div>
        <div class="number-type">
          <div class="number-type-name">进店单量</div>
          <div class="number-type-number">
            {{
              $lodash.get(businessList, 'intoHandleStatistics.intoBillNum', 0)
                | toThousands
            }}
            <!--
            {{ intoBillNum | toThousands }}
             -->
          </div>
          <div class="customer-type">
            <p>
              会员
              {{
                $lodash.get(businessList, 'intoHandleStatistics.memberNum', 0)
                  | toThousands
              }}
            </p>
            <p>
              散客
              {{
                $lodash.get(businessList, 'intoHandleStatistics.travelerNum', 0)
                  | toThousands
              }}
            </p>
            <p>
              协议
              {{
                $lodash.get(businessList, 'intoHandleStatistics.agreeNum', 0)
                  | toThousands
              }}
            </p>
          </div>
        </div>
        <div class="number-type">
          <div class="number-type-name">提车单量</div>
          <div class="number-type-number">
            <!-- {{
              $lodash.get(businessList, 'carHandleStatistics.takeCarBillNum', 0)
                | toThousands
            }} -->
            {{ takeCarBillNum | toThousands }}
          </div>
          <div class="customer-type">
            <p>
              会员
              {{
                $lodash.get(businessList, 'carHandleStatistics.memberNum', 0)
                  | toThousands
              }}
            </p>
            <p>
              散客
              {{
                $lodash.get(businessList, 'carHandleStatistics.travelerNum', 0)
                  | toThousands
              }}
            </p>
            <p>
              协议
              {{
                $lodash.get(businessList, 'carHandleStatistics.agreeNum', 0)
                  | toThousands
              }}
            </p>
          </div>
        </div>
        <div class="number-type">
          <div class="number-type-name">结账单量</div>
          <div class="number-type-number">
            <!-- {{
              $lodash.get(
                businessList,
                'settleAccountsHandleStatistics.settleAccountsBillNum',
                0
              ) | toThousands
            }} -->
            {{ settleAccountsBillNum | toThousands }}
          </div>
          <div class="customer-type">
            <p>
              会员
              {{
                $lodash.get(
                  businessList,
                  'settleAccountsHandleStatistics.memberNum',
                  0
                ) | toThousands
              }}
            </p>
            <p>
              散客
              {{
                $lodash.get(
                  businessList,
                  'settleAccountsHandleStatistics.travelerNum',
                  0
                ) | toThousands
              }}
            </p>
            <p>
              协议
              {{
                $lodash.get(
                  businessList,
                  'settleAccountsHandleStatistics.agreeNum',
                  0
                ) | toThousands
              }}
            </p>
          </div>
        </div>
      </div>
      <div
        class="orange infor-bt"
        style="background: #fff4e5; margin-top: 60px"
      >
        自费
      </div>
      <div class="source-number">
        <div>
          <p>营业总额</p>
          <span style="color: #333"
            >￥{{
              $lodash.get(totalList[0], 'selfTotal', 0) | toThousands
            }}</span
          >
        </div>
        <div>
          <p>服务营业额</p>
          <span>
            {{
              $lodash.get(
                businessList,
                'selfTotalBusinessTurnover.serviceTurnover',
                0
              ) | toThousands
            }}</span
          >
        </div>
        <div>
          <p>商品营业额</p>
          <span>
            {{
              $lodash.get(
                businessList,
                'selfTotalBusinessTurnover.goodTurnover',
                0
              ) | toThousands
            }}</span
          >
        </div>
        <div>
          <p>提车单量</p>
          <span>
            {{
              $lodash.get(
                businessList,
                'selfTotalBusinessTurnover.takeCarBillNum',
                0
              ) | toThousands
            }}
            <!-- {{ takeCarBillNum | toThousands }} -->
          </span>
        </div>
        <div>
          <p>进店单量</p>
          <span>
            {{
              $lodash.get(
                businessList,
                'selfTotalBusinessTurnover.intoBillNum',
                0
              ) | toThousands
            }}
            <!-- {{ intoBillNum | toThousands }} -->
          </span>
        </div>
        <div>
          <p>结账单量</p>
          <span>
            {{
              $lodash.get(
                businessList,
                'selfTotalBusinessTurnover.settleAccountsBillNum',
                0
              ) | toThousands
            }}
            <!-- {{ settleAccountsBillNum | toThousands }} -->
          </span>
        </div>
      </div>
      <div class="primary infor-bt" style="background: #e8f3ee">包干</div>
      <div class="source-number">
        <div>
          <p>营业总额</p>
          <span style="color: #333"
            >￥{{
              $lodash.get(totalList[0], 'contractTotal', 0) | toThousands
            }}</span
          >
        </div>
        <div>
          <p>服务营业额</p>
          <span>{{
            $lodash.get(
              businessList,
              'contractTotalBusinessTurnover.serviceTurnover',
              0
            ) | toThousands
          }}</span>
        </div>
        <div>
          <p>商品营业额</p>
          <span>{{
            $lodash.get(
              businessList,
              'contractTotalBusinessTurnover.goodTurnover',
              0
            ) | toThousands
          }}</span>
        </div>
        <div>
          <p>提车单量</p>
          <span>{{
            $lodash.get(
              businessList,
              'contractTotalBusinessTurnover.takeCarBillNum',
              0
            ) | toThousands
          }}</span>
        </div>
        <div>
          <p>进店单量</p>
          <span>{{
            $lodash.get(
              businessList,
              'contractTotalBusinessTurnover.intoBillNum',
              0
            ) | toThousands
          }}</span>
        </div>
        <div>
          <p>结账单量</p>
          <span>{{
            $lodash.get(
              businessList,
              'contractTotalBusinessTurnover.settleAccountsBillNum',
              0
            ) | toThousands
          }}</span>
        </div>
      </div>
      <div class="second infor-bt" style="background: #ecf8f8">理赔</div>
      <div class="source-number">
        <div>
          <p>营业总额</p>
          <span style="color: #333"
            >￥{{
              $lodash.get(totalList[0], 'claimSettlementTotal', 0) | toThousands
            }}</span
          >
        </div>
        <div>
          <p>服务营业额</p>
          <span>{{
            $lodash.get(
              businessList,
              'claimSettlementTotalBusinessTurnover.serviceTurnover',
              0
            ) | toThousands
          }}</span>
        </div>
        <div>
          <p>商品营业额</p>
          <span>{{
            $lodash.get(
              businessList,
              'claimSettlementTotalBusinessTurnover.goodTurnover',
              0
            ) | toThousands
          }}</span>
        </div>
        <div>
          <p>提车单量</p>
          <span>{{
            $lodash.get(
              businessList,
              'claimSettlementTotalBusinessTurnover.takeCarBillNum',
              0
            ) | toThousands
          }}</span>
        </div>
        <div>
          <p>进店单量</p>
          <span>{{
            $lodash.get(
              businessList,
              'claimSettlementTotalBusinessTurnover.intoBillNum',
              0
            ) | toThousands
          }}</span>
        </div>
        <div>
          <p>结账单量</p>
          <span>{{
            $lodash.get(
              businessList,
              'claimSettlementTotalBusinessTurnover.settleAccountsBillNum',
              0
            ) | toThousands
          }}</span>
        </div>
      </div>
      <div class="blue infor-bt" style="background: #ecf7fc">索赔</div>
      <div class="source-number">
        <div>
          <p>营业总额</p>
          <span style="color: #333"
            >￥{{
              $lodash.get(totalList[0], 'claimForCompensationTotal', 0)
                | toThousands
            }}</span
          >
        </div>
        <div>
          <p>服务营业额</p>
          <span>{{
            $lodash.get(
              businessList,
              'claimForCompensationTotalBusinessTurnover.serviceTurnover',
              0
            ) | toThousands
          }}</span>
        </div>
        <div>
          <p>商品营业额</p>
          <span>{{
            $lodash.get(
              businessList,
              'claimForCompensationTotalBusinessTurnover.goodTurnover',
              0
            ) | toThousands
          }}</span>
        </div>
        <div>
          <p>提车单量</p>
          <span>{{
            $lodash.get(
              businessList,
              'claimForCompensationTotalBusinessTurnover.takeCarBillNum',
              0
            ) | toThousands
          }}</span>
        </div>
        <div>
          <p>进店单量</p>
          <span>{{
            $lodash.get(
              businessList,
              'claimForCompensationTotalBusinessTurnover.intoBillNum',
              0
            ) | toThousands
          }}</span>
        </div>
        <div>
          <p>结账单量</p>
          <span>{{
            $lodash.get(
              businessList,
              'claimForCompensationTotalBusinessTurnover.settleAccountsBillNum',
              0
            ) | toThousands
          }}</span>
        </div>
      </div>

      <div class="custom-tabs">
        <div
          @click="status = -1"
          :class="{
            'custom-tab-item': true,
            active: status == -1,
          }"
        >
          全部
        </div>
        <div
          @click="status = 0"
          :class="{
            'custom-tab-item': true,
            active: status == 0,
          }"
        >
          自费
        </div>
        <div
          @click="status = 1"
          :class="{
            'custom-tab-item': true,
            active: status == 1,
          }"
        >
          包干
        </div>
        <div
          @click="status = 2"
          :class="{
            'custom-tab-item': true,
            active: status == 2,
          }"
        >
          理赔
        </div>
        <div
          @click="status = 3"
          :class="{
            'custom-tab-item': true,
            active: status == 3,
          }"
        >
          索赔
        </div>
        <p>注：下方柱状图与数据展示区根据所选择的业务进行展示</p>
      </div>
      <!-- 这个柱状图位置 -->
      <div class="chart-table">
        <div class="chart-table-list">
          <div class="chart-table-tip">
            <p>汇总收入明细</p>
            <p>商品服务统计已提车单据记录，未统计整单折扣</p>
          </div>
          <div ref="chart1" style="width: 100%; height: 450px"></div>
        </div>
      </div>
      <!-- 最后表格位置 -->
      <div class="footer-table">
        <div class="serve-table">
          <!-- 服务的表格 :span-method="objectSpanMethod"-->
          <el-table
            :data="tableData1"
            class="service-table"
            border
            style="
              width: 100%;
              border-radius: 4px 4px 0px 0px;
              border-bottom: 0px;
            "
            :summary-method="getServiceSummaries"
            :span-method="serviceTableSpanMethod"
            show-summary
          >
            >
            <el-table-column label="分类" width="100">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.fathername }}
                </div>
              </template>
            </el-table-column>
            <el-table-column type="expand">
              <template #default="{ row }">
                <el-table
                  :data="row.secondServiceCategories"
                  style="width: 96%; margin-left: 4%"
                  border
                >
                  <el-table-column
                    prop="firstCategoryName"
                    label="细类"
                    min-width="200"
                  >
                  </el-table-column>
                  <el-table-column prop="num" label="总数量" width="120">
                  </el-table-column>
                  <el-table-column
                    prop="memberCardPayNum"
                    label="含卡扣数"
                    width="120"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="cost"
                    label="出库成本"
                    width="120"
                  ></el-table-column>
                  <el-table-column prop="total" label="项目小计" width="120">
                    <template slot-scope="scope">
                      <span class="pointer text_operation blue">{{
                        scope.row.total
                      }}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <el-table-column
              prop="firstCategoryName"
              label="大类"
              min-width="200"
            >
            </el-table-column>
            <el-table-column prop="num" label="总数量" width="120">
            </el-table-column>
            <el-table-column
              prop="memberCardPayNum"
              label="含卡扣数"
              width="120"
            >
            </el-table-column>
            <el-table-column
              prop="cost"
              label="出库成本"
              width="120"
            ></el-table-column>
            <el-table-column prop="total" label="项目小计" width="120">
              <template slot-scope="scope">
                <span class="pointer text_operation blue">{{
                  scope.row.total
                }}</span>
              </template>
            </el-table-column>
          </el-table>
          <!-- 商品的表格 -->
          <el-table
            :show-header="false"
            :data="goodsTableList"
            border
            style="width: 100%; border-radius: 0px; border-bottom: 0px"
            :span-method="goodsTableSpanMethod"
            :summary-method="getGoodsSummaries"
            show-summary
          >
            <el-table-column label="分类" width="100">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.fathername }}
                </div>
              </template>
            </el-table-column>
            <el-table-column type="expand">
              <template #default="{ row }">
                <el-table
                  :data="row.secondServiceCategories"
                  style="width: 96%; margin-left: 4%"
                  border
                >
                  <el-table-column
                    prop="firstCategoryName"
                    label="细类"
                    min-width="200"
                  >
                  </el-table-column>
                  <el-table-column prop="num" label="总数量" width="120">
                  </el-table-column>
                  <el-table-column
                    prop="memberCardPayNum"
                    label="含卡扣数"
                    width="120"
                  >
                  </el-table-column>
                  <el-table-column label="出库成本" width="120" prop="cost">
                  </el-table-column>
                  <el-table-column prop="total" label="项目小计" width="120">
                    <template slot-scope="scope">
                      <span class="pointer text_operation blue">{{
                        scope.row.total
                      }}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <el-table-column
              prop="firstCategoryName"
              label="大类"
              min-width="200"
            >
            </el-table-column>
            <el-table-column prop="num" label="总数量" width="120">
            </el-table-column>
            <el-table-column
              prop="memberCardPayNum"
              label="含卡扣数"
              width="120"
            >
            </el-table-column>
            <el-table-column prop="cost" label="出库成本" width="120">
            </el-table-column>
            <el-table-column prop="total" label="项目小计" width="120">
              <template slot-scope="scope">
                <span class="pointer text_operation blue">{{
                  scope.row.total
                }}</span>
              </template>
            </el-table-column>
          </el-table>
          <div class="statistical-style flex">
            <p>
              整单折扣
              <el-tooltip
                effect="light"
                placement="bottom-start"
                content="接车单中订单总折扣金额"
              >
                <i class="iconfont grow-icon_doubt"></i>
              </el-tooltip>
            </p>
            <p>
              <span style="margin-left: 10px">
                {{
                  $lodash.get(businessList, 'turnover.totalDiscount', 0)
                    | toThousands
                }}</span
              >
            </p>
          </div>
          <div class="statistical-style flex">
            <p>
              营业总额
              <el-tooltip
                effect="light"
                placement="bottom-start"
                content="服务营业额合计 + 商品营业额合计 -整单折扣"
              >
                <i class="iconfont grow-icon_doubt"></i>
              </el-tooltip>
            </p>
            <p>
              <span style="margin-left: 10px">{{
                totalTurnover | toThousands
              }}</span>
            </p>
          </div>
          <!-- 其他业务的表格 -->
          <el-table
            :show-header="false"
            :data="otherBusinessTableList"
            border
            style="width: 100%; border-radius: 0px 0px 4px 4px"
            :span-method="objectSpanMethod"
            :summary-method="getOtherSummaries"
            show-summary
          >
            <el-table-column label="分类" width="100">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.fathername }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="firstCategoryName"
              label="大类-细类"
              min-width="200"
            >
            </el-table-column>
            <el-table-column prop="num" label="总数量" width="120">
            </el-table-column>
            <el-table-column
              prop="memberCardPayNum"
              label="含卡扣数"
              width="120"
            >
            </el-table-column>
            <el-table-column prop="cost" label="出库成本" width="120">
            </el-table-column>
            <el-table-column prop="total" label="项目小计" width="120">
              <template slot-scope="scope">
                <span class="pointer text_operation blue">{{
                  scope.row.total
                }}</span>
              </template>
            </el-table-column>
          </el-table>
          <div class="statistical-style flex" style="background-color: #f5f7fa">
            <p>
              收入合计
              <el-tooltip
                effect="light"
                placement="bottom-start"
                content="营业总额 + 其他业务合计"
              >
                <i class="iconfont grow-icon_doubt"></i>
              </el-tooltip>
            </p>
            <p>
              <span style="margin-left: 10px">{{
                (totalTurnover + otherBusinessTotal) | toThousands
              }}</span>
            </p>
          </div>
        </div>

        <div class="pay-card-table">
          <el-table
            :data="tableData2"
            border
            :header-cell-style="{
              background: '#FAFAFA',
              color: '#000000',
              fontSize: '14px',
              fontWeight: '500',
            }"
            :span-method="objectSpanMethod"
          >
            <el-table-column prop="fathername" mix-width="50">
            </el-table-column>
            <el-table-column prop="payName" label="收款方式" mix-width="180">
            </el-table-column>
            <el-table-column prop="total" label="收款金额" mix-width="100">
              <template slot-scope="scope">
                <span
                  class="pointer text_operation blue"
                  style="float: right"
                  >{{ scope.row.total }}</span
                >
              </template>
            </el-table-column>
            <el-table-column label="合计" mix-width="100">
              <template slot-scope="scope">
                <span style="float: right">{{ scope.row.alltotal }}</span>
              </template>
            </el-table-column>

            <template slot="append">
              <div class="table_footer">
                <p class="table_footer_deripto">收入合计</p>
                <p class="table_footer_deriptt">
                  {{ combinedTotal | toThousands }}
                </p>
              </div>
            </template>
          </el-table>
          <el-table :data="memberCardBill" border style="margin-top: 10px">
            <el-table-column prop="name" label="会员卡" mix-width="100">
            </el-table-column>
            <el-table-column prop="num" label="数量" mix-width="100">
              <template slot-scope="scope">
                <span class="pointer text_operation blue">{{
                  scope.row.num
                }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="total" label="金额" mix-width="100">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <el-dialog title="导出提示" :visible.sync="showExportDialog" center>
      <div class="export-dialog__body">
        <div class="export-dialog-page__input">
          <span>导出范围：第</span>
          <el-input
            type="number"
            size="small"
            v-model="startPage"
            :max="Math.ceil(total / pageOption.size)"
            :min="1"
          ></el-input>
          <span>至</span>
          <el-input
            type="number"
            size="small"
            v-model="endPage"
            :max="Math.ceil(total / pageOption.size)"
            :min="1"
          ></el-input>
        </div>
        <p class="export-dialog__tip">
          共{{ Math.ceil(total / pageOption.size) }}页，一次最多导出10000条数据
        </p>
      </div>
      <div slot="footer">
        <el-button @click="showExportDialog = false">取 消</el-button>
        <el-button type="primary" @click="doExport">确 定</el-button>
      </div>
    </el-dialog>
  </base-container>
</template>
<script>
// 工具
import * as echarts from 'echarts';
import { exportCusterListUrl } from '@/api/customer/customer';
// 分页组件
import { downloadFile } from '@/utils/fileApi';
import { mapState } from 'vuex';
import {
  getBusinessList,
  getFinanceCollectType,
  exportBusinessCollectListUrl,
} from '@/api/financial/financialOver';
import operateCompanyCascader from '@/components/businessComponent/operateCompanyCascader';
// 工具
import Big from 'big.js';
export default {
  name: 'showBusinessDetailsList',
  components: { operateCompanyCascader },
  data() {
    return {
      form: {
        times: [],
      },
      //导出的页面设置
      // startPage: 1,
      // endPage: 1,
      //分页组件
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      showExportDialog: false,

      //柱状图
      chart1: '',
      chartOption1: {
        xAxis: {
          type: 'category',
          data: [],
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            data: [],
            type: 'bar',
            barWidth: 40, //柱图宽度
          },
        ],
        color: ['#33ab79'],
      },
      //尾部表格
      tableData1: [],
      tableData2: [],
      businessList: [],
      //营业总额字段
      totalList: [],
      chartXList: [],
      spanArr: [], //用于存放每一行记录的合并数
      status: -1,
      actuaTotal: '', //实际收入合计
      otherTotal: '', //其他收入合计
      combinedTotal: '', //右表格的收入合计
      totalTurnover: '', //营业总额字段
      takeCarBillNum: '', //提车单量字段
      intoBillNum: '', //进店单量字段
      settleAccountsBillNum: '', // 结账单量字段
      memberCardBill: [], //会员卡表格字段

      //左一表格分开表格显示数据
      goodsTableList: [],
      otherBusinessTableList: [],
      otherBusinessTotal: '', //其他业务合计字段

      //表格商品营业额合计字段
      totalNum: '',
      totalMemberCardPayNum: '',
      totalCos: '',
      totals: '',
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  watch: {
    status() {
      this.getChangeBillType();
    },
  },
  created() {
    this.form.operateCompanyId = [this.companyId];
    this.pickTimes();
    this.getList();
    this.getChangeBillType();
  },
  mounted() {
    this.$nextTick(() => {
      this.chart1 = echarts.init(this.$refs.chart1);
      this.chart1.setOption(this.chartOption1);
    });
  },
  methods: {
    // 置空输入框数据
    empty() {
      this.form.operateCompanyId = [];
    },
    //设置查询日期的默认时间
    pickTimes() {
      var tYear = new Date().getFullYear();
      var tMonth = new Date().getMonth();
      tMonth = tYear + '-' + this.doHandleZero(tMonth + 1) + '-' + '01';
      this.form.times = [tMonth, new Date().Format('yyyy-MM-dd')];
    },
    nowDate() {
      this.form.times = [
        new Date().Format('yyyy-MM-dd'),
        new Date().Format('yyyy-MM-dd'),
      ];
      // this.dateRangeChange();
      this.getList();
      this.getChangeBillType();
    },
    // 补零函数
    doHandleZero(zero) {
      var date = zero;
      if (zero.toString().length == 1) {
        date = '0' + zero;
      }
      return date;
    },
    nowMonth() {
      var tYear = new Date().getFullYear();
      var tMonth = new Date().getMonth();
      tMonth = tYear + '-' + this.doHandleZero(tMonth + 1) + '-' + '01 ';
      this.form.times = [tMonth, new Date().Format('yyyy-MM-dd')];
      this.dateRangeChange();
      this.getList();
      this.getChangeBillType();
    },
    /**
     * 服务表格合并方法
     */
    serviceTableSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex === 0) {
          return [row.rowspan, 1];
        } else {
          return [0, 0];
        }
      }
    },
    /**
     * 商品表格合并方法
     */
    goodsTableSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex === 0) {
          return [row.rowspan, 1];
        } else {
          return [0, 0];
        }
      }
    },
    // 合并行
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (
        row.fathername == '实际收款方式' ||
        row.fathername == '其他收款方式'
      ) {
        if (columnIndex === 0 || columnIndex === 3) {
          var rowCount = 1;
          var fathername = row.fathername;
          var index = rowIndex;
          while (
            this.tableData2.length - 1 > index &&
            this.tableData2[rowIndex - 1]?.fathername !== fathername &&
            fathername === this.tableData2[index + 1].fathername
          ) {
            rowCount++;
            index++;
          }
          if (rowCount > 1) {
            return {
              rowspan: rowCount,
              colspan: 1,
            };
          }
          if (this.tableData2[rowIndex - 1]?.fathername === fathername) {
            return {
              colspan: 0,
              rowspan: 0,
            };
          }
        }
      } else {
        // return {
        //   colspan: 0,
        //   rowspan: 0,
        // };

        //  其他业务表格的合并
        if (columnIndex === 0) {
          rowCount = 1;
          fathername = row.fathername;
          index = rowIndex;
          while (
            this.otherBusinessTableList.length - 1 > index &&
            this.otherBusinessTableList[rowIndex - 1]?.fathername !==
              fathername &&
            fathername === this.otherBusinessTableList[index + 1].fathername
          ) {
            rowCount++;
            index++;
          }
          if (rowCount > 1) {
            return {
              rowspan: rowCount,
              colspan: 1,
            };
          }
          if (
            this.otherBusinessTableList[rowIndex - 1]?.fathername === fathername
          ) {
            return {
              colspan: 0,
              rowspan: 0,
            };
          }
        }
      }
    },
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
    },
    //导出客户列表
    doExport() {
      var data = {
        operateCompanyId: this.form.operateCompanyId || [this.companyId],
        billType: this.status == -1 ? '' : this.status,
        startDate: this.form.times?.[0]
          ? this.form.times?.[0] + ' 00:00:00'
          : '',
        endDate: this.form.times?.[1] ? this.form.times?.[1] + ' 23:59:59' : '',
      };
      downloadFile({
        url: exportBusinessCollectListUrl,
        // data: Object.assign(data, {
        //   exportStartNum: Big(this.startPage - 1).times(this.pageOption.size),
        //   exportEndNum: Big(this.endPage).times(this.pageOption.size),
        // }),
        data: data,
      });
      // this.showExportDialog = false;
    },
    //获取数据
    getList() {
      this.totalList = [];
      var data = {
        operateCompanyId: this.form.operateCompanyId || [this.companyId],
        startDate: this.form.times?.[0]
          ? this.form.times?.[0] + ' 00:00:00'
          : '',
        endDate: this.form.times?.[1] ? this.form.times?.[1] + ' 23:59:59' : '',
      };

      getBusinessList(data).then((res) => {
        this.businessList = res;
        this.memberCardBill = res.memberCardBill || [];
        //营业总额字段
        this.totalTurnover = this.businessList?.turnover?.totalTurnover || 0;
        //提车单量字段
        this.takeCarBillNum =
          this.businessList.carHandleStatistics.memberNum +
          this.businessList.carHandleStatistics.travelerNum +
          this.businessList.carHandleStatistics.agreeNum;
        //进店单量字段
        this.intoBillNum =
          this.businessList.intoHandleStatistics.memberNum +
          this.businessList.intoHandleStatistics.travelerNum +
          this.businessList.intoHandleStatistics.agreeNum;
        // 结账单量字段
        this.settleAccountsBillNum =
          this.businessList.settleAccountsHandleStatistics.memberNum +
          this.businessList.settleAccountsHandleStatistics.travelerNum +
          this.businessList.settleAccountsHandleStatistics.agreeNum;
        //自费、包干、索赔、理赔的营业总额
        this.totalList.push({
          selfTotal:
            res.selfTotalBusinessTurnover.serviceTurnover +
            res.selfTotalBusinessTurnover.goodTurnover,
          contractTotal:
            res.contractTotalBusinessTurnover.serviceTurnover +
            res.contractTotalBusinessTurnover.goodTurnover,
          claimSettlementTotal:
            res.claimSettlementTotalBusinessTurnover.serviceTurnover +
            res.claimSettlementTotalBusinessTurnover.goodTurnover,
          claimForCompensationTotal:
            res.claimForCompensationTotalBusinessTurnover.serviceTurnover +
            res.claimForCompensationTotalBusinessTurnover.goodTurnover,
        });
      });
    },
    //柱状图四种类型切换事件
    getChangeBillType() {
      this.chartXList = [];
      this.tableData2 = [];
      this.tableData1 = [];
      this.goodsTableList = [];
      this.otherBusinessTableList = [];
      var data = {
        billType: this.status == -1 ? '' : this.status,
        operateCompanyId: this.form.operateCompanyId || [this.companyId],
        startDate: this.form.times?.[0]
          ? this.form.times?.[0] + ' 00:00:00'
          : '',
        endDate: this.form.times?.[1] ? this.form.times?.[1] + ' 23:59:59' : '',
      };
      getFinanceCollectType(data).then((res) => {
        this.chartXList = this.chartXList.concat(
          ...this.$lodash.cloneDeep(
            res.serviceCategory.firstServiceCategories.map((item) => ({
              name: item.firstCategoryName,
              total: item.total,
            }))
          ),
          ...this.$lodash.cloneDeep(
            res.goodCategory.firstServiceCategories.map((item) => ({
              name: item.firstCategoryName,
              total: item.total,
            }))
          ),
          ...this.$lodash.cloneDeep(res.otherBusiness.details)
        );
        //动态赋值柱状图数据
        this.chart1.setOption({
          xAxis: {
            type: 'category',
            data: this.$lodash.cloneDeep(
              this.chartXList.map((item) => {
                // 每一项的名字
                return item.name;
              })
            ),
          },
          series: [
            {
              data: this.$lodash.cloneDeep(
                this.chartXList.map((item) => {
                  return item.total;
                })
              ),
              type: 'bar',
              barWidth: 40, //柱图宽度
            },
          ],
        });
        this.actuaTotal = this.getTotalPrice(
          this.$lodash.cloneDeep(res.actualIncome.payDetails)
        ); //实际收入合计
        this.otherTotal = this.getTotalPrice(
          this.$lodash.cloneDeep(res.otherIncome.payDetails)
        ); //其他收入合计
        //尾部右一表格数据
        this.tableData2 = this.tableData2.concat(
          ...this.$lodash.cloneDeep(
            res.actualIncome.payDetails.map((item) => ({
              fathername: '实际收款方式',
              payName: item.payName,
              total: item.total,
              alltotal: this.actuaTotal,
            }))
          ),
          ...this.$lodash.cloneDeep(
            res.otherIncome.payDetails.map((item) => ({
              fathername: '其他收款方式',
              payName: item.payName,
              total: item.total,
              alltotal: this.otherTotal,
            }))
          )
        );
        //求右表格收入合计
        this.combinedTotal = this.actuaTotal + this.otherTotal;
        //尾部左一表格数据
        // this.tableData1 = this.tableData1.concat(
        //   ...this.$lodash.cloneDeep(
        //     res.serviceCategory.firstServiceCategories.map((item) => ({
        //       fathername: '服务',
        //       firstCategoryName: item.firstCategoryName,
        //       memberCardPayNum: item.memberCardPayNum,
        //       total: item.total,
        //       cost: item.cost,
        //       num: item.num,
        //     }))
        //   ),
        //   ...this.$lodash.cloneDeep(
        //     res.goodCategory.firstServiceCategories.map((item) => ({
        //       fathername: '商品',
        //       firstCategoryName: item.firstCategoryName,
        //       memberCardPayNum: item.memberCardPayNum,
        //       total: item.total,
        //       cost: item.cost,
        //       num: item.num,
        //     }))
        //   ),
        //   ...this.$lodash.cloneDeep(
        //     res.otherBusiness.details.map((item) => ({
        //       fathername: '其他业务',
        //       firstCategoryName: item.name,
        //       total: item.total,
        //     }))
        //   )
        // );

        //左下角服务表格
        var id = 1;
        this.tableData1 = this.$lodash.cloneDeep(
          res.serviceCategory.firstServiceCategories.map((item) => ({
            fathername: '服务',
            id: id++,
            firstCategoryName: item.firstCategoryName,
            memberCardPayNum: item.memberCardPayNum,
            secondServiceCategories:
              item.secondServiceCategories?.map((el) => ({
                fathername: '服务',
                id: id++,
                firstCategoryName: el.secondCategoryName,
                memberCardPayNum: el.memberCardPayNum,
                total: el.total,
                num: el.num,
                cost: el.cost,
              })) || [],
            total: item.total,
            num: item.num,
          }))
        );
        if (this.tableData1.length != 0) {
          this.tableData1[0].rowspan = id + 1;
        }

        //左下角商品表格
        var goodId = 1;
        this.goodsTableList = this.$lodash.cloneDeep(
          res.goodCategory.firstServiceCategories.map((item) => ({
            fathername: '商品',
            goodId: goodId++,
            firstCategoryName: item.firstCategoryName,
            memberCardPayNum: item.memberCardPayNum,
            secondServiceCategories:
              item.secondServiceCategories?.map((el) => ({
                fathername: '商品',
                goodId: goodId++,
                firstCategoryName: el.secondCategoryName,
                memberCardPayNum: el.memberCardPayNum,
                total: el.total,
                num: el.num,
                cost: el.cost,
              })) || [],
            total: item.total,
            cost: item.cost,
            num: item.num,
          }))
        );
        if (this.goodsTableList.length != 0) {
          this.goodsTableList[0].rowspan = goodId + 1;
        }

        //左下其他业务表格
        this.otherBusinessTableList = this.$lodash.cloneDeep(
          res.otherBusiness.details.map((item) => ({
            fathername: '其他业务',
            firstCategoryName: item.name,
            total: item.total,
          }))
        );
        this.otherBusinessTotal = this.otherBusinessTableList.reduce(
          (prev, item) => {
            return prev + Number(item.total) || 0;
          },
          0
        );
        this.calcSummaries();
      });
    },
    //计算合计
    calcSummaries() {
      //计算当页合计
      var list = this.$lodash.cloneDeep(this.goodsTableList);
      this.totalNum = list
        .reduce((prev, item) => {
          return prev + +item.num || 0;
        }, 0)
        .toFixed(2);
      this.totalMemberCardPayNum = list
        .reduce((prev, item) => {
          return prev + +item.memberCardPayNum || 0;
        }, 0)
        .toFixed(2);
      this.totalCost = list
        .reduce((prev, item) => {
          return prev + +item.cost || 0;
        }, 0)
        .toFixed(2);
      this.totals = list
        .reduce((prev, item) => {
          return prev + +item.total || 0;
        }, 0)
        .toFixed(2);
    },
    //自定义服务表格的合计方法
    getServiceSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 2) {
          sums[index] = '服务营业额合计';
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] += ' ';
        } else {
          sums[index] = '';
        }
      });
      return sums;
    },
    getGoodsSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 2) {
          sums[index] = '商品营业额合计';
          return;
        }
        //合计列展示
        if (index > 2) {
          switch (index) {
            case 3:
              sums[index] = this.totalNum;
              break;
            case 4:
              sums[index] = this.totalMemberCardPayNum;
              break;
            case 5:
              sums[index] = this.totalCost;
              break;
            case 6:
              sums[index] = this.totals;
              break;
          }
        }
        // const values = data.map((item) => Number(item[column.property]));
        // if (!values.every((value) => isNaN(value))) {
        //   sums[index] = values.reduce((prev, curr) => {
        //     const value = Number(curr);
        //     if (!isNaN(value)) {
        //       return prev + curr;
        //     } else {
        //       return prev;
        //     }
        //   }, 0);
        //   sums[index] += ' ';
        // } else {
        //   sums[index] = '';
        // }
      });

      return sums;
    },
    getOtherSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 4) {
          sums[index] = '其它业务合计';
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] += ' ';
        } else {
          sums[index] = '';
        }
      });

      return sums;
    },
    //计算合计的函数  pre代表前一个值，cur代表后一个值，后面的0代表初始第一个值为0
    getTotalPrice(price) {
      return price.reduce((pre, cur) => {
        return pre + cur.total;
      }, 0);
    },
    //查询按钮
    query() {
      this.getList();
      this.getChangeBillType();
    },
    //表格后面添加行
    addAfterRowRelyon(index, tableData1) {
      let insert = { memberCardPayNum: '', num: '', firstCategoryName: '' };
      console.log('角标的', index);
      this.tableData1.splice(index + 1, 0, insert);
    },
  },
};
</script>
<style lang="scss" scoped>
.el-table th.el-table__cell.is-leaf {
  color: #999 !important;
}
// ::v-deep.base-container.default-padding {
//   padding: 30px !important;
// }
//修改选择时间框长度
.el-form {
  width: 100%;
  .el-form-item__content > div {
    width: 100%;
  }
}
.last-type-sty {
  display: flex;
  .el-input {
    margin-left: 20px;
  }
}
.pay-type {
  width: 98%;
  margin: 20px 1% 30px;
  display: flex;
  justify-content: space-between;
  .pay-type-list {
    width: 30%;
    height: 120px;
    background: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(102, 102, 102, 0.1);
    border-radius: 4px;
    padding-top: 16px;
    .type-incon {
      margin: 0px 18px 24px;
      color: #666666;
    }
    .type-word {
      margin-left: 15px;
      span {
        color: #333333;
        font-size: 16px;
        & + span {
          font-size: 28px;
          font-family: Helvetica-Bold, Helvetica;
          font-weight: bold;
        }
      }
      span:first-child {
        margin-right: 4px;
        font-weight: 600;
      }
      p {
        color: #999999;
        font-size: 14px;
        margin-top: 12px;
        margin-left: 5px;
      }
    }
  }
}
.infor-bt {
  width: 87px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin-left: 1%;
  border: 0px;
  border-radius: 4px 4px 0px 0px;
}
.number-type-list {
  width: 98%;
  margin: 20px 1%;
  display: flex;
  justify-content: space-between;
  .number-type {
    width: 19%;
    height: 101px;
    background: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(102, 102, 102, 0.1);
    border-radius: 4px;
    color: #666666;
    .number-type-name {
      margin: 10px 18px 14px;
      font-size: 14px;
    }
    .number-type-number {
      text-align: center;
      font-size: 22px;
    }
    .customer-type {
      display: flex;
      justify-content: space-between;
      color: #999999;
      font-size: 12px;
      margin: 13px 16px;
    }
  }
}
.source-number {
  width: 98%;
  margin: 0px 1% 30px;
  display: flex;
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(153, 153, 153, 0.1);
  border-radius: 2px;
  div {
    width: 16%;
    &:first-child {
      width: 14%;
      border-right: 1px solid #e8e8e8;
      margin: 0px 24px;
    }
    p {
      line-height: 36px;
      color: #333;
    }
    span {
      font-size: 26px;
      color: #666666;
    }
    & + div p {
      color: #666;
    }
  }
}
.chart-table {
  width: 100%;
  height: 500px;
  border-radius: 6px;
  display: flex;
  .chart-table-list {
    width: 100%;
    height: 500px;
    box-shadow: 0px 0px 10px 0px rgba(153, 153, 153, 0.1);
    .chart-table-tip {
      margin: 24px;
      p:first-child {
        font-size: 18px;
        span {
          font-size: 12px;
        }
      }
      p:last-child {
        color: #808080;
        font-size: 12px;
        margin-top: 5px;
      }
    }
  }
}
.footer-table {
  display: flex;
  justify-content: space-between;
  margin: 40px 0px;
  .serve-table {
    width: 70%;
  }
  .pay-card-table {
    width: 28%;
  }
  .table_footer {
    line-height: 50px;
    display: flex;
    width: 100%;
    p {
      width: 76%;
      text-align: end;
      padding-right: 5px;
      border-right: 1px solid #f2f2f2;
      & + p {
        width: 24%;
      }
    }
  }
}
//导出列表样式
.export-dialog__body {
  margin: 20px 0;
  text-align: center;
  .export-dialog-page__input {
    display: flex;
    justify-content: center;
    align-items: center;
    .el-input {
      width: 140px;
      margin: 0 15px;
    }
  }
  .export-dialog__tip {
    margin-top: 20px;
  }
}
.data-statis {
  margin: 20px 0px;
  font-size: 14px;
  span {
    margin-right: 30px;
  }
}
.custom-tabs {
  display: flex;
  margin-bottom: 10px;
  .custom-tab-item {
    padding: 10px 16px;
    border-radius: 4px;
    border: 1px solid #eee;
    cursor: pointer;
    color: #999999;
    & + .custom-tab-item {
      margin-left: 30px;
    }
    &.active,
    &:hover {
      color: $primary;
      background: $bg;
    }
  }
  &.nopadding .custom-tab-item {
    margin-left: 0;
  }
  p {
    font-size: 12px;
    color: #999;
    margin: 16px 20px 0px;
  }
}

.statistical-style {
  width: 99.9%;
  height: 48px;
  line-height: 48px;
  border: 1px solid #f2f2f2;
  font-size: 14px;
  color: #606266;
  justify-content: end;
  p:nth-child(1) {
    margin-right: 10px;
  }
  p:nth-child(2) {
    width: 120px;
    border-left: 1px solid #ebeef5;
  }
}
</style>
