<template>
  <el-dialog
    :title="title"
    :visible="visibility"
    width="800px"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <el-form
      ref="form"
      size="small"
      :model="editForm"
      :rules="rules"
      label-width="90px"
      style="margin-top: 18px"
    >
      <el-row :gutter="10">
        <el-col :span="24" :offset="0">
          <el-form-item label="流程单号" prop="internalSettTrackingSeq">
            <el-input
              v-model="editForm.internalSettTrackingSeq"
              maxlength="200"
              placeholder="请输入内部结算流程单号"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24" :offset="0">
          <el-form-item label="提单备注" prop="remark">
            <el-input
              type="textarea"
              :rows="5"
              v-model="editForm.remark"
              maxlength="200"
              placeholder="请输入提单备注"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button size="small" type="primary" @click="save"> 确 定 </el-button>
      <el-button size="small" @click="handleClose"> 取 消 </el-button>
    </template>
  </el-dialog>
</template>

<script>
// 组件
// 接口
import { transactionBatchSubmitTask } from '@/api/financial/financialOver';
export default {
  name: 'editSubmitWorkDialog',
  components: {},
  props: {
    visibility: Boolean,
    ids: {
      type: Array,
      require: true,
      default: () => [],
    },
    submitInternalSettTrackingSeq: {
      type: String,
      require: false,
      default: () => '',
    },
    submitRemark: {
      type: String,
      require: false,
      default: () => '',
    },
  },
  data() {
    return {
      // 表单信息
      editForm: {
        internalSettTrackingSeq: '',
        remark: '',
      },
      // 表单规则
      rules: {
        internalSettTrackingSeq: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入内部结算流程单号',
          },
        ],
      },
    };
  },
  computed: {
    title() {
      return '回款认领流水提单';
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.submitRemark !== undefined) {
        this.editForm.remark = this.submitRemark;
      }
      if (this.submitInternalSettTrackingSeq !== undefined) {
        this.editForm.internalSettTrackingSeq =
          this.submitInternalSettTrackingSeq;
      }
    },
    /**
     * 保存
     */
    save() {
      this.$refs.form
        .validate()
        .then((result, object) => {
          var body = {
            ids: this.ids,
            internalSettTrackingSeq: this.editForm.internalSettTrackingSeq,
            remark: this.editForm.remark,
          };
          transactionBatchSubmitTask(body).then((res) => {
            this.$message.success('保存成功！');
            this.$emit('save');
            this.handleClose();
          });
        })
        .catch((error) => {
          console.log(error);
          var msg =
            '<p>' +
            Object.values(error)
              .map((el) => el.map((v) => v.message))
              .join('<br />') +
            '</p>';
          this.$message({
            type: 'warning',
            dangerouslyUseHTMLString: true,
            message: msg,
          });
        });
    },

    /**
     * 关闭抽屉
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item {
  .el-date-editor,
  .el-select {
    width: 100%;
  }
  .el-form-item__label {
    width: 100px;
  }
  .el-form-item__content {
    margin-left: 100px;
  }
}
.selected-car-model-list {
  margin: 6px 0 12px 0;
  .el-tag {
    margin-top: 6px;
    margin-left: 6px;
  }
}
</style>
