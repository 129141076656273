<template>
  <el-drawer
    title="选择要返修追溯的车单"
    :visible="visibility"
    size="60%"
    :append-to-body="true"
    :before-close="handleClose"
  >
    <div style="height: 70%; padding: 0 30px">
      <base-container :default-padding="false">
        <template slot="header">
          <p>选择一条记录，点击下面的表格行</p>
        </template>
        <div class="content-box">
          <el-table
            ref="multipleTable"
            :data="tableData"
            border
            highlight-current-row
            class="data-table"
            @current-change="handleSelectionChange"
          >
            <el-table-column label="序号" type="index" width="60">
            </el-table-column>
            <el-table-column width="180" prop="companyName" label="操作门店">
            </el-table-column>
            <el-table-column width="180" prop="billCode" label="车单号">
            </el-table-column>
            <el-table-column
              width="180"
              prop="dateCreated"
              label="车辆进场日期"
            >
            </el-table-column>
            <el-table-column width="180" prop="projNames" label="车单项目">
            </el-table-column>
            <el-table-column
              width="180"
              prop="detectionUserNames"
              label="检车人"
            >
            </el-table-column>
            <el-table-column width="180" prop="technicianNames" label="施工人">
            </el-table-column>
          </el-table>
        </div>
        <template slot="footer">
          <pagination @pageChange="changePage" :total="total"></pagination>
        </template>
      </base-container>
    </div>
    <div class="footer">
      <el-button type="primary" size="small" @click="confirmSelect"
        >确 定</el-button
      >
      <el-button size="small" @click="handleClose">取 消</el-button>
    </div>
  </el-drawer>
</template>
<script>
import { findTracingBillByCarId } from '@/api/carBill';
import pagination from '@/components/Pagination.vue';
import { mapState } from 'vuex';
export default {
  name: 'selectRepairTracingBillDrawer',
  props: {
    visibility: { type: Boolean, default: false },
    carId: [Number],
    oldData: {
      type: Array,
      default: () => [],
    },
  },
  components: { pagination },
  data() {
    return {
      // 列表数据
      tableData: [],
      selectedRow: undefined,
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
    };
  },
  created() {
    this.getListData();
  },
  methods: {
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getListData();
    },
    /**
     * 关闭弹窗
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
    /**
     * table选中事件
     */
    handleSelectionChange(row) {
      this.selectedRow = row;
    },
    /**
     * 确认选择
     */
    confirmSelect() {
      // 调用自定义事件，传入参数
      if (this.selectedRow == undefined) {
        this.$message.error('请选择1条返修追溯车单！', 5000);
        return;
      }
      this.$emit('select', this.selectedRow);
      this.$emit('update:visibility', false);
    },

    /**
     * 获取数据
     */
    getListData() {
      var body = Object.assign(
        {},
        {
          ...this.pageOption,
          carId: this.carId,
        }
      );
      findTracingBillByCarId(body, {
        noShowWaiting: false,
      }).then((res) => {
        this.tableData = res.records;
        this.total = res.total;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.page-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
  &::v-deep .el-tabs__content {
    flex: 1;
    .el-tab-pane {
      height: calc(100% - 109px);
    }
  }
}

.content-box {
  height: 100%;
  display: flex;
  padding-top: 20px;
  box-sizing: border-box;
  .data-table {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    ::v-deep .el-table__header-wrapper {
      flex-shrink: 0;
    }
    ::v-deep .el-table__body-wrapper {
      flex: 1;
      overflow: auto;
    }
  }
}

.class-box {
  width: 30%;
  height: 100%;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  .el-tree {
    overflow: auto;
  }
}

.class-box .title {
  flex-shrink: 0;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #e4e4e4;
  background: #fafafa;
}

.footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 109px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  padding-right: 60px;
  // border-top: 1px solid #e4e4e4;
  // border-bottom: 1px solid #e4e4e4;
  box-shadow: -4px 0px 30px 0px rgb(0 0 0 / 10%);
  background: #fff;
  z-index: 99;
}
.footer > p {
  margin-right: 30px;
}
.footer span {
  color: #999;
  font-size: 14px;
  margin-left: 10px;
}
</style>
