var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { height: "600px" } }, [
    _c("p", [_vm._v("预览图")]),
    _c(
      "div",
      {
        ref: "printDom",
        staticStyle: { width: "800px", padding: "12px", background: "white" },
        attrs: { id: "tableId" },
      },
      _vm._l(_vm.tableList, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            ref: "print",
            refInFor: true,
            staticClass: "settlement-page",
            staticStyle: { "page-break-after": "always", position: "relative" },
          },
          [
            _c("div", { staticStyle: { display: "flex" } }, [
              _c("div", [
                _vm.config.leftImagePath
                  ? _c("img", {
                      staticStyle: {
                        "min-width": "75px",
                        "max-width": "200px",
                        height: "75px",
                      },
                      attrs: { src: _vm.config.leftImagePath },
                    })
                  : _vm._e(),
                _vm.config.rightImagePath
                  ? _c("img", {
                      staticStyle: {
                        "min-width": "75px",
                        "max-width": "200px",
                        height: "75px",
                      },
                      attrs: { src: _vm.config.rightImagePath },
                    })
                  : _vm._e(),
              ]),
              _c(
                "div",
                { staticStyle: { "margin-left": "10%", height: "75px" } },
                [
                  _c(
                    "h1",
                    {
                      staticStyle: {
                        "font-size": "26px",
                        "text-align": "center",
                      },
                    },
                    [_vm._v(" " + _vm._s(item.operateCompanyName) + " ")]
                  ),
                  _c(
                    "p",
                    {
                      staticStyle: {
                        "font-size": "18px",
                        "text-align": "center",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.config.title) + " ")]
                  ),
                ]
              ),
            ]),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "font-size": "12px",
                  "justify-content": "flex-end",
                  height: "10px",
                },
              },
              [_vm._v(" 打印时间：" + _vm._s(_vm.printTime) + " ")]
            ),
            _c("div", { staticStyle: { "margin-top": "-10px" } }, [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c("p", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v(
                      " 客户：" +
                        _vm._s(item.carBillClientMessage.clientName) +
                        " "
                    ),
                  ]),
                  _c("p", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v(
                      " 接车单号：" + _vm._s(item.billMessage.billCode) + " "
                    ),
                  ]),
                ]
              ),
              _c("div", { staticStyle: { "margin-top": "-14px" } }, [
                _c("table", { staticStyle: { "font-size": "15px" } }, [
                  _c("tbody", [
                    _c("tr", [
                      _c(
                        "td",
                        {
                          staticStyle: {
                            width: "85px",
                            "text-align": "left",
                            border: "1px solid #000 !important",
                          },
                        },
                        [_vm._v(" 送修司机 ")]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            width: "165px",
                            "text-align": "left",
                            border: "1px solid #000 !important",
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(item.billMessage.customerName) + " "
                          ),
                        ]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            width: "100px",
                            "text-align": "left",
                            border: "1px solid #000 !important",
                          },
                        },
                        [_vm._v(" 送修司机电话 ")]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            width: "179px",
                            "text-align": "left",
                            border: "1px solid #000 !important",
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(item.billMessage.customerPhone) + " "
                          ),
                        ]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            width: "85px",
                            "text-align": "left",
                            border: "1px solid #000 !important",
                          },
                        },
                        [_vm._v(" 车牌号 ")]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            width: "162px",
                            "text-align": "left",
                            border: "1px solid #000 !important",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(item.carBillClientMessage.carNumber) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                    _c("tr", [
                      _c(
                        "td",
                        {
                          staticStyle: {
                            width: "85px",
                            "text-align": "left",
                            border: "1px solid #000 !important",
                          },
                        },
                        [_vm._v(" 车型 ")]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            width: "165px",
                            "text-align": "left",
                            border: "1px solid #000 !important",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(item.carBillClientMessage.brandName) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            width: "90px",
                            "text-align": "left",
                            border: "1px solid #000 !important",
                          },
                        },
                        [_vm._v(" 车架号 ")]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            width: "179px",
                            "text-align": "left",
                            border: "1px solid #000 !important",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(item.carBillClientMessage.uniqueId) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            width: "85px",
                            "text-align": "left",
                            border: "1px solid #000 !important",
                          },
                        },
                        [_vm._v(" 发动机号 ")]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            width: "162px",
                            "text-align": "left",
                            border: "1px solid #000 !important",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(item.carBillClientMessage.engineNumber) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                    _c("tr", [
                      _c(
                        "td",
                        {
                          staticStyle: {
                            width: "85px",
                            "text-align": "left",
                            border: "1px solid #000 !important",
                          },
                        },
                        [_vm._v(" 接车员 ")]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            width: "165px",
                            "text-align": "left",
                            border: "1px solid #000 !important",
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(item.billMessage.pickUpUserName) + " "
                          ),
                        ]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            width: "90px",
                            "text-align": "left",
                            border: "1px solid #000 !important",
                          },
                        },
                        [_vm._v(" 进店时间 ")]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            width: "179px",
                            "text-align": "left",
                            border: "1px solid #000 !important",
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(item.billMessage.dateCreated) + " "
                          ),
                        ]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            width: "85px",
                            "text-align": "left",
                            border: "1px solid #000 !important",
                          },
                        },
                        [_vm._v(" 进店里程 ")]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            width: "162px",
                            "text-align": "left",
                            border: "1px solid #000 !important",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.billMessage.mileage
                                  ? item.billMessage.mileage + "km"
                                  : ""
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                    _c("tr", [
                      _c(
                        "td",
                        {
                          staticStyle: {
                            width: "85px",
                            "text-align": "left",
                            border: "1px solid #000 !important",
                          },
                        },
                        [_vm._v(" 结账收银员 ")]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            width: "165px",
                            "text-align": "left",
                            border: "1px solid #000 !important",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(item.clientOtherIncomeStyle.cashier) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            width: "90px",
                            "text-align": "left",
                            border: "1px solid #000 !important",
                          },
                        },
                        [_vm._v(" 结账时间 ")]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            "text-align": "left",
                            border: "1px solid #000 !important",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(item.clientOtherIncomeStyle.checkDate) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            width: "90px",
                            "text-align": "left",
                            border: "1px solid #000 !important",
                          },
                        },
                        [_vm._v(" 提车时间 ")]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            "text-align": "left",
                            border: "1px solid #000 !important",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.billMessage.takeTime ||
                                  item.billMessage.checkDate
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
              item.serviceList && item.serviceList.length
                ? _c(
                    "div",
                    { staticStyle: { width: "100%", "margin-top": "10px" } },
                    [
                      _c(
                        "h4",
                        {
                          staticStyle: {
                            width: "100%",
                            "text-align": "center",
                            "font-weight": "600",
                          },
                        },
                        [_vm._v(" 服务明细 ")]
                      ),
                      _c(
                        "table",
                        {
                          staticStyle: {
                            width: "100%",
                            "margin-top": "-20px",
                            "font-size": "15px",
                          },
                        },
                        [
                          _vm._m(0, true),
                          _c(
                            "tbody",
                            [
                              _vm._l(item.serviceList, function (item, index) {
                                return _c("tr", { key: index }, [
                                  _c(
                                    "td",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        border: "1px solid #000 !important",
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(index + 1) + " ")]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticStyle: {
                                        "text-align": "left",
                                        border: "1px solid #000 !important",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(item.serviceItemName) + " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticStyle: {
                                        "text-align": "right",
                                        border: "1px solid #000 !important",
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(item.num) + " ")]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticStyle: {
                                        "text-align": "right",
                                        border: "1px solid #000 !important",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("toThousands")(item.price)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticStyle: {
                                        "text-align": "right",
                                        border: "1px solid #000 !important",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.discount ? item.discount : "无"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticStyle: {
                                        "text-align": "right",
                                        border: "1px solid #000 !important",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("toThousands")(item.total)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ])
                              }),
                              _c(
                                "tr",
                                {
                                  staticStyle: {
                                    "text-align": "right",
                                    border: "1px solid #000 !important",
                                  },
                                },
                                [
                                  _c(
                                    "td",
                                    {
                                      staticStyle: {
                                        padding: "4px",
                                        "text-align": "right",
                                      },
                                      attrs: { colspan: 6 },
                                    },
                                    [
                                      _c(
                                        "b",
                                        {
                                          staticStyle: {
                                            "font-weight": "600",
                                            "text-right": "right",
                                            "font-size": "15px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "折前服务费合计：" +
                                              _vm._s(
                                                _vm._f("toThousands")(
                                                  item.beforeTotalServicePrice
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b",
                                        {
                                          staticStyle: {
                                            "font-weight": "600",
                                            "text-right": "right",
                                            "font-size": "15px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "折后服务费合计：" +
                                              _vm._s(
                                                _vm._f("toThousands")(
                                                  item.afterTotalServicePrice
                                                )
                                              )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            2
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              item.goodsList && item.goodsList.length
                ? _c("div", [
                    _c(
                      "h4",
                      {
                        ref: "materialTitle",
                        refInFor: true,
                        staticStyle: {
                          width: "100%",
                          "text-align": "center",
                          "font-weight": "600",
                        },
                      },
                      [_vm._v(" 材料明细 ")]
                    ),
                    _c(
                      "table",
                      {
                        ref: "materialTable",
                        refInFor: true,
                        staticStyle: { width: "100%", "margin-top": "-20px" },
                      },
                      [
                        _c("thead", [
                          _c("tr", { ref: "goodTr", refInFor: true }, [
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  width: "40px",
                                  "text-align": "center",
                                  border: "1px solid #000 !important",
                                  "font-size": "15px",
                                  "font-weight": "0",
                                },
                              },
                              [_vm._v(" 序号 ")]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "130px",
                                  "text-align": "center",
                                  border: "1px solid #000 !important",
                                  "font-size": "15px",
                                  "font-weight": "0",
                                },
                              },
                              [_vm._v(" 商品名称 ")]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  width: "65px",
                                  "text-align": "center",
                                  border: "1px solid #000 !important",
                                  "font-size": "15px",
                                  "font-weight": "0",
                                },
                              },
                              [_vm._v(" 数量 ")]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  width: "70px",
                                  "text-align": "center",
                                  border: "1px solid #000 !important",
                                  "font-size": "15px",
                                  "font-weight": "0",
                                },
                              },
                              [_vm._v(" 单价 ")]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  width: "40px",
                                  "text-align": "center",
                                  border: "1px solid #000 !important",
                                  "font-size": "15px",
                                  "font-weight": "0",
                                },
                              },
                              [_vm._v(" 折扣 ")]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  width: "80px",
                                  "text-align": "center",
                                  border: "1px solid #000 !important",
                                  "font-size": "15px",
                                  "font-weight": "0",
                                },
                              },
                              [_vm._v(" 小计 ")]
                            ),
                          ]),
                        ]),
                        _c(
                          "tbody",
                          [
                            _vm._l(item.goodsList, function (item, index) {
                              return _c("tr", { key: index }, [
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "text-align": "center",
                                      border: "1px solid #000 !important",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(index + 1) + " ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "text-align": "left",
                                      border: "1px solid #000 !important",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(item.name) + " ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "text-align": "right",
                                      border: "1px solid #000 !important",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(item.num) + " ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "text-align": "right",
                                      border: "1px solid #000 !important",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("toThousands")(item.price)
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "text-align": "right",
                                      border: "1px solid #000 !important",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item.discount ? item.discount : "无"
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "text-align": "right",
                                      border: "1px solid #000 !important",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("toThousands")(item.total)
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ])
                            }),
                            _c(
                              "tr",
                              {
                                staticStyle: {
                                  "text-align": "right",
                                  border: "1px solid #000 !important",
                                },
                              },
                              [
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      padding: "4px",
                                      "text-align": "right",
                                    },
                                    attrs: { colspan: 6 },
                                  },
                                  [
                                    _c(
                                      "b",
                                      {
                                        staticStyle: {
                                          "font-weight": "600",
                                          "text-right": "right",
                                          "font-size": "15px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "折前材料费合计：" +
                                            _vm._s(
                                              _vm._f("toThousands")(
                                                item.beforeTotalGoodsPrice
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "b",
                                      {
                                        staticStyle: {
                                          "font-weight": "600",
                                          "text-right": "right",
                                          "font-size": "15px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "折后材料费合计：" +
                                            _vm._s(
                                              _vm._f("toThousands")(
                                                item.afterTotalGoodsPrice
                                              )
                                            )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          2
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c("div", { staticStyle: { width: "45%" } }, [
                    _c(
                      "h4",
                      {
                        ref: "receivableTitle",
                        refInFor: true,
                        staticStyle: {
                          display: "flex",
                          "justify-content": "center",
                          margin: "7px 0 1px",
                          "font-weight": "600",
                        },
                      },
                      [_vm._v(" 应收明细 ")]
                    ),
                    _c(
                      "table",
                      {
                        ref: "receivableTable",
                        refInFor: true,
                        staticStyle: { "font-size": "15px" },
                      },
                      [
                        _vm._m(1, true),
                        _c("tbody", [
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  "text-align": "center",
                                  border: "1px solid #000 !important",
                                },
                              },
                              [_vm._v(" 1 ")]
                            ),
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  "text-align": "left",
                                  border: "1px solid #000 !important",
                                },
                              },
                              [_vm._v(" 工时费 ")]
                            ),
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  "text-align": "right",
                                  border: "1px solid #000 !important",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("toThousands")(
                                        item.serviceTotalPrice
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  "text-align": "center",
                                  border: "1px solid #000 !important",
                                },
                              },
                              [_vm._v(" 2 ")]
                            ),
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  "text-align": "left",
                                  border: "1px solid #000 !important",
                                },
                              },
                              [_vm._v(" 材料费 ")]
                            ),
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  "text-align": "right",
                                  border: "1px solid #000 !important",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("toThousands")(
                                        item.goodsTotalPrice
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  "text-align": "center",
                                  border: "1px solid #000 !important",
                                },
                              },
                              [_vm._v(" 3 ")]
                            ),
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  "text-align": "left",
                                  border: "1px solid #000 !important",
                                },
                              },
                              [_vm._v(" 优惠金额 ")]
                            ),
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  "text-align": "right",
                                  border: "1px solid #000 !important",
                                },
                              },
                              [
                                _vm._v(
                                  " -" +
                                    _vm._s(
                                      _vm._f("toThousands")(item.discountAmount)
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  "text-align": "right",
                                  "font-weight": "600",
                                  border: "1px solid #000 !important",
                                  "font-size": "15px",
                                },
                                attrs: { colspan: 3 },
                              },
                              [
                                _vm._v(
                                  " 应收合计(大写): " +
                                    _vm._s(
                                      _vm._f("toThousands")(item.needPay)
                                    ) +
                                    " (" +
                                    _vm._s(
                                      _vm._f("amountToChinese")(item.needPay)
                                    ) +
                                    ") "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                  _c(
                    "div",
                    { staticStyle: { "border-bottom": "none", width: "45%" } },
                    [
                      _c(
                        "h4",
                        {
                          ref: "chargeTitle",
                          refInFor: true,
                          staticStyle: {
                            display: "flex",
                            "justify-content": "center",
                            margin: "7px 0 1px",
                            "font-weight": "600",
                          },
                        },
                        [_vm._v(" 收费结算 ")]
                      ),
                      _c(
                        "table",
                        {
                          ref: "chargeTable",
                          refInFor: true,
                          staticStyle: { "font-size": "15px" },
                        },
                        [
                          _c(
                            "tbody",
                            [
                              _vm._m(2, true),
                              _vm._l(item.paidList, function (item, index) {
                                return _c("tr", { key: index }, [
                                  _c(
                                    "td",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        border: "1px solid #000 !important",
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(index + 1) + " ")]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticStyle: {
                                        "text-align": "left",
                                        border: "1px solid #000 !important",
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(item.payType) + " ")]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticStyle: {
                                        "text-align": "right",
                                        border: "1px solid #000 !important",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("toThousands")(item.money)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ])
                              }),
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "text-align": "right",
                                      "font-weight": "600",
                                      border:
                                        "1px solid rgb(0, 0, 0) !important",
                                      "font-size": "15px",
                                    },
                                    attrs: { colspan: 3 },
                                  },
                                  [
                                    _vm._v(
                                      " 实收合计(大写): " +
                                        _vm._s(
                                          _vm._f("toThousands")(item.paidTotal)
                                        ) +
                                        " (" +
                                        _vm._s(
                                          _vm._f("amountToChinese")(
                                            item.paidTotal
                                          )
                                        ) +
                                        ") "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "text-align": "right",
                                      "font-weight": "600",
                                      border:
                                        "1px solid rgb(0, 0, 0) !important",
                                      "font-size": "15px",
                                    },
                                    attrs: { colspan: 3 },
                                  },
                                  [
                                    _vm._v(
                                      " 欠收合计(大写): " +
                                        _vm._s(
                                          _vm._f("toThousands")(
                                            item.needPaidTotal
                                          )
                                        ) +
                                        " (" +
                                        _vm._s(
                                          _vm._f("amountToChinese")(
                                            item.needPaidTotal
                                          )
                                        ) +
                                        ") "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            2
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  ref: "signature",
                  refInFor: true,
                  staticStyle: {
                    display: "flex",
                    padding: "20px 0",
                    "border-bottom": "1px solid #000 !important",
                  },
                },
                [
                  _c("b", { staticStyle: { width: "60%" } }, [
                    _vm._v("服务顾问签名："),
                  ]),
                  _c("b", { staticStyle: { width: "40%" } }, [
                    _vm._v("客户签名："),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              {
                staticClass: "address",
                staticStyle: {
                  "margin-top": "10px",
                  "flex-wrap": "wrap",
                  display: "flex",
                  position: "relative",
                },
              },
              [
                _c(
                  "div",
                  { staticStyle: { width: "60%", "font-size": "14px" } },
                  [_vm._v(" 联系地址：" + _vm._s(item.companyAddress) + " ")]
                ),
                _c("div", { staticStyle: { "font-size": "14px" } }, [
                  _vm._v(" 联系电话：" + _vm._s(item.telephone) + " "),
                  _c("span", { staticStyle: { "margin-left": "30px" } }, [
                    _vm._v(_vm._s(item.alternativeTelephone)),
                  ]),
                ]),
              ]
            ),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c(
          "th",
          {
            staticStyle: {
              width: "40px",
              "text-align": "center",
              border: "1px solid #000 !important",
              "font-size": "15px",
              "font-weight": "0",
            },
          },
          [_vm._v(" 序号 ")]
        ),
        _c(
          "th",
          {
            staticStyle: {
              "min-width": "130px",
              "text-align": "center",
              border: "1px solid #000 !important",
              "font-size": "15px",
              "font-weight": "0",
            },
          },
          [_vm._v(" 服务名称 ")]
        ),
        _c(
          "th",
          {
            staticStyle: {
              width: "65px",
              "text-align": "center",
              border: "1px solid #000 !important",
              "font-size": "15px",
              "font-weight": "0",
            },
          },
          [_vm._v(" 工时 ")]
        ),
        _c(
          "th",
          {
            staticStyle: {
              width: "70px",
              "text-align": "center",
              border: "1px solid #000 !important",
              "font-size": "15px",
              "font-weight": "0",
            },
          },
          [_vm._v(" 单价 ")]
        ),
        _c(
          "th",
          {
            staticStyle: {
              width: "40px",
              "text-align": "center",
              border: "1px solid #000 !important",
              "font-size": "15px",
              "font-weight": "0",
            },
          },
          [_vm._v(" 折扣 ")]
        ),
        _c(
          "th",
          {
            staticStyle: {
              width: "80px",
              "text-align": "center",
              border: "1px solid #000 !important",
              "font-size": "15px",
              "font-weight": "0",
            },
          },
          [_vm._v(" 小计 ")]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c(
          "th",
          {
            staticStyle: {
              width: "40px",
              "text-align": "center",
              border: "1px solid #000 !important",
              "font-size": "15px",
              "font-weight": "0",
            },
          },
          [_vm._v(" 序号 ")]
        ),
        _c(
          "th",
          {
            staticStyle: {
              "min-width": "180px",
              "text-align": "center",
              border: "1px solid #000 !important",
              "font-size": "15px",
              "font-weight": "0",
            },
          },
          [_vm._v(" 名称 ")]
        ),
        _c(
          "th",
          {
            staticStyle: {
              width: "74px",
              "text-align": "center",
              border: "1px solid #000 !important",
              "font-size": "15px",
              "font-weight": "0",
            },
          },
          [_vm._v(" 金额 ")]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c(
        "th",
        {
          staticStyle: {
            width: "40px",
            "text-align": "center",
            border: "1px solid #000 !important",
            "font-size": "15px",
            "font-weight": "0",
          },
        },
        [_vm._v(" 序号 ")]
      ),
      _c(
        "th",
        {
          staticStyle: {
            "min-width": "180px",
            "text-align": "center",
            border: "1px solid #000 !important",
            "font-size": "15px",
            "font-weight": "0",
          },
        },
        [_vm._v(" 支付方式 ")]
      ),
      _c(
        "th",
        {
          staticStyle: {
            "text-align": "center",
            width: "110px",
            border: "1px solid #000 !important",
            "font-size": "15px",
            "font-weight": "0",
          },
        },
        [_vm._v(" 金额 ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }