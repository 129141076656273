<template>
  <el-dialog
    title="预约完成"
    top="5vh"
    width="40%"
    :append-to-body="true"
    :visible="visibility"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <div style="height: 150px; overflow: auto">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="100px"
        size="small"
      >
        <el-row>
          <el-col :span="20">
            <el-form-item label="车单号" prop="billCode">
              <el-input
                v-model="form.billCode"
                clearable
                placeholder="请输入车单号"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="20">
            <el-form-item label="备注" prop="remark">
              <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4 }"
                placeholder="请输入备注"
                v-model="form.remark"
                maxlength="100"
                show-word-limit
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <template slot="footer">
      <div>
        <el-button size="small" @click="handleClose">取 消</el-button>
        <el-button size="small" @click="verifyBeforeSave" type="primary"
          >确认</el-button
        >
      </div>
    </template>
  </el-dialog>
</template>

<script>
// 组件
// 接口
import { bookingComplete } from '@/api/carBill/miniProgramBooking';
// 字典

export default {
  name: 'completeBookingDialog',
  components: {},
  props: {
    visibility: {
      type: Boolean,
      default: () => false,
    },
    id: {
      type: [Number, String],
      default: () => 0,
    },
  },
  data() {
    return {
      // 表单数据
      form: {
        billCode: '',
        remark: '',
      },
      // 表单规则
      rules: {},
    };
  },
  computed: {},
  created() {},
  methods: {
    init() {},
    /**
     * 保存前校验
     */
    verifyBeforeSave() {
      const _this = this;
      this.$refs.form
        .validate((result, object) => {
          var body = Object.assign({}, this.form, {
            id: this.id,
          });
          bookingComplete(body).then((res) => {
            this.$message.success('预约完成处理成功');
            this.$emit('save');
            this.handleClose();
          });
        })
        .catch((error) => {
          console.log(error);
          var msg =
            '<p>' +
            Object.values(error)
              .map((el) => el.map((v) => v.message))
              .join('<br />') +
            '</p>';
          this.$message({
            type: 'warning',
            dangerouslyUseHTMLString: true,
            message: msg,
          });
        });
    },
    /**
     * 关闭/取消
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item {
  .el-date-editor,
  .el-select {
    width: 100%;
  }
}
.rows-tip {
  color: #999;
  line-height: 18px;
}
.img-note {
  color: #999;
  font-size: 14px;
}
.car-imgs {
  display: flex;
}
.block-title {
  margin: 30px 0;
  padding-bottom: 12px;
  border-bottom: 1px solid #e8e8e8;
  color: #333;
  font-size: 16px;
  font-weight: 600;
}
.client-list {
  .client {
    display: flex;
    align-items: center;
    > .icon_delete {
      font-size: 18px;
      color: #ff9200;
      margin-right: 15px;
    }
    .client-info {
      width: 95%;
      padding: 12px 18px;
      background: #f5f7fa;
      border-radius: 4px;
      > div + div {
        margin-top: 10px;
      }
      i {
        margin: 0 5px;
      }
      .icon_person {
        color: $blue;
      }
      .icon_sex {
        color: $blue;
      }
      .icon_grade {
        color: $blue;
      }
      .grow-icon_girl {
        color: #f48080;
      }
      .icon_wechat {
        color: #999;
      }
      .icon_wechat_active {
        color: #00c800;
      }
      .icon_phone {
        color: #42bbb8;
      }
      .icon_vip {
        color: $orange;
      }
      .icon_sex,
      .icon_grade,
      .icon_wechat,
      .icon_wechat_active,
      .icon_vip {
        font-size: 14px;
      }
    }
  }
}
</style>
