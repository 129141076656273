var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-input",
    {
      attrs: {
        placeholder: _vm.placeholder,
        disabled: _vm.disabled,
        size: _vm.size,
        "prefix-icon": _vm.prefixIcon,
        "suffix-icon": _vm.suffixIcon,
        clearable: _vm.clearable,
      },
      on: {
        blur: _vm.onBlur,
        focus: _vm.onFocus,
        change: _vm.onChange,
        input: _vm.onInput,
        clear: _vm.onClear,
      },
      model: {
        value: _vm.realValue,
        callback: function ($$v) {
          _vm.realValue = $$v
        },
        expression: "realValue",
      },
    },
    [
      _c("template", { slot: "prefix" }, [_vm._t("prefix")], 2),
      _c("template", { slot: "suffix" }, [_vm._t("suffix")], 2),
      _c("template", { slot: "prepend" }, [_vm._t("prepend")], 2),
      _c("template", { slot: "append" }, [_vm._t("append")], 2),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }