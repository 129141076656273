var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-container tabs-sty" },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.activeTabOrderRecordType,
            callback: function ($$v) {
              _vm.activeTabOrderRecordType = $$v
            },
            expression: "activeTabOrderRecordType",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "现金流表", name: "first" } },
            [_c("balance-water-table")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }