var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "printDom", staticClass: "print-template" }, [
    _c("div", { ref: "print" }, [
      _c("div", { staticClass: "print-template__header" }, [
        _c("h1", { staticClass: "print-template__header__title" }, [
          _vm._v(_vm._s(_vm.company.companyName)),
        ]),
        _c(
          "p",
          {
            staticStyle: {
              "margin-top": "5px",
              "font-size": "18px",
              "text-align": "center",
            },
          },
          [_vm._v(" " + _vm._s(_vm.config.title) + " ")]
        ),
        _vm.config.leftImagePath
          ? _c("img", {
              staticClass: "print-template__header__lt-img",
              attrs: { src: _vm.config.leftImagePath },
            })
          : _vm._e(),
        _vm.config.rightImagePath
          ? _c("img", {
              staticClass: "print-template__header__rt-img",
              attrs: { src: _vm.config.rightImagePath },
            })
          : _vm._e(),
      ]),
      _c("p", { staticStyle: { float: "right", "margin-bottom": "5px" } }, [
        _vm._v(" 单据号: " + _vm._s(_vm.billMessage.billCode) + " "),
      ]),
      _c("table", { staticClass: "table-base-info" }, [
        _c("tr", [
          _c("td", [_vm._v("车牌号: " + _vm._s(_vm.carInfo.carNumber))]),
          _c("td", [_vm._v("车型: " + _vm._s(_vm.carInfo.brandName))]),
          _c("td", [_vm._v("车架号: " + _vm._s(_vm.carInfo.uniqueId))]),
        ]),
        _c("tr", [
          _c("td", [
            _vm._v("接车员: " + _vm._s(_vm.billMessage.pickUpUserName)),
          ]),
          _c("td", [
            _vm._v(
              " 进店里程: " +
                _vm._s(
                  _vm.billMessage.mileage ? _vm.billMessage.mileage + "km" : ""
                ) +
                " "
            ),
          ]),
          _c("td", [
            _vm._v("进店时间: " + _vm._s(_vm.billMessage.dateCreated)),
          ]),
        ]),
      ]),
      _c("div", [
        _c(
          "table",
          {
            staticClass: "print-template__box__border__table",
            staticStyle: { "margin-top": "20px" },
          },
          [
            _vm._m(0),
            _c(
              "tbody",
              [
                _vm._l(_vm.materialList, function (item, index) {
                  return _c("tr", { key: index }, [
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(" " + _vm._s(index + 1) + " "),
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(" " + _vm._s(item.code) + " "),
                    ]),
                    _c("td", { staticClass: "text-left" }, [
                      _vm._v(" " + _vm._s(item.name) + " "),
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(" " + _vm._s(item.num) + " "),
                    ]),
                  ])
                }),
                _c("tr", [
                  _c(
                    "td",
                    { staticClass: "text-right", attrs: { colspan: "4" } },
                    [
                      _vm._v(
                        " 合计: " +
                          _vm._s(_vm._f("toThousands")(_vm.total)) +
                          "件 "
                      ),
                    ]
                  ),
                ]),
              ],
              2
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c(
          "th",
          { staticClass: "text-center", staticStyle: { width: "10%" } },
          [_vm._v("序号")]
        ),
        _c(
          "th",
          { staticClass: "text-center", staticStyle: { width: "30%" } },
          [_vm._v("商品编码")]
        ),
        _c(
          "th",
          { staticClass: "text-center", staticStyle: { width: "50%" } },
          [_vm._v("商品名称")]
        ),
        _c(
          "th",
          { staticClass: "text-center", staticStyle: { width: "10%" } },
          [_vm._v("数量")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }