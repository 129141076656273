var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { ref: "baseContainer", attrs: { paddingTop: "" } },
    [
      [
        _c(
          "div",
          [
            _c(
              "el-form",
              {
                staticClass: "filter-form",
                attrs: {
                  model: _vm.form,
                  "label-width": "100px",
                  size: "small",
                },
              },
              [
                _c("div", { staticClass: "flex-x-between" }, [
                  _c(
                    "div",
                    { staticClass: "flex-warp flex-shrink0" },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c("template", { slot: "label" }, [
                            _c(
                              "div",
                              [
                                _vm._v(" 操作门店 "),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "light",
                                      content:
                                        "车单反向记录的操作门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)",
                                      placement: "bottom-start",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "iconfont grow-icon_doubt",
                                      staticStyle: { color: "#9a9a9a" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("operateCompanyCascader", {
                            model: {
                              value: _vm.form.operateCompanyId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "operateCompanyId", $$v)
                              },
                              expression: "form.operateCompanyId",
                            },
                          }),
                        ],
                        2
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "车牌号" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入", clearable: "" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.doSearch.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.form.plateNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "plateNumber", $$v)
                              },
                              expression: "form.plateNumber",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "接车单号" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入", clearable: "" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.doSearch.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.form.billCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "billCode", $$v)
                              },
                              expression: "form.billCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "flex" }, [
                    _c("div", { staticClass: "el-form-item--small" }, [
                      _c(
                        "div",
                        {
                          staticClass: "el-form-item__label pointer",
                          on: {
                            click: function ($event) {
                              _vm.showMore = !_vm.showMore
                            },
                          },
                        },
                        [
                          _vm._v(" 更多筛选 "),
                          _c("i", {
                            class: {
                              primary: true,
                              "el-icon-arrow-right": !_vm.showMore,
                              "el-icon-arrow-down": _vm.showMore,
                            },
                          }),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "btn_search",
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.doSearch },
                            },
                            [_vm._v("查询")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "btn_search",
                              attrs: { size: "small" },
                              on: { click: _vm.empty },
                            },
                            [_vm._v("置空")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showMore,
                        expression: "showMore",
                      },
                    ],
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "操作日期" } },
                      [
                        _c("el-date-picker", {
                          staticClass: "w100",
                          attrs: {
                            type: "daterange",
                            "unlink-panels": "",
                            "range-separator": "至",
                            "start-placeholder": "起始时间",
                            "end-placeholder": "截止时间",
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                          },
                          model: {
                            value: _vm.form.operationTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "operationTime", $$v)
                            },
                            expression: "form.operationTime",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "操作人" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              filterable: "",
                              clearable: "",
                              remote: "",
                              placeholder: "搜索姓名、手机号",
                              "remote-method": _vm.searchAdminJobListUsre,
                              loading: _vm.searchAdminJobListUsrLoading,
                            },
                            on: { change: _vm.selectAdminJobListUsre },
                            model: {
                              value: _vm.form.operatorId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "operatorId", $$v)
                              },
                              expression: "form.operatorId",
                            },
                          },
                          _vm._l(_vm.AdminJobListUsrList, function (item) {
                            return _c(
                              "el-option",
                              {
                                key: item.userId,
                                attrs: {
                                  label: item.realName,
                                  value: item.userId,
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      float: "left",
                                      color: "#8492a6",
                                      "font-size": "13px",
                                    },
                                  },
                                  [_vm._v(_vm._s(item.realName))]
                                ),
                                _c(
                                  "span",
                                  { staticStyle: { float: "right" } },
                                  [_vm._v(_vm._s(item.phone))]
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入", clearable: "" },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.doSearch.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.form.remark,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "remark", $$v)
                            },
                            expression: "form.remark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ],
      [
        _c("div", { staticClass: "btns flex-x-between" }, [
          _c("div", [
            _c("div", { staticClass: "custom-tabs nopadding" }, [
              _c(
                "div",
                {
                  class: {
                    "custom-tab-item": true,
                    active: _vm.dispatchType == -1,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.changeDispatchType(-1)
                    },
                  },
                },
                [_vm._v(" 全部 ")]
              ),
              _c(
                "div",
                {
                  class: {
                    "custom-tab-item": true,
                    active: _vm.dispatchType == 0,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.changeDispatchType(0)
                    },
                  },
                },
                [_vm._v(" 自费业务 ")]
              ),
              _c(
                "div",
                {
                  class: {
                    "custom-tab-item": true,
                    active: _vm.dispatchType == 1,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.changeDispatchType(1)
                    },
                  },
                },
                [_vm._v(" 包干业务 ")]
              ),
              _c(
                "div",
                {
                  class: {
                    "custom-tab-item": true,
                    active: _vm.dispatchType == 2,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.changeDispatchType(2)
                    },
                  },
                },
                [_vm._v(" 理赔业务 ")]
              ),
              _c(
                "div",
                {
                  class: {
                    "custom-tab-item": true,
                    active: _vm.dispatchType == 3,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.changeDispatchType(3)
                    },
                  },
                },
                [_vm._v(" 索赔业务 ")]
              ),
            ]),
          ]),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "auth",
                      rawName: "v-auth",
                      value: 440,
                      expression: "440",
                    },
                  ],
                  staticClass: "blue",
                  attrs: { size: "small", type: "text" },
                  on: {
                    click: function ($event) {
                      _vm.showExportDialog = true
                    },
                  },
                },
                [
                  _vm._v("导出列表"),
                  _c("i", {
                    staticClass: "iconfont grow-icon_daochu el-icon--right",
                  }),
                ]
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          {
            staticClass: "flex h100",
            staticStyle: { flex: "1", overflow: "auto" },
          },
          [
            _c(
              "div",
              {
                staticClass: "h100",
                staticStyle: { flex: "1", overflow: "auto" },
              },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "fixedScrollBar",
                        rawName: "v-fixedScrollBar",
                        value: _vm.$refs.baseContainer,
                        expression: "$refs.baseContainer",
                      },
                    ],
                    staticClass: "custom-table",
                    attrs: {
                      data: _vm.carRecordTable,
                      border: "",
                      "header-cell-style": _vm.headerCellStyle,
                    },
                    on: {
                      "row-dblclick": function ($event) {
                        return _vm.goCarBillDetail($event.billId)
                      },
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { type: "index", label: "序号", width: "80" },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "180",
                        label: "操作门店",
                        prop: "operateCompanyName",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "180",
                        label: "操作时间",
                        prop: "operateTime",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { width: "180", label: "操作类型" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.operation[row.operateType]) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { width: "180", label: "接车单号" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "text_operation blue",
                                  on: {
                                    click: function ($event) {
                                      return _vm.goCarBillDetail(row.billId)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(row.billCode))]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "100",
                        label: "接车单类型",
                        prop: "billType",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              row.billType !== 0
                                ? _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("dict")(
                                            row.billType,
                                            "carBillType"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("dict")(
                                            row.isOthersSelfPayState,
                                            "isOthersSelfPayState"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "100",
                        label: "车牌号",
                        prop: "plateNumber",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { width: "100", label: "车型", prop: "carInfo" },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "180",
                        label: "操作人",
                        prop: "operator",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "100",
                        label: "操作人手机",
                        prop: "operatorPhone",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        "mix-width": "100",
                        label: "反向操作备注",
                        prop: "remark",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "class-box" },
              [
                _c("p", { staticClass: "title" }, [
                  _vm._v("状态与统计（数量）"),
                ]),
                _c("el-tree", {
                  staticClass: "custom-tree",
                  attrs: {
                    data: _vm.treeData,
                    props: _vm.treeProp,
                    "highlight-current": "",
                    "node-key": "id",
                    "current-node-key": 0,
                  },
                  on: { "node-click": _vm.onNodeClick },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ data }) {
                        return [
                          _c("div", { staticClass: "tree-node" }, [
                            _c("span", [_vm._v(_vm._s(data.name))]),
                            _c("span", [
                              _vm._v("(" + _vm._s(data.count) + ")"),
                            ]),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]
        ),
        _c("export-dialog", {
          attrs: {
            visibility: _vm.showExportDialog,
            total: _vm.total,
            pageOption: _vm.pageOption,
            filterForm: _vm.form,
            url: _vm.exportUrl,
          },
          on: {
            "update:visibility": function ($event) {
              _vm.showExportDialog = $event
            },
          },
        }),
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c("pagination", {
            attrs: { total: _vm.total },
            on: { pageChange: _vm.changePage },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }