var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { ref: "baseContainer", attrs: { paddingTop: "" } },
    [
      _c(
        "div",
        { staticStyle: { height: "92%" } },
        [
          _c(
            "p",
            {
              staticClass: "red",
              staticStyle: { "font-size": "14px", "margin-bottom": "20px" },
            },
            [
              _vm._v(
                " 注：无解绑车辆权限的点击保存后仅能保存未绑定客户的车辆，请注意操作 "
              ),
            ]
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "fixedScrollBar",
                  rawName: "v-fixedScrollBar",
                  value: _vm.$refs.baseContainer,
                  expression: "$refs.baseContainer",
                },
              ],
              staticClass: "custom-table",
              staticStyle: { width: "100%" },
              attrs: { data: _vm.carTableData, border: "" },
            },
            [
              _c("el-table-column", {
                attrs: { width: "150", label: "车牌", prop: "carNumber" },
              }),
              _vm.showCustomer
                ? _c(
                    "el-table-column",
                    {
                      attrs: { "min-width": "140" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      filterable: "",
                                      clearable: "",
                                      remote: "",
                                      size: "small",
                                      placeholder: "请输入客户名称搜索",
                                      "remote-method": _vm.searchClient,
                                      loading: _vm.searchClientLoading,
                                    },
                                    on: { change: _vm.selectClient },
                                    model: {
                                      value: row.clientMessage,
                                      callback: function ($$v) {
                                        _vm.$set(row, "clientMessage", $$v)
                                      },
                                      expression: "row.clientMessage",
                                    },
                                  },
                                  _vm._l(_vm.custlist, function (item) {
                                    return _c(
                                      "el-option",
                                      {
                                        key: item.id,
                                        attrs: {
                                          label: item.clientName,
                                          value: item.id,
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              float: "left",
                                              color: "#8492a6",
                                              "font-size": "13px",
                                            },
                                          },
                                          [_vm._v(_vm._s(item.clientName))]
                                        ),
                                      ]
                                    )
                                  }),
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3300122044
                      ),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c("span", [_vm._v("签约客户")]),
                        _c("i", {
                          staticClass:
                            "iconfont grow-a-icon_fillout color666 el-icon--right pointer",
                          staticStyle: { "font-size": "14px" },
                          on: {
                            click: function ($event) {
                              return _vm.openChioce(1)
                            },
                          },
                        }),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
              _c("el-table-column", {
                attrs: { "min-width": "150", prop: "carModelName" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c("span", [_vm._v("车型")]),
                        _c("i", {
                          staticClass:
                            "iconfont grow-a-icon_fillout color666 el-icon--right pointer",
                          staticStyle: { "font-size": "14px" },
                          on: {
                            click: function ($event) {
                              return _vm.openChioce(2)
                            },
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              filterable: "",
                              remote: "",
                              size: "small",
                              placeholder: "搜索车型",
                              "remote-method": _vm.searchModel,
                              loading: _vm.searchModelLoading,
                              clearable: "",
                            },
                            model: {
                              value: row.carModelName,
                              callback: function ($$v) {
                                _vm.$set(row, "carModelName", $$v)
                              },
                              expression: "row.carModelName",
                            },
                          },
                          _vm._l(_vm.models, function (item) {
                            return _c(
                              "el-option",
                              {
                                key: _vm.$lodash.get(item, "series.id"),
                                attrs: {
                                  value: _vm.$lodash.get(
                                    item,
                                    "series.name",
                                    _vm.$lodash.get(
                                      item,
                                      "brand.name",
                                      _vm.$lodash.get(item, "logo.name")
                                    )
                                  ),
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.selectCarModel(item, row)
                                  },
                                },
                              },
                              [
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(item.markedRedName),
                                  },
                                }),
                              ]
                            )
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { "min-width": "160", prop: "annualSurveyCarType" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c("span", [_vm._v("年审类型")]),
                        _c("i", {
                          staticClass:
                            "iconfont grow-a-icon_fillout color666 el-icon--right pointer",
                          staticStyle: { "font-size": "14px" },
                          on: {
                            click: function ($event) {
                              return _vm.openChioce(3)
                            },
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "90%" },
                            attrs: {
                              placeholder: "请选择年审类型",
                              filterable: "",
                              remote: "",
                              clearable: "",
                              size: "small",
                            },
                            model: {
                              value: row.annualSurveyCarType,
                              callback: function ($$v) {
                                _vm.$set(row, "annualSurveyCarType", $$v)
                              },
                              expression: "row.annualSurveyCarType",
                            },
                          },
                          _vm._l(_vm.annualVerificationTypes, function (type) {
                            return _c("el-option", {
                              key: type.value,
                              attrs: { value: type.value, label: type.label },
                            })
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { "min-width": "150", prop: "remark" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c("span", [_vm._v("备注")]),
                        _c("i", {
                          staticClass:
                            "iconfont grow-a-icon_fillout color666 el-icon--right pointer",
                          staticStyle: { "font-size": "14px" },
                          on: {
                            click: function ($event) {
                              return _vm.openChioce(5)
                            },
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            rows: 1,
                            placeholder: "请输入车辆备注",
                            size: "small",
                            clearable: "",
                            maxlength: "100",
                            "show-word-limit": "",
                          },
                          model: {
                            value: row.remark,
                            callback: function ($$v) {
                              _vm.$set(row, "remark", $$v)
                            },
                            expression: "row.remark",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          { staticClass: "flex-x-end foot-btn" },
          [
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 53,
                    expression: "53",
                  },
                ],
                attrs: { type: "primary" },
                on: { click: _vm.save },
              },
              [_vm._v("保存")]
            ),
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 53,
                    expression: "53",
                  },
                ],
                on: { click: _vm.cancel },
              },
              [_vm._v("取消")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            width: "30%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _vm._v(" " + _vm._s(_vm.dialogVisibleTitel) + " "),
          ]),
          _vm.type == 1
            ? _c(
                "div",
                { staticClass: "select-sty" },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "80%" },
                      attrs: {
                        filterable: "",
                        clearable: "",
                        remote: "",
                        size: "small",
                        placeholder: "请输入姓名",
                        "remote-method": _vm.searchClient,
                        loading: _vm.searchClientLoading,
                      },
                      on: { change: _vm.selectClient },
                      model: {
                        value: _vm.clientName,
                        callback: function ($$v) {
                          _vm.clientName = $$v
                        },
                        expression: "clientName",
                      },
                    },
                    _vm._l(_vm.custlist, function (item) {
                      return _c(
                        "el-option",
                        {
                          key: item.id,
                          attrs: { label: item.clientName, value: item.id },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                float: "left",
                                color: "#8492a6",
                                "font-size": "13px",
                              },
                            },
                            [_vm._v(_vm._s(item.clientName))]
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.type == 2
            ? _c(
                "div",
                { staticClass: "select-sty" },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "80%" },
                      attrs: {
                        filterable: "",
                        remote: "",
                        size: "small",
                        placeholder: "搜索车型",
                        "remote-method": _vm.searchModel,
                        loading: _vm.searchModelLoading,
                        clearable: "",
                      },
                      model: {
                        value: _vm.carModelName,
                        callback: function ($$v) {
                          _vm.carModelName = $$v
                        },
                        expression: "carModelName",
                      },
                    },
                    _vm._l(_vm.models, function (item) {
                      return _c(
                        "el-option",
                        {
                          key: _vm.$lodash.get(item, "series.id"),
                          attrs: {
                            value: _vm.$lodash.get(
                              item,
                              "series.name",
                              _vm.$lodash.get(
                                item,
                                "brand.name",
                                _vm.$lodash.get(item, "logo.name")
                              )
                            ),
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.selectCarModel(item)
                            },
                          },
                        },
                        [
                          _c("span", {
                            domProps: { innerHTML: _vm._s(item.markedRedName) },
                          }),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.type == 3
            ? _c(
                "div",
                { staticClass: "select-sty" },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "80%" },
                      attrs: {
                        placeholder: "请选择年审类型",
                        filterable: "",
                        remote: "",
                        clearable: "",
                        size: "small",
                      },
                      model: {
                        value: _vm.annualSurveyCarType,
                        callback: function ($$v) {
                          _vm.annualSurveyCarType = $$v
                        },
                        expression: "annualSurveyCarType",
                      },
                    },
                    _vm._l(_vm.annualVerificationTypes, function (type) {
                      return _c("el-option", {
                        key: type.value,
                        attrs: { value: type.value, label: type.label },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.type == 4
            ? _c(
                "div",
                { staticClass: "select-sty" },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "80%" },
                      attrs: {
                        placeholder: "请选择",
                        filterable: "",
                        remote: "",
                        clearable: "",
                        size: "small",
                      },
                      model: {
                        value: _vm.carStates,
                        callback: function ($$v) {
                          _vm.carStates = $$v
                        },
                        expression: "carStates",
                      },
                    },
                    _vm._l(_vm.carState, function (type) {
                      return _c("el-option", {
                        key: type.value,
                        attrs: { value: type.value, label: type.label },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.type == 5
            ? _c(
                "div",
                { staticClass: "select-sty" },
                [
                  _c("el-input", {
                    staticStyle: { width: "80%" },
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 2, maxRows: 4 },
                      placeholder: "请输入车辆备注",
                      size: "small",
                      clearable: "",
                      maxlength: "100",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.remark,
                      callback: function ($$v) {
                        _vm.remark = $$v
                      },
                      expression: "remark",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.determine } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }