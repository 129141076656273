var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { ref: "baseContainer", attrs: { paddingTop: "" } },
    [
      [
        _c(
          "el-form",
          {
            staticClass: "filter-form",
            attrs: { model: _vm.form, "label-width": "100px", size: "small" },
          },
          [
            _c("div", { staticClass: "flex-x-between" }, [
              _c(
                "div",
                { staticClass: "flex flex-shrink0" },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c(
                          "div",
                          [
                            _vm._v(" 操作门店 "),
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  effect: "light",
                                  content:
                                    "接车单的操作门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)",
                                  placement: "bottom-start",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "iconfont grow-icon_doubt",
                                  staticStyle: { color: "#9a9a9a" },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "el-select",
                        {
                          key: _vm.selectKey,
                          attrs: {
                            size: "small",
                            filterable: "",
                            clearable: "",
                            remote: "",
                            placeholder: "搜索车店名称、编号",
                            "remote-method": _vm.searchStore,
                            loading: _vm.searchStoreLoading,
                          },
                          on: {
                            change: _vm.selectStore,
                            clear: _vm.deleteStore,
                          },
                          model: {
                            value: _vm.form.operateCompanyId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "operateCompanyId", $$v)
                            },
                            expression: "form.operateCompanyId",
                          },
                        },
                        _vm._l(_vm.storeList, function (item) {
                          return _c(
                            "el-option",
                            {
                              key: item.companyId,
                              attrs: {
                                label: item.companyName,
                                value: item.companyId,
                              },
                            },
                            [
                              _c("span", { staticStyle: { float: "left" } }, [
                                _vm._v(_vm._s(item.companyName)),
                              ]),
                              _c("span", { staticStyle: { float: "right" } }, [
                                _vm._v(_vm._s(item.companyId)),
                              ]),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    2
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "消费客户" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入客户名称", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.doSearch.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.form.consumerMsg,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "consumerMsg", $$v)
                          },
                          expression: "form.consumerMsg",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "车单结账日期" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "w100",
                        attrs: {
                          type: "daterange",
                          "unlink-panels": "",
                          "range-separator": "至",
                          "start-placeholder": "起始时间",
                          "end-placeholder": "截止时间",
                          "value-format": "yyyy-MM-dd",
                        },
                        model: {
                          value: _vm.form.checkStartTimes,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "checkStartTimes", $$v)
                          },
                          expression: "form.checkStartTimes",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "el-form-item--small" }, [
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "btn_search",
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.doSearch },
                      },
                      [_vm._v("查询")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn_search",
                        attrs: { size: "small" },
                        on: { click: _vm.empty },
                      },
                      [_vm._v("置空")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "flex-warp" },
              [
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "签约客户" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            clearable: "",
                            remote: "",
                            placeholder: "请输入客户名称",
                            "remote-method": _vm.searchClient,
                            loading: _vm.searchClientLoading,
                          },
                          on: { change: _vm.selectClient },
                          model: {
                            value: _vm.form.clientId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "clientId", $$v)
                            },
                            expression: "form.clientId",
                          },
                        },
                        _vm._l(_vm.clientList, function (item) {
                          return _c(
                            "el-option",
                            {
                              key: item.id,
                              attrs: { label: item.clientName, value: item.id },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    float: "left",
                                    color: "#8492a6",
                                    "font-size": "13px",
                                  },
                                },
                                [_vm._v(_vm._s(item.clientName))]
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "业务来源" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            clearable: "",
                            remote: "",
                            placeholder: "搜索业务来源",
                            "remote-method": _vm.searchBillSource,
                            loading: _vm.searchBillSourceLoading,
                          },
                          on: {
                            change: _vm.selectBillSource,
                            clear: _vm.deleteBillSource,
                          },
                          model: {
                            value: _vm.form.billSourceTypeId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "billSourceTypeId", $$v)
                            },
                            expression: "form.billSourceTypeId",
                          },
                        },
                        _vm._l(_vm.billSourceList, function (item) {
                          return _c("el-option", {
                            key: item.billSourceTypeId,
                            attrs: {
                              label: item.name,
                              value: item.billSourceTypeId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "业务类型" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "w100",
                          attrs: { placeholder: "请选择", clearable: "" },
                          model: {
                            value: _vm.form.billType,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "billType", $$v)
                            },
                            expression: "form.billType",
                          },
                        },
                        _vm._l(_vm.businessType, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "接车进店日期" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          "unlink-panels": "",
                          "range-separator": "至",
                          "start-placeholder": "起始时间",
                          "end-placeholder": "截止时间",
                          "value-format": "yyyy-MM-dd",
                        },
                        model: {
                          value: _vm.form.intoStoreTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "intoStoreTime", $$v)
                          },
                          expression: "form.intoStoreTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "接车单号" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入", clearable: "" },
                        model: {
                          value: _vm.form.billCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "billCode", $$v)
                          },
                          expression: "form.billCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "单据状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.form.billState,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "billState", $$v)
                            },
                            expression: "form.billState",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: "" },
                          }),
                          _c("el-option", {
                            attrs: { label: "服务中", value: 0 },
                          }),
                          _c("el-option", {
                            attrs: { label: "挂单中", value: 4 },
                          }),
                          _c("el-option", {
                            attrs: { label: "待结账", value: 2 },
                          }),
                          _c("el-option", {
                            attrs: { label: "已结账", value: 1 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "单据金额" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.form.paid,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "paid", $$v)
                            },
                            expression: "form.paid",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: 2 },
                          }),
                          _c("el-option", {
                            attrs: { label: "大于0", value: 1 },
                          }),
                          _c("el-option", {
                            attrs: { label: "等于0", value: 0 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        ),
      ],
      [
        _vm.showQueryResult
          ? _c("div", { staticClass: "tip_word red" }, [
              _c("p", [_vm._v("已找到" + _vm._s(_vm.queryTotal) + "条数据")]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "seal color333" }, [
          _c("div", { staticStyle: { width: "33%" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 506,
                    expression: "506",
                  },
                ],
              },
              [
                _c("p", { staticStyle: { "margin-bottom": "8px" } }, [
                  _vm._v("盖章设置"),
                ]),
                _vm._v(" 业务章： "),
                _c(
                  "el-radio-group",
                  {
                    on: { change: _vm.handleChangeBusinessSeal },
                    model: {
                      value: _vm.businessSeal,
                      callback: function ($$v) {
                        _vm.businessSeal = $$v
                      },
                      expression: "businessSeal",
                    },
                  },
                  [
                    _c(
                      "el-radio",
                      {
                        staticStyle: { margin: "10px" },
                        attrs: { label: "0" },
                      },
                      [_vm._v("不盖章")]
                    ),
                    _c(
                      "el-radio",
                      {
                        staticStyle: { margin: "10px" },
                        attrs: { label: "1" },
                      },
                      [_vm._v("按单据开票主体")]
                    ),
                    _c(
                      "el-radio",
                      {
                        staticStyle: { margin: "0 10px" },
                        attrs: { label: "2" },
                      },
                      [
                        _vm._v(" 固定章 "),
                        _c(
                          "el-select",
                          {
                            attrs: {
                              size: "small",
                              filterable: "",
                              clearable: "",
                              remote: "",
                              placeholder: "搜索开票主体",
                              "remote-method": _vm.searchInvoice,
                              loading: _vm.searchInvoiceLoading,
                            },
                            on: {
                              change: _vm.selectInvoice,
                              clear: _vm.deleteInvoice,
                            },
                            model: {
                              value: _vm.invoiceId,
                              callback: function ($$v) {
                                _vm.invoiceId = $$v
                              },
                              expression: "invoiceId",
                            },
                          },
                          _vm._l(_vm.invoiceList, function (item) {
                            return _c("el-option", {
                              key: item.companyId,
                              attrs: {
                                label: item.invoicingEntity,
                                value: item.companyId,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.showPrintProcess
              ? _c(
                  "div",
                  { staticClass: "flex", staticStyle: { margin: "15px 0px" } },
                  [
                    _c("p", [_vm._v("操作人：" + _vm._s(_vm.userName))]),
                    _c("p", { staticStyle: { "margin-left": "5%" } }, [
                      _vm._v("操作时间：" + _vm._s(_vm.operatingTime)),
                    ]),
                  ]
                )
              : _vm._e(),
            _c("div", { staticClass: "flex red" }, [
              _vm.showPrintProcess
                ? _c("p", [
                    _vm._v(" 总计" + _vm._s(_vm.notPrintTotal) + "条 "),
                    _vm.preparedTotal != ""
                      ? _c("span", [
                          _vm._v("已准备" + _vm._s(_vm.preparedTotal) + "条"),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm.showPrintProcess
                ? _c("p", [
                    _vm.noPrint
                      ? _c("span", [_vm._v("（未完成打印）")])
                      : _vm._e(),
                    _vm.inPrint ? _c("span", [_vm._v("(打印中)")]) : _vm._e(),
                    _vm.endPrint
                      ? _c("span", [_vm._v("(打印成功)")])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm.notPrintNum > 0
                ? _c(
                    "p",
                    {
                      staticClass: "blue pointer",
                      staticStyle: { "margin-left": "10%" },
                      on: { click: _vm.continuePrint },
                    },
                    [_vm._v(" 继续打印 ")]
                  )
                : _vm._e(),
            ]),
          ]),
          _c(
            "div",
            { staticStyle: { width: "67%" } },
            [
              _c("printed-page", {
                staticStyle: { position: "fixed", left: "-999px" },
                attrs: {
                  carBillData: _vm.carBillData,
                  businessSeal: _vm.businessSeal,
                  invoiceName: _vm.invoiceName,
                  printPageCurr: _vm.printPageCurr,
                  printPageSize: _vm.printPageSize,
                  count: _vm.billIds.length,
                },
              }),
              _c("div", { ref: "printDom", staticClass: "print-template" }, [
                _c("div", { ref: "print" }, [
                  _c("div", { staticClass: "print-template__header" }, [
                    _c("h1", { staticClass: "print-template__header__title" }, [
                      _vm._v(" " + _vm._s(_vm.company.companyName) + " "),
                    ]),
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "margin-top": "5px",
                          "font-size": "18px",
                          "text-align": "center",
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.config.title) + " ")]
                    ),
                    _vm.config.leftImagePath
                      ? _c("img", {
                          staticClass: "print-template__header__lt-img",
                          attrs: { src: _vm.config.leftImagePath },
                        })
                      : _vm._e(),
                    _vm.config.rightImagePath
                      ? _c("img", {
                          staticClass: "print-template__header__rt-img",
                          attrs: { src: _vm.config.rightImagePath },
                        })
                      : _vm._e(),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "print-template__date",
                      staticStyle: { "font-size": "12px" },
                    },
                    [_vm._v(" 打印时间：" + _vm._s(_vm.printTime) + " ")]
                  ),
                  _c("div", { staticClass: "print-template__user" }, [
                    _c("h3", { ref: "clientName" }, [
                      _vm._v("客户：" + _vm._s(_vm.carInfo.clientName)),
                    ]),
                    _c("h3", [
                      _vm._v("接车单号：" + _vm._s(_vm.billMessage.billCode)),
                    ]),
                  ]),
                  _c("div", { staticClass: "print-template__box__border" }, [
                    _c(
                      "table",
                      { staticClass: "print-template__box__border__table" },
                      [
                        _c("tbody", [
                          _vm.billType === 2
                            ? _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      width: "85px",
                                      "text-align": "left",
                                    },
                                  },
                                  [_vm._v("消费客户")]
                                ),
                                _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.carBillInsuranceInfo
                                          .claimsInsuranceCompanyName
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      width: "85px",
                                      "text-align": "left",
                                    },
                                  },
                                  [_vm._v("报案号")]
                                ),
                                _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.carBillInsuranceInfo.caseNum) +
                                      " "
                                  ),
                                ]),
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      width: "85px",
                                      "text-align": "left",
                                    },
                                  },
                                  [_vm._v("事故责任")]
                                ),
                                _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("dict")(
                                          _vm.carBillInsuranceInfo.dutyType,
                                          "dutyType"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.billType === 3
                            ? _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      width: "85px",
                                      "text-align": "left",
                                    },
                                  },
                                  [_vm._v("消费客户")]
                                ),
                                _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.carBillUndertakeInfo
                                          .undertakeInsuranceCompanyName
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      width: "85px",
                                      "text-align": "left",
                                    },
                                  },
                                  [_vm._v("索赔单号")]
                                ),
                                _c("td", { attrs: { colspan: "3" } }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.carBillUndertakeInfo.claimsNum.join(
                                          "、"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  width: "85px",
                                  "text-align": "left",
                                },
                              },
                              [_vm._v("送修司机")]
                            ),
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  width: "165px",
                                  "text-align": "left",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.billMessage.customerName) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  width: "100px",
                                  "text-align": "left",
                                },
                              },
                              [_vm._v(" 送修司机电话 ")]
                            ),
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  width: "179px",
                                  "text-align": "left",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.billMessage.customerPhone) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  width: "85px",
                                  "text-align": "left",
                                },
                              },
                              [_vm._v("车牌号")]
                            ),
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  width: "162px",
                                  "text-align": "left",
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.carInfo.carNumber) + " "
                                ),
                              ]
                            ),
                          ]),
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  width: "85px",
                                  "text-align": "left",
                                },
                              },
                              [_vm._v("车型")]
                            ),
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  width: "165px",
                                  "text-align": "left",
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.carInfo.brandName) + " "
                                ),
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  width: "90px",
                                  "text-align": "left",
                                },
                              },
                              [_vm._v("车架号")]
                            ),
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  width: "179px",
                                  "text-align": "left",
                                },
                              },
                              [_vm._v(" " + _vm._s(_vm.carInfo.uniqueId) + " ")]
                            ),
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  width: "85px",
                                  "text-align": "left",
                                },
                              },
                              [_vm._v("发动机号")]
                            ),
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  width: "162px",
                                  "text-align": "left",
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.carInfo.engineNumber) + " "
                                ),
                              ]
                            ),
                          ]),
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  width: "85px",
                                  "text-align": "left",
                                },
                              },
                              [_vm._v("接车员")]
                            ),
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  width: "165px",
                                  "text-align": "left",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.billMessage.pickUpUserName) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  width: "90px",
                                  "text-align": "left",
                                },
                              },
                              [_vm._v("进店时间")]
                            ),
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  width: "179px",
                                  "text-align": "left",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.billMessage.dateCreated) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  width: "85px",
                                  "text-align": "left",
                                },
                              },
                              [_vm._v("进店里程")]
                            ),
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  width: "162px",
                                  "text-align": "left",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.billMessage.mileage
                                        ? _vm.billMessage.mileage + "km"
                                        : ""
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  width: "85px",
                                  "text-align": "left",
                                },
                              },
                              [_vm._v("结账收银员")]
                            ),
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  width: "165px",
                                  "text-align": "left",
                                },
                              },
                              [_vm._v(" " + _vm._s(_vm.cashier) + " ")]
                            ),
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  width: "90px",
                                  "text-align": "left",
                                },
                              },
                              [_vm._v("结账时间")]
                            ),
                            _c(
                              "td",
                              { staticStyle: { "text-align": "left" } },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.billMessage.checkDate) + " "
                                ),
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  width: "90px",
                                  "text-align": "left",
                                },
                              },
                              [_vm._v("提车时间")]
                            ),
                            _c(
                              "td",
                              { staticStyle: { "text-align": "left" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.billMessage.takeTime ||
                                        _vm.billMessage.checkDate
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                  _vm.serviceList && _vm.serviceList.length
                    ? _c(
                        "div",
                        { staticClass: "print-template__box__border" },
                        [
                          _c("h3", { staticClass: "print-template__title" }, [
                            _vm._v("服务明细"),
                          ]),
                          _c(
                            "table",
                            {
                              staticClass: "print-template__box__border__table",
                            },
                            [
                              _c("thead", [
                                _c("tr", [
                                  _c(
                                    "th",
                                    {
                                      staticStyle: {
                                        width: "32px",
                                        "text-align": "center",
                                      },
                                    },
                                    [_vm._v("序号")]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticStyle: {
                                        "min-width": "130px",
                                        "text-align": "center",
                                      },
                                    },
                                    [_vm._v(" 服务名称 ")]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticStyle: {
                                        width: "65px",
                                        "text-align": "center",
                                      },
                                    },
                                    [_vm._v("工时")]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticStyle: {
                                        width: "70px",
                                        "text-align": "center",
                                      },
                                    },
                                    [_vm._v("单价")]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticStyle: {
                                        width: "40px",
                                        "text-align": "center",
                                      },
                                    },
                                    [_vm._v("折扣")]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticStyle: {
                                        width: "75px",
                                        "text-align": "center",
                                      },
                                    },
                                    [_vm._v("小计")]
                                  ),
                                ]),
                              ]),
                              _c(
                                "tbody",
                                [
                                  _vm._l(
                                    _vm.serviceList,
                                    function (item, index) {
                                      return _c("tr", { key: index }, [
                                        _c(
                                          "td",
                                          {
                                            staticStyle: {
                                              "text-align": "center",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(index + 1) + " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticStyle: {
                                              "text-align": "left",
                                            },
                                          },
                                          [_vm._v(_vm._s(item.name))]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticStyle: {
                                              "text-align": "right",
                                            },
                                          },
                                          [_vm._v(" " + _vm._s(item.num) + " ")]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticStyle: {
                                              "text-align": "right",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("toThousands")(
                                                    item.price
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticStyle: {
                                              "text-align": "right",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.discount
                                                    ? item.discount
                                                    : "无"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticStyle: {
                                              "text-align": "right",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("toThousands")(
                                                    item.total
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ])
                                    }
                                  ),
                                  _c(
                                    "tr",
                                    { staticStyle: { "text-align": "right" } },
                                    [
                                      _c(
                                        "td",
                                        {
                                          staticStyle: { padding: "4px" },
                                          attrs: { colspan: 6 },
                                        },
                                        [
                                          _c(
                                            "b",
                                            {
                                              staticClass:
                                                "print-template__box__border__table__total text-right",
                                              staticStyle: {
                                                "font-weight": "600",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "折前服务费合计：" +
                                                  _vm._s(
                                                    _vm._f("toThousands")(
                                                      _vm.beforeTotalServicePrice
                                                    )
                                                  )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "b",
                                            {
                                              staticClass:
                                                "print-template__box__border__table__total text-right",
                                              staticStyle: {
                                                "font-weight": "600",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "折后服务费合计：" +
                                                  _vm._s(
                                                    _vm._f("toThousands")(
                                                      _vm.afterTotalServicePrice
                                                    )
                                                  )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                2
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.goodsList && _vm.goodsList.length
                    ? _c(
                        "div",
                        { staticClass: "print-template__box__border" },
                        [
                          _c(
                            "h3",
                            {
                              ref: "materialTitle",
                              staticClass: "print-template__title",
                            },
                            [_vm._v(" 材料明细 ")]
                          ),
                          _c(
                            "table",
                            {
                              ref: "materialTable",
                              staticClass: "print-template__box__border__table",
                            },
                            [
                              _c("thead", [
                                _c("tr", { ref: "goodTr" }, [
                                  _c(
                                    "th",
                                    {
                                      staticStyle: {
                                        width: "32px",
                                        "text-align": "center",
                                      },
                                    },
                                    [_vm._v("序号")]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticStyle: {
                                        "min-width": "176px",
                                        "text-align": "center",
                                      },
                                    },
                                    [_vm._v(" 商品名称 ")]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticStyle: {
                                        width: "75px",
                                        "text-align": "center",
                                      },
                                    },
                                    [_vm._v("数量")]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticStyle: {
                                        width: "70px",
                                        "text-align": "center",
                                      },
                                    },
                                    [_vm._v("单价")]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticStyle: {
                                        width: "40px",
                                        "text-align": "center",
                                      },
                                    },
                                    [_vm._v("折扣")]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticStyle: {
                                        width: "75px",
                                        "text-align": "center",
                                      },
                                    },
                                    [_vm._v("小计")]
                                  ),
                                ]),
                              ]),
                              _c(
                                "tbody",
                                [
                                  _vm._l(_vm.goodsList, function (item, index) {
                                    return _c("tr", { key: index }, [
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "text-align": "center",
                                          },
                                        },
                                        [_vm._v(" " + _vm._s(index + 1) + " ")]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticStyle: { "text-align": "left" },
                                        },
                                        [_vm._v(_vm._s(item.name))]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "text-align": "right",
                                          },
                                        },
                                        [_vm._v(_vm._s(item.num))]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "text-align": "right",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("toThousands")(
                                                  item.price
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "text-align": "right",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.discount
                                                  ? item.discount
                                                  : "无"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "text-align": "right",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("toThousands")(
                                                  item.total
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ])
                                  }),
                                  _c(
                                    "tr",
                                    { staticStyle: { "text-align": "right" } },
                                    [
                                      _c(
                                        "td",
                                        {
                                          staticStyle: { padding: "4px" },
                                          attrs: { colspan: 6 },
                                        },
                                        [
                                          _c(
                                            "b",
                                            {
                                              staticClass:
                                                "print-template__box__border__table__total",
                                              staticStyle: {
                                                "font-weight": "600",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "折前材料费合计：" +
                                                  _vm._s(
                                                    _vm._f("toThousands")(
                                                      _vm.beforeTotalGoodsPrice
                                                    )
                                                  )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "b",
                                            {
                                              staticClass:
                                                "print-template__box__border__table__total",
                                              staticStyle: {
                                                "font-weight": "600",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "折后材料费合计：" +
                                                  _vm._s(
                                                    _vm._f("toThousands")(
                                                      _vm.afterTotalGoodsPrice
                                                    )
                                                  )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                2
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "print-template__common" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "print-template__box__border print-template__common__column",
                      },
                      [
                        _c(
                          "h3",
                          {
                            ref: "receivableTitle",
                            staticClass: "print-template__title",
                          },
                          [_vm._v(" 应收明细 ")]
                        ),
                        _c(
                          "table",
                          {
                            ref: "receivableTable",
                            staticClass: "print-template__box__border__table",
                          },
                          [
                            _c("thead", [
                              _c("tr", [
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      width: "32px",
                                      "text-align": "center",
                                    },
                                  },
                                  [_vm._v("序号")]
                                ),
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "min-width": "180px",
                                      "text-align": "center",
                                    },
                                  },
                                  [_vm._v(" 名称 ")]
                                ),
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      width: "74px",
                                      "text-align": "center",
                                    },
                                  },
                                  [_vm._v("金额")]
                                ),
                              ]),
                            ]),
                            _c("tbody", [
                              _c("tr", [
                                _c(
                                  "td",
                                  { staticStyle: { "text-align": "center" } },
                                  [_vm._v("1")]
                                ),
                                _c(
                                  "td",
                                  { staticStyle: { "text-align": "left" } },
                                  [_vm._v("工时费")]
                                ),
                                _c(
                                  "td",
                                  { staticStyle: { "text-align": "right" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("toThousands")(
                                            _vm.serviceTotalPrice
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("tr", [
                                _c(
                                  "td",
                                  { staticStyle: { "text-align": "center" } },
                                  [_vm._v("2")]
                                ),
                                _c(
                                  "td",
                                  { staticStyle: { "text-align": "left" } },
                                  [_vm._v("材料费")]
                                ),
                                _c(
                                  "td",
                                  { staticStyle: { "text-align": "right" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("toThousands")(
                                            _vm.goodsTotalPrice
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("tr", [
                                _c(
                                  "td",
                                  { staticStyle: { "text-align": "center" } },
                                  [_vm._v("3")]
                                ),
                                _c(
                                  "td",
                                  { staticStyle: { "text-align": "left" } },
                                  [_vm._v("优惠金额")]
                                ),
                                _c(
                                  "td",
                                  { staticStyle: { "text-align": "right" } },
                                  [
                                    _vm._v(
                                      " -" +
                                        _vm._s(
                                          _vm._f("toThousands")(
                                            _vm.discountAmount
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "text-align": "right",
                                      "font-weight": "600",
                                    },
                                    attrs: { colspan: 3 },
                                  },
                                  [
                                    _vm._v(
                                      " 应收合计(大写): " +
                                        _vm._s(
                                          _vm._f("toThousands")(_vm.needPay)
                                        ) +
                                        " (" +
                                        _vm._s(
                                          _vm._f("amountToChinese")(_vm.needPay)
                                        ) +
                                        ") "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "print-template__box__border",
                        staticStyle: { "border-bottom": "none", width: "45%" },
                      },
                      [
                        _c(
                          "h3",
                          {
                            ref: "chargeTitle",
                            staticClass: "print-template__title",
                          },
                          [_vm._v(" 收费结算 ")]
                        ),
                        _c(
                          "table",
                          {
                            ref: "chargeTable",
                            staticClass: "print-template__box__border__table",
                          },
                          [
                            _c(
                              "tbody",
                              [
                                _c("tr", [
                                  _c(
                                    "th",
                                    {
                                      staticStyle: {
                                        width: "32px",
                                        "text-align": "center",
                                      },
                                    },
                                    [_vm._v("序号")]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticStyle: {
                                        "min-width": "180px",
                                        "text-align": "center",
                                      },
                                    },
                                    [_vm._v(" 支付方式 ")]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        width: "74px",
                                      },
                                    },
                                    [_vm._v("金额")]
                                  ),
                                ]),
                                _vm._l(_vm.paidList, function (item, index) {
                                  return _c("tr", { key: index }, [
                                    _c(
                                      "td",
                                      {
                                        staticStyle: { "text-align": "center" },
                                      },
                                      [_vm._v(_vm._s(index + 1))]
                                    ),
                                    _c(
                                      "td",
                                      { staticStyle: { "text-align": "left" } },
                                      [_vm._v(_vm._s(item.payType))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticStyle: { "text-align": "right" },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("toThousands")(item.money)
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ])
                                }),
                                _c("tr", [
                                  _c(
                                    "td",
                                    {
                                      staticStyle: {
                                        "text-align": "right",
                                        "font-weight": "600",
                                      },
                                      attrs: { colspan: 3 },
                                    },
                                    [
                                      _vm._v(
                                        " 实收合计(大写): " +
                                          _vm._s(
                                            _vm._f("toThousands")(_vm.paidTotal)
                                          ) +
                                          " (" +
                                          _vm._s(
                                            _vm._f("amountToChinese")(
                                              _vm.paidTotal
                                            )
                                          ) +
                                          ") "
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("tr", [
                                  _c(
                                    "td",
                                    {
                                      staticStyle: {
                                        "text-align": "right",
                                        "font-weight": "600",
                                      },
                                      attrs: { colspan: 3 },
                                    },
                                    [
                                      _vm._v(
                                        " 欠收合计(大写): " +
                                          _vm._s(
                                            _vm._f("toThousands")(
                                              _vm.needPaidTotal
                                            )
                                          ) +
                                          " (" +
                                          _vm._s(
                                            _vm._f("amountToChinese")(
                                              _vm.needPaidTotal
                                            )
                                          ) +
                                          ") "
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              2
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      ref: "signature",
                      staticClass: "print-template__signature",
                    },
                    [
                      _c("b", { staticClass: "print-template__title" }, [
                        _vm._v("服务顾问签名："),
                      ]),
                      _c("b", { staticClass: "print-template__title" }, [
                        _vm._v("客户签名："),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "print-template__contact",
                      staticStyle: { position: "relative" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "print-template__contact__item fontsize",
                          staticStyle: { width: "60%" },
                        },
                        [
                          _vm._v(
                            " 联系地址：" + _vm._s(_vm.company.address) + " "
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "print-template__contact__item fontsize",
                        },
                        [
                          _vm._v(
                            " 联系电话：" + _vm._s(_vm.company.telephone) + " "
                          ),
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "30px" } },
                            [_vm._v(_vm._s(_vm.company.alternativeTelephone))]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            position: "absolute",
                            left: "30px",
                            top: "-70px",
                          },
                        },
                        [
                          _c("seal-demo", {
                            attrs: {
                              businessSeal: _vm.businessSeal,
                              companyName: _vm.selectInvoiceCompanyName,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ]),
            ],
            1
          ),
        ]),
      ],
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          { staticClass: "flex-x-end foot-btn" },
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.handlePrint } },
              [_vm._v("打印")]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }