var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { attrs: { customStyle: "padding-top:8px;" } },
    [
      _c(
        "div",
        { staticClass: "car-detail-box" },
        [
          _c(
            "div",
            {
              staticClass: "customer-info",
              attrs: { id: "uq-car-customer-info" },
            },
            [
              _c(
                "div",
                { staticClass: "customer-info-header flex-x-between" },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "bill-type flex-center flex-shrink0" },
                      [
                        _c("div", { staticClass: "bill-type-text" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("dict")(
                                  _vm.billMessage.billType,
                                  "carBillType"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    ),
                    _c("div", { staticClass: "car-logo flex-shrink0" }, [
                      _vm.carInfo.carLogoIcon
                        ? _c("img", { attrs: { src: _vm.carInfo.carLogoIcon } })
                        : _c("img", {
                            attrs: { src: require("@/assets/img/carLogo.png") },
                          }),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "flex-x-between flex-col flex-shrink0",
                        staticStyle: {
                          "margin-left": "16px",
                          padding: "6px 0",
                        },
                      },
                      [
                        _c("div", { staticClass: "car-info flex-y-end" }, [
                          _c(
                            "span",
                            {
                              staticClass: "car-number pointer",
                              on: { click: _vm.goCarDetail },
                            },
                            [_vm._v(_vm._s(_vm.carInfo.carNumber))]
                          ),
                          _vm.carInfo.brandName
                            ? _c(
                                "span",
                                { staticClass: "car-info-tag car-brand" },
                                [_vm._v(_vm._s(_vm.carInfo.brandName))]
                              )
                            : _vm._e(),
                          _c(
                            "span",
                            {
                              staticClass: "car-info-tag history pointer",
                              on: { click: _vm.viewHistory },
                            },
                            [
                              _vm._v(
                                "历史进店 " +
                                  _vm._s(_vm.carInfo.intoStoresCount) +
                                  " 次"
                              ),
                            ]
                          ),
                          _vm.carInfo.isRiskControlCar === 1
                            ? _c(
                                "span",
                                { staticClass: "car-info-tag riskControlCar" },
                                [_vm._v("风控车")]
                              )
                            : _vm._e(),
                        ]),
                        _c(
                          "div",
                          { staticClass: "flex" },
                          [
                            _vm.carInfo.clientId
                              ? _c("div", { staticClass: "client-info" }, [
                                  [0, 1, 2].includes(_vm.carInfo.clientLevel)
                                    ? _c("i", {
                                        staticClass: "iconfont icon_grade",
                                        class:
                                          _vm.levelIcons[
                                            _vm.carInfo.clientLevel
                                          ],
                                        staticStyle: { "margin-right": "14px" },
                                        attrs: {
                                          title:
                                            _vm.levelIconsWord[
                                              _vm.carInfo.clientLevel
                                            ],
                                        },
                                      })
                                    : _vm._e(),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "client-name pointer",
                                      on: { click: _vm.goClientDetail },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.carInfo.clientName) + " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "auth",
                                          rawName: "v-auth",
                                          value: 546,
                                          expression: "546",
                                        },
                                      ],
                                      staticClass: "primary pointer",
                                      staticStyle: {
                                        "margin-left": "16px",
                                        "font-size": "14px",
                                        "text-decoration": "underline",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.clientCommandDrawerVisibility = true
                                        },
                                      },
                                    },
                                    [_vm._v("客户指令")]
                                  ),
                                ])
                              : _c(
                                  "div",
                                  { staticStyle: { "margin-top": "3px" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "color999",
                                        staticStyle: {
                                          "margin-left": "5px",
                                          "font-size": "14px",
                                        },
                                      },
                                      [_vm._v("暂未绑定客户")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "auth",
                                            rawName: "v-auth",
                                            value: 63,
                                            expression: "63",
                                          },
                                        ],
                                        staticClass: "blue pointer",
                                        staticStyle: {
                                          "margin-left": "5px",
                                          "font-size": "14px",
                                        },
                                        on: {
                                          click: _vm.showBindingCustomerDialog,
                                        },
                                      },
                                      [_vm._v("绑定客户")]
                                    ),
                                    _vm.carInfo.clientRemark
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "pointer",
                                            staticStyle: {
                                              "margin-left": "16px",
                                              "font-size": "14px",
                                              "text-decoration": "underline",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.remarksDrawerVisibilityEvent(
                                                  1
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("注意事项")]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                            _vm.carAgreementId
                              ? _c("div", [
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "auth",
                                          rawName: "v-auth",
                                          value: 579,
                                          expression: "579",
                                        },
                                      ],
                                      staticClass: "primary pointer",
                                      staticStyle: {
                                        "margin-left": "16px",
                                        "font-size": "14px",
                                        "text-decoration": "underline",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.agreementPriceDialogVisibility = true
                                        },
                                      },
                                    },
                                    [_vm._v("协议报价")]
                                  ),
                                ])
                              : _vm._e(),
                            _vm.underWarrantyState == 0
                              ? _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      width: "300",
                                      placement: "bottom",
                                      trigger: "hover",
                                    },
                                  },
                                  [
                                    _c(
                                      "template",
                                      { slot: "reference" },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "warranty-info",
                                            attrs: {
                                              type: "primary",
                                              plain: "",
                                            },
                                          },
                                          [
                                            _vm._v("未知"),
                                            _c("i", {
                                              staticClass:
                                                "iconfont grow-icon_doubt",
                                              staticStyle: {
                                                "font-size": "12px",
                                                "margin-left": "3px",
                                              },
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.vehicleVo.length == 0 &&
                                    _vm.threePowerVo.length == 0 &&
                                    _vm.powerBatteryVo.length == 0 &&
                                    _vm.batteryVo.length == 0 &&
                                    _vm.wearingPartsVo.length == 0
                                      ? _c("div", [
                                          _c(
                                            "span",
                                            { staticClass: "warranty-title" },
                                            [_vm._v("未知")]
                                          ),
                                          _c("span", { staticClass: "tips" }, [
                                            _vm._v("（暂无该车型质保规则）"),
                                          ]),
                                        ])
                                      : _c("div", [
                                          _c(
                                            "span",
                                            { staticClass: "warranty-title" },
                                            [_vm._v("未知")]
                                          ),
                                          _c("span", { staticClass: "tips" }, [
                                            _vm._v(
                                              "（注册时间为空或暂无进店里程）"
                                            ),
                                          ]),
                                          _vm.vehicleVo.length != 0
                                            ? _c(
                                                "div",
                                                _vm._l(
                                                  _vm.vehicleVo,
                                                  function (item, index) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: index,
                                                        staticClass: "tips",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value:
                                                                  index == 0,
                                                                expression:
                                                                  "index == 0",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "warranty-title",
                                                          },
                                                          [_vm._v("1.整车质保")]
                                                        ),
                                                        _c("span", [
                                                          _vm._v(
                                                            "(" +
                                                              _vm._s(
                                                                item.month
                                                              ) +
                                                              "月/" +
                                                              _vm._s(item.km) +
                                                              "公里)"
                                                          ),
                                                        ]),
                                                        _c("p", [
                                                          _vm._v(
                                                            _vm._s(item.content)
                                                          ),
                                                        ]),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                          _vm.threePowerVo.length != 0
                                            ? _c(
                                                "div",
                                                _vm._l(
                                                  _vm.threePowerVo,
                                                  function (item, index) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: index,
                                                        staticClass: "tips",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value:
                                                                  index == 0,
                                                                expression:
                                                                  "index == 0",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "warranty-title",
                                                          },
                                                          [_vm._v("2.三电质保")]
                                                        ),
                                                        _c("span", [
                                                          _vm._v(
                                                            "(" +
                                                              _vm._s(
                                                                item.month
                                                              ) +
                                                              "月/" +
                                                              _vm._s(item.km) +
                                                              "公里)"
                                                          ),
                                                        ]),
                                                        _c("p", [
                                                          _vm._v(
                                                            _vm._s(item.content)
                                                          ),
                                                        ]),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                          _vm.powerBatteryVo.length != 0
                                            ? _c(
                                                "div",
                                                _vm._l(
                                                  _vm.powerBatteryVo,
                                                  function (item, index) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: index,
                                                        staticClass: "tips",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value:
                                                                  index == 0,
                                                                expression:
                                                                  "index == 0",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "warranty-title",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "3.动力电池质保"
                                                            ),
                                                          ]
                                                        ),
                                                        _c("span", [
                                                          _vm._v(
                                                            "(" +
                                                              _vm._s(
                                                                item.month
                                                              ) +
                                                              "月/" +
                                                              _vm._s(item.km) +
                                                              "公里)"
                                                          ),
                                                        ]),
                                                        _c("p", [
                                                          _vm._v(
                                                            _vm._s(item.content)
                                                          ),
                                                        ]),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                          _vm.batteryVo.length != 0
                                            ? _c(
                                                "div",
                                                _vm._l(
                                                  _vm.batteryVo,
                                                  function (item, index) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: index,
                                                        staticClass: "tips",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value:
                                                                  index == 0,
                                                                expression:
                                                                  "index == 0",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "warranty-title",
                                                          },
                                                          [_vm._v("4.电芯质保")]
                                                        ),
                                                        _c("span", [
                                                          _vm._v(
                                                            "(" +
                                                              _vm._s(
                                                                item.month
                                                              ) +
                                                              "月/" +
                                                              _vm._s(item.km) +
                                                              "公里)"
                                                          ),
                                                        ]),
                                                        _c("p", [
                                                          _vm._v(
                                                            _vm._s(item.content)
                                                          ),
                                                        ]),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                          _vm.wearingPartsVo.length != 0
                                            ? _c(
                                                "div",
                                                _vm._l(
                                                  _vm.wearingPartsVo,
                                                  function (item, index) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: index,
                                                        staticClass: "tips",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value:
                                                                  index == 0,
                                                                expression:
                                                                  "index == 0",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "warranty-title",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "5.易损件质保"
                                                            ),
                                                          ]
                                                        ),
                                                        _c("span", [
                                                          _vm._v(
                                                            "(" +
                                                              _vm._s(
                                                                item.month
                                                              ) +
                                                              "月/" +
                                                              _vm._s(item.km) +
                                                              "公里)"
                                                          ),
                                                        ]),
                                                        _c("p", [
                                                          _vm._v(
                                                            _vm._s(item.content)
                                                          ),
                                                        ]),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                        ]),
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm.underWarrantyState == 1
                              ? _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      width: "300",
                                      placement: "bottom",
                                      trigger: "hover",
                                    },
                                  },
                                  [
                                    _c(
                                      "template",
                                      { slot: "reference" },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "warranty-info",
                                            attrs: {
                                              type: "primary",
                                              plain: "",
                                            },
                                          },
                                          [
                                            _vm._v("过保"),
                                            _c("i", {
                                              staticClass:
                                                "iconfont grow-icon_doubt",
                                              staticStyle: {
                                                "font-size": "12px",
                                                "margin-left": "3px",
                                              },
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("div", [
                                      _c("p", [_vm._v("过保")]),
                                      _vm.vehicleVo.length != 0
                                        ? _c(
                                            "div",
                                            _vm._l(
                                              _vm.vehicleVo,
                                              function (item, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: index,
                                                    staticClass: "tips",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value: index == 0,
                                                            expression:
                                                              "index == 0",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "warranty-title",
                                                      },
                                                      [_vm._v("1.整车质保")]
                                                    ),
                                                    _c("span", [
                                                      _vm._v(
                                                        "(" +
                                                          _vm._s(item.month) +
                                                          "月/" +
                                                          _vm._s(item.km) +
                                                          "公里)"
                                                      ),
                                                    ]),
                                                    _c("p", [
                                                      _vm._v(
                                                        _vm._s(item.content)
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _vm._e(),
                                      _vm.threePowerVo.length != 0
                                        ? _c(
                                            "div",
                                            _vm._l(
                                              _vm.threePowerVo,
                                              function (item, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: index,
                                                    staticClass: "tips",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value: index == 0,
                                                            expression:
                                                              "index == 0",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "warranty-title",
                                                      },
                                                      [_vm._v("2.三电质保")]
                                                    ),
                                                    _c("span", [
                                                      _vm._v(
                                                        "(" +
                                                          _vm._s(item.month) +
                                                          "月/" +
                                                          _vm._s(item.km) +
                                                          "公里)"
                                                      ),
                                                    ]),
                                                    _c("p", [
                                                      _vm._v(
                                                        _vm._s(item.content)
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _vm._e(),
                                      _vm.powerBatteryVo.length != 0
                                        ? _c(
                                            "div",
                                            _vm._l(
                                              _vm.powerBatteryVo,
                                              function (item, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: index,
                                                    staticClass: "tips",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value: index == 0,
                                                            expression:
                                                              "index == 0",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "warranty-title",
                                                      },
                                                      [_vm._v("3.动力电池质保")]
                                                    ),
                                                    _c("span", [
                                                      _vm._v(
                                                        "(" +
                                                          _vm._s(item.month) +
                                                          "月/" +
                                                          _vm._s(item.km) +
                                                          "公里)"
                                                      ),
                                                    ]),
                                                    _c("p", [
                                                      _vm._v(
                                                        _vm._s(item.content)
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _vm._e(),
                                      _vm.batteryVo.length != 0
                                        ? _c(
                                            "div",
                                            _vm._l(
                                              _vm.batteryVo,
                                              function (item, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: index,
                                                    staticClass: "tips",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value: index == 0,
                                                            expression:
                                                              "index == 0",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "warranty-title",
                                                      },
                                                      [_vm._v("4.电芯质保")]
                                                    ),
                                                    _c("span", [
                                                      _vm._v(
                                                        "(" +
                                                          _vm._s(item.month) +
                                                          "月/" +
                                                          _vm._s(item.km) +
                                                          "公里)"
                                                      ),
                                                    ]),
                                                    _c("p", [
                                                      _vm._v(
                                                        _vm._s(item.content)
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _vm._e(),
                                      _vm.wearingPartsVo.length != 0
                                        ? _c(
                                            "div",
                                            _vm._l(
                                              _vm.wearingPartsVo,
                                              function (item, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: index,
                                                    staticClass: "tips",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value: index == 0,
                                                            expression:
                                                              "index == 0",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "warranty-title",
                                                      },
                                                      [_vm._v("5.易损件质保")]
                                                    ),
                                                    _c("span", [
                                                      _vm._v(
                                                        "(" +
                                                          _vm._s(item.month) +
                                                          "月/" +
                                                          _vm._s(item.km) +
                                                          "公里)"
                                                      ),
                                                    ]),
                                                    _c("p", [
                                                      _vm._v(
                                                        _vm._s(item.content)
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _vm._e(),
                                    ]),
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm.underWarrantyState == 2 &&
                            _vm.vehicleVo.length != 0
                              ? _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      width: "300",
                                      placement: "bottom",
                                      trigger: "hover",
                                    },
                                  },
                                  [
                                    _c(
                                      "template",
                                      { slot: "reference" },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "warranty-info",
                                            attrs: {
                                              type: "primary",
                                              plain: "",
                                            },
                                          },
                                          [
                                            _vm._v("整车质保"),
                                            _c("i", {
                                              staticClass:
                                                "iconfont grow-icon_doubt",
                                              staticStyle: {
                                                "font-size": "12px",
                                                "margin-left": "3px",
                                              },
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.vehicleVo.length != 0
                                      ? _c("div", [
                                          _c("p", [
                                            _c(
                                              "span",
                                              { staticClass: "warranty-title" },
                                              [_vm._v("整车质保")]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "tips" },
                                              [
                                                _vm._v(
                                                  "(" +
                                                    _vm._s(
                                                      _vm.vehicleVo[0].month
                                                    ) +
                                                    "月/" +
                                                    _vm._s(
                                                      _vm.vehicleVo[0].km
                                                    ) +
                                                    "公里)"
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c("p", { staticClass: "tips" }, [
                                            _vm._v(
                                              _vm._s(_vm.vehicleVo[0].content)
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm.underWarrantyState == 2 &&
                            _vm.threePowerVo.length != 0
                              ? _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      width: "300",
                                      placement: "bottom",
                                      trigger: "hover",
                                    },
                                  },
                                  [
                                    _c(
                                      "template",
                                      { slot: "reference" },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "warranty-info",
                                            attrs: {
                                              type: "primary",
                                              plain: "",
                                            },
                                          },
                                          [
                                            _vm._v("三电质保"),
                                            _c("i", {
                                              staticClass:
                                                "iconfont grow-icon_doubt",
                                              staticStyle: {
                                                "font-size": "12px",
                                                "margin-left": "3px",
                                              },
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("div", [
                                      _c("p", [
                                        _c(
                                          "span",
                                          { staticClass: "warranty-title" },
                                          [_vm._v("三电质保")]
                                        ),
                                        _c("span", { staticClass: "tips" }, [
                                          _vm._v(
                                            "(" +
                                              _vm._s(
                                                _vm.threePowerVo[0].month
                                              ) +
                                              "月/" +
                                              _vm._s(_vm.threePowerVo[0].km) +
                                              "公里)"
                                          ),
                                        ]),
                                      ]),
                                      _c("p", { staticClass: "tips" }, [
                                        _vm._v(
                                          _vm._s(_vm.threePowerVo[0].content)
                                        ),
                                      ]),
                                    ]),
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm.underWarrantyState == 2 &&
                            _vm.powerBatteryVo.length != 0
                              ? _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      width: "300",
                                      placement: "bottom",
                                      trigger: "hover",
                                    },
                                  },
                                  [
                                    _c(
                                      "template",
                                      { slot: "reference" },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "warranty-info",
                                            attrs: {
                                              type: "primary",
                                              plain: "",
                                            },
                                          },
                                          [
                                            _vm._v("动力电池质保"),
                                            _c("i", {
                                              staticClass:
                                                "iconfont grow-icon_doubt",
                                              staticStyle: {
                                                "font-size": "12px",
                                                "margin-left": "3px",
                                              },
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("div", [
                                      _c("p", [
                                        _c(
                                          "span",
                                          { staticClass: "warranty-title" },
                                          [_vm._v("动力电池质保")]
                                        ),
                                        _c("span", { staticClass: "tips" }, [
                                          _vm._v(
                                            "(" +
                                              _vm._s(
                                                _vm.powerBatteryVo[0].month
                                              ) +
                                              "月/" +
                                              _vm._s(_vm.powerBatteryVo[0].km) +
                                              "公里)"
                                          ),
                                        ]),
                                      ]),
                                      _c("p", { staticClass: "tips" }, [
                                        _vm._v(
                                          _vm._s(_vm.powerBatteryVo[0].content)
                                        ),
                                      ]),
                                    ]),
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm.underWarrantyState == 2 &&
                            _vm.batteryVo.length != 0
                              ? _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      width: "300",
                                      placement: "bottom",
                                      trigger: "hover",
                                    },
                                  },
                                  [
                                    _c(
                                      "template",
                                      { slot: "reference" },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "warranty-info",
                                            attrs: {
                                              type: "primary",
                                              plain: "",
                                            },
                                          },
                                          [
                                            _vm._v("电芯质保"),
                                            _c("i", {
                                              staticClass:
                                                "iconfont grow-icon_doubt",
                                              staticStyle: {
                                                "font-size": "12px",
                                                "margin-left": "3px",
                                              },
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("div", [
                                      _c("p", [
                                        _c(
                                          "span",
                                          { staticClass: "warranty-title" },
                                          [_vm._v("电芯质保")]
                                        ),
                                        _c("span", { staticClass: "tips" }, [
                                          _vm._v(
                                            "(" +
                                              _vm._s(_vm.batteryVo[0].month) +
                                              "月/" +
                                              _vm._s(_vm.batteryVo[0].km) +
                                              "公里)"
                                          ),
                                        ]),
                                      ]),
                                      _c("p", { staticClass: "tips" }, [
                                        _vm._v(
                                          _vm._s(_vm.batteryVo[0].content)
                                        ),
                                      ]),
                                    ]),
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm.underWarrantyState == 2 &&
                            _vm.wearingPartsVo.length != 0
                              ? _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      width: "300",
                                      placement: "bottom",
                                      trigger: "hover",
                                    },
                                  },
                                  [
                                    _c(
                                      "template",
                                      { slot: "reference" },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "warranty-info",
                                            attrs: {
                                              type: "primary",
                                              plain: "",
                                            },
                                          },
                                          [
                                            _vm._v("易损件质保"),
                                            _c("i", {
                                              staticClass:
                                                "iconfont grow-icon_doubt",
                                              staticStyle: {
                                                "font-size": "12px",
                                                "margin-left": "3px",
                                              },
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("div", [
                                      _c("p", [
                                        _c(
                                          "span",
                                          { staticClass: "warranty-title" },
                                          [_vm._v("易损件质保")]
                                        ),
                                        _c("span", { staticClass: "tips" }, [
                                          _vm._v(
                                            "(" +
                                              _vm._s(
                                                _vm.wearingPartsVo[0].month
                                              ) +
                                              "月/" +
                                              _vm._s(_vm.wearingPartsVo[0].km) +
                                              "公里)"
                                          ),
                                        ]),
                                      ]),
                                      _c("p", { staticClass: "tips" }, [
                                        _vm._v(
                                          _vm._s(_vm.wearingPartsVo[0].content)
                                        ),
                                      ]),
                                    ]),
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c("div", { staticStyle: { "margin-left": "24px" } }, [
                      _c("i", {
                        class: [
                          "bill-state",
                          "iconfont",
                          "blue",
                          _vm.$lodash.get(
                            _vm.carBillStateIconDict[_vm.billMessage.billState],
                            "label",
                            ""
                          ),
                        ],
                        style: {
                          color: _vm.$lodash.get(
                            _vm.carBillStateIconDict[_vm.billMessage.billState],
                            "color",
                            ""
                          ),
                        },
                      }),
                      [1, 2, 4, 5].includes(_vm.billMessage.billState)
                        ? _c("i", {
                            class: {
                              "bill-state": true,
                              newicon: true,
                              "grow-a-icon_yitiche3x":
                                !!_vm.billMessage.takeTime,
                              "grow-a-icon_weijiezhang3x":
                                !_vm.billMessage.takeTime,
                            },
                            style: {
                              "margin-left": "10px",
                              color: _vm.billMessage.takeTime
                                ? "#33ab79"
                                : "#84878c",
                            },
                          })
                        : _vm._e(),
                    ]),
                    _c("p", { staticClass: "car-remark" }, [
                      _vm._v(_vm._s(_vm.carInfo.carRemark)),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "flex-y-center" },
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            width: "800",
                            placement: "top-end",
                            trigger: "hover",
                          },
                        },
                        [
                          _c(
                            "template",
                            { slot: "reference" },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "process-btn",
                                  attrs: { type: "primary", plain: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.historicalDrawerVisibility = true
                                    },
                                  },
                                },
                                [_vm._v("流程详情")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "bill-process flex-y-center" },
                            [
                              _c("div", { staticClass: "state-name" }, [
                                _c("span", [_vm._v("接待开单")]),
                                _c("span", { staticClass: "time" }, [
                                  _vm._v(_vm._s(_vm.billMessage.dateCreated)),
                                ]),
                              ]),
                              _c("div", { staticClass: "split-string" }, [
                                _c("div", { staticClass: "top-text" }, [
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.billMessage.planFinishTime,
                                          expression:
                                            "billMessage.planFinishTime",
                                        },
                                      ],
                                    },
                                    [
                                      _vm._v(
                                        " 预交车: " +
                                          _vm._s(
                                            _vm.billMessage.planFinishTime
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.billMessage.waitInStore,
                                          expression: "billMessage.waitInStore",
                                        },
                                      ],
                                    },
                                    [_vm._v("在店等")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.billMessage.undetermined,
                                          expression:
                                            "billMessage.undetermined",
                                        },
                                      ],
                                    },
                                    [_vm._v("待定")]
                                  ),
                                ]),
                                _c("div", { staticClass: "line" }),
                                _c(
                                  "div",
                                  { staticClass: "bottom-text orange" },
                                  [
                                    _vm.billMessage.planFinishTime &&
                                    _vm.billMessage.billState == 0
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.distancePlannedTime)
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "state-name" }, [
                                _c("div", { staticClass: "top-text" }),
                                _c("span", [_vm._v("质检交车")]),
                                _c("span", { staticClass: "time" }),
                              ]),
                              _c("div", { staticClass: "split-string" }, [
                                _c("div", {
                                  staticClass: "top-text plan-time",
                                }),
                                _c("div", { staticClass: "line" }),
                                _c("div", {
                                  staticClass: "bottom-text orange",
                                }),
                              ]),
                              _c("div", { staticClass: "state-name" }, [
                                _c("div", { staticClass: "top-text" }),
                                _c("span", [_vm._v("提交结账")]),
                                _c("span", { staticClass: "time" }),
                              ]),
                              _c("div", { staticClass: "split-string" }, [
                                _c("div", { staticClass: "top-text orange" }, [
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.billMessage.takeTime,
                                          expression: "billMessage.takeTime",
                                        },
                                      ],
                                    },
                                    [_vm._v(" 客户已提车")]
                                  ),
                                ]),
                                _c("div", { staticClass: "line" }),
                                _c(
                                  "div",
                                  { staticClass: "bottom-text orange" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.billMessage.takeTime) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "state-name" }, [
                                _c("span", [_vm._v("车单结账")]),
                                _vm.billMessage.checkDate
                                  ? _c("span", { staticClass: "time" }, [
                                      _vm._v(_vm._s(_vm.billMessage.checkDate)),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "bill-info-form",
              attrs: { id: "uq-car-bill-info-form" },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "label" }, [_vm._v("接车员：")]),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.billMessage.pickUpUserName)),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "label" }, [_vm._v("进店时间：")]),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.billMessage.dateCreated)),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "label" }, [_vm._v("单据号：")]),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.billMessage.billCode)),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "label" }, [
                      _vm._v("预交车时间："),
                    ]),
                    _c("div", { staticClass: "value" }, [
                      _vm.billMessage.planFinishTime
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.billMessage.planFinishTime)),
                          ])
                        : _vm._e(),
                      _vm.billMessage.waitInStore
                        ? _c("span", [_vm._v("在店等")])
                        : _vm._e(),
                      _vm.billMessage.undetermined
                        ? _c("span", [_vm._v("待定")])
                        : _vm._e(),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "label" }, [_vm._v("送修人：")]),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.billMessage.customerName) +
                          " " +
                          _vm._s(_vm.billMessage.customerPhone) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "label" }, [_vm._v("进店里程：")]),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.billMessage.mileage
                              ? _vm.billMessage.mileage + " KM"
                              : ""
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "label" }, [_vm._v("消费客户：")]),
                    _c("div", { staticClass: "value" }, [
                      _vm.billMessage.billType == 2
                        ? _c(
                            "span",
                            [
                              [0, 1].includes(_vm.otherData.isInsuranceTurnSelf)
                                ? [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.otherData.beTurnConsumer) +
                                        " "
                                    ),
                                    _vm.settlementWay &&
                                    ![1, 3].includes(_vm.billMessage.billState)
                                      ? _c(
                                          "span",
                                          {
                                            style: {
                                              margin: "0 5px",
                                              color:
                                                _vm.settlementWay === "现结"
                                                  ? "#33ab79"
                                                  : "red",
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.settlementWay))]
                                        )
                                      : _vm._e(),
                                    _c("el-tag", { attrs: { size: "mini" } }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.otherData.isInsuranceTurnSelf
                                            ? "转自费"
                                            : "免赔"
                                        )
                                      ),
                                    ]),
                                  ]
                                : [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.carBillInsuranceInfo
                                            .claimsInsuranceCompanyName
                                        ) +
                                        " "
                                    ),
                                  ],
                            ],
                            2
                          )
                        : _vm.billMessage.billType == 3
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.carBillUndertakeInfo
                                  .undertakeInsuranceCompanyName
                              )
                            ),
                          ])
                        : _c("span", [
                            _c("span", [
                              _vm._v(_vm._s(_vm.payerInfo.consumerName)),
                            ]),
                            _vm.settlementWay
                              ? _c(
                                  "span",
                                  {
                                    style: {
                                      "margin-left": "5px",
                                      color:
                                        _vm.settlementWay === "现结"
                                          ? "#33ab79"
                                          : "red",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.settlementWay))]
                                )
                              : _vm._e(),
                            _vm.otherData.isOthersSelfPayState
                              ? _c("span", { staticClass: "border-tag" }, [
                                  _vm._v("他人自费"),
                                ])
                              : _vm._e(),
                          ]),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "label" }, [_vm._v("开票主体：")]),
                    _c("div", { staticClass: "value" }, [
                      [0, 1, undefined, null].includes(_vm.billMessage.billType)
                        ? _c("span", [_vm._v(_vm._s(_vm.billMessage.invoice))])
                        : _vm._e(),
                      _vm.billMessage.billType == 2
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.carBillInsuranceInfo.insuranceCompanyName
                              )
                            ),
                          ])
                        : _vm._e(),
                      _vm.billMessage.billType == 3
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.carBillUndertakeInfo.undertakeCompanyName
                              )
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "label" }, [_vm._v("操作门店：")]),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(
                        " " + _vm._s(_vm.otherData.operateCompanyName) + " "
                      ),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "label" }, [_vm._v("施工类型：")]),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("dict")(
                              _vm.billMessage.difficulty,
                              "difficulty"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "label" }, [_vm._v("业务来源：")]),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.billMessage.billSourceTypeName)),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "label" }, [_vm._v("环检图片：")]),
                    _c(
                      "div",
                      { staticClass: "value" },
                      [
                        !_vm.billImgs.length
                          ? _c("span", { staticClass: "color999" }, [
                              _vm._v("暂无附件图片"),
                            ])
                          : _c(
                              "el-badge",
                              { attrs: { value: _vm.billImgs.length } },
                              [
                                _c("img", {
                                  staticClass: "thumbnail-img",
                                  attrs: { src: _vm.billImgs[0], alt: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.previewImgs(
                                        _vm.billImgs,
                                        _vm.billImgsTitleArr
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm.billMessage.billType == 2
                ? _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("保险公司："),
                        ]),
                        _c("div", { staticClass: "value" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.carBillInsuranceInfo
                                  .claimsInsuranceCompanyName
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("报案号："),
                        ]),
                        _c("div", { staticClass: "value" }, [
                          _c(
                            "span",
                            {
                              staticClass: "pointer",
                              staticStyle: { "text-decoration": "underline" },
                              on: { click: _vm.carlossevent },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.carBillInsuranceInfo.caseNum) +
                                  " "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("事故责任："),
                        ]),
                        _c("div", { staticClass: "value" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("dict")(
                                  _vm.carBillInsuranceInfo.dutyType,
                                  "dutyType"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("理赔图片："),
                        ]),
                        _c(
                          "div",
                          { staticClass: "value" },
                          [
                            !_vm.insuranceImgs.length
                              ? _c("span", { staticClass: "color999" }, [
                                  _vm._v("暂无附件图片"),
                                ])
                              : _c(
                                  "el-badge",
                                  {
                                    attrs: { value: _vm.insuranceImgs.length },
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "thumbnail-img",
                                      attrs: {
                                        src: _vm.insuranceImgs[0],
                                        alt: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.previewImgs(
                                            _vm.insuranceImgs,
                                            _vm.insuranceImgTitles
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.billMessage.billType == 2
                ? _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("定损状态："),
                        ]),
                        _c("div", { staticClass: "value" }, [
                          _c(
                            "p",
                            [
                              _vm.carBillInsuranceInfo
                                .lossDeterminationState === 2 &&
                              _vm.carBillInsuranceInfo.investigationState === 2
                                ? [_vm._v("待复勘")]
                                : _vm.carBillInsuranceInfo
                                    .lossDeterminationState === 2 &&
                                  _vm.carBillInsuranceInfo.recoveryState === 2
                                ? [_vm._v("待收旧")]
                                : [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("dict")(
                                            _vm.carBillInsuranceInfo
                                              .lossDeterminationState,
                                            "lossDeterminationState"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ],
                            ],
                            2
                          ),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("定损金额："),
                        ]),
                        _c(
                          "div",
                          { staticClass: "value" },
                          [
                            _vm.carBillInsuranceInfo.findLossAmount !==
                            undefined
                              ? [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("toThousands")(
                                          _vm.carBillInsuranceInfo
                                            .findLossAmount
                                        )
                                      ) +
                                      " 元 "
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c("div", { staticClass: "label" }),
                        _c("div", { staticClass: "value" }),
                      ]),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c("div", { staticClass: "label" }),
                        _c("div", { staticClass: "value" }),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.billMessage.billType == 3
                ? _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("三包厂家："),
                        ]),
                        _c("div", { staticClass: "value" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.carBillUndertakeInfo
                                  .undertakeInsuranceCompanyName
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 18 } }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("索赔单号："),
                        ]),
                        _c(
                          "div",
                          { staticClass: "value" },
                          _vm._l(
                            _vm.carBillUndertakeInfo.claimsNum,
                            function (item, index) {
                              return _c(
                                "span",
                                {
                                  key: index,
                                  staticClass: "pointer",
                                  staticStyle: {
                                    "margin-left": "5px",
                                    "text-decoration": "underline",
                                  },
                                },
                                [_vm._v(" " + _vm._s(item) + " ")]
                              )
                            }
                          ),
                          0
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.billMessage.doubleSysInputState !== null &&
              _vm.billMessage.doubleSysInputState !== undefined &&
              [1, 2].includes(_vm.billMessage.doubleSysInputState)
                ? _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("双系统录单："),
                        ]),
                        _c("div", { staticClass: "value" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.billMessage.doubleSysInputState === 1
                                  ? "待录单"
                                  : "已录单"
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 18 } }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("双系统单号："),
                        ]),
                        _c("div", { staticClass: "value" }, [
                          _vm._v(
                            " " + _vm._s(_vm.billMessage.doubleSysNum) + " "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _vm.billMessage.billType == 2
                    ? _c("el-col", { attrs: { span: 6 } }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("推修对象："),
                        ]),
                        _c("div", { staticClass: "value" }, [
                          _vm._v(_vm._s(_vm.otherData.pushClient)),
                        ]),
                      ])
                    : _vm._e(),
                  _c(
                    "el-col",
                    { attrs: { span: _vm.billMessage.billType == 2 ? 6 : 12 } },
                    [
                      _c("div", { staticClass: "label" }, [
                        _vm._v("车主要求："),
                      ]),
                      _c("div", { staticClass: "value" }, [
                        _vm._v(_vm._s(_vm.billMessage.remark)),
                      ]),
                    ]
                  ),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "label" }, [
                      _vm._v("初诊与备注："),
                    ]),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.billMessage.firstVisitRemark)),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "label" }, [_vm._v("车单备注：")]),
                    _c(
                      "div",
                      { staticClass: "value" },
                      [
                        _vm.otherData.isClaimUnderWarranty === 1
                          ? _c(
                              "el-tag",
                              {
                                staticClass: "car-bill-tag",
                                attrs: { size: "small" },
                              },
                              [_vm._v("在保")]
                            )
                          : _vm._e(),
                        _vm.otherData.isClaimUnderWarranty === 0
                          ? _c(
                              "el-tag",
                              {
                                staticClass: "car-bill-tag",
                                attrs: { size: "small" },
                              },
                              [_vm._v("不在保")]
                            )
                          : _vm._e(),
                        _vm.otherData.isClaimActivity === 1
                          ? _c(
                              "el-tag",
                              {
                                staticClass: "car-bill-tag",
                                attrs: { size: "small" },
                              },
                              [_vm._v("有活动")]
                            )
                          : _vm._e(),
                        _vm.otherData.isClaimActivity === 0
                          ? _c(
                              "el-tag",
                              {
                                staticClass: "car-bill-tag",
                                attrs: { size: "small" },
                              },
                              [_vm._v("无活动")]
                            )
                          : _vm._e(),
                        _vm.billMessage.isReturnCar
                          ? _c(
                              "el-tag",
                              {
                                staticClass: "car-bill-tag",
                                attrs: { size: "small" },
                              },
                              [_vm._v("退车整备")]
                            )
                          : _vm._e(),
                        _vm.billMessage.isRepair
                          ? _c(
                              "el-tag",
                              {
                                staticClass: "car-bill-tag",
                                attrs: { size: "small" },
                              },
                              [_vm._v("返修")]
                            )
                          : _vm._e(),
                        _vm.billMessage.isSupplementaryOrder
                          ? _c(
                              "el-tag",
                              {
                                staticClass: "car-bill-tag",
                                attrs: { size: "small" },
                              },
                              [_vm._v("补单")]
                            )
                          : _vm._e(),
                        _vm.billMessage.isClaimSettlToSelfPayment === 1
                          ? _c(
                              "el-tag",
                              {
                                staticClass: "car-bill-tag",
                                attrs: { size: "small" },
                              },
                              [_vm._v("理赔转自费")]
                            )
                          : _vm._e(),
                        _vm.billMessage.isClaimSettlFullLoss === 1
                          ? _c(
                              "el-tag",
                              {
                                staticClass: "car-bill-tag",
                                attrs: { size: "small" },
                              },
                              [_vm._v("全损")]
                            )
                          : _vm._e(),
                        _vm.billMessage.isClaimExternalService === 1
                          ? _c(
                              "el-tag",
                              {
                                staticClass: "car-bill-tag",
                                attrs: { size: "small" },
                              },
                              [_vm._v("外服")]
                            )
                          : _vm._e(),
                        _vm.billMessage.doubleSysInputState === 1
                          ? _c(
                              "el-tag",
                              {
                                staticClass: "car-bill-tag",
                                attrs: { size: "small" },
                              },
                              [_vm._v("双系统待录单")]
                            )
                          : _vm._e(),
                        _vm.billMessage.doubleSysInputState === 2
                          ? _c(
                              "el-tag",
                              {
                                staticClass: "car-bill-tag",
                                attrs: { size: "small" },
                              },
                              [_vm._v("双系统已录单")]
                            )
                          : _vm._e(),
                        _vm._v(
                          " " + _vm._s(_vm.billMessage.companyRemark) + " "
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "btns flex-x-between",
              attrs: { id: "uq-car-bill-services-header" },
            },
            [
              _c("div", { staticClass: "flex-y-end" }, [
                _c("div", { staticClass: "bao-btn" }, [_vm._v("报价项目")]),
                _c(
                  "div",
                  {
                    staticClass: "flex-y-end",
                    staticStyle: { "margin-bottom": "4px" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "border-button material-button",
                        on: {
                          click: function ($event) {
                            _vm.materialBillDrawerVisibility = true
                          },
                        },
                      },
                      [
                        _c("p", { staticClass: "pointer" }, [
                          _vm._v("材料领用状态"),
                        ]),
                        _c("div", [
                          _c("span", [
                            _vm._v(
                              " 待领：" +
                                _vm._s(
                                  _vm.otherData.materialBillUnProcessedNum || 0
                                ) +
                                ", "
                            ),
                          ]),
                          _c("span", [
                            _vm._v(
                              " 已领：" +
                                _vm._s(
                                  _vm.otherData.materialBillProcessedNum || 0
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "border-button material-button",
                        on: {
                          click: function ($event) {
                            _vm.inspectCarDrawerVisibility = true
                          },
                        },
                      },
                      [
                        _c("p", { staticClass: "pointer" }, [_vm._v("检车单")]),
                        _c("div", [
                          _c("span", [_vm._v(_vm._s(_vm.carDisposeStateNum))]),
                        ]),
                      ]
                    ),
                    [0].includes(_vm.billMessage.billState)
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "auth",
                                rawName: "v-auth",
                                value: 507,
                                expression: "507",
                              },
                            ],
                            staticClass: "border-button",
                            on: { click: _vm.copyVal },
                          },
                          [
                            _c("p", { staticClass: "pointer" }, [
                              _vm._v("审核报价"),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm.billMessage.billState == 0
                      ? [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "auth",
                                  rawName: "v-auth",
                                  value: 470,
                                  expression: "470",
                                },
                              ],
                              staticClass: "border-button",
                              on: { click: _vm.submitConstruction },
                            },
                            [_vm._v(" 提交施工 ")]
                          ),
                        ]
                      : _vm._e(),
                    _vm.goodsException &&
                    [1, 2, 5].includes(_vm.billMessage.billState)
                      ? [
                          _c(
                            "div",
                            {
                              staticClass: "border-button orange-btn",
                              on: {
                                click: function ($event) {
                                  _vm.goodsExceptionDialogVisibility = true
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-warning-outline orange",
                                staticStyle: { "margin-right": "5px" },
                              }),
                              _vm._v(" 温馨提示 "),
                            ]
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
            ]
          ),
          _c(
            "el-table",
            {
              ref: "servicesTable",
              staticClass: "service-table",
              staticStyle: { width: "100%" },
              attrs: {
                "max-height": _vm.servicesTableMaxHeight,
                border: "",
                data: _vm.serviceTableData,
                "default-expand-all": "",
                "row-key": "id",
                "tree-props": {
                  children: "billGoods",
                  hasChildren: "hasChildren",
                },
                "span-method": _vm.spanMethod,
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "项目名称", "min-width": "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.isAgreePrice
                          ? _c(
                              "el-tag",
                              { attrs: { type: "info", size: "mini" } },
                              [_vm._v("协议")]
                            )
                          : _vm._e(),
                        row.name
                          ? _c(
                              "span",
                              { class: { orange: row.emptyService } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      `${row.title}${row.title ? "." : ""}`
                                    ) +
                                    _vm._s(row.name)
                                ),
                              ]
                            )
                          : _vm._e(),
                        row.lastUseDate
                          ? _c("p", { staticClass: "red" }, [
                              _vm._v(
                                " 最近服务: " + _vm._s(row.lastUseDate) + " "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "code", label: "项目编码", "min-width": "100" },
              }),
              _c("el-table-column", {
                attrs: { prop: "price", label: "单价", width: "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", { key: _vm.tableKey + 1 }, [
                          _vm._v(
                            " " + _vm._s(_vm._f("toThousands")(row.price)) + " "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "赠送",
                  "min-width": "100",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              effect: "light",
                              placement: "bottom-start",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "#666",
                                  "font-size": "13px",
                                  "line-height": "20px",
                                },
                                attrs: { slot: "content" },
                                slot: "content",
                              },
                              [
                                _c("div", { staticClass: "flex" }, [
                                  _vm._v(
                                    " 满足足够条件，附赠的服务(可以是项目或者商品配件) "
                                  ),
                                ]),
                              ]
                            ),
                            _c("i", {
                              staticClass: "iconfont grow-icon_doubt",
                            }),
                          ]
                        ),
                        _vm._v(" 赠送 "),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.isAgreePrice
                          ? [
                              row.agreementFree
                                ? _c(
                                    "el-tag",
                                    { attrs: { type: "info", size: "mini" } },
                                    [_vm._v("赠送")]
                                  )
                                : _vm._e(),
                              _vm._v(
                                " " + _vm._s(row.agreementFreeRemark) + " "
                              ),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "折旧费", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              effect: "light",
                              placement: "bottom-start",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "#666",
                                  "font-size": "13px",
                                  "line-height": "20px",
                                },
                                attrs: { slot: "content" },
                                slot: "content",
                              },
                              [
                                _c("div", { staticClass: "flex" }, [
                                  _vm._v("配件换新等抵扣"),
                                ]),
                              ]
                            ),
                            _c("i", {
                              staticClass: "iconfont grow-icon_doubt",
                            }),
                          ]
                        ),
                        _vm._v(" 折旧费 "),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.isDepreciation
                          ? [
                              row.isDepreciation
                                ? _c(
                                    "el-tag",
                                    { attrs: { type: "info", size: "mini" } },
                                    [_vm._v("折旧")]
                                  )
                                : _vm._e(),
                              _c("span", [
                                _vm._v(
                                  "¥" +
                                    _vm._s(
                                      _vm._f("toThousands")(
                                        row.depreciationCharge
                                      )
                                    )
                                ),
                              ]),
                              _c("hr"),
                              _vm._v(
                                " " + _vm._s(row.depreciationRemark) + " "
                              ),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "discount", label: "折扣", width: "60" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", { key: _vm.tableKey + 2 }, [
                          _vm._v(" " + _vm._s(row.discount || "无") + " "),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "date", label: "数量", width: "70" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", { key: _vm.tableKey + 3 }, [
                          row.showAllNum
                            ? _c("span", { staticClass: "orange" }, [
                                _c("p", [_vm._v("报价" + _vm._s(row.num))]),
                                row.waitPickNum
                                  ? _c("p", [
                                      _vm._v("待领" + _vm._s(row.waitPickNum)),
                                    ])
                                  : _vm._e(),
                                row.pickedNum
                                  ? _c("p", [
                                      _vm._v("已领" + _vm._s(row.pickedNum)),
                                    ])
                                  : _vm._e(),
                              ])
                            : _c("span", [_vm._v(_vm._s(row.num))]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "date", label: "小计", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("div", [
                          _c("span", { key: _vm.tableKey + 4 }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm._f("toThousands")(row.total)) +
                                " "
                            ),
                          ]),
                          _vm.otherData.paid != _vm.otherData.total
                            ? _c("div", [
                                _c("span", [_vm._v("整单折扣后: ")]),
                                _c("span", [
                                  _vm._v(
                                    "¥" +
                                      _vm._s(
                                        _vm._f("toThousands")(row.billDiscount)
                                      )
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "项目合计", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm.otherData.paid == _vm.otherData.total
                          ? _c("div", [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("toThousands")(row.itemTotalBefore)
                                  )
                                ),
                              ]),
                            ])
                          : _c("div", [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("toThousands")(row.itemTotalAfter)
                                  )
                                ),
                              ]),
                              _c("del", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("toThousands")(row.itemTotalBefore)
                                  )
                                ),
                              ]),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { width: "84" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "flex-y-center" }, [
                          _c("span", [_vm._v("成本小计")]),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.goodsId
                          ? _c("span", [
                              _vm.showCost
                                ? _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "auth",
                                          rawName: "v-auth",
                                          value: {
                                            code: 169,
                                            handle: "dataMarking",
                                          },
                                          expression:
                                            "{\n                code: 169,\n                handle: 'dataMarking',\n              }",
                                        },
                                      ],
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("toThousands")(
                                            row.totalCostPrice
                                          )
                                        ) + " "
                                      ),
                                    ]
                                  )
                                : _c("span", [_vm._v("****")]),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { width: "94", label: "项目毛利" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm.showCost
                          ? _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "auth",
                                    rawName: "v-auth",
                                    value: { code: 169, handle: "dataMarking" },
                                    expression:
                                      "{ code: 169, handle: 'dataMarking' }",
                                  },
                                ],
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("toThousands")(
                                        row.itemsGrossProfit
                                      )
                                    )
                                ),
                              ]
                            )
                          : _c("span", [_vm._v("****")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "施工技师", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("div", { key: _vm.tableKey + 5 }, [
                          _vm.$lodash.get(
                            row,
                            "technicianInfoVos.length",
                            false
                          )
                            ? _c("div", [
                                _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$lodash
                                          .get(row, "technicianInfoVos", [])
                                          .map((item) => item.technicianName)
                                          .join("、")
                                      ) +
                                      " "
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                        ![1, 2].includes(_vm.billMessage.billState)
                          ? _c("div", { staticClass: "orange" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("dict")(
                                      row.dispatchTaskState,
                                      "dispatchTaskState"
                                    )
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "故障 | 完工图", width: "110" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "flex-y-center",
                            staticStyle: { padding: "10px 0" },
                          },
                          [
                            !_vm.$lodash.get(
                              row,
                              "preConstructionPics.length",
                              0
                            )
                              ? _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      width: "32px",
                                      "text-align": "center",
                                    },
                                  },
                                  [_vm._v("无")]
                                )
                              : _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "top",
                                      trigger: "hover",
                                    },
                                  },
                                  [
                                    _c("upload-file-list", {
                                      attrs: {
                                        disabled: "",
                                        value: row.preImages,
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "video-list" },
                                      _vm._l(
                                        row.preVideos,
                                        function (src, index) {
                                          return _c(
                                            "video",
                                            {
                                              key: index,
                                              staticClass: "thumbnail-video",
                                              attrs: {
                                                preload: "auto",
                                                src: src,
                                                poster: _vm.playImg,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleClickVideo(
                                                    $event,
                                                    row.preVideos,
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("source", {
                                                attrs: {
                                                  src: src,
                                                  type: "video/mp4",
                                                },
                                              }),
                                              _vm._v(
                                                " 您的设备不支持 video 标签。 "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                    _c(
                                      "template",
                                      { slot: "reference" },
                                      [
                                        _c(
                                          "el-badge",
                                          {
                                            staticStyle: {
                                              "margin-right": "8px",
                                            },
                                            attrs: {
                                              value:
                                                row.preConstructionPics.length,
                                            },
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "thumbnail-img",
                                              attrs: { src: row.prePoster },
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  2
                                ),
                            _c("div", {
                              staticStyle: {
                                width: "2px",
                                height: "32px",
                                margin: "0 8px",
                                background: "#ebeef5",
                              },
                            }),
                            !_vm.$lodash.get(
                              row,
                              "afterConstructionPics.length",
                              0
                            )
                              ? _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      width: "32px",
                                      "text-align": "center",
                                    },
                                  },
                                  [_vm._v("无")]
                                )
                              : _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "top",
                                      trigger: "hover",
                                    },
                                  },
                                  [
                                    _c("upload-file-list", {
                                      attrs: {
                                        disabled: "",
                                        value: row.afterImages,
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "video-list" },
                                      _vm._l(
                                        row.afterVideos,
                                        function (src, index) {
                                          return _c(
                                            "video",
                                            {
                                              key: index,
                                              staticClass: "thumbnail-video",
                                              attrs: {
                                                preload: "auto",
                                                src: src,
                                                poster: _vm.playImg,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleClickVideo(
                                                    $event,
                                                    row.afterVideos,
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("source", {
                                                attrs: {
                                                  src: src,
                                                  type: "video/mp4",
                                                },
                                              }),
                                              _vm._v(
                                                " 您的设备不支持 video 标签。 "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                    _c(
                                      "template",
                                      { slot: "reference" },
                                      [
                                        _c(
                                          "el-badge",
                                          {
                                            staticStyle: {
                                              "margin-right": "8px",
                                            },
                                            attrs: {
                                              value:
                                                row.afterConstructionPics
                                                  .length,
                                            },
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "thumbnail-img",
                                              attrs: { src: row.afterPoster },
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  2
                                ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("template", { slot: "empty" }, [
                _c(
                  "div",
                  { staticStyle: { "border-bottom": "1px solid #ebeef5" } },
                  [_vm._v("未添加服务")]
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          { staticClass: "footers", attrs: { id: "uq-car-bill-footer" } },
          [
            _c(
              "div",
              {
                staticClass: "flex-col",
                staticStyle: {
                  "justify-content": "center",
                  "padding-left": "30px",
                },
              },
              [
                _c("div", { staticClass: "bill-price-box" }, [
                  _vm._v(" 单据总额： "),
                  _c("span", { staticClass: "rmb" }, [_vm._v("¥")]),
                  _c("span", [
                    _c("span", [
                      _c("span", { staticClass: "price-number" }, [
                        _vm._v(
                          _vm._s(_vm._f("toThousands")(_vm.otherData.paid))
                        ),
                      ]),
                      _vm.otherData.paid != _vm.otherData.total
                        ? _c("del", { staticClass: "original-price" }, [
                            _vm._v(
                              "¥" +
                                _vm._s(
                                  _vm._f("toThousands")(_vm.otherData.total)
                                )
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _vm.billMessage.billType == 1 &&
                  _vm.billMessage.billState == 1
                    ? _c(
                        "span",
                        [
                          _c(
                            "el-tooltip",
                            { attrs: { effect: "light", placement: "top" } },
                            [
                              _c("template", { slot: "content" }, [
                                _vm._v(
                                  " 包干金额,结账当月显示为查询时点的预分摊金额,结账次月显示为实际分摊金额 "
                                ),
                              ]),
                              _c("i", {
                                staticClass:
                                  "el-tooltip iconfont grow-icon_doubt color666",
                                staticStyle: { "margin-left": "11px" },
                              }),
                            ],
                            2
                          ),
                          _vm._v(" 包干金额: "),
                          _c("span", [
                            _c("span", { staticClass: "rmb" }, [_vm._v("¥")]),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "auth",
                                    rawName: "v-auth",
                                    value: { code: 473, handle: "dataMarking" },
                                    expression:
                                      "{ code: 473, handle: 'dataMarking' }",
                                  },
                                ],
                                staticClass: "price-number",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("toThousands")(
                                        _vm.otherData.contractAmount
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("span", { staticStyle: { "margin-left": "15px" } }, [
                    _vm._v("成本合计： "),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "auth",
                            rawName: "v-auth",
                            value: { code: 169, handle: "dataMarking" },
                            expression: "{ code: 169, handle: 'dataMarking' }",
                          },
                        ],
                      },
                      [
                        _vm.showCost
                          ? _c("span", [
                              _vm._v(
                                "¥ " +
                                  _vm._s(
                                    _vm._f("toThousands")(
                                      _vm.otherData.finalTotalCostPrice
                                    )
                                  )
                              ),
                            ])
                          : _c("span", [_vm._v("****")]),
                      ]
                    ),
                    _vm._v(" 单据毛利： "),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "auth",
                            rawName: "v-auth",
                            value: { code: 169, handle: "dataMarking" },
                            expression: "{ code: 169, handle: 'dataMarking' }",
                          },
                        ],
                      },
                      [
                        _vm.showCost
                          ? _c("span", [
                              _vm._v(
                                "¥ " +
                                  _vm._s(
                                    _vm._f("toThousands")(
                                      _vm.otherData.billGrossProfit
                                    )
                                  )
                              ),
                            ])
                          : _c("span", [_vm._v("****")]),
                      ]
                    ),
                  ]),
                  _c("span", [
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showCost,
                          expression: "showCost",
                        },
                        {
                          name: "auth",
                          rawName: "v-auth",
                          value: 169,
                          expression: "169",
                        },
                      ],
                      staticClass:
                        "pointer iconfont grow-yanjing_xianshi_o el-icon--right",
                      staticStyle: { "font-size": "24px" },
                      on: { click: _vm.changeShowCostState },
                    }),
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.showCost,
                          expression: "!showCost",
                        },
                        {
                          name: "auth",
                          rawName: "v-auth",
                          value: 169,
                          expression: "169",
                        },
                      ],
                      staticClass:
                        "pointer iconfont grow-yanjing_yincang_o el-icon--right",
                      staticStyle: { "font-size": "24px" },
                      on: { click: _vm.changeShowCostState },
                    }),
                  ]),
                ]),
                _c("p", { staticClass: "price-detail" }, [
                  _vm._v(
                    " = 服务：¥" +
                      _vm._s(_vm._f("toThousands")(_vm.serviceTotalPrice)) +
                      " + 商品：¥" +
                      _vm._s(_vm._f("toThousands")(_vm.goodsTotalPrice)) +
                      " "
                  ),
                  _vm.otherData.total != _vm.otherData.paid
                    ? _c("span", [
                        _vm._v(
                          " - 整单折扣优惠：¥" +
                            _vm._s(
                              _vm._f("toThousands")(
                                _vm.otherData.total - _vm.otherData.paid
                              )
                            )
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]
            ),
            _vm.dataLoaded
              ? _c(
                  "div",
                  { staticClass: "buttons" },
                  [
                    _vm.claimVo !== undefined &&
                    _vm.claimVo.claimAmount !== undefined
                      ? [
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "top-end",
                                title: "认领明细",
                                width: "873",
                                trigger: "hover",
                              },
                            },
                            [
                              _c(
                                "el-table",
                                {
                                  attrs: {
                                    border: "",
                                    data: _vm.carBillClaimDetailList,
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      width: "200",
                                      property: "invoicingEntity",
                                      label: "回款主体",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      width: "160",
                                      property: "receivingDatetime",
                                      label: "回款时间",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      width: "90",
                                      property: "claimAmount",
                                      label: "认领金额",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      width: "160",
                                      property: "claimDateTime",
                                      label: "认领日期",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      width: "100",
                                      property: "claimOperator",
                                      label: "认领人",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      width: "160",
                                      property: "paymentDateTime",
                                      label: "付款时间",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "price-number",
                                  staticStyle: {
                                    "font-size": "18px",
                                    color: "#ee8f33",
                                    "margin-right": "30px",
                                  },
                                  attrs: { slot: "reference" },
                                  on: { click: _vm.gotoClaimReportDetail },
                                  slot: "reference",
                                },
                                [
                                  _vm._v("认领金额："),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "rmb",
                                      staticStyle: {
                                        "font-size": "12px",
                                        color: "#ee8f33",
                                      },
                                    },
                                    [_vm._v("¥")]
                                  ),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("toThousands")(
                                          _vm.claimVo.claimAmount
                                        )
                                      )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                    _vm.billMessage.billState == 0
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "auth",
                                    rawName: "v-auth",
                                    value: 4,
                                    expression: "4",
                                  },
                                ],
                                attrs: { type: "primary" },
                                on: { click: _vm.editCarBill },
                              },
                              [_vm._v("编辑车单")]
                            ),
                            _c(
                              "el-dropdown",
                              {
                                staticStyle: { margin: "0px 10px" },
                                attrs: {
                                  trigger: "click",
                                  "split-button": "",
                                  placement: "top",
                                },
                                on: { click: _vm.submitInvoicingEvent },
                              },
                              [
                                _vm._v(" 提交结账 "),
                                _c(
                                  "template",
                                  { slot: "dropdown" },
                                  [
                                    _c(
                                      "el-dropdown-menu",
                                      [
                                        _c(
                                          "el-dropdown-item",
                                          {
                                            directives: [
                                              {
                                                name: "auth",
                                                rawName: "v-auth",
                                                value: 471,
                                                expression: "471",
                                              },
                                            ],
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.pickUpCarDialogEvent.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("挂单")]
                                        ),
                                        _c(
                                          "el-dropdown-item",
                                          {
                                            directives: [
                                              {
                                                name: "auth",
                                                rawName: "v-auth",
                                                value: 215,
                                                expression: "215",
                                              },
                                            ],
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.cancelReceptionDialogEvent.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("取消接待")]
                                        ),
                                        _c(
                                          "el-dropdown-item",
                                          {
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.ChangesCarTypeDialogEvent.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("变更业务类型")]
                                        ),
                                        _c(
                                          "el-dropdown-item",
                                          {
                                            directives: [
                                              {
                                                name: "auth",
                                                rawName: "v-auth",
                                                value: 451,
                                                expression: "451",
                                              },
                                            ],
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.strandedNoteDialogEvent.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("滞留说明")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        )
                      : _vm.billMessage.billState == 2
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "auth",
                                    rawName: "v-auth",
                                    value: 5,
                                    expression: "5",
                                  },
                                ],
                                attrs: { type: "primary" },
                                on: { click: _vm.stayInvoicingDialogEvent },
                              },
                              [_vm._v("结账")]
                            ),
                            _c(
                              "el-dropdown",
                              {
                                staticStyle: { margin: "0px 10px" },
                                attrs: { trigger: "click", placement: "top" },
                              },
                              [
                                _c("el-button", [
                                  _vm._v(" 更多操作"),
                                  _c("i", {
                                    staticClass:
                                      "el-icon-arrow-down el-icon--right",
                                  }),
                                ]),
                                _c(
                                  "el-dropdown-menu",
                                  {
                                    attrs: { slot: "dropdown" },
                                    slot: "dropdown",
                                  },
                                  [
                                    _vm.billMessage.takeTime
                                      ? [
                                          _c(
                                            "el-dropdown-item",
                                            {
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.handleRevocationTakeCar.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("撤销提车")]
                                          ),
                                        ]
                                      : [
                                          _c(
                                            "el-dropdown-item",
                                            {
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.handleTakeCar.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("提车")]
                                          ),
                                        ],
                                    _c(
                                      "el-dropdown-item",
                                      {
                                        directives: [
                                          {
                                            name: "auth",
                                            rawName: "v-auth",
                                            value: 227,
                                            expression: "227",
                                          },
                                        ],
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.handleResetService.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("重新服务")]
                                    ),
                                    _c(
                                      "el-dropdown-item",
                                      {
                                        directives: [
                                          {
                                            name: "auth",
                                            rawName: "v-auth",
                                            value: 575,
                                            expression: "575",
                                          },
                                        ],
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.carBillLock(
                                              "editCarBillDialogVisibility"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("编辑车单")]
                                    ),
                                    _c(
                                      "el-dropdown-item",
                                      {
                                        directives: [
                                          {
                                            name: "auth",
                                            rawName: "v-auth",
                                            value: 451,
                                            expression: "451",
                                          },
                                        ],
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.strandedNoteDialogEvent.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("滞留说明")]
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm.billMessage.billState == 4 ||
                        _vm.billMessage.billState == 5
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-right": "20px" },
                                attrs: { type: "primary" },
                                on: {
                                  click:
                                    _vm.CancellationAreCancelledDialogEvent,
                                },
                              },
                              [_vm._v(" 撤销挂单 ")]
                            ),
                            [1, 6].includes(_vm.billMessage.dispatchState)
                              ? [
                                  _c(
                                    "el-dropdown",
                                    {
                                      staticStyle: { margin: "0px 10px" },
                                      attrs: {
                                        trigger: "click",
                                        "split-button": "",
                                        placement: "top",
                                      },
                                      on: { click: _vm.submitInvoicingEvent },
                                    },
                                    [
                                      _vm._v(" 提交结账 "),
                                      _c(
                                        "el-dropdown-menu",
                                        {
                                          attrs: { slot: "dropdown" },
                                          slot: "dropdown",
                                        },
                                        [
                                          _c(
                                            "el-dropdown-item",
                                            {
                                              directives: [
                                                {
                                                  name: "auth",
                                                  rawName: "v-auth",
                                                  value: 4,
                                                  expression: "4",
                                                },
                                              ],
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.editCarBill.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("编辑车单")]
                                          ),
                                          _c(
                                            "el-dropdown-item",
                                            {
                                              directives: [
                                                {
                                                  name: "auth",
                                                  rawName: "v-auth",
                                                  value: 451,
                                                  expression: "451",
                                                },
                                              ],
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.strandedNoteDialogEvent.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("滞留说明")]
                                          ),
                                          _vm.billMessage.takeTime
                                            ? [
                                                _c(
                                                  "el-dropdown-item",
                                                  {
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        return _vm.handleRevocationTakeCar.apply(
                                                          null,
                                                          arguments
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("撤销提车")]
                                                ),
                                              ]
                                            : [
                                                _c(
                                                  "el-dropdown-item",
                                                  {
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        return _vm.handleTakeCar.apply(
                                                          null,
                                                          arguments
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("提车")]
                                                ),
                                              ],
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              : [
                                  _c(
                                    "el-button",
                                    {
                                      on: {
                                        click: _vm.strandedNoteDialogEvent,
                                      },
                                    },
                                    [_vm._v("滞留说明")]
                                  ),
                                ],
                          ],
                          2
                        )
                      : _vm.billMessage.billState == 1
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-dropdown",
                              {
                                staticStyle: { margin: "0px 10px" },
                                attrs: {
                                  trigger: "click",
                                  "split-button": "",
                                  placement: "top",
                                },
                              },
                              [
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "top-start",
                                      width: "729",
                                      trigger: "hover",
                                    },
                                  },
                                  [
                                    _c("p", { staticClass: "tip-word" }, [
                                      _c("span", [_vm._v("结账收款")]),
                                      _vm.billMessage.billType == 1 &&
                                      _vm.billMessage.billState == 1
                                        ? _c(
                                            "i",
                                            {
                                              staticClass:
                                                "iconfont grow-icon_warn_32 orange",
                                              staticStyle: {
                                                margin: "0px 4px",
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "包干业务，收款金额即为包干金额，结账当月显示为查询时点的预分摊金额，结账次月显示为实际分摊金额"
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                    _c(
                                      "el-table",
                                      {
                                        attrs: {
                                          data: _vm.payTypeTable,
                                          border: "",
                                          "header-cell-style": {
                                            background: "#FAFAFA",
                                            color: "#000000",
                                            fontSize: "14px",
                                            fontWeight: "500",
                                          },
                                        },
                                      },
                                      [
                                        _c("el-table-column", {
                                          attrs: {
                                            width: "60",
                                            label: "序号",
                                            type: "index",
                                          },
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "payType",
                                            label: "支付方式",
                                            "mix-width": "180",
                                          },
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "money",
                                            label: "金额",
                                            "mix-width": "100",
                                          },
                                        }),
                                        _c("template", { slot: "append" }, [
                                          _c(
                                            "div",
                                            { staticClass: "table-append" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "table_footer" },
                                                [
                                                  _c("p", [
                                                    _vm._v(
                                                      "结账收银员：" +
                                                        _vm._s(
                                                          _vm.payInfo.cashier
                                                        )
                                                    ),
                                                  ]),
                                                  _c("p", [
                                                    _vm._v(
                                                      "结账时间：" +
                                                        _vm._s(
                                                          _vm.payInfo.checkDate
                                                        )
                                                    ),
                                                  ]),
                                                  _c("p", [
                                                    _vm._v(
                                                      "收款总额：￥" +
                                                        _vm._s(
                                                          _vm._f("toThousands")(
                                                            _vm.payInfoTotal
                                                          )
                                                        )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "table_footer_deriptt",
                                                },
                                                [
                                                  _vm._v(
                                                    " 结账备注：" +
                                                      _vm._s(
                                                        _vm.payInfo.remark
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ],
                                      2
                                    ),
                                    _vm.hasChargeOffs
                                      ? _c(
                                          "p",
                                          { staticClass: "cancel_word" },
                                          [
                                            _vm._v(" 销账收款 "),
                                            _c(
                                              "span",
                                              { staticClass: "orange" },
                                              [_vm._v("未销账：￥")]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "orange" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("toThousands")(
                                                      _vm.notChargeOffs -
                                                        _vm.chargeOffsAmount
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.hasChargeOffs
                                      ? _c(
                                          "el-table",
                                          {
                                            attrs: {
                                              data: _vm.chargeOffsTable,
                                              border: "",
                                              "header-cell-style": {
                                                background: "#FAFAFA",
                                                color: "#000000",
                                                fontSize: "14px",
                                                fontWeight: "500",
                                              },
                                              "span-method":
                                                _vm.arraySpanMethod,
                                            },
                                          },
                                          [
                                            _c("el-table-column", {
                                              attrs: {
                                                prop: "index",
                                                width: "60",
                                                label: "序号",
                                              },
                                            }),
                                            _c("el-table-column", {
                                              attrs: {
                                                prop: "writeOffNum",
                                                label: "销账收款单号",
                                                "mix-width": "180",
                                              },
                                            }),
                                            _c("el-table-column", {
                                              attrs: {
                                                prop: "repaymentDateTime",
                                                label: "销账时间",
                                                width: "100",
                                              },
                                            }),
                                            _c("el-table-column", {
                                              attrs: {
                                                prop: "cashier",
                                                label: "销账收银员",
                                                "min-width": "100",
                                              },
                                            }),
                                            _c("el-table-column", {
                                              attrs: {
                                                label: "支付方式",
                                                width: "100",
                                                prop: "payType",
                                              },
                                            }),
                                            _c("el-table-column", {
                                              attrs: {
                                                label: "收款金额",
                                                "min-width": "100",
                                                prop: "money",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "reference" },
                                        slot: "reference",
                                      },
                                      [_vm._v("支付明细")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-dropdown-menu",
                                  {
                                    attrs: { slot: "dropdown" },
                                    slot: "dropdown",
                                  },
                                  [
                                    _c(
                                      "el-dropdown-item",
                                      {
                                        directives: [
                                          {
                                            name: "auth",
                                            rawName: "v-auth",
                                            value: 120,
                                            expression: "120",
                                          },
                                        ],
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.reverseSettlementDialogEvent.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("反结算")]
                                    ),
                                    _vm.billMessage.takeTime
                                      ? [
                                          _c(
                                            "el-dropdown-item",
                                            {
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.handleRevocationTakeCar.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("撤销提车")]
                                          ),
                                        ]
                                      : [
                                          _c(
                                            "el-dropdown-item",
                                            {
                                              directives: [
                                                {
                                                  name: "auth",
                                                  rawName: "v-auth",
                                                  value: 451,
                                                  expression: "451",
                                                },
                                              ],
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.strandedNoteDialogEvent.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("滞留说明")]
                                          ),
                                          _c(
                                            "el-dropdown-item",
                                            {
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.handleTakeCar.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("提车")]
                                          ),
                                        ],
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-dropdown",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: {
                          size: "small",
                          trigger: "click",
                          placement: "top",
                        },
                        on: { command: _vm.handlePrint },
                      },
                      [
                        _c("el-button", [
                          _vm._v(" 打印"),
                          _c("i", {
                            staticClass: "el-icon-arrow-down el-icon--right",
                          }),
                        ]),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c(
                              "el-dropdown-item",
                              { attrs: { command: "settlement" } },
                              [_vm._v("结算单")]
                            ),
                            _c(
                              "el-dropdown-item",
                              { attrs: { command: "material" } },
                              [_vm._v("材料单")]
                            ),
                            _c(
                              "el-dropdown-item",
                              { attrs: { command: "dispatch" } },
                              [_vm._v("派工单")]
                            ),
                            _c(
                              "el-dropdown-item",
                              { attrs: { command: "customerConfirm" } },
                              [_vm._v("接待单")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                )
              : _vm._e(),
          ]
        ),
      ]),
      _vm.materialBillDrawerVisibility
        ? _c("material-bill-drawer", {
            attrs: {
              visibility: _vm.materialBillDrawerVisibility,
              materialBillId: _vm.otherData.materialBillId,
              createCompanyId: _vm.otherData.companyId,
              createCompanyName: _vm.otherData.operateCompanyName,
            },
            on: {
              "update:visibility": function ($event) {
                _vm.materialBillDrawerVisibility = $event
              },
            },
          })
        : _vm._e(),
      _vm.inspectCarDrawerVisibility
        ? _c("inspect-car-drawer", {
            attrs: {
              visibility: _vm.inspectCarDrawerVisibility,
              billState: _vm.billMessage.billState,
              billId: _vm.id,
              billType: _vm.billMessage.billType,
              typeStatus: _vm.typeStatus,
            },
            on: {
              "update:visibility": function ($event) {
                _vm.inspectCarDrawerVisibility = $event
              },
            },
          })
        : _vm._e(),
      _vm.carBillSuspendDialogVisibility
        ? _c("car-bill-suspend-dialog", {
            attrs: {
              visibility: _vm.carBillSuspendDialogVisibility,
              billId: _vm.id,
            },
            on: {
              "update:visibility": function ($event) {
                _vm.carBillSuspendDialogVisibility = $event
              },
              save: _vm.getData,
            },
          })
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.cancelReceptionDialog,
            width: "40%",
            top: "15%",
            "before-close": _vm.handleCloses,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.cancelReceptionDialog = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c("p", [
              _c("span", [_vm._v("取消接待")]),
              _c("i", {
                staticClass: "iconfont grow-icon_warn_32 orange",
                staticStyle: { margin: "0px 4px" },
              }),
              _c(
                "span",
                { staticStyle: { "font-size": "14px", color: "#999999" } },
                [
                  _vm._v(
                    "确定取消接待，接车单转为“已取消”状态，且无法恢复重复服务"
                  ),
                ]
              ),
            ]),
          ]),
          _c("el-input", {
            attrs: {
              type: "textarea",
              rows: 4,
              placeholder: "请输入取消接待原因（必填）",
            },
            model: {
              value: _vm.cancelTextarea,
              callback: function ($$v) {
                _vm.cancelTextarea = $$v
              },
              expression: "cancelTextarea",
            },
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.cancelTipsDialogEvent },
                },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.handleCloses } },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.cancelTipsDialog,
            width: "30%",
            top: "15%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.cancelTipsDialog = $event
            },
          },
        },
        [
          _c("p", { staticStyle: { "text-align": "center" } }, [
            _vm._v(" 定损单非待报损状态无法取消，请先撤销至待报损状态 "),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.cancelTipsDialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.jump },
                },
                [_vm._v("跳转")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "温馨提示",
            visible: _vm.submitCarTipsDialog,
            width: "20%",
            top: "15%",
            "before-close": _vm.closeCarTips,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.submitCarTipsDialog = $event
            },
          },
        },
        [
          _c(
            "p",
            {
              staticStyle: { "text-align": "center", "margin-bottom": "10px" },
            },
            [_vm._v(" 确认要提交到车间检车项目？ ")]
          ),
          _c("p", { staticStyle: { "text-align": "center" } }, [
            _c("i", { staticClass: "iconfont grow-icon_warn_32 orange" }, [
              _c(
                "span",
                { staticStyle: { "font-size": "12px", color: "#999999" } },
                [
                  _vm._v(
                    "注：操作成功，有【提交检车】通知推送给有接收权限的员工"
                  ),
                ]
              ),
            ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.closeCarTips },
                },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.closeCarTips } },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.toServiceDialog,
            width: "35%",
            top: "15%",
            "before-close": _vm.handleCloses,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.toServiceDialog = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c("p", [
              _c("span", { staticStyle: { "font-size": "16px" } }, [
                _vm._v("重新服务"),
              ]),
              _c("i", {
                staticClass: "iconfont grow-icon_warn_32 orange",
                staticStyle: { margin: "0px 4px" },
              }),
              _c(
                "span",
                { staticStyle: { "font-size": "14px", color: "#999999" } },
                [_vm._v("确定重新服务，接车单将返回到服务中状态")]
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "remark-box" },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 4,
                  placeholder: "请输入重新服务原因（必填）",
                },
                model: {
                  value: _vm.resetServiceReason,
                  callback: function ($$v) {
                    _vm.resetServiceReason = $$v
                  },
                  expression: "resetServiceReason",
                },
              }),
            ],
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.resetService },
                },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.toServiceDialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.reverseSettlementDialog,
            width: "35%",
            top: "15%",
            "before-close": _vm.handleCloses,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.reverseSettlementDialog = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c("p", [
              _c("span", { staticStyle: { "font-size": "16px" } }, [
                _vm._v("反结算"),
              ]),
              _c("i", {
                staticClass: "iconfont grow-icon_warn_32 orange",
                staticStyle: { margin: "0px 4px" },
              }),
              _c(
                "span",
                { staticStyle: { "font-size": "14px", color: "#999999" } },
                [_vm._v("如之前结账有使用优惠券，反结算后将不返还")]
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "remark-box" },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 4,
                  placeholder: "请输入反结算的原因（必填）",
                },
                model: {
                  value: _vm.reverseSettlementReason,
                  callback: function ($$v) {
                    _vm.reverseSettlementReason = $$v
                  },
                  expression: "reverseSettlementReason",
                },
              }),
            ],
            1
          ),
          _c("template", { slot: "footer" }, [
            _c("div", { staticClass: "flex-x-end flex-y-end" }, [
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.reverseSettlements },
                    },
                    [_vm._v("确 定")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.reverseSettlementDialog = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        2
      ),
      _vm.invoicingDialog
        ? _c("invoicing-dialog", {
            attrs: {
              invoicingDialog: _vm.invoicingDialog,
              billId: _vm.billMessage.billId,
              billType: _vm.billMessage.billType,
              serviceTableData: _vm.serviceTableData,
              customer: _vm.carInfo,
              money: _vm.otherData.paid,
              takeCar: _vm.billMessage.takeTime,
            },
            on: {
              "update:invoicingDialog": function ($event) {
                _vm.invoicingDialog = $event
              },
              "update:invoicing-dialog": function ($event) {
                _vm.invoicingDialog = $event
              },
              editMaterialBill: _vm.handleEditMaterialBill,
              confirm: _vm.getData,
            },
          })
        : _vm._e(),
      _vm.stayInvoicingDialog
        ? _c("stay-invoicing-dialog", {
            attrs: {
              stayInvoicingDialog: _vm.stayInvoicingDialog,
              billId: _vm.billMessage.billId,
              companyId: _vm.companyId,
              carInfo: _vm.carInfo,
              customerName: _vm.carInfo.clientName,
              insuranceCompanyName:
                _vm.carBillInsuranceInfo.claimsInsuranceCompanyName,
              undertakeInsuranceCompanyName:
                _vm.carBillUndertakeInfo.undertakeInsuranceCompanyName,
              amountOfDocuments: _vm.otherData.paid || _vm.otherData.total,
              otherData: _vm.otherData,
              billType: _vm.billMessage.billType,
              payerInfo: _vm.payerInfo,
              orderTime: _vm.billMessage.dateCreated,
              beHang: _vm.otherData.beHang,
            },
            on: {
              "update:stayInvoicingDialog": function ($event) {
                _vm.stayInvoicingDialog = $event
              },
              "update:stay-invoicing-dialog": function ($event) {
                _vm.stayInvoicingDialog = $event
              },
              updateData: _vm.getData,
            },
          })
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.CancellationAreCancelledDialog,
            title: "撤销挂单",
            width: "30%",
            top: "15%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.CancellationAreCancelledDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                "text-align": "center",
                color: "#666666",
                "font-size": "14px",
              },
            },
            [_vm._v(" 确定撤销挂单，返回服务中，重新服务车辆？ ")]
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.CancellationConfirm },
                },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.CancellationAreCancelledDialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.ChangesCarTypeDialog,
            title: "变更业务类型",
            width: "30%",
            top: "15%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.ChangesCarTypeDialog = $event
            },
          },
        },
        [
          _c("div", { staticStyle: { display: "flex" } }, [
            _c("p", { staticStyle: { "margin-top": "13px" } }, [
              _vm._v("变更为："),
            ]),
            _c("p"),
            _c("div", { staticClass: "custom-tabs nopadding" }, [
              _c(
                "div",
                {
                  class: {
                    "custom-tab-item": true,
                    active: _vm.typeStatus == 0,
                    disabled: _vm.billMessage.billType == 0,
                  },
                  on: {
                    click: function ($event) {
                      _vm.typeStatus = 0
                    },
                  },
                },
                [_vm._v(" 自费 ")]
              ),
              _c(
                "div",
                {
                  class: {
                    "custom-tab-item": true,
                    active: _vm.typeStatus == 2,
                    disabled: _vm.billMessage.billType == 2,
                  },
                  on: {
                    click: function ($event) {
                      _vm.typeStatus = 2
                    },
                  },
                },
                [_vm._v(" 理赔 ")]
              ),
              _c(
                "div",
                {
                  class: {
                    "custom-tab-item": true,
                    active: _vm.typeStatus == 3,
                    disabled: _vm.billMessage.billType == 3,
                  },
                  on: {
                    click: function ($event) {
                      _vm.typeStatus = 3
                    },
                  },
                },
                [_vm._v(" 索赔 ")]
              ),
              _c(
                "div",
                {
                  class: {
                    "custom-tab-item": true,
                    active: _vm.typeStatus == 1,
                    disabled: _vm.billMessage.billType == 1,
                  },
                  on: {
                    click: function ($event) {
                      _vm.typeStatus = 1
                    },
                  },
                },
                [_vm._v(" 包干 ")]
              ),
            ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.ChangesCarTypeDialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.ChangesCarTypeComfirm },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm.editMaterialBillDialogVisibility
        ? _c("edit-material-bill-dialog", {
            attrs: {
              visibility: _vm.editMaterialBillDialogVisibility,
              id: _vm.otherData.materialBillId,
              carId: _vm.carInfo.carId,
              billId: _vm.billMessage.billId,
            },
            on: {
              "update:visibility": function ($event) {
                _vm.editMaterialBillDialogVisibility = $event
              },
              save: _vm.getData,
            },
          })
        : _vm._e(),
      _vm.remarksDrawerVisibility
        ? _c("remarks-drawer", {
            attrs: {
              remarksDrawerVisibility: _vm.remarksDrawerVisibility,
              clientRemark: _vm.clientRemark,
            },
            on: {
              "update:remarksDrawerVisibility": function ($event) {
                _vm.remarksDrawerVisibility = $event
              },
              "update:remarks-drawer-visibility": function ($event) {
                _vm.remarksDrawerVisibility = $event
              },
            },
          })
        : _vm._e(),
      _vm.carBindingCustomerDialogVisibility
        ? _c("car-binding-customer", {
            attrs: {
              visibility: _vm.carBindingCustomerDialogVisibility,
              carId: _vm.carInfo.carId,
            },
            on: {
              "update:visibility": function ($event) {
                _vm.carBindingCustomerDialogVisibility = $event
              },
              binding: _vm.handleBindingClient,
            },
          })
        : _vm._e(),
      _c("goods-exception-dialog", {
        attrs: {
          visibility: _vm.goodsExceptionDialogVisibility,
          inconsistentPricesList: _vm.inconsistentPricesList,
          inconsistentQuantityList: _vm.inconsistentQuantityList,
        },
        on: {
          "update:visibility": function ($event) {
            _vm.goodsExceptionDialogVisibility = $event
          },
        },
      }),
      _vm.lossRecordDrawer
        ? _c("car-loss-record-drawer", {
            attrs: {
              visibility: _vm.lossRecordDrawer,
              carLossDeterminationBillId:
                _vm.carBillInsuranceInfo.carLossDeterminationBillId,
              billId: this.id,
            },
            on: {
              "update:visibility": function ($event) {
                _vm.lossRecordDrawer = $event
              },
              updateData: function ($event) {
                return _vm.getData()
              },
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticStyle: { position: "fixed", left: "-9999px" } },
        [
          _c("settlementPrintTemplate", { ref: "settlementPrintTemplate" }),
          _c("dispatchPrintTemplate", { ref: "dispatchPrintTemplate" }),
          _c("materialPrintTemplate", { ref: "materialPrintTemplate" }),
          _c("customerConfirmPrintTemplate", {
            ref: "customerConfirmPrintTemplate",
          }),
        ],
        1
      ),
      _vm.showVideoPlayerDialog
        ? _c("videoPlayerDialog", {
            attrs: {
              visibility: _vm.showVideoPlayerDialog,
              videos: _vm.previewVideos,
              index: _vm.previewVideoIndex,
            },
            on: {
              "update:visibility": function ($event) {
                _vm.showVideoPlayerDialog = $event
              },
            },
          })
        : _vm._e(),
      _vm.clientCommandDrawerVisibility
        ? _c("client-command-drawer", {
            attrs: {
              visibility: _vm.clientCommandDrawerVisibility,
              clientId: _vm.carInfo.clientId,
              defaultActiveName: _vm._f("dict")(
                _vm.billMessage.billType,
                "defaultActiveName"
              ),
            },
            on: {
              "update:visibility": function ($event) {
                _vm.clientCommandDrawerVisibility = $event
              },
            },
          })
        : _vm._e(),
      _vm.historicalDrawerVisibility
        ? _c("historical-operation-drawer", {
            attrs: {
              visibility: _vm.historicalDrawerVisibility,
              billId: _vm.billMessage.billId,
            },
            on: {
              "update:visibility": function ($event) {
                _vm.historicalDrawerVisibility = $event
              },
            },
          })
        : _vm._e(),
      _vm.strandedNoteDialogVisibility
        ? _c("stranded-note-dialog", {
            attrs: {
              visibility: _vm.strandedNoteDialogVisibility,
              billId: _vm.id,
            },
            on: {
              "update:visibility": function ($event) {
                _vm.strandedNoteDialogVisibility = $event
              },
            },
          })
        : _vm._e(),
      _vm.editCarBillDialogVisibility
        ? _c("edit-car-bill-dialog", {
            attrs: {
              visibility: _vm.editCarBillDialogVisibility,
              billId: _vm.id,
              billType: _vm.billMessage.billType,
              remark: _vm.billMessage.companyRemark,
              clientInfo: _vm.clientInfo,
              carBillInfo: _vm.billMessage,
              isSpecificClient: _vm.isSpecificClient(),
            },
            on: {
              "update:visibility": function ($event) {
                _vm.editCarBillDialogVisibility = $event
              },
              save: _vm.getData,
            },
          })
        : _vm._e(),
      _vm.agreementPriceDialogVisibility
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "协议报价",
                visible: _vm.agreementPriceDialogVisibility,
                width: "94%",
                top: "10vh",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.agreementPriceDialogVisibility = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "agreementPriceDialog-clientInfo" },
                [
                  _vm._v(" " + _vm._s(_vm.carInfo.clientName) + " "),
                  _vm.carInfo.companyFullName
                    ? [
                        _vm._v(
                          " / " + _vm._s(_vm.carInfo.companyFullName) + " "
                        ),
                      ]
                    : _vm._e(),
                  _vm.carInfo.clientName != _vm.clientInfo.consumerName
                    ? [
                        _c("i", {
                          staticClass: "el-icon-warning orange el-icon--left",
                        }),
                        _vm._v(
                          " 当前车单消费客户为“" +
                            _vm._s(_vm.clientInfo.consumerName) +
                            "”与签约客户不一致，本单不按协议报价 "
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _c("agreement-price-list", {
                attrs: {
                  id: _vm.carAgreementId,
                  showSearch: "",
                  watermark: "",
                  watermarkText: _vm.watermarkText,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.inconsistentAgreementPriceDialogVisibility
        ? _c("inconsistent-agreement-price-dialog", {
            attrs: {
              visibility: _vm.inconsistentAgreementPriceDialogVisibility,
              list: _vm.inconsistentAgreementPriceList,
              customerName: _vm.payerInfo.consumerName,
              id: _vm.id,
            },
            on: {
              "update:visibility": function ($event) {
                _vm.inconsistentAgreementPriceDialogVisibility = $event
              },
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }