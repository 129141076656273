var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-drawer",
    {
      attrs: {
        title: "选择商品",
        visible: _vm.visibility,
        size: "60%",
        "append-to-body": true,
        "before-close": _vm.handleClose,
      },
    },
    [
      _c(
        "div",
        { staticStyle: { height: "100%", padding: "0 30px" } },
        [
          _c(
            "el-tabs",
            {
              staticClass: "page-tabs",
              model: {
                value: _vm.activeTabName,
                callback: function ($$v) {
                  _vm.activeTabName = $$v
                },
                expression: "activeTabName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "全部", name: "first" } },
                [
                  _c(
                    "base-container",
                    { attrs: { "default-padding": false } },
                    [
                      _c(
                        "template",
                        { slot: "header" },
                        [
                          _c(
                            "el-form",
                            {
                              attrs: { "label-width": "100px", size: "small" },
                            },
                            [
                              _c(
                                "el-row",
                                { attrs: { gutter: 20 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 3, offset: 0 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "库存大于0" } },
                                        [
                                          _c("el-checkbox", {
                                            staticStyle: {
                                              "margin-left": "15px",
                                            },
                                            on: { change: _vm.changeInStock },
                                            model: {
                                              value: _vm.pageInStock,
                                              callback: function ($$v) {
                                                _vm.pageInStock = $$v
                                              },
                                              expression: "pageInStock",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6, offset: 0 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "商品名称" } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入商品名称",
                                              clearable: "",
                                            },
                                            nativeOn: {
                                              keyup: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                )
                                                  return null
                                                return _vm.getData(true)
                                              },
                                            },
                                            model: {
                                              value: _vm.goodsName,
                                              callback: function ($$v) {
                                                _vm.goodsName = $$v
                                              },
                                              expression: "goodsName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6, offset: 0 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "适用车型" } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              clearable: "",
                                              size: "small",
                                              placeholder: "请输入适用车型",
                                            },
                                            on: { input: _vm.handleModelInput },
                                            nativeOn: {
                                              keyup: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                )
                                                  return null
                                                return _vm.getData(true)
                                              },
                                            },
                                            model: {
                                              value: _vm.modelName,
                                              callback: function ($$v) {
                                                _vm.modelName = $$v
                                              },
                                              expression: "modelName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6, offset: 0 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "原厂编码" } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入原厂编码",
                                              clearable: "",
                                            },
                                            nativeOn: {
                                              keyup: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                )
                                                  return null
                                                return _vm.getData(true)
                                              },
                                            },
                                            model: {
                                              value: _vm.goodsCode,
                                              callback: function ($$v) {
                                                _vm.goodsCode = $$v
                                              },
                                              expression: "goodsCode",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 2, offset: 0 } },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.getData.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("查询")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                { attrs: { gutter: 20 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "商品品名" } },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                multiple: "",
                                                filterable: "",
                                                remote: "",
                                                placeholder: "请搜索，支持多选",
                                                "remote-method":
                                                  _vm.searchProductList,
                                                loading:
                                                  _vm.searchProductListLoading,
                                                "value-key": "id",
                                              },
                                              model: {
                                                value: _vm.productIds,
                                                callback: function ($$v) {
                                                  _vm.productIds = $$v
                                                },
                                                expression: "productIds",
                                              },
                                            },
                                            _vm._l(
                                              _vm.productList,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.id,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.id,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "入库批次号" } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入入库批次号",
                                              clearable: "",
                                            },
                                            nativeOn: {
                                              keyup: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                )
                                                  return null
                                                return _vm.getData(true)
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.inventoryInAndOutBillCode,
                                              callback: function ($$v) {
                                                _vm.inventoryInAndOutBillCode =
                                                  $$v
                                              },
                                              expression:
                                                "inventoryInAndOutBillCode",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "content-box" },
                        [
                          _c(
                            "el-table",
                            {
                              ref: "multipleTable",
                              staticClass: "data-table",
                              attrs: { data: _vm.tableData, border: "" },
                              on: {
                                select: _vm.handleSelectionChange,
                                "select-all": _vm.handleSelectAll,
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: { type: "selection", width: "40" },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "序号",
                                  type: "index",
                                  width: "60",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  width: "180",
                                  prop: "matchCarType",
                                  label: "适用车型",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ row }) {
                                      return [
                                        row.isAgreePrice
                                          ? _c(
                                              "el-tag",
                                              {
                                                staticClass: "el-icon--left",
                                                attrs: {
                                                  type: "info",
                                                  size: "mini",
                                                },
                                              },
                                              [_vm._v("协议")]
                                            )
                                          : _vm._e(),
                                        _c("span", [
                                          _vm._v(_vm._s(row.matchCarType)),
                                        ]),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  width: "180",
                                  label: "商品标准名",
                                  prop: "standardName",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: { width: "100", label: "品质" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ row }) {
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("dict")(
                                                row.goodsType,
                                                "manufactoryType"
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  width: "140",
                                  label: "自编码",
                                  prop: "ownCode",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  width: "140",
                                  label: "原厂编码",
                                  prop: "goodsCode",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: { width: "120", label: "本店库存/单位" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ row }) {
                                      return [
                                        _c("span", [
                                          _vm._v(_vm._s(row.inventoryTotalNum)),
                                        ]),
                                        row.unit
                                          ? _c("span", [
                                              _vm._v(
                                                "(" + _vm._s(row.unit) + ")"
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: { width: "120", label: "他店库存/单位" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ row }) {
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(row.otherInventoryTotalNum)
                                          ),
                                        ]),
                                        row.unit && row.otherInventoryTotalNum
                                          ? _c("span", [
                                              _vm._v(
                                                "(" + _vm._s(row.unit) + ")"
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: { width: "120", label: "售价" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ row }) {
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("toThousands")(row.price)
                                            )
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  "min-width": "120",
                                  label: "商品备注",
                                  prop: "remark",
                                  "show-overflow-tooltip": "",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "class-box" },
                            [
                              _c("p", { staticClass: "title" }, [
                                _vm._v("商品分类"),
                              ]),
                              _c("el-tree", {
                                staticClass: "custom-tree",
                                attrs: {
                                  data: _vm.treeData,
                                  props: _vm.treeProp,
                                  "highlight-current": "",
                                  "node-key": "categoryId",
                                },
                                on: { "node-click": _vm.onNodeClick },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "template",
                        { slot: "footer" },
                        [
                          _c("pagination", {
                            attrs: { total: _vm.total },
                            on: { pageChange: _vm.changePage },
                          }),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c("p", [
            _c("i", { staticClass: "iconfont grow-icon_warn_32 orange" }),
            _c("span", [
              _vm._v("已选择商品 " + _vm._s(_vm.selectedItems.length)),
            ]),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.confirmSelect },
            },
            [_vm._v("确 定")]
          ),
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.handleClose } },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }