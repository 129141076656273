<template>
  <el-dialog
    :title="title"
    :visible="dialogTableVisible"
    :append-to-body="true"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <base-container>
      <template slot="header">
        <el-form
          :model="form"
          label-width="120px"
          size="small"
          ref="form"
          :rules="rules"
        >
          <el-row>
            <el-col :span="24"
              ><el-form-item label="保险公司名称:" prop="insuranceName">
                <el-input
                  v-model="form.insuranceName"
                  placeholder="请输入"
                  :disabled="inputTypes"
                ></el-input> </el-form-item
            ></el-col>
          </el-row>
          <el-row>
            <el-col :span="12"
              ><el-form-item label="公司编码:">
                <el-input
                  v-model="form.insuranceNum"
                  placeholder="请输入"
                  :disabled="inputTypes"
                ></el-input> </el-form-item
            ></el-col>
            <el-col :span="12"
              ><el-form-item v-auth="484" label="启用状态:">
                <el-switch
                  v-model="chioceType"
                  active-color="#33ab79"
                  inactive-color="#999999"
                >
                </el-switch>
                <span style="color: #999999; margin-left: 5px; font-size: 10px">
                  {{
                    chioceType == true
                      ? '(已选择启用本保险公司)'
                      : '(已选择禁用本保险公司)'
                  }}
                </span></el-form-item
              ></el-col
            >
          </el-row>
          <el-row>
            <el-col :span="12"
              ><el-form-item label="联系电话:">
                <el-input
                  v-model="form.insurancePhone"
                  placeholder="请输固话或手机号"
                  maxlength="11"
                  show-word-limit
                  @input="phoneCofime($event, 'insurancePhone')"
                ></el-input> </el-form-item
            ></el-col>
            <el-col :span="12"
              ><el-form-item label="联系人:">
                <el-input
                  v-model="form.insuranceContact"
                  placeholder="请输入"
                ></el-input></el-form-item
            ></el-col>
          </el-row>
          <el-row>
            <el-col :span="12"
              ><el-form-item label="备用电话:">
                <el-input
                  v-model="form.insuranceAlternatePhone"
                  placeholder="请输入"
                  maxlength="11"
                  show-word-limit
                  @input="phoneCofime($event, 'insuranceAlternatePhone')"
                ></el-input> </el-form-item
            ></el-col>
            <el-col :span="12"
              ><el-form-item label="传真:">
                <el-input
                  v-model="form.insuranceFax"
                  placeholder="请输入"
                ></el-input></el-form-item
            ></el-col>
          </el-row>
          <el-row>
            <el-col :span="24"
              ><el-form-item label="联系地址:">
                <el-input
                  v-model="form.insuranceAddress"
                  placeholder="请输入"
                  maxlength="100"
                  show-word-limit
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24"
              ><el-form-item label="备注:">
                <el-input
                  type="textarea"
                  :rows="5"
                  placeholder="请输入"
                  v-model="form.insuranceRemark"
                  maxlength="100"
                  show-word-limit
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 开票主体注释：因为保险公司所有主体都能定损，觉得工作量太大，不需要添加开票主体 -->
          <!-- 下面是开票主体的添加 -->
          <!-- <el-row>
            <el-col :span="24">
              <div class="add_more">
                <div class="flex flex-x-center">
                  开票主体
                  <el-tooltip effect="light" placement="bottom-start">
                    <div
                      slot="content"
                      style="
                        color: #666666;
                        font-size: 13px;
                        line-height: 20px;
                        text-indent: 15px;
                      "
                    >
                      保险公司的开票主体，即为定损主体，也是理赔回款管理门店。
                    </div>
                    <i
                      class="iconfont grow-icon_doubt"
                      style="color: #9a9a9a"
                    ></i>
                  </el-tooltip>
                </div>
                <div @click="addMore()" class="add_mores">添加更多</div>
              </div>
            </el-col>
          </el-row> -->
          <!-- 下面是添加更多开票主体的 -->
          <!-- <el-row v-for="(item, index) in invoices" v-bind:key="index">
            <div class="table_date">
              <div style="margin-top: 5px; margin-right: 5px">
                <i
                  class="iconfont grow-icon_delete3"
                  style="color: #ff9200; font-size: 22px"
                  @click="deleteInvoices(index)"
                ></i>
              </div>
              <el-col :span="12">
                <el-form-item
                  label-width="100"
                  :prop="'companyId-' + index"
                  :rules="[{ trigger: 'blur', validator: valiCompany }]"
                >
                  <template slot="label">
                    <span>开票主体</span> <span style="color: #f00">*</span>:
                  </template>
                  <el-select
                    v-model="item.companyId"
                    @change="selectClient"
                    filterable
                    remote
                    placeholder="搜索车店名称、编码"
                    :remote-method="searchClient"
                    :loading="searchClientLoading"
                  >
                    <el-option
                      v-for="item in invoiceListOptions"
                      :key="item.companyId"
                      :label="item.companyName"
                      :value="item.companyId"
                      :disabled="item.disabled"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <div class="flex">
                  <p style="flex-shrink: 0" class="type_name">定损星级:</p>
                  <el-input
                    style="width: 60%"
                    size="small"
                    placeholder="请输入"
                    v-model="item.star"
                    clearable
                  />
                </div>
              </el-col>
            </div>
          </el-row> -->

          <!-- 下面是适用门店 -->
          <el-row>
            <el-col :span="24">
              <div class="add_more">
                <div class="flex flex-x-center">适用门店</div>
              </div>
            </el-col>
          </el-row>
          <p class="describe">
            适用门店说明：设置适用门店后，只有适用门店才能选择本保险公司进行理赔业务接待开单
          </p>
          <div class="o_radio">
            <el-radio v-model="form.hasAppliedStore" :label="0"
              ><span style="color: #000000">无限制，全部门店</span></el-radio
            >
            <el-radio v-model="form.hasAppliedStore" :label="1"
              ><span style="color: #000000">有限制，部分门店</span></el-radio
            >
            <button
              @click="storeDrawerVisibility = true"
              class="buttons"
              v-show="form.hasAppliedStore == 1"
            >
              选择门店
            </button>
            <p class="descri_word" v-show="form.hasAppliedStore == 0">
              (已选择全部门店，适用门店是不固定，后续新增的门店将自动适用)
            </p>
            <p class="descri_word_t" v-show="form.hasAppliedStore == 1">
              (已选择部分门店，适用门店是固定的，新增的门店需要更新设置)
            </p>
          </div>
          <div style="margin-top: 10px" v-if="tableData.length != 0">
            <el-table
              :data="tableData"
              style="width: 100%"
              max-height="250"
              border
            >
              <el-table-column label="操作" width="80">
                <template slot-scope="scope">
                  <el-button
                    @click.native.prevent="deleteRow(scope.$index, tableData)"
                    type="text"
                    size="small"
                  >
                    <i
                      class="iconfont grow-icon_delete3"
                      style="color: #ff9200; font-size: 22px"
                    ></i>
                  </el-button>
                </template>
              </el-table-column>
              <el-table-column type="index" label="序号" width="80">
              </el-table-column>
              <el-table-column
                prop="companyId"
                label="车店编号"
                min-width="120"
              >
              </el-table-column>
              <el-table-column
                prop="companyName"
                label="车店名称"
                min-width="120"
              >
              </el-table-column>
              <el-table-column
                prop="chainCompanyType"
                label="连锁类型"
                min-width="120"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.chainCompanyType | dict('chainCompanyType')
                  }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-form>
        <select-store
          v-if="storeDrawerVisibility"
          :storeDrawerVisibility.sync="storeDrawerVisibility"
          :stores="storeslist"
          @select="storeIds"
        ></select-store>
      </template>
    </base-container>
    <template slot="footer">
      <div class="foot-btn">
        <el-button type="primary" size="small" @click="saveList"
          >确认保存</el-button
        >
        <el-button size="small" style="background: #f0f2f5" @click="handleClose"
          >取消</el-button
        >
      </div>
    </template>
  </el-dialog>
</template>

<script>
import selectStore from '@/views/systemManagement/selectStore';
import {
  getInsuranDetailList,
  addInsuran,
  updateInsuran,
  selList,
} from '@/api/customer/sysmanent';
import { mapState } from 'vuex';
export default {
  name: 'addInsurance',
  components: { selectStore },
  props: ['dialogTableVisible', 'id'],
  data() {
    return {
      inputTypes: false, //设置输入框是否能输入
      form: { hasAppliedStore: 0 },
      title: '新增保险公司',
      // 搜索门店
      stores: [],
      loading: false,

      storeslist: [],

      chioceType: true,
      textarea: '',
      // 下面是添加更多开票主体的
      invoices: [],

      radio: '',
      // 选择门店的
      storeDrawerVisibility: false,
      tableData: [], //选中有门店之后的表格
      storeId: [], //选中门店的id
      searchClientLoading: false,
      custlist: [],
      clientId: '',
      // 表单规则
      rules: {
        insuranceName: [
          { required: true, message: '请输入保险公司名称', trigger: 'blur' },
        ],
        companyId: [
          { required: true, message: '请输入开票主体', trigger: 'blur' },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
    //处理如果已存在的开票不能设置不能选择
    // invoiceListOptions() {
    //   if (this.custlist.length != 0) {
    //     let list = this.custlist.map((item) => {
    //       if (this.invoices.find((el) => el.companyId === item.companyId)) {
    //         item.disabled = true;
    //       } else {
    //         item.disabled = false;
    //       }
    //       return item;
    //     });
    //     return list;
    //   }
    //   return this.custlist;
    // },
  },
  created() {
    if (this.id) {
      this.title = '编辑保险公司';
      this.inputTypes = true;
      var data = {
        insuranceCompanyId: this.id,
      };
      getInsuranDetailList(data).then((res) => {
        this.tableData = res.stores;
        this.storeslist = this.$lodash.cloneDeep(res.stores); //这是把数据深拷贝一份
        this.form = res;
        this.chioceType = res.insuranceEnabled; //这个是表示启用或者是禁用开关的
        this.form.hasAppliedStore = res.hasAppliedStore ? 1 : 0; //这是判断适用门店的，false表示全部门店  true表示部分门店
        // this.invoices = res.invoices;
        this.custlist = this.$lodash.cloneDeep(res.invoices); //这是把数据深拷贝一份
      });
    }
    // this.invoices.push({
    //   companyId: this.companyId,
    //   companyName: '',
    // });
    this.searchClient();
  },
  methods: {
    //自定义严重规则
    valiCompany(rule, value, callback) {
      // var res = this.invoices.every((item) => !!item.companyId);
      var index = rule.field.split('-')[1];
      var res = this.invoices[index];
      if (!res.companyId) {
        return callback(new Error('开票主体不能为空'));
      }
      callback();
    },
    /**
     * 添加更多开票主体的数据方法
     */
    // addMore() {
    //   this.invoices.push({});
    // },
    //下面是校验开票主体必须要存在一个
    // deleteInvoices(index) {
    //   if (index > 0) {
    //     this.invoices.splice(index, 1);
    //   } else {
    //     console.log(this.invoices);
    //     this.invoices[0].companyId = '';
    //   }
    // },
    /**
     * 加载数据
     */
    getData() {
      console.log('getData');
    },

    /**
     * 关闭弹窗
     */
    handleClose() {
      this.$emit('update:dialogTableVisible', false);
    },
    /**
     * 保存数据
     */
    saveList() {
      this.$refs.form.validate((result, object) => {
        if (result) {
          this.doSave();
        } else {
          this.$message.warning('请填写必填项');
        }
      });
    },
    doSave() {
      this.form.insuranceEnabled = this.chioceType ? 1 : 0;
      this.form.insuranceNum = this.form.insuranceNum || '';
      this.invoiceCompanyId = '1';
      this.tableData.map((i) => {
        this.storeId.push(Object.assign({}, { companyId: i.companyId }));
      }); //循环表格中的数据，然后取出来转换成数组对象格式
      this.form.stores = this.storeId;
      // this.invoices.map((item) => {
      //   if (item.star == undefined) {
      //     item.star = '';
      //   }
      // });
      // this.form.invoices = this.invoices;
      // 保存
      if (this.id) {
        updateInsuran(this.form).then((res) => {
          console.log(res);
          if (res.code === 200) {
            this.$message.success('保存成功');
            this.$emit('update');
            this.$emit('update:dialogTableVisible', false);
          }
        });
      }
      // 新增
      else {
        addInsuran(this.form).then((res) => {
          if (res.code === 200) {
            this.$message.success('保存成功');
            this.$emit('update');
            this.$emit('update:dialogTableVisible', false);
          }
        });
      }
    },
    /**
     * 把在选择门店抽屉里选的数据拿过来
     */
    storeIds(value) {
      this.tableData = value;
      this.storeslist = value;
      // value.map((item) => {
      //   this.tableData.push(item);
      // });
    },
    /**
     * 远程搜索客户列表
     */
    searchClient(keyWord = '') {
      this.searchClientLoading = true;
      selList(keyWord, this.companyId)
        .then((res) => {
          this.custlist = res.records;
        })
        .finally(() => {
          this.searchClientLoading = false;
        });
    },
    /**
     * 选择客户
     */
    selectClient(clientId) {
      if (clientId) {
        const client = this.custlist.find(
          (item) => item.companyId === clientId
        );
      }
    },

    /**
     * 删除客户
     */
    deleteClient() {},
    /**这是点击表格里面删除图标的操作 */
    deleteRow(index, rows) {
      rows.splice(index, 1);
    },
    //限制手机输入框只能输入数字事件
    phoneCofime(value, prop) {
      this.form[prop] = value.replace(/\D/g, '');
    },
  },
};
</script>
<style lang="scss" scoped>
.base-container {
  overflow-y: scroll;
  height: 60vh;
}
.form-top {
  height: 28px;
}
.form-item__customlabel {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > i:last-child {
    margin-left: 5px;
  }
}
.primary {
  color: $primary;
}
.btn_search {
  width: 76px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.add_more {
  display: flex;
  justify-content: space-between;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 25px;
}
.add_more div:nth-child(2) {
  color: $blue;
}
.invo_lit {
  width: 100%;
  height: 50px;
  background: pink;
  margin-bottom: 20px;
}
// 添加更多开票主体的样式
li {
  list-style: none;
}
.table_date {
  display: flex;
  margin-bottom: 15px;
}
.deteles {
  line-height: 26px;
  margin-left: 16px;
}
.table_date input {
  border: none;
  border: 1px solid #e4e4e4;
  border-radius: 2px;
  height: 26px;
}
.type_name {
  line-height: 26px;
  margin-right: 5px;
}
.type_name .icon:after {
  content: '*';
  color: #ff532f;
}
.describe {
  color: #999999;
  font-size: 14px;
  margin: 20px 0px;
}
// 适用门店的样式
.describe {
  color: #999999;
  font-size: 14px;
  margin: 10px 0px 30px 0px;
}

// 选择门店的样式
.o_radio {
  height: 49px;
  margin-left: 18%;
  padding-top: 30px;
}
.descri_word {
  color: $orange;
  font-size: 12px;
  margin-top: 10px;
}
.descri_word_t {
  color: $orange;
  font-size: 12px;
  margin-top: 10px;
  margin-left: 32%;
}
.buttons {
  width: 104px;
  height: 30px;
  border: none;
  border-radius: 4px;
  background: $orange;
  color: #ffffff;
  font-size: 14px;
  margin-left: 40px;
}
.querys {
  width: 80px;
  height: 30px;
  border: none;
  background: $primary;
  color: #ffffff;
  border-radius: 4px;
}
.add_type_name {
  color: #3eb1e0;
}

.foot-btn {
  // margin-top: 50px;
  padding: 30px 20px 30px;
  box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.1);
}
.add_mores {
  color: $blue;
  &:hover {
    cursor: pointer;
  }
}
</style>
