var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { ref: "baseContainer", attrs: { paddingTop: "" } },
    [
      [
        _c(
          "el-form",
          {
            staticClass: "filter-form",
            attrs: { model: _vm.form, "label-width": "100px", size: "small" },
          },
          [
            _c("div", { staticClass: "flex-x-between" }, [
              _c(
                "div",
                { staticClass: "flex-warp flex-shrink0" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "签约客户" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            clearable: "",
                            remote: "",
                            placeholder: "请输入客户名称",
                            "remote-method": _vm.searchClient,
                            loading: _vm.searchClientLoading,
                          },
                          model: {
                            value: _vm.form.clientId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "clientId", $$v)
                            },
                            expression: "form.clientId",
                          },
                        },
                        _vm._l(_vm.clientList, function (item) {
                          return _c(
                            "el-option",
                            {
                              key: item.id,
                              attrs: { label: item.clientName, value: item.id },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    float: "left",
                                    color: "#8492a6",
                                    "font-size": "13px",
                                  },
                                },
                                [_vm._v(_vm._s(item.clientName))]
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c(
                          "div",
                          [
                            _vm._v(" 操作门店 "),
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  effect: "light",
                                  content:
                                    "接车单的操作门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)",
                                  placement: "bottom-start",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "iconfont grow-icon_doubt",
                                  staticStyle: { color: "#9a9a9a" },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("operateCompanyCascader", {
                        attrs: {
                          operateCompanyUrl: _vm.form.operateCompanyUrl,
                        },
                        on: {
                          "update:operateCompanyUrl": function ($event) {
                            return _vm.$set(
                              _vm.form,
                              "operateCompanyUrl",
                              $event
                            )
                          },
                          "update:operate-company-url": function ($event) {
                            return _vm.$set(
                              _vm.form,
                              "operateCompanyUrl",
                              $event
                            )
                          },
                        },
                        model: {
                          value: _vm.form.operateCompanyId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "operateCompanyId", $$v)
                          },
                          expression: "form.operateCompanyId",
                        },
                      }),
                    ],
                    2
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "接车进店日期" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          "unlink-panels": "",
                          "range-separator": "至",
                          "start-placeholder": "起始时间",
                          "end-placeholder": "截止时间",
                          "value-format": "yyyy-MM-dd",
                        },
                        model: {
                          value: _vm.form.intoStoreDateRange,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "intoStoreDateRange", $$v)
                          },
                          expression: "form.intoStoreDateRange",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "flex" }, [
                _c("div", { staticClass: "el-form-item--small" }, [
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "btn_search",
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.getData },
                        },
                        [_vm._v("查询")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "btn_search",
                          attrs: { size: "small" },
                          on: { click: _vm.resetFilterForm },
                        },
                        [_vm._v("置空")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "flex-warp" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "业务类型" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          multiple: "",
                          clearable: "",
                          placeholder: "全部 支持多选",
                        },
                        model: {
                          value: _vm.form.billType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "billType", $$v)
                          },
                          expression: "form.billType",
                        },
                      },
                      _vm._l(_vm.businessType, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "事故责任" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          multiple: "",
                          clearable: "",
                          placeholder: "全部 支持多选",
                        },
                        model: {
                          value: _vm.form.dutyType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "dutyType", $$v)
                          },
                          expression: "form.dutyType",
                        },
                      },
                      _vm._l(_vm.dutyType, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "客户等级" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { clearable: "", placeholder: "请选择事故责任" },
                        model: {
                          value: _vm.form.clientLevel,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "clientLevel", $$v)
                          },
                          expression: "form.clientLevel",
                        },
                      },
                      _vm._l(_vm.clientLevel, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ],
      [
        _c(
          "div",
          { staticClass: "flex-x-between" },
          [
            _c(
              "div",
              { staticStyle: { "margin-bottom": "15px", "font-size": "14px" } },
              [
                _c("i", {
                  staticClass: "pointer iconfont grow-icon_warn_32",
                  staticStyle: {
                    color: "#ff9200",
                    "font-size": "15px",
                    margin: "5px 10px",
                  },
                }),
                _c("span", { staticStyle: { color: "#909399" } }, [
                  _vm._v(
                    "今日在场只统计【服务中】【挂单中未提车】【待结账未提车】【已结账未提车】的车辆"
                  ),
                ]),
              ]
            ),
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 522,
                    expression: "522",
                  },
                ],
                staticClass: "blue",
                attrs: { size: "small", type: "text" },
                on: {
                  click: function ($event) {
                    _vm.showExportDialog = true
                  },
                },
              },
              [
                _vm._v("导出列表"),
                _c("i", {
                  staticClass: "iconfont grow-icon_daochu el-icon--right",
                }),
              ]
            ),
          ],
          1
        ),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "fixedScrollBar",
                rawName: "v-fixedScrollBar",
                value: _vm.$refs.baseContainer,
                expression: "$refs.baseContainer",
              },
            ],
            staticClass: "custom-table summary",
            attrs: {
              id: "customTable",
              data: _vm.tableData,
              border: "",
              "show-summary": "",
              "summary-method": _vm.getSummaries,
            },
          },
          [
            _c("el-table-column", {
              attrs: { type: "index", label: "序号", width: "60" },
            }),
            _c("el-table-column", {
              attrs: {
                "header-align": "center",
                "min-width": "120",
                label: "签约客户",
                prop: "clientName",
              },
            }),
            _c(
              "el-table-column",
              {
                attrs: { "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "span",
                          {
                            staticClass: "blue pointer",
                            on: {
                              click: function ($event) {
                                return _vm.jump(row, 1)
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.carSum))]
                        ),
                      ]
                    },
                  },
                ]),
              },
              [
                _c("template", { slot: "header" }, [
                  _c("div", { staticStyle: { "text-align": "center" } }, [
                    _vm._v("在场车辆"),
                    _c("br"),
                    _vm._v("（台）"),
                  ]),
                ]),
              ],
              2
            ),
            _c(
              "el-table-column",
              {
                attrs: { "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "span",
                          {
                            staticClass: "blue pointer",
                            on: {
                              click: function ($event) {
                                return _vm.jump(row, 2)
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.carStrandedWithinSevenDayNum))]
                        ),
                      ]
                    },
                  },
                ]),
              },
              [
                _c("template", { slot: "header" }, [
                  _c("div", { staticStyle: { "text-align": "center" } }, [
                    _vm._v("滞留超7天"),
                    _c("br"),
                    _vm._v("（台）"),
                  ]),
                ]),
              ],
              2
            ),
            _c(
              "el-table-column",
              {
                attrs: { "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "span",
                          {
                            staticClass: "blue pointer",
                            on: {
                              click: function ($event) {
                                return _vm.jump(row, 3)
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.todayCarNum))]
                        ),
                      ]
                    },
                  },
                ]),
              },
              [
                _c("template", { slot: "header" }, [
                  _c("div", { staticStyle: { "text-align": "center" } }, [
                    _vm._v("今日进店"),
                    _c("br"),
                    _vm._v("（台）"),
                  ]),
                ]),
              ],
              2
            ),
            _c(
              "el-table-column",
              { attrs: { label: "服务中", "header-align": "center" } },
              [
                _c(
                  "el-table-column",
                  {
                    attrs: { "min-width": "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c(
                              "span",
                              {
                                staticClass: "blue pointer",
                                on: {
                                  click: function ($event) {
                                    return _vm.jump(row, 4)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(row.inServiceBillTotal))]
                            ),
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _c(
                      "template",
                      { slot: "header" },
                      [
                        _c("span", [_vm._v("合计")]),
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              effect: "light",
                              placement: "bottom-start",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "#666",
                                  "font-size": "13px",
                                  "line-height": "16px",
                                  "text-indent": "15px",
                                },
                                attrs: { slot: "content" },
                                slot: "content",
                              },
                              [_vm._v(" 服务中（在修）车辆台数，已去重 ")]
                            ),
                            _c("i", {
                              staticClass: "iconfont grow-icon_doubt",
                              staticStyle: { "margin-left": "5px" },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "80",
                    label: "自费(单)",
                    "header-align": "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "blue pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.jump(row, 5)
                                },
                              },
                            },
                            [_vm._v(_vm._s(row.inServiceSelfPayBillNum))]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "80",
                    label: "包干(单)",
                    "header-align": "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "blue pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.jump(row, 6)
                                },
                              },
                            },
                            [_vm._v(_vm._s(row.inServiceContractForBillNum))]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "80",
                    label: "理赔(单)",
                    "header-align": "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "blue pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.jump(row, 7)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(row.inServiceClaimSettlementBillNum)
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "80",
                    label: "索赔(单)",
                    "header-align": "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "blue pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.jump(row, 8)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(row.inServiceClaimForCompensationBillNum)
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c(
              "el-table-column",
              {
                attrs: { label: "挂单中（未提车）", "header-align": "center" },
              },
              [
                _c(
                  "el-table-column",
                  {
                    attrs: { "min-width": "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c(
                              "span",
                              {
                                staticClass: "blue pointer",
                                on: {
                                  click: function ($event) {
                                    return _vm.jump(row, 14)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(row.registrationNoTakeCarBillTotal)
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _c(
                      "template",
                      { slot: "header" },
                      [
                        _c("span", [_vm._v("合计")]),
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              effect: "light",
                              placement: "bottom-start",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "#666",
                                  "font-size": "13px",
                                  "line-height": "16px",
                                  "text-indent": "15px",
                                },
                                attrs: { slot: "content" },
                                slot: "content",
                              },
                              [_vm._v(" 挂单中（未提车）车辆台数，已去重 ")]
                            ),
                            _c("i", {
                              staticClass: "iconfont grow-icon_doubt",
                              staticStyle: { "margin-left": "5px" },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "80",
                    label: "自费(单)",
                    "header-align": "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "blue pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.jump(row, 15)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(row.registrationNoTakeCarSelfPayBillNum)
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "80",
                    label: "包干(单)",
                    "header-align": "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "blue pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.jump(row, 16)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  row.registrationNoTakeCarContractForBillNum
                                )
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "80",
                    label: "理赔(单)",
                    "header-align": "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "blue pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.jump(row, 17)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  row.registrationNoTakeCarClaimSettlementBillNum
                                )
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "80",
                    label: "索赔(单)",
                    "header-align": "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "blue pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.jump(row, 18)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  row.registrationNoTakeCarClaimForCompensationBillNum
                                )
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c(
              "el-table-column",
              {
                attrs: { label: "待结账（未提车）", "header-align": "center" },
              },
              [
                _c(
                  "el-table-column",
                  {
                    attrs: { "min-width": "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c(
                              "span",
                              {
                                staticClass: "blue pointer",
                                on: {
                                  click: function ($event) {
                                    return _vm.jump(row, 9)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(row.toPayNoTakeCarBillTotal))]
                            ),
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _c(
                      "template",
                      { slot: "header" },
                      [
                        _c("span", [_vm._v("合计")]),
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              effect: "light",
                              placement: "bottom-start",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "#666",
                                  "font-size": "13px",
                                  "line-height": "16px",
                                  "text-indent": "15px",
                                },
                                attrs: { slot: "content" },
                                slot: "content",
                              },
                              [_vm._v(" 待结账（完工待提）车辆台数，已去重 ")]
                            ),
                            _c("i", {
                              staticClass: "iconfont grow-icon_doubt",
                              staticStyle: { "margin-left": "5px" },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "80",
                    label: "自费(单)",
                    "header-align": "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "blue pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.jump(row, 10)
                                },
                              },
                            },
                            [_vm._v(_vm._s(row.toPayNoTakeCarSelfPayBillNum))]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "80",
                    label: "包干(单)",
                    "header-align": "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "blue pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.jump(row, 11)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(row.toPayNoTakeCarContractForBillNum)
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "80",
                    label: "理赔(单)",
                    "header-align": "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "blue pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.jump(row, 12)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(row.toPayNoTakeCarClaimSettlementBillNum)
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "80",
                    label: "索赔(单)",
                    "header-align": "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "blue pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.jump(row, 13)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  row.toPayNoTakeCarClaimForCompensationBillNum
                                )
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c(
              "el-table-column",
              {
                attrs: { label: "已结账（未提车）", "header-align": "center" },
              },
              [
                _c(
                  "el-table-column",
                  {
                    attrs: { "min-width": "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c(
                              "span",
                              {
                                staticClass: "blue pointer",
                                on: {
                                  click: function ($event) {
                                    return _vm.jump(row, 19)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(row.checkoutNoTakeCarBillTotal))]
                            ),
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _c(
                      "template",
                      { slot: "header" },
                      [
                        _c("span", [_vm._v("合计")]),
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              effect: "light",
                              placement: "bottom-start",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "#666",
                                  "font-size": "13px",
                                  "line-height": "16px",
                                  "text-indent": "15px",
                                },
                                attrs: { slot: "content" },
                                slot: "content",
                              },
                              [_vm._v(" 已结账（完工待提）车辆台数，已去重 ")]
                            ),
                            _c("i", {
                              staticClass: "iconfont grow-icon_doubt",
                              staticStyle: { "margin-left": "5px" },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "80",
                    label: "自费(单)",
                    "header-align": "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "blue pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.jump(row, 20)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(row.checkoutNoTakeCarSelfPayBillNum)
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "80",
                    label: "包干(单)",
                    "header-align": "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "blue pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.jump(row, 21)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(row.checkoutNoTakeCarContractForBillNum)
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "80",
                    label: "理赔(单)",
                    "header-align": "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "blue pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.jump(row, 22)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  row.checkoutNoTakeCarClaimSettlementBillNum
                                )
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "80",
                    label: "索赔(单)",
                    "header-align": "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "blue pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.jump(row, 23)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  row.checkoutNoTakeCarClaimForCompensationBillNum
                                )
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm.showExportDialog
          ? _c("export-dialog", {
              attrs: {
                visibility: _vm.showExportDialog,
                total: _vm.total,
                pageOption: _vm.pageOption,
                filterForm: _vm.exportForm,
                url: _vm.exportUrl,
              },
              on: {
                "update:visibility": function ($event) {
                  _vm.showExportDialog = $event
                },
              },
            })
          : _vm._e(),
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c("pagination", {
            attrs: { total: _vm.total },
            on: { pageChange: _vm.changePage },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }