var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-drawer",
    {
      attrs: {
        title: "车单商品检测",
        visible: _vm.visibility,
        size: "60%",
        "append-to-body": true,
        "before-close": _vm.handleClose,
      },
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "p",
            {
              staticClass: "orange",
              staticStyle: { "margin-bottom": "10px", "font-size": "14px" },
            },
            [
              _vm._v(
                " 当前接车单存在商品报价数量与出库数量不一致情况，点击确定，进行同步本单商品报价数量 "
              ),
            ]
          ),
          _c(
            "div",
            { staticStyle: { "margin-bottom": "16px" } },
            [
              _c("el-cascader", {
                ref: "commodity",
                attrs: {
                  options: _vm.treeData,
                  "show-all-levels": false,
                  props: {
                    value: "id",
                    label: "name",
                    children: "goodsSecondCategory",
                    multiple: true,
                  },
                  size: "small",
                  clearable: "",
                  placeholder: "选择分类",
                },
                on: { change: _vm.handleChange },
                model: {
                  value: _vm.sortData,
                  callback: function ($$v) {
                    _vm.sortData = $$v
                  },
                  expression: "sortData",
                },
              }),
              _c("i", {
                staticClass: "iconfont grow-icon_warn_32 orange",
                staticStyle: { "margin-top": "10px", "margin-left": "10px" },
              }),
              _c(
                "span",
                {
                  staticStyle: {
                    "font-size": "14px",
                    color: "#999999",
                    "margin-top": "11px",
                    "margin-left": "5px",
                  },
                },
                [_vm._v("按商品分类快速勾选材料单商品")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              ref: "goodsListTable",
              attrs: { data: _vm.list, border: "" },
              on: { "selection-change": _vm.handleSelect },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  "header-align": "center",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  width: "60",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "name", label: "项目名称", "min-width": "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.serviceItemName
                          ? _c("span", [_vm._v(_vm._s(row.serviceItemName))])
                          : _c("select-service-popover", {
                              key: row.goodsId,
                              attrs: {
                                carId: _vm.carId,
                                error: !row.serviceItemName && _vm.hasClickSave,
                                errorPlaceholder: "请设定该商品所属服务项",
                              },
                              on: {
                                select: function ($event) {
                                  return _vm.onSelectServiceItem($event, row)
                                },
                              },
                            }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "商品名称", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [_c("span", [_vm._v(_vm._s(row.goodsName))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "商品编码", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [_c("span", [_vm._v(_vm._s(row.goodsCode))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "商品分类", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(row.firstCategoryName) +
                              "/" +
                              _vm._s(row.secondCategoryName)
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "原车单报价数量", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [_c("span", [_vm._v(_vm._s(row.num))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "材料单待领数", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [_c("span", [_vm._v(_vm._s(row.waitPickedNum))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "材料单已领数", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [_c("span", [_vm._v(_vm._s(row.pickedNum))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "确定后报价数量", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("number-input", {
                          attrs: { min: 1, clearable: false, nullable: false },
                          on: {
                            blur: function ($event) {
                              return _vm.verifyNum($event, row)
                            },
                          },
                          model: {
                            value: row.newNum,
                            callback: function ($$v) {
                              _vm.$set(row, "newNum", $$v)
                            },
                            expression: "row.newNum",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("div", { staticClass: "footer flex-x-between" }, [
            _c(
              "div",
              { staticClass: "flex-y-center" },
              [
                _c(
                  "el-checkbox",
                  {
                    on: { change: _vm.handleSelectAll },
                    model: {
                      value: _vm.isSelectAll,
                      callback: function ($$v) {
                        _vm.isSelectAll = $$v
                      },
                      expression: "isSelectAll",
                    },
                  },
                  [_vm._v("全选")]
                ),
                _vm.selectedGoods.length
                  ? _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "30px" },
                        attrs: { size: "small", type: "text" },
                        on: {
                          click: function ($event) {
                            _vm.isBatchSettingOwningService = true
                          },
                        },
                      },
                      [_vm._v("设定所属服务")]
                    )
                  : _vm._e(),
                _vm.isBatchSettingOwningService
                  ? _c("select-service-popover", {
                      attrs: { carId: _vm.carId },
                      on: {
                        select: function ($event) {
                          return _vm.batchSettingOwningService($event)
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.doSave },
                  },
                  [_vm._v("保存")]
                ),
                _c(
                  "el-button",
                  { attrs: { size: "small" }, on: { click: _vm.handleClose } },
                  [_vm._v("取消")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }