<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <el-form
        class="filter-form"
        :model="form"
        label-width="100px"
        size="small"
      >
        <div class="flex-x-between">
          <div class="flex flex-shrink0">
            <el-form-item label="服务名称">
              <el-input
                v-model="form.name"
                placeholder="请输入服务名称"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
            <el-form-item label="服务分类">
              <select-service-category
                :value="[form.firstCategoryId, form.secondCategoryId]"
                @select="handleSelectServiceCategory"
                :clearBol="clearBol"
                @clearBol="clearBolSon"
              ></select-service-category>
            </el-form-item>
            <el-form-item label="启用状态">
              <el-select v-model="form.isActive" placeholder="请选择启用状态">
                <el-option label="已启用" :value="true"> </el-option>
                <el-option label="未启用" :value="false"> </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="el-form-item--small">
            <div class="flex">
              <el-button
                class="btn_search"
                type="primary"
                size="small"
                @click="getData"
                >查询</el-button
              >
              <el-button class="btn_search" size="small" @click="empty"
                >置空</el-button
              >
            </div>
          </div>
        </div>
      </el-form>
    </template>

    <template>
      <div style="margin: 10px 0 14px 0" class="btns flex">
        <el-button
          v-auth="54"
          size="small"
          class="btn_insert"
          @click="addServiceManagement"
        >
          新增
        </el-button>
        <el-button v-auth="54" size="small" @click="batchImport">
          批量导入<i class="el-icon-arrow-right" style="margin-left: 10px" />
        </el-button>
      </div>
      <el-table
        :data="serviceListData"
        border
        @selection-change="handleSelectionChange"
        @row-dblclick="handleEdit"
        v-fixedScrollBar="$refs.baseContainer"
        class="custom-table"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column type="index" label="序号" width="60">
        </el-table-column>
        <el-table-column label="操作" width="60">
          <template slot-scope="scope">
            <span
              v-auth="53"
              class="text_operation blue"
              @click="handleEdit(scope.row)"
              >编辑</span
            >
          </template>
        </el-table-column>
        <el-table-column width="170" label="服务名称" prop="name">
          <template #default="{ row }">
            <p>{{ row.name }}</p>
          </template>
        </el-table-column>
        <el-table-column width="130" label="服务编码" prop="serviceItemCode">
          <template #default="{ row }">
            <p>{{ row.serviceItemCode }}</p>
          </template>
        </el-table-column>
        <el-table-column width="170" label="关联商品品名" prop="productNames">
          <template #default="{ row }">
            <p>{{ row.productNames }}</p>
          </template>
        </el-table-column>
        <el-table-column width="120" label="服务时效(H)" prop="serviceTime">
          <template #default="{ row }">
            <p>{{ row.serviceTime }}</p>
          </template>
        </el-table-column>
        <el-table-column width="120" label="单价(元/H)" prop="unitPrice">
          <template #default="{ row }">
            <p>{{ row.unitPrice }}</p>
          </template>
        </el-table-column>
        <el-table-column width="120" label="标准价(元)" prop="price">
          <template #default="{ row }">
            <p>{{ row.price }}</p>
          </template>
        </el-table-column>
        <el-table-column width="120" label="人力成本" prop="humanCost">
          <template #default="{ row }">
            <p>{{ row.humanCost }}</p>
          </template>
        </el-table-column>
        <el-table-column
          width="80"
          label="工种"
          prop="constructionTypeEnumOption"
        >
          <template #default="{ row }">
            <p>{{ row.constructionTypeEnumOption.desc }}</p>
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          label="施工难度"
          prop="constructionDifficultyOption"
        >
          <template #default="{ row }">
            <p>{{ row.constructionDifficultyOption.desc }}</p>
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          label="施工工时费用"
          prop="constructionTimePrice"
        >
          <template #default="{ row }">
            <p>{{ row.constructionTimePrice }}</p>
          </template>
        </el-table-column>
        <el-table-column width="150" label="服务分类">
          <template #default="{ row }">
            <p>{{ row.firstCategoryName }} / {{ row.secondCategoryName }}</p>
          </template>
        </el-table-column>
        <el-table-column min-width="160" label="备注" prop="description">
          <template #default="{ row }">
            <p>{{ row.description }}</p>
          </template>
        </el-table-column>
        <el-table-column min-width="200" label="创建时间" prop="dateCreated">
        </el-table-column>
        <el-table-column min-width="200" label="更新时间" prop="lastUpdated">
        </el-table-column>
        <el-table-column min-width="160" label="创建人" prop="creator">
        </el-table-column>
        <el-table-column min-width="160" label="更新人" prop="updater">
        </el-table-column>
      </el-table>
      <service-management-dialog
        v-if="showServiceManagementDialog"
        :showServiceManagementDialog.sync="showServiceManagementDialog"
        :id="editId"
        :detail="editDetail"
        @save="getData"
      >
      </service-management-dialog>
      <import-dialog
        v-if="showImportDialog"
        :visibility.sync="showImportDialog"
        title="服务导入"
        :uploadFileApi="importDataApi"
        :templateFileUrl="templateFileUrl"
        :importResult="importResult"
        :importFailDetail="serviceItemImportMessage"
      />
    </template>

    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
  </base-container>
</template>

<script>
// 组件
import pagination from '@/components/Pagination.vue';
import ServiceManagementDialog from './serviceManagementDialog';
import selectServiceCategory from './selectServiceCategory';
import importDialog from '@/components/businessComponent/importDialog';
// 接口
import {
  getServiceItemPage,
  importData,
  importResult,
} from '@/api/systemManagement/carOrderSettings/serviceManagement';
import { searchClient } from '@/api/customer/customer';
import { searchStore } from '@/api/customer/store';

// 字典数据
import { carState, underLeaseState } from '@/service/dict/dictData';
import { downloadFile } from '@/utils/fileApi';

import { mapState, mapGetters, mapMutations } from 'vuex';

// 工具
import Big from 'big.js';

export default {
  name: 'serviceManagement',
  components: {
    pagination,
    ServiceManagementDialog,
    selectServiceCategory,
    importDialog,
  },
  data() {
    return {
      editDetail: {},
      editId: '',
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      // 列表数据
      serviceListData: [],
      showServiceManagementDialog: false,
      showImportDialog: false,
      clearBol: false,
      form: {
        filingCompanyId: '',
        isActive: true,
      },
      importDataApi: importData,
      importResult: importResult,
      serviceItemImportMessage: '/admin/serviceItemImportMessage/export',
      templateFileUrl:
        'https://growxny-dev-center.oss-cn-guangzhou.aliyuncs.com/template/20240415/%E6%9C%8D%E5%8A%A1%E7%AE%A1%E7%90%86%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF_20240426.xlsx',
      //选中列表数据
      selectedIds: [],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  methods: {
    /**
     * 选择商品分类
     */
    handleSelectServiceCategory(val) {
      this.form.firstCategoryId = val[0] || '';
      this.form.secondCategoryId = val[1] || '';
    },
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getData(false);
    },
    /**
     * 加载数据
     */
    getData(reset = true) {
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      this.form.serviceMessage = this.form.name;
      let data = Object.assign(this.form, this.pageOption);
      getServiceItemPage(data).then((res) => {
        console.log(res);
        // 修改总条数
        this.total = res.total;
        // 赋值records
        this.serviceListData = res.records;
      });
    },
    //级联组件回传
    clearBolSon(bol) {
      this.clearBol = bol;
    },
    /**
     * 批量导入
     */
    batchImport() {
      this.$nextTick().then(() => {
        this.showImportDialog = true;
      });
      console.log('批量导入');
    },
    /**
     * 新增
     */
    addServiceManagement() {
      this.editDetail = {};
      this.editId = '';
      this.$nextTick().then(() => {
        this.showServiceManagementDialog = true;
      });
    },
    /**
     * 编辑
     */
    handleEdit(row) {
      this.editDetail = row;
      this.editId = row.serviceItemId;
      this.$nextTick().then(() => {
        this.showServiceManagementDialog = true;
      });
    },
    /**
     * 置空输入框数据
     */
    empty() {
      this.form = {
        isActive: true,
      };
      this.clearBol = true;
    },
    //选中的列表数据
    handleSelectionChange(val) {
      this.selectedIds = val.map((item) => {
        return item.serviceItemId;
      });
    },
  },
  created() {
    this.getData();
  },
};
</script>
<style lang="scss" scoped>
.el-form {
  width: 100%;
}

.priceSpan {
  margin-left: 10px;
  color: #606266;
  font-size: 12px;
}

.warningIcon {
  color: orange;
  margin-left: 10px;
}

.fade-enter-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.export-dialog__body {
  margin: 20px 0;
  text-align: center;
  .export-dialog-page__input {
    display: flex;
    justify-content: center;
    align-items: center;
    .el-input {
      width: 140px;
      margin: 0 15px;
    }
  }
  .export-dialog__tip {
    margin-top: 20px;
  }
}

.btn-dropdown:focus,
.btn-dropdown:hover {
  color: #606266;
  background: #f5f7fa;
  border-color: #f5f7fa;
}
</style>
