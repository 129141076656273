var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { attrs: { paddingTop: "" } },
    [
      _c(
        "el-tabs",
        {
          staticClass: "tabs",
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            {
              directives: [
                {
                  name: "auth",
                  rawName: "v-auth",
                  value: 762,
                  expression: "762",
                },
              ],
              attrs: {
                label: "事故车进店日报(门店)",
                name: "entryReportDaily",
              },
            },
            [_c("wreckedCarEntryCountDailyStatList")],
            1
          ),
          _c(
            "el-tab-pane",
            {
              directives: [
                {
                  name: "auth",
                  rawName: "v-auth",
                  value: 764,
                  expression: "764",
                },
              ],
              attrs: { label: "目标配置", name: "targetSettings" },
            },
            [_c("wreckedCarEntryCountCompanySettings")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }