var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "温馨提示",
        visible: _vm.visibility,
        width: "50%",
        "close-on-click-modal": false,
        "before-close": _vm.handleClose,
      },
    },
    [
      _vm.inconsistentQuantityList.length
        ? _c("p", { staticClass: "tip-word" }, [
            _vm._v(
              " 当前接车单存在商品报价数量与出库数量不一致情况（标红商品），请确认。 "
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        [
          _vm.inconsistentQuantityList.length
            ? _c(
                "el-table",
                {
                  key: 1,
                  staticClass: "service-table",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.inconsistentQuantityList,
                    border: "",
                    "default-expand-all": "",
                    "row-key": "id",
                    "tree-props": {
                      children: "billGoods",
                      hasChildren: "hasChildren",
                    },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "title", width: "80", label: "序号" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "报价项目", width: "180" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "div",
                                { class: { red: row.type === "goods" } },
                                [_vm._v(_vm._s(row.name))]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1978783380
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "code",
                      label: "报价项目编码",
                      width: "180",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "div",
                                { class: { red: row.type === "goods" } },
                                [_vm._v(_vm._s(row.code))]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1886938910
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "num",
                      label: "报价数量",
                      "min-width": "120",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "div",
                                { class: { red: row.type === "goods" } },
                                [_vm._v(_vm._s(row.num))]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2380797125
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "num",
                      label: "出库数量",
                      "min-width": "120",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "div",
                                { class: { red: row.type === "goods" } },
                                [_vm._v(" " + _vm._s(row.pickedNum) + " ")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      94080309
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.inconsistentPricesList.length
        ? _c("p", { staticClass: "tip-word" }, [
            _vm._v(" 当前接车单存在商品报价小于成本价（标红商品），请确认。 "),
          ])
        : _vm._e(),
      _vm.inconsistentPricesList.length
        ? _c(
            "el-table",
            {
              key: 2,
              staticClass: "service-table",
              staticStyle: { width: "100%", "margin-bottom": "40px" },
              attrs: {
                data: _vm.inconsistentPricesList,
                border: "",
                "default-expand-all": "",
                "row-key": "id",
                "tree-props": {
                  children: "billGoods",
                  hasChildren: "hasChildren",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "title", width: "80", label: "序号" },
              }),
              _c("el-table-column", {
                attrs: { prop: "name", label: "报价项目", width: "180" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c("div", { class: { red: row.type === "goods" } }, [
                            _vm._v(_vm._s(row.name)),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1978783380
                ),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "itemCode",
                  label: "报价项目编码",
                  width: "180",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c("div", { class: { red: row.type === "goods" } }, [
                            _vm._v(_vm._s(row.code)),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1886938910
                ),
              }),
              _c("el-table-column", {
                attrs: { prop: "num", label: "报价价格", "min-width": "120" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c("div", { class: { red: row.type === "goods" } }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm._f("toThousands")(row.total)) +
                                " "
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  146656126
                ),
              }),
              _c("el-table-column", {
                attrs: { prop: "num", label: "成本价格", "min-width": "120" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          row.type === "goods"
                            ? _c(
                                "div",
                                { class: { red: row.type === "goods" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("toThousands")(
                                          row.totalCostPrice
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2600240790
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleClose } },
            [_vm._v("知道了")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }