<template>
  <div class="search-wrapper">
    <div class="header">
      <!--	顶部搜索框		-->
      <el-form ref="formRef" :inline="true" :model="searchForm" class="demo-form-inline">
        <div class="header-left">
          <!--    理赔跟进/结算跟进      -->
          <template v-if="type == 'ClaimsFollowUp' || type == 'SettlementFollowUp'">
            <el-form-item label="操作门店" label-width="70px" prop="operateCompanyId">
              <operateCompanyCascader v-model="searchForm.operateCompanyId" />
            </el-form-item>
            <el-form-item label="车牌号" label-width="70px" prop="plateNumber">
              <el-input v-model="searchForm.plateNumber" clearable placeholder="请输入车牌号" @keyup.enter.native="onSubmit" />
            </el-form-item>
            <el-form-item label="接车单号" label-width="70px" prop="billCode">
              <el-input v-model="searchForm.billCode" clearable placeholder="请输入接车单号" @keyup.enter.native="onSubmit" />
            </el-form-item>
            <el-form-item label="业务来源" label-width="70px" prop="sourceName">
              <el-input v-model="searchForm.sourceName" clearable placeholder="请输入业务来源" @keyup.enter.native="onSubmit" />
            </el-form-item>
            <template v-if="toggleSearchStatus">
              <el-form-item label="保险公司" label-width="70px" prop="insuranceCompanyId">
                <el-select
                  v-model="searchForm.insuranceCompanyId"
                  v-loadMore="selectLoadMore"
                  :loading="loadMoreLoading"
                  :remote-method="getInsuranceCompany"
                  clearable
                  filterable
                  placeholder="搜索公司名称、编号"
                  remote
                  @visible-change="selectVisible">
                  <el-option v-for="item in insuranceCompanyList" :key="item.id" :label="item.insuranceName" :value="item.id" />
                </el-select>
              </el-form-item>
              <el-form-item label="事故责任" label-width="70px" prop="dutyType">
                <el-select v-model="searchForm.dutyType" clearable placeholder="请选择事故责任">
                  <el-option v-for="(item, index) in dutyTypeList" :key="index" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="客户名称" label-width="70px" prop="clientName">
                <el-input v-model="searchForm.clientName" clearable placeholder="请输入客户名称" @keyup.enter.native="onSubmit" />
              </el-form-item>
              <template v-if="type == 'ClaimsFollowUp'">
                <el-form-item label="解决方案" label-width="70px" prop="solutionType">
                  <el-select
                    v-model="searchForm.solutionType"
                    clearable
                    placeholder="请选择案件分类"
                    @visible-change="getClaimsSelect(solutionDataList)">
                    <el-option v-for="(item, index) in solutionDataList" :key="index" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="案件分类" label-width="70px" prop="categoryType">
                  <el-select
                    v-model="searchForm.categoryType"
                    clearable
                    placeholder="请选择案件分类"
                    @visible-change="getClaimsSelect(caseCategoryDataList)">
                    <el-option
                      v-for="(item, index) in caseCategoryDataList"
                      :key="index"
                      :label="item.label"
                      :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </template>
              <el-form-item label="分类" label-width="70px" prop="categoryType">
                <el-select
                  v-model="searchForm.categoryType"
                  clearable
                  placeholder="请选择案件分类"
                  @visible-change="getCategorySelect(categoryData)">
                  <el-option v-for="(item, index) in categoryData" :key="index" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="填写人" label-width="70px" prop="fillUserName">
                <el-input v-model="searchForm.fillUserName" clearable placeholder="请输入填写人" @keyup.enter.native="onSubmit" />
              </el-form-item>
              <el-form-item label="进店时间" label-width="70px" prop="intoStoreTime">
                <el-date-picker
                  v-model="searchForm.intoStoreTime"
                  clearable
                  end-placeholder="结束日期"
                  range-separator="至"
                  size="small"
                  start-placeholder="开始日期"
                  type="daterange"
                  value-format="yyyy-MM-dd" />
              </el-form-item>
              <el-form-item label="结算时间" label-width="70px" prop="checkTime">
                <el-date-picker
                  v-model="searchForm.checkTime"
                  clearable
                  end-placeholder="结束日期"
                  range-separator="至"
                  size="small"
                  start-placeholder="开始日期"
                  type="daterange"
                  value-format="yyyy-MM-dd" />
              </el-form-item>
            </template>
          </template>
          <!--    会计跟进      -->
          <template v-if="type == 'AccountingFollowUp'">
            <el-form-item label="操作门店" label-width="70px" prop="operateCompanyId">
              <operateCompanyCascader v-model="searchForm.operateCompanyId" />
            </el-form-item>
            <el-form-item label="车牌号" label-width="70px" prop="plateNumber">
              <el-input v-model="searchForm.plateNumber" clearable placeholder="请输入车牌号" @keyup.enter.native="onSubmit" />
            </el-form-item>
            <el-form-item label="接车单号" label-width="70px" prop="billCode">
              <el-input v-model="searchForm.billCode" clearable placeholder="请输入接车单号" @keyup.enter.native="onSubmit" />
            </el-form-item>
            <el-form-item label="业务来源" label-width="70px" prop="sourceName">
              <el-input v-model="searchForm.sourceName" clearable placeholder="请输入业务来源" @keyup.enter.native="onSubmit" />
            </el-form-item>
            <template v-if="toggleSearchStatus">
              <el-form-item label="保险公司" label-width="70px" prop="insuranceCompanyId">
                <el-select
                  v-model="searchForm.insuranceCompanyId"
                  v-loadMore="selectLoadMore"
                  :loading="loadMoreLoading"
                  :remote-method="getInsuranceCompany"
                  clearable
                  filterable
                  placeholder="搜索公司名称、编号"
                  remote
                  @visible-change="selectVisible">
                  <el-option v-for="item in insuranceCompanyList" :key="item.id" :label="item.insuranceName" :value="item.id" />
                </el-select>
              </el-form-item>
              <el-form-item label="事故责任" label-width="70px" prop="dutyType">
                <el-select v-model="searchForm.dutyType" clearable placeholder="请选择事故责任">
                  <el-option v-for="(item, index) in dutyTypeList" :key="index" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="客户名称" label-width="70px" prop="clientName">
                <el-input v-model="searchForm.clientName" clearable placeholder="请输入客户名称" @keyup.enter.native="onSubmit" />
              </el-form-item>
              <el-form-item label="挂帐车队" label-width="70px" prop="pendingFleetName">
                <el-input v-model="searchForm.pendingFleetName" clearable placeholder="请输入挂帐车队" @keyup.enter.native="onSubmit" />
              </el-form-item>
              <el-form-item label="账单月份" label-width="70px" prop="billingMonth">
                <el-input v-model="searchForm.billingMonth" clearable placeholder="请输入账单月份" @keyup.enter.native="onSubmit" />
              </el-form-item>
              <el-form-item label="填写人" label-width="70px" prop="fillUserName">
                <el-input v-model="searchForm.fillUserName" clearable placeholder="请输入填写人" @keyup.enter.native="onSubmit" />
              </el-form-item>
              <el-form-item label="对账人" label-width="70px" prop="reconciliationUserName">
                <el-input v-model="searchForm.reconciliationUserName" clearable placeholder="请输入对账人" @keyup.enter.native="onSubmit" />
              </el-form-item>
              <el-form-item label="当前状态" label-width="70px" prop="currentState">
                <el-select
                  v-model="searchForm.currentState"
                  clearable
                  placeholder="请选择当前状态"
                  @visible-change="getAccountSelect(stateData)">
                  <el-option v-for="(item, index) in stateData" :key="index" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="进店时间" label-width="70px" prop="intoStoreTime">
                <el-date-picker
                  v-model="searchForm.intoStoreTime"
                  clearable
                  end-placeholder="结束日期"
                  range-separator="至"
                  size="small"
                  start-placeholder="开始日期"
                  type="daterange"
                  value-format="yyyy-MM-dd" />
              </el-form-item>
              <el-form-item label="结算时间" label-width="70px" prop="checkTime">
                <el-date-picker
                  v-model="searchForm.checkTime"
                  clearable
                  end-placeholder="结束日期"
                  range-separator="至"
                  size="small"
                  start-placeholder="开始日期"
                  type="daterange"
                  value-format="yyyy-MM-dd" />
              </el-form-item>
            </template>
          </template>
          <!--    跟进明细      -->
          <template v-if="type == 'FollowUpDetails'">
            <el-form-item label="操作门店" label-width="70px" prop="operateCompanyId">
              <operateCompanyCascader v-model="searchForm.operateCompanyId" />
            </el-form-item>
            <el-form-item label="车牌号" label-width="70px" prop="plateNumber">
              <el-input v-model="searchForm.plateNumber" clearable placeholder="请输入车牌号" @keyup.enter.native="onSubmit" />
            </el-form-item>
            <el-form-item label="接车单号" label-width="70px" prop="billCode">
              <el-input v-model="searchForm.billCode" clearable placeholder="请输入接车单号" @keyup.enter.native="onSubmit" />
            </el-form-item>
            <el-form-item label="业务来源" label-width="70px" prop="sourceName">
              <el-input v-model="searchForm.sourceName" clearable placeholder="请输入业务来源" @keyup.enter.native="onSubmit" />
            </el-form-item>
            <template v-if="toggleSearchStatus">
              <el-form-item label="保险公司" label-width="70px" prop="insuranceCompanyId">
                <el-select
                  v-model="searchForm.insuranceCompanyId"
                  v-loadMore="selectLoadMore"
                  :loading="loadMoreLoading"
                  :remote-method="getInsuranceCompany"
                  clearable
                  filterable
                  placeholder="搜索公司名称、编号"
                  remote
                  @visible-change="selectVisible">
                  <el-option v-for="item in insuranceCompanyList" :key="item.id" :label="item.insuranceName" :value="item.id" />
                </el-select>
              </el-form-item>
              <el-form-item label="事故责任" label-width="70px" prop="dutyType">
                <el-select v-model="searchForm.dutyType" clearable placeholder="请选择事故责任">
                  <el-option v-for="(item, index) in dutyTypeList" :key="index" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="客户名称" label-width="70px" prop="clientName">
                <el-input v-model="searchForm.clientName" clearable placeholder="请输入客户名称" @keyup.enter.native="onSubmit" />
              </el-form-item>
              <el-form-item label="新旧区分" label-width="70px" prop="newOrOld">
                <el-select v-model="searchForm.newOrOld" clearable placeholder="请选择" @visible-change="getFollowUpSelect(newOrOldData)">
                  <el-option v-for="(item, index) in newOrOldData" :key="index" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="历史坏账" label-width="70px" prop="badDebtsAmount">
                <el-input v-model="searchForm.badDebtsAmount" clearable placeholder="请输入" @keyup.enter.native="onSubmit" />
              </el-form-item>
              <el-form-item label="跟进部门" label-width="70px" prop="followUpDeptType">
                <el-select
                  v-model="searchForm.followUpDeptType"
                  clearable
                  placeholder="请选择部门"
                  @visible-change="getFollowUpSelect(followUpDeptTypeData)">
                  <el-option v-for="(item, index) in followUpDeptTypeData" :key="index" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>
              <el-form-item label="填写人" label-width="70px" prop="fillUserName">
                <el-input v-model="searchForm.fillUserName" clearable placeholder="请输入填写人" @keyup.enter.native="onSubmit" />
              </el-form-item>
              <el-form-item label="进店时间" label-width="70px" prop="intoStoreTime">
                <el-date-picker
                  v-model="searchForm.intoStoreTime"
                  clearable
                  end-placeholder="结束日期"
                  range-separator="至"
                  size="small"
                  start-placeholder="开始日期"
                  type="daterange"
                  value-format="yyyy-MM-dd" />
              </el-form-item>
              <el-form-item label="结算时间" label-width="70px" prop="checkTime">
                <el-date-picker
                  v-model="searchForm.checkTime"
                  clearable
                  end-placeholder="结束日期"
                  range-separator="至"
                  size="small"
                  start-placeholder="开始日期"
                  type="daterange"
                  value-format="yyyy-MM-dd" />
              </el-form-item>
            </template>
          </template>
          <!--    索赔跟进      -->
          <template v-if="type == 'ClaimDamage'">
            <el-form-item label="操作门店" label-width="70px" prop="operateCompanyId">
              <operateCompanyCascader v-model="searchForm.operateCompanyId" />
            </el-form-item>
            <el-form-item label="接车单号" label-width="70px" prop="billCode">
              <el-input v-model="searchForm.billCode" clearable placeholder="请输入" @keyup.enter.native="onSubmit" />
            </el-form-item>
            <el-form-item label="消费客户" label-width="70px" prop="consumerClientName">
              <el-input v-model="searchForm.consumerClientName" clearable placeholder="请输入" @keyup.enter.native="onSubmit" />
            </el-form-item>
            <el-form-item label="车牌号" label-width="70px" prop="plateNumber">
              <el-input v-model="searchForm.plateNumber" clearable placeholder="请输入" @keyup.enter.native="onSubmit" />
            </el-form-item>
            <template v-if="toggleSearchStatus">
              <el-form-item label="开票主体" label-width="70px" prop="invoiceId">
                <el-select
                  v-model="searchForm.invoiceId"
                  :loading="searchInvoiceLoading"
                  :remote-method="getSearchInvoice"
                  clearable
                  filterable
                  placeholder="搜索开票主体名称"
                  remote>
                  <el-option v-for="item in invoiceList" :key="item.companyId" :label="item.invoicingEntity" :value="item.companyId" />
                </el-select>
              </el-form-item>
              <el-form-item label="解决方案" label-width="70px" prop="solutionType">
                <el-select v-model="searchForm.solutionType" clearable placeholder="请选择" @visible-change="getSelect(SolutionData)">
                  <el-option v-for="(item, index) in SolutionData" :key="index" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>
              <el-form-item label="情况说明" label-width="70px" prop="statusDescType">
                <el-select v-model="searchForm.statusDescType" clearable placeholder="请选择" @visible-change="getSelect(descriptionData)">
                  <el-option v-for="(item, index) in descriptionData" :key="index" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>
              <el-form-item label="填写人" label-width="70px" prop="fillUserName">
                <el-input v-model="searchForm.fillUserName" clearable placeholder="请输入" @keyup.enter.native="onSubmit" />
              </el-form-item>
              <el-form-item label="进店日期" label-width="70px" prop="intoStoreTime">
                <el-date-picker
                  v-model="searchForm.intoStoreTime"
                  clearable
                  end-placeholder="结束日期"
                  range-separator="至"
                  size="small"
                  start-placeholder="开始日期"
                  type="daterange"
                  value-format="yyyy-MM-dd" />
              </el-form-item>
              <el-form-item label="结账(挂账)日期" prop="checkTime">
                <el-date-picker
                  v-model="searchForm.checkTime"
                  clearable
                  end-placeholder="结束日期"
                  range-separator="至"
                  size="small"
                  start-placeholder="开始日期"
                  type="daterange"
                  value-format="yyyy-MM-dd" />
              </el-form-item>
            </template>
          </template>
          <!--    免赔变动信息表      -->
          <template v-if="type == 'DeductibleChanges'">
            <el-form-item label="变动时间" label-width="70px" prop="EntryTime">
              <el-date-picker
                v-model="searchForm.EntryTime"
                clearable
                end-placeholder="结束日期"
                range-separator="至"
                size="small"
                start-placeholder="开始日期"
                type="daterange"
                value-format="yyyy-MM-dd" />
            </el-form-item>
            <el-form-item label="报案号" prop="caseNum">
              <el-input v-model="searchForm.caseNum" clearable placeholder="请输入" @keyup.enter.native="onSubmit" />
            </el-form-item>
            <el-form-item label="车单号" prop="billCode">
              <el-input v-model="searchForm.billCode" clearable placeholder="请输入" @keyup.enter.native="onSubmit" />
            </el-form-item>
            <el-form-item label="客户" prop="clientName">
              <el-input v-model="searchForm.clientName" clearable placeholder="请输入" @keyup.enter.native="onSubmit" />
            </el-form-item>
          </template>
        </div>

        <div class="header-right">
          <!--   @type       -->
          <!--   ClaimsFollowUp           理赔跟进       -->
          <!--   SettlementFollowUp       结算跟进       -->
          <!--   AccountingFollowUp       会计跟进       -->
          <!--   FollowUpDetails          跟进明细       -->
          <!--   ClaimDamage              索赔跟进       -->

<!--          <el-link
            v-if="
              type == 'ClaimsFollowUp' ||
              type == 'SettlementFollowUp' ||
              type == 'AccountingFollowUp' ||
              type == 'FollowUpDetails' ||
              type == 'ClaimDamage'
            "
            :underline="false"
            class="linkText"
            type="primary"
            @click="toggleStatus">
            {{ toggleSearchStatus ? "收起" : "展开" }}
            <i :class="toggleSearchStatus ? 'up' : 'down'" class="el-icon-d-arrow-right"></i>
          </el-link>-->

          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="onSubmit">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-refresh-right" @click="reset('formRef')">清空</el-button>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import operateCompanyCascader from "@/components/businessComponent/operateCompanyCascader.vue";
import {
  batchImportClaimsFollowResultApi,
  getAccountFollowSelect,
  getClaimDamageSelect,
  getClaimsFollowSelect,
  getFollowUpDetailSelect,
  getSettlementSelect,
} from "@/api/financial/financialOver";
import { searchInvoice } from "@/api/storeManagement";
import { searchInsuranceCompanyWithInvoiceAndPaging } from "@/api/customer/sysmanent";
import { mapState } from "vuex";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Search",
  components: {
    operateCompanyCascader,
  },
  props: {
    type: {
      type: String,
      default: "",
    },
  },
  watch: {},
  computed: {
    ...mapState({
      companyId: state => state.user.userInfo.companyId,
    }),
  },
  data() {
    return {
      searchForm: {},
      toggleSearchStatus: true,
      SolutionData: [],
      descriptionData: [],
      dutyTypeList: [
        { value: 0, label: "全责" },
        { value: 1, label: "无责" },
        { value: 2, label: "主责" },
        { value: 3, label: "次责" },
        { value: 4, label: "同责" },
      ],
      newOrOldData: [],
      followUpDeptTypeData: [],
      invoiceList: [],
      searchInvoiceLoading: false,
      insuranceCompanyList: [],
      loadMoreLoading: false,
      pageSize: 15,
      currentPage: 1,
      total: 0,
      stateData: [],
      categoryData: [],
      solutionDataList: [],
      caseCategoryDataList: [],
    };
  },
  created() {
    this.getSearchInvoice();
    if (
      this.type == "ClaimsFollowUp" ||
      this.type == "SettlementFollowUp" ||
      this.type == "AccountingFollowUp" ||
      this.type == "FollowUpDetails" ||
      this.type == "ClaimDamage"
    ) {
      const date = new Date();
      const currentYear = date.getFullYear();
      const currentMonth = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
      const today = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
      let timeStart = `${currentYear}-${currentMonth}-01`;
      let time = `${currentYear}-${currentMonth}-${today}`;
      this.$set(this.searchForm, "checkTime", [timeStart, time])
      // this.$set(this.searchForm, "checkTime", ['2024-10-01', '2024-10-31'])
    }
  },
  mounted() {},
  methods: {
    batchImportClaimsFollowResultApi,
    /** 搜索查询 **/
    onSubmit() {
      this.$emit("submit", this.searchForm);
    },
    /** 重置搜索框 **/
    reset(formName) {
      // this.$refs[formName].resetFields();
      Object.keys(this.searchForm).forEach(key => {
        this.searchForm[key] = "";
      });
      this.searchForm.operateCompanyId = [];
      this.$forceUpdate();
      this.$emit("clearForm", "");
    },
    /** 展开/收起 **/
    toggleStatus() {
      this.toggleSearchStatus = !this.toggleSearchStatus;
      this.$emit("toggleStatus", this.toggleSearchStatus);
    },
    /** 获取解决方案/情况说明下拉框数据 **/
    getSelect(arr) {
      console.log("arr-->>>", arr);
      if (arr.length <= 0) {
        getClaimDamageSelect().then(res => {
          this.SolutionData = res.solutionEnumList;
          this.descriptionData = res.statusDescriptionEnumList;
        });
      }
    },
    /** 获取新旧说明/跟进部门下拉框数据 **/
    getFollowUpSelect(arr) {
      console.log("arr-->>>", arr);
      if (arr.length <= 0) {
        getFollowUpDetailSelect().then(res => {
          this.newOrOldData = res.oldOrNewEnumList;
          this.followUpDeptTypeData = res.followupDeptEnumList;
        });
      }
    },
    /** 获取状态下拉框数据 **/
    getAccountSelect(arr) {
      console.log("arr-->>>", arr);
      if (arr.length <= 0) {
        getAccountFollowSelect().then(res => {
          this.stateData = res.currentStateEnumList;
        });
      }
    },
    /** 获取分类下拉框数据 **/
    getCategorySelect(arr) {
      console.log("arr-->>>", arr);
      if (arr.length <= 0) {
        getSettlementSelect().then(res => {
          this.categoryData = res.categoryTypeEnumList;
        });
      }
    },
    /** 获取解决方案/案件分类下拉框数据 **/
    getClaimsSelect(arr) {
      console.log("arr-->>>", arr);
      if (arr.length <= 0) {
        getClaimsFollowSelect().then(res => {
          this.solutionDataList = res.solutionEnumList;
          this.caseCategoryDataList = res.caseCategoryEnumList;
        });
      }
    },
    /** 远程搜索开票主体门店列表 **/
    getSearchInvoice(keyWord = "") {
      this.searchInvoiceLoading = true;
      searchInvoice(keyWord, this.companyId)
        .then(res => {
          this.invoiceList = res;
        })
        .finally(() => {
          this.searchInvoiceLoading = false;
        });
    },
    /** 保险公司远程搜索下拉数据 **/
    getInsuranceCompany(keyWord = "", type) {
      if ((!keyWord && !type) || (keyWord && !type)) {
        this.pageSize = 15;
        this.currentPage = 1;
        this.insuranceCompanyList = [];
      }
      this.loadMoreLoading = false;
      searchInsuranceCompanyWithInvoiceAndPaging(keyWord, this.companyId, this.pageSize, this.currentPage).then(res => {
        this.insuranceCompanyList.push(...res.records);
        this.total = Number(res.total);
      });
    },
    /** select下拉框，上拉加载更多 **/
    selectLoadMore() {
      if (this.insuranceCompanyList.length < this.total) {
        this.loadMoreLoading = true;
        this.currentPage++;
        this.getInsuranceCompany("", 1);
      }
    },
    /** select **/
    selectVisible(e) {
      if (e) {
        this.pageSize = 15;
        this.currentPage = 1;
        this.insuranceCompanyList = [];
        this.getInsuranceCompany();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search-wrapper {
  padding: 14px;

  .header {
    background: #efefef;
    padding: 14px 14px 0;
    border-radius: 8px;

    .el-form-item {
      margin-bottom: 14px !important;
    }

    :deep .el-form-item__label {
      line-height: 32px !important;
    }

    :deep .el-form-item__content {
      line-height: 32px !important;
    }

    :deep .el-input__inner {
      width: 225px !important;
      height: 32px !important;
      line-height: 32px !important;
    }

    :deep .el-button {
      padding: 8px 16px !important;
    }

    .demo-form-inline {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      :deep .el-date-editor {
        width: auto !important;
      }

      :deep .el-date-editor--daterange {
        width: 225px !important;
      }

      :deep .el-cascader {
        width: auto !important;
      }
    }

    .header-left {
      //width: 80%;
      width: 85%;
    }

    .header-right {
      //width: 20%;
      width: 15%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
    }

    .linkText {
      margin-right: 14px;
      margin-bottom: 14px;
      margin-top: 12px;
    }
  }

  .up {
    margin-left: 2px;
    transform: rotate(-90deg);
  }

  .down {
    margin-left: 2px;
    transform: rotate(90deg);
  }
}
</style>
