var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { attrs: { paddingTop: "" } },
    [
      _c(
        "div",
        { staticClass: "switch-style flex" },
        [
          _c("p", [_vm._v("车间工单")]),
          _c("el-switch", {
            staticClass: "el-icon--right",
            on: { change: _vm.changeSingleValue },
            model: {
              value: _vm.singleValue,
              callback: function ($$v) {
                _vm.singleValue = $$v
              },
              expression: "singleValue",
            },
          }),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.singleValue,
                  expression: "singleValue",
                },
              ],
            },
            [_vm._v("点击关闭施工单流程，后车单将无需走施工流程")]
          ),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.singleValue == false,
                  expression: "singleValue == false",
                },
              ],
            },
            [_vm._v("点击开启施工单流程，选中的门店后续车单将走施工流程")]
          ),
        ],
        1
      ),
      _c(
        "p",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.singleValue,
              expression: "singleValue",
            },
          ],
          staticClass: "orange",
          staticStyle: { "font-size": "12px", "margin-bottom": "12px" },
        },
        [
          _vm._v(
            " 注：下方门店显示范围为当前登录账号所属管辖组范围的门店，不在登陆账号管辖组范围的不显示 "
          ),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.singleValue,
              expression: "singleValue",
            },
          ],
          staticClass: "flex",
          on: { click: _vm.titleShow },
        },
        [
          _c("p", { staticClass: "blue pointer" }, [
            _vm._v(_vm._s(_vm.tipTitle)),
          ]),
          _c("i", {
            staticClass: "iconfont",
            class: {
              "grow-icon_Choice": !_vm.showIcon,
              "grow-icon_Choice_activate": _vm.showIcon,
            },
            staticStyle: { color: "#9a9a9a", "margin-left": "3px" },
          }),
        ]
      ),
      _vm.showTable && _vm.singleValue
        ? _c("div", [
            _c("div", { staticClass: "chioce-style flex" }, [
              _c("p", [_vm._v("门店名称")]),
              _c(
                "div",
                { staticStyle: { width: "60%", "margin-top": "8px" } },
                [
                  _c(
                    "el-select",
                    {
                      key: _vm.selectKey,
                      attrs: {
                        size: "small",
                        filterable: "",
                        clearable: "",
                        remote: "",
                        placeholder: "请输入门店名称",
                        "remote-method": _vm.searchStore,
                        loading: _vm.searchStoreLoading,
                      },
                      on: { change: _vm.selectStore, clear: _vm.deleteStore },
                      model: {
                        value: _vm.storeName,
                        callback: function ($$v) {
                          _vm.storeName = $$v
                        },
                        expression: "storeName",
                      },
                    },
                    _vm._l(_vm.storeList, function (item) {
                      return _c(
                        "el-option",
                        {
                          key: item.companyId,
                          attrs: {
                            label: item.companyName,
                            value: item.companyId,
                          },
                        },
                        [
                          _c("span", { staticStyle: { float: "left" } }, [
                            _vm._v(_vm._s(item.companyName)),
                          ]),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "el-table-infinite-scroll",
                        rawName: "v-el-table-infinite-scroll",
                        value: _vm.loadTableData,
                        expression: "loadTableData",
                      },
                    ],
                    ref: "multipleTable",
                    staticClass: "ant-table-body",
                    staticStyle: {
                      width: "40%",
                      "border-radius": "0px 0px 4px 4px",
                    },
                    attrs: {
                      data: _vm.tableData,
                      height: "300",
                      border: "",
                      "infinite-scroll-disabled": !_vm.hasMore,
                    },
                    on: { "selection-change": _vm.handleSelectionChange },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { type: "selection", width: "40" },
                    }),
                    _c("el-table-column", {
                      attrs: { type: "index", label: "序号", width: "80" },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "companyName",
                        label: "车店名称",
                        width: "180",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "chainCompanyName", label: "连锁类型" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          { staticClass: "flex-x-end foot-btn" },
          [
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.save },
              },
              [_vm._v("保存")]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }