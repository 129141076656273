<template>
  <el-dialog
    title="保养方案"
    :visible="maintenancePlanDialogVisibility"
    size="50%"
    :append-to-body="true"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <div class="base-container">
      <el-row>
        <el-col :span="2" class="icon-sty">
          <img v-if="carInfo.carLogoIcon" :src="carInfo.carLogoIcon" />
          <img v-else src="@/assets/img/carLogo.png" />
        </el-col>
        <el-col :span="22" class="car-info">
          <p>
            {{ carInfo.carNumber }}<span>{{ carInfo.vehicleType }}</span
            ><span>{{ carInfo.uniqueId }}</span>
          </p>
          <p>当前行驶里程：{{ mileage }}KM</p>
        </el-col>
      </el-row>
      <el-row>
        <p class="car-tip">
          已根据车辆厂家提供的<span @click="maintenanceDialog = true"
            >保养手册</span
          >推荐如下保养方案，可根据实际情况手动修改调整项目
        </p>
      </el-row>
      <el-row class="maintenance-type">
        <div v-for="(item, index) in mainType" :key="index">
          <p>{{ item.maintenanceItemName }}</p>
        </div>
      </el-row>
      <p class="maintenance-type-tip">请选择保养工时与商品</p>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="mainName" label="保养项目"> </el-table-column>
        <el-table-column prop="name" label="项目名称"> </el-table-column>
        <el-table-column prop="mainId" label="项目编码"> </el-table-column>
        <el-table-column prop="caozuo" label="操作"> </el-table-column>
        <el-table-column prop="price" label="单价">
          <template>
            <el-input size="small"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="discount" label="折扣">
          <template>
            <el-input size="small"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="number" label="数量">
          <template>
            <el-input size="small"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="combin" label="小计">
          <template>
            <el-input size="small"></el-input>
          </template>
        </el-table-column>
        <template slot="append">
          <div class="orange aggreAmount">金额合计：￥5983.15</div>
        </template>
      </el-table>
    </div>
    <template slot="footer">
      <el-button type="primary " size="small" @click="handleClose"
        >添加报价</el-button
      >
      <el-button size="small" @click="handleClose" style="margin-right: 20px"
        >取 消</el-button
      >
    </template>
    <el-dialog
      title="保养手册"
      :visible.sync="maintenanceDialog"
      size="50%"
      :append-to-body="true"
      :before-close="handleCloseTwo"
      :close-on-click-modal="false"
    >
      <el-table :data="tableData2" style="width: 100%">
        <el-table-column prop="name" label="保养项目"> </el-table-column>
        <el-table-column prop="fiveKilometers" label="5000KM">
        </el-table-column>
        <el-table-column prop="tenKilometers" label="10000KM">
        </el-table-column>
        <el-table-column prop="fifteenKilometers" label="15000KM">
        </el-table-column>
        <el-table-column prop="twentyKilometers" label="20000KM">
        </el-table-column>
        <el-table-column prop="twentyFiveKilometers" label="25000KM">
        </el-table-column>
        <el-table-column prop="thirtyKilometers" label="30000KM">
        </el-table-column>
        <el-table-column prop="thirtyFiveKilometers" label="35000KM">
        </el-table-column>
        <el-table-column prop="fortyKilometers" label="40000KM">
        </el-table-column>
      </el-table>
    </el-dialog>
  </el-dialog>
</template>
<script>
import { getMaintenancePlan } from '@/api/carBill';
export default {
  name: 'maintenancePlanDialog',
  props: {
    maintenancePlanDialogVisibility: { type: Boolean, default: false },
    mileage: {
      type: [Number, String],
      default: 0,
    },
    carInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      mainType: [],
      tableData: [
        {
          mainName: '机油',
          name: '小保养',
          mainId: '15896423',
          caozuo: '-',
          price: '',
          dicount: '',
          number: '',
          combin: '',
        },
        {
          mainName: '空气滤清器',
          name: '小保养',
          mainId: '15895423',
          caozuo: '-',
          price: '',
          dicount: '',
          number: '',
          combin: '',
        },
        {
          mainName: '燃油',
          name: '小保养',
          mainId: '56346423',
          caozuo: '-',
          price: '',
          dicount: '',
          number: '',
          combin: '',
        },
      ],
      maintenanceDialog: false,
      tableData2: [
        {
          name: '机油保养',
          fiveKilometers: '5000',
          tenKilometers: '10000',
          fifteenKilometers: '15000',
          twentyKilometers: '20000',
          twentyFiveKilometers: '25000',
          thirtyKilometers: '30000',
          thirtyFiveKilometers: '35000',
          fortyKilometers: '40000',
        },
        {
          name: '燃油保养',
          fiveKilometers: '5000',
          tenKilometers: '10000',
          fifteenKilometers: '15000',
          twentyKilometers: '20000',
          twentyFiveKilometers: '25000',
          thirtyKilometers: '30000',
          thirtyFiveKilometers: '35000',
          fortyKilometers: '40000',
        },
      ],
    };
  },

  created() {
    getMaintenancePlan({
      carId: this.carInfo.carId,
      mileage: this.mileage,
      companyId: this.$store.state.user.companyId,
    }).then((res) => {
      console.log(res);
      this.mainType = res.recommendMaintenanceInfo?.recommendGoods || [];
    });
  },
  methods: {
    //关闭抽屉
    handleClose() {
      this.$emit('update:maintenancePlanDialogVisibility', false);
    },
    //关闭内弹窗
    handleCloseTwo() {
      this.maintenanceDialog = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.base-container {
  height: 60vh;
  overflow-y: scroll;
}
.icon-sty {
  text-align: center;
  i {
    font-size: 50px;
  }
}
.car-info {
  font-size: 30px;
  color: #333333;
  span {
    padding: 2px 10px;
    margin-left: 5px;
    font-size: 10px !important;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #42bbb8;
    border: 1px solid #42bb#000000b8;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
  p:last-child {
    margin-top: 5px;
    font-size: 16px;
    color: #666666;
  }
}
.car-tip {
  margin: 37px 0px 10px;
  font-size: 14px;
  color: #666666;
  span {
    color: #42bbb8;
  }
}
.maintenance-type {
  display: flex;
  width: 100%;
  flex-flow: wrap;
  margin-top: 20px;
  div {
    padding: 5px 10px;
    border: 1px solid #666666;
    border-radius: 4px;
    margin-right: 30px;
    margin-bottom: 10px;
    font-size: 14px;
  }
}
.maintenance-type-tip {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  margin: 20px 0px 10px;
}
.aggreAmount {
  height: 50px;
  line-height: 50px;
  background: #fafafa;
  margin-left: 10px;
}
</style>
