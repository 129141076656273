var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { ref: "baseContainer", attrs: { paddingTop: "" } },
    [
      [
        _c(
          "el-form",
          {
            staticClass: "filter-form",
            attrs: { model: _vm.form, "label-width": "100px", size: "small" },
          },
          [
            _c("div", { staticClass: "flex-x-between" }, [
              _c(
                "div",
                { staticClass: "flex flex-shrink0" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "操作门店" } },
                    [
                      _c("operateCompanySelect", {
                        attrs: {
                          maxCollapseTags: 3,
                          placeholder: "请选择操作门店, 可以多选",
                          operateCompanyUrl: _vm.form.operateCompanyUrl,
                        },
                        model: {
                          value: _vm.form.companyId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "companyId", $$v)
                          },
                          expression: "form.companyId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品名称" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入商品名称", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.getData.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.form.goodName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "goodName", $$v)
                          },
                          expression: "form.goodName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品编码" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入商品编码", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.getData.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.form.goodCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "goodCode", $$v)
                          },
                          expression: "form.goodCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "el-form-item--small" }, [
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "auth",
                            rawName: "v-auth",
                            value: 624,
                            expression: "624",
                          },
                        ],
                        staticClass: "btn_search",
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.getData },
                      },
                      [_vm._v("查询")]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "auth",
                            rawName: "v-auth",
                            value: 624,
                            expression: "624",
                          },
                        ],
                        staticClass: "btn_search",
                        attrs: { size: "small" },
                        on: { click: _vm.empty },
                      },
                      [_vm._v("置空")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "flex-warp" },
              [
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "库存预警" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择库存预警状态" },
                          model: {
                            value: _vm.form.isWarning,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "isWarning", $$v)
                            },
                            expression: "form.isWarning",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: "" },
                          }),
                          _c("el-option", {
                            attrs: { label: "启用", value: 1 },
                          }),
                          _c("el-option", {
                            attrs: { label: "禁用", value: 0 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "汇总显示" } },
                    [
                      _c("el-switch", {
                        staticClass: "ml-2",
                        staticStyle: {
                          "--el-switch-on-color": "#13ce66",
                          "--el-switch-off-color": "#ff4949",
                        },
                        model: {
                          value: _vm.form.isSummary,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "isSummary", $$v)
                          },
                          expression: "form.isSummary",
                        },
                      }),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        ),
      ],
      [
        _c(
          "div",
          {
            staticClass: "btns flex-x-between",
            staticStyle: { margin: "30px 0 14px 0" },
          },
          [
            _c(
              "div",
              { staticClass: "supplier-management__btn" },
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "auth",
                        rawName: "v-auth",
                        value: 625,
                        expression: "625",
                      },
                    ],
                    staticClass: "btn_insert",
                    staticStyle: { height: "35px" },
                    attrs: { size: "small" },
                    on: { click: _vm.add },
                  },
                  [_vm._v("新增")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "auth",
                        rawName: "v-auth",
                        value: 631,
                        expression: "631",
                      },
                    ],
                    staticClass: "btn_insert",
                    staticStyle: { height: "35px" },
                    attrs: { size: "small" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.batchOperation(2, "批量删除")
                      },
                    },
                  },
                  [_vm._v("批量删除")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "auth",
                        rawName: "v-auth",
                        value: 627,
                        expression: "627",
                      },
                    ],
                    staticClass: "btn_insert",
                    staticStyle: { height: "35px", "margin-left": "10px" },
                    attrs: { size: "small" },
                    on: {
                      click: function ($event) {
                        _vm.importDialogVisibility = true
                      },
                    },
                  },
                  [
                    _vm._v("批量导入"),
                    _c("i", { staticClass: "el-icon-arrow-right" }),
                  ]
                ),
              ],
              1
            ),
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 628,
                    expression: "628",
                  },
                ],
                staticClass: "blue",
                attrs: { size: "small", type: "text" },
                on: {
                  click: function ($event) {
                    _vm.showExportDialog = true
                  },
                },
              },
              [
                _vm._v("导出列表"),
                _c("i", {
                  staticClass: "iconfont grow-icon_daochu el-icon--right",
                }),
              ]
            ),
          ],
          1
        ),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "fixedScrollBar",
                rawName: "v-fixedScrollBar",
                value: _vm.$refs.baseContainer,
                expression: "$refs.baseContainer",
              },
            ],
            staticClass: "custom-table",
            attrs: { data: _vm.listData, border: "" },
            on: { "selection-change": _vm.handleSelectionChange },
          },
          [
            _c("el-table-column", {
              attrs: {
                type: "selection",
                width: "60",
                prop: "selection",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: { type: "index", label: "序号", width: "60" },
            }),
            !_vm.selectedSummary
              ? _c("el-table-column", {
                  attrs: { label: "操作", width: "100" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row && scope.row.id && scope.row.id > 0
                              ? _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "auth",
                                        rawName: "v-auth",
                                        value: 626,
                                        expression: "626",
                                      },
                                    ],
                                    staticClass: "text_operation blue",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleEdit(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2387594622
                  ),
                })
              : _vm._e(),
            !_vm.selectedSummary
              ? _c("el-table-column", {
                  attrs: { width: "120", label: "门店", prop: "companyName" },
                })
              : _vm._e(),
            _c("el-table-column", {
              attrs: { width: "140", label: "商品ID", prop: "goodsId" },
            }),
            _c("el-table-column", {
              attrs: { width: "140", label: "商品名称", prop: "goodName" },
            }),
            _c("el-table-column", {
              attrs: {
                width: "180",
                label: "是否禁止采购",
                prop: "disablePurchaseStr",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "140",
                label: "商品大类",
                prop: "goodFirstCategory",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "140",
                label: "商品细类",
                prop: "goodSecondCategory",
              },
            }),
            _c("el-table-column", {
              attrs: { width: "120", label: "商品品牌", prop: "goodBrand" },
            }),
            _c("el-table-column", {
              attrs: { width: "120", label: "商品编码", prop: "goodCode" },
            }),
            _c("el-table-column", {
              attrs: { width: "120", label: "商品规格", prop: "goodsFormat" },
            }),
            _c("el-table-column", {
              attrs: { width: "120", label: "特征", prop: "feature" },
            }),
            _c("el-table-column", {
              attrs: { width: "120", label: "品质", prop: "manufactoryType" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      row.manufactoryTypeOption &&
                      row.manufactoryTypeOption.desc
                        ? _c("span", [
                            _vm._v(
                              " " + _vm._s(row.manufactoryTypeOption.desc) + " "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "120", label: "品名", prop: "prodName" },
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "单位", prop: "unit" },
            }),
            _c("el-table-column", {
              attrs: {
                width: "210",
                label: "近一周领料出库量",
                prop: "lastWeekShowOut",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "210",
                label: "近一个月领料出库量",
                prop: "lastMonthShowOut",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "210",
                label: "前3个月月均领料出库数量",
                prop: "firstThreeMonthsShowOut",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "210",
                label: "前6个月月均领料出库数量",
                prop: "firstSixMonthsShowOut",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "210",
                label: "前3个月月均其他出库数量",
                prop: "firstThreeMonthsOtherOut",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "210",
                label: "前6个月月均其他出库数量",
                prop: "firstSixMonthsOtherOut",
              },
            }),
            !_vm.selectedSummary
              ? _c("el-table-column", {
                  attrs: { width: "100", label: "安全库存", prop: "safeStock" },
                })
              : _vm._e(),
            !_vm.selectedSummary
              ? _c("el-table-column", {
                  attrs: {
                    width: "100",
                    label: "预警状态",
                    prop: "warnStatus",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm.showEditDialog
          ? _c("edit-inventory-warning-dialog", {
              attrs: {
                visibility: _vm.showEditDialog,
                id: _vm.editId,
                entityData: _vm.rowEntityData,
                companyList: _vm.companyList,
              },
              on: {
                "update:visibility": function ($event) {
                  _vm.showEditDialog = $event
                },
                save: _vm.getData,
              },
            })
          : _vm._e(),
        _c("export-dialog", {
          attrs: {
            visibility: _vm.showExportDialog,
            total: _vm.total,
            pageOption: _vm.pageOption,
            filterForm: _vm.form,
            url: _vm.exportUrl,
          },
          on: {
            "update:visibility": function ($event) {
              _vm.showExportDialog = $event
            },
          },
        }),
        _vm.importDialogVisibility
          ? _c("import-dialog", {
              attrs: {
                visibility: _vm.importDialogVisibility,
                title: "库存预警导入",
                templateFileUrl:
                  "https://growxny-dev-center.oss-cn-guangzhou.aliyuncs.com/template/20240408/%E5%BA%93%E5%AD%98%E9%A2%84%E8%AD%A6%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx",
                uploadFileApi: _vm.fileImportApi,
                getLastResultApi: _vm.fileImportResultApi,
                importFailDetail: _vm.fileImportMessageExportUrlApi,
              },
              on: {
                "update:visibility": function ($event) {
                  _vm.importDialogVisibility = $event
                },
              },
            })
          : _vm._e(),
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c("pagination", {
            attrs: { total: _vm.total },
            on: { pageChange: _vm.changePage },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }