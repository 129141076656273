var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visibility,
        width: "40%",
        "close-on-click-modal": false,
        "before-close": _vm.handleClose,
        title: "选择客户",
        "append-to-body": "",
      },
    },
    [
      _c(
        "el-popover",
        {
          attrs: {
            placement: "bottom",
            trigger: "manual",
            "popper-class": "popper-class",
          },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c(
            "el-table",
            {
              staticClass: "car-table",
              attrs: { "max-height": 400, border: "", data: _vm.listData },
              on: { "row-click": _vm.handleClick },
            },
            [
              _c("el-table-column", {
                attrs: { width: "90 ", label: "客户名称", prop: "clientName" },
              }),
              _c("el-table-column", {
                attrs: { width: "160", label: "协议客户" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.isAgreementClient == 1
                          ? _c("p", [
                              _vm._v(" 协议客户"),
                              row.beContract
                                ? _c("span", [_vm._v("（包干）")])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        row.isAgreementClient == 0 ? _c("p") : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { width: "90 ", label: "等级", prop: "clientLevel" },
              }),
              _c("template", { slot: "append" }, [
                _vm.hasMore
                  ? _c(
                      "div",
                      {
                        staticClass: "load-more-btn",
                        on: {
                          click: function ($event) {
                            return _vm.getData(false, true)
                          },
                        },
                      },
                      [_vm._v(" 加载更多数据 ")]
                    )
                  : _vm._e(),
              ]),
            ],
            2
          ),
          _c(
            "template",
            { slot: "reference" },
            [
              _c("el-input", {
                ref: "keyWordInput",
                staticStyle: { width: "300px" },
                attrs: {
                  size: "small",
                  placeholder: "搜索客户名称",
                  clearable: "",
                },
                on: {
                  input: function ($event) {
                    return _vm.getData(true)
                  },
                  focus: function ($event) {
                    _vm.visible = true
                  },
                  clear: _vm.handleClear,
                },
                model: {
                  value: _vm.keyWord,
                  callback: function ($$v) {
                    _vm.keyWord = $$v
                  },
                  expression: "keyWord",
                },
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "auth",
                      rawName: "v-auth",
                      value: 61,
                      expression: "61",
                    },
                  ],
                  staticStyle: { margin: "0 15px" },
                },
                [_vm._v("或")]
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "auth",
                      rawName: "v-auth",
                      value: 61,
                      expression: "61",
                    },
                  ],
                  staticClass: "primary text_operation",
                  on: {
                    click: function ($event) {
                      _vm.showAddCusterDialog = true
                    },
                  },
                },
                [_vm._v("新增")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.clientInfo.id,
              expression: "clientInfo.id",
            },
          ],
          staticClass: "client",
        },
        [
          _c("i", {
            staticClass: "iconfont grow-icon_delete3 icon_delete pointer",
            on: { click: _vm.deleteClient },
          }),
          _c("div", { staticClass: "client-info" }, [
            _c("i", {
              staticClass: "iconfont icon_grade",
              class: _vm.levelIconsOther[_vm.clientInfo.clientLevel],
              attrs: { title: [_vm.clientInfo.clientLevel] || "" },
            }),
            _c("span", [_vm._v(_vm._s(_vm.clientInfo.clientName))]),
            _vm.clientInfo.isAgreementClient == 1
              ? _c("span", [_vm._v("协议客户（包干） ")])
              : _vm._e(),
          ]),
        ]
      ),
      _vm.showAddCusterDialog
        ? _c("add-customer-dialog", {
            attrs: { showAddCusterDialog: _vm.showAddCusterDialog },
            on: {
              "update:showAddCusterDialog": function ($event) {
                _vm.showAddCusterDialog = $event
              },
              "update:show-add-custer-dialog": function ($event) {
                _vm.showAddCusterDialog = $event
              },
              custerInforma: _vm.handleAddCustomer,
            },
          })
        : _vm._e(),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.handleClose } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.confirm },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }