<template>
  <el-dialog
    :title="title"
    :visible="visibility"
    width="50%"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <el-form
      ref="form"
      size="small"
      :model="editForm"
      :rules="rules"
      label-width="80px"
      style="margin-top: 18px"
    >
      <el-row :gutter="10">
        <el-col :span="12" :offset="0">
          <el-form-item label="品名" prop="name">
            <el-input v-model="editForm.name" placeholder="请输入品名">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="商品分类" prop="class">
            <select-goods-class-cascader
              :value="[editForm.firstCategoryId, editForm.secondCategoryId]"
              @select="handleSelectGoodsClass"
              @change="handleBlur"
              @blur="handleBlur"
            ></select-goods-class-cascader>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12" :offset="0">
          <el-form-item label="俗称" prop="vulgo">
            <el-input v-model="editForm.vulgo" placeholder="请输入俗称">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="启用状态" prop="isActive">
            <el-switch
              v-model="editForm.isActive"
              :active-value="1"
              :inactive-value="0"
            >
            </el-switch>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24" :offset="0">
          <el-form-item label="项目" prop="projContent">
            <el-input
              v-model="editForm.projContent"
              type="textarea"
              :rows="4"
              placeholder="请输入项目"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <template slot="footer">
      <el-button size="small" type="primary" @click="save"> 确 定 </el-button>
      <el-button size="small" @click="handleClose"> 取 消 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex';

import { addProductName, updateProductName } from '@/api/stock/goodsSetting';
import selectGoodsClassCascader from '@/components/businessComponent/selectGoodsClassCascader.vue';
export default {
  name: 'editGoodsAliasDialog',
  components: {
    selectGoodsClassCascader,
  },
  props: {
    visibility: Boolean,
    // id: Number,
    detail: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      // 表单信息
      editForm: {
        name: '',
        firstCategoryId: '',
        secondCategoryId: '',
        isActive: 1,
      },
      // 表单规则
      rules: {
        name: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入品名！',
          },
        ],
        class: [
          {
            required: true,
            // trigger: 'blur',
            validator: this.validateClass,
          },
        ],
      },
    };
  },
  computed: {
    // ...mapState({
    //   userId: (state) => state.user.userInfo.userId,
    // }),
    title() {
      return this.editForm.id ? '编辑品名' : '新增品名';
    },
  },
  created() {
    if (Object.values(this.detail).length) {
      this.editForm = this.$lodash.clone(this.detail);
    }
  },
  methods: {
    /**
     * 校验商品分类
     */
    validateClass(rule, value, callback) {
      if (!this.editForm.firstCategoryId) {
        // callback(new Error('请选择一级分类！'));
        callback(new Error('请选择商品分类！'));
      } else if (!this.editForm.secondCategoryId) {
        callback(new Error('请选择二级分类！'));
      }
      // if (!this.editForm.firstCategoryId || !this.editForm.secondCategoryId) {
      //   callback(new Error('请选择商品分类！'));
      // }
      else {
        callback();
      }
    },
    /**
     * 选择商品分类失焦事件
     */
    handleBlur() {
      this.$refs.form.validateField('class');
    },
    /**
     * 选择商品分类
     */
    handleSelectGoodsClass(val) {
      this.editForm.firstCategoryId = val[0] || '';
      this.editForm.secondCategoryId = val[1] || '';
    },
    /**
     * 保存
     */
    save() {
      this.$refs.form
        .validate()
        .then((result, object) => {
          var api = this.editForm.id ? updateProductName : addProductName;
          api(this.editForm).then((res) => {
            this.$message.success('保存成功！');
            this.$emit('save');
            this.handleClose();
          });
        })
        .catch((error) => {
          console.log(error);
          var msg =
            '<p>' +
            Object.values(error)
              .map((el) => el.map((v) => v.message))
              .join('<br />') +
            '</p>';
          this.$message({
            type: 'warning',
            dangerouslyUseHTMLString: true,
            message: msg,
          });
        });
    },

    /**
     * 关闭抽屉
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
  },
};
</script>

<style lang="scss" scoped></style>
