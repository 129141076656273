<template>
  <div class="wrapper">
    <search ref="searchRef" :type="'SettlementFollowUp'" @toggleStatus="setTableHei" @submit="searchSubmit" />

    <div class="table-wrap">
      <div class="export-btn-wrap" ref="exportRef">
        <el-button v-auth="803" class="export-btn" plain @click="importIsShow=true">导入</el-button>
        <el-button v-auth="789" class="export-btn" type="primary" @click="showExportDialog=true">导出</el-button>
      </div>
      <el-table :data="tableData" :height="tableHei" class="new-table">
        <el-table-column align="center" fixed label="序号" type="index" width="60" />
        <el-table-column align="center" fixed label="操作门店" prop="companyName" show-overflow-tooltip width="150" />
        <el-table-column align="center" fixed label="车单号" prop="billCode" show-overflow-tooltip width="220">
          <template #default="{ row }">
            <el-link :underline="false" style="color: #3fa1e6" type="primary" @click="goCarOrderDetail(row.billId)">{{ row.billCode }}</el-link>
          </template>
        </el-table-column>
        <el-table-column align="center" fixed label="车牌号" prop="carNumber" show-overflow-tooltip width="150" />
        <el-table-column align="center" fixed label="客户名称" prop="clientName" show-overflow-tooltip width="150" />
        <el-table-column align="center" label="进店时间" prop="dateCreated" show-overflow-tooltip width="150" />
        <el-table-column align="center" label="结算时间" prop="checkDate" show-overflow-tooltip width="150" />
        <el-table-column align="center" label="挂账金额" prop="unclearedDebtFixed" show-overflow-tooltip width="120" />
        <el-table-column align="center" label="已收金额" prop="alreadyPaid" show-overflow-tooltip width="120" />
        <el-table-column align="center" label="业务来源" prop="billSourceTypeName" show-overflow-tooltip width="120" />
        <el-table-column align="center" label="保险公司" prop="insuranceCompanyName" show-overflow-tooltip width="120" />
        <el-table-column align="center" label="事故责任" prop="dutyTypeDesc" show-overflow-tooltip width="120" />
        <el-table-column align="center" label="填写人" prop="fillUserName" show-overflow-tooltip width="120" />
        <el-table-column align="center" label="分类" prop="categoryTypeDesc" show-overflow-tooltip width="120" />
        <el-table-column align="center" label="未核销金额" prop="receivedUnWriteOffAmount" show-overflow-tooltip width="120" />
        <el-table-column align="center" label="说明" prop="remark" show-overflow-tooltip width="150" />
        <el-table-column v-auth="790" align="center" fixed="right" label="操作" width="100">
          <template #default="{ row }">
            <div class="operate-center">
              <el-link :underline="false" class="table-text" type="primary" @click="edit(row)">编辑</el-link>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-wrap">
        <div class="total-text">共{{ total }}条</div>
        <el-pagination
          :current-page="currentPage"
          :page-size="pageSize"
          :page-sizes="[20, 30, 50, 100]"
          :total="total"
          layout="->, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange" />
      </div>
    </div>

    <el-dialog :visible.sync="EditVisible" title="编辑" width="30%">
      <el-form :inline="true" :model="EditForm" class="form-wrap" label-width="110px">
        <el-form-item label="分类:">
          <el-select v-model="EditForm.categoryType" clearable placeholder="请选择案件分类">
            <el-option v-for="(item,index) in categoryData" :key="index" :label="item.label" :value="item.value"/>
          </el-select>
        </el-form-item>
        <el-form-item label="未核销金额:">
          <el-input v-model="EditForm.receivedUnWriteOffAmount" clearable placeholder="请输入" />
        </el-form-item>
        <el-form-item label="说明:">
          <el-input
            v-model="EditForm.remark"
            :rows="5"
            clearable
            maxlength="200"
            placeholder="请输入说明"
            show-word-limit
            type="textarea" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="editSave">确定</el-button>
      </div>
    </el-dialog>

    <!-- 导入弹窗 -->
    <import-dialog
      v-if="importIsShow"
      :visibility.sync="importIsShow"
      :uploadFileApi="batchImportUrl"
      :getLastResultApi="batchImportResultUrl"
      :importFailDetail="batchImportFailUrl"
      :showTip="false"
      templateFileUrl="https://growxny-dev-center.oss-cn-guangzhou.aliyuncs.com/template/%E5%BA%94%E6%94%B6%E8%B4%A6%E6%AC%BE%E7%90%86%E8%B5%94-%E7%90%86%E8%B5%94%E7%BB%84%E8%B7%9F%E8%BF%9B-%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF_20241111.xlsx"
      title="批量导入" />
    <!--  导出弹框  -->
    <export-dialog
      :filterForm="form"
      :pageOption="pageOption"
      :setWid="'30%'"
      :total="total"
      :url="exportUrl"
      :visibility.sync="showExportDialog" />
  </div>
</template>

<script>
import search from "@/components/search/index.vue";
import importDialog from "@/components/businessComponent/importDialog.vue";
import ExportDialog from "@/components/ExportDialog.vue";
import {
  getSettlementList,
  getSettlementEdit,
  getSettlementSelect,
  getSettlementUrl,
  batchImportSettlementApi,
  batchImportSettlementResultApi,
  batchImportSettlementFailApi,
} from "@/api/financial/financialOver";

export default {
  name: "SettlementFollowUp",
  components: { ExportDialog, importDialog, search },
  data() {
    return {
      tableHei: "50vh",
      tableData: [],
      pageSize: 20,
      currentPage: 1,
      total: 0,
      EditVisible: false,
      EditForm: {},
      EditRow: "",
      categoryData: [],

      showExportDialog: false,
      pageOption: {
        size: 20,
        current: 1,
      },
      form: {},
      exportUrl: getSettlementUrl,
      importIsShow: false,
      batchImportUrl: batchImportSettlementApi,
      batchImportResultUrl: batchImportSettlementResultApi,
      batchImportFailUrl: batchImportSettlementFailApi,
    };
  },
  created() {
    this.getListData({ checkTime: this.getTodayDate() });
    getSettlementSelect().then(res => {
      this.categoryData = res.categoryTypeEnumList;
    });
  },
  mounted() {
    this.setTableHei();
  },
  methods: {
    /** 动态控制表格高度 **/
    setTableHei(){
      this.$nextTick(()=>{
        let windowsHei = document.documentElement.clientHeight;
        let searchHei = this.$refs.searchRef.$el.offsetHeight;
        let exportHei = this.$refs.exportRef.offsetHeight;
        this.tableHei = windowsHei - searchHei - exportHei - 160
      })
    },
    /** 跳转车单详情 **/
    goCarOrderDetail(id) {
      this.$router.push({
        name: "carBillDetail",
        query: { id, },
      });
    },
    /** 获取今天的日期 **/
    getTodayDate(){
      const date = new Date();
      const currentYear = date.getFullYear();
      const currentMonth = (date.getMonth() + 1)<10? `0${date.getMonth() + 1}`:date.getMonth() + 1;
      const today = date.getDate()<10? `0${date.getDate()}`:date.getDate();
      let timeStart = `${currentYear}-${currentMonth}-01`;
      let time = `${currentYear}-${currentMonth}-${today}`;
      return [timeStart, time]
    },
    /** 搜索数据返回值 **/
    searchSubmit(data) {
      console.log("search----data--->>>", data);
      this.pageSize = 20;
      this.currentPage = 1;
      this.form = data || {}
      this.getListData(data);
    },
    /** 获取列表数据 **/
    getListData(data) {
      let obj = {
        current: this.currentPage,
        size: this.pageSize,
        operateCompanyId: data?.operateCompanyId, //操作门店列表
        billCode: data?.billCode,  // 接车单号
        plateNumber: data?.plateNumber,  // 车牌号: "",
        sourceName: data?.sourceName,  // 业务来源名称
        insuranceCompanyId: data?.insuranceCompanyId,  // 保险公司ID
        dutyType: data?.dutyType,  // 事故责任
        clientName: data?.clientName,  // 客户名称: "",
        intoStoreBeginDateTime: data?.intoStoreTime?.length > 0 ? `${data?.intoStoreTime[0]} 00:00:00` : "",  // 进店开始时间
        intoStoreEndDateTime: data?.intoStoreTime?.length > 0 ? `${data?.intoStoreTime[1]} 23:59:59` : "",  // 进店结束时间
        checkBeginDateTime: data?.checkTime?.length > 0 ? `${data?.checkTime[0]} 00:00:00` : "",  // 结算开始时间
        checkEndDateTime: data?.checkTime?.length > 0 ? `${data?.checkTime[1]} 23:59:59` : "",  // 结算结束时间
        categoryType: data?.categoryType,  // 分类
        fillUserName: data?.fillUserName,  // 填写人
      }
      this.tableData = []
      getSettlementList(obj).then(res=>{
        this.tableData = res.records
        this.total = Number(res.total)
      })
    },
    /** 编辑数据 **/
    edit(row) {
      console.log("edit----row---->>>", row);
      this.EditRow = row
      this.EditVisible = true;
      this.$set(this.EditForm, "categoryType", String(row.categoryType || ""));
      this.$set(this.EditForm, "receivedUnWriteOffAmount",row.receivedUnWriteOffAmount || "");
      this.$set(this.EditForm, "remark", row.remark || "");
    },
    /** 编辑保存  **/
    editSave() {
      console.log("EditForm--->>>", this.EditForm);
      let obj = {
        id: this.EditRow.id,  // 跟进记录ID
        billId: this.EditRow.billId,  // 车单ID
        ...this.EditForm
      }
      getSettlementEdit(obj).then(res => {
        this.EditVisible = false;
        this.$message.success("操作成功！");
        this.getListData(this.form);
      });
    },
    /** 切换一页显示多少数据 **/
    handleSizeChange(newSize) {
      console.log("newSize--->>>", newSize);
      this.pageSize = newSize;
      this.pageOption.size = newSize;
      this.getListData(this.form);
    },
    /** 切换页数 **/
    handleCurrentChange(newPage) {
      console.log("newPage--->>>", newPage);
      this.currentPage = newPage;
      this.getListData(this.form);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  /** 全局form-item/button/input样式 **/
  .el-form-item {
    display: flex;
  }

  :deep .el-input__inner {
    width: 100% !important;
  }

  .table-wrap {
    padding: 0 14px 14px;

    .export-btn-wrap {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 12px;
    }
  }

  .pagination-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;

    .total-text {
      font-size: 14px;
      color: #444;
    }
  }

  .form-wrap {
    margin-top: 10px;

    :deep .el-form-item__content {
      width: 75% !important;
    }

    :deep .el-select {
      width: 100% !important;
    }

    :deep .el-input {
      width: 100% !important;
    }
  }

  .dialog-footer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
