<template>
  <base-container paddingTop>
    <el-descriptions :column="3" border class="des">
      <el-descriptions-item label="协议编号">
        <div class="flex-x-between">
          <div class="flex-col-y-between">
            <h2 class="car-number">{{ detailData.contractNumber }}</h2>
            <div>
              <el-switch v-model="detailData.beContract" disabled></el-switch>
              <span class="is-responsibility"
                >（{{ detailData.beContract ? '已' : '未' }}启用包干业务）</span
              >
            </div>
          </div>
          <i
            :class="[
              'icon-state',
              'iconfont',
              detailData.status,
              stateDict[detailData.status],
            ]"
          ></i>
        </div>
      </el-descriptions-item>
      <el-descriptions-item label="签约客户">
        <p
          class="pointer blue"
          @click="goDetail(detailData.agreeClient.clientId)"
        >
          {{ $lodash.get(detailData, 'agreeClient.name', '') }}
        </p>
      </el-descriptions-item>
      <el-descriptions-item label="公司全称">
        <p>
          {{ $lodash.get(detailData, 'agreeClient.companyFullName', '') }}
        </p>
      </el-descriptions-item>
      <el-descriptions-item label="有效期限">
        {{ detailData.startDate | delhms }} 至
        {{ detailData.endDate | delhms }}</el-descriptions-item
      >
      <el-descriptions-item label="签约门店">{{
        detailData.signCompany
      }}</el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <el-tooltip effect="light" placement="bottom">
            <div slot="content" class="rows-tip">
              客户协议的开票主体，即为协议客户的应收账款的回款管理门店
            </div>
            <i class="iconfont grow-icon_doubt" style="color: #9a9a9a"></i>
          </el-tooltip>
          开票主体
        </template>
        {{ detailData.invoiceCompany }}</el-descriptions-item
      >
      <el-descriptions-item label="回款周期">
        <div class="flex-y-center">
          {{ detailData.paymentPeriod }}个自然月
          <el-tooltip effect="light" placement="bottom">
            <div slot="content" class="rows-tip">
              消费结账后第{{ detailData.paymentPeriod }}个自然月月底前回款
            </div>
            <i class="iconfont grow-icon_doubt" style="color: #9a9a9a"></i>
          </el-tooltip>
        </div>
      </el-descriptions-item>
      <el-descriptions-item label="开户行">{{
        detailData.bank
      }}</el-descriptions-item>
      <el-descriptions-item label="银行账号">{{
        detailData.bankNum
      }}</el-descriptions-item>
      <el-descriptions-item label="自费">
        {{ $lodash.get(detailData, 'settlementDescription.selfPaying', '') }}
      </el-descriptions-item>
      <el-descriptions-item label="包干">
        {{ $lodash.get(detailData, 'settlementDescription.contractFor', '') }}
      </el-descriptions-item>
      <el-descriptions-item label="保险理赔">
        {{
          $lodash.get(detailData, 'settlementDescription.insuranceClaims', '')
        }}
      </el-descriptions-item>
      <el-descriptions-item label="理赔返点">
        {{
          $lodash.get(detailData, 'settlementDescription.accidentReturn', '')
        }}
      </el-descriptions-item>
      <el-descriptions-item label="年审">
        {{ $lodash.get(detailData, 'settlementDescription.annualReview', '') }}
      </el-descriptions-item>
      <el-descriptions-item label="保险手续费">
        {{
          $lodash.get(
            detailData,
            'settlementDescription.insuranceHandlingFees',
            ''
          )
        }}
      </el-descriptions-item>
      <el-descriptions-item label="备注" :span="2">{{
        detailData.remark
      }}</el-descriptions-item>
      <el-descriptions-item label="自费挂账设置">
        <div class="flex-y-center">
          <el-switch disabled :value="detailData.beHang"></el-switch>
          <span class="is-responsibility"
            >（{{ detailData.beHang ? '允许挂账' : '不允许挂账' }}）</span
          >
        </div>
      </el-descriptions-item>
      <el-descriptions-item label="协议图片" :span="3">
        <div class="imgs" v-if="detailData.pics">
          <img
            v-for="(img, index) in $lodash
              .get(detailData, 'pics', '')
              .split(',')"
            :key="index"
            @click="
              previewImage(
                $lodash.get(detailData, 'pics', '').split(','),
                index
              )
            "
            :src="detailData.picPath + img"
            alt=""
          />
        </div>
        <span class="color666" v-else>无附件图片</span>
      </el-descriptions-item>
    </el-descriptions>
    <p
      class="requirement-title"
      v-if="
        !(
          !selfPayingVo.effectiveAsk &&
          !returnCarVo.effectiveAsk &&
          !contractForVo.effectiveAsk &&
          !claimSettlementVo.effectiveAsk
        )
      "
    >
      时效要求
    </p>
    <el-descriptions :column="3" border class="des">
      <el-descriptions-item
        label="自费"
        :span="3"
        v-if="selfPayingVo.effectiveAsk"
        :contentStyle="{ width: '89%' }"
        >{{
          $lodash.get(selfPayingVo, 'effectiveAsk', '')
        }}</el-descriptions-item
      >
      <el-descriptions-item
        label="退还车"
        :span="3"
        v-if="returnCarVo.effectiveAsk"
        :contentStyle="{ width: '89%' }"
        >{{
          $lodash.get(returnCarVo, 'effectiveAsk', '')
        }}</el-descriptions-item
      >
      <el-descriptions-item
        label="包干"
        :span="3"
        v-if="contractForVo.effectiveAsk"
        :contentStyle="{ width: '89%' }"
        >{{
          $lodash.get(contractForVo, 'effectiveAsk', '')
        }}</el-descriptions-item
      >
      <el-descriptions-item
        label="理赔"
        :span="3"
        v-if="claimSettlementVo.effectiveAsk"
        :contentStyle="{ width: '89%' }"
        >{{
          $lodash.get(claimSettlementVo, 'effectiveAsk', '')
        }}</el-descriptions-item
      >
    </el-descriptions>
    <p
      class="requirement-title"
      v-if="
        !(
          !selfPayingVo.qualityAsk &&
          !returnCarVo.qualityAsk &&
          !contractForVo.qualityAsk &&
          !claimSettlementVo.qualityAsk
        )
      "
    >
      质量要求
    </p>
    <el-descriptions
      :column="3"
      border
      class="des"
      :contentStyle="{ verticalAlign: 'middle' }"
    >
      <el-descriptions-item
        label="自费"
        :span="3"
        v-if="selfPayingVo.qualityAsk"
        :contentStyle="{ width: '89%' }"
        >{{ $lodash.get(selfPayingVo, 'qualityAsk', '') }}</el-descriptions-item
      >
      <el-descriptions-item
        label="退还车"
        :span="3"
        v-if="returnCarVo.qualityAsk"
        :contentStyle="{ width: '89%' }"
        >{{ $lodash.get(returnCarVo, 'qualityAsk', '') }}</el-descriptions-item
      >
      <el-descriptions-item
        label="包干"
        :span="3"
        v-if="contractForVo.qualityAsk"
        :contentStyle="{ width: '89%' }"
        >{{
          $lodash.get(contractForVo, 'qualityAsk', '')
        }}</el-descriptions-item
      >
      <el-descriptions-item
        label="理赔"
        :span="3"
        v-if="claimSettlementVo.qualityAsk"
        :contentStyle="{ width: '89%' }"
        >{{
          $lodash.get(claimSettlementVo, 'qualityAsk', '')
        }}</el-descriptions-item
      >
    </el-descriptions>
    <el-tabs class="tabs" v-model="activeName">
      <el-tab-pane label="自费项目折扣" name="first">
        <agreement-price-list :id="$route.query.id"></agreement-price-list>
        <!-- <discount-for-self-paid-items
          :detail="discountDetail"
        ></discount-for-self-paid-items> -->
      </el-tab-pane>
      <el-tab-pane label="包干规则" name="second">
        <all-in-rule v-if="initialized" :detail="detailData"></all-in-rule>
      </el-tab-pane>
      <el-tab-pane label="协议适用门店" name="thirdly">
        <!-- <applicable-stores :id="$route.query.id"></applicable-stores> -->
        <applicable-stores
          v-if="initialized"
          :detail="detailData"
          :id="$route.query.id"
        ></applicable-stores>
      </el-tab-pane>
    </el-tabs>

    <template slot="footer">
      <div class="flex-x-end foot-btn">
        <!-- <el-button
          v-if="!['overdue', 'rescissory'].includes(detailData.status)"
          v-auth="476"
          type="primary"
          @click="edit"
          >编辑协议</el-button
        > -->
        <el-button
          v-if="!['rescissory'].includes(detailData.status)"
          v-auth="476"
          type="primary"
          @click="edit"
          >编辑协议</el-button
        >
        <el-button v-auth="475" @click="copy">复制协议</el-button>
        <el-dropdown
          v-if="!['overdue', 'rescissory'].includes(detailData.status)"
          v-auth="477"
          size="small"
          trigger="click"
          placement="top"
        >
          <el-button>
            更多操作<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item icon="el-icon-delete" @click.native="cancel"
              >作废客户协议</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </template>
  </base-container>
</template>

<script>
import { previewImage as previewImageApi } from '@/utils/fileApi';
// import DiscountForSelfPaidItems from './discountForSelfPaidItems.vue';
import agreementPriceList from './agreementPriceList.vue';
import AllInRule from './allInRule.vue';
import applicableStores from './applicableStores.vue';

import { mapState, mapGetters, mapMutations } from 'vuex';
import { openPermiss } from '@/service/dict/dictData';

// 权限
import { verifyAuth } from '@/utils/permissions';

import { levelIconsOther } from '@/const/client';

import {
  getAgreementDetail,
  // getAgreementDiscount,
  updateAgreement,
} from '@/api/customer/agreement';

export default {
  name: 'agreementDetail',
  components: {
    // DiscountForSelfPaidItems,
    agreementPriceList,
    AllInRule,
    applicableStores,
  },
  data() {
    return {
      initialized: false,
      detailData: {},
      // discountDetail: {},
      activeName: 'first',
      // 客户等级对应图标
      levelIconsOther,
      // 状态
      stateDict: {
        normal: 'grow-icon_normal',
        invalid: 'grow-icon_daishengxiao',
        overdue: 'grow-icon_guoqi',
        rescissory: 'grow-icon_zuofei',
      },
      openPermiss: openPermiss,
      selfPayingVo: {},
      returnCarVo: {},
      contractForVo: {},
      claimSettlementVo: {},
    };
  },
  computed: {
    ...mapGetters(['activeTagIndex']),
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  created() {
    this.getData();
  },
  methods: {
    ...mapMutations({
      closeTag: 'DEL_TAG',
    }),
    /**
     * 获取详情数据
     */
    getData() {
      console.log(this.$route.query.id);
      getAgreementDetail(this.$route.query.id).then((res) => {
        console.log(res);

        // 判断协议状态
        const now = new Date().getTime();
        const start = new Date(res.startDate).getTime();
        const end = new Date(res.endDate).getTime();
        const isCancel = res.beCancel;

        if (isCancel) {
          res.status = 'rescissory';
        } else if (start > now) {
          res.status = 'invalid';
        } else if (end < now) {
          res.status = 'overdue';
        } else {
          res.status = 'normal';
        }

        // 协议适用门店 是否限制  保存时存 0 1 查询时返回true false
        res.hasSelfPaying = res.hasSelfPaying ? 1 : 0;
        res.hasContractFor = res.hasContractFor ? 1 : 0;
        res.hasClaimSettlement = res.hasClaimSettlement ? 1 : 0;
        res.hasClaimForCompensation = res.hasClaimForCompensation ? 1 : 0;

        this.detailData = res;
        this.selfPayingVo = res.contractAsk.selfPayingVo;
        this.returnCarVo = res.contractAsk.returnCarVo;
        this.contractForVo = res.contractAsk.contractForVo;
        this.claimSettlementVo = res.contractAsk.claimSettlementVo;
        this.initialized = true;
      });
      // getAgreementDiscount(this.companyId, this.$route.query.id).then((res) => {
      //   this.discountDetail = res;
      // });
    },
    edit() {
      // this.$router.push({
      //   name: 'addAgreement',
      //   query: {
      //     id: this.$route.query.id,
      //   },
      // });
      let route = {
        name: 'addAgreement',
        path: '/customer/addAgreement',
        fullPath: `/customer/addAgreement?id=${this.$route.query.id}`,
        query: {
          id: this.$route.query.id,
        },
      };
      this.$store.dispatch('REPLACE_TAG', route);
    },
    /**
     * 复制协议
     */
    copy() {
      this.$confirm(
        '确定新开新增协议页面并带入除【协议编号、有效期】以外的所有信息?',
        '提示',
        {
          confirmButtonText: '是',
          cancelButtonText: '否',
          type: 'warning',
        }
      )
        .then(() => {
          this.$router.push({
            name: 'addAgreement',
            query: {
              id: this.$route.query.id,
              copy: true,
            },
          });
        })
        .catch((e) => {});
    },
    /**
     * 作废
     */
    cancel() {
      this.$confirm('确认要作废该客户协议？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let data = this.$lodash.cloneDeep(this.detailData);
          data.beCancel = 1;
          data.createCompanyId = this.companyId;
          updateAgreement(data).then((res) => {
            console.log(res);
            if (res.code === 200) {
              this.$message.success('操作成功');
              this.closeTag({ index: this.activeTagIndex });
            }
          });
        })
        .catch((e) => {});
    },
    previewImage(imgs, index) {
      previewImageApi({
        images: imgs.map((item) => this.detailData.picPath + item),
        options: {
          initialViewIndex: index,
        },
      });
    },
    /**
     * 跳转客户详情页
     */
    async goDetail(clientId) {
      await verifyAuth(107);
      this.$router.push({
        name: 'customerDetails',
        query: { id: clientId },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.des {
  .car-number {
    color: #333;
    font-weight: 600;
    font-size: 16px;
  }
  .is-responsibility {
    font-size: 12px;
    color: #999;
  }
  .flex-col-y-between {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .icon-state {
    font-size: 60px;
    line-height: 64px;
    &.normal {
      color: $primary;
    }
    &.invalid {
      color: #3eb1e0;
    }
    &.overdue {
      color: #ec2121;
    }
    &.rescissory {
      color: #999;
    }
  }
  .client-info {
    > div + div {
      margin-top: 10px;
    }
    i {
      margin: 0 5px;
    }
    .icon_person {
      color: $blue;
    }
    .icon_sex {
      color: $blue;
    }
    .icon_grade {
      color: $blue;
    }
    .grow-icon_girl {
      color: #f48080;
    }
    .icon_wechat {
      color: #999;
    }
    .icon_wechat_active {
      color: #00c800;
    }
    .icon_phone {
      color: #42bbb8;
    }
    .icon_vip {
      color: $orange;
    }
    .icon_sex,
    .icon_grade,
    .icon_wechat,
    .icon_wechat_active,
    .icon_vip {
      font-size: 14px;
    }
  }
  .imgs {
    display: flex;
    > img {
      width: 60px;
      height: 60px;
      object-fit: cover;
      border-radius: 4px;
      overflow: hidden;
      cursor: pointer;
      & + img {
        margin-left: 10px;
      }
    }
  }
}
.rows-tip {
  color: #999;
  line-height: 18px;
}
.tabs {
  margin: 60px 0 30px;
}
.foot-btn {
  padding: 10px 20px;
  box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.1);
  .el-dropdown {
    margin-left: 24px;
  }
  .el-dropdown-item ::v-deep .el-dropdown-menu {
    padding: 0;
  }
}
.requirement-title {
  margin: 20px 0;
  font-size: 14px;
  color: #303133;
}
.note {
  margin: 8px 0 16px;
  color: #999;
  font-size: 14px;
  p + p {
    margin-top: 8px;
  }
}
</style>
