import { render, staticRenderFns } from "./waitReturnInInventory.vue?vue&type=template&id=20c7c73c&scoped=true"
import script from "./waitReturnInInventory.vue?vue&type=script&lang=js"
export * from "./waitReturnInInventory.vue?vue&type=script&lang=js"
import style0 from "./waitReturnInInventory.vue?vue&type=style&index=0&id=20c7c73c&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20c7c73c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/jiaxu/Documents/code/geyue-back-end-management-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('20c7c73c')) {
      api.createRecord('20c7c73c', component.options)
    } else {
      api.reload('20c7c73c', component.options)
    }
    module.hot.accept("./waitReturnInInventory.vue?vue&type=template&id=20c7c73c&scoped=true", function () {
      api.rerender('20c7c73c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/stock/report/material/waitReturnInInventory.vue"
export default component.exports