var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { ref: "baseContainer", attrs: { paddingTop: "" } },
    [
      [
        _c(
          "div",
          [
            _c(
              "el-form",
              {
                staticClass: "filter-form",
                attrs: {
                  model: _vm.form,
                  "label-width": "100px",
                  size: "small",
                },
              },
              [
                _c("div", { staticClass: "flex-x-between" }, [
                  _c(
                    "div",
                    { staticClass: "flex-warp flex-shrink0" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "故障现象" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "故障现象", clearable: "" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.getList.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.form.wzAppearanceName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "wzAppearanceName", $$v)
                              },
                              expression: "form.wzAppearanceName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "故障原因" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "搜索故障原因",
                              clearable: "",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.getList.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.form.wzCauseName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "wzCauseName", $$v)
                              },
                              expression: "form.wzCauseName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "维修方案" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "搜索维修方案",
                              clearable: "",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.getList.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.form.wzServiceName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "wzServiceName", $$v)
                              },
                              expression: "form.wzServiceName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "flex" }, [
                    _c("div", { staticClass: "el-form-item--small" }, [
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "btn_search",
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.getList },
                            },
                            [_vm._v("查询")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "btn_search",
                              attrs: { size: "small" },
                              on: { click: _vm.empty },
                            },
                            [_vm._v("置空")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "flex-warp" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "状态" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择", clearable: "" },
                            model: {
                              value: _vm.form.state,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "state", $$v)
                              },
                              expression: "form.state",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "待处理", value: 1 },
                            }),
                            _c("el-option", {
                              attrs: { label: "已处理", value: 2 },
                            }),
                            _c("el-option", {
                              attrs: { label: "已新增", value: 3 },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "车型" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              filterable: "",
                              remote: "",
                              placeholder: "搜索车型",
                              "remote-method": _vm.searchModel,
                              loading: _vm.searchModelLoading,
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.carModel,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "carModel", $$v)
                              },
                              expression: "form.carModel",
                            },
                          },
                          _vm._l(_vm.carModelList, function (item) {
                            return _c(
                              "el-option",
                              {
                                key: _vm.$lodash.get(item, "series.id"),
                                attrs: {
                                  value: _vm.$lodash.get(
                                    item,
                                    "series.name",
                                    _vm.$lodash.get(
                                      item,
                                      "brand.name",
                                      _vm.$lodash.get(item, "logo.name")
                                    )
                                  ),
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.selectCarModel(item)
                                  },
                                },
                              },
                              [
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(item.markedRedName),
                                  },
                                }),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "签约客户" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              filterable: "",
                              clearable: "",
                              remote: "",
                              placeholder: "请输入客户名称",
                              "remote-method": _vm.searchClient,
                              loading: _vm.searchClientLoading,
                            },
                            on: { change: _vm.selectClient },
                            model: {
                              value: _vm.form.clientId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "clientId", $$v)
                              },
                              expression: "form.clientId",
                            },
                          },
                          _vm._l(_vm.customList, function (item) {
                            return _c(
                              "el-option",
                              {
                                key: item.id,
                                attrs: {
                                  label: item.clientName,
                                  value: item.id,
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "#8492a6",
                                      "font-size": "13px",
                                    },
                                  },
                                  [_vm._v(_vm._s(item.clientName))]
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "进店时间" } },
                      [
                        _c("el-date-picker", {
                          staticClass: "w100",
                          attrs: {
                            type: "daterange",
                            "unlink-panels": "",
                            "range-separator": "至",
                            "start-placeholder": "起始时间",
                            "end-placeholder": "截止时间",
                            "value-format": "yyyy-MM-dd",
                          },
                          model: {
                            value: _vm.form.intoStoreTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "intoStoreTime", $$v)
                            },
                            expression: "form.intoStoreTime",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ],
      [
        _c("div", { staticClass: "btns flex-x-between" }, [
          _c("div", [
            _c(
              "div",
              { staticClass: "custom-tabs nopadding" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.batchProcessing },
                  },
                  [_vm._v("批量处理")]
                ),
                _c("i", {
                  staticClass: "pointer iconfont grow-icon_warn_32",
                  staticStyle: {
                    color: "#ff9200",
                    "font-size": "15px",
                    margin: "8px 6px",
                  },
                }),
                _c(
                  "span",
                  {
                    staticStyle: {
                      color: "#909399",
                      "margin-top": "10px",
                      "font-size": "12px",
                    },
                  },
                  [
                    _vm._v(
                      "可提炼问诊模型库时请点击操作新增模型，已有模型时请点击处理标记状态"
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "auth",
                      rawName: "v-auth",
                      value: 494,
                      expression: "494",
                    },
                  ],
                  staticClass: "blue",
                  attrs: { size: "small", type: "text" },
                  on: {
                    click: function ($event) {
                      _vm.showExportDialog = true
                    },
                  },
                },
                [
                  _vm._v("导出列表"),
                  _c("i", {
                    staticClass: "iconfont grow-icon_daochu el-icon--right",
                  }),
                ]
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          {
            staticClass: "flex h100",
            staticStyle: { flex: "1", overflow: "auto" },
          },
          [
            _c(
              "el-table",
              {
                staticClass: "custom-table",
                attrs: { data: _vm.faultList, border: "" },
                on: { "selection-change": _vm.handleSelectionChange },
              },
              [
                _c("el-table-column", {
                  attrs: { type: "selection", width: "55" },
                }),
                _c("el-table-column", {
                  attrs: { type: "index", label: "序号", width: "60" },
                }),
                _c("el-table-column", {
                  attrs: { width: "150", label: "操作" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          row.state == 1
                            ? _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "auth",
                                      rawName: "v-auth",
                                      value: 557,
                                      expression: "557",
                                    },
                                  ],
                                  staticClass: "blue pointer",
                                  staticStyle: { "margin-right": "6px" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addEvent(row)
                                    },
                                  },
                                },
                                [_vm._v("新增模型")]
                              )
                            : _vm._e(),
                          row.state == 1
                            ? _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "auth",
                                      rawName: "v-auth",
                                      value: 564,
                                      expression: "564",
                                    },
                                  ],
                                  staticClass: "red pointer",
                                  on: {
                                    click: function ($event) {
                                      return _vm.dealEvent(row.id)
                                    },
                                  },
                                },
                                [_vm._v("处理")]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "100",
                    label: "故障现象",
                    prop: "wzAppearanceName",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "150",
                    label: "故障原因",
                    prop: "wzCauseName",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "150",
                    label: "维修方案",
                    prop: "wzServiceName",
                  },
                }),
                _c("el-table-column", {
                  attrs: { width: "150", label: "故障图" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "flex-y-center",
                              staticStyle: { padding: "10px 0" },
                            },
                            [
                              !_vm.$lodash.get(row, "faultPicList.length", 0)
                                ? _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        width: "32px",
                                        "text-align": "center",
                                      },
                                    },
                                    [_vm._v("无")]
                                  )
                                : _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        placement: "top",
                                        trigger: "hover",
                                      },
                                    },
                                    [
                                      _c("upload-file-list", {
                                        attrs: {
                                          disabled: "",
                                          value: row.preImages,
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "video-list" },
                                        _vm._l(
                                          row.preVideos,
                                          function (src, index) {
                                            return _c(
                                              "video",
                                              {
                                                key: index,
                                                staticClass: "thumbnail-video",
                                                attrs: {
                                                  preload: "auto",
                                                  src: src,
                                                  poster: _vm.playImg,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleClickVideo(
                                                      $event,
                                                      row.preVideos,
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("source", {
                                                  attrs: {
                                                    src: src,
                                                    type: "video/mp4",
                                                  },
                                                }),
                                                _vm._v(
                                                  " 您的设备不支持 video 标签。 "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                      _c(
                                        "template",
                                        { slot: "reference" },
                                        [
                                          _c(
                                            "el-badge",
                                            {
                                              staticStyle: {
                                                "margin-right": "8px",
                                              },
                                              attrs: {
                                                value: row.faultPicList.length,
                                              },
                                            },
                                            [
                                              _c("img", {
                                                staticClass: "thumbnail-img",
                                                attrs: { src: row.prePoster },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    2
                                  ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "120",
                    label: "车牌",
                    prop: "carNumber",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "100",
                    label: "车型",
                    prop: "carModel",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "150",
                    label: "操作门店",
                    prop: "companyName",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "170",
                    label: "关联车单号",
                    prop: "billCode",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "blue pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.goDetail(row.billId)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(row.billCode) + " ")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { width: "100", label: "接车员", prop: "creatorName" },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "120",
                    label: "签约客户",
                    prop: "clientName",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "100",
                    label: "进店时间",
                    prop: "dateCreated",
                  },
                }),
                _c("el-table-column", {
                  attrs: { width: "100", label: "状态", prop: "state" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                row.state == 1
                                  ? "待处理"
                                  : row.state == 2
                                  ? "已处理"
                                  : "已新增"
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "100",
                    label: "操作人",
                    prop: "operatorName",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "100",
                    label: "操作时间",
                    prop: "lastUpdated",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c("export-dialog", {
          attrs: {
            visibility: _vm.showExportDialog,
            total: _vm.total,
            pageOption: _vm.pageOption,
            filterForm: _vm.form,
            url: _vm.exportUrl,
          },
          on: {
            "update:visibility": function ($event) {
              _vm.showExportDialog = $event
            },
          },
        }),
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c("pagination", {
            attrs: { total: _vm.total },
            on: { pageChange: _vm.changePage },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.dealDialog,
            width: "25%",
            top: "15%",
            "before-close": _vm.handleClose,
            "close-on-click-modal": false,
            modal: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dealDialog = $event
            },
          },
        },
        [
          _c("div", { staticClass: "prompt-sty" }, [
            _c("p", [_vm._v("点击“确定”将选择的疑难杂症项标记为已处理")]),
          ]),
          _c("template", { slot: "footer" }, [
            _c(
              "div",
              { staticStyle: { "text-align": "center" } },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small" },
                    on: {
                      click: function ($event) {
                        _vm.dealDialog = false
                      },
                    },
                  },
                  [_vm._v("取 消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: { click: _vm.changeSate },
                  },
                  [_vm._v("确 定")]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _vm.editDialog
        ? _c("consultation-dialog", {
            attrs: {
              visibility: _vm.editDialog,
              title: _vm.title,
              rowData: _vm.rowData,
              showContent: false,
            },
            on: {
              "update:visibility": function ($event) {
                _vm.editDialog = $event
              },
              upList: _vm.getList,
            },
          })
        : _vm._e(),
      _vm.showVideoPlayerDialog
        ? _c("videoPlayerDialog", {
            attrs: {
              visibility: _vm.showVideoPlayerDialog,
              videos: _vm.previewVideos,
              index: _vm.previewVideoIndex,
            },
            on: {
              "update:visibility": function ($event) {
                _vm.showVideoPlayerDialog = $event
              },
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }