<template>
  <el-cascader
    ref="cascader"
    v-model="category"
    :options="categoryTree"
    @change="handleChange"
    clearable
    :size="size"
    show-all-levels
    :props="{
      value: 'categoryId',
      label: 'name',
      children: 'subCategories',
      checkStrictly: checkStrictly,
    }"
    filterable
    @blur="handleBlur"
  >
  </el-cascader>
</template>

<script>
import { mapState } from 'vuex';
import { getCategory } from '@/api/systemManagement/carOrderSettings/serviceManagement';
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      default: 'small',
    },
    checkStrictly: {
      type: Boolean,
      default: false,
    },
    clearBol: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    clearBol(n) {
      if (n) this.category = '';
      this.$emit('clearBol', false);
    },
  },
  data() {
    return {
      category: [],
      categoryTree: [],
    };
  },
  computed: {},
  created() {
    this.getData();
    this.category = this.value || [];
  },
  methods: {
    getData() {
      getCategory({
        companyId: this.$store.state.user.companyId,
      }).then((res) => {
        this.categoryTree = res;
      });
    },
    handleChange(value) {
      this.$emit('select', value);
    },
    handleBlur() {
      this.$emit('blur');
    },
  },
};
</script>
<style lang="scss" scoped>
.el-cascader {
  width: 100% !important;
}
</style>
