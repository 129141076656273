<template>
  <div>
    <el-dialog
      title="编辑用料"
      width="50%"
      :visible="visibility"
      :before-close="handleClose"
      :close-on-click-modal="false"
      append-to-body
    >
      <div class="edit-box">
        <p style="font-size: 12px; margin: 10px 0 20px 0" class="orange">
          修改报价项目里的商品数量，用料清单将同步更新。也可单独编辑用料清单，编辑保存后不影响报价项目的数据
        </p>
        <el-table
          style="width: 100%"
          class="service-table"
          border
          :data="tableData"
          default-expand-all
          row-key="id"
          :tree-props="{
            children: 'materialGoods',
            hasChildren: 'hasChildren',
          }"
          :span-method="spanMethod"
        >
          <el-table-column width="80" label="操作">
            <template #default="{ row }">
              <span v-if="row.type === 'goods'">
                <i
                  style="color: #ff9200; font-size: 22px"
                  class="iconfont grow-icon_delete3 pointer table-del-icon"
                  @click="deleteRow(row)"
                ></i>
              </span>
            </template>
          </el-table-column>
          <el-table-column width="60" label="序号" prop="title">
          </el-table-column>
          <el-table-column label="" width="180">
            <template slot="header"> 报价项目 </template>
            <template #default="{ row, $index }">
              <span v-if="row.name">{{ row.name }}</span>
              <div v-else>
                <select-goods-popover
                  :carId="carId"
                  @select="onSelectGoodsItem($event, row, $index)"
                ></select-goods-popover>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="项目编码" min-width="100">
            <template #default="{ row }">
              <span v-if="row.type === 'service'">{{ row.code }}</span>
              <span v-else>{{ row.goods.code }}</span>
            </template>
          </el-table-column>
          <el-table-column label="商品及库存" width="180">
            <template #default="{ row, $index }">
              <div v-if="row.type === 'service'">
                <el-button size="small" @click="addGoodsRow(row)"
                  >+ 添加</el-button
                >
                <el-tooltip content="批量添加">
                  <i
                    class="iconfont grow-icon_add el-icon--right primary pointer"
                    @click="showMultipleSelectGoodsDrawer(row, $index)"
                  ></i>
                </el-tooltip>
              </div>
              <div v-else>
                <span>{{ row.inventoryTotalNum }}</span>
                <span style="margin-left: 5px">{{ row.goods.unit }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="库存预警值" width="100">
            <template #default="{ row }">
              <span v-if="row.type === 'goods'">
                {{ row.goods.alarmNum }}
              </span>
              <!-- 施工技师（班组） -->
              <div v-else style="text-align: center">
                {{ row.technicianName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="用料数量" width="120">
            <template #default="{ row }">
              <number-input
                :key="tableKey"
                v-if="row.type === 'goods'"
                v-model="row.num"
                size="small"
                :clearable="false"
                :min="1"
                :enabledDecimals="false"
              ></number-input>
            </template>
          </el-table-column>
          <el-table-column label="状态" width="120">
            <template #default="{ row }">
              {{ row.materialGoodsState | dict('materialGoodsState') }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer">
        <el-button size="small" @click="handleClose">取 消</el-button>
        <el-button size="small" type="primary" @click="handleConfirm"
          >保 存</el-button
        >
      </div>
      <!-- 批量添加商品 -->
      <select-goods-for-car-bill
        v-if="multipleSelectGoodsDrawerVisibility"
        :carId="carId"
        :visibility.sync="multipleSelectGoodsDrawerVisibility"
        @select="multipleSelectGoods"
      ></select-goods-for-car-bill>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { editMaterialBill, getMaterialBillDetails } from '@/api/carBill';
//车单解锁
import { getBillLocakRelease } from '@/api/financial/pickSingle';
import numberInput from '@/components/numberInput';
import selectGoodsPopover from '@/components/businessComponent/selectGoodsPopover.vue';
import selectGoodsForCarBill from '@/components/businessComponent/selectGoodsForCarBill';
export default {
  name: 'editMaterialBillDialog',
  components: { numberInput, selectGoodsPopover, selectGoodsForCarBill },
  props: {
    visibility: { type: Boolean, default: false },
    list: {
      type: Array,
      default: () => [],
    },
    id: {
      type: [String, Number],
      default: 0,
    },
    billId: {
      type: [String, Number],
      default: 0,
    },
    carId: {
      type: [String, Number],
      default: 0,
    },
  },

  data() {
    return {
      // 表格数据
      tableData: [],

      // 服务项，正在编辑 下标
      editIndex: 0,
      // 服务项，正在编辑 对象
      editRow: null,

      // 批量添加商品抽屉可见性
      multipleSelectGoodsDrawerVisibility: false,

      tableKey: 0,
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.companyId,
      userId: (state) => state.user.userInfo?.userId,
    }),
  },
  created() {
    // if (this.list.length) this.tableData = this.$lodash.cloneDeep(this.list);
    if (this.list.length) this.handleList(this.list);
    else this.getData();
  },
  destroyed() {
    getBillLocakRelease(this.billId);
  },
  methods: {
    /**
     * 获取数据
     */
    getData() {
      var data = {
        id: this.id,
        companyId: this.companyId,
      };
      getMaterialBillDetails(data).then((res) => {
        console.log('领料数据', res);
        let { materialService, ...otherData } = res;
        this.handleList(materialService);
      });
    },
    handleList(materialService) {
      let unreceivedMaterialList = [];
      materialService.forEach((item) => {
        // 有商品
        if (item.materialGoods?.length) {
          // 按照是否领取分组
          let { materialGoods, ...serviceData } = item;
          // 未领
          let unreceived = materialGoods.filter(
            (goods) => goods.materialGoodsState === 0
          );
          unreceivedMaterialList.push(
            Object.assign({}, serviceData, { materialGoods: unreceived })
          );
        } else {
          unreceivedMaterialList.push(
            Object.assign({}, item, { materialGoods: [] })
          );
        }
      });
      // 处理数据
      function handleTableData(list) {
        list.forEach((service) => {
          service.name = service.serviceItemName;
          service.id = service.serviceItemId;
          service.type = 'service';
          service.count = 0;
          service.itemTotalPrice = 0;
          service.rowspan = 1;
          if (service.materialGoods?.length) {
            service.rowspan += service.materialGoods.length;
            service.materialGoods.forEach((item) => {
              item.name = item.goods.goodsName;
              item.id = service.id + Math.random();
              item.type = 'goods';
              // 领料小计
              service.count += item.pickedNum || 0;
              // 项目成本小计
              service.itemTotalPrice += item.totalCostPrice || 0;
            });
          }
        });
      }
      handleTableData(unreceivedMaterialList);

      this.tableData = unreceivedMaterialList;

      this.setIndex(this.tableData);
    },
    /**
     * 添加商品 空行
     */
    addGoodsRow(row) {
      this.tableData.forEach((item) => {
        if (item.materialGoods?.length) {
          item.materialGoods = item.materialGoods.filter((el) => el.name);
        }
      });
      if (Array.isArray(row?.materialGoods)) {
        this.$set(row.materialGoods, row.materialGoods.length, {
          type: 'goods',
          id: row.id + Math.random(),
          goods: {},
        });
        this.editRow = row;
      } else {
        this.$set(row, 'materialGoods', [
          {
            type: 'goods',
            id: row.id + Math.random(),
            goods: {},
          },
        ]);
        this.editRow = row;
      }
      // 设置标题
      this.setIndex(this.tableData);
    },
    /**
     * 选择商品
     * @param item{object} 选中的商品
     * @param row{object} 当前编辑商品行内容
     */
    onSelectGoodsItem(item, row) {
      item = this.$lodash.cloneDeep(item);
      // 如果添加相同商品 则数量+1
      let sameIndex = this.editRow.materialGoods?.findIndex(
        (goods) => goods.goods?.goodsId === item.goodsId
      );
      if (sameIndex >= 0) {
        this.editRow.materialGoods[sameIndex].num =
          +this.editRow.materialGoods[sameIndex].num + 1;
        this.tableKey = Math.random();
        return;
      }

      item.name = item.goodsName;
      row.num = '1';
      row.serviceItemId = this.editRow.serviceItemId;
      row.id = item.goodsId + Math.random();
      row.goods = {
        goodsId: item.goodsId,
        code: item.code,
        inventoryTotalNum: item.inventoryTotalNum,
        unit: item.unit,
        alarmNum: item.alarmNum,
      };

      row = Object.assign(row, item);

      // 设置标题
      this.setIndex(this.tableData);
      let serviceItem = this.tableData[row.title.split('.')[0] - 1];
      this.addGoodsRow(serviceItem);
    },
    /**
     * 批量添加商品
     */
    multipleSelectGoods(items) {
      items = this.$lodash.cloneDeep(items);
      console.log(items);
      let addItems = [];
      let repeated = [];
      items.forEach((row) => {
        if (
          this.editRow?.materialGoods?.find(
            (g) => g.goods?.goodsId === row.goodsId
          )
        ) {
          repeated.push(row);
        } else {
          addItems.push(row);
        }
      });

      var goods = addItems.map((item) => {
        var row = {};
        row.serviceItemId = this.editRow.serviceItemId;
        row.type = 'goods';
        row.num = '1';
        row.id = item.goodsId + Math.random();
        row.goods = {
          goodsId: item.goodsId,
          code: item.code,
          inventoryTotalNum: item.inventoryTotalNum,
          unit: item.unit,
          alarmNum: item.alarmNum,
        };
        row.name = item.goodsName;

        row = Object.assign(row, item);
        return row;
      });
      if (Array.isArray(this.editRow?.materialGoods)) {
        goods.forEach((item) => {
          this.$set(
            this.editRow.materialGoods,
            this.editRow.materialGoods.length,
            item
          );
        });
      } else {
        this.$set(this.editRow, 'materialGoods', goods);
      }
      // 去除空行
      this.editRow.materialGoods = this.editRow.materialGoods.filter(
        (item) => item.goods?.goodsId
      );

      repeated.forEach((row) => {
        let index = this.editRow.materialGoods.findIndex(
          (s) => s.goods?.goodsId === row.goodsId
        );
        this.editRow.materialGoods[index].num++;
      });

      this.setIndex(this.tableData);
      this.$set(this, 'tableData', this.tableData);
      this.tableKey = Math.random();
    },
    /**
     * 显示批量选择商品抽屉
     */
    showMultipleSelectGoodsDrawer(row, index) {
      this.editRow = row;
      this.editIndex = +row.title - 1;
      this.multipleSelectGoodsDrawerVisibility = true;
    },
    /**
     * 删除报价项目
     */
    deleteRow(row) {
      let serviceIndex = row.title.split('.')[0] - 1;
      let goodsIndex = row.title.split('.')[1] - 1;
      if (row.materialGoodsId) {
        if (
          Array.isArray(this.tableData[serviceIndex].removeMaterialGoodsIds)
        ) {
          this.tableData[serviceIndex].removeMaterialGoodsIds.push(
            row.materialGoodsId
          );
        } else {
          this.tableData[serviceIndex].removeMaterialGoodsIds = [
            row.materialGoodsId,
          ];
        }
      }
      this.tableData[serviceIndex].materialGoods.splice(goodsIndex, 1);
      this.setIndex(this.tableData);
    },
    /**
     * 表格合并方法
     */
    spanMethod({ row, column, rowIndex, columnIndex }) {
      if (row.type === 'service' && columnIndex === 5) {
        return {
          rowspan: 1,
          colspan: 3,
        };
      }
    },
    /**
     * 确认事件
     */
    handleConfirm() {
      let zeroRow = [];
      this.tableData.forEach((s) => {
        s.materialGoods?.map((el) => {
          if (el.goods?.goodsId && !+el.num) {
            zeroRow.push(
              `<p>报价项目【${el.name}】的用料数量必须大于等于1!</p>`
            );
          }
        });
      });
      if (zeroRow.length) {
        this.$alert(zeroRow.join(''), '提示', {
          dangerouslyUseHTMLString: true,
        });
        return;
      }
      var cloneData = this.$lodash.cloneDeep(this.tableData);
      var list = cloneData.map((item) => {
        item.materialGoods =
          item.materialGoods?.map((el) => ({
            serviceItemId: el.serviceItemId,
            materialGoodsId: el.materialGoodsId,
            goodsId: el.goods?.goodsId,
            num: +el.num || 0,
            sequence: el.title?.split('.')[1] - 1,
          })) || [];
        item.materialGoods = item.materialGoods.filter((row) => row.goodsId);
        return item;
      });
      var body = {
        materialBillId: this.id,
        materialService: list,
      };
      editMaterialBill(body).then((res) => {
        console.log(res);
        this.$emit('save');
        this.handleClose();
      });
    },
    /**
     * 关闭弹窗
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
    /**
     * 设置序号
     */
    setIndex(arr, indexArr = [], children = 'materialGoods') {
      arr.forEach((item, index) => {
        let cloneArr = [];
        if (indexArr.length) {
          cloneArr = indexArr.concat(index + 1);
        } else {
          cloneArr.push(index + 1);
        }
        item.title = cloneArr.join('.');
        if (item[children]?.length) {
          this.setIndex(item[children], cloneArr);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.service-table.el-table ::v-deep .el-table__row--level-1 {
  background: #f5f7fa;
}
</style>
