var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "tags-box" }, [
      _c(
        "ul",
        { staticClass: "tags-ul_tags" },
        _vm._l(_vm.tagList, function (tag, index) {
          return _c(
            "li",
            {
              key: tag.fullPath,
              staticClass: "tags-li_tag",
              class: { "tags-li-tag_active": index === _vm.activeTagIndex },
              attrs: { title: tag.title },
            },
            [
              _c(
                "router-link",
                { staticClass: "tags-link_title", attrs: { to: tag.fullPath } },
                [
                  _vm._v(" " + _vm._s(tag.title) + " "),
                  index === _vm.activeTagIndex
                    ? _c("i", {
                        staticClass:
                          "tags-tag-icon_refresh iconfont grow-a-icon_tabbar_refresh",
                        attrs: { title: "刷新" },
                        on: { click: _vm.refresh },
                      })
                    : _vm._e(),
                  tag.name != "wel"
                    ? _c("i", {
                        staticClass:
                          "tags-tag-icon_close iconfont grow-a-icon_tabbar_close",
                        attrs: { title: "关闭" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.closeTag({ index })
                          },
                        },
                      })
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        }),
        0
      ),
    ]),
    _c(
      "div",
      { staticClass: "car-bill_shortcut" },
      [
        _c(
          "el-dropdown",
          { attrs: { trigger: "click" }, on: { command: _vm.handleCommand } },
          [
            _c("div", { staticClass: "add-box" }, [
              _c("div", { staticClass: "add-btn flex-center" }, [
                _vm._v("开单"),
              ]),
            ]),
            _c(
              "el-dropdown-menu",
              { attrs: { slot: "dropdown" }, slot: "dropdown" },
              [
                _c("el-dropdown-item", { attrs: { command: "0" } }, [
                  _vm._v("自费开单"),
                ]),
                _c("el-dropdown-item", { attrs: { command: "1" } }, [
                  _vm._v("包干开单"),
                ]),
                _c("el-dropdown-item", { attrs: { command: "2" } }, [
                  _vm._v("理赔开单"),
                ]),
                _c("el-dropdown-item", { attrs: { command: "3" } }, [
                  _vm._v("索赔开单"),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c("search-car-bill-by-car-number-popover", {
          on: {
            select: function ($event) {
              return _vm.goDetail($event.billId)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }