import axios from 'axios';
// import Vue from 'vue';
import { MessageBox } from 'element-ui';

import axiosConfig from '@/config/axios';
import router from '@/router';
import store from '@/store';

// 请求基础路径
axios.defaults.baseURL = '/';

// 请求超时时间
axios.defaults.timeout = 3e5;
// 请求头和请求基础路径
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
// 请求池
let requestPool = [];
let isLoading = 0;
let loading = null;

let requestId = 0;

// 注意: 可以使用同一个 cancel token 取消多个请求
const CancelToken = axios.CancelToken;
let source = CancelToken.source();

/**
 * 显示消息框
 */
function showMessageBox({ title = '提示', msg = '加载中', option = {} }) {
  return MessageBox.alert(msg, title, option);
}

/**
 * 关闭消息框
 */
function closeLoading() {
  MessageBox.close();
  loading = null;
}

// 请求拦截
axios.interceptors.request.use(
  (config) => {
    // 默认只返回 res.data
    if (config.onlyReturnData === undefined) {
      config.onlyReturnData = true;
    }
    if (!config.noShowWaiting) {
      isLoading++;
      if (loading === null) {
        loading = showMessageBox({
          title: '加载中',
          msg: `<img src="${axiosConfig.loadingImg}" style="width: 28px;margin: auto;display: block;object-fit: contain;"/>`,
          option: {
            dangerouslyUseHTMLString: true,
            showCancelButton: true,
            showConfirmButton: false,
            callback: (e) => {
              console.log(e);
              // 取消请求
              source.cancel('user cancel');
              source = CancelToken.source();
            },
          },
        });
      }
    }
    // 添加token 220809 pks 取消token
    // const token = window.sessionStorage.getItem('token');
    // if (token) {
    //   config.headers.GrowxnyAuth = token;
    // }

    // 添加cancelToken
    config.cancelToken = source.token;

    config.requestId = requestId++;
    requestPool.push(config);
    return config;
  },
  (error) => {
    return error;
  }
);

// 响应拦截
axios.interceptors.response.use(
  (res) => {
    if (!res.config.noShowWaiting) {
      isLoading--;
      if (isLoading <= 0 && loading != null) {
        closeLoading();
      }
    }

    // 在请求池中删除对应请求记录
    const index = requestPool.findIndex(
      (item) => item.requestId === res.config.requestId
    );
    index !== undefined && requestPool.splice(index, 1);

    // http 响应头状态码
    if (res.status === 200) {
      // 文件流 或者 不需要判断
      if (res.data instanceof Blob || res.config.noHandleError) {
        return res;
      }

      if (res.data.code === 200) {
        if (res.config.onlyReturnData) {
          return res.data.data;
        } else {
          return res.data;
        }
      } else {
        return handlerResponseError(res);
      }
    } else {
      return handlerResponseError(res);
    }
  },
  // 失败响应
  (error) => {
    // 主动取消请求
    if (axios.isCancel(error)) {
      requestPool = [];
      isLoading = false;
      loading = null;
      return Promise.reject(error);
    } else {
      if (!error.config.noShowWaiting) {
        isLoading--;
        if (isLoading <= 0 && loading != null) {
          closeLoading();
        }
      }
      // 处理错误请求
      return handlerResponseError(error);
    }
  }
);

/**
 * 处理响应错误
 */
function handlerResponseError(error) {
  // 状态码
  let status =
    error.response?.data?.status || error.response?.status || error.data?.code;
  // 后端返回有number类型和string类型  统一转为string类型处理
  status += '';

  // 错误消息
  const message = error.data?.message || error.data?.msg;
  // 如果错误消息里存在'Token无效'
  if (message?.includes('Token无效')) {
    status = 401;
  }

  const normalError = [
    // 201 操作失败
    '201',
    // 202 查询失败
    '202',
    // 203 更新失败
    '203',
    // 204 创建失败
    '204',
    // 205 删除失败
    '205',
    // 206 参数错误
    '206',
  ];

  const sysError = [
    // 500
    '500',
    // 502
    '502',
  ];

  // 401 token无效
  const unauthorized = ['401', '402', '403'];
  if (unauthorized.includes(status)) {
    showMessageBox({
      msg: '请登录后重试',
    });
    // router.push('/login');
    store.dispatch('logout');
  } else if (normalError.includes(status)) {
    showMessageBox({
      title: '提示',
      msg: message || '系统错误！',
      option: { callback: error.config?.errorCallback },
    });
  } else if (sysError.includes(status)) {
    if (message?.length < 200) {
      showMessageBox({ msg: message });
    } else {
      // 不显示后台错误信息 用户看不懂
      showMessageBox({ msg: '系统错误' });
    }
  } else if (error.code === 'ECONNABORTED') {
    showMessageBox({ msg: '请求超时！' });
  }
  return Promise.reject(error);
}

export default axios;
