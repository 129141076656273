<template>
  <div class="wrapper">
    <search ref="searchRef" :type="'DeductibleChanges'" @submit="searchSubmit" />

    <div class="table-wrap">
      <div ref="exportRef" class="export-btn-wrap">
        <el-button v-auth="813" class="export-btn" type="primary" @click="showExportDialog = true">导出</el-button>
      </div>
      <el-table :data="tableData" :height="tableHei" class="new-table">
        <el-table-column align="center" fixed label="序号" type="index" width="60" />
        <el-table-column align="center" fixed label="变动时间" prop="dateCreated" show-overflow-tooltip width="150" />
        <el-table-column align="center" fixed label="接车单号" prop="billCode" show-overflow-tooltip width="220">
          <template #default="{ row }">
            <el-link :underline="false" style="color: #3fa1e6" type="primary" @click="goCarOrderDetail(row.billId)">
              {{ row.billCode }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column align="center" fixed label="客户" prop="clientName" show-overflow-tooltip width="150" />
        <el-table-column align="center" fixed label="报案号" prop="caseNum" show-overflow-tooltip width="150" />
        <el-table-column align="center" label="是否有免赔(原)" prop="isExemptionPay2000OriginalDesc" show-overflow-tooltip width="150" />
        <el-table-column align="center" label="是否有免赔(改)" prop="isExemptionPay2000NewDesc" show-overflow-tooltip width="150" />
        <el-table-column align="center" label="免赔金额(原)" prop="exemptionPayMoneyOriginalAmount" show-overflow-tooltip width="150">
          <template #default="{ row }">
            {{ row.exemptionPayMoneyOriginalAmount | toThousands }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="免赔金额(改)" prop="exemptionPayMoneyNew" show-overflow-tooltip width="150">
          <template #default="{ row }">
            {{ row.exemptionPayMoneyNew | toThousands }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="免赔支付方(原)" prop="settlementMethodOriginalDesc" show-overflow-tooltip width="150" />
        <el-table-column align="center" label="免赔支付方(改)" prop="settlementMethodNewDesc" show-overflow-tooltip width="150" />
        <el-table-column align="center" label="当次备注" prop="remark" show-overflow-tooltip width="150" />
        <el-table-column align="center" label="变更人" prop="changeUserName" show-overflow-tooltip width="150" />
      </el-table>
      <div class="pagination-wrap">
        <div class="total-text">共{{ total }}条</div>
        <el-pagination
          :current-page="currentPage"
          :page-size="pageSize"
          :page-sizes="[20, 30, 50, 100]"
          :total="total"
          layout="->, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange" />
      </div>
    </div>

    <export-dialog
      :filterForm="form"
      :pageOption="pageOption"
      :setWid="'30%'"
      :total="total"
      :url="exportUrl"
      :visibility.sync="showExportDialog" />
  </div>
</template>

<script>
import search from "@/components/search/index.vue";
import { getDeductibleChangesExportUrl, getDeductibleChangesList } from "@/api/financial/financialOver";
import ExportDialog from "@/components/ExportDialog.vue";

export default {
  name: "DeductibleChanges",
  components: {
    search,
    ExportDialog,
  },
  data() {
    return {
      tableHei: "50vh",
      tableData: [],
      pageSize: 20,
      total: 0,
      currentPage: 1,
      showExportDialog: false,
      pageOption: {
        size: 15,
        current: 1,
      },
      form: {},
      exportUrl: getDeductibleChangesExportUrl,
    };
  },
  created() {
    this.getListData();
  },
  mounted() {
    this.setTableHei();
  },
  methods: {
    /** 动态控制表格高度 **/
    setTableHei(){
      this.$nextTick(()=>{
        let windowsHei = document.documentElement.clientHeight;
        let searchHei = this.$refs.searchRef.$el.offsetHeight;
        let exportHei = this.$refs.exportRef.offsetHeight;
        this.tableHei = windowsHei - searchHei - exportHei - 160
      })
    },
    /** 跳转车单详情 **/
    goCarOrderDetail(id) {
      this.$router.push({
        name: "carBillDetail",
        query: { id },
      });
    },
    /** 搜索数据返回值 **/
    searchSubmit(data) {
      data.changeBeginDateTime = data?.EntryTime?.length > 0 ? data?.EntryTime[0] + " 00:00:00" : "";
      data.changeEndDateTime = data?.EntryTime?.length > 0 ? data?.EntryTime[1] + " 23:59:59" : "";
      this.pageSize = 20;
      this.currentPage = 1;
      this.form = data;
      this.getListData(data);
      console.log("search----data--->>>", data);
    },
    /** 获取列表数据 **/
    getListData(data) {
      let obj = {
        current: this.currentPage,
        size: this.pageSize,
        caseNum: data?.caseNum,
        billCode: data?.billCode,
        clientName: data?.clientName,
        changeBeginDateTime: data?.EntryTime?.length > 0 ? data?.EntryTime[0] + " 00:00:00" : "",
        changeEndDateTime: data?.EntryTime?.length > 0 ? data?.EntryTime[1] + " 23:59:59" : "",
      };
      this.tableData = [];
      getDeductibleChangesList(obj).then(res => {
        console.log("res--->", res);
        this.tableData = res.records;
        this.total = Number(res.total);
      });
    },
    /** 切换一页显示多少数据 **/
    handleSizeChange(newSize) {
      console.log("newSize--->>>", newSize);
      this.pageSize = newSize;
      this.pageOption.size = newSize;
      this.getListData(this.form);
    },
    /** 切换页数 **/
    handleCurrentChange(newPage) {
      console.log("newPage--->>>", newPage);
      this.currentPage = newPage;
      this.getListData(this.form);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  /** 全局form-item/button/input样式 **/
  .el-form-item {
    display: flex;
  }

  :deep .el-input__inner {
    width: 100% !important;
  }

  .table-wrap {
    padding: 0 14px 14px;

    .export-btn-wrap {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 12px;
    }
  }

  .pagination-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;

    .total-text {
      font-size: 14px;
      color: #444;
    }
  }
}
</style>
