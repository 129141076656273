<template>
  <el-drawer
    title="材料领用状态"
    :visible="visibility"
    size="60%"
    :append-to-body="true"
    :before-close="handleClose"
    modal
    close-on-press-escape
    wrapperClosable
  >
    <div class="content">
      <el-table
        ref="multipleTable"
        :data="unreceivedMaterialList"
        border
        style="width: 100%; margin-bottom: 10px"
        class="service-table"
        @select="handleSelect"
        @select-all="handleSelectAll"
      >
        <el-table-column
          type="selection"
          header-align="center"
          align="center"
          width="60"
        ></el-table-column>
        <el-table-column type="index" label="序号" width="60">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="goods.goodsName"
          label="商品名称"
          min-width="160"
        >
        </el-table-column>
        <el-table-column label="商品编码" min-width="100" prop="goods.code">
        </el-table-column>
        <el-table-column label="本店库存" width="88">
          <template #default="{ row }">
            <span>{{ row.inventoryTotalNum }}</span>
            <span>{{ row.goods.unit }}</span>
          </template>
        </el-table-column>
        <el-table-column label="" width="110">
          <template slot="header">
            <div class="flex">
              <span>他店库存</span>
              <i
                class="iconfont grow-icon_search blue"
                style="margin-left: 5px"
                @click="checkOtherInventoryTotalNum"
              ></i>
            </div>
          </template>
          <template slot-scope="scope">
            <span
              class="blue pointer"
              @click="handleCheck(scope.$index, scope.row)"
              >{{
                scope.row.goods.judge === true
                  ? scope.row.goods.otherInventoryTotalNum
                  : '查看'
              }}</span
            >
            <!-- <p>{{ scope.row.goods.otherInventoryTotalNum }}</p> -->
          </template>
        </el-table-column>
        <el-table-column label="预警值" width="68">
          <template #default="{ row }">
            {{ row.goods.alarmNum }}
          </template>
        </el-table-column>
        <el-table-column label="用料数量" width="88" prop="waitPickedNum">
        </el-table-column>
        <el-table-column label="状态" width="80">
          <template>
            <span> 待领料 </span>
            <!-- <span>{{
              row.materialGoodsState | dict('materialGoodsState')
            }}</span> -->
          </template>
        </el-table-column>
      </el-table>
      <el-tabs v-model="activeName">
        <el-tab-pane label="已领明细" name="first">
          <el-table :data="receivedMaterialList" border>
            <el-table-column label="序号" type="index" width="60">
            </el-table-column>
            <el-table-column
              label="商品名称"
              min-width="160"
              prop="goods.goodsName"
            >
            </el-table-column>
            <el-table-column label="项目编码" min-width="100" prop="goods.code">
            </el-table-column>
            <el-table-column label="商品单位" width="88" prop="goods.unit">
            </el-table-column>
            <el-table-column label="已领数量" width="88" prop="pickedNum">
            </el-table-column>
            <el-table-column width="140">
              <template #header>
                <div class="flex-y-center">
                  <span>成本小计</span>
                  <span
                    :key="refreshKey"
                    style="margin-left: 5px"
                    class="flex-y-center"
                  >
                    <i
                      style="font-size: 24px"
                      class="iconfont grow-yanjing_xianshi_o"
                      v-show="showCost"
                      v-auth="169"
                      @click="changeShowCostState"
                    ></i>
                    <i
                      style="font-size: 24px"
                      class="iconfont grow-yanjing_yincang_o"
                      v-show="!showCost"
                      v-auth="169"
                      @click="changeShowCostState"
                    ></i>
                  </span>
                </div>
              </template>
              <template #default="{ row }">
                <span :key="refreshKey">
                  <span v-if="!showCost">****</span>
                  <span v-else>
                    <span v-auth="{ code: 169, handle: 'dataMarking' }">
                      {{
                        $lodash.get(row, 'totalCostPrice', 0) | toThousands
                      }}</span
                    >
                  </span>
                </span>
              </template>
            </el-table-column>
            <template slot="append">
              <div class="received-total flex-x-between">
                <div style="color: #3c3d40">合计</div>
                <div>
                  领料数量合计：{{ count }}， 出库成本总额：
                  <span v-if="!showCost"> **** </span>
                  <span v-else>
                    <span v-auth="{ code: 169, handle: 'dataMarking' }"
                      >{{ totalPrice | toThousands }}
                    </span>
                  </span>
                </div>
              </div>
            </template>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <div class="footer flex-x-between">
        <el-checkbox
          v-model="isSelectAll"
          @change="handleSelectAllChange"
          style="margin-left: 20px"
          >全选</el-checkbox
        >
        <div class="btns-box" style="margin-right: 20px">
          <el-button
            v-auth="71"
            size="small"
            class="custom-btn"
            @click="materialDeliver"
            type="primary"
            >去领料</el-button
          >
          <el-button v-auth="410" size="small" @click="buyAndUse"
            >即采即用</el-button
          >
          <el-button size="small" @click="otherInventory">他店库存</el-button>
        </div>
      </div>
    </div>

    <!-- 他店库存弹窗 -->
    <el-dialog
      title="他店库存"
      :visible.sync="otherInventoryDialog"
      width="50%"
      :before-close="handleCloses"
      :close-on-click-modal="false"
      :modal="false"
    >
      <div>
        <el-table
          :data="otherInventoryList"
          height="350"
          border
          style="margin: 20px 0px; overflow: auto"
          class="service-table"
        >
          <el-table-column type="index" label="序号" width="60">
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            prop="name"
            label="商品名称"
            min-width="160"
          >
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="costPrice" width="100">
            <template #header>
              <div class="flex-y-center">
                <span>成本价</span>
                <span
                  :key="refreshKey"
                  style="margin-left: 5px"
                  class="flex-y-center"
                >
                  <i
                    style="font-size: 24px"
                    class="iconfont grow-yanjing_xianshi_o"
                    v-show="showCost"
                    v-auth="169"
                    @click="changeShowCostState"
                  ></i>
                  <i
                    style="font-size: 24px"
                    class="iconfont grow-yanjing_yincang_o"
                    v-show="!showCost"
                    v-auth="169"
                    @click="changeShowCostState"
                  ></i>
                </span>
              </div>
            </template>
            <template #default="{ row }">
              <span :key="refreshKey">
                <span v-if="!showCost">****</span>
                <span v-else>
                  <span v-auth="{ code: 169, handle: 'dataMarking' }">
                    {{ $lodash.get(row, 'costPrice', 0) | toThousands }}</span
                  >
                </span>
              </span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip label="库存余量" width="88">
            <template #default="{ row }">
              <span :key="refreshKey">
                <span v-if="!showInventory">****</span>
                <span v-else>
                  <span> {{ $lodash.get(row, 'num', 0) | toThousands }}</span>
                </span>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            prop="companyName"
            label="门店"
            min-width="160"
          >
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            prop="inventoryInAndOutBillCode"
            label="批次单据号"
            min-width="140"
          >
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            prop="supplierName"
            label="供应商"
            min-width="140"
          >
          </el-table-column>
        </el-table>
        <div class="other-footer flex-x-end">
          <el-button size="small" @click="jumpOtherInventory"
            >跳转库存调拨</el-button
          >
        </div>
      </div>
    </el-dialog>
  </el-drawer>
</template>
<script>
//接口
import { getMaterialBillDetails, goodOtherInventory } from '@/api/carBill';

import { mapState, mapMutations } from 'vuex';
import { hasAuth } from '@/utils/permissions';

import {
  admin1PagePrefix,
  admin2PagePrefix,
} from '@/config/compatibilityOlderSystems';
export default {
  name: 'materialBillDrawer',
  props: {
    visibility: { type: Boolean, default: false },
    materialBillId: {
      type: [Number, String],
      default: '',
    },
    createCompanyId: {
      type: [Number, String],
      default: '',
    },
    createCompanyName: String,
  },
  data() {
    return {
      // 未领物料列表
      unreceivedMaterialList: [],
      // 已领物料列表
      receivedMaterialList: [],
      // 单据数据
      otherData: {},
      activeName: 'first',
      // 已领数量
      count: 0,
      // 出库成本
      totalPrice: 0,

      // 选中商品
      selectedGoods: [],

      refreshKey: 1,

      // 是否全选
      isSelectAll: false,
      //他店库存弹窗可见性
      otherInventoryDialog: false,
      otherInventoryList: [],
      showInventory: false,
    };
  },
  watch: {
    showCost() {
      this.refreshKey++;
    },
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
      // 是否显示成本
      showCost: (state) => state.user.showCost,
      companyList: (state) => state.user.companyList,
    }),
  },
  created() {
    this.init();
    let result = hasAuth(169);
    if (result) {
      this.showInventory = true;
    } else {
      this.showInventory = false;
    }
  },
  methods: {
    ...mapMutations(['changeShowCostState']),
    // 初始化
    init() {
      this.getData();
    },
    //获取详情数据
    getData() {
      var data = {
        id: this.materialBillId,
        companyId: this.companyId,
      };
      getMaterialBillDetails(data).then((res) => {
        console.log('领料数据', res);
        let { materialService, ...otherData } = res;
        let unreceivedMaterialList = [];
        let receivedMaterialList = [];
        materialService.forEach((item) => {
          // 有商品
          if (item.materialGoods?.length) {
            // 按照是否领取分组
            let { materialGoods, ...serviceData } = item;
            // 已领
            let received = materialGoods.filter(
              // (goods) => ![0, 1, 2].includes(goods.materialGoodsState)
              (goods) => goods.pickedNum
            );

            if (received.length) {
              receivedMaterialList.push(...received);
            }
            // 未领
            let unreceived = materialGoods.filter(
              (goods) => goods.waitPickedNum
            );
            if (unreceived.length) {
              unreceivedMaterialList.push(...unreceived);
            }
          }
        });
        // 处理数据
        function handleTableData(list) {
          list.forEach((service) => {
            service.name = service.serviceItemName;
            service.id = service.serviceItemId;
            if (!service.id) {
              service.id = Math.random();
            }
            service.type = 'service';
            service.count = 0;
            service.itemTotalPrice = 0;
            service.rowspan = 1;
            if (service.materialGoods?.length) {
              service.rowspan += service.materialGoods.length;
              service.materialGoods.forEach((item) => {
                item.name = item.goods.goodsName;
                item.id = service.id + Math.random();
                item.type = 'goods';
                // 领料小计
                service.count += item.pickedNum || 0;
                // 项目成本小计
                service.itemTotalPrice += item.totalCostPrice || 0;
              });
            }
          });
        }
        // handleTableData(unreceivedMaterialList);
        // handleTableData(receivedMaterialList);

        this.unreceivedMaterialList = unreceivedMaterialList;
        this.receivedMaterialList = receivedMaterialList;

        this.otherData = otherData;

        this.calcTotal();
      });
    },
    /**
     * 计算已领合计
     */
    calcTotal() {
      console.log('calc');
      let count = 0;
      let totalPrice = 0;
      this.receivedMaterialList.forEach((item) => {
        count += item.pickedNum;
        totalPrice += +item.totalCostPrice || 0;
      });
      this.count = count;
      this.totalPrice = totalPrice;
    },
    /**
     * 表格勾选
     */
    handleSelect(selection, row) {
      this.selectedGoods = selection;
      this.isSelectAll =
        this.selectedGoods.length === this.unreceivedMaterialList.length;
    },
    /**
     * 勾选全部
     */
    handleSelectAll(val, isSelectAll) {
      if (isSelectAll !== undefined) {
        this.$refs.multipleTable?.toggleAllSelection();
      } else {
        this.selectedGoods = val;
        this.isSelectAll =
          this.selectedGoods.length === this.unreceivedMaterialList.length;
      }
    },
    /**
     * 处理全选按钮变化事件
     */
    handleSelectAllChange(bool) {
      this.handleSelectAll(false, bool);
    },
    /**
     * 即采即用
     */
    buyAndUse() {
      if (!this.selectedGoods?.length) {
        this.$message.warning('请先选择商品！');
        return;
      }

      var bool = this.checkCurrentUserStore();
      if (!bool) return;

      let purchasingBill = [];
      this.selectedGoods.forEach((goods) => {
        // 以下来自ng旧系统逻辑 c.reception2 :1565
        if (goods.num - goods.pickedNum > 0) {
          var needGoods = this.$lodash.cloneDeep(goods);

          needGoods.goods.goodsCode = needGoods.goods.code;
          needGoods.goodsId = needGoods.goods.goodsId;

          needGoods.num = needGoods.waitPickedNum;
          needGoods.carId = this.otherData.carId;
          needGoods.carNumber = this.otherData.carNumber;
          // needGoods.car =this.otherData.
          needGoods.price = needGoods.goods.price || 0;
          if (needGoods.storeRoomInventory) {
            if (needGoods.storeRoomInventory.storeRoom) {
              needGoods.storeRoom = needGoods.storeRoomInventory.storeRoom;
              needGoods.storeRoomId =
                needGoods.storeRoomInventory.storeRoom.storeRoomId;
            }
            if (needGoods.storeRoomInventory.location) {
              needGoods.location = needGoods.storeRoomInventory.location;
              needGoods.locationId =
                needGoods.storeRoomInventory.location.locationId;
            }
          }
          purchasingBill.push(needGoods);
        }
      });

      localStorage.setItem('purchasingBill', JSON.stringify(purchasingBill));
      localStorage.setItem('companyId', this.createCompanyId);

      window.open(
        admin2PagePrefix +
          '#/stock/purchaseEdit?purchaseType=0&isPurchasePicking=true&keepRoute=true',
        '_blank'
      );
    },
    /**
     * 领料出库
     */
    materialDeliver() {
      if (!this.selectedGoods?.length) {
        this.$message.warning('请先选择商品！');
        return;
      }

      var bool = this.checkCurrentUserStore();
      if (!bool) return;

      const ids = this.selectedGoods.map((item) => item?.goods?.goodsId);
      console.log(ids);
      localStorage.setItem('goodsIds', JSON.stringify(ids));
      const id = this.otherData.materialBillId;
      localStorage.setItem('companyId', this.createCompanyId);
      window.open(
        admin2PagePrefix +
          `#/stock/outOfStock/repairPickingEdit?keepRoute=true&materialBillId=${id}`,
        '_blank'
      );
    },
    //他店库存按钮
    otherInventory() {
      if (!this.selectedGoods?.length) {
        this.$message.warning('请先选择商品！');
        return;
      }
      const ids = this.selectedGoods.map((item) => item?.goods?.goodsId);
      var data = {
        goodIds: ids,
      };
      goodOtherInventory(data).then((res) => {
        this.otherInventoryList = res;
      });
      this.otherInventoryDialog = true;
    },
    //跳转旧系统调拨页面
    jumpOtherInventory() {
      var bool = this.checkCurrentUserStore();
      if (!bool) return;
      localStorage.setItem('companyId', this.createCompanyId);
      window.open(
        admin2PagePrefix +
          '#/stock/allocateInBill?allocateType=1&keepRoute=true',
        '_blank'
      );
    },
    /**
     * 校验当前用户门店列表内是否包含该车单开单门店
     */
    checkCurrentUserStore() {
      if (
        !this.companyList
          .map((el) => el.company[0].companyId)
          .includes(this.createCompanyId)
      ) {
        this.$message.warning(
          `该车单所属门店“${this.createCompanyName}”，你暂无该门店操作权限，请联系管理员`
        );
        return false;
      } else {
        return true;
      }
    },
    /**
     * 关闭他店库存弹窗
     */
    handleCloses() {
      this.otherInventoryDialog = false;
    },
    /**
     * 关闭弹窗
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
    /**
     * 编辑用料保存时
     */
    handleEdit() {
      this.getData();
    },
    checkOtherInventoryTotalNum() {
      this.unreceivedMaterialList.forEach((item) => {
        item.goods.judge = true;
      });
    },
    handleCheck(index, row) {
      //实现点击哪一个查看就显示哪一列的数据
      this.unreceivedMaterialList[index].goods.judge = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  height: calc(100% - 52px);
  position: relative;
  padding: 0 30px;
  box-sizing: border-box;
  overflow: auto;
}
.received-total {
  padding: 15px;
  background: #f0f2f5;
  color: #333;
  font-size: 14px;
}
.footer {
  width: 60%;
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  background: #fff;
  z-index: 1;

  // .btns-box {
  //   margin-bottom: 15px;
  //   color: #666;
  //   .custom-btn {
  //     border-color: #42bbb8;
  //     background: #42bbb8;
  //     color: #fff;
  //     &:hover {
  //       border-color: #5ebdbb;
  //       background: #5ebdbb;
  //     }
  //   }
  // }
}
// .service-table.el-table ::v-deep .el-table__row--level-1 {
//   background: #f5f7fa;
// }
// .service-table.el-table ::v-deep .el-table__placeholder {
//   width: 0;
// }
// .service-table.el-table ::v-deep .el-table__indent {
//   padding: 0 !important;
// }
.other-footer {
  height: 70px;
  button {
    height: 32px;
    margin-top: 20px;
  }
}
</style>
