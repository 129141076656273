<template>
  <div>
    <el-dialog
      width="50%"
      :visible="visibility"
      :before-close="handleClose"
      :close-on-click-modal="false"
      append-to-body
    >
      <template slot="title">
        <div class="flex-y-center custom-dialog-title">
          <h2>设置成本</h2>
          <i
            class="pointer iconfont grow-icon_warn_32"
            style="color: #ff9200; font-size: 15px; margin: 8px 6px"
          ></i
          ><span style="color: #909399; margin-top: 2px; font-size: 12px"
            >设置{{ time.slice(0, 4) }}年{{ time.slice(5, 7) }}月成本</span
          >
        </div>
      </template>
      <div style="height: 400px; overflow-y: auto">
        <el-form
          :model="form"
          :rules="rules"
          ref="ruleForm"
          label-width="120px"
          class="demo-ruleForm"
        >
          <el-form-item label="选择业务类型" prop="businessType">
            <el-radio-group v-model="form.businessType" @input="change">
              <el-radio
                v-for="item in carBillType"
                :key="item.value"
                :label="item.value"
              >
                <span style="margin-left: 5px">{{ item.label }}</span>
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="车单成本类型" prop="costType">
            <el-radio-group v-model="form.costType" @input="change">
              <el-radio
                v-for="item in carCostType"
                :key="item.value"
                :label="item.value"
                style="margin-bottom: 10px"
              >
                <span style="margin-left: 5px">{{ item.label }}</span>
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="设置成本金额">
            <p style="font-weight: 400; color: #606266; font-size: 12px">
              仅设置“指挥中心-门店管理”的门店清单
            </p>
            <template slot="label">
              <div style="text-align: left; margin-left: 14px">
                <span class="red">*</span>&nbsp;设置成本金额
              </div>
            </template>
            <el-table
              :data="tableData"
              style="width: 500px; color: #242526"
              border
            >
              <el-table-column prop="companyName" label="门店管理" width="250">
              </el-table-column>
              <el-table-column prop="amount" label="成本金额">
                <template #default="{ row }">
                  <number-input
                    v-model="row.amount"
                    decimalDigits="2"
                    placeholder="请输入成本金额"
                    clearable
                    size="small"
                  >
                  </number-input>
                </template>
              </el-table-column>
            </el-table>
            <p
              :class="{ showTip: !noNull }"
              style="font-size: 12px; color: #e84c4c"
            >
              最少有一家门店成本金额非空
            </p>
          </el-form-item>
          <!-- <el-form-item>
            <div class="footer">
              
            </div>
          </el-form-item> -->
        </el-form>
      </div>
      <template slot="footer">
        <el-button size="small" type="primary" @click="handleConfirm()"
          >确 定</el-button
        >
        <el-button size="small" @click="handleClose">取 消</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
// 字典数据
import { carBillType, costType } from '@/service/dict/dictData';
import {
  companyCost,
  companyCostBatchSave,
} from '@/api/technologyCenter/profit';
export default {
  name: 'costDialog',
  components: {},
  props: {
    visibility: { type: Boolean, default: false },
    time: {
      type: [Number, String],
    },
    typeNum: {
      type: [Number, String],
    },
    costNum: {
      type: [Number, String],
    },
  },
  data() {
    return {
      form: {
        businessType: '',
        costType: '',
      },
      rules: {
        businessType: [
          { required: true, message: '请选择业务类型', trigger: 'change' },
        ],
        costType: [
          { required: true, message: '请选择车单成本类型', trigger: 'change' },
        ],
      },
      carBillType: carBillType,
      carCostType: costType,
      tableData: [],
      noNull: '',
    };
  },
  watch: {},
  created() {
    this.form.businessType = this.typeNum;
    this.form.costType = this.costNum;
    this.getData();
  },
  methods: {
    change(value) {
      this.getData();
    },
    /**
     * 关闭弹窗
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
    handleConfirm() {
      var data = {
        statisticsPeriod: this.time,
        billType: this.form.businessType,
        costType: this.form.costType,
        list: Object.assign(this.tableData),
      };
      //判断每个门店的成本金额是否全部为空
      this.noNull = this.tableData.every((item) => {
        return !item.amount;
      });
      if (this.noNull) {
        // 显示要输入必填项
      } else {
        companyCostBatchSave(data).then((res) => {
          if (res.code == 200) {
            this.$message.success('设置成功');
            this.$emit('update:visibility', false);
            this.$emit('updateData'); //点击成功之后更新数据
          }
        });
      }
      // this.$refs[formName].validate((valid) => {
      //   if (valid) {
      //   } else {
      //     return false;
      //   }
      // });
    },
    //获取门店成本
    getData() {
      var data = {
        statisticsPeriod: this.time,
        startTime: this.time.slice(0, 4) + '-' + this.time.slice(5, 7) + '-01',
        billType: this.form.businessType,
        costType: this.form.costType,
      };
      companyCost(data).then((res) => {
        this.tableData = res;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.footer {
  margin-top: 51px;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 16px;
}
.showTip {
  display: none;
}
.el-form-item label {
  color: #242526 !important;
}
</style>
