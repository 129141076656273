var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      ref: "popover",
      attrs: {
        placement: "bottom-start",
        trigger: "manual",
        "popper-class": "popper-class",
      },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
          staticClass: "data-table",
          attrs: { "max-height": 200, border: "", data: _vm.listData },
          on: { "row-click": _vm.handleClick },
        },
        [
          _c("el-table-column", {
            attrs: { width: "90 ", label: "客户名称", prop: "clientName" },
          }),
          _c("el-table-column", {
            attrs: { "min-width": "160", label: "协议客户" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.isAgreementClient == 1
                      ? _c("p", [
                          _vm._v(" 协议客户"),
                          row.beContract
                            ? _c("span", [_vm._v("（包干）")])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    row.isAgreementClient == 0 ? _c("p") : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "90 ", label: "等级", prop: "clientLevel" },
          }),
          _c("template", { slot: "append" }, [
            _vm.hasMore
              ? _c(
                  "div",
                  {
                    staticClass: "load-more-btn",
                    on: {
                      click: function ($event) {
                        return _vm.getData(false, true)
                      },
                    },
                  },
                  [_vm._v(" 加载更多数据 ")]
                )
              : _vm._e(),
          ]),
        ],
        2
      ),
      _c(
        "template",
        { slot: "reference" },
        [
          _c(
            "el-input",
            {
              ref: "keyWordInput",
              staticStyle: { width: "200px" },
              attrs: {
                size: "small",
                placeholder: "搜索客户名称",
                clearable: _vm.clearable,
              },
              on: {
                input: function ($event) {
                  return _vm.getData(true)
                },
                focus: function ($event) {
                  _vm.listData.length && (_vm.visible = true)
                },
                clear: _vm.handleClear,
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.getData(true)
                },
              },
              model: {
                value: _vm.keyWord,
                callback: function ($$v) {
                  _vm.keyWord = $$v
                },
                expression: "keyWord",
              },
            },
            [
              _c("i", {
                staticClass: "el-input__icon el-icon-search pointer",
                attrs: { slot: "suffix" },
                on: {
                  click: function ($event) {
                    return _vm.getData(true)
                  },
                },
                slot: "suffix",
              }),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }