var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visibility,
        width: "800px",
        "before-close": _vm.handleClose,
        "close-on-click-modal": false,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticStyle: { "margin-top": "18px" },
          attrs: {
            size: "small",
            model: _vm.editForm,
            rules: _vm.rules,
            "label-width": "110px",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "解决方案", prop: "solutionType" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: "请选择解决方案",
                          },
                          model: {
                            value: _vm.editForm.solutionType,
                            callback: function ($$v) {
                              _vm.$set(_vm.editForm, "solutionType", $$v)
                            },
                            expression: "editForm.solutionType",
                          },
                        },
                        _vm._l(_vm.solutionTypeEnumList, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "卡点责任人",
                        prop: "stuckPointResponsibleUserName",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "200",
                          placeholder: "请输入卡点责任人",
                        },
                        model: {
                          value: _vm.editForm.stuckPointResponsibleUserName,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.editForm,
                              "stuckPointResponsibleUserName",
                              $$v
                            )
                          },
                          expression: "editForm.stuckPointResponsibleUserName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "清单责任人",
                        prop: "detailListResponsibleUserName",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "200",
                          placeholder: "请输入清单责任人",
                        },
                        model: {
                          value: _vm.editForm.detailListResponsibleUserName,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.editForm,
                              "detailListResponsibleUserName",
                              $$v
                            )
                          },
                          expression: "editForm.detailListResponsibleUserName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "完成时间",
                        prop: "strandedCompletionTime",
                      },
                    },
                    [
                      _c("el-date-picker", {
                        staticClass: "w100",
                        attrs: {
                          type: "datetime",
                          placeholder: "请选择完成时间",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.dateRangeChange()
                          },
                        },
                        model: {
                          value: _vm.editForm.strandedCompletionTime,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.editForm,
                              "strandedCompletionTime",
                              $$v
                            )
                          },
                          expression: "editForm.strandedCompletionTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "滞留原因", prop: "strandedReason" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: 4,
                          "show-word-limit": "",
                          maxlength: "100",
                          placeholder: "请输入滞留原因",
                        },
                        model: {
                          value: _vm.editForm.strandedReason,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "strandedReason", $$v)
                          },
                          expression: "editForm.strandedReason",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.save },
            },
            [_vm._v(" 确 定 ")]
          ),
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.handleClose } },
            [_vm._v(" 取 消 ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }