import http from '@/service/http';

export function listData(data) {
  return http.post('/admin/car/bill/tracing/nf/setting/list', data);
}
export function saveData(data) {
  return http.post('/admin/car/bill/tracing/nf/setting/save', data);
}
export function updateData(data) {
  return http.post('/admin/car/bill/tracing/nf/setting/update', data);
}
export function deleteData(data) {
  return http.post('/admin/car/bill/tracing/nf/setting/delete', data);
}
export function enableData(data) {
  return http.post('/admin/car/bill/tracing/nf/setting/enable', data);
}
export function disableData(data) {
  return http.post('/admin/car/bill/tracing/nf/setting/disable', data);
}
export function detailData(data) {
  return http.post('/admin/car/bill/tracing/nf/setting/detail', data);
}
export function listAllCompany(data) {
  return http.post('/admin/car/bill/tracing/nf/setting/listAllCompany', data);
}
export function listUserByCompanyId(data) {
  return http.get(
    '/admin/car/bill/tracing/nf/setting/listUserByCompanyId?companyId=' +
      data.companyId,
    data
  );
}
// 导出
export const exportDataUrl = '/admin/car/bill/tracing/nf/setting/list/export';
