var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    {
      attrs: {
        customStyle: "background: #f1f7fa;",
        customMainStyle: "margin-bottom: 16px;",
        defaultPadding: false,
      },
    },
    [
      _c(
        "div",
        { staticClass: "flex-x-between", staticStyle: { height: "100%" } },
        [
          _c("div", { staticClass: "preview" }, [
            _c("div", { staticClass: "material-info" }, [
              _c("div", { staticClass: "flex" }, [
                _c("div", { staticClass: "tag" }, [_vm._v("技术案例")]),
                _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.title)),
                ]),
              ]),
              _c("p", [
                _vm._v(
                  "供稿：" +
                    _vm._s(_vm.companyName) +
                    " " +
                    _vm._s(_vm.creatorName)
                ),
              ]),
            ]),
            _c("div", {
              staticClass: "preview-html-box not-apply-reset",
              domProps: { innerHTML: _vm._s(_vm.html) },
            }),
          ]),
          _c(
            "div",
            { staticClass: "material-box" },
            [
              _c(
                "el-row",
                { staticClass: "flex-y-center" },
                [
                  _c("el-col", { attrs: { span: 21 } }, [
                    _c(
                      "div",
                      { staticClass: "flex-y-center" },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: { margin: "0 5px", color: "#e84c4c" },
                          },
                          [_vm._v("*")]
                        ),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "margin-right": "10px",
                              "flex-shrink": "0",
                              "font-size": "14px",
                              color: "#242526",
                            },
                          },
                          [_vm._v("标题")]
                        ),
                        _c("el-input", {
                          attrs: {
                            placeholder: "请填写",
                            maxlength: "255",
                            "show-word-limit": "",
                          },
                          model: {
                            value: _vm.title,
                            callback: function ($$v) {
                              _vm.title = $$v
                            },
                            expression: "title",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 2, offset: 1 } },
                    [
                      _c(
                        "el-button",
                        { on: { click: _vm.handleUseTemplate } },
                        [_vm._v("使用模板")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "p",
                {
                  staticStyle: {
                    margin: "12px 0 20px 0",
                    "font-size": "12px",
                    color: "#606266",
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-icon-warning orange",
                    staticStyle: { "font-size": "14px", "margin-right": "5px" },
                  }),
                  _vm._v(" 注意：标题请按模板填写：车型-故障现象-故障原因 "),
                ]
              ),
              _c(
                "div",
                { staticClass: "editor-container" },
                [
                  _c("Toolbar", {
                    staticStyle: { "border-bottom": "1px solid #dcdfe6" },
                    attrs: {
                      editor: _vm.editor,
                      defaultConfig: _vm.toolbarConfig,
                      mode: _vm.mode,
                    },
                  }),
                  _c("Editor", {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.showLoading,
                        expression: "showLoading",
                      },
                    ],
                    staticClass: "not-apply-reset",
                    staticStyle: {
                      flex: "1",
                      height: "100%",
                      "overflow-y": "hidden",
                    },
                    attrs: { defaultConfig: _vm.editorConfig, mode: _vm.mode },
                    on: { onCreated: _vm.onCreated },
                    model: {
                      value: _vm.html,
                      callback: function ($$v) {
                        _vm.html = $$v
                      },
                      expression: "html",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("template", { slot: "footer" }, [
        _c("div", { staticClass: "footer-box" }, [
          _c(
            "div",
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "margin-right": "5px",
                    "font-size": "14px",
                    color: "#242526",
                  },
                },
                [_vm._v("供稿人")]
              ),
              _c("searchEmployeePopover", {
                attrs: { clearable: false, searchKeyWord: _vm.creatorName },
                on: {
                  select: _vm.handleSelectEmployee,
                  clear: _vm.handleClearEmployee,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "flex" },
            [
              _vm.auth
                ? [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.save(1)
                          },
                        },
                      },
                      [_vm._v("提交上架")]
                    ),
                  ]
                : [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.save(2)
                          },
                        },
                      },
                      [_vm._v("提交审核")]
                    ),
                  ],
              ![1, 4].includes(_vm.state)
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", plain: "", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.save(3)
                        },
                      },
                    },
                    [_vm._v("存为草稿")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.closePage } },
                [_vm._v("取 消")]
              ),
            ],
            2
          ),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "通过",
            visible: _vm.submitDialogVisible,
            width: "30%",
            top: "20vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.submitDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { size: "small" } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "车型信息",
                    "label-width": "80px",
                    required: "",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w100",
                      attrs: {
                        filterable: "",
                        remote: "",
                        placeholder: "搜索车型",
                        "remote-method": _vm.searchModel,
                        loading: _vm.searchModelLoading,
                        clearable: "",
                      },
                      model: {
                        value: _vm.carModel,
                        callback: function ($$v) {
                          _vm.carModel = $$v
                        },
                        expression: "carModel",
                      },
                    },
                    _vm._l(_vm.models, function (item) {
                      return _c(
                        "el-option",
                        {
                          key: _vm.$lodash.get(item, "series.id"),
                          attrs: {
                            value: _vm.$lodash.get(
                              item,
                              "series.name",
                              _vm.$lodash.get(
                                item,
                                "brand.name",
                                _vm.$lodash.get(item, "logo.name")
                              )
                            ),
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.selectCarModel(item)
                            },
                          },
                        },
                        [
                          _c("span", {
                            domProps: { innerHTML: _vm._s(item.markedRedName) },
                          }),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "所属系统",
                    "label-width": "80px",
                    required: "",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w100",
                      attrs: { placeholder: "请选择", filterable: "" },
                      model: {
                        value: _vm.guideSysId,
                        callback: function ($$v) {
                          _vm.guideSysId = $$v
                        },
                        expression: "guideSysId",
                      },
                    },
                    _vm._l(_vm.systemList, function (item) {
                      return _c("el-option", {
                        key: item.sysId,
                        attrs: { label: item.name, value: item.sysId },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submit(true)
                    },
                  },
                },
                [_vm._v("上 架")]
              ),
              _vm.state != 4
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.submit(false)
                        },
                      },
                    },
                    [_vm._v("暂不上架")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }