var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    {
      ref: "baseContainer",
      attrs: {
        customStyle: "background: #f1f7fa;",
        customMainStyle: "margin-bottom: 16px;",
        defaultPadding: false,
      },
    },
    [
      [
        _c(
          "el-form",
          {
            staticClass: "filter-form",
            staticStyle: { background: "#fff" },
            attrs: { "label-width": "100px", size: "small" },
            nativeOn: {
              submit: function ($event) {
                $event.preventDefault()
              },
            },
          },
          [
            _c("div", { staticClass: "flex-x-between" }, [
              _c(
                "div",
                { staticClass: "flex flex-shrink0" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "项目名称" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入项目名称", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.getData.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.serviceName,
                          callback: function ($$v) {
                            _vm.serviceName = $$v
                          },
                          expression: "serviceName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "el-form-item--small" }, [
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "btn_search",
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.getData },
                      },
                      [_vm._v("查询")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn_search",
                        attrs: { size: "small" },
                        on: { click: _vm.empty },
                      },
                      [_vm._v("置空")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        ),
      ],
      [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "left" }, [
            _c(
              "ul",
              {
                staticClass: "car-brand-tree_ul",
                staticStyle: { "margin-top": "16px" },
              },
              [
                _c("div", { staticClass: "title" }, [_vm._v("品牌车型")]),
                _vm._l(_vm.treeData, function (lv1, index) {
                  return _c(
                    "li",
                    { key: index, staticClass: "car-brand-item_li" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "car-brand-name_div",
                          class: {
                            "has-children": lv1.series.length,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleLv1Click(lv1)
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticStyle: { "margin-right": "5px" },
                            attrs: { src: lv1.logoImage },
                          }),
                          _c("span", { staticClass: "car-brand-name_text" }, [
                            _vm._v(
                              " " +
                                _vm._s(lv1.logoName) +
                                " (" +
                                _vm._s(lv1.series.length) +
                                ") "
                            ),
                          ]),
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: lv1.series,
                                expression: "lv1.series",
                              },
                            ],
                            staticClass:
                              "item-expand_icon newicon grow-icon-arrow-right",
                            class: { active: lv1.showChild },
                          }),
                        ]
                      ),
                      _c(
                        "ul",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: lv1.showChild,
                              expression: "lv1.showChild",
                            },
                          ],
                          staticClass: "tree-item_ul",
                        },
                        _vm._l(lv1.series, function (lv2, index) {
                          return _c(
                            "li",
                            { key: index, staticClass: "tree-item_li" },
                            [
                              _c(
                                "div",
                                {
                                  class: {
                                    "tree-item-name_div": true,
                                    active: _vm.lv2ActiveId == lv2.seriesId,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleLv2Click(lv2)
                                    },
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(" " + _vm._s(lv2.seriesName) + " "),
                                  ]),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.$lodash.get(
                                          lv2.modelNumbers,
                                          "length",
                                          0
                                        ),
                                        expression:
                                          "$lodash.get(lv2.modelNumbers, 'length', 0)",
                                      },
                                    ],
                                    staticClass:
                                      "item-expand_icon newicon grow-icon-arrow-right",
                                    class: { active: lv2.showChild },
                                  }),
                                ]
                              ),
                              _c(
                                "ul",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: lv2.showChild,
                                      expression: "lv2.showChild",
                                    },
                                  ],
                                  staticClass: "tree-item_ul",
                                },
                                _vm._l(lv2.modelNumbers, function (lv3, index) {
                                  return _c(
                                    "li",
                                    { key: index, staticClass: "tree-item_li" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          class: {
                                            "tree-item-name_div": true,
                                            active:
                                              _vm.lv3ActiveId ==
                                              lv3.modelNumberId,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleLv3Click(lv3)
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(lv3.modelNumberName) +
                                                " "
                                            ),
                                          ]),
                                          _c("i", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.$lodash.get(
                                                  lv3.modelYears,
                                                  "length",
                                                  0
                                                ),
                                                expression:
                                                  "$lodash.get(lv3.modelYears, 'length', 0)",
                                              },
                                            ],
                                            staticClass:
                                              "item-expand_icon newicon grow-icon-arrow-right",
                                            class: { active: lv3.showChild },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "ul",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: lv3.showChild,
                                              expression: "lv3.showChild",
                                            },
                                          ],
                                          staticClass: "tree-item_ul",
                                        },
                                        _vm._l(
                                          lv3.modelYears,
                                          function (lv4, index) {
                                            return _c(
                                              "li",
                                              {
                                                key: index,
                                                staticClass: "tree-item_li",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    class: {
                                                      "tree-item-name_div": true,
                                                      "last-level": true,
                                                      active:
                                                        _vm.lv4ActiveId ==
                                                        lv4.modelYearId,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleLv4Click(
                                                          lv4
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          lv4.modelYearName
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  )
                }),
              ],
              2
            ),
          ]),
          _c(
            "div",
            { staticClass: "right" },
            [
              _c(
                "div",
                { staticClass: "right-content" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "flex-x-between",
                      staticStyle: { "margin-bottom": "16px" },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "auth",
                                  rawName: "v-auth",
                                  value: 581,
                                  expression: "581",
                                },
                              ],
                              attrs: { type: "primary", size: "small" },
                              on: {
                                click: function ($event) {
                                  _vm.importDialogVisibility = true
                                },
                              },
                            },
                            [_vm._v("导入")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-table",
                    { attrs: { data: _vm.listData, border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: { type: "index", label: "序号", width: "60" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "min-width": "125",
                          label: "服务项目",
                          prop: "serviceName",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          width: "120",
                          label: "服务时效（H）",
                          prop: "serviceTime",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          width: "100",
                          label: "工时报价",
                          prop: "servicePrice",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "min-width": "170",
                          label: "商品标准名",
                          prop: "standardName",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "min-width": "100",
                          label: "原厂编码",
                          prop: "goodsCode",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          width: "80",
                          label: "数量",
                          prop: "num",
                          align: "center",
                        },
                      }),
                      _c(
                        "el-table-column",
                        {
                          attrs: {
                            width: "200",
                            label: "商品成本价",
                            align: "center",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              width: "100",
                              label: "品牌",
                              prop: "brandCost",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              width: "100",
                              label: "原厂",
                              prop: "originalCost",
                              align: "center",
                            },
                          }),
                          _vm.showOfficialCost
                            ? _c("el-table-column", {
                                attrs: {
                                  width: "100",
                                  label: "正厂",
                                  prop: "officialCost",
                                  align: "center",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("pagination", {
                staticClass: "pagination-box",
                attrs: { total: _vm.total },
                on: { pageChange: _vm.changePage },
              }),
            ],
            1
          ),
        ]),
      ],
      _vm.importDialogVisibility
        ? _c("import-dialog", {
            attrs: {
              visibility: _vm.importDialogVisibility,
              title: "常用报价模板导入",
              templateFileUrl:
                "https://growxny-dev-center.oss-cn-guangzhou.aliyuncs.com/template/20231204/%E5%B8%B8%E7%94%A8%E6%8A%A5%E4%BB%B7%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx",
              uploadFileApi: _vm.usualQuoteImportApi,
              getLastResultApi: _vm.usualQuoteImportResultApi,
              importFailDetail: _vm.usualQuoteImportMessageExportUrlApi,
              disabled: false,
            },
            on: {
              "update:visibility": function ($event) {
                _vm.importDialogVisibility = $event
              },
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }