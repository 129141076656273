var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("video-player", {
    ref: "videoPlayer",
    staticClass: "video-player-box vjs-big-play-centered",
    attrs: { playsinline: true, options: _vm.playerOptions },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }