var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visibility,
        width: "800px",
        title: "门店范围管理",
        "close-on-click-modal": false,
        "before-close": _vm.handleClose,
      },
    },
    [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("p", { staticClass: "tip-word" }, [
            _c("i", { staticClass: "el-icon-warning orange" }),
            _vm._v(
              " 设置门店运营指挥中心中需要管控的门店清单，仅管控统计列表的门店清单 "
            ),
          ]),
          _c(
            "el-table",
            { attrs: { data: _vm.tableData, border: "" } },
            [
              _c("el-table-column", {
                attrs: { label: "操作", width: "60", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ $index }) {
                      return [
                        _c("i", {
                          staticClass: "iconfont grow-icon_delete3 pointer red",
                          on: {
                            click: function ($event) {
                              return _vm.del($index)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "序号", width: "60", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ $index }) {
                      return [_vm._v(" " + _vm._s($index + 1) + " ")]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "门店编号", prop: "companyId" },
              }),
              _c("el-table-column", {
                attrs: { label: "门店名称", prop: "companyName" },
              }),
              _c("el-table-column", {
                attrs: { prop: "showState" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              placement: "top-start",
                              content: "控制指挥中心地图是否显示该门店位置",
                            },
                          },
                          [
                            _c("span", [
                              _vm._v("是否显示门店位置 "),
                              _c("i", {
                                staticClass: "iconfont grow-icon_doubt",
                                staticStyle: { color: "#9a9a9a" },
                              }),
                            ]),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("el-switch", {
                          attrs: {
                            "active-text": "显示",
                            "inactive-text": "不显示",
                            "active-value": 0,
                            "inactive-value": 1,
                          },
                          model: {
                            value: row.showState,
                            callback: function ($$v) {
                              _vm.$set(row, "showState", $$v)
                            },
                            expression: "row.showState",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("template", { slot: "append" }, [
                _c(
                  "div",
                  { staticClass: "append" },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          size: "small",
                          filterable: "",
                          remote: "",
                          placeholder: "搜索车店名称、编号",
                          "remote-method": _vm.searchNotControlCompanyList,
                          loading: _vm.searchNotControlCompanyListLoading,
                        },
                        on: { change: _vm.handleSelect },
                        model: {
                          value: _vm.selectedCompany,
                          callback: function ($$v) {
                            _vm.selectedCompany = $$v
                          },
                          expression: "selectedCompany",
                        },
                      },
                      _vm._l(_vm.notControlCompanyList, function (item) {
                        return _c(
                          "el-option",
                          {
                            key: item.companyId,
                            attrs: { label: item.companyName, value: item },
                          },
                          [
                            _c("span", { staticStyle: { float: "left" } }, [
                              _vm._v(_vm._s(item.companyId)),
                            ]),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  float: "right",
                                  color: "#8492a6",
                                },
                              },
                              [_vm._v(_vm._s(item.companyName))]
                            ),
                          ]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.handleClose } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.handleSave },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }