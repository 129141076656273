var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "header" }, [
      _c("img", {
        staticClass: "header-title",
        attrs: {
          src: require("./images/header-title.png"),
          alt: "门店运营指挥中心",
        },
      }),
      _c("img", {
        directives: [
          { name: "auth", rawName: "v-auth", value: 536, expression: "536" },
        ],
        staticClass: "eye",
        attrs: {
          src: _vm.hideNumber
            ? require("./images/icon_eye_close.png")
            : require("./images/icon_eye_open.png"),
        },
        on: {
          click: function ($event) {
            _vm.hideNumber = !_vm.hideNumber
          },
        },
      }),
      _c("div", { staticClass: "time" }, [
        _vm._v("当前时间：" + _vm._s(_vm.time)),
      ]),
    ]),
    _c("div", { staticClass: "middle" }, [
      _c("div", { staticClass: "side" }, [
        _c("div", { staticClass: "left-top" }, [
          _c("div", { staticClass: "title" }, [_vm._v("经营数据")]),
          _c("div", { staticClass: "manage-data" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "top" }, [
                _c("img", {
                  attrs: {
                    src: require("./images/icon_fuwutaici@2x.png"),
                    alt: "",
                  },
                }),
                _c("div", { staticClass: "text" }, [
                  _c(
                    "div",
                    { staticClass: "transition turnover data-number" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("toThousands")(
                              _vm.manageData.intoNumOfYear,
                              0
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "label" }, [
                    _vm._v("本年服务（台次）"),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "top" }, [
                _c("img", {
                  attrs: {
                    src: require("./images/icon_bennianleiji@2x.png"),
                    alt: "",
                  },
                }),
                _c("div", { staticClass: "text" }, [
                  _c(
                    "div",
                    { staticClass: "transition turnover data-number" },
                    [
                      !_vm.hideNumber
                        ? [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("toThousands")(
                                    _vm.manageData.turnoverOfYear,
                                    0
                                  )
                                ) +
                                " "
                            ),
                          ]
                        : [_vm._v(_vm._s(_vm.hideNumberText))],
                    ],
                    2
                  ),
                  _c("div", { staticClass: "label" }, [
                    _vm._v("本年累计（元）"),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "box" }, [
                _c("div", { staticClass: "label" }, [_vm._v("本月")]),
                _c("div", { staticClass: "number" }, [
                  _c("span", { staticClass: "transition data-number" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("toThousands")(_vm.manageData.intoNumOfMonth, 0)
                      )
                    ),
                  ]),
                  _c("span", [_vm._v("台次")]),
                ]),
                _c("div", { staticClass: "money" }, [
                  _c(
                    "span",
                    { staticClass: "transition data-number" },
                    [
                      !_vm.hideNumber
                        ? [
                            _vm._v(
                              " ￥" +
                                _vm._s(
                                  _vm._f("toThousands")(
                                    _vm.manageData.turnoverOfMonth,
                                    0
                                  )
                                ) +
                                " "
                            ),
                          ]
                        : [_vm._v(_vm._s(_vm.hideNumberText))],
                    ],
                    2
                  ),
                ]),
              ]),
              _c("div", { staticClass: "box" }, [
                _c("div", { staticClass: "label" }, [_vm._v("本日")]),
                _c("div", { staticClass: "number" }, [
                  _c("span", { staticClass: "transition data-number" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("toThousands")(_vm.manageData.intoNumOfDay, 0)
                      )
                    ),
                  ]),
                  _c("span", [_vm._v("台次")]),
                ]),
                _c(
                  "div",
                  { staticClass: "transition money data-number" },
                  [
                    !_vm.hideNumber
                      ? [
                          _vm._v(
                            "￥" +
                              _vm._s(
                                _vm._f("toThousands")(
                                  _vm.manageData.turnoverOfDay,
                                  0
                                )
                              )
                          ),
                        ]
                      : [_vm._v(_vm._s(_vm.hideNumberText))],
                  ],
                  2
                ),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "left-bottom" }, [
          _c("div", { staticClass: "title" }, [_vm._v("服务品牌")]),
          _c("div", { staticClass: "service-brand" }, [
            _c("div", { attrs: { id: "service-brand-chart" } }),
            _c("div", { staticClass: "service-brand-list" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "service-brand-item first" }, [
                  _c("div", { staticClass: "left-info" }, [
                    _c("div", { staticClass: "square" }),
                    _c(
                      "div",
                      {
                        staticClass: "brand-name",
                        attrs: { title: _vm.serviceBrandData[0].name },
                      },
                      [_vm._v(" " + _vm._s(_vm.serviceBrandData[0].name) + " ")]
                    ),
                  ]),
                  _c("div", { staticClass: "number" }, [
                    _c(
                      "span",
                      { staticClass: "data-number" },
                      [
                        !_vm.hideNumber
                          ? [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("toThousands")(
                                      _vm.serviceBrandData[0].carNum,
                                      0
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          : [_vm._v(_vm._s(_vm.hideNumberText))],
                      ],
                      2
                    ),
                    _vm._v(" 台 "),
                  ]),
                ]),
                _c("div", { staticClass: "service-brand-item second" }, [
                  _c("div", { staticClass: "left-info" }, [
                    _c("div", { staticClass: "square" }),
                    _c(
                      "div",
                      {
                        staticClass: "brand-name",
                        attrs: { title: _vm.serviceBrandData[1].name },
                      },
                      [_vm._v(" " + _vm._s(_vm.serviceBrandData[1].name) + " ")]
                    ),
                  ]),
                  _c("div", { staticClass: "number" }, [
                    _c(
                      "span",
                      { staticClass: "data-number" },
                      [
                        !_vm.hideNumber
                          ? [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("toThousands")(
                                      _vm.serviceBrandData[1].carNum,
                                      0
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          : [_vm._v(_vm._s(_vm.hideNumberText))],
                      ],
                      2
                    ),
                    _vm._v(" 台 "),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "service-brand-item third" }, [
                  _c("div", { staticClass: "left-info" }, [
                    _c("div", { staticClass: "square" }),
                    _c(
                      "div",
                      {
                        staticClass: "brand-name",
                        attrs: { title: _vm.serviceBrandData[2].name },
                      },
                      [_vm._v(" " + _vm._s(_vm.serviceBrandData[2].name) + " ")]
                    ),
                  ]),
                  _c("div", { staticClass: "number" }, [
                    _c(
                      "span",
                      { staticClass: "data-number" },
                      [
                        !_vm.hideNumber
                          ? [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("toThousands")(
                                      _vm.serviceBrandData[2].carNum,
                                      0
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          : [_vm._v(_vm._s(_vm.hideNumberText))],
                      ],
                      2
                    ),
                    _vm._v(" 台 "),
                  ]),
                ]),
                _c("div", { staticClass: "service-brand-item fourth" }, [
                  _c("div", { staticClass: "left-info" }, [
                    _c("div", { staticClass: "square" }),
                    _c(
                      "div",
                      {
                        staticClass: "brand-name",
                        attrs: { title: _vm.serviceBrandData[3].name },
                      },
                      [_vm._v(" " + _vm._s(_vm.serviceBrandData[3].name) + " ")]
                    ),
                  ]),
                  _c("div", { staticClass: "number" }, [
                    _c(
                      "span",
                      { staticClass: "data-number" },
                      [
                        !_vm.hideNumber
                          ? [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("toThousands")(
                                      _vm.serviceBrandData[3].carNum,
                                      0
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          : [_vm._v(_vm._s(_vm.hideNumberText))],
                      ],
                      2
                    ),
                    _vm._v(" 台 "),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "center" }, [_c("AMap", { ref: "AMap" })], 1),
      _c("div", { staticClass: "side" }, [
        _c("div", { staticClass: "right-top" }, [
          _c("div", { staticClass: "title" }, [_vm._v("本月经营")]),
          _c("div", { staticClass: "button-list" }, [
            _c(
              "div",
              {
                class: { "button-item": true, active: _vm.showType == 1 },
                on: {
                  click: function ($event) {
                    _vm.showType = 1
                  },
                },
              },
              [_vm._v(" 营业额 ")]
            ),
            _c(
              "div",
              {
                class: { "button-item": true, active: _vm.showType == 2 },
                on: {
                  click: function ($event) {
                    _vm.showType = 2
                  },
                },
              },
              [_vm._v(" 毛利率 ")]
            ),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showType == 1,
                  expression: "showType == 1",
                },
              ],
              staticClass: "current-month-target",
            },
            [
              _c("div", { staticClass: "left" }, [
                _c("div", { attrs: { id: "current-month-target-chart" } }),
                _c("div", { staticClass: "target-text" }, [
                  _vm._v(" 目标额： "),
                  _c(
                    "span",
                    { staticClass: "data-number" },
                    [
                      !_vm.hideNumber
                        ? [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("toThousands")(
                                    _vm.currentMonthTargetData.totalTarget /
                                      1e4,
                                    0
                                  )
                                ) +
                                " "
                            ),
                          ]
                        : [_vm._v(_vm._s(_vm.hideNumberText))],
                    ],
                    2
                  ),
                  _vm._v(" 万 "),
                ]),
              ]),
              _c("div", { staticClass: "right" }, [
                _c("div", { staticClass: "target-list" }, [
                  _c("div", { staticClass: "target-item" }, [
                    _c("div", [_vm._v("自费")]),
                    _c(
                      "div",
                      { staticClass: "money data-number" },
                      [
                        !_vm.hideNumber
                          ? [
                              _vm._v(
                                "￥ " +
                                  _vm._s(
                                    _vm._f("toThousands")(
                                      _vm.currentMonthTargetData
                                        .selfPayTurnover,
                                      0
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          : [_vm._v(_vm._s(_vm.hideNumberText))],
                      ],
                      2
                    ),
                    _c("div", { staticClass: "data-number" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("toThousands")(
                              _vm.currentMonthTargetData
                                .selfPayPercentageComplete,
                              0
                            )
                          ) +
                          "% "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "target-item" }, [
                    _c("div", [_vm._v("包干")]),
                    _c(
                      "div",
                      { staticClass: "money data-number" },
                      [
                        !_vm.hideNumber
                          ? [
                              _vm._v(
                                "￥ " +
                                  _vm._s(
                                    _vm._f("toThousands")(
                                      _vm.currentMonthTargetData
                                        .contractForTurnover,
                                      0
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          : [_vm._v(_vm._s(_vm.hideNumberText))],
                      ],
                      2
                    ),
                    _c("div", { staticClass: "data-number" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("toThousands")(
                              _vm.currentMonthTargetData
                                .contractForPercentageComplete,
                              0
                            )
                          ) +
                          "% "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "target-item" }, [
                    _c("div", [_vm._v("理赔")]),
                    _c(
                      "div",
                      { staticClass: "money data-number" },
                      [
                        !_vm.hideNumber
                          ? [
                              _vm._v(
                                "￥ " +
                                  _vm._s(
                                    _vm._f("toThousands")(
                                      _vm.currentMonthTargetData
                                        .claimSettlementTurnover,
                                      0
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          : [_vm._v(_vm._s(_vm.hideNumberText))],
                      ],
                      2
                    ),
                    _c("div", { staticClass: "data-number" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("toThousands")(
                              _vm.currentMonthTargetData
                                .claimSettlementPercentageComplete,
                              0
                            )
                          ) +
                          "% "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "target-item" }, [
                    _c("div", [_vm._v("索赔")]),
                    _c(
                      "div",
                      { staticClass: "money data-number" },
                      [
                        !_vm.hideNumber
                          ? [
                              _vm._v(
                                "￥ " +
                                  _vm._s(
                                    _vm._f("toThousands")(
                                      _vm.currentMonthTargetData
                                        .claimForCompensationTurnover,
                                      0
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          : [_vm._v(_vm._s(_vm.hideNumberText))],
                      ],
                      2
                    ),
                    _c("div", { staticClass: "data-number" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("toThousands")(
                              _vm.currentMonthTargetData
                                .claimForCompensationPercentageComplete,
                              0
                            )
                          ) +
                          "% "
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showType == 2,
                  expression: "showType == 2",
                },
              ],
              staticClass: "radar-chart-box",
            },
            [_c("div", { attrs: { id: "radar-chart" } })]
          ),
        ]),
        _c("div", { staticClass: "right-bottom" }, [
          _c("div", { staticClass: "title" }, [
            _vm._v(
              " " + _vm._s(_vm.showType === 1 ? "门店排行榜" : "门店明细") + " "
            ),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showType == 1,
                  expression: "showType == 1",
                },
              ],
              staticClass: "company-ranked-box",
            },
            [
              _c(
                "div",
                { staticClass: "types" },
                _vm._l(_vm.companyRankedLegend, function (item, index) {
                  return _c("div", { key: index, staticClass: "type-item" }, [
                    _c("div", {
                      staticClass: "circle",
                      style: { backgroundColor: item.color },
                    }),
                    _c("div", { staticClass: "name" }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                  ])
                }),
                0
              ),
              _c(
                "div",
                { staticClass: "company-ranked-list" },
                _vm._l(_vm.activatedCompanyList, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "company-ranked-item" },
                    [
                      _c("div", { staticClass: "rank data-number" }, [
                        _vm._v(_vm._s(item.index)),
                      ]),
                      _c("div", { staticClass: "part2" }, [
                        _c(
                          "div",
                          {
                            staticClass: "company-name",
                            attrs: { title: item.companyName },
                          },
                          [_vm._v(" " + _vm._s(item.companyName) + " ")]
                        ),
                        _c("div", { staticClass: "waring" }, [
                          _c("div", {
                            staticClass: "circle",
                            style: {
                              backgroundColor: _vm.companyRankedLegend[0].color,
                              opacity: item.selfPayTurnoverWarning ? 1 : 0,
                            },
                          }),
                          _c("div", {
                            staticClass: "circle",
                            style: {
                              backgroundColor: _vm.companyRankedLegend[1].color,
                              opacity: item.contractForTurnoverWarning ? 1 : 0,
                            },
                          }),
                          _c("div", {
                            staticClass: "circle",
                            style: {
                              backgroundColor: _vm.companyRankedLegend[2].color,
                              opacity: item.claimSettlementTurnoverWarning
                                ? 1
                                : 0,
                            },
                          }),
                          _c("div", {
                            staticClass: "circle",
                            style: {
                              backgroundColor: _vm.companyRankedLegend[3].color,
                              opacity: item.claimForCompensationTurnoverWarning
                                ? 1
                                : 0,
                            },
                          }),
                        ]),
                      ]),
                      _c("div", { staticClass: "progress-box" }, [
                        _c(
                          "div",
                          {
                            staticClass: "total-progress",
                            style: {
                              width:
                                item.totalPercentageComplete > 100
                                  ? "100%"
                                  : item.totalPercentageComplete + "%",
                            },
                          },
                          [
                            item.totalPercentageComplete
                              ? _c(
                                  "span",
                                  {
                                    class: {
                                      "number-progress": true,
                                      "data-number": true,
                                      warning: item.totalTurnoverWarning,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          Math.ceil(
                                            item.totalPercentageComplete
                                          )
                                        ) +
                                        "% "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                        _c("div", { staticClass: "progress-types" }, [
                          _c("div", { staticClass: "type1" }, [
                            _c("div", {
                              staticClass: "type1-progress",
                              style: {
                                width:
                                  item.selfPayPercentageComplete > 100
                                    ? "100"
                                    : item.selfPayPercentageComplete + "%",
                              },
                              attrs: {
                                title: item.selfPayPercentageComplete + "%",
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "type2" }, [
                            _c("div", {
                              staticClass: "type2-progress",
                              style: {
                                width:
                                  item.contractForPercentageComplete > 100
                                    ? "100"
                                    : item.contractForPercentageComplete + "%",
                              },
                              attrs: {
                                title: item.contractForPercentageComplete + "%",
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "type3" }, [
                            _c("div", {
                              staticClass: "type3-progress",
                              style: {
                                width:
                                  item.claimSettlementPercentageComplete > 100
                                    ? "100"
                                    : item.claimSettlementPercentageComplete +
                                      "%",
                              },
                              attrs: {
                                title:
                                  item.claimSettlementPercentageComplete + "%",
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "type4" }, [
                            _c("div", {
                              staticClass: "type4-progress",
                              style: {
                                width:
                                  item.claimForCompensationPercentageComplete >
                                  100
                                    ? "100"
                                    : item.claimForCompensationPercentageComplete +
                                      "%",
                              },
                              attrs: {
                                title:
                                  item.claimForCompensationPercentageComplete +
                                  "%",
                              },
                            }),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "company-number" }, [
                        _c(
                          "span",
                          { staticClass: "data-number" },
                          [
                            !_vm.hideNumber
                              ? [
                                  _vm._v(
                                    " " + _vm._s(item.totalTarget_changed) + " "
                                  ),
                                ]
                              : [_vm._v(_vm._s(_vm.hideNumberText))],
                          ],
                          2
                        ),
                        _vm._v(" 万 "),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showType == 2,
                  expression: "showType == 2",
                },
              ],
              staticClass: "company-ranked-box",
            },
            [
              _c(
                "div",
                { staticClass: "types" },
                _vm._l(_vm.companyRankedLegend, function (item, index) {
                  return _c("div", { key: index, staticClass: "type-item" }, [
                    _c("div", {
                      staticClass: "circle",
                      style: { backgroundColor: item.color },
                    }),
                    _c("div", { staticClass: "name" }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                  ])
                }),
                0
              ),
              _c(
                "div",
                { staticClass: "company-ranked-list" },
                _vm._l(
                  _vm.activeGrossMarginCompanyList,
                  function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "company-ranked-item" },
                      [
                        _c("div", { staticClass: "rank data-number" }, [
                          _vm._v(_vm._s(item.index)),
                        ]),
                        _c("div", { staticClass: "part2" }, [
                          _c(
                            "div",
                            {
                              staticClass: "company-name",
                              attrs: { title: item.companyName },
                            },
                            [_vm._v(" " + _vm._s(item.companyName) + " ")]
                          ),
                          _c("div", { staticClass: "waring" }, [
                            _c("div", {
                              staticClass: "circle",
                              style: {
                                backgroundColor:
                                  _vm.companyRankedLegend[0].color,
                                opacity: item.selfPayGrossProfitRateWarning
                                  ? 1
                                  : 0,
                              },
                            }),
                            _c("div", {
                              staticClass: "circle",
                              style: {
                                backgroundColor:
                                  _vm.companyRankedLegend[1].color,
                                opacity: item.contractForGrossProfitRateWarning
                                  ? 1
                                  : 0,
                              },
                            }),
                            _c("div", {
                              staticClass: "circle",
                              style: {
                                backgroundColor:
                                  _vm.companyRankedLegend[2].color,
                                opacity:
                                  item.claimSettlementGrossProfitRateWarning
                                    ? 1
                                    : 0,
                              },
                            }),
                            _c("div", {
                              staticClass: "circle",
                              style: {
                                backgroundColor:
                                  _vm.companyRankedLegend[3].color,
                                opacity:
                                  item.claimForCompensationGrossProfitRateWarning
                                    ? 1
                                    : 0,
                              },
                            }),
                          ]),
                        ]),
                        _c("div", { staticClass: "progress-box" }, [
                          _c("div", {
                            staticClass: "total-progress",
                            style: {
                              width:
                                item.totalGrossProfitRate > 100
                                  ? "100%"
                                  : item.totalGrossProfitRate + "%",
                            },
                          }),
                          _c("div", { staticClass: "progress-types" }, [
                            _c("div", { staticClass: "type1" }, [
                              _c("div", {
                                staticClass: "type1-progress",
                                style: {
                                  width:
                                    item.selfPayGrossProfitRate > 100
                                      ? "100"
                                      : item.selfPayGrossProfitRate + "%",
                                },
                                attrs: {
                                  title: item.selfPayGrossProfitRate + "%",
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "type2" }, [
                              _c("div", {
                                staticClass: "type2-progress",
                                style: {
                                  width:
                                    item.contractForGrossProfitRate > 100
                                      ? "100"
                                      : item.contractForGrossProfitRate + "%",
                                },
                                attrs: {
                                  title: item.contractForGrossProfitRate + "%",
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "type3" }, [
                              _c("div", {
                                staticClass: "type3-progress",
                                style: {
                                  width:
                                    item.claimSettlementGrossProfitRate > 100
                                      ? "100"
                                      : item.claimSettlementGrossProfitRate +
                                        "%",
                                },
                                attrs: {
                                  title:
                                    item.claimSettlementGrossProfitRate + "%",
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "type4" }, [
                              _c("div", {
                                staticClass: "type4-progress",
                                style: {
                                  width:
                                    item.claimForCompensationGrossProfitRate >
                                    100
                                      ? "100"
                                      : item.claimForCompensationGrossProfitRate +
                                        "%",
                                },
                                attrs: {
                                  title:
                                    item.claimForCompensationGrossProfitRate +
                                    "%",
                                },
                              }),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "company-number" }, [
                          _c(
                            "span",
                            {
                              class: {
                                "data-number": true,
                                warning: item.totalGrossProfitRateWarning,
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(item.totalGrossProfitRate) + "% "
                              ),
                            ]
                          ),
                        ]),
                      ]
                    )
                  }
                ),
                0
              ),
            ]
          ),
        ]),
      ]),
    ]),
    _vm._m(0),
    _c("div", {
      staticClass: "full-screen",
      on: { click: _vm.handleFullScreen },
    }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "footer" }, [
      _c("div", { staticClass: "footer-content" }, [
        _c("div", { staticClass: "title" }, [_vm._v(" 事故进场 ")]),
        _c("div", { attrs: { id: "accident-repair-statistic" } }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }