import store from '@/store';
import authTree from './auth';
import { MessageBox } from 'element-ui';

export function hasAuth(code) {
  const roles = store?.state?.user?.roles || [];
  return roles?.includes('' + code);
}

let map = new Map();

function setUrl(arr = [], nodes = []) {
  arr.forEach((node) => {
    node.parentNode = nodes.concat(node.name);
    map.set(node.code, node.parentNode);
    if (node.children?.length) {
      setUrl(node.children, node.parentNode);
    }
  });
}
setUrl(authTree);

/**
 * 根据权限码返回对应该限权对应路径
 * @param {number|string} code 权限编码
 * @returns {Array<string>} 路径
 */
export function getAuthUrl(code) {
  return map.get('' + code);
}

/**
 * 判断是否有权限 如果没有权限弹窗提示
 * @param {number|string} code 权限编码
 * @param {boolean} showMessage 是否提示无权限
 * @returns {Promise<boolean>} 是否有权限
 */
export function verifyAuth(code, showMessage = true) {
  return new Promise((resolve, reject) => {
    if (!hasAuth(code)) {
      if (showMessage) {
        let url = getAuthUrl(code);
        let urlString = url.reduce((prev, item, index) => {
          if (index === url.length - 1) {
            item = `【${item}】`;
          }
          if (index > 0) {
            item = ` > ${item}`;
          }
          return prev + item;
        }, '');
        let msg = `无“${urlString}”的权限，请联系超级用户设置角色权限`;
        MessageBox.alert(msg, '温馨提示');
      }
      reject(false);
    } else {
      resolve(true);
    }
  });
}
