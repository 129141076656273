var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { ref: "baseContainer", attrs: { paddingTop: "" } },
    [
      [
        _c(
          "el-form",
          {
            staticClass: "filter-form",
            attrs: { model: _vm.form, "label-width": "100px", size: "small" },
          },
          [
            _c("div", { staticClass: "flex-x-between" }, [
              _c(
                "div",
                { staticClass: "flex-shrink0 flex" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "协议客户" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入客户名称", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.doSearch.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.form.phoneOrClientName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "phoneOrClientName", $$v)
                          },
                          expression: "form.phoneOrClientName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "协议编号" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.doSearch.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.form.agreeNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "agreeNum", $$v)
                          },
                          expression: "form.agreeNum",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "包干协议" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择", clearable: "" },
                          model: {
                            value: _vm.form.beContract,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "beContract", $$v)
                            },
                            expression: "form.beContract",
                          },
                        },
                        [
                          _c("el-option", { attrs: { label: "是", value: 1 } }),
                          _c("el-option", { attrs: { label: "否", value: 0 } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "el-form-item--small" }, [
                _c(
                  "div",
                  {
                    staticClass: "el-form-item__label pointer",
                    on: {
                      click: function ($event) {
                        _vm.showMore = !_vm.showMore
                      },
                    },
                  },
                  [
                    _vm._v(" 更多筛选 "),
                    _c("i", {
                      class: {
                        primary: _vm.showMore,
                        "el-icon-arrow-right": !_vm.showMore,
                        "el-icon-arrow-down": _vm.showMore,
                      },
                    }),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "btn_search",
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.doSearch },
                      },
                      [_vm._v("查询")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn_search",
                        attrs: { size: "small" },
                        on: { click: _vm.empty },
                      },
                      [_vm._v("置空")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _vm.showMore
              ? [
                  _c(
                    "div",
                    { staticClass: "flex-warp" },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c("template", { slot: "label" }, [
                            _c(
                              "div",
                              [
                                _vm._v(" 适用门店 "),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "light",
                                      content:
                                        "客户协议的适用门店,可查当前登录门店和登录用户所属管辖组内其他门店",
                                      placement: "bottom-start",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "iconfont grow-icon_doubt",
                                      staticStyle: { color: "#9a9a9a" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                clearable: "",
                                remote: "",
                                placeholder: "搜索车店名称、编号",
                                "remote-method": _vm.searchStore,
                                loading: _vm.searchStoreLoading,
                              },
                              model: {
                                value: _vm.form.enableStoreNameOrNum,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "enableStoreNameOrNum",
                                    $$v
                                  )
                                },
                                expression: "form.enableStoreNameOrNum",
                              },
                            },
                            _vm._l(_vm.storeList, function (item) {
                              return _c(
                                "el-option",
                                {
                                  key: item.companyId,
                                  attrs: {
                                    label: item.companyName,
                                    value: item.companyId,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { float: "left" } },
                                    [_vm._v(_vm._s(item.companyId))]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        float: "right",
                                        color: "#8492a6",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.companyName))]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        2
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c("template", { slot: "label" }, [
                            _c(
                              "div",
                              [
                                _vm._v(" 签约门店 "),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "light",
                                      content:
                                        "客户协议的签约门店,可查当前登录门店和登录用户所属管辖组内其他门店",
                                      placement: "bottom-start",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "iconfont grow-icon_doubt",
                                      staticStyle: { color: "#9a9a9a" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                clearable: "",
                                remote: "",
                                placeholder: "搜索车店名称、编号",
                                "remote-method": _vm.searchStore,
                                loading: _vm.searchStoreLoading,
                              },
                              model: {
                                value: _vm.form.agreedStoreNameOrNum,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "agreedStoreNameOrNum",
                                    $$v
                                  )
                                },
                                expression: "form.agreedStoreNameOrNum",
                              },
                            },
                            _vm._l(_vm.storeList, function (item) {
                              return _c(
                                "el-option",
                                {
                                  key: item.companyId,
                                  attrs: {
                                    label: item.companyName,
                                    value: item.companyId,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { float: "left" } },
                                    [_vm._v(_vm._s(item.companyId))]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        float: "right",
                                        color: "#8492a6",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.companyName))]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        2
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c("template", { slot: "label" }, [
                            _c(
                              "div",
                              [
                                _vm._v(" 开票主体 "),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "light",
                                      content:
                                        "开票主体。即为协议客户、保险公司和三包厂家的应收账款的回款管理门店(保险公司的开票主体也是定损主体),客户协议的开票主体,可查已成为客户协议开票主体的全部门店",
                                      placement: "bottom-start",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "iconfont grow-icon_doubt",
                                      staticStyle: { color: "#9a9a9a" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                clearable: "",
                                remote: "",
                                placeholder: "搜索开票主体名称",
                                "remote-method": _vm.searchInvoice,
                                loading: _vm.searchInvoiceLoading,
                              },
                              model: {
                                value: _vm.form.invoiceStoreNameOrNum,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "invoiceStoreNameOrNum",
                                    $$v
                                  )
                                },
                                expression: "form.invoiceStoreNameOrNum",
                              },
                            },
                            _vm._l(_vm.invoiceList, function (item) {
                              return _c("el-option", {
                                key: item.companyId,
                                attrs: {
                                  label: item.invoicingEntity,
                                  value: item.companyId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        2
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "生效日期" } },
                        [
                          _c("el-date-picker", {
                            staticClass: "w100",
                            attrs: {
                              type: "daterange",
                              "unlink-panels": "",
                              "range-separator": "至",
                              "start-placeholder": "起始时间",
                              "end-placeholder": "截止时间",
                              "value-format": "yyyy-MM-dd",
                            },
                            model: {
                              value: _vm.form.start,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "start", $$v)
                              },
                              expression: "form.start",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "到期日期" } },
                        [
                          _c("el-date-picker", {
                            staticClass: "w100",
                            attrs: {
                              type: "daterange",
                              "unlink-panels": "",
                              "range-separator": "至",
                              "start-placeholder": "起始时间",
                              "end-placeholder": "截止时间",
                              "value-format": "yyyy-MM-dd",
                            },
                            model: {
                              value: _vm.form.end,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "end", $$v)
                              },
                              expression: "form.end",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              : _vm._e(),
          ],
          2
        ),
      ],
      [
        _c(
          "div",
          {
            staticClass: "btns flex-x-between",
            staticStyle: { "margin-bottom": "14px" },
          },
          [
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 475,
                    expression: "475",
                  },
                ],
                staticClass: "btn_insert",
                attrs: { size: "small" },
                on: { click: _vm.add },
              },
              [_vm._v("新增协议")]
            ),
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 478,
                    expression: "478",
                  },
                ],
                staticClass: "blue",
                attrs: { size: "small", type: "text" },
                on: {
                  click: function ($event) {
                    _vm.showExportDialog = true
                  },
                },
              },
              [
                _vm._v("导出列表"),
                _c("i", {
                  staticClass: "iconfont grow-icon_daochu el-icon--right",
                }),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "flex",
            staticStyle: { height: "100%", overflow: "auto" },
          },
          [
            _c(
              "div",
              { staticStyle: { flex: "1", overflow: "auto" } },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "fixedScrollBar",
                        rawName: "v-fixedScrollBar",
                        value: _vm.$refs.baseContainer,
                        expression: "$refs.baseContainer",
                      },
                    ],
                    staticClass: "custom-table",
                    attrs: { data: _vm.listData, border: "" },
                    on: { "row-dblclick": _vm.goDetail },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { type: "index", label: "序号", width: "60" },
                    }),
                    _c("el-table-column", {
                      attrs: { label: "操作", width: "80" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              ["正常有效", "待生效"].includes(scope.row.status)
                                ? _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "auth",
                                          rawName: "v-auth",
                                          value: 476,
                                          expression: "476",
                                        },
                                      ],
                                      staticClass: "text_operation blue",
                                      on: {
                                        click: function ($event) {
                                          return _vm.edit(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("编辑")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { width: "180", label: "协议编号" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "text_operation blue",
                                  on: {
                                    click: function ($event) {
                                      return _vm.goDetail(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(scope.row.contractNumber))]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { width: "100", label: "包干协议" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _vm._v(
                                " " + _vm._s(row.beContract ? "是" : "否") + " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { width: "180", label: "协议状态" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c("p", [_vm._v(_vm._s(row.status))]),
                              (Date.parse(row.endDate) - Date.now()) /
                                (1 * 24 * 60 * 60 * 1000) <=
                                30 &&
                              (row.status == "正常有效" ||
                                row.status == "待生效")
                                ? _c("p", { staticClass: "orange" }, [
                                    _vm._v(" 即将过期 "),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { width: "180", label: "协议客户" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "text_operation blue",
                                  on: {
                                    click: function ($event) {
                                      _vm.goClientDetail(
                                        _vm.$lodash.get(
                                          scope.row,
                                          "agreeClient.id",
                                          ""
                                        )
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$lodash.get(
                                        scope.row,
                                        "agreeClient.name",
                                        ""
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { width: "180", label: "联系人/联系电话" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [_vm._v(_vm._s(scope.row.contact))]),
                              _c("div", [_vm._v(_vm._s(scope.row.phone))]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { width: "200", label: "有效期限" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("delhms")(scope.row.startDate)
                                  ) +
                                    " 至 " +
                                    _vm._s(_vm._f("delhms")(scope.row.endDate))
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { width: "180", label: "账期" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _vm._v(_vm._s(row.paymentPeriod) + "个自然月"),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c(
                      "el-table-column",
                      { attrs: { width: "180", prop: "invoiceCompanyName" } },
                      [
                        _c("template", { slot: "header" }, [
                          _c(
                            "div",
                            [
                              _vm._v(" 开票主体 "),
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    effect: "light",
                                    content:
                                      "开票主体，即为协议客户、保险公司和三包厂家的应收账款的回款管理门店(保险公司的开票主体也是定损主体)",
                                    placement: "top",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "iconfont grow-icon_doubt",
                                    staticStyle: { color: "#9a9a9a" },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      2
                    ),
                    _c("el-table-column", {
                      attrs: { width: "180", label: "自费业务适用门店" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.hasSelfPaying
                                ? _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        placement: "bottom",
                                        width: "450",
                                        trigger: "click",
                                      },
                                      on: {
                                        show: function ($event) {
                                          return _vm.getStoreList(scope.row.id)
                                        },
                                      },
                                    },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          " 部分门店（" +
                                            _vm._s(scope.row.selfPayingCount) +
                                            "）, "
                                        ),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              scope.row
                                                .selfPayingSelfContained == 0
                                                ? "不包含本店"
                                                : "包含本店"
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _c(
                                        "el-table",
                                        {
                                          attrs: {
                                            "max-height": "400px",
                                            data: _vm.applyStores.selfPaying,
                                          },
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              type: "index",
                                              label: "序号",
                                              width: "60",
                                            },
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              width: "120",
                                              prop: "companyId",
                                              label: "车店编号",
                                            },
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              width: "150",
                                              prop: "companyName",
                                              label: "车店名称",
                                            },
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              width: "100",
                                              prop: "type",
                                              label: "连锁类型",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("dict")(
                                                            scope.row
                                                              .chainCompanyType,
                                                            "chainCompanyType"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticClass: "pointer blue",
                                          attrs: { slot: "reference" },
                                          slot: "reference",
                                        },
                                        [
                                          _vm._v(
                                            " 部分门店（" +
                                              _vm._s(
                                                scope.row.selfPayingCount
                                              ) +
                                              "）, "
                                          ),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                scope.row
                                                  .selfPayingSelfContained == 0
                                                  ? "不包含本店"
                                                  : "包含本店"
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _c("p", [_vm._v("全部门店")]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { width: "180", label: "包干业务适用门店" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.hasContractFor
                                ? _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        placement: "bottom",
                                        width: "450",
                                        trigger: "click",
                                      },
                                      on: {
                                        show: function ($event) {
                                          return _vm.getStoreList(scope.row.id)
                                        },
                                      },
                                    },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          " 部分门店（" +
                                            _vm._s(scope.row.contractForCount) +
                                            "）, "
                                        ),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              scope.row
                                                .contractForSelfContained == 0
                                                ? "不包含本店"
                                                : "包含本店"
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _c(
                                        "el-table",
                                        {
                                          attrs: {
                                            "max-height": "400px",
                                            data: _vm.applyStores.contractFor,
                                          },
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              type: "index",
                                              label: "序号",
                                              width: "60",
                                            },
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              width: "120",
                                              prop: "companyId",
                                              label: "车店编号",
                                            },
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              width: "150",
                                              prop: "companyName",
                                              label: "车店名称",
                                            },
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              width: "100",
                                              prop: "type",
                                              label: "连锁类型",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("dict")(
                                                            scope.row
                                                              .chainCompanyType,
                                                            "chainCompanyType"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticClass: "pointer blue",
                                          attrs: { slot: "reference" },
                                          slot: "reference",
                                        },
                                        [
                                          _vm._v(
                                            " 部分门店（" +
                                              _vm._s(
                                                scope.row.contractForCount
                                              ) +
                                              "）, "
                                          ),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                scope.row
                                                  .contractForSelfContained == 0
                                                  ? "不包含本店"
                                                  : "包含本店"
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _c("p", [_vm._v("全部门店")]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { width: "180", label: "理赔业务适用门店" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.hasClaimSettlement
                                ? _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        placement: "bottom",
                                        width: "450",
                                        trigger: "click",
                                      },
                                      on: {
                                        show: function ($event) {
                                          return _vm.getStoreList(scope.row.id)
                                        },
                                      },
                                    },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          " 部分门店（" +
                                            _vm._s(
                                              scope.row.claimSettlementCount
                                            ) +
                                            "）, "
                                        ),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              scope.row
                                                .claimSettlementSelfContained ==
                                                0
                                                ? "不包含本店"
                                                : "包含本店"
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _c(
                                        "el-table",
                                        {
                                          attrs: {
                                            "max-height": "400px",
                                            data: _vm.applyStores
                                              .claimSettlement,
                                          },
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              type: "index",
                                              label: "序号",
                                              width: "60",
                                            },
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              width: "120",
                                              prop: "companyId",
                                              label: "车店编号",
                                            },
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              width: "150",
                                              prop: "companyName",
                                              label: "车店名称",
                                            },
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              width: "100",
                                              prop: "type",
                                              label: "连锁类型",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("dict")(
                                                            scope.row
                                                              .chainCompanyType,
                                                            "chainCompanyType"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticClass: "pointer blue",
                                          attrs: { slot: "reference" },
                                          slot: "reference",
                                        },
                                        [
                                          _vm._v(
                                            " 部分门店（" +
                                              _vm._s(
                                                scope.row.claimSettlementCount
                                              ) +
                                              "）, "
                                          ),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                scope.row
                                                  .claimSettlementSelfContained ==
                                                  0
                                                  ? "不包含本店"
                                                  : "包含本店"
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _c("p", [_vm._v("全部门店")]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { width: "180", label: "索赔业务适用门店" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.hasClaimForCompensation
                                ? _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        placement: "bottom",
                                        width: "450",
                                        trigger: "click",
                                      },
                                      on: {
                                        show: function ($event) {
                                          return _vm.getStoreList(scope.row.id)
                                        },
                                      },
                                    },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          " 部分门店（" +
                                            _vm._s(
                                              scope.row
                                                .claimForCompensationCount
                                            ) +
                                            "）, "
                                        ),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              scope.row
                                                .claimForCompensationSelfContained ==
                                                0
                                                ? "不包含本店"
                                                : "包含本店"
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _c(
                                        "el-table",
                                        {
                                          attrs: {
                                            "max-height": "400px",
                                            data: _vm.applyStores
                                              .claimForCompensation,
                                          },
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              type: "index",
                                              label: "序号",
                                              width: "60",
                                            },
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              width: "120",
                                              prop: "companyId",
                                              label: "车店编号",
                                            },
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              width: "150",
                                              prop: "companyName",
                                              label: "车店名称",
                                            },
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              width: "100",
                                              prop: "type",
                                              label: "连锁类型",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("dict")(
                                                            scope.row
                                                              .chainCompanyType,
                                                            "chainCompanyType"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticClass: "pointer blue",
                                          attrs: { slot: "reference" },
                                          slot: "reference",
                                        },
                                        [
                                          _vm._v(
                                            " 部分门店（" +
                                              _vm._s(
                                                scope.row
                                                  .claimForCompensationCount
                                              ) +
                                              "）, "
                                          ),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                scope.row
                                                  .claimForCompensationSelfContained ==
                                                  0
                                                  ? "不包含本店"
                                                  : "包含本店"
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _c("p", [_vm._v("全部门店")]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "180",
                        label: "备注",
                        prop: "remark",
                        "show-overflow-tooltip": true,
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "180",
                        label: "签约门店",
                        prop: "signCompanyName",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { width: "100", label: "建档日期" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(row.createTime.substring(0, 10)) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { width: "100", label: "建档人", prop: "creator" },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "180",
                        label: "建档门店",
                        prop: "createCompanyName",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "class-box" },
              [
                _c("p", { staticClass: "title" }, [
                  _vm._v("状态与统计（数量）"),
                ]),
                _c("el-tree", {
                  ref: "my-tree",
                  staticClass: "custom-tree",
                  attrs: {
                    data: _vm.treeData,
                    "default-expand-all": "",
                    props: _vm.treeProp,
                    "highlight-current": "",
                    "node-key": "id",
                    "current-node-key": _vm.nodeKey,
                  },
                  on: { "node-click": _vm.onNodeClick },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ data }) {
                        return [
                          _c("div", { staticClass: "tree-node" }, [
                            _c("span", [_vm._v(_vm._s(data.name))]),
                            _c("span", [
                              _vm._v("(" + _vm._s(data.count) + ")"),
                            ]),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]
        ),
        _c("export-dialog", {
          attrs: {
            visibility: _vm.showExportDialog,
            total: _vm.total,
            pageOption: _vm.pageOption,
            filterForm: _vm.lastParams,
            url: _vm.exportUrl,
          },
          on: {
            "update:visibility": function ($event) {
              _vm.showExportDialog = $event
            },
          },
        }),
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c("pagination", {
            attrs: { total: _vm.total },
            on: { pageChange: _vm.changePage },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }