<template>
  <div>
    <el-dialog
      title="施工领工"
      width="50%"
      :visible="visibility"
      :before-close="handleClose"
      :close-on-click-modal="false"
      append-to-body
    >
      <div class="update-fix-items_box">
        <div class="bill-info">
          <div class="row flex-x-between">
            <div class="col flex">
              <div class="label">接车单：</div>
              <div class="value">
                <span
                  class="blue pointer"
                  @click="goCarBillDetail(detailData.billId)"
                  >{{ detailData.billCode }}</span
                >
                <span v-if="detailData.isOthersSelfPayState"> (他人自费) </span>
              </div>
            </div>
            <div class="col flex">
              <div class="label">预交车时间：</div>
              <div class="value">
                <span v-if="detailData.planFinishTime">{{
                  detailData.planFinishTime
                }}</span>
                <span v-else-if="detailData.waitInStore"> 在店等 </span>
              </div>
            </div>
          </div>
          <div style="margin-top: 20px" class="row flex-x-between">
            <div class="col flex">
              <div class="label">车牌号：</div>
              <div class="value">
                <span>{{ detailData.carNumber }}</span>
                <span v-if="detailData.carType !== undefined">
                  ({{ detailData.vehicleType }})
                </span>
              </div>
            </div>
            <div class="col flex">
              <div class="label">车型/车架号：</div>
              <div class="value">
                <span>{{ detailData.carModel }}</span>
                <span> VIN: {{ detailData.uniqueId }} </span>
              </div>
            </div>
          </div>
        </div>
        <div>
          您当前归属【一级班组名称XXX 或 一级班组名称 /
          二级班组名称XXX】部门班组，请选择要领取施工的待派工项目，并“确认”完成领工。
        </div>
        <el-table
          style="width: 100%; margin: 10px 0"
          border
          :data="taskList"
          @selection-change="selectionChange"
        >
          <el-table-column type="selection"> </el-table-column>
          <el-table-column type="index" label="序号" width="60">
          </el-table-column>
          <el-table-column label="项目名称" width="200" prop="serviceItemName">
          </el-table-column>
          <el-table-column label="项目编码" width="120" prop="serviceItemCode">
          </el-table-column>
          <el-table-column label="内部标签" width="140" prop="tags">
          </el-table-column>
          <el-table-column label="施工技师">
            <template #default="{ row }">
              {{ row.technicianNames }}
            </template>
          </el-table-column>
          <template slot="append">
            <div style="margin: 10px" class="blue">
              已选择项目<span class="el-icon--right">{{
                selectedList.length
              }}</span>
            </div>
          </template>
        </el-table>
      </div>
      <div slot="footer">
        <el-button size="small" @click="handleClose">取 消</el-button>
        <el-button size="small" type="primary" @click="handleConfirm"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { getForDispatchDetailsById, submitForDispatch } from '@/api/carBill';
export default {
  name: 'getTaskDialog',
  components: {},
  props: {
    visibility: { type: Boolean, default: false },
    id: {
      type: [Number, String],
    },
    dispatchType: {
      type: [Number, String],
    },
  },
  data() {
    return {
      detailData: {},

      // 列表
      taskList: [],

      // 选中列表
      selectedList: [],
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.companyId,
      userId: (state) => state.user.userInfo?.userId,
    }),
  },
  created() {
    this.getData();
  },
  methods: {
    /**
     * 获取数据
     */
    async getData() {
      let res = await getForDispatchDetailsById({
        id: this.id,
        dispatchType: this.dispatchType,
      });
      let { taskBillServiceItems = [], ...data } = res;
      this.taskList = taskBillServiceItems;
      this.detailData = data;
    },
    /**
     * 选择事件
     */
    selectionChange(list) {
      this.selectedList = list;
    },
    /**
     * 确认派工
     */
    handleConfirm() {
      if (this.selectedList.length == 0) {
        return this.$message.warning('请先选择项目');
      }
      let forDispatchSubmitServices = this.selectedList.map((item) => ({
        isConstructionFree: item.isConstructionFree,
        billServiceId: item.billServiceId,
        taskBillId: item.taskBillId,
        currentUserId: this.userId,
      }));
      let body = {
        companyId: this.companyId,
        operateType: 1, //操作类型(0,1 >>> 派工,领工)
        dispatchBillId: this.id,
        forDispatchSubmitServices,
      };
      submitForDispatch(body).then(() => {
        this.$message.success('领工成功！');
        this.$emit('finish');
        this.handleClose();
      });
    },
    /**
     * 关闭弹窗
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
    /**
     * 跳转详情页
     */
    goCarBillDetail(id) {
      this.handleClose();
      this.$router.push({
        name: 'carBillDetail',
        query: {
          id,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.update-fix-items_box {
  .bill-info {
    margin: 30px 30px 60px;
    .row {
      color: #333;
      .col {
        width: 50%;
        .label {
          flex-shrink: 0;
        }
      }
    }
  }
}
</style>
