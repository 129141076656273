import * as dicts from './dictData';

export function dict(value, key, returnProp = 'label') {
  if (['', undefined, null].includes(value)) {
    // console.warn(`该字典值不合法 ${value}`);
    return '';
  }
  const data = dicts[key];
  if (!data) {
    // console.warn(`未找到该字典选项 ${key}`);
    return '';
  }
  const obj = data.find((item) => item.value == value);
  return obj?.[returnProp] ?? '';
}
