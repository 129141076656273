var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "search-wrapper" }, [
    _c(
      "div",
      { staticClass: "header" },
      [
        _c(
          "el-form",
          {
            ref: "formRef",
            staticClass: "demo-form-inline",
            attrs: { inline: true, model: _vm.searchForm },
          },
          [
            _c(
              "div",
              { staticClass: "header-left" },
              [
                _vm.type == "ClaimsFollowUp" || _vm.type == "SettlementFollowUp"
                  ? [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "操作门店",
                            "label-width": "70px",
                            prop: "operateCompanyId",
                          },
                        },
                        [
                          _c("operateCompanyCascader", {
                            model: {
                              value: _vm.searchForm.operateCompanyId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.searchForm,
                                  "operateCompanyId",
                                  $$v
                                )
                              },
                              expression: "searchForm.operateCompanyId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "车牌号",
                            "label-width": "70px",
                            prop: "plateNumber",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "请输入车牌号",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.onSubmit.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.searchForm.plateNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "plateNumber", $$v)
                              },
                              expression: "searchForm.plateNumber",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "接车单号",
                            "label-width": "70px",
                            prop: "billCode",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "请输入接车单号",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.onSubmit.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.searchForm.billCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "billCode", $$v)
                              },
                              expression: "searchForm.billCode",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "业务来源",
                            "label-width": "70px",
                            prop: "sourceName",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "请输入业务来源",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.onSubmit.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.searchForm.sourceName,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "sourceName", $$v)
                              },
                              expression: "searchForm.sourceName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.toggleSearchStatus
                        ? [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "保险公司",
                                  "label-width": "70px",
                                  prop: "insuranceCompanyId",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    directives: [
                                      {
                                        name: "loadMore",
                                        rawName: "v-loadMore",
                                        value: _vm.selectLoadMore,
                                        expression: "selectLoadMore",
                                      },
                                    ],
                                    attrs: {
                                      loading: _vm.loadMoreLoading,
                                      "remote-method": _vm.getInsuranceCompany,
                                      clearable: "",
                                      filterable: "",
                                      placeholder: "搜索公司名称、编号",
                                      remote: "",
                                    },
                                    on: { "visible-change": _vm.selectVisible },
                                    model: {
                                      value: _vm.searchForm.insuranceCompanyId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "insuranceCompanyId",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "searchForm.insuranceCompanyId",
                                    },
                                  },
                                  _vm._l(
                                    _vm.insuranceCompanyList,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.insuranceName,
                                          value: item.id,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "事故责任",
                                  "label-width": "70px",
                                  prop: "dutyType",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请选择事故责任",
                                    },
                                    model: {
                                      value: _vm.searchForm.dutyType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "dutyType",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.dutyType",
                                    },
                                  },
                                  _vm._l(
                                    _vm.dutyTypeList,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "客户名称",
                                  "label-width": "70px",
                                  prop: "clientName",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请输入客户名称",
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.onSubmit.apply(null, arguments)
                                    },
                                  },
                                  model: {
                                    value: _vm.searchForm.clientName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "clientName",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.clientName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm.type == "ClaimsFollowUp"
                              ? [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "解决方案",
                                        "label-width": "70px",
                                        prop: "solutionType",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            clearable: "",
                                            placeholder: "请选择案件分类",
                                          },
                                          on: {
                                            "visible-change": function (
                                              $event
                                            ) {
                                              return _vm.getClaimsSelect(
                                                _vm.solutionDataList
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.searchForm.solutionType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.searchForm,
                                                "solutionType",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "searchForm.solutionType",
                                          },
                                        },
                                        _vm._l(
                                          _vm.solutionDataList,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "案件分类",
                                        "label-width": "70px",
                                        prop: "categoryType",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            clearable: "",
                                            placeholder: "请选择案件分类",
                                          },
                                          on: {
                                            "visible-change": function (
                                              $event
                                            ) {
                                              return _vm.getClaimsSelect(
                                                _vm.caseCategoryDataList
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.searchForm.categoryType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.searchForm,
                                                "categoryType",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "searchForm.categoryType",
                                          },
                                        },
                                        _vm._l(
                                          _vm.caseCategoryDataList,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "分类",
                                  "label-width": "70px",
                                  prop: "categoryType",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请选择案件分类",
                                    },
                                    on: {
                                      "visible-change": function ($event) {
                                        return _vm.getCategorySelect(
                                          _vm.categoryData
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.searchForm.categoryType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "categoryType",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.categoryType",
                                    },
                                  },
                                  _vm._l(
                                    _vm.categoryData,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "填写人",
                                  "label-width": "70px",
                                  prop: "fillUserName",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请输入填写人",
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.onSubmit.apply(null, arguments)
                                    },
                                  },
                                  model: {
                                    value: _vm.searchForm.fillUserName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "fillUserName",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.fillUserName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "进店时间",
                                  "label-width": "70px",
                                  prop: "intoStoreTime",
                                },
                              },
                              [
                                _c("el-date-picker", {
                                  attrs: {
                                    clearable: "",
                                    "end-placeholder": "结束日期",
                                    "range-separator": "至",
                                    size: "small",
                                    "start-placeholder": "开始日期",
                                    type: "daterange",
                                    "value-format": "yyyy-MM-dd",
                                  },
                                  model: {
                                    value: _vm.searchForm.intoStoreTime,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "intoStoreTime",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.intoStoreTime",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "结算时间",
                                  "label-width": "70px",
                                  prop: "checkTime",
                                },
                              },
                              [
                                _c("el-date-picker", {
                                  attrs: {
                                    clearable: "",
                                    "end-placeholder": "结束日期",
                                    "range-separator": "至",
                                    size: "small",
                                    "start-placeholder": "开始日期",
                                    type: "daterange",
                                    "value-format": "yyyy-MM-dd",
                                  },
                                  model: {
                                    value: _vm.searchForm.checkTime,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "checkTime", $$v)
                                    },
                                    expression: "searchForm.checkTime",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _vm.type == "AccountingFollowUp"
                  ? [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "操作门店",
                            "label-width": "70px",
                            prop: "operateCompanyId",
                          },
                        },
                        [
                          _c("operateCompanyCascader", {
                            model: {
                              value: _vm.searchForm.operateCompanyId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.searchForm,
                                  "operateCompanyId",
                                  $$v
                                )
                              },
                              expression: "searchForm.operateCompanyId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "车牌号",
                            "label-width": "70px",
                            prop: "plateNumber",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "请输入车牌号",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.onSubmit.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.searchForm.plateNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "plateNumber", $$v)
                              },
                              expression: "searchForm.plateNumber",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "接车单号",
                            "label-width": "70px",
                            prop: "billCode",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "请输入接车单号",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.onSubmit.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.searchForm.billCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "billCode", $$v)
                              },
                              expression: "searchForm.billCode",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "业务来源",
                            "label-width": "70px",
                            prop: "sourceName",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "请输入业务来源",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.onSubmit.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.searchForm.sourceName,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "sourceName", $$v)
                              },
                              expression: "searchForm.sourceName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.toggleSearchStatus
                        ? [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "保险公司",
                                  "label-width": "70px",
                                  prop: "insuranceCompanyId",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    directives: [
                                      {
                                        name: "loadMore",
                                        rawName: "v-loadMore",
                                        value: _vm.selectLoadMore,
                                        expression: "selectLoadMore",
                                      },
                                    ],
                                    attrs: {
                                      loading: _vm.loadMoreLoading,
                                      "remote-method": _vm.getInsuranceCompany,
                                      clearable: "",
                                      filterable: "",
                                      placeholder: "搜索公司名称、编号",
                                      remote: "",
                                    },
                                    on: { "visible-change": _vm.selectVisible },
                                    model: {
                                      value: _vm.searchForm.insuranceCompanyId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "insuranceCompanyId",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "searchForm.insuranceCompanyId",
                                    },
                                  },
                                  _vm._l(
                                    _vm.insuranceCompanyList,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.insuranceName,
                                          value: item.id,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "事故责任",
                                  "label-width": "70px",
                                  prop: "dutyType",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请选择事故责任",
                                    },
                                    model: {
                                      value: _vm.searchForm.dutyType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "dutyType",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.dutyType",
                                    },
                                  },
                                  _vm._l(
                                    _vm.dutyTypeList,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "客户名称",
                                  "label-width": "70px",
                                  prop: "clientName",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请输入客户名称",
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.onSubmit.apply(null, arguments)
                                    },
                                  },
                                  model: {
                                    value: _vm.searchForm.clientName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "clientName",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.clientName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "挂帐车队",
                                  "label-width": "70px",
                                  prop: "pendingFleetName",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请输入挂帐车队",
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.onSubmit.apply(null, arguments)
                                    },
                                  },
                                  model: {
                                    value: _vm.searchForm.pendingFleetName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "pendingFleetName",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.pendingFleetName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "账单月份",
                                  "label-width": "70px",
                                  prop: "billingMonth",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请输入账单月份",
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.onSubmit.apply(null, arguments)
                                    },
                                  },
                                  model: {
                                    value: _vm.searchForm.billingMonth,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "billingMonth",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.billingMonth",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "填写人",
                                  "label-width": "70px",
                                  prop: "fillUserName",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请输入填写人",
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.onSubmit.apply(null, arguments)
                                    },
                                  },
                                  model: {
                                    value: _vm.searchForm.fillUserName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "fillUserName",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.fillUserName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "对账人",
                                  "label-width": "70px",
                                  prop: "reconciliationUserName",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请输入对账人",
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.onSubmit.apply(null, arguments)
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.searchForm.reconciliationUserName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "reconciliationUserName",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "searchForm.reconciliationUserName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "当前状态",
                                  "label-width": "70px",
                                  prop: "currentState",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请选择当前状态",
                                    },
                                    on: {
                                      "visible-change": function ($event) {
                                        return _vm.getAccountSelect(
                                          _vm.stateData
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.searchForm.currentState,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "currentState",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.currentState",
                                    },
                                  },
                                  _vm._l(_vm.stateData, function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "进店时间",
                                  "label-width": "70px",
                                  prop: "intoStoreTime",
                                },
                              },
                              [
                                _c("el-date-picker", {
                                  attrs: {
                                    clearable: "",
                                    "end-placeholder": "结束日期",
                                    "range-separator": "至",
                                    size: "small",
                                    "start-placeholder": "开始日期",
                                    type: "daterange",
                                    "value-format": "yyyy-MM-dd",
                                  },
                                  model: {
                                    value: _vm.searchForm.intoStoreTime,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "intoStoreTime",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.intoStoreTime",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "结算时间",
                                  "label-width": "70px",
                                  prop: "checkTime",
                                },
                              },
                              [
                                _c("el-date-picker", {
                                  attrs: {
                                    clearable: "",
                                    "end-placeholder": "结束日期",
                                    "range-separator": "至",
                                    size: "small",
                                    "start-placeholder": "开始日期",
                                    type: "daterange",
                                    "value-format": "yyyy-MM-dd",
                                  },
                                  model: {
                                    value: _vm.searchForm.checkTime,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "checkTime", $$v)
                                    },
                                    expression: "searchForm.checkTime",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _vm.type == "FollowUpDetails"
                  ? [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "操作门店",
                            "label-width": "70px",
                            prop: "operateCompanyId",
                          },
                        },
                        [
                          _c("operateCompanyCascader", {
                            model: {
                              value: _vm.searchForm.operateCompanyId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.searchForm,
                                  "operateCompanyId",
                                  $$v
                                )
                              },
                              expression: "searchForm.operateCompanyId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "车牌号",
                            "label-width": "70px",
                            prop: "plateNumber",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "请输入车牌号",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.onSubmit.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.searchForm.plateNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "plateNumber", $$v)
                              },
                              expression: "searchForm.plateNumber",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "接车单号",
                            "label-width": "70px",
                            prop: "billCode",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "请输入接车单号",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.onSubmit.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.searchForm.billCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "billCode", $$v)
                              },
                              expression: "searchForm.billCode",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "业务来源",
                            "label-width": "70px",
                            prop: "sourceName",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "请输入业务来源",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.onSubmit.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.searchForm.sourceName,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "sourceName", $$v)
                              },
                              expression: "searchForm.sourceName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.toggleSearchStatus
                        ? [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "保险公司",
                                  "label-width": "70px",
                                  prop: "insuranceCompanyId",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    directives: [
                                      {
                                        name: "loadMore",
                                        rawName: "v-loadMore",
                                        value: _vm.selectLoadMore,
                                        expression: "selectLoadMore",
                                      },
                                    ],
                                    attrs: {
                                      loading: _vm.loadMoreLoading,
                                      "remote-method": _vm.getInsuranceCompany,
                                      clearable: "",
                                      filterable: "",
                                      placeholder: "搜索公司名称、编号",
                                      remote: "",
                                    },
                                    on: { "visible-change": _vm.selectVisible },
                                    model: {
                                      value: _vm.searchForm.insuranceCompanyId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "insuranceCompanyId",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "searchForm.insuranceCompanyId",
                                    },
                                  },
                                  _vm._l(
                                    _vm.insuranceCompanyList,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.insuranceName,
                                          value: item.id,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "事故责任",
                                  "label-width": "70px",
                                  prop: "dutyType",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请选择事故责任",
                                    },
                                    model: {
                                      value: _vm.searchForm.dutyType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "dutyType",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.dutyType",
                                    },
                                  },
                                  _vm._l(
                                    _vm.dutyTypeList,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "客户名称",
                                  "label-width": "70px",
                                  prop: "clientName",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请输入客户名称",
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.onSubmit.apply(null, arguments)
                                    },
                                  },
                                  model: {
                                    value: _vm.searchForm.clientName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "clientName",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.clientName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "新旧区分",
                                  "label-width": "70px",
                                  prop: "newOrOld",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请选择",
                                    },
                                    on: {
                                      "visible-change": function ($event) {
                                        return _vm.getFollowUpSelect(
                                          _vm.newOrOldData
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.searchForm.newOrOld,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "newOrOld",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.newOrOld",
                                    },
                                  },
                                  _vm._l(
                                    _vm.newOrOldData,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "历史坏账",
                                  "label-width": "70px",
                                  prop: "badDebtsAmount",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请输入",
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.onSubmit.apply(null, arguments)
                                    },
                                  },
                                  model: {
                                    value: _vm.searchForm.badDebtsAmount,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "badDebtsAmount",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.badDebtsAmount",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "跟进部门",
                                  "label-width": "70px",
                                  prop: "followUpDeptType",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请选择部门",
                                    },
                                    on: {
                                      "visible-change": function ($event) {
                                        return _vm.getFollowUpSelect(
                                          _vm.followUpDeptTypeData
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.searchForm.followUpDeptType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "followUpDeptType",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.followUpDeptType",
                                    },
                                  },
                                  _vm._l(
                                    _vm.followUpDeptTypeData,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "填写人",
                                  "label-width": "70px",
                                  prop: "fillUserName",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请输入填写人",
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.onSubmit.apply(null, arguments)
                                    },
                                  },
                                  model: {
                                    value: _vm.searchForm.fillUserName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "fillUserName",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.fillUserName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "进店时间",
                                  "label-width": "70px",
                                  prop: "intoStoreTime",
                                },
                              },
                              [
                                _c("el-date-picker", {
                                  attrs: {
                                    clearable: "",
                                    "end-placeholder": "结束日期",
                                    "range-separator": "至",
                                    size: "small",
                                    "start-placeholder": "开始日期",
                                    type: "daterange",
                                    "value-format": "yyyy-MM-dd",
                                  },
                                  model: {
                                    value: _vm.searchForm.intoStoreTime,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "intoStoreTime",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.intoStoreTime",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "结算时间",
                                  "label-width": "70px",
                                  prop: "checkTime",
                                },
                              },
                              [
                                _c("el-date-picker", {
                                  attrs: {
                                    clearable: "",
                                    "end-placeholder": "结束日期",
                                    "range-separator": "至",
                                    size: "small",
                                    "start-placeholder": "开始日期",
                                    type: "daterange",
                                    "value-format": "yyyy-MM-dd",
                                  },
                                  model: {
                                    value: _vm.searchForm.checkTime,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "checkTime", $$v)
                                    },
                                    expression: "searchForm.checkTime",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _vm.type == "ClaimDamage"
                  ? [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "操作门店",
                            "label-width": "70px",
                            prop: "operateCompanyId",
                          },
                        },
                        [
                          _c("operateCompanyCascader", {
                            model: {
                              value: _vm.searchForm.operateCompanyId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.searchForm,
                                  "operateCompanyId",
                                  $$v
                                )
                              },
                              expression: "searchForm.operateCompanyId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "接车单号",
                            "label-width": "70px",
                            prop: "billCode",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { clearable: "", placeholder: "请输入" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.onSubmit.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.searchForm.billCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "billCode", $$v)
                              },
                              expression: "searchForm.billCode",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "消费客户",
                            "label-width": "70px",
                            prop: "consumerClientName",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { clearable: "", placeholder: "请输入" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.onSubmit.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.searchForm.consumerClientName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.searchForm,
                                  "consumerClientName",
                                  $$v
                                )
                              },
                              expression: "searchForm.consumerClientName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "车牌号",
                            "label-width": "70px",
                            prop: "plateNumber",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { clearable: "", placeholder: "请输入" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.onSubmit.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.searchForm.plateNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "plateNumber", $$v)
                              },
                              expression: "searchForm.plateNumber",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.toggleSearchStatus
                        ? [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "开票主体",
                                  "label-width": "70px",
                                  prop: "invoiceId",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      loading: _vm.searchInvoiceLoading,
                                      "remote-method": _vm.getSearchInvoice,
                                      clearable: "",
                                      filterable: "",
                                      placeholder: "搜索开票主体名称",
                                      remote: "",
                                    },
                                    model: {
                                      value: _vm.searchForm.invoiceId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "invoiceId",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.invoiceId",
                                    },
                                  },
                                  _vm._l(_vm.invoiceList, function (item) {
                                    return _c("el-option", {
                                      key: item.companyId,
                                      attrs: {
                                        label: item.invoicingEntity,
                                        value: item.companyId,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "解决方案",
                                  "label-width": "70px",
                                  prop: "solutionType",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请选择",
                                    },
                                    on: {
                                      "visible-change": function ($event) {
                                        return _vm.getSelect(_vm.SolutionData)
                                      },
                                    },
                                    model: {
                                      value: _vm.searchForm.solutionType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "solutionType",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.solutionType",
                                    },
                                  },
                                  _vm._l(
                                    _vm.SolutionData,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "情况说明",
                                  "label-width": "70px",
                                  prop: "statusDescType",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请选择",
                                    },
                                    on: {
                                      "visible-change": function ($event) {
                                        return _vm.getSelect(
                                          _vm.descriptionData
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.searchForm.statusDescType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "statusDescType",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.statusDescType",
                                    },
                                  },
                                  _vm._l(
                                    _vm.descriptionData,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "填写人",
                                  "label-width": "70px",
                                  prop: "fillUserName",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请输入",
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.onSubmit.apply(null, arguments)
                                    },
                                  },
                                  model: {
                                    value: _vm.searchForm.fillUserName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "fillUserName",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.fillUserName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "进店日期",
                                  "label-width": "70px",
                                  prop: "intoStoreTime",
                                },
                              },
                              [
                                _c("el-date-picker", {
                                  attrs: {
                                    clearable: "",
                                    "end-placeholder": "结束日期",
                                    "range-separator": "至",
                                    size: "small",
                                    "start-placeholder": "开始日期",
                                    type: "daterange",
                                    "value-format": "yyyy-MM-dd",
                                  },
                                  model: {
                                    value: _vm.searchForm.intoStoreTime,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "intoStoreTime",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.intoStoreTime",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "结账(挂账)日期",
                                  prop: "checkTime",
                                },
                              },
                              [
                                _c("el-date-picker", {
                                  attrs: {
                                    clearable: "",
                                    "end-placeholder": "结束日期",
                                    "range-separator": "至",
                                    size: "small",
                                    "start-placeholder": "开始日期",
                                    type: "daterange",
                                    "value-format": "yyyy-MM-dd",
                                  },
                                  model: {
                                    value: _vm.searchForm.checkTime,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "checkTime", $$v)
                                    },
                                    expression: "searchForm.checkTime",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _vm.type == "DeductibleChanges"
                  ? [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "变动时间",
                            "label-width": "70px",
                            prop: "EntryTime",
                          },
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              clearable: "",
                              "end-placeholder": "结束日期",
                              "range-separator": "至",
                              size: "small",
                              "start-placeholder": "开始日期",
                              type: "daterange",
                              "value-format": "yyyy-MM-dd",
                            },
                            model: {
                              value: _vm.searchForm.EntryTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "EntryTime", $$v)
                              },
                              expression: "searchForm.EntryTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "报案号", prop: "caseNum" } },
                        [
                          _c("el-input", {
                            attrs: { clearable: "", placeholder: "请输入" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.onSubmit.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.searchForm.caseNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "caseNum", $$v)
                              },
                              expression: "searchForm.caseNum",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "车单号", prop: "billCode" } },
                        [
                          _c("el-input", {
                            attrs: { clearable: "", placeholder: "请输入" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.onSubmit.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.searchForm.billCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "billCode", $$v)
                              },
                              expression: "searchForm.billCode",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户", prop: "clientName" } },
                        [
                          _c("el-input", {
                            attrs: { clearable: "", placeholder: "请输入" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.onSubmit.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.searchForm.clientName,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "clientName", $$v)
                              },
                              expression: "searchForm.clientName",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "header-right" },
              [
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { icon: "el-icon-search", type: "primary" },
                        on: { click: _vm.onSubmit },
                      },
                      [_vm._v("查询")]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { icon: "el-icon-refresh-right" },
                        on: {
                          click: function ($event) {
                            return _vm.reset("formRef")
                          },
                        },
                      },
                      [_vm._v("清空")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }