var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "base-container",
      class: { "default-padding": _vm.defaultPadding, pt: _vm.paddingTop },
      style: _vm.customStyle,
    },
    [
      _c("div", { staticClass: "header" }, [_vm._t("header")], 2),
      _c(
        "div",
        { staticClass: "main", style: _vm.customMainStyle },
        [_vm._t("default")],
        2
      ),
      _c("div", { staticClass: "footer" }, [_vm._t("footer")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }