<template>
  <el-dialog
    :title="title"
    :visible="visibility"
    width="800px"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <el-form
      ref="form"
      size="small"
      :model="editForm"
      :rules="rules"
      label-width="90px"
      style="margin-top: 18px"
    >
      <el-row :gutter="10">
        <el-col :span="12" :offset="0">
          <el-form-item label="商品名称" prop="goodsId">
            <el-select
              v-model="editForm.goodsId"
              @change="goodValueChange"
              @clear="clearGoodValue"
              filterable
              clearable
              remote
              placeholder="请输入商品名称搜索选择"
              :remote-method="searchGoods"
              :loading="searchGoodsLoading"
              :disabled="this.id !== ''"
            >
              <el-option
                v-for="item in goodsList"
                :key="item.goodsId"
                :label="item.goodName"
                :value="item.goodsId"
              >
                <span style="float: left; color: #8492a6; font-size: 13px">{{
                  item.goodName
                }}</span>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="商品分类">
            {{ editForm.goodCategory }}
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12" :offset="0">
          <el-form-item label="商品编码">
            {{ editForm.goodCode }}
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="商品品牌">
            {{ editForm.goodBrand }}
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12" :offset="0">
          <el-form-item label="单位">
            {{ editForm.unit }}
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="安全库存" prop="safeStock">
            <el-input-number
              v-model="editForm.safeStock"
              maxlength="200"
              :min="0"
              placeholder="请输入安全库存"
              clearable
            >
            </el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12" :offset="0">
          <el-form-item label="门店" prop="companyId">
            <el-select
              v-model="editForm.companyId"
              filterable
              clearable
              placeholder="请输入门店名称搜索选择"
              :disabled="this.id !== ''"
            >
              <el-option
                v-for="item in companyList"
                :key="item.companyId"
                :label="item.companyName"
                :value="item.companyId"
              >
                <span style="float: left; color: #8492a6; font-size: 13px">{{
                  item.companyName
                }}</span>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="库存预警" prop="isWarning">
            <el-select
              v-model="editForm.isWarning"
              placeholder="请选择库存预警状态"
            >
              <el-option label="启用" :value="1"> </el-option>
              <el-option label="禁用" :value="0"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button size="small" type="primary" @click="save"> 确 定 </el-button>
      <el-button size="small" @click="handleClose"> 取 消 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex';
// 接口

import {
  searchStoreRoomByName,
  inventoryWarningAdd,
  inventoryWarningEdit,
  inventoryWarningDetail,
  getInventoryWarningGoodsList,
} from '@/api/stock/inventoryWarning/index';
export default {
  name: 'editInventoryWarningDialog',
  props: {
    visibility: Boolean,
    id: [Number, String],
    rowEntityData: [Object],
    companyList: [Array],
  },
  data() {
    return {
      // 表单信息
      editForm: {
        isWarning: 1,
      },
      goodsList: [],
      searchGoodsLoading: false,
      searchGoodTimer: null,
      storeRoomList: [],
      searchStoreRoomLoading: false,
      // 表单规则
      rules: {
        goodsId: [
          {
            required: true,
            trigger: 'blur',
            message: '请选择商品！',
          },
        ],
        safeStock: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入安全库存！',
          },
        ],
        companyId: [
          {
            required: true,
            trigger: 'blur',
            message: '请选择门店！',
          },
        ],
        isWarning: [
          {
            required: true,
            trigger: 'blur',
            message: '请选择预警状态！',
          },
        ],
      },
    };
  },
  computed: {
    title() {
      return this.id ? '编辑库存预警信息' : '新增库存预警信息';
    },
  },
  created() {
    this.init();
  },
  methods: {
    clearGoodValue() {
      delete this.editForm.goodsId;
      this.editForm.goodCategory = '';
      this.editForm.goodCode = '';
      this.editForm.goodBrand = '';
      this.editForm.unit = '';
    },
    goodValueChange(item) {
      console.log('ccccc', item);
      const len = this.goodsList.length;
      for (let i = 0; i < len; i++) {
        if (this.goodsList[i].goodsId == item) {
          this.editForm.goodCategory = this.goodsList[i].goodCategory;
          this.editForm.goodCode = this.goodsList[i].goodCode;
          this.editForm.goodBrand = this.goodsList[i].goodBrand;
          this.editForm.unit = this.goodsList[i].unit;
        }
      }
    },
    /**
     * 根据商品名称搜索商品列表
     * @param {String} goodName 商品名称
     */
    async searchGoods(goodName) {
      if (this.searchGoodTimer) {
        clearTimeout(this.searchGoodTimer);
      }
      this.searchGoodTimer = setTimeout(() => {
        this.searchGoodsLoading = true;
        const data = {
          goodName: goodName,
        };
        getInventoryWarningGoodsList(data)
          .then((res) => {
            this.goodsList = res;
          })
          .finally(() => {
            this.searchGoodsLoading = false;
          });
      }, 1000);
    },
    async searchGoods1(goodsId, goodName) {
      this.searchGoodsLoading = true;
      const data = {
        goodName: goodName,
        goodsId: goodsId,
      };
      getInventoryWarningGoodsList(data)
        .then((res) => {
          this.goodsList = res;
        })
        .finally(() => {
          this.searchGoodsLoading = false;
        });
    },
    init() {
      if (this.id) {
        inventoryWarningDetail({ id: this.id }).then(async (res) => {
          const data = {
            goodsId: res.goodsId,
          };
          getInventoryWarningGoodsList(data)
            .then((res1) => {
              this.goodsList = res1;
              this.editForm = res;
              this.goodValueChange(res.goodsId);
            })
            .finally(() => {
              this.searchGoodsLoading = false;
            });
        });
      }
    },
    /**
     * 保存
     */
    save() {
      this.$refs.form
        .validate()
        .then((result, object) => {
          var api = this.id ? inventoryWarningEdit : inventoryWarningAdd;
          var body = Object.assign({}, this.editForm);
          api(body).then((res) => {
            this.$message.success('保存成功！');
            this.$emit('save');
            this.handleClose();
          });
        })
        .catch((error) => {
          console.log(error);
          var msg =
            '<p>' +
            Object.values(error)
              .map((el) => el.map((v) => v.message))
              .join('<br />') +
            '</p>';
          this.$message({
            type: 'warning',
            dangerouslyUseHTMLString: true,
            message: msg,
          });
        });
    },

    /**
     * 关闭抽屉
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item {
  .el-date-editor,
  .el-select {
    width: 100%;
  }
}
.selected-car-model-list {
  margin: 6px 0 12px 0;
  .el-tag {
    margin-top: 6px;
    margin-left: 6px;
  }
}
</style>
