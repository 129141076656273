var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-form",
            {
              attrs: { model: _vm.form, "label-width": "100px", size: "small" },
            },
            [
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c(
                          "div",
                          [
                            _vm._v(" 消费门店 "),
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  effect: "light",
                                  content:
                                    "车辆的消费门店，可查连锁范围内全部门店",
                                  placement: "bottom-start",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "iconfont grow-icon_doubt",
                                  staticStyle: { color: "#9a9a9a" },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "el-select",
                        {
                          attrs: {
                            size: "small",
                            filterable: "",
                            clearable: "",
                            remote: "",
                            placeholder: "搜索车店名称、编号",
                            "remote-method": _vm.searchStore,
                            loading: _vm.searchStoreLoading,
                          },
                          on: {
                            change: _vm.selectStore,
                            clear: _vm.deleteStore,
                          },
                          model: {
                            value: _vm.form.consumeCompanyId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "consumeCompanyId", $$v)
                            },
                            expression: "form.consumeCompanyId",
                          },
                        },
                        _vm._l(_vm.storeList, function (item) {
                          return _c(
                            "el-option",
                            {
                              key: item.companyId,
                              attrs: {
                                label: item.companyName,
                                value: item.companyId,
                              },
                            },
                            [
                              _c("span", { staticStyle: { float: "left" } }, [
                                _vm._v(_vm._s(item.companyName)),
                              ]),
                              _c("span", { staticStyle: { float: "right" } }, [
                                _vm._v(_vm._s(item.companyId)),
                              ]),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "车牌号" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            size: "small",
                            filterable: "",
                            clearable: "",
                            placeholder: "名下全部车辆",
                          },
                          model: {
                            value: _vm.form.carId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "carId", $$v)
                            },
                            expression: "form.carId",
                          },
                        },
                        _vm._l(_vm.carList, function (item) {
                          return _c(
                            "el-option",
                            {
                              key: item.carId,
                              attrs: {
                                label: item.carNumber,
                                value: item.carId,
                              },
                            },
                            [
                              _c("span", { staticStyle: { float: "left" } }, [
                                _vm._v(_vm._s(item.carNumber)),
                              ]),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "项目名称" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "输入服务、商品项目名称",
                          clearable: "",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.getDataList.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.form.billServiceMsg,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "billServiceMsg", $$v)
                          },
                          expression: "form.billServiceMsg",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "车架号" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入车架号", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.getDataList.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.form.uniqueId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "uniqueId", $$v)
                          },
                          expression: "form.uniqueId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn_search",
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.getDataList },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      [
        _c("ul", { staticClass: "header-ul_menus" }, [
          _c(
            "li",
            {
              staticClass: "header-li_menu",
              class: { selected: _vm.checkedIndex == 0 },
              on: {
                click: function ($event) {
                  return _vm.handleChange(0)
                },
              },
            },
            [
              _c(
                "el-badge",
                { staticClass: "item", attrs: { value: _vm.total } },
                [
                  _c("h3", { staticClass: "header-h3_title" }, [
                    _vm._v(
                      " 消费记录(￥" + _vm._s(_vm.totalConsumptionRecord) + ") "
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ]),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.record1,
                expression: "record1",
              },
            ],
            staticClass: "custom-table",
            staticStyle: { height: "calc(63vh)" },
            attrs: { data: _vm.carListData, border: "" },
          },
          [
            _c("el-table-column", {
              attrs: { type: "index", label: "序号", width: "60" },
            }),
            _c("el-table-column", {
              attrs: {
                label: "消费门店",
                width: "120",
                prop: "consumeCompanyName",
              },
            }),
            _c("el-table-column", {
              attrs: { "min-width": "180", label: "单据号" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "p",
                        {
                          staticClass: "pointer blue",
                          on: {
                            click: function ($event) {
                              return _vm.goDetail(scope.row.billId)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(scope.row.billCode) + " ")]
                      ),
                      scope.row.billType != 0
                        ? _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("dict")(
                                    scope.row.billType,
                                    "carBillType"
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("dict")(
                                    scope.row.isOthersSelfPayState,
                                    "isOthersSelfPayState"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { "min-width": "180", label: "车架号", prop: "uniqueId" },
            }),
            _c("el-table-column", {
              attrs: { "min-width": "180", label: "车牌号", prop: "carNumber" },
            }),
            _c("el-table-column", {
              attrs: { "min-width": "180", label: "消费客户" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      row.consumerName
                        ? _c("p", [
                            _vm._v("客户名称：" + _vm._s(row.consumerName)),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                "min-width": "180",
                label: "进店时间",
                prop: "intoStoreStartTime",
              },
            }),
            _c("el-table-column", {
              attrs: { "min-width": "180", label: "进店里程", prop: "mileage" },
            }),
            _c("el-table-column", {
              attrs: {
                "min-width": "200",
                label: "服务",
                prop: "service",
                "show-overflow-tooltip": "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.billServiceNames
                        ? _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.billServiceNames
                                    .map((i) => {
                                      return i
                                    })
                                    .join()
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                "min-width": "180",
                label: "商品",
                prop: "goods",
                "show-overflow-tooltip": "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.billGoodsNames
                        ? _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.billGoodsNames
                                    .map((i) => {
                                      return i
                                    })
                                    .join()
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { "min-width": "180", label: "单据金额", prop: "total" },
            }),
            _c("el-table-column", {
              attrs: { "min-width": "180", label: "实收金额", prop: "paid" },
            }),
            _c("el-table-column", {
              attrs: {
                "min-width": "180",
                label: "结账备注",
                "show-overflow-tooltip": "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [_vm._v(" " + _vm._s(row.billCheckRemark) + " ")]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.record2,
                expression: "record2",
              },
            ],
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.tableData2,
              border: "",
              height: "600px",
              "header-cell-style": {
                background: "#FAFAFA",
                color: "#000000",
                fontSize: "14px",
                fontWeight: "500",
              },
            },
          },
          [
            _c("el-table-column", {
              attrs: { type: "index", width: "60", label: "序号" },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "documentDoc",
                label: "单据号",
                "min-width": "180",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "licenseNum",
                label: "车牌号",
                "min-width": "180",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "deliveryDate",
                label: "提车日期",
                "min-width": "180",
              },
            }),
            _c("el-table-column", {
              attrs: { prop: "intMileage", label: "进店里程", width: "180" },
            }),
            _c("el-table-column", {
              attrs: { prop: "service", label: "服务", "min-width": "180" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-popover",
                        { attrs: { trigger: "hover", placement: "bottom" } },
                        [
                          _c("p", [_vm._v(_vm._s(scope.row.service))]),
                          _c(
                            "div",
                            {
                              staticClass: "name-wrapper",
                              attrs: { slot: "reference" },
                              slot: "reference",
                            },
                            [
                              _c("el-tag", { attrs: { size: "medium" } }, [
                                _vm._v(_vm._s(scope.row.service)),
                              ]),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", { attrs: { prop: "agoods", label: "商品" } }),
            _c("el-table-column", {
              attrs: {
                prop: "amountDoc",
                label: "单据金额",
                "min-width": "180",
              },
            }),
            _c("el-table-column", {
              attrs: { prop: "salesman", label: "业务员", "min-width": "180" },
            }),
            _c("el-table-column", {
              attrs: { prop: "markst", label: "备注", "min-width": "140" },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "beforeStore",
                label: "所属门店",
                "min-width": "180",
              },
            }),
          ],
          1
        ),
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c("pagination", {
            attrs: { total: _vm.total },
            on: { pageChange: _vm.changePage },
          }),
        ],
        1
      ),
      _vm.billId
        ? _c("details-list", {
            attrs: {
              billId: _vm.billId,
              dialogTableVisible: _vm.dialogTableVisible,
            },
            on: {
              "update:billId": function ($event) {
                _vm.billId = $event
              },
              "update:bill-id": function ($event) {
                _vm.billId = $event
              },
              "update:dialogTableVisible": function ($event) {
                _vm.dialogTableVisible = $event
              },
              "update:dialog-table-visible": function ($event) {
                _vm.dialogTableVisible = $event
              },
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }