// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./images/img_side.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.tipMarker {\n  color: #555;\n  background-color: rgba(255, 254, 239, 0.8);\n  border: 1px solid #7e7e7e;\n  padding: 2px 6px;\n  font-size: 12px;\n  white-space: nowrap;\n  display: inline-block;\n}\n.tipMarker:before,\n.tipMarker:after {\n  content: '';\n  display: block;\n  position: absolute;\n  margin: auto;\n  width: 0;\n  height: 0;\n  border: solid transparent;\n  border-width: 5px 5px;\n}\n.tipMarker.top {\n  -webkit-transform: translate(-50%, -110%);\n          transform: translate(-50%, -110%);\n}\n.tipMarker.top:before,\n.tipMarker.top:after {\n  bottom: -9px;\n  left: 0;\n  right: 0;\n  border-top-color: rgba(255, 254, 239, 0.8);\n}\n.tipMarker.top:before {\n  bottom: -10px;\n  border-top-color: #7e7e7e;\n}\n.store-marker {\n  width: 0;\n}\n.store-marker .hidden-box {\n  position: relative;\n  display: none;\n  z-index: 999;\n}\n.store-marker .tip {\n  position: absolute;\n  top: -96px;\n  width: -webkit-max-content;\n  width: -moz-max-content;\n  width: max-content;\n  padding: 4px 8px;\n  border: 1px solid #7af6ff;\n  border-radius: 4px;\n  -webkit-transform: translate(-50%, -20px);\n          transform: translate(-50%, -20px);\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n  color: #7af6ff;\n  font-size: 14px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n  line-height: 20px;\n  /* #F22727  */\n  /* #7AF6FF */\n}\n.store-marker-name {\n  width: -webkit-max-content;\n  width: -moz-max-content;\n  width: max-content;\n  padding: 4px 6px;\n  border-radius: 5px;\n  -webkit-transform: translate(-50%, -60%);\n          transform: translate(-50%, -60%);\n  color: #ffdd3d;\n  line-height: 14px;\n  letter-spacing: 1px;\n  font-size: 14px;\n  font-family: PangMenZhengDao;\n}\n.store-marker-icon:hover + .hidden-box {\n  display: block;\n}\n.amap-logo {\n  display: none !important;\n  opacity: 0 !important;\n}\n.amap-copyright {\n  opacity: 0;\n}\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
