var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visibility,
        width: "500px",
        "before-close": _vm.handleClose,
        "close-on-click-modal": false,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticStyle: { "margin-top": "18px" },
          attrs: {
            size: "small",
            model: _vm.editForm,
            rules: _vm.rules,
            "label-width": "90px",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "车型", prop: "carModel" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "blue pointer flex-y-center",
                          staticStyle: { height: "32px" },
                          on: {
                            click: function ($event) {
                              _vm.selectCarModelDialogVisibility = true
                            },
                          },
                        },
                        [_vm._v(" 选择车型 ")]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c("el-col", { attrs: { span: 24, offset: 0 } }, [
                _c(
                  "p",
                  { staticStyle: { "padding-left": "90px", height: "30px" } },
                  [_vm._v(_vm._s(_vm.carModelInfoStr))]
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "服务名称", prop: "serviceItemId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            filterable: "",
                            remote: "",
                            "remote-method": _vm.searchAllServiceItem,
                            loading: _vm.searchAllServiceItemLoading,
                            placeholder: "请输入服务名称搜索选择！",
                            "value-key": "serviceItemId",
                          },
                          on: {
                            change: _vm.serviceItemChangeEvent,
                            clear: _vm.serviceItemClearEvent,
                          },
                          model: {
                            value: _vm.editForm.serviceItemId,
                            callback: function ($$v) {
                              _vm.$set(_vm.editForm, "serviceItemId", $$v)
                            },
                            expression: "editForm.serviceItemId",
                          },
                        },
                        _vm._l(_vm.serviceItems, function (item) {
                          return _c(
                            "el-option",
                            {
                              key: item.serviceItemId,
                              attrs: { label: item.name, value: item },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    float: "left",
                                    color: "#8492a6",
                                    "font-size": "13px",
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.name) + " ")]
                              ),
                              _c("span", { staticStyle: { float: "right" } }, [
                                _vm._v(_vm._s(item.serviceItemCode)),
                              ]),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c("el-col", { attrs: { span: 24, offset: 0 } }, [
                _c(
                  "p",
                  { staticStyle: { "padding-left": "90px", height: "30px" } },
                  [_vm._v(" " + _vm._s(_vm.selectedServiceItem?.name) + " ")]
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c("el-form-item", { attrs: { label: "当前服务价" } }, [
                    _vm._v(" " + _vm._s(_vm.selectedServiceItem?.price) + " "),
                  ]),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "协议价", prop: "serviceAgreementPrice" },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "200",
                          placeholder: "请输入服务协议价！",
                        },
                        model: {
                          value: _vm.editForm.serviceAgreementPrice,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.editForm,
                              "serviceAgreementPrice",
                              _vm._n($$v)
                            )
                          },
                          expression: "editForm.serviceAgreementPrice",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品标准名", prop: "goodsId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            filterable: "",
                            remote: "",
                            "remote-method": _vm.searchAllGoods,
                            loading: _vm.searchAllGoodsLoading,
                            placeholder: "请输入商品名称搜索选择！",
                            "value-key": "goodsId",
                          },
                          on: {
                            change: _vm.goodsItemChangeEvent,
                            clear: _vm.goodsItemClearEvent,
                          },
                          model: {
                            value: _vm.editForm.goodsId,
                            callback: function ($$v) {
                              _vm.$set(_vm.editForm, "goodsId", $$v)
                            },
                            expression: "editForm.goodsId",
                          },
                        },
                        _vm._l(_vm.goodsItems, function (item, index) {
                          return _c(
                            "el-option",
                            {
                              key: index,
                              attrs: {
                                label: `${item.name}-${_vm.myDict(
                                  item.manufactoryType,
                                  "goodsManufactoryType"
                                )}-${item.matchCarType || ""}`,
                                value: item,
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    color: "#8492a6",
                                    "font-size": "13px",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(item.name) +
                                      "-" +
                                      _vm._s(
                                        _vm._f("dict")(
                                          item.manufactoryType,
                                          "goodsManufactoryType"
                                        )
                                      ) +
                                      "-" +
                                      _vm._s(item.matchCarType) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c("el-col", { attrs: { span: 24, offset: 0 } }, [
                _c(
                  "p",
                  { staticStyle: { "padding-left": "90px", height: "30px" } },
                  [_vm._v(" " + _vm._s(_vm.selectedGoods?.name) + " ")]
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c("el-form-item", { attrs: { label: "当前商品价" } }, [
                    _vm._v(" " + _vm._s(_vm.selectedGoods?.price) + " "),
                  ]),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "协议价", prop: "goodsAgreementPrice" } },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "200",
                          placeholder: "请输入商品协议价",
                        },
                        model: {
                          value: _vm.editForm.goodsAgreementPrice,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.editForm,
                              "goodsAgreementPrice",
                              _vm._n($$v)
                            )
                          },
                          expression: "editForm.goodsAgreementPrice",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.selectCarModelDialogVisibility
        ? _c("selectCarModelDialog", {
            attrs: {
              visibility: _vm.selectCarModelDialogVisibility,
              value: _vm.carModelList,
            },
            on: {
              "update:visibility": function ($event) {
                _vm.selectCarModelDialogVisibility = $event
              },
              select: _vm.handleSelectCarModel,
            },
          })
        : _vm._e(),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.save },
            },
            [_vm._v(" 确 定 ")]
          ),
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.handleClose } },
            [_vm._v(" 取 消 ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }