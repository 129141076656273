var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      ref: "popover",
      attrs: {
        "popper-class": "custom-popover",
        placement: "bottom",
        width: "50vw",
        trigger: "manual",
      },
      model: {
        value: _vm.selectServicePopoverVisible,
        callback: function ($$v) {
          _vm.selectServicePopoverVisible = $$v
        },
        expression: "selectServicePopoverVisible",
      },
    },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
          attrs: { "max-height": "300px", data: _vm.serviceList },
          on: { "row-click": _vm.selectServiceRow },
        },
        [
          _c("el-table-column", {
            attrs: { type: "index", label: "序号", width: "60" },
          }),
          _c("el-table-column", {
            attrs: { width: "180", prop: "name", label: "服务项目" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.isAgreePrice
                      ? _c(
                          "el-tag",
                          {
                            staticClass: "el-icon--left",
                            attrs: { type: "info", size: "mini" },
                          },
                          [_vm._v("协议")]
                        )
                      : _vm._e(),
                    _c("span", [_vm._v(_vm._s(row.name))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "110", prop: "serviceTime", label: "服务时效(H)" },
          }),
          _c("el-table-column", {
            attrs: { width: "80", prop: "price", label: "售价" },
          }),
          _c("el-table-column", {
            attrs: { label: "最近服务", prop: "lastUseDate", width: "120" },
          }),
          _c("el-table-column", {
            attrs: {
              "min-width": "120",
              prop: "description",
              label: "服务备注",
            },
          }),
        ],
        1
      ),
      _c("div", { staticStyle: { height: "40px" } }, [
        _vm.hasMore
          ? _c(
              "div",
              {
                staticClass: "load-more-btn",
                on: {
                  click: function ($event) {
                    return _vm.getData(false, true)
                  },
                },
              },
              [_vm._v(" 加载更多数据 ")]
            )
          : _c("div", { staticClass: "all" }, [_vm._v("已全部加载")]),
      ]),
      _c("el-input", {
        ref: "keyWordInput",
        class: _vm.showErrorClass ? "error" : "",
        attrs: {
          slot: "reference",
          clearable: "",
          size: "small",
          placeholder: _vm.placeholder,
        },
        on: {
          input: function ($event) {
            return _vm.getData(true)
          },
          focus: function ($event) {
            _vm.selectServicePopoverVisible = true
          },
          blur: _vm.inputBlur,
        },
        slot: "reference",
        model: {
          value: _vm.keyWord,
          callback: function ($$v) {
            _vm.keyWord = $$v
          },
          expression: "keyWord",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }