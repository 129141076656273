var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-drawer",
    {
      attrs: {
        title: "库存详情",
        visible: _vm.visibility,
        size: "60%",
        "append-to-body": true,
        "before-close": _vm.handleClose,
        modal: "",
        "close-on-press-escape": "",
        wrapperClosable: "",
      },
    },
    [
      _c(
        "div",
        { staticClass: "box" },
        [
          _c("div", { staticClass: "header" }, [_vm._v("基本信息")]),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "label" }, [_vm._v("适用车型：")]),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.detail.matchCarType)),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "label" }, [
                      _vm._v("商品标准名："),
                    ]),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.detail.standardName)),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "label" }, [_vm._v("原厂编码：")]),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.detail.goodsCode)),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "label" }, [_vm._v("自编码：")]),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.detail.ownCode)),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "label" }, [_vm._v("品质：")]),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$lodash.get(
                              _vm.detail,
                              "manufactoryTypeOption.desc"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "label" }, [_vm._v("单位：")]),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.detail.unit)),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "label" }, [_vm._v("商品分类：")]),
                    _c("div", { staticClass: "value" }, [
                      _c("span", [
                        _vm._v(_vm._s(_vm.detail.firstCategoryName)),
                      ]),
                      _vm.detail.secondCategoryName
                        ? _c("span", [
                            _vm._v("/" + _vm._s(_vm.detail.secondCategoryName)),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "label" }, [_vm._v("商品名称：")]),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.detail.goodsName)),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c("div", { staticClass: "label" }, [_vm._v("商品备注：")]),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.detail.remark)),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c("div", { staticClass: "label" }, [_vm._v("商品图片：")]),
                    _c(
                      "div",
                      { staticClass: "value" },
                      [
                        _c("upload-file-list", {
                          attrs: { value: _vm.detail.images, disabled: "" },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "label" }, [
                      _vm._v("库位（余量）："),
                    ]),
                    _c(
                      "div",
                      { staticClass: "value" },
                      _vm._l(_vm.detail.inventories, function (item, index) {
                        return _c("div", { key: index }, [
                          _c("span", [
                            _vm._v(_vm._s(item.storeRoom.storeRoomName)),
                          ]),
                          _vm.$lodash.get(item, "location.locationName")
                            ? _c("span", [
                                _vm._v(
                                  "-" + _vm._s(item.location.locationName)
                                ),
                              ])
                            : _vm._e(),
                          _c("span", [
                            _vm._v("(" + _vm._s(item.inventoryNum) + ")"),
                          ]),
                        ])
                      }),
                      0
                    ),
                  ]),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "font-size": "14px" },
                      attrs: { span: 12 },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "blue pointer",
                          on: { click: _vm.handleClick },
                        },
                        [_vm._v("收发流水")]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "blue pointer",
                          staticStyle: { "margin-left": "5px" },
                          on: { click: _vm.handleInventoryDetailMargin },
                        },
                        [_vm._v("批次余量")]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "header", staticStyle: { "margin-top": "16px" } },
            [_vm._v("供应商")]
          ),
          _c(
            "el-table",
            { attrs: { data: _vm.listData, border: "" } },
            [
              _c("el-table-column", {
                attrs: { label: "序号", type: "index", width: "60" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "供应商简称",
                  "min-width": "120",
                  prop: "supplier.supplierName",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "采购价", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("toThousands")(row.lastPurchasePrice)
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "上次采购时间",
                  "min-width": "100",
                  prop: "lastPurchaseDate",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "采购次数",
                  "min-width": "100",
                  prop: "purchaseTimes",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }