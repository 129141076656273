import http from '@/service/http';

// 获取列表数据
export function getInvoiceList(data) {
  return http.post('/admin/company/invoiceList', data);
}
// 开票主体修改
export function getInvoiceUpdate(data) {
  return http.put('/admin/company/invoiceUpdate', data, {
    onlyReturnData: false,
  });
}

// 开票主体
export function searchInvoice(
  keyWord = '',
  currentCompanyId = '',
  config = { noShowWaiting: true }
) {
  return http.post(
    '/admin/company/invoiceDropList',
    {
      invoiceName: keyWord,
      currentCompanyId: currentCompanyId,
      size: 50,
      page: 1,
    },
    config
  );
}

/**
 * 获取门店信息
 */
export function getCompanyDetail(id = 0) {
  return http.post('/admin/company/getCompanyById', { id });
}
/**
 * 门店接车总览-查询
 * @param {*} data 
 * @returns 
 */
export function getCompanyOverviewData(data) {
  return http.post('/admin/company/overview', data);
}