var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "导入批次明细列表",
        visible: _vm.visibility,
        width: "90%",
        "before-close": _vm.handleClose,
        "close-on-click-modal": false,
      },
    },
    [
      _c(
        "el-table",
        {
          ref: "queryDataTable",
          staticClass: "custom-table",
          attrs: { data: _vm.listData, border: "" },
        },
        _vm._l(_vm.showColumns, function (item, index) {
          return _c("el-table-column", {
            key: index,
            attrs: { width: "200", label: item.label, prop: item.prop },
          })
        }),
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("pagination", {
            attrs: { total: _vm.total },
            on: { pageChange: _vm.changePage },
          }),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "auth",
                  rawName: "v-auth",
                  value: 717,
                  expression: "717",
                },
              ],
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.downloadAllRecords },
            },
            [_vm._v("导出批次列表")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }