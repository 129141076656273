<template>
  <base-container ref="baseContainer" paddingTop>
    <div class="mainContainer">
      <companyOverviewItem :difficultyType="0" :companyId="companyId" itemName="保养车"></companyOverviewItem>
      <companyOverviewItem :difficultyType="1" :companyId="companyId" itemName="故障车"></companyOverviewItem>
      <companyOverviewItem :difficultyType="2" :companyId="companyId" itemName="事故车"></companyOverviewItem>
      <companyOverviewItem :difficultyType="4" :companyId="companyId" itemName="其它车"></companyOverviewItem>
      <!--
      -->
    </div>
  </base-container>
</template>
<script>
// 组件
import companyOverviewItem from './components/companyOverviewItem.vue';

import { mapState } from 'vuex';
export default {
  name: 'companyOverview',
  components: {
    companyOverviewItem,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  activated() {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.mainContainer {
}
</style>
