var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑用料",
            width: "50%",
            visible: _vm.visibility,
            "before-close": _vm.handleClose,
            "close-on-click-modal": false,
            "append-to-body": "",
          },
        },
        [
          _c(
            "div",
            { staticClass: "edit-box" },
            [
              _c(
                "p",
                {
                  staticClass: "orange",
                  staticStyle: { "font-size": "12px", margin: "10px 0 20px 0" },
                },
                [
                  _vm._v(
                    " 修改报价项目里的商品数量，用料清单将同步更新。也可单独编辑用料清单，编辑保存后不影响报价项目的数据 "
                  ),
                ]
              ),
              _c(
                "el-table",
                {
                  staticClass: "service-table",
                  staticStyle: { width: "100%" },
                  attrs: {
                    border: "",
                    data: _vm.tableData,
                    "default-expand-all": "",
                    "row-key": "id",
                    "tree-props": {
                      children: "materialGoods",
                      hasChildren: "hasChildren",
                    },
                    "span-method": _vm.spanMethod,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { width: "80", label: "操作" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            row.type === "goods"
                              ? _c("span", [
                                  _c("i", {
                                    staticClass:
                                      "iconfont grow-icon_delete3 pointer table-del-icon",
                                    staticStyle: {
                                      color: "#ff9200",
                                      "font-size": "22px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteRow(row)
                                      },
                                    },
                                  }),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { width: "60", label: "序号", prop: "title" },
                  }),
                  _c(
                    "el-table-column",
                    {
                      attrs: { label: "", width: "180" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row, $index }) {
                            return [
                              row.name
                                ? _c("span", [_vm._v(_vm._s(row.name))])
                                : _c(
                                    "div",
                                    [
                                      _c("select-goods-popover", {
                                        attrs: { carId: _vm.carId },
                                        on: {
                                          select: function ($event) {
                                            return _vm.onSelectGoodsItem(
                                              $event,
                                              row,
                                              $index
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _vm._v(" 报价项目 "),
                      ]),
                    ],
                    2
                  ),
                  _c("el-table-column", {
                    attrs: { label: "项目编码", "min-width": "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            row.type === "service"
                              ? _c("span", [_vm._v(_vm._s(row.code))])
                              : _c("span", [_vm._v(_vm._s(row.goods.code))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "商品及库存", width: "180" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row, $index }) {
                          return [
                            row.type === "service"
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { size: "small" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addGoodsRow(row)
                                          },
                                        },
                                      },
                                      [_vm._v("+ 添加")]
                                    ),
                                    _c(
                                      "el-tooltip",
                                      { attrs: { content: "批量添加" } },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "iconfont grow-icon_add el-icon--right primary pointer",
                                          on: {
                                            click: function ($event) {
                                              return _vm.showMultipleSelectGoodsDrawer(
                                                row,
                                                $index
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _c("div", [
                                  _c("span", [
                                    _vm._v(_vm._s(row.inventoryTotalNum)),
                                  ]),
                                  _c(
                                    "span",
                                    { staticStyle: { "margin-left": "5px" } },
                                    [_vm._v(_vm._s(row.goods.unit))]
                                  ),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "库存预警值", width: "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            row.type === "goods"
                              ? _c("span", [
                                  _vm._v(
                                    " " + _vm._s(row.goods.alarmNum) + " "
                                  ),
                                ])
                              : _c(
                                  "div",
                                  { staticStyle: { "text-align": "center" } },
                                  [
                                    _vm._v(
                                      " " + _vm._s(row.technicianName) + " "
                                    ),
                                  ]
                                ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "用料数量", width: "120" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            row.type === "goods"
                              ? _c("number-input", {
                                  key: _vm.tableKey,
                                  attrs: {
                                    size: "small",
                                    clearable: false,
                                    min: 1,
                                    enabledDecimals: false,
                                  },
                                  model: {
                                    value: row.num,
                                    callback: function ($$v) {
                                      _vm.$set(row, "num", $$v)
                                    },
                                    expression: "row.num",
                                  },
                                })
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "状态", width: "120" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("dict")(
                                    row.materialGoodsState,
                                    "materialGoodsState"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.handleClose } },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleConfirm },
                },
                [_vm._v("保 存")]
              ),
            ],
            1
          ),
          _vm.multipleSelectGoodsDrawerVisibility
            ? _c("select-goods-for-car-bill", {
                attrs: {
                  carId: _vm.carId,
                  visibility: _vm.multipleSelectGoodsDrawerVisibility,
                },
                on: {
                  "update:visibility": function ($event) {
                    _vm.multipleSelectGoodsDrawerVisibility = $event
                  },
                  select: _vm.multipleSelectGoods,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }