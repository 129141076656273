var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-cascader", {
    ref: "cascader",
    attrs: {
      options: _vm.companyTree,
      clearable: "",
      size: _vm.size,
      "show-all-levels": false,
      props: {
        value: "id",
        label: "name",
        children: "nextGroup",
        checkStrictly: true,
      },
      filterable: "",
      placeholder: _vm.placeholder,
    },
    on: { change: _vm.handleChange },
    model: {
      value: _vm.companyIds,
      callback: function ($$v) {
        _vm.companyIds = $$v
      },
      expression: "companyIds",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }