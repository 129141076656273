import http from '@/service/http';

const defaultListOption = {
  current: 1,
  size: 10,
};

/**
 * 门店预约配置-车型车系列表
 * @param {Object} data
 * @returns
 */
export function carModelList(data) {
  return http.post('/admin/mini/booking/company/config/carModelList', data);
}
/**
 * 门店预约配置-获取信息
 * @param {Object} data
 * @returns
 */
export function info(data) {
  return http.post('/admin/mini/booking/company/config/info', data);
}

/**
 * 门店预约配置-新增或者编辑
 * @param {Object} data
 * @returns
 */
export function saveOrUpdate(data = defaultListOption) {
  return http.post('/admin/mini/booking/company/config/saveOrUpdate', data);
}

/**
 * 门店预约配置-可用时间段配置新增或者更新
 * @param {Object} data
 * @returns
 */
export function availableDateTimePhaseChange(data = {}) {
  return http.post(
    '/admin/mini/booking/company/config/availableDateTimePhaseChange',
    data
  );
}

/**
 * 门店预约配置-可用时间段配置查询(根据门店ID&服务类型&日期查询)
 * @param {Object} data
 * @returns
 */
export function getAvailableTimePhase(data = {}) {
  return http.post(
    '/admin/mini/booking/company/config/getAvailableTimePhase',
    data
  );
}

/**
 * 小程序预约列表
 * @param {Object} data 查询条件
 * @returns
 */
export function miniProgramBookingPreList(data = defaultListOption) {
  return http.post('/admin/mini/booking/preList', data);
}
/**
 * 小程序预约列表
 * @param {Object} data 查询条件
 * @returns
 */
export function miniProgramBookingList(data = defaultListOption) {
  return http.post('/admin/mini/booking/list', data);
}

/**
 * 小程序预约完成操作
 * @param {Object} data
 * @returns
 */
export function bookingComplete(data) {
  return http.post('/admin/mini/booking/bookingComplete', data);
}

// 导出地址
export const miniProgramBookingListExport = '/admin/mini/booking/list/export';

// 现场取号排队
/**
 * 现场取号排队-完成叫号
 * @param {Object} data
 * @returns
 */
export function miniNumberTakeComplete(data) {
  return http.post('/admin/mini/number/take/complete', data);
}
/**
 * 现场取号排队-完成叫号
 * @param {Object} data
 * @returns
 */
export function miniNumberTakeList(data) {
  return http.post('/admin/mini/number/take/list', data);
}
// 导出地址
export const miniNumberTakeListExportUrl =
  '/admin/mini/number/take/list/export';

// 送修注册
/**
 * 送修注册-列表
 * @param {Object} data
 * @returns
 */
export function miniSenderOfRepairList(data) {
  return http.post('/admin/mini/sender/repair/list', data);
}

// 送修注册-导出地址
export const miniSenderOfRepairListExportUrl =
  '/admin/mini/sender/repair/list/export';
