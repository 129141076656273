<template>
  <div class="homePage_container">
    <!-- <header class="homePage-head">
      <div class="homePage-logo">
        <img
          class="homePage-logo-img"
          src="@/assets/logo.svg"
          alt="格悦新能源"
        />
      </div>
      <home-header></home-header>
    </header> -->
    <section class="homePage-section_container">
      <!-- <home-sidebar></home-sidebar> -->
      <new-sidebar
        :num="num"
        @permissionChangeCount="permissionChangeCount"
      ></new-sidebar>
      <!-- <div v-for="(item, index) in list" :key="index"> -->
      <NotificationBox
        v-if="showNotificationDialog"
        :visibility.sync="showNotificationDialog"
        :newTodoList="newTodoList"
        :flag="flag"
        @goDetail="goDetail"
      ></NotificationBox>
      <!-- </div> -->
      <main class="homePage-main">
        <home-tags></home-tags>
        <main-view></main-view>
      </main>
    </section>
    <!-- 定损单详情弹窗 -->
    <car-loss-record-drawer
      v-if="lossRecordDrawer"
      :visibility.sync="lossRecordDrawer"
      :carLossDeterminationBillId="this.ids"
      :billId="this.billId"
    >
    </car-loss-record-drawer>
  </div>
</template>
<script>
// import HomeHeader from './Header';
// import HomeSidebar from './Sidebar';
import NewSidebar from './NewSidebar';
import NotificationBox from './notificationBox';
import HomeTags from './Tags';
import MainView from './Main';
import { mapState } from 'vuex';
// 定损单详情弹窗
import carLossRecordDrawer from '@/views/carBill/carLossDeterminationBill/carLossDeterminationBillDetailDrawer.vue';
import WebsocketMixin from '@/service/websocket/WebsocketMixin';
import { statisticNum, changeToCompleted } from '@/api/unTreated';
export default {
  mixins: [WebsocketMixin],
  components: {
    // HomeHeader,
    // HomeSidebar,
    NewSidebar,
    HomeTags,
    MainView,
    NotificationBox,
    carLossRecordDrawer,
  },
  data() {
    return {
      //通知弹框
      showNotificationDialog: false,
      //定损记录详情抽屉
      lossRecordDrawer: false,
      ids: '', //定损单ID字段
      billId: '', //车单ID字段
      list: [],
      num: '',
      // 新推送
      newTodoList: [],
      index: 0,
      flag: 0,
    };
  },
  mounted() {
    this.$bus.$on('changeUnTreatedStateEvent', (data) => {
      this.statisticNum();
    });
    this.$bus.$on('todoMsgReceivedEvent', (data) => {
      this.handleMessageReceived(data);
    });
  },
  created() {
    window.localStorage.setItem(
      'deviceId',
      this.$store?.state?.user?.userInfo?.phone
    );
    this.initWebsocket();
    this.statisticNum();
    this.newTodoList = [];
  },
  computed: {
    unTreatedStates() {
      return this.$store.state.user.unTreatedState;
    },
  },
  watch: {
    unTreatedStates(newVal) {
      this.statisticNum();
    },
  },
  methods: {
    ...mapState({
      unTreatedState: (state) => state.user.unTreatedState,
    }),
    // 切换身份
    permissionChangeCount() {
      this.statisticNum();
      if (this.index) {
        this.showNotificationDialog = false;
      } else {
        this.index++;
      }
    },
    // 获取待办数量
    statisticNum() {
      const now = new Date();
      const year = now.getFullYear();
      const month = ('0' + (now.getMonth() + 1)).slice(-2);
      const day = ('0' + now.getDate()).slice(-2);
      const hours = ('0' + now.getHours()).slice(-2);
      const minutes = ('0' + now.getMinutes()).slice(-2);
      const seconds = ('0' + now.getSeconds()).slice(-2);
      let dateTime =
        year +
        '-' +
        month +
        '-' +
        day +
        ' ' +
        hours +
        ':' +
        minutes +
        ':' +
        seconds;

      statisticNum(dateTime).then((res) => {
        this.num = res.num + '';
      });
    },
    // 定损记录详情抽屉弹出
    goDetail(businessKey, id) {
      this.ids = businessKey;
      this.lossRecordDrawer = true;
    },
    // /**
    //  *  websocket 收到消息
    //  */
    handleMessageReceived(message) {
      if (message.action == 'todoMsg') {
        this.statisticNum();
        console.log('收到消息  ws:', JSON.parse(message.content));
        let data = JSON.parse(message.content);
        this.num = data.num + '';
        if (data.newTodoList.length) {
          this.newTodoList = data.newTodoList;
          this.showNotificationDialog = true;
          if (this.newTodoList.length == 1) {
            this.flag = 1;
          } else {
            this.flag = 2;
          }
        }
      }
      // if (message.action === '4') {
      //   this.logout();
      // }
    },
    // /**
    //  * 登出
    //  */
    // logout() {
    //   this.CIMPushManager?.stop();
    //   this.CIMPushManager = null;
    //   this.$store.dispatch('logout');
    // },
  },
  beforeDestroy() {
    // this.CIMPushManager?.stop();
    // this.CIMPushManager = null;
  },
};
</script>

<style lang="scss" scoped>
.homePage_container {
  height: 100%;
}
.homePage-head {
  display: flex;
}
.homePage-logo {
  flex-shrink: 0;
  width: 160px;
  height: 70px;
  // background: #f5f8fa;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(193, 193, 193, 0.14);
  display: flex;
  align-items: center;
  justify-content: center;
}
.homePage-logo-img {
  width: 85px;
  height: 30px;
}
.homePage-section_container {
  display: flex;
  height: 100%;
  // height: calc(100% - 70px);
}
.homePage-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  // margin: 16px 16px 0 16px;
  border-radius: 4px;
  overflow: auto;
  background: #f1f7fa;
}
</style>
