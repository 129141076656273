<template>
  <el-drawer
    :title="title"
    size="55%"
    :visible="addWarrantyDialog"
    :wrapperClosable="true"
    :before-close="handleClose"
    :modal="true"
  >
    <el-form
      :model="form"
      label-width="100px"
      size="small"
      :rules="rules"
      ref="form"
      style="height: 80vh; overflow-y: scroll; margin: 0 20px"
    >
      <div class="flex-x-between">
        <div class="flex-shrink0">
          <el-form-item label="品牌" prop="name">
            <el-select
              v-model="form.name"
              filterable
              remote
              placeholder="请搜索或选择车型"
              :remote-method="searchModel"
              :loading="searchModelLoading"
              clearable
            >
              <el-option
                v-for="item in carModelList"
                :key="$lodash.get(item, 'series.id')"
                :value="
                  $lodash.get(
                    item,
                    'series.name',
                    $lodash.get(
                      item,
                      'brand.name',
                      $lodash.get(item, 'logo.name')
                    )
                  )
                "
                @click.native="selectCarModel(item)"
              >
                <span v-html="item.markedRedName"></span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="车辆所有人" style="margin: 28px 0">
            <el-input
              v-model="form.vehicleOwner"
              placeholder="请输入车辆所有人"
              style="width: 32%"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="整车质保">
            <div
              class="flex spacing"
              v-for="(item, index) in vehicleList"
              :key="index"
            >
              <number-input
                v-model="item.month"
                placeholder="请输入年限，必填"
                clearable
              ></number-input>
              <number-input
                v-model="item.km"
                decimalDigits="2"
                placeholder="请输入里程，必填"
                style="margin: 0px 10px"
              >
              </number-input>
              <el-input
                v-model="item.content"
                placeholder="请输入质保内容"
                clearable
              ></el-input>
              <i
                v-if="index == 0"
                class="pointer orange el-icon-circle-plus-outline"
                style="color: #ff9200; font-size: 24px; margin: 4px 10px"
                @click="addList('vehicleList')"
              ></i>
              <i
                v-else
                class="pointer iconfont grow-icon_delete3"
                style="color: #ff9200; font-size: 24px; margin: 0px 10px"
                @click="deleteList('vehicleList', index)"
              ></i>
            </div>
          </el-form-item>
          <el-form-item label="三电质保">
            <div
              class="flex spacing"
              v-for="(item, index) in threePowerList"
              :key="index"
            >
              <number-input
                v-model="item.month"
                placeholder="请输入年限，必填"
                clearable
              ></number-input>
              <number-input
                v-model="item.km"
                decimalDigits="2"
                placeholder="请输入里程，必填"
                style="margin: 0px 10px"
              >
              </number-input>
              <el-input
                v-model="item.content"
                placeholder="请输入质保内容"
                clearable
              ></el-input>
              <i
                v-if="index == 0"
                class="pointer orange el-icon-circle-plus-outline"
                style="color: #ff9200; font-size: 24px; margin: 4px 10px"
                @click="addList('threePowerList')"
              ></i>
              <i
                v-else
                class="pointer iconfont grow-icon_delete3"
                style="color: #ff9200; font-size: 24px; margin: 0px 10px"
                @click="deleteList('threePowerList', index)"
              ></i>
            </div>
          </el-form-item>
          <el-form-item label="动力电池质保">
            <div
              class="flex spacing"
              v-for="(item, index) in powerBatteryList"
              :key="index"
            >
              <number-input
                v-model="item.month"
                placeholder="请输入年限，必填"
                clearable
              ></number-input>
              <number-input
                v-model="item.km"
                decimalDigits="2"
                placeholder="请输入里程，必填"
                style="margin: 0px 10px"
              >
              </number-input>
              <el-input
                v-model="item.content"
                placeholder="请输入质保内容"
                clearable
              ></el-input>
              <i
                v-if="index == 0"
                class="pointer orange el-icon-circle-plus-outline"
                style="color: #ff9200; font-size: 24px; margin: 4px 10px"
                @click="addList('powerBatteryList')"
              ></i>
              <i
                v-else
                class="pointer iconfont grow-icon_delete3"
                style="color: #ff9200; font-size: 24px; margin: 0px 10px"
                @click="deleteList('powerBatteryList', index)"
              ></i>
            </div>
          </el-form-item>
          <el-form-item label="电芯质保">
            <div
              class="flex spacing"
              v-for="(item, index) in batteryList"
              :key="index"
            >
              <number-input
                v-model="item.month"
                placeholder="请输入年限，必填"
                clearable
              ></number-input>
              <number-input
                v-model="item.km"
                decimalDigits="2"
                placeholder="请输入里程，必填"
                style="margin: 0px 10px"
              >
              </number-input>
              <el-input
                v-model="item.content"
                placeholder="请输入质保内容"
                clearable
              ></el-input>
              <i
                v-if="index == 0"
                class="pointer orange el-icon-circle-plus-outline"
                style="color: #ff9200; font-size: 24px; margin: 4px 10px"
                @click="addList('batteryList')"
              ></i>
              <i
                v-else
                class="pointer iconfont grow-icon_delete3"
                style="color: #ff9200; font-size: 24px; margin: 0px 10px"
                @click="deleteList('batteryList', index)"
              ></i>
            </div>
          </el-form-item>

          <el-form-item label="易损件质保">
            <div
              class="flex spacing"
              v-for="(item, index) in wearingPartsList"
              :key="index"
            >
              <number-input
                v-model="item.month"
                placeholder="请输入年限，必填"
                clearable
              ></number-input>
              <number-input
                v-model="item.km"
                decimalDigits="2"
                placeholder="请输入里程，必填"
                style="margin: 0px 10px"
              >
              </number-input>
              <el-input
                v-model="item.content"
                placeholder="请输入质保内容"
                clearable
              ></el-input>
              <i
                v-if="index == 0"
                class="pointer orange el-icon-circle-plus-outline"
                style="color: #ff9200; font-size: 24px; margin: 4px 10px"
                @click="addList('wearingPartsList')"
              ></i>
              <i
                v-else
                class="pointer iconfont grow-icon_delete3"
                style="color: #ff9200; font-size: 24px; margin: 0px 10px"
                @click="deleteList('wearingPartsList', index)"
              ></i>
            </div>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <div class="footer">
      <el-button size="small" type="primary" @click="determine('form')"
        >确定</el-button
      >
      <el-button size="small" @click="handleClose">取消</el-button>
    </div>
  </el-drawer>
</template>

<script>
//模糊搜索接口
import { searchCarModel } from '@/api/customer/car';
import {
  carWarrantyRuleSave,
  carWarrantyRuleUpdate,
  carWarrantyRuleDetail,
} from '@/api/system/warrantyRule';
export default {
  name: 'addQualityRules',
  props: ['addWarrantyDialog', 'id'],
  data() {
    return {
      //搜索车型信息列表字段
      searchModelLoading: false,
      carModelList: [],
      //选中车型
      //   carLogoName: '',
      //   carBrandName: '',
      //   carSeriesName: '',
      title: '',
      form: {},
      //表单规则
      rules: {
        name: [{ required: true, message: '请选择车型', trigger: 'blur' }],
      },
      //整车质保添加
      vehicleList: [],
      //易损件质保添加
      wearingPartsList: [],
      // 三电质保
      threePowerList: [],
      // 动力电池质保
      powerBatteryList: [],
      // 电芯质保
      batteryList: [],
    };
  },
  created() {
    this.searchModel();
    if (this.id) {
      this.title = '编辑质保规则';
      this.getData(this.id);
    } else {
      this.title = '新增质保规则';
      this.addList('vehicleList');
      this.addList('wearingPartsList');
      this.addList('threePowerList');
      this.addList('powerBatteryList');
      this.addList('batteryList');
    }
  },
  methods: {
    handleClose() {
      this.$emit('update:addWarrantyDialog', false);
    },
    //远程搜索车型信息列表
    searchModel(key = '') {
      this.searchModelLoading = true;
      searchCarModel(key)
        .then((res) => {
          this.carModelList = res.records || [];
        })
        .finally(() => {
          this.searchModelLoading = false;
        });
    },
    selectCarModel(val) {
      //   this.carLogoName = val.logo.name;
      //   this.carBrandName = val.brand.name;
      //   this.carSeriesName = val.series.name;
    },
    getData(id) {
      carWarrantyRuleDetail(id).then((res) => {
        this.form = this.$lodash.cloneDeep(res);
        var list = this.$lodash.cloneDeep(res);
        this.vehicleList = list.vehicleVo || [];
        this.wearingPartsList = list.wearingPartsVo || [];
        this.threePowerList = list.threePowerVo || [];
        this.powerBatteryList = list.powerBatteryVo || [];
        this.batteryList = list.batteryVo || [];
        if (this.vehicleList.length == 0) {
          this.addList('vehicleList');
        }
        if (this.wearingPartsList.length == 0) {
          this.addList('wearingPartsList');
        }
        if (this.threePowerList.length == 0) {
          this.addList('threePowerList');
        }
        if (this.powerBatteryList.length == 0) {
          this.addList('powerBatteryList');
        }
        if (this.batteryList.length == 0) {
          this.addList('batteryList');
        }
      });
    },
    async determine(formName) {
      let res = await this.verifyBeforeSave().catch((err) => {
        console.log('保存前检验结果: ', err);
      });
      if (!res) return;
      var saveData = {
        id: this.form.id || '',
        name: this.form.name,
        vehicleOwner: this.form.vehicleOwner,
        vehicleList: this.vehicleList,
        wearingPartsList: this.wearingPartsList,
        threePowerList: this.threePowerList,
        powerBatteryList: this.powerBatteryList,
        batteryList: this.batteryList,
      };
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.id) {
            //修改质保
            carWarrantyRuleUpdate(saveData).then((res) => {
              if (res.code === 200) {
                this.$message.success('修改成功');
                this.$emit('update:addWarrantyDialog', false);
                this.$emit('updateData');
              }
            });
          } else {
            //新增质保
            carWarrantyRuleSave(saveData).then((res) => {
              if (res.code === 200) {
                this.$message.success('保存成功');
                this.$emit('update:addWarrantyDialog', false);
                this.$emit('updateData');
              }
            });
          }
        } else {
          console.log('error submit!!');
        }
      });
    },
    //保存前校验
    verifyBeforeSave() {
      return new Promise((resolve, reject) => {
        var vehicleMonth = this.vehicleList.some((item) => {
          return ['', undefined].includes(item.month);
        });
        var vehicleKm = this.vehicleList.some((item) => {
          return ['', undefined].includes(item.km);
        });
        var wearingPartsMonth = this.wearingPartsList.some((item) => {
          return ['', undefined].includes(item.month);
        });
        var wearingPartsKm = this.wearingPartsList.some((item) => {
          return ['', undefined].includes(item.km);
        });
        var threePowerMonth = this.threePowerList.some((item) => {
          return ['', undefined].includes(item.month);
        });
        var threePowerKm = this.threePowerList.some((item) => {
          return ['', undefined].includes(item.km);
        });
        var powerBatteryMonth = this.powerBatteryList.some((item) => {
          return ['', undefined].includes(item.month);
        });
        var powerBatteryKm = this.powerBatteryList.some((item) => {
          return ['', undefined].includes(item.km);
        });
        var batteryMonth = this.batteryList.some((item) => {
          return ['', undefined].includes(item.month);
        });
        var batteryKm = this.batteryList.some((item) => {
          return ['', undefined].includes(item.km);
        });
        if (vehicleMonth) {
          this.$alert('请填写整车质保的年限必填项！', '提示');
          reject(false);
          return;
        } else if (vehicleKm) {
          this.$alert('请填写整车质保的里程必填项！', '提示');
          reject(false);
          return;
        } else if (threePowerMonth) {
          this.$alert('请填写三电质保的年限必填项！', '提示');
          reject(false);
          return;
        } else if (threePowerKm) {
          this.$alert('请填写三电质保的里程必填项！', '提示');
          reject(false);
          return;
        } else if (powerBatteryMonth) {
          this.$alert('请填写动力电池质保的年限必填项！', '提示');
          reject(false);
          return;
        } else if (powerBatteryKm) {
          this.$alert('请填写动力电池质保的里程必填项！', '提示');
          reject(false);
          return;
        } else if (batteryMonth) {
          this.$alert('请填写电芯质保的年限必填项！', '提示');
          reject(false);
          return;
        } else if (batteryKm) {
          this.$alert('请填写电芯质保的里程必填项！', '提示');
          reject(false);
          return;
        } else if (wearingPartsMonth) {
          this.$alert('请填写易损件质保的年限必填项！', '提示');
          reject(false);
          return;
        } else if (wearingPartsKm) {
          this.$alert('请填写易损件质保的里程必填项！', '提示');
          reject(false);
          return;
        }
        resolve(true);
      });
    },

    // 添加更多质保
    addList(prop) {
      this[prop].push({
        month: '',
        km: '',
        content: '',
      });
    },
    // 删除更多质保的某个
    deleteList(prop, index) {
      this[prop].splice(index, 1);
    },
  },
};
</script>
<style lang="scss" scoped>
li {
  list-style: none;
}
::v-deep .el-form-item__label {
  color: #656673;
}
.footer {
  position: fixed;
  bottom: 20px;
  right: 40px;
}
.spacing {
  margin-bottom: 16px;
}
</style>
