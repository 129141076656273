var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { ref: "baseContainer", attrs: { paddingTop: "" } },
    [
      [
        _c(
          "el-form",
          {
            ref: "form",
            staticClass: "filter-form",
            attrs: { model: _vm.form, "label-width": "100px", size: "small" },
          },
          [
            _c("div", { staticClass: "flex-x-between" }, [
              _c(
                "div",
                { staticClass: "flex flex-shrink0" },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c(
                          "div",
                          [
                            _vm._v(" 门店 "),
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  effect: "light",
                                  content:
                                    "门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)",
                                  placement: "bottom-start",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "iconfont grow-icon_doubt",
                                  staticStyle: { color: "#9a9a9a" },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("operateCompanyCascader", {
                        attrs: {
                          operateCompanyUrl: _vm.form.operateCompanyUrl,
                        },
                        model: {
                          value: _vm.form.operateCompanyId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "operateCompanyId", $$v)
                          },
                          expression: "form.operateCompanyId",
                        },
                      }),
                    ],
                    2
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "车牌" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入车牌", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.getData.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.form.carNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "carNumber", $$v)
                          },
                          expression: "form.carNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "车单号" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入车单号", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.getData.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.form.billCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "billCode", $$v)
                          },
                          expression: "form.billCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "el-form-item--small" }, [
                _c(
                  "div",
                  {
                    staticClass: "el-form-item__label pointer",
                    on: {
                      click: function ($event) {
                        _vm.showMore = !_vm.showMore
                      },
                    },
                  },
                  [
                    _vm._v(" 更多筛选 "),
                    _c("i", {
                      class: {
                        primary: _vm.showMore,
                        "el-icon-arrow-right": !_vm.showMore,
                        "el-icon-arrow-down": _vm.showMore,
                      },
                    }),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "auth",
                            rawName: "v-auth",
                            value: 654,
                            expression: "654",
                          },
                        ],
                        staticClass: "btn_search",
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.getData },
                      },
                      [_vm._v("查询")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn_search",
                        attrs: { size: "small" },
                        on: { click: _vm.empty },
                      },
                      [_vm._v("置空")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showMore,
                    expression: "showMore",
                  },
                ],
                staticClass: "flex-warp",
              },
              [
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "回款状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择回款状态" },
                          model: {
                            value: _vm.form.receivingStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "receivingStatus", $$v)
                            },
                            expression: "form.receivingStatus",
                          },
                        },
                        _vm._l(_vm.receivingStatusEnum, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.desc, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "结账状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择结账状态" },
                          model: {
                            value: _vm.form.checkoutStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "checkoutStatus", $$v)
                            },
                            expression: "form.checkoutStatus",
                          },
                        },
                        _vm._l(_vm.checkoutStatusEnum, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.desc, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "核销状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择核销状态" },
                          model: {
                            value: _vm.form.writeOffStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "writeOffStatus", $$v)
                            },
                            expression: "form.writeOffStatus",
                          },
                        },
                        _vm._l(_vm.writeOffStatusEnum, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.desc, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "提单状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择提单状态" },
                          model: {
                            value: _vm.form.submitStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "submitStatus", $$v)
                            },
                            expression: "form.submitStatus",
                          },
                        },
                        _vm._l(_vm.submitStatusEnum, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.desc, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "结账时间" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "w100",
                        attrs: {
                          type: "daterange",
                          "unlink-panels": "",
                          "range-separator": "至",
                          "start-placeholder": "起始时间",
                          "end-placeholder": "截止时间",
                          "value-format": "yyyy-MM-dd",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.dateRangeChange()
                          },
                        },
                        model: {
                          value: _vm.form.checkDateTimes,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "checkDateTimes", $$v)
                          },
                          expression: "form.checkDateTimes",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "回款主体", prop: "invoicingEntity" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: "请选择回款主体",
                          },
                          model: {
                            value: _vm.form.invoicingEntity,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "invoicingEntity", $$v)
                            },
                            expression: "form.invoicingEntity",
                          },
                        },
                        _vm._l(_vm.invoicingEntities, function (item) {
                          return _c("el-option", {
                            key: item,
                            attrs: { label: item, value: item },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "保险公司", prop: "insuranceCompanyId" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: "请选择保险公司",
                          },
                          model: {
                            value: _vm.form.insuranceCompanyId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "insuranceCompanyId", $$v)
                            },
                            expression: "form.insuranceCompanyId",
                          },
                        },
                        _vm._l(_vm.insuranceCompanyList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label: item.insuranceName,
                              value: item.id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "回款时间", prop: "receivingDateTimes" },
                    },
                    [
                      _c("el-date-picker", {
                        staticClass: "w100",
                        attrs: {
                          type: "daterange",
                          "unlink-panels": "",
                          "range-separator": "至",
                          "start-placeholder": "起始时间",
                          "end-placeholder": "截止时间",
                          "value-format": "yyyy-MM-dd",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.dateRangeChange()
                          },
                        },
                        model: {
                          value: _vm.form.receivingDateTimes,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "receivingDateTimes", $$v)
                          },
                          expression: "form.receivingDateTimes",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "认领人", prop: "claimOperator" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            clearable: "",
                            remote: "",
                            placeholder: "搜索姓名、手机号",
                            "remote-method": _vm.searchAllUser,
                            loading: _vm.searchAllUserLoading,
                          },
                          on: {
                            select: function ($event) {
                              return _vm.doVerify("claimOperator")
                            },
                          },
                          model: {
                            value: _vm.form.claimOperator,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "claimOperator", $$v)
                            },
                            expression: "form.claimOperator",
                          },
                        },
                        _vm._l(_vm.allUserList, function (item) {
                          return _c("el-option", {
                            key: item.userId,
                            attrs: {
                              label: item.realName,
                              value: item.realName,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "流程单号" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入流程单号", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.getData.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.form.internalSettTrackingSeq,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "internalSettTrackingSeq", $$v)
                          },
                          expression: "form.internalSettTrackingSeq",
                        },
                      }),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        ),
      ],
      [
        _c(
          "div",
          {
            staticClass: "btns flex-x-between",
            staticStyle: { margin: "30px 0 14px 0" },
          },
          [
            _c("div"),
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "auth",
                        rawName: "v-auth",
                        value: 655,
                        expression: "655",
                      },
                    ],
                    staticClass: "blue",
                    attrs: { size: "small", type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.downloadAllRecords()
                      },
                    },
                  },
                  [
                    _vm._v("导出列表"),
                    _c("i", {
                      staticClass: "iconfont grow-icon_daochu el-icon--right",
                    }),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "fixedScrollBar",
                rawName: "v-fixedScrollBar",
                value: _vm.$refs.baseContainer,
                expression: "$refs.baseContainer",
              },
            ],
            staticClass: "custom-table",
            attrs: { data: _vm.listData, border: "" },
          },
          [
            _c("el-table-column", {
              attrs: { type: "index", label: "序号", width: "60" },
            }),
            _c("el-table-column", {
              attrs: { label: "操作", width: "200" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      !scope.row.id || scope.row.hasWriteOffStatus !== "已核销"
                        ? _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "auth",
                                  rawName: "v-auth",
                                  value: 656,
                                  expression: "656",
                                },
                              ],
                              staticClass: "text_operation blue",
                              staticStyle: { "margin-right": "10px" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleEdit(scope.row)
                                },
                              },
                            },
                            [_vm._v("编辑认领")]
                          )
                        : _vm._e(),
                      scope.row.id
                        ? _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "auth",
                                  rawName: "v-auth",
                                  value: 658,
                                  expression: "658",
                                },
                              ],
                              staticClass: "text_operation blue",
                              on: {
                                click: function ($event) {
                                  return _vm.handleGotoDetail(scope.row)
                                },
                              },
                            },
                            [_vm._v("认领详情")]
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "200", label: "门店", prop: "companyName" },
            }),
            _c("el-table-column", {
              attrs: { width: "140", label: "车牌", prop: "carNumber" },
            }),
            _c("el-table-column", {
              attrs: { width: "200", label: "车单号", prop: "billCode" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("gotoCarDetail", {
                        attrs: { billCode: row.billCode, billId: row.billId },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                width: "120",
                label: "车单金额(元)",
                prop: "billAmount",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "120",
                label: "待收金额(元)",
                prop: "unclearedDebtFixedAmount",
              },
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "结算状态", prop: "hasSettlement" },
            }),
            _c("el-table-column", {
              attrs: {
                width: "160",
                label: "结账时间",
                prop: "settlementDateTime",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "120",
                label: "已认领金额(元)",
                prop: "claimAmount",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "120",
                label: "可认领金额(元)",
                prop: "unClaimAmount",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "120",
                label: "挂账金额(元)",
                prop: "pendingAmount",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "120",
                label: "坏账金额(元)",
                prop: "badDebtsAmount",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "100",
                label: "回款状况",
                prop: "receivingStatus",
              },
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "认领人", prop: "claimUserName" },
            }),
            _c("el-table-column", {
              attrs: { width: "160", label: "认领时间", prop: "claimDateTime" },
            }),
            _c("el-table-column", {
              attrs: {
                width: "140",
                label: "认领备注",
                prop: "claimRemark",
                "show-overflow-tooltip": "",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "140",
                label: "核销备注",
                prop: "writeOffRemark",
                "show-overflow-tooltip": "",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "140",
                label: "反核销备注",
                prop: "reversalWriteOffRemark",
                "show-overflow-tooltip": "",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "100",
                label: "核销状态",
                prop: "hasWriteOffStatus",
              },
            }),
            _c("el-table-column", {
              attrs: { label: "核销操作", width: "300" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm.canShowGotoSubmitWorkTask(scope.row)
                        ? _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "auth",
                                  rawName: "v-auth",
                                  value: 659,
                                  expression: "659",
                                },
                              ],
                              staticClass: "text_operation blue",
                              staticStyle: { "margin-right": "10px" },
                              on: {
                                click: function ($event) {
                                  return _vm.gotoSubmitWorkTask(scope.row)
                                },
                              },
                            },
                            [_vm._v("转到提单")]
                          )
                        : _vm._e(),
                      scope.row.id &&
                      scope.row.hasSettlement === "已结算" &&
                      (scope.row.hasWriteOffStatus === "已核销" ||
                        scope.row.hasWriteOffStatus === "部分核销")
                        ? _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "auth",
                                  rawName: "v-auth",
                                  value: 664,
                                  expression: "664",
                                },
                              ],
                              staticClass: "text_operation blue",
                              staticStyle: { "margin-right": "10px" },
                              on: {
                                click: function ($event) {
                                  return _vm.openChangeWriteOffRemark(scope.row)
                                },
                              },
                            },
                            [_vm._v("变更核销备注")]
                          )
                        : _vm._e(),
                      scope.row.id &&
                      scope.row.hasSettlement === "已结算" &&
                      (scope.row.hasWriteOffStatus === "已核销" ||
                        scope.row.hasWriteOffStatus === "部分核销")
                        ? _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "auth",
                                  rawName: "v-auth",
                                  value: 665,
                                  expression: "665",
                                },
                              ],
                              staticClass: "text_operation blue",
                              staticStyle: { "margin-right": "10px" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleReversalWriteOffStatus(
                                    scope.row
                                  )
                                },
                              },
                            },
                            [_vm._v("反核销")]
                          )
                        : _vm._e(),
                      scope.row.id &&
                      scope.row.hasSettlement === "已结算" &&
                      (scope.row.hasWriteOffStatus === "未核销" ||
                        scope.row.hasWriteOffStatus === "部分核销")
                        ? _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "auth",
                                  rawName: "v-auth",
                                  value: 664,
                                  expression: "664",
                                },
                              ],
                              staticClass: "text_operation blue",
                              staticStyle: { "margin-right": "10px" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleWriteOffStatus(scope.row)
                                },
                              },
                            },
                            [_vm._v("核销")]
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm.showDetailDialog
          ? _c("ClaimBankTransactionDetailDialog", {
              attrs: {
                visibility: _vm.showDetailDialog,
                id: _vm.editId,
                billId: _vm.billId,
                entityData: _vm.rowEntityData,
              },
              on: {
                "update:visibility": function ($event) {
                  _vm.showDetailDialog = $event
                },
              },
            })
          : _vm._e(),
        _vm.showEditDialog
          ? _c("ClaimBankTransactionDialog", {
              attrs: {
                visibility: _vm.showEditDialog,
                id: _vm.editId,
                billId: _vm.billId,
                rowEntityData: _vm.rowEntityData,
                companyList: _vm.companyList,
                insuranceCompanyList: _vm.insuranceCompanyList,
                invoicingEntities: _vm.invoicingEntities,
              },
              on: {
                "update:visibility": function ($event) {
                  _vm.showEditDialog = $event
                },
                save: _vm.getData,
              },
            })
          : _vm._e(),
        _c(
          "el-dialog",
          {
            attrs: {
              "close-on-click-modal": false,
              title: "认领核销备注变更",
              visible: _vm.showChangeWriteOffRemarkDialog,
              width: "30%",
            },
            on: {
              "update:visible": function ($event) {
                _vm.showChangeWriteOffRemarkDialog = $event
              },
            },
          },
          [
            _c("p", { staticClass: "dialogSecTitle" }, [
              _vm._v("点击“确定”更新核销备注信息。"),
            ]),
            _c(
              "div",
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c("el-input", {
                          staticClass: "textarea",
                          attrs: {
                            maxlength: "250",
                            type: "textarea",
                            rows: 3,
                            resize: "none",
                            placeholder: "请输入核销备注",
                          },
                          model: {
                            value: _vm.writeOffRemark,
                            callback: function ($$v) {
                              _vm.writeOffRemark = $$v
                            },
                            expression: "writeOffRemark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        _vm.showChangeWriteOffRemarkDialog = false
                      },
                    },
                  },
                  [_vm._v("取 消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.confirmChangeWriteOffRemark },
                  },
                  [_vm._v("确 定")]
                ),
              ],
              1
            ),
          ]
        ),
        _c(
          "el-dialog",
          {
            attrs: {
              "close-on-click-modal": false,
              title: "认领核销",
              visible: _vm.showWriteOffStatusDialog,
              width: "30%",
            },
            on: {
              "update:visible": function ($event) {
                _vm.showWriteOffStatusDialog = $event
              },
            },
          },
          [
            _c("p", [
              _vm._v(
                " 点击“确定”核销单据，车单已支付金额和未支付金额将更新, 应收账款将更新。 "
              ),
            ]),
            _c(
              "div",
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c("el-input", {
                          staticClass: "textarea",
                          attrs: {
                            maxlength: "250",
                            type: "textarea",
                            rows: 3,
                            resize: "none",
                            placeholder: "请输入核销说明",
                          },
                          model: {
                            value: _vm.writeOffRemark,
                            callback: function ($$v) {
                              _vm.writeOffRemark = $$v
                            },
                            expression: "writeOffRemark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        _vm.showWriteOffStatusDialog = false
                      },
                    },
                  },
                  [_vm._v("取 消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.confirmWriteOff },
                  },
                  [_vm._v("确 定")]
                ),
              ],
              1
            ),
          ]
        ),
        _c(
          "el-dialog",
          {
            attrs: {
              "close-on-click-modal": false,
              title: "认领反核销",
              visible: _vm.showReversalWriteOffStatusDialog,
              width: "30%",
            },
            on: {
              "update:visible": function ($event) {
                _vm.showReversalWriteOffStatusDialog = $event
              },
            },
          },
          [
            _c("p", [
              _vm._v(
                " 点击“确定”反核销单据，单据认领金额核销收款将退回，车单已支付金额和未支付金额将更新。 "
              ),
            ]),
            _c(
              "div",
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c("el-input", {
                          staticClass: "textarea",
                          attrs: {
                            maxlength: "250",
                            type: "textarea",
                            rows: 3,
                            resize: "none",
                            placeholder: "请输入反核销原因",
                          },
                          model: {
                            value: _vm.reversalWriteOffRemark,
                            callback: function ($$v) {
                              _vm.reversalWriteOffRemark = $$v
                            },
                            expression: "reversalWriteOffRemark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        _vm.showReversalWriteOffStatusDialog = false
                      },
                    },
                  },
                  [_vm._v("取 消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.confirmReversalWriteOff },
                  },
                  [_vm._v("确 定")]
                ),
              ],
              1
            ),
          ]
        ),
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c("pagination", {
            attrs: { total: _vm.total },
            on: { pageChange: _vm.changePage },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }