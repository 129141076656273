var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "修改密码",
        visible: _vm.visibility,
        width: "400px",
        "before-close": _vm.handleClose,
        "close-on-click-modal": false,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticStyle: { "margin-top": "18px" },
          attrs: {
            size: "small",
            model: _vm.editForm,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _vm.needOldPassword
            ? _c(
                "el-form-item",
                { attrs: { label: "旧密码", prop: "oldPassword" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "password",
                      placeholder: "请输入旧密码",
                      maxlength: "16",
                    },
                    model: {
                      value: _vm.editForm.oldPassword,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "oldPassword", $$v)
                      },
                      expression: "editForm.oldPassword",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "新密码", prop: "newPassword" } },
            [
              _c("el-input", {
                attrs: {
                  type: "password",
                  placeholder: "请输入6-16位新密码",
                  maxlength: "16",
                },
                on: {
                  input: function ($event) {
                    return _vm.filterSpace($event, "newPassword")
                  },
                },
                model: {
                  value: _vm.editForm.newPassword,
                  callback: function ($$v) {
                    _vm.$set(_vm.editForm, "newPassword", $$v)
                  },
                  expression: "editForm.newPassword",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "确认新密码", prop: "confirmNewPassword" } },
            [
              _c("el-input", {
                attrs: {
                  type: "password",
                  placeholder: "请再次输入6-16位新密码",
                  maxlength: "16",
                },
                on: {
                  input: function ($event) {
                    return _vm.filterSpace($event, "confirmNewPassword")
                  },
                },
                model: {
                  value: _vm.editForm.confirmNewPassword,
                  callback: function ($$v) {
                    _vm.$set(_vm.editForm, "confirmNewPassword", $$v)
                  },
                  expression: "editForm.confirmNewPassword",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.save },
            },
            [_vm._v(" 确 定 ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }