var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            width: "50%",
            visible: _vm.visibility,
            "before-close": _vm.handleClose,
            "close-on-click-modal": false,
            "append-to-body": "",
          },
        },
        [
          _c("template", { slot: "title" }, [_vm._v(_vm._s(_vm.otherTitle))]),
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "类型", prop: "type" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.ruleForm.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "type", $$v)
                        },
                        expression: "ruleForm.type",
                      },
                    },
                    _vm._l(_vm.receptionType, function (type) {
                      return _c("el-option", {
                        key: type.value,
                        attrs: { value: type.value, label: type.label },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "故障现象", prop: "wzAppearanceName" } },
                [
                  _c("el-autocomplete", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      "fetch-suggestions": _vm.remoteMethod,
                      placeholder: "请输入故障现象",
                      "trigger-on-focus": false,
                      disabled: !!_vm.clickAppearanceName,
                    },
                    on: { select: _vm.handleSelect },
                    model: {
                      value: _vm.ruleForm.wzAppearanceName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "wzAppearanceName", $$v)
                      },
                      expression: "ruleForm.wzAppearanceName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "故障原因", prop: "wzCauseName" } },
                [
                  _c("el-autocomplete", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      "fetch-suggestions": _vm.remoteMethodCause,
                      placeholder: "请输入故障原因",
                      "trigger-on-focus": false,
                      disabled: !!_vm.clickCauseName,
                    },
                    on: { select: _vm.handleChange },
                    model: {
                      value: _vm.ruleForm.wzCauseName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "wzCauseName", $$v)
                      },
                      expression: "ruleForm.wzCauseName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "维修方案", prop: "wzServiceId" } },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: { placement: "bottom", trigger: "click" },
                      model: {
                        value: _vm.selectServicePopoverVisible,
                        callback: function ($$v) {
                          _vm.selectServicePopoverVisible = $$v
                        },
                        expression: "selectServicePopoverVisible",
                      },
                    },
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.isLoading,
                              expression: "isLoading",
                            },
                          ],
                          staticClass: "customer-table",
                          attrs: {
                            data: _vm.serviceList,
                            "max-height": 400,
                            border: "",
                          },
                          on: { "current-change": _vm.handleCurrentChange },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              type: "index",
                              label: "序号",
                              width: "60",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              width: "180",
                              prop: "name",
                              label: "服务项目",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              width: "120",
                              prop: "serviceItemCode",
                              label: "服务编码",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.hasMore
                        ? _c(
                            "div",
                            {
                              staticClass: "load-more-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.getServiceData(false, true)
                                },
                              },
                            },
                            [_vm._v(" 加载更多数据 ")]
                          )
                        : _vm._e(),
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          slot: "reference",
                          size: "small",
                          placeholder: "请搜索服务项目",
                          clearable: "",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.getServiceData(true)
                          },
                          clear: _vm.handleClear,
                        },
                        slot: "reference",
                        model: {
                          value: _vm.ruleForm.wzServiceName,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "wzServiceName", $$v)
                          },
                          expression: "ruleForm.wzServiceName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "所属系统", prop: "wzSysId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.ruleForm.wzSysId,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "wzSysId", $$v)
                        },
                        expression: "ruleForm.wzSysId",
                      },
                    },
                    _vm._l(_vm.owningSystem, function (type) {
                      return _c("el-option", {
                        key: type.value,
                        attrs: { value: type.sysId, label: type.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "适用车型", prop: "isGeneral" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.isGeneral,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "isGeneral", $$v)
                        },
                        expression: "ruleForm.isGeneral",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("通用")]),
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("部分车型适用"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.ruleForm.isGeneral === 0
                ? _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            remote: "",
                            clearable: "",
                            placeholder: "搜索车型",
                            "remote-method": _vm.searchModel,
                            loading: _vm.searchModelLoading,
                          },
                          on: { clear: _vm.clearCarModel },
                          model: {
                            value: _vm.ruleForm.carName,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "carName", $$v)
                            },
                            expression: "ruleForm.carName",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "itemStyle" },
                            _vm._l(_vm.models, function (item) {
                              return _c(
                                "el-option",
                                {
                                  key: _vm.$lodash.get(item, "series.id"),
                                  attrs: {
                                    value: _vm.$lodash.get(
                                      item,
                                      "series.name",
                                      _vm.$lodash.get(
                                        item,
                                        "brand.name",
                                        _vm.$lodash.get(item, "logo.name")
                                      )
                                    ),
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.changeCarModel(item)
                                    },
                                  },
                                },
                                [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(item.markedRedName),
                                    },
                                  }),
                                ]
                              )
                            }),
                            1
                          ),
                        ]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "text_operation blue",
                          staticStyle: { "margin-left": "10px" },
                          on: {
                            click: function ($event) {
                              _vm.showCarModelsDialog = true
                            },
                          },
                        },
                        [_vm._v("选择车型")]
                      ),
                      _vm._l(_vm.carList, function (item, index) {
                        return _c("div", { key: index }, [
                          _c("i", {
                            staticClass:
                              "iconfont grow-icon_delete3 icon_delete pointer orange",
                            staticStyle: { "margin-right": "5px" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteCar(index)
                              },
                            },
                          }),
                          _c("span", [
                            _vm._v(_vm._s(item.modelDetailSeriesName)),
                          ]),
                        ])
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("template", { slot: "footer" }, [
            _c("div", { staticClass: "flex-x-between" }, [
              _vm.showContent || _vm.editChange
                ? _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "auth",
                          rawName: "v-auth",
                          value: 559,
                          expression: "559",
                        },
                      ],
                      staticClass: "red pointer",
                      staticStyle: { "margin-top": "5px" },
                      on: { click: _vm.detele },
                    },
                    [_vm._v(" 删除 ")]
                  )
                : _vm._e(),
              _c("p"),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: { click: _vm.handleClose },
                    },
                    [_vm._v("取 消")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.promptDialog,
            width: "25%",
            top: "15%",
            "before-close": _vm.handleClosePrompt,
            "close-on-click-modal": false,
            modal: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.promptDialog = $event
            },
          },
        },
        [
          _c("p", [
            _vm._v(
              " 系统已存在“" +
                _vm._s(_vm.promptTitle) +
                "”问诊模型，无须重复新增。如需修改请点击“去编辑” "
            ),
          ]),
          _c("template", { slot: "footer" }, [
            _c(
              "div",
              { staticStyle: { "text-align": "center" } },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small" },
                    on: {
                      click: function ($event) {
                        _vm.promptDialog = false
                      },
                    },
                  },
                  [_vm._v("取 消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: { click: _vm.edit },
                  },
                  [_vm._v("去编辑")]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _vm.showCarModelsDialog
        ? _c("car-models-dialog", {
            attrs: { showCarModelsDialog: _vm.showCarModelsDialog },
            on: {
              "update:showCarModelsDialog": function ($event) {
                _vm.showCarModelsDialog = $event
              },
              "update:show-car-models-dialog": function ($event) {
                _vm.showCarModelsDialog = $event
              },
              carModel: _vm.selectCarModel,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }