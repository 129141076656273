var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      ref: "popover",
      attrs: {
        placement: "top-start",
        trigger: "manual",
        "popper-class": "popper-class",
      },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
          staticClass: "data-table",
          attrs: { "max-height": 400, border: "", data: _vm.listData },
          on: { "row-click": _vm.handleClick },
        },
        [
          _c("el-table-column", {
            attrs: { type: "index", width: "60", label: "序号" },
          }),
          _c("el-table-column", {
            attrs: { width: "120", label: "所属门店", prop: "companyName" },
          }),
          _c("el-table-column", {
            attrs: { width: "120", label: "员工姓名", prop: "realName" },
          }),
          _c("el-table-column", {
            attrs: { width: "120", label: "手机号码", prop: "phone" },
          }),
          _c("template", { slot: "append" }, [
            _vm.hasMore
              ? _c(
                  "div",
                  {
                    staticClass: "load-more-btn",
                    on: {
                      click: function ($event) {
                        return _vm.getData(false, true)
                      },
                    },
                  },
                  [_vm._v(" 加载更多数据 ")]
                )
              : _vm._e(),
          ]),
        ],
        2
      ),
      _c(
        "template",
        { slot: "reference" },
        [
          _c(
            "el-input",
            {
              ref: "keyWordInput",
              staticStyle: { width: "200px" },
              attrs: {
                size: "small",
                placeholder: "搜索姓名、手机号",
                clearable: _vm.clearable,
              },
              on: {
                input: function ($event) {
                  return _vm.getData(true)
                },
                focus: function ($event) {
                  _vm.listData.length && (_vm.visible = true)
                },
                clear: _vm.handleClear,
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.getData(true)
                },
              },
              model: {
                value: _vm.keyWord,
                callback: function ($$v) {
                  _vm.keyWord = $$v
                },
                expression: "keyWord",
              },
            },
            [
              _c("i", {
                staticClass: "el-input__icon el-icon-search pointer",
                attrs: { slot: "suffix" },
                on: {
                  click: function ($event) {
                    return _vm.getData(true)
                  },
                },
                slot: "suffix",
              }),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }