var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "box" },
      [
        _c(
          "el-descriptions",
          { attrs: { column: 3, border: "", labelClassName: "table-label" } },
          [
            _c("el-descriptions-item", { attrs: { label: "车牌" } }, [
              _vm._v(_vm._s(_vm.detailData.carNumber) + " "),
            ]),
            _c("el-descriptions-item", { attrs: { label: "检车人" } }, [
              _vm._v(_vm._s(_vm.detailData.name) + " "),
            ]),
            _c("el-descriptions-item", { attrs: { label: "车型" } }, [
              _vm._v(_vm._s(_vm.detailData.automobileBrand) + " "),
            ]),
            _c("el-descriptions-item", { attrs: { label: "进店时间" } }, [
              _vm._v(_vm._s(_vm.detailData.time) + " "),
            ]),
            _c("el-descriptions-item", { attrs: { label: "进店里程" } }, [
              _vm._v(_vm._s(_vm.detailData.mileage) + " "),
            ]),
            _c("el-descriptions-item", { attrs: { label: "操作门店" } }, [
              _vm._v(_vm._s(_vm.detailData.storeName) + " "),
            ]),
            _c("el-descriptions-item", { attrs: { label: "检车结论" } }, [
              _vm._v(_vm._s(_vm.detailData.note) + " "),
            ]),
          ],
          1
        ),
        _vm._m(0),
        _c("div", { staticClass: "border-table" }, [
          _vm._m(1),
          _c(
            "div",
            { staticClass: "right" },
            [
              _c(
                "div",
                { staticClass: "imgs" },
                _vm._l(_vm.imgs, function (img, index) {
                  return _c("img", {
                    key: img,
                    attrs: { src: img, alt: "" },
                    on: {
                      click: function ($event) {
                        return _vm.previewImage(_vm.imgs, index)
                      },
                    },
                  })
                }),
                0
              ),
              _c(
                "el-collapse",
                [
                  _c(
                    "el-collapse-item",
                    [
                      _c("template", { slot: "title" }, [
                        _c("div", { staticClass: "collapse-item_label" }, [
                          _c("div", [_vm._v("1、更换刹车片")]),
                          _c(
                            "div",
                            [
                              _vm._v(" 工时X5 "),
                              _c(
                                "el-tag",
                                { attrs: { size: "mini", type: "warning" } },
                                [_vm._v("vip")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "fix-detail" }, [
                        _c("p", { staticClass: "flex-x-between" }, [
                          _c("span", [_vm._v("· 刹车片")]),
                          _c("span", [_vm._v("X5")]),
                        ]),
                        _c("p", { staticClass: "flex-x-between" }, [
                          _c("span", [_vm._v("· 螺母")]),
                          _c("span", [_vm._v("X5")]),
                        ]),
                        _c("p", [_vm._v("施工技师：张三（技师所在的班组）")]),
                        _c("p", [
                          _vm._v(
                            " 项目外部便签与备注：xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx "
                          ),
                        ]),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "el-collapse-item",
                    [
                      _c("template", { slot: "title" }, [
                        _c("div", { staticClass: "collapse-item_label" }, [
                          _c("div", [_vm._v("2、洗车")]),
                          _c("div", [_vm._v("工时X5")]),
                        ]),
                      ]),
                      _c("div", { staticClass: "fix-detail" }, [
                        _c("p", { staticClass: "flex-x-between" }, [
                          _c("span", [_vm._v("· 刹车片")]),
                          _c("span", [_vm._v("X5")]),
                        ]),
                        _c("p", { staticClass: "flex-x-between" }, [
                          _c("span", [_vm._v("· 螺母")]),
                          _c("span", [_vm._v("X5")]),
                        ]),
                        _c("p", [_vm._v("施工技师：张三（技师所在的班组）")]),
                        _c("p", [
                          _vm._v(
                            " 项目外部便签与备注：xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx "
                          ),
                        ]),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "border-table" }, [
          _vm._m(2),
          _c("div", { staticClass: "right" }, [
            _c(
              "div",
              { staticClass: "imgs" },
              _vm._l(_vm.imgs, function (img, index) {
                return _c("img", {
                  key: img,
                  attrs: { src: img, alt: "" },
                  on: {
                    click: function ($event) {
                      return _vm.previewImage(_vm.imgs, index)
                    },
                  },
                })
              }),
              0
            ),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "problem-title" }, [
      _c("h5", [_vm._v("发现问题")]),
      _c("span", { staticClass: "red" }, [_vm._v("(2)")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "left" }, [
      _c("div", [
        _c("h2", [_vm._v("一、空调格")]),
        _c("span", { staticClass: "red" }, [_vm._v("已不制冷")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "left" }, [
      _c("div", [
        _c("h2", [_vm._v("二、轮胎")]),
        _c("span", { staticClass: "red" }, [_vm._v("左前轮爆胎")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }