<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <div class="form-top">
        <el-form :model="form" label-width="100px" size="small">
          <el-row>
            <el-col :span="5">
              <el-form-item>
                <template slot="label">
                  <div>
                    操作门店
                    <el-tooltip
                      effect="light"
                      content="接车单的操作门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)"
                      placement="bottom-start"
                    >
                      <i
                        class="iconfont grow-icon_doubt"
                        style="color: #9a9a9a"
                      ></i>
                    </el-tooltip>
                  </div>
                </template>
                <operateCompanyCascader
                  v-model="form.operateCompanyId"
                ></operateCompanyCascader>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="车牌号">
                <el-input
                  v-model="form.carNumber"
                  placeholder="请输入"
                  clearable
                  @keyup.enter.native="getList"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="车单结账日期">
                <el-date-picker
                  class="w100"
                  v-model="form.times"
                  type="daterange"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="起始时间"
                  end-placeholder="截止时间"
                  value-format="yyyy-MM-dd"
                  :clearable="false"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <div class="el-form-item--small" style="margin-left: 20%">
                <div
                  @click="showMore = !showMore"
                  class="el-form-item__label pointer"
                >
                  更多筛选
                  <i
                    :class="{
                      primary: true,
                      'el-icon-arrow-right': !showMore,
                      'el-icon-arrow-down': showMore,
                    }"
                  ></i>
                </div>
                <div class="flex">
                  <el-button
                    class="btn_search"
                    type="primary"
                    size="small"
                    @click="getList()"
                    >查询</el-button
                  >
                  <el-button class="btn_search" size="small" @click="empty"
                    >置空</el-button
                  >
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="5">
              <el-form-item>
                <template slot="label">
                  <div>
                    消费客户
                    <el-tooltip effect="light" placement="bottom-start">
                      <template slot="content">
                        <p>自费/包干客户：输入客户名称</p>
                        <p>保险公司：输入公司名称、编码</p>
                        <p>三包厂家：输入厂家名称、编码</p>
                      </template>
                      <i
                        class="iconfont grow-icon_doubt"
                        style="color: #9a9a9a"
                      ></i>
                    </el-tooltip>
                  </div>
                </template>
                <el-input
                  v-model="form.consumerNameOrPhoneOrNum"
                  placeholder="请输入"
                  clearable
                  @keyup.enter.native="getList"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 分割线 -->
      <el-divider v-if="showMore"></el-divider>
      <transition name="fade">
        <div v-show="showMore">
          <el-form :model="form" label-width="100px" size="small">
            <el-row>
              <el-col :span="7">
                <el-form-item label="接车进店日期">
                  <el-date-picker
                    class="w100"
                    v-model="form.carTimes"
                    type="daterange"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="起始时间"
                    end-placeholder="截止时间"
                    value-format="yyyy-MM-dd"
                    :clearable="false"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item>
                  <template slot="label">
                    <div class="flex flex-x-center">
                      开票主体
                      <el-tooltip effect="light" placement="bottom-start">
                        <div
                          slot="content"
                          style="
                            color: #666666;
                            font-size: 13px;
                            line-height: 20px;
                            text-indent: 15px;
                          "
                        >
                          开票主体，即为协议客户、保险公司和三包厂家的应收账款<br />
                          的回款管理门店(保险公司的开票主体也是定损主体)。保险公司<br />管理的开票主体，可查已成为保险公司开票
                          主体的全部门店。
                        </div>
                        <i
                          class="iconfont grow-icon_doubt"
                          style="color: #9a9a9a"
                        ></i>
                      </el-tooltip>
                    </div>
                  </template>

                  <el-select
                    v-model="form.invoiceName"
                    @change="selectInvoice"
                    @clear="deleteInvoice"
                    filterable
                    clearable
                    remote
                    placeholder="搜索开票主体名称"
                    :remote-method="searchInvoice"
                    :loading="searchinvoiceLoading"
                  >
                    <el-option
                      v-for="item in invoiceList"
                      :key="item.companyId"
                      :label="item.invoicingEntity"
                      :value="item.companyId"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="车型信息">
                  <el-select
                    v-model="form.carInfo"
                    filterable
                    remote
                    placeholder="搜索车型"
                    :remote-method="searchModel"
                    :loading="searchModelLoading"
                    clearable
                  >
                    <el-option
                      v-for="item in models"
                      :key="$lodash.get(item, 'series.id')"
                      :value="$lodash.get(item, 'series.name')"
                    >
                      <span v-html="item.markedRedName"></span>
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label="支付方式">
                  <el-select
                    v-model="form.payType"
                    filterable
                    placeholder="选择支付方式"
                    clearable
                  >
                    <el-option
                      v-for="item in payType"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="7">
                <el-form-item label="签约客户">
                  <el-input
                    v-model="form.carOwnerOrPhone"
                    placeholder="请输入客户名称"
                    clearable
                    @keyup.enter.native="getList"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="接车单号">
                  <el-input
                    v-model="form.billCode"
                    placeholder="请输入"
                    clearable
                    @keyup.enter.native="getList"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="业务来源">
                  <el-select
                    v-model="form.sourceId"
                    @change="selectBillSource"
                    @clear="deleteBillSource"
                    filterable
                    clearable
                    remote
                    placeholder="搜索业务来源"
                    :remote-method="searchBillSource"
                    :loading="searchBillSourceLoading"
                  >
                    <el-option
                      v-for="item in billSourceList"
                      :key="item.billSourceTypeId"
                      :label="item.name"
                      :value="item.billSourceTypeId"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label="开票状态" v-model="form.hasInvoice">
                  <el-select
                    v-model="form.hasInvoice"
                    filterable
                    placeholder="请选择"
                    clearable
                  >
                    <el-option
                      v-for="item in hasInvoiceType"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="7">
                <el-form-item label="接车员">
                  <el-select
                    v-model="form.receiver"
                    @change="selectAdminJobListUsre"
                    filterable
                    clearable
                    remote
                    placeholder="搜索姓名、手机号"
                    :remote-method="searchAdminJobListUsre"
                    :loading="searchAdminJobListUsrLoading"
                  >
                    <el-option
                      v-for="item in AdminJobListUsrList"
                      :key="item.userId"
                      :label="item.realName"
                      :value="item.userId"
                    >
                      <span
                        style="float: left; color: #8492a6; font-size: 13px"
                        >{{ item.realName }}</span
                      >
                      <span style="float: right">{{ item.phone }}</span>
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="业务员">
                  <el-select
                    size="small"
                    v-model="form.salesmanId"
                    filterable
                    clearable
                    remote
                    placeholder="搜索姓名、手机号"
                    :remote-method="searchClient"
                    :loading="searchClientLoading"
                  >
                    <el-option
                      v-for="item in clientList"
                      :key="item.id"
                      :label="item.clientName"
                      :value="item.id"
                    >
                      <span style="float: left">{{ item.clientName }}</span>
                      <span style="float: right">{{ item.clientPhone }}</span>
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="施工技师">
                  <el-select
                    v-model="form.technician"
                    filterable
                    placeholder="搜索姓名、电话"
                    clearable
                  >
                    <el-option
                      v-for="item in techniciansList"
                      :key="item.userId"
                      :label="item.realName"
                      :value="item.userId"
                    >
                      <span
                        style="float: left; color: #8492a6; font-size: 13px"
                        >{{ item.realName }}</span
                      >
                      <span style="float: right">{{ item.phone }}</span>
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label="施工班组">
                  <el-input
                    placeholder="请输入"
                    @keyup.enter.native="getList"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="7">
                <el-form-item label="结账收款备注">
                  <el-input
                    v-model="form.checkRemark"
                    placeholder="请输入"
                    clearable
                    @keyup.enter.native="getList"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="事故责任">
                  <el-select
                    v-model="form.dutyType"
                    filterable
                    placeholder="搜索事故责任"
                    clearable
                  >
                    <el-option
                      v-for="item in dutyTypeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="车单标签">
                  <div
                    v-if="checkedLabels.length == 0"
                    class="chioce-sty"
                    @click="openLabelDialog()"
                  >
                    <span class="pointer">点击选择</span>
                  </div>
                  <el-popover
                    placement="bottom"
                    width="200"
                    trigger="hover"
                    v-else
                    style="text-align: center"
                  >
                    <div v-for="(item, index) in checkedLabels" :key="index">
                      <div style="color: #666">
                        {{ item.name }}&nbsp;&nbsp;/{{ item.billLabelId }}
                      </div>
                    </div>
                    <div
                      slot="reference"
                      class="chioce-sty"
                      style="position: relative"
                    >
                      <span class="pointer" @click="openLabelDialog()"
                        >已选择({{ checkedLabels.length }})</span
                      >
                      <i
                        class="iconfont grow-a-icon_tabbar_close icon-sty"
                        style="color: #42bbb8"
                        @click="checkedLabels = []"
                      ></i>
                    </div>
                  </el-popover>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </transition>
    </template>
    <!-- 以下是表格 -->
    <template>
      <div class="content-container">
        <div class="btns flex-x-between">
          <div>
            <div class="custom-tabs nopadding">
              <div
                @click="status = -1"
                :class="{
                  'custom-tab-item': true,
                  active: status == -1,
                }"
              >
                全部
              </div>
              <div
                @click="status = 0"
                :class="{
                  'custom-tab-item': true,
                  active: status == 0,
                }"
              >
                自费业务
              </div>
              <div
                @click="status = 1"
                :class="{
                  'custom-tab-item': true,
                  active: status == 1,
                }"
              >
                包干业务
              </div>
              <div
                @click="status = 2"
                :class="{
                  'custom-tab-item': true,
                  active: status == 2,
                }"
              >
                理赔业务
              </div>
              <div
                @click="status = 3"
                :class="{
                  'custom-tab-item': true,
                  active: status == 3,
                }"
              >
                索赔业务
              </div>
            </div>
          </div>
          <div>
            <el-button
              v-auth="160"
              size="small"
              type="text"
              class="blue"
              @click="showExportDialog = true"
              >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
            ></el-button>
          </div>
        </div>

        <div class="content-box">
          <el-table
            :data="carBillList"
            border
            show-summary
            class="data-table"
            :summary-method="getSummaries"
            :header-cell-style="headerCellStyle"
          >
            <el-table-column type="index" label="序号" width="80">
            </el-table-column>
            <el-table-column width="180" label="操作门店" prop="operateCompany">
            </el-table-column>
            <el-table-column min-width="200" label="接车单号">
              <template #default="{ row }">
                <div class="blue pointer" @click="goDetail(row.id)">
                  {{ row.billCode }}
                </div>
                <div>
                  <span v-if="row.billType == 0">
                    {{
                      row.isOthersSelfPayState | dict('isOthersSelfPayState')
                    }}
                  </span>
                  <span v-else>
                    {{ row.billType | dict('carBillType') }}
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column width="100" label="结账时间" prop="checkTime">
            </el-table-column>
            <el-table-column width="100" label="进店时间" prop="intoTime">
            </el-table-column>
            <el-table-column min-width="160" label="车牌号" prop="plateNum">
            </el-table-column>
            <el-table-column min-width="250" label="车型/车架号">
              <template #default="{ row }">
                <div>车型:{{ row.carModel }}</div>
                <div class="color999">VIN:{{ row.carVIN }}</div>
              </template>
            </el-table-column>
            <el-table-column min-width="180" label="签约客户">
              <template #default="{ row }">
                <div v-if="row.carOwner">
                  <div>客户名:{{ row.carOwner.ownerName }}</div>
                  <div class="color999">{{ row.carOwner.ownerPhone }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column width="180" label="消费客户">
              <template #default="{ row }">
                <div v-if="row.consumer">
                  <div>客户名:{{ row.consumer.consumerName }}</div>
                  <div class="color999">
                    {{ row.consumer.consumerPhone }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column min-width="180" label="开票主体">
              <template #default="{ row }">
                {{
                  row.invoiceName
                    .map((i) => {
                      return i;
                    })
                    .join()
                }}
              </template>
            </el-table-column>
            <el-table-column width="140" label="业务来源" prop="businessSource">
            </el-table-column>
            <el-table-column width="140" label="事故责任">
              <template #default="{ row }">
                {{ row.dutyType | dict('dutyType') }}
              </template>
            </el-table-column>
            <el-table-column width="100" label="接车员" prop="receiver">
            </el-table-column>
            <el-table-column width="100" label="业务员" prop="salesman">
            </el-table-column>
            <el-table-column width="100" label="施工技师">
              <template #default="{ row }">
                <span class="pointer" v-if="row.constructionTechnician != null">{{
                  row.constructionTechnician
                    .map((i) => {
                      return i;
                    })
                    .join()
                }}</span>
              </template>
            </el-table-column>
            <el-table-column label="报价信息" style="text-align: center">
              <el-table-column
                width="180"
                label="服务"
                prop="quotedPriceInfo.serviceTotal"
              ></el-table-column>
              <el-table-column
                width="180"
                label="商品"
                prop="quotedPriceInfo.goodTotal"
              ></el-table-column>
              <el-table-column
                width="180"
                label="其他"
                prop="quotedPriceInfo.otherTotal"
              ></el-table-column>
            </el-table-column>
            <el-table-column width="100" label="报价合计" prop="billTotal">
            </el-table-column>
            <el-table-column width="180" label="整单折扣优惠" prop="discount">
            </el-table-column>
            <el-table-column width="100" prop="billTotal">
              <template slot="header">
                <div class="flex flex-x-center">
                  单据金额
                  <el-tooltip effect="light" placement="bottom-start">
                    <div
                      slot="content"
                      style="
                        color: #666666;
                        font-size: 13px;
                        line-height: 20px;
                        text-indent: 15px;
                      "
                    >
                      单据金额=报价金额-整单折扣优惠
                    </div>
                    <i
                      class="iconfont grow-icon_doubt"
                      style="color: #9a9a9a; margin-left: 5px"
                    ></i>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
            <el-table-column width="100" prop="contractTotal">
              <template slot="header">
                <div class="flex flex-x-center">
                  包干金额
                  <el-tooltip effect="light" placement="bottom-start">
                    <div
                      slot="content"
                      style="
                        color: #666666;
                        font-size: 13px;
                        line-height: 20px;
                        text-indent: 15px;
                      "
                    >
                      <p>
                        包干业务才有包干金额，结账当月显示为查询时点的预分摊金额，
                      </p>
                      <p>结账次月显示为实际分摊金额</p>
                    </div>
                    <i
                      class="iconfont grow-icon_doubt"
                      style="color: #9a9a9a; margin-left: 5px"
                    ></i>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
            <el-table-column width="100" label="优惠券" prop="discountCoupon">
            </el-table-column>
            <el-table-column width="100" label="免账" prop="freeAccount">
            </el-table-column>
            <el-table-column width="100" prop="moneyReceived">
              <template slot="header">
                <div class="flex flex-x-center">
                  实收金额
                  <el-tooltip effect="light" placement="bottom-start">
                    <div
                      slot="content"
                      style="
                        color: #666666;
                        font-size: 13px;
                        line-height: 20px;
                        text-indent: 15px;
                      "
                    >
                      <p>实收金额=单据金额-优惠券-免账；</p>
                      <p>包干业务的实收金额=包干金额-优惠券-免账</p>
                    </div>
                    <i
                      class="iconfont grow-icon_doubt"
                      style="color: #9a9a9a; margin-left: 5px"
                    ></i>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="实收明细" style="text-align: center">
              <el-table-column
                width="180"
                label="定金抵扣"
                prop="receiverDetail.depositDeduction"
              ></el-table-column>
              <el-table-column
                width="180"
                label="分红抵扣"
                prop="receiverDetail.bonusDeduction"
              ></el-table-column>
              <el-table-column
                width="180"
                label="股本抵扣"
                prop="receiverDetail.capitalDeduction"
              ></el-table-column>
              <el-table-column
                width="180"
                label="现金"
                prop="receiverDetail.cash"
              ></el-table-column>
              <el-table-column
                width="180"
                label="会员卡"
                prop="receiverDetail.memberCard"
              ></el-table-column>
              <el-table-column
                width="180"
                label="挂账"
                prop="receiverDetail.hang"
              ></el-table-column>
              <el-table-column
                width="180"
                label="微信"
                prop="receiverDetail.wechat"
              ></el-table-column>
              <el-table-column
                width="180"
                label="本时段发生过的其他支付方式"
                prop="receiverDetail.total"
              ></el-table-column>
            </el-table-column>
            <el-table-column
              min-width="120"
              label="材料成本合计"
              prop="materialCost"
            >
            </el-table-column>
            <el-table-column width="100" prop="marginTotal">
              <template slot="header">
                <div class="flex flex-x-center">
                  整单毛利
                  <el-tooltip effect="light" placement="bottom-start">
                    <div
                      slot="content"
                      style="
                        color: #666666;
                        font-size: 13px;
                        line-height: 20px;
                        text-indent: 15px;
                      "
                    >
                      <p>整单毛利=实收金额-材料成本合计</p>
                      <p>整单毛利不含卡内项目的金额(已在开卡或充值时收取)</p>
                    </div>
                    <i
                      class="iconfont grow-icon_doubt"
                      style="color: #9a9a9a; margin-left: 5px"
                    ></i>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
            <el-table-column width="150" label="结算人" prop="cashier">
            </el-table-column>
            <el-table-column width="100" label="开票状态">
              <template #default="{ row }">
                <p v-if="row.invoiceNum">
                  <span class="blue">{{ row.invoiceNum }}</span
                  ><br />
                  <span>已开票</span>
                </p>
                <p v-else>未开票</p>
              </template>
            </el-table-column>
            <el-table-column
              width="180"
              label="(结账)收账备注"
              show-overflow-tooltip
            >
              <template #default="{ row }">
                <span class="pointer text_operation">
                  {{ row.checkRemark }}</span
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <export-dialog
        :visibility.sync="showExportDialog"
        :total="total"
        :pageOption="pageOption"
        :filterForm="form"
        :url="exportUrl"
      ></export-dialog>
    </template>

    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>

    <!-- 选择车单标签公用-->
    <el-dialog :visible.sync="labelDialogVisible" width="40%">
      <template slot="title">
        <div class="bill-dialog">
          选择项目标签<span>（可双击直接单选，或单击勾选再确定选择）</span>
        </div>
      </template>
      <div class="billLabel">
        <div class="artisan-list">
          <div
            v-for="label in labelList"
            :key="label.id"
            :class="{ label: true, active: label.checked }"
            @click="handleArtisanChecked(label)"
            @dblclick="confirmLabel(label)"
          >
            {{ label.name }}
          </div>
        </div>
      </div>
      <div class="chekced">
        <span>已选择：</span>
        <span class="primary">{{
          checkedLabels.map((item) => item.name).join('、')
        }}</span>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="labelDialogVisible = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="confirmLabel()"
          style="margin-right: 60px"
          size="small"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </base-container>
</template>
<script>
import { searchClient } from '@/api/customer/customer';
// 分页组件
import pagination from '@/components/Pagination.vue';
import ExportDialog from '@/components/ExportDialog';
import { downloadFile } from '@/utils/fileApi';
import { mapState } from 'vuex';
// 工具
import Big from 'big.js';
//接口
import {
  getBillDetailOnAccountList,
  searchAdminJobListUsr,
  exportBusinessBillDetailOnAccountListUrl,
} from '@/api/financial/financialOver';
import { getBillSourceTypeList } from '@/api/billSource';
import { searchCarModel } from '@/api/customer/car';
import { searchInvoice } from '@/api/storeManagement/index';

import { getLabelList as getLabelListApi } from '@/api/label';

import { getTechniciansList } from '@/api/employee';
import operateCompanyCascader from '@/components/businessComponent/operateCompanyCascader';

export default {
  name: 'invoiciDetailsList',
  components: { pagination, ExportDialog, operateCompanyCascader },
  data() {
    return {
      form: {
        times: [],
        carTimes: [],
      },
      // 展示更多筛选
      showMore: false,
      //导出的页面设置
      exportUrl: exportBusinessBillDetailOnAccountListUrl,
      //分页组件
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      showExportDialog: false,
      status: '-1',
      // 车单列表数据
      carBillList: [],
      //搜索开票主体门店字段
      searchinvoiceLoading: false,
      invoiceList: [],
      //搜索车型信息列表字段
      searchModelLoading: false,
      models: [],
      //搜索业务来源字段
      searchBillSourceLoading: false,
      billSourceList: [],
      //搜索接车员列表字段
      searchAdminJobListUsrLoading: false,
      AdminJobListUsrList: [],
      //支付方式字段
      payType: [
        {
          value: 0,
          label: '现金',
        },
        {
          value: 1,
          label: '支付宝',
        },
        {
          value: 2,
          label: '微信',
        },
        {
          value: 3,
          label: '其他',
        },
        {
          value: 4,
          label: '会员卡',
        },
        {
          value: 5,
          label: '挂账',
        },
        {
          value: 6,
          label: '免账',
        },
      ],
      //开票状态
      hasInvoiceType: [
        {
          value: 0,
          label: '未开票',
        },
        {
          value: 1,
          label: '已开票',
        },
      ],
      selectKey: 1,
      //事故责任字段
      dutyTypeList: [
        {
          value: 0,
          label: '全责',
        },
        {
          value: 1,
          label: '无责',
        },
        {
          value: 2,
          label: '主责',
        },
        {
          value: 3,
          label: '次责',
        },
        {
          value: 4,
          label: '同责',
        },
      ],
      //业务员
      clientList: [],
      searchClientLoading: false,
      //施工技师字段
      techniciansList: [],
      //车单标签弹窗可见性：
      labelDialogVisible: false,
      //选中车单
      checkedLabels: [],
      // 车单标签列表
      labelList: [],
      labelPageSize: 10,
      labelPageCurrent: 1,
      labelHasMore: true,
    };
  },
  watch: {
    status() {
      this.getList();
    },
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  created() {
    this.form.operateCompanyId = [this.companyId];
    this.pickTimes();
    this.searchInvoice();
    // this.searchModel();
    // this.searchBillSource();
    // this.searchAdminJobListUsre();
    this.getList();
    //获取施工技师接口
    getTechniciansList(this.companyId).then((res) => {
      console.log(res);
      this.techniciansList = res;
    });
  },
  mounted() {},
  methods: {
    // 置空输入框数据
    empty() {
      this.form = {};
      this.form.operateCompanyId = [];
      this.checkedLabels = [];
      this.pickTimes();
    },
    // 更改页码
    changePage(e) {
      this.pageOption = e;
      this.getList(false);
    },
    headerCellStyle({ column: { label } }) {
      if (label == '报价信息' || label == '实收明细') {
        return 'text-align:center';
      }
    },
    doHandleZero(zero) {
      var date = zero;
      if (zero.toString().length == 1) {
        date = '0' + zero;
      }
      return date;
    },
    //设置查询日期的默认时间
    pickTimes() {
      this.form.times = [
        new Date().Format('yyyy-MM-dd'),
        new Date().Format('yyyy-MM-dd'),
      ];
    },
    //远程搜索开票主体门店列表
    searchInvoice(keyWord = '') {
      this.searchinvoiceLoading = true;
      searchInvoice(keyWord, this.companyId)
        .then((res) => {
          this.invoiceList = res;
        })
        .finally(() => {
          this.searchinvoiceLoading = false;
        });
    },
    // 选择开票主体门店
    selectInvoice(invoiceId) {},
    //删除开票主体门店
    deleteInvoice() {},
    //远程搜索车型信息列表
    searchModel(key = '') {
      searchCarModel(key).then((res) => {
        this.models = res.records || [];
      });
    },
    //远程搜索业务来源列表
    searchBillSource(keyWord = '') {
      this.searchBillSourceLoading = true;
      getBillSourceTypeList({ name: keyWord })
        .then((res) => {
          this.billSourceList = res.records;
        })
        .finally(() => {
          this.searchBillSourceLoading = false;
        });
    },
    // 选择业务来源
    selectBillSource(billSourceId) {},
    //删除业务来源
    deleteBillSource() {},
    //远程搜索接车员列表
    searchAdminJobListUsre(keyWord = '') {
      this.searchAdminJobListUsrLoading = true;
      searchAdminJobListUsr(keyWord, this.companyId)
        .then((res) => {
          this.AdminJobListUsrList = res;
        })
        .finally(() => {
          this.searchAdminJobListUsrLoading = false;
        });
    },
    //选中接车员
    selectAdminJobListUsre() {},
    //获取数据
    getList(reset = true) {
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      var alltimes = {
        checkStartTime: this.form.times?.[0]
          ? this.form.times?.[0] + ' 00:00:00'
          : '',
        checkEndTime: this.form.times?.[1]
          ? this.form.times?.[1] + ' 23:59:59'
          : '',
        intoStartDate: this.form.carTimes?.[0]
          ? this.form.carTimes?.[0] + ' 00:00:00'
          : '',
        intoEndDate: this.form.carTimes?.[1]
          ? this.form.carTimes?.[1] + ' 23:59:59'
          : '',
        billType: this.status == -1 ? '' : this.status,
      };

      var data = Object.assign(this.form, alltimes, this.pageOption);
      getBillDetailOnAccountList(data).then((res) => {
        this.total = res.total;
        this.carBillList = res.records;
        this.carBillList.map((item) => {
          item.amountDocuments = item.billTotal - item.discount;
          item.grossProfit = item.moneyReceived - item.materialCost;
        });
      });
    },

    //表格尾部自定义表格合计
    getSummaries(param) {
      //columns是每列的信息，data是每行的信息
      const { columns, data } = param;
      console.log(param);
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        if (
          [
            'quotedPriceInfo.serviceTotal',
            'quotedPriceInfo.goodTotal',
            'quotedPriceInfo.otherTotal',
            'quoteTotal',
            'discount',
            'billTotal',
            ' amountDocuments',
            'contractTotal',
            'discountCoupon',
            'freeAccount',
            'moneyReceived',
            'receiverDetail.depositDeduction',
            'receiverDetail.bonusDeduction',
            'receiverDetail.capitalDeduction',
            'receiverDetail.cash',
            'receiverDetail.memberCard',
            'receiverDetail.hang',
            'receiverDetail.wechat',
            'receiverDetail.total',
            'grossProfit',
            'materialCost',
            'marginTotal',
            'amountDocuments',
            'amountTotal',
          ].includes(column.property)
        ) {
          sums[index] = data
            .reduce((prev, curr) => {
              const value = Number(this.$lodash.get(curr, column.property, 0));
              return prev + value;
            }, 0)
            .toFixed(2);
          sums[index] += ' ';
        }
      });

      return sums;
    },

    /**
     * 跳转详情页
     */
    goDetail(id) {
      this.$router.push({
        name: 'carBillDetail',
        query: {
          id,
        },
      });
    },
    //标签弹窗选择操作
    handleArtisanChecked(label) {
      this.$set(label, 'checked', !label.checked);
      if (label.checked) {
        this.checkedLabels.push(label);
      } else {
        const index = this.checkedLabels.findIndex(
          (item) => item.id == label.id
        );
        if (index !== undefined) {
          this.checkedLabels.splice(index, 1);
        }
      }
    },
    confirmLabel(label) {
      if (label) {
        this.$set(label, 'checked', true);
        this.checkedLabels.push(label);
      }
      this.labelDialogVisible = false;
    },
    openLabelDialog() {
      this.getLabelList();
      this.labelDialogVisible = true;
    },
    /**
     * 获取标签列表
     */
    getLabelList() {
      getLabelListApi({
        size: this.labelPageSize,
        current: this.labelPageCurrent,
        labelType: 1, //标签类型(0-车单标签;1-项目标签)
        // isShare: true, //总店用，是否共享到全部分店
        isActive: true, //是否启用
      }).then((res) => {
        // this.labelList.push(...res.records);
        this.labelList = res.records;
        this.labelHasMore = this.labelPageCurrent < res.pages;

        //已选有的设置选中状态
        if (this.checkedLabels?.length) {
          var ids = this.checkedLabels.map((item) => item.billLabelId);
          var label = this.labelList.filter((item) =>
            ids.includes(item.billLabelId)
          );
          label.forEach((item) => {
            this.$set(item, 'checked', true);
          });
        }
      });
    },
    /**
     * 远程搜索业务员列表
     */
    searchClient(keyWord) {
      if (keyWord) {
        this.searchClientLoading = true;
        searchClient(keyWord)
          .then((res) => {
            console.log('yewuyuan', res.records);
            this.clientList = res.records;
          })
          .finally(() => {
            this.searchClientLoading = false;
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 30px 30px 0;
  box-sizing: border-box;
}
//修改选择时间框长度
.el-form {
  width: 100%;
  .el-form-item__content > div {
    width: 100%;
  }
}
//设置表格中合计行颜色
::v-deep .el-table__footer-wrapper tbody td.el-table__cell {
  color: $orange !important;
}
//设置头部筛选条件时分割线的上下间距
.el-divider--horizontal {
  margin: 0px 0px 20px !important;
}
//导出列表样式
.export-dialog__body {
  margin: 20px 0;
  text-align: center;
  .export-dialog-page__input {
    display: flex;
    justify-content: center;
    align-items: center;
    .el-input {
      width: 140px;
      margin: 0 15px;
    }
  }
  .export-dialog__tip {
    margin-top: 20px;
  }
}
.chioce-sty {
  width: 76px;
  height: 31px;
  background: rgba(66, 187, 184, 0.1);
  border-radius: 4px;
  font-size: 12px;
  color: #42bbb8;
  text-align: center;
}
.content-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: auto;
}
.custom-tabs {
  display: flex;
  .custom-tab-item {
    padding: 12px 16px;
    border-radius: 4px 4px 0 0;
    border: 1px solid #eee;
    border-bottom: none;
    cursor: pointer;
    &.active,
    &:hover {
      color: $primary;
      background: $bg;
    }
  }
  &.nopadding .custom-tab-item {
    margin-right: 10px;
  }
}
.billLabel {
  height: 440px;
  overflow-y: scroll;
}
.artisan-list {
  margin-left: 5px;
  display: flex;
  flex-wrap: wrap;
  .label {
    margin-top: 10px;
    margin-right: 15px;
    padding: 10px 15px;
    border-radius: 4px;
    border: 1px solid #eee;
    cursor: pointer;
    &.active {
      background: $bg;
      color: $primary;
    }
  }
}
.chioce-sty {
  width: 80px !important;
  height: 31px;
  background: rgba(66, 187, 184, 0.1);
  border-radius: 4px;
  font-size: 12px;
  color: #42bbb8;
  text-align: center;
}
.icon-sty {
  position: absolute;
  top: -8px;
  right: 0px;
}

.content-box {
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  .data-table {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    ::v-deep .el-table__header-wrapper {
      flex-shrink: 0;
    }
    ::v-deep .el-table__body-wrapper {
      flex: 1;
      overflow: auto;
    }
  }
}
</style>
