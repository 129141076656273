<template>
  <el-dialog
    :title="title"
    :visible="visibility"
    width="50%"
    :before-close="handleClose"
    :close-on-click-modal="false"
    append-to-body
  >
    <el-form
      ref="form"
      size="small"
      :model="editForm"
      :rules="rules"
      label-width="100px"
      style="margin-top: 18px"
    >
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="挂账金额：" prop="pendingAmount">
            {{ editForm.pendingAmount }}
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="账单确认：" prop="billConfirm">
            <el-radio-group v-model="editForm.billConfirm" class="ml-4">
              <el-radio :label="true" size="large">是</el-radio>
              <el-radio :label="false" size="large">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="infoVo.includeBadDebts === true">
        <el-col :span="12">
          <el-form-item label="坏账金额：" prop="badDebtsAmount">
            {{ editForm.badDebtsAmount }}
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="infoVo.includeBadDebts === true">
        <el-col :span="12">
          <el-form-item label="坏账审核：" prop="badDebtsConfirm">
            <el-radio-group v-model="editForm.badDebtsConfirm" class="ml-4">
              <el-radio :label="true" size="large">是</el-radio>
              <el-radio :label="false" size="large">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="坏账处理人：" prop="badDebtsUserId">
            <el-select
              v-model="editForm.badDebtsUserId"
              filterable
              clearable
              remote
              placeholder="搜索姓名、手机号"
              :remote-method="searchAllUser"
              :loading="searchAllUserLoading"
              @select="doVerify('badDebtsUserId')"
            >
              <el-option
                v-for="item in allUserList"
                :key="item.userId"
                :label="item.realName"
                :value="item.userId"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="当次留言：" prop="message">
            <el-input
              type="textarea"
              v-model="editForm.message"
              maxlength="300"
              show-word-limit
              placeholder="请输入当次留言，最多300个字符"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="图片" prop="feeListPicA">
            <span style="color: #909399; font-size: 12px"
              >支持上传 10 张图片，建议每张图片大小不超过 2 MB</span
            >

            <div>
              <upload-file
                v-model="editForm.runningLicenceImg"
                title="行驶证"
              ></upload-file>
              <upload-file
                v-model="editForm.picDrivingLicence"
                title="驾驶证"
              ></upload-file>
              <upload-file
                v-model="editForm.feeListPicA"
                title="定损单1"
                :requiredIcon="type >= 8"
              >
              </upload-file>
              <upload-file
                v-model="editForm.feeListPicB"
                title="定损单2"
              ></upload-file>
              <upload-file
                v-model="editForm.feeListPicC"
                title="定损单3"
              ></upload-file>
              <upload-file-list
                :max="5"
                v-model="editForm.feeListPicOthersList"
              ></upload-file-list>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="附件" prop="attachment">
            <span style="color: #909399; font-size: 12px"
              >只支持上传zip文件，最多5个</span
            >
            <div class="flex">
              <ul style="max-width: 300px">
                <li v-for="(item, i) in attachmentList" :key="i">
                  <i
                    class="iconfont grow-icon_delete3 orange el-icon--left pointer"
                    @click="handleDelete(i)"
                  ></i>
                  <span>{{ item.name }}</span>
                </li>
              </ul>
              <div style="margin-left: 20px" v-if="attachmentList.length < 5">
                <el-button size="small" @click="chooseFile">选择文件</el-button>
              </div>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <template #footer>
      <el-button size="small" type="primary" @click="save"> 确 定 </el-button>
      <el-button size="small" @click="handleClose"> 取 消 </el-button>
    </template>
  </el-dialog>
</template>

<script>
// 字典数据
import {
  followUpStateList,
  recoveryState,
  investigationState,
} from '@/service/dict/dictData';

import { getAllUser } from '@/api/employee';
import {
  updateCarLossDeterminationBill,
  auditingSave,
  uploadZipFile,
} from '@/api/carBill/carLossDeterminationBill';

import UploadFile from '@/components/uploadFile.vue';
import uploadFileList from '@/components/uploadFileList';
import { chooseFile } from '@/utils/fileApi';

export default {
  name: 'AuditingEditDialog',
  components: { UploadFile, uploadFileList },
  props: {
    visibility: Boolean,
    id: Number,
    type: Number,
    title: {
      type: String,
      default: '编辑',
    },
    billVoData: {
      type: Object,
      default: () => ({}),
    },
    carVoData: {
      type: Object,
      default: () => ({}),
    },
    infoVoData: {
      type: Object,
      default: () => ({}),
    },
    picVoData: {
      type: Object,
      default: () => ({}),
    },
    form: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      // 表单信息
      editForm: {
        billConfirm: false,
        badDebtsConfirm: false,
        badDebtsUserId: null,
        message: '',
      },

      // 车单信息
      billVo: {},
      // 车辆信息
      carVo: {},
      // 定损单信息
      infoVo: {},
      // 图片信息
      picVo: {},
      attachmentList: [],
      searchAllUserLoading: false,
      searchUserTimer: null,
      allUserList: [],
    };
  },
  computed: {
    // 表单规则
    rules() {
      const part1 = {
        billConfirm: [
          { required: true, message: '请选择账单是否确认', trigger: 'blur' },
        ],
      };
      if (this.infoVo.includeBadDebts === true) {
        part1.badDebtsConfirm = [
          { required: true, message: '请选择坏账审核通过', trigger: 'blur' },
        ];
      }
      const part2 = {};
      if (
        this.editForm.badDebtsConfirm &&
        this.editForm.badDebtsConfirm === true
      ) {
        part2.badDebtsUserId = [
          { required: true, message: '请选择坏账处理人' },
        ];
      }
      return Object.assign({}, part1, part2);
    },
  },
  created() {
    this.init();
  },
  methods: {
    /**
     * 搜索坏账处理人
     */
    searchAllUser(msg) {
      if (this.searchUserTimer) {
        clearTimeout(this.searchUserTimer);
      }
      this.searchGoodTimer = setTimeout(() => {
        this.searchAllUserLoading = true;
        let params = {};
        if (typeof msg === 'object') {
          params = msg;
        } else {
          params = { msg };
        }
        getAllUser(params)
          .then((res) => {
            this.allUserList = res.records || [];
          })
          .finally(() => {
            this.searchAllUserLoading = false;
          });
      }, 800);
    },
    /**
     * 校验某个字段
     */
    doVerify(prop) {
      this.$refs.form.validateField(prop);
    },
    init() {
      this.getData();
    },
    getData() {
      this.billVo = this.$lodash.cloneDeep(this.billVoData);
      this.carVo = this.$lodash.cloneDeep(this.carVoData);
      this.infoVo = this.$lodash.cloneDeep(this.infoVoData);
      this.picVo = this.$lodash.cloneDeep(this.picVoData);
      this.editForm = { ...this.infoVo, ...this.picVo };
      this.editForm.feeListPicOthersList =
        this.editForm.feeListPicOthersList.join(',');
      if (this.editForm.attachmentUrl) {
        const url = this.editForm.attachmentUrl.split(',').filter((el) => el);
        const name = this.editForm.attachmentName.split(',').filter((el) => el);
        this.attachmentList = url.map((item, index) => {
          return {
            url: item,
            name: name[index],
          };
        });
      }
      // 清空，必须让用户自己选——不管是不是之前编辑回来的
      this.$set(this.editForm, 'billConfirm', null);
      if (this.infoVo.billConfirm === true) {
        this.$set(this.editForm, 'billConfirm', true);
      }
      this.$set(this.editForm, 'badDebtsConfirm', null);
      if (this.infoVo.badDebtsConfirm === false) {
        this.$set(this.editForm, 'badDebtsConfirm', false);
      }
      this.$set(this.editForm, 'badDebtsUserId', null);
      this.$set(this.editForm, 'message', null);
    },
    /**
     * 保存
     */
    save() {
      this.$refs.form.clearValidate();
      this.$refs.form
        .validate()
        .then((result, object) => {
          //处理图片
          const picObj = {
            runningLicenceImg: this.editForm.runningLicenceImg
              ?.split('/')
              .at(-1),
            picDrivingLicence: this.editForm.picDrivingLicence
              ?.split('/')
              .at(-1),
            feeListPicA: this.editForm.feeListPicA?.split('/').at(-1),
            feeListPicB: this.editForm.feeListPicB?.split('/').at(-1),
            feeListPicC: this.editForm.feeListPicC?.split('/').at(-1),
            feeListPicOthers: this.editForm.feeListPicOthersList
              ?.split(',')
              .map((item) => item.split('/').at(-1))
              .join(),
          };
          const attachmentObj = {
            attachmentName: this.attachmentList
              .map((item) => item.name)
              .join(','),
            attachmentUrl: this.attachmentList
              .map((item) => item.url.split('/').at(-1))
              .join(','),
          };
          const body = Object.assign(
            {
              id: this.id,
              billId: this.billVo.billId,
              billConfirm: this.editForm.billConfirm,
              badDebtsConfirm: this.editForm.badDebtsConfirm,
              badDebtsUserId: this.editForm.badDebtsUserId,
              message: this.editForm.message,
            },
            picObj,
            attachmentObj
          );
          auditingSave(body).then((res) => {
            this.$message.success(res.data || '操作成功!');
            this.$emit('save');
            this.handleClose();
          });
        })
        .catch((error) => {
          console.log(error);
          var msg =
            '<p>' +
            Object.values(error)
              .map((el) => el.map((v) => v.message))
              .join('<br />') +
            '</p>';
          this.$message({
            type: 'warning',
            dangerouslyUseHTMLString: true,
            message: msg,
          });
        });
    },

    /**
     * 关闭抽屉
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
    /**
     * 选择文件
     */
    chooseFile() {
      chooseFile({ accept: '.zip', multiple: false }).then((file) => {
        console.log(file[0]);
        // 大于100M不允许上传
        if (file[0].size > 1024 * 1024 * 100) {
          this.$message.error('上传的附件大小不能超过100MB!');
          return;
        }
        uploadZipFile(file[0]).then((res) => {
          this.attachmentList.push({
            url: res.imageUrl,
            name: file[0].name.replace(/,/g, ''),
          });
        });
      });
    },

    /**
     * 删除
     */
    handleDelete(index) {
      this.attachmentList.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item {
  .el-date-editor,
  .el-select {
    width: 100%;
  }
}
</style>
