var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { attrs: { paddingTop: "" } },
    [
      _c(
        "el-descriptions",
        { staticClass: "des", attrs: { column: 3, border: "" } },
        [
          _c("el-descriptions-item", { attrs: { label: "协议编号" } }, [
            _c("div", { staticClass: "flex-x-between" }, [
              _c("div", { staticClass: "flex-col-y-between" }, [
                _c("h2", { staticClass: "car-number" }, [
                  _vm._v(_vm._s(_vm.detailData.contractNumber)),
                ]),
                _c(
                  "div",
                  [
                    _c("el-switch", {
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.detailData.beContract,
                        callback: function ($$v) {
                          _vm.$set(_vm.detailData, "beContract", $$v)
                        },
                        expression: "detailData.beContract",
                      },
                    }),
                    _c("span", { staticClass: "is-responsibility" }, [
                      _vm._v(
                        "（" +
                          _vm._s(_vm.detailData.beContract ? "已" : "未") +
                          "启用包干业务）"
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("i", {
                class: [
                  "icon-state",
                  "iconfont",
                  _vm.detailData.status,
                  _vm.stateDict[_vm.detailData.status],
                ],
              }),
            ]),
          ]),
          _c("el-descriptions-item", { attrs: { label: "签约客户" } }, [
            _c(
              "p",
              {
                staticClass: "pointer blue",
                on: {
                  click: function ($event) {
                    return _vm.goDetail(_vm.detailData.agreeClient.clientId)
                  },
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$lodash.get(_vm.detailData, "agreeClient.name", "")
                    ) +
                    " "
                ),
              ]
            ),
          ]),
          _c("el-descriptions-item", { attrs: { label: "公司全称" } }, [
            _c("p", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$lodash.get(
                      _vm.detailData,
                      "agreeClient.companyFullName",
                      ""
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("el-descriptions-item", { attrs: { label: "有效期限" } }, [
            _vm._v(
              " " +
                _vm._s(_vm._f("delhms")(_vm.detailData.startDate)) +
                " 至 " +
                _vm._s(_vm._f("delhms")(_vm.detailData.endDate))
            ),
          ]),
          _c("el-descriptions-item", { attrs: { label: "签约门店" } }, [
            _vm._v(_vm._s(_vm.detailData.signCompany)),
          ]),
          _c(
            "el-descriptions-item",
            [
              _c(
                "template",
                { slot: "label" },
                [
                  _c(
                    "el-tooltip",
                    { attrs: { effect: "light", placement: "bottom" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "rows-tip",
                          attrs: { slot: "content" },
                          slot: "content",
                        },
                        [
                          _vm._v(
                            " 客户协议的开票主体，即为协议客户的应收账款的回款管理门店 "
                          ),
                        ]
                      ),
                      _c("i", {
                        staticClass: "iconfont grow-icon_doubt",
                        staticStyle: { color: "#9a9a9a" },
                      }),
                    ]
                  ),
                  _vm._v(" 开票主体 "),
                ],
                1
              ),
              _vm._v(" " + _vm._s(_vm.detailData.invoiceCompany)),
            ],
            2
          ),
          _c("el-descriptions-item", { attrs: { label: "回款周期" } }, [
            _c(
              "div",
              { staticClass: "flex-y-center" },
              [
                _vm._v(
                  " " + _vm._s(_vm.detailData.paymentPeriod) + "个自然月 "
                ),
                _c(
                  "el-tooltip",
                  { attrs: { effect: "light", placement: "bottom" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "rows-tip",
                        attrs: { slot: "content" },
                        slot: "content",
                      },
                      [
                        _vm._v(
                          " 消费结账后第" +
                            _vm._s(_vm.detailData.paymentPeriod) +
                            "个自然月月底前回款 "
                        ),
                      ]
                    ),
                    _c("i", {
                      staticClass: "iconfont grow-icon_doubt",
                      staticStyle: { color: "#9a9a9a" },
                    }),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c("el-descriptions-item", { attrs: { label: "开户行" } }, [
            _vm._v(_vm._s(_vm.detailData.bank)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "银行账号" } }, [
            _vm._v(_vm._s(_vm.detailData.bankNum)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "自费" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$lodash.get(
                    _vm.detailData,
                    "settlementDescription.selfPaying",
                    ""
                  )
                ) +
                " "
            ),
          ]),
          _c("el-descriptions-item", { attrs: { label: "包干" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$lodash.get(
                    _vm.detailData,
                    "settlementDescription.contractFor",
                    ""
                  )
                ) +
                " "
            ),
          ]),
          _c("el-descriptions-item", { attrs: { label: "保险理赔" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$lodash.get(
                    _vm.detailData,
                    "settlementDescription.insuranceClaims",
                    ""
                  )
                ) +
                " "
            ),
          ]),
          _c("el-descriptions-item", { attrs: { label: "理赔返点" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$lodash.get(
                    _vm.detailData,
                    "settlementDescription.accidentReturn",
                    ""
                  )
                ) +
                " "
            ),
          ]),
          _c("el-descriptions-item", { attrs: { label: "年审" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$lodash.get(
                    _vm.detailData,
                    "settlementDescription.annualReview",
                    ""
                  )
                ) +
                " "
            ),
          ]),
          _c("el-descriptions-item", { attrs: { label: "保险手续费" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$lodash.get(
                    _vm.detailData,
                    "settlementDescription.insuranceHandlingFees",
                    ""
                  )
                ) +
                " "
            ),
          ]),
          _c("el-descriptions-item", { attrs: { label: "备注", span: 2 } }, [
            _vm._v(_vm._s(_vm.detailData.remark)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "自费挂账设置" } }, [
            _c(
              "div",
              { staticClass: "flex-y-center" },
              [
                _c("el-switch", {
                  attrs: { disabled: "", value: _vm.detailData.beHang },
                }),
                _c("span", { staticClass: "is-responsibility" }, [
                  _vm._v(
                    "（" +
                      _vm._s(
                        _vm.detailData.beHang ? "允许挂账" : "不允许挂账"
                      ) +
                      "）"
                  ),
                ]),
              ],
              1
            ),
          ]),
          _c(
            "el-descriptions-item",
            { attrs: { label: "协议图片", span: 3 } },
            [
              _vm.detailData.pics
                ? _c(
                    "div",
                    { staticClass: "imgs" },
                    _vm._l(
                      _vm.$lodash.get(_vm.detailData, "pics", "").split(","),
                      function (img, index) {
                        return _c("img", {
                          key: index,
                          attrs: { src: _vm.detailData.picPath + img, alt: "" },
                          on: {
                            click: function ($event) {
                              _vm.previewImage(
                                _vm.$lodash
                                  .get(_vm.detailData, "pics", "")
                                  .split(","),
                                index
                              )
                            },
                          },
                        })
                      }
                    ),
                    0
                  )
                : _c("span", { staticClass: "color666" }, [
                    _vm._v("无附件图片"),
                  ]),
            ]
          ),
        ],
        1
      ),
      !(
        !_vm.selfPayingVo.effectiveAsk &&
        !_vm.returnCarVo.effectiveAsk &&
        !_vm.contractForVo.effectiveAsk &&
        !_vm.claimSettlementVo.effectiveAsk
      )
        ? _c("p", { staticClass: "requirement-title" }, [_vm._v(" 时效要求 ")])
        : _vm._e(),
      _c(
        "el-descriptions",
        { staticClass: "des", attrs: { column: 3, border: "" } },
        [
          _vm.selfPayingVo.effectiveAsk
            ? _c(
                "el-descriptions-item",
                {
                  attrs: {
                    label: "自费",
                    span: 3,
                    contentStyle: { width: "89%" },
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$lodash.get(_vm.selfPayingVo, "effectiveAsk", "")
                    )
                  ),
                ]
              )
            : _vm._e(),
          _vm.returnCarVo.effectiveAsk
            ? _c(
                "el-descriptions-item",
                {
                  attrs: {
                    label: "退还车",
                    span: 3,
                    contentStyle: { width: "89%" },
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$lodash.get(_vm.returnCarVo, "effectiveAsk", ""))
                  ),
                ]
              )
            : _vm._e(),
          _vm.contractForVo.effectiveAsk
            ? _c(
                "el-descriptions-item",
                {
                  attrs: {
                    label: "包干",
                    span: 3,
                    contentStyle: { width: "89%" },
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$lodash.get(_vm.contractForVo, "effectiveAsk", "")
                    )
                  ),
                ]
              )
            : _vm._e(),
          _vm.claimSettlementVo.effectiveAsk
            ? _c(
                "el-descriptions-item",
                {
                  attrs: {
                    label: "理赔",
                    span: 3,
                    contentStyle: { width: "89%" },
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$lodash.get(_vm.claimSettlementVo, "effectiveAsk", "")
                    )
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      !(
        !_vm.selfPayingVo.qualityAsk &&
        !_vm.returnCarVo.qualityAsk &&
        !_vm.contractForVo.qualityAsk &&
        !_vm.claimSettlementVo.qualityAsk
      )
        ? _c("p", { staticClass: "requirement-title" }, [_vm._v(" 质量要求 ")])
        : _vm._e(),
      _c(
        "el-descriptions",
        {
          staticClass: "des",
          attrs: {
            column: 3,
            border: "",
            contentStyle: { verticalAlign: "middle" },
          },
        },
        [
          _vm.selfPayingVo.qualityAsk
            ? _c(
                "el-descriptions-item",
                {
                  attrs: {
                    label: "自费",
                    span: 3,
                    contentStyle: { width: "89%" },
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$lodash.get(_vm.selfPayingVo, "qualityAsk", ""))
                  ),
                ]
              )
            : _vm._e(),
          _vm.returnCarVo.qualityAsk
            ? _c(
                "el-descriptions-item",
                {
                  attrs: {
                    label: "退还车",
                    span: 3,
                    contentStyle: { width: "89%" },
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$lodash.get(_vm.returnCarVo, "qualityAsk", ""))
                  ),
                ]
              )
            : _vm._e(),
          _vm.contractForVo.qualityAsk
            ? _c(
                "el-descriptions-item",
                {
                  attrs: {
                    label: "包干",
                    span: 3,
                    contentStyle: { width: "89%" },
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$lodash.get(_vm.contractForVo, "qualityAsk", ""))
                  ),
                ]
              )
            : _vm._e(),
          _vm.claimSettlementVo.qualityAsk
            ? _c(
                "el-descriptions-item",
                {
                  attrs: {
                    label: "理赔",
                    span: 3,
                    contentStyle: { width: "89%" },
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$lodash.get(_vm.claimSettlementVo, "qualityAsk", "")
                    )
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-tabs",
        {
          staticClass: "tabs",
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "自费项目折扣", name: "first" } },
            [
              _c("agreement-price-list", {
                attrs: { id: _vm.$route.query.id },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "包干规则", name: "second" } },
            [
              _vm.initialized
                ? _c("all-in-rule", { attrs: { detail: _vm.detailData } })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "协议适用门店", name: "thirdly" } },
            [
              _vm.initialized
                ? _c("applicable-stores", {
                    attrs: { detail: _vm.detailData, id: _vm.$route.query.id },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          { staticClass: "flex-x-end foot-btn" },
          [
            !["rescissory"].includes(_vm.detailData.status)
              ? _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "auth",
                        rawName: "v-auth",
                        value: 476,
                        expression: "476",
                      },
                    ],
                    attrs: { type: "primary" },
                    on: { click: _vm.edit },
                  },
                  [_vm._v("编辑协议")]
                )
              : _vm._e(),
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 475,
                    expression: "475",
                  },
                ],
                on: { click: _vm.copy },
              },
              [_vm._v("复制协议")]
            ),
            !["overdue", "rescissory"].includes(_vm.detailData.status)
              ? _c(
                  "el-dropdown",
                  {
                    directives: [
                      {
                        name: "auth",
                        rawName: "v-auth",
                        value: 477,
                        expression: "477",
                      },
                    ],
                    attrs: {
                      size: "small",
                      trigger: "click",
                      placement: "top",
                    },
                  },
                  [
                    _c("el-button", [
                      _vm._v(" 更多操作"),
                      _c("i", {
                        staticClass: "el-icon-arrow-down el-icon--right",
                      }),
                    ]),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      [
                        _c(
                          "el-dropdown-item",
                          {
                            attrs: { icon: "el-icon-delete" },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.cancel.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v("作废客户协议")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }