import http from '@/service/http';

// const defaultListOption = {
//   current: 1,
//   size: 10,
// };

// 搜索反馈人信息
export function searchFeedbackUser(keyword) {
  return http.post(
    '/admin/mini-feedback/searchFeedbackUser',
    { keyword },
    {
      noShowWaiting: true,
    }
  );
}

// 导出
export const exportFeedbackUrl = '/admin/mini-feedback/export';

// 获取反馈列表
export function getFeedbackList(data) {
  return http.post('/admin/mini-feedback/list', {
    ...data,
  });
}
