import http from '@/service/http';

const defaultListOption = {
  current: 1,
  size: 10,
};

// 疑似返修记录
export function getRepairDiscoverList(data = defaultListOption) {
  return http.post('/admin/car/bill/repairDiscover/detail', data);
}

// 返修备注
export function updateRepairDiscoverRemark(data = {}) {
  return http.post('/admin/car/bill/repairDiscover/updateRemark', data);
}

// 返修导出地址
export const exportRepairDiscoverListUrl =
  '/admin/car/bill/repairDiscover/export';
