var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "手机号验证",
        visible: _vm.visibility,
        width: "420px",
        "before-close": _vm.handleClose,
        "close-on-click-modal": false,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticStyle: { "margin-top": "18px" },
          attrs: {
            size: "small",
            model: _vm.editForm,
            rules: _vm.rules,
            "label-width": "80px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "手机号", prop: "phoneNumber" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入手机号", maxlength: "11" },
                on: { input: _vm.filterNonDigits },
                model: {
                  value: _vm.editForm.phoneNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.editForm, "phoneNumber", $$v)
                  },
                  expression: "editForm.phoneNumber",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "验证码", prop: "verificationCode" } },
            [
              _c(
                "div",
                { staticClass: "flex" },
                [
                  _c("el-input", {
                    staticStyle: { width: "57%" },
                    attrs: { placeholder: "请输入验证码" },
                    model: {
                      value: _vm.editForm.verificationCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "verificationCode", $$v)
                      },
                      expression: "editForm.verificationCode",
                    },
                  }),
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "43%", "margin-left": "5px" },
                      attrs: {
                        type: "primary",
                        size: "small",
                        disabled: _vm.countdown > 0,
                      },
                      on: { click: _vm.getCode },
                    },
                    [_vm._v(_vm._s(_vm.text))]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.save },
            },
            [_vm._v(" 确 定 ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }