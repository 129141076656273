var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    [
      _c("template", { slot: "header" }),
      [
        _c("div", { staticClass: "header-box" }, [
          _c("div", { staticClass: "row1" }, [
            _c("div", { staticClass: "flex-y-center h100" }, [
              _c("div", { staticClass: "bill-type flex-center" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm._f("dict")(_vm.billType, "carBillType")) +
                    " "
                ),
              ]),
              _vm.id == null
                ? _c(
                    "div",
                    { staticClass: "select-car flex-y-center" },
                    [
                      _c("span", { staticClass: "select-car-label" }, [
                        _vm._v("选择已有车辆"),
                      ]),
                      _c("select-car-for-create-car-bill-popover", {
                        ref: "selectCar",
                        attrs: {
                          searchKeyWord: _vm.carInfo.carNumber,
                          billType: _vm.billType,
                        },
                        on: { clear: _vm.deleteCar, select: _vm.selectCar },
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "auth",
                              rawName: "v-auth",
                              value: 54,
                              expression: "54",
                            },
                          ],
                          staticClass: "or",
                        },
                        [_vm._v("或")]
                      ),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "auth",
                              rawName: "v-auth",
                              value: 54,
                              expression: "54",
                            },
                          ],
                          staticClass: "orange-btn",
                          attrs: { size: "small", type: "text" },
                          on: {
                            click: function ($event) {
                              _vm.addCarDialogVisibility = true
                            },
                          },
                        },
                        [_vm._v("新增")]
                      ),
                    ],
                    1
                  )
                : _c("div", { staticClass: "select-car flex-y-center" }, [
                    _c("span", { staticClass: "select-car-label" }, [
                      _vm._v("接车单号：" + _vm._s(_vm.form.billCode)),
                    ]),
                    _c("i", {
                      class: [
                        "bill-state",
                        "iconfont",
                        "blue",
                        _vm.$lodash.get(
                          _vm.carBillStateIconDict[_vm.form.billState],
                          "label",
                          ""
                        ),
                      ],
                      style: {
                        color: _vm.$lodash.get(
                          _vm.carBillStateIconDict[_vm.form.billState],
                          "color",
                          ""
                        ),
                      },
                    }),
                  ]),
            ]),
            _c("div", { staticClass: "bill-process" }, [
              _c("div", { staticClass: "state-name" }, [
                _c("span", [_vm._v("接待开单")]),
                _c("span", { staticClass: "time" }, [
                  _vm._v(_vm._s(_vm.form.dateCreated)),
                ]),
              ]),
              _c("div", { staticClass: "split-string" }, [
                _c("div", { staticClass: "top-text" }, [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.form.planFinishTime,
                          expression: "form.planFinishTime",
                        },
                      ],
                    },
                    [
                      _vm._v(
                        " 预交车: " + _vm._s(_vm.form.planFinishTime) + " "
                      ),
                    ]
                  ),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.form.waitInStore,
                          expression: "form.waitInStore",
                        },
                      ],
                    },
                    [_vm._v("在店等")]
                  ),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.form.undetermined,
                          expression: "form.undetermined",
                        },
                      ],
                    },
                    [_vm._v("待定")]
                  ),
                ]),
                _c("div", { staticClass: "line" }),
                _c("div", { staticClass: "bottom-text orange" }, [
                  _vm.form.planFinishTime
                    ? _c("span", [_vm._v(_vm._s(_vm.distancePlannedTime))])
                    : _vm._e(),
                ]),
              ]),
              _c("div", { staticClass: "state-name" }, [
                _c("span", [_vm._v("质检交车")]),
                _c("span", { staticClass: "time" }),
              ]),
              _c("div", { staticClass: "split-string" }, [
                _c("div", { staticClass: "top-text plan-time" }),
                _c("div", { staticClass: "line" }),
                _c("div", { staticClass: "bottom-text orange" }),
              ]),
              _c("div", { staticClass: "state-name" }, [
                _c("span", [_vm._v("提交结账")]),
                _c("span", { staticClass: "time" }),
              ]),
              _c("div", { staticClass: "split-string" }, [
                _c("div", { staticClass: "top-text orange" }),
                _c("div", { staticClass: "line" }),
                _c("div", { staticClass: "bottom-text orange" }),
              ]),
              _c("div", { staticClass: "state-name" }, [
                _c("span", [_vm._v("车单结账")]),
                _c("span", { staticClass: "time" }),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "split-line" }),
          _vm.carInfo.carId
            ? _c("div", { staticClass: "row2" }, [
                _c("div", { staticClass: "car-and-client-info-box" }, [
                  _c("div", { staticClass: "car-logo" }, [
                    _vm.carInfo.carLogoIcon
                      ? _c("img", { attrs: { src: _vm.carInfo.carLogoIcon } })
                      : _c("img", {
                          attrs: { src: require("@/assets/img/carLogo.png") },
                        }),
                  ]),
                  _c("div", { staticClass: "client-info" }, [
                    _c("div", { staticClass: "car-info" }, [
                      _c("div", { staticClass: "flex" }, [
                        _c(
                          "span",
                          {
                            staticClass: "car-number pointer",
                            on: { click: _vm.goCarDetail },
                          },
                          [_vm._v(_vm._s(_vm.carInfo.carNumber))]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "car-info-tag",
                            staticStyle: { display: "inline-block" },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "tag history pointer",
                                on: { click: _vm.viewHistory },
                              },
                              [
                                _vm._v(
                                  "历史进店 " +
                                    _vm._s(_vm.carInfo.intoStoresCount) +
                                    " 次"
                                ),
                              ]
                            ),
                            _vm.carInfo.brandName
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "tag car-brand",
                                    staticStyle: { "margin-left": "0px" },
                                  },
                                  [_vm._v(_vm._s(_vm.carInfo.brandName))]
                                )
                              : _vm._e(),
                            _vm.carInfo.isRiskControlCar === 1
                              ? _c(
                                  "span",
                                  { staticClass: "tag riskControlCar" },
                                  [_vm._v("风控车")]
                                )
                              : _vm._e(),
                            _c("div", { staticStyle: { clear: "both" } }),
                            _vm.carInfo.billClientMemberMessageVo !=
                              undefined &&
                            _vm.carInfo.billClientMemberMessageVo
                              .isAgreementClient != undefined &&
                            _vm.carInfo.billClientMemberMessageVo.state !=
                              undefined &&
                            _vm.carInfo.billClientMemberMessageVo
                              .isAgreementClient == 1 &&
                            _vm.carInfo.billClientMemberMessageVo.state == 1
                              ? _c(
                                  "span",
                                  { staticClass: "tag car-Contract" },
                                  [_vm._v("包干车辆")]
                                )
                              : _vm._e(),
                          ]
                        ),
                        _c(
                          "p",
                          {
                            staticClass: "color626 text-ellipsis",
                            staticStyle: {
                              display: "inline-block",
                              width: "100px",
                              "vertical-align": "middle",
                              "flex-grow": "1",
                              "margin-top": "4px",
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.carInfo.carRemark) + " ")]
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "client-base-info flex-x-between" },
                        [
                          _c(
                            "div",
                            { staticClass: "flex" },
                            [
                              _vm.carInfo.clientId
                                ? _c(
                                    "div",
                                    { staticStyle: { "margin-top": "5px" } },
                                    [
                                      [0, 1, 2].includes(
                                        _vm.carInfo.clientLevel
                                      )
                                        ? _c("i", {
                                            staticClass: "iconfont blue",
                                            class:
                                              _vm.levelIcons[
                                                _vm.carInfo.clientLevel
                                              ],
                                            attrs: {
                                              title:
                                                _vm.levelIconsWord[
                                                  _vm.carInfo.clientLevel
                                                ],
                                            },
                                          })
                                        : _vm._e(),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "client-name pointer",
                                          on: { click: _vm.goClientDetail },
                                        },
                                        [_vm._v(_vm._s(_vm.carInfo.clientName))]
                                      ),
                                      _vm.carOwnerSettlementWay
                                        ? _c(
                                            "span",
                                            {
                                              style: {
                                                "margin-left": "5px",
                                                color:
                                                  _vm.carOwnerSettlementWay ===
                                                  "现结"
                                                    ? "#33ab79"
                                                    : "red",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.carOwnerSettlementWay
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "auth",
                                              rawName: "v-auth",
                                              value: 546,
                                              expression: "546",
                                            },
                                          ],
                                          staticClass: "primary pointer",
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.clientCommandDrawerVisibility = true
                                            },
                                          },
                                        },
                                        [_vm._v("客户指令")]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              !_vm.carInfo.clientId
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "font-size": "14px",
                                        display: "inline",
                                        "margin-top": "5px",
                                      },
                                    },
                                    [
                                      _c("span", { staticClass: "color999" }, [
                                        _vm._v("暂未绑定客户"),
                                      ]),
                                      !_vm.id
                                        ? _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "auth",
                                                  rawName: "v-auth",
                                                  value: 63,
                                                  expression: "63",
                                                },
                                              ],
                                              staticClass: "blue pointer",
                                              staticStyle: {
                                                "margin-left": "5px",
                                              },
                                              on: {
                                                click:
                                                  _vm.showBindingCustomerDialog,
                                              },
                                            },
                                            [_vm._v("绑定客户")]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.underWarrantyState == 0
                                ? _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        width: "300",
                                        placement: "bottom",
                                        trigger: "hover",
                                      },
                                    },
                                    [
                                      _c(
                                        "template",
                                        { slot: "reference" },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "warranty-info",
                                              attrs: {
                                                type: "primary",
                                                plain: "",
                                              },
                                            },
                                            [
                                              _vm._v("未知"),
                                              _c("i", {
                                                staticClass:
                                                  "iconfont grow-icon_doubt",
                                                staticStyle: {
                                                  "font-size": "12px",
                                                  "margin-left": "3px",
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.vehicleVo.length == 0 &&
                                      _vm.corePartsVo.length == 0 &&
                                      _vm.wearingPartsVo.length == 0
                                        ? _c("div", [
                                            _c(
                                              "span",
                                              { staticClass: "warranty-title" },
                                              [_vm._v("未知")]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "tips" },
                                              [_vm._v("（暂无该车型质保规则）")]
                                            ),
                                          ])
                                        : _c("div", [
                                            _vm.vehicleVo.length != 0
                                              ? _c("div", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "warranty-title",
                                                    },
                                                    [_vm._v("未知")]
                                                  ),
                                                  _c(
                                                    "span",
                                                    { staticClass: "tips" },
                                                    [
                                                      _vm._v(
                                                        "（注册时间为空或暂无进店里程）"
                                                      ),
                                                    ]
                                                  ),
                                                  _c("p", [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "warranty-title",
                                                      },
                                                      [_vm._v("1.整车质保")]
                                                    ),
                                                    _c(
                                                      "span",
                                                      { staticClass: "tips" },
                                                      [
                                                        _vm._v(
                                                          "(" +
                                                            _vm._s(
                                                              _vm.vehicleVo[0]
                                                                .month
                                                            ) +
                                                            "月/" +
                                                            _vm._s(
                                                              _vm.vehicleVo[0]
                                                                .km
                                                            ) +
                                                            "公里)"
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                  _c(
                                                    "p",
                                                    { staticClass: "tips" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.vehicleVo[0]
                                                            .content
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm.vehicleVo.length > 1
                                                    ? _c(
                                                        "div",
                                                        _vm._l(
                                                          _vm.vehicleVoList,
                                                          function (
                                                            item,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "tips",
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "(" +
                                                                      _vm._s(
                                                                        item.month
                                                                      ) +
                                                                      "月/" +
                                                                      _vm._s(
                                                                        item.km
                                                                      ) +
                                                                      "公里)"
                                                                  ),
                                                                ]),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.content
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      )
                                                    : _vm._e(),
                                                ])
                                              : _vm._e(),
                                            _vm.corePartsVo.length != 0
                                              ? _c("div", [
                                                  _c("p", [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "warranty-title",
                                                      },
                                                      [_vm._v("2.核心件质保")]
                                                    ),
                                                    _c(
                                                      "span",
                                                      { staticClass: "tips" },
                                                      [
                                                        _vm._v(
                                                          "(" +
                                                            _vm._s(
                                                              _vm.corePartsVo[0]
                                                                .month
                                                            ) +
                                                            "月/" +
                                                            _vm._s(
                                                              _vm.corePartsVo[0]
                                                                .km
                                                            ) +
                                                            "公里)"
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                  _c(
                                                    "p",
                                                    { staticClass: "tips" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.corePartsVo[0]
                                                            .content
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm.corePartsVo.length > 1
                                                    ? _c(
                                                        "div",
                                                        _vm._l(
                                                          _vm.corePartsVoList,
                                                          function (
                                                            item,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "tips",
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "(" +
                                                                      _vm._s(
                                                                        item.month
                                                                      ) +
                                                                      "月/" +
                                                                      _vm._s(
                                                                        item.km
                                                                      ) +
                                                                      "公里)"
                                                                  ),
                                                                ]),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.content
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      )
                                                    : _vm._e(),
                                                ])
                                              : _vm._e(),
                                            _vm.wearingPartsVo.length != 0
                                              ? _c("div", [
                                                  _c("p", [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "warranty-title",
                                                      },
                                                      [_vm._v("3.易损件质保")]
                                                    ),
                                                    _c(
                                                      "span",
                                                      { staticClass: "tips" },
                                                      [
                                                        _vm._v(
                                                          "(" +
                                                            _vm._s(
                                                              _vm
                                                                .wearingPartsVo[0]
                                                                .month
                                                            ) +
                                                            "月/" +
                                                            _vm._s(
                                                              _vm
                                                                .wearingPartsVo[0]
                                                                .km
                                                            ) +
                                                            "公里)"
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                  _c(
                                                    "p",
                                                    { staticClass: "tips" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.wearingPartsVo[0]
                                                            .content
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm.wearingPartsVo.length > 1
                                                    ? _c(
                                                        "div",
                                                        _vm._l(
                                                          _vm.wearingPartsVoList,
                                                          function (
                                                            item,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "tips",
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "(" +
                                                                      _vm._s(
                                                                        item.month
                                                                      ) +
                                                                      "月/" +
                                                                      _vm._s(
                                                                        item.km
                                                                      ) +
                                                                      "公里)"
                                                                  ),
                                                                ]),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.content
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      )
                                                    : _vm._e(),
                                                ])
                                              : _vm._e(),
                                          ]),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm.underWarrantyState == 1
                                ? _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        width: "300",
                                        placement: "bottom",
                                        trigger: "hover",
                                      },
                                    },
                                    [
                                      _c(
                                        "template",
                                        { slot: "reference" },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "warranty-info",
                                              attrs: {
                                                type: "primary",
                                                plain: "",
                                              },
                                            },
                                            [
                                              _vm._v("过保"),
                                              _c("i", {
                                                staticClass:
                                                  "iconfont grow-icon_doubt",
                                                staticStyle: {
                                                  "font-size": "12px",
                                                  "margin-left": "3px",
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.vehicleVo.length != 0
                                        ? _c("div", [
                                            _c("p", [_vm._v("过保")]),
                                            _c("p", [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "warranty-title",
                                                },
                                                [_vm._v("1.整车质保")]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "tips" },
                                                [
                                                  _vm._v(
                                                    "(" +
                                                      _vm._s(
                                                        _vm.vehicleVo[0].month
                                                      ) +
                                                      "月/" +
                                                      _vm._s(
                                                        _vm.vehicleVo[0].km
                                                      ) +
                                                      "公里)"
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _c("p", { staticClass: "tips" }, [
                                              _vm._v(
                                                _vm._s(_vm.vehicleVo[0].content)
                                              ),
                                            ]),
                                            _vm.vehicleVo.length > 1
                                              ? _c(
                                                  "div",
                                                  _vm._l(
                                                    _vm.vehicleVoList,
                                                    function (item, index) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: index,
                                                          staticClass: "tips",
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "(" +
                                                                _vm._s(
                                                                  item.month
                                                                ) +
                                                                "月/" +
                                                                _vm._s(
                                                                  item.km
                                                                ) +
                                                                "公里)"
                                                            ),
                                                          ]),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.content
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                      _vm.corePartsVo.length != 0
                                        ? _c("div", [
                                            _c("p", [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "warranty-title",
                                                },
                                                [_vm._v("2.核心件质保")]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "tips" },
                                                [
                                                  _vm._v(
                                                    "(" +
                                                      _vm._s(
                                                        _vm.corePartsVo[0].month
                                                      ) +
                                                      "月/" +
                                                      _vm._s(
                                                        _vm.corePartsVo[0].km
                                                      ) +
                                                      "公里)"
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _c("p", { staticClass: "tips" }, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.corePartsVo[0].content
                                                )
                                              ),
                                            ]),
                                            _vm.corePartsVo.length > 1
                                              ? _c(
                                                  "div",
                                                  _vm._l(
                                                    _vm.corePartsVoList,
                                                    function (item, index) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: index,
                                                          staticClass: "tips",
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "(" +
                                                                _vm._s(
                                                                  item.month
                                                                ) +
                                                                "月/" +
                                                                _vm._s(
                                                                  item.km
                                                                ) +
                                                                "公里)"
                                                            ),
                                                          ]),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.content
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                      _vm.wearingPartsVo.length != 0
                                        ? _c("div", [
                                            _c("p", [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "warranty-title",
                                                },
                                                [_vm._v("3.易损件质保")]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "tips" },
                                                [
                                                  _vm._v(
                                                    "(" +
                                                      _vm._s(
                                                        _vm.wearingPartsVo[0]
                                                          .month
                                                      ) +
                                                      "月/" +
                                                      _vm._s(
                                                        _vm.wearingPartsVo[0].km
                                                      ) +
                                                      "公里)"
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _c("p", { staticClass: "tips" }, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.wearingPartsVo[0].content
                                                )
                                              ),
                                            ]),
                                            _vm.wearingPartsVo.length > 1
                                              ? _c(
                                                  "div",
                                                  _vm._l(
                                                    _vm.wearingPartsVoList,
                                                    function (item, index) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: index,
                                                          staticClass: "tips",
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "(" +
                                                                _vm._s(
                                                                  item.month
                                                                ) +
                                                                "月/" +
                                                                _vm._s(
                                                                  item.km
                                                                ) +
                                                                "公里)"
                                                            ),
                                                          ]),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.content
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm.underWarrantyState == 2 &&
                              _vm.vehicleVo.length != 0
                                ? _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        width: "300",
                                        placement: "bottom",
                                        trigger: "hover",
                                      },
                                    },
                                    [
                                      _c(
                                        "template",
                                        { slot: "reference" },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "warranty-info",
                                              attrs: {
                                                type: "primary",
                                                plain: "",
                                              },
                                            },
                                            [
                                              _vm._v("整车质保"),
                                              _c("i", {
                                                staticClass:
                                                  "iconfont grow-icon_doubt",
                                                staticStyle: {
                                                  "font-size": "12px",
                                                  "margin-left": "3px",
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.vehicleVo.length != 0
                                        ? _c("div", [
                                            _c("p", [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "warranty-title",
                                                },
                                                [_vm._v("整车质保")]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "tips" },
                                                [
                                                  _vm._v(
                                                    "(" +
                                                      _vm._s(
                                                        _vm.vehicleVo[0].month
                                                      ) +
                                                      "月/" +
                                                      _vm._s(
                                                        _vm.vehicleVo[0].km
                                                      ) +
                                                      "公里)"
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _c("p", { staticClass: "tips" }, [
                                              _vm._v(
                                                _vm._s(_vm.vehicleVo[0].content)
                                              ),
                                            ]),
                                          ])
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm.underWarrantyState == 2 &&
                              _vm.corePartsVo.length != 0
                                ? _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        width: "300",
                                        placement: "bottom",
                                        trigger: "hover",
                                      },
                                    },
                                    [
                                      _c(
                                        "template",
                                        { slot: "reference" },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "warranty-info",
                                              attrs: {
                                                type: "primary",
                                                plain: "",
                                              },
                                            },
                                            [
                                              _vm._v("核心件质保"),
                                              _c("i", {
                                                staticClass:
                                                  "iconfont grow-icon_doubt",
                                                staticStyle: {
                                                  "font-size": "12px",
                                                  "margin-left": "3px",
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.corePartsVo.length != 0
                                        ? _c("div", [
                                            _c("p", [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "warranty-title",
                                                },
                                                [_vm._v(" 核心件质保")]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "tips" },
                                                [
                                                  _vm._v(
                                                    "(" +
                                                      _vm._s(
                                                        _vm.corePartsVo[0].month
                                                      ) +
                                                      "月/" +
                                                      _vm._s(
                                                        _vm.corePartsVo[0].km
                                                      ) +
                                                      "公里)"
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _c("p", { staticClass: "tips" }, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.corePartsVo[0].content
                                                )
                                              ),
                                            ]),
                                          ])
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm.underWarrantyState == 2 &&
                              _vm.wearingPartsVo.length != 0
                                ? _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        width: "300",
                                        placement: "bottom",
                                        trigger: "hover",
                                      },
                                    },
                                    [
                                      _c(
                                        "template",
                                        { slot: "reference" },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "warranty-info",
                                              attrs: {
                                                type: "primary",
                                                plain: "",
                                              },
                                            },
                                            [
                                              _vm._v("易损件质保"),
                                              _c("i", {
                                                staticClass:
                                                  "iconfont grow-icon_doubt",
                                                staticStyle: {
                                                  "font-size": "12px",
                                                  "margin-left": "3px",
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.wearingPartsVo.length != 0
                                        ? _c("div", [
                                            _c("p", [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "warranty-title",
                                                },
                                                [_vm._v("易损件质保")]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "tips" },
                                                [
                                                  _vm._v(
                                                    "(" +
                                                      _vm._s(
                                                        _vm.wearingPartsVo[0]
                                                          .month
                                                      ) +
                                                      "月/" +
                                                      _vm._s(
                                                        _vm.wearingPartsVo[0].km
                                                      ) +
                                                      "公里)"
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _c("p", { staticClass: "tips" }, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.wearingPartsVo[0].content
                                                )
                                              ),
                                            ]),
                                          ])
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm.billType === 0
                            ? _c(
                                "div",
                                { staticStyle: { "margin-right": "60px" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "color666",
                                      staticStyle: {
                                        "margin-right": "10px",
                                        "font-size": "14px",
                                      },
                                    },
                                    [_vm._v("他人自费")]
                                  ),
                                  _c("el-switch", {
                                    on: {
                                      change: _vm.handleOthersSelfPayChange,
                                    },
                                    model: {
                                      value: _vm.isOthersSelfPayState,
                                      callback: function ($$v) {
                                        _vm.isOthersSelfPayState = $$v
                                      },
                                      expression: "isOthersSelfPayState",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.billType === 2
                            ? _c(
                                "div",
                                { staticStyle: { "margin-right": "60px" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "color666",
                                      staticStyle: { "font-size": "14px" },
                                    },
                                    [_vm._v("他人推修")]
                                  ),
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        content:
                                          "车单默认推修人为签约客户，如需调整请打开开关设置",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "iconfont grow-icon_doubt color626",
                                        staticStyle: { "margin-right": "10px" },
                                      }),
                                    ]
                                  ),
                                  _c("el-switch", {
                                    attrs: {
                                      "inactive-value": 0,
                                      "active-value": 1,
                                    },
                                    on: {
                                      change: _vm.handleOtherClientPushChange,
                                    },
                                    model: {
                                      value: _vm.isOtherClientPush,
                                      callback: function ($$v) {
                                        _vm.isOtherClientPush = $$v
                                      },
                                      expression: "isOtherClientPush",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.isOthersSelfPayState && _vm.payerInfo.clientId
            ? _c("div", { staticClass: "other-people-payment-box" }, [
                _c("div", { staticClass: "label" }, [
                  _c("span", [_vm._v("他人自费客户：")]),
                ]),
                _c("div", { staticClass: "select-client-box" }, [
                  _c(
                    "div",
                    { staticClass: "select-client" },
                    [
                      _c("span", { staticStyle: { "margin-right": "27px" } }, [
                        _vm._v(_vm._s(_vm.payerInfo.clientName)),
                      ]),
                      _vm.payerSettlementWay
                        ? _c(
                            "span",
                            {
                              style: {
                                "margin-right": "27px",
                                color:
                                  _vm.payerSettlementWay === "现结"
                                    ? "#33ab79"
                                    : "red",
                              },
                            },
                            [_vm._v(_vm._s(_vm.payerSettlementWay))]
                          )
                        : _vm._e(),
                      _c(
                        "el-button",
                        {
                          staticClass: "primary orange-btn",
                          staticStyle: { "margin-left": "10px" },
                          attrs: { size: "small", type: "text" },
                          on: {
                            click: function ($event) {
                              _vm.selectOtherPayClientDialogVisibility = true
                            },
                          },
                        },
                        [_vm._v("重选")]
                      ),
                    ],
                    1
                  ),
                  _vm.payerInfo.clientRemark
                    ? _c(
                        "span",
                        {
                          staticClass: "blue pointer",
                          staticStyle: { "margin-left": "10px" },
                          on: {
                            click: function ($event) {
                              return _vm.remarksDrawerVisibilityEvent(2)
                            },
                          },
                        },
                        [_vm._v("注意事项")]
                      )
                    : _vm._e(),
                ]),
              ])
            : _vm._e(),
          _vm.isOtherClientPush && _vm.pushClientId
            ? _c("div", { staticClass: "other-people-payment-box" }, [
                _c("div", { staticClass: "label" }, [
                  _c("span", [_vm._v("推修对象：")]),
                ]),
                _c("div", { staticClass: "select-client-box" }, [
                  _c(
                    "div",
                    { staticClass: "select-client" },
                    [
                      _c("span", { staticStyle: { "margin-right": "27px" } }, [
                        _vm._v(_vm._s(_vm.pushClientName)),
                      ]),
                      _c(
                        "el-button",
                        {
                          staticClass: "primary orange-btn",
                          staticStyle: { "margin-left": "10px" },
                          attrs: { size: "small", type: "text" },
                          on: {
                            click: function ($event) {
                              _vm.selectOtherClientPushDialogVisibility = true
                            },
                          },
                        },
                        [_vm._v("重选")]
                      ),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.carInfo.carId && [2, 3].includes(_vm.billType)
            ? _c("div", { staticClass: "split-line" })
            : _vm._e(),
          _vm.carInfo.carId && _vm.billType === 2
            ? _c(
                "div",
                { staticClass: "insurance-type-bill-box" },
                [
                  _c("div", [
                    _c(
                      "div",
                      { staticStyle: { "margin-bottom": "20px" } },
                      [
                        _c("span", [_vm._v("理赔信息")]),
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: "可上传8张图片每张图片建议不超过2M",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "iconfont grow-icon_doubt color626",
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("交商不同")]),
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content:
                                "交强险与商业险保险公司不一致时，请打开开关设置",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "iconfont grow-icon_doubt color626",
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "margin-top": "5px",
                        },
                      },
                      [
                        _c("el-switch", {
                          attrs: { "inactive-value": 0, "active-value": 1 },
                          model: {
                            value: _vm.isJqInfo,
                            callback: function ($$v) {
                              _vm.isJqInfo = $$v
                            },
                            expression: "isJqInfo",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "el-form",
                    {
                      attrs: {
                        model: _vm.billInsuranceDto,
                        "label-width": "110px",
                        size: "small",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 10 } },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "template",
                                    { slot: "label" },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#f00" } },
                                        [_vm._v("*")]
                                      ),
                                      _c("span", [_vm._v("保险公司")]),
                                      _c(
                                        "el-tooltip",
                                        {
                                          attrs: { content: "商业险保险公司" },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "iconfont grow-icon_doubt color626",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("select-insurance-company-popover", {
                                    attrs: {
                                      searchKeyWord:
                                        _vm.billInsuranceDto
                                          .claimsInsuranceCompanyName,
                                    },
                                    on: {
                                      select: _vm.handleSelectInsuranceCompany,
                                      clear: _vm.handleClearInsuranceCompany,
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 10, offset: 1 } },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c("template", { slot: "label" }, [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#f00" } },
                                      [_vm._v("*")]
                                    ),
                                    _c("span", [_vm._v("报案号")]),
                                  ]),
                                  _c("el-input", {
                                    attrs: {
                                      size: "small",
                                      placeholder: "请输入报案号",
                                    },
                                    model: {
                                      value: _vm.billInsuranceDto.caseNum,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.billInsuranceDto,
                                          "caseNum",
                                          $$v
                                        )
                                      },
                                      expression: "billInsuranceDto.caseNum",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.isJqInfo
                        ? _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 10 } },
                                [
                                  _c(
                                    "el-form-item",
                                    [
                                      _c("template", { slot: "label" }, [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "#f00" } },
                                          [_vm._v("*")]
                                        ),
                                        _c("span", [_vm._v("交强保险公司")]),
                                      ]),
                                      _c("select-insurance-company-popover", {
                                        attrs: {
                                          searchKeyWord: _vm.jqInsuranceName,
                                        },
                                        on: {
                                          select:
                                            _vm.handleSelectJqInsuranceCompany,
                                          clear:
                                            _vm.handleClearJqInsuranceCompany,
                                        },
                                      }),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 10, offset: 1 } },
                                [
                                  _c(
                                    "el-form-item",
                                    [
                                      _c("template", { slot: "label" }, [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "#f00" } },
                                          [_vm._v("*")]
                                        ),
                                        _c("span", [_vm._v("交强报案号")]),
                                      ]),
                                      _c("el-input", {
                                        attrs: {
                                          size: "small",
                                          placeholder: "请输入报案号",
                                        },
                                        model: {
                                          value: _vm.jqCaseNum,
                                          callback: function ($$v) {
                                            _vm.jqCaseNum = $$v
                                          },
                                          expression: "jqCaseNum",
                                        },
                                      }),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 10 } },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c("template", { slot: "label" }, [
                                    _c("span", { staticClass: "red" }, [
                                      _vm._v("*"),
                                    ]),
                                    _c("span", [_vm._v("开票主体")]),
                                  ]),
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        size: "small",
                                        filterable: "",
                                        clearable: "",
                                        remote: "",
                                        placeholder: "搜索开票主体名称",
                                        "remote-method": _vm.searchStore,
                                        loading: _vm.searchStoreLoading,
                                      },
                                      model: {
                                        value:
                                          _vm.billInsuranceDto
                                            .insuranceCompanyId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.billInsuranceDto,
                                            "insuranceCompanyId",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression:
                                          "billInsuranceDto.insuranceCompanyId",
                                      },
                                    },
                                    _vm._l(_vm.storeList, function (item) {
                                      return _c("el-option", {
                                        key: item.companyId,
                                        attrs: {
                                          label: item.invoicingEntity,
                                          value: item.companyId,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 10, offset: 1 } },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c("template", { slot: "label" }, [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#f00" } },
                                      [_vm._v("*")]
                                    ),
                                    _c("span", [_vm._v("事故责任")]),
                                  ]),
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { size: "small" },
                                      model: {
                                        value: _vm.billInsuranceDto.dutyType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.billInsuranceDto,
                                            "dutyType",
                                            $$v
                                          )
                                        },
                                        expression: "billInsuranceDto.dutyType",
                                      },
                                    },
                                    _vm._l(_vm.dutyTypeDicts, function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          value: item.value,
                                          label: item.label,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 10 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "其他信息" } },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.isInsuranceTurnSelf,
                                        callback: function ($$v) {
                                          _vm.isInsuranceTurnSelf = $$v
                                        },
                                        expression: "isInsuranceTurnSelf",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        {
                                          attrs: { label: 0 },
                                          nativeOn: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.handleClickInsuranceTurnSelf(
                                                0
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("免赔2000")]
                                      ),
                                      _c(
                                        "el-radio",
                                        {
                                          attrs: { label: 1 },
                                          nativeOn: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.handleClickInsuranceTurnSelf(
                                                1
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("转自费")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 10, offset: 1 } },
                            [
                              [0, 1].includes(_vm.isInsuranceTurnSelf)
                                ? _c(
                                    "el-form-item",
                                    [
                                      _c(
                                        "template",
                                        { slot: "label" },
                                        [
                                          _c(
                                            "span",
                                            { staticStyle: { color: "#f00" } },
                                            [_vm._v("*")]
                                          ),
                                          _c("span", [_vm._v("消费客户")]),
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content:
                                                  "当前理赔单（免赔或转自费）费用承担方",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "iconfont grow-icon_doubt color626",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.beTurnConsumerName) +
                                          " "
                                      ),
                                      _vm.beTurnConsumerSettlementWay
                                        ? _c(
                                            "span",
                                            {
                                              style: {
                                                "margin-right": "5px",
                                                color:
                                                  _vm.beTurnConsumerSettlementWay ===
                                                  "现结"
                                                    ? "#33ab79"
                                                    : "red",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.beTurnConsumerSettlementWay
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "primary orange-btn",
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            size: "small",
                                            type: "text",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.selectInsuranceCustomerDialogVisibility = true
                                            },
                                          },
                                        },
                                        [_vm._v("重选")]
                                      ),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "color666",
                      staticStyle: {
                        display: "flex",
                        "flex-wrap": "wrap",
                        "font-size": "14px",
                      },
                    },
                    [
                      _c("upload-file", {
                        attrs: { title: "驾驶证" },
                        model: {
                          value: _vm.billInsuranceDto.picDrivingLicence,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.billInsuranceDto,
                              "picDrivingLicence",
                              $$v
                            )
                          },
                          expression: "billInsuranceDto.picDrivingLicence",
                        },
                      }),
                      _c("upload-file", {
                        attrs: { title: "定损单1" },
                        model: {
                          value: _vm.billInsuranceDto.feeListPicA,
                          callback: function ($$v) {
                            _vm.$set(_vm.billInsuranceDto, "feeListPicA", $$v)
                          },
                          expression: "billInsuranceDto.feeListPicA",
                        },
                      }),
                      _c("upload-file", {
                        attrs: { title: "定损单2" },
                        model: {
                          value: _vm.billInsuranceDto.feeListPicB,
                          callback: function ($$v) {
                            _vm.$set(_vm.billInsuranceDto, "feeListPicB", $$v)
                          },
                          expression: "billInsuranceDto.feeListPicB",
                        },
                      }),
                      _c("upload-file", {
                        attrs: { title: "定损单3" },
                        model: {
                          value: _vm.billInsuranceDto.feeListPicC,
                          callback: function ($$v) {
                            _vm.$set(_vm.billInsuranceDto, "feeListPicC", $$v)
                          },
                          expression: "billInsuranceDto.feeListPicC",
                        },
                      }),
                      _c("upload-file-list", {
                        attrs: { max: 4 },
                        model: {
                          value: _vm.billInsuranceDto.feeListPicOthers,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.billInsuranceDto,
                              "feeListPicOthers",
                              $$v
                            )
                          },
                          expression: "billInsuranceDto.feeListPicOthers",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.carInfo.carId && _vm.billType === 3
            ? _c(
                "div",
                { staticClass: "undertake-type-bill-box" },
                [
                  _c("div", { staticClass: "title" }, [
                    _c("span", [_vm._v("索赔信息")]),
                    _c("span", [_vm._v("(可上传5张图片每张图片建议不超过2M)")]),
                  ]),
                  _c(
                    "el-form",
                    {
                      attrs: {
                        model: _vm.billUndertakeDto,
                        "label-width": "100px",
                        size: "small",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 10 } },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c("template", { slot: "label" }, [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#f00" } },
                                      [_vm._v("*")]
                                    ),
                                    _c("span", [_vm._v("三包厂家")]),
                                  ]),
                                  _c("select-undertake-company-popover", {
                                    attrs: {
                                      searchKeyWord:
                                        _vm.billUndertakeDto
                                          .undertakeInsuranceCompanyName,
                                    },
                                    on: {
                                      select: _vm.handleSelectUndertakeCompany,
                                      clear: _vm.handleClearUndertakeCompany,
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 10 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "索赔单号" } },
                                _vm._l(
                                  _vm.billUndertakeDto.claimsNumArr,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "undertake-num",
                                      },
                                      [
                                        index !== 0
                                          ? _c("i", {
                                              staticClass:
                                                "iconfont grow-icon_delete3 pointer orange",
                                              staticStyle: {
                                                "margin-right": "12px",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.delClaimsNum(index)
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                        _c("el-input", {
                                          attrs: {
                                            clearable: "",
                                            placeholder: "请输入",
                                            size: "small",
                                          },
                                          model: {
                                            value: item.value,
                                            callback: function ($$v) {
                                              _vm.$set(item, "value", $$v)
                                            },
                                            expression: "item.value",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          ),
                          _c("el-col", { attrs: { span: 4 } }, [
                            _c(
                              "div",
                              {
                                staticClass: "blue pointer",
                                staticStyle: { margin: "11px 12px" },
                                on: { click: _vm.addClaimsNum },
                              },
                              [_vm._v(" +添加更多 ")]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c("template", { slot: "label" }, [
                                    _c("span", [_vm._v("开票主体")]),
                                  ]),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": "14px",
                                        color: "#666",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.billUndertakeDto
                                            .undertakeCompanyName
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "color666",
                      staticStyle: {
                        display: "flex",
                        "flex-wrap": "wrap",
                        "font-size": "14px",
                      },
                    },
                    [
                      _c("upload-file-list", {
                        attrs: { max: 5 },
                        model: {
                          value: _vm.billUndertakeDto.undertakePics,
                          callback: function ($$v) {
                            _vm.$set(_vm.billUndertakeDto, "undertakePics", $$v)
                          },
                          expression: "billUndertakeDto.undertakePics",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
        ![0, 1, 2, 3].includes(this.typeStatus)
          ? _c("div", [
              _vm.carInfo.carId
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form",
                        {
                          ref: "form",
                          staticStyle: { margin: "30px 0" },
                          attrs: {
                            model: _vm.form,
                            rules: _vm.rules,
                            "label-width": "100px",
                            size: "small",
                          },
                        },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 16 } },
                                [
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 7 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { prop: "mileage" } },
                                            [
                                              _c(
                                                "template",
                                                { slot: "label" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "flex-col" },
                                                    [
                                                      _c("span", [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              color: "#f00",
                                                            },
                                                          },
                                                          [_vm._v("*")]
                                                        ),
                                                        _vm._v("进店里程"),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "number-input",
                                                {
                                                  attrs: {
                                                    max: 999999,
                                                    enabledZero: false,
                                                    enabledDecimals: false,
                                                    clearable: "",
                                                  },
                                                  model: {
                                                    value: _vm.form.mileage,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "mileage",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "form.mileage",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "template",
                                                    { slot: "append" },
                                                    [_vm._v("KM")]
                                                  ),
                                                ],
                                                2
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 7, offset: 1 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { prop: "customerName" } },
                                            [
                                              _c(
                                                "template",
                                                { slot: "label" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "flex-col" },
                                                    [
                                                      _c("span", [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              color: "#f00",
                                                            },
                                                          },
                                                          [_vm._v("*")]
                                                        ),
                                                        _vm._v("送修人姓名"),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder:
                                                    "请输入送修人姓名",
                                                  maxlength: "10",
                                                },
                                                model: {
                                                  value: _vm.form.customerName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "customerName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.customerName",
                                                },
                                              }),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 7, offset: 1 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            [
                                              _c(
                                                "template",
                                                { slot: "label" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "flex-col" },
                                                    [
                                                      _c("span", [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              color: "#f00",
                                                            },
                                                          },
                                                          [_vm._v("*")]
                                                        ),
                                                        _vm._v("送修人电话"),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c("number-input", {
                                                attrs: {
                                                  placeholder:
                                                    "请输入送修人电话",
                                                  maxlength: 11,
                                                  enabledDecimals: false,
                                                  negativeNumber: false,
                                                },
                                                model: {
                                                  value: _vm.form.customerPhone,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "customerPhone",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.customerPhone",
                                                },
                                              }),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 7 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "业务来源",
                                                prop: "billSourceType",
                                              },
                                            },
                                            [
                                              _c("selectBillSourcePopover", {
                                                attrs: {
                                                  searchKeyWord:
                                                    _vm.form.billSourceTypeName,
                                                  isCarBill: true,
                                                },
                                                model: {
                                                  value:
                                                    _vm.form.billSourceTypeId,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "billSourceTypeId",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.billSourceTypeId",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 7, offset: 1 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: { label: "进店油(电)表" },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    placeholder:
                                                      "请选择进店油(电)表",
                                                    clearable: "",
                                                  },
                                                  model: {
                                                    value: _vm.form.oilMeter,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "oilMeter",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "form.oilMeter",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.energyPercentage,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.value,
                                                      attrs: {
                                                        label: item.label,
                                                        value: item.value,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 7, offset: 1 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            [
                                              _c(
                                                "template",
                                                { slot: "label" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "flex-col" },
                                                    [
                                                      _c("span", [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              color: "#f00",
                                                            },
                                                          },
                                                          [_vm._v("*")]
                                                        ),
                                                        _vm._v("施工类型"),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    placeholder:
                                                      "请选择施工类型",
                                                    clearable: "",
                                                  },
                                                  model: {
                                                    value: _vm.form.difficulty,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "difficulty",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.difficulty",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.difficultyDicts,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.value,
                                                      attrs: {
                                                        label: item.label,
                                                        value: item.value,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-row",
                                    [
                                      _c("el-col", { attrs: { span: 7 } }, [
                                        ![0, 1, 2, 3].includes(this.typeStatus)
                                          ? _c("div", [
                                              _vm.carInfo.carId
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "plan-finish-time-box",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "flex-y",
                                                        },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            [
                                                              _c(
                                                                "template",
                                                                {
                                                                  slot: "label",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "flex-col",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  color:
                                                                                    "#f00",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "*"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            "预交车时间"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "el-date-picker",
                                                                {
                                                                  attrs: {
                                                                    type: "datetime",
                                                                    placeholder:
                                                                      "请选择预交车时间",
                                                                    "value-format":
                                                                      "yyyy-MM-dd HH:mm:ss",
                                                                    disabled:
                                                                      _vm.form
                                                                        .undetermined,
                                                                    "picker-options":
                                                                      {
                                                                        disabledDate:
                                                                          _vm.disabledDate,
                                                                      },
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.form
                                                                        .planFinishTime,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.form,
                                                                          "planFinishTime",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "form.planFinishTime",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            2
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                "margin-left":
                                                                  "102px",
                                                                "margin-top":
                                                                  "-12px",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-checkbox",
                                                                {
                                                                  on: {
                                                                    change:
                                                                      _vm.changeUndetermined,
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.form
                                                                        .undetermined,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.form,
                                                                          "undetermined",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "form.undetermined",
                                                                  },
                                                                },
                                                                [_vm._v("待定")]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                      ]),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 7, offset: 1 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "真实续航" } },
                                            [
                                              _c("number-input", {
                                                attrs: {
                                                  placeholder: "请输入真实续航",
                                                  enabledDecimals: false,
                                                  negativeNumber: false,
                                                },
                                                model: {
                                                  value: _vm.form.trueEndurance,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "trueEndurance",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.trueEndurance",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 7, offset: 1 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "接车员" } },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    placeholder: "请选择接车员",
                                                    filterable: "",
                                                    clearable: "",
                                                    remote: "",
                                                    "remote-method":
                                                      _vm.getEmployeeList,
                                                    loading:
                                                      _vm.loadingEmployee,
                                                  },
                                                  on: {
                                                    clear:
                                                      _vm.handleClearPickUpUser,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.pickUpUserId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "pickUpUserId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.pickUpUserId",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.employeeList,
                                                  function (item, index) {
                                                    return _c("el-option", {
                                                      key: index,
                                                      attrs: {
                                                        label: item.realName,
                                                        value: item.userId,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          staticStyle: { "margin-top": "20px" },
                                          attrs: { span: 23 },
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "环检图片" } },
                                            [
                                              _c(
                                                "template",
                                                { slot: "label" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "bao-btn" },
                                                    [
                                                      _c(
                                                        "el-tooltip",
                                                        {
                                                          attrs: {
                                                            effect: "light",
                                                            placement:
                                                              "bottom-start",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                color: "#666",
                                                                "font-size":
                                                                  "13px",
                                                                "line-height":
                                                                  "20px",
                                                              },
                                                              attrs: {
                                                                slot: "content",
                                                              },
                                                              slot: "content",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "flex",
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "(可上传20张图片,建议每张图片大小不超过1M)"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c("i", {
                                                            staticClass:
                                                              "iconfont grow-icon_doubt",
                                                          }),
                                                        ]
                                                      ),
                                                      _vm._v(" 环检图片 "),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "color666" },
                                                [
                                                  _c("upload-file", {
                                                    attrs: {
                                                      title: "车身正面",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form
                                                          .carBodyPositivePic,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "carBodyPositivePic",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.carBodyPositivePic",
                                                    },
                                                  }),
                                                  _c("upload-file", {
                                                    attrs: {
                                                      title: "车身左侧",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form.carBodyLeftPic,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "carBodyLeftPic",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.carBodyLeftPic",
                                                    },
                                                  }),
                                                  _c("upload-file", {
                                                    attrs: {
                                                      title: "车身后面",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form
                                                          .carBodyBehindPic,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "carBodyBehindPic",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.carBodyBehindPic",
                                                    },
                                                  }),
                                                  _c("upload-file", {
                                                    attrs: {
                                                      title: "车身右侧",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form
                                                          .carBodyRightPic,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "carBodyRightPic",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.carBodyRightPic",
                                                    },
                                                  }),
                                                  _c("upload-file", {
                                                    attrs: { title: "行驶证" },
                                                    model: {
                                                      value:
                                                        _vm.form
                                                          .runningLicenceImg,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "runningLicenceImg",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.runningLicenceImg",
                                                    },
                                                  }),
                                                  _c("upload-file", {
                                                    attrs: { title: "仪表盘" },
                                                    model: {
                                                      value:
                                                        _vm.form
                                                          .carInstrumentPanelPic,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "carInstrumentPanelPic",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.carInstrumentPanelPic",
                                                    },
                                                  }),
                                                  _c("upload-file", {
                                                    attrs: {
                                                      title: "车辆铭牌",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form
                                                          .vehicleNameplateImg,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "vehicleNameplateImg",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.vehicleNameplateImg",
                                                    },
                                                  }),
                                                  _c("upload-file", {
                                                    attrs: {
                                                      title: "电池铭牌",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form
                                                          .batteryNameplateImg,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "batteryNameplateImg",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.batteryNameplateImg",
                                                    },
                                                  }),
                                                  _c("upload-file-list", {
                                                    attrs: { max: 12 },
                                                    model: {
                                                      value:
                                                        _vm.form.carOtherPics,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "carOtherPics",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.carOtherPics",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.isSpecificClient()
                                    ? _c(
                                        "el-row",
                                        [
                                          _c(
                                            "el-col",
                                            { attrs: { span: 7 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "录单情况",
                                                    prop: "doubleSysInputState",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "flex" },
                                                    [
                                                      _c(
                                                        "el-select",
                                                        {
                                                          attrs: {
                                                            placeholder:
                                                              "双系统录单情况",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.form
                                                                .doubleSysInputState,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.form,
                                                                "doubleSysInputState",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "form.doubleSysInputState",
                                                          },
                                                        },
                                                        [
                                                          _c("el-option", {
                                                            attrs: {
                                                              label:
                                                                "双系统待录单",
                                                              value: 1,
                                                            },
                                                          }),
                                                          _c("el-option", {
                                                            attrs: {
                                                              label:
                                                                "双系统已录单",
                                                              value: 2,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 10, offset: 1 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "双系统单号",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.doubleSysNumArr,
                                                  function (item, index) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: index,
                                                        staticClass:
                                                          "double-num-item",
                                                      },
                                                      [
                                                        index !== 0
                                                          ? _c("i", {
                                                              staticClass:
                                                                "iconfont grow-icon_delete3 pointer orange",
                                                              staticStyle: {
                                                                "margin-right":
                                                                  "12px",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.delDoubleSysNum(
                                                                      index
                                                                    )
                                                                  },
                                                              },
                                                            })
                                                          : _vm._e(),
                                                        _c("el-input", {
                                                          attrs: {
                                                            clearable: "",
                                                            placeholder:
                                                              "请输入双系统单号",
                                                            size: "small",
                                                          },
                                                          model: {
                                                            value: item.value,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "value",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.value",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 4, offset: 1 } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "blue pointer",
                                                  staticStyle: {
                                                    margin: "11px 12px",
                                                  },
                                                  on: {
                                                    click: _vm.addDoubleSysNum,
                                                  },
                                                },
                                                [_vm._v(" +添加更多 ")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 23, offset: 1 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { prop: "remark" } },
                                        [
                                          _c("template", { slot: "label" }, [
                                            _c(
                                              "div",
                                              { staticClass: "flex-col" },
                                              [
                                                _c("span", [
                                                  _vm._v("车主要求"),
                                                ]),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      color: "#999",
                                                      "font-size": "12px",
                                                      "line-height": "14px",
                                                    },
                                                  },
                                                  [_vm._v("(客户可见)")]
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c("el-input", {
                                            attrs: {
                                              type: "textarea",
                                              rows: 3,
                                              placeholder: "请输入车主要求",
                                            },
                                            model: {
                                              value: _vm.form.remark,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "remark",
                                                  $$v
                                                )
                                              },
                                              expression: "form.remark",
                                            },
                                          }),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 23, offset: 1 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { prop: "firstVisitRemark" } },
                                        [
                                          _c("template", { slot: "label" }, [
                                            _c(
                                              "div",
                                              { staticClass: "flex-col" },
                                              [
                                                _c("span", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "#f00",
                                                      },
                                                    },
                                                    [_vm._v("*")]
                                                  ),
                                                  _vm._v("初诊与备注"),
                                                ]),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      color: "#999",
                                                      "font-size": "12px",
                                                      "line-height": "14px",
                                                    },
                                                  },
                                                  [_vm._v("(客户可见)")]
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c("el-input", {
                                            attrs: {
                                              type: "textarea",
                                              rows: 3,
                                              placeholder:
                                                "请输入接待初诊、备注（必填）",
                                            },
                                            model: {
                                              value: _vm.form.firstVisitRemark,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "firstVisitRemark",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.firstVisitRemark",
                                            },
                                          }),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 23, offset: 1 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        [
                                          _c("template", { slot: "label" }, [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "line-height": "20px",
                                                  "font-size": "14px",
                                                },
                                              },
                                              [
                                                _vm._v(" 车单备注 "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      color: "#999",
                                                      "font-size": "12px",
                                                    },
                                                  },
                                                  [_vm._v(" (客户不可见) ")]
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c("el-input", {
                                            attrs: {
                                              type: "textarea",
                                              rows: 2,
                                              placeholder: "请输入门店内部备注",
                                            },
                                            model: {
                                              value: _vm.form.companyRemark,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "companyRemark",
                                                  $$v
                                                )
                                              },
                                              expression: "form.companyRemark",
                                            },
                                          }),
                                          _c("div", { staticClass: "flex" }, [
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "el-checkbox",
                                                  {
                                                    attrs: {
                                                      "true-label": 1,
                                                      "false-label": 0,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form.isReturnCar,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "isReturnCar",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.isReturnCar",
                                                    },
                                                  },
                                                  [_vm._v("退车整备")]
                                                ),
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    attrs: {
                                                      content: "退车整备车单",
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "color626 iconfont grow-icon_doubt",
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-left": "8px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-checkbox",
                                                  {
                                                    attrs: {
                                                      "true-label": 1,
                                                      "false-label": 0,
                                                    },
                                                    model: {
                                                      value: _vm.form.isRepair,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "isRepair",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.isRepair",
                                                    },
                                                  },
                                                  [_vm._v("返修")]
                                                ),
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    attrs: {
                                                      content: "车辆返修车单",
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "color626 iconfont grow-icon_doubt",
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-left": "8px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-checkbox",
                                                  {
                                                    attrs: {
                                                      "true-label": 1,
                                                      "false-label": 0,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form
                                                          .isSupplementaryOrder,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "isSupplementaryOrder",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.isSupplementaryOrder",
                                                    },
                                                  },
                                                  [_vm._v("补单")]
                                                ),
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    attrs: {
                                                      content:
                                                        "非真实进店，车单补录。",
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "color626 iconfont grow-icon_doubt",
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("div", { staticClass: "flex" }, [
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "el-checkbox",
                                                  {
                                                    attrs: {
                                                      "true-label": 1,
                                                      "false-label": 0,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form
                                                          .isClaimSettlToSelfPayment,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "isClaimSettlToSelfPayment",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.isClaimSettlToSelfPayment",
                                                    },
                                                  },
                                                  [_vm._v("理赔转自费")]
                                                ),
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    attrs: {
                                                      content:
                                                        "用于统计没有买车损险转自费的车辆，业务来源还是建客户自费，但是算事故车进场台次里",
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "color626 iconfont grow-icon_doubt",
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-left": "8px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-checkbox",
                                                  {
                                                    attrs: {
                                                      "true-label": 1,
                                                      "false-label": 0,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form
                                                          .isClaimSettlFullLoss,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "isClaimSettlFullLoss",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.isClaimSettlFullLoss",
                                                    },
                                                  },
                                                  [_vm._v("全损")]
                                                ),
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    attrs: {
                                                      content:
                                                        "理赔的全损报废车辆",
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "color626 iconfont grow-icon_doubt",
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-left": "8px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-checkbox",
                                                  {
                                                    attrs: {
                                                      "true-label": 1,
                                                      "false-label": 0,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form
                                                          .isClaimExternalService,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "isClaimExternalService",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.isClaimExternalService",
                                                    },
                                                  },
                                                  [_vm._v("外服")]
                                                ),
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    attrs: {
                                                      content: "索赔的电池外服",
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "color626 iconfont grow-icon_doubt",
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 23, offset: 1 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "索赔情况" } },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "flex" },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    placeholder: "在保情况",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form
                                                        .isClaimUnderWarranty,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "isClaimUnderWarranty",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.isClaimUnderWarranty",
                                                  },
                                                },
                                                [
                                                  _c("el-option", {
                                                    attrs: {
                                                      label: "在保",
                                                      value: 1,
                                                    },
                                                  }),
                                                  _c("el-option", {
                                                    attrs: {
                                                      label: "不在保",
                                                      value: 0,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "5%",
                                                  },
                                                  attrs: {
                                                    placeholder: "活动情况",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.isClaimActivity,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "isClaimActivity",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.isClaimActivity",
                                                  },
                                                },
                                                [
                                                  _c("el-option", {
                                                    attrs: {
                                                      label: "有活动",
                                                      value: 1,
                                                    },
                                                  }),
                                                  _c("el-option", {
                                                    attrs: {
                                                      label: "无活动",
                                                      value: 0,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 23, offset: 1 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        [
                                          _c("template", { slot: "label" }, [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "line-height": "20px",
                                                  "font-size": "14px",
                                                },
                                              },
                                              [
                                                _vm._v(" 返修追溯备注 "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      color: "#999",
                                                      "font-size": "12px",
                                                    },
                                                  },
                                                  [_vm._v(" (客户不可见) ")]
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c("el-input", {
                                            attrs: {
                                              type: "textarea",
                                              rows: 2,
                                              readonly: "",
                                              placeholder: "请输入返修追溯备注",
                                            },
                                            model: {
                                              value: _vm.form.repairRemark,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "repairRemark",
                                                  $$v
                                                )
                                              },
                                              expression: "form.repairRemark",
                                            },
                                          }),
                                          _c("div", { staticClass: "flex" }, [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-left": "8px",
                                                  "margin-top": "10px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      type: "primary",
                                                      size: "small",
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.openRepairPopover,
                                                    },
                                                  },
                                                  [_vm._v("返修追溯")]
                                                ),
                                                _vm.form.tracingBillId !=
                                                undefined
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "10px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "已选择" +
                                                            _vm._s(
                                                              _vm.form
                                                                .tracingBillCode
                                                            )
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.form.tracingBillId !=
                                                undefined
                                                  ? _c("i", {
                                                      staticClass:
                                                        "pointer iconfont grow-a-icon_tabbar_close icon-sty",
                                                      staticStyle: {
                                                        color: "#ff9200",
                                                      },
                                                      attrs: {
                                                        title:
                                                          "清除返修追溯车单",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.clearRepairTracingBill,
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "btns flex-x-between" }, [
                        _c("div", { staticClass: "flex" }, [
                          _c(
                            "div",
                            { staticClass: "bao-btn" },
                            [
                              _vm._v(" 报价项目 "),
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    effect: "light",
                                    placement: "bottom-start",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "#666",
                                        "font-size": "13px",
                                        "line-height": "20px",
                                      },
                                      attrs: { slot: "content" },
                                      slot: "content",
                                    },
                                    [
                                      _c("div", { staticClass: "flex" }, [
                                        _c("span", [_vm._v("报价说明:")]),
                                        _c("p", [
                                          _vm._v(
                                            " 1、报价项目,先添加服务项目,再在服务项目下添加商品(“商品及库存”列,操作【添加】或【批量添加】搜索选择报价商品),"
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            " 2、会员卡内服务项目请从【批量选择服务】入口进入进行选择报价;会员卡内商品项目请从“商品及库存”列的【批量添加】入口进入进行选择报价;"
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            " 3、报价的商品,将在接待开单或保存接车单后自动保存到接车单的“领用材料”清单里,可在“领用材料”入口进入进行领料出库 "
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                  _c("i", {
                                    staticClass: "iconfont grow-icon_doubt",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                          [0, 1].includes(_vm.billType)
                            ? _c(
                                "div",
                                { staticClass: "flex-y-center" },
                                [
                                  _c(
                                    "el-tag",
                                    {
                                      staticClass: "el-icon--left",
                                      attrs: { type: "info", size: "mini" },
                                    },
                                    [_vm._v("协议")]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticClass: "color626",
                                      staticStyle: { "font-size": "12px" },
                                    },
                                    [
                                      _vm._v(
                                        " 存在该标识时该项目与客户约定的协议价，不允许修改报价 "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ]),
                      _c(
                        "el-table",
                        {
                          staticClass: "service-table",
                          staticStyle: { width: "100%" },
                          attrs: {
                            border: "",
                            data: _vm.serviceTableData,
                            "default-expand-all": "",
                            "row-key": "id",
                            "tree-props": {
                              children: "billGoods",
                              hasChildren: "hasChildren",
                            },
                            "span-method": _vm.spanMethod,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              width: "60",
                              label: "操作",
                              align: "center",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      !scope.row.isChild
                                        ? _c("span", [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-delete pointer table-del-icon",
                                              staticStyle: {
                                                color: "#ff9200",
                                                "font-size": "22px",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteRow(
                                                    scope.row,
                                                    scope.$index
                                                  )
                                                },
                                              },
                                            }),
                                          ])
                                        : _c("span", [
                                            _c("i", {
                                              staticClass:
                                                "iconfont grow-icon_delete3 pointer table-del-icon",
                                              staticStyle: {
                                                color: "#ff9200",
                                                "font-size": "22px",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteRow(
                                                    scope.row,
                                                    scope.$index
                                                  )
                                                },
                                              },
                                            }),
                                          ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1357975216
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              width: "60",
                              label: "序号",
                              prop: "title",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: { label: "项目名称", "min-width": "160" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "header",
                                  fn: function () {
                                    return [
                                      _vm._v(" 项目名称 "),
                                      _c(
                                        "el-tooltip",
                                        { attrs: { content: "批量添加" } },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "iconfont grow-icon_add primary pointer",
                                            on: {
                                              click: function ($event) {
                                                _vm.multipleSelectServiceDrawerVisibility = true
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "default",
                                  fn: function ({ row, $index }) {
                                    return [
                                      row.name
                                        ? _c(
                                            "span",
                                            [
                                              row.isAgreePrice
                                                ? _c(
                                                    "el-tag",
                                                    {
                                                      staticClass:
                                                        "el-icon--left",
                                                      attrs: {
                                                        type: "info",
                                                        size: "mini",
                                                      },
                                                    },
                                                    [_vm._v("协议")]
                                                  )
                                                : _vm._e(),
                                              _c("span", [
                                                _vm._v(_vm._s(row.name)),
                                              ]),
                                              row.lastUseDate
                                                ? _c(
                                                    "p",
                                                    { staticClass: "red" },
                                                    [
                                                      _vm._v(
                                                        " 最近服务: " +
                                                          _vm._s(
                                                            row.lastUseDate
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "div",
                                            [
                                              _c("select-goods-popover", {
                                                ref: "selectGoodsPopover",
                                                attrs: {
                                                  carId: _vm.carInfo.carId,
                                                  code: row.keywordOfCode,
                                                  isAgreePrice:
                                                    _vm.editRow.isAgreePrice,
                                                  serviceItemId:
                                                    _vm.editRow.serviceItemId,
                                                  billType: _vm.billType,
                                                  clientAgreeId:
                                                    _vm.customerAgreementId ||
                                                    (_vm.isOthersSelfPayState
                                                      ? ""
                                                      : _vm.carAgreementId),
                                                  serviceProducts:
                                                    _vm.serviceProducts,
                                                  carModel: _vm.carModelKeyword,
                                                  isMatchThisModel:
                                                    _vm.carModelKeyword ===
                                                    _vm.oldCarModelName
                                                      ? 1
                                                      : 0,
                                                  billCompanyId:
                                                    _vm.createCompanyId,
                                                },
                                                on: {
                                                  "update:carModel": function (
                                                    $event
                                                  ) {
                                                    _vm.carModelKeyword = $event
                                                  },
                                                  "update:car-model": function (
                                                    $event
                                                  ) {
                                                    _vm.carModelKeyword = $event
                                                  },
                                                  select: function ($event) {
                                                    return _vm.onSelectGoodsItem(
                                                      $event,
                                                      row,
                                                      $index
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              267656514
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "项目编码", "min-width": "150" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ row }) {
                                    return [
                                      row.name
                                        ? _c("span", [_vm._v(_vm._s(row.code))])
                                        : _c(
                                            "div",
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  id: "codeInput",
                                                  clearable: "",
                                                  placeholder: "搜索自编码",
                                                  size: "small",
                                                },
                                                on: {
                                                  focus:
                                                    _vm.showSelectGoodsPopover,
                                                },
                                                model: {
                                                  value: row.keywordOfCode,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      row,
                                                      "keywordOfCode",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "row.keywordOfCode",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3200288009
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "商品及库存", "min-width": "110" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ row, $index }) {
                                    return [
                                      row.name
                                        ? _c("div", [
                                            !row.isChild
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "el-dropdown",
                                                      {
                                                        attrs: {
                                                          size: "mini",
                                                          "split-button": "",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.addGoodsRow(
                                                              row
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 添加 "),
                                                        _c(
                                                          "el-dropdown-menu",
                                                          {
                                                            attrs: {
                                                              slot: "dropdown",
                                                            },
                                                            slot: "dropdown",
                                                          },
                                                          [
                                                            _c(
                                                              "el-dropdown-item",
                                                              {
                                                                nativeOn: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.showMultipleSelectGoodsDrawer(
                                                                        row,
                                                                        $index
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "批量添加"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _c("div", [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        row.inventoryTotalNum
                                                      )
                                                    ),
                                                  ]),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "margin-left": "5px",
                                                      },
                                                    },
                                                    [_vm._v(_vm._s(row.unit))]
                                                  ),
                                                ]),
                                          ])
                                        : _c(
                                            "div",
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "auth",
                                                      rawName: "v-auth",
                                                      value: 19,
                                                      expression: "19",
                                                    },
                                                  ],
                                                  staticClass: "orange-btn",
                                                  attrs: {
                                                    type: "text",
                                                    size: "small",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.addGoodsDialogVisibility = true
                                                    },
                                                  },
                                                },
                                                [_vm._v("新增商品")]
                                              ),
                                            ],
                                            1
                                          ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1033934000
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "price",
                              label: "单价",
                              "min-width": "90",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ row }) {
                                    return [
                                      row.isAgreePrice
                                        ? [
                                            _c(
                                              "span",
                                              {
                                                on: {
                                                  click: _vm.showDisabledReason,
                                                },
                                              },
                                              [_vm._v(_vm._s(row.price))]
                                            ),
                                          ]
                                        : [
                                            row.name
                                              ? _c("number-input", {
                                                  key: _vm.tableKey + 1,
                                                  attrs: {
                                                    size: "small",
                                                    nullable: false,
                                                    decimalDigits: 2,
                                                    fixedLength: 2,
                                                    clearable: false,
                                                  },
                                                  on: {
                                                    blur: function ($event) {
                                                      return _vm.changePrice(
                                                        row
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: row.price,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        row,
                                                        "price",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "row.price",
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2170203728
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "赠送", "min-width": "90" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "header",
                                  fn: function () {
                                    return [
                                      _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            effect: "light",
                                            placement: "bottom-start",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                color: "#666",
                                                "font-size": "13px",
                                                "line-height": "20px",
                                              },
                                              attrs: { slot: "content" },
                                              slot: "content",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "flex" },
                                                [
                                                  _vm._v(
                                                    " 满足条件，附赠的服务(可以是项目或者商品配件) "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c("i", {
                                            staticClass:
                                              "iconfont grow-icon_doubt",
                                          }),
                                        ]
                                      ),
                                      _vm._v(" 赠送 "),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "default",
                                  fn: function ({ row }) {
                                    return [
                                      row.isAgreePrice
                                        ? [
                                            _c(
                                              "el-checkbox",
                                              {
                                                attrs: {
                                                  "true-label": true,
                                                  "false-label": false,
                                                },
                                                on: {
                                                  change: (newValue) =>
                                                    _vm.changeAgreementFree(
                                                      newValue,
                                                      row
                                                    ),
                                                },
                                                model: {
                                                  value: row.agreementFree,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      row,
                                                      "agreementFree",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "row.agreementFree",
                                                },
                                              },
                                              [_vm._v("赠送")]
                                            ),
                                            _c("el-input", {
                                              attrs: {
                                                type: "textarea",
                                                rows: 3,
                                                disabled: !row.agreementFree,
                                                placeholder: "请输入赠送说明",
                                              },
                                              model: {
                                                value: row.agreementFreeRemark,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    row,
                                                    "agreementFreeRemark",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "row.agreementFreeRemark",
                                              },
                                            }),
                                          ]
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              4060716288
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "折旧费", "min-width": "200" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "header",
                                  fn: function () {
                                    return [
                                      _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            effect: "light",
                                            placement: "bottom-start",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                color: "#666",
                                                "font-size": "13px",
                                                "line-height": "20px",
                                              },
                                              attrs: { slot: "content" },
                                              slot: "content",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "flex" },
                                                [_vm._v("配件换新等抵扣")]
                                              ),
                                            ]
                                          ),
                                          _c("i", {
                                            staticClass:
                                              "iconfont grow-icon_doubt",
                                          }),
                                        ]
                                      ),
                                      _vm._v(" 折旧费 "),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "default",
                                  fn: function ({ row }) {
                                    return [
                                      row.goodsId && !row.agreementFree
                                        ? [
                                            _c(
                                              "el-row",
                                              { attrs: { gutter: 10 } },
                                              [
                                                _c(
                                                  "el-col",
                                                  {
                                                    attrs: {
                                                      span: 8,
                                                      offset: 0,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-checkbox",
                                                      {
                                                        staticStyle: {
                                                          "margin-top": "7px",
                                                        },
                                                        attrs: {
                                                          "true-label": true,
                                                          "false-label": false,
                                                        },
                                                        on: {
                                                          change: (newValue) =>
                                                            _vm.changeDepreciationChargeCheckBox(
                                                              newValue,
                                                              row
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            row.isDepreciation,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              row,
                                                              "isDepreciation",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "row.isDepreciation",
                                                        },
                                                      },
                                                      [_vm._v("折旧")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "el-col",
                                                  {
                                                    attrs: {
                                                      span: 16,
                                                      offset: 0,
                                                    },
                                                  },
                                                  [
                                                    _c("number-input", {
                                                      attrs: {
                                                        decimalDigits: "2",
                                                        min: 0.0,
                                                        max: 999999.99,
                                                        placeholder:
                                                          "请输入折旧费",
                                                        clearable: "",
                                                      },
                                                      on: {
                                                        change: (newValue) =>
                                                          _vm.changeDepreciationCharge(
                                                            newValue,
                                                            row
                                                          ),
                                                      },
                                                      model: {
                                                        value:
                                                          row.depreciationCharge,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            row,
                                                            "depreciationCharge",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "row.depreciationCharge",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c("el-input", {
                                              staticStyle: {
                                                "margin-top": "4px",
                                              },
                                              attrs: {
                                                type: "textarea",
                                                rows: 3,
                                                maxlength: 20,
                                                disabled: !row.isDepreciation,
                                                placeholder:
                                                  "折旧备注(字数限制在20个字内)",
                                              },
                                              model: {
                                                value: row.depreciationRemark,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    row,
                                                    "depreciationRemark",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "row.depreciationRemark",
                                              },
                                            }),
                                          ]
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              249084779
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "discount", width: "110" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "header",
                                  fn: function () {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.editDiscountBatch,
                                              expression: "editDiscountBatch",
                                            },
                                          ],
                                        },
                                        [
                                          _c("number-input", {
                                            staticStyle: { width: "50px" },
                                            attrs: {
                                              size: "mini",
                                              max: 9.9,
                                              decimalDigits: 1,
                                              enabledZero: false,
                                              placeholder: "请输入",
                                            },
                                            model: {
                                              value: _vm.batchDiscountNumber,
                                              callback: function ($$v) {
                                                _vm.batchDiscountNumber = $$v
                                              },
                                              expression: "batchDiscountNumber",
                                            },
                                          }),
                                          _c(
                                            "el-button",
                                            {
                                              staticStyle: {
                                                padding: "7px 2px",
                                                "margin-left": "4px",
                                              },
                                              attrs: { size: "mini" },
                                              on: {
                                                click:
                                                  _vm.handleBatchSetDiscount,
                                              },
                                            },
                                            [_vm._v("确定")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !_vm.editDiscountBatch,
                                              expression: "!editDiscountBatch",
                                            },
                                          ],
                                        },
                                        [
                                          _c("span", [_vm._v("折扣")]),
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content:
                                                  "批量设置折扣(协议项目不支持打折)",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "iconfont grow-a-icon_fillout color666 el-icon--right pointer",
                                                staticStyle: {
                                                  "font-size": "12px",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.editDiscountBatch = true
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "default",
                                  fn: function ({ row }) {
                                    return [
                                      row.name
                                        ? _c("number-input", {
                                            key: _vm.tableKey + 2,
                                            attrs: {
                                              size: "small",
                                              disabled: row.isAgreePrice,
                                              max: 9.9,
                                              decimalDigits: 1,
                                              enabledZero: false,
                                              placeholder: "无",
                                            },
                                            on: {
                                              blur: function ($event) {
                                                return _vm.changeDiscount(row)
                                              },
                                              clear: function ($event) {
                                                return _vm.changeDiscount(row)
                                              },
                                            },
                                            model: {
                                              value: row.discount,
                                              callback: function ($$v) {
                                                _vm.$set(row, "discount", $$v)
                                              },
                                              expression: "row.discount",
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              712655150
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "date",
                              label: "数量",
                              "min-width": "80",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ row }) {
                                    return [
                                      row.name
                                        ? _c("number-input", {
                                            key: _vm.tableKey + 3,
                                            attrs: {
                                              size: "small",
                                              clearable: false,
                                              min: 0.01,
                                              decimalDigits: 2,
                                            },
                                            on: {
                                              blur: function ($event) {
                                                return _vm.changeNumber(row)
                                              },
                                            },
                                            model: {
                                              value: row.num,
                                              callback: function ($$v) {
                                                _vm.$set(row, "num", $$v)
                                              },
                                              expression: "row.num",
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2854401072
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "date",
                              label: "小计",
                              "min-width": "80",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ row }) {
                                    return [
                                      row.name
                                        ? _c("div", [
                                            _c(
                                              "span",
                                              { key: _vm.tableKey + 4 },
                                              [_vm._v(_vm._s(row.total))]
                                            ),
                                            _vm.billDiscountTotalPrice !=
                                            _vm.billTotalPrice
                                              ? _c("div", [
                                                  _c("span", [
                                                    _vm._v("整单折扣后: "),
                                                  ]),
                                                  _c("span", [
                                                    _vm._v(
                                                      "¥" +
                                                        _vm._s(
                                                          _vm._f("toThousands")(
                                                            row.billDiscount
                                                          )
                                                        )
                                                    ),
                                                  ]),
                                                ])
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              282032485
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: { "min-width": "100", label: "项目合计" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ row }) {
                                    return [
                                      _vm.billDiscountTotalPrice ==
                                      _vm.billTotalPrice
                                        ? _c("div", [
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("toThousands")(
                                                    row.itemTotalBefore
                                                  )
                                                )
                                              ),
                                            ]),
                                          ])
                                        : _c("div", [
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("toThousands")(
                                                    row.itemTotalAfter
                                                  )
                                                )
                                              ),
                                            ]),
                                            _c("del", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("toThousands")(
                                                    row.itemTotalBefore
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1796597548
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: { width: "100" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "header",
                                  fn: function () {
                                    return [
                                      _c("span", [_vm._v("施工技师")]),
                                      _c("i", {
                                        directives: [
                                          {
                                            name: "auth",
                                            rawName: "v-auth",
                                            value: 95,
                                            expression: "95",
                                          },
                                        ],
                                        staticClass:
                                          "iconfont grow-a-icon_fillout color666 el-icon--right pointer",
                                        staticStyle: { "font-size": "14px" },
                                        on: {
                                          click:
                                            _vm.showSelectTechniciansDialogForAllItem,
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "default",
                                  fn: function ({ row, $index }) {
                                    return [
                                      _c("div", { key: _vm.tableKey + 5 }, [
                                        !_vm.id ||
                                        !_vm.checkCurrentCompanyHasEnableDispatch ||
                                        row.dispatchTaskState === undefined ||
                                        [5, 6].includes(row.dispatchTaskState)
                                          ? _c("div", [
                                              _vm.$lodash.get(
                                                row,
                                                "technicianIds.length",
                                                false
                                              )
                                                ? _c("div", [
                                                    _c("div", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            row.technicianIds
                                                              .map(
                                                                (item) =>
                                                                  item.realName
                                                              )
                                                              .join("、")
                                                          ) +
                                                          " "
                                                      ),
                                                      _c("i", {
                                                        staticClass:
                                                          "iconfont grow-a-icon_fillout color666 el-icon--right pointer",
                                                        staticStyle: {
                                                          "font-size": "14px",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.showSelectTechniciansDialog(
                                                              row,
                                                              $index
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]),
                                                  ])
                                                : _c("div", [
                                                    _c(
                                                      "span",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "auth",
                                                            rawName: "v-auth",
                                                            value: 95,
                                                            expression: "95",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "blue pointer",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.showSelectTechniciansDialog(
                                                              row,
                                                              $index
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("点击设置")]
                                                    ),
                                                  ]),
                                            ])
                                          : _c("div", [
                                              _vm.$lodash.get(
                                                row,
                                                "technicianIds.length",
                                                false
                                              )
                                                ? _c("div", [
                                                    _c("div", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            row.technicianIds
                                                              .map(
                                                                (item) =>
                                                                  item.realName
                                                              )
                                                              .join("、")
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                                  ])
                                                : _vm._e(),
                                            ]),
                                        _c("div", { staticClass: "orange" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("dict")(
                                                  row.dispatchTaskState,
                                                  "dispatchTaskState"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2415746645
                            ),
                          }),
                          _c("template", { slot: "empty" }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "border-bottom": "1px solid #ebeef5",
                                },
                              },
                              [_vm._v("未添加服务")]
                            ),
                          ]),
                          _c("template", { slot: "append" }, [
                            _c(
                              "div",
                              { staticClass: "flex add-service-item" },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { width: "160px" } },
                                  [
                                    _c("select-service-popover", {
                                      attrs: {
                                        billType: _vm.billType,
                                        clientAgreeId:
                                          _vm.customerAgreementId ||
                                          (_vm.isOthersSelfPayState
                                            ? ""
                                            : _vm.carAgreementId),
                                        carId: _vm.carInfo.carId,
                                        billId: _vm.form.billId,
                                        billCompanyId: _vm.createCompanyId,
                                      },
                                      on: { select: _vm.onSelectServiceItem },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "auth",
                                        rawName: "v-auth",
                                        value: 25,
                                        expression: "25",
                                      },
                                    ],
                                    staticClass: "orange-btn",
                                    staticStyle: { "margin-left": "20px" },
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        _vm.addServiceDialogVisibility = true
                                      },
                                    },
                                  },
                                  [_vm._v("新增服务")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "修改单据金额",
              visible: _vm.changeTotalPriceDialogVisible,
              width: "400px",
              "close-on-click-modal": false,
            },
            on: {
              "update:visible": function ($event) {
                _vm.changeTotalPriceDialogVisible = $event
              },
            },
          },
          [
            _c(
              "template",
              { slot: "title" },
              [
                _c("span", [_vm._v("修改单据金额")]),
                _c(
                  "el-tooltip",
                  { attrs: { effect: "light", placement: "bottom-start" } },
                  [
                    _c("template", { slot: "content" }, [
                      _c(
                        "p",
                        {
                          staticClass: "color666",
                          staticStyle: { "line-height": "20px" },
                        },
                        [
                          _vm._v(
                            " 整个折扣分摊到单个项目规则,非最后一单项按计算整单折扣分摊金额:"
                          ),
                          _c("br"),
                          _vm._v(
                            " 单折后小计=单项折后小计-单项折后金额/整单斤1折前金额合计X整单"
                          ),
                          _c("br"),
                          _vm._v(
                            " 的折扣优惠(仅显示两个小数位,四舍五入)金最后一单项分摊金额=该"
                          ),
                          _c("br"),
                          _vm._v(" 单据剩余未分摊金额; "),
                        ]
                      ),
                    ]),
                    _c("i", {
                      staticClass:
                        "iconfont grow-icon_doubt color666 pointer el-icon--right",
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { color: "#333" } },
              [
                _c(
                  "el-form",
                  { attrs: { "label-width": "100px" } },
                  [
                    _c("el-form-item", { attrs: { label: "折前金额" } }, [
                      _vm._v(
                        " ¥" +
                          _vm._s(_vm._f("toThousands")(_vm.billTotalPrice)) +
                          " "
                      ),
                    ]),
                    _c(
                      "el-form-item",
                      { attrs: { label: "折扣" } },
                      [
                        _c("number-input", {
                          attrs: {
                            size: "small",
                            max: 9.9,
                            decimalDigits: 1,
                            placeholder: "无",
                          },
                          on: {
                            input: _vm.changeBillDiscountTemp,
                            blur: _vm.changeBillDiscountTemp,
                            clear: _vm.changeBillDiscountTemp,
                          },
                          model: {
                            value: _vm.billDiscountTemp,
                            callback: function ($$v) {
                              _vm.billDiscountTemp = $$v
                            },
                            expression: "billDiscountTemp",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "折后金额" } },
                      [
                        _c("number-input", {
                          attrs: {
                            size: "small",
                            decimalDigits: 2,
                            clearable: false,
                            placeholder: "无",
                          },
                          on: { blur: _vm.changeBillDiscountPriceTemp },
                          model: {
                            value: _vm.billDiscountPriceTemp,
                            callback: function ($$v) {
                              _vm.billDiscountPriceTemp = $$v
                            },
                            expression: "billDiscountPriceTemp",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { attrs: { slot: "footer" }, slot: "footer" },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        _vm.changeTotalPriceDialogVisible = false
                      },
                    },
                  },
                  [_vm._v("取 消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.confirmBillDiscount },
                  },
                  [_vm._v("确 定")]
                ),
              ],
              1
            ),
          ],
          2
        ),
        _c("add-car-dialog", {
          attrs: { showAddCarDialog: _vm.addCarDialogVisibility },
          on: {
            "update:showAddCarDialog": function ($event) {
              _vm.addCarDialogVisibility = $event
            },
            "update:show-add-car-dialog": function ($event) {
              _vm.addCarDialogVisibility = $event
            },
            save: _vm.onAddCar,
          },
        }),
        _vm.multipleSelectServiceDrawerVisibility
          ? _c("select-services-for-car-bill", {
              attrs: {
                visibility: _vm.multipleSelectServiceDrawerVisibility,
                oldData: _vm.serviceTableData,
                carId: _vm.carInfo.carId,
                billId: _vm.form.billId,
                clientAgreeId:
                  _vm.customerAgreementId ||
                  (_vm.isOthersSelfPayState ? "" : _vm.carAgreementId),
                billCompanyId: _vm.createCompanyId,
              },
              on: {
                "update:visibility": function ($event) {
                  _vm.multipleSelectServiceDrawerVisibility = $event
                },
                select: _vm.multipleSelectService,
              },
            })
          : _vm._e(),
        _vm.selectRepairTracingBillDrawerVisibility
          ? _c("selectRepairTracingBillDrawer", {
              attrs: {
                visibility: _vm.selectRepairTracingBillDrawerVisibility,
                carId: _vm.carInfo.carId,
              },
              on: {
                "update:visibility": function ($event) {
                  _vm.selectRepairTracingBillDrawerVisibility = $event
                },
                select: _vm.onSelectRepairTracingBill,
              },
            })
          : _vm._e(),
        _vm.multipleSelectGoodsDrawerVisibility
          ? _c("select-goods-for-car-bill", {
              attrs: {
                visibility: _vm.multipleSelectGoodsDrawerVisibility,
                oldData: _vm.editRow.billGoods || [],
                serviceProducts: _vm.serviceProducts,
                isAgreePrice: _vm.editRow.isAgreePrice,
                serviceItemId: _vm.editRow.serviceItemId,
                carId: _vm.carInfo.carId,
                carModel: _vm.carModelKeyword,
                isMatchThisModel:
                  _vm.carModelKeyword === _vm.oldCarModelName ? 1 : 0,
                billType: _vm.billType,
                clientAgreeId:
                  _vm.customerAgreementId ||
                  (_vm.isOthersSelfPayState ? "" : _vm.carAgreementId),
                billCompanyId: _vm.createCompanyId,
              },
              on: {
                "update:visibility": function ($event) {
                  _vm.multipleSelectGoodsDrawerVisibility = $event
                },
                "update:carModel": function ($event) {
                  _vm.carModelKeyword = $event
                },
                "update:car-model": function ($event) {
                  _vm.carModelKeyword = $event
                },
                select: _vm.multipleSelectGoods,
              },
            })
          : _vm._e(),
        _vm.selectTechniciansDialogVisibility
          ? _c("select-technicians-dialog", {
              attrs: { visibility: _vm.selectTechniciansDialogVisibility },
              on: {
                "update:visibility": function ($event) {
                  _vm.selectTechniciansDialogVisibility = $event
                },
                select: _vm.handleSelectTechnicians,
              },
            })
          : _vm._e(),
        _vm.selectSalesmanDialogVisibility
          ? _c("select-salesman-dialog", {
              attrs: { visibility: _vm.selectSalesmanDialogVisibility },
              on: {
                "update:visibility": function ($event) {
                  _vm.selectSalesmanDialogVisibility = $event
                },
                select: _vm.handleSelectSalesman,
              },
            })
          : _vm._e(),
        _vm.maintenancePlanDialogVisibility
          ? _c("maintenance-plan-dialog", {
              attrs: {
                maintenancePlanDialogVisibility:
                  _vm.maintenancePlanDialogVisibility,
                carInfo: _vm.carInfo,
                mileage: _vm.form.mileage,
              },
              on: {
                "update:maintenancePlanDialogVisibility": function ($event) {
                  _vm.maintenancePlanDialogVisibility = $event
                },
                "update:maintenance-plan-dialog-visibility": function ($event) {
                  _vm.maintenancePlanDialogVisibility = $event
                },
              },
            })
          : _vm._e(),
        _vm.remarksDrawerVisibility
          ? _c("remarks-drawer", {
              attrs: {
                remarksDrawerVisibility: _vm.remarksDrawerVisibility,
                clientRemark: _vm.clientRemark,
              },
              on: {
                "update:remarksDrawerVisibility": function ($event) {
                  _vm.remarksDrawerVisibility = $event
                },
                "update:remarks-drawer-visibility": function ($event) {
                  _vm.remarksDrawerVisibility = $event
                },
              },
            })
          : _vm._e(),
        _vm.carBindingCustomerDialogVisibility
          ? _c("car-binding-customer", {
              attrs: {
                visibility: _vm.carBindingCustomerDialogVisibility,
                carId: _vm.carInfo.carId,
              },
              on: {
                "update:visibility": function ($event) {
                  _vm.carBindingCustomerDialogVisibility = $event
                },
                binding: _vm.handleBindingClient,
              },
            })
          : _vm._e(),
        _vm.selectOtherPayClientDialogVisibility
          ? _c("select-other-pay-client-dialog", {
              attrs: { visibility: _vm.selectOtherPayClientDialogVisibility },
              on: {
                "update:visibility": function ($event) {
                  _vm.selectOtherPayClientDialogVisibility = $event
                },
                select: _vm.handleSelectOtherPayClient,
                clear: _vm.handleClearSelectOtherPayClient,
              },
            })
          : _vm._e(),
        _vm.selectOtherClientPushDialogVisibility
          ? _c("select-other-pay-client-dialog", {
              attrs: { visibility: _vm.selectOtherClientPushDialogVisibility },
              on: {
                "update:visibility": function ($event) {
                  _vm.selectOtherClientPushDialogVisibility = $event
                },
                select: _vm.handleSelectOtherClientPush,
                clear: _vm.handleClearSelectOtherClientPush,
              },
            })
          : _vm._e(),
        _vm.selectInsuranceCustomerDialogVisibility
          ? _c("select-other-pay-client-dialog", {
              attrs: {
                visibility: _vm.selectInsuranceCustomerDialogVisibility,
              },
              on: {
                "update:visibility": function ($event) {
                  _vm.selectInsuranceCustomerDialogVisibility = $event
                },
                select: _vm.handleSelectInsuranceCustomer,
                clear: _vm.handleClearSelectInsuranceCustomer,
              },
            })
          : _vm._e(),
        _vm.editCarBillGoodsDrawerVisibility
          ? _c("edit-car-bill-goods-drawer", {
              attrs: {
                visibility: _vm.editCarBillGoodsDrawerVisibility,
                carId: _vm.carInfo.carId,
                list: _vm.exceptionList,
                treeData: _vm.goodsTree,
              },
              on: {
                "update:visibility": function ($event) {
                  _vm.editCarBillGoodsDrawerVisibility = $event
                },
                save: _vm.mergeGoods,
              },
            })
          : _vm._e(),
        _vm.clientCommandDrawerVisibility
          ? _c("client-command-drawer", {
              attrs: {
                visibility: _vm.clientCommandDrawerVisibility,
                clientId: _vm.carInfo.clientId,
                defaultActiveName: _vm._f("dict")(
                  _vm.billType,
                  "defaultActiveName"
                ),
              },
              on: {
                "update:visibility": function ($event) {
                  _vm.clientCommandDrawerVisibility = $event
                },
              },
            })
          : _vm._e(),
        _vm.addServiceDialogVisibility
          ? _c("add-service-dialog", {
              attrs: {
                showServiceManagementDialog: _vm.addServiceDialogVisibility,
              },
              on: {
                "update:showServiceManagementDialog": function ($event) {
                  _vm.addServiceDialogVisibility = $event
                },
                "update:show-service-management-dialog": function ($event) {
                  _vm.addServiceDialogVisibility = $event
                },
              },
            })
          : _vm._e(),
        _vm.addGoodsDialogVisibility
          ? _c("add-goods-dialog", {
              attrs: { visibility: _vm.addGoodsDialogVisibility },
              on: {
                "update:visibility": function ($event) {
                  _vm.addGoodsDialogVisibility = $event
                },
              },
            })
          : _vm._e(),
        _vm.carFactoryCompensationActivityDialogVisibility
          ? _c("carFactoryCompensationActivityDialog", {
              attrs: {
                visibility: _vm.carFactoryCompensationActivityDialogVisibility,
                data: _vm.carFactoryCompensationActivityData,
              },
              on: {
                "update:visibility": function ($event) {
                  _vm.carFactoryCompensationActivityDialogVisibility = $event
                },
                serviceItemClick: _vm.onAddCarFactoryCompensationActivity,
              },
            })
          : _vm._e(),
      ],
      _c("template", { slot: "footer" }, [
        ![0, 1, 2, 3].includes(this.typeStatus)
          ? _c("div", { staticClass: "footer-box" }, [
              _c("div", [
                _c("div", { staticClass: "bill-price-box" }, [
                  _c("span", { staticClass: "color333" }, [
                    _vm._v(" 单据总额："),
                  ]),
                  _c("span", { staticClass: "rmb" }, [_vm._v("¥")]),
                  _c("span", [
                    _c(
                      "span",
                      {
                        staticClass: "price-number",
                        staticStyle: { "margin-right": "4px" },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm._f("toThousands")(_vm.billDiscountTotalPrice)
                          )
                        ),
                      ]
                    ),
                    _vm.billDiscountTotalPrice != _vm.billTotalPrice
                      ? _c("del", { staticClass: "original-price color999" }, [
                          _vm._v(
                            "¥" +
                              _vm._s(_vm._f("toThousands")(_vm.billTotalPrice))
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _c("i", {
                    staticClass:
                      "iconfont grow-a-icon_fillout color666 el-icon--right pointer",
                    on: { click: _vm.showBillDiscountEditDialog },
                  }),
                ]),
                _c("p", { staticClass: "price-detail" }, [
                  _vm._v(
                    " 项目报价总额：¥" +
                      _vm._s(_vm._f("toThousands")(_vm.billTotalPrice))
                  ),
                  _vm.billDiscountTotalPrice != _vm.billTotalPrice
                    ? _c("span", [_vm._v("（")])
                    : _vm._e(),
                  _vm._v(
                    "服务项目：¥" +
                      _vm._s(_vm._f("toThousands")(_vm.serviceTotalPrice)) +
                      " + 商品项目：¥" +
                      _vm._s(_vm._f("toThousands")(_vm.goodsTotalPrice))
                  ),
                  _vm.billDiscountTotalPrice != _vm.billTotalPrice
                    ? _c("span", [
                        _vm._v(
                          "）- 整单折扣优惠：¥" +
                            _vm._s(
                              _vm._f("toThousands")(
                                _vm.billTotalPrice - _vm.billDiscountTotalPrice
                              )
                            )
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.saveBill },
                    },
                    [_vm._v(_vm._s(_vm.id ? "保 存" : "接待开单"))]
                  ),
                  _vm.id
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "small" },
                          on: { click: _vm.verifyGoodsQuantity },
                        },
                        [_vm._v("商品检测")]
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    { attrs: { size: "small" }, on: { click: _vm.close } },
                    [_vm._v("取 消")]
                  ),
                ],
                1
              ),
            ])
          : _c(
              "div",
              {
                staticClass: "footer-box",
                staticStyle: { "justify-content": "flex-end" },
              },
              [
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: { click: _vm.saveBill },
                      },
                      [_vm._v("变更")]
                    ),
                    _c(
                      "el-button",
                      { attrs: { size: "small" }, on: { click: _vm.close } },
                      [_vm._v("取 消")]
                    ),
                  ],
                  1
                ),
              ]
            ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }