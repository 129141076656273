import http from '@/service/http';

//获取配置施工单事件列表
export function getDispatchStateList(data) {
  return http.post('/admin/sys/dispatchState/list', data);
}
//新增或更新施工单配置
export function updateDispatchState(data) {
  return http.post('/admin/sys/dispatchState', data, {
    onlyReturnData: false,
  });
}
