import {
  getBillLockState,
  getBillLocakRelease,
  getCreateBillLocked,
} from '@/api/financial/pickSingle';
import { MessageBox } from 'element-ui';
import { Message } from 'element-ui';
export default async function verifyBeforeEditCarBill(billId) {
  //检验锁单接口
  let res = await getBillLockState(billId);
  if (res.data?.code !== 200) {
    let needLockRelease = await MessageBox({
      title: '温馨提示',
      message: res.data.message,
      showCancelButton: true,
      confirmButtonText: '确定',
      cancelButtonText: '取消',
    });
    console.log(needLockRelease);
    if (needLockRelease === 'confirm') {
      //解锁接口
      let lockReleaseState = await getBillLocakRelease(billId);
      if (lockReleaseState.code === 200) {
        Message({
          message: '解锁成功!',
          type: 'success',
        });
        //上锁接口
        let createBillLock = await getCreateBillLocked(billId);
        if (createBillLock.data?.code === 200) {
          Message({
            message: '上锁成功!',
            type: 'success',
          });
          return true;
        }
      }
    }
  } else {
    getCreateBillLocked(billId);
    return true;
  }
}
