var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-drawer",
    {
      attrs: {
        title: "接车单详情",
        visible: _vm.dialogTableVisible,
        size: "58%",
        "append-to-body": true,
        "before-close": _vm.handleClose,
        modal: "",
        "close-on-press-escape": "",
        wrapperClosable: "",
      },
    },
    [
      _c(
        "div",
        { staticStyle: { "margin-left": "25px" } },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "基础信息", name: "first" } },
                [
                  _c("div", { staticClass: "car_state" }, [
                    _c("div", { staticClass: "car_state_sh" }, [
                      _c("p", [
                        _vm._v("接车单号：" + _vm._s(_vm.billMessage.billCode)),
                      ]),
                      _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("dict")(
                                _vm.billMessage.billType,
                                "carBillType"
                              )
                            ) +
                            "业务 "
                        ),
                        _vm.billMessage.dispatchState
                          ? _c("span", { staticClass: "el-icon--right" }, [
                              _vm._v(
                                "（" +
                                  _vm._s(
                                    _vm._f("dict")(
                                      _vm.billMessage.dispatchState,
                                      "dispatchState"
                                    )
                                  ) +
                                  "）"
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                    _c("div", { staticClass: "bill-process flex-y-center" }, [
                      _c("div", { staticClass: "state-name" }, [
                        _c("span", [_vm._v("接待开单")]),
                        _c("span", { staticClass: "time" }, [
                          _vm._v(_vm._s(_vm.billMessage.dateCreated)),
                        ]),
                      ]),
                      _c("div", { staticClass: "split-string" }, [
                        _c("div", { staticClass: "top-text" }, [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.billMessage.planFinishTime,
                                  expression: "billMessage.planFinishTime",
                                },
                              ],
                            },
                            [
                              _vm._v(
                                " 预交车: " +
                                  _vm._s(_vm.billMessage.planFinishTime) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.billMessage.waitInStore,
                                  expression: "billMessage.waitInStore",
                                },
                              ],
                            },
                            [_vm._v("在店等")]
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.billMessage.undetermined,
                                  expression: "billMessage.undetermined",
                                },
                              ],
                            },
                            [_vm._v("待定")]
                          ),
                        ]),
                        _c("div", { staticClass: "line" }),
                        _c("div", { staticClass: "bottom-text orange" }, [
                          _vm.billMessage.planFinishTime
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.distancePlannedTime)),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                      _c("div", { staticClass: "state-name" }, [
                        _c("div", { staticClass: "top-text" }),
                        _c("span", [_vm._v("质检交车")]),
                        _c("span", { staticClass: "time" }),
                      ]),
                      _c("div", { staticClass: "split-string" }, [
                        _c("div", { staticClass: "top-text plan-time" }),
                        _c("div", { staticClass: "line" }),
                        _c("div", { staticClass: "bottom-text orange" }),
                      ]),
                      _c("div", { staticClass: "state-name" }, [
                        _c("div", { staticClass: "top-text" }),
                        _c("span", [_vm._v("提交结账")]),
                        _c("span", { staticClass: "time" }),
                      ]),
                      _c("div", { staticClass: "split-string" }, [
                        _c("div", { staticClass: "top-text orange" }, [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.billMessage.takeTime,
                                  expression: "billMessage.takeTime",
                                },
                              ],
                            },
                            [_vm._v(" 客户提前提车")]
                          ),
                        ]),
                        _c("div", { staticClass: "line" }),
                        _c("div", { staticClass: "bottom-text orange" }, [
                          _vm._v(_vm._s(_vm.billMessage.takeTime)),
                        ]),
                      ]),
                      _c("div", { staticClass: "state-name" }, [
                        _c("div", { staticClass: "top-text orange" }, [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.billMessage.takeTime,
                                  expression: "billMessage.takeTime",
                                },
                              ],
                            },
                            [_vm._v(" 客户提前提车")]
                          ),
                        ]),
                        _c("span", [_vm._v("车单结账")]),
                        _vm.billMessage.checkDate
                          ? _c("span", { staticClass: "time" }, [
                              _vm._v(_vm._s(_vm.billMessage.checkDate)),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "cart_sty" }, [
                    _c("div", { staticClass: "car-logo" }, [
                      _vm.carInfo.carLogoIcon
                        ? _c("img", { attrs: { src: _vm.carInfo.carLogoIcon } })
                        : _c("img", {
                            attrs: { src: require("@/assets/img/carLogo.png") },
                          }),
                    ]),
                    _c("div", { staticStyle: { "margin-top": "19px" } }, [
                      _c("p", { staticClass: "car-info" }, [
                        _c("span", { staticClass: "car_number" }, [
                          _vm._v(_vm._s(_vm.carInfo.carNumber)),
                        ]),
                        _vm.carInfo.vehicleType
                          ? _c(
                              "span",
                              { staticClass: "tag car-vehicle-type" },
                              [_vm._v(_vm._s(_vm.carInfo.vehicleType))]
                            )
                          : _vm._e(),
                        _vm.carInfo.uniqueId
                          ? _c("span", { staticClass: "tag car-uniqueId" }, [
                              _vm._v("VIN: " + _vm._s(_vm.carInfo.uniqueId)),
                            ])
                          : _vm._e(),
                        _vm.carInfo.brandName
                          ? _c("span", { staticClass: "tag car-brand" }, [
                              _vm._v(_vm._s(_vm.carInfo.brandName)),
                            ])
                          : _vm._e(),
                        _c("span", { staticClass: "tag history" }, [
                          _vm._v(
                            "历史进店 " +
                              _vm._s(_vm.carInfo.intoStoresCount) +
                              " 次"
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "client-base-info flex" }, [
                        _c("i", {
                          staticClass: "iconfont grow-icon_person icon-person",
                        }),
                        _c("span", { staticClass: "custer_wrd" }, [
                          _vm._v(_vm._s(_vm.carInfo.clientName)),
                        ]),
                        _c("i", {
                          staticClass: "iconfont icon_grade",
                          class: _vm.levelIcons[_vm.carInfo.clientLevel],
                          attrs: {
                            title:
                              _vm.levelIconsWord[_vm.carInfo.clientLevel] || "",
                          },
                        }),
                        _vm.carInfo.clientRemark
                          ? _c(
                              "span",
                              {
                                staticClass: "blue pointer",
                                staticStyle: { "margin-left": "10px" },
                                on: {
                                  click: function ($event) {
                                    _vm.remarksDrawerVisibility = true
                                  },
                                },
                              },
                              [_vm._v("客户备注")]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                  _c(
                    "el-descriptions",
                    {
                      attrs: {
                        column: 3,
                        border: "",
                        labelClassName: "table-label",
                      },
                    },
                    [
                      _vm.billMessage.billType === 0
                        ? _c(
                            "el-descriptions-item",
                            { attrs: { span: 3 } },
                            [
                              _c(
                                "template",
                                { slot: "label" },
                                [
                                  _c("el-switch", {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.otherData.isOthersSelfPayState,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.otherData,
                                          "isOthersSelfPayState",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "otherData.isOthersSelfPayState",
                                    },
                                  }),
                                  _vm._v(" 他人自费 "),
                                ],
                                1
                              ),
                              _vm.otherData.isOthersSelfPayState &&
                              [0].includes(_vm.billMessage.billType)
                                ? _c("div", [
                                    _c(
                                      "div",
                                      { staticClass: "client-base-info flex" },
                                      [
                                        _vm.payerInfo.consumerName
                                          ? _c("i", {
                                              staticClass:
                                                "iconfont grow-icon_person icon-person",
                                            })
                                          : _vm._e(),
                                        _c(
                                          "span",
                                          { staticClass: "custer_wrd" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.payerInfo.consumerName
                                              ) +
                                                " " +
                                                _vm._s(
                                                  _vm.payerInfo.consumerPhone
                                                )
                                            ),
                                          ]
                                        ),
                                        _c("i", {
                                          staticClass: "iconfont icon_sex",
                                          class: {
                                            "grow-icon_man":
                                              _vm.payerInfo.clientGender == 0,
                                            "grow-icon_girl":
                                              _vm.payerInfo.clientGender == 1,
                                          },
                                        }),
                                        _c("i", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.payerInfo.isVip === 1,
                                              expression:
                                                "payerInfo.isVip === 1",
                                            },
                                          ],
                                          staticClass:
                                            "iconfont grow-icon_grade icon_grade",
                                          class:
                                            _vm.levelIcons[
                                              _vm.payerInfo.clientLevel
                                            ],
                                        }),
                                        _vm.payerInfo.clientId
                                          ? _c(
                                              "p",
                                              {
                                                staticClass: "client-vip-info",
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "iconfont grow-icon_vip",
                                                  class: {
                                                    orange: _vm.payerInfo.isVip,
                                                    color999:
                                                      !_vm.payerInfo.isVip,
                                                  },
                                                }),
                                                _vm.payerInfo.isVip
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass: "vip-text",
                                                      },
                                                      [
                                                        false
                                                          ? _c("span", [
                                                              _vm._v(
                                                                " 会员客户 ( 会员卡: " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .payerInfo
                                                                      .billClientMemberMessageVo
                                                                      .memberCardNums
                                                                  ) +
                                                                  "张，余额: ¥" +
                                                                  _vm._s(
                                                                    _vm
                                                                      .payerInfo
                                                                      .billClientMemberMessageVo
                                                                      .memberBalance
                                                                  ) +
                                                                  "，余次: " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .payerInfo
                                                                      .billClientMemberMessageVo
                                                                      .memberResidueDegree
                                                                  ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .payerInfo
                                                                      .billClientMemberMessageVo
                                                                      .isAgreementClient
                                                                      ? "；客户协议 - 包干"
                                                                      : ""
                                                                  ) +
                                                                  " ) "
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  : _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "10px",
                                                          "font-size": "14px",
                                                        },
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("非会员"),
                                                        ]),
                                                      ]
                                                    ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm.billMessage.billType == 2
                        ? _c(
                            "el-descriptions-item",
                            { attrs: { span: 3, label: "理赔信息" } },
                            [
                              _c(
                                "div",
                                { staticStyle: { "line-height": "30px" } },
                                [
                                  _c("p", [
                                    _vm._v(
                                      " 保险公司：" +
                                        _vm._s(
                                          _vm.carBillInsuranceInfo
                                            .claimsInsuranceCompanyName
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      "开票主体：" +
                                        _vm._s(
                                          _vm.carBillInsuranceInfo
                                            .insuranceCompanyName
                                        )
                                    ),
                                  ]),
                                  _c("p", [
                                    _vm._v(" 报案号："),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "#3eb1e0",
                                          "margin-right": "13px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.carBillInsuranceInfo.caseNum
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#ff9200" } },
                                      [_vm._v("（待报损）")]
                                    ),
                                  ]),
                                ]
                              ),
                              !_vm.carBillInsuranceInfo.runningLicenceImg &&
                              !_vm.carBillInsuranceInfo.picDrivingLicence &&
                              !_vm.carBillInsuranceInfo.feeListPicA &&
                              !_vm.carBillInsuranceInfo.feeListPicB &&
                              !_vm.carBillInsuranceInfo.feeListPicC &&
                              _vm.carBillInsuranceInfo.feeListPicOthers
                                .length == 0
                                ? _c("div", { staticClass: "color999" }, [
                                    _vm._v(" 无附件图片 "),
                                  ])
                                : _c(
                                    "div",
                                    { staticClass: "h100" },
                                    [
                                      _vm.carBillInsuranceInfo.runningLicenceImg
                                        ? _c("upload-file", {
                                            attrs: {
                                              title: "行驶证",
                                              disabled: "",
                                            },
                                            model: {
                                              value:
                                                _vm.carBillInsuranceInfo
                                                  .runningLicenceImg,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.carBillInsuranceInfo,
                                                  "runningLicenceImg",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "carBillInsuranceInfo.runningLicenceImg",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.carBillInsuranceInfo.picDrivingLicence
                                        ? _c("upload-file", {
                                            attrs: {
                                              title: "驾驶证",
                                              disabled: "",
                                            },
                                            model: {
                                              value:
                                                _vm.carBillInsuranceInfo
                                                  .picDrivingLicence,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.carBillInsuranceInfo,
                                                  "picDrivingLicence",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "carBillInsuranceInfo.picDrivingLicence",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.carBillInsuranceInfo.feeListPicA
                                        ? _c("upload-file", {
                                            attrs: {
                                              title: "定损单1",
                                              disabled: "",
                                            },
                                            model: {
                                              value:
                                                _vm.carBillInsuranceInfo
                                                  .feeListPicA,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.carBillInsuranceInfo,
                                                  "feeListPicA",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "carBillInsuranceInfo.feeListPicA",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.carBillInsuranceInfo.feeListPicB
                                        ? _c("upload-file", {
                                            attrs: {
                                              title: "定损单2",
                                              disabled: "",
                                            },
                                            model: {
                                              value:
                                                _vm.carBillInsuranceInfo
                                                  .feeListPicB,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.carBillInsuranceInfo,
                                                  "feeListPicB",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "carBillInsuranceInfo.feeListPicB",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.carBillInsuranceInfo.feeListPicC
                                        ? _c("upload-file", {
                                            attrs: {
                                              title: "定损单3",
                                              disabled: "",
                                            },
                                            model: {
                                              value:
                                                _vm.carBillInsuranceInfo
                                                  .feeListPicC,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.carBillInsuranceInfo,
                                                  "feeListPicC",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "carBillInsuranceInfo.feeListPicC",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.carBillInsuranceInfo.feeListPicOthers
                                        ? _c("upload-file-list", {
                                            attrs: { disabled: "" },
                                            model: {
                                              value:
                                                _vm.carBillInsuranceInfo
                                                  .feeListPicOthers,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.carBillInsuranceInfo,
                                                  "feeListPicOthers",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "carBillInsuranceInfo.feeListPicOthers",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                              _c("p", [
                                _vm._v(
                                  " 事故责任： " +
                                    _vm._s(
                                      _vm._f("dict")(
                                        _vm.carBillInsuranceInfo.dutyType,
                                        "dutyType"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.billMessage.billType == 3
                        ? _c(
                            "el-descriptions-item",
                            { attrs: { span: 3, label: "索赔信息" } },
                            [
                              _c(
                                "div",
                                { staticStyle: { "line-height": "30px" } },
                                [
                                  _c("p", [
                                    _vm._v(
                                      " 三包厂家： " +
                                        _vm._s(
                                          _vm.carBillUndertakeInfo
                                            .undertakeInsuranceCompanyName
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      " 开票主体： " +
                                        _vm._s(
                                          _vm.carBillUndertakeInfo
                                            .undertakeCompanyName
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    [
                                      _vm._v(" 索赔单号： "),
                                      _vm._l(
                                        _vm.carBillUndertakeInfo.claimsNum,
                                        function (item, index) {
                                          return _c(
                                            "p",
                                            {
                                              key: index,
                                              staticClass: "blue pointer",
                                            },
                                            [_vm._v(" " + _vm._s(item) + " ")]
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "flex-y-center h100" },
                                [
                                  _vm.carBillUndertakeInfo.undertakePics
                                    .length != 0
                                    ? _c("upload-file-list", {
                                        attrs: {
                                          disabled: "",
                                          value:
                                            _vm.carBillUndertakeInfo
                                              .undertakePics,
                                        },
                                      })
                                    : _c("div", { staticClass: "color999" }, [
                                        _vm._v("无附件图片"),
                                      ]),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "业务来源" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.billMessage.billSourceTypeName) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "接车人" } },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.billMessage.pickUpUserName) + " "
                          ),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "施工类型" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("dict")(
                                  _vm.billMessage.difficulty,
                                  "difficulty"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "送修司机信息" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.billMessage.customerName) +
                              " " +
                              _vm._s(_vm.billMessage.customerPhone) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "进店里程" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.billMessage.mileage
                                  ? _vm.billMessage.mileage + " KM"
                                  : ""
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "油(电)表" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("dict")(
                                  _vm.billMessage.oilMeter,
                                  "energyPercentage"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "预交车时间", span: 3 } },
                        [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.billMessage.planFinishTime,
                                  expression: "billMessage.planFinishTime",
                                },
                              ],
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.billMessage.planFinishTime) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.billMessage.waitInStore,
                                  expression: "billMessage.waitInStore",
                                },
                              ],
                            },
                            [_vm._v("在店等")]
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.billMessage.undetermined,
                                  expression: "billMessage.undetermined",
                                },
                              ],
                            },
                            [_vm._v("待定")]
                          ),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "车主要求", span: 3 } },
                        [_vm._v(" " + _vm._s(_vm.billMessage.remark) + " ")]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "初诊与备注", span: 3 } },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.billMessage.firstVisitRemark) + " "
                          ),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "环检图片", span: 3 } },
                        [
                          Object.keys(_vm.billMessage.carBodyInfoVo || {})
                            .length === 0
                            ? _c("p", [_vm._v(" 无环检图片 ")])
                            : _c(
                                "div",
                                { staticClass: "flex color666" },
                                [
                                  _vm.billMessage.carBodyInfoVo
                                    .carBodyPositivePic
                                    ? _c("upload-file", {
                                        attrs: {
                                          value: _vm.$lodash.get(
                                            _vm.billMessage,
                                            "carBodyInfoVo.carBodyPositivePic",
                                            ""
                                          ),
                                          title: "车身正面",
                                          disabled: "",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.billMessage.carBodyInfoVo.carBodyLeftPic
                                    ? _c("upload-file", {
                                        attrs: {
                                          value: _vm.$lodash.get(
                                            _vm.billMessage,
                                            "carBodyInfoVo.carBodyLeftPic",
                                            ""
                                          ),
                                          title: "车身左面",
                                          disabled: "",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.billMessage.carBodyInfoVo.carBodyBehindPic
                                    ? _c("upload-file", {
                                        attrs: {
                                          value: _vm.$lodash.get(
                                            _vm.billMessage,
                                            "carBodyInfoVo.carBodyBehindPic",
                                            ""
                                          ),
                                          title: "车身后面",
                                          disabled: "",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.billMessage.carBodyInfoVo.carBodyRightPic
                                    ? _c("upload-file", {
                                        attrs: {
                                          value: _vm.$lodash.get(
                                            _vm.billMessage,
                                            "carBodyInfoVo.carBodyRightPic",
                                            ""
                                          ),
                                          title: "车身右面",
                                          disabled: "",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.billMessage.carBodyInfoVo
                                    .runningLicenceImg
                                    ? _c("upload-file", {
                                        attrs: {
                                          value: _vm.$lodash.get(
                                            _vm.billMessage,
                                            "carBodyInfoVo.runningLicenceImg",
                                            ""
                                          ),
                                          title: "行驶证",
                                          disabled: "",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.billMessage.carBodyInfoVo
                                    .carInstrumentPanelPic
                                    ? _c("upload-file", {
                                        attrs: {
                                          value: _vm.$lodash.get(
                                            _vm.billMessage,
                                            "carBodyInfoVo.carInstrumentPanelPic",
                                            ""
                                          ),
                                          title: "仪表盘",
                                          disabled: "",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.billMessage.carBodyInfoVo.carOtherPics
                                    ? _c("upload-file-list", {
                                        attrs: {
                                          max: 3,
                                          value: _vm.$lodash
                                            .get(
                                              _vm.billMessage,
                                              "carBodyInfoVo.carOtherPics",
                                              []
                                            )
                                            .join(),
                                          disabled: "",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "车单内标签与备注", span: 3 } },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.billMessage.companyRemark) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("p", { staticClass: "title_bar" }, [
                        _vm._v("报价项目"),
                      ]),
                      _c(
                        "el-table",
                        {
                          staticClass: "service-table",
                          staticStyle: { width: "100%" },
                          attrs: {
                            border: "",
                            data: _vm.serviceTableData,
                            "default-expand-all": "",
                            "row-key": "id",
                            "tree-props": {
                              children: "billGoods",
                              hasChildren: "hasChildren",
                            },
                            "span-method": _vm.spanMethod,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { width: "110", label: "序号" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    _c("div", [
                                      _vm._v(" " + _vm._s(row.title) + " "),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "报价项目名称", width: "180" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    row.name
                                      ? _c("span", [_vm._v(_vm._s(row.name))])
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "商品单位",
                              prop: "unit",
                              width: "180",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "price",
                              label: "单价",
                              width: "120",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    _c("span", { key: _vm.tableKey + 1 }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("toThousands")(row.price)
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "discount",
                              label: "折扣",
                              width: "120",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    _c("span", { key: _vm.tableKey + 2 }, [
                                      _vm._v(
                                        " " + _vm._s(row.discount || "无") + " "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "date",
                              label: "数量",
                              width: "120",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    _c("span", { key: _vm.tableKey + 3 }, [
                                      row.showAllNum
                                        ? _c(
                                            "span",
                                            { staticClass: "orange" },
                                            [
                                              _c("p", [
                                                _vm._v(
                                                  "报价" + _vm._s(row.num)
                                                ),
                                              ]),
                                              row.waitPickNum
                                                ? _c("p", [
                                                    _vm._v(
                                                      "待领" +
                                                        _vm._s(row.waitPickNum)
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              row.pickedNum
                                                ? _c("p", [
                                                    _vm._v(
                                                      "已领" +
                                                        _vm._s(row.pickedNum)
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ]
                                          )
                                        : _c("span", [_vm._v(_vm._s(row.num))]),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "date",
                              label: "小计",
                              width: "180",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    _c("div", [
                                      _c("span", { key: _vm.tableKey + 4 }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("toThousands")(row.total)
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      ![0, "", undefined].includes(
                                        row.billDiscount
                                      )
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-left": "5px",
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("整单折扣后: "),
                                              ]),
                                              _c("span", [
                                                _vm._v(
                                                  "¥" +
                                                    _vm._s(
                                                      _vm._f("toThousands")(
                                                        row.billDiscount
                                                      )
                                                    )
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "项目合计" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    [0, "", undefined].includes(
                                      row.billDiscount
                                    )
                                      ? _c("div", [
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("toThousands")(
                                                  row.itemTotalBefore
                                                )
                                              )
                                            ),
                                          ]),
                                        ])
                                      : _c("div", [
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("toThousands")(
                                                  row.itemTotalAfter
                                                )
                                              )
                                            ),
                                          ]),
                                          _c("del", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("toThousands")(
                                                  row.itemTotalBefore
                                                )
                                              )
                                            ),
                                          ]),
                                        ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "施工技师", width: "180" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    _c("div", { key: _vm.tableKey + 5 }, [
                                      _vm.$lodash.get(
                                        row,
                                        "technicianInfoVos.length",
                                        false
                                      )
                                        ? _c("div", [
                                            _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$lodash
                                                      .get(
                                                        row,
                                                        "technicianInfoVos",
                                                        []
                                                      )
                                                      .map(
                                                        (item) =>
                                                          item.technicianName
                                                      )
                                                      .join("、")
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ])
                                        : _vm._e(),
                                    ]),
                                    _c("div", { staticClass: "orange" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("dict")(
                                              row.dispatchTaskState,
                                              "dispatchTaskState"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "sellerName",
                              label: "业务员",
                              width: "180",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "address",
                              label: "施工前图片",
                              "min-width": "300",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    _c(
                                      "div",
                                      { key: _vm.tableKey + 6 },
                                      [
                                        _c(
                                          "el-popover",
                                          {
                                            attrs: {
                                              placement: "top",
                                              width: "450",
                                              trigger: "click",
                                            },
                                          },
                                          [
                                            _c("upload-file-list", {
                                              attrs: { disabled: "" },
                                              on: {
                                                input: function ($event) {
                                                  _vm.tableKey = Math.random()
                                                },
                                              },
                                              model: {
                                                value: row.preConstructionPics,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    row,
                                                    "preConstructionPics",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "row.preConstructionPics",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "pointer blue",
                                                staticStyle: {
                                                  margin: "0 16px",
                                                },
                                                attrs: { slot: "reference" },
                                                slot: "reference",
                                              },
                                              [
                                                _vm._v(
                                                  "图片(" +
                                                    _vm._s(
                                                      _vm.$lodash.get(
                                                        row,
                                                        "preConstructionPics",
                                                        []
                                                      ).length
                                                    ) +
                                                    ")"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "address",
                              label: "施工后图片",
                              "min-width": "300",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    _c(
                                      "div",
                                      { key: _vm.tableKey + 7 },
                                      [
                                        _c(
                                          "el-popover",
                                          {
                                            attrs: {
                                              placement: "top",
                                              width: "450",
                                              trigger: "click",
                                            },
                                          },
                                          [
                                            _c("upload-file-list", {
                                              attrs: { disabled: "" },
                                              on: {
                                                input: function ($event) {
                                                  _vm.tableKey = Math.random()
                                                },
                                              },
                                              model: {
                                                value:
                                                  row.afterConstructionPics,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    row,
                                                    "afterConstructionPics",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "row.afterConstructionPics",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "pointer blue",
                                                staticStyle: {
                                                  margin: "0 16px",
                                                },
                                                attrs: { slot: "reference" },
                                                slot: "reference",
                                              },
                                              [
                                                _vm._v(
                                                  "图片(" +
                                                    _vm._s(
                                                      _vm.$lodash.get(
                                                        row,
                                                        "afterConstructionPics",
                                                        []
                                                      ).length
                                                    ) +
                                                    ")"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("template", { slot: "empty" }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "border-bottom": "1px solid #ebeef5",
                                },
                              },
                              [_vm._v("未添加服务")]
                            ),
                          ]),
                        ],
                        2
                      ),
                      _c(
                        "p",
                        {
                          staticClass: "title_bar",
                          staticStyle: {
                            overflow: "auto",
                            "word-break": "break-all",
                            "line-height": "20px",
                            "margin-top": "20px",
                          },
                        },
                        [
                          _vm._v(" 结账收款( "),
                          _c("span", { staticClass: "tips_wordsr" }, [
                            _vm._v("单据总额￥"),
                          ]),
                          _c("span", [
                            _c(
                              "span",
                              { staticClass: "price-number tips_wordsr" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("toThousands")(_vm.otherData.paid)
                                  )
                                ),
                              ]
                            ),
                            _vm.otherData.paid != _vm.otherData.total
                              ? _c(
                                  "del",
                                  {
                                    staticClass: "original-price color999",
                                    staticStyle: { margin: "0px 6px" },
                                  },
                                  [
                                    _vm._v(
                                      "¥" +
                                        _vm._s(
                                          _vm._f("toThousands")(
                                            _vm.otherData.total
                                          )
                                        )
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _c("span", { staticClass: "color333" }, [
                            _vm._v(" =( 项目报价总额：¥"),
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("toThousands")(_vm.otherData.total)
                              ) +
                              " "
                          ),
                          _vm.otherData.paid != _vm.otherData.total
                            ? _c("span", [_vm._v("（")])
                            : _vm._e(),
                          _c("span", { staticClass: "color333" }, [
                            _vm._v(" 服务项目：¥"),
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("toThousands")(_vm.serviceTotalPrice)
                              ) +
                              " "
                          ),
                          _c("span", { staticClass: "color333" }, [
                            _vm._v(" + 商品项目：¥"),
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("toThousands")(_vm.goodsTotalPrice)
                              ) +
                              " "
                          ),
                          _vm.otherData.paid != _vm.otherData.total
                            ? _c("span", { staticClass: "color333" }, [
                                _vm._v(
                                  " ）- 整单折扣优惠：¥" +
                                    _vm._s(
                                      _vm._f("toThousands")(
                                        _vm.otherData.total - _vm.otherData.paid
                                      )
                                    )
                                ),
                              ])
                            : _vm._e(),
                          _c("span", [_vm._v("))")]),
                          _vm.billMessage.billType == 1 &&
                          _vm.billMessage.billState == 1
                            ? _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    effect: "light",
                                    placement: "bottom-start",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "#666666",
                                        "font-size": "13px",
                                        "line-height": "20px",
                                        "text-indent": "15px",
                                      },
                                      attrs: { slot: "content" },
                                      slot: "content",
                                    },
                                    [
                                      _vm._v(
                                        " 包干金额，结账当月显示为查询时点的预分摊"
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "金额，结账次月显示为实际分摊金额 "
                                      ),
                                    ]
                                  ),
                                  _c("i", {
                                    staticClass: "iconfont grow-icon_doubt",
                                    staticStyle: {
                                      color: "#9a9a9a",
                                      margin: "12px 4px 11px 15px",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm.billMessage.billType == 1 &&
                          _vm.billMessage.billState == 1
                            ? _c("span", { staticClass: "tips_wordsr" }, [
                                _vm._v(
                                  "包干金额：￥ " +
                                    _vm._s(
                                      _vm._f("toThousands")(
                                        _vm.otherData.contractAmount
                                      )
                                    )
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "el-table",
                        {
                          attrs: {
                            data: _vm.payTypeTable,
                            border: "",
                            "header-cell-style": {
                              background: "#FAFAFA",
                              color: "#000000",
                              fontSize: "14px",
                              fontWeight: "500",
                            },
                            "span-method": _vm.objectSpanMethod,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              width: "60",
                              label: "序号",
                              type: "index",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "payType",
                              label: "支付方式",
                              "mix-width": "180",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "offerDetails",
                              label: "优惠、抵扣明细",
                              "mix-width": "100",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "money",
                              label: "金额",
                              "mix-width": "100",
                            },
                          }),
                          _c("template", { slot: "append" }, [
                            _c("div", { staticClass: "table_footer" }, [
                              _c("div", [
                                _c(
                                  "p",
                                  { staticClass: "table_footer_deripto" },
                                  [
                                    _vm._v(
                                      " 结账收银员：" +
                                        _vm._s(_vm.payInfo.cashier) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "p",
                                  { staticClass: "table_footer_deriptt" },
                                  [
                                    _vm._v(
                                      " 结账备注：" +
                                        _vm._s(_vm.payInfo.remark) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("div", [
                                _c(
                                  "p",
                                  { staticClass: "table_footer_deripto" },
                                  [
                                    _vm._v(
                                      " 收款总额：￥" +
                                        _vm._s(
                                          _vm._f("toThousands")(
                                            _vm.payInfoTotal
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "p",
                                  { staticClass: "table_footer_deriptt" },
                                  [
                                    _vm._v(
                                      " 结账时间：" +
                                        _vm._s(_vm.payInfo.checkDate) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                        ],
                        2
                      ),
                      _c("p", { staticClass: "title_bar" }, [
                        _vm._v(" 销账收款"),
                        _c("span", { staticClass: "tips_wordsr" }, [
                          _vm._v(
                            "(未销账：￥" +
                              _vm._s(_vm.notChargeOffs - _vm.chargeOffsAmount) +
                              ")"
                          ),
                        ]),
                      ]),
                      _c(
                        "el-table",
                        {
                          attrs: {
                            data: _vm.tableData,
                            border: "",
                            "header-cell-style": {
                              background: "#FAFAFA",
                              color: "#000000",
                              fontSize: "14px",
                              fontWeight: "500",
                            },
                            "span-method": _vm.arraySpanMethod,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { width: "60", label: "序号", prop: "sort" },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "writeOffNum",
                              label: "销账收款单号",
                              width: "180",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "repaymentDateTime",
                              label: "销账时间",
                              width: "100",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "cashier",
                              label: "销账收银人",
                              "min-width": "100",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "支付方式",
                              width: "100",
                              prop: "payType",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "收款金额",
                              "min-width": "100",
                              prop: "money",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "flex-x-end foot-btn" },
                        [
                          _c(
                            "el-button",
                            { attrs: { type: "primary", size: "small" } },
                            [_vm._v("打印结算单")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticStyle: { background: "#f0f2f5" },
                              attrs: { size: "small" },
                            },
                            [_vm._v("打印结算小票")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.remarksDrawerVisibility
        ? _c("remarks-drawer", {
            attrs: {
              remarksDrawerVisibility: _vm.remarksDrawerVisibility,
              clientRemark: _vm.carInfo.clientRemark,
            },
            on: {
              "update:remarksDrawerVisibility": function ($event) {
                _vm.remarksDrawerVisibility = $event
              },
              "update:remarks-drawer-visibility": function ($event) {
                _vm.remarksDrawerVisibility = $event
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }