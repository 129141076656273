<template>
  <el-drawer
    title="销账收款详情"
    :visible="selectDocumentsDrawer"
    size="50%"
    :append-to-body="true"
    :before-close="handleClose"
    modal
    close-on-press-escape
    wrapperClosable
  >
    <div class="conten_body">
      <div style="height: 62vh; overflow-y: scroll">
        <el-form :model="form" label-width="100px" size="small">
          <el-row>
            <el-col :span="10">
              <el-form-item label="挂账日期">
                <el-date-picker
                  class="w100"
                  v-model="form.times"
                  type="daterange"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="起始时间"
                  end-placeholder="截止时间"
                  value-format="yyyy-MM-dd"
                  :clearable="false"
                  @input="dateRangeChange()"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="车牌号">
                <el-input
                  v-model="form.plateNumber"
                  placeholder="请输入"
                  clearable
                  @keyup.enter.native="getList"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <div class="el-form-item--small" style="margin-left: 20%">
                <el-button
                  class="btn_search"
                  type="primary"
                  size="small"
                  @click="getList()"
                  >查询</el-button
                >
                <el-button class="btn_search" size="small" @click="empty"
                  >置空</el-button
                >
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item label="业务来源">
                <el-select
                  v-model="form.sourceId"
                  @change="selectBillSource"
                  @clear="deleteBillSource"
                  filterable
                  clearable
                  remote
                  placeholder="搜索业务来源"
                  :remote-method="searchBillSource"
                  :loading="searchBillSourceLoading"
                >
                  <el-option
                    v-for="item in billSourceList"
                    :key="item.billSourceTypeId"
                    :label="item.name"
                    :value="item.billSourceTypeId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="接车单号">
                <el-input
                  v-model="form.billCode"
                  placeholder="请输入"
                  clearable
                  @keyup.enter.native="getList"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item label="单据号">
                <el-input
                  v-model="form.invoiceNum"
                  placeholder="请输入开发票的单据号"
                  clearable
                  @keyup.enter.native="getList"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="13">
              <el-form-item label="结账收款备注">
                <el-input
                  v-model="form.remark"
                  placeholder="请输入结账收款备注"
                  clearable
                  @keyup.enter.native="getList"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item label="事故责任">
                <el-select
                  v-model="form.dutyType"
                  filterable
                  placeholder="搜索事故责任"
                  clearable
                >
                  <el-option
                    v-for="item in dutyTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <p class="select-word">选择本次开票的单据</p>
        <el-table
          ref="multipleTable"
          style="width: 95%; margin-left: 2.5%"
          :data="tableData"
          border
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column type="index" label="序号" width="60">
          </el-table-column>
          <el-table-column label="接车单号" min-width="200">
            <template slot-scope="scope">
              <p class="blue">{{ scope.row.billCode }}</p>
              <p>{{ scope.row.billType | dict('carBillType') }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="checkDate" label="结账时间" min-width="180">
          </el-table-column>
          <el-table-column
            prop="sourceOfBusiness"
            label="业务来源"
            min-width="100"
          >
          </el-table-column>
          <el-table-column prop="carNumber" label="车牌号" min-width="180">
          </el-table-column>
          <el-table-column prop="billPaid" label="单据金额" min-width="100">
          </el-table-column>
          <el-table-column prop="contractTotal" min-width="200">
            <template slot="header">
              <div class="flex">
                挂账(包干）总额
                <el-tooltip effect="light" placement="bottom-start">
                  <div
                    slot="content"
                    style="
                      color: #666666;
                      font-size: 13px;
                      line-height: 16px;
                      text-indent: 15px;
                    "
                  >
                    包干业务的挂账金额为包干分摊金额，<br />
                    结账当月显示为查询时点的预分摊金额，<br />
                    结账次月显示为实际分摊金额
                  </div>
                  <i
                    class="iconfont grow-icon_doubt"
                    style="margin-left: 5px"
                  ></i>
                </el-tooltip>
              </div>
            </template>
            <template slot-scope="scope">
              <span>{{ scope.row.contractTotal }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="alreadyPaid" label="已收金额" min-width="100">
          </el-table-column>
          <el-table-column
            prop="unclearedDebt"
            label="待收金额"
            min-width="180"
          >
          </el-table-column>
          <el-table-column prop="invoiceNum" label="开票单据号" min-width="100">
          </el-table-column>
          <el-table-column label="事故责任" min-width="100">
            <template #default="{ row }">
              {{ row.dutyType | dict('dutyType') }}
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="备注" min-width="100">
          </el-table-column>
        </el-table>
      </div>

      <div class="footer">
        <p>
          <i class="iconfont grow-icon_warn_32 orange"></i>
          <span
            >共选择门店{{ selectedData.length }}，本次新增选择{{
              addLength
            }}</span
          >
        </p>
        <el-button type="primary" size="small" @click="confirm"
          >确 定</el-button
        >
        <el-button size="small" @click="handleClose">取 消</el-button>
      </div>
    </div>
  </el-drawer>
</template>
<script>
//接口
import { getBillSourceTypeList } from '@/api/billSource';
import { getDetailOnAccountList } from '@/api/financial/financialOver';

// 工具
import { mapState } from 'vuex';
export default {
  name: 'selectDocumentsDrawer',
  props: {
    selectDocumentsDrawer: { type: Boolean, default: false },
    times: {
      type: Array,
      default: () => [],
    },
    selectOldList: {
      type: Array,
      default: () => [],
    },
  },
  components: {},
  data() {
    return {
      form: {},
      //业务来源字段
      searchBillSourceLoading: false,
      billSourceList: [],
      //事故责任字段
      dutyTypeList: [
        {
          value: 0,
          label: '全责',
        },
        {
          value: 1,
          label: '无责',
        },
        {
          value: 2,
          label: '主责',
        },
        {
          value: 3,
          label: '次责',
        },
        {
          value: 4,
          label: '同责',
        },
      ],
      tableData: [],
      //选中
      selectedData: [],
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
    addLength() {
      var l = this.selectedData.length - this.selectOldList.length;
      return l > 0 ? l : 0;
    },
  },
  created() {
    this.form.times = this.times;
    this.getList();
  },
  methods: {
    //下面是解决设置日期默认值时重新选择无法刷新日期的方法
    dateRangeChange() {
      let _this = this;
      _this.$nextTick(() => {
        _this.$forceUpdate();
      });
    },
    //关闭抽屉
    handleClose() {
      this.$emit('update:selectDocumentsDrawer', false);
    },
    //置空
    empty() {
      this.form = {};
    },
    //远程搜索业务来源列表
    searchBillSource(keyWord = '') {
      this.searchBillSourceLoading = true;
      getBillSourceTypeList({ name: keyWord })
        .then((res) => {
          this.billSourceList = res.records;
        })
        .finally(() => {
          this.searchBillSourceLoading = false;
        });
    },
    // 选择业务来源
    selectBillSource(billSourceId) {},
    //删除业务来源
    deleteBillSource() {},

    //获取数据列表
    getList() {
      var allTimes = {
        startDate: this.form.times?.[0]
          ? this.form.times?.[0] + ' 00:00:00'
          : '',
        endDate: this.form.times?.[1] ? this.form.times?.[1] + ' 23:59:59' : '',
        companyId: this.form.companyId || this.companyId,
      };
      var data = Object.assign(this.form, allTimes);
      getDetailOnAccountList(data).then((res) => {
        console.log(res);
        this.tableData = res.records;
        this.syncSelectedStatus();
      });
    },

    //表格选中项
    handleSelectionChange(selectedData) {
      this.selectedData = selectedData;
    },
    /**
     * 同步选中状态
     */
    syncSelectedStatus() {
      this.$nextTick().then(() => {
        if (this.selectOldList?.length) {
          var ids = this.selectOldList.map((item) => item.billId);
          var shouldBeSelected = this.tableData.filter((item) =>
            ids.includes(item.billId)
          );

          shouldBeSelected.forEach((row) => {
            this.$refs.multipleTable.toggleRowSelection(row, true);
          });
        }
      });
    },
    //确认按钮事件
    confirm() {
      let first = this.selectedData[0];
      //判断选中的每一项的客户电话或者是开票主体是否是相同的
      let isHasSame = this.selectedData.every(
        (item) =>
          item.client.clientPhone === first.client.clientPhone &&
          item.invoiceName === item.invoiceName
      );
      console.log('item', this.selectedData);
      if (isHasSame) {
        this.$emit('update:selectDocumentsDrawer', false);
        this.$emit('selectTableList', this.selectedData);
      } else {
        this.$message({
          type: 'warning',
          message: '客户/开票主体不一致',
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
//修改选择时间框长度
.el-form {
  width: 100%;
  .el-form-item__content > div {
    width: 96%;
  }
}
.conten_body {
  margin-top: 30px;
}
.select-word {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  margin: 30px 2.5% 15px;
}
.footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 109px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  padding-right: 60px;
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
  box-shadow: -4px 0px 30px 0px rgb(0 0 0 / 10%);
}
.footer > p {
  margin-right: 30px;
}
.footer span {
  color: #999999;
  font-size: 14px;
  margin-left: 10px;
}
</style>
