var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      ref: "popover",
      staticStyle: { width: "50vw" },
      attrs: {
        placement: "bottom",
        "popper-class": "custom-popover",
        trigger: "manual",
        "open-delay": 200,
        offset: 200,
      },
      model: {
        value: _vm.selectGoodsPopoverVisible,
        callback: function ($$v) {
          _vm.selectGoodsPopoverVisible = $$v
        },
        expression: "selectGoodsPopoverVisible",
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            { attrs: { "label-width": "100px", size: "small" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 4, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "库存大于0" } },
                        [
                          _c("el-checkbox", {
                            staticStyle: { "margin-left": "15px" },
                            on: { change: _vm.changeInStock },
                            model: {
                              value: _vm.pageInStock,
                              callback: function ($$v) {
                                _vm.pageInStock = $$v
                              },
                              expression: "pageInStock",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品品名" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                multiple: "",
                                filterable: "",
                                remote: "",
                                placeholder: "请搜索，支持多选",
                                "remote-method": _vm.searchProductList,
                                loading: _vm.searchProductListLoading,
                                "value-key": "id",
                              },
                              on: { change: _vm.getData },
                              model: {
                                value: _vm.productIds,
                                callback: function ($$v) {
                                  _vm.productIds = $$v
                                },
                                expression: "productIds",
                              },
                            },
                            _vm._l(_vm.productList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "适用车型" } },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              size: "small",
                              placeholder: "请输入适用车型",
                            },
                            on: { input: _vm.handleModelInput },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.getData(true)
                              },
                            },
                            model: {
                              value: _vm.keyWordOfModel,
                              callback: function ($$v) {
                                _vm.keyWordOfModel = $$v
                              },
                              expression: "keyWordOfModel",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "原厂编码" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入原厂编码",
                              clearable: "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.getData(true)
                              },
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.getData(true)
                              },
                            },
                            model: {
                              value: _vm.goodsCode,
                              callback: function ($$v) {
                                _vm.goodsCode = $$v
                              },
                              expression: "goodsCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "入库批次号" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入入库批次号",
                              clearable: "",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.getData(true)
                              },
                            },
                            model: {
                              value: _vm.inventoryInAndOutBillCode,
                              callback: function ($$v) {
                                _vm.inventoryInAndOutBillCode = $$v
                              },
                              expression: "inventoryInAndOutBillCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
          attrs: {
            "max-height": "300px",
            data: _vm.goodsList,
            "show-overflow-tooltip": "",
          },
          on: { "row-click": _vm.selectGoodsRow },
        },
        [
          _c("el-table-column", {
            attrs: { type: "index", label: "序号", width: "60" },
          }),
          _c("el-table-column", {
            attrs: { width: "180", prop: "matchCarType", label: "适用车型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.isAgreePrice
                      ? _c(
                          "el-tag",
                          {
                            staticClass: "el-icon--left",
                            attrs: { type: "info", size: "mini" },
                          },
                          [_vm._v("协议")]
                        )
                      : _vm._e(),
                    _c("span", [_vm._v(_vm._s(row.matchCarType))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "180", label: "商品标准名", prop: "standardName" },
          }),
          _c("el-table-column", {
            attrs: { width: "100", label: "品质" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm._f("dict")(row.goodsType, "manufactoryType"))
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "140", label: "自编码", prop: "ownCode" },
          }),
          _c("el-table-column", {
            attrs: { width: "140", label: "原厂编码", prop: "goodsCode" },
          }),
          _c("el-table-column", {
            attrs: { width: "120", label: "本店库存/单位" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("span", [_vm._v(_vm._s(row.inventoryTotalNum))]),
                    row.unit
                      ? _c("span", [_vm._v("(" + _vm._s(row.unit) + ")")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "120", label: "他店库存/单位" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("span", [_vm._v(_vm._s(row.otherInventoryTotalNum))]),
                    row.unit && row.otherInventoryTotalNum
                      ? _c("span", [_vm._v("(" + _vm._s(row.unit) + ")")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "120", label: "售价" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("toThousands")(row.price))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              "min-width": "120",
              label: "商品备注",
              prop: "remark",
              "show-overflow-tooltip": "",
            },
          }),
        ],
        1
      ),
      _c("div", { staticStyle: { height: "40px" } }, [
        _vm.hasMore
          ? _c(
              "div",
              {
                staticClass: "load-more-btn",
                on: {
                  click: function ($event) {
                    return _vm.getData(false, true)
                  },
                },
              },
              [_vm._v(" 加载更多数据 ")]
            )
          : _c("div", { staticClass: "all" }, [_vm._v("已全部加载")]),
      ]),
      _c("el-input", {
        ref: "keyWordInput",
        attrs: {
          slot: "reference",
          clearable: "",
          size: "small",
          placeholder: "搜索商品名称",
        },
        on: {
          input: function ($event) {
            return _vm.getData(true)
          },
          focus: _vm.handleInputFocus,
        },
        slot: "reference",
        model: {
          value: _vm.keyWord,
          callback: function ($$v) {
            _vm.keyWord = $$v
          },
          expression: "keyWord",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }