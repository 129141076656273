<template>
  <base-container paddingTop>
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="150px"
      size="small"
    >
      <el-row v-if="!edit" style="margin-left: -45px">
        <el-col :span="24">
          <el-form-item label="选择已有客户" prop="clientName">
            <el-popover
              placement="bottom"
              width="500"
              trigger="click"
              v-model="visible"
            >
              <el-table
                :data="clientList"
                class="customer-table"
                :max-height="400"
                border
                @current-change="handleCurrentChange"
              >
                <el-table-column
                  width="100"
                  property="clientName"
                  label="客户名称"
                ></el-table-column>
                <el-table-column
                  width="100"
                  property="isAgreementClient"
                  label="协议客户"
                >
                  <template #default="{ row }">
                    <span v-if="row.isAgreementClient == 1"
                      >协议客户<span v-if="row.beContract">（包干）</span></span
                    >
                    <span v-else></span>
                  </template>
                </el-table-column>
                <el-table-column
                  width="100"
                  property="companyFullName"
                  label="公司全称"
                ></el-table-column>
                <el-table-column
                  min-width="70"
                  property="clientLevel"
                  label="等级"
                ></el-table-column>
              </el-table>
              <div
                v-if="hasMore"
                class="load-more-btn"
                @click="getCustomerData(false, true)"
              >
                加载更多数据
              </div>
              <el-input
                slot="reference"
                v-model="form.clientName"
                style="width: 260px"
                size="small"
                placeholder="请输入客户名称搜索"
                @input="getCustomerData(true)"
                @clear="handleClear"
                clearable
              ></el-input>
            </el-popover>

            <span v-auth="61" style="margin: 0 15px">或</span>
            <span
              v-auth="61"
              class="orange text_operation"
              @click="showAddCusterDialog = true"
              >新增客户</span
            >

            <span style="margin-left: 30px; color: #999"
              >选定并保存成功后不能修改，请谨慎选择</span
            >
          </el-form-item>
        </el-col>
      </el-row>
      <el-row style="margin-left: -45px">
        <el-col :span="24">
          <div class="client" v-show="form.clientId">
            <div class="client-info">
              <div>
                <i
                  class="iconfont icon_grade"
                  :class="levelIconsOther[clientInfo.clientLevel]"
                  :title="clientInfo.clientLevel || ''"
                ></i>
                <span>{{ clientInfo.clientName }}</span>
                <span v-if="clientInfo.companyFullName">/</span>
                <span>{{ clientInfo.companyFullName }}</span>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-divider></el-divider>

      <el-tabs v-model="activeName" style="margin-top: 30px">
        <el-tab-pane label="协议基础信息" name="first">
          <p style="margin: 12px 0px 12px 0px; font-size: 12px" class="orange">
            注：协议编号、有效期、协议客户一但创建不可修改，请注意操作。
          </p>
          <div style="margin-left: -68px">
            <el-row>
              <el-col :span="6">
                <el-form-item label="协议编号" prop="contractNumber">
                  <el-input
                    v-model="form.contractNumber"
                    :disabled="!!edit"
                    placeholder="请输入协议编号"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="签约门店" prop="signCompanyId">
                  <el-select
                    v-model.number="form.signCompanyId"
                    filterable
                    clearable
                    remote
                    placeholder="请输入门店名称搜索"
                    :remote-method="searchStore"
                    :loading="searchStoreLoading"
                  >
                    <el-option
                      v-for="item in storeList"
                      :key="item.companyId"
                      :label="item.companyName"
                      :value="item.companyId"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item prop="invoiceCompanyId">
                  <template slot="label">
                    <el-tooltip
                      effect="light"
                      placement="bottom"
                      style="margin-right: 5px"
                    >
                      <div slot="content" class="rows-tip">
                        客户协议的开票主体，即为协议客户的应收账款的回款管理门店
                      </div>
                      <i
                        class="iconfont grow-icon_doubt"
                        style="color: #9a9a9a"
                      ></i>
                    </el-tooltip>
                    开票主体
                  </template>
                  <el-select
                    v-model.number="form.invoiceCompanyId"
                    filterable
                    clearable
                    remote
                    placeholder="搜索开票主体名称"
                    :remote-method="searchInvoice"
                    :loading="searchInvoiceLoading"
                  >
                    <el-option
                      v-for="item in invoiceList"
                      :key="item.companyId"
                      :label="item.invoicingEntity"
                      :value="item.companyId"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="回款账期" prop="paymentPeriod">
                  <template slot="label">
                    <el-tooltip
                      effect="light"
                      placement="bottom"
                      style="margin-right: 5px"
                    >
                      <div slot="content" class="rows-tip">
                        消费结账后第{{ form.paymentPeriod }}个自然月月底前回款
                      </div>
                      <i
                        class="iconfont grow-icon_doubt"
                        style="color: #9a9a9a"
                      ></i>
                    </el-tooltip>
                    <span>回款账期</span>
                  </template>
                  <number-input v-model="form.paymentPeriod" decimalDigits="2">
                    <template slot="append">月 </template></number-input
                  >
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <el-form-item label="生效日期" prop="startDate">
                  <el-date-picker
                    type="date"
                    placeholder="选择日期"
                    v-model="form.startDate"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    :disabled="!!edit"
                    @change="changeDate"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="有效期至" prop="endDate">
                  <el-date-picker
                    type="date"
                    placeholder="请选择截止日期"
                    v-model="form.endDate"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    :picker-options="endDateOption"
                    :disabled="!!edit"
                    @change="changeDate"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="开户行" prop="bank">
                  <el-input
                    v-model="form.bank"
                    placeholder="请输入"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="银行账号" prop="bankNum">
                  <el-input
                    v-model="form.bankNum"
                    placeholder="请输入"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="备注" prop="remark">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4 }"
                    placeholder="请输入"
                    v-model="form.remark"
                    maxlength="100"
                    show-word-limit
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <p style="margin: 20px 0 10px 0">结算方式说明</p>
          <div class="settlement">
            <el-row class="first_row">
              <el-col :span="6">
                <el-form-item label="自费" prop="selfPaying">
                  <el-input
                    v-model="settlementDescription.selfPaying"
                    placeholder="请输入"
                    maxlength="140"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="包干" prop="contractFor">
                  <el-input
                    v-model="settlementDescription.contractFor"
                    placeholder="请输入"
                    maxlength="140"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="事故返点" prop="accidentReturn">
                  <el-input
                    v-model="settlementDescription.accidentReturn"
                    placeholder="请输入"
                    maxlength="140"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="保险理赔" prop="insuranceClaims">
                  <el-input
                    v-model="settlementDescription.insuranceClaims"
                    placeholder="请输入"
                    maxlength="140"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="two_row">
              <el-col :span="6">
                <el-form-item label="年审" prop="annualReview">
                  <el-input
                    v-model="settlementDescription.annualReview"
                    placeholder="请输入"
                    maxlength="140"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="保险手续费" prop="insuranceHandlingFees">
                  <el-input
                    v-model="settlementDescription.insuranceHandlingFees"
                    placeholder="请输入"
                    maxlength="140"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <p style="margin: 20px 0 10px 0">自费挂账设置</p>
          <el-switch v-model="form.beHang"></el-switch>
          <span class="is-responsibility">{{
            form.beHang ? '允许挂账' : '不允许挂账'
          }}</span>
          <p style="margin: 20px 0 10px 0">合同要求</p>
          <div class="container">
            <table class="border-table">
              <thead>
                <tr>
                  <th>业务类型</th>
                  <th>自费</th>
                  <th>退还车</th>
                  <th>包干</th>
                  <th>理赔</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>时效要求</td>
                  <td>
                    <el-input
                      v-model="selfPaying.effectiveAsk"
                      type="textarea"
                      autosize
                      :rows="1"
                      placeholder="请输入"
                      maxlength="100"
                    >
                    </el-input>
                  </td>
                  <td>
                    <el-input
                      v-model="returnCar.effectiveAsk"
                      type="textarea"
                      autosize
                      :rows="1"
                      placeholder="请输入"
                      maxlength="100"
                    >
                    </el-input>
                  </td>
                  <td>
                    <el-input
                      v-model="contractFor.effectiveAsk"
                      type="textarea"
                      autosize
                      :rows="1"
                      placeholder="请输入"
                      maxlength="100"
                    >
                    </el-input>
                  </td>
                  <td>
                    <el-input
                      v-model="claimSettlement.effectiveAsk"
                      type="textarea"
                      autosize
                      :rows="1"
                      placeholder="请输入"
                      maxlength="100"
                    >
                    </el-input>
                  </td>
                </tr>
                <tr>
                  <td>质量要求</td>
                  <td>
                    <el-input
                      v-model="selfPaying.qualityAsk"
                      type="textarea"
                      autosize
                      :rows="1"
                      placeholder="请输入"
                      maxlength="100"
                    >
                    </el-input>
                  </td>
                  <td>
                    <el-input
                      v-model="returnCar.qualityAsk"
                      type="textarea"
                      autosize
                      :rows="1"
                      placeholder="请输入"
                      maxlength="100"
                    >
                    </el-input>
                  </td>
                  <td>
                    <el-input
                      v-model="contractFor.qualityAsk"
                      type="textarea"
                      autosize
                      :rows="1"
                      placeholder="请输入"
                      maxlength="100"
                    >
                    </el-input>
                  </td>
                  <td>
                    <el-input
                      v-model="claimSettlement.qualityAsk"
                      type="textarea"
                      autosize
                      :rows="1"
                      placeholder="请输入"
                      maxlength="100"
                    >
                    </el-input>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <el-form-item label="协议图片" style="margin-left: -80px">
            <p class="img-note">
              （最多可上传10张图片，建议每张图片大小不超过1M）
            </p>
            <div class="car-imgs" style="margin-left: -80px">
              <upload-file-list v-model="form.pics"></upload-file-list>
            </div>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="自费项目折扣" name="second">
          <div class="flex note">
            <p>项目折扣说明：1、仅适用自费、包干业务报价；</p>
          </div>
          <div style="margin-bottom: 8px" class="flex-x-between">
            <div></div>
            <div v-if="edit">
              <el-button
                size="small"
                @click="serviceImportDialogVisibility = true"
                >导入服务价</el-button
              >
              <el-button
                size="small"
                @click="goodsImportDialogVisibility = true"
                >导入商品价</el-button
              >
            </div>
          </div>
          <agreement-price-list
            v-if="edit"
            ref="agreementPriceListDOM"
            :id="$route.query.id"
            :showTips="false"
          ></agreement-price-list>
          <!-- <div class="flex mt60">
            <div class="service-class">
              <div class="table-title">服务分类折扣</div>
              <el-table
                :data="form.servicesDiscount"
                row-key="id"
                border
                :tree-props="{ children: 'secondStage' }"
              >
                <el-table-column label="序号" prop="title" width="100">
                </el-table-column>
                <el-table-column prop="name" label="服务分类">
                </el-table-column>
                <el-table-column prop="discount" label="协议折扣">
                  <template #default="{ row }">
                    <el-input
                      v-model="row.discount"
                      placeholder="请输入折扣"
                      size="small"
                      type="number"
                      min="0"
                      max="9.9"
                      step="0.1"
                      @input="numberChange(arguments[0], row, 'discount', 9.9)"
                      @change="numberChange(arguments[0], row, 'discount', 9.9)"
                    >
                      <template slot="append"> 折 </template></el-input
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="goods-class">
              <div class="table-title">商品分类折扣</div>
              <el-table
                :data="form.goodsDiscount"
                row-key="id"
                border
                :tree-props="{ children: 'secondStage' }"
              >
                <el-table-column label="序号" prop="title" width="100">
                </el-table-column>
                <el-table-column prop="name" label="服务分类">
                </el-table-column>
                <el-table-column prop="discount" label="协议折扣">
                  <template #default="{ row }">
                    <el-input
                      v-model="row.discount"
                      placeholder="请输入折扣"
                      size="small"
                      type="number"
                      min="0"
                      max="9.9"
                      step="0.1"
                      @input="numberChange(arguments[0], row, 'discount', 9.9)"
                      @change="numberChange(arguments[0], row, 'discount', 9.9)"
                    >
                      <template slot="append"> 折 </template>
                    </el-input>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="table-title-but">
            <div class="table-title mt60">项目特殊协议价</div>
            <div class="mt60">
              <el-button size="small" @click="serviceDrawerEvent"
                >选择服务</el-button
              >
              <el-button size="small" @click="goodsDrawerEvent"
                >选择商品</el-button
              >
            </div>
          </div>
          <el-table :data="form.specialDiscount" border>
            <el-table-column type="index" label="序号" width="100">
            </el-table-column>
            <el-table-column label="操作" width="80">
              <template #default="{ $index }">
                <i
                  @click="form.specialDiscount.splice($index, 1)"
                  class="iconfont grow-icon_delete3 orange pointer"
                ></i>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="服务名称"> </el-table-column>
            <el-table-column prop="serviceNum" label="服务编码">
            </el-table-column>
            <el-table-column label="项目类型">
              <template #default="{ row }">{{
                row.serviceType | dict('serviceType')
              }}</template>
            </el-table-column>
            <el-table-column prop="f4" label="单位"> </el-table-column>
            <el-table-column label="售价">
              <template #default="{ row }">{{
                row.sellPrice | toThousands
              }}</template>
            </el-table-column>
            <el-table-column label="协议价">
              <template #default="{ row }">
                <el-input
                  v-model="row.negotiatedPrice"
                  placeholder="请输入协议价"
                  size="small"
                  type="number"
                >
                </el-input>
              </template>
            </el-table-column>
          </el-table>-->
        </el-tab-pane>
        <el-tab-pane label="包干业务设置" name="third">
          <div class="contract-set">
            <p class="undertake-title">
              包干金额分摊规则说明（仅适用“固定金额”、“固定单价”模式）：
            </p>
            <div class="tip-block">
              <p class="tip-title">1、结账当月预分摊制：</p>
              <p class="tip-line">M日预分摊金额 = 包干总额 ÷ 当月天数 × M</p>
              <p class="tip-line">
                单笔分摊金额=单笔结算金额 ×（M日预分摊金额÷M日累计结算金额）
              </p>
              <p class="tip-line">
                单项分摊金额=单项结算总额 ×（单笔分摊金额÷单笔结算金额）
              </p>
              <p class="tip-line">
                注：仅M日累计结算金额大于M日预分摊金额时才进行预分摊
              </p>
            </div>
            <div class="tip-block">
              <p class="tip-title">2、结账次月开始正式分摊制：</p>
              <p class="tip-line">
                结账次月，自动跑批，形成正式分摊，本月分摊金额完结，次月结算的单据纳入下个月的包干分摊金额计算。
              </p>
              <p class="tip-line">注：仅累计结算金额大于包干总额时才进行分摊</p>
            </div>
          </div>
          <div style="height: 32px; margin: 30px 0" class="flex-y-center">
            <!-- <p
              style="margin-left: 15px; color: #999"
              v-if="['overdue'].includes(form.status) && !form.beContract"
            >
              {{ form.beContract ? '已启用包干业务' : '未启用包干业务' }}
            </p> -->
            <div class="flex">
              <el-switch
                v-model="form.beContract"
                @change="beContractChange"
                v-if="!['overdue'].includes(form.status)"
              ></el-switch>
              <p style="margin-left: 15px; color: #999">
                {{ form.beContract ? '已启用包干业务' : '未启用包干业务' }}
              </p>
            </div>
          </div>
          <div v-show="form.beContract">
            <el-radio-group
              v-model="form.undertakeType"
              @input="changeUndertakeType"
            >
              <el-radio :label="0" style="font-size: 14px; color: #242526"
                >固定金额</el-radio
              >
              <el-radio :label="1" style="font-size: 14px; color: #242526"
                >固定单价</el-radio
              >
              <el-radio :label="2" style="font-size: 14px; color: #242526"
                >单据金额比例</el-radio
              >
              <el-radio :label="3" style="font-size: 14px; color: #242526"
                >理赔返点比例</el-radio
              >
            </el-radio-group>
          </div>
          <div class="undertake-type" v-show="form.beContract">
            <div v-if="form.undertakeType == 0">
              <!-- <el-radio v-model="form.undertakeType" :label="0"
                ><span style="color: #333"
                  >包干金额每月固定，不限车辆台数</span
                ></el-radio
              > -->
              <span style="color: #333; font-size: 14px"
                >包干金额每月固定，不限车辆台数</span
              >
              <div
                v-show="form.undertakeType == 0"
                style="margin: 15px 0px"
                class="flex-x-between"
              >
                <div class="input-line">
                  首月包干金额：
                  <el-input
                    v-model="form.firstMonthPayment"
                    placeholder="请输入"
                    size="small"
                    :min="0"
                    oninput="if(value<0)value=0"
                    @input="filterNonDigit($event, 'firstMonthPayment')"
                  ></el-input>
                </div>
                <div class="input-line">
                  中间月包干金额：
                  <el-input
                    v-model="form.middMonthPayment"
                    placeholder="请输入"
                    size="small"
                    :min="0"
                    oninput="if(value<0)value=0"
                    @input="filterNonDigit($event, 'middMonthPayment')"
                  ></el-input>
                </div>
                <div class="input-line">
                  末月包干金额：
                  <el-input
                    v-model="form.endMonthPayment"
                    placeholder="请输入"
                    size="small"
                    :min="0"
                    oninput="if(value<0)value=0"
                    @input="filterNonDigit($event, 'endMonthPayment')"
                  ></el-input>
                </div>
              </div>
            </div>
            <div v-if="form.undertakeType == 1">
              <div>
                <span style="color: #333; font-size: 14px"
                  >包干金额每月非固定，每月包干金额 = 每月包干车辆台数
                  x包干单价</span
                >
                <div style="margin-top: 15px">
                  <div class="input-line">
                    包干单价(元)：
                    <number-input
                      v-model="form.moneyAmout"
                      @input="inputMoney"
                      placeholder="请输入"
                      size="small"
                    ></number-input>
                  </div>
                </div>
              </div>
              <div style="margin-top: 30px">
                <el-table
                  :data="form.agreementUndertake"
                  border
                  :key="refreshKey"
                >
                  <el-table-column type="index" label="序号" width="100">
                  </el-table-column>
                  <el-table-column label="月份">
                    <template #default="{ row }">{{ row.date }}</template>
                  </el-table-column>
                  <el-table-column label="包干车辆（台）">
                    <template #default="{ row }">
                      <number-input
                        v-model="row.carAmount"
                        @input="
                          (val) => {
                            changeCarNumber(val, row);
                          }
                        "
                        placeholder="请输入"
                        size="small"
                        :enabledDecimals="false"
                      ></number-input>
                    </template>
                  </el-table-column>
                  <el-table-column label="包干单价">
                    <template #default="{ row }">{{
                      row.moneyAmout | toThousands
                    }}</template>
                  </el-table-column>
                  <el-table-column label="包干金额">
                    <template #default="{ row }">{{
                      $lodash.multiply(row.moneyAmout, row.carAmount)
                        | toThousands
                    }}</template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <div v-if="form.undertakeType == 2">
              <span style="color: #333; font-size: 14px"
                >包干金额每月按单据金额百分比，每月包干金额=每月包干单据金额 x
                比例</span
              >
              <div class="input-line" style="margin-top: 16px">
                比例（%）：
                <number-input
                  v-model="form.contractRatio"
                  placeholder="请输入0-100的比例值"
                  size="small"
                  :enabledDecimals="false"
                  :max="100"
                  clearable
                ></number-input>
              </div>
            </div>
            <div v-if="form.undertakeType == 3">
              <span style="color: #333; font-size: 14px"
                >包干金额 = 当月已结账理赔单据金额 * 比例</span
              >
              <div class="input-line" style="margin-top: 16px">
                比例（%）：
                <number-input
                  v-model="form.returnPoint"
                  placeholder="请输入0-100的比例值"
                  size="small"
                  :enabledDecimals="false"
                  :max="100"
                  clearable
                ></number-input>
              </div>
            </div>

            <div class="car-scope">
              <div class="car-scope-title">车辆范围</div>
              <p class="car-scope-text">
                请选择该客户需要进行包干业务的车辆，在范围内的车辆才允许包干开单
              </p>
              <div>
                <el-radio-group v-model="form.vehicleRange">
                  <el-radio :label="0">全部车辆</el-radio>
                  <el-radio :label="1">部分车辆</el-radio>
                </el-radio-group>
              </div>

              <div v-if="form.vehicleRange == 1">
                <div style="margin: 30px 0px 10px">
                  <span>导入模式：</span>
                  <el-radio-group v-model="opType">
                    <el-radio :label="0">追加</el-radio>
                    <el-radio :label="1">覆盖</el-radio>
                  </el-radio-group>
                </div>
                <div class="car-scope-text">
                  <span>导入文件：</span>
                  <template v-if="!carScopeImportFile">
                    <el-button type="primary" size="small" @click="chooseFile"
                      >选择文件</el-button
                    >
                  </template>
                  <template v-else>
                    <span>{{ carScopeImportFile.name }}</span>
                    <span
                      class="blue pointer el-icon--right"
                      @click="chooseFile"
                      >更换</span
                    >
                    <p style="margin-top: 8px">
                      <span class="orange"
                        >注意：仅按照最新上传的表修改车辆包干范围，请上传全部需要包干的车辆</span
                      >
                    </p>
                  </template>
                  <p style="margin-top: 16px">
                    仅支持xlsx格式文件，点击

                    <a
                      style="font-size: 14px; text-decoration: none"
                      class="blue pointer"
                      href="https://growxny-dev-center.oss-cn-guangzhou.aliyuncs.com/template/20230922/%E5%8C%85%E5%B9%B2%E8%BD%A6%E8%BE%86%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx"
                      download
                      >下载导入模板 <i class="iconfont grow-icon_download"></i
                    ></a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="协议适用门店" name="fourth">
          <div class="flex" style="font-size: 14px">
            <p style="color: #666">适用门店说明</p>
            <p style="margin-left: 15px; color: #999">
              分业务设置适用门店后，协议客户的车辆只能去到适用门店进行对应业务的接待开单。
            </p>
          </div>
          <div class="flex item-block">
            <h6>自费业务:</h6>
            <div>
              <el-radio v-model="form.hasSelfPaying" :label="0"
                ><span style="color: #333">无限制，全部门店</span></el-radio
              >
              <el-radio
                style="margin-left: 30px"
                v-model="form.hasSelfPaying"
                :label="1"
                ><span style="color: #333">有限制，部分门店</span></el-radio
              >
              <p class="orange" style="margin-top: 12px; font-size: 12px">
                {{
                  form.hasSelfPaying == 0
                    ? '（已选择全部门店，适用门店不固定，后续新增的门店将自动适用）'
                    : '（已选择部分门店，适用门店是固定的，除非重新设置）'
                }}
              </p>
            </div>
            <el-button
              v-if="form.hasSelfPaying == 1"
              size="small"
              class="btn_insert"
              @click="addStore('selfPaying')"
              >选择门店</el-button
            >
          </div>
          <div class="table-box">
            <el-table
              border
              v-if="form.hasSelfPaying == 1"
              :data="form.selfPaying"
            >
              <el-table-column label="操作" width="80">
                <template #default="{ $index }">
                  <el-button
                    @click="deleteRow(form.selfPaying, $index)"
                    type="text"
                    size="small"
                  >
                    <i
                      class="iconfont grow-icon_delete3"
                      style="color: #ff9200; font-size: 22px"
                    ></i>
                  </el-button>
                </template>
              </el-table-column>
              <el-table-column
                type="index"
                label="序号"
                width="60"
              ></el-table-column>
              <el-table-column
                width="120"
                prop="companyId"
                label="车店编号"
              ></el-table-column>
              <el-table-column
                prop="companyName"
                label="车店名称"
              ></el-table-column>
              <el-table-column width="100" prop="type" label="连锁类型">
                <template slot-scope="scope">{{
                  scope.row.chainCompanyType | dict('chainCompanyType')
                }}</template>
              </el-table-column>
            </el-table>
          </div>
          <div class="flex item-block">
            <h6>包干业务:</h6>
            <div>
              <el-radio v-model="form.hasContractFor" :label="0"
                ><span style="color: #333">无限制，全部门店</span></el-radio
              >
              <el-radio
                style="margin-left: 30px"
                v-model="form.hasContractFor"
                :label="1"
                ><span style="color: #333">有限制，部分门店</span></el-radio
              >
              <p class="orange" style="margin-top: 12px; font-size: 12px">
                {{
                  form.hasContractFor == 0
                    ? '（已选择全部门店，适用门店是不固定，后续新增的门店将自动适用）'
                    : '（已选择部分门店，适用门店是固定的，新增的门店需要重新设置）'
                }}
              </p>
            </div>
            <el-button
              v-if="form.hasContractFor == 1"
              size="small"
              class="btn_insert"
              @click="addStore('contractFor')"
              >选择门店</el-button
            >
          </div>
          <div class="table-box">
            <el-table
              border
              v-if="form.hasContractFor == 1"
              :data="form.contractFor"
            >
              <el-table-column label="操作" width="80">
                <template #default="{ $index }">
                  <el-button
                    @click="deleteRow(form.contractFor, $index)"
                    type="text"
                    size="small"
                  >
                    <i
                      class="iconfont grow-icon_delete3"
                      style="color: #ff9200; font-size: 22px"
                    ></i>
                  </el-button>
                </template>
              </el-table-column>
              <el-table-column
                type="index"
                label="序号"
                width="60"
              ></el-table-column>
              <el-table-column
                width="120"
                prop="companyId"
                label="车店编号"
              ></el-table-column>
              <el-table-column
                prop="companyName"
                label="车店名称"
              ></el-table-column>
              <el-table-column width="100" prop="type" label="连锁类型">
                <template slot-scope="scope">{{
                  scope.row.chainCompanyType | dict('chainCompanyType')
                }}</template>
              </el-table-column>
            </el-table>
          </div>
          <div class="flex item-block">
            <h6>理赔业务:</h6>
            <div>
              <el-radio v-model="form.hasClaimSettlement" :label="0"
                ><span style="color: #333">无限制，全部门店</span></el-radio
              >
              <el-radio
                style="margin-left: 30px"
                v-model="form.hasClaimSettlement"
                :label="1"
                ><span style="color: #333">有限制，部分门店</span></el-radio
              >
              <p class="orange" style="margin-top: 12px; font-size: 12px">
                {{
                  form.hasClaimSettlement == 0
                    ? '（已选择全部门店，适用门店是不固定，后续新增的门店将自动适用）'
                    : '（已选择部分门店，适用门店是固定的，新增的门店需要重新设置）'
                }}
              </p>
            </div>
            <el-button
              v-if="form.hasClaimSettlement == 1"
              size="small"
              class="btn_insert"
              @click="addStore('claimSettlement')"
              >选择门店</el-button
            >
          </div>
          <div class="table-box">
            <el-table
              border
              v-if="form.hasClaimSettlement == 1"
              :data="form.claimSettlement"
            >
              <el-table-column label="操作" width="80">
                <template #default="{ $index }">
                  <el-button
                    @click="deleteRow(form.contractFor, $index)"
                    type="text"
                    size="small"
                  >
                    <i
                      class="iconfont grow-icon_delete3"
                      style="color: #ff9200; font-size: 22px"
                    ></i>
                  </el-button>
                </template>
              </el-table-column>
              <el-table-column
                type="index"
                label="序号"
                width="60"
              ></el-table-column>
              <el-table-column
                width="120"
                prop="companyId"
                label="车店编号"
              ></el-table-column>
              <el-table-column
                prop="companyName"
                label="车店名称"
              ></el-table-column>
              <el-table-column width="100" prop="type" label="连锁类型">
                <template slot-scope="scope">{{
                  scope.row.chainCompanyType | dict('chainCompanyType')
                }}</template>
              </el-table-column>
            </el-table>
          </div>
          <div class="flex item-block">
            <h6>索赔业务:</h6>
            <div>
              <el-radio v-model="form.hasClaimForCompensation" :label="0"
                ><span style="color: #333">无限制，全部门店</span></el-radio
              >
              <el-radio
                style="margin-left: 30px"
                v-model="form.hasClaimForCompensation"
                :label="1"
                ><span style="color: #333">有限制，部分门店</span></el-radio
              >
              <p class="orange" style="margin-top: 12px; font-size: 12px">
                {{
                  form.hasClaimForCompensation == 0
                    ? '（已选择全部门店，适用门店是不固定，后续新增的门店将自动适用）'
                    : '（已选择部分门店，适用门店是固定的，新增的门店需要重新设置）'
                }}
              </p>
            </div>
            <el-button
              v-if="form.hasClaimForCompensation == 1"
              size="small"
              class="btn_insert"
              @click="addStore('claimForCompensation')"
              >选择门店</el-button
            >
          </div>
          <div class="table-box">
            <el-table
              border
              v-if="form.hasClaimForCompensation == 1"
              :data="form.claimForCompensation"
            >
              <el-table-column label="操作" width="80">
                <template #default="{ $index }">
                  <el-button
                    @click="deleteRow(form.claimForCompensation, $index)"
                    type="text"
                    size="small"
                  >
                    <i
                      class="iconfont grow-icon_delete3"
                      style="color: #ff9200; font-size: 22px"
                    ></i>
                  </el-button>
                </template>
              </el-table-column>
              <el-table-column
                type="index"
                label="序号"
                width="60"
              ></el-table-column>
              <el-table-column
                width="120"
                prop="companyId"
                label="车店编号"
              ></el-table-column>
              <el-table-column
                prop="companyName"
                label="车店名称"
              ></el-table-column>
              <el-table-column width="100" prop="type" label="连锁类型">
                <template slot-scope="scope">{{
                  scope.row.chainCompanyType | dict('chainCompanyType')
                }}</template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-form>

    <select-store
      v-if="storeDrawerVisibility"
      :storeDrawerVisibility.sync="storeDrawerVisibility"
      :stores="addStoreData"
      @select="handleSelectStore"
    ></select-store>

    <add-customer-dialog
      v-if="showAddCusterDialog"
      :showAddCusterDialog.sync="showAddCusterDialog"
      @custerInforma="custerInforma"
    ></add-customer-dialog>

    <!-- 选择商品抽屉  -->
    <!-- <select-goods-car-bill
      v-if="goodsDrawerVisibility"
      :oldGoodsData="billGoods || []"
      :goods="addGoodsData"
      @select="handleSelectGoods"
      :visibility.sync="goodsDrawerVisibility"
    ></select-goods-car-bill> -->
    <!-- 选择服务抽屉  -->
    <!-- <select-services-car-bill
      v-if="serviceDrawerVisibility"
      :oldServicesData="billServices || []"
      @select="handleSelectService"
      :service="addServiceData"
      :visibility.sync="serviceDrawerVisibility"
    ></select-services-car-bill> -->
    <!-- 改变包干业务模式弹窗小提示 -->
    <el-dialog
      title="提示"
      :visible.sync="undertakeTypeDialog"
      width="30%"
      :before-close="handleCloseDialog"
    >
      <span style="line-height: 22px; letter-spacing: 2px; color: #242526"
        >本次包干方式由“{{ oldUndertakeWord }}”修改为“{{
          newUndertakeWord
        }}”。当月历史单据将于晚上零点重新跑批更新包干金额。往月单据如需更改，请前往“财务管理->应收款->包干分摊批次”重跑该客户批次</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelTypeDialog">取 消</el-button>
        <el-button type="primary" @click="undertakeTypeDialog = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 服务导入弹窗 -->
    <import-dialog
      v-if="serviceImportDialogVisibility"
      :visibility.sync="serviceImportDialogVisibility"
      title="协议价服务导入"
      templateFileUrl="https://growxny-dev-center.oss-cn-guangzhou.aliyuncs.com/template/20231019/%E5%8D%8F%E8%AE%AE%E4%BB%B7-%E6%9C%8D%E5%8A%A1%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx"
      :uploadFileApi="serviceAgreementPriceImportApi"
      :getLastResultApi="serviceAgreementPriceImportResultApi"
      :importFailDetail="serviceAgreementPriceImportMessageExportUrlApi"
      :downloadResultParams="{ id }"
      :disabled="false"
      @save="handleImport"
    ></import-dialog>

    <!-- 商品导入弹窗 -->
    <import-dialog
      v-if="goodsImportDialogVisibility"
      :visibility.sync="goodsImportDialogVisibility"
      title="协议价商品导入"
      templateFileUrl="https://growxny-dev-center.oss-cn-guangzhou.aliyuncs.com/template/20231019/%E5%8D%8F%E8%AE%AE%E4%BB%B7-%E5%95%86%E5%93%81%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx"
      :uploadFileApi="goodsAgreementPriceImportApi"
      :getLastResultApi="goodsAgreementPriceImportResultApi"
      :importFailDetail="goodsAgreementPriceImportMessageExportUrlApi"
      :downloadResultParams="{ id }"
      :disabled="false"
      @save="handleImport"
    ></import-dialog>

    <template slot="footer">
      <div class="flex-x-end foot-btn">
        <el-button size="small" @click="verifyBeforeSave" type="primary"
          >确定保存</el-button
        >
        <el-button size="small" @click="handleClose">取 消</el-button>
      </div>
    </template>
  </base-container>
</template>

<script>
// 组件
import uploadFileList from '@/components/uploadFileList';
import BaseContainer from '@/components/BaseContainer.vue';
import selectStore from '@/views/systemManagement/selectStore';
import addCustomerDialog from '../../addCustomer.vue';
import SelectGoodsCarBill from '@/views/carBill/selectGoodsCarBill';
import SelectServicesCarBill from '@/views/carBill/selectServicesCarBill';
import importDialog from '@/components/businessComponent/importDialog.vue';
import agreementPriceList from '@/views/customerManagement/agreement/agreementDetail/agreementPriceList.vue';

import { levelIconsOther } from '@/const/client';

// 接口
import {
  getAgreementDetail,
  insertAgreement,
  getAgreementDiscount,
  updateAgreement,
  getAgreementUndertake,
  getAgreementStore,
} from '@/api/customer/agreement';
import {
  carScopeImport as carScopeImportApi,
  serviceAgreementPriceImport,
  serviceAgreementPriceImportResult,
  serviceAgreementPriceImportMessageExportUrl,
  goodsAgreementPriceImport,
  goodsAgreementPriceImportResult,
  goodsAgreementPriceImportMessageExportUrl,
} from '@/api/system/dataImport';
import { searchStore } from '@/api/customer/store';
import { searchClient, getClientBySearch } from '@/api/customer/customer';

// 字典
import {
  carType,
  fuelType,
  annualSurveyCarType,
} from '@/service/dict/dictData';

import { mapState, mapGetters, mapMutations } from 'vuex';
import Big from 'big.js';
import { getApartMonths } from '@/utils/tools/date';
import { searchInvoice } from '@/api/storeManagement/index';
import lodash from 'lodash';

import { chooseFile } from '@/utils/fileApi';

export default {
  name: 'addAgreement',
  components: {
    BaseContainer,
    uploadFileList,
    selectStore,
    addCustomerDialog,
    importDialog,
    agreementPriceList,
    // SelectGoodsCarBill,
    // SelectServicesCarBill,
  },
  data() {
    return {
      Big,
      refreshKey: 1,
      activeName: 'first',
      agreementId: 0,
      opType: 0,
      // 表单数据
      form: {
        startDate: new Date().Format('yyyy-MM-dd'),
        openPermiss: ['0'],
        // 是否开启包干 默认关闭
        beContract: 0,
        // 适用门店 默认所有门店
        hasSelfPaying: 0,
        hasContractFor: 0,
        hasClaimSettlement: 0,
        hasClaimForCompensation: 0,
        // 签约门店
        signCompanyId: '',
        // 开票主体
        invoiceCompanyId: '',
        // 车辆范围 默认全部
        vehicleRange: 0,
      },
      // 表单规则
      rules: {
        clientName: [
          {
            // required: true,
            validator: (rule, value, callback) => {
              setTimeout(() => {
                if (!this.form) {
                  callback(new Error('请选择客户'));
                } else {
                  callback();
                }
              }, 1e2);
            },
            message: '请选择客户',
            trigger: 'blur',
          },
        ],
        contractNumber: [
          { required: true, message: '请输入协议编号', trigger: 'blur' },
        ],
        signCompanyId: [
          {
            required: true,
            message: '请选择签约门店',
            trigger: ['blur', 'change'],
          },
        ],
        invoiceCompanyId: [
          {
            required: true,
            message: '请选择开票主体',
            trigger: ['blur', 'change'],
          },
        ],
        startDate: [
          {
            required: true,
            message: '请选择生效日期',
            trigger: ['blur'],
          },
        ],
        endDate: [
          {
            required: true,
            message: '请选择有效期截止日期',
            trigger: ['blur'],
          },
        ],
        paymentPeriod: [
          { required: true, message: '请输入回款账期', trigger: 'blur' },
        ],
      },
      // 开始日期选项
      startDateOption: {
        disabledDate: (time) => {
          if (this.form.endDate) {
            return new Date(this.form.endDate).getTime() < time.getTime();
          }
        },
      },
      // 结束日期选项
      endDateOption: {
        disabledDate: (time) => {
          if (
            this.form.startDate &&
            new Date(this.form.startDate).getTime() > new Date().getTime()
          ) {
            return new Date(this.form.startDate).getTime() > time.getTime();
          }
          return new Date().getTime() > time.getTime();
        },
      },
      // 机动车种类
      vehicleType: carType,

      searchModelLoading: false,
      models: [],
      // 燃料类型
      fuelTypes: fuelType,
      // 年审类型
      annualVerificationTypes: annualSurveyCarType,

      // 搜索用户
      clientList: [],
      searchClientLoading: false,
      clientInfo: {},

      // 搜索门店
      storeList: [],
      searchStoreLoading: false,

      // 开票主体
      invoiceList: [],
      searchInvoiceLoading: false,

      //选择服务抽屉可见性
      serviceDrawerVisibility: false,
      addServiceData: [],

      //选择商品抽屉可见性
      goodsDrawerVisibility: false,
      addGoodsData: [],

      // 客户等级对应图标
      levelIconsOther,

      // 选择门店抽屉可见性
      storeDrawerVisibility: false,
      // 当前选择门店字段
      addStoreProp: '',
      // 当前选择门店数据
      addStoreData: [],

      // 是否修改图片
      oldImgString: '',

      //新增客户
      showAddCusterDialog: false,

      agreeClients: {},

      // //本身存在的商品数据
      // billGoods: [],
      // //本身存在的服务数据
      // billServices: [],
      //改变包干业务模式弹窗提示
      undertakeTypeDialog: false,
      oldUndertakeWord: '',
      newUndertakeWord: '',
      oldUndertakeType: '',

      settlementDescription: {},
      //合同要求表格
      selfPaying: {},
      returnCar: {},
      contractFor: {},
      claimSettlement: {},
      // 可见性
      visible: false,
      // 是否未加载完所有数据
      hasMore: true,
      // 分页所在页数
      pageCurrent: 0,
      // 是否首次加载
      isFirstLoad: true,
      // 是否正在加载
      isLoading: false,

      // 车辆范围导入文件
      carScopeImportFile: null,

      serviceImportDialogVisibility: false,
      // serviceAgreementPriceImportApi: serviceAgreementPriceImport,
      // serviceAgreementPriceImportResultApi: serviceAgreementPriceImportResult,
      serviceAgreementPriceImportMessageExportUrlApi:
        serviceAgreementPriceImportMessageExportUrl,

      goodsImportDialogVisibility: false,
      // goodsAgreementPriceImportApi: goodsAgreementPriceImport,
      // goodsAgreementPriceImportResultApi: goodsAgreementPriceImportResult,
      goodsAgreementPriceImportMessageExportUrlApi:
        goodsAgreementPriceImportMessageExportUrl,
    };
  },
  created() {
    if (this.edit || this.copy) {
      this.agreementId = this.$route.query.id;
      getAgreementDetail(this.$route.query.id).then((res) => {
        // 判断协议状态
        const now = new Date().getTime();
        const start = new Date(res.startDate).getTime();
        const end = new Date(res.endDate).getTime();
        const isCancel = res.beCancel;
        if (isCancel) {
          res.status = 'rescissory';
        } else if (start > now) {
          res.status = 'invalid';
        } else if (end < now) {
          res.status = 'overdue';
        } else {
          res.status = 'normal';
        }

        this.form = res;
        this.settlementDescription = res.settlementDescription;
        this.oldUndertakeType = res.undertakeType;
        this.agreeClients = {
          clientName: res.agreeClient.name,
          clientPhone: res.agreeClient.phone,
          isBindingWx: res.agreeClient.wxbind,
          gender: res.agreeClient.gender,
          clientLevel: res.agreeClient.clientLevel,
          id: res.agreeClient.clientId,
          companyFullName: res.agreeClient.companyFullName,
        };
        this.clientInfo = this.agreeClients;
        this.form.clientName = this.agreeClients.clientName;
        //处理数据回显问题
        this.clientList.push(this.agreeClients);
        this.clientList = this.$lodash.unionBy(this.clientList, 'id');

        this.storeList.push({
          companyId: res.signCompanyId,
          companyName: res.signCompany,
        });
        this.storeList = this.$lodash.unionBy(this.storeList, 'companyId');

        this.invoiceList.push({
          companyId: res.invoiceCompanyId,
          invoicingEntity: res.invoiceCompany,
        });
        this.invoiceList = this.$lodash.unionBy(this.invoiceList, 'companyId');

        this.form.openPermiss = (res.openPermiss + '')?.split(',');
        // this.form.specialDiscount = this.form.specialDiscount || [];

        // 图片添加前缀
        this.form.pics = this.form.pics
          ?.split(',')
          .map((item) => this.form.picPath + item)
          .join();

        // 协议适用门店 是否限制  保存时存 0 1 查询时返回true false
        this.form.hasSelfPaying = this.form.hasSelfPaying ? 1 : 0;
        this.form.hasContractFor = this.form.hasContractFor ? 1 : 0;
        this.form.hasClaimSettlement = this.form.hasClaimSettlement ? 1 : 0;
        this.form.hasClaimForCompensation = this.form.hasClaimForCompensation
          ? 1
          : 0;

        // 获取包干情况
        if (res.undertakeType == 1 && !this.copy) {
          getAgreementUndertake(res.id).then((res) => {
            res = this.setTitle(res || []);
            res.forEach((item) => {
              delete item.id;
            });
            this.form.agreementUndertake = res || [];
            if (Array.isArray(res) && res.length) {
              this.form.moneyAmout = res[0].moneyAmout;
            }
            // this.form.agreementUndertake.forEach(item=>{item.allMoneyAmount = item.})
          });
        }
        // 获取协议适用门店
        getAgreementStore(this.$route.query.id).then((res) => {
          this.form.selfPaying = res.selfPaying;
          this.form.contractFor = res.contractFor;
          this.form.claimForCompensation = res.claimForCompensation;
          this.form.claimSettlement = res.claimSettlement;
        });

        // 复制协议
        if (this.copy) {
          this.$delete(this.form, 'id');
          this.form.contractNumber = '';
          this.form.startDate = '';
          this.form.endDate = '';
        }

        //判断包干业务设置中改变包干模式的选择
        switch (res.undertakeType) {
          case 0:
            this.oldUndertakeWord = '固定金额';
            break;
          case 1:
            this.oldUndertakeWord = '固定单价';
            break;
          case 2:
            this.oldUndertakeWord = '车单金额比例';
            break;
          case 3:
            this.oldUndertakeWord = '理赔返点比例';
            break;
        }
        //处理数据回显到合同要求表格
        this.selfPaying = res.contractAsk.selfPayingVo;
        this.returnCar = res.contractAsk.returnCarVo;
        this.contractFor = res.contractAsk.contractForVo;
        this.claimSettlement = res.contractAsk.claimSettlementVo;
      });
    } else {
      this.$route.meta.title = '新增协议';
      this.form.signCompanyId = this.companyId;
      this.form.invoiceCompanyId = this.companyId;
      this.storeList.unshift({
        companyId: this.companyId,
        companyName: this.companyName,
        invoicingEntity: this.companyName,
      });
      this.getCustomerData();
    }

    // 获取 商品、服务折扣分类 特殊商品折扣
    this.getDiscount();

    // this.searchClient('', true);
    this.searchStore('', true);
    this.searchInvoice('', true);
  },
  activated() {
    this.$store.commit('UPDATE_TAG_TITLE', {
      title: this.edit ? '编辑协议' : '新增协议',
    });
  },
  computed: {
    ...mapGetters(['activeTagIndex']),
    ...mapState({
      companyId: (state) => state.user.companyId,
      companyName: (state) => state.user.companyName,
    }),
    edit() {
      return this.$route.query.id && !this.$route.query.copy;
    },
    id() {
      return this.$route.query.id;
    },
    copy() {
      return this.$route.query.copy;
    },
    pagination() {
      return { size: 5, current: this.pageCurrent };
    },
  },
  methods: {
    ...mapMutations({
      closeTag: 'DEL_TAG',
    }),
    async getDiscount() {
      let res;
      if (this.$route.query.id) {
        res = await getAgreementDiscount(this.companyId, this.$route.query.id);
      } else {
        res = await getAgreementDiscount(this.companyId);
      }
      // let {
      //   agreementServiceCategory,
      //   agreementGoodCategory,
      //   agreementSpecialCategory,
      // } = res;
      // this.form.servicesDiscount = agreementServiceCategory;
      // this.form.goodsDiscount = agreementGoodCategory;
      // this.form.specialDiscount = agreementSpecialCategory || [];

      // this.setIndex(this.form.servicesDiscount);
      // this.setIndex(this.form.goodsDiscount);
      // //过滤出项目特殊协议价中的存在的商品类型数据
      // this.billGoods = this.form.specialDiscount.filter(
      //   (item) => item.serviceType === 0
      // );
      // //过滤出项目特殊协议价中的存在的服务类型数据
      // this.billServices = this.form.specialDiscount.filter(
      //   (item) => item.serviceType === 1
      // );
      // this.billServices.map((item) => {
      //   item.serviceItemId = item.itemId;
      // });
    },
    setTitle(res) {
      let months = getApartMonths(this.form.startDate, this.form.endDate);
      return res.map((item, i) => {
        item.date = months[i];
        return item;
      });
    },
    setIndex(arr, indexArr = []) {
      arr.forEach((item, index) => {
        let cloneArr = [];
        if (indexArr.length) {
          cloneArr = indexArr.concat(index + 1);
        } else {
          cloneArr.push(index + 1);
        }
        item.title = cloneArr.join('.');
        if (item.secondStage?.length) {
          this.setIndex(item.secondStage, cloneArr);
        }
      });
    },
    /**
     * 关闭/取消
     */
    handleClose() {
      this.$confirm('如果离开页面，已填写的数据将丢失，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.closeTag({ index: this.activeTagIndex });
        })
        .catch((action) => {});
    },
    /**
     * 远程搜索客户列表
     */
    searchClient(keyWord = '', concat = false) {
      this.searchClientLoading = true;
      searchClient(keyWord)
        .then((res) => {
          this.clientList = concat
            ? this.clientList.concat(res.records)
            : res.records;
          this.clientList = this.$lodash.unionBy(this.clientList, 'id');
        })
        .finally(() => {
          this.searchClientLoading = false;
        });
    },
    //搜索客户列表-实现翻页
    getCustomerData(reset, loadMore) {
      this.debounceGetData(reset, loadMore);
    },
    debounceGetData: lodash.debounce(function (reset, loadMore) {
      if (!this.isFirstLoad) this.isLoading = true;
      if (loadMore) this.current++;
      // 重置分页，清空已有数据
      if (reset) {
        this.pageCurrent = 1;
        //  滚动到头部
        // this.hasMore = true;
      } else {
        this.pageCurrent++;
      }
      getClientBySearch(
        {
          clientMsg: this.form.clientName,
          companyId: this.$store.state.user.userInfo.companyId,
          ...this.pagination,
        },
        { noShowWaiting: true }
      )
        .then((res) => {
          if (reset) this.clientList = [];
          console.log(res);
          this.clientList.push(...res.records);
          this.hasMore = this.pageCurrent < res.pages;
        })
        .finally(() => {
          this.isLoading = false;
          this.isFirstLoad = false;
        });
    }, 5e2),
    /**
     * 选择客户
     */
    selectClient(clientId) {
      if (clientId) {
        const client = this.clientList.find((item) => item.id === clientId);
        this.clientInfo = client;
        this.form.name = client.clientName;
        // this.$set(this.form, 'clientId', client.id);
        // this.form.clientId = client.id;
        // this.form.clientLevel = client.clientLevel;
        // this.form.clientName = client.clientName;
        // this.form.clientPhone = client.clientPhone;
        // this.form.gender = client.gender;
        // this.form.isBindingWx = client.isBindingWx;
      }
    },
    /**
     * 删除客户
     */
    deleteClient() {
      if (this.form.clientId) this.form.clientId = '';
      this.clientInfo = {};
      // this.form.clientId = '';
      // this.form.clientLevel = '';
      // this.form.clientName = '';
      // this.form.clientPhone = '';
      // this.form.gender = '';
      // this.form.isBindingWx = '';
    },
    /**
     * 远程搜索门店列表
     */
    searchStore(keyWord = '', concat = false) {
      this.searchStoreLoading = true;
      searchStore(keyWord, this.companyId)
        .then((res) => {
          this.storeList = concat ? this.storeList.concat(res) : res;
          this.storeList = this.$lodash.unionBy(this.storeList, 'companyId');
        })
        .finally(() => {
          this.searchStoreLoading = false;
        });
    },
    /**
     * 远程搜索开票主体列表
     */
    searchInvoice(keyWord = '', concat = false) {
      this.searchInvoiceLoading = true;
      searchInvoice(keyWord, this.companyId)
        .then((res) => {
          this.invoiceList = concat ? this.invoiceList.concat(res) : res;
          this.invoiceList = this.$lodash.unionBy(
            this.invoiceList,
            'companyId'
          );
        })
        .finally(() => {
          this.searchInvoiceLoading = false;
        });
    },
    /**
     * 限制输入框最小最大值
     */
    numberChange(val, object, field, maxNum) {
      if (val === '') {
        object[field] = '';
        return;
      }
      //转换数字类型
      val = Number(val);
      //重新渲染
      this.$nextTick(() => {
        //比较输入的值和最大值，返回小的
        let num = Math.min(val, maxNum);
        //输入负值的情况下
        if (num < 0) {
          object[field] = 0;
        } else {
          //反之
          object[field] = num;
        }
      });
    },
    /**
     * 选择日期
     */
    changeDate() {
      if (this.form.startDate && this.form.endDate) {
        // 判断日期先后顺序
        let start = new Date(this.form.startDate).getTime();
        let end = new Date(this.form.endDate).getTime();
        if (end < start) {
          this.form.endDate = '';
          this.$message.warning('生效日期不能大于有效期');
          return;
        }
        // 取月份数
        this.form.agreementUndertake = [];
        let months = getApartMonths(this.form.startDate, this.form.endDate);
        for (let i = 0; i < months.length; i++) {
          this.form.agreementUndertake.push({
            date: months[i],
            serialNum: i + 1,
            moneyAmout: this.form.moneyAmout,
            carAmount: 0,
            // allMoneyAmount: 0,
          });
        }
      } else {
        this.form.agreementUndertake = [];
      }
    },
    /**
     * 修改单价
     */
    inputMoney(e) {
      this.form.agreementUndertake.map((item) => {
        item.moneyAmout = +e ?? 0;
        // item.allMoneyAmount = Big(item.moneyAmout).times(item.carAmount);
      });
      this.$set(this.form, 'agreementUndertake', this.form.agreementUndertake);
      this.refreshKey++;
    },
    /**
     * 修改车辆数量
     */
    changeCarNumber(carAmount, row) {
      // row.allMoneyAmount = carAmount * row.moneyAmout;
    },
    /**
     * 添加门店
     */
    addStore(prop) {
      // 设置标识
      this.addStoreProp = prop;
      this.addStoreData = this.form[prop] || [];
      this.storeDrawerVisibility = true;
    },
    /**
     * 删除适用门店表格行
     */
    deleteRow(tableData, index) {
      tableData.splice(index, 1);
    },
    /**
     * 处理选择门店回调
     */
    handleSelectStore(stores) {
      this.form[this.addStoreProp] = stores;
    },
    /**
     * 修改开启包干
     */
    beContractChange(value) {
      if (value && (!this.form.startDate || !this.form.endDate)) {
        this.$message.warning('请先选择协议起止时间');
        this.form.beContract = false;
      }
    },
    /**
     * 保存前校验
     */
    verifyBeforeSave() {
      this.$refs.form.validate((result, object) => {
        if (result) {
          // 开启包干未设置金额
          if (this.form.beContract && this.form.undertakeType === undefined) {
            this.$message.warning('请选择包干金额方式！');
            this.activeName = 'third';
            return;
          }
          if (
            this.form.undertakeType == 1 &&
            [undefined, ''].includes(this.form.moneyAmout)
          ) {
            this.$message.warning('请输入包干单价！');
            this.activeName = 'third';
            return;
          }
          if (
            this.form.undertakeType == 3 &&
            [undefined, ''].includes(this.form.returnPoint)
          ) {
            this.$message.warning('请输入理赔返点比例！');
            this.activeName = 'third';
            return;
          }
          if ([undefined, ''].includes(this.form.clientName)) {
            this.$message.warning('请选择客户！');
            this.activeName = 'first';
            return;
          }
          this.doSave();
        } else {
          this.$message.warning('请填写必填项');
          console.log(result, object);
        }
      });
    },
    /**
     * 保存
     */
    doSave() {
      // // 递归删除id
      // function delId(arr) {
      //   if (arr?.length) {
      //     arr.forEach((item) => {
      //       delete item.id;
      //       if (item.secondStage?.length) {
      //         delId(item.secondStage);
      //       }
      //     });
      //   }
      // }
      //整合合同要求表格的数据
      this.form.settlementDescription = this.settlementDescription;
      this.form.contractAsk = {
        selfPaying: this.selfPaying,
        returnCar: this.returnCar,
        contractFor: this.contractFor,
        claimSettlement: this.claimSettlement,
      };
      let cloneForm = this.$lodash.cloneDeep(this.form);

      // delId(cloneForm.goodsDiscount);
      // delId(cloneForm.servicesDiscount);

      // // 特殊协议价商品或服务 不传id
      // cloneForm.specialDiscount?.forEach((item) => {
      //   delete item.id;
      // });
      console.log(cloneForm);
      // 处理图片
      cloneForm.pics = cloneForm.pics
        ?.split(',')
        .map((item) => item.split('/').at(-1))
        .join();
      // cloneForm.vciGuaranteeSlipImg = this.form.vciGuaranteeSlipImg
      //   ?.split('/')
      //   .at(-1);
      // cloneForm.tciGuaranteeSlipImg = this.form.tciGuaranteeSlipImg
      //   ?.split('/')
      //   .at(-1);
      // cloneForm.viGuaranteeSlipImg = this.form.viGuaranteeSlipImg
      //   ?.split('/')
      //   .at(-1);

      cloneForm.openPermiss = cloneForm.openPermiss.join();

      cloneForm.createCompanyId = this.companyId;

      // // 扁平化
      // var flatMap = (arr) => {
      //   return this.$lodash.flatMap(arr, (item) => {
      //     let { secondStage: children = [], ...other } = item;
      //     return [other, ...children];
      //   });
      // };
      // var goods = flatMap(cloneForm.goodsDiscount);
      // var services = flatMap(cloneForm.servicesDiscount);

      // cloneForm.goodsDiscount = goods.map((item) => ({
      //   categoryId: item.id,
      //   discount: item.discount,
      // }));
      // cloneForm.servicesDiscount = services.map((item) => ({
      //   categoryId: item.id,
      //   discount: item.discount,
      // }));

      // cloneForm.specialDiscount.map(item=>{
      //   item.categoryId
      // })

      // 如果关闭包干
      if (!cloneForm.beContract) {
        [
          // 包干类型
          'undertakeType',
          // 'hasSelfPaying',
          // 'hasContractFor',
          // 'hasClaimSettlement',
          // 'hasClaimForCompensation',
          'firstMonthPayment',
          'middMonthPayment',
          'endMonthPayment',
          'agreementUndertake',
        ].map((item) => {
          delete cloneForm[item];
        });
      }
      if (cloneForm.hasSelfPaying && !cloneForm.selfPaying) {
        cloneForm.selfPaying = [];
      }
      if (cloneForm.hasContractFor && !cloneForm.contractFor) {
        cloneForm.contractFor = [];
      }
      if (cloneForm.hasClaimSettlement && !cloneForm.claimSettlement) {
        cloneForm.claimSettlement = [];
      }
      if (
        cloneForm.hasClaimForCompensation &&
        !cloneForm.claimForCompensation
      ) {
        cloneForm.claimForCompensation = [];
      }
      // 处理日期
      if (cloneForm.startDate?.length < 18) {
        cloneForm.startDate += ' 00:00:00';
      }
      if (cloneForm.endDate?.length < 18) {
        cloneForm.endDate += ' 23:59:59';
      }

      // 开启包干并选择部分车辆范围时，执行导入操作
      if (this.edit && cloneForm.beContract && cloneForm.vehicleRange) {
        this.importCarScope();
      }

      // 保存
      if (this.edit) {
        updateAgreement(cloneForm).then((res) => {
          console.log(res);
          if (res.code === 200) {
            // this.oldImgString = newImgString;
            this.$message.success('保存成功');
            // 跳转到详情
            // this.$router.push({
            //   name: 'agreementDetail',
            //   query: { id: this.$route.query.id },
            // });
            let route = {
              name: 'agreementDetail',
              path: '/customer/agreementDetail',
              fullPath: `/customer/agreementDetail?id=${this.$route.query.id}`,
              query: {
                id: this.$route.query.id,
              },
            };
            this.$store.dispatch('REPLACE_TAG', route);
          }
        });
      }
      // 新增
      else {
        insertAgreement(cloneForm).then((res) => {
          console.log(res);
          if (res.code === 200) {
            if (cloneForm.beContract && cloneForm.vehicleRange) {
              this.agreementId = res.data;
              this.importCarScope();
            }
            this.$message.success('保存成功');
            // 跳转到详情
            // this.$router.push({
            //   name: 'agreementDetail',
            //   query: { id: res.data },
            // });
            let route = {
              name: 'agreementDetail',
              path: '/customer/agreementDetail',
              fullPath: `/customer/agreementDetail?id=${res.data}`,
              query: {
                id: res.data,
              },
            };
            this.$store.dispatch('REPLACE_TAG', route);
          }
        });
      }
    },
    //限制金额入框只能输入数字事件
    filterNonDigit(value, prop) {
      this.form[prop] = value.replace(/\D/g, '');
    },
    //新增客户成功时返回的事件
    custerInforma(value) {
      this.clientInfo = value;
      this.form.clientId = value.id;
      this.form.name = value.clientName;
      this.clientList = [this.clientInfo]; //处理回显数据
      this.form.clientName = value.clientName;
    },

    // /**
    //  * 处理选择服务回调
    //  */
    // handleSelectService(services) {
    //   //判断如果返回的值有数据跟没数据处理
    //   if (services.length != 0) {
    //     services = services.map((item) => ({
    //       count: 1,
    //       itemId: item.serviceItemId,
    //       serviceNum: item.serviceItemCode || '',
    //       // f4: item.unit,
    //       sellPrice: item.price,
    //       serviceType: 1,
    //       name: item.name,
    //       serviceItemId: item.serviceItemId,
    //     }));

    //     this.form.specialDiscount.push(...services);
    //     // 处理数组去重
    //     this.form.specialDiscount = this.$lodash.unionBy(
    //       this.form.specialDiscount,
    //       'itemId'
    //     );
    //     this.addServiceData = services;
    //     this.billServices = services;
    //   } else {
    //     //如果返回的服务数没有，就把之前表格存在的服务数据过滤掉
    //     this.form.specialDiscount = this.form.specialDiscount.filter(
    //       (item) => item.serviceType === 0
    //     );
    //     this.addServiceData = [];
    //     this.billServices = [];
    //   }
    // },
    // /**
    //  * 处理选择商品回调
    //  */
    // handleSelectGoods(goods) {
    //   //判断如果返回的值有数据跟没数据处理
    //   if (goods.length != 0) {
    //     goods = goods.map((item) => ({
    //       count: 1,
    //       itemId: item.goodsId,
    //       serviceNum: item.goodsCode || '',
    //       f4: item.unit,
    //       sellPrice: item.price,
    //       serviceType: 0,
    //       name: item.goodsName,
    //       goodsId: item.goodsId,
    //     }));
    //     this.form.specialDiscount.push(...goods);
    //     // 处理数组去重
    //     this.form.specialDiscount = this.$lodash.unionBy(
    //       this.form.specialDiscount,
    //       'itemId'
    //     );
    //     this.addGoodsData = goods;
    //     this.billGoods = goods;
    //   } else {
    //     //如果返回的商品数没有，就把之前表格存在的商品数据过滤掉
    //     this.form.specialDiscount = this.form.specialDiscount.filter(
    //       (item) => item.serviceType === 1
    //     );
    //     this.addGoodsData = [];
    //     this.billGoods = [];
    //   }
    // },

    // //选择服务事件
    // serviceDrawerEvent() {
    //   //过滤出项目特殊协议价中的存在的服务类型数据(因为有可能删除了一些服务数据)
    //   this.billServices = this.form.specialDiscount.filter(
    //     (item) => item.serviceType === 1
    //   );
    //   this.serviceDrawerVisibility = true;
    // },
    // //选择商品事件
    // goodsDrawerEvent() {
    //   //过滤出项目特殊协议价中的存在的商品类型数据(因为有可能删除了一些商品数据)
    //   this.billGoods = this.form.specialDiscount.filter(
    //     (item) => item.serviceType === 0
    //   );
    //   this.goodsDrawerVisibility = true;
    // },
    //包干规则中选择模式改变事件
    changeUndertakeType(val) {
      if (val == 1) {
        this.changeDate();
      }
      if (this.edit || this.copy) {
        switch (val) {
          case 0:
            this.newUndertakeWord = '固定金额';
            break;
          case 1:
            this.newUndertakeWord = '固定单价';
            break;
          case 2:
            this.newUndertakeWord = '车单金额比例';
            break;
          case 3:
            this.newUndertakeWord = '理赔返点比例';
            break;
        }
        if (this.oldUndertakeWord) {
          this.undertakeTypeDialog = true;
        }
      }
    },
    //关闭提示弹窗
    handleCloseDialog() {
      this.undertakeTypeDialog = false;
    },
    cancelTypeDialog() {
      this.form.undertakeType = this.oldUndertakeType;
      this.undertakeTypeDialog = false;
    },
    /**
     * 选中
     */
    handleCurrentChange(val) {
      console.log('val', val);
      if (val != null) {
        const client = this.clientList.find((item) => item.id === val.id);
        this.clientInfo = client;
        this.form.name = client.clientName;
        this.form.clientId = val.id;
        this.form.clientName = val.clientName;
      }
      this.visible = false;
    },
    /**
     * 清空
     */
    handleClear() {
      this.visible = false;
      if (this.form.clientId) this.form.clientId = '';
      if (this.form.clientName) this.form.clientName = '';
      this.clientInfo = {};
    },
    /**
     * 选择车辆范围excel文件
     */
    chooseFile() {
      chooseFile(
        'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ).then((file) => {
        this.carScopeImportFile = file[0];
      });
    },
    /**
     * 导入车辆范围
     */
    importCarScope() {
      carScopeImportApi(
        this.carScopeImportFile,
        this.form.clientId,
        this.agreementId,
        this.opType
      )
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log('upload error', err);
          const result = err.data || {};
          if (200 !== result.code) {
            this.$message.warning(result.message || '导入包干车辆清单错误');
          }
        });
    },

    serviceAgreementPriceImportApi(file) {
      return serviceAgreementPriceImport(file, this.id);
    },
    serviceAgreementPriceImportResultApi() {
      return serviceAgreementPriceImportResult(this.id);
    },

    goodsAgreementPriceImportApi(file) {
      return goodsAgreementPriceImport(file, this.id);
    },
    goodsAgreementPriceImportResultApi() {
      return goodsAgreementPriceImportResult(this.id);
    },
    handleImport() {
      this.$refs.agreementPriceListDOM.init();
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item {
  .el-date-editor,
  .el-select {
    width: 100%;
  }
}

.client {
  margin-left: 50px;
  display: flex;
  align-items: center;
  > .icon_delete {
    font-size: 18px;
    color: #ff9200;
    margin-right: 15px;
  }
  .client-info {
    background: #f5f7fa;
    border-radius: 4px;
    width: 99%;
    padding: 20px;
    > div + div {
      margin-top: 10px;
    }
    i {
      margin: 0 5px;
    }
    .icon_person {
      color: $blue;
    }
    .icon_sex {
      color: $blue;
    }
    .icon_grade {
      color: $blue;
    }
    .grow-icon_girl {
      color: #f48080;
    }
    .icon_wechat {
      color: #999;
    }
    .icon_wechat_active {
      color: #00c800;
    }
    .icon_phone {
      color: #42bbb8;
    }
    .icon_vip {
      color: $orange;
    }
    .icon_sex,
    .icon_grade,
    .icon_wechat,
    .icon_wechat_active,
    .icon_vip {
      font-size: 14px;
    }
  }
}
.client-tip {
  margin-left: 30px;
  font-size: 14px;
  color: #999;
  line-height: 20px;
}
.img-note {
  color: #999;
  font-size: 14px;
}
.car-imgs {
  display: flex;
}
.note {
  margin-top: 30px;
  color: #999;
  font-size: 14px;
  > p {
    flex-shrink: 0;
  }
  > div > p + p {
    margin-top: 10px;
  }
}
.mt60 {
  margin-top: 60px;
}
.table-title {
  width: 150px;
  height: 50px;
  font-size: 14px;
  border-radius: 4px 4px 0px 0px;
  background: $bg;
  text-align: center;
  line-height: 50px;
  color: #33ab79;
  font-weight: 600;
}
.service-class {
  width: 45%;
}
.goods-class {
  margin-left: 10%;
  width: 45%;
}
.undertake-title {
  margin: 20px 0;
  font-size: 14px;
  font-weight: 500;
  color: #242526;
}
.tip-block {
  margin-bottom: 24px;
  font-size: 14px;
  .tip-title {
    font-weight: 400;
    color: #242526;
  }
  .tip-line {
    color: #999;
    margin: 8px 15px 0;
  }
}
.undertake-type {
  margin: 30px 0 60px;
  > div {
    margin-top: 30px;
  }
}
.input-line {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #666;
  .el-input {
    width: 200px;
  }
}
.item-block {
  display: flex;
  margin: 30px 0;
  > h6 {
    margin-right: 30px;
    color: #333;
    font-weight: 600;
  }
}
.table-box {
  width: 450px;
  margin-left: 100px;
}
.foot-btn {
  padding: 10px 20px;
  box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.1);
}
.table-title-but {
  display: flex;
  justify-content: space-between;
  .mt60 {
    button {
      color: #ffffff;
      border: 0px;
    }
    button:first-child {
      background: #33ab79;
    }
    button:last-child {
      background: #55b78e;
    }
    button:nth-child(2) {
      background: #33ab79;
    }
  }
}
.contract-set {
  background: #f5f7fa;
  padding: 2px 21px;
  border-radius: 4px;
}
.el-descriptions ::v-deep .table-label {
  vertical-align: middle;
  color: #666;
  text-align: center;
  background: $descriptions-label-bg;
  width: 10%;
}
.is-responsibility {
  font-size: 12px;
  color: #999;
  margin-left: 5px;
}
.settlement {
  width: 100%;
  background: #f5f7fa;
  border-radius: 4px;
  padding-top: 16px;
  .first_row {
    margin: 0px 26px 0 -68px;
  }
  .two_row {
    margin: 0px 26px 24px -68px;
  }
}
.container {
  .border-table {
    border-collapse: collapse;
    width: 100%;
    font-size: 14px;

    th,
    tr {
      line-height: 18px;
      font-size: 14px;
    }
    th,
    td {
      box-sizing: border-box;
      border: 1px solid #ebeef5;
      padding: 8px;
      white-space: pre-wrap;
      color: #3c3d40;
      font-weight: 400;
    }

    th {
      padding: 10px 8px;
      background: #f5f7fa;
      font-weight: 500;
      color: #242526;
      text-align: left;
    }
    td {
      height: 1px;
      vertical-align: top;
      &:first-child {
        width: 140px;
        vertical-align: middle;
        background: #f5f7fa;
        color: #242526;
        font-weight: 400;
      }
    }
    .el-textarea {
      height: 100%;
      ::v-deep .el-textarea__inner {
        min-height: 100% !important;
      }
    }
  }
}
.load-more-btn {
  text-align: center;
  line-height: 50px;
  color: #3eb1e0;
  cursor: pointer;
  &:hover {
    color: #14a3db;
  }
}
.customer-table ::v-deep .el-table__body-wrapper {
  overflow: auto;
}

.car-scope {
  .car-scope-title {
    font-size: 14px;
    font-weight: 500;
    color: #242526;
    line-height: 20px;
  }
  .car-scope-text {
    margin: 8px 0;
    font-size: 12px;
    font-weight: 400;
    color: #606266;
    line-height: 17px;
  }
  .import-tips {
    margin: 40px 0 8px 0;
    font-size: 20px;
    font-weight: 500;
    color: #242526;
    line-height: 28px;
  }
}
</style>
