<template>
  <el-drawer
    title="流程详情"
    :visible="visibility"
    size="60%"
    :append-to-body="true"
    :before-close="handleClose"
    modal
    close-on-press-escape
    wrapperClosable
  >
    <div class="content">
      <el-table :data="controlsList" border>
        <el-table-column type="index" label="序号" width="60">
        </el-table-column>
        <el-table-column width="100" label="操作类型" prop="operationType">
          <template #default="{ row }">
            {{ row.operationType | dict('operationType') }}
          </template>
        </el-table-column>
        <el-table-column width="120" label="操作人" prop="operatorName">
        </el-table-column>
        <el-table-column width="150" label="操作时间" prop="dateCreated">
        </el-table-column>
        <el-table-column label="操作详情" prop="description">
          <template #default="{ row }">
            <el-tooltip :content="row.description">
              <div class="text-ellipsis pointer">
                {{ row.description }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column width="110" label="明细">
          <template #default="{ row }">
            <div class="blue pointer" @click="openDetail(row.billLogId)">
              {{ [13, 14, 31].includes(row.operationType) ? '明细' : '' }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-drawer
      size="50%"
      title="明细"
      :visible.sync="detailDrawer"
      :append-to-body="true"
      modal
      wrapperClosable
    >
      <div style="margin: 0 30px">
        <el-table
          style="width: 100%"
          class="service-table"
          border
          :data="serviceTableData"
          default-expand-all
          row-key="id"
          :tree-props="{ children: 'billGoods', hasChildren: 'hasChildren' }"
          :span-method="spanMethod"
        >
          <el-table-column width="60" label="序号">
            <template #default="{ row }">
              <div>
                {{ row.title }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="项目名称" min-width="160" prop="name">
            <template #default="{ row }">
              <span v-if="row.name" :class="{ orange: row.emptyService }">
                {{ row.name }}</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="price" label="单价" width="80">
            <template #default="{ row }">
              <span :key="tableKey + 1">
                {{ row.price | toThousands }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="discount" label="折扣" width="60">
            <template #default="{ row }">
              <span :key="tableKey + 2">
                {{ row.discount || '无' }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="date" label="数量" width="70">
            <template #default="{ row }">
              <span :key="tableKey + 3">
                <span v-if="row.showAllNum" class="orange">
                  <p>报价{{ row.num }}</p>
                  <p v-if="row.waitPickNum">待领{{ row.waitPickNum }}</p>
                  <p v-if="row.pickedNum">已领{{ row.pickedNum }}</p>
                </span>
                <span v-else>{{ row.num }}</span>
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="date" label="小计" width="90">
            <template #default="{ row }">
              <div>
                <span :key="tableKey + 4">
                  {{ row.total | toThousands }}
                </span>
                <div
                  v-if="![0, '', undefined, null].includes(row.billDiscount)"
                >
                  <span>整单折扣后: </span>
                  <span>¥{{ row.billDiscount | toThousands }}</span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="项目合计" width="90">
            <template #default="{ row }">
              <div v-if="[0, '', undefined].includes(row.billDiscount)">
                <div>{{ row.itemTotalBefore | toThousands }}</div>
              </div>
              <div v-else>
                <div>{{ row.itemTotalAfter | toThousands }}</div>
                <del>{{ row.itemTotalBefore | toThousands }}</del>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="施工技师" width="90">
            <template #default="{ row }">
              <div :key="tableKey + 5">
                <div v-if="$lodash.get(row, 'technicianInfoVos.length', false)">
                  <div>
                    {{
                      $lodash
                        .get(row, 'technicianInfoVos', [])
                        .map((item) => item.technicianName)
                        .join('、')
                    }}
                  </div>
                </div>
                <!-- <div v-else-if="row.isConstructionFree">免施工</div> -->
              </div>
              <!-- <div
                class="orange"
                v-if="![1, 2].includes(billMessage.billState)"
              >
                {{ row.dispatchTaskState | dict('dispatchTaskState') }}
              </div> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-drawer>
  </el-drawer>
</template>
<script>
import { getBillFlowList, getDetailsByLogId } from '@/api/carBill';
//字典
import { operationType } from '@/service/dict/dictData';
export default {
  name: 'historicalOperationDrawer',
  props: {
    visibility: { type: Boolean, default: false },
    billId: {
      type: [Number, String],
      default: '',
    },
  },
  data() {
    return {
      controlsList: [],
      //明细抽屉可见性
      detailDrawer: false,
      serviceTableData: [],

      tableKey: 999,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      getBillFlowList(this.billId).then((res) => {
        this.controlsList = res.datas;
      });
    },
    /**
     * 关闭弹窗
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
    //打开明细抽屉
    openDetail(id) {
      this.detailDrawer = true;
      this.serviceTableData = [];
      getDetailsByLogId(id).then((res) => {
        if (res.serviceItems) {
          //处理数据
          var serviceTableData = this.$lodash
            .cloneDeep(res.serviceItems)
            .map((service) => {
              service.id = service.billServiceId;
              if (!service.id) {
                service.id = Math.random();
                service.name = '提示: 只出库未报价商品客户不可见不打印';
                service.emptyService = true;
                service.goodsInfoVos?.forEach((g) => {
                  g.showAllNum = true;
                  g.num = 0;
                });
              } else {
                service.name = service.serviceItemName;
              }
              service.code = service.serviceItemCode;

              service.billGoods = service.goodsInfoVos;
              if (service?.billGoods?.length) {
                service.billGoods?.forEach((item) => {
                  item.id = item.billGoodsId;
                  if (!item.id) item.id = Math.random();
                  item.name = item.goodsName;
                  item.code = item.goodsCode;
                  item.parentId = service.id;

                  /**
                   * 判断是否显示待领
                   * 当报价单商品=材料单商品数，且商品待领数非0时，显示待领数量
                   * 当报价商品!=材料单商品时，显示当前报价数、待领数、已领数。当待领数为0时，不显示待领0 字样。
                   */
                  let materialCount =
                    (item.pickedNum || 0) + (item.waitPickNum || 0);
                  if (
                    (materialCount && item.num != materialCount) ||
                    item.waitPickNum > 0
                  ) {
                    item.showAllNum = true;
                  }
                });
              }

              return service;
            });
          this.setIndex(serviceTableData);
          this.serviceTableData = serviceTableData;
        }
      });
    },
    /**
     * 设置序号
     */
    setIndex(arr, indexArr = [], children = 'billGoods') {
      arr.forEach((item, index) => {
        let cloneArr = [];
        if (indexArr.length) {
          cloneArr = indexArr.concat(index + 1);
        } else {
          cloneArr.push(index + 1);
        }
        item.title = cloneArr.join('.');
        if (item[children]?.length) {
          this.setIndex(item[children], cloneArr);
        }
      });
    },
    /**
     * 表格合并方法
     */
    spanMethod({ row, column, rowIndex, columnIndex }) {
      if ([6, 7].includes(columnIndex)) {
        if (!row.parentId) {
          var length = row.billGoods?.length || 0;
          if (length) {
            return {
              rowspan: length + 1,
              colspan: 1,
            };
          } else {
            return [1, 1];
          }
        } else {
          return [0, 0];
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-drawer__header {
  margin-bottom: 12px !important;
}
.content {
  height: calc(100% - 52px);
  position: relative;
  padding: 0 30px;
  box-sizing: border-box;
  overflow: auto;
}
.service-table.el-table ::v-deep .el-table__row--level-1 {
  background: #f5f7fa;
}
// 不显示收起图标
.service-table.el-table ::v-deep .el-table__expand-icon {
  display: none;
}
.service-table ::v-deep .el-table__empty-text {
  width: 100% !important;
}
.service-table.el-table ::v-deep .el-table__placeholder {
  width: 0;
}
.service-table.el-table ::v-deep .el-table__indent {
  padding: 0 !important;
}
</style>
