var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visibility,
        width: "800px",
        "before-close": _vm.handleClose,
        "close-on-click-modal": false,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticStyle: { "margin-top": "18px" },
          attrs: {
            size: "small",
            model: _vm.editForm,
            rules: _vm.rules,
            "label-width": "90px",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "回款门店", prop: "receivingCompanyId" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            clearable: "",
                            placeholder: "请输入门店名称搜索选择",
                          },
                          model: {
                            value: _vm.editForm.receivingCompanyId,
                            callback: function ($$v) {
                              _vm.$set(_vm.editForm, "receivingCompanyId", $$v)
                            },
                            expression: "editForm.receivingCompanyId",
                          },
                        },
                        _vm._l(_vm.companyList, function (item) {
                          return _c(
                            "el-option",
                            {
                              key: item.companyId,
                              attrs: {
                                label: item.companyName,
                                value: item.companyId,
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    float: "left",
                                    color: "#8492a6",
                                    "font-size": "13px",
                                  },
                                },
                                [_vm._v(_vm._s(item.companyName))]
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "流水编号", prop: "sysStatementSeqCode" },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          disabled: "",
                          maxlength: "200",
                          placeholder: "系统流水编号系统自动填充",
                        },
                        model: {
                          value: _vm.editForm.sysStatementSeqCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "sysStatementSeqCode", $$v)
                          },
                          expression: "editForm.sysStatementSeqCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "回款主体", prop: "invoicingEntity" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: "请选择回款主体",
                          },
                          model: {
                            value: _vm.editForm.invoicingEntity,
                            callback: function ($$v) {
                              _vm.$set(_vm.editForm, "invoicingEntity", $$v)
                            },
                            expression: "editForm.invoicingEntity",
                          },
                        },
                        _vm._l(_vm.invoicingEntities, function (item) {
                          return _c("el-option", {
                            key: item,
                            attrs: { label: item, value: item },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "开户行", prop: "depositBank" } },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "200",
                          placeholder: "请输入开户行",
                        },
                        model: {
                          value: _vm.editForm.depositBank,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "depositBank", $$v)
                          },
                          expression: "editForm.depositBank",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "银行账号", prop: "bankAccount" } },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "200",
                          placeholder: "请输入银行账号",
                        },
                        model: {
                          value: _vm.editForm.bankAccount,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "bankAccount", $$v)
                          },
                          expression: "editForm.bankAccount",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "付款主体", prop: "insuranceCompanyId" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: "请选择付款主体",
                          },
                          model: {
                            value: _vm.editForm.insuranceCompanyId,
                            callback: function ($$v) {
                              _vm.$set(_vm.editForm, "insuranceCompanyId", $$v)
                            },
                            expression: "editForm.insuranceCompanyId",
                          },
                        },
                        _vm._l(_vm.insuranceCompanyList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label: item.insuranceName,
                              value: item.id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "付款账号", prop: "paymentAccount" } },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "200",
                          placeholder: "请输入银行账号",
                        },
                        model: {
                          value: _vm.editForm.paymentAccount,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "paymentAccount", $$v)
                          },
                          expression: "editForm.paymentAccount",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "回款时间", prop: "receivingDatetime" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "w100",
                        attrs: {
                          type: "datetime",
                          placeholder: "请选择回款时间",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.dateRangeChange()
                          },
                        },
                        model: {
                          value: _vm.editForm.receivingDatetime,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "receivingDatetime", $$v)
                          },
                          expression: "editForm.receivingDatetime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "金额", prop: "amount" } },
                    [
                      _c("el-input-number", {
                        attrs: {
                          maxlength: "200",
                          min: 0,
                          "show-word-limit": "",
                          placeholder: "请输入金额",
                          clearable: "",
                        },
                        model: {
                          value: _vm.editForm.amount,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "amount", $$v)
                          },
                          expression: "editForm.amount",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "银行摘要", prop: "bankSummary" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: 3,
                          maxlength: "200",
                          placeholder: "请输入银行摘要",
                        },
                        model: {
                          value: _vm.editForm.bankSummary,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "bankSummary", $$v)
                          },
                          expression: "editForm.bankSummary",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "流水号", prop: "transactionSeq" } },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "200",
                          placeholder: "请输入资金流水号",
                        },
                        model: {
                          value: _vm.editForm.transactionSeq,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "transactionSeq", $$v)
                          },
                          expression: "editForm.transactionSeq",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.save },
            },
            [_vm._v(" 确 定 ")]
          ),
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.handleClose } },
            [_vm._v(" 取 消 ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }