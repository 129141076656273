<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  created() {
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('VuexData', JSON.stringify(this.$store.state));
    });
    try {
      sessionStorage.getItem('VuexData') &&
        this.$store.replaceState(
          Object.assign(
            {},
            this.$store.state,
            JSON.parse(sessionStorage.getItem('VuexData'))
          )
        );
    } catch (err) {
      console.log(err);
    }
    sessionStorage.removeItem('VuexData');
  },
  mounted() {
    if (location.search.includes('?t=')) {
      history.replaceState(null, null, './' + location.hash);
    }
  },
  methods: {},
};
</script>
<style lang="scss"></style>
