var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "content" }, [
      _vm.localHasAuth(744) || _vm.localHasAuth(745)
        ? _c("div", { staticClass: "flex index-path" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 744,
                    expression: "744",
                  },
                ],
                staticClass: "path-item",
              },
              [
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "editCar",
                        on: {
                          click: function ($event) {
                            return _vm.gotoNewSysPage(
                              1007,
                              "miniProgramBookingRecordList",
                              {}
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.overviewData.todayBookingWaitCount) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._m(0),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 745,
                    expression: "745",
                  },
                ],
                staticClass: "path-item",
              },
              [
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "editCar",
                        on: {
                          click: function ($event) {
                            return _vm.gotoNewSysPage(
                              1013,
                              "miniProgramBookingNumberTakeList",
                              {}
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.overviewData.takeNumberWaitCount) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._m(1),
              ]
            ),
          ])
        : _vm._e(),
      _vm.localHasAuth(746) ||
      _vm.localHasAuth(747) ||
      _vm.localHasAuth(748) ||
      _vm.localHasAuth(749) ||
      _vm.localHasAuth(750) ||
      _vm.localHasAuth(751)
        ? _c("div", { staticClass: "flex index-path" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 746,
                    expression: "746",
                  },
                ],
                staticClass: "path-item",
              },
              [
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "editCar",
                        on: {
                          click: function ($event) {
                            return _vm.gotoNewSysPage(35, "carBillList", {
                              billState: 48,
                            })
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.overviewData.notDetectionBillCount) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._m(2),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 747,
                    expression: "747",
                  },
                ],
                staticClass: "path-item",
              },
              [
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "editCar",
                        on: {
                          click: function ($event) {
                            return _vm.gotoNewSysPage(35, "carBillList", {
                              billState: 49,
                            })
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.overviewData.notQuoteBillCount) + " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._m(3),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 748,
                    expression: "748",
                  },
                ],
                staticClass: "path-item",
              },
              [
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "editCar",
                        on: {
                          click: function ($event) {
                            return _vm.gotoNewSysPage(35, "carBillList", {
                              billState: 50,
                            })
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.overviewData.notQuoteApproveBillCount) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._m(4),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 749,
                    expression: "749",
                  },
                ],
                staticClass: "path-item",
              },
              [
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "editCar",
                        on: {
                          click: function ($event) {
                            return _vm.gotoNewSysPage(35, "carBillList", {
                              billState: 51,
                            })
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.overviewData.notDispatchBillCount) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._m(5),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 750,
                    expression: "750",
                  },
                ],
                staticClass: "path-item",
              },
              [
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "editCar",
                        on: {
                          click: function ($event) {
                            return _vm.gotoNewSysPage(
                              94,
                              "constructionBill",
                              { billState: 47 },
                              { dispatchType: 4 }
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.overviewData.notBeginTaskCount) + " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._m(6),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 751,
                    expression: "751",
                  },
                ],
                staticClass: "path-item",
              },
              [
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "editCar",
                        on: {
                          click: function ($event) {
                            return _vm.gotoNewSysPage(
                              94,
                              "constructionBill",
                              { billState: 47 },
                              { dispatchType: 0 }
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.overviewData.beginAndDoNotDoneTaskCount
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._m(7),
              ]
            ),
          ])
        : _vm._e(),
      _vm.localHasAuth(752) ||
      _vm.localHasAuth(753) ||
      _vm.localHasAuth(754) ||
      _vm.localHasAuth(755)
        ? _c("div", { staticClass: "flex index-path" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 752,
                    expression: "752",
                  },
                ],
                staticClass: "path-item",
              },
              [
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "editCar",
                        on: {
                          click: function ($event) {
                            return _vm.goOldPage("waitPicking")
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.overviewData.waitPickingCount) + " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._m(8),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 753,
                    expression: "753",
                  },
                ],
                staticClass: "path-item",
              },
              [
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "editCar",
                        on: {
                          click: function ($event) {
                            return _vm.goOldPage("waitPurchase")
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.overviewData.waitPurchaseBillCount) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._m(9),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 754,
                    expression: "754",
                  },
                ],
                staticClass: "path-item",
              },
              [
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "editCar",
                        on: {
                          click: function ($event) {
                            return _vm.goOldPage("purchaseWaitInInventory")
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.overviewData.purchaseBillWaitInInventoryCount
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._m(10),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 755,
                    expression: "755",
                  },
                ],
                staticClass: "path-item",
              },
              [
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "editCar",
                        on: {
                          click: function ($event) {
                            return _vm.gotoNewSysPage(
                              1018,
                              "waitReturnInInventory",
                              {},
                              {}
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.overviewData.waitReturnInInventoryCount
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._m(11),
              ]
            ),
          ])
        : _vm._e(),
      _vm.localHasAuth(756) || _vm.localHasAuth(757)
        ? _c("div", { staticClass: "flex index-path" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 756,
                    expression: "756",
                  },
                ],
                staticClass: "path-item",
              },
              [
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "editCar",
                        on: {
                          click: function ($event) {
                            return _vm.gotoNewSysPage(35, "carBillList", {
                              billState: 46,
                            })
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.overviewData.taskCompleteAndNotCheckBillCount
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._m(12),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 757,
                    expression: "757",
                  },
                ],
                staticClass: "path-item",
              },
              [
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "editCar",
                        on: {
                          click: function ($event) {
                            return _vm.gotoNewSysPage(35, "carBillList", {
                              billState: 47,
                            })
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.overviewData.checkAndNotTakeCarBillCount
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._m(13),
              ]
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "path-tip" }, [
      _c("p", [_vm._v("当天预约待处理")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "path-tip" }, [
      _c("p", [_vm._v("当前排号数")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "path-tip" }, [_c("p", [_vm._v("未检车")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "path-tip" }, [_c("p", [_vm._v("未报价")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "path-tip" }, [_c("p", [_vm._v("未审核")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "path-tip" }, [
      _c("p", [_vm._v("未提交施工")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "path-tip" }, [_c("p", [_vm._v("待施工")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "path-tip" }, [
      _c("p", [_vm._v("施工中未完工")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "path-tip" }, [
      _c("p", [_vm._v("待领料出库")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "path-tip" }, [_c("p", [_vm._v("待采购")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "path-tip" }, [
      _c("p", [_vm._v("采购待入库")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "path-tip" }, [
      _c("p", [_vm._v("待退料入库")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "path-tip" }, [
      _c("p", [_vm._v("已完工未结账")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "path-tip" }, [
      _c("p", [_vm._v("已结账未提车")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }