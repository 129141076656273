var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { ref: "baseContainer", attrs: { paddingTop: "" } },
    [
      [
        _c(
          "el-form",
          {
            staticClass: "filter-form",
            attrs: { model: _vm.form, "label-width": "100px", size: "small" },
          },
          [
            _c("div", { staticClass: "flex-x-between" }, [
              _c(
                "div",
                { staticClass: "flex flex-shrink0" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品标准名" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入商品标准名",
                          clearable: "",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.getData.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.form.standardName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "standardName", $$v)
                          },
                          expression: "form.standardName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "适用车型" } },
                    [
                      _c("el-autocomplete", {
                        attrs: {
                          "fetch-suggestions": _vm.searchCarModelList,
                          placeholder: "请输入车型",
                          clearable: "",
                        },
                        model: {
                          value: _vm.form.matchCarType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "matchCarType", $$v)
                          },
                          expression: "form.matchCarType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "自编码" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入自编码", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.getData.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.form.ownCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "ownCode", $$v)
                          },
                          expression: "form.ownCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "el-form-item--small" }, [
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "btn_search",
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.getData },
                      },
                      [_vm._v("查询")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn_search",
                        attrs: { size: "small" },
                        on: { click: _vm.empty },
                      },
                      [_vm._v("置空")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "flex-warp" },
              [
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "原厂编码" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入原厂编码", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.getData.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.form.goodsCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "goodsCode", $$v)
                          },
                          expression: "form.goodsCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品名称" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入商品名称", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.getData.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品分类" } },
                    [
                      _c("select-goods-class-cascader", {
                        ref: "goodsClass",
                        attrs: {
                          value: [
                            _vm.form.firstCategoryId,
                            _vm.form.secondCategoryId,
                          ],
                          checkStrictly: "",
                        },
                        on: { select: _vm.handleSelectGoodsClass },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "启用状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择启用状态" },
                          model: {
                            value: _vm.form.isActive,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "isActive", $$v)
                            },
                            expression: "form.isActive",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "已启用", value: true },
                          }),
                          _c("el-option", {
                            attrs: { label: "未启用", value: false },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品ID" } },
                    [
                      _c("number-input", {
                        attrs: {
                          decimalDigits: "0",
                          max: 9999999999999999,
                          placeholder: "请输入完整的商品ID",
                          clearable: "",
                        },
                        model: {
                          value: _vm.form.goodsId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "goodsId", $$v)
                          },
                          expression: "form.goodsId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        ),
      ],
      [
        _c(
          "div",
          {
            staticClass: "btns flex-x-between",
            staticStyle: { margin: "30px 0 14px 0" },
          },
          [
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "auth",
                        rawName: "v-auth",
                        value: 19,
                        expression: "19",
                      },
                    ],
                    staticClass: "btn_insert",
                    attrs: { size: "small" },
                    on: { click: _vm.add },
                  },
                  [_vm._v("新增")]
                ),
                _c(
                  "el-button",
                  {
                    staticStyle: { "margin-left": "10px" },
                    attrs: { size: "small" },
                    on: {
                      click: function ($event) {
                        _vm.importDialogVisibility = true
                      },
                    },
                  },
                  [
                    _vm._v(" 批量导入"),
                    _c("i", { staticClass: "el-icon-arrow-right" }),
                  ]
                ),
              ],
              1
            ),
            _c(
              "el-button",
              {
                staticClass: "blue",
                attrs: { size: "small", type: "text" },
                on: {
                  click: function ($event) {
                    return _vm.downloadAllRecords()
                  },
                },
              },
              [
                _vm._v("导出列表"),
                _c("i", {
                  staticClass: "iconfont grow-icon_daochu el-icon--right",
                }),
              ]
            ),
          ],
          1
        ),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "fixedScrollBar",
                rawName: "v-fixedScrollBar",
                value: _vm.$refs.baseContainer,
                expression: "$refs.baseContainer",
              },
            ],
            staticClass: "custom-table",
            attrs: {
              data: _vm.listData,
              "default-sort": { prop: "goodsCode", order: "desc" },
              border: "",
            },
            on: { "sort-change": _vm.listDataTableSortChangeEvent },
          },
          [
            _c("el-table-column", {
              attrs: { type: "index", label: "序号", width: "60" },
            }),
            _c("el-table-column", {
              attrs: { label: "操作", width: "60" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "auth",
                              rawName: "v-auth",
                              value: 21,
                              expression: "21",
                            },
                          ],
                          staticClass: "text_operation blue",
                          on: {
                            click: function ($event) {
                              return _vm.handleEdit(scope.row)
                            },
                          },
                        },
                        [_vm._v("编辑")]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "商品ID", prop: "goodsId" },
            }),
            _c("el-table-column", {
              attrs: { width: "140", label: "适用车型", prop: "matchCarType" },
            }),
            _c("el-table-column", {
              attrs: { width: "180", label: "商品标准名" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("span", { staticClass: "blue" }, [
                        _vm._v(_vm._s(row.standardName)),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "180", label: "商品名称", prop: "name" },
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "品质" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("dict")(
                              row.manufactoryType,
                              "manufactoryType"
                            )
                          )
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                width: "140",
                label: "自编码",
                prop: "ownCode",
                sortable: "custom",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "140",
                label: "原厂编码",
                prop: "goodsCode",
                sortable: "custom",
              },
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "单位", prop: "unit" },
            }),
            _c("el-table-column", {
              attrs: { width: "160", label: "商品分类" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("span", [_vm._v(_vm._s(row.firstCategoryName))]),
                      row.secondCategoryName
                        ? _c("span", [
                            _vm._v("/" + _vm._s(row.secondCategoryName)),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "库存预警值", prop: "alarmNum" },
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "总库存", prop: "allInventoryNum" },
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "创建人", prop: "creator" },
            }),
            _c("el-table-column", {
              attrs: { width: "200", label: "创建时间", prop: "dateCreated" },
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "修改人", prop: " updater" },
            }),
            _c("el-table-column", {
              attrs: { width: "200", label: "修改时间", prop: "lastUpdated" },
            }),
            _c("el-table-column", {
              attrs: { "min-width": "160", label: "备注", prop: "remark" },
            }),
          ],
          1
        ),
        _vm.showEditDialog
          ? _c("edit-goods-dialog", {
              attrs: { visibility: _vm.showEditDialog, id: _vm.editId },
              on: {
                "update:visibility": function ($event) {
                  _vm.showEditDialog = $event
                },
                save: _vm.getData,
              },
            })
          : _vm._e(),
        _vm.importDialogVisibility
          ? _c("import-dialog", {
              attrs: {
                visibility: _vm.importDialogVisibility,
                title: "商品导入",
                templateFileUrl:
                  "https://growxny-dev-center.oss-cn-guangzhou.aliyuncs.com/template/20240418/%E5%95%86%E5%93%81%E7%AE%A1%E7%90%86%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF_20240418.xlsx",
                uploadFileApi: _vm.goodsImportApi,
                getLastResultApi: _vm.goodsImportResultApi,
                importFailDetail: _vm.goodsImportMessageExportUrlApi,
              },
              on: {
                "update:visibility": function ($event) {
                  _vm.importDialogVisibility = $event
                },
              },
            })
          : _vm._e(),
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c("pagination", {
            attrs: { total: _vm.total },
            on: { pageChange: _vm.changePage },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }