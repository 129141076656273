import http from '@/service/http';

// 管控门店列表
export function getControlCompanyList(
  keyWord = '',
  startTime = '',
  config = {
    noShowWaiting: true,
  }
) {
  return http.post(
    '/admin/company/controlCompanyList',
    { name: keyWord, startTime: startTime },
    config
  );
}
//毛利明细
export function getGrossProfitList(data) {
  return http.post('/admin/data/board/grossProfitDetails', data, {
    onlyReturnData: true,
  });
}
//门店成本
export function companyCost(
  data,
  config = {
    noShowWaiting: true,
  }
) {
  return http.post('/admin/data/board/companyCost', data, config);
}
//门店成本批量保存
export function companyCostBatchSave(data, config = { onlyReturnData: false }) {
  return http.post('/admin/data/board/companyCost/batchSave', data, config);
}
