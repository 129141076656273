<template>
  <base-container>
    <!-- <div class="tip">
        <i class="iconfont grow-icon_warn_32 orange"></i>
        <span>提醒规则修改后将于次日零点生效</span>
      </div> -->
    <div class="flex">
      <el-table class="custom-table" :data="lossWaringDate" border>
        <el-table-column
          width="60"
          label="序号"
          type="index"
          :index="indexMethod"
        >
        </el-table-column>
        <el-table-column width="100" label="定损状态" prop="lossReportedState">
          <template #default="{ row }">
            <!-- lossReportedState -->
            <div>
              <span>{{ row.lossReportedState }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="120" label="提醒文案" prop="remind">
        </el-table-column>
        <el-table-column width="330" label="提醒设置">
          <template #default="{ row }">
            <div class="flex">
              <div class="flex" style="margin-top: 10px; margin-right: 20px">
                <el-radio v-model="row.state" label="0">不提醒</el-radio>
                <el-radio v-model="row.state" label="1">提醒</el-radio>
              </div>
              <el-input
                v-model.number="row.time"
                placeholder="请输入整数"
                :maxlength="6"
              >
              </el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          label="提醒节点"
          prop="reminderNode"
        ></el-table-column>
        <el-table-column width="90" label="时间" prop="timeUnit">
        </el-table-column>
        <el-table-column mix-width="280" label="备注" prop="remark">
          <template #default="{ row }">
            <!-- <div v-if="row.state == 1"> -->
            <div>
              <p v-if="row.stateNum == 0">
                进店 {{ row.time }} 分钟后仍为“待报损”状态将提示“提报超期”
              </p>
              <p v-if="row.stateNum == 1">
                报损 {{ row.time }} 分钟后仍为“待调度”状态将提示“调度超期”
              </p>
              <p v-if="row.stateNum == 2">
                进店 {{ row.time }} 天后仍为“待拍照对件”状态将提示“对件超期”
              </p>
              <p v-if="row.stateNum == 3">
                拍照对件 {{ row.time }} 小时后仍为“待提定损“状态将提示”定损超期“
              </p>
              <p v-if="row.stateNum == 4">
                进店 {{ row.time }} 天后仍为“待出定损”状态将提示“出损超期”
              </p>
              <p v-if="row.stateNum == 5">
                进店 {{ row.time }} 天后仍为“已出定损”状态将提示“回款超期”
              </p>
              <p v-if="row.stateNum == 6">
                已出定损 {{ row.time }} 小时后仍为“待复勘”状态将提示“复勘超期”
              </p>
              <p v-if="row.stateNum == 7">
                已出定损 {{ row.time }} 小时后仍为“待收旧”状态将提示“收旧超期”
              </p>
              <p v-if="row.stateNum == 8">
                进店 {{ row.time }} 天后不在“全部回款”状态将提示“回款超期”
              </p>
            </div>
            <!-- <div v-else>
              <p>不提醒</p>
            </div> -->
          </template>
        </el-table-column>
        <el-table-column
          label="当日开单计时"
          prop="dailyBillingTiming"
          width="280"
          align="center"
        ></el-table-column>
      </el-table>
    </div>
    <template slot="footer">
      <div class="flex-x-end foot-btn">
        <el-button
          size="small"
          type="primary"
          style="margin-right: 20px"
          @click="save"
          >保存</el-button
        >
      </div>
    </template>
  </base-container>
</template>
<script>
import {
  carLossDeterminationBillReminderInfo,
  carLossDeterminationBillReminderSave,
} from '@/api/systemManagement/lossWaringSet';

export default {
  name: 'lossWarningSet',
  data() {
    return {
      lossWaringDate: [
        {
          stateNum: 0,
          lossReportedState: '待报损',
          remind: '提报超期',
          remindSet: '提醒',
          reminderNode: '进店时间',
          timeUnit: '分钟',
          remark: '',
          state: '',
          time: '',
          dailyBillingTiming: '17:30后进店于次日9点开始计时',
        },
        {
          stateNum: 1,
          lossReportedState: '待调度',
          remind: '调度超期',
          remindSet: '提醒',
          reminderNode: '完成报损',
          timeUnit: '分钟',
          remark: '',
          state: '',
          time: '',
          dailyBillingTiming: '',
        },
        {
          stateNum: 2,
          lossReportedState: '待拍照对件',
          remind: '对件超期',
          remindSet: '提醒',
          reminderNode: '进店时间',
          timeUnit: '天',
          remark: '',
          state: '',
          time: '',
          dailyBillingTiming: '17:30后进店于次日9点开始计时',
        },
        {
          stateNum: 3,
          lossReportedState: '待提定损',
          remind: '定损超期',
          remindSet: '提醒',
          reminderNode: '拍照对件',
          timeUnit: '小时',
          remark: '',
          state: '',
          time: '',
          dailyBillingTiming: '',
        },
        {
          stateNum: 4,
          lossReportedState: '待出定损',
          remind: '出损超期',
          remindSet: '提醒',
          reminderNode: '进店时间',
          timeUnit: '天',
          remark: '',
          state: '',
          time: '',
          dailyBillingTiming: '',
        },
        {
          stateNum: 5,
          lossReportedState: '已出定损',
          remind: '回款超期',
          remindSet: '提醒',
          reminderNode: '进店时间',
          timeUnit: '天',
          remark: '',
          state: '',
          time: '',
          dailyBillingTiming: '',
        },
        {
          stateNum: 6,
          lossReportedState: '待复勘',
          remind: '复勘超期',
          remindSet: '提醒',
          reminderNode: '已出定损',
          timeUnit: '小时',
          remark: '',
          state: '',
          time: '',
          dailyBillingTiming: '',
        },
        {
          stateNum: 7,
          lossReportedState: '待收旧',
          remind: '收旧超期',
          remindSet: '提醒',
          reminderNode: '已出定损',
          timeUnit: '小时',
          remark: '',
          state: '',
          time: '',
          dailyBillingTiming: '',
        },
        {
          stateNum: 8,
          lossReportedState: '已提资料',
          remind: '回款超期',
          remindSet: '提醒',
          reminderNode: '进店时间',
          timeUnit: '天',
          remark: '',
          state: '',
          time: '',
          dailyBillingTiming: '',
        },
      ],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      carLossDeterminationBillReminderInfo().then((res) => {
        //待报损
        this.lossWaringDate[0].state = res.waitLossReportedState.toString();
        this.lossWaringDate[0].time =
          res.waitLossReportedTime == 0 ? '' : res.waitLossReportedTime;
        //待调度
        this.lossWaringDate[1].state = res.waitDispatchState.toString();
        this.lossWaringDate[1].time =
          res.waitDispatchTime == 0 ? '' : res.waitDispatchTime;
        //待拍照对件
        this.lossWaringDate[2].state = res.waitPhotoMatchingState.toString();
        this.lossWaringDate[2].time =
          res.waitPhotoMatchingTime == 0 ? '' : res.waitPhotoMatchingTime;
        //待提定损
        this.lossWaringDate[3].state = res.waitSubmitLossState.toString();
        this.lossWaringDate[3].time =
          res.waitSubmitLossTime == 0 ? '' : res.waitSubmitLossTime;
        //待出定损
        this.lossWaringDate[4].state = res.waitAckLossState.toString();
        this.lossWaringDate[4].time =
          res.waitAckLossTime == 0 ? '' : res.waitAckLossTime;
        //已出定损
        this.lossWaringDate[5].state = res.ackLossState.toString();
        this.lossWaringDate[5].time =
          res.ackLossTime == 0 ? '' : res.ackLossTime;
        //待复勘
        this.lossWaringDate[6].state = res.waitInvestigationState.toString();
        this.lossWaringDate[6].time =
          res.waitInvestigationTime == 0 ? '' : res.waitInvestigationTime;
        //待收旧
        this.lossWaringDate[7].state = res.waitRecycledState.toString();
        this.lossWaringDate[7].time =
          res.waitRecycledTime == 0 ? '' : res.waitRecycledTime;
        //已提资料
        this.lossWaringDate[8].state = res.informationProvidedState.toString();
        this.lossWaringDate[8].time =
          res.informationProvidedTime == 0 ? '' : res.informationProvidedTime;
      });
    },
    indexMethod(index) {
      if (index == 6) {
        return (index = 6.1);
      } else if (index == 7) {
        return (index = 6.2);
      } else if (index == 8) {
        return (index = 7);
      } else {
        return index + 1;
      }
    },
    // isnumber(val) {
    //   console.log(val.replace(/\D/g, ''), '555');
    //   val = val.replace(/\D/g, '');
    //   return val;
    // },
    //保存事件
    save() {
      var data = {
        //待报损
        waitLossReportedState: this.lossWaringDate[0].state,
        waitLossReportedTime:
          this.lossWaringDate[0].time == '' ? '0' : this.lossWaringDate[0].time,
        //待调度
        waitDispatchState: this.lossWaringDate[1].state,
        waitDispatchTime:
          this.lossWaringDate[1].time == '' ? '0' : this.lossWaringDate[1].time,
        //待拍照对件
        waitPhotoMatchingState: this.lossWaringDate[2].state,
        waitPhotoMatchingTime:
          this.lossWaringDate[2].time == '' ? '0' : this.lossWaringDate[2].time,
        //待提定损
        waitSubmitLossState: this.lossWaringDate[3].state,
        waitSubmitLossTime:
          this.lossWaringDate[3].time == '' ? '0' : this.lossWaringDate[3].time,
        //待出定损
        waitAckLossState: this.lossWaringDate[4].state,
        waitAckLossTime:
          this.lossWaringDate[4].time == '' ? '0' : this.lossWaringDate[4].time,
        //已出定损
        ackLossState: this.lossWaringDate[5].state,
        ackLossTime:
          this.lossWaringDate[5].time == '' ? '0' : this.lossWaringDate[5].time,
        //待复勘
        waitInvestigationState: this.lossWaringDate[6].state,
        waitInvestigationTime:
          this.lossWaringDate[6].time == '' ? '0' : this.lossWaringDate[6].time,
        //待收旧
        waitRecycledState: this.lossWaringDate[7].state,
        waitRecycledTime:
          this.lossWaringDate[7].time == '' ? '0' : this.lossWaringDate[7].time,
        //已提资料
        informationProvidedState: this.lossWaringDate[8].state,
        informationProvidedTime:
          this.lossWaringDate[8].time == '' ? '0' : this.lossWaringDate[8].time,
      };
      carLossDeterminationBillReminderSave(data).then((res) => {
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '操作成功!',
          });
          this.getList();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-table {
  margin: 16px 0px 12px 0px;
}
.tip {
  margin: 16px 0px 12px 0px;
  span {
    color: #909399;
    font-size: 14px;
    margin-left: 5px;
  }
  i {
    font-size: 18px;
    vertical-align: text-top;
  }
}
.foot-btn {
  padding: 10px 20px;
  box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.1);
}
// .lossReportedState {
//   text-align: right;
//   // margin-right: 20px;
// }
</style>
