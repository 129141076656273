var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "img" } }, [
    _vm.base64
      ? _c("img", {
          staticStyle: { width: "300px", height: "150px", "z-index": "-1" },
          attrs: { src: _vm.base64 },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }