<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <div>
        <el-form
          class="filter-form"
          :model="form"
          label-width="100px"
          size="small"
        >
          <div class="flex-x-between">
            <div class="flex-warp flex-shrink0">
              <el-form-item>
                <template slot="label">
                  <div>
                    操作门店
                    <el-tooltip
                      effect="light"
                      content="车单反向记录的操作门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)"
                      placement="bottom-start"
                    >
                      <i
                        class="iconfont grow-icon_doubt"
                        style="color: #9a9a9a"
                      ></i>
                    </el-tooltip>
                  </div>
                </template>
                <operateCompanyCascader
                  v-model="form.operateCompanyId"
                ></operateCompanyCascader>
              </el-form-item>
              <el-form-item label="车牌号">
                <el-input
                  v-model="form.plateNumber"
                  placeholder="请输入"
                  clearable
                  @keyup.enter.native="doSearch"
                ></el-input>
              </el-form-item>
              <el-form-item label="接车单号">
                <el-input
                  v-model="form.billCode"
                  placeholder="请输入"
                  clearable
                  @keyup.enter.native="doSearch"
                ></el-input>
              </el-form-item>
            </div>
            <div class="flex">
              <div class="el-form-item--small">
                <div
                  @click="showMore = !showMore"
                  class="el-form-item__label pointer"
                >
                  更多筛选
                  <i
                    :class="{
                      primary: true,
                      'el-icon-arrow-right': !showMore,
                      'el-icon-arrow-down': showMore,
                    }"
                  ></i>
                </div>
                <div class="flex">
                  <el-button
                    class="btn_search"
                    type="primary"
                    size="small"
                    @click="doSearch"
                    >查询</el-button
                  >
                  <el-button class="btn_search" size="small" @click="empty"
                    >置空</el-button
                  >
                </div>
              </div>
            </div>
          </div>
          <div v-show="showMore">
            <el-form-item label="操作日期">
              <el-date-picker
                class="w100"
                v-model="form.operationTime"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="截止时间"
                value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="操作人">
              <el-select
                v-model="form.operatorId"
                @change="selectAdminJobListUsre"
                filterable
                clearable
                remote
                placeholder="搜索姓名、手机号"
                :remote-method="searchAdminJobListUsre"
                :loading="searchAdminJobListUsrLoading"
              >
                <el-option
                  v-for="item in AdminJobListUsrList"
                  :key="item.userId"
                  :label="item.realName"
                  :value="item.userId"
                >
                  <span style="float: left; color: #8492a6; font-size: 13px">{{
                    item.realName
                  }}</span>
                  <span style="float: right">{{ item.phone }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注">
              <el-input
                v-model="form.remark"
                placeholder="请输入"
                clearable
                @keyup.enter.native="doSearch"
              ></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </template>
    <!-- 以下是表格 -->
    <template>
      <div class="btns flex-x-between">
        <div>
          <div class="custom-tabs nopadding">
            <div
              @click="changeDispatchType(-1)"
              :class="{
                'custom-tab-item': true,
                active: dispatchType == -1,
              }"
            >
              全部
            </div>
            <div
              @click="changeDispatchType(0)"
              :class="{
                'custom-tab-item': true,
                active: dispatchType == 0,
              }"
            >
              自费业务
            </div>
            <div
              @click="changeDispatchType(1)"
              :class="{
                'custom-tab-item': true,
                active: dispatchType == 1,
              }"
            >
              包干业务
            </div>
            <div
              @click="changeDispatchType(2)"
              :class="{
                'custom-tab-item': true,
                active: dispatchType == 2,
              }"
            >
              理赔业务
            </div>
            <div
              @click="changeDispatchType(3)"
              :class="{
                'custom-tab-item': true,
                active: dispatchType == 3,
              }"
            >
              索赔业务
            </div>
          </div>
        </div>
        <div>
          <el-button
            v-auth="440"
            size="small"
            type="text"
            class="blue"
            @click="showExportDialog = true"
            >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
          ></el-button>
        </div>
      </div>
      <div class="flex h100" style="flex: 1; overflow: auto">
        <div class="h100" style="flex: 1; overflow: auto">
          <el-table
            class="custom-table"
            :data="carRecordTable"
            border
            :header-cell-style="headerCellStyle"
            @row-dblclick="goCarBillDetail($event.billId)"
            v-fixedScrollBar="$refs.baseContainer"
          >
            <el-table-column type="index" label="序号" width="80">
            </el-table-column>
            <el-table-column
              width="180"
              label="操作门店"
              prop="operateCompanyName"
            >
            </el-table-column>
            <el-table-column width="180" label="操作时间" prop="operateTime">
            </el-table-column>
            <el-table-column width="180" label="操作类型">
              <template #default="{ row }">
                {{ operation[row.operateType] }}
              </template>
            </el-table-column>
            <el-table-column width="180" label="接车单号">
              <template #default="{ row }">
                <span
                  class="text_operation blue"
                  @click="goCarBillDetail(row.billId)"
                  >{{ row.billCode }}</span
                >
              </template>
            </el-table-column>
            <el-table-column width="100" label="接车单类型" prop="billType">
              <template #default="{ row }">
                <div v-if="row.billType !== 0">
                  {{ row.billType | dict('carBillType') }}
                </div>
                <div v-else>
                  {{ row.isOthersSelfPayState | dict('isOthersSelfPayState') }}
                </div>
              </template>
            </el-table-column>
            <el-table-column width="100" label="车牌号" prop="plateNumber">
            </el-table-column>
            <el-table-column width="100" label="车型" prop="carInfo">
            </el-table-column>
            <el-table-column width="180" label="操作人" prop="operator">
            </el-table-column>
            <el-table-column
              width="100"
              label="操作人手机"
              prop="operatorPhone"
            >
            </el-table-column>
            <el-table-column mix-width="100" label="反向操作备注" prop="remark">
            </el-table-column>
          </el-table>
        </div>
        <div class="class-box">
          <p class="title">状态与统计（数量）</p>
          <el-tree
            class="custom-tree"
            :data="treeData"
            :props="treeProp"
            highlight-current
            node-key="id"
            :current-node-key="0"
            @node-click="onNodeClick"
          >
            <template #default="{ data }">
              <div class="tree-node">
                <span>{{ data.name }}</span>
                <span>({{ data.count }})</span>
              </div>
            </template>
          </el-tree>
        </div>
      </div>
      <export-dialog
        :visibility.sync="showExportDialog"
        :total="total"
        :pageOption="pageOption"
        :filterForm="form"
        :url="exportUrl"
      ></export-dialog>
    </template>

    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
  </base-container>
</template>
<script>
import { exportCusterListUrl } from '@/api/customer/customer';
// 分页组件
import pagination from '@/components/Pagination.vue';
import ExportDialog from '@/components/ExportDialog';
import { downloadFile } from '@/utils/fileApi';
//模糊搜索接口
import { getUserList } from '@/api/employee';

//接口
import {
  getListReverseProcessRecord,
  getReverseProcessRecordStatistics,
} from '@/api/financial/pickSingle';
import operateCompanyCascader from '@/components/businessComponent/operateCompanyCascader.vue';
// 工具
import Big from 'big.js';
import { mapState } from 'vuex';
export default {
  name: 'reverseProcessRecordList',
  components: { pagination, ExportDialog, operateCompanyCascader },
  data() {
    return {
      form: {},
      // 展示更多筛选
      showMore: false,
      //消费客户
      customers: [
        {
          value: 0,
          label: '自费/包干客户',
        },
        {
          value: 1,
          label: '保险公司',
        },
        {
          value: 2,
          label: '三包厂家',
        },
      ],
      //导出的页面设置
      exportUrl: exportCusterListUrl,

      //分页组件
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      showExportDialog: false,
      // 状态
      dispatchType: -1,
      // 车单列表数据
      carRecordTable: [],
      treeData: [
        {
          id: 0,
          name: '全部',
          count: 0,
        },
        {
          id: 20,
          name: '反结算',
          count: 0,
        },
        {
          id: 18,
          name: '重新服务',
          count: 0,
        },
        {
          id: 17,
          name: '取消接待',
          count: 0,
        },
      ],
      treeProp: {
        children: 'children',
        label: 'name',
      },
      //判断操作类型
      operation: {
        17: '取消接待',
        18: '重新服务',
        20: '反结算',
      },
      selectKey: 1,

      operationType: '',
      //搜索操作人列表字段
      searchAdminJobListUsrLoading: false,
      AdminJobListUsrList: [],
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  created() {
    this.form.operateCompanyId = [this.companyId];
    this.getList();
    this.getCarStatistic();
    this.searchAdminJobListUsre();
  },
  mounted() {},
  methods: {
    //置空输入框数据
    empty() {
      this.form = {};
    },
    // 更改页码
    changePage(e) {
      this.pageOption = e;
      this.getList(false);
    },
    headerCellStyle({ column: { label } }) {
      if (label == '滞留当日最后滞留情况') {
        return 'text-align:center';
      }
    },
    /**
     * 点击查询
     */
    doSearch() {
      this.pageOption = {
        size: 10,
        current: 1,
      };
      this.getList();
      this.getCarStatistic();
    },
    //获取列表数据
    getList(reset = true) {
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      var otherConditions = {
        operationType: this.operationType,
        startDate: this.form.operationTime?.[0]
          ? this.form.operationTime?.[0]
          : '',
        endDate: this.form.operationTime?.[1]
          ? this.form.operationTime?.[1]
          : '',
        billType: this.dispatchType == -1 ? '' : this.dispatchType,
      };
      var data = Object.assign(this.form, this.pageOption, otherConditions);
      getListReverseProcessRecord(data).then((res) => {
        this.total = res.total;
        this.carRecordTable = res.records;
      });
    },
    //获取列表状态与统计
    getCarStatistic() {
      var otherConditions = {
        operationType: '',
        startDate: this.form.operationTime?.[0]
          ? this.form.operationTime?.[0]
          : '',
        endDate: this.form.operationTime?.[1]
          ? this.form.operationTime?.[1]
          : '',
        billType: this.dispatchType == -1 ? '' : this.dispatchType,
      };
      var data = Object.assign(this.form, this.pageOption, otherConditions);
      getReverseProcessRecordStatistics(data).then((res) => {
        this.treeData[0].count = res.total;
        this.treeData[1].count = res.reverseReceptionSum;
        this.treeData[2].count = res.backServiceSum;
        this.treeData[3].count = res.cancelReceptionSum;
      });
    },
    /**
     * 点击节点时
     */
    onNodeClick(node) {
      this.operationType = node.id;
      this.getList();
    },
    /**
     * 改变状态
     */
    changeDispatchType(val) {
      this.dispatchType = val;
      this.getList();
    },

    //远程搜索操作人列表
    adminJobUsrKeyWordChange(keyWord) {
      if (keyWord) {
        this.searchAdminJobListUsre(keyWord);
      }
    },
    searchAdminJobListUsre(keyWord = '') {
      this.searchAdminJobListUsrLoading = true;
      getUserList(keyWord)
        .then((res) => {
          this.AdminJobListUsrList = res.records || [];
        })
        .finally(() => {
          this.searchAdminJobListUsrLoading = false;
        });
    },
    //选中接车员
    selectAdminJobListUsre() {},
    /**
     * 跳转车单详情页
     */
    goCarBillDetail(id) {
      this.$router.push({ name: 'carBillDetail', query: { id } });
    },
  },
};
</script>
<style lang="scss" scoped>
//状态与统计的样式
.class-box {
  width: 15%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  box-sizing: border-box;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  .title {
    flex-shrink: 0;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-bottom: 1px solid #e4e4e4;
    background: #fafafa;
    color: #888;
    font-size: 14px;
  }
}
.tree-node {
  color: #888;
  font-size: 14px;
}
</style>
