<template>
  <el-drawer
    title="库存详情"
    :visible="visibility"
    size="60%"
    :append-to-body="true"
    :before-close="handleClose"
    modal
    close-on-press-escape
    wrapperClosable
  >
    <div class="box">
      <div class="header">基本信息</div>
      <div class="block">
        <el-row :gutter="10">
          <el-col :span="12">
            <div class="label">适用车型：</div>
            <div class="value">{{ detail.matchCarType }}</div>
          </el-col>
          <el-col :span="12">
            <div class="label">商品标准名：</div>
            <div class="value">{{ detail.standardName }}</div>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <div class="label">原厂编码：</div>
            <div class="value">{{ detail.goodsCode }}</div>
          </el-col>
          <el-col :span="12">
            <div class="label">自编码：</div>
            <div class="value">{{ detail.ownCode }}</div>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <div class="label">品质：</div>
            <div class="value">
              {{ $lodash.get(detail, 'manufactoryTypeOption.desc') }}
            </div>
          </el-col>
          <el-col :span="12">
            <div class="label">单位：</div>
            <div class="value">{{ detail.unit }}</div>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <div class="label">商品分类：</div>
            <div class="value">
              <span>{{ detail.firstCategoryName }}</span>
              <span v-if="detail.secondCategoryName"
                >/{{ detail.secondCategoryName }}</span
              >
            </div>
          </el-col>
          <el-col :span="12">
            <div class="label">商品名称：</div>
            <div class="value">{{ detail.goodsName }}</div>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="24">
            <div class="label">商品备注：</div>
            <div class="value">{{ detail.remark }}</div>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="24">
            <div class="label">商品图片：</div>
            <div class="value">
              <upload-file-list
                :value="detail.images"
                disabled
              ></upload-file-list>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <div class="label">库位（余量）：</div>
            <div class="value">
              <div v-for="(item, index) in detail.inventories" :key="index">
                <span>{{ item.storeRoom.storeRoomName }}</span>
                <span v-if="$lodash.get(item, 'location.locationName')"
                  >-{{ item.location.locationName }}</span
                >
                <span>({{ item.inventoryNum }})</span>
              </div>
            </div>
          </el-col>
          <el-col :span="12" style="font-size: 14px">
            <span class="blue pointer" @click="handleClick">收发流水</span>
            <span
              style="margin-left: 5px"
              class="blue pointer"
              @click="handleInventoryDetailMargin"
              >批次余量</span
            >
          </el-col>
        </el-row>
      </div>
      <div class="header" style="margin-top: 16px">供应商</div>
      <el-table :data="listData" border>
        <el-table-column label="序号" type="index" width="60">
        </el-table-column>
        <el-table-column
          label="供应商简称"
          min-width="120"
          prop="supplier.supplierName"
        >
        </el-table-column>
        <el-table-column label="采购价" min-width="100">
          <template #default="{ row }">
            {{ row.lastPurchasePrice | toThousands }}
          </template>
        </el-table-column>
        <el-table-column
          label="上次采购时间"
          min-width="100"
          prop="lastPurchaseDate"
        ></el-table-column>
        <el-table-column
          label="采购次数"
          min-width="100"
          prop="purchaseTimes"
        ></el-table-column>
      </el-table>
    </div>
  </el-drawer>
</template>

<script>
import {
  admin1PagePrefix,
  admin2PagePrefix,
} from '@/config/compatibilityOlderSystems';
import uploadFileList from '@/components/uploadFileList';
import {
  goodsInventoryDetail,
  queryGoodsSupplierInfo,
} from '@/api/stock/inventory';
export default {
  name: 'inventoryDetailDrawer',
  components: { uploadFileList },
  props: {
    visibility: { type: Boolean, default: false },
    id: Number,
    companyId: Number,
  },
  data() {
    return {
      detail: {},
      listData: [],
    };
  },
  computed: {},
  created() {
    this.getData();
  },
  methods: {
    /**
     * 获取数据
     */
    getData() {
      goodsInventoryDetail({
        goodsId: this.id,
        companyId: this.companyId,
      }).then((res) => {
        this.detail = res;
      });
      queryGoodsSupplierInfo({
        goodsId: this.id,
        companyId: this.companyId,
      }).then((res) => {
        this.listData = res.records;
      });
    },

    handleClose() {
      this.$emit('update:visibility', false);
    },
    /**
     * 跳转收发流水
     */
    handleClick() {
      window.open(
        admin2PagePrefix +
          `#/stock/query/receiveRecord?recordGoodsId=${this.detail.goodsId}&keepRoute=true`,
        '_blank'
      );
    },
    /**
     * 跳转批次余量
     */
    handleInventoryDetailMargin() {
      window.open(
        admin2PagePrefix +
          `#/stock/query/inventoryDetailMargin?goodsId=${this.detail.goodsId}&keepRoute=true`,
        '_blank'
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.box {
  padding: 0 30px;
  .header {
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 16px;
  }
  .block {
    .el-row {
      // & + .el-row {
      //   margin-top: 20px;
      // }
      .el-col {
        display: flex;
      }
      .label,
      .value {
        line-height: 20px;
        font-size: 14px;
        font-weight: 400;
      }
      .label {
        width: 100px;
        height: 32px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-shrink: 0;
        color: #303133;
      }
      .value {
        min-height: 32px;
        padding-top: 6px;
        box-sizing: border-box;
        color: #606266;
      }
    }
  }
}
</style>
