var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-drawer",
    {
      attrs: {
        title: "流程详情",
        visible: _vm.visibility,
        size: "50%",
        "append-to-body": true,
        "before-close": _vm.handleClose,
        modal: "",
        "close-on-press-escape": "",
        wrapperClosable: "",
      },
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-table",
            { attrs: { data: _vm.tableList, border: "" } },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  width: "60",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { width: "85", label: "操作类型", prop: "type" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("dict")(
                                row.type,
                                "carLossDeterminationBillOperationType"
                              )
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { width: "70", label: "操作人", prop: "userName" },
              }),
              _c("el-table-column", {
                attrs: { width: "100", label: "操作时间", prop: "lastUpdated" },
              }),
              _c("el-table-column", {
                attrs: { label: "留言", prop: "message" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }