var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    {
      ref: "selEle",
      staticStyle: { width: "240px" },
      attrs: {
        multiple: "",
        clearable: "",
        filterable: "",
        "collapse-tags": "",
        "collapse-tags-tooltip": "",
        "max-collapse-tags": _vm.maxCollapseTags,
        placeholder: _vm.placeholder,
        "popper-class": "custom-header",
      },
      on: { clear: _vm.handlerSelClear, change: _vm.handleSelChange },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "el-checkbox",
                {
                  attrs: { indeterminate: _vm.indeterminate },
                  on: { change: _vm.handleCheckAll },
                  model: {
                    value: _vm.checkAll,
                    callback: function ($$v) {
                      _vm.checkAll = $$v
                    },
                    expression: "checkAll",
                  },
                },
                [_vm._v(" 全选 ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.companyIds,
        callback: function ($$v) {
          _vm.companyIds = $$v
        },
        expression: "companyIds",
      },
    },
    _vm._l(_vm.companyList, function (item) {
      return _c("el-option", {
        key: item.id,
        attrs: { label: item.name, value: item.id },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }