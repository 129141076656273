var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        top: "5vh",
        width: "950px",
        title: "选择车型",
        visible: _vm.visibility,
        "before-close": _vm.handleClose,
        "append-to-body": "",
        "close-on-click-modal": false,
      },
    },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "filter-item letter-list" }, [
          _c(
            "ul",
            _vm._l(_vm.letterList, function (letter, i) {
              return _c(
                "li",
                {
                  key: i,
                  class: { active: letter === _vm.currentLetter },
                  on: {
                    click: function ($event) {
                      return _vm.selectLetter(letter)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(letter) + " ")]
              )
            }),
            0
          ),
        ]),
        _c("div", { staticClass: "filter-item brand-list" }, [
          _c("div", { staticClass: "title" }, [_vm._v("品牌")]),
          _c(
            "ul",
            { ref: "brandList", on: { scroll: _vm.onItemListScroll } },
            _vm._l(_vm.brandList, function (brand, i) {
              return _c(
                "li",
                {
                  key: i,
                  ref: "brandItem",
                  refInFor: true,
                  class: { active: brand.id === _vm.currentBrandId },
                  attrs: { "data-letter": brand.prefixLetter },
                  on: {
                    click: function ($event) {
                      return _vm.brandChange(brand)
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: { src: brand.imagePath, alt: brand.name },
                  }),
                  _c("span", [_vm._v(_vm._s(brand.name))]),
                ]
              )
            }),
            0
          ),
        ]),
        _c("div", { staticClass: "filter-item series-list" }, [
          _c("div", { staticClass: "title" }, [_vm._v("车系")]),
          _c(
            "ul",
            _vm._l(_vm.seriesList, function (series, i) {
              return _c(
                "li",
                {
                  key: i,
                  class: { active: series.id === _vm.currentSeriesId },
                  on: {
                    click: function ($event) {
                      return _vm.seriesChange(series)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(series.name) + " ")]
              )
            }),
            0
          ),
        ]),
        _c("div", { staticClass: "filter-item model-list" }, [
          _c("div", { staticClass: "title" }, [_vm._v("型号")]),
          _c(
            "ul",
            _vm._l(_vm.modelList, function (model, i) {
              return _c(
                "li",
                {
                  key: i,
                  class: { active: model.modelNumberId === _vm.currentModelId },
                  on: {
                    click: function ($event) {
                      return _vm.modelChange(model)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(model.name) + " ")]
              )
            }),
            0
          ),
        ]),
        _c("div", { staticClass: "filter-item year-list" }, [
          _c("div", { staticClass: "title" }, [_vm._v("年份")]),
          _c(
            "ul",
            _vm._l(_vm.yearList, function (year, i) {
              return _c(
                "li",
                {
                  key: i,
                  class: { active: year.modelYearId === _vm.currentYearId },
                  on: {
                    click: function ($event) {
                      return _vm.yearChange(year)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(year.name) + " ")]
              )
            }),
            0
          ),
        ]),
      ]),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          { staticClass: "flex-x-between flex-y-center" },
          [
            _c("div", { staticClass: "tag-box" }, [
              _c("div", [_vm._v("已选：")]),
              _c(
                "div",
                { staticClass: "tag-list" },
                _vm._l(_vm.selectedList, function (tag) {
                  return _c(
                    "el-tag",
                    {
                      key: tag.seriesId,
                      staticStyle: { "margin-left": "8px" },
                      attrs: {
                        type: "info",
                        size: "small",
                        closable: _vm.multiple,
                        "disable-transitions": "",
                      },
                      on: {
                        close: function ($event) {
                          return _vm.handleTagClose(tag)
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(tag.seriesName) +
                          _vm._s(tag.modelNumberName) +
                          _vm._s(tag.modelYearName ? " " : "") +
                          _vm._s(tag.modelYearName) +
                          _vm._s(tag.modelYearName ? "款" : "") +
                          " "
                      ),
                    ]
                  )
                }),
                1
              ),
            ]),
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.confirmSelect },
              },
              [_vm._v("确 定")]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }