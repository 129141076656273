var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visibility,
        width: "50%",
        top: "1vh",
        "before-close": _vm.handleClose,
        "close-on-click-modal": false,
        "append-to-body": "",
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.save },
                },
                [_vm._v(" 确 定 ")]
              ),
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.handleClose } },
                [_vm._v(" 取 消 ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticStyle: { "margin-top": "18px" },
          attrs: {
            size: "small",
            model: _vm.editForm,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "flex",
              staticStyle: {
                "margin-bottom": "30px",
                "margin-left": "40px",
                height: "40px",
              },
            },
            [
              _c("el-button", {
                staticClass: "el-icon-refresh",
                staticStyle: { position: "absolute", right: "30px" },
                attrs: {
                  size: "small",
                  type: "primary",
                  circle: "",
                  title: "刷新认领流水",
                },
                on: { click: _vm.refreshAmountClaimData },
              }),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "跟进人：", prop: "followUpPeople" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            clearable: "",
                            remote: "",
                            placeholder: "搜索姓名、手机号",
                            "remote-method": _vm.searchAllUser,
                            loading: _vm.searchAllUserLoading,
                          },
                          on: {
                            select: function ($event) {
                              return _vm.doVerify("followUpPeople")
                            },
                          },
                          model: {
                            value: _vm.editForm.followUpPeople,
                            callback: function ($$v) {
                              _vm.$set(_vm.editForm, "followUpPeople", $$v)
                            },
                            expression: "editForm.followUpPeople",
                          },
                        },
                        _vm._l(_vm.allUserList, function (item) {
                          return _c("el-option", {
                            key: item.userId,
                            attrs: { label: item.realName, value: item.userId },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "最终定损金额：",
                        prop: "finalLossAmount",
                      },
                    },
                    [
                      _c("number-input", {
                        attrs: {
                          decimalDigits: "2",
                          max: 999999.99,
                          placeholder: "请输入最终定损金额",
                          clearable: "",
                        },
                        on: {
                          blur: function ($event) {
                            return _vm.doVerify("finalLossAmount")
                          },
                        },
                        model: {
                          value: _vm.editForm.finalLossAmount,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "finalLossAmount", $$v)
                          },
                          expression: "editForm.finalLossAmount",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { staticStyle: { "text-align": "right" } },
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "auth",
                              rawName: "v-auth",
                              value: 654,
                              expression: "654",
                            },
                          ],
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.gotoClaimDetailPage },
                        },
                        [_vm._v("去认领")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("el-form-item", { attrs: { label: "车单状态：" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("dict")(_vm.billVo.billState, "carBillState")
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("el-form-item", { attrs: { label: "单据金额:" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("toThousands")(_vm.billVo.documentAmount)
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("el-form-item", { attrs: { label: "待收金额:" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("toThousands")(_vm.unclearedDebt)) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.hasReceivingAmount
            ? _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [_c("el-form-item", { attrs: { label: "认领情况：" } })],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.hasReceivingAmount
            ? _c(
                "el-row",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { gutter: 10 },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-table",
                        {
                          attrs: {
                            "show-summary": "",
                            height: _vm.receivingAmountHeight,
                            "summary-method": _vm.getSummaries,
                            "max-height": "300",
                            width: "360",
                            data: _vm.claimTableInfoList,
                            border: "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              "min-width": "180",
                              label: "银行流水号",
                              prop: "transactionSeq",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              width: "180",
                              label: "回款认领金额",
                              prop: "claimAmount",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "回款情况：",
                        prop: "receivingAmountStatus",
                      },
                    },
                    [
                      _c("el-switch", {
                        staticClass: "mb-2",
                        attrs: {
                          "active-value": 1,
                          "inactive-value": 0,
                          "active-text": "全部回款",
                          "inactive-text": "部分回款",
                        },
                        on: { change: _vm.handleReceivingAmountStatusChange },
                        model: {
                          value: _vm.editForm.receivingAmountStatus,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "receivingAmountStatus", $$v)
                          },
                          expression: "editForm.receivingAmountStatus",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.billVo.billState !== 1 && _vm.editForm.receivingAmountStatus === 1
            ? _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "是否全部自费：",
                            prop: "isAllSelfPay",
                          },
                        },
                        [
                          _c("el-switch", {
                            staticClass: "mb-2",
                            attrs: {
                              "active-value": true,
                              "inactive-value": false,
                              "active-text": "是",
                              "inactive-text": "否",
                            },
                            model: {
                              value: _vm.editForm.isAllSelfPay,
                              callback: function ($$v) {
                                _vm.$set(_vm.editForm, "isAllSelfPay", $$v)
                              },
                              expression: "editForm.isAllSelfPay",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.partialPaymentReceived
            ? _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "多保司回款:",
                            prop: "multiInsurancePayment",
                          },
                        },
                        [
                          _c("el-switch", {
                            staticClass: "mb-2",
                            attrs: {
                              "active-value": true,
                              "inactive-value": false,
                            },
                            model: {
                              value: _vm.editForm.multiInsurancePayment,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.editForm,
                                  "multiInsurancePayment",
                                  $$v
                                )
                              },
                              expression: "editForm.multiInsurancePayment",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.partialPaymentReceived
            ? _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "挂账车队：" } }, [
                        _vm._v(
                          " " + _vm._s(_vm.infoVo.settlementClientName) + " "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "坏账：", prop: "includeBadDebts" } },
                        [
                          _c("el-switch", {
                            staticClass: "mb-2",
                            attrs: {
                              "active-value": true,
                              "inactive-value": false,
                            },
                            model: {
                              value: _vm.editForm.includeBadDebts,
                              callback: function ($$v) {
                                _vm.$set(_vm.editForm, "includeBadDebts", $$v)
                              },
                              expression: "editForm.includeBadDebts",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.partialPaymentReceived
            ? _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "挂账金额：", prop: "pendingAmount" },
                        },
                        [
                          _c("number-input", {
                            attrs: {
                              max: 999999.99,
                              decimalDigits: "2",
                              placeholder: "请输入挂账金额",
                              clearable: "",
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.doVerify("lossAssessmentAmount")
                              },
                            },
                            model: {
                              value: _vm.editForm.pendingAmount,
                              callback: function ($$v) {
                                _vm.$set(_vm.editForm, "pendingAmount", $$v)
                              },
                              expression: "editForm.pendingAmount",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "坏账金额：",
                            prop: "badDebtsAmount",
                          },
                        },
                        [
                          _c("number-input", {
                            attrs: {
                              max: 999999.99,
                              disabled: !_vm.editForm.includeBadDebts,
                              decimalDigits: "2",
                              placeholder: "请输入坏账金额",
                              clearable: "",
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.doVerify("lossAmount")
                              },
                            },
                            model: {
                              value: _vm.editForm.badDebtsAmount,
                              callback: function ($$v) {
                                _vm.$set(_vm.editForm, "badDebtsAmount", $$v)
                              },
                              expression: "editForm.badDebtsAmount",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "当次留言：", prop: "message" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          maxlength: "300",
                          "show-word-limit": "",
                          placeholder: "请输入当次留言，最多300个字符",
                        },
                        model: {
                          value: _vm.editForm.message,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "message", $$v)
                          },
                          expression: "editForm.message",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "图片", prop: "feeListPicA" } },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#909399",
                            "font-size": "12px",
                          },
                        },
                        [
                          _vm._v(
                            "支持上传 10 张图片，建议每张图片大小不超过 2 MB"
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        [
                          _c("upload-file", {
                            attrs: { title: "行驶证" },
                            model: {
                              value: _vm.editForm.runningLicenceImg,
                              callback: function ($$v) {
                                _vm.$set(_vm.editForm, "runningLicenceImg", $$v)
                              },
                              expression: "editForm.runningLicenceImg",
                            },
                          }),
                          _c("upload-file", {
                            attrs: { title: "驾驶证" },
                            model: {
                              value: _vm.editForm.picDrivingLicence,
                              callback: function ($$v) {
                                _vm.$set(_vm.editForm, "picDrivingLicence", $$v)
                              },
                              expression: "editForm.picDrivingLicence",
                            },
                          }),
                          _c("upload-file", {
                            attrs: {
                              title: "定损单1",
                              requiredIcon: _vm.type >= 8,
                            },
                            model: {
                              value: _vm.editForm.feeListPicA,
                              callback: function ($$v) {
                                _vm.$set(_vm.editForm, "feeListPicA", $$v)
                              },
                              expression: "editForm.feeListPicA",
                            },
                          }),
                          _c("upload-file", {
                            attrs: { title: "定损单2" },
                            model: {
                              value: _vm.editForm.feeListPicB,
                              callback: function ($$v) {
                                _vm.$set(_vm.editForm, "feeListPicB", $$v)
                              },
                              expression: "editForm.feeListPicB",
                            },
                          }),
                          _c("upload-file", {
                            attrs: { title: "定损单3" },
                            model: {
                              value: _vm.editForm.feeListPicC,
                              callback: function ($$v) {
                                _vm.$set(_vm.editForm, "feeListPicC", $$v)
                              },
                              expression: "editForm.feeListPicC",
                            },
                          }),
                          _c("upload-file-list", {
                            attrs: { max: 5 },
                            model: {
                              value: _vm.editForm.feeListPicOthersList,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.editForm,
                                  "feeListPicOthersList",
                                  $$v
                                )
                              },
                              expression: "editForm.feeListPicOthersList",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "附件", prop: "attachment" } },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#909399",
                            "font-size": "12px",
                          },
                        },
                        [_vm._v("只支持上传zip文件，最多5个")]
                      ),
                      _c("div", { staticClass: "flex" }, [
                        _c(
                          "ul",
                          { staticStyle: { "max-width": "300px" } },
                          _vm._l(_vm.attachmentList, function (item, i) {
                            return _c("li", { key: i }, [
                              _c("i", {
                                staticClass:
                                  "iconfont grow-icon_delete3 orange el-icon--left pointer",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete(i)
                                  },
                                },
                              }),
                              _c("span", [_vm._v(_vm._s(item.name))]),
                            ])
                          }),
                          0
                        ),
                        _vm.attachmentList.length < 5
                          ? _c(
                              "div",
                              { staticStyle: { "margin-left": "20px" } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "small" },
                                    on: { click: _vm.chooseFile },
                                  },
                                  [_vm._v("选择文件")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }