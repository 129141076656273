/**
 * 获取两个日期之前的月份数
 * @param {*} start
 * @param {*} end
 * @return ['yyyy-MM']
 */
export function getApartMonths(start, end) {
  var result = [];
  var newResult = [];
  var s = new Date(start);
  var e = new Date(end);
  var staYear = parseInt(s.getFullYear());
  var staMon = parseInt(s.getMonth() + 1);
  var endYear = parseInt(e.getFullYear());
  var endMon = parseInt(e.getMonth() + 1);
  result.push({ year: staYear, month: staMon });
  while (staYear <= endYear) {
    if (staYear === endYear) {
      while (staMon < endMon) {
        staMon++;
        result.push({ year: staYear, month: staMon });
      }
      staYear++;
    } else {
      staMon++;
      if (staMon > 12) {
        staMon = 1;
        staYear++;
      }
      result.push({ year: staYear, month: staMon });
    }
  }

  for (var i = 0; i < result.length; i++) {
    var year = result[i].year;
    var monthInit = result[i].month;
    var month = monthInit;
    //补'0'操作
    if (monthInit < 10) {
      month = '0' + monthInit;
    } else {
      month = monthInit + '';
    }
    var ym = year + '-' + month;
    newResult.push(ym);
  }
  return newResult;
}

/**
 * 将毫秒数转换成天时分秒
 * @param {number} timestamp 毫秒数
 * @param {boolean} needZero 是否需要保留0
 */
export function getDHMS(timestamp, needZero) {
  timestamp = +timestamp / 1000;
  let d = parseInt(timestamp / 60 / 60 / 24);
  // 转换为式分秒
  let h = parseInt((timestamp / 60 / 60) % 24);
  if (needZero) h = h < 10 ? '0' + h : h;
  let m = parseInt((timestamp / 60) % 60);
  if (needZero) m = m < 10 ? '0' + m : m;
  let s = parseInt(timestamp % 60);
  if (needZero) s = s < 10 ? '0' + s : s;
  // 作为返回值返回
  return [d, h, m, s];
}
