<template>
  <div class="upload-file-box" v-if="!disabled || imageUrl">
    <!--
      :headers="headers"
      :data="formData"
      :on-success="handleSuccess"
      -->
    <el-upload
      class="avatar-uploader"
      action="/oss/admin/oss/uploadImages"
      :accept="fileType"
      :show-file-list="false"
      :http-request="customUpload"
      :before-upload="beforeUpload"
      :disabled="disabled"
      :requiredIcon="requiredIcon"
    >
      <img
        v-if="imageUrl"
        :src="imageUrl"
        @click.stop="previewImg"
        class="avatar"
      />
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      <i
        v-if="imageUrl && !disabled"
        @click.stop="deleteImg"
        class="delete-icon pointer iconfont grow-a-icon_tabbar_close_unselected"
      ></i>
    </el-upload>
    <p class="flex">
      <span v-show="requiredIcon" style="color: #e84c4c; margin: 8px 3px 0 0"
        >*</span
      >
      <span class="img-title">{{ title }}</span>
    </p>
  </div>
</template>

<script>
import { previewImage } from '@/utils/fileApi';
import { uploadImage } from '@/utils/fileApi';

export default {
  props: {
    // 文件链接
    value: String,
    // 文件标题
    title: String,
    // 文件类型限制
    fileType: {
      type: String,
      default: 'image/*',
    },
    // 文件大小限制
    maxSize: Number,
    // 禁用
    disabled: Boolean,
    // 额外参数
    uploadType: {
      type: String,
      default: '1',
    },
    // 在upload file list 组件中的下标
    index: Number,
    // 父组件自定义删除方法
    customDelImg: {
      type: Function,
    },
    // 父组件传递的预览图片数组
    imagesForPreview: {
      type: Array,
      default: () => [],
    },
    //必填图标
    requiredIcon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imageUrl: '',
      imageInfo: {},
      headers: {
        // 'Content-Type': 'multipart/form-data',
        GrowxnyAuth: window.sessionStorage.getItem('token'),
      },
      formData: { uploadType: this.uploadType },
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.imageUrl = val || '';
      },
    },
  },
  methods: {
    // 上传方法
    async customUpload(params) {
      console.log(params);
      let res = await uploadImage(params.file);
      this.imageUrl = res.imageUrl;
      this.imageInfo = res;
      this.$emit('input', this.imageUrl);
      this.$emit('upload', this.index, this.imageUrl);
    },
    // 上传成功钩子
    // handleSuccess(res, file) {
    //   console.log(res);
    //   this.imageUrl = res.data.imageUrl;
    //   this.$emit('input', this.imageUrl);
    // },
    // 上传前钩子
    beforeUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      const oversize = !this.maxSize || file.size / 1024 / 1024 < this.maxSize;

      // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!oversize) {
        this.$message.error(`上传图片大小不能超过 ${this.maxSize}MB!`);
      }
      return oversize;
    },
    // 预览图片
    previewImg() {
      if (this.imagesForPreview?.length) {
        let index = this.imagesForPreview.findIndex(
          (img) => img === this.value
        );
        previewImage(this.imagesForPreview, index);
      } else {
        previewImage({
          images: [this.imageUrl],
          options: {
            title: (image, imageData) => {
              return this.title;
            },
          },
        });
      }
    },
    // 删除图片
    deleteImg() {
      if (this.customDelImg) {
        this.customDelImg(this.index);
      } else {
        this.handleDelImg();
      }
    },
    // 实际处理方法
    handleDelImg() {
      this.imageUrl = '';
      this.$emit('input', this.imageUrl);
    },
  },
};
</script>

<style lang="scss">
.upload-file-box {
  display: inline-flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  line-height: normal;
  margin-left: 10px;
}
.avatar-uploader {
  width: 60px;
  height: 60px;
  box-sizing: border-box;
  line-height: normal;
  .el-upload {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px dashed #d9d9d9;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    line-height: normal;
  }
}

.avatar-uploader .el-upload:hover {
  border-color: $blue;
}
i.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
}
.avatar {
  width: 60px;
  height: 60px;
  display: block;
  object-fit: cover;
}
.delete-icon {
  position: absolute;
  top: 0;
  right: 0;
  line-height: 14px;
  color: #a1e2c7;
  &:hover {
    color: $primary;
  }
}
.img-title {
  padding-top: 5px;
  text-align: center;
}
</style>
