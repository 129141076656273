<template>
  <div>
    <el-button type="primary" @click="chooseExcel">选择excel</el-button>
    <p style="margin: 10px 0">已选择：{{ file.name }}</p>
    <el-table
      v-if="excelData.length"
      :data="excelData"
      border
      :row-class-name="tableRowClassName"
      max-height="500"
    >
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column
        v-for="(k, i) in tableHeader"
        :key="i"
        :prop="k"
        :label="k"
      >
      </el-table-column>
    </el-table>

    <el-divider></el-divider>

    <el-button type="primary" @click="chooseWord">选择word</el-button>

    <el-table
      id="wordTable"
      v-if="wordList.length"
      :data="wordList"
      border
      :row-class-name="wordRowClassName"
      max-height="500"
    >
      <el-table-column prop="name" label="文件名"> </el-table-column>
      <el-table-column prop="状态" label="转换状态">
        <template #default="{ row }">
          <el-tag v-if="row.状态 === '成功'" type="success">
            {{ row.状态 }}
          </el-tag>
          <el-tag v-else-if="row.状态 === '失败'" type="danger">
            {{ row.状态 }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="导入状态" label="导入状态"> </el-table-column>
    </el-table>

    <el-divider></el-divider>
    <div style="margin-bottom: 16px">
      导入后状态：
      <el-select v-model="state">
        <el-option label="草稿" :value="0"> </el-option>
        <el-option label="待审核" :value="1"> </el-option>
        <el-option label="不通过" :value="2"> </el-option>
        <el-option label="上架" :value="3"> </el-option>
        <el-option label="强制下架" :value="4"> </el-option>
      </el-select>
    </div>

    <el-button type="danger" @click="doConvert" v-loading="convertLoading"
      >转换</el-button
    >
    <el-button type="danger" @click="doUpload" v-loading="uploadLoading"
      >导入</el-button
    >
    <el-button type="danger" @click="exportSuccessResult"
      >导入结果导出</el-button
    >
  </div>
</template>

<script>
import { read, utils, writeFile } from 'xlsx';
import { chooseFile, base64ToFile, uploadImage } from '@/utils/fileApi';
import mammoth from 'mammoth/mammoth.browser.min.js';

// 接口
import {
  getMaterialInfo,
  addAndEditMaterial,
  getWzGuideSystemList,
  newSearchCarModel,
} from '@/api/technologyCenter';
export default {
  data() {
    return {
      file: {},

      excelData: [],

      wordList: [],

      convertLoading: false,

      uploadLoading: false,

      state: 3,
    };
  },
  computed: {
    // 生成表头
    tableHeader() {
      let header = [];
      if (this.excelData[0]) {
        for (let key in this.excelData[0]) {
          header.push(key);
        }
      }
      return header;
    },
  },
  created() {
    // this.loadScript(
    //   'https://cdnjs.cloudflare.com/ajax/libs/mammoth/1.4.12/mammoth.browser.min.js'
    // );
  },
  methods: {
    /**
     * 加载script
     * @param {string} url
     */
    // loadScript(url) {
    //   return new Promise((resolve, reject) => {
    //     var script = document.createElement('script');
    //     script.type = 'text/javascript';
    //     if (script.readyState) {
    //       script.onreadystatechange = function () {
    //         if (
    //           script.readyState == 'loaded' ||
    //           script.readyState == 'complete'
    //         ) {
    //           script.onreadystatechange = null;
    //           resolve();
    //         }
    //       };
    //     } else {
    //       script.onload = function () {
    //         resolve();
    //       };
    //     }
    //     script.src = url;
    //     document.body.appendChild(script);
    //   });
    // },
    chooseExcel() {
      chooseFile(
        'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ).then(async (file) => {
        this.file = file[0];
        console.log(file);
        const data = await this.file2ArrayBuffer(this.file);
        this.readFile(data);
      });
    },
    file2ArrayBuffer(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          resolve(data);
        };
        reader.onerror = (e) => {
          reject(e);
        };
      });
    },
    readFile(arrayBuffer) {
      const wb = read(arrayBuffer, { type: 'array' });
      console.log(wb);
      const data = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
      data.forEach((e, i) => {
        e.状态 = '';
        e.供稿时间 = this.convertExcelDate(e.供稿时间);
      });
      console.log(data);
      this.excelData = data;
    },
    convertExcelDate(excelDate) {
      const millisecondsPerDay = 24 * 60 * 60 * 1000; // 一天的毫秒数
      const offsetDays = excelDate - 1; // Excel 中的日期是从 1900 年 1 月 1 日开始的，因此需要减去一天

      const date = new Date(
        new Date(1900, 0, 1).getTime() + offsetDays * millisecondsPerDay
      );

      return date.toISOString().substring(0, 10); // 返回日期的字符串表示形式，例如 "2018-10-16"
    },
    chooseWord() {
      chooseFile({ accept: '.docx', multiple: true }).then(async (file) => {
        this.wordList = file.map((item) => ({ name: item.name, file: item }));
      });
    },

    async doConvert() {
      if (!this.wordList.length) {
        this.$message.error('先选择word文件');
        return;
      }

      function* generator(list) {
        for (let i = 0; i < list.length; i++) {
          const el = list[i];
          yield this.word2RichText(el.file, i);
        }
      }

      const gen = generator.call(this, this.wordList);

      this.convertLoading = true;
      for await (let res of gen) {
        this.$set(this.wordList[res.i], '状态', res.status);
        this.$set(this.wordList[res.i], 'html', res.value);
        this.$message[res.type](res.msg);
      }
      this.convertLoading = false;
    },
    async word2RichText(file, i) {
      var arrayBuffer = await this.file2ArrayBuffer(file);

      return new Promise((resolve, reject) => {
        var title = `docx转换html: ${file.name}`;
        console.time(title);

        var options = {
          includeDefaultStyleMap: false,
          convertImage: mammoth.images.imgElement((image) => {
            return image.read('base64').then(async (imageBuffer) => {
              let base64 =
                'data:' + image.contentType + ';base64,' + imageBuffer;

              let file = base64ToFile(base64);
              let res = await uploadImage(file, 1, { noShowWaiting: true });
              // console.log(res);
              return {
                src: res.imageUrl,
              };
            });
          }),
        };
        // Convert docx to HTML using Mammoth.js
        mammoth
          .convertToHtml({ arrayBuffer: arrayBuffer }, options)
          .then((result) => {
            var html = result.value;

            // 去除开头标题投稿人信息
            const regex = /(<[^/>]+>)*一、车辆基本信息/;
            const match = regex.exec(html);
            if (match) {
              const position = match.index;
              html = html.substring(position);
            }

            resolve({
              value: html,
              i,
              status: '成功',
              msg: '转换成功',
              type: 'success',
            });
            console.timeEnd(title);
          })
          .catch((e) => {
            resolve({
              value: e,
              i,
              status: '失败',
              msg: '转换失败',
              type: 'error',
            });
          })
          .done();
      });
    },

    tableRowClassName({ row, rowIndex }) {
      switch (row.状态) {
        case '未处理':
          return 'warning-row';
        case '成功':
          return 'success-row';
        case '失败':
          return 'error-row';
        default:
          return '';
      }
    },
    wordRowClassName({ row, rowIndex }) {
      switch (row.导入状态) {
        case '未处理':
          return 'warning-row';
        case '成功':
          return 'success-row';
        case '失败':
          return 'error-row';
        default:
          return '';
      }
    },
    async doUpload() {
      this.uploadLoading = true;
      function* generator(list) {
        for (let i = 0; i < list.length; i++) {
          const el = list[i];
          yield this.mergeParamsAndUpload(el, i);
        }
      }

      const gen = generator.call(this, this.wordList);

      for await (let res of gen) {
        this.$set(this.wordList[res.i], '导入状态', res.status);
        this.$set(this.wordList[res.i], 'errorMsg', res.msg);
        this.$message[res.type](res.msg);
      }
      this.uploadLoading = false;
    },
    mergeParamsAndUpload(file, i) {
      return new Promise((resolve, reject) => {
        // 查找对应的excel记录
        let record = this.excelData.find(
          (el) => el.案例标题 === file.name.split('.')[0]
        );
        if (record) {
          // const regex =
          //   /(<img[^>]*src="([^"]*)"[^>]*>)|(<video[^>]*src="([^"]*)"[^>]*>)/gi;
          // let match;
          // var srcList = [];
          // while ((match = regex.exec(this.html)) !== null) {
          //   const src = match[2] || match[4];
          //   srcList.push(src);
          // }

          const regex =
            /<img[^>]*src="([^"]*)"[^>]*>|<video[^>]*>\s*<source[^>]*src="([^"]*)"[^>]*>\s*<\/video>/gi;

          const srcList = Array.from(
            file.html.matchAll(regex),
            (m) => m[1] || m[2]
          );

          var params = {
            state: this.state,
            title: record.案例标题,
            modelDetailLogoId: record.modelDetailLogoId,
            modelDetailBrandId: record.modelDetailBrandId,
            modelDetailSeriesId: record.modelDetailSeriesId,
            guideSysId: record.guideSysId,
            companyId: record.companyId,
            creatorId: record.creatorId,
            html: file.html,
            picVideo: srcList.join(','),
            contributeTime: record.供稿时间 + ' 00:00:00',
            verifierId: 147238,
            verifierCompanyId: 14890,
          };
          console.log(params);
          addAndEditMaterial(params, {
            noShowWaiting: true,
            noHandleError: true,
          })
            .then((res) => {
              if (res.data.code == 200) {
                setTimeout(() => {
                  resolve({
                    i,
                    status: '成功',
                    msg: '上传成功',
                    type: 'success',
                  });
                }, 3000);
              } else {
                resolve({
                  i,
                  status: '失败',
                  msg: '上传失败',
                  type: 'error',
                });
              }
            })
            .catch((err) => {
              resolve({
                i,
                status: '失败',
                msg: '上传失败',
                type: 'error',
              });
            });
          return;
        } else {
          resolve({
            i,
            status: '失败',
            msg: '未找到对应的excel记录',
            type: 'error',
          });
        }
      });
    },
    exportSuccessResult() {
      // var list = this.wordList.filter((el) => el.导入状态 === '成功');
      const table = document.getElementById('wordTable');
      const wb = utils.table_to_book(table);

      /* Export to file (start a download) */
      writeFile(wb, '导入结果.xlsx');
    },
  },
};
</script>
<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #a7f17e;
}

.el-table .error-row {
  background: rgb(237 79 79 / 78%);
}
</style>
