<template>
  <div class="wrapper">
    <div class="table-wrap">
      <el-tabs v-model="tabIndex" @tab-click="tabChange">
        <el-tab-pane label="索赔应收汇总" name="0" />
        <el-tab-pane label="索赔应收配置" name="1" />
      </el-tabs>
      <div v-if="tabIndex == 0" class="export-btn-wrap" ref="exportRef">
        <el-button v-auth="798" class="export-btn" type="primary" @click="showExportDialog = true">导出</el-button>
      </div>
      <el-table v-if="tabIndex == 0" :data="tableData" :height="tableHei" :header-cell-style="{ height: '32px', padding: '0!important' }" class="new-table">
        <el-table-column align="center" fixed label="品牌" prop="brandName" show-overflow-tooltip width="220" />
        <el-table-column align="center" label="1个月以内">
          <el-table-column align="center" label="单量" prop="withinOneMonthBillCount" width="100" />
          <el-table-column align="center" label="金额" prop="withinOneMonthBillAmount" width="100" />
        </el-table-column>
        <el-table-column align="center" label="1-3个月">
          <el-table-column align="center" label="单量" prop="withinRangeOneToThreeMonthBillCount" width="100" />
          <el-table-column align="center" label="金额" prop="withinRangeOneToThreeMonthBillAmount" width="100" />
        </el-table-column>
        <el-table-column align="center" label="3-6个月">
          <el-table-column align="center" label="单量" prop="withinRangeThreeToSixMonthBillCount" width="100" />
          <el-table-column align="center" label="金额" prop="withinRangeThreeToSixMonthBillAmount" width="100" />
        </el-table-column>
        <el-table-column align="center" label="6-12个月">
          <el-table-column align="center" label="单量" prop="withinRangeSixToTwelveMonthBillCount" width="100" />
          <el-table-column align="center" label="金额" prop="withinRangeSixToTwelveMonthBillAmount" width="100" />
        </el-table-column>
        <el-table-column align="center" label="1年以上">
          <el-table-column align="center" label="单量" prop="moreThanOneYearBillCount" width="100" />
          <el-table-column align="center" label="金额" prop="moreThanOneYearBillAmount" width="100" />
        </el-table-column>
        <el-table-column align="center" label="小计">
          <el-table-column align="center" label="单量" prop="subTotalBillCount" width="100" />
          <el-table-column align="center" label="金额" prop="subTotalBillAmount" width="100" />
        </el-table-column>
        <el-table-column align="center" label="占比">
          <el-table-column align="center" label="单量" prop="subTotalBillCountRate" width="100" />
          <el-table-column align="center" label="金额" prop="subTotalBillAmountRate" width="100" />
        </el-table-column>
      </el-table>
      <div v-if="tabIndex == 1" class="settings-wrap">
        <el-form ref="formRef" :inline="true" :model="searchForm" class="demo-form-inline">
          <el-form-item label="" label-width="70px" prop="BusinessSource">
            <el-input
              v-model="searchForm.BusinessSource"
              clearable
              placeholder="请输入业务来源"
              @clear="onSubmit"
              @keyup.enter.native="onSubmit" />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="onSubmit">查询</el-button>
          </el-form-item>
        </el-form>
        <el-table :data="tableData" :height="tableHei" class="new-table">
          <el-table-column align="center" label="ID" prop="billSourceTypeId" />
          <el-table-column align="center" label="业务来源" prop="billSourceTypeName" />
          <el-table-column align="center" label="是否显示" prop="isStatShow">
            <template #header>
              <div class="col-head">
                <span class="col-text">*</span>
                是否显示
              </div>
            </template>
            <template #default="{ row }">
              <el-switch v-model="row.isStatShow" :disabled="row.switchShow" />
            </template>
          </el-table-column>
          <el-table-column align="center" label="显示名称（品牌）" prop="showBrandName">
            <template #header>
              <div class="col-head">
                <span class="col-text">*</span>
                显示名称（品牌）
              </div>
            </template>
            <template #default="{ row }">
              <span v-if="row.switchShow">{{ row.showBrandName }}</span>
              <el-input v-else v-model="row.showBrandName" clearable placeholder="请输入" />
            </template>
          </el-table-column>
          <el-table-column align="center" v-auth="810" label="操作" width="100">
            <template #default="{ row }">
              <div class="operate-center">
                <el-link :underline="false" class="table-text" type="primary" @click="editData(row, row.switchShow ? 0 : 1)">
                  {{ row.switchShow ? "编辑" : "保存" }}
                </el-link>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-wrap">
          <div class="total-text">共{{ total }}条</div>
          <el-pagination
            :current-page="currentPage"
            :page-size="pageSize"
            :page-sizes="[20, 30, 50, 100]"
            :total="total"
            layout="->, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange" />
        </div>
      </div>
    </div>

    <export-dialog
      :filterForm="form"
      :pageOption="pageOption"
      :setWid="'30%'"
      :total="total"
      :url="exportUrl"
      :visibility.sync="showExportDialog" />
  </div>
</template>

<script>
import { getClaimsSummaryExportUrl, getClaimsSummaryList, getClaimsSummarySettingEdit, getClaimsSummarySettingList } from "@/api/dataBoard";
import ExportDialog from "@/components/ExportDialog.vue";

export default {
  name: "ClaimsSummary",
  components: { ExportDialog },
  data() {
    return {
      tableHei: "50vh",
      tableData: [],
      tabIndex: 0,
      searchForm: {},
      showExportDialog: false,
      total: 0,
      pageOption: {
        size: 15,
        current: 1,
      },
      form: {},
      exportUrl: getClaimsSummaryExportUrl,
      pageSize: 20,
      currentPage: 1,
    };
  },
  created() {
    this.getListData();
  },
  mounted() {
    this.setTableHei(200);
  },
  methods: {
    /** 动态控制表格高度 **/
    setTableHei(num){
      this.$nextTick(()=>{
        let windowsHei = document.documentElement.clientHeight;
        let exportHei = this.$refs.exportRef.offsetHeight;
        this.tableHei = windowsHei - exportHei - num
      })
    },
    /** tab点击 **/
    tabChange(e) {
      console.log(e);
      console.log("tabIndex--->>", this.tabIndex);
      if (this.tabIndex == "0") {
        this.setTableHei(200);
        this.getListData();
      } else {
        let windowsHei = document.documentElement.clientHeight;
        this.tableHei = windowsHei - 280
        this.pageSize = 20
        this.currentPage = 1
        this.getSettingList();
      }
    },
    /** 获取列表数据 **/
    getListData() {
      getClaimsSummaryList()
        .then(res => {
          console.log("res---->>>", res);
          this.tableData = [];
          res.statItems?.forEach(item => {
            item.subTotalBillCountRate += "%";
            item.subTotalBillAmountRate += "%";
            this.tableData.push(item);
          });
          this.tableData.push(res.sumStatItem);
          Object.keys(res.rateStatItem).forEach(key => {
            if (key != "brandName") {
              res.rateStatItem[key] += "%";
            }
          });
          this.tableData.push(res.rateStatItem);
        })
    },
    /** 获取配置列表数据 **/
    getSettingList() {
      let obj = {
        sourceTypeName: this.searchForm.BusinessSource,
        current: this.currentPage,
        size: this.pageSize,
      };
      this.tableData = [];
      getClaimsSummarySettingList(obj)
        .then(res => {
          console.log("res---->>>", res);
          res.records?.forEach(item => {
            item.switchShow = true;
          });
          this.tableData = res.records;
          this.total = Number(res.total);
        })
    },
    /** 编辑 **/
    editData(row, type) {
      console.log("row--->>", row);
      switch (type) {
        case 0:
          row.switchShow = false;
          break;
        case 1:
          let obj = {
            id: row.id,
            billSourceTypeId: row.billSourceTypeId,
            isStatShow: row.isStatShow,
            brandName: row.showBrandName,
          };
          getClaimsSummarySettingEdit(obj).then(res => {
            row.switchShow = true;
            this.$message.success("操作成功！");
            this.getSettingList()
          })

          break;
      }
    },
    /** 搜索 **/
    onSubmit() {
      this.getSettingList();
    },
    /** 切换一页显示多少数据 **/
    handleSizeChange(newSize) {
      this.pageSize = newSize;
      this.pageOption.size = newSize;
      this.getSettingList();
    },
    /** 切换页数 **/
    handleCurrentChange(newPage) {
      this.currentPage = newPage;
      this.getSettingList();
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  .table-wrap {
    padding: 14px;

    .export-btn-wrap {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 12px;
    }

    .col-head {
      display: flex;
      align-items: center;
      justify-content: center;

      .col-text {
        color: red;
      }
    }
  }
  .pagination-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;

    .total-text {
      font-size: 14px;
      color: #444;
    }
  }
}
</style>
