<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <el-form
        class="filter-form"
        :model="form"
        label-width="100px"
        size="small"
      >
        <div class="flex-x-between">
          <div class="flex-warp flex-shrink0">
            <el-form-item>
              <template slot="label">
                <div>门店清单</div>
              </template>
              <el-select
                v-model="form.companyId"
                @change="selectCompany"
                filterable
                clearable
                remote
                placeholder="搜索车店名称、编号"
                :remote-method="searchControlCompany"
                :loading="searchControlCompanyLoading"
              >
                <el-option
                  v-for="item in companyList"
                  :key="item.companyId"
                  :label="item.companyName"
                  :value="item.companyId"
                >
                  <span style="float: left; font-size: 13px">{{
                    item.companyName
                  }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="统计周期">
              <el-date-picker
                v-model="form.statisticsPeriod"
                @change="changeMonth"
                type="month"
                placeholder="选择月"
                value-format="yyyy-MM"
              >
              </el-date-picker>
            </el-form-item>
          </div>
          <div class="flex">
            <div class="el-form-item--small">
              <el-button
                class="btn_search"
                type="primary"
                size="small"
                @click="getData"
                >查询</el-button
              >
            </div>
          </div>
        </div>
      </el-form>
    </template>
    <template>
      <div>
        <el-button
          size="small"
          type="primary"
          v-auth="574"
          @click="openCostEvent(0, 0)"
          >设置成本</el-button
        >
      </div>
      <div class="table-box">
        <table class="border-table" style="margin-top: 20px">
          <tr>
            <th rowspan="2" colspan="2" style="width: 15%">类型</th>
            <th rowspan="2" style="width: 15%">合计</th>
            <th colspan="4">业务类型</th>
          </tr>
          <tr>
            <th style="width: 10%">自费</th>
            <th style="width: 10%">
              包干
              <el-tooltip
                effect="light"
                content="包干金额为分摊后实际产值金额，非单据金额"
                placement="bottom-start"
              >
                <i class="iconfont grow-icon_doubt" style="color: #9a9a9a"></i>
              </el-tooltip>
            </th>
            <th style="width: 10%">理赔</th>
            <th style="width: 10%">索赔</th>
          </tr>
          <tr>
            <td colspan="2" class="columnName">营业额</td>
            <td>
              {{ tabularData.turnover }}
            </td>
            <td>
              {{ tabularData.selfPayingTurnover }}
            </td>
            <td>
              {{ tabularData.contractForTurnover }}
            </td>
            <td>
              {{ tabularData.claimSettlementTurnover }}
            </td>
            <td>
              {{ tabularData.claimForCompensationTurnover }}
            </td>
          </tr>
          <tr>
            <td
              rowspan="7"
              width="90"
              class="columnName"
              style="vertical-align: middle"
            >
              车单成本
            </td>
            <td class="columnName">材料成本</td>
            <td>
              {{ tabularData.material }}
            </td>
            <td>
              {{ tabularData.selfPayingMaterial }}
            </td>
            <td>
              {{ tabularData.contractForMaterial }}
            </td>
            <td>
              {{ tabularData.claimSettlementMaterial }}
            </td>
            <td>
              {{ tabularData.claimForCompensationMaterial }}
            </td>
          </tr>
          <tr>
            <td class="columnName">合同折扣</td>
            <td>
              {{ tabularData.contract }}
            </td>
            <td>
              <div class="pointer flex seen">
                {{ tabularData.selfPayingContract }}
                <i
                  style="font-size: 14px"
                  class="iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show"
                  v-auth="574"
                  @click="openCostEvent(0, 0)"
                ></i>
              </div>
            </td>
            <td>
              <div class="pointer flex seen">
                {{ tabularData.contractForContract }}
                <i
                  style="font-size: 14px"
                  class="iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show"
                  v-auth="574"
                  @click="openCostEvent(1, 0)"
                ></i>
              </div>
            </td>
            <td>
              <div class="pointer flex seen">
                {{ tabularData.claimSettlementContract }}
                <i
                  style="font-size: 14px"
                  class="iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show"
                  v-auth="574"
                  @click="openCostEvent(2, 0)"
                ></i>
              </div>
            </td>
            <td>
              <div class="pointer flex seen">
                {{ tabularData.claimForCompensationContract }}
                <i
                  style="font-size: 14px"
                  class="iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show"
                  v-auth="574"
                  @click="openCostEvent(3, 0)"
                ></i>
              </div>
            </td>
          </tr>
          <tr>
            <td class="columnName">财务折扣</td>
            <td>
              {{ tabularData.finance }}
            </td>
            <td>
              <div class="pointer flex seen">
                {{ tabularData.selfPayingFinance }}
                <i
                  style="font-size: 14px"
                  class="iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show"
                  v-auth="574"
                  @click="openCostEvent(0, 1)"
                ></i>
              </div>
            </td>
            <td>
              <div class="pointer flex seen">
                {{ tabularData.contractForFinance }}
                <i
                  style="font-size: 14px"
                  class="iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show"
                  v-auth="574"
                  @click="openCostEvent(1, 1)"
                ></i>
              </div>
            </td>
            <td>
              <div class="pointer flex seen">
                {{ tabularData.claimSettlementFinance }}
                <i
                  style="font-size: 14px"
                  class="iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show"
                  v-auth="574"
                  @click="openCostEvent(2, 1)"
                ></i>
              </div>
            </td>
            <td>
              <div class="pointer flex seen">
                {{ tabularData.claimForCompensationFinance }}
                <i
                  style="font-size: 14px"
                  class="iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show"
                  v-auth="574"
                  @click="openCostEvent(3, 1)"
                ></i>
              </div>
            </td>
          </tr>
          <tr>
            <td class="columnName">坏账</td>
            <td>
              {{ tabularData.badDebts }}
            </td>
            <td>
              <div class="pointer flex seen">
                {{ tabularData.selfPayingBadDebts }}
                <i
                  style="font-size: 14px"
                  class="iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show"
                  v-auth="574"
                  @click="openCostEvent(0, 2)"
                ></i>
              </div>
            </td>
            <td>
              <div class="pointer flex seen">
                {{ tabularData.contractForBadDebts }}
                <i
                  style="font-size: 14px"
                  class="iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show"
                  v-auth="574"
                  @click="openCostEvent(1, 2)"
                ></i>
              </div>
            </td>
            <td>
              <div class="pointer flex seen">
                {{ tabularData.claimSettlementBadDebts }}
                <i
                  style="font-size: 14px"
                  class="iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show"
                  v-auth="574"
                  @click="openCostEvent(2, 2)"
                ></i>
              </div>
            </td>
            <td>
              <div class="pointer flex seen">
                {{ tabularData.claimForCompensationBadDebts }}
                <i
                  style="font-size: 14px"
                  class="iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show"
                  v-auth="574"
                  @click="openCostEvent(3, 2)"
                ></i>
              </div>
            </td>
          </tr>
          <tr>
            <td class="columnName">保费成本</td>
            <td>
              {{ tabularData.premium }}
            </td>
            <td>
              <div class="pointer flex seen">
                {{ tabularData.selfPayingPremium }}
                <i
                  style="font-size: 14px"
                  class="iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show"
                  v-auth="574"
                  @click="openCostEvent(0, 3)"
                ></i>
              </div>
            </td>
            <td>
              <div class="pointer flex seen">
                {{ tabularData.contractForPremium }}
                <i
                  style="font-size: 14px"
                  class="iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show"
                  v-auth="574"
                  @click="openCostEvent(1, 3)"
                ></i>
              </div>
            </td>
            <td>
              <div class="pointer flex seen">
                {{ tabularData.claimSettlementPremium }}
                <i
                  style="font-size: 14px"
                  class="iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show"
                  v-auth="574"
                  @click="openCostEvent(2, 3)"
                ></i>
              </div>
            </td>
            <td>
              <div class="pointer flex seen">
                {{ tabularData.claimForCompensationPremium }}
                <i
                  style="font-size: 14px"
                  class="iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show"
                  v-auth="574"
                  @click="openCostEvent(3, 3)"
                ></i>
              </div>
            </td>
          </tr>
          <tr>
            <td class="columnName">代定损费用</td>
            <td>
              {{ tabularData.lossDetermined }}
            </td>
            <td>
              <div class="pointer flex seen">
                {{ tabularData.selfPayingLossDetermined }}
                <i
                  style="font-size: 14px"
                  class="iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show"
                  v-auth="574"
                  @click="openCostEvent(0, 4)"
                ></i>
              </div>
            </td>
            <td>
              <div class="pointer flex seen">
                {{ tabularData.contractForLossDetermined }}
                <i
                  style="font-size: 14px"
                  class="iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show"
                  v-auth="574"
                  @click="openCostEvent(1, 4)"
                ></i>
              </div>
            </td>
            <td>
              <div class="pointer flex seen">
                {{ tabularData.claimSettlementLossDetermined }}
                <i
                  style="font-size: 14px"
                  class="iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show"
                  v-auth="574"
                  @click="openCostEvent(2, 4)"
                ></i>
              </div>
            </td>
            <td>
              <div class="pointer flex seen">
                {{ tabularData.claimForCompensationLossDetermined }}
                <i
                  style="font-size: 14px"
                  class="iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show"
                  v-auth="574"
                  @click="openCostEvent(3, 4)"
                ></i>
              </div>
            </td>
          </tr>
          <tr>
            <td class="columnName">业务人员费用</td>
            <td>
              {{ tabularData.salesman }}
            </td>
            <td>
              <div class="pointer flex seen">
                {{ tabularData.selfPayingSalesman }}
                <i
                  style="font-size: 14px"
                  class="iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show"
                  v-auth="574"
                  @click="openCostEvent(0, 5)"
                ></i>
              </div>
            </td>
            <td>
              <div class="pointer flex seen">
                {{ tabularData.contractForSalesman }}
                <i
                  style="font-size: 14px"
                  class="iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show"
                  v-auth="574"
                  @click="openCostEvent(1, 5)"
                ></i>
              </div>
            </td>
            <td>
              <div class="pointer flex seen">
                {{ tabularData.claimSettlementSalesman }}
                <i
                  style="font-size: 14px"
                  class="iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show"
                  v-auth="574"
                  @click="openCostEvent(2, 5)"
                ></i>
              </div>
            </td>
            <td>
              <div class="pointer flex seen">
                {{ tabularData.claimForCompensationSalesman }}
                <i
                  style="font-size: 14px"
                  class="iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show"
                  v-auth="574"
                  @click="openCostEvent(3, 5)"
                ></i>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="columnName">毛利</td>
            <td>
              {{ profit.contractProfit }}
            </td>
            <td>
              {{ profit.selfPayingProfit }}
            </td>
            <td>
              {{ profit.contractForProfit }}
            </td>
            <td>
              {{ profit.claimSettlementProfit }}
            </td>
            <td>
              {{ profit.claimForCompensationProfit }}
            </td>
          </tr>
          <tr>
            <td colspan="2" class="columnName">毛利率</td>
            <td>
              {{ profitMargin.contractRate }}
            </td>
            <td>
              {{ profitMargin.selfPayingRate }}
            </td>
            <td>
              {{ profitMargin.contractForRate }}
            </td>
            <td>
              {{ profitMargin.claimSettlementRate }}
            </td>
            <td>
              {{ profitMargin.claimForCompensationRate }}
            </td>
          </tr>
        </table>
      </div>
    </template>
    <!-- 设置成本的弹窗 -->
    <cost-dialog
      v-if="setupCostDialog"
      :visibility.sync="setupCostDialog"
      :time="form.statisticsPeriod"
      :typeNum="typeCombination"
      :costNum="costCombination"
      @updateData="getData"
    ></cost-dialog>
  </base-container>
</template>

<script>
//接口
import {
  getControlCompanyList,
  getGrossProfitList,
} from '@/api/technologyCenter/profit';
import costDialog from '@/components/businessComponent/costDialog.vue';
export default {
  name: 'marginDetail',
  components: {
    costDialog,
  },
  data() {
    return {
      form: {
        statisticsPeriod: '',
      },
      //门店列表
      companyList: [],
      searchControlCompanyLoading: false,
      //表格数据
      tabularData: {},
      //毛利数据
      profit: {
        contractProfit: '',
        selfPayingProfit: '',
        contractForProfit: '',
        claimSettlementProfit: '',
        claimForCompensationProfit: '',
      },
      //毛利率数据
      profitMargin: {
        contractRate: '',
        selfPayingRate: '',
        contractForRate: '',
        claimSettlementRate: '',
        claimForCompensationRate: '',
      },
      //设置成本弹窗可见性 ==0 ?'-':
      setupCostDialog: false,
      typeCombination: '',
      costCombination: '',
    };
  },
  created() {},
  activated() {
    if (Object.keys(this.$route.params).length) {
      this.$set(
        this.form,
        'statisticsPeriod',
        this.$route.params.statisticsPeriod
      );
      this.$set(this.form, 'companyId', this.$route.params.companyId);
    } else {
      this.form.statisticsPeriod = new Date().Format('yyyy-MM');
    }
    this.searchControlCompany(this.$route.params?.companyName || '');
    this.getData();
  },
  methods: {
    openCostEvent(type, value) {
      this.setupCostDialog = true;
      this.typeCombination = type;
      this.costCombination = value;
    },
    changeMonth() {
      this.searchControlCompany();
    },
    //选中门店
    selectCompany() {},
    searchControlCompany(keyWord = '') {
      this.searchControlCompanyLoading = true;
      getControlCompanyList(
        keyWord,
        new Date(this.form.statisticsPeriod).Format('yyyy-MM-dd')
      )
        .then((res) => {
          this.companyList = res || [];
        })
        .finally(() => {
          this.searchControlCompanyLoading = false;
        });
    },
    //获取表格数据
    getData() {
      var year = new Date(this.form.statisticsPeriod).getFullYear();
      var month = new Date(this.form.statisticsPeriod).getMonth();
      var time = {
        startTime: new Date(this.form.statisticsPeriod).Format('yyyy-MM-dd'),
        endTime: new Date(year, month + 1, 0).Format('yyyy-MM-dd'),
      };
      var data = Object.assign(
        {},
        this.form,
        time,
        new Date(this.form.statisticsPeriod).Format('yyyy/MM')
      );

      getGrossProfitList(data).then((res) => {
        // const reg = /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g;
        const reg = /(?!^)(?=(\d{3})+\.)/g;
        this.tabularData = this.$lodash.cloneDeep(res);
        for (let key in this.tabularData) {
          if (this.tabularData[key] == 0) {
            this.tabularData[key] = '-';
          } else {
            this.tabularData[key] = this.tabularData[key]
              .toFixed(2)
              .toString()
              .replace(reg, ',');
          }
        }
        //合计车单成本
        var contract =
          res.material +
          res.contract +
          res.finance +
          res.badDebts +
          res.premium +
          res.lossDetermined +
          res.salesman;
        //自费车单成本
        var selfPaying =
          res.selfPayingMaterial +
          res.selfPayingContract +
          res.selfPayingFinance +
          res.selfPayingFinance +
          res.selfPayingPremium +
          res.selfPayingLossDetermined +
          res.selfPayingSalesman;
        //包干车单成本
        var contractFor =
          res.contractForMaterial +
          res.contractForContract +
          res.contractForFinance +
          res.contractForBadDebts +
          res.contractForPremium +
          res.contractForLossDetermined +
          res.contractForSalesman;
        //理赔车单成本
        var claimSettlement =
          res.claimSettlementMaterial +
          res.claimSettlementContract +
          res.claimSettlementFinance +
          res.claimSettlementBadDebts +
          res.claimSettlementPremium +
          res.claimSettlementLossDetermined +
          res.claimSettlementSalesman;
        //索赔车单成本
        var claimForCompensation =
          res.claimForCompensationMaterial +
          res.claimForCompensationContract +
          res.claimForCompensationFinance +
          res.claimForCompensationBadDebts +
          res.claimForCompensationPremium +
          res.claimForCompensationLossDetermined +
          res.claimForCompensationSalesman;
        //毛利
        this.profit.contractProfit = res.turnover - contract;
        this.profit.selfPayingProfit = res.selfPayingTurnover - selfPaying;
        this.profit.contractForProfit = res.contractForTurnover - contractFor;

        this.profit.claimSettlementProfit =
          res.claimSettlementTurnover - claimSettlement;
        this.profit.claimForCompensationProfit =
          res.claimForCompensationTurnover - claimForCompensation;
        for (let key in this.profit) {
          if (this.profit[key] == 0) {
            this.profit[key] = '-';
          } else {
            this.profit[key] = this.profit[key]
              .toFixed(2)
              .toString()
              .replace(reg, ',');
          }
        }

        //合计毛利率
        if (
          Math.sign(res.turnover - contract) < 0 &&
          Math.sign(res.turnover) == 0
        ) {
          this.profitMargin.contractRate = 0;
        } else if (res.turnover - contract == 0 && res.turnover == 0) {
          this.profitMargin.contractRate = 0;
        } else if (!isNaN(res.turnover - contract) && !isNaN(res.turnover)) {
          this.profitMargin.contractRate = Math.round(
            ((res.turnover - contract) / res.turnover) * 100
          );
        }
        // 自费毛利率
        if (
          Math.sign(res.selfPayingTurnover - selfPaying) < 0 &&
          Math.sign(res.selfPayingTurnover) == 0
        ) {
          this.profitMargin.selfPayingRate = 0;
        } else if (
          res.selfPayingTurnover - selfPaying == 0 &&
          res.selfPayingTurnover == 0
        ) {
          this.profitMargin.selfPayingRate = 0;
        } else if (
          !isNaN(res.selfPayingTurnover - selfPaying) &&
          !isNaN(res.selfPayingTurnover)
        ) {
          this.profitMargin.selfPayingRate = Math.round(
            ((res.selfPayingTurnover - selfPaying) / res.selfPayingTurnover) *
              100
          );
        }
        //包干毛利率
        if (
          Math.sign(res.contractForTurnover - contractFor) < 0 &&
          Math.sign(res.contractForTurnover) == 0
        ) {
          this.profitMargin.contractForRate = 0;
        } else if (
          res.contractForTurnover - contractFor == 0 &&
          res.contractForTurnover == 0
        ) {
          this.profitMargin.contractForRate = 0;
        } else if (
          !isNaN(res.contractForTurnover - contractFor) &&
          !isNaN(res.contractForTurnover)
        ) {
          this.profitMargin.contractForRate = Math.round(
            ((res.contractForTurnover - contractFor) /
              res.contractForTurnover) *
              100
          );
        }

        //理赔毛利率
        if (
          Math.sign(res.claimSettlementTurnover - claimSettlement) < 0 &&
          Math.sign(res.claimSettlementTurnover) == 0
        ) {
          this.profitMargin.claimSettlementRate = 0;
        } else if (
          res.claimSettlementTurnover - claimSettlement == 0 &&
          res.claimSettlementTurnover == 0
        ) {
          this.profitMargin.claimSettlementRate = 0;
        } else if (
          !isNaN(res.claimSettlementTurnover - claimSettlement) &&
          !isNaN(res.claimSettlementTurnover)
        ) {
          this.profitMargin.claimSettlementRate = Math.round(
            ((res.claimSettlementTurnover - claimSettlement) /
              res.claimSettlementTurnover) *
              100
          );
        }
        //索赔毛利率
        if (
          Math.sign(res.claimForCompensationTurnover - claimForCompensation) <
            0 &&
          Math.sign(res.claimForCompensationTurnover) == 0
        ) {
          this.profitMargin.claimForCompensationRate = 0;
        } else if (
          res.claimForCompensationTurnover - claimForCompensation == 0 &&
          res.claimForCompensationTurnover == 0
        ) {
          this.profitMargin.claimForCompensationRate = 0;
        } else if (
          !isNaN(res.claimForCompensationTurnover - claimForCompensation) &&
          !isNaN(res.claimForCompensationTurnover)
        ) {
          this.profitMargin.claimForCompensationRate = Math.round(
            ((res.claimForCompensationTurnover - claimForCompensation) /
              res.claimForCompensationTurnover) *
              100
          );
        }

        for (let key in this.profitMargin) {
          if (this.profitMargin[key] == 0) {
            this.profitMargin[key] = '-';
          } else {
            this.profitMargin[key] = this.profitMargin[key] + '%';
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.table-box {
  flex: 1;
  overflow: auto;
  width: 100%;
}
.border-table {
  border-collapse: collapse;
  width: 100%;
  font-size: 14px;
  tr {
    th {
      vertical-align: middle;
    }
  }
  th,
  tr {
    line-height: 18px;
    font-size: 14px;
  }
  th,
  td {
    box-sizing: border-box;
    border: 1px solid #ebeef5;
    padding: 8px;
    color: #242526;
    font-weight: 400;
  }

  th {
    padding: 10px 8px;
    background: #f5f7fa;
    font-weight: 500;
    color: #242526;
  }
  td {
    height: 1px;
    vertical-align: top;
    text-align: right;
    &:hover {
      background: #f5f7fa;
    }
  }
  .el-textarea {
    height: 100%;
    ::v-deep .el-textarea__inner {
      min-height: 100% !important;
    }
  }
  .columnName {
    text-align: left;
  }
}

.seen {
  justify-content: flex-end;
  .seen-show {
    display: none;
    cursor: pointer;
  }
  &:hover .seen-show {
    display: block;
  }
}
</style>
