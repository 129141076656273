<template>
  <!-- <div class="page-container"> -->
  <base-container ref="baseContainer" paddingTop>
    <template>
      <div>
        <el-form :model="filterForm" label-width="100px" size="small">
          <div class="flex-x-between">
            <div class="flex-warp flex-shrink0">
              <el-form-item label="批次时间">
                <el-date-picker
                  v-model="times"
                  type="month"
                  placeholder="选择时间"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="包干客户">
                <el-input
                  v-model="filterForm.clientNameOrPhoneOrNum"
                  placeholder="请输入客户名称"
                  clearable
                  @keyup.enter.native="getList"
                ></el-input>
              </el-form-item>
            </div>
            <div class="el-form-item--small">
              <el-button
                class="btn_search"
                type="primary"
                size="small"
                @click="getList()"
                >查询</el-button
              >
              <el-button
                class="btn_search"
                size="small"
                @click="resetFilterForm"
                >置空</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
    </template>
    <!-- 以下是表格 -->
    <template>
      <div class="flex-x-end">
        <el-button
          v-auth="544"
          size="small"
          type="text"
          class="blue"
          @click="showExportDialog = true"
          >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
        ></el-button>
      </div>
      <div class="content-container">
        <div style="width: 100%; flex: 1; overflow: auto; display: flex">
          <el-table :data="tableData" class="custom-table" border>
            <el-table-column type="index" label="序号" width="60">
            </el-table-column>
            <el-table-column label="操作" width="80">
              <template slot-scope="scope">
                <el-button
                  v-auth="445"
                  class="heavy-btn"
                  :disabled="disabled"
                  @click="handleHeavyRun(scope.row)"
                  :type="types"
                >
                  {{ words }}
                </el-button>
              </template>
            </el-table-column>
            <el-table-column prop="batchCode" label="批次号" min-width="180">
              <template slot-scope="scope">
                <p class="blue pointer" @click="goCarBillList(scope.row)">
                  {{ scope.row.batchCode }}
                </p>
              </template>
            </el-table-column>
            <el-table-column
              prop="batchDate"
              label="批次生成时间"
              min-width="180"
            >
            </el-table-column>
            <el-table-column
              prop="updateTime"
              label="最后更新时间"
              min-width="180"
            >
            </el-table-column>
            <el-table-column
              prop="contractClientName"
              label="包干客户"
              min-width="150"
            >
            </el-table-column>
            <el-table-column
              prop="contractTypeChar"
              label="包干方式"
              min-width="120"
            >
            </el-table-column>
            <el-table-column prop="billPaids" label="单据金额" min-width="120">
            </el-table-column>
            <el-table-column prop="billCosts" label="单据成本" min-width="120">
            </el-table-column>
            <el-table-column
              prop="contractTotal"
              label="包干金额"
              min-width="100"
            >
            </el-table-column>
            <el-table-column prop="carBillCount" label="车单数" min-width="100">
            </el-table-column>
            <el-table-column prop="storeCount" label="门店数" min-width="100">
            </el-table-column>
            <el-table-column prop="contractState" label="状态" min-width="100">
              <template slot-scope="scope">{{
                scope.row.contractState | dict('contractState')
              }}</template>
            </el-table-column>
            <el-table-column label="备注" min-width="200">
              <template slot-scope="scope">
                <p v-if="scope.row.contractState == 2">
                  {{ scope.row.remark }}
                </p>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="reloader" label="重跑操作人" min-width="100">
            </el-table-column>
            <el-table-column prop="reloadTime" label="重跑时间" min-width="180">
            </el-table-column>
            <el-table-column
              prop="reloadReason"
              label="重跑原因"
              min-width="180"
            >
            </el-table-column> -->
          </el-table>
        </div>
      </div>
      <template slot="footer">
        <pagination @pageChange="changePage" :total="total"></pagination>
      </template>
    </template>
    <export-dialog
      :visibility.sync="showExportDialog"
      :total="total"
      :pageOption="pageOption"
      :filterForm="filterForm"
      :url="exportUrl"
    ></export-dialog>
  </base-container>
  <!-- </div> -->
</template>

<script>
import pagination from '@/components/Pagination.vue';
import ExportDialog from '@/components/ExportDialog';
//接口
import {
  getContractBatchOnAccountList,
  getContractBatchRun,
  exportContractBatchUrl,
} from '@/api/financial/financialOver';
export default {
  name: 'parcelDryFourch',
  components: { pagination, ExportDialog },
  data() {
    return {
      filterForm: {},
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      tableData: [],
      showExportDialog: false,
      exportUrl: exportContractBatchUrl,
      //设置表格中重跑按钮
      disabled: false,
      words: '重跑',
      types: '',
      startTime: '',
      endTime: '',
      times: new Date().Format('yyyy-MM'),
      UpStartTime: '',
      UpEndTime: '',
    };
  },
  created() {
    this.getFirstDateAndLastDate(this.times);
    this.getList();
  },
  watch: {
    times() {
      this.getFirstDateAndLastDate(this.times);
    },
  },
  mounted() {},
  methods: {
    //获取选中月的第一天跟最后一天和选中的上一个月的第一天跟最后一天
    getFirstDateAndLastDate(times) {
      var date = new Date(times);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var lastYear = date.getFullYear();
      var lastMonth = date.getMonth();
      if (month > 12) {
        month = 1;
        year++;
      }
      if (month < 10) {
        month = '0' + month;
      }
      //选中月的上一个月
      if (lastMonth > 12) {
        lastMonth = 1;
        lastYear++;
      }
      if (lastMonth < 10) {
        lastMonth = '0' + lastMonth;
      }
      var monthLastDay = new Date(year, month, 0).getDate();
      this.startTime = year + '-' + month + '-' + '01' + ' 00:00:00';
      this.endTime = year + '-' + month + '-' + monthLastDay + ' 23:59:59';
      //选中的上个月的第一天跟最后一天
      var upMonthLastDay = new Date(lastYear, lastMonth, 0).getDate();
      this.UpStartTime = lastYear + '-' + lastMonth + '-' + '01';
      this.UpEndTime = lastYear + '-' + lastMonth + '-' + upMonthLastDay;
    },
    /**
     * 重置筛选表单
     */
    resetFilterForm() {
      this.filterForm = {};
      this.times = '';
    },
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getList(false);
    },
    getList(reset = true) {
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      var batchTime = {
        startTime: this.times == null ? '' : this.startTime,
        endTime: this.times == null ? '' : this.endTime,
      };
      var data = Object.assign(this.filterForm, this.pageOption, batchTime);
      getContractBatchOnAccountList(data).then((res) => {
        this.total = res.total;
        this.tableData = res.records;
      });
    },
    handleHeavyRun(row) {
      var data = {
        batchId: Number(row.batchId),
      };
      getContractBatchRun(data).then((res) => {
        this.getList(false);
      });
      // this.words = '重跑中';
      // this.disabled = true;
      // this.types = 'primary';
    },
    /**
     * 跳转车单记录
     */
    goCarBillList(row) {
      var times = row.batchDate.slice(0, 7);
      //已结账  (状态是待结账 结账时间为对应批次时间的上月时间)
      this.getFirstDateAndLastDate(times);
      var checkStartTimes;
      if (['', undefined, null].includes(times)) {
        checkStartTimes = [];
      } else {
        checkStartTimes = [this.UpStartTime, this.UpEndTime];
      }
      this.$router.push({
        name: 'carBillList',
        params: {
          forms: {
            billState: 1,
            nodeKey: 1,
            status: 1,
            consumerMsg: row.contractClientName,
            checkStartTimes: checkStartTimes,
          },
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 30px 30px 0;
  box-sizing: border-box;
}
.content-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: auto;
}
.heavy-btn {
  width: 51px;
  height: 28px;
  padding: 0px;
  font-size: 14px;
  border-radius: 14px;
  border: 1px solid $blue;
  color: $blue;
}
.custom-table {
  display: flex;
  flex-direction: column;
  overflow: auto;
  ::v-deep .el-table__header-wrapper {
    flex-shrink: 0;
  }
  ::v-deep .el-table__body-wrapper {
    flex: 1;
    overflow: auto;
  }
}
</style>
