<template>
  <div>
    <el-drawer
      title="客户指令"
      :visible="visibility"
      size="60%"
      :append-to-body="true"
      :before-close="handleClose"
      modal
      close-on-press-escape
      wrapperClosable
    >
      <div class="container">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="自费" name="selfPaying"></el-tab-pane>
          <el-tab-pane label="退还车" name="returnCar"></el-tab-pane>
          <el-tab-pane label="包干" name="contractFor"></el-tab-pane>
          <el-tab-pane label="理赔" name="claimSettlement"></el-tab-pane>
        </el-tabs>
        <div class="table-box">
          <table class="border-table">
            <thead>
              <tr>
                <th align="center">项目</th>
                <th align="center">指令详情</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="activeType.coreAsk">
                <td>核心要求</td>
                <td>{{ activeType.coreAsk }}</td>
              </tr>
              <tr v-if="activeType.specialAsk">
                <td>专项要求</td>
                <td>{{ activeType.specialAsk }}</td>
              </tr>
              <tr v-if="activeType.enterAsk">
                <td>进场要求</td>
                <td>{{ activeType.enterAsk }}</td>
              </tr>
              <tr v-if="activeType.ringAsk">
                <td>环检要求</td>
                <td>{{ activeType.ringAsk }}</td>
              </tr>
              <tr v-if="activeType.reportAsk">
                <td>报备/审批要求</td>
                <td>{{ activeType.reportAsk }}</td>
              </tr>
              <tr v-if="activeType.reportTarget">
                <td>报备对象</td>
                <td>{{ activeType.reportTarget }}</td>
              </tr>
              <tr v-if="activeType.faultPicsVideo">
                <td>故障照片/视频</td>
                <td>{{ activeType.faultPicsVideo }}</td>
              </tr>
              <tr v-if="activeType.factoryAsk">
                <td>出厂要求</td>
                <td>{{ activeType.factoryAsk }}</td>
              </tr>
              <tr v-if="activeType.maintenanceAsk">
                <td>保养要求</td>
                <td>{{ activeType.maintenanceAsk }}</td>
              </tr>
              <tr v-if="activeType.tireReplaceAsk">
                <td>轮胎更换要求</td>
                <td>{{ activeType.tireReplaceAsk }}</td>
              </tr>
              <tr v-if="activeType.effectiveAsk">
                <td>时效要求</td>
                <td>{{ activeType.effectiveAsk }}</td>
              </tr>
              <tr v-if="activeType.qualityAsk">
                <td>质量要求</td>
                <td>{{ activeType.qualityAsk }}</td>
              </tr>
              <tr v-if="activeType.driverManageAsk">
                <td>司机管理要求</td>
                <td>{{ activeType.driverManageAsk }}</td>
              </tr>
              <tr v-if="activeType.outageSubsidyAsk">
                <td>停运补偿标准</td>
                <td>{{ activeType.outageSubsidyAsk }}</td>
              </tr>
              <tr v-if="activeType.issueRepairProve">
                <td>开具维修证明标准</td>
                <td>{{ activeType.issueRepairProve }}</td>
              </tr>
              <tr v-if="activeType.costCollectAsk">
                <td>费用收取要求</td>
                <td>{{ activeType.costCollectAsk }}</td>
              </tr>
              <tr v-if="activeType.insuredRule">
                <td>标的规则</td>
                <td>{{ activeType.insuredRule }}</td>
              </tr>
              <tr v-if="activeType.tripartiteRule">
                <td>三者规则</td>
                <td>{{ activeType.tripartiteRule }}</td>
              </tr>
              <tr v-if="activeType.insuranceInfo">
                <td>保险信息</td>
                <td>{{ activeType.insuranceInfo }}</td>
              </tr>
              <tr v-if="activeType.driverDangerManage">
                <td>司机出险管理</td>
                <td>{{ activeType.driverDangerManage }}</td>
              </tr>
            </tbody>
          </table>
          <p class="empty" v-if="!Object.keys(activeType).length">
            该客户暂无该业务类型指令
          </p>
        </div>
        <div class="footer">
          <el-button
            type="primary"
            v-auth="545"
            size="small"
            @click="handleEdit"
            >编辑指令</el-button
          >
        </div>
      </div>
    </el-drawer>
    <editClientCommandDialog
      v-if="showEditDialog"
      :visibility.sync="showEditDialog"
      :clientId="clientId"
      @save="getData"
    ></editClientCommandDialog>
  </div>
</template>

<script>
import { getClientCommand } from '@/api/customer/customer';
import editClientCommandDialog from './editClientCommandDialog.vue';

export default {
  name: 'clientCommandDrawer',
  components: {
    editClientCommandDialog,
  },
  props: {
    visibility: { type: Boolean, default: false },
    clientId: {
      type: Number,
      required: true,
    },
    defaultActiveName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      activeName: 'selfPaying',
      selfPaying: {},
      returnCar: {},
      contractFor: {},
      claimSettlement: {},

      showEditDialog: false,
    };
  },
  computed: {
    activeType() {
      switch (this.activeName) {
        case 'selfPaying':
          return this.selfPaying;
        case 'returnCar':
          return this.returnCar;
        case 'contractFor':
          return this.contractFor;
        case 'claimSettlement':
          return this.claimSettlement;
        default:
          return this.selfPaying;
      }
    },
  },
  created() {
    this.activeName = this.defaultActiveName;
    this.getData();
  },
  methods: {
    /**
     * 获取数据
     */
    getData() {
      getClientCommand(this.clientId).then((res) => {
        console.log(res);
        this.selfPaying = res.selfPaying;
        this.returnCar = res.returnCar;
        this.contractFor = res.contractFor;
        this.claimSettlement = res.claimSettlement;
      });
    },
    handleEdit() {
      this.showEditDialog = true;
    },
    handleClick(tabName) {},
    handleClose() {
      this.$emit('update:visibility', false);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-drawer__header {
  margin-bottom: 8px;
}
.container {
  padding: 0 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  .table-box {
    flex: 1;
    overflow: auto;
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 10px 24px;
  }
}
.border-table {
  border-collapse: collapse;
  width: 100%;
  font-size: 14px;

  th,
  tr {
    line-height: 18px;
    font-size: 14px;
  }
  th,
  td {
    box-sizing: border-box;
    border: 1px solid #ebeef5;
    padding: 8px;
    white-space: pre-wrap;
    color: #3c3d40;
    font-weight: 400;
  }

  th {
    padding: 10px 8px;
    background: #f5f7fa;
    font-weight: 500;
    color: #242526;
  }
  td {
    &:first-child {
      width: 140px;
      vertical-align: middle;
      background: #f5f7fa;
      color: #242526;
      font-weight: 400;
    }
  }
}

.empty {
  text-align: center;
  color: #666;
  padding: 20px 0;
}
</style>
