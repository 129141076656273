import http from '@/service/http';

//定损提醒列表
export function carLossDeterminationBillReminderInfo(data) {
  return http.post('/admin/carLossDeterminationBill/reminderInfo', data, {
    onlyReturnData: true,
  });
}

//定损提醒设置保存
export function carLossDeterminationBillReminderSave(data) {
  return http.post('/admin/carLossDeterminationBill/reminderSave', data, {
    onlyReturnData: false,
  });
}
