import http from '@/service/http';

const defaultListOption = {
  current: 1,
  size: 10,
};

//获取服务管理列表
export function getServiceItemPage(data = defaultListOption) {
  return http.post('/admin/service/getServiceItemPage', data);
}

//新增服务管理
export function addServiceItem(data) {
  return http.post('/admin/service/addServiceItem', data);
}

//新增服务管理
export function editServiceItem(data) {
  return http.post('/admin/service/editServiceItem', data);
}

//获取服务分类
export function getCategory(id) {
  return http.post('/admin/service/getIsShareServiceCategory', id);
}
/**
 * 根据关键字查询服务信息
 * @param {Object} data
 * @returns
 */
export function searchServiceItemByKeyword(data) {
  return http.post('/admin/service/searchServiceItemByKeyword', data);
}
//id查询
export function getOneServiceItem(data) {
  return http.post('/admin/service/getOneServiceItem', data);
}

//关联品名
export function getProductName(data) {
  return http.post('/admin/productName/list', data);
}

export function getServiceConstructionItem(data) {
  return http.post('/admin/service/getServiceConstructionItem', data);
}

//服务项目导入
export function importData(file) {
  let formData = new FormData();
  formData.append('file', file);

  return http.request({
    method: 'post',
    url: '/admin/file/serviceItem/import',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
    timeout: 18e5,
  });
}

//服务项目导入结果
export function importResult(file) {
  let formData = new FormData();
  formData.append('file', file);

  return http.request({
    method: 'post',
    url: '/admin/file/serviceItem/importResult',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
    timeout: 18e5,
  });
}

// //服务项目导入信息导出
// export function serviceItemImportMessage(data) {
//   return http.post('/admin/serviceItemImportMessage/export', data);
// }
