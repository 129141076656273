var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visibility,
        width: "80%",
        "before-close": _vm.handleClose,
        "close-on-click-modal": false,
      },
    },
    [
      _c("p", { staticStyle: { margin: "20px 0px", "font-size": "20px" } }, [
        _vm._v("认领记录"),
      ]),
      _c(
        "el-table",
        {
          staticClass: "custom-table",
          attrs: {
            data: _vm.claimRecordDetails,
            "empty-text": "没有认领记录",
            border: "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { type: "index", label: "序号", width: "60" },
          }),
          _c("el-table-column", {
            attrs: { label: "核销操作", width: "300" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.writeOffStatus === 1
                      ? _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "auth",
                                rawName: "v-auth",
                                value: 665,
                                expression: "665",
                              },
                            ],
                            staticClass: "text_operation blue",
                            staticStyle: { "margin-right": "10px" },
                            on: {
                              click: function ($event) {
                                return _vm.handleSingleReversalWriteOffStatus(
                                  scope.row
                                )
                              },
                            },
                          },
                          [_vm._v("反核销")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              width: "200",
              label: "回款门店",
              prop: "receivingCompanyName",
            },
          }),
          _c("el-table-column", {
            attrs: {
              width: "200",
              label: "系统流水编号",
              prop: "sysStatementSeqCode",
            },
          }),
          _c("el-table-column", {
            attrs: { width: "200", label: "回款主体", prop: "invoicingEntity" },
          }),
          _c("el-table-column", {
            attrs: { width: "200", label: "银行账号", prop: "bankAccount" },
          }),
          _c("el-table-column", {
            attrs: { width: "200", label: "开户行", prop: "depositBank" },
          }),
          _c("el-table-column", {
            attrs: {
              width: "162",
              label: "回款时间",
              prop: "receivingDatetime",
            },
          }),
          _c("el-table-column", {
            attrs: {
              width: "200",
              label: "保险公司",
              prop: "insuranceCompanyName",
            },
          }),
          _c("el-table-column", {
            attrs: { width: "200", label: "付款账号", prop: "paymentAccount" },
          }),
          _c("el-table-column", {
            attrs: {
              "min-width": "140",
              label: "银行摘要",
              prop: "bankSummary",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("el-tooltip", { attrs: { content: row.bankSummary } }, [
                      _c("div", { staticClass: "text-ellipsis-2-row" }, [
                        _vm._v(" " + _vm._s(row.bankSummary) + " "),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              "min-width": "140",
              label: "资金流水号",
              prop: "transactionSeq",
            },
          }),
          _c("el-table-column", {
            attrs: { width: "140", label: "已认领金额", prop: "claimAmount" },
          }),
          _c("el-table-column", {
            attrs: { width: "140", label: "退款金额", prop: "refundAmount" },
          }),
          _c("el-table-column", {
            attrs: { width: "140", label: "业务冲抵费", prop: "offsetFee" },
          }),
        ],
        1
      ),
      _c("p", { staticStyle: { margin: "20px 0px", "font-size": "20px" } }, [
        _vm._v("备注"),
      ]),
      _c("el-input", {
        attrs: {
          type: "textarea",
          maxlength: "200",
          "show-word-limit": "",
          disabled: "",
          placeholder: "请输入认领资金流水备注",
        },
        model: {
          value: _vm.editForm.remark,
          callback: function ($$v) {
            _vm.$set(_vm.editForm, "remark", $$v)
          },
          expression: "editForm.remark",
        },
      }),
      _c("template", { slot: "footer" }, [
        _c(
          "span",
          {
            staticStyle: {
              margin: "20px 0px",
              "font-size": "15px",
              "margin-right": "100px",
            },
          },
          [
            _c("span", [
              _vm._v(
                "已认领：" +
                  _vm._s(_vm._f("toThousands")(_vm.claimAmount)) +
                  " "
              ),
            ]),
          ]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }