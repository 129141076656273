<template>
  <base-container paddingTop>
    <div>
      <el-form
        :model="form"
        label-width="100px"
        size="small"
        style="margin-left: -22px"
        class="filter-form"
      >
        <div class="flex-x-between">
          <div class="flex-warp">
            <el-form-item label="门店名称">
              <el-select
                class="w100"
                v-model="form.companyId"
                filterable
                clearable
                remote
                placeholder="请输入门店名称、编号"
                :remote-method="searchStore"
                :loading="searchStoreLoading"
              >
                <el-option
                  v-for="item in storeList"
                  :key="item.companyId"
                  :label="item.companyName"
                  :value="item.companyId"
                >
                  <span style="float: left; color: #8492a6; font-size: 13px">{{
                    item.companyName
                  }}</span>
                  <span style="float: right">{{ item.companyId }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <template slot="label">
                <div>
                  开票主体
                  <el-tooltip
                    effect="light"
                    content="开票主体，即为协议客户、保险公司和三包厂家的应收账款的回款管理门店（保险公司的开票主体也是定损主体）。车单记录的开票主体，可查已成为开票主体的全部门店"
                    placement="bottom-start"
                  >
                    <i
                      class="iconfont grow-icon_doubt"
                      style="color: #9a9a9a"
                    ></i>
                  </el-tooltip>
                </div>
              </template>
              <el-select
                class="w100"
                v-model="form.invoiceName"
                filterable
                clearable
                remote
                placeholder="搜索开票主体名称"
                :remote-method="searchInvoice"
                :loading="searchInvoiceLoading"
              >
                <el-option
                  v-for="item in invoiceList"
                  :key="item.companyId"
                  :label="item.invoicingEntity"
                  :value="item.invoicingEntity"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="h100">
            <el-button
              class="btn_search"
              type="primary"
              size="small"
              @click="getList"
              >查询</el-button
            >
          </div>
        </div>
      </el-form>
    </div>
    <el-table
      :data="invoiceTable"
      border
      style="width: 100%"
      class="custom-table"
      v-el-table-infinite-scroll="loadTableData"
    >
      <el-table-column label="操作" width="70">
        <template slot-scope="scope">
          <div
            @click="handleEdit(scope.$index, scope.row)"
            class="primary pointer"
          >
            {{ scope.row.operationText }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="companyName" label="门店名称" min-width="180">
      </el-table-column>
      <el-table-column min-width="180" label="开票主体">
        <template #default="{ row }">
          <div v-if="row.change">
            <el-input
              placeholder="请输入"
              clearable
              size="small"
              v-model="row.invoicingEntity"
            >
            </el-input>
          </div>
          <div v-else>{{ row.invoicingEntity }}</div>
        </template>
      </el-table-column>
    </el-table>
  </base-container>
</template>

<script>
import elTableInfiniteScroll from 'el-table-infinite-scroll'; //引入el-table无限滚动指令
//接口
import {
  getInvoiceList,
  getInvoiceUpdate,
  searchInvoice,
} from '@/api/storeManagement/index';
import { searchStore } from '@/api/customer/store';
import { mapState } from 'vuex';

export default {
  directives: {
    'el-table-infinite-scroll': elTableInfiniteScroll,
  },
  name: 'storeManagement',
  components: {},
  data() {
    return {
      form: {},
      // 搜索门店
      storeList: [],
      searchStoreLoading: false,
      //开票主体
      invoiceList: [],
      searchInvoiceLoading: false,

      invoiceTable: [],
      hasMore: true,
      current: 0,
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
      companyName: (state) => state.user.companyName,
    }),
  },
  created() {
    this.searchStore();
    this.searchInvoice();
  },
  methods: {
    /**
     * 获取数据
     */
    loadTableData() {
      if (!this.hasMore) return;
      var currentCompanyId = {
        currentCompanyId: this.companyId,
        current: ++this.current,
        size: 10,
      };
      var data = Object.assign(this.form, currentCompanyId);
      getInvoiceList(data).then((res) => {
        let _invoiceTable = [];
        _invoiceTable.push(...res.records);
        _invoiceTable = this.$lodash.uniqBy(_invoiceTable, 'companyId'); //去重
        _invoiceTable.forEach((row) => {
          row.operationText = '编辑';
          row.change = false;
        });
        this.invoiceTable.push(..._invoiceTable);
        this.invoiceTable = this.$lodash.uniqBy(this.invoiceTable, 'companyId'); //去重
        this.hasMore = res.total > this.current * 10; //判断如果总条数大于当前请求的总条数就实现分页加载数据
      });
    },
    //获取列表数据
    getList() {
      var currentCompanyId = {
        currentCompanyId: this.companyId,
      };
      this.form.current = 1;
      var data = Object.assign(this.form, currentCompanyId);
      getInvoiceList(data).then((res) => {
        const _invoiceTable = this.$lodash.cloneDeep(res.records);
        _invoiceTable.forEach((row) => {
          row.operationText = '编辑';
          row.change = false;
        });
        this.invoiceTable = _invoiceTable;
        this.hasMore = res.total > this.form.current * 10; //判断如果总条数大于当前请求的总条数就实现分页加载数据
        this.current = 1;
        this.loadTableData();
      });
    },
    /**
     * 远程搜索门店列表
     */
    storeKeyWorkChange(keyWord) {
      if (keyWord) {
        this.searchStore(keyWord);
      }
    },
    searchStore(keyWord = '') {
      this.searchStoreLoading = true;
      searchStore(keyWord, this.companyId)
        .then((res) => {
          this.storeList = res;
        })
        .finally(() => {
          this.searchStoreLoading = false;
        });
    },
    // 选择门店
    selectStore(storeId) {},
    //删除门店
    deleteStore() {},
    /**
     * 远程搜索开票主体表
     */
    invoiceKeyWorkChange(keyWord) {
      if (keyWord) {
        this.searchInvoice(keyWord);
      }
    },
    searchInvoice(keyWord = '') {
      this.searchInvoiceLoading = true;
      searchInvoice(keyWord, this.companyId)
        .then((res) => {
          this.invoiceList = res;
        })
        .finally(() => {
          this.searchInvoiceLoading = false;
        });
    },
    //点击编辑按钮
    handleEdit(index, row) {
      if (this.invoiceTable[index].operationText == '保存') {
        getInvoiceUpdate({
          companyId: row.companyId,
          invoicingEntity: row.invoicingEntity,
        }).then((res) => {
          if (res.code == 200) {
            this.$message.success('保存成功！');
            this.invoiceTable[index].operationText = '编辑';
            this.invoiceTable[index].change = false;
            this.searchInvoice();
            this.loadTableData();
          }
        });
      } else {
        if (['', undefined].includes(row.invoicingEntity)) {
          this.$set(
            this.invoiceTable[index],
            'invoicingEntity',
            this.invoiceTable[index].companyName
          );
        }
        this.invoiceTable[index].operationText = '保存';
        this.invoiceTable[index].change = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
  &::v-deep .el-tabs__content {
    height: 100%;
    .el-tab-pane {
      // height: calc(100% - 109px);
      height: 100%;
      overflow: auto;
    }
  }
}
</style>
