var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { ref: "baseContainer", attrs: { paddingTop: "" } },
    [
      [
        _c(
          "div",
          [
            _c(
              "el-form",
              {
                staticClass: "filter-form",
                attrs: {
                  model: _vm.form,
                  "label-width": "100px",
                  size: "small",
                },
              },
              [
                _c("div", { staticClass: "flex-x-between" }, [
                  _c(
                    "div",
                    { staticClass: "flex-warp flex-shrink0" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "车牌号" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入", clearable: "" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.doSearch.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.form.carNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "carNumber", $$v)
                              },
                              expression: "form.carNumber",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c("template", { slot: "label" }, [
                            _c(
                              "div",
                              [
                                _vm._v(" 操作门店 "),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "light",
                                      content:
                                        "定损单的操作门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)",
                                      placement: "bottom-start",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "iconfont grow-icon_doubt",
                                      staticStyle: { color: "#9a9a9a" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("operateCompanyCascader", {
                            attrs: {
                              operateCompanyUrl: _vm.form.operateCompanyUrl,
                            },
                            model: {
                              value: _vm.form.operateCompanyId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "operateCompanyId", $$v)
                              },
                              expression: "form.operateCompanyId",
                            },
                          }),
                        ],
                        2
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "回款认领日期" } },
                        [
                          _c("el-date-picker", {
                            staticClass: "w100",
                            attrs: {
                              type: "daterange",
                              "unlink-panels": "",
                              "range-separator": "至",
                              "start-placeholder": "起始时间",
                              "end-placeholder": "截止时间",
                              "value-format": "yyyy-MM-dd",
                            },
                            model: {
                              value: _vm.form.paymentCollectionTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "paymentCollectionTime", $$v)
                              },
                              expression: "form.paymentCollectionTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "flex" }, [
                    _c("div", { staticClass: "el-form-item--small" }, [
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "btn_search",
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.doSearch },
                            },
                            [_vm._v("查询")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "btn_search",
                              attrs: { size: "small" },
                              on: { click: _vm.empty },
                            },
                            [_vm._v("置空")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "flex-warp" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "跟进人" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              filterable: "",
                              clearable: "",
                              remote: "",
                              placeholder: "搜索姓名、手机号",
                              "remote-method": _vm.searchAllUser,
                              loading: _vm.searchAllUserLoading,
                            },
                            model: {
                              value: _vm.form.followUpPeople,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "followUpPeople", $$v)
                              },
                              expression: "form.followUpPeople",
                            },
                          },
                          _vm._l(_vm.allUserList, function (item) {
                            return _c("el-option", {
                              key: item.userId,
                              attrs: {
                                label: item.realName,
                                value: item.userId,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "接车单号" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入", clearable: "" },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.doSearch.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.form.billCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "billCode", $$v)
                            },
                            expression: "form.billCode",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "接车进店日期" } },
                      [
                        _c("el-date-picker", {
                          staticClass: "w100",
                          attrs: {
                            type: "daterange",
                            "unlink-panels": "",
                            "range-separator": "至",
                            "start-placeholder": "起始时间",
                            "end-placeholder": "截止时间",
                            "value-format": "yyyy-MM-dd",
                          },
                          model: {
                            value: _vm.form.intoStoreTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "intoStoreTime", $$v)
                            },
                            expression: "form.intoStoreTime",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "报案号" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入", clearable: "" },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.doSearch.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.form.caseNum,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "caseNum", $$v)
                            },
                            expression: "form.caseNum",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ],
      [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "justify-content": "space-between",
            },
          },
          [
            _c("p", [
              _c("i", {
                staticClass: "iconfont grow-icon_warn_32 orange el-icon--left",
              }),
              _c(
                "span",
                { staticStyle: { color: "#666", "font-size": "12px" } },
                [
                  _vm._v(
                    "只统计已回款状态(全部回款)的定损单，统计当前车单执行情况"
                  ),
                ]
              ),
            ]),
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 604,
                    expression: "604",
                  },
                ],
                staticClass: "blue",
                attrs: { size: "small", type: "text" },
                on: {
                  click: function ($event) {
                    _vm.showExportDialog = true
                  },
                },
              },
              [
                _vm._v("导出列表"),
                _c("i", {
                  staticClass: "iconfont grow-icon_daochu el-icon--right",
                }),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "flex h100" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "fixedScrollBar",
                    rawName: "v-fixedScrollBar",
                    value: _vm.$refs.baseContainer,
                    expression: "$refs.baseContainer",
                  },
                ],
                staticClass: "custom-table",
                attrs: { data: _vm.tableData, border: "" },
              },
              [
                _c("el-table-column", {
                  attrs: { type: "index", label: "序号", width: "80" },
                }),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "180",
                    label: "操作门店",
                    prop: "operateCompanyName",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "200",
                    label: "回款时间",
                    prop: "paymentCollectionTime",
                  },
                }),
                _c("el-table-column", {
                  attrs: { width: "180", label: "车牌号", prop: "carNumber" },
                }),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "200",
                    label: "接车单号",
                    prop: "billCode",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "blue pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.goCarBillDetail(row.billId)
                                },
                              },
                            },
                            [_vm._v(_vm._s(row.billCode))]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "180",
                    label: "报案号",
                    prop: "caseNum",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "blue pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.showDetailDrawer(
                                    row.id,
                                    row.billId
                                  )
                                },
                              },
                            },
                            [_vm._v(_vm._s(row.caseNum))]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "150",
                    label: "签约客户",
                    prop: "clientName",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "90",
                    label: "跟进人",
                    prop: "followUpPeopleName",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "90",
                    label: "超期次数",
                    prop: "overDueCount",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "110",
                    label: "被撤销次数",
                    prop: "revokeCount",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c("export-dialog", {
          attrs: {
            visibility: _vm.showExportDialog,
            total: _vm.total,
            pageOption: _vm.pageOption,
            filterForm: _vm.form,
            url: _vm.exportUrl,
          },
          on: {
            "update:visibility": function ($event) {
              _vm.showExportDialog = $event
            },
          },
        }),
        _vm.detailDrawerVisibility
          ? _c("car-loss-record-drawer", {
              attrs: {
                visibility: _vm.detailDrawerVisibility,
                carLossDeterminationBillId: this.activeId,
              },
              on: {
                "update:visibility": function ($event) {
                  _vm.detailDrawerVisibility = $event
                },
              },
            })
          : _vm._e(),
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c("pagination", {
            attrs: { total: _vm.total },
            on: { pageChange: _vm.changePage },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }