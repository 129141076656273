var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        top: "5vh",
        "append-to-body": true,
        width: "60%",
        visible: _vm.showAddCusterDialog,
        "before-close": _vm.handleClose,
        "close-on-click-modal": false,
      },
    },
    [
      _c(
        "div",
        {
          staticStyle: { height: "60vh", "overflow-y": "scroll" },
          attrs: { id: "client-content-box" },
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c("div", { staticClass: "add_more" }, [
                  _c("div", [_vm._v("基本信息")]),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": "100px",
                size: "small",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "客户简称:",
                            prop: "clientName",
                            placeholder: "请输入客户简称",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入客户简称",
                              maxlength: "20",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.clientName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "clientName", $$v)
                              },
                              expression: "form.clientName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "公司全称:" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入公司名称",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.companyFullName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "companyFullName", $$v)
                              },
                              expression: "form.companyFullName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "车辆来源:" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入车辆来源",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.vehicleSource,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "vehicleSource", $$v)
                              },
                              expression: "form.vehicleSource",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户等级:" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择", clearable: "" },
                              model: {
                                value: _vm.form.clientLevel,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "clientLevel", $$v)
                                },
                                expression: "form.clientLevel",
                              },
                            },
                            _vm._l(_vm.clientLevel, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "车队管理系统:" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入车队管理系统",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.fleetManageSystem,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "fleetManageSystem", $$v)
                              },
                              expression: "form.fleetManageSystem",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "社会信用代码:" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入信用代码",
                              maxlength: "18",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.form.idNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "idNum", $$v)
                              },
                              expression: "form.idNum",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "股东结构:" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入股东结构",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.shareholderStructure,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "shareholderStructure", $$v)
                              },
                              expression: "form.shareholderStructure",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "法人信息:" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入法人", clearable: "" },
                            model: {
                              value: _vm.form.legalPerson,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "legalPerson", $$v)
                              },
                              expression: "form.legalPerson",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "成立时间:" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "date",
                              placeholder: "请选择时间",
                              "value-format": "yyyy-MM-dd",
                            },
                            model: {
                              value: _vm.form.establishedTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "establishedTime", $$v)
                              },
                              expression: "form.establishedTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "实际运营车辆:" } },
                        [
                          _c("number-input", {
                            attrs: {
                              enabledDecimals: false,
                              placeholder: "请输入运营中车辆数",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.actualVehicle,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "actualVehicle", $$v)
                              },
                              expression: "form.actualVehicle",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "公司性质:" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入公司性质",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.companyNature,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "companyNature", $$v)
                              },
                              expression: "form.companyNature",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "发车模式:" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入发车模式",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.departureMode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "departureMode", $$v)
                              },
                              expression: "form.departureMode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "车辆规模:" } },
                        [
                          _c("number-input", {
                            attrs: {
                              enabledDecimals: false,
                              placeholder: "请输入车辆规模",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.vehicleScale,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "vehicleScale", $$v)
                              },
                              expression: "form.vehicleScale",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "kt", attrs: { label: "专属客拓" } },
                        [
                          _c("template", { slot: "label" }, [
                            _c(
                              "div",
                              { staticClass: "kt-span" },
                              [
                                _c("div", [_vm._v("专属客拓:")]),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticStyle: { "padding-right": "10px" },
                                    attrs: {
                                      effect: "light",
                                      content:
                                        "当前客户的专属客拓经理。支持用在小程序车管可直接联系该员工",
                                      placement: "bottom-start",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "iconfont grow-icon_doubt",
                                      staticStyle: { color: "#9a9a9a" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "div",
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    clearable: "",
                                    remote: "",
                                    placeholder: "搜索姓名",
                                    "remote-method": _vm.searchAllUser,
                                    loading: _vm.searchAllUserLoading,
                                  },
                                  model: {
                                    value: _vm.form.exclusiveCustomerManager,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "exclusiveCustomerManager",
                                        $$v
                                      )
                                    },
                                    expression: "form.exclusiveCustomerManager",
                                  },
                                },
                                _vm._l(_vm.allUserList, function (item) {
                                  return _c("el-option", {
                                    key: item.userId,
                                    attrs: {
                                      label: item.realName,
                                      value: item.userId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("el-form-item", { attrs: { label: "双系客户:" } }, [
                        _c(
                          "div",
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  filterable: "",
                                  clearable: "",
                                  placeholder: "请选择双系客户",
                                },
                                model: {
                                  value: _vm.form.isDualSystem,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "isDualSystem", $$v)
                                  },
                                  expression: "form.isDualSystem",
                                },
                              },
                              [
                                _c(
                                  "el-option",
                                  { attrs: { value: true, label: "是" } },
                                  [_vm._v("是")]
                                ),
                                _c(
                                  "el-option",
                                  { attrs: { value: false, label: "否" } },
                                  [_vm._v("否")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注:" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入备注",
                              maxlength: "1000",
                              "show-word-limit": "",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "remark", $$v)
                              },
                              expression: "form.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "办公地址:" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入办公地址",
                              maxlength: "100",
                              "show-word-limit": "",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.address,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "address", $$v)
                              },
                              expression: "form.address",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-form-item", { attrs: { label: "客户图片:" } }, [
                        _c("div", { staticClass: "tipsWord" }, [
                          _vm._v(
                            " (最多可上传10张图片，建议每张图片大小不超过1M) "
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "custer-imgs" },
                          [
                            _c("upload-file", {
                              attrs: {
                                title: "营业执照",
                                imagesForPreview: _vm.allPictures,
                              },
                              on: { input: _vm.handlerAllPictures },
                              model: {
                                value: _vm.form.picBusinessLicense,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "picBusinessLicense", $$v)
                                },
                                expression: "form.picBusinessLicense",
                              },
                            }),
                            _c("upload-file", {
                              attrs: {
                                title: "开户许可证",
                                imagesForPreview: _vm.allPictures,
                              },
                              on: { input: _vm.handlerAllPictures },
                              model: {
                                value: _vm.form.picLicenceOpeningAccounts,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "picLicenceOpeningAccounts",
                                    $$v
                                  )
                                },
                                expression: "form.picLicenceOpeningAccounts",
                              },
                            }),
                            _c("upload-file", {
                              attrs: {
                                title: "其他证件",
                                imagesForPreview: _vm.allPictures,
                              },
                              on: { input: _vm.handlerAllPictures },
                              model: {
                                value: _vm.form.picCertificateOther,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "picCertificateOther", $$v)
                                },
                                expression: "form.picCertificateOther",
                              },
                            }),
                            _c("upload-file-list", {
                              attrs: {
                                max: 7,
                                imagesForPreview: _vm.allPictures,
                              },
                              on: { input: _vm.handlerAllPictures },
                              model: {
                                value: _vm.form.picOther,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "picOther", $$v)
                                },
                                expression: "form.picOther",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c("div", { staticClass: "add_more" }, [
                      _c("div", [_vm._v("其他联系人")]),
                      _c(
                        "div",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.addMore()
                            },
                          },
                        },
                        [_vm._v("新增其他联系人")]
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._l(_vm.otherContacts, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "add_lies" },
                  [
                    _c("div", { staticStyle: { width: "5%" } }, [
                      _c("i", {
                        staticClass: "iconfont grow-icon_delete3 orange",
                        staticStyle: {
                          position: "absolute",
                          top: "40%",
                          "font-size": "22px",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.deleteContacts(index, item.contactsId)
                          },
                        },
                      }),
                    ]),
                    _c(
                      "el-form",
                      {
                        staticClass: "other-form",
                        attrs: {
                          model: item,
                          "label-width": "100px",
                          size: "small",
                        },
                      },
                      [
                        _c(
                          "el-row",
                          { attrs: { gutter: 24 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "岗位：" } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          placeholder: "请选择岗位",
                                          clearable: "",
                                        },
                                        model: {
                                          value: item.position,
                                          callback: function ($$v) {
                                            _vm.$set(item, "position", $$v)
                                          },
                                          expression: "item.position",
                                        },
                                      },
                                      _vm._l(_vm.position, function (item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "联系人姓名：" } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "请输入姓名",
                                        maxlength: "20",
                                      },
                                      model: {
                                        value: item.contactsName,
                                        callback: function ($$v) {
                                          _vm.$set(item, "contactsName", $$v)
                                        },
                                        expression: "item.contactsName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "联系方式：" } },
                                  [
                                    _c("number-input", {
                                      attrs: {
                                        placeholder: "请输入联系方式",
                                        maxlength: 11,
                                        clearable: "",
                                      },
                                      model: {
                                        value: item.contactsPhone,
                                        callback: function ($$v) {
                                          _vm.$set(item, "contactsPhone", $$v)
                                        },
                                        expression: "item.contactsPhone",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          { attrs: { gutter: 24 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "备注：" } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "请填写联系人备注信息",
                                        maxlength: "100",
                                        "show-word-limit": "",
                                        clearable: "",
                                      },
                                      model: {
                                        value: item.remark,
                                        callback: function ($$v) {
                                          _vm.$set(item, "remark", $$v)
                                        },
                                        expression: "item.remark",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c("div", { staticClass: "add_more" }, [
                      _c("div", [_vm._v("业务信息")]),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.clientBusinessMessageDto,
                    "label-width": "115px",
                    size: "small",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "合作模式:" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入", clearable: "" },
                                model: {
                                  value:
                                    _vm.clientBusinessMessageDto
                                      .cooperationModel,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.clientBusinessMessageDto,
                                      "cooperationModel",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "clientBusinessMessageDto.cooperationModel",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "合作时间:" } },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "date",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "请选择日期",
                                },
                                model: {
                                  value:
                                    _vm.clientBusinessMessageDto
                                      .cooperationTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.clientBusinessMessageDto,
                                      "cooperationTime",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "clientBusinessMessageDto.cooperationTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "客户来源:" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择",
                                    clearable: "",
                                  },
                                  model: {
                                    value:
                                      _vm.clientBusinessMessageDto
                                        .customerSource,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.clientBusinessMessageDto,
                                        "customerSource",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "clientBusinessMessageDto.customerSource",
                                  },
                                },
                                _vm._l(_vm.customerSource, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "自费维保:" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入", clearable: "" },
                                model: {
                                  value:
                                    _vm.clientBusinessMessageDto
                                      .selfPayingMaintenance,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.clientBusinessMessageDto,
                                      "selfPayingMaintenance",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "clientBusinessMessageDto.selfPayingMaintenance",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "事故维修:" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入", clearable: "" },
                                model: {
                                  value:
                                    _vm.clientBusinessMessageDto
                                      .accidentMaintenance,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.clientBusinessMessageDto,
                                      "accidentMaintenance",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "clientBusinessMessageDto.accidentMaintenance",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "退还车/整备:" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入", clearable: "" },
                                model: {
                                  value: _vm.clientBusinessMessageDto.returnCar,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.clientBusinessMessageDto,
                                      "returnCar",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "clientBusinessMessageDto.returnCar",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "车辆保险:" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入", clearable: "" },
                                model: {
                                  value:
                                    _vm.clientBusinessMessageDto.carInsurance,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.clientBusinessMessageDto,
                                      "carInsurance",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "clientBusinessMessageDto.carInsurance",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "强收车:" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入", clearable: "" },
                                model: {
                                  value:
                                    _vm.clientBusinessMessageDto
                                      .forcedRetracting,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.clientBusinessMessageDto,
                                      "forcedRetracting",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "clientBusinessMessageDto.forcedRetracting",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "停车场:" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入", clearable: "" },
                                model: {
                                  value:
                                    _vm.clientBusinessMessageDto.parkingLot,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.clientBusinessMessageDto,
                                      "parkingLot",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "clientBusinessMessageDto.parkingLot",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "车务代办:" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入", clearable: "" },
                                model: {
                                  value:
                                    _vm.clientBusinessMessageDto.vehicleAgent,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.clientBusinessMessageDto,
                                      "vehicleAgent",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "clientBusinessMessageDto.vehicleAgent",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "保费分期:" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入", clearable: "" },
                                model: {
                                  value:
                                    _vm.clientBusinessMessageDto
                                      .premiumInstalment,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.clientBusinessMessageDto,
                                      "premiumInstalment",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "clientBusinessMessageDto.premiumInstalment",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "车辆代发:" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入", clearable: "" },
                                model: {
                                  value:
                                    _vm.clientBusinessMessageDto.carDispatch,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.clientBusinessMessageDto,
                                      "carDispatch",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "clientBusinessMessageDto.carDispatch",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "车管报表:" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入", clearable: "" },
                                model: {
                                  value: _vm.clientBusinessMessageDto.carReport,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.clientBusinessMessageDto,
                                      "carReport",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "clientBusinessMessageDto.carReport",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "金融服务:" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入", clearable: "" },
                                model: {
                                  value:
                                    _vm.clientBusinessMessageDto
                                      .financialServices,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.clientBusinessMessageDto,
                                      "financialServices",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "clientBusinessMessageDto.financialServices",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "新车采购:" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入", clearable: "" },
                                model: {
                                  value:
                                    _vm.clientBusinessMessageDto.newCarPurchase,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.clientBusinessMessageDto,
                                      "newCarPurchase",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "clientBusinessMessageDto.newCarPurchase",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "二手车销售:" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入", clearable: "" },
                                model: {
                                  value:
                                    _vm.clientBusinessMessageDto
                                      .secondHandCarSales,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.clientBusinessMessageDto,
                                      "secondHandCarSales",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "clientBusinessMessageDto.secondHandCarSales",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "电池回收:" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入", clearable: "" },
                                model: {
                                  value:
                                    _vm.clientBusinessMessageDto
                                      .batteryRecycling,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.clientBusinessMessageDto,
                                      "batteryRecycling",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "clientBusinessMessageDto.batteryRecycling",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c("div", { staticClass: "add_more" }, [
                          _c("div", [_vm._v("事故信息")]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c("template", { slot: "label" }, [
                                _c(
                                  "div",
                                  { staticClass: "flex flex-x-center" },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "light",
                                          content:
                                            "事故信息保险公司推送接收人号码",
                                          placement: "bottom-start",
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "iconfont grow-icon_doubt",
                                          staticStyle: { color: "#9a9a9a" },
                                        }),
                                      ]
                                    ),
                                    _c("span", [_vm._v(" 信息接收人")]),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-input", {
                                attrs: {
                                  placeholder: "输入信息接收人及号码",
                                  clearable: "",
                                },
                                model: {
                                  value:
                                    _vm.clientBusinessMessageDto
                                      .messageReceivingNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.clientBusinessMessageDto,
                                      "messageReceivingNumber",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "clientBusinessMessageDto.messageReceivingNumber",
                                },
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c("template", { slot: "label" }, [
                                _c(
                                  "div",
                                  { staticClass: "flex flex-x-center" },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "light",
                                          content:
                                            "事故消息保险公司推送格悦的方式",
                                          placement: "bottom-start",
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "iconfont grow-icon_doubt",
                                          staticStyle: { color: "#9a9a9a" },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" 保司推送方式 "),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-input", {
                                attrs: {
                                  placeholder: "输入保险公司推送方式",
                                  clearable: "",
                                },
                                model: {
                                  value:
                                    _vm.clientBusinessMessageDto
                                      .insuranceCompanyPush,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.clientBusinessMessageDto,
                                      "insuranceCompanyPush",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "clientBusinessMessageDto.insuranceCompanyPush",
                                },
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c("template", { slot: "label" }, [
                                _c(
                                  "div",
                                  { staticClass: "flex flex-x-center" },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "light",
                                          content: "事故信息车队推送格悦的方式",
                                          placement: "bottom-start",
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "iconfont grow-icon_doubt",
                                          staticStyle: { color: "#9a9a9a" },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" 车队推送方式 "),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-input", {
                                attrs: {
                                  placeholder: "输入车队推送方式",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.clientBusinessMessageDto.fleetPush,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.clientBusinessMessageDto,
                                      "fleetPush",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "clientBusinessMessageDto.fleetPush",
                                },
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    {
                      staticStyle: { "margin-top": "36px" },
                      attrs: { gutter: 24 },
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c("template", { slot: "label" }, [
                                _c(
                                  "div",
                                  { staticClass: "flex flex-x-center" },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "light",
                                          content:
                                            "与司机合同约定有责事故联系方式",
                                          placement: "bottom-start",
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "iconfont grow-icon_doubt",
                                          staticStyle: { color: "#9a9a9a" },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" 有责事故约定 "),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-input", {
                                attrs: {
                                  placeholder: "输入司机合同约定",
                                  clearable: "",
                                },
                                model: {
                                  value:
                                    _vm.clientBusinessMessageDto
                                      .responsibleAccidentAgreement,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.clientBusinessMessageDto,
                                      "responsibleAccidentAgreement",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "\n                  clientBusinessMessageDto.responsibleAccidentAgreement\n                ",
                                },
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c("template", { slot: "label" }, [
                                _c(
                                  "div",
                                  { staticClass: "flex flex-x-center" },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "light",
                                          content:
                                            "与司机合同约定无责事故联系方式",
                                          placement: "bottom-start",
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "iconfont grow-icon_doubt",
                                          staticStyle: { color: "#9a9a9a" },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" 无责事故约定 "),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-input", {
                                attrs: {
                                  placeholder: "输入司机合同约定",
                                  clearable: "",
                                },
                                model: {
                                  value:
                                    _vm.clientBusinessMessageDto
                                      .noResponsibleAccidentAgreement,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.clientBusinessMessageDto,
                                      "noResponsibleAccidentAgreement",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "\n                  clientBusinessMessageDto.noResponsibleAccidentAgreement\n                ",
                                },
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.saveList },
              },
              [_vm._v("确定保存")]
            ),
            _c(
              "el-button",
              { attrs: { size: "small" }, on: { click: _vm.handleClose } },
              [_vm._v("取 消")]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }