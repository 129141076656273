<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <div>
        <el-form
          class="filter-form"
          :model="form"
          label-width="100px"
          size="small"
        >
          <div class="flex-x-between">
            <div class="flex-warp flex-shrink0">
              <el-form-item label="车牌号">
                <el-input
                  v-model="form.carNumber"
                  placeholder="请输入"
                  clearable
                  @keyup.enter.native="doSearch"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <template slot="label">
                  <div>
                    操作门店
                    <el-tooltip
                      effect="light"
                      content="定损单的操作门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)"
                      placement="bottom-start"
                    >
                      <i
                        class="iconfont grow-icon_doubt"
                        style="color: #9a9a9a"
                      ></i>
                    </el-tooltip>
                  </div>
                </template>
                <operateCompanyCascader
                  v-model="form.operateCompanyId"
                  :operateCompanyUrl="form.operateCompanyUrl"
                ></operateCompanyCascader>
              </el-form-item>
              <el-form-item label="回款认领日期">
                <el-date-picker
                  class="w100"
                  v-model="form.paymentCollectionTime"
                  type="daterange"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="起始时间"
                  end-placeholder="截止时间"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </div>
            <div class="flex">
              <div class="el-form-item--small">
                <!-- <div
                  @click="showMore = !showMore"
                  class="el-form-item__label pointer"
                >
                  更多筛选
                  <i
                    :class="{
                      primary: true,
                      'el-icon-arrow-right': !showMore,
                      'el-icon-arrow-down': showMore,
                    }"
                  ></i>
                </div> -->
                <div class="flex">
                  <el-button
                    class="btn_search"
                    type="primary"
                    size="small"
                    @click="doSearch"
                    >查询</el-button
                  >
                  <el-button class="btn_search" size="small" @click="empty"
                    >置空</el-button
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="flex-warp">
            <el-form-item label="跟进人">
              <el-select
                v-model="form.followUpPeople"
                filterable
                clearable
                remote
                placeholder="搜索姓名、手机号"
                :remote-method="searchAllUser"
                :loading="searchAllUserLoading"
              >
                <el-option
                  v-for="item in allUserList"
                  :key="item.userId"
                  :label="item.realName"
                  :value="item.userId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="接车单号">
              <el-input
                v-model="form.billCode"
                placeholder="请输入"
                clearable
                @keyup.enter.native="doSearch"
              ></el-input>
            </el-form-item>

            <el-form-item label="接车进店日期">
              <el-date-picker
                class="w100"
                v-model="form.intoStoreTime"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="截止时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>

            <el-form-item label="报案号">
              <el-input
                v-model="form.caseNum"
                placeholder="请输入"
                clearable
                @keyup.enter.native="doSearch"
              ></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </template>
    <!-- 以下是表格 -->
    <template>
      <div style="display: flex; justify-content: space-between">
        <p>
          <i class="iconfont grow-icon_warn_32 orange el-icon--left"></i>
          <span style="color: #666; font-size: 12px"
            >只统计已回款状态(全部回款)的定损单，统计当前车单执行情况</span
          >
        </p>
        <el-button
          v-auth="604"
          size="small"
          type="text"
          class="blue"
          @click="showExportDialog = true"
          >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
        ></el-button>
      </div>
      <div class="flex h100">
        <el-table
          class="custom-table"
          :data="tableData"
          border
          v-fixedScrollBar="$refs.baseContainer"
        >
          <el-table-column type="index" label="序号" width="80">
          </el-table-column>
          <el-table-column
            min-width="180"
            label="操作门店"
            prop="operateCompanyName"
          >
          </el-table-column>
          <el-table-column
            width="200"
            label="回款时间"
            prop="paymentCollectionTime"
          >
          </el-table-column>
          <el-table-column width="180" label="车牌号" prop="carNumber">
          </el-table-column>
          <el-table-column min-width="200" label="接车单号" prop="billCode">
            <template #default="{ row }">
              <span class="blue pointer" @click="goCarBillDetail(row.billId)">{{
                row.billCode
              }}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="180" label="报案号" prop="caseNum">
            <template #default="{ row }">
              <span
                class="blue pointer"
                @click="showDetailDrawer(row.id, row.billId)"
                >{{ row.caseNum }}</span
              >
            </template>
          </el-table-column>
          <el-table-column width="150" label="签约客户" prop="clientName">
          </el-table-column>
          <el-table-column width="90" label="跟进人" prop="followUpPeopleName">
          </el-table-column>
          <el-table-column width="90" label="超期次数" prop="overDueCount">
          </el-table-column>
          <el-table-column width="110" label="被撤销次数" prop="revokeCount">
          </el-table-column>
        </el-table>
      </div>
      <export-dialog
        :visibility.sync="showExportDialog"
        :total="total"
        :pageOption="pageOption"
        :filterForm="form"
        :url="exportUrl"
      ></export-dialog>

      <!-- 定损单详情抽屉 -->
      <car-loss-record-drawer
        v-if="detailDrawerVisibility"
        :visibility.sync="detailDrawerVisibility"
        :carLossDeterminationBillId="this.activeId"
      >
      </car-loss-record-drawer>
    </template>
    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
  </base-container>
</template>
<script>
// 组件
import pagination from '@/components/Pagination.vue';
import ExportDialog from '@/components/ExportDialog';
import operateCompanyCascader from '@/components/businessComponent/operateCompanyCascader.vue';
import carLossRecordDrawer from '@/views/carBill/carLossDeterminationBill/carLossDeterminationBillDetailDrawer.vue';

// 模糊搜索接口
import { getAllUser } from '@/api/employee';

// 列表接口
// import { exportCarLossDeterminationBillListUrl } from '@/api/financial/pickSingle';
import {
  carLossDeterminationExecutionReport,
  carLossDeterminationExecutionReportExport,
} from '@/api/carBill/carLossDeterminationBill';

// 工具
import Big from 'big.js';
import { mapState } from 'vuex';

export default {
  name: 'lossAssessmentExecutionReport',
  components: {
    pagination,
    ExportDialog,
    operateCompanyCascader,
    carLossRecordDrawer,
  },
  data() {
    return {
      form: {},
      // 展示更多筛选
      showMore: false,
      // 导出设置
      exportUrl: carLossDeterminationExecutionReportExport,
      showExportDialog: false,

      //分页组件
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },

      // 列表数据
      tableData: [],

      searchAllUserLoading: false,
      allUserList: [],

      activeId: '',
      billId: '',
      detailDrawerVisibility: false,
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  watch: {},
  activated() {},
  created() {
    this.form.operateCompanyId = [this.companyId];
    this.form.operateCompanyUrl = [this.companyId];

    // var yesterday = new Date();
    // yesterday.setDate(yesterday.getDate() - 1);
    this.$set(this.form, 'paymentCollectionTime', [
      new Date().Format('yyyy-MM-dd'),
      new Date().Format('yyyy-MM-dd'),
    ]);

    this.getData();
    this.searchAllUser();
  },
  mounted() {},
  methods: {
    /**
     * 搜索跟进人列表
     */
    searchAllUser(msg) {
      this.searchAllUserLoading = true;
      getAllUser({ msg })
        .then((res) => {
          this.allUserList = res.records || [];
        })
        .finally(() => {
          this.searchAllUserLoading = false;
        });
    },

    /**
     * 跳转详情页
     */
    goCarBillDetail(id) {
      this.$router.push({
        name: 'carBillDetail',
        query: {
          id,
        },
      });
    },

    /**
     * 打开定损单抽屉
     */
    showDetailDrawer(id, billId) {
      this.activeId = id;
      this.billId = billId;
      this.detailDrawerVisibility = true;
    },

    /**
     * 清空查询条件
     */
    empty() {
      this.form = {};
    },

    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getData(false);
    },

    /**
     * 点击查询
     */
    doSearch() {
      this.getData();
    },

    /**
     * 获取数据
     */
    getData(reset = true) {
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      var allTimes = {
        intoStoreStartTime: this.form.intoStoreTime?.[0]
          ? this.form.intoStoreTime?.[0]
          : '',
        intoStoreEndTime: this.form.intoStoreTime?.[1]
          ? this.form.intoStoreTime?.[1]
          : '',
        paymentCollectionStartTime: this.form.paymentCollectionTime?.[0]
          ? this.form.paymentCollectionTime?.[0]
          : '',
        paymentCollectionEndTime: this.form.paymentCollectionTime?.[1]
          ? this.form.paymentCollectionTime?.[1]
          : '',
      };
      var data = Object.assign(this.form, allTimes, this.pageOption);
      carLossDeterminationExecutionReport(data).then((res) => {
        this.total = res.total;
        this.tableData = res.records;
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
