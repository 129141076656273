<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <el-form
        class="filter-form"
        :model="form"
        label-width="100px"
        size="small"
      >
        <div class="flex-x-between">
          <div class="flex flex-shrink0">
            <el-form-item>
              <template slot="label">
                <div>
                  操作门店
                  <el-tooltip
                    effect="light"
                    content="接车单的操作门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)"
                    placement="bottom-start"
                  >
                    <i
                      class="iconfont grow-icon_doubt"
                      style="color: #9a9a9a"
                    ></i>
                  </el-tooltip>
                </div>
              </template>
              <operateCompanyCascader
                v-model="form.companyIds"
                :operateCompanyUrl="form.operateCompanyUrl"
              ></operateCompanyCascader>
            </el-form-item>
            <el-form-item label="供应商">
              <el-select
                v-model="form.supplierId"
                filterable
                clearable
                remote
                :remote-method="searchSupplierList"
                :loading="searchSupplierListLoading"
                placeholder="请选择供应商"
              >
                <el-option
                  v-for="(item, index) in supplierList"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="单据号">
              <el-input
                v-model="form.inventoryInAndOutBillCode"
                placeholder="请输入单据号"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
          </div>
          <div class="el-form-item--small">
            <div class="flex">
              <el-button
                v-auth="629"
                class="btn_search"
                type="primary"
                size="small"
                @click="getData"
                >查询</el-button
              >
              <el-button class="btn_search" size="small" @click="empty"
                >置空</el-button
              >
            </div>
          </div>
        </div>
        <div class="flex-warp">
          <template>
            <el-form-item label="商品标准名">
              <el-input
                v-model="form.goodsStandardName"
                placeholder="请输入商品标准名"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
            <el-form-item label="创建时间">
              <el-date-picker
                class="w100"
                v-model="form.dateCreateds"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="截止时间"
                value-format="yyyy-MM-dd"
                @input="dateRangeChange()"
              >
              </el-date-picker>
            </el-form-item>
          </template>
        </div>
      </el-form>
    </template>

    <template>
      <div style="margin: 30px 0 14px 0" class="btns flex-x-between">
        <div></div>
        <el-button
          v-auth="713"
          size="small"
          type="text"
          class="blue"
          @click="showExportDialog = true"
          >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
        ></el-button>
      </div>
      <el-table
        class="custom-table"
        :data="listData"
        border
        v-fixedScrollBar="$refs.baseContainer"
      >
        <el-table-column type="index" label="序号" width="60" align="center">
        </el-table-column>
        <el-table-column width="200" label="操作门店" prop="companyName">
        </el-table-column>
        <el-table-column width="150" label="单据号" prop="purchaseBillCode">
        </el-table-column>
        <el-table-column
          width="100"
          label="单据类型"
          prop="purchaseBillTypeDesc"
        >
        </el-table-column>
        <el-table-column width="150" label="供应商名称" prop="supplierName">
        </el-table-column>
        <el-table-column width="150" label="供货单号" prop="supplyBillCode">
        </el-table-column>
        <el-table-column width="200" label="创建时间" prop="dateCreated">
        </el-table-column>
        <el-table-column width="100" label="商品ID" prop="goodsId">
        </el-table-column>
        <el-table-column
          width="200"
          label="商品标准名"
          prop="goodsStandardName"
        >
        </el-table-column>
        <el-table-column width="100" label="品质" prop="manufactoryTypeDesc">
        </el-table-column>
        <el-table-column width="150" label="原厂编号" prop="goodsCode">
        </el-table-column>
        <el-table-column width="100" label="单位" prop="unit">
        </el-table-column>
        <el-table-column width="100" label="当前采购价" prop="purchasePrice">
        </el-table-column>
        <el-table-column width="100" label="数量" prop="purchaseNum">
        </el-table-column>
        <el-table-column width="100" label="金额合计" prop="totalAmount">
        </el-table-column>
        <el-table-column
          width="100"
          label="采购上限价"
          prop="purchaseCeilingPrice"
        >
        </el-table-column>
        <el-table-column
          width="100"
          label="采购价备注"
          prop="purchasePriceRemark"
        >
        </el-table-column>
      </el-table>
      <export-dialog
        :visibility.sync="showExportDialog"
        :total="total"
        :pageOption="pageOption"
        :filterForm="exportForm"
        :url="exportUrl"
      ></export-dialog>
    </template>
    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
  </base-container>
</template>

<script>
// 组件
import pagination from '@/components/Pagination.vue';
import ExportDialog from '@/components/ExportDialog';
import operateCompanyCascader from '@/components/businessComponent/operateCompanyCascader';
// 接口
import { supplierSearch } from '@/api/stock/inventoryReplenishment/index';
import {
  purchaseReportByGoods,
  purchaseReportByGoodsExportUrl,
} from '@/api/stock/inventory';
import { hasAuth } from '@/utils/permissions';

import { mapState } from 'vuex';

export default {
  name: 'purchaseReportByGoods',
  components: {
    pagination,
    ExportDialog,
    operateCompanyCascader,
  },
  data() {
    return {
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      showExportDialog: false,
      exportUrl: purchaseReportByGoodsExportUrl,
      supplierList: [],
      searchSupplierListLoading: false,
      searchSupplierTimer: undefined,
      form: {},
      exportForm: {},
      listData: [],
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.user,
      companyList: (state) => state.user.companyList,
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  activated() {},
  created() {
    this.init();
  },
  methods: {
    init() {
      this.empty();
      this.getData();
    },
    //下面是解决设置日期默认值时重新选择无法刷新日期的方法
    dateRangeChange() {
      let _this = this;
      _this.$nextTick(() => {
        _this.$forceUpdate();
      });
    },
    searchSupplierList(keyWord) {
      const _this = this;
      _this.searchSupplierTimer != undefined &&
        window.clearTimeout(_this.searchSupplierTimer);

      _this.searchSupplierTimer = window.setTimeout(function () {
        _this.searchSupplierListLoading = true;
        supplierSearch({ key: keyWord || '' }, { noShowWaiting: true })
          .then((res) => {
            _this.supplierList = res || [];
          })
          .finally(() => {
            _this.searchSupplierListLoading = false;
          });
      }, 800);
    },
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getData(false);
    },
    /**
     * 加载数据
     */
    getData(reset = true) {
      var res = hasAuth(712);
      if (!res) {
        return;
      }
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      const times = {
        startTime: this.form.dateCreateds?.[0]
          ? this.form.dateCreateds?.[0] + ' 00:00:00'
          : '',
        endTime: this.form.dateCreateds?.[1]
          ? this.form.dateCreateds?.[1] + ' 23:59:59'
          : '',
      };
      var data = Object.assign({}, this.form, this.pageOption, times);
      this.exportForm = data;
      purchaseReportByGoods(data).then((res) => {
        // 修改总条数
        this.total = res.total;
        // 赋值records
        this.listData = res.records;
      });
    },
    /**
     * 置空输入框数据
     */
    empty() {
      this.form = {
        companyIds: [this.companyId],
      };
      this.exportForm = [];
      this.exportForm.companyIds = [this.companyId];
    },
  },
};
</script>
<style lang="scss" scoped>
.el-form {
  width: 100%;
}
</style>
