var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { ref: "baseContainer", attrs: { paddingTop: "" } },
    [
      [
        _c(
          "div",
          { staticClass: "form-top" },
          [
            _c(
              "el-form",
              {
                attrs: {
                  model: _vm.form,
                  "label-width": "100px",
                  size: "small",
                },
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          [
                            _c("template", { slot: "label" }, [
                              _c(
                                "div",
                                [
                                  _vm._v(" 操作门店 "),
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        content:
                                          "接车单的操作门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)",
                                        effect: "light",
                                        placement: "bottom-start",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "iconfont grow-icon_doubt",
                                        staticStyle: { color: "#9a9a9a" },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("operateCompanyCascader", {
                              model: {
                                value: _vm.form.operateCompanyId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "operateCompanyId", $$v)
                                },
                                expression: "form.operateCompanyId",
                              },
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "待收金额" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择" },
                                model: {
                                  value: _vm.form.unclearedDebt,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "unclearedDebt", $$v)
                                  },
                                  expression: "form.unclearedDebt",
                                },
                              },
                              _vm._l(_vm.unclearedDebt, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "挂账日期" } },
                          [
                            _c("el-date-picker", {
                              staticClass: "w100",
                              attrs: {
                                clearable: false,
                                "end-placeholder": "截止时间",
                                "range-separator": "至",
                                "start-placeholder": "起始时间",
                                type: "daterange",
                                "unlink-panels": "",
                                "value-format": "yyyy-MM-dd",
                              },
                              model: {
                                value: _vm.form.times,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "times", $$v)
                                },
                                expression: "form.times",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("el-col", { attrs: { span: 6 } }, [
                      _c(
                        "div",
                        {
                          staticClass: "el-form-item--small",
                          staticStyle: { "margin-left": "20%" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "el-form-item__label pointer",
                              on: {
                                click: function ($event) {
                                  _vm.showMore = !_vm.showMore
                                },
                              },
                            },
                            [
                              _vm._v(" 更多筛选 "),
                              _c("i", {
                                class: {
                                  primary: true,
                                  "el-icon-arrow-right": !_vm.showMore,
                                  "el-icon-arrow-down": _vm.showMore,
                                },
                              }),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "flex" },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "btn_search",
                                  attrs: { size: "small", type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getList()
                                    },
                                  },
                                },
                                [_vm._v("查询")]
                              ),
                              _c(
                                "el-button",
                                {
                                  staticClass: "btn_search",
                                  attrs: { size: "small" },
                                  on: { click: _vm.empty },
                                },
                                [_vm._v("置空")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "消费客户" } }, [
                          _c(
                            "div",
                            { staticClass: "last-type-sty" },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择",
                                  },
                                  model: {
                                    value: _vm.form.consumerType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "consumerType", $$v)
                                    },
                                    expression: "form.consumerType",
                                  },
                                },
                                _vm._l(_vm.customerList, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                              _c("el-input", {
                                attrs: {
                                  clearable: "",
                                  placeholder: "请输入客户名称",
                                },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.getList.apply(null, arguments)
                                  },
                                },
                                model: {
                                  value: _vm.form.clientId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "clientId", $$v)
                                  },
                                  expression: "form.clientId",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          [
                            _c("template", { slot: "label" }, [
                              _c(
                                "div",
                                { staticClass: "flex flex-x-center" },
                                [
                                  _vm._v(" 开票主体 "),
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        effect: "light",
                                        placement: "bottom-start",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            color: "#666666",
                                            "font-size": "13px",
                                            "line-height": "20px",
                                            "text-indent": "15px",
                                          },
                                          attrs: { slot: "content" },
                                          slot: "content",
                                        },
                                        [
                                          _vm._v(
                                            " 开票主体，即为协议客户、保险公司和三包厂家的应收账款 "
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            " 的回款管理门店(保险公司的开票主体也是定损主体)。保险公司 "
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            " 管理的开票主体，可查已成为保险公司开票 主体的全部门店。 "
                                          ),
                                        ]
                                      ),
                                      _c("i", {
                                        staticClass: "iconfont grow-icon_doubt",
                                        staticStyle: { color: "#9a9a9a" },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  loading: _vm.searchinvoiceLoading,
                                  "remote-method": _vm.searchInvoice,
                                  clearable: "",
                                  filterable: "",
                                  placeholder: "搜索开票主体名称",
                                  remote: "",
                                },
                                on: {
                                  change: _vm.selectInvoice,
                                  clear: _vm.deleteInvoice,
                                },
                                model: {
                                  value: _vm.form.invoiceCompanyId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "invoiceCompanyId", $$v)
                                  },
                                  expression: "form.invoiceCompanyId",
                                },
                              },
                              _vm._l(_vm.invoiceList, function (item) {
                                return _c("el-option", {
                                  key: item.companyId,
                                  attrs: {
                                    label: item.invoicingEntity,
                                    value: item.companyId,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm.showMore ? _c("el-divider") : _vm._e(),
        _c("transition", { attrs: { name: "fade" } }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showMore,
                  expression: "showMore",
                },
              ],
            },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    model: _vm.form,
                    "label-width": "100px",
                    size: "small",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "车牌号" } },
                            [
                              _c("el-input", {
                                attrs: { clearable: "", placeholder: "请输入" },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.getList.apply(null, arguments)
                                  },
                                },
                                model: {
                                  value: _vm.form.plateNumber,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "plateNumber", $$v)
                                  },
                                  expression: "form.plateNumber",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "接车单号" } },
                            [
                              _c("el-input", {
                                attrs: { clearable: "", placeholder: "请输入" },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.getList.apply(null, arguments)
                                  },
                                },
                                model: {
                                  value: _vm.form.billCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "billCode", $$v)
                                  },
                                  expression: "form.billCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "接车进店日期" } },
                            [
                              _c("el-date-picker", {
                                staticClass: "w100",
                                attrs: {
                                  "end-placeholder": "截止时间",
                                  "range-separator": "至",
                                  "start-placeholder": "起始时间",
                                  type: "daterange",
                                  "unlink-panels": "",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                },
                                model: {
                                  value: _vm.form.intoTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "intoTime", $$v)
                                  },
                                  expression: "form.intoTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "事故责任" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    filterable: "",
                                    placeholder: "搜索事故责任",
                                  },
                                  model: {
                                    value: _vm.form.dutyType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "dutyType", $$v)
                                    },
                                    expression: "form.dutyType",
                                  },
                                },
                                _vm._l(_vm.dutyTypeList, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "结账收款备注" } },
                            [
                              _c("el-input", {
                                attrs: { clearable: "", placeholder: "请输入" },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.getList.apply(null, arguments)
                                  },
                                },
                                model: {
                                  value: _vm.form.remark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "remark", $$v)
                                  },
                                  expression: "form.remark",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "开票单据号" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  clearable: "",
                                  placeholder: "请输入开发票的单据号",
                                },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.getList.apply(null, arguments)
                                  },
                                },
                                model: {
                                  value: _vm.form.invoiceNum,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "invoiceNum", $$v)
                                  },
                                  expression: "form.invoiceNum",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "业务来源" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    loading: _vm.searchBillSourceLoading,
                                    "remote-method": _vm.searchBillSource,
                                    clearable: "",
                                    filterable: "",
                                    placeholder: "搜索业务来源",
                                    remote: "",
                                  },
                                  on: {
                                    change: _vm.selectBillSource,
                                    clear: _vm.deleteBillSource,
                                  },
                                  model: {
                                    value: _vm.form.sourceId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "sourceId", $$v)
                                    },
                                    expression: "form.sourceId",
                                  },
                                },
                                _vm._l(_vm.billSourceList, function (item) {
                                  return _c("el-option", {
                                    key: item.billSourceTypeId,
                                    attrs: {
                                      label: item.name,
                                      value: item.billSourceTypeId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ],
      [
        _c("div", { staticClass: "content-container" }, [
          _c("div", { staticClass: "btns flex-x-between" }, [
            _c("div", [
              _c("div", { staticClass: "custom-tabs" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "auth",
                        rawName: "v-auth",
                        value: 162,
                        expression: "162",
                      },
                    ],
                    staticClass: "collect-btn pointer",
                    on: { click: _vm.collection },
                  },
                  [
                    _vm._v(" 收款 "),
                    _vm.selectedData.length > 0
                      ? _c("span", [
                          _vm._v("（" + _vm._s(_vm.selectedData.length) + "）"),
                        ])
                      : _vm._e(),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: {
                      "custom-tab-item": true,
                      active: _vm.status == -1,
                    },
                    on: {
                      click: function ($event) {
                        _vm.status = -1
                      },
                    },
                  },
                  [_vm._v(" 全部 ")]
                ),
                _c(
                  "div",
                  {
                    class: {
                      "custom-tab-item": true,
                      active: _vm.status == 0,
                    },
                    on: {
                      click: function ($event) {
                        _vm.status = 0
                      },
                    },
                  },
                  [_vm._v(" 自费业务 ")]
                ),
                _c(
                  "div",
                  {
                    class: {
                      "custom-tab-item": true,
                      active: _vm.status == 1,
                    },
                    on: {
                      click: function ($event) {
                        _vm.status = 1
                      },
                    },
                  },
                  [_vm._v(" 包干业务 ")]
                ),
                _c(
                  "div",
                  {
                    class: {
                      "custom-tab-item": true,
                      active: _vm.status == 2,
                    },
                    on: {
                      click: function ($event) {
                        _vm.status = 2
                      },
                    },
                  },
                  [_vm._v(" 理赔业务 ")]
                ),
                _c(
                  "div",
                  {
                    class: {
                      "custom-tab-item": true,
                      active: _vm.status == 3,
                    },
                    on: {
                      click: function ($event) {
                        _vm.status = 3
                      },
                    },
                  },
                  [_vm._v(" 索赔业务 ")]
                ),
              ]),
            ]),
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "auth",
                        rawName: "v-auth",
                        value: 707,
                        expression: "707",
                      },
                    ],
                    staticClass: "blue",
                    staticStyle: { "margin-left": "10px" },
                    attrs: { size: "small", type: "text" },
                    on: {
                      click: function ($event) {
                        _vm.importDialogVisibility = true
                      },
                    },
                  },
                  [
                    _vm._v(" 导入核销 "),
                    _c("i", {
                      staticClass: "iconfont grow-icon_daoru el-icon--right",
                    }),
                  ]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "auth",
                        rawName: "v-auth",
                        value: 332,
                        expression: "332",
                      },
                    ],
                    staticClass: "blue",
                    staticStyle: { "margin-left": "10px" },
                    attrs: { size: "small", type: "text" },
                    on: {
                      click: function ($event) {
                        _vm.showExportDialog = true
                      },
                    },
                  },
                  [
                    _vm._v(" 导出列表 "),
                    _c("i", {
                      staticClass: "iconfont grow-icon_daochu el-icon--right",
                    }),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "flex",
              staticStyle: { flex: "1", overflow: "auto" },
            },
            [
              _c(
                "div",
                { staticStyle: { width: "85%", flex: "1", overflow: "auto" } },
                [
                  _c(
                    "el-table",
                    {
                      ref: "multipleTable",
                      attrs: {
                        data: _vm.tableData,
                        "select-on-indeterminate": false,
                        "summary-method": _vm.getSummaries,
                        border: "",
                        height: "100%",
                        "show-summary": "",
                      },
                      on: { "selection-change": _vm.handleSelectionChange },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "selection", width: "105" },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "序号", type: "index", width: "60" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作门店",
                          prop: "operateCompanyName",
                          width: "100",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "接车单号", "min-width": "200" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "p",
                                  {
                                    staticClass: "blue pointer",
                                    on: {
                                      click: function ($event) {
                                        return _vm.goDetail(scope.row.billId)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(scope.row.billCode) + " "
                                    ),
                                  ]
                                ),
                                scope.row.billType !== 0
                                  ? _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("dict")(
                                              scope.row.billType,
                                              "carBillType"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("dict")(
                                              scope.row.isOthersSelfPayState,
                                              "isOthersSelfPayState"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "结账(挂账）时间",
                          "min-width": "180",
                          prop: "checkDate",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "进店时间",
                          "min-width": "180",
                          prop: "dateCreated",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "车牌号",
                          "min-width": "180",
                          prop: "carNumber",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "签约客户", "min-width": "150" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                row.client
                                  ? _c("div", [
                                      _c("p", [
                                        _vm._v(_vm._s(row.client.clientName)),
                                      ]),
                                      _c("p", { staticClass: "color999" }, [
                                        _vm._v(_vm._s(row.client.clientPhone)),
                                      ]),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "消费客户", "min-width": "120" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                row.consumer
                                  ? _c("div", [
                                      _c("p", [
                                        _vm._v(
                                          _vm._s(row.consumer.consumerName)
                                        ),
                                      ]),
                                      _c("p", { staticClass: "color999" }, [
                                        _vm._v(
                                          _vm._s(row.consumer.consumerPhone)
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "开票主体", "min-width": "100" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      row.invoiceName
                                        .map((i) => {
                                          return i
                                        })
                                        .join()
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "开票单据号",
                          "min-width": "100",
                          prop: "invoiceNum",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "业务来源",
                          "min-width": "100",
                          prop: "sourceOfBusiness",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "事故责任", "min-width": "100" },
                        scopedSlots: _vm._u(
                          [
                            _vm.status != 3
                              ? {
                                  key: "default",
                                  fn: function ({ row }) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("dict")(
                                              row.dutyType,
                                              "dutyType"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                }
                              : null,
                          ],
                          null,
                          true
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "单据金额",
                          "min-width": "100",
                          prop: "paid",
                        },
                      }),
                      _c(
                        "el-table-column",
                        {
                          attrs: { "min-width": "200", prop: "contractTotal" },
                        },
                        [
                          _c("template", { slot: "header" }, [
                            _c(
                              "div",
                              { staticClass: "flex" },
                              [
                                _vm._v(" 挂账(包干）总额 "),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "light",
                                      placement: "bottom-start",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          color: "#666666",
                                          "font-size": "13px",
                                          "line-height": "16px",
                                          "text-indent": "15px",
                                        },
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _vm._v(
                                          " 包干业务的挂账金额为包干分摊金额， "
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          " 结账当月显示为查询时点的预分摊金额， "
                                        ),
                                        _c("br"),
                                        _vm._v(" 结账次月显示为实际分摊金额 "),
                                      ]
                                    ),
                                    _c("i", {
                                      staticClass: "iconfont grow-icon_doubt",
                                      staticStyle: { "margin-left": "5px" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        2
                      ),
                      _c("el-table-column", {
                        attrs: {
                          label: "已收金额",
                          "min-width": "100",
                          prop: "alreadyPaid",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "待收金额",
                          "min-width": "180",
                          prop: "unclearedDebt",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "挂账总额",
                          "min-width": "180",
                          prop: "unclearedDebtFixed",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "结账收款备注",
                          "min-width": "200",
                          prop: "remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "15%",
                    "padding-left": "30px",
                    "box-sizing": "border-box",
                  },
                },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.tableData2 },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "消费客户与统计（客户数）",
                          prop: "filder14",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ]),
        _vm.importDialogVisibility
          ? _c("import-dialog", {
              attrs: {
                getLastResultApi: _vm.batchImportWriteOffResultApi,
                importFailDetail: _vm.batchImportWriteOffFailResultExportUrlApi,
                showTip: false,
                uploadFileApi: _vm.batchImportWriteOffApi,
                visibility: _vm.importDialogVisibility,
                templateFileUrl:
                  "https://growxny-dev-center.oss-cn-guangzhou.aliyuncs.com/template/20240731/%E6%94%B6%E6%AC%BE%E9%94%80%E8%B4%A6%E6%89%B9%E9%87%8F%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx",
                title: "批量核销导入",
              },
              on: {
                "update:visibility": function ($event) {
                  _vm.importDialogVisibility = $event
                },
              },
            })
          : _vm._e(),
        _c("export-dialog", {
          attrs: {
            filterForm: _vm.form,
            pageOption: _vm.pageOption,
            total: _vm.total,
            url: _vm.exportUrl,
            visibility: _vm.showExportDialog,
          },
          on: {
            "update:visibility": function ($event) {
              _vm.showExportDialog = $event
            },
          },
        }),
        _c(
          "template",
          { slot: "footer" },
          [
            _c("pagination", {
              attrs: { total: _vm.total },
              on: { pageChange: _vm.changePage },
            }),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }