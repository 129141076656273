var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "div",
        { staticClass: "table-wrap" },
        [
          _c(
            "div",
            { ref: "exportRef", staticClass: "export-btn-wrap" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "auth",
                      rawName: "v-auth",
                      value: 798,
                      expression: "798",
                    },
                  ],
                  staticClass: "export-btn",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.showExportDialog = true
                    },
                  },
                },
                [_vm._v("导出")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              staticClass: "new-table",
              attrs: {
                data: _vm.tableData,
                height: _vm.tableHei,
                "header-cell-style": { height: "32px", padding: "0!important" },
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  fixed: "",
                  label: "操作门店",
                  prop: "companyName",
                  "show-overflow-tooltip": "",
                  width: "220",
                },
              }),
              _c(
                "el-table-column",
                { attrs: { align: "center", label: "1个月以内" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "单量",
                      prop: "withinOneMonthBillCount",
                      width: "100",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "金额",
                      prop: "withinOneMonthBillAmount",
                      width: "100",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-table-column",
                { attrs: { align: "center", label: "1-3个月" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "单量",
                      prop: "withinRangeOneToThreeMonthBillCount",
                      width: "100",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "金额",
                      prop: "withinRangeOneToThreeMonthBillAmount",
                      width: "100",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-table-column",
                { attrs: { align: "center", label: "3-6个月" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "单量",
                      prop: "withinRangeThreeToSixMonthBillCount",
                      width: "100",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "金额",
                      prop: "withinRangeThreeToSixMonthBillAmount",
                      width: "100",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-table-column",
                { attrs: { align: "center", label: "6-12个月" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "单量",
                      prop: "withinRangeSixToTwelveMonthBillCount",
                      width: "100",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "金额",
                      prop: "withinRangeSixToTwelveMonthBillAmount",
                      width: "100",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-table-column",
                { attrs: { align: "center", label: "1年以上" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "单量",
                      prop: "moreThanOneYearBillCount",
                      width: "100",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "金额",
                      prop: "moreThanOneYearBillAmount",
                      width: "100",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-table-column",
                { attrs: { align: "center", label: "小计" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "单量",
                      prop: "subTotalBillCount",
                      width: "100",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "金额",
                      prop: "subTotalBillAmount",
                      width: "100",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-table-column",
                { attrs: { align: "center", label: "占比" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "单量",
                      prop: "subTotalBillCountRate",
                      width: "100",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "金额",
                      prop: "subTotalBillAmountRate",
                      width: "100",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("export-dialog", {
        attrs: {
          filterForm: _vm.form,
          pageOption: _vm.pageOption,
          setWid: "30%",
          total: _vm.total,
          url: _vm.exportUrl,
          visibility: _vm.showExportDialog,
        },
        on: {
          "update:visibility": function ($event) {
            _vm.showExportDialog = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }