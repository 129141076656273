/**
 * 文件相关操作
 * @author fcd
 * @since 2022/04/19
 */

import axios from 'axios';
import http from '@/service/http';
import { api as viewerApi } from 'v-viewer';
import $ from 'jquery';

export function previewImage(param, index, titleArr = []) {
  let viewer;
  let optionObj = {
    images: [],
    options: {
      initialViewIndex: 0,
      toolbar: {
        zoomIn: true,
        zoomOut: true,
        oneToOne: true,
        reset: true,
        prev: true,
        play: true,
        next: true,
        rotateLeft: true,
        rotateRight: true,
        flipHorizontal: true,
        flipVertical: true,
        download: function (e) {
          // 图片跨域会导致无法下载
          axios
            .get(viewer.image.src, { responseType: 'blob' })
            .then((response) => {
              const blob = new Blob([response.data]);
              const link = document.createElement('a');
              link.href = URL.createObjectURL(blob);
              link.download = viewer.image.alt;
              link.click();
              URL.revokeObjectURL(link.href);
            })
            .catch((err) => {
              console.error(err);
            });
        },
      },
    },
  };
  // 如果参数是字符串，则认为是图片地址
  if (typeof param === 'string') {
    optionObj.images = [param];
  }
  // 如果参数是数组，则认为是图片数组
  else if (Array.isArray(param)) {
    optionObj.images = param;
    // 默认打开第几张
    optionObj.options.initialViewIndex = index || 0;
    optionObj.options.title = (image, imageData) => {
      return titleArr[viewer.index] || image.alt;
    };
  }
  // 如果参数是对象，则认为是配置对象
  else if (Object.keys(param).length) {
    let { images = [], options = {} } = param;
    optionObj.options = Object.assign(optionObj.options, options);
    optionObj.images = images;
  }

  viewer = viewerApi(optionObj);
  // 添加下载图标
  var timer = setInterval(() => {
    let dom = document.getElementsByClassName('viewer-download')[0];
    if (dom) {
      const icon = document.createElement('i');
      icon.classList.add('el-icon-download');
      icon.style.cssText = `font-size: 14px;
                            color: #fff;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-weight: bold;`;
      dom.appendChild(icon);
      clearInterval(timer);
    }
  }, 200);
  console.log(viewer);
}

export async function uploadImage(file, type = '1', requestConfig = {}) {
  // try {
  //   if (file.size > 1024 * 300 && file.type.includes('image')) {
  //     console.time('压缩图片');
  //     file = await compressImage(file, 1024 * 300);
  //     console.timeEnd('压缩图片');
  //   }
  // } catch (error) {
  //   console.error(error);
  // }
  let formData = new FormData();
  formData.append('uploadType', type);
  formData.append('file', file);

  return http.requestNative({
    method: 'post',
    url: '/oss/admin/oss/uploadImages',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
    ...requestConfig,
  });
}

export function uploadVideo(file, type = '1') {
  let formData = new FormData();
  formData.append('uploadType', type);
  formData.append('file', file);

  return http.requestNative({
    method: 'post',
    url: '/oss/admin/oss/uploadVideo',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  });
}

export function downloadFile(option) {
  let { url, data, fileName, ...config } = option;
  console.log(config);
  http
    .post(url, data, Object.assign({ responseType: 'blob' }, config))
    .then((res) => {
      handleSave(res, fileName);
    });
}
export function handleSave(res, fileName) {
  const blob = res.data;
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  if (!fileName) {
    try {
      link.download = decodeURIComponent(
        res.headers['content-disposition'].split('filename=')[1]
      );
    } catch {
      link.download = '导出文件.xlsx';
    }
  } else {
    link.download = fileName;
  }
  document.body.appendChild(link);
  link.click();
  setTimeout(function () {
    URL.revokeObjectURL(blob);
    document.body.removeChild(link);
  }, 0);
}

export function chooseFile(params) {
  //参数默认值
  var defaults = {
    multiple: true, //默认多选
    accept: '', //默认接受所有类型的文件
    returnInput: false, //默认不返回 input
  };
  var $fileInput, fileInput; //文件选择器(jQuery) 文件选择器(Dom)
  return new Promise((resolve, reject) => {
    //当只传一个参数时
    if (arguments.length === 1) {
      //若唯一实参是 boolean 类型，认为该参数是 multiple: boolean 多选
      if (typeof params === 'boolean') {
        params = {
          multiple: params,
        };
      } else if (typeof params === 'string') {
        //若唯一实参是 string 类型，认为该参数是 accept: string 接受的文件类型
        params = {
          accept: params,
        };
      } else {
        params = params || {};
      }
    } else {
      params = params || {};
    }
    //填充默认值
    params = Object.assign({}, defaults, params);

    //创建文件选择器
    //文件选择器
    $fileInput = $('<input>');
    fileInput = $fileInput[0];

    $fileInput
      .attr('type', 'file') //类型：文件
      .css('position', 'fixed') //移出视野
      .css('left', '-1000px') //移出视野
      .css('top', '-1000px') //移出视野
      .appendTo('body');

    if (params.multiple) $fileInput.attr('multiple', 'multiple'); //多选
    if (params.accept) $fileInput.attr('accept', params.accept); //接受的文件类型

    // var fileChosen = $q.defer();

    //把焦点放在文件选择器上
    $fileInput.focus();

    //绑定【改变事件】
    $fileInput.one('change', function () {
      if (params.returnInput) resolve($fileInput);

      //返回 File 数组
      resolve(Array.prototype.slice.call(fileInput.files));
    });
    //绑定【获得焦点事件】
    $fileInput.one('focus', function () {
      //【弹出】文件选择窗口时，文件选择器会【失去焦点】
      //【关闭】文件选择窗口时，文件选择器会重新【获得焦点】
      //由于直接点击【关闭】或【取消】按钮关闭文件选择窗口后不会触发【改变事件】
      //只能在重新【获得焦点事件】里判断是选择了文件还是没选
      //而且必须延时等待触发【改变事件】后再判断
      setTimeout(() => {
        if (!fileInput.files || !fileInput.files.length) reject('取消选择文件');
      }, 3e3);
    });
    $fileInput.click();
  }).finally(function () {
    if (!params.returnInput && $fileInput) {
      $fileInput.remove();
    }
  });
}

export async function chooseAndUploadFiles(params) {
  let files = await chooseFile(params);
  let promises = files.map((file) => uploadImage(file));
  const allSettled = (promises) =>
    Promise.all(
      promises.map((promise) =>
        promise
          .then((value) => ({ status: 'fulfilled', value }))
          .catch((reason) => ({ status: 'rejected', reason }))
      )
    );

  let results = await allSettled(promises);
  let successResults = results.filter((item) => item.status === 'fulfilled');
  return successResults.map((item) => item.value);
}

export const isSuffixMatch = function (url, suffixToMatch) {
  //文件后缀名
  var suffix = getSuffix(url);
  if (!suffix) return false;

  return suffixToMatch.some((item) => {
    return suffix === item;
  });
};

export const getSuffix = function (url, withDot) {
  //最后一个点号位置
  var lastIndexOfDot = url.lastIndexOf('.');
  if (lastIndexOfDot < 0) return '';

  var suffixIndex = lastIndexOfDot;
  if (!withDot) suffixIndex++;

  //文件后缀名
  var suffix = url.substr(suffixIndex).toLowerCase();

  return suffix;
};

/**
 * 判断文件是否图片
 * @return {boolean}
 */
export const isImage = function (url) {
  return isSuffixMatch(url, [
    'jpg',
    'jpeg',
    'jpe',
    'webp',
    'jfif',
    'gif',
    'png',
    'bmp',
    'dib',
    'tif',
    'tiff',
    'heic',
  ]);
};
/**
 * 判断文件是否视频
 * @return {boolean}
 */
export const isVideo = function (url) {
  return isSuffixMatch(url, [
    'mp4',
    'mkv',
    'opus',
    'ogv',
    'mov',
    'm4v',
    'mp3',
    'aac',
    'oga',
    'm3u8',
  ]);
};

/**
 * 图片 base64转Blob
 */
export const base64ToBlob = function (base64) {
  var bytes = window.atob(base64.split(',')[1]); //去掉url的头，并转换为byte
  //处理异常,将ascii码小于0的转换为大于0
  var ab = new ArrayBuffer(bytes.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < bytes.length; i++) {
    ia[i] = bytes.charCodeAt(i);
  }
  return new Blob([ab], { type: 'image/png' });
};

/**
 * 图片 base64转File
 *
 * @param {string} base64
 * @param {string} fileName
 * @returns {File}
 */
export const base64ToFile = function (
  base64,
  fileName = new Date().getTime() + '.png'
) {
  return new File([base64ToBlob(base64)], fileName, { type: 'image/png' });
};

/**
 * 压缩图片
 * @param {File} file 图片文件
 * @param {number} maxSize 最大尺寸，单位：字节
 */
export function compressImage(file, maxSize) {
  return new Promise((resolve, reject) => {
    function dataURItoBlob(dataURI) {
      const byteString = atob(dataURI.split(',')[1]);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: 'image/png' });
    }

    if (!file.type.includes('image')) {
      reject(new Error('请选择图片文件'));
      return;
    }

    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = function (event) {
      const img = new Image();
      img.src = event.target.result;

      img.onload = function () {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        let quality = 1;
        let dataURL = canvas.toDataURL(file.type, quality);
        while (dataURL.length > maxSize && quality > 0.1) {
          quality -= 0.1;
          dataURL = canvas.toDataURL(file.type, quality);
        }

        if (quality <= 0) {
          reject(new Error('压缩失败，请重新上传'));
          return;
        }

        const blob = dataURItoBlob(dataURL);
        const compressedFile = new File([blob], file.name, {
          type: file.type,
          lastModified: Date.now(),
        });

        resolve(compressedFile);
      };
    };

    reader.onerror = function (error) {
      reject(new Error('文件读取失败'));
    };
  });
}

/**
 * 保存File类型文件
 * @param {File} file
 */
export function saveFile(file) {
  const url = URL.createObjectURL(file); // 创建 URL 对象
  const a = document.createElement('a'); // 创建一个 <a> 标签
  a.href = url; // 设置下载地址
  a.download = file.name; // 设置文件名
  document.body.appendChild(a); // 将 <a> 标签添加到 DOM 中
  a.click(); // 模拟点击事件触发下载
  document.body.removeChild(a); // 下载完成后移除 <a> 标签
  URL.revokeObjectURL(url); // 释放 URL 对象所占用的内存
}
