<template>
  <base-container>
    <template slot="header">
      <div class="form-top">
        <el-form :model="form" label-width="100px" size="small">
          <el-row>
            <el-col :span="6">
              <el-form-item label="查询日期">
                <el-date-picker
                  class="w100"
                  v-model="form.times"
                  type="daterange"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="起始时间"
                  end-placeholder="截止时间"
                  value-format="yyyy-MM-dd "
                  @input="dateRangeChange()"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="支付方式">
                <div class="last-type-sty">
                  <el-select
                    v-model="form.customers"
                    placeholder="请选择"
                    clearable
                  >
                    <el-option
                      v-for="item in customers"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="收支类型">
                <div class="last-type-sty">
                  <el-select
                    v-model="form.customers"
                    placeholder="请选择"
                    clearable
                  >
                    <el-option
                      v-for="item in customers"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="类别">
                <div class="last-type-sty">
                  <el-select
                    v-model="form.customers"
                    placeholder="请选择"
                    clearable
                  >
                    <el-option
                      v-for="item in customers"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <div class="el-form-item--small" style="margin-left: 20%">
                <el-button class="btn_search" type="primary" size="small"
                  >查询</el-button
                >
                <el-button class="btn_search" size="small" @click="empty"
                  >置空</el-button
                >
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </template>
    <template>
      <div class="content-container">
        <div style="display: flex; justify-content: end">
          <el-button
            size="small"
            type="text"
            class="blue"
            @click="showExportDialog = true"
            >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
          ></el-button>
        </div>
        <el-table height="100%" :data="carBillList" border>
          <el-table-column width="100" label="操作" prop="field6">
            <template slot-scope="scope">
              <span
                class="pointer text_operation blue"
                @click="getUpdate(scope.$index, scope.row)"
                >明细</span
              >
            </template>
          </el-table-column>
          <el-table-column mix-width="180" label="时间" prop="field7">
          </el-table-column>
          <el-table-column mix-width="180" label="收支类型" prop="field7">
          </el-table-column>
          <el-table-column mix-width="180" label="类别" prop="field7">
          </el-table-column>
          <el-table-column mix-width="180" label="支付方式" prop="field7">
          </el-table-column>
          <el-table-column mix-width="180" label="金额" prop="field7">
          </el-table-column>
          <el-table-column mix-width="180" label="操作人" prop="field7">
          </el-table-column>
          <el-table-column mix-width="180" label="车牌" prop="field7">
          </el-table-column>
          <el-table-column mix-width="180" label="备注" prop="field7">
          </el-table-column>
        </el-table>
        <p class="data-statis">
          实际合计：<span class="orange">￥11524.154 </span>实付合计：<span
            class="orange"
            >￥11524.154
          </span>
          结余：<span class="orange">￥11524.154 </span> 免账合计：<span
            class="orange"
            >￥11524.154
          </span>
          会员卡合计：<span class="orange">￥11524.154 </span> 挂账合计：<span
            class="orange"
            >￥11524.154
          </span>
        </p>
      </div>
    </template>
    <el-dialog title="导出提示" :visible.sync="showExportDialog" center>
      <div class="export-dialog__body">
        <div class="export-dialog-page__input">
          <span>导出范围：第</span>
          <el-input
            type="number"
            size="small"
            v-model="startPage"
            :max="Math.ceil(total / pageOption.size)"
            :min="1"
          ></el-input>
          <span>至</span>
          <el-input
            type="number"
            size="small"
            v-model="endPage"
            :max="Math.ceil(total / pageOption.size)"
            :min="1"
          ></el-input>
        </div>
        <p class="export-dialog__tip">
          共{{ Math.ceil(total / pageOption.size) }}页，一次最多导出10000条数据
        </p>
      </div>
      <div slot="footer">
        <el-button @click="showExportDialog = false">取 消</el-button>
        <el-button type="primary" @click="doExport">确 定</el-button>
      </div>
    </el-dialog>
    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
  </base-container>
</template>
<script>
import { searchClient, exportCusterListUrl } from '@/api/customer/customer';
// 分页组件
import pagination from '@/components/Pagination.vue';
import { downloadFile } from '@/utils/fileApi';
// 工具
import Big from 'big.js';
export default {
  name: 'cashFlowTable',
  components: { pagination },
  data() {
    return {
      form: {},
      customers: [],
      //导出的页面设置
      startPage: 1,
      endPage: 1,
      //分页组件
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      showExportDialog: false,
      carBillList: [
        {
          field6: '格局小了店',
          field7: '琼·B250',
          field8: '玛莎拉蒂/玛莎拉蒂 GT/玛莎拉蒂 GT',
          field9: 'HJHHKTR4657JHK5637564',
          field10: '2022-05-20 13:14:99',
          field11: '无需',
          field12: '王五',
          field13: '因为XXX原因滞留',
          field14: 'JS23059636745375',
          field15: '车主自费',
          field16: '服务中',
          field17: '今日预交',
          field18: '待派检 / 报价中 / 未施工',
          field19: '2012–01-19 14:00',
          field20: '张三',
          field21: '待定',
          field22: '检车员1、检车员2',
          field23: '所在班组',
          field24: '检车员1、检车员2',
          field25: '所在班组',
          money: '200',
        },
        {
          field6: '格局小了店',
          field7: '琼·B250',
          field8: '玛莎拉蒂/玛莎拉蒂 GT/玛莎拉蒂 GT',
          field9: 'HJHHKTR4657JHK5637564',
          field10: '2022-05-20 13:14:99',
          field11: '无需',
          field12: '王五',
          field13: '因为XXX原因滞留',
          field14: 'JS23059636745375',
          field15: '车主自费',
          field16: '服务中',
          field17: '今日预交',
          field18: '待派检 / 报价中 / 未施工',
          field19: '2012–01-19 14:00',
          field20: '张三',
          field21: '待定',
          field22: '检车员1、检车员2',
          field23: '所在班组',
          field24: '检车员1、检车员2',
          field25: '所在班组',
          money: '200',
        },
      ],
    };
  },

  created() {
    this.pickTimes();
  },
  mounted() {},
  methods: {
    //设置查询日期的默认时间
    pickTimes() {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      this.form.times = [start, end];
    },
    //下面是解决设置日期默认值时重新选择无法刷新日期的方法
    dateRangeChange() {
      let _this = this;
      _this.$nextTick(() => {
        _this.$forceUpdate();
      });
    },
    /**
     * 置空输入框数据
     */
    empty() {
      this.form = {};
    },
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      //   this.getDataList();
    },
    /**
     * 导出客户列表
     */
    doExport() {
      downloadFile({
        url: exportCusterListUrl,
        data: Object.assign(this.form, {
          exportStartNum: Big(this.startPage - 1).times(this.pageOption.size),
          exportEndNum: Big(this.endPage).times(this.pageOption.size),
        }),
      });
      this.showExportDialog = false;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep.base-container.default-padding > .header {
  padding: 30px 0px 0px !important;
  margin-left: -25px;
}
::v-deep.base-container.default-padding > .main {
  padding: 0px !important;
}
//修改选择时间框长度
.el-form {
  width: 100%;
  .el-form-item__content > div {
    width: 100%;
  }
}
//改变base-container头部间距
.base-container.default-padding {
  padding: 10px 0px !important;
}
.last-type-sty {
  display: flex;
  .el-input {
    margin-left: 20px;
  }
}
//导出列表样式
.export-dialog__body {
  margin: 20px 0;
  text-align: center;
  .export-dialog-page__input {
    display: flex;
    justify-content: center;
    align-items: center;
    .el-input {
      width: 140px;
      margin: 0 15px;
    }
  }
  .export-dialog__tip {
    margin-top: 20px;
  }
}
.data-statis {
  margin: 20px 0px;
  font-size: 14px;
  span {
    margin-right: 30px;
  }
}
</style>
