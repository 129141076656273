<template>
  <base-container paddingTop>
    <div>
      <el-form
        :model="form"
        label-width="100px"
        size="small"
        style="margin-left: -22px"
        class="filter-form"
      >
        <div class="flex-x-between">
          <div class="flex-warp">
            <el-form-item label="门店名称">
              <el-input
                v-model="form.companyName"
                placeholder="请输入门店名称"
                clearable
                size="small"
              >
              </el-input>
            </el-form-item>
          </div>
          <div class="h100">
            <el-button
              v-auth="762"
              class="btn_search"
              type="primary"
              size="small"
              @click="getList"
              >查询</el-button
            >
          </div>
        </div>
      </el-form>
    </div>
    <el-table
      :data="invoiceTable"
      border
      style="width: 100%"
      class="custom-table"
    >
      <el-table-column label="操作" width="70">
        <template slot-scope="scope">
          <div
            @click="handleEdit(scope.$index, scope.row)"
            class="primary pointer"
          >
            {{ scope.row.operationText }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="companyId" label="门店编号" min-width="180">
      </el-table-column>
      <el-table-column prop="companyName" label="门店名称" min-width="180">
      </el-table-column>
      <el-table-column min-width="180" label="是否统计门店">
        <template #default="{ row }">
          <div v-if="row.change">
            <el-switch
              v-model="row.isStatShow"
              active-text="统计"
              inactive-text="不统计"
              :active-value="true"
              :inactive-value="false"
            >
            </el-switch>
          </div>
          <div v-else>
            <el-switch
              v-model="row.isStatShow"
              active-text="统计"
              inactive-text="不统计"
              :active-value="true"
              :inactive-value="false"
              disabled
            >
            </el-switch>
          </div>
        </template>
      </el-table-column>
      <el-table-column min-width="180" label="责任人">
        <template #default="{ row }">
          <div v-if="row.change">
            <el-input
              placeholder="请输入责任人"
              clearable
              size="small"
              v-model="row.responsibleUserName"
            >
            </el-input>
          </div>
          <div v-else>{{ row.responsibleUserName }}</div>
        </template>
      </el-table-column>
      <el-table-column min-width="180" label="目标值">
        <template #default="{ row }">
          <div v-if="row.change">
            <el-input
              placeholder="请输入目标值"
              clearable
              size="small"
              v-model="row.responsibilityTargetNum"
            >
            </el-input>
          </div>
          <div v-else>{{ row.responsibilityTargetNum }}</div>
        </template>
      </el-table-column>
    </el-table>
    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
  </base-container>
</template>

<script>
import pagination from '@/components/Pagination.vue';
//接口
import {
  wreckedCarEntryCountSettingsCompanyList,
  wreckedCarEntryCountSettingsCompanyUpdate,
} from '@/api/carBill/carWreckedAndStranded';
import { hasAuth } from '@/utils/permissions';
import { mapState } from 'vuex';

export default {
  name: 'wreckedCarEntryCountCompanySettings',
  components: {
    pagination,
  },
  data() {
    return {
      form: {},
      invoiceTable: [],
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
      companyName: (state) => state.user.companyName,
    }),
  },
  created() {
    this.getList();
  },
  methods: {
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getList(false);
    },
    //获取列表数据
    getList(reset = true) {
      var res = hasAuth(762);
      if (!res) {
        return;
      }
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      var data = Object.assign({}, this.form, this.pageOption);
      wreckedCarEntryCountSettingsCompanyList(data).then((res) => {
        const _invoiceTable = this.$lodash.cloneDeep(res.records);
        _invoiceTable.forEach((row) => {
          row.operationText = '编辑';
          row.change = false;
        });
        // 修改总条数
        this.total = res.total;
        // 赋值records
        this.invoiceTable = _invoiceTable;
      });
    },
    //点击编辑按钮
    handleEdit(index, row) {
      const _this = this;
      if (_this.invoiceTable[index].operationText == '保存') {
        wreckedCarEntryCountSettingsCompanyUpdate({
          companyId: row.companyId,
          id: row.id,
          isStatShow: row.isStatShow !== undefined ? row.isStatShow : false,
          responsibleUserName: row.responsibleUserName,
          responsibilityTargetNum: row.responsibilityTargetNum,
        }).then((res) => {
          if (res.code == 200) {
            _this.$message.success('保存成功！');
            _this.invoiceTable[index].operationText = '编辑';
            _this.invoiceTable[index].change = false;
            _this.getList(false);
          }
        });
      } else {
        _this.invoiceTable[index].operationText = '保存';
        _this.invoiceTable[index].change = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
  &::v-deep .el-tabs__content {
    height: 100%;
    .el-tab-pane {
      // height: calc(100% - 109px);
      height: 100%;
      overflow: auto;
    }
  }
}
</style>
