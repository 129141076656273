var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { attrs: { paddingTop: "" } },
    [
      _c(
        "el-tabs",
        {
          staticClass: "tabs",
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            {
              directives: [
                {
                  name: "auth",
                  rawName: "v-auth",
                  value: 692,
                  expression: "692",
                },
              ],
              attrs: { label: "预约信息", name: "bookingList" },
            },
            [_c("miniProgramBookingRecords")],
            1
          ),
          _c(
            "el-tab-pane",
            {
              directives: [
                {
                  name: "auth",
                  rawName: "v-auth",
                  value: 689,
                  expression: "689",
                },
              ],
              attrs: { label: "快保配置", name: "quickConfig" },
            },
            [
              _c("miniProgramBookingServiceConfig", {
                attrs: { "service-type": 0 },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            {
              directives: [
                {
                  name: "auth",
                  rawName: "v-auth",
                  value: 689,
                  expression: "689",
                },
              ],
              attrs: { label: "故障配置", name: "faultRepairConfig" },
            },
            [
              _c("miniProgramBookingServiceConfig", {
                attrs: { "service-type": 1 },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            {
              directives: [
                {
                  name: "auth",
                  rawName: "v-auth",
                  value: 689,
                  expression: "689",
                },
              ],
              attrs: { label: "事故配置", name: "accidentConfig" },
            },
            [
              _c("miniProgramBookingServiceConfig", {
                attrs: { "service-type": 2 },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            {
              directives: [
                {
                  name: "auth",
                  rawName: "v-auth",
                  value: 689,
                  expression: "689",
                },
              ],
              attrs: { label: "退车配置", name: "returnCarConfig" },
            },
            [
              _c("miniProgramBookingServiceConfig", {
                attrs: { "service-type": 3 },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }