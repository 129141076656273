var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    {
      ref: "baseContainer",
      attrs: {
        customStyle: "background: #f1f7fa;",
        customMainStyle: "margin-bottom: 16px;",
        defaultPadding: false,
      },
    },
    [
      _c(
        "div",
        { staticClass: "box" },
        [
          _c(
            "div",
            { staticClass: "calendar" },
            [
              _c("customCalendar", {
                staticClass: "customCalendar",
                attrs: { operationalState: _vm.operationalState },
                on: {
                  goNowTime: _vm.goNowTime,
                  startDataHandle: _vm.startDataHandle,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "table" },
            [
              _c(
                "div",
                { staticClass: "right-content" },
                [
                  _c(
                    "el-tabs",
                    {
                      on: { "tab-click": _vm.handleClick },
                      model: {
                        value: _vm.activeName,
                        callback: function ($$v) {
                          _vm.activeName = $$v
                        },
                        expression: "activeName",
                      },
                    },
                    [
                      _c("el-tab-pane", {
                        attrs: { label: "当日待办", name: "notFinished" },
                      }),
                      _c("el-tab-pane", {
                        attrs: { label: "已办", name: "finished" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    {
                      ref: "tableRef",
                      attrs: {
                        data: _vm.listData,
                        "row-key": _vm.getRowKeys,
                        border: "",
                      },
                      on: { "selection-change": _vm.handleChange },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "index", label: "序号", width: "60" },
                      }),
                      _c(
                        "el-table-column",
                        { attrs: { label: "状态", prop: "status" } },
                        [
                          _c("el-table-column", {
                            attrs: {
                              "reserve-selection": true,
                              type: "selection",
                              fixed: "left",
                              width: "80",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-table-column",
                        {
                          attrs: {
                            label: "提醒",
                            width: "120",
                            prop: "overTime",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ row }) {
                                return [
                                  _c("p", { staticClass: "red" }, [
                                    _vm._v(" " + _vm._s(row.overTime) + " "),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        },
                        [_c("p")]
                      ),
                      _c("el-table-column", {
                        attrs: {
                          label: "分类",
                          width: "80",
                          prop: "funType.description",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                row.funType == 0
                                  ? _c("span", [_vm._v("定损单")])
                                  : _vm._e(),
                                row.funType == 1
                                  ? _c("span", [_vm._v("车单")])
                                  : _vm._e(),
                                row.funType == 2
                                  ? _c("span", [_vm._v("车单返修追溯")])
                                  : _vm._e(),
                                row.funType == 3
                                  ? _c("span", [_vm._v("派工单")])
                                  : _vm._e(),
                                row.funType == -1
                                  ? _c("span", [_vm._v("未知待办类型")])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "待办内容",
                          "min-width": "200",
                          prop: "title",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                !["", undefined, null].includes(row.title) &&
                                row.funType == 0
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "pointer text_operation blue",
                                        on: {
                                          click: function ($event) {
                                            return _vm.goCarBillInfo(row)
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(row.title))]
                                    )
                                  : !["", undefined, null].includes(
                                      row.title
                                    ) && row.funType == 2
                                  ? _c("span", { staticClass: "blue" }, [
                                      _vm._v(_vm._s(row.title)),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "创建时间",
                          width: "200",
                          prop: "dateCreated",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "截止时间",
                          width: "200",
                          prop: "deadlineDateTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("pagination", {
                staticClass: "pagination-box",
                attrs: { total: _vm.total },
                on: { pageChange: _vm.changePage },
              }),
            ],
            1
          ),
          _vm.lossRecordDrawer
            ? _c("car-loss-record-drawer", {
                attrs: {
                  visibility: _vm.lossRecordDrawer,
                  carLossDeterminationBillId: this.id,
                },
                on: {
                  "update:visibility": function ($event) {
                    _vm.lossRecordDrawer = $event
                  },
                  updateData: _vm.doSearch,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "操作提示",
            visible: _vm.dialogVisible,
            width: "30%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("span", [
            _vm._v("点击确定把当前事项设置为" + _vm._s(_vm.dialogText)),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }