<template>
  <el-drawer
    title="接车单详情"
    :visible="dialogTableVisible"
    size="58%"
    :append-to-body="true"
    :before-close="handleClose"
    modal
    close-on-press-escape
    wrapperClosable
  >
    <div style="margin-left: 25px">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="基础信息" name="first">
          <div class="car_state">
            <div class="car_state_sh">
              <p>接车单号：{{ billMessage.billCode }}</p>
              <p>
                {{ billMessage.billType | dict('carBillType') }}业务
                <!-- <span class="orange el-icon--right">(未检车</span>
              <el-divider direction="vertical"></el-divider>
              <span class="orange">报价已审</span>
              <el-divider direction="vertical"></el-divider>
              <span class="orange">服务中)</span> -->
                <!-- <span class="orange el-icon--right">{{
                billMessage.billState | dict('carBillState')
              }}</span> -->
                <span class="el-icon--right" v-if="billMessage.dispatchState"
                  >（{{
                    billMessage.dispatchState | dict('dispatchState')
                  }}）</span
                >
              </p>
            </div>
            <div class="bill-process flex-y-center">
              <div class="state-name">
                <span>接待开单</span>
                <span class="time">{{ billMessage.dateCreated }}</span>
              </div>
              <div class="split-string">
                <div class="top-text">
                  <span v-show="billMessage.planFinishTime">
                    预交车: {{ billMessage.planFinishTime }}
                  </span>
                  <span v-show="billMessage.waitInStore">在店等</span>
                  <span v-show="billMessage.undetermined">待定</span>
                </div>
                <div class="line"></div>
                <div class="bottom-text orange">
                  <span v-if="billMessage.planFinishTime">{{
                    distancePlannedTime
                  }}</span>
                </div>
              </div>
              <div class="state-name">
                <div class="top-text"></div>
                <span>质检交车</span>
                <span class="time"></span>
              </div>
              <div class="split-string">
                <div class="top-text plan-time"></div>
                <div class="line"></div>
                <div class="bottom-text orange"></div>
              </div>
              <div class="state-name">
                <div class="top-text"></div>
                <span>提交结账</span>
                <span class="time"></span>
              </div>
              <div class="split-string">
                <div class="top-text orange">
                  <span v-show="billMessage.takeTime"> 客户提前提车</span>
                </div>
                <div class="line"></div>
                <div class="bottom-text orange">{{ billMessage.takeTime }}</div>
              </div>
              <div class="state-name">
                <div class="top-text orange">
                  <span v-show="billMessage.takeTime"> 客户提前提车</span>
                </div>
                <span>车单结账</span>
                <span class="time" v-if="billMessage.checkDate">{{
                  billMessage.checkDate
                }}</span>
              </div>
            </div>
          </div>
          <div class="cart_sty">
            <div class="car-logo">
              <img v-if="carInfo.carLogoIcon" :src="carInfo.carLogoIcon" />
              <img v-else src="@/assets/img/carLogo.png" />
            </div>
            <div style="margin-top: 19px">
              <p class="car-info">
                <span class="car_number">{{ carInfo.carNumber }}</span>
                <span class="tag car-vehicle-type" v-if="carInfo.vehicleType">{{
                  carInfo.vehicleType
                }}</span>
                <span v-if="carInfo.uniqueId" class="tag car-uniqueId"
                  >VIN: {{ carInfo.uniqueId }}</span
                >
                <span v-if="carInfo.brandName" class="tag car-brand">{{
                  carInfo.brandName
                }}</span>
                <span class="tag history"
                  >历史进店 {{ carInfo.intoStoresCount }} 次</span
                >
              </p>
              <div class="client-base-info flex">
                <i class="iconfont grow-icon_person icon-person"></i>
                <span class="custer_wrd">{{ carInfo.clientName }}</span>
                <i
                  class="iconfont icon_grade"
                  :class="levelIcons[carInfo.clientLevel]"
                  :title="levelIconsWord[carInfo.clientLevel] || ''"
                ></i>
                <span
                  class="blue pointer"
                  style="margin-left: 10px"
                  v-if="carInfo.clientRemark"
                  @click="remarksDrawerVisibility = true"
                  >客户备注</span
                >
                <!-- bug764隐藏 -->
                <!--<i
                    class="iconfont grow-icon_VX_16"
                    :class="{
                      icon_wechat: carInfo.isBindingWx == 0,
                      icon_wechat_active: carInfo.isBindingWx == 1,
                    }"
                    :title="['未关注', '已关注'][carInfo.isBindingWx] || ''"
                  ></i> -->
                <!-- <p v-if="carInfo.clientId" class="client-vip-info">
                  <i
                    class="iconfont grow-icon_vip"
                    :class="{
                      orange: carInfo.isVip,
                      color999: !carInfo.isVip,
                    }"
                  ></i>
                  <span class="vip-text" v-if="carInfo.isVip">
                    <span v-if="false">
                      会员客户 ( 会员卡:
                      {{
                        carInfo.billClientMemberMessageVo.memberCardNums
                      }}张，余额: ¥{{
                        carInfo.billClientMemberMessageVo.memberBalance
                      }}，余次:
                      {{
                        carInfo.billClientMemberMessageVo.memberResidueDegree
                      }}
                      {{
                        carInfo.billClientMemberMessageVo.isAgreementClient
                          ? '；客户协议 - 包干'
                          : ''
                      }}
                      )
                    </span>
                  </span>
                  <span v-else style="margin-left: 10px; font-size: 14px">
                    <span>非会员</span>
                  </span>
                </p> -->
              </div>
            </div>
          </div>
          <el-descriptions :column="3" border labelClassName="table-label">
            <el-descriptions-item :span="3" v-if="billMessage.billType === 0">
              <template slot="label">
                <el-switch
                  disabled
                  v-model="otherData.isOthersSelfPayState"
                ></el-switch>
                他人自费
              </template>
              <div
                v-if="
                  otherData.isOthersSelfPayState &&
                  [0].includes(billMessage.billType)
                "
              >
                <div class="client-base-info flex">
                  <i
                    class="iconfont grow-icon_person icon-person"
                    v-if="payerInfo.consumerName"
                  ></i>
                  <span class="custer_wrd"
                    >{{ payerInfo.consumerName }}
                    {{ payerInfo.consumerPhone }}</span
                  >
                  <i
                    class="iconfont icon_sex"
                    :class="{
                      'grow-icon_man': payerInfo.clientGender == 0,
                      'grow-icon_girl': payerInfo.clientGender == 1,
                    }"
                  ></i>
                  <i
                    v-show="payerInfo.isVip === 1"
                    class="iconfont grow-icon_grade icon_grade"
                    :class="levelIcons[payerInfo.clientLevel]"
                  ></i>
                  <!-- bug764隐藏 -->
                  <!-- <i
                    class="iconfont grow-icon_VX_16"
                    :class="{
                      icon_wechat: payerInfo.isBindingWx == 0,
                      icon_wechat_active: payerInfo.isBindingWx == 1,
                    }"
                    v-if="payerInfo.isBindingWx"
                  ></i> -->
                  <p v-if="payerInfo.clientId" class="client-vip-info">
                    <i
                      class="iconfont grow-icon_vip"
                      :class="{
                        orange: payerInfo.isVip,
                        color999: !payerInfo.isVip,
                      }"
                    ></i>
                    <span class="vip-text" v-if="payerInfo.isVip">
                      <span v-if="false">
                        会员客户 ( 会员卡:
                        {{
                          payerInfo.billClientMemberMessageVo.memberCardNums
                        }}张，余额: ¥{{
                          payerInfo.billClientMemberMessageVo.memberBalance
                        }}，余次:
                        {{
                          payerInfo.billClientMemberMessageVo
                            .memberResidueDegree
                        }}
                        {{
                          payerInfo.billClientMemberMessageVo.isAgreementClient
                            ? '；客户协议 - 包干'
                            : ''
                        }}
                        )
                      </span>
                    </span>
                    <span v-else style="margin-left: 10px; font-size: 14px">
                      <span>非会员</span>
                    </span>
                  </p>
                </div>
              </div>
            </el-descriptions-item>
            <!-- 是理赔信息状态的时候 -->
            <el-descriptions-item
              :span="3"
              label="理赔信息"
              v-if="billMessage.billType == 2"
            >
              <div style="line-height: 30px">
                <p>
                  保险公司：{{
                    carBillInsuranceInfo.claimsInsuranceCompanyName
                  }}
                </p>
                <p>开票主体：{{ carBillInsuranceInfo.insuranceCompanyName }}</p>
                <p>
                  报案号：<span style="color: #3eb1e0; margin-right: 13px">{{
                    carBillInsuranceInfo.caseNum
                  }}</span
                  ><span style="color: #ff9200">（待报损）</span>
                </p>
              </div>
              <div
                v-if="
                  !carBillInsuranceInfo.runningLicenceImg &&
                  !carBillInsuranceInfo.picDrivingLicence &&
                  !carBillInsuranceInfo.feeListPicA &&
                  !carBillInsuranceInfo.feeListPicB &&
                  !carBillInsuranceInfo.feeListPicC &&
                  carBillInsuranceInfo.feeListPicOthers.length == 0
                "
                class="color999"
              >
                无附件图片
              </div>
              <div class="h100" v-else>
                <upload-file
                  title="行驶证"
                  v-model="carBillInsuranceInfo.runningLicenceImg"
                  v-if="carBillInsuranceInfo.runningLicenceImg"
                  disabled
                ></upload-file>
                <upload-file
                  title="驾驶证"
                  v-model="carBillInsuranceInfo.picDrivingLicence"
                  v-if="carBillInsuranceInfo.picDrivingLicence"
                  disabled
                ></upload-file>
                <upload-file
                  title="定损单1"
                  v-model="carBillInsuranceInfo.feeListPicA"
                  v-if="carBillInsuranceInfo.feeListPicA"
                  disabled
                ></upload-file>
                <upload-file
                  title="定损单2"
                  v-model="carBillInsuranceInfo.feeListPicB"
                  v-if="carBillInsuranceInfo.feeListPicB"
                  disabled
                ></upload-file>
                <upload-file
                  title="定损单3"
                  v-model="carBillInsuranceInfo.feeListPicC"
                  v-if="carBillInsuranceInfo.feeListPicC"
                  disabled
                ></upload-file>
                <upload-file-list
                  v-model="carBillInsuranceInfo.feeListPicOthers"
                  v-if="carBillInsuranceInfo.feeListPicOthers"
                  disabled
                ></upload-file-list>
              </div>

              <p>
                事故责任：
                {{ carBillInsuranceInfo.dutyType | dict('dutyType') }}
              </p>
            </el-descriptions-item>
            <!-- 是索赔信息状态的时候 -->
            <el-descriptions-item
              :span="3"
              label="索赔信息"
              v-if="billMessage.billType == 3"
            >
              <div style="line-height: 30px">
                <p>
                  三包厂家：
                  {{ carBillUndertakeInfo.undertakeInsuranceCompanyName }}
                </p>
                <p>
                  开票主体： {{ carBillUndertakeInfo.undertakeCompanyName }}
                </p>
                <div>
                  索赔单号：
                  <p
                    class="blue pointer"
                    v-for="(item, index) in carBillUndertakeInfo.claimsNum"
                    :key="index"
                  >
                    {{ item }}
                  </p>
                </div>
              </div>
              <div class="flex-y-center h100">
                <upload-file-list
                  disabled
                  :value="carBillUndertakeInfo.undertakePics"
                  v-if="carBillUndertakeInfo.undertakePics.length != 0"
                ></upload-file-list>
                <div v-else class="color999">无附件图片</div>
              </div>
            </el-descriptions-item>
            <el-descriptions-item label="业务来源">
              {{ billMessage.billSourceTypeName }}
            </el-descriptions-item>
            <el-descriptions-item label="接车人">
              {{ billMessage.pickUpUserName }}
            </el-descriptions-item>
            <el-descriptions-item label="施工类型">
              {{ billMessage.difficulty | dict('difficulty') }}
            </el-descriptions-item>
            <el-descriptions-item label="送修司机信息">
              {{ billMessage.customerName }} {{ billMessage.customerPhone }}
            </el-descriptions-item>
            <el-descriptions-item label="进店里程">
              {{ billMessage.mileage ? billMessage.mileage + ' KM' : '' }}
            </el-descriptions-item>
            <el-descriptions-item label="油(电)表">
              {{ billMessage.oilMeter | dict('energyPercentage') }}
            </el-descriptions-item>
            <el-descriptions-item label="预交车时间" :span="3">
              <span v-show="billMessage.planFinishTime">
                {{ billMessage.planFinishTime }}
              </span>
              <span v-show="billMessage.waitInStore">在店等</span>
              <span v-show="billMessage.undetermined">待定</span>
            </el-descriptions-item>
            <el-descriptions-item label="车主要求" :span="3">
              {{ billMessage.remark }}
            </el-descriptions-item>
            <el-descriptions-item label="初诊与备注" :span="3">
              {{ billMessage.firstVisitRemark }}
            </el-descriptions-item>
            <el-descriptions-item label="环检图片" :span="3">
              <p
                v-if="Object.keys(billMessage.carBodyInfoVo || {}).length === 0"
              >
                无环检图片
              </p>
              <div class="flex color666" v-else>
                <upload-file
                  :value="
                    $lodash.get(
                      billMessage,
                      'carBodyInfoVo.carBodyPositivePic',
                      ''
                    )
                  "
                  title="车身正面"
                  v-if="billMessage.carBodyInfoVo.carBodyPositivePic"
                  disabled
                ></upload-file>

                <upload-file
                  :value="
                    $lodash.get(billMessage, 'carBodyInfoVo.carBodyLeftPic', '')
                  "
                  title="车身左面"
                  v-if="billMessage.carBodyInfoVo.carBodyLeftPic"
                  disabled
                ></upload-file>

                <upload-file
                  :value="
                    $lodash.get(
                      billMessage,
                      'carBodyInfoVo.carBodyBehindPic',
                      ''
                    )
                  "
                  title="车身后面"
                  v-if="billMessage.carBodyInfoVo.carBodyBehindPic"
                  disabled
                ></upload-file>
                <upload-file
                  :value="
                    $lodash.get(
                      billMessage,
                      'carBodyInfoVo.carBodyRightPic',
                      ''
                    )
                  "
                  title="车身右面"
                  v-if="billMessage.carBodyInfoVo.carBodyRightPic"
                  disabled
                ></upload-file>
                <upload-file
                  :value="
                    $lodash.get(
                      billMessage,
                      'carBodyInfoVo.runningLicenceImg',
                      ''
                    )
                  "
                  title="行驶证"
                  v-if="billMessage.carBodyInfoVo.runningLicenceImg"
                  disabled
                ></upload-file>
                <upload-file
                  :value="
                    $lodash.get(
                      billMessage,
                      'carBodyInfoVo.carInstrumentPanelPic',
                      ''
                    )
                  "
                  title="仪表盘"
                  v-if="billMessage.carBodyInfoVo.carInstrumentPanelPic"
                  disabled
                ></upload-file>
                <!-- <upload-file
                  :value="
                    $lodash.get(billMessage, 'carBodyInfoVo.carRoofPic', '')
                  "
                  title="车顶"
                  v-if="billMessage.carBodyInfoVo.carRoofPic"
                  disabled
                ></upload-file> -->
                <upload-file-list
                  :max="3"
                  :value="
                    $lodash
                      .get(billMessage, 'carBodyInfoVo.carOtherPics', [])
                      .join()
                  "
                  disabled
                  v-if="billMessage.carBodyInfoVo.carOtherPics"
                ></upload-file-list>
              </div>
            </el-descriptions-item>
            <el-descriptions-item label="车单内标签与备注" :span="3">
              {{ billMessage.companyRemark }}
            </el-descriptions-item>
          </el-descriptions>
          <div>
            <p class="title_bar">报价项目</p>
            <el-table
              style="width: 100%"
              class="service-table"
              border
              :data="serviceTableData"
              default-expand-all
              row-key="id"
              :tree-props="{
                children: 'billGoods',
                hasChildren: 'hasChildren',
              }"
              :span-method="spanMethod"
            >
              <el-table-column width="110" label="序号">
                <template #default="{ row }">
                  <div>
                    {{ row.title }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="报价项目名称" width="180">
                <template #default="{ row }">
                  <span v-if="row.name">{{ row.name }}</span>
                  <!-- <div v-else>
              <select-goods-popover
                :carId="carInfo.carId"
                @select="onSelectGoodsItem($event, row, $index)"
              ></select-goods-popover>
            </div> -->
                </template>
              </el-table-column>
              <!-- <el-table-column prop="itemCode" label="报价项目编码" width="180">
            </el-table-column> -->
              <el-table-column label="商品单位" prop="unit" width="180">
              </el-table-column>
              <!-- <el-table-column label="项目标签" width="220">
                <template #default="{ row }">
                  {{
                    $lodash
                      .get(row, 'carBillLabelInfo', [])
                      .map((item) => item.labelName)
                      .join()
                  }}
                </template>
              </el-table-column> -->
              <el-table-column prop="price" label="单价" width="120">
                <template #default="{ row }">
                  <span :key="tableKey + 1">
                    {{ row.price | toThousands }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="discount" label="折扣" width="120">
                <template #default="{ row }">
                  <span :key="tableKey + 2">
                    {{ row.discount || '无' }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="date" label="数量" width="120">
                <template #default="{ row }">
                  <span :key="tableKey + 3">
                    <span v-if="row.showAllNum" class="orange">
                      <p>报价{{ row.num }}</p>
                      <p v-if="row.waitPickNum">待领{{ row.waitPickNum }}</p>
                      <p v-if="row.pickedNum">已领{{ row.pickedNum }}</p>
                    </span>
                    <span v-else>{{ row.num }}</span>
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="date" label="小计" width="180">
                <template #default="{ row }">
                  <div>
                    <span :key="tableKey + 4">
                      {{ row.total | toThousands }}
                    </span>
                    <div
                      style="margin-left: 5px"
                      v-if="![0, '', undefined].includes(row.billDiscount)"
                    >
                      <span>整单折扣后: </span>
                      <span>¥{{ row.billDiscount | toThousands }}</span>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="项目合计">
                <template #default="{ row }">
                  <div v-if="[0, '', undefined].includes(row.billDiscount)">
                    <div>{{ row.itemTotalBefore | toThousands }}</div>
                  </div>
                  <div v-else>
                    <div>{{ row.itemTotalAfter | toThousands }}</div>
                    <del>{{ row.itemTotalBefore | toThousands }}</del>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="施工技师" width="180">
                <template #default="{ row }">
                  <div :key="tableKey + 5">
                    <div
                      v-if="$lodash.get(row, 'technicianInfoVos.length', false)"
                    >
                      <div>
                        {{
                          $lodash
                            .get(row, 'technicianInfoVos', [])
                            .map((item) => item.technicianName)
                            .join('、')
                        }}
                      </div>
                    </div>
                    <!-- <div v-else-if="row.isConstructionFree">免施工</div> -->
                  </div>
                  <div class="orange">
                    {{ row.dispatchTaskState | dict('dispatchTaskState') }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="sellerName" label="业务员" width="180">
              </el-table-column>
              <el-table-column
                prop="address"
                label="施工前图片"
                min-width="300"
              >
                <template #default="{ row }">
                  <div :key="tableKey + 6">
                    <el-popover placement="top" width="450" trigger="click">
                      <upload-file-list
                        disabled
                        v-model="row.preConstructionPics"
                        @input="tableKey = Math.random()"
                      ></upload-file-list>
                      <span
                        style="margin: 0 16px"
                        class="pointer blue"
                        slot="reference"
                        >图片({{
                          $lodash.get(row, 'preConstructionPics', []).length
                        }})</span
                      >
                    </el-popover>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="address"
                label="施工后图片"
                min-width="300"
              >
                <template #default="{ row }">
                  <div :key="tableKey + 7">
                    <el-popover placement="top" width="450" trigger="click">
                      <upload-file-list
                        disabled
                        v-model="row.afterConstructionPics"
                        @input="tableKey = Math.random()"
                      ></upload-file-list>
                      <span
                        style="margin: 0 16px"
                        class="pointer blue"
                        slot="reference"
                        >图片({{
                          $lodash.get(row, 'afterConstructionPics', []).length
                        }})</span
                      >
                    </el-popover>
                  </div>
                </template>
              </el-table-column>
              <!-- <el-table-column
          prop="date"
          label="项目外部标签与备注（客户可见）"
          width="180"
        >
        </el-table-column> -->
              <template slot="empty">
                <div style="border-bottom: 1px solid #ebeef5">未添加服务</div>
              </template>
            </el-table>
            <p
              class="title_bar"
              style="
                overflow: auto;
                word-break: break-all;
                line-height: 20px;
                margin-top: 20px;
              "
            >
              结账收款(
              <span class="tips_wordsr">单据总额￥</span>
              <span>
                <span class="price-number tips_wordsr">{{
                  otherData.paid | toThousands
                }}</span>
                <del
                  v-if="otherData.paid != otherData.total"
                  class="original-price color999"
                  style="margin: 0px 6px"
                  >¥{{ otherData.total | toThousands }}</del
                >
              </span>
              <span class="color333"> =( 项目报价总额：¥</span>
              {{ otherData.total | toThousands }}
              <span v-if="otherData.paid != otherData.total">（</span>
              <span class="color333"> 服务项目：¥</span>
              {{ serviceTotalPrice | toThousands }}
              <span class="color333"> + 商品项目：¥</span>
              {{ goodsTotalPrice | toThousands }}
              <span v-if="otherData.paid != otherData.total" class="color333">
                ）- 整单折扣优惠：¥{{
                  (otherData.total - otherData.paid) | toThousands
                }}</span
              ><span>))</span>
              <el-tooltip
                effect="light"
                placement="bottom-start"
                v-if="billMessage.billType == 1 && billMessage.billState == 1"
              >
                <div
                  slot="content"
                  style="
                    color: #666666;
                    font-size: 13px;
                    line-height: 20px;
                    text-indent: 15px;
                  "
                >
                  包干金额，结账当月显示为查询时点的预分摊<br />金额，结账次月显示为实际分摊金额
                </div>
                <i
                  class="iconfont grow-icon_doubt"
                  style="color: #9a9a9a; margin: 12px 4px 11px 15px"
                ></i>
              </el-tooltip>
              <span
                class="tips_wordsr"
                v-if="billMessage.billType == 1 && billMessage.billState == 1"
                >包干金额：￥ {{ otherData.contractAmount | toThousands }}</span
              >
            </p>
            <el-table
              :data="payTypeTable"
              border
              :header-cell-style="{
                background: '#FAFAFA',
                color: '#000000',
                fontSize: '14px',
                fontWeight: '500',
              }"
              :span-method="objectSpanMethod"
            >
              <el-table-column width="60" label="序号" type="index">
              </el-table-column>
              <el-table-column prop="payType" label="支付方式" mix-width="180">
              </el-table-column>
              <el-table-column
                prop="offerDetails"
                label="优惠、抵扣明细"
                mix-width="100"
              >
              </el-table-column>
              <el-table-column prop="money" label="金额" mix-width="100">
              </el-table-column>

              <template slot="append">
                <div class="table_footer">
                  <div>
                    <p class="table_footer_deripto">
                      结账收银员：{{ payInfo.cashier }}
                    </p>
                    <p class="table_footer_deriptt">
                      结账备注：{{ payInfo.remark }}
                    </p>
                  </div>
                  <div>
                    <p class="table_footer_deripto">
                      收款总额：￥{{ payInfoTotal | toThousands }}
                    </p>
                    <p class="table_footer_deriptt">
                      结账时间：{{ payInfo.checkDate }}
                    </p>
                  </div>
                </div>
              </template>
            </el-table>
            <p class="title_bar">
              销账收款<span class="tips_wordsr"
                >(未销账：￥{{ notChargeOffs - chargeOffsAmount }})</span
              >
            </p>
            <el-table
              :data="tableData"
              border
              :header-cell-style="{
                background: '#FAFAFA',
                color: '#000000',
                fontSize: '14px',
                fontWeight: '500',
              }"
              :span-method="arraySpanMethod"
            >
              <el-table-column width="60" label="序号" prop="sort">
              </el-table-column>
              <el-table-column
                prop="writeOffNum"
                label="销账收款单号"
                width="180"
              >
              </el-table-column>
              <el-table-column
                prop="repaymentDateTime"
                label="销账时间"
                width="100"
              >
              </el-table-column>
              <el-table-column
                prop="cashier"
                label="销账收银人"
                min-width="100"
              >
              </el-table-column>
              <el-table-column label="支付方式" width="100" prop="payType">
              </el-table-column>
              <el-table-column label="收款金额" min-width="100" prop="money">
              </el-table-column>
            </el-table>
            <div class="flex-x-end foot-btn">
              <el-button type="primary" size="small">打印结算单</el-button>
              <el-button size="small" style="background: #f0f2f5"
                >打印结算小票</el-button
              >
            </div>
          </div>
        </el-tab-pane>
        <!-- <el-tab-pane label="故障检测报告" name="second">
          <car-report></car-report>
        </el-tab-pane>
        <el-tab-pane label="操作记录" name="third">操作记录</el-tab-pane>   暂时隐藏-->
      </el-tabs>
    </div>
    <!-- 客户备注抽屉 -->
    <remarks-drawer
      v-if="remarksDrawerVisibility"
      :remarksDrawerVisibility.sync="remarksDrawerVisibility"
      :clientRemark="carInfo.clientRemark"
    ></remarks-drawer>
  </el-drawer>
</template>
<script>
import carReport from '@/views/customerManagement/carReport.vue';

import uploadFile from '@/components/uploadFile';
import uploadFileList from '@/components/uploadFileList';

import { getDHMS } from '@/utils/tools/date';

import { levelIcons, levelIconsWord } from '@/const/client';

// 接口
import { getCarBillDetail } from '@/api/carBill';
import { clientPersonalAccounts } from '@/api/customer/customer';
//客户备注弹窗
import remarksDrawer from '@/views/carBill/editCarBill/remarksDrawer.vue';

export default {
  name: 'detailsList',
  components: { uploadFile, uploadFileList, remarksDrawer },
  props: {
    billId: { type: [Number, String], default: '' },
    dialogTableVisible: { type: Boolean, default: false },
  },
  data() {
    return {
      activeName: 'first',
      tableData: [],
      payTypeTable: [],
      payInfo: {},
      payInfoTotal: '',

      // 车单信息
      billMessage: {},
      // 客户信息
      carInfo: {},
      // 他人自费客户信息
      payerInfo: {},
      // 理赔信息
      carBillInsuranceInfo: {},
      // 索赔信息
      carBillUndertakeInfo: {},
      // 服务项,
      serviceTableData: [],
      // 单据其他数据
      otherData: {},
      // 服务项总额
      serviceTotalPrice: 0,
      // 商品总额
      goodsTotalPrice: 0,
      // 客户等级对应图标
      levelIcons,

      levelIconsWord,

      tableKey: 999,

      payTypeList: [],
      otherPay: [],
      chargeOffsAmount: '', // 销账金额合计
      notChargeOffs: '', //未销账金额

      //客户备注抽屉可见性
      remarksDrawerVisibility: false,
    };
  },
  computed: {
    distancePlannedTime() {
      if (this.billMessage.planFinishTime) {
        let now = new Date();
        let plan = new Date(this.billMessage.planFinishTime);
        let difference = plan - now;
        let word = difference > 0 ? '剩余' : '已超时';
        difference = Math.abs(difference);
        let res = getDHMS(difference);
        return `${word}${res[0] ? res[0] + '天' : ''}${res[1]}时${res[2]}分`;
      } else {
        return '';
      }
    },
  },
  activated() {
    this.getData();
    console.log('33243243', this.billId);
  },
  created() {
    this.getData();
  },
  methods: {
    //关闭抽屉
    handleClose() {
      this.$emit('update:dialogTableVisible', false);
      this.$emit('update:billId', '');
    },
    handleClick() {},
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      // if (columnIndex === 1 || columnIndex === 0) {
      //   if (row.span) {
      //     return row.span;
      //   } else {
      //     return [0, 0];
      //   }
      // }
    },
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex < 4) {
        if (row.isParent) {
          return {
            rowspan: row.rowspan,
            colspan: 1,
          };
        } else if (row.isChild) {
          return [0, 0];
        } else {
          return [1, 1];
        }
      }
    },

    /**
     * 获取车单数据
     */
    getData() {
      getCarBillDetail(this.billId).then((res) => {
        let {
          billMessage,
          carBillClientMessage,
          carBillConsumerMessage,
          carBillInsuranceInfo,
          carBillUndertakeInfo,
          serviceItems,
          ...otherData
        } = res;
        this.billMessage = billMessage || {};
        this.carInfo = carBillClientMessage || {};
        this.payerInfo = carBillConsumerMessage || {};
        this.carBillInsuranceInfo = carBillInsuranceInfo || {};
        this.carBillUndertakeInfo = carBillUndertakeInfo || {};
        this.otherData = otherData;
        // var serviceTableData = serviceItems.map((service) => {
        //   service.name = service.serviceItemName;
        //   service.id = service.billServiceId;

        //   service.billGoods = service.goodsInfoVos;
        //   if (service?.billGoods?.length) {
        //     service.billGoods?.forEach((item) => {
        //       item.name = item.goodsName;
        //       item.id = item.billGoodsId;
        //       item.parentId = service.id;
        //     });
        //   }
        //   return service;
        // });
        var serviceTableData = serviceItems.map((service) => {
          service.id = service.billServiceId;
          if (!service.id) {
            service.id = Math.random();
            service.goodsInfoVos?.forEach((g) => {
              g.showAllNum = true;
              g.num = 0;
            });
          }
          service.name = service.serviceItemName;
          service.code = service.serviceItemCode;

          service.billGoods = service.goodsInfoVos;
          if (service?.billGoods?.length) {
            service.billGoods?.forEach((item) => {
              item.id = item.billGoodsId;
              if (!item.id) item.id = Math.random();
              item.name = item.goodsName;
              item.code = item.goodsCode;
              item.parentId = service.id;

              /**
               * 判断是否显示待领
               * 当报价单商品=材料单商品数，且商品待领数非0时，显示待领数量
               * 当报价商品!=材料单商品时，显示当前报价数、待领数、已领数。当待领数为0时，不显示待领0 字样。
               */
              let materialCount =
                (item.pickedNum || 0) + (item.waitPickNum || 0);
              if (
                (materialCount && item.num != materialCount) ||
                item.waitPickNum > 0
              ) {
                item.showAllNum = true;
              }
            });
          }

          return service;
        });
        this.setIndex(serviceTableData);
        this.serviceTableData = serviceTableData;
        this.calculateTotalAmount();
        this.getPersonalAccounts();
      });
    },

    //获取收账情况数据
    getPersonalAccounts() {
      clientPersonalAccounts(this.billId).then((res) => {
        this.payInfo = res.data;
        this.tableData = res.data.writeOff;
        // this.otherPay = res.data.paid.map((item) => {
        //   return {
        //     payWay: item.payType,
        //     money: item.money,
        //     offerDetails: '',
        //     span: {
        //       rowspan: 1,
        //       colspan: 1,
        //     },
        //   };
        // });
        // this.payTypeTable = [
        //   {
        //     payWay: '优惠/抵扣',
        //     offerDetails: '优惠券',
        //     span: {
        //       rowspan: 6,
        //       colspan: 1,
        //     },
        //     money: res.data.couponDeductionAmount,
        //   },
        //   {
        //     payWay: '优惠/抵扣',
        //     offerDetails: '定金抵扣',
        //     money: res.data.depositAmount,
        //   },
        //   {
        //     payWay: '优惠/抵扣',
        //     offerDetails: '会员卡(卡种+卡号)',
        //     money: res.data.memberCard,
        //   },
        //   {
        //     payWay: '优惠/抵扣',
        //     offerDetails: '股本抵扣',
        //     money: res.data.bonusAmount,
        //   },
        //   {
        //     payWay: '优惠/抵扣',
        //     offerDetails: '分红抵扣',
        //     money: res.data.shareCapitalAmount,
        //   },
        //   {
        //     payWay: '优惠/抵扣',
        //     offerDetails: '挂账',
        //     money: res.data.hang,
        //   },
        // ];
        // this.payTypeTable = res.data.paid.map((item) => {
        //   return {
        //     payWay: item.payType,
        //     money: item.money,
        //     offerDetails: '',
        //   };
        // });
        this.payInfoTotal = res.data.paid
          .reduce((prev, item) => {
            return prev + +item.money || 0;
          }, 0)
          .toFixed(2);
        // 自定义处理表格序号
        // let sort = 0;
        // for (let n in this.payTypeTable) {
        //   if (this.payTypeTable[n].payWay != this.payTypeTable[n - 1]?.payWay) {
        //     sort += 1;
        //     this.$set(this.payTypeTable[n], 'sort', sort);
        //   }
        // }
        // 过滤掉不是包干业务的时候不显示挂账
        // if (this.billMessage.billType != 1) {
        //   this.payTypeTable = res.data.paid.filter(
        //     (item) => item.payType != '挂账'
        //   );
        //   return;
        // }
        this.payTypeTable = res.data.paid;
        //存在挂账时取挂账的金额,次金额为销账收款的金额
        this.payTypeTable.map((item) => {
          if (item.payType == '挂账') {
            this.notChargeOffs = item.money;
          }
        });

        this.handleList();
      });
    },
    //处理销账收款的表格
    handleList() {
      var arr = [];
      var cloneData = this.$lodash.cloneDeep(this.tableData);
      let rowIndex = 0;
      cloneData.forEach((item) => {
        if (item.paid?.length) {
          item.rowspan = item.paid.length;
          item.paid.forEach((el, index) => {
            if (index === 0) {
              el.index = ++rowIndex;
              el.isParent = true;
              el.rowspan = item.paid.length;
            } else {
              el.isChild = true;
            }
            arr.push(
              Object.assign(this.$lodash.cloneDeep(el), {
                id: item.id,
                cashier: item.cashier,
                repaymentDateTime: item.repaymentDateTime,
                writeOffNum: item.writeOffNum,
                money: el.money,
                payType: el.payType,
              })
            );
          });
        } else {
          item.index = ++rowIndex;
          arr.push(item);
        }
      });
      this.tableData = arr;
      //处理数据总额相加
      this.chargeOffsAmount = this.tableData
        .reduce((prev, item) => {
          return prev + +item.money || 0;
        }, 0)
        .toFixed(2);
    },
    /**
     * 表格合并方法
     */
    spanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex > 8) {
        if (!row.parentId) {
          var length = row.billGoods?.length || 0;
          if (length) {
            return {
              rowspan: length + 1,
              colspan: 1,
            };
          } else {
            return [1, 1];
          }
        } else {
          return [0, 0];
        }
      }
    },
    /**
     * 设置序号
     */
    setIndex(arr, indexArr = [], children = 'billGoods') {
      arr.forEach((item, index) => {
        let cloneArr = [];
        if (indexArr.length) {
          cloneArr = indexArr.concat(index + 1);
        } else {
          cloneArr.push(index + 1);
        }
        item.title = cloneArr.join('.');
        if (item[children]?.length) {
          this.setIndex(item[children], cloneArr);
        }
      });
    },
    /**
     * 计算总金额
     */
    calculateTotalAmount() {
      // 服务项目报价
      var servicePrice = 0;
      // 商品报价
      var goodsPrice = 0;

      this.serviceTableData.forEach((service) => {
        let itemTotalBefore = 0;
        if (+service.total) {
          servicePrice += +service.total;
          itemTotalBefore += +service.total;
        }
        if (service.billGoods?.length) {
          service.billGoods.forEach((item) => {
            if (+item.total) {
              goodsPrice += +item.total;
              itemTotalBefore += +item.total;
            }
          });
        }
        if (!service.itemTotalBefore) service.itemTotalBefore = itemTotalBefore;
      });
      this.serviceTotalPrice = servicePrice;
      this.goodsTotalPrice = goodsPrice;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-descriptions-item__label.is-bordered-label {
  vertical-align: middle;
  color: #666;
  text-align: center;
  background: #eef7f3 !important;
}
.car_stl {
  font-size: 60px;
  line-height: 90px;
  margin: 0px 20px;
  color: #666666;
}
.car_number {
  font-size: 20px;
  color: #333333;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  margin-right: 13px;
}
.custer_wrd {
  color: #666;
  font-size: 14px;
  margin-left: 4px;
  margin-top: 2px;
}
.title_bar {
  height: 60px;
  line-height: 60px;
  font-size: 14px;
}

.table_footer {
  margin-top: 10px;
  display: flex;
  height: 60px;
  margin: 16px 21px;
  line-height: 30px;
  justify-content: space-between;
  .table_footer_deripto {
    color: #333333;
    font-size: 16px;
  }
  .table_footer_deriptt {
    color: #666666;
    font-size: 14px;
  }
}
.tips_wordsr {
  color: $red;
  font-size: 14px;
}
.tips_words_dec {
  text-decoration: line-through;
  color: #333333;
  font-size: 14px;
}
.tips_wordsr_thr {
  font-size: 14px;
  color: #333333;
  margin-right: 20px;
}
.foot-btn {
  margin-top: 100px;
  padding: 30px 30px;
  box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.1);
}
.car_state {
  display: flex;
  margin: 30px 0px;
  justify-content: space-between;
  .car_state_sh {
    p:nth-child(0) {
      color: #333333;
      font-size: 30px;
    }
    p:nth-child(2) {
      color: $orange;
      font-size: 16px;
      margin-top: 12px;
    }
    span {
      font-size: 14px;
    }
  }
}
.cart_sty {
  width: 100%;
  height: 90px;
  border: 1px solid #f2f2f2;
  border-bottom: 0px;
  display: flex;
  > .car-logo {
    width: 69px;
    height: 69px;
    margin: 10px 21px;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.imgs {
  display: flex;
  > img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    & + img {
      margin-left: 10px;
    }
  }
}
.bill-process {
  display: flex;
  padding-right: 20px;
  font-size: 12px;
  color: #999;
  .state-name {
    display: flex;
    flex-direction: column;
    text-align: center;
    span:first-child {
      font-size: 14px;
    }
    .time {
      margin-top: 5px;
    }
  }
  .split-string {
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: -8px 10px 0;
    .top-text {
      height: 17px;
      box-sizing: border-box;
      // position: absolute;
      // bottom: 100%;
      padding-bottom: 5px;
      white-space: nowrap;
      &.plan-time {
        // color: #333;
      }
    }
    .line {
      height: 1px;
      width: 100%;
      background: #d8d8d8;
      min-width: 50px;
    }
    .bottom-text {
      margin-top: 10px;
    }
  }
}
.car-info {
  .car-vehicle-type {
    color: #42bbb8;
  }
  .car-uniqueId {
    color: #3eb1e0;
  }
  .car-brand {
    color: #55b78e;
  }
  .history {
    color: #e84931;
  }
  .tag {
    display: inline-block;
    margin-left: 15px;
    padding: 4px 6px;
    border-radius: 4px;
    border: 1px solid rgba(66, 187, 184, 0.1);
    font-size: 12px;
  }
}
// 用户信息 车辆信息和他人自费共用
.client-base-info {
  margin-top: 10px;
  .icon-person {
    color: #44bf86;
    margin-left: 0;
  }
  .client-name {
    margin-left: 10px;
    color: #333;
  }
  .client-phone {
    margin-left: 15px;
    color: #333;
  }
  .icon_sex {
    color: $blue;
  }
  .icon_grade {
    color: $blue;
  }
  .grow-icon_girl {
    color: #f48080;
  }
  .icon_wechat {
    color: #999;
  }
  .icon_wechat_active {
    color: #00c800;
  }
  i {
    margin-left: 15px;
  }
}
// 不显示收起图标
.service-table.el-table ::v-deep .el-table__expand-icon {
  display: none;
}
.service-table.el-table ::v-deep .el-table__row--level-1 {
  background: #f5f7fa;
}
</style>
