var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { ref: "baseContainer", attrs: { paddingTop: "" } },
    [
      [
        _c(
          "div",
          [
            _c(
              "el-form",
              {
                staticClass: "filter-form",
                attrs: {
                  model: _vm.form,
                  "label-width": "100px",
                  size: "small",
                },
              },
              [
                _c("div", { staticClass: "flex-x-between" }, [
                  _c(
                    "div",
                    { staticClass: "flex-warp flex-shrink0" },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c("template", { slot: "label" }, [
                            _c(
                              "div",
                              [
                                _vm._v(" 操作门店 "),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "light",
                                      content:
                                        "定损单的操作门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)",
                                      placement: "bottom-start",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "iconfont grow-icon_doubt",
                                      staticStyle: { color: "#9a9a9a" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("operateCompanyCascader", {
                            attrs: {
                              operateCompanyUrl: _vm.form.operateCompanyUrl,
                            },
                            model: {
                              value: _vm.form.operateCompanyId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "operateCompanyId", $$v)
                              },
                              expression: "form.operateCompanyId",
                            },
                          }),
                        ],
                        2
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "车牌号" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入", clearable: "" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.doSearch.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.form.carNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "carNumber", $$v)
                              },
                              expression: "form.carNumber",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "报案号" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入", clearable: "" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.doSearch.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.form.caseNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "caseNum", $$v)
                              },
                              expression: "form.caseNum",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "flex" }, [
                    _c("div", { staticClass: "el-form-item--small" }, [
                      _c(
                        "div",
                        {
                          staticClass: "el-form-item__label pointer",
                          on: {
                            click: function ($event) {
                              _vm.showMore = !_vm.showMore
                            },
                          },
                        },
                        [
                          _vm._v(" 更多筛选 "),
                          _c("i", {
                            class: {
                              primary: true,
                              "el-icon-arrow-right": !_vm.showMore,
                              "el-icon-arrow-down": _vm.showMore,
                            },
                          }),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "btn_search",
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.doSearch },
                            },
                            [_vm._v("查询")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "btn_search",
                              attrs: { size: "small" },
                              on: { click: _vm.empty },
                            },
                            [_vm._v("置空")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "flex-warp" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "接车单号" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入", clearable: "" },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.doSearch.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.form.billCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "billCode", $$v)
                            },
                            expression: "form.billCode",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "签约客户" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              filterable: "",
                              clearable: "",
                              remote: "",
                              placeholder: "请输入姓名",
                              "remote-method": _vm.searchClient,
                              loading: _vm.searchClientLoading,
                            },
                            model: {
                              value: _vm.form.clientMessage,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "clientMessage", $$v)
                              },
                              expression: "form.clientMessage",
                            },
                          },
                          _vm._l(_vm.clientList, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.clientName, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "车单状态" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { clearable: "", placeholder: "请选择" },
                            model: {
                              value: _vm.form.billState,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "billState", $$v)
                              },
                              expression: "form.billState",
                            },
                          },
                          _vm._l(_vm.carBillState, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "接车进店日期" } },
                      [
                        _c("el-date-picker", {
                          staticClass: "w100",
                          attrs: {
                            type: "daterange",
                            "unlink-panels": "",
                            "range-separator": "至",
                            "start-placeholder": "起始时间",
                            "end-placeholder": "截止时间",
                            "value-format": "yyyy-MM-dd",
                          },
                          model: {
                            value: _vm.form.intoStoreTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "intoStoreTime", $$v)
                            },
                            expression: "form.intoStoreTime",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.showMore
                      ? [
                          _c(
                            "el-form-item",
                            { attrs: { label: "回款认领日期" } },
                            [
                              _c("el-date-picker", {
                                staticClass: "w100",
                                attrs: {
                                  type: "daterange",
                                  "unlink-panels": "",
                                  "range-separator": "至",
                                  "start-placeholder": "起始时间",
                                  "end-placeholder": "截止时间",
                                  "value-format": "yyyy-MM-dd",
                                },
                                model: {
                                  value: _vm.form.paymentCollectionTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "paymentCollectionTime",
                                      $$v
                                    )
                                  },
                                  expression: "form.paymentCollectionTime",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "出损日期" } },
                            [
                              _c("el-date-picker", {
                                staticClass: "w100",
                                attrs: {
                                  type: "daterange",
                                  "unlink-panels": "",
                                  "range-separator": "至",
                                  "start-placeholder": "起始时间",
                                  "end-placeholder": "截止时间",
                                  "value-format": "yyyy-MM-dd",
                                },
                                model: {
                                  value: _vm.form.damageOfTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "damageOfTime", $$v)
                                  },
                                  expression: "form.damageOfTime",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "提交定损日期" } },
                            [
                              _c("el-date-picker", {
                                staticClass: "w100",
                                attrs: {
                                  type: "daterange",
                                  "unlink-panels": "",
                                  "range-separator": "至",
                                  "start-placeholder": "起始时间",
                                  "end-placeholder": "截止时间",
                                  "value-format": "yyyy-MM-dd",
                                },
                                model: {
                                  value: _vm.form.breakageTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "breakageTime", $$v)
                                  },
                                  expression: "form.breakageTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            ),
          ],
          1
        ),
      ],
      [
        _c(
          "div",
          { staticStyle: { display: "flex", "justify-content": "end" } },
          [
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 606,
                    expression: "606",
                  },
                ],
                staticClass: "blue",
                attrs: { size: "small", type: "text" },
                on: {
                  click: function ($event) {
                    _vm.showExportDialog = true
                  },
                },
              },
              [
                _vm._v("导出列表"),
                _c("i", {
                  staticClass: "iconfont grow-icon_daochu el-icon--right",
                }),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "flex h100" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "fixedScrollBar",
                    rawName: "v-fixedScrollBar",
                    value: _vm.$refs.baseContainer,
                    expression: "$refs.baseContainer",
                  },
                ],
                staticClass: "custom-table",
                attrs: { data: _vm.tableData, border: "" },
              },
              [
                _c("el-table-column", {
                  attrs: { type: "index", label: "序号", width: "80" },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "180",
                    label: "操作门店",
                    prop: "operateCompanyName",
                  },
                }),
                _c("el-table-column", {
                  attrs: { width: "180", label: "报案号", prop: "caseNum" },
                }),
                _c("el-table-column", {
                  attrs: { width: "180", label: "车牌号", prop: "carNumber" },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "150",
                    label: "签约客户",
                    prop: "clientName",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "200",
                    label: "进店时间",
                    prop: "intoStoreTime",
                  },
                }),
                _c("el-table-column", {
                  attrs: { width: "200", label: "接车单号", prop: "billCode" },
                }),
                _c("el-table-column", {
                  attrs: { width: "100", label: "定损单状态" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "p",
                            [
                              row.lossDeterminationState === 2 &&
                              row.investigationState === 2
                                ? [_vm._v("待复勘")]
                                : row.lossDeterminationState === 2 &&
                                  row.recoveryState === 2
                                ? [_vm._v("待收旧")]
                                : [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("dict")(
                                            row.lossDeterminationState,
                                            "lossDeterminationState"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ],
                            ],
                            2
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { width: "100", label: "车单状态" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("dict")(row.billState, "carBillState")
                                ) +
                                " "
                            ),
                            row.billOtherState
                              ? _c("span", { staticClass: "red" }, [
                                  _vm._v(
                                    "(" + _vm._s(row.billOtherState) + ")"
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _c("p", { staticClass: "color999" }),
                          row.dispatchState !== undefined
                            ? _c("div", [
                                _c("span", [_vm._v(" /")]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("dict")(
                                        row.dispatchState,
                                        "dispatchState"
                                      )
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "180",
                    label: "报损时间",
                    prop: "reportingLossesTime",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "180",
                    label: "调度时间",
                    prop: "dispatchTime",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "180",
                    label: "拍照对件时间",
                    prop: "photographTime",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "180",
                    label: "提交定损时间",
                    prop: "breakageTime",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "180",
                    label: "出损时间",
                    prop: "damageOfTime",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "180",
                    label: "复勘时间",
                    prop: "investigationTime",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "180",
                    label: "收旧时间",
                    prop: "recoveryTime",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "180",
                    label: "提交资料时间",
                    prop: "submissionTime",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "200",
                    label: "回款时间",
                    prop: "paymentCollectionTime",
                  },
                }),
                _c("el-table-column", {
                  attrs: { width: "100", label: "车损金额" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("toThousands")(row.carDamageAmount)
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { width: "100", label: "预估金额" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("toThousands")(row.estimatedAmount)
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { width: "100", label: "核损金额" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("toThousands")(row.lossAssessmentAmount)
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { width: "100", label: "定损金额" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("toThousands")(row.lossAmount)) +
                              " "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { width: "100", label: "单据金额" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("toThousands")(row.documentAmount)
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "100",
                    label: "毛利率",
                    prop: "grossProfitRate",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _vm._v(" " + _vm._s(row.grossProfitRate) + " "),
                          row.grossProfitRate !== undefined
                            ? _c("span", [_vm._v("%")])
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c("export-dialog", {
          attrs: {
            visibility: _vm.showExportDialog,
            total: _vm.total,
            pageOption: _vm.pageOption,
            filterForm: _vm.form,
            url: _vm.exportUrl,
          },
          on: {
            "update:visibility": function ($event) {
              _vm.showExportDialog = $event
            },
          },
        }),
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c("pagination", {
            attrs: { total: _vm.total },
            on: { pageChange: _vm.changePage },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }