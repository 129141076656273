var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visibility,
        width: "50%",
        "before-close": _vm.handleClose,
        "close-on-click-modal": false,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticStyle: { "margin-top": "18px" },
          attrs: {
            size: "small",
            model: _vm.editForm,
            rules: _vm.rules,
            "label-width": "80px",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "品名", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入品名" },
                        model: {
                          value: _vm.editForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "name", $$v)
                          },
                          expression: "editForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品分类", prop: "class" } },
                    [
                      _c("select-goods-class-cascader", {
                        attrs: {
                          value: [
                            _vm.editForm.firstCategoryId,
                            _vm.editForm.secondCategoryId,
                          ],
                        },
                        on: {
                          select: _vm.handleSelectGoodsClass,
                          change: _vm.handleBlur,
                          blur: _vm.handleBlur,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "俗称", prop: "vulgo" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入俗称" },
                        model: {
                          value: _vm.editForm.vulgo,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "vulgo", $$v)
                          },
                          expression: "editForm.vulgo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "启用状态", prop: "isActive" } },
                    [
                      _c("el-switch", {
                        attrs: { "active-value": 1, "inactive-value": 0 },
                        model: {
                          value: _vm.editForm.isActive,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "isActive", $$v)
                          },
                          expression: "editForm.isActive",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "项目", prop: "projContent" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: 4,
                          placeholder: "请输入项目",
                        },
                        model: {
                          value: _vm.editForm.projContent,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "projContent", $$v)
                          },
                          expression: "editForm.projContent",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.save },
            },
            [_vm._v(" 确 定 ")]
          ),
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.handleClose } },
            [_vm._v(" 取 消 ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }