<template>
  <div class="box">
    <div class="container">
      <div class="avatar">
        <img :src="avatar" alt="头像" />
      </div>
      <div class="info">
        <el-row :gutter="20">
          <el-col :span="6" :offset="0"
            ><span class="user-name">{{ userInfo.realName }}</span></el-col
          >
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6" :offset="0">手机号：</el-col>
          <el-col :span="10" :offset="0">{{ userInfo.phone }}</el-col>
          <el-col :span="8" :offset="0">
            <span
              class="pointer blue"
              @click="changePhoneNumberDialogVisibility = true"
              >变更手机号</span
            >
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6" :offset="0">性别：</el-col>
          <el-col :span="10" :offset="0">{{
            userInfo.gender | dict('gender')
          }}</el-col>
        </el-row>
        <el-row type="flex" justify="space-between" :gutter="20">
          <el-col :span="6" :offset="0">生日：</el-col>
          <el-col :span="10" :offset="0">{{ userInfo.birthDay }}</el-col>
          <el-col :span="8" :offset="0">
            <span
              class="pointer blue"
              @click="changePasswordDialogVisibility = true"
              >修改密码</span
            >
          </el-col>
        </el-row>
      </div>

      <change-phone-number-dialog
        v-if="changePhoneNumberDialogVisibility"
        :visibility.sync="changePhoneNumberDialogVisibility"
        :accountId="userInfo.accountId"
      ></change-phone-number-dialog>

      <change-password-dialog
        v-if="changePasswordDialogVisibility"
        :visibility.sync="changePasswordDialogVisibility"
        :needOldPassword="true"
        :phoneNumber="userInfo.phone"
      ></change-password-dialog>
    </div>
  </div>
</template>

<script>
import ChangePhoneNumberDialog from '@/components/businessComponent/changePhoneNumberDialog.vue';
import ChangePasswordDialog from '@/components/businessComponent/changePasswordDialog.vue';

import { getAccountInfo } from '@/api/system';

export default {
  name: 'userInfo',
  components: {
    ChangePhoneNumberDialog,
    ChangePasswordDialog,
  },
  data() {
    return {
      avatar: require('@/assets/img/default_avatar.png'),
      userInfo: {
        phone: '',
        realName: '',
        gender: '',
        birthDay: '',
      },

      changePhoneNumberDialogVisibility: false,
      changePasswordDialogVisibility: false,
    };
  },
  created() {
    this.getAccountInfo();
  },
  methods: {
    getAccountInfo() {
      getAccountInfo().then((res) => {
        this.userInfo = res;
        if (res.profilePicture) {
          this.avatar = res.profilePicture;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container {
  width: 432px;
  margin: auto;
  display: flex;
  .avatar {
    width: 100px;
    height: 100px;
    img {
      width: 100%;
      height: 100%;
      border: 3px solid #f0f2f5;
      border-radius: 50%;
      object-fit: contain;
      overflow: hidden;
    }
  }
  .info {
    flex: 1;
    margin-left: 20px;
    .el-row {
      font-size: 14px;
      font-weight: 400;
      .user-name {
        margin-right: 13px;
        font-size: 16px;
        font-weight: 500;
      }
      & + .el-row {
        margin-top: 12px;
      }
      .el-col:nth-child(1) {
        text-align: right;
      }
      span {
        font-size: 14px;
      }
    }
  }
}
</style>
