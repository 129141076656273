var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("search", {
        ref: "searchRef",
        attrs: { type: "SettlementFollowUp" },
        on: { toggleStatus: _vm.setTableHei, submit: _vm.searchSubmit },
      }),
      _c(
        "div",
        { staticClass: "table-wrap" },
        [
          _c(
            "div",
            { ref: "exportRef", staticClass: "export-btn-wrap" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "auth",
                      rawName: "v-auth",
                      value: 803,
                      expression: "803",
                    },
                  ],
                  staticClass: "export-btn",
                  attrs: { plain: "" },
                  on: {
                    click: function ($event) {
                      _vm.importIsShow = true
                    },
                  },
                },
                [_vm._v("导入")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "auth",
                      rawName: "v-auth",
                      value: 789,
                      expression: "789",
                    },
                  ],
                  staticClass: "export-btn",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.showExportDialog = true
                    },
                  },
                },
                [_vm._v("导出")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              staticClass: "new-table",
              attrs: { data: _vm.tableData, height: _vm.tableHei },
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  fixed: "",
                  label: "序号",
                  type: "index",
                  width: "60",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  fixed: "",
                  label: "操作门店",
                  prop: "companyName",
                  "show-overflow-tooltip": "",
                  width: "150",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  fixed: "",
                  label: "车单号",
                  prop: "billCode",
                  "show-overflow-tooltip": "",
                  width: "220",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-link",
                          {
                            staticStyle: { color: "#3fa1e6" },
                            attrs: { underline: false, type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.goCarOrderDetail(row.billId)
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.billCode))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  fixed: "",
                  label: "车牌号",
                  prop: "carNumber",
                  "show-overflow-tooltip": "",
                  width: "150",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  fixed: "",
                  label: "客户名称",
                  prop: "clientName",
                  "show-overflow-tooltip": "",
                  width: "150",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "进店时间",
                  prop: "dateCreated",
                  "show-overflow-tooltip": "",
                  width: "150",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "结算时间",
                  prop: "checkDate",
                  "show-overflow-tooltip": "",
                  width: "150",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "挂账金额",
                  prop: "unclearedDebtFixed",
                  "show-overflow-tooltip": "",
                  width: "120",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "已收金额",
                  prop: "alreadyPaid",
                  "show-overflow-tooltip": "",
                  width: "120",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "业务来源",
                  prop: "billSourceTypeName",
                  "show-overflow-tooltip": "",
                  width: "120",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "保险公司",
                  prop: "insuranceCompanyName",
                  "show-overflow-tooltip": "",
                  width: "120",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "事故责任",
                  prop: "dutyTypeDesc",
                  "show-overflow-tooltip": "",
                  width: "120",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "填写人",
                  prop: "fillUserName",
                  "show-overflow-tooltip": "",
                  width: "120",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "分类",
                  prop: "categoryTypeDesc",
                  "show-overflow-tooltip": "",
                  width: "120",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "未核销金额",
                  prop: "receivedUnWriteOffAmount",
                  "show-overflow-tooltip": "",
                  width: "120",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "说明",
                  prop: "remark",
                  "show-overflow-tooltip": "",
                  width: "150",
                },
              }),
              _c("el-table-column", {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 790,
                    expression: "790",
                  },
                ],
                attrs: {
                  align: "center",
                  fixed: "right",
                  label: "操作",
                  width: "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "div",
                          { staticClass: "operate-center" },
                          [
                            _c(
                              "el-link",
                              {
                                staticClass: "table-text",
                                attrs: { underline: false, type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.edit(row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pagination-wrap" },
            [
              _c("div", { staticClass: "total-text" }, [
                _vm._v("共" + _vm._s(_vm.total) + "条"),
              ]),
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.currentPage,
                  "page-size": _vm.pageSize,
                  "page-sizes": [20, 30, 50, 100],
                  total: _vm.total,
                  layout: "->, sizes, prev, pager, next, jumper",
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.EditVisible, title: "编辑", width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.EditVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              staticClass: "form-wrap",
              attrs: {
                inline: true,
                model: _vm.EditForm,
                "label-width": "110px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分类:" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择案件分类" },
                      model: {
                        value: _vm.EditForm.categoryType,
                        callback: function ($$v) {
                          _vm.$set(_vm.EditForm, "categoryType", $$v)
                        },
                        expression: "EditForm.categoryType",
                      },
                    },
                    _vm._l(_vm.categoryData, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "未核销金额:" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入" },
                    model: {
                      value: _vm.EditForm.receivedUnWriteOffAmount,
                      callback: function ($$v) {
                        _vm.$set(_vm.EditForm, "receivedUnWriteOffAmount", $$v)
                      },
                      expression: "EditForm.receivedUnWriteOffAmount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "说明:" } },
                [
                  _c("el-input", {
                    attrs: {
                      rows: 5,
                      clearable: "",
                      maxlength: "200",
                      placeholder: "请输入说明",
                      "show-word-limit": "",
                      type: "textarea",
                    },
                    model: {
                      value: _vm.EditForm.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.EditForm, "remark", $$v)
                      },
                      expression: "EditForm.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.editSave } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.importIsShow
        ? _c("import-dialog", {
            attrs: {
              visibility: _vm.importIsShow,
              uploadFileApi: _vm.batchImportUrl,
              getLastResultApi: _vm.batchImportResultUrl,
              importFailDetail: _vm.batchImportFailUrl,
              showTip: false,
              templateFileUrl:
                "https://growxny-dev-center.oss-cn-guangzhou.aliyuncs.com/template/%E5%BA%94%E6%94%B6%E8%B4%A6%E6%AC%BE%E7%90%86%E8%B5%94-%E7%90%86%E8%B5%94%E7%BB%84%E8%B7%9F%E8%BF%9B-%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF_20241111.xlsx",
              title: "批量导入",
            },
            on: {
              "update:visibility": function ($event) {
                _vm.importIsShow = $event
              },
            },
          })
        : _vm._e(),
      _c("export-dialog", {
        attrs: {
          filterForm: _vm.form,
          pageOption: _vm.pageOption,
          setWid: "30%",
          total: _vm.total,
          url: _vm.exportUrl,
          visibility: _vm.showExportDialog,
        },
        on: {
          "update:visibility": function ($event) {
            _vm.showExportDialog = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }