<template>
  <div>
    <div class="lie_one">
      <p class="title_word">允许一车多单</p>
      <div class="switch">
        <el-switch
          v-model="value1"
          active-color="#33ab79"
          inactive-color="#999999"
        >
        </el-switch>
      </div>

      <p class="tip_word">允许一台车开多张单</p>
    </div>
    <div class="all_liet">
      <div class="lie_two">
        <p class="title_word">使用云服务</p>
        <div class="switch">
          <el-switch
            v-model="value2"
            active-color="#33ab79"
            inactive-color="#999999"
            @change="chice1(value2)"
          >
          </el-switch>
        </div>
        <p class="tip_word">
          开启时，接车单添加服务使用系统自带云服务，系统匹配车况
        </p>
      </div>
      <div class="chice_word" v-show="chioce1">
        <p>服务默认所属分类</p>
        <input placeholder="  大类+细类" />
        <p>
          下载使用云服务时，默认下载并设置到所属云分类，无默认时使用设置的分类
        </p>
      </div>
      <div class="lie_two lie_two_st">
        <p class="title_word">使用云商品</p>
        <div class="switch">
          <el-switch
            v-model="value3"
            active-color="#33ab79"
            inactive-color="#999999"
            @change="chice2(value3)"
          >
          </el-switch>
        </div>
        <p class="tip_word">
          开启时，接车单添加商品使用系统自带云商品，系统匹配车况
        </p>
      </div>
      <div class="chice_word" v-show="chioce2">
        <p>商品默认所属分类</p>
        <input placeholder="  大类+细类" />
        <p>
          下载使用云商品时，默认下载并设置到所属云分类，无默认时使用设置的分类
        </p>
      </div>
    </div>
    <div class="all_lie_th">
      <div class="lie_two">
        <p class="title_word">开放车单反结算</p>
        <div class="switch">
          <el-switch
            v-model="value4"
            active-color="#33ab79"
            inactive-color="#999999"
            @change="chice3(value4)"
          >
          </el-switch>
        </div>
        <p class="tip_word">
          开启时，允许接车单在结账后，操作反结算，回到待结账状态，只能操作一个月内的车单
        </p>
      </div>
      <div class="chice_word other_st" v-show="chioce3">
        <p>车单反结算期限</p>
        <input placeholder="  一个月" />
        <p>车单可在操作结账的日期后一个月内操作反结算</p>
      </div>
    </div>
    <div class="all_lie_th">
      <div class="lie_two">
        <p class="title_word">接车单自动扣减库存</p>
        <div class="switch">
          <el-switch
            v-model="value5"
            active-color="#33ab79"
            inactive-color="#999999"
            @change="chice4(value5)"
          >
          </el-switch>
        </div>
        <p class="tip_word">
          已开启自动扣减，并允许负库存出库，接车单结账后将自动扣减库存
        </p>
      </div>
      <div v-show="chioce4" style="margin-top: 17px">
        <div class="lie_two">
          <p class="title_word" style="color: #999999">允许负库存出库</p>
          <div class="switch">
            <el-switch
              v-model="value6"
              active-color="#33ab79"
              inactive-color="#999999"
              @change="chice5(value6)"
            >
            </el-switch>
          </div>
        </div>
        <div class="footer">
          <p>默认出库仓库</p>
          <input placeholder="  选择出库仓库" />
        </div>
      </div>
    </div>
    <div class="footer_but">
      <button>保存</button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      value1: true,
      value2: false,
      chioce1: false,
      value3: false,
      chioce2: false,
      value4: false,
      chioce3: false,
      value5: false,
      chioce4: false,
      value6: false,
      chioce5: false,
    };
  },
  methods: {
    chice1(val) {
      if (val == true) {
        this.chioce1 = true;
      } else {
        this.chioce1 = false;
      }
    },
    chice2(val) {
      if (val == true) {
        this.chioce2 = true;
      } else {
        this.chioce2 = false;
      }
    },
    chice3(val) {
      if (val == true) {
        this.chioce3 = true;
      } else {
        this.chioce3 = false;
      }
    },
    chice4(val) {
      if (val == true) {
        this.chioce4 = true;
      } else {
        this.chioce4 = false;
      }
    },
    chice5(val) {
      if (val == true) {
        this.chioce5 = true;
      } else {
        this.chioce5 = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.lie_one {
  display: flex;
  width: 100%;
  height: 70px;
  line-height: 70px;
  border-bottom: 1px solid #f5f8fa;
}
.title_word {
  width: 30%;
  text-align: right;
  font-size: 14px;
}
.switch {
  margin-top: -2px !important;
  margin: 0px 12px;
}
.tip_word {
  font-size: 12px;
  color: #999999;
  font-family: PingFangSC-Regular, PingFang SC;
}
.lie_two {
  display: flex;
}
.chice_word {
  display: flex;
  margin-top: 20px;
  margin-left: 22%;
  p:nth-child(1) {
    font-size: 14px;
    color: #999999;
    font-family: PingFangSC-Regular, PingFang SC;
  }
  p:nth-child(3) {
    font-size: 12px;
    color: #999999;
    font-family: PingFangSC-Regular, PingFang SC;
  }
  input {
    width: 153px;
    height: 26px;
    border: none;
    border: 1px solid #e4e4e4;
    border-radius: 2px;
    margin: -8px 20px 0px 12px;
  }
}
.lie_two_st {
  margin-top: 30px;
}
.all_liet,
.all_lie_th {
  padding: 31px 0px;
  border-bottom: 1px solid #f5f8fa;
}
.other_st {
  margin-left: 23% !important;
}
.footer {
  display: flex;
  margin-top: 18px;
  font-size: 14px;
  p {
    margin-left: 24%;
    color: #999999;
  }
  input {
    width: 153px;
    height: 24px;
    margin-left: 12px;
    margin-top: -8px;
    border: none;
    border: 1px solid #e4e4e4;
    border-radius: 2px;
  }
}
.footer_but {
  button {
    width: 80px;
    height: 24px;
    margin-left: 50%;
    margin-top: 30px;
    border: none;
    background: $primary;
    color: #ffffff;
    border-radius: 4px;
  }
}
</style>
