var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { ref: "baseContainer", attrs: { paddingTop: "" } },
    [
      [
        _c(
          "el-form",
          {
            staticClass: "filter-form",
            attrs: { model: _vm.form, "label-width": "100px", size: "small" },
          },
          [
            _c("div", { staticClass: "flex-x-between" }, [
              _c(
                "div",
                { staticClass: "flex-warp flex-shrink0" },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c("div", [_vm._v("门店清单")]),
                      ]),
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            clearable: "",
                            remote: "",
                            placeholder: "搜索车店名称、编号",
                            "remote-method": _vm.searchControlCompany,
                            loading: _vm.searchControlCompanyLoading,
                          },
                          on: { change: _vm.selectCompany },
                          model: {
                            value: _vm.form.companyId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "companyId", $$v)
                            },
                            expression: "form.companyId",
                          },
                        },
                        _vm._l(_vm.companyList, function (item) {
                          return _c(
                            "el-option",
                            {
                              key: item.companyId,
                              attrs: {
                                label: item.companyName,
                                value: item.companyId,
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    float: "left",
                                    "font-size": "13px",
                                  },
                                },
                                [_vm._v(_vm._s(item.companyName))]
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    2
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "统计周期" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "month",
                          placeholder: "选择月",
                          "value-format": "yyyy-MM",
                        },
                        on: { change: _vm.changeMonth },
                        model: {
                          value: _vm.form.statisticsPeriod,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "statisticsPeriod", $$v)
                          },
                          expression: "form.statisticsPeriod",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "flex" }, [
                _c(
                  "div",
                  { staticClass: "el-form-item--small" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "btn_search",
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.getData },
                      },
                      [_vm._v("查询")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        ),
      ],
      [
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 574,
                    expression: "574",
                  },
                ],
                attrs: { size: "small", type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.openCostEvent(0, 0)
                  },
                },
              },
              [_vm._v("设置成本")]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "table-box" }, [
          _c(
            "table",
            {
              staticClass: "border-table",
              staticStyle: { "margin-top": "20px" },
            },
            [
              _c("tr", [
                _c(
                  "th",
                  {
                    staticStyle: { width: "15%" },
                    attrs: { rowspan: "2", colspan: "2" },
                  },
                  [_vm._v("类型")]
                ),
                _c(
                  "th",
                  { staticStyle: { width: "15%" }, attrs: { rowspan: "2" } },
                  [_vm._v("合计")]
                ),
                _c("th", { attrs: { colspan: "4" } }, [_vm._v("业务类型")]),
              ]),
              _c("tr", [
                _c("th", { staticStyle: { width: "10%" } }, [_vm._v("自费")]),
                _c(
                  "th",
                  { staticStyle: { width: "10%" } },
                  [
                    _vm._v(" 包干 "),
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          effect: "light",
                          content: "包干金额为分摊后实际产值金额，非单据金额",
                          placement: "bottom-start",
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "iconfont grow-icon_doubt",
                          staticStyle: { color: "#9a9a9a" },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _c("th", { staticStyle: { width: "10%" } }, [_vm._v("理赔")]),
                _c("th", { staticStyle: { width: "10%" } }, [_vm._v("索赔")]),
              ]),
              _c("tr", [
                _c(
                  "td",
                  { staticClass: "columnName", attrs: { colspan: "2" } },
                  [_vm._v("营业额")]
                ),
                _c("td", [
                  _vm._v(" " + _vm._s(_vm.tabularData.turnover) + " "),
                ]),
                _c("td", [
                  _vm._v(
                    " " + _vm._s(_vm.tabularData.selfPayingTurnover) + " "
                  ),
                ]),
                _c("td", [
                  _vm._v(
                    " " + _vm._s(_vm.tabularData.contractForTurnover) + " "
                  ),
                ]),
                _c("td", [
                  _vm._v(
                    " " + _vm._s(_vm.tabularData.claimSettlementTurnover) + " "
                  ),
                ]),
                _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.tabularData.claimForCompensationTurnover) +
                      " "
                  ),
                ]),
              ]),
              _c("tr", [
                _c(
                  "td",
                  {
                    staticClass: "columnName",
                    staticStyle: { "vertical-align": "middle" },
                    attrs: { rowspan: "7", width: "90" },
                  },
                  [_vm._v(" 车单成本 ")]
                ),
                _c("td", { staticClass: "columnName" }, [_vm._v("材料成本")]),
                _c("td", [
                  _vm._v(" " + _vm._s(_vm.tabularData.material) + " "),
                ]),
                _c("td", [
                  _vm._v(
                    " " + _vm._s(_vm.tabularData.selfPayingMaterial) + " "
                  ),
                ]),
                _c("td", [
                  _vm._v(
                    " " + _vm._s(_vm.tabularData.contractForMaterial) + " "
                  ),
                ]),
                _c("td", [
                  _vm._v(
                    " " + _vm._s(_vm.tabularData.claimSettlementMaterial) + " "
                  ),
                ]),
                _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.tabularData.claimForCompensationMaterial) +
                      " "
                  ),
                ]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "columnName" }, [_vm._v("合同折扣")]),
                _c("td", [
                  _vm._v(" " + _vm._s(_vm.tabularData.contract) + " "),
                ]),
                _c("td", [
                  _c("div", { staticClass: "pointer flex seen" }, [
                    _vm._v(
                      " " + _vm._s(_vm.tabularData.selfPayingContract) + " "
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "auth",
                          rawName: "v-auth",
                          value: 574,
                          expression: "574",
                        },
                      ],
                      staticClass:
                        "iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show",
                      staticStyle: { "font-size": "14px" },
                      on: {
                        click: function ($event) {
                          return _vm.openCostEvent(0, 0)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c("div", { staticClass: "pointer flex seen" }, [
                    _vm._v(
                      " " + _vm._s(_vm.tabularData.contractForContract) + " "
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "auth",
                          rawName: "v-auth",
                          value: 574,
                          expression: "574",
                        },
                      ],
                      staticClass:
                        "iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show",
                      staticStyle: { "font-size": "14px" },
                      on: {
                        click: function ($event) {
                          return _vm.openCostEvent(1, 0)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c("div", { staticClass: "pointer flex seen" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.tabularData.claimSettlementContract) +
                        " "
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "auth",
                          rawName: "v-auth",
                          value: 574,
                          expression: "574",
                        },
                      ],
                      staticClass:
                        "iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show",
                      staticStyle: { "font-size": "14px" },
                      on: {
                        click: function ($event) {
                          return _vm.openCostEvent(2, 0)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c("div", { staticClass: "pointer flex seen" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.tabularData.claimForCompensationContract) +
                        " "
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "auth",
                          rawName: "v-auth",
                          value: 574,
                          expression: "574",
                        },
                      ],
                      staticClass:
                        "iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show",
                      staticStyle: { "font-size": "14px" },
                      on: {
                        click: function ($event) {
                          return _vm.openCostEvent(3, 0)
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "columnName" }, [_vm._v("财务折扣")]),
                _c("td", [_vm._v(" " + _vm._s(_vm.tabularData.finance) + " ")]),
                _c("td", [
                  _c("div", { staticClass: "pointer flex seen" }, [
                    _vm._v(
                      " " + _vm._s(_vm.tabularData.selfPayingFinance) + " "
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "auth",
                          rawName: "v-auth",
                          value: 574,
                          expression: "574",
                        },
                      ],
                      staticClass:
                        "iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show",
                      staticStyle: { "font-size": "14px" },
                      on: {
                        click: function ($event) {
                          return _vm.openCostEvent(0, 1)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c("div", { staticClass: "pointer flex seen" }, [
                    _vm._v(
                      " " + _vm._s(_vm.tabularData.contractForFinance) + " "
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "auth",
                          rawName: "v-auth",
                          value: 574,
                          expression: "574",
                        },
                      ],
                      staticClass:
                        "iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show",
                      staticStyle: { "font-size": "14px" },
                      on: {
                        click: function ($event) {
                          return _vm.openCostEvent(1, 1)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c("div", { staticClass: "pointer flex seen" }, [
                    _vm._v(
                      " " + _vm._s(_vm.tabularData.claimSettlementFinance) + " "
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "auth",
                          rawName: "v-auth",
                          value: 574,
                          expression: "574",
                        },
                      ],
                      staticClass:
                        "iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show",
                      staticStyle: { "font-size": "14px" },
                      on: {
                        click: function ($event) {
                          return _vm.openCostEvent(2, 1)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c("div", { staticClass: "pointer flex seen" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.tabularData.claimForCompensationFinance) +
                        " "
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "auth",
                          rawName: "v-auth",
                          value: 574,
                          expression: "574",
                        },
                      ],
                      staticClass:
                        "iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show",
                      staticStyle: { "font-size": "14px" },
                      on: {
                        click: function ($event) {
                          return _vm.openCostEvent(3, 1)
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "columnName" }, [_vm._v("坏账")]),
                _c("td", [
                  _vm._v(" " + _vm._s(_vm.tabularData.badDebts) + " "),
                ]),
                _c("td", [
                  _c("div", { staticClass: "pointer flex seen" }, [
                    _vm._v(
                      " " + _vm._s(_vm.tabularData.selfPayingBadDebts) + " "
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "auth",
                          rawName: "v-auth",
                          value: 574,
                          expression: "574",
                        },
                      ],
                      staticClass:
                        "iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show",
                      staticStyle: { "font-size": "14px" },
                      on: {
                        click: function ($event) {
                          return _vm.openCostEvent(0, 2)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c("div", { staticClass: "pointer flex seen" }, [
                    _vm._v(
                      " " + _vm._s(_vm.tabularData.contractForBadDebts) + " "
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "auth",
                          rawName: "v-auth",
                          value: 574,
                          expression: "574",
                        },
                      ],
                      staticClass:
                        "iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show",
                      staticStyle: { "font-size": "14px" },
                      on: {
                        click: function ($event) {
                          return _vm.openCostEvent(1, 2)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c("div", { staticClass: "pointer flex seen" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.tabularData.claimSettlementBadDebts) +
                        " "
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "auth",
                          rawName: "v-auth",
                          value: 574,
                          expression: "574",
                        },
                      ],
                      staticClass:
                        "iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show",
                      staticStyle: { "font-size": "14px" },
                      on: {
                        click: function ($event) {
                          return _vm.openCostEvent(2, 2)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c("div", { staticClass: "pointer flex seen" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.tabularData.claimForCompensationBadDebts) +
                        " "
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "auth",
                          rawName: "v-auth",
                          value: 574,
                          expression: "574",
                        },
                      ],
                      staticClass:
                        "iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show",
                      staticStyle: { "font-size": "14px" },
                      on: {
                        click: function ($event) {
                          return _vm.openCostEvent(3, 2)
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "columnName" }, [_vm._v("保费成本")]),
                _c("td", [_vm._v(" " + _vm._s(_vm.tabularData.premium) + " ")]),
                _c("td", [
                  _c("div", { staticClass: "pointer flex seen" }, [
                    _vm._v(
                      " " + _vm._s(_vm.tabularData.selfPayingPremium) + " "
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "auth",
                          rawName: "v-auth",
                          value: 574,
                          expression: "574",
                        },
                      ],
                      staticClass:
                        "iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show",
                      staticStyle: { "font-size": "14px" },
                      on: {
                        click: function ($event) {
                          return _vm.openCostEvent(0, 3)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c("div", { staticClass: "pointer flex seen" }, [
                    _vm._v(
                      " " + _vm._s(_vm.tabularData.contractForPremium) + " "
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "auth",
                          rawName: "v-auth",
                          value: 574,
                          expression: "574",
                        },
                      ],
                      staticClass:
                        "iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show",
                      staticStyle: { "font-size": "14px" },
                      on: {
                        click: function ($event) {
                          return _vm.openCostEvent(1, 3)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c("div", { staticClass: "pointer flex seen" }, [
                    _vm._v(
                      " " + _vm._s(_vm.tabularData.claimSettlementPremium) + " "
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "auth",
                          rawName: "v-auth",
                          value: 574,
                          expression: "574",
                        },
                      ],
                      staticClass:
                        "iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show",
                      staticStyle: { "font-size": "14px" },
                      on: {
                        click: function ($event) {
                          return _vm.openCostEvent(2, 3)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c("div", { staticClass: "pointer flex seen" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.tabularData.claimForCompensationPremium) +
                        " "
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "auth",
                          rawName: "v-auth",
                          value: 574,
                          expression: "574",
                        },
                      ],
                      staticClass:
                        "iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show",
                      staticStyle: { "font-size": "14px" },
                      on: {
                        click: function ($event) {
                          return _vm.openCostEvent(3, 3)
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "columnName" }, [_vm._v("代定损费用")]),
                _c("td", [
                  _vm._v(" " + _vm._s(_vm.tabularData.lossDetermined) + " "),
                ]),
                _c("td", [
                  _c("div", { staticClass: "pointer flex seen" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.tabularData.selfPayingLossDetermined) +
                        " "
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "auth",
                          rawName: "v-auth",
                          value: 574,
                          expression: "574",
                        },
                      ],
                      staticClass:
                        "iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show",
                      staticStyle: { "font-size": "14px" },
                      on: {
                        click: function ($event) {
                          return _vm.openCostEvent(0, 4)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c("div", { staticClass: "pointer flex seen" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.tabularData.contractForLossDetermined) +
                        " "
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "auth",
                          rawName: "v-auth",
                          value: 574,
                          expression: "574",
                        },
                      ],
                      staticClass:
                        "iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show",
                      staticStyle: { "font-size": "14px" },
                      on: {
                        click: function ($event) {
                          return _vm.openCostEvent(1, 4)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c("div", { staticClass: "pointer flex seen" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.tabularData.claimSettlementLossDetermined) +
                        " "
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "auth",
                          rawName: "v-auth",
                          value: 574,
                          expression: "574",
                        },
                      ],
                      staticClass:
                        "iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show",
                      staticStyle: { "font-size": "14px" },
                      on: {
                        click: function ($event) {
                          return _vm.openCostEvent(2, 4)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c("div", { staticClass: "pointer flex seen" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.tabularData.claimForCompensationLossDetermined
                        ) +
                        " "
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "auth",
                          rawName: "v-auth",
                          value: 574,
                          expression: "574",
                        },
                      ],
                      staticClass:
                        "iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show",
                      staticStyle: { "font-size": "14px" },
                      on: {
                        click: function ($event) {
                          return _vm.openCostEvent(3, 4)
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "columnName" }, [
                  _vm._v("业务人员费用"),
                ]),
                _c("td", [
                  _vm._v(" " + _vm._s(_vm.tabularData.salesman) + " "),
                ]),
                _c("td", [
                  _c("div", { staticClass: "pointer flex seen" }, [
                    _vm._v(
                      " " + _vm._s(_vm.tabularData.selfPayingSalesman) + " "
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "auth",
                          rawName: "v-auth",
                          value: 574,
                          expression: "574",
                        },
                      ],
                      staticClass:
                        "iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show",
                      staticStyle: { "font-size": "14px" },
                      on: {
                        click: function ($event) {
                          return _vm.openCostEvent(0, 5)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c("div", { staticClass: "pointer flex seen" }, [
                    _vm._v(
                      " " + _vm._s(_vm.tabularData.contractForSalesman) + " "
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "auth",
                          rawName: "v-auth",
                          value: 574,
                          expression: "574",
                        },
                      ],
                      staticClass:
                        "iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show",
                      staticStyle: { "font-size": "14px" },
                      on: {
                        click: function ($event) {
                          return _vm.openCostEvent(1, 5)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c("div", { staticClass: "pointer flex seen" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.tabularData.claimSettlementSalesman) +
                        " "
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "auth",
                          rawName: "v-auth",
                          value: 574,
                          expression: "574",
                        },
                      ],
                      staticClass:
                        "iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show",
                      staticStyle: { "font-size": "14px" },
                      on: {
                        click: function ($event) {
                          return _vm.openCostEvent(2, 5)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c("div", { staticClass: "pointer flex seen" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.tabularData.claimForCompensationSalesman) +
                        " "
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "auth",
                          rawName: "v-auth",
                          value: 574,
                          expression: "574",
                        },
                      ],
                      staticClass:
                        "iconfont grow-a-icon_fillout color666 el-icon--right pointer seen-show",
                      staticStyle: { "font-size": "14px" },
                      on: {
                        click: function ($event) {
                          return _vm.openCostEvent(3, 5)
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _c("tr", [
                _c(
                  "td",
                  { staticClass: "columnName", attrs: { colspan: "2" } },
                  [_vm._v("毛利")]
                ),
                _c("td", [
                  _vm._v(" " + _vm._s(_vm.profit.contractProfit) + " "),
                ]),
                _c("td", [
                  _vm._v(" " + _vm._s(_vm.profit.selfPayingProfit) + " "),
                ]),
                _c("td", [
                  _vm._v(" " + _vm._s(_vm.profit.contractForProfit) + " "),
                ]),
                _c("td", [
                  _vm._v(" " + _vm._s(_vm.profit.claimSettlementProfit) + " "),
                ]),
                _c("td", [
                  _vm._v(
                    " " + _vm._s(_vm.profit.claimForCompensationProfit) + " "
                  ),
                ]),
              ]),
              _c("tr", [
                _c(
                  "td",
                  { staticClass: "columnName", attrs: { colspan: "2" } },
                  [_vm._v("毛利率")]
                ),
                _c("td", [
                  _vm._v(" " + _vm._s(_vm.profitMargin.contractRate) + " "),
                ]),
                _c("td", [
                  _vm._v(" " + _vm._s(_vm.profitMargin.selfPayingRate) + " "),
                ]),
                _c("td", [
                  _vm._v(" " + _vm._s(_vm.profitMargin.contractForRate) + " "),
                ]),
                _c("td", [
                  _vm._v(
                    " " + _vm._s(_vm.profitMargin.claimSettlementRate) + " "
                  ),
                ]),
                _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.profitMargin.claimForCompensationRate) +
                      " "
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ],
      _vm.setupCostDialog
        ? _c("cost-dialog", {
            attrs: {
              visibility: _vm.setupCostDialog,
              time: _vm.form.statisticsPeriod,
              typeNum: _vm.typeCombination,
              costNum: _vm.costCombination,
            },
            on: {
              "update:visibility": function ($event) {
                _vm.setupCostDialog = $event
              },
              updateData: _vm.getData,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }