var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { ref: "baseContainer", attrs: { paddingTop: "" } },
    [
      [
        _c(
          "el-form",
          {
            staticClass: "filter-form",
            attrs: { model: _vm.form, "label-width": "100px", size: "small" },
          },
          [
            _c("div", { staticClass: "flex-x-between" }, [
              _c(
                "div",
                { staticClass: "flex flex-shrink0" },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c(
                          "div",
                          [
                            _vm._v(" 录入门店 "),
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  effect: "light",
                                  content:
                                    "商品的录入门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)",
                                  placement: "bottom-start",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "iconfont grow-icon_doubt",
                                  staticStyle: { color: "#9a9a9a" },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("operateCompanyCascader", {
                        attrs: {
                          operateCompanyUrl: _vm.form.operateCompanyUrl,
                        },
                        model: {
                          value: _vm.form.companyIds,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "companyIds", $$v)
                          },
                          expression: "form.companyIds",
                        },
                      }),
                    ],
                    2
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品名称" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入商品名称", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.getData.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.form.goodsName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "goodsName", $$v)
                          },
                          expression: "form.goodsName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品编码" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入商品编码", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.getData.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.form.goodsCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "goodsCode", $$v)
                          },
                          expression: "form.goodsCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "el-form-item--small" }, [
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "auth",
                            rawName: "v-auth",
                            value: 633,
                            expression: "633",
                          },
                        ],
                        staticClass: "btn_search",
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.getData },
                      },
                      [_vm._v("查询")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn_search",
                        attrs: { size: "small" },
                        on: { click: _vm.empty },
                      },
                      [_vm._v("置空")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "flex-warp" },
              [
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否启用" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择启用状态" },
                          model: {
                            value: _vm.form.isActive,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "isActive", $$v)
                            },
                            expression: "form.isActive",
                          },
                        },
                        _vm._l(_vm.availableStatusEnum, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.desc, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否共享" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择共享状态" },
                          model: {
                            value: _vm.form.isShare,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "isShare", $$v)
                            },
                            expression: "form.isShare",
                          },
                        },
                        _vm._l(_vm.sharedStatusEnum, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.desc, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否禁止采购" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择是否禁止采购" },
                          model: {
                            value: _vm.form.purchaseDisable,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "purchaseDisable", $$v)
                            },
                            expression: "form.purchaseDisable",
                          },
                        },
                        _vm._l(_vm.purchaseDisableEnum, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.desc, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品ID" } },
                    [
                      _c("number-input", {
                        attrs: {
                          decimalDigits: "0",
                          max: 9999999999999999,
                          placeholder: "请输入完整的商品ID",
                          clearable: "",
                        },
                        model: {
                          value: _vm.form.goodsId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "goodsId", $$v)
                          },
                          expression: "form.goodsId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        ),
      ],
      [
        _c(
          "div",
          {
            staticClass: "btns flex-x-between",
            staticStyle: { margin: "5px 0 14px 0" },
          },
          [
            _c("div"),
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "auth",
                        rawName: "v-auth",
                        value: 634,
                        expression: "634",
                      },
                    ],
                    staticClass: "blue",
                    attrs: { size: "small", type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.downloadAllRecords()
                      },
                    },
                  },
                  [
                    _vm._v("导出列表"),
                    _c("i", {
                      staticClass: "iconfont grow-icon_daochu el-icon--right",
                    }),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "fixedScrollBar",
                rawName: "v-fixedScrollBar",
                value: _vm.$refs.baseContainer,
                expression: "$refs.baseContainer",
              },
            ],
            staticClass: "custom-table",
            attrs: {
              data: _vm.listData,
              border: "",
              "default-sort": { prop: "goodsCode", order: "desc" },
            },
            on: { "sort-change": _vm.listDataTableSortChangeEvent },
          },
          [
            _c("el-table-column", {
              attrs: { type: "index", label: "序号", width: "60" },
            }),
            _c("el-table-column", {
              attrs: { label: "操作", width: "60" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "auth",
                              rawName: "v-auth",
                              value: 635,
                              expression: "635",
                            },
                          ],
                          staticClass: "text_operation blue",
                          on: {
                            click: function ($event) {
                              return _vm.handleEdit(scope.row)
                            },
                          },
                        },
                        [_vm._v("编辑")]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                width: "140",
                label: "录入门店",
                prop: "company.companyName",
              },
            }),
            _c("el-table-column", {
              attrs: { width: "180", label: "商品ID" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [_vm._v(" " + _vm._s(row.goods.goodsId) + " ")]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "180", label: "商品名称" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [_vm._v(" " + _vm._s(row.goods.name) + " ")]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "180", label: "商品编码", sortable: "custom" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [_vm._v(" " + _vm._s(row.goods.goodsCode) + " ")]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "180", label: "商品标准名称" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [_vm._v(" " + _vm._s(row.goods.standardName) + " ")]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "180", label: "是否禁止采购" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [_vm._v(" " + _vm._s(row.disablePurchase) + " ")]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "180", label: "是否启用" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [_vm._v(" " + _vm._s(row.isActiveOption.desc) + " ")]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "180", label: "是否共享" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [_vm._v(" " + _vm._s(row.isSharedOption.desc) + " ")]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "180", label: "商品品牌" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [_vm._v(" " + _vm._s(row.goods.brandName) + " ")]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "总库存", prop: "allInventoryNum" },
            }),
            _c("el-table-column", {
              attrs: { width: "180", label: "单位" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [_vm._v(" " + _vm._s(row.goods.unit) + " ")]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "180", label: "售价" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [_vm._v(" " + _vm._s(row.goods.price) + " ")]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm.showEditDialog
          ? _c("editGoodsControlDialog", {
              attrs: {
                visibility: _vm.showEditDialog,
                id: _vm.editId,
                detail: _vm.editData,
                inventoryGoodsControlModuleList:
                  _vm.inventoryGoodsControlModuleList,
                sharedStatusEnum: _vm.sharedStatusEnum,
                availableStatusEnum: _vm.availableStatusEnum,
              },
              on: {
                "update:visibility": function ($event) {
                  _vm.showEditDialog = $event
                },
                save: _vm.getData,
              },
            })
          : _vm._e(),
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c("pagination", {
            attrs: { total: _vm.total },
            on: { pageChange: _vm.changePage },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }