/**
 * 过滤日期中的时分秒
 */
export function delhms(date = '') {
  if (typeof date !== 'string') return '';
  return date.split(' ')[0] || '';
}

export function delYmdAndSecond(date = '') {
  if (typeof date !== 'string') {
    return '';
  }
  let dStr = date.split(' ')[1] || '';
  if (dStr.indexOf(':') != -1) {
    const hms = dStr.split(':');
    return hms[0] + ':' + hms[1];
  }
  return dStr;
}

export function delSecond(date = '') {
  if (typeof date !== 'string') {
    return '';
  }
  let dStr = date.split(' ')[0] || '';
  let sStr = date.split(' ')[1] || '';
  if (sStr.indexOf(':') != -1) {
    const hms = sStr.split(':');
    return dStr + ' ' + hms[0] + ':' + hms[1];
  }
  return date;
}
