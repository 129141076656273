var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "完工质检",
            width: "60%",
            visible: _vm.visibility,
            "before-close": _vm.handleClose,
            "close-on-click-modal": false,
            "append-to-body": "",
          },
        },
        [
          _c(
            "div",
            { staticClass: "quality-testing_box" },
            [
              _c("div", { staticClass: "bill-info" }, [
                _c("div", { staticClass: "row flex-x-between" }, [
                  _c("div", { staticClass: "col flex" }, [
                    _c("div", { staticClass: "label" }, [_vm._v("接车单：")]),
                    _c("div", { staticClass: "value" }, [
                      _c(
                        "span",
                        {
                          staticClass: "blue pointer",
                          on: {
                            click: function ($event) {
                              return _vm.goCarBillDetail(_vm.detailData.billId)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.detailData.billCode))]
                      ),
                      _vm._v(" ("),
                      _vm.detailData.isOthersSelfPayState != undefined
                        ? _c("span", { staticClass: "color999" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("dict")(
                                    _vm.detailData.isOthersSelfPayState,
                                    "isOthersSelfPayState"
                                  )
                                )
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(") "),
                    ]),
                  ]),
                  _c("div", { staticClass: "col flex" }, [
                    _c("div", { staticClass: "label" }, [
                      _vm._v("预交车时间："),
                    ]),
                    _c("div", { staticClass: "value" }, [
                      _vm.detailData.undetermined
                        ? _c("div", [_vm._v("待定")])
                        : _c("div", { staticClass: "flex" }, [
                            _c("p", [
                              _vm._v(_vm._s(_vm.detailData.planFinishTime)),
                            ]),
                            _vm.detailData.waitInStore
                              ? _c("p", { staticClass: "color999" }, [
                                  _vm._v(
                                    " (" +
                                      _vm._s(
                                        _vm.detailData.waitInStore
                                          ? "在店等"
                                          : ""
                                      ) +
                                      ") "
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "row flex-x-between",
                    staticStyle: { "margin-top": "20px" },
                  },
                  [
                    _c("div", { staticClass: "col flex" }, [
                      _c("div", { staticClass: "label" }, [_vm._v("车牌号：")]),
                      _c("div", { staticClass: "value" }, [
                        _c("span", [_vm._v(_vm._s(_vm.detailData.carNumber))]),
                        _vm.detailData.carType !== undefined
                          ? _c("span", [
                              _vm._v(
                                " (" + _vm._s(_vm.detailData.vehicleType) + ") "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                    _c("div", { staticClass: "col flex" }, [
                      _c("div", { staticClass: "label" }, [
                        _vm._v("车型/车架号："),
                      ]),
                      _c("div", { staticClass: "value" }, [
                        _c("span", [_vm._v(_vm._s(_vm.detailData.carModel))]),
                        _vm.detailData.uniqueId
                          ? _c("p", { staticStyle: { "margin-top": "5px" } }, [
                              _vm._v(
                                " VIN: " + _vm._s(_vm.detailData.uniqueId) + " "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ]
                ),
              ]),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    border: "",
                    data: _vm.taskList,
                    "span-method": _vm.spanMethod,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "序号", width: "80", prop: "index" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "报价项目", width: "180" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("p", [_vm._v(_vm._s(row.serviceItemName))]),
                            row.serviceItemCode
                              ? _c("span", [
                                  _vm._v(
                                    "编码：" + _vm._s(row.serviceItemCode)
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { width: "110", prop: "tags", label: "内部标签" },
                  }),
                  _c("el-table-column", {
                    attrs: { width: "110", label: "施工技师" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("div", [_vm._v(_vm._s(row.technicianNames))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      width: "110",
                      prop: "reworkTimes",
                      label: "返工次数",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      width: "160",
                      prop: "updateTime",
                      label: "完工时间",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { width: "160" },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              { attrs: { insignificance: scope.isRework } },
                              [
                                _vm._v(" 返工"),
                                _c("el-switch", {
                                  staticClass: "el-icon--right",
                                  attrs: {
                                    "active-value": 1,
                                    "inactive-value": 0,
                                  },
                                  on: { change: _vm.changeIsRework },
                                  model: {
                                    value: _vm.isRework,
                                    callback: function ($$v) {
                                      _vm.isRework = $$v
                                    },
                                    expression: "isRework",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(row.reworkOrNot ? "返工" : "完工") +
                                " "
                            ),
                            _c("el-switch", {
                              attrs: { "active-value": 1, "inactive-value": 0 },
                              on: {
                                change: function ($event) {
                                  return _vm.changeItemReworkStatus($event, row)
                                },
                              },
                              model: {
                                value: row.reworkOrNot,
                                callback: function ($$v) {
                                  _vm.$set(row, "reworkOrNot", $$v)
                                },
                                expression: "row.reworkOrNot",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { width: "300", label: "返工原因(可上传5张图片)" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("div", { key: _vm.key }, [
                              row.reworkOrNot
                                ? _c(
                                    "div",
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          type: "textarea",
                                          rows: "2",
                                          placeholder: "请输入",
                                        },
                                        model: {
                                          value: row.reworkReason,
                                          callback: function ($$v) {
                                            _vm.$set(row, "reworkReason", $$v)
                                          },
                                          expression: "row.reworkReason",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            margin: "10px 0 0 -10px",
                                          },
                                        },
                                        [
                                          _c("upload-file-list", {
                                            attrs: { max: 5 },
                                            model: {
                                              value: row.reworkPics,
                                              callback: function ($$v) {
                                                _vm.$set(row, "reworkPics", $$v)
                                              },
                                              expression: "row.reworkPics",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    { staticClass: "flex" },
                                    [
                                      _c("upload-file-list", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: row.reworkPics,
                                            expression: "row.reworkPics",
                                          },
                                        ],
                                        attrs: {
                                          disabled: "",
                                          value: row.reworkPics,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        "header-align": "center",
                        label: "历史返工详情",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          width: "110",
                          label: "质检时间",
                          prop: "qualityInspectionTime",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          width: "110",
                          label: "质检员",
                          prop: "qualityInspectionUserName",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "返工原因", prop: "reworkReason" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("template", { slot: "footer" }, [
            _c(
              "div",
              { staticClass: "flex-x-end" },
              [
                _c(
                  "el-button",
                  { attrs: { size: "small" }, on: { click: _vm.handleClose } },
                  [_vm._v("取 消")]
                ),
                _c("el-button", { attrs: { size: "small" } }, [
                  _vm._v("保 存"),
                ]),
                _c(
                  "div",
                  { staticStyle: { "margin-left": "10px" } },
                  [
                    _vm.buttonName === "车间完工"
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "auth",
                                rawName: "v-auth",
                                value: 456,
                                expression: "456",
                              },
                            ],
                            attrs: { size: "small", type: "primary" },
                            on: { click: _vm.submit },
                          },
                          [_vm._v(_vm._s(_vm.buttonName))]
                        )
                      : _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "auth",
                                rawName: "v-auth",
                                value: 455,
                                expression: "455",
                              },
                            ],
                            attrs: { size: "small", type: "primary" },
                            on: { click: _vm.submit },
                          },
                          [_vm._v(_vm._s(_vm.buttonName))]
                        ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }