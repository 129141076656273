var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { attrs: { paddingTop: "" } },
    [
      _c(
        "div",
        { staticClass: "filter flex-x-between" },
        [
          _c(
            "div",
            { staticClass: "date" },
            [
              _c("span", [_vm._v("统计周期：")]),
              _c("el-date-picker", {
                attrs: {
                  type: "month",
                  size: "small",
                  "value-format": "yyyy/MM",
                  placeholder: "选择月",
                },
                model: {
                  value: _vm.date,
                  callback: function ($$v) {
                    _vm.date = $$v
                  },
                  expression: "date",
                },
              }),
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.doSearch },
            },
            [_vm._v("查询")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "flex-x-between" }, [
        _c("p", { staticClass: "tip-word color666" }, [
          _c("i", { staticClass: "el-icon-warning orange" }),
          _vm._v(
            " 数据更新于" +
              _vm._s(new Date().Format("yyyy/MM/dd hh:mm:ss")) +
              " "
          ),
        ]),
        _c(
          "div",
          { staticClass: "flex" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "text" },
                on: {
                  click: function ($event) {
                    _vm.setGrossMarginStandardDialog = true
                  },
                },
              },
              [
                _vm._v("毛利率设置 "),
                _c("i", { staticClass: "el-icon-s-tools" }),
              ]
            ),
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 541,
                    expression: "541",
                  },
                ],
                attrs: { type: "text" },
                on: {
                  click: function ($event) {
                    _vm.setStoreScopeDialog = true
                  },
                },
              },
              [_vm._v("门店管理 "), _c("i", { staticClass: "el-icon-s-tools" })]
            ),
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 537,
                    expression: "537",
                  },
                ],
                attrs: { type: "text" },
                on: {
                  click: function ($event) {
                    _vm.viewTurnoverTargetListDialog = true
                  },
                },
              },
              [_vm._v("查看目标 "), _c("i", { staticClass: "el-icon-view" })]
            ),
            _c(
              "el-button",
              { attrs: { type: "text" }, on: { click: _vm.openNewWindow } },
              [_vm._v("指挥中心 "), _c("i", { staticClass: "el-icon-monitor" })]
            ),
          ],
          1
        ),
      ]),
      _vm.setStoreScopeDialog
        ? _c("set-store-scope-dialog", {
            attrs: { visibility: _vm.setStoreScopeDialog },
            on: {
              "update:visibility": function ($event) {
                _vm.setStoreScopeDialog = $event
              },
            },
          })
        : _vm._e(),
      _vm.viewTurnoverTargetListDialog
        ? _c("view-turnover-target-list-dialog", {
            attrs: { visibility: _vm.viewTurnoverTargetListDialog },
            on: {
              "update:visibility": function ($event) {
                _vm.viewTurnoverTargetListDialog = $event
              },
            },
          })
        : _vm._e(),
      _vm.setGrossMarginStandardDialog
        ? _c("set-gross-margin-standard-dialog", {
            attrs: { visibility: _vm.setGrossMarginStandardDialog },
            on: {
              "update:visibility": function ($event) {
                _vm.setGrossMarginStandardDialog = $event
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }