var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "login-wrap" },
    [
      _c("div", { staticClass: "content" }, [
        _c("img", {
          attrs: { src: require("@/assets/img/login_bg.jpg"), alt: "" },
        }),
        _c("div", { staticClass: "form" }, [
          _vm._m(0),
          _c("div", { staticClass: "box" }, [
            _c("div", { staticClass: "login-way" }, [
              _c(
                "div",
                {
                  staticClass: "account pointer",
                  class: { active: _vm.activeName === "account" },
                  on: {
                    click: function ($event) {
                      _vm.activeName = "account"
                    },
                  },
                },
                [_vm._v(" 账号登录 ")]
              ),
              _c(
                "div",
                {
                  staticClass: "qrcode pointer",
                  class: { active: _vm.activeName === "qrcode" },
                  on: {
                    click: function ($event) {
                      _vm.activeName = "qrcode"
                    },
                  },
                },
                [_vm._v(" 扫码登录 ")]
              ),
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeName === "qrcode",
                    expression: "activeName === 'qrcode'",
                  },
                ],
                staticClass: "qrcode-tab",
              },
              [_c("div", { attrs: { id: "qrcodeDom" } })]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeName === "account",
                    expression: "activeName === 'account'",
                  },
                ],
                staticClass: "account-tab",
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("img", {
                    attrs: { src: require("@/assets/img/icon_1.png"), alt: "" },
                  }),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.ruleForm.phone,
                        expression: "ruleForm.phone",
                      },
                    ],
                    attrs: {
                      type: "text",
                      placeholder: "请输入手机号",
                      maxlength: "11",
                    },
                    domProps: { value: _vm.ruleForm.phone },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.ruleForm, "phone", $event.target.value)
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("img", {
                    attrs: { src: require("@/assets/img/icon_2.png"), alt: "" },
                  }),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.ruleForm.password,
                        expression: "ruleForm.password",
                      },
                    ],
                    attrs: { type: "password", placeholder: "请输入密码" },
                    domProps: { value: _vm.ruleForm.password },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.ruleForm, "password", $event.target.value)
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "flex" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.ruleForm.imageCode,
                          expression: "ruleForm.imageCode",
                        },
                      ],
                      attrs: { type: "text", placeholder: "请输入验证码" },
                      domProps: { value: _vm.ruleForm.imageCode },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.ruleForm,
                              "imageCode",
                              $event.target.value
                            )
                          },
                          _vm.regCode,
                        ],
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "captcha" }, [
                    _c("img", {
                      attrs: { src: _vm.imgUrl },
                      on: {
                        click: function ($event) {
                          return _vm.changeImgCode()
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "flex" }, [
                  _c(
                    "button",
                    {
                      staticClass: "log-btn pointer",
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v(" 登    录 ")]
                  ),
                ]),
                _c("div", { staticClass: "forget-password" }, [
                  _c(
                    "a",
                    {
                      staticClass: "pointer",
                      on: { click: _vm.forgetPassword },
                    },
                    [_vm._v("忘记密码")]
                  ),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
      _vm.verifyPhoneNumberDialogVisibility
        ? _c("verify-phone-number-dialog", {
            attrs: { visibility: _vm.verifyPhoneNumberDialogVisibility },
            on: {
              "update:visibility": function ($event) {
                _vm.verifyPhoneNumberDialogVisibility = $event
              },
              pass: _vm.showChangePasswordDialog,
            },
          })
        : _vm._e(),
      _vm.changePasswordDialogVisibility
        ? _c("change-password-dialog", {
            attrs: {
              visibility: _vm.changePasswordDialogVisibility,
              phoneNumber: _vm.ruleForm.phone,
            },
            on: {
              "update:visibility": function ($event) {
                _vm.changePasswordDialogVisibility = $event
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "logo" }, [
      _c("img", {
        attrs: { src: require("@/assets/img/grow_logo.png"), alt: "grow" },
      }),
      _c("h2", [_vm._v("格悦新能源·管理系统")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }