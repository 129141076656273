<template>
  <el-drawer
    title="定损单详情"
    :visible="visibility"
    size="62%"
    :append-to-body="true"
    :before-close="handleClose"
    modal
    close-on-press-escape
    :wrapperClosable="true"
  >
    <div style="margin: 0 8px 40px 24px; overflow: scroll">
      <div class="report-sty">
        <div class="flex">
          <div class="car-logo flex-shrink0">
            <img v-if="carVo.carLogoIcon" :src="carVo.carLogoIcon" />
            <img v-else src="@/assets/img/carLogo.png" />
          </div>
          <div style="margin-top: 3px; margin-left: 16px">
            <div class="car-info">
              <span class="car-number">{{ carVo.carNumber }}</span>
              <span v-if="carVo.brandName" class="car-info-tag car-brand">{{
                carVo.brandName
              }}</span>
            </div>

            <div class="flex" style="margin-top: 16px">
              <span style="text-decoration: underline; color: #303133">
                {{ billVo.clientName }}
              </span>
              <span style="margin: 0 16px; font-weight: 600">
                跟进人：{{ infoVo.followUpPeopleName }}
              </span>
              <el-tag size="mini" type="danger" v-if="infoVo.remind != 0">
                {{ infoVo.remind | dict('remind') }}
              </el-tag>
            </div>
          </div>
          <i
            :class="['newicon', stateIcon]"
            style="font-size: 60px; vertical-align: middle; margin-left: 17px"
          ></i>
        </div>
        <div class="flex-y-center">
          <el-button
            size="small"
            @click="operatingRecordDrawerVisibility = true"
            >流程详情</el-button
          >
        </div>
      </div>
      <el-descriptions
        border
        labelClassName="table-label"
        :contentStyle="{ verticalAlign: 'middle' }"
      >
        <!-- <el-descriptions-item label="接车员" :span="1">
          {{ billVo.pickUpUserName }}
        </el-descriptions-item> -->
        <el-descriptions-item label="操作门店" :span="1">
          {{ infoVo.companyName }}
        </el-descriptions-item>
        <el-descriptions-item label="进店时间" :span="1">
          {{ billVo.dateCreated }}
        </el-descriptions-item>
        <el-descriptions-item label="单据号" :span="1">
          <template>
            <span
              class="blue pointer"
              style="word-break: break-word"
              @click="goDetail(billVo.billId)"
            >
              {{ billVo.billCode }}</span
            >
          </template>
        </el-descriptions-item>
        <el-descriptions-item label="单据金额" :span="1">
          {{ billVo.documentAmount }}
        </el-descriptions-item>
        <el-descriptions-item label="送修人" :span="1">
          {{ billVo.contactName }}
        </el-descriptions-item>
        <el-descriptions-item label="送修人电话" :span="1">
          {{ billVo.contactPhone }}
        </el-descriptions-item>
        <el-descriptions-item label="报案号" :span="1">
          <p style="word-break: break-word">{{ billVo.caseNum }}</p>
        </el-descriptions-item>
        <el-descriptions-item :span="1">
          <template slot="label">
            保险公司
            <el-tooltip content="商业险保险公司">
              <i
                style="margin-left: 5px"
                class="iconfont grow-icon_doubt color626"
              ></i>
            </el-tooltip>
          </template>
          {{ billVo.insuranceName }}
        </el-descriptions-item>
        <el-descriptions-item label="开票主体" :span="1">
          {{ billVo.invoice }}
        </el-descriptions-item>
        <template v-if="infoVo.tcCaseNum">
          <el-descriptions-item label="交强报案号" :span="1">
            <p style="word-break: break-word">{{ infoVo.tcCaseNum }}</p>
          </el-descriptions-item>
          <el-descriptions-item label="交强保险公司" :span="2">
            {{ infoVo.tcInsuranceCompany }}
          </el-descriptions-item>
        </template>
        <el-descriptions-item label="事故责任" :span="1">
          {{ billVo.dutyType | dict('dutyType') }}
        </el-descriptions-item>
        <el-descriptions-item label="车单备注" :span="2">
          {{ billVo.companyRemark }}
        </el-descriptions-item>
      </el-descriptions>

      <el-descriptions
        border
        labelClassName="table-label"
        :contentStyle="{ verticalAlign: 'middle' }"
        style="margin-top: 32px"
      >
        <el-descriptions-item label="报案日期" :span="1">
          {{ infoVo.reportingTime }}
        </el-descriptions-item>
        <el-descriptions-item label="定损员" :span="1" style="width: 450px">
          {{ infoVo.lossDeterminationPersonName }}
        </el-descriptions-item>
        <el-descriptions-item label="联系电话" :span="1">
          {{ infoVo.lossDeterminationPersonPhone }}
        </el-descriptions-item>
        <el-descriptions-item label="拍照要求" :span="2">
          {{ infoVo.photoRequest }}
        </el-descriptions-item>
        <el-descriptions-item label="风险评估" :span="1">
          {{
            [true, false].includes(infoVo.riskAssessment)
              ? infoVo.riskAssessment
                ? '有风险'
                : '无风险'
              : ''
          }}
        </el-descriptions-item>
        <el-descriptions-item label="拖车费" :span="1">
          {{ infoVo.towingCharge | toThousands }}
        </el-descriptions-item>
        <el-descriptions-item label="车损金额" :span="1">
          {{ infoVo.carDamageAmount | toThousands }}
        </el-descriptions-item>
        <el-descriptions-item label="预估金额" :span="1">
          {{ infoVo.estimatedAmount | toThousands }}
        </el-descriptions-item>
        <el-descriptions-item label="核损金额" :span="1">
          {{ infoVo.lossAssessmentAmount | toThousands }}
        </el-descriptions-item>
        <el-descriptions-item label="定损金额" :span="1">
          {{ infoVo.lossAmount | toThousands }}
        </el-descriptions-item>
        <el-descriptions-item label="最终定损金额" :span="1">
          {{ infoVo.finalLossAmount | toThousands }}
        </el-descriptions-item>
        <el-descriptions-item label="跟进进展" :span="1">
          <div
            :style="
              $lodash.get(
                followUpStateList.find(
                  (item) => item.value === infoVo.followUpState
                ),
                'iconStyle',
                ''
              )
            "
          >
            {{ infoVo.followUpState | dict('followUpStateList', 'iconText') }}
          </div>
          <span style="margin-left: 5px">{{
            infoVo.followUpState | dict('followUpStateList')
          }}</span>
        </el-descriptions-item>

        <el-descriptions-item label="收旧情况" :span="1">
          <p v-if="![0, 2].includes(infoVo.recoveryState)">
            {{ infoVo.recoveryState | dict('recoveryState') }}
          </p>
          <p class="red" v-if="infoVo.recoveryState == 2">
            {{ infoVo.recoveryState | dict('recoveryState') }}
          </p>
        </el-descriptions-item>
        <el-descriptions-item label="复勘情况" :span="1">
          <p v-if="![0, 2].includes(infoVo.investigationState)">
            {{ infoVo.investigationState | dict('investigationState') }}
          </p>
          <p class="red" v-if="infoVo.investigationState == 2">
            {{ infoVo.investigationState | dict('investigationState') }}
          </p>
        </el-descriptions-item>
        <el-descriptions-item label="回款状态" :span="1">
          {{ infoVo.collectionState == 0 ? '未回款' : '已回款' }}
        </el-descriptions-item>
        <el-descriptions-item label="备注" :span="3">
          {{ infoVo.remark }}
        </el-descriptions-item>

        <el-descriptions-item label="是否有免赔" :span="1">
          {{ infoVo.exemptionPay2000Str }}
        </el-descriptions-item>
        <el-descriptions-item label="免赔金额" :span="1">
          <span v-if="[1].includes(infoVo.isExemptionPay2000)">
            {{ infoVo.exemptionPayMoney | toThousands }}
          </span>
        </el-descriptions-item>
        <el-descriptions-item label="免赔支付方" :span="1">
          {{ infoVo.settlementMethodStr }}
          <span
            v-if="
              [0].includes(infoVo.settlementMethod) &&
              [1].includes(infoVo.isExemptionPay2000)
            "
          >
            ({{ infoVo.settlementClientName }})
          </span>
        </el-descriptions-item>
      </el-descriptions>
      <!-- 理赔认领 -->
      <el-descriptions
        border
        labelClassName="table-label"
        :contentStyle="{ verticalAlign: 'middle' }"
        style="margin-top: 32px"
      >
        <el-descriptions-item label="车单状态" :span="1">
          {{ billVo.billState | dict('carBillState') }}
        </el-descriptions-item>
        <el-descriptions-item label="单据金额" :span="1">
          {{ billVo.documentAmount | toThousands }}
        </el-descriptions-item>
        <el-descriptions-item label="认领状态" :span="1">
          {{ claimVo.claimStatusStr }}
        </el-descriptions-item>
        <el-descriptions-item label="回款金额" :span="1">
          {{ claimVo.claimAmount | toThousands }}
        </el-descriptions-item>
        <el-descriptions-item label="挂账车队金额" :span="1">
          <span>{{ infoVo.pendingAmount | toThousands }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="挂账车队账单确认" :span="1">
          <span
            v-if="
              infoVo.receivingAmountAudit !== undefined &&
              infoVo.billConfirm != undefined
            "
          >
            {{ infoVo.billConfirm === true ? '是' : '否' }}
          </span>
        </el-descriptions-item>
        <el-descriptions-item label="坏账金额" :span="1">
          <span>{{ infoVo.badDebtsAmount | toThousands }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="坏账处理" :span="1">
          <span
            v-if="
              infoVo.receivingAmountAudit !== undefined &&
              infoVo.includeBadDebts === true
            "
          >
            {{ infoVo.includeBadDebts === true ? '是' : '否' }}
          </span>
        </el-descriptions-item>
        <el-descriptions-item label="坏账处理人" :span="1">
          <span
            v-if="
              infoVo.receivingAmountAudit !== undefined &&
              infoVo.badDebtsUserName !== undefined
            "
          >
            {{ infoVo.badDebtsUserName }}
          </span>
        </el-descriptions-item>
        <el-descriptions-item label="回款认领备注" :span="3">
          {{ claimVo.claimRemark }}
        </el-descriptions-item>
        <el-descriptions-item label="坏账审核通过" :span="1">
          <span
            v-if="
              infoVo.receivingAmountAudit !== undefined &&
              infoVo.includeBadDebts === true &&
              infoVo.badDebtsConfirm !== undefined
            "
          >
            {{ infoVo.badDebtsConfirm === true ? '是' : '否' }}
          </span>
        </el-descriptions-item>
        <el-descriptions-item label="审核留言" :span="3">
          <span
            v-if="
              infoVo.receivingAmountAuditComment !== undefined &&
              infoVo.receivingAmountAudit !== undefined
            "
          >
            {{ infoVo.receivingAmountAuditComment }}
          </span>
        </el-descriptions-item>
      </el-descriptions>
      <div class="car-imgs" style="padding-bottom: 30px">
        <p>图片：</p>
        <span
          v-if="
            !picVo.runningLicenceImg &&
            !picVo.picDrivingLicence &&
            !picVo.feeListPicA &&
            !picVo.feeListPicB &&
            !picVo.feeListPicC &&
            !feeListPicOthersList.length &&
            !carBillImages.length
          "
          class="color999"
          >暂无附件图片</span
        >
        <div v-else class="flex">
          <div v-if="carBillImages.length">
            <el-badge :value="carBillImages.length">
              <img
                class="thumbnail-img"
                @click="previewImgs(carBillImages)"
                :src="carBillImages[0]"
              />
            </el-badge>
            <p style="margin-top: 5px; text-align: center">环检图</p>
          </div>

          <div class="flex">
            <upload-file
              :value="picVo.runningLicenceImg"
              disabled
              title="行驶证"
            ></upload-file>
            <upload-file
              :value="picVo.picDrivingLicence"
              disabled
              title="驾驶证"
            ></upload-file>
            <upload-file
              :value="picVo.feeListPicA"
              disabled
              title="定损单1"
            ></upload-file>
            <upload-file
              :value="picVo.feeListPicB"
              disabled
              title="定损单2"
            ></upload-file>
            <upload-file
              :value="picVo.feeListPicC"
              disabled
              title="定损单3"
            ></upload-file>
          </div>
          <div class="flex">
            <upload-file-list
              v-model="picVo.feeListPicOthersList"
              disabled
            ></upload-file-list>
          </div>
        </div>
      </div>

      <div>
        <el-table :data="messageList" border>
          <!-- <el-table-column type="index" label="序号" width="60">
          </el-table-column> -->
          <el-table-column width="100" label="操作" prop="type">
            <template #default="{ row }">
              {{ row.type | dict('carLossDeterminationBillOperationType') }}
            </template>
          </el-table-column>
          <el-table-column width="160" label="操作时间" prop="lastUpdated">
          </el-table-column>
          <el-table-column width="120" label="操作人" prop="userName">
          </el-table-column>
          <el-table-column min-width="100" label="当次留言" prop="message">
          </el-table-column>
          <template #append>
            <p style="text-align: center; padding: 10px">
              <span
                v-if="hasMoreMessage"
                class="pointer blue"
                @click="getMessageRecord(false)"
                >加载更多</span
              >
              <span v-else>已加载全部</span>
            </p>
          </template>
        </el-table>
      </div>

      <div style="padding-top: 30px">
        <p>
          附件：
          <span style="color: #909399; font-size: 12px">
            只支持上传zip文件，最多5个
          </span>
        </p>
        <div
          style="margin-left: 20px; margin-top: 10px; margin-bottom: 5px"
          v-if="attachmentList.length < 5"
        >
          <el-button size="small" @click="chooseAttachmentFile">
            选择文件
          </el-button>
        </div>
        <div style="margin: 10px 0 0 20px" class="flex-y">
          <div
            v-for="(item, i) in attachmentList"
            :key="i"
            style="margin-bottom: 10px"
          >
            <i
              class="iconfont grow-icon_delete3 orange el-icon--left pointer"
              @click="handleAttachmentDelete(item, i)"
            ></i>
            <span
              style="text-decoration: none; font-size: 14px"
              class="blue pointer"
              @click="handleDownloadFile(item)"
              >{{ item.name }} <i class="iconfont grow-icon_download"></i
            ></span>
          </div>
        </div>
      </div>
    </div>

    <!-- footer -->
    <div class="footer">
      <!-- 0 待报损 -->
      <template v-if="infoVo.lossDeterminationState == 0">
        <el-button v-auth="592" size="small" type="primary" @click="nextStep(0)"
          >完成报损</el-button
        >
      </template>
      <!-- 7 待调度 -->
      <template v-if="infoVo.lossDeterminationState == 7">
        <el-button v-auth="594" size="small" type="primary" @click="nextStep(2)"
          >完成调度</el-button
        >
        <el-button v-auth="593" size="small" @click="previousStep(1)"
          >撤销报损</el-button
        >
      </template>
      <!-- 4 待拍照对件 -->
      <template v-if="infoVo.lossDeterminationState == 4">
        <el-button v-auth="517" size="small" type="primary" @click="nextStep(4)"
          >完成对件</el-button
        >
        <el-button v-auth="595" size="small" @click="previousStep(3)"
          >撤销调度</el-button
        >
      </template>
      <!-- 8 待提定损 -->
      <template v-if="infoVo.lossDeterminationState == 8">
        <el-button v-auth="459" size="small" type="primary" @click="nextStep(6)"
          >提交定损</el-button
        >
        <el-button v-auth="520" size="small" @click="previousStep(5)"
          >撤销对件</el-button
        >
      </template>
      <!-- 1 待出定损 -->
      <template v-if="infoVo.lossDeterminationState == 1">
        <el-button v-auth="460" size="small" type="primary" @click="nextStep(8)"
          >已出定损</el-button
        >
        <el-button v-auth="597" size="small" @click="previousStep(7)"
          >撤销提损</el-button
        >
      </template>
      <!-- 30 待复勘 -->
      <template
        v-if="
          infoVo.lossDeterminationState == 2 && infoVo.investigationState == 2
        "
      >
        <el-button
          v-auth="598"
          size="small"
          type="primary"
          @click="secondEdit('完成复勘')"
          >完成复勘</el-button
        >
        <el-button v-auth="463" size="small" @click="previousStep(9)"
          >撤销出损</el-button
        >
      </template>
      <!-- 31 待收旧 -->
      <template
        v-else-if="
          infoVo.lossDeterminationState == 2 && infoVo.recoveryState == 2
        "
      >
        <el-button
          v-auth="599"
          size="small"
          type="primary"
          @click="secondEdit('完成收旧')"
          >完成收旧</el-button
        >
        <el-button v-auth="463" size="small" @click="previousStep(9)"
          >撤销出损</el-button
        >
      </template>
      <!-- 2 已出定损 -->
      <template v-else-if="infoVo.lossDeterminationState == 2">
        <el-button
          v-auth="518"
          size="small"
          type="primary"
          @click="lastStep(12)"
          >已提资料</el-button
        >
        <el-button v-auth="463" size="small" @click="previousStep(9)"
          >撤销出损</el-button
        >
      </template>
      <!-- 5 已提资料 -->
      <template v-if="infoVo.lossDeterminationState == 5">
        <el-button
          v-auth="462"
          size="small"
          type="primary"
          @click="gotoPaymentReceivedDialog"
          >回款认领</el-button
        >
        <el-button v-auth="519" size="small" @click="previousStep(13)"
          >撤销提交</el-button
        >
      </template>
      <!-- 3 已回款 -->
      <template
        v-if="
          infoVo.lossDeterminationState === 3100 ||
          infoVo.lossDeterminationState === 3101
        "
      >
        <!-- 
        1、部分回款
        2、交商不同、主次责，多个保险公司回款的
        3、回款认领+挂账+坏账 不等于 待收金额 或者 最终定损金额
        -->
        <el-button
          v-auth="462"
          v-if="isShowGotoPaymentReceivedDialog()"
          size="small"
          type="primary"
          @click="gotoPaymentReceivedDialog"
          >回款认领</el-button
        >
        <!-- 部分回款 && 未审核 || 坏账审核不通过，继续 审核 -->
        <el-button
          v-auth="666"
          v-if="
            infoVo.lossDeterminationState === 3100 &&
            infoVo.forwardToAudited === true
          "
          size="small"
          type="primary"
          @click="gotoReceivingAmountAuditDialog"
          >审核处理</el-button
        >
        <el-button
          v-auth="464"
          v-if="
            infoVo.lossDeterminationState === 3100 ||
            infoVo.lossDeterminationState === 3101
          "
          size="small"
          @click="previousStep(15)"
          >撤销认领</el-button
        >
      </template>
      <el-button size="small" v-auth="696" @click="gotoExemptionDialog"
        >调整免赔</el-button
      >
      <el-button size="small" @click="messageDialogVisibility = true"
        >留言</el-button
      >
    </div>

    <!-- 操作记录 -->
    <operating-record-drawer
      v-if="operatingRecordDrawerVisibility"
      :visibility.sync="operatingRecordDrawerVisibility"
      :id="carLossDeterminationBillId"
    ></operating-record-drawer>

    <!-- 留言弹窗 -->
    <el-dialog
      v-if="messageDialogVisibility"
      :visible.sync="messageDialogVisibility"
      title="留言"
      width="40%"
      append-to-body
    >
      <div>
        <p style="margin-bottom: 10px">当次留言：</p>
        <el-input
          type="textarea"
          size="small"
          v-model="messageContent"
          :rows="2"
          maxlength="300"
          show-word-limit
          placeholder="请输入当次留言，最多300个字符"
        ></el-input>
      </div>
      <template #footer>
        <el-button type="primary" @click="saveMessage" size="small"
          >确 定</el-button
        >
        <el-button @click="messageDialogVisibility = false" size="small"
          >取 消</el-button
        >
      </template>
    </el-dialog>
    <!--调整免赔信息弹窗 -->
    <EditExemptionDialog
      v-if="editExemptionDialogVisibility"
      :visibility.sync="editExemptionDialogVisibility"
      :id="carLossDeterminationBillId"
      :infoVoData="infoVo"
      title="调整免赔信息"
      @save="init"
    ></EditExemptionDialog>
    <!-- 回款认领弹窗 -->
    <PaymentReceivedDialog
      v-if="paymentReceivedDialogVisibility"
      :visibility.sync="paymentReceivedDialogVisibility"
      :id="carLossDeterminationBillId"
      :billVoData="billVo"
      :carVoData="carVo"
      :infoVoData="infoVo"
      :picVoData="picVo"
      title="认领回款"
      :lastName="infoVo.previousStateFollowUpPeopleName"
      :lastId="infoVo.previousStateFollowUpPeople"
      @gotoClaimDetailPage="handleClose"
      @save="init"
    ></PaymentReceivedDialog>
    <!-- 回款认领审核弹窗 -->
    <AuditingEditDialog
      v-if="receivingAmountAuditDialogVisibility"
      :visibility.sync="receivingAmountAuditDialogVisibility"
      :id="carLossDeterminationBillId"
      :billVoData="billVo"
      :carVoData="carVo"
      :infoVoData="infoVo"
      :picVoData="picVo"
      title="审核处理"
      :lastName="infoVo.previousStateFollowUpPeopleName"
      :lastId="infoVo.previousStateFollowUpPeople"
      @save="init"
    ></AuditingEditDialog>
    <!-- 撤销弹窗 -->
    <reversal-dialog
      v-if="previousDialogVisibility"
      :visibility.sync="previousDialogVisibility"
      :id="carLossDeterminationBillId"
      :type="previousType"
      :title="previousDialogTitle"
      :lastName="infoVo.previousStateFollowUpPeopleName"
      :lastId="infoVo.previousStateFollowUpPeople"
      @save="init"
    ></reversal-dialog>

    <!-- 编辑弹窗 -->
    <edit-dialog
      v-if="editDialogVisibility"
      :visibility.sync="editDialogVisibility"
      :id="carLossDeterminationBillId"
      :type="editType"
      :title="editDialogTitle"
      :form="editForm"
      @save="init"
    ></edit-dialog>

    <!-- 完成复勘 完成收旧 弹窗 -->
    <secondEditDialog
      v-if="secondEditDialogVisibility"
      :visibility.sync="secondEditDialogVisibility"
      :id="carLossDeterminationBillId"
      :title="secondEditDialogTitle"
      :form="secondEditForm"
      @save="init"
    ></secondEditDialog>
  </el-drawer>
</template>
<script>
//列表接口
import {
  getCarLossDeterminationBillInfo,
  getCarLossDeterminationBillLockState,
  setCarLossDeterminationBillLock,
  removeCarLossDeterminationBillLock,
  getCarLossDeterminationBillOperateLossRepor,
  getCarLossDeterminationConfirmLossDetermination,
  getCarLossDeterminationBillCancelOperate,
  getBillLocakConfirmPaymentCollection,
  getCarLossDeterminationBillUpdate,
  carLossDeterminationBillNext,
  carLossDeterminationBillBack,
} from '@/api/financial/pickSingle';

import { chooseFile } from '@/utils/fileApi';

import { uploadZipFile } from '@/api/carBill/carLossDeterminationBill';

import {
  getCarLossDeterminationBillMessageRecord,
  addMessageForCarLossDeterminationBill,
  submissionAndPaymentCollection,
  downloadZipFileUrl,
  updateAttachmentFile,
} from '@/api/carBill/carLossDeterminationBill';

//模糊搜索接口
import { searchStore as searchStoreApi } from '@/api/customer/store';
// 字典数据
import { followUpStateList } from '@/service/dict/dictData';
// 工具
import Big from 'big.js';
import { mapState } from 'vuex';
import { exportCusterListUrl } from '@/api/customer/customer';
import { previewImage, downloadFile } from '@/utils/fileApi';

import { dict as dictFunction } from '@/service/dict/dictFilter';

import uploadFileList from '@/components/uploadFileList';
import UploadFile from '@/components/uploadFile.vue';
import OperatingRecordDrawer from './operatingRecordDrawer.vue';
import reversalDialog from './reversalDialog.vue';
import AuditingEditDialog from './AuditingEditDialog.vue';
import PaymentReceivedDialog from './PaymentReceivedDialog.vue';
import EditExemptionDialog from './EditExemptionDialog.vue';
import editDialog from './editDialog.vue';
import secondEditDialog from './secondEditDialog.vue';

export default {
  name: 'carLossDeterminationBillDetailDrawer',
  props: {
    // 弹窗可见性
    visibility: { type: Boolean, default: false },
    // 车辆定损单ID
    carLossDeterminationBillId: {
      type: [Number, String],
      default: '',
    },
    // 车单ID
    billId: {
      type: [Number, String],
      default: '',
    },
  },
  components: {
    UploadFile,
    uploadFileList,
    OperatingRecordDrawer,
    reversalDialog,
    editDialog,
    secondEditDialog,
    PaymentReceivedDialog,
    EditExemptionDialog,
    AuditingEditDialog,
  },
  data() {
    return {
      followUpStateList,
      feeListPicOthersList: [],
      // 状态
      stateDict: {
        0: 'grow-a-state_loss13x primary', //待报损
        1: 'grow-daichudingsun primary', //提交定损
        2: 'grow-a-state_loss43x primary', //已出定损
        3: 'grow-a-state_loss63x primary', //已回款
        // 4: 'grow-a-state_loss23x primary', //拍照对件
        4: 'grow-daipaizhaoduijian primary', //拍照对件
        5: 'grow-a-state_loss53x primary', //已提交资料
        6: 'grow-a-state_loss73x', //已取消
        7: 'grow-daitiaodu3x primary', //待调度
        8: 'grow-daitidingsun primary', //待提定损
        30: 'grow-daifukan primary', //待复勘
        31: 'grow-daishoujiu  primary', //待收旧
        3100: 'grow-PARTIAL_PAYMENT_RECEIVED primary', //部分回款
        3101: 'grow-ALL_PAYMENT_RECEIVED  primary', //全部回款
      },

      // 车单信息
      billVo: {},
      // 车辆信息
      carVo: {},
      // 定损单信息
      infoVo: {},
      // 图片信息
      picVo: {},
      // 认领信息
      claimVo: {},

      //编辑弹窗可见性
      editDialog: false,
      //二次确认提示弹窗可见性
      confirmDialog: false,
      //撤销二次确认提示弹窗可见性
      revocationDialog: false,
      //已出定损编辑提示可见性
      showLossTip: false,

      // 流程详情抽屉显示
      operatingRecordDrawerVisibility: false,
      // 调整免赔弹出框
      editExemptionDialogVisibility: false,
      // 留言分页
      messagePage: 1,
      // 留言列表
      messageList: [],
      hasMoreMessage: true,

      // 留言内容
      messageContent: '',
      // 留言弹窗显示
      messageDialogVisibility: false,
      // 附件操作弹窗显示
      attachmentDialogVisibility: false,

      // 撤销类型
      previousType: '',
      // 撤销标题
      previousDialogTitle: '',
      // 撤销弹窗显示
      previousDialogVisibility: false,

      // 编辑弹窗显示
      editDialogVisibility: false,
      // 弹窗标题
      editDialogTitle: '',
      // 编辑类型
      editType: '',

      // 收旧、复勘弹窗
      secondEditDialogVisibility: false,
      secondEditDialogTitle: '',
      secondEditForm: {},
      // 回款认领， 回款认领审核弹窗
      receivingAmountAuditDialogVisibility: false,
      paymentReceivedDialogVisibility: false,
    };
  },
  computed: {
    stateIcon() {
      if (this.infoVo.lossDeterminationState === 2) {
        if (this.infoVo.investigationState === 2) {
          return this.stateDict[30];
        }
        if (this.infoVo.recoveryState === 2) {
          return this.stateDict[31];
        }
      }
      return this.stateDict[this.infoVo.lossDeterminationState];
    },
    // 环检图片
    carBillImages() {
      const {
        picVo: { carBodyInfoVo = {} },
      } = this;
      let result = [];
      Object.values(carBodyInfoVo).forEach((item) => {
        if (typeof item === 'string') {
          result.push(item);
        } else if (Array.isArray(item)) {
          result.push(...item);
        }
      });
      return result;
    },
    // 附件列表
    attachmentList() {
      const url =
        this.infoVo.attachmentUrl?.split(',').filter((el) => el) || [];
      const names =
        this.infoVo.attachmentName?.split(',').filter((el) => el) || [];
      return url.map((item, index) => {
        return {
          url: item,
          name: names[index],
        };
      });

      // return [
      //   {
      //     name: 'xxx.zip',
      //     url: 'https://growxny-dev-center.oss-cn-guangzhou.aliyuncs.com/template/20231019/%E5%8D%8F%E8%AE%AE%E4%BB%B7-%E6%9C%8D%E5%8A%A1%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
      //   },
      //   {
      //     name: 'hhh.zip',
      //     url: 'https://growxny-dev-center.oss-cn-guangzhou.aliyuncs.com/template/20231019/%E5%8D%8F%E8%AE%AE%E4%BB%B7-%E6%9C%8D%E5%8A%A1%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
      //   },
      // ];
    },
    // 编辑
    editForm() {
      return { ...this.infoVo, ...this.picVo };
    },
  },
  mounted() {},
  created() {
    this.init();
  },
  methods: {
    isShowGotoPaymentReceivedDialog() {
      // 1、部分回款
      // 2、交商不同、主次责，多个保险公司回款的
      // 3、回款认领+挂账+坏账 不等于 待收金额 或者 最终定损金额
      // 4、如果还未提交到审核处理
      let isShow =
        this.infoVo.lossDeterminationState === 3100 &&
        this.infoVo.forwardToAudited === false;
      // if (
      //   this.infoVo.lossDeterminationState === 3100 &&
      //   this.infoVo.multiInsurancePayment === true
      // ) {
      //   let claimAmount = Number(this.claimVo.claimAmount || 0);
      //   let pendingAmount = Number(this.infoVo.pendingAmount || 0);
      //   let badDebtsAmount = Number(this.infoVo.badDebtsAmount || 0);
      //   const compareAmount = claimAmount + pendingAmount + badDebtsAmount;
      //   if (this.billVo.billState === 1) {
      //     let unclearedDebtFixed = Number(this.billVo.unclearedDebtFixed || 0);
      //     if (unclearedDebtFixed > compareAmount) {
      //       isShow = true;
      //     }
      //   } else {
      //     let finalLossAmount = Number(this.infoVo.finalLossAmount || 0);
      //     if (finalLossAmount > compareAmount) {
      //       isShow = true;
      //     }
      //   }
      // }
      return isShow;
    },
    /**
     * 调整免赔弹窗
     */
    gotoExemptionDialog() {
      this.editExemptionDialogVisibility = true;
    },
    /**
     * 打开回款认领弹窗
     */
    gotoPaymentReceivedDialog() {
      this.paymentReceivedDialogVisibility = true;
    },
    /**
     * 打开回款认领审核处理弹窗
     */
    gotoReceivingAmountAuditDialog() {
      this.receivingAmountAuditDialogVisibility = true;
    },
    /**
     * 初始化
     */
    init() {
      // 重新获取详情内容，留言记录
      this.getData();
      this.getMessageRecord(true);
    },
    /**
     * 获取详情数据
     */
    getData() {
      var data = {
        id: Number(this.carLossDeterminationBillId),
      };
      getCarLossDeterminationBillInfo(data).then((res) => {
        let { billVo, carVo, infoVo, picVo, claimVo } = res;
        this.billVo = billVo;
        this.carVo = carVo;
        this.infoVo = infoVo;
        this.picVo = picVo;
        this.claimVo = claimVo;
        this.feeListPicOthersList = res.picVo.feeListPicOthersList;
      });
    },

    /**
     * 处理关闭抽屉
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },

    //封装测但检验锁单，跟车单解锁操作
    carBillLock(dialog) {
      getCarLossDeterminationBillLockState(Number(this.infoVo.id)).then(
        (res) => {
          if (res.data.code == '200') {
            setCarLossDeterminationBillLock(Number(this.infoVo.id)).then(
              (res) => {}
            );
            this[dialog] = true;
          } else {
            this.$confirm(
              res.data.message + '！是否对此单进行解锁操作',
              '提示',
              {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
              }
            ).then(() => {
              removeCarLossDeterminationBillLock(Number(this.infoVo.id)).then(
                (res) => {
                  if (res.code === 200) {
                    this.$message({
                      type: 'success',
                      message: '解锁成功!',
                    });
                    setCarLossDeterminationBillLock(
                      Number(this.infoVo.id)
                    ).then((res) => {});
                    this[dialog] = true;
                  }
                }
              );
            });
          }
        }
      );
    },

    /**
     * 跳转详情页
     */
    goDetail(id) {
      this.$emit('update:visibility', false);
      this.$router.push({
        name: 'carBillDetail',
        query: {
          id: id,
          t: new Date().getTime(),
        },
      });
    },
    /**
     * 预览图片
     * @param {Array<String>} imgs 图片数组
     */
    previewImgs(imgs, titles) {
      previewImage(imgs, 0, titles);
    },
    /**
     * 获取留言记录
     */
    getMessageRecord(reset) {
      if (reset) {
        this.messagePage = 1;
      } else {
        this.messagePage++;
      }
      getCarLossDeterminationBillMessageRecord({
        id: this.carLossDeterminationBillId,
        size: 5,
        current: this.messagePage,
      }).then((res) => {
        if (reset) {
          this.messageList = res.records;
        } else {
          this.messageList.push(...res.records);
        }
        this.hasMoreMessage = this.messagePage < res.pages;
      });
    },
    handleAfterUploadOrDeleteAttachmentFile(attachmentList) {
      const data = {
        id: this.carLossDeterminationBillId,
        attachmentName: attachmentList.map((item) => item.name).join(','),
        attachmentUrl: attachmentList
          .map((item) => item.url.split('/').at(-1))
          .join(','),
      };
      updateAttachmentFile(data).then((res) => {
        this.init();
        this.$message.success('附件更新成功!');
      });
    },
    /**
     * 删除
     */
    handleAttachmentDelete(item, index) {
      this.attachmentList.splice(index, 1);
      this.handleAfterUploadOrDeleteAttachmentFile(this.attachmentList);
    },
    /**
     * 下载
     */
    handleDownloadFile(item) {
      var data = new FormData();
      data.append('fileName', item.url);
      downloadFile({
        url: downloadZipFileUrl,
        fileName: item.name,
        data,
        prefix: '/oss',
      });
    },
    /**
     * 选择文件
     */
    chooseAttachmentFile() {
      chooseFile({ accept: '.zip', multiple: false }).then((file) => {
        console.log(file[0]);
        // 大于100M不允许上传
        if (file[0].size > 1024 * 1024 * 100) {
          this.$message.error('上传的附件大小不能超过100MB!');
          return;
        }
        uploadZipFile(file[0]).then((res) => {
          this.attachmentList.push({
            url: res.imageUrl,
            name: file[0].name.replace(/,/g, ''),
          });
          this.handleAfterUploadOrDeleteAttachmentFile(this.attachmentList);
        });
      });
    },
    /**
     * 保存留言
     */
    saveMessage() {
      if (!this.messageContent) {
        this.$message.warning('当次留言不能为空');
        return;
      }
      addMessageForCarLossDeterminationBill({
        id: this.carLossDeterminationBillId,
        message: this.messageContent,
      }).then(() => {
        this.$message.success('留言成功');
        this.messageDialogVisibility = false;
        this.messageContent = '';
        this.init();
      });
    },
    /**
     * 撤销到上一步
     * @param {Number} type 撤销类型
     */
    previousStep(type) {
      this.previousType = type;
      this.previousDialogTitle =
        dictFunction(type, 'carLossDeterminationBillOperationType') || '撤销';
      this.previousDialogVisibility = true;
    },
    /**
     * 进行下一步
     */
    nextStep(type) {
      this.editType = type;
      this.editDialogVisibility = true;
      this.editDialogTitle =
        dictFunction(type, 'carLossDeterminationBillOperationType') || '编辑';
    },
    /**
     * 收旧、复勘操作
     */
    secondEdit(title) {
      this.secondEditDialogTitle = title;
      this.secondEditForm = {
        followUpPeople: this.infoVo.followUpPeople,
        followUpPeopleName: this.infoVo.followUpPeopleName,
        followUpState: this.infoVo.followUpState,
        attachmentUrl: this.infoVo.attachmentUrl,
        attachmentName: this.infoVo.attachmentName,
      };
      this.secondEditDialogVisibility = true;
    },
    /**
     * 已提资料和回款认领
     */
    lastStep(type) {
      this.$confirm('确认操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        submissionAndPaymentCollection({
          id: this.carLossDeterminationBillId,
          type,
        }).then((res) => {
          this.$message.success(res.data || '操作成功!');
          this.init();
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.el-descriptions ::v-deep .table-label {
  vertical-align: middle;
  color: #666;
  text-align: center;
  background: $descriptions-label-bg;
  width: 15%;
}

.last-type-sty {
  display: flex;
  .el-input {
    margin-left: 20px;
  }
}

.report-sty {
  display: flex;
  margin: 31px 0px 16px 0px;
  justify-content: space-between;
}
.car-imgs {
  display: flex;
  margin: 30px 0px;
  color: #666;
}
.footer {
  position: fixed;
  bottom: 0px;
  right: 0;
  width: 62%;
  padding: 10px 40px;
  box-sizing: border-box;
  background: #fff;
  text-align: right;
  z-index: 999;
}
.car-logo {
  width: 64px;
  height: 64px;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.car-info {
  .car-number {
    color: #303133;
    font-size: 20px;
    font-weight: 500;
  }

  .car-info-tag {
    display: inline-block;
    margin-left: 16px;
    padding: 4px 6px;
    border-radius: 2px;
    border: 1px solid #ebeef5;
    font-size: 12px;
    &.car-brand {
      color: #606266;
    }
  }
}
//设置描述列表的行高
::v-deep .el-descriptions .is-bordered .el-descriptions-item__cell {
  padding: 7px 10px !important;
}
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: inherit !important;
}
.thumbnail-img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 4px;
  border: 1px dashed #d9d9d9;
}
</style>
