var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      staticStyle: { width: "50vw" },
      attrs: { placement: "bottom", trigger: "manual" },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c(
        "el-table",
        {
          attrs: {
            "max-height": "400",
            border: "",
            data: _vm.listData,
            "span-method": _vm.spanMethod,
          },
          on: { "row-click": _vm.handleClick },
        },
        [
          _c("el-table-column", {
            attrs: { width: "140", label: "保险公司" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_c("div", [_vm._v(_vm._s(row.insuranceName))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "140", label: "编码", prop: "insuranceNum" },
          }),
          _c("template", { slot: "append" }, [
            _vm.hasMore
              ? _c(
                  "div",
                  {
                    staticClass: "load-more-btn",
                    on: {
                      click: function ($event) {
                        return _vm.getData(false, true)
                      },
                    },
                  },
                  [_vm._v(" 加载更多数据 ")]
                )
              : _vm._e(),
          ]),
        ],
        2
      ),
      _c("el-input", {
        ref: "keyWordInput",
        attrs: {
          slot: "reference",
          size: "small",
          placeholder: "搜索公司名称、编号",
          clearable: "",
        },
        on: {
          input: function ($event) {
            return _vm.getData(true)
          },
          focus: function ($event) {
            _vm.visible = true
          },
          clear: _vm.handleClear,
        },
        slot: "reference",
        model: {
          value: _vm.keyWord,
          callback: function ($$v) {
            _vm.keyWord = $$v
          },
          expression: "keyWord",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }