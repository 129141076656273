import http from '@/service/http';
import store from '@/store';

const defaultListOption = {
  current: 1,
  size: 10,
};

// 获取技师列表
export function getTechniciansList(
  data,
  config = {
    noShowWaiting: true,
  }
) {
  let body;
  if (typeof data === 'number') {
    body = { companyId: data };
  } else if (typeof data === 'object') {
    body = data;
  }
  return http.post('/admin/job/listTechnicians', body, config);
}

// 获取业务员列表
export function getSalesmanList(
  companyId,
  departmentId,
  config = {
    noShowWaiting: true,
  }
) {
  // 22-08-01 国栋:所有员工都是接车员
  return http.post(
    '/admin/job/listSalesman',
    { companyId: companyId, departmentId: departmentId },
    config
  );
}

// 获取员工列表
export function getUserList(
  keyWord = '',
  size = 10,
  current = 1,
  config = {
    noShowWaiting: true,
  }
) {
  return http.post(
    '/admin/job/findByPage',
    { msg: keyWord, companyId: store?.state?.user?.companyId },
    config
  );
}

// 获取技师部门列表
export function getTechniciansDepartmentTree() {
  return http.post('/admin/department/getTechniciansDepartmentTree', {});
}

// 获取业务员部门列表
export function getSalesmanDepartmentTree() {
  return http.post('/admin/department/getSalesmanDepartmentTree', {});
}

// 根据门店体系查询员工分页列表
export function findUserPageByCompanySystem(
  data,
  config = { noShowWaiting: true }
) {
  return http.post('/admin/job/findUserPageByCompanySystem', data, config);
}
// 根据体系查询所有员工分页列表
export function getAllUserByCompanySystemPage(
  keyWord = '',
  size = 50,
  current = 1,
  config = {
    noShowWaiting: true,
  }
) {
  return http.post(
    '/admin/job/getAllUserByCompanySystemPage',
    { msg: keyWord, size: 50, current: 1 },
    config
  );
}

// 体系员工列表

export function getAllUser(data = {}, config = { noShowWaiting: true }) {
  return http.post(
    '/admin/user/allList',
    Object.assign({ size: 50, current: 1 }, data),
    config
  );
}
