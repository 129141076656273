var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    [
      [
        _c("div", { staticClass: "flex" }, [
          _c("div", { staticClass: "flex", staticStyle: { width: "50%" } }, [
            _c("div", { staticClass: "customer-name" }, [_vm._v("协议客户")]),
            _c("div", { staticClass: "customer-contents" }, [
              _vm._v(
                " 客户名称：" +
                  _vm._s(
                    _vm.$lodash.get(
                      _vm.collectionData[0],
                      "client.clientName",
                      ""
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("div", { staticClass: "flex", staticStyle: { width: "50%" } }, [
            _c("div", { staticClass: "customer-name" }, [_vm._v("开票主体")]),
            _c("div", { staticClass: "customer-contents" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$lodash.get(_vm.collectionData[0], "invoiceName[0]", "")
                  ) +
                  " "
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "flex tip-word" }, [
          _c("div", [_vm._v("销账单据清单")]),
          _c(
            "p",
            {
              staticClass: "blue pointer text-underline",
              staticStyle: { "margin-right": "60px" },
              on: {
                click: function ($event) {
                  _vm.selectDocumentsDrawer = true
                },
              },
            },
            [_vm._v(" 选择单据 ")]
          ),
        ]),
        _c(
          "el-table",
          {
            staticClass: "service-table",
            staticStyle: { width: "100%" },
            attrs: {
              border: "",
              data: _vm.collectionData,
              "summary-method": _vm.getSummaries,
              "show-summary": "",
            },
          },
          [
            _c("el-table-column", {
              attrs: { width: "80", label: "操作" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("i", {
                        staticClass: "iconfont grow-icon_delete3 pointer",
                        staticStyle: { color: "#ff9200", "font-size": "22px" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteRow(scope.row, scope.$index)
                          },
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "60", label: "序号", prop: "title" },
            }),
            _c("el-table-column", {
              attrs: { label: "接车单号", width: "200" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("p", { staticClass: "blue" }, [
                        _vm._v(_vm._s(scope.row.billCode)),
                      ]),
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm._f("dict")(scope.row.billType, "carBillType")
                          )
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "checkDate",
                label: "结账(挂账）时间",
                "min-width": "180",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "sourceOfBusiness",
                label: "业务来源",
                "min-width": "100",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "carNumber",
                label: "车牌号",
                "min-widthh": "180",
              },
            }),
            _c("el-table-column", {
              attrs: { prop: "paid", label: "单据金额", "min-widthh": "180" },
            }),
            _c(
              "el-table-column",
              { attrs: { prop: "contractTotal", "min-width": "180" } },
              [
                _c("template", { slot: "header" }, [
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _vm._v(" 挂账(包干）总额 "),
                      _c(
                        "el-tooltip",
                        {
                          attrs: { effect: "light", placement: "bottom-start" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                color: "#666666",
                                "font-size": "13px",
                                "line-height": "16px",
                                "text-indent": "15px",
                              },
                              attrs: { slot: "content" },
                              slot: "content",
                            },
                            [
                              _vm._v(" 包干业务的挂账金额为包干分摊金额，"),
                              _c("br"),
                              _vm._v(" 结账当月显示为查询时点的预分摊金额，"),
                              _c("br"),
                              _vm._v(" 结账次月显示为实际分摊金额 "),
                            ]
                          ),
                          _c("i", {
                            staticClass: "iconfont grow-icon_doubt",
                            staticStyle: { "margin-left": "5px" },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
            _c("el-table-column", {
              attrs: {
                prop: "alreadyPaid",
                label: "已收金额",
                "min-widthh": "180",
              },
            }),
            _c("el-table-column", {
              attrs: { label: "本次收款", "min-widthh": "180" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("number-input", {
                        attrs: {
                          size: "small",
                          clearable: false,
                          min: 1,
                          decimalDigits: "2",
                        },
                        model: {
                          value: row.itemCodes,
                          callback: function ($$v) {
                            _vm.$set(row, "itemCodes", $$v)
                          },
                          expression: "row.itemCodes",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "invoiceNum",
                label: "开票单据号",
                "min-width": "180",
              },
            }),
            _c("el-table-column", {
              attrs: { label: "事故责任", "min-width": "180" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("dict")(row.dutyType, "dutyType")) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "remark", label: "备注", "min-width": "180" },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "remark" },
          [
            _c("el-input", {
              attrs: {
                type: "textarea",
                rows: 4,
                placeholder: "请输入本次收款销账备注",
              },
              model: {
                value: _vm.remarks,
                callback: function ($$v) {
                  _vm.remarks = $$v
                },
                expression: "remarks",
              },
            }),
          ],
          1
        ),
      ],
      _c("template", { slot: "footer" }, [
        _c("div", { staticClass: "flex foot-btn" }, [
          _c(
            "div",
            { staticClass: "foot-btn-item" },
            [
              _vm._v(" 账单时间： "),
              _c("el-date-picker", {
                attrs: {
                  type: "datetime",
                  placeholder: "选择日期",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                },
                model: {
                  value: _vm.dateTime,
                  callback: function ($$v) {
                    _vm.dateTime = $$v
                  },
                  expression: "dateTime",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "foot-btn-item" },
            [
              _vm._v(" 支付方式： "),
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    placeholder: "请选择支付方式",
                    size: "small",
                    "value-key": "payType",
                  },
                  model: {
                    value: _vm.payType,
                    callback: function ($$v) {
                      _vm.payType = $$v
                    },
                    expression: "payType",
                  },
                },
                _vm._l(_vm.payTypeList, function (item) {
                  return _c("el-option", {
                    key: item.payType,
                    attrs: { label: item.payTypeName, value: item },
                  })
                }),
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "blue pay-btn",
                  attrs: { size: "small" },
                  on: { click: _vm.combinationPay },
                },
                [_vm._v("组合支付")]
              ),
            ],
            1
          ),
          _c("p", [
            _vm._v(" 本次收款销账："),
            _c("span", { staticClass: "orange" }, [
              _vm._v(_vm._s(_vm._f("toThousands")(_vm.collectMoney))),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "btn-item" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: { click: _vm.collectionDialogEvent },
                },
                [_vm._v("收款销账")]
              ),
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.cancel } },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "温馨提示",
            visible: _vm.collectionDialogVisible,
            width: "30%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.collectionDialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                "text-align": "center",
                "font-size": "16px",
                color: "#333",
              },
            },
            [_vm._v(" 已确认信息无误，确定收款销账？ ")]
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "center", "margin-top": "30px" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.collectionDialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirmCheck } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "组合支付",
            visible: _vm.payDialogVisible,
            width: "40%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.payDialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "other-chioce-pay" }, [
            _c(
              "div",
              {
                staticClass: "flex color333",
                staticStyle: { "margin-bottom": "16px" },
              },
              [
                _c(
                  "div",
                  { staticStyle: { "margin-right": "30px" } },
                  [
                    _vm._v(" 账单时间： "),
                    _c("el-date-picker", {
                      staticStyle: { width: "200px" },
                      attrs: {
                        size: "small",
                        type: "datetime",
                        "value-format": "yyyy-MM-dd HH:mm:ss",
                        placeholder: "选择日期",
                      },
                      model: {
                        value: _vm.checkTime,
                        callback: function ($$v) {
                          _vm.checkTime = $$v
                        },
                        expression: "checkTime",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticStyle: { "margin-top": "8px" } }, [
                  _vm._v(" 应收金额："),
                  _c("span", { staticClass: "orange" }, [
                    _vm._v(
                      "￥" + _vm._s(_vm._f("toThousands")(_vm.receivableMoney))
                    ),
                  ]),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "flex color333",
                staticStyle: { position: "relative" },
              },
              [
                _c(
                  "el-form",
                  {
                    attrs: {
                      model: _vm.form,
                      "label-width": "200px",
                      size: "small",
                    },
                  },
                  [
                    _vm._l(_vm.billCheckDetails, function (item, index) {
                      return _c("el-row", { key: index }, [
                        _c(
                          "div",
                          {
                            staticClass: "flex",
                            staticStyle: {
                              "margin-bottom": "20px",
                              width: "80%",
                            },
                          },
                          [
                            index > 0
                              ? _c("i", {
                                  staticClass: "iconfont grow-icon_delete3",
                                  staticStyle: {
                                    color: "#ff9200",
                                    "font-size": "22px",
                                    margin: "5px 15px 5px 36px",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deletePayType(index)
                                    },
                                  },
                                })
                              : _vm._e(),
                            index == 0
                              ? _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "margin-right": "14px",
                                      "margin-top": "10px",
                                    },
                                  },
                                  [_vm._v("收款方式:")]
                                )
                              : _vm._e(),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      filterable: "",
                                      placeholder: "请选择支付方式",
                                      size: "small",
                                      "value-key": "payType",
                                    },
                                    model: {
                                      value: item.payType,
                                      callback: function ($$v) {
                                        _vm.$set(item, "payType", $$v)
                                      },
                                      expression: "item.payType",
                                    },
                                  },
                                  _vm._l(_vm.payTypeOptions, function (item) {
                                    return _c("el-option", {
                                      key: item.payType,
                                      attrs: {
                                        label: item.payTypeName,
                                        value: item,
                                        disabled: item.disabled,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 10, offset: 1 } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: " 请输入收款金额",
                                    size: "small",
                                    disabled: item.payType === undefined,
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.changePrice(item.price, index)
                                    },
                                  },
                                  model: {
                                    value: item.price,
                                    callback: function ($$v) {
                                      _vm.$set(item, "price", $$v)
                                    },
                                    expression: "item.price",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ])
                    }),
                    _c(
                      "p",
                      {
                        staticStyle: {
                          position: "absolute",
                          right: "5%",
                          bottom: "30px",
                        },
                      },
                      [
                        _vm._v(" 剩余应收："),
                        _c("span", { staticClass: "orange" }, [
                          _vm._v(
                            "￥" +
                              _vm._s(_vm._f("toThousands")(_vm.remainingMoney))
                          ),
                        ]),
                      ]
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "p",
              {
                staticClass: "pointer blue addOtherPay",
                on: {
                  click: function ($event) {
                    return _vm.addOtherPay()
                  },
                },
              },
              [_vm._v(" +添加其他收款方式 ")]
            ),
          ]),
          _c("el-input", {
            attrs: {
              type: "textarea",
              rows: 4,
              placeholder: "输入本次结账收款备注",
            },
            model: {
              value: _vm.billCheckRemark,
              callback: function ($$v) {
                _vm.billCheckRemark = $$v
              },
              expression: "billCheckRemark",
            },
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer btn-item",
              staticStyle: { "text-align": "center", "margin-top": "30px" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.conTest } },
                [_vm._v("收款销账")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.payDialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.selectDocumentsDrawer
        ? _c("select-documents-drawer", {
            attrs: {
              selectDocumentsDrawer: _vm.selectDocumentsDrawer,
              times: _vm.times,
              selectOldList: _vm.collectionData,
            },
            on: {
              "update:selectDocumentsDrawer": function ($event) {
                _vm.selectDocumentsDrawer = $event
              },
              "update:select-documents-drawer": function ($event) {
                _vm.selectDocumentsDrawer = $event
              },
              selectTableList: _vm.selectList,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }