<template>
  <el-dialog
    :title="title"
    :visible="visibility"
    width="500px"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <el-form
      ref="form"
      size="small"
      :model="editForm"
      :rules="rules"
      label-width="90px"
      style="margin-top: 18px"
    >
      <el-row :gutter="10">
        <el-col :span="24" :offset="0">
          <el-form-item label="车型" prop="carModel">
            <div
              style="height: 32px"
              class="blue pointer flex-y-center"
              @click="selectCarModelDialogVisibility = true"
            >
              选择车型
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24" :offset="0">
          <p style="padding-left: 90px; height: 30px">{{ carModelInfoStr }}</p>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24" :offset="0">
          <el-form-item label="服务名称" prop="serviceItemId">
            <el-select
              v-model="editForm.serviceItemId"
              clearable
              filterable
              remote
              :remote-method="searchAllServiceItem"
              :loading="searchAllServiceItemLoading"
              placeholder="请输入服务名称搜索选择！"
              value-key="serviceItemId"
              @change="serviceItemChangeEvent"
              @clear="serviceItemClearEvent"
            >
              <el-option
                v-for="item in serviceItems"
                :key="item.serviceItemId"
                :label="item.name"
                :value="item"
              >
                <span style="float: left; color: #8492a6; font-size: 13px">
                  {{ item.name }}
                </span>
                <span style="float: right">{{ item.serviceItemCode }}</span>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24" :offset="0">
          <p style="padding-left: 90px; height: 30px">
            {{ selectedServiceItem?.name }}
          </p>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12" :offset="0">
          <el-form-item label="当前服务价">
            {{ selectedServiceItem?.price }}
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="协议价" prop="serviceAgreementPrice">
            <el-input
              v-model.number="editForm.serviceAgreementPrice"
              maxlength="200"
              placeholder="请输入服务协议价！"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24" :offset="0">
          <el-form-item label="商品标准名" prop="goodsId">
            <el-select
              v-model="editForm.goodsId"
              clearable
              filterable
              remote
              :remote-method="searchAllGoods"
              :loading="searchAllGoodsLoading"
              placeholder="请输入商品名称搜索选择！"
              value-key="goodsId"
              @change="goodsItemChangeEvent"
              @clear="goodsItemClearEvent"
            >
              <el-option
                v-for="(item, index) in goodsItems"
                :key="index"
                :label="`${item.name}-${myDict(
                  item.manufactoryType,
                  'goodsManufactoryType'
                )}-${item.matchCarType || ''}`"
                :value="item"
              >
                <span style="color: #8492a6; font-size: 13px">
                  {{ item.name }}-{{
                    item.manufactoryType | dict('goodsManufactoryType')
                  }}-{{ item.matchCarType }}
                </span>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24" :offset="0">
          <p style="padding-left: 90px; height: 30px">
            {{ selectedGoods?.name }}
          </p>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12" :offset="0">
          <el-form-item label="当前商品价">
            {{ selectedGoods?.price }}
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="协议价" prop="goodsAgreementPrice">
            <el-input
              v-model.number="editForm.goodsAgreementPrice"
              maxlength="200"
              placeholder="请输入商品协议价"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <selectCarModelDialog
      v-if="selectCarModelDialogVisibility"
      :visibility.sync="selectCarModelDialogVisibility"
      @select="handleSelectCarModel"
      :value="carModelList"
    ></selectCarModelDialog>
    <template slot="footer">
      <el-button size="small" type="primary" @click="save"> 确 定 </el-button>
      <el-button size="small" @click="handleClose"> 取 消 </el-button>
    </template>
  </el-dialog>
</template>

<script>
// 组件
import selectCarModelDialog from '@/components/businessComponent/selectCarModelDialog.vue';

// 接口
import {
  clientAgreementPriceInfo,
  clientAgreementPriceSave,
  clientAgreementPriceUpdate,
} from '@/api/customer/agreement';
import { searchGoodsByKeyword } from '@/api/stock/goodsSetting';
import { searchServiceItemByKeyword } from '@/api/systemManagement/carOrderSettings/serviceManagement';
import { dict } from '@/service/dict/dictFilter';
export default {
  name: 'addOrEditAgreementPriceDialog',
  components: {
    selectCarModelDialog,
  },
  props: {
    visibility: Boolean,
    agreementId: {
      type: [Number, String],
      required: true,
      default: 0,
    },
    usualQuoteId: [Number, String],
    agreementServicePriceId: [Number, String],
    agreementGoodsPriceId: [Number, String],
  },
  data() {
    return {
      selectCarModelDialogVisibility: false,
      carModelList: [],
      carModelInfoStr: '',
      // 表单信息
      editForm: {},
      // 选中的服务
      selectedServiceItem: {},
      // 选中的商品
      selectedGoods: {},
      searchAllServiceItemLoading: false,
      searchAllServiceItemTimer: undefined,
      serviceItems: [],
      searchAllGoodsLoading: false,
      searchAllGoodsTimer: undefined,
      goodsItems: [],
      otherData: {},
      // 表单规则
      rules: {
        carModel: [
          {
            validator: (rule, value, callback) => {
              const _this = this;
              if (_this.carModelList?.length === 0) {
                return callback(new Error('请选择车型'));
              } else if (_this.carModelList?.length === 1) {
                if (!_this.carModelList[0].modelYearId) {
                  return callback(new Error('请选择车型'));
                }
              }
              callback();
            },
            trigger: 'blur',
          },
        ],
        serviceItemId: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入服务名称搜索选择！',
          },
        ],
        serviceAgreementPrice: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入服务协议价！',
          },
          {
            type: 'number',
            trigger: 'blur',
            message: '服务协议价必须是数字！',
          },
        ],
      },
    };
  },
  computed: {
    title() {
      return this.usualQuoteId ? '编辑客户协议价' : '新增客户协议价';
    },
  },
  created() {
    if (this.usualQuoteId) {
      this.getData();
    }
  },
  methods: {
    myDict(value, key) {
      return dict(value, key);
    },
    searchAllServiceItem(keyword) {
      if (this.searchAllServiceItemTimer) {
        clearTimeout(this.searchAllServiceItemTimer);
      }
      if (keyword === '') {
        this.serviceItems = [];
        this.searchAllServiceItemLoading = false;
        return;
      }
      this.searchAllServiceItemTimer = setTimeout(() => {
        this.searchAllServiceItemLoading = true;
        let params = {};
        if (typeof msg === 'object') {
          params = keyword;
        } else {
          params = { keyword };
        }
        searchServiceItemByKeyword(params)
          .then((res) => {
            this.serviceItems = res || [];
          })
          .finally(() => {
            this.searchAllServiceItemLoading = false;
          });
      }, 800);
    },
    searchAllGoods(keyword) {
      if (this.searchAllGoodsTimer) {
        clearTimeout(this.searchAllGoodsTimer);
      }
      if (keyword === '') {
        this.goodsItems = [];
        this.searchAllGoodsLoading = false;
        return;
      }
      // 提示必须选择商品车型，以便匹配车型件
      if (this.carModelList.length === 0) {
        this.goodsItems = [];
        this.searchAllGoodsLoading = false;
        this.$message.warning('选择商品协议价，必选选择车型，以便匹配车型件');
        return;
      }
      this.searchAllGoodsTimer = setTimeout(() => {
        this.searchAllGoodsLoading = true;
        const carModelInfo = this.carModelList[0];
        let params = {};
        if (typeof msg === 'object') {
          params = keyword;
        } else {
          params = { keyword };
        }
        params = Object.assign({}, params, {
          logoId: carModelInfo.logoId,
          brandId: carModelInfo.brandId,
          seriesId: carModelInfo.seriesId,
          modelNumberId: carModelInfo.modelNumberId,
          modelYearId: carModelInfo.modelYearId,
        });
        searchGoodsByKeyword(params)
          .then((res) => {
            this.goodsItems = res || [];
          })
          .finally(() => {
            this.searchAllGoodsLoading = false;
          });
      }, 800);
    },
    /**
     * 选择车辆型号
     */
    handleSelectCarModel(list) {
      console.log(list);
      // 校验下，可能没有型号、年款
      if (list?.length > 0) {
        const item = list[0];
        if (
          item.logoId &&
          item.brandId &&
          item.seriesId &&
          item.modelNumberId &&
          item.modelYearId
        ) {
          // 车型正常
        } else {
          // 弹出提示
          this.$message({
            type: 'warning',
            dangerouslyUseHTMLString: true,
            message: '车型数据缺失，必须要有年款',
          });
          this.carModelList.length = 0;
          this.carModelInfoStr = '';
          return;
        }
      } else {
        // 清空
        this.$message({
          type: 'warning',
          dangerouslyUseHTMLString: true,
          message: '车型数据缺失，必须要有年款',
        });
        this.carModelList.length = 0;
        this.carModelInfoStr = '';
        return;
      }
      this.carModelList = list;
      this.buildCarModelInfoBySelectedCarModel();
    },
    buildCarModelInfoBySelectedCarModel() {
      const carModelInfo = this.carModelList[0];
      // logo 车系 车型 年款
      this.carModelInfoStr =
        carModelInfo?.logoName +
        ' ' +
        carModelInfo?.seriesName +
        ' ' +
        carModelInfo?.modelNumberName +
        ' ' +
        carModelInfo?.modelYearName;
    },
    serviceItemChangeEvent(serviceItem) {
      if (serviceItem) {
        this.selectedServiceItem = serviceItem;
        this.$set(this.editForm, 'serviceAgreementPrice', '');
      } else {
        this.serviceItemClearEvent();
      }
    },
    serviceItemClearEvent() {
      this.selectedServiceItem = {};
      this.$set(this.editForm, 'serviceAgreementPrice', '');
      this.$refs.form.clearValidate();
    },
    goodsItemChangeEvent(goodsItem) {
      const _this = this;
      if (goodsItem) {
        _this.selectedGoods = goodsItem;
        _this.$set(this.editForm, 'goodsAgreementPrice', '');
        _this.rules.goodsAgreementPrice = [
          {
            required: true,
            trigger: 'blur',
            message: '请输入商品协议价！',
          },
          {
            type: 'number',
            trigger: 'blur',
            message: '商品协议价必须是数字！',
          },
        ];
      } else {
        _this.goodsItemClearEvent();
      }
    },
    goodsItemClearEvent() {
      this.selectedGoods = {};
      this.$set(this.editForm, 'goodsAgreementPrice', '');
      delete this.rules.goodsAgreementPrice;
      this.$refs.form.clearValidate();
    },
    /**
     * 获取数据
     */
    getData() {
      const _this = this;
      const data = {
        agreementId: this.agreementId,
        usualQuoteId: this.usualQuoteId,
        agreementServicePriceId: this.agreementServicePriceId,
        agreementGoodsPriceId: this.agreementGoodsPriceId,
      };
      clientAgreementPriceInfo(data).then((res) => {
        setTimeout(() => {
          _this.$set(this.editForm, 'serviceItemId', res.serviceItemEntity);
        }, 100);
        _this.serviceItems = [res.serviceItemEntity];
        _this.selectedServiceItem = res.serviceItemEntity;
        if (res.goodsEntity) {
          setTimeout(() => {
            _this.$set(this.editForm, 'goodsId', res.goodsEntity);
          }, 100);
          _this.goodsItems = [res.goodsEntity];
          _this.selectedGoods = res.goodsEntity;
          _this.rules.goodsAgreementPrice = [
            {
              required: true,
              trigger: 'blur',
              message: '请输入商品协议价！',
            },
            {
              type: 'number',
              trigger: 'blur',
              message: '商品协议价必须是数字！',
            },
          ];
        }
        if (res.agreeServicePriceEntity) {
          _this.$set(
            this.editForm,
            'serviceAgreementPrice',
            res.agreeServicePriceEntity?.agreePrice
          );
        }
        if (res.agreeGoodsPriceEntity) {
          _this.$set(
            this.editForm,
            'goodsAgreementPrice',
            res.agreeGoodsPriceEntity?.agreePrice
          );
        }
        _this.buildCarModelInfoByClientAgreementPriceInfo(res);
        _this.buildEditFormByClientAgreementPriceInfo(res);
        _this.otherData = res;
      });
    },
    buildCarModelInfoByClientAgreementPriceInfo(res) {
      this.carModelList = [
        {
          logoId: res?.cloudCarLogoEntity?.id || '',
          logoName: res?.cloudCarLogoEntity?.name || '',
          brandId: res?.cloudCarBrandEntity?.id || '',
          brandName: res?.cloudCarBrandEntity?.name || '',
          seriesId: res?.cloudCarSeriesEntity?.id || '',
          seriesName: res?.cloudCarSeriesEntity?.name || '',
          modelNumberId: res?.cloudCarModelNumberEntity?.id || '',
          modelNumberName: res?.cloudCarModelNumberEntity?.name || '',
          modelYearId: res?.cloudCarModelYearEntity?.id || '',
          modelYearName: res?.cloudCarModelYearEntity?.name || '',
        },
      ];
      // logo 车系 车型 年款
      this.carModelInfoStr =
        res?.cloudCarLogoEntity?.name +
        ' ' +
        res?.cloudCarSeriesEntity?.name +
        ' ' +
        res?.cloudCarModelNumberEntity?.name +
        ' ' +
        res?.cloudCarModelYearEntity?.name;
    },
    buildEditFormByClientAgreementPriceInfo(res) {
      this.$set(
        this.editForm,
        'serviceItemId',
        res.serviceItemEntity.serviceItemId
      );
      if (res.goodsEntity) {
        this.$set(this.editForm, 'goodsId', res.goodsEntity.goodsId);
        this.$set(
          this.editForm,
          'goodsAgreementPrice',
          res.agreeGoodsPriceEntity.agreePrice
        );
      }
    },
    /**
     * 保存
     */
    save() {
      this.$refs.form
        .validate()
        .then((result, object) => {
          // 车型件
          var api = this.usualQuoteId
            ? clientAgreementPriceUpdate
            : clientAgreementPriceSave;
          const carModelInfo = this.carModelList[0];
          const data = {
            agreementId: this.agreementId,
            usualQuoteId: this.usualQuoteId,
            agreementServicePriceId: this.agreementServicePriceId,
            agreementGoodsPriceId: this.agreementGoodsPriceId,
            logoId: carModelInfo.logoId,
            brandId: carModelInfo.brandId,
            seriesId: carModelInfo.seriesId,
            modelNumberId: carModelInfo.modelNumberId,
            modelYearId: carModelInfo.modelYearId,
            serviceItemId: this.editForm.serviceItemId.serviceItemId,
            serviceAgreementPrice: this.editForm.serviceAgreementPrice,
            goodsId: this.editForm.goodsId?.goodsId || null,
            goodsAgreementPrice: this.editForm?.goodsAgreementPrice || null,
          };
          api(data).then((res) => {
            this.$message.success('保存成功！');
            this.$emit('save');
            this.handleClose();
          });
        })
        .catch((error) => {
          console.log(error);
          var msg =
            '<p>' +
            Object.values(error)
              .map((el) => el.map((v) => v.message))
              .join('<br />') +
            '</p>';
          this.$message({
            type: 'warning',
            dangerouslyUseHTMLString: true,
            message: msg,
          });
        });
    },

    /**
     * 关闭抽屉
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item {
  .el-date-editor,
  .el-select {
    width: 100%;
  }
  .el-form-item__label {
    width: 100px;
  }
  .el-form-item__content {
    margin-left: 100px;
  }
}
.selected-car-model-list {
  margin: 6px 0 12px 0;
  .el-tag {
    margin-top: 6px;
    margin-left: 6px;
  }
}
</style>
