<template>
  <el-dialog
    title="编辑车系"
    :visible="visibility"
    width="50%"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <el-form
      ref="form"
      size="small"
      :model="editForm"
      :rules="rules"
      label-width="80px"
      style="margin-top: 18px"
    >
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="所属品牌" prop="logoName">
            {{ editForm.logoName }}
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="车系" prop="seriesName">
            <el-input v-model="editForm.seriesName" placeholder="请输入车系">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <template slot="footer">
      <el-button size="small" type="primary" @click="save"> 确 定 </el-button>
      <el-button size="small" @click="handleClose"> 取 消 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex';
import {
  getCarSeriesDetail,
  updateCarSeries,
} from '@/api/customer/carModelManage';

export default {
  name: 'editCarSeriesDialog',
  components: {},
  props: {
    visibility: Boolean,
    detail: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      // 表单信息
      editForm: {},
      // 表单规则
      rules: {
        seriesName: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入车系！',
          },
        ],
      },
    };
  },
  created() {
    if (Object.values(this.detail).length) {
      this.editForm = this.$lodash.clone(this.detail);
      this.$set(this.editForm, 'seriesId', this.editForm.id);
      this.$set(this.editForm, 'seriesName', this.editForm.name);
    }
  },
  methods: {
    /**
     * 保存
     */
    save() {
      this.$refs.form
        .validate()
        .then((result, object) => {
          updateCarSeries(this.editForm).then((res) => {
            this.$message.success('保存成功！');
            this.$emit('save');
            this.handleClose();
          });
        })
        .catch((error) => {
          console.log(error);
          var msg =
            '<p>' +
            Object.values(error)
              .map((el) => el.map((v) => v.message))
              .join('<br />') +
            '</p>';
          this.$message({
            type: 'warning',
            dangerouslyUseHTMLString: true,
            message: msg,
          });
        });
    },

    /**
     * 关闭抽屉
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
  },
};
</script>

<style lang="scss" scoped></style>
