<template>
  <el-dialog
    :title="title"
    :visible="visibility"
    width="800px"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <el-form
      ref="form"
      size="small"
      :model="editForm"
      :rules="rules"
      label-width="90px"
      style="margin-top: 18px"
    >
      <el-row :gutter="10">
        <el-col :span="8" :offset="0">
          <el-form-item label="适用车型" prop="carModel">
            <el-radio-group
              v-model="editForm.isGeneral"
              @change="handleIsGeneralChange"
            >
              <el-radio :label="1">通用</el-radio>
              <el-radio :label="0">车型件</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <div v-show="editForm.isGeneral === 0">
            <el-select
              v-model="selectedCarModel"
              filterable
              remote
              placeholder="搜索车型"
              :remote-method="searchCarModelList"
              :loading="searchCarModelListLoading"
              value-key="id"
              size="small"
              class="w100"
            >
              <el-option
                v-for="(item, index) in carModelData"
                :key="index"
                :label="item.name"
                :value="item"
                @click.native="handleAddCarModel(item)"
              >
              </el-option>
            </el-select>
            <div style="margin-bottom: 10px" class="flex">
              <div
                style="
                  flex-shirk: 0;
                  width: 42px;
                  margin-top: 16px;
                  white-space: nowrap;
                "
              >
                已选：
              </div>
              <div class="selected-car-model-list">
                <el-tag
                  v-for="(tag, i) in carModelList"
                  :key="tag.seriesId"
                  type="info"
                  size="mini"
                  closable
                  @close="handleDeleteCarModel(i)"
                >
                  {{ tag.name }}
                </el-tag>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="4" :offset="0">
          <div
            v-show="editForm.isGeneral === 0"
            style="height: 32px"
            class="blue pointer flex-y-center"
            @click="selectCarModelDialogVisibility = true"
          >
            选择车型
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12" :offset="0">
          <el-form-item label="品牌" prop="brandName">
            <el-input
              v-model="editForm.brandName"
              maxlength="50"
              placeholder="请输入品牌"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="品名" prop="productId">
            <el-select
              v-model="editForm.productId"
              filterable
              clearable
              remote
              placeholder="请输入品名查询"
              :remote-method="searchProductList"
              :loading="searchProductListLoading"
              @change="handleProductIdChange"
              @clear="handleProductClear"
              @blur="handleProductBlur"
            >
              <el-option
                v-for="item in productList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="12" :offset="0">
          <el-form-item label="商品分类" prop="class">
            <select-goods-class-cascader
              :value="[editForm.firstCategoryId, editForm.secondCategoryId]"
              @select="handleSelectGoodsClass"
              @change="handleBlur"
              @blur="handleBlur"
            ></select-goods-class-cascader>
          </el-form-item>
        </el-col> -->
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12" :offset="0">
          <el-form-item label="规格" prop="goodsFormat">
            <el-input
              v-model="editForm.goodsFormat"
              maxlength="200"
              placeholder="请输入规格"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="特征" prop="feature">
            <el-input
              v-model="editForm.feature"
              maxlength="200"
              placeholder="请输入特征"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12" :offset="0">
          <el-form-item label="品质" prop="manufactoryType">
            <el-select
              v-model="editForm.manufactoryType"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in manufactoryTypeDict"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="原厂编码" prop="goodsCode">
            <el-input
              v-model="editForm.goodsCode"
              maxlength="200"
              placeholder="请输入原厂编码"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12" :offset="0">
          <el-form-item label="单位" prop="unit">
            <el-input
              v-model="editForm.unit"
              maxlength="200"
              placeholder="请输入单位"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="商品备注" prop="remark">
            <el-input
              v-model="editForm.remark"
              placeholder="请输入商品备注"
              maxlength="100"
              show-word-limit
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12" :offset="0">
          <el-form-item label="商品分类">
            {{ className }}
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="索赔属性"> </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24" :offset="0">
          <el-form-item label="库存预警值" prop="alarmNum">
            <el-input-number
              v-model="editForm.alarmNum"
              maxlength="200"
              :min="0"
              placeholder="请输入库存预警值"
              clearable
            >
            </el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12" :offset="0">
          <el-form-item label="商品标准名">
            <span>{{ standardName }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="商品名称">{{ goodsFullName }}</el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12" :offset="0">
          <el-form-item label="商品图片">
            <div>
              <p style="font-size: 12px; color: #84878c">（最多上传3张）</p>
              <upload-file-list
                v-model="editForm.images"
                :max="3"
              ></upload-file-list>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="启用状态">
            <el-switch v-model="editForm.isActive"> </el-switch>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12" :offset="0">
          <div
            v-if="editForm.oldName"
            style="margin-left: 22px; height: 32px; line-height: 32px"
          >
            原系统商品名称: {{ editForm.oldName }}
          </div>
        </el-col>
      </el-row>
    </el-form>
    <select-car-model-dialog
      v-if="selectCarModelDialogVisibility"
      :visibility.sync="selectCarModelDialogVisibility"
      multiple
      @select="handleSelectCarModel"
      :value="carModelList"
    ></select-car-model-dialog>

    <template slot="footer">
      <el-button size="small" type="primary" @click="save"> 确 定 </el-button>
      <el-button size="small" @click="handleClose"> 取 消 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex';

// 组件
import uploadFileList from '@/components/uploadFileList.vue';
import selectGoodsClassCascader from '@/components/businessComponent/selectGoodsClassCascader.vue';
import selectCarModelDialog from '@/components/businessComponent/selectCarModelDialog.vue';

// 接口
import {
  getProductNameList,
  addGoods,
  updateGoods,
  getGoodsDetail,
} from '@/api/stock/goodsSetting';
import { getCarModelList } from '@/api/customer/car';

// 字典数据
import { manufactoryType } from '@/service/dict/dictData';
export default {
  name: 'editGoodsDialog',
  components: {
    // selectGoodsClassCascader,
    selectCarModelDialog,
    uploadFileList,
  },
  props: {
    visibility: Boolean,
    id: [Number, String],
  },
  data() {
    return {
      // 表单信息
      editForm: {
        isGeneral: 0,
        isActive: true,
      },
      carModelList: [],

      // 表单规则
      rules: {
        isGeneral: [
          {
            required: true,
            trigger: 'blur',
            message: '请选择适用车型！',
          },
        ],
        manufactoryType: [
          {
            required: true,
            trigger: 'blur',
            message: '请选择品质！',
          },
        ],
        productId: [
          {
            required: true,
            trigger: 'blur',
            message: '请选择商品品名！',
          },
        ],
        carModel: [
          {
            required: true,
            // trigger: 'blur',
            validator: this.validateCarModel,
          },
        ],
      },

      manufactoryTypeDict: manufactoryType,
      productList: [],
      searchProductListLoading: false,

      selectCarModelDialogVisibility: false,

      carModelData: [],
      searchCarModelListLoading: false,

      selectedCarModel: '',
    };
  },
  computed: {
    // ...mapState({
    //   userId: (state) => state.user.userInfo.userId,
    // }),
    title() {
      return this.id ? '编辑商品' : '新增商品';
    },
    // 商品分类
    className() {
      return `${this.editForm.firstCategoryName || ''}${
        this.editForm.secondCategoryName ? '/' : ''
      }${this.editForm.secondCategoryName || ''}`;
    },
    // 商品标准名
    standardName() {
      return `${this.editForm.brandName || ''}${
        this.editForm.productName || ''
      }${this.editForm.goodsFormat || ''}${this.editForm.feature || ''}`;
    },
    matchCarType() {
      var carModel = '';
      if (this.editForm.isGeneral) {
        carModel = '通用';
      } else {
        // 处理车型
        carModel = this.carModelList
          .map((item) => {
            var name = '';
            if (item.logoName) {
              name += item.logoName;
            }
            if (item.seriesName) {
              name += item.seriesName;
            }
            if (item.modelNumberName) {
              name += item.modelNumberName;
            }
            if (item.modelYearName) {
              name = name + ' ' + item.modelYearName + '款';
            }
            return name;
          })
          .join('/');
      }
      return carModel;
    },
    // 商品名称
    goodsFullName() {
      return `${this.matchCarType}-${this.standardName}-${
        this.manufactoryTypeDict.find(
          (el) => el.value === this.editForm.manufactoryType
        )?.label || ''
      }`;
    },
  },
  created() {
    if (this.id) {
      this.getData();
    } else {
      this.searchProductList();
    }
    this.searchCarModelList();
  },
  methods: {
    /**
     * 校验适用车型
     */
    validateCarModel(rule, value, callback) {
      if (!this.editForm.isGeneral && !this.carModelList.length) {
        callback(new Error('请选择车型！'));
      } else {
        callback();
      }
    },
    /**
     * 搜索车型列表
     */
    searchCarModelList(keyWord) {
      this.searchCarModelListLoading = true;
      getCarModelList(
        { msg: keyWord || '', current: 1, size: 50 },
        { noShowWaiting: true }
      )
        .then((res) => {
          this.carModelData =
            res.records.map((el) => {
              el.id =
                '' +
                el.logoId +
                el.brandId +
                el.seriesId +
                el.modelNumberId +
                el.modelYearId;
              el.name = `${el.logoName}${el.seriesName ? el.seriesName : ''}${
                el.modelNumberName ? el.modelNumberName : ''
              }${el.modelYearName ? ' ' + el.modelYearName : ''}`;
              return el;
            }) || [];
        })
        .finally(() => {
          this.searchCarModelListLoading = false;
        });
    },
    /**
     * 搜索品名列表
     */
    searchProductList(keyWord) {
      this.searchProductListLoading = true;
      getProductNameList(
        { name: keyWord || '', current: 1, size: 50 },
        { noShowWaiting: true }
      )
        .then((res) => {
          if (keyWord) {
            this.productList = res.records || [];
          } else {
            this.productList = this.$lodash.unionBy(
              this.productList.concat(res.records || []),
              'id'
            );
          }
        })
        .finally(() => {
          this.searchProductListLoading = false;
        });
    },
    /**
     * 商品品名变更
     */
    handleProductIdChange() {
      // debugger;
      if (
        typeof this.editForm.productId === 'number' ||
        (typeof this.editForm.productId === 'string' &&
          /^\d+$/.test(this.editForm.productId))
      ) {
        var item =
          this.productList.find((el) => el.id === this.editForm.productId) ||
          {};
        this.$set(this.editForm, 'productName', item.name || '');
        this.$set(this.editForm, 'firstCategoryId', item.firstCategoryId || '');
        this.$set(
          this.editForm,
          'firstCategoryName',
          item.firstCategoryName || ''
        );
        this.$set(
          this.editForm,
          'secondCategoryId',
          item.secondCategoryId || ''
        );
        this.$set(
          this.editForm,
          'secondCategoryName',
          item.secondCategoryName || ''
        );

        // this.editForm.productName = item.name || '';
        // this.editForm.firstCategoryId = item.firstCategoryId || '';
        // this.editForm.firstCategoryName = item.firstCategoryName || '';
        // this.editForm.secondCategoryId = item.secondCategoryId || '';
        // this.editForm.secondCategoryName = item.secondCategoryName || '';
        this.handleProductBlur();
      } else {
        this.handleProductClear();
      }
    },
    handleProductClear() {
      this.$set(this.editForm, 'productName', '');
      this.$set(this.editForm, 'firstCategoryId', '');
      this.$set(this.editForm, 'firstCategoryName', '');
      this.$set(this.editForm, 'secondCategoryId', '');
      this.$set(this.editForm, 'secondCategoryName', '');
    },
    handleProductBlur() {
      this.$refs.form.validateField('productId');
    },
    /**
     * 适用车型变更
     */
    handleIsGeneralChange(val) {
      if (val) {
        // 清空已选车型
        // this.editForm.matchCarType = '';
      }
    },
    /**
     * 获取数据
     */
    getData() {
      getGoodsDetail({ id: this.id }).then((res) => {
        this.editForm = res || {};
        this.$set(this.editForm, 'images', res.images?.join() || '');
        this.$set(this.editForm, 'isGeneral', res.isGeneral || 0);
        this.carModelList = res.carModelList || [];
        this.addFullName();

        if (res.productId) {
          this.productList.unshift({
            id: res.productId,
            name: res.productName,
            firstCategoryId: res.firstCategoryId,
            firstCategoryName: res.firstCategoryName,
            secondCategoryId: res.secondCategoryId,
            secondCategoryName: res.secondCategoryName,
          });
        } else {
          this.searchProductList();
        }
      });
    },
    /**
     * 选择车辆型号
     */
    handleSelectCarModel(list) {
      console.log(list);
      this.carModelList = list;
      this.addFullName();
      this.$refs.form.validateField('carModel');
    },
    /**
     * 添加单个适用车型
     */
    handleAddCarModel(model) {
      // 是否已存在车系
      const exist = this.carModelList.findIndex(
        (el) => el.seriesId === model.seriesId
      );
      if (exist >= 0) {
        this.carModelList.splice(exist, 1, model);
      } else {
        this.carModelList.push(model);
      }
      this.selectedCarModel = '';
    },
    /**
     * 删除单个车型
     */
    handleDeleteCarModel(i) {
      this.carModelList.splice(i, 1);
    },

    /**
     * 处理车型全称
     */
    addFullName() {
      this.carModelList.forEach((el) => {
        el.name = `${el.logoName}${el.seriesName}${
          el.modelNumberName ? el.modelNumberName : ''
        }${el.modelYearName ? ' ' + el.modelYearName : ''}`;
      });
    },
    /**
     * 保存
     */
    save() {
      this.$refs.form
        .validate()
        .then((result, object) => {
          // 车型件
          var api = this.id ? updateGoods : addGoods;
          if (this.editForm.images) {
            var images =
              this.editForm.images
                ?.split(',')
                ?.map((item) => item.split('/').at(-1)) || [];
          }

          var body = Object.assign({}, this.editForm, {
            images,
            name: this.goodsFullName,
            standardName: this.standardName,
            matchCarType: this.matchCarType,
            carModelList: this.carModelList,
          });
          api(body).then((res) => {
            this.$message.success('保存成功！');
            this.$emit('save');
            this.handleClose();
          });
        })
        .catch((error) => {
          console.log(error);
          var msg =
            '<p>' +
            Object.values(error)
              .map((el) => el.map((v) => v.message))
              .join('<br />') +
            '</p>';
          this.$message({
            type: 'warning',
            dangerouslyUseHTMLString: true,
            message: msg,
          });
        });
    },

    /**
     * 关闭抽屉
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item {
  .el-date-editor,
  .el-select {
    width: 100%;
  }
}
.selected-car-model-list {
  margin: 6px 0 12px 0;
  .el-tag {
    margin-top: 6px;
    margin-left: 6px;
  }
}
</style>
