var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "温馨提示",
            visible: _vm.invoicingDialog,
            width: "50%",
            "close-on-click-modal": false,
            "before-close": _vm.handleClose,
          },
        },
        [
          _vm.step === 1
            ? _c(
                "div",
                [
                  _vm.inconsistentQuantityList.length
                    ? _c("p", { staticClass: "tip-word" }, [
                        _vm._v(
                          " 当前接车单存在商品报价数量与出库数量不一致情况（标红商品），请确认是否继续提交结账 "
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    [
                      _vm.inconsistentQuantityList.length
                        ? _c(
                            "el-table",
                            {
                              key: 1,
                              staticClass: "service-table",
                              staticStyle: { width: "100%" },
                              attrs: {
                                data: _vm.inconsistentQuantityList,
                                border: "",
                                "default-expand-all": "",
                                "row-key": "id",
                                "tree-props": {
                                  children: "billGoods",
                                  hasChildren: "hasChildren",
                                },
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "title",
                                  width: "80",
                                  label: "序号",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "name",
                                  label: "报价项目",
                                  width: "180",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ row }) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              class: {
                                                red: row.type === "goods",
                                              },
                                            },
                                            [_vm._v(_vm._s(row.name))]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1978783380
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "code",
                                  label: "报价项目编码",
                                  width: "180",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ row }) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              class: {
                                                red: row.type === "goods",
                                              },
                                            },
                                            [_vm._v(_vm._s(row.code))]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1886938910
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "num",
                                  label: "报价数量",
                                  "min-width": "120",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ row }) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              class: {
                                                red: row.type === "goods",
                                              },
                                            },
                                            [_vm._v(_vm._s(row.num))]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2380797125
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "num",
                                  label: "出库数量",
                                  "min-width": "120",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ row }) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              class: {
                                                red: row.type === "goods",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(row.pickedNum) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  94080309
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.inconsistentPricesList.length
                    ? _c("p", { staticClass: "tip-word" }, [
                        _vm._v(
                          " 当前接车单存在商品报价小于成本价（标红商品），请确认是否继续提交结账 "
                        ),
                      ])
                    : _vm._e(),
                  _vm.inconsistentPricesList.length
                    ? _c(
                        "el-table",
                        {
                          key: 2,
                          staticClass: "service-table",
                          staticStyle: {
                            width: "100%",
                            "margin-bottom": "40px",
                          },
                          attrs: {
                            data: _vm.inconsistentPricesList,
                            "default-expand-all": "",
                            "row-key": "id",
                            "tree-props": {
                              children: "billGoods",
                              hasChildren: "hasChildren",
                            },
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "title",
                              width: "80",
                              label: "序号",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "name",
                              label: "报价项目",
                              width: "180",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ row }) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          class: { red: row.type === "goods" },
                                        },
                                        [_vm._v(_vm._s(row.name))]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1978783380
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "itemCode",
                              label: "报价项目编码",
                              width: "180",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ row }) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          class: { red: row.type === "goods" },
                                        },
                                        [_vm._v(_vm._s(row.code))]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1886938910
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "num",
                              label: "报价价格",
                              "min-width": "120",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ row }) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          class: { red: row.type === "goods" },
                                        },
                                        [_vm._v(_vm._s(row.total))]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3942167729
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "num",
                              label: "成本价格",
                              "min-width": "120",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ row }) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          class: { red: row.type === "goods" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(row.totalCostPrice || "") +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              4130452247
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _c(
                "div",
                [
                  _c("p", { staticStyle: { "margin-bottom": "20px" } }, [
                    _vm._v("是否确认提交结账?"),
                  ]),
                  _c(
                    "el-form",
                    { attrs: { size: "small" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "提车状态", required: "" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.takeState,
                                callback: function ($$v) {
                                  _vm.takeState = $$v
                                },
                                expression: "takeState",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("已提车"),
                              ]),
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("未提车"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
          _c(
            "template",
            { slot: "footer" },
            [
              _vm.step === 1
                ? [
                    _vm.inconsistentQuantityList.length !== 0
                      ? _c("span", { staticClass: "red" }, [
                          _vm._v(
                            " 商品报价数量与出库数量不一致，不允许提交结账 "
                          ),
                        ])
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "small",
                          type: "primary",
                          disabled: _vm.inconsistentQuantityList.length !== 0,
                        },
                        on: { click: _vm.nextStep },
                      },
                      [_vm._v("提交结账")]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "auth",
                            rawName: "v-auth",
                            value: 4,
                            expression: "4",
                          },
                        ],
                        attrs: { size: "small" },
                        on: { click: _vm.editCarBill },
                      },
                      [_vm._v("编辑车单")]
                    ),
                  ]
                : [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small" },
                        on: { click: _vm.handleClose },
                      },
                      [_vm._v("取 消")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: { click: _vm.confirm },
                      },
                      [_vm._v("确 定")]
                    ),
                  ],
            ],
            2
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }