<template>
  <el-dialog
    :title="title"
    top="25vh"
    width="45%"
    :append-to-body="true"
    :visible="showServiceManagementDialog"
    :before-close="handleClose"
    :close-on-click-modal="false"
    class="dialogSize"
  >
    <div style="overflow: auto">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="100px"
        size="small"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="服务名称" prop="name">
              <el-input
                maxlength="50"
                v-model="form.name"
                clearable
                placeholder="请输入服务名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="服务分类" prop="class">
              <select-service-category
                :value="[form.firstCategoryId, form.secondCategoryId]"
                @select="handleSelectServiceCategory"
                @change="handleBlur"
                @blur="handleBlur"
              ></select-service-category>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="服务时效" prop="serviceTime">
              <el-input
                v-model="form.serviceTime"
                placeholder="请输入数字,支持2位小数点"
                @input="validateInput('serviceTime')"
                clearable
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="单价" prop="unitPrice">
              <div>
                <el-input
                  v-model="form.unitPrice"
                  placeholder="请输入数字"
                  @input="validateInput('unitPrice')"
                  clearable
                >
                </el-input>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="人力成本" prop="humanCost">
              <el-input
                v-model="form.humanCost"
                placeholder="请输入数字"
                @input="validateInput('humanCost')"
                clearable
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="标准价" prop="price">
              <div v-if="!form.serviceTime">
                <span v-if="form.price && form.serviceTime != ''">{{
                  form.price
                }}</span>
                <span v-else style="color: #c0c4cc">服务时效*单价</span>
              </div>
              <div v-else>
                <span v-if="form.serviceTime">
                  <span v-if="!form.unitPrice" style="color: #c0c4cc"
                    >服务时效*单价</span
                  >
                  <span v-else>{{
                    parseFloat((form.unitPrice * form.serviceTime).toFixed(2))
                  }}</span>
                </span>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="施工难度" prop="constructionDifficulty">
              <el-select
                v-model="form.constructionDifficulty"
                placeholder="请选择施工难度"
                style="width: 100%"
              >
                <el-option
                  v-for="item in constructionDifficultyType"
                  :key="item.value"
                  :value="item.value"
                  :label="item.desc"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="施工工时费" prop="constructionTimePrice">
              <el-input
                v-model="form.constructionTimePrice"
                placeholder="请输入施工工时费，数字类型,支持2位小数点"
                @input="validateInput('constructionTimePrice')"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12" :offset="0">
            <el-form-item label="关联品名" prop="productIds">
              <el-select
                v-model="form.productIds"
                multiple
                filterable
                remote
                placeholder="请搜索，支持多选"
                :remote-method="remoteMethod"
                :loading="loading"
              >
                <el-option
                  v-for="item in productNamesList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="启用状态">
              <el-switch v-model="form.isActive"> </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="21">
            <el-form-item label="工种" prop="constructionType">
              <el-select
                v-model="form.constructionType"
                placeholder="请选择工种"
                style="width: 100%"
              >
                <el-option
                  v-for="item in constructionTypeList"
                  :key="item.value"
                  :value="item.value"
                  :label="item.desc"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="21">
            <el-form-item label="备注" prop="description">
              <el-input
                v-model="form.description"
                type="textarea"
                maxlength="50"
                show-word-limit
                placeholder="请输入, 最多50字"
                clearable
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <template slot="footer">
      <div>
        <el-button size="small" @click="handleClose">取 消</el-button>
        <el-button size="small" @click="verifyBeforeSave" type="primary"
          >确定</el-button
        >
      </div>
    </template>
  </el-dialog>
</template>

<script>
// 接口
import {
  addServiceItem,
  getCategory,
  getOneServiceItem,
  getProductName,
  editServiceItem,
  getServiceConstructionItem,
} from '@/api/systemManagement/carOrderSettings/serviceManagement';

import { getDictValueByLabel } from '@/service/dict/dictTool';
import { mapState, mapGetters, mapMutations } from 'vuex';
import { levelIconsOther } from '@/const/client';
//组件
import selectServiceCategory from './selectServiceCategory';
export default {
  // props: ['showServiceManagementDialog', 'id'],
  props: {
    showServiceManagementDialog: Boolean,
    id: [Number, String],
    detail: {
      type: Object,
      default: () => ({}),
    },
  },
  components: { selectServiceCategory },
  data() {
    return {
      title: '新增服务',
      // 表单数据
      form: {
        isActive: true,
        constructionType: 3,
      },
      productIdsList: [],
      //远程搜索加载
      loading: false,
      //品名数据
      productNamesList: [],
      // 施工难度下拉列表
      constructionDifficultyType: [],
      constructionTypeList: [],
      product: [],
      // 表单规则
      rules: {
        name: [{ required: true, message: '请输入服务名称', trigger: 'blur' }],
        class: [
          {
            required: true,
            // trigger: 'blur',
            validator: this.validateClass,
          },
        ],
        serviceTime: [
          { required: true, message: '请输入服务时效', trigger: 'blur' },
        ],
        unitPrice: [{ required: true, message: '请输入单价', trigger: 'blur' }],
        constructionDifficulty: [
          { required: true, message: '请选择施工难度', trigger: 'blur' },
        ],
        constructionTimePrice: [
          {
            required: true,
            message: '请输入施工工时费，数字类型,支持2位小数点',
            trigger: 'blur',
          },
        ],
        constructionType: [
          { required: true, message: '请选择工种', trigger: 'blur' },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  created() {
    this.init();
    if (this.id) {
      console.log(this.id, 'this.id');
      this.title = '编辑服务';
      if (Object.values(this.detail).length) {
        this.form = this.$lodash.clone(this.detail);
      }
      getOneServiceItem(this.id).then((res) => {
        this.productIdsList = res;
        if (this.productIdsList.productNames) {
          let nameList = this.productIdsList.productNames.split(',');
          let idList = this.productIdsList.productIds;
          for (let i = 0; i < nameList.length; i++) {
            this.form.productIds.push(idList[i]);
            this.productNamesList.push({
              id: idList[i],
              name: nameList[i],
            });
          }
        }
        console.log(this.form.productIds, 'this.form.productIds');
      });
    }
  },
  methods: {
    init() {
      getServiceConstructionItem({}).then((res) => {
        this.constructionDifficultyType = res.constructionItems;
        this.constructionTypeList = res.constructionTypeEnumItems;
      });
    },
    /**
     * 校验商品分类
     */
    validateClass(rule, value, callback) {
      if (!this.form.firstCategoryId) {
        callback(new Error('请选择一级分类！'));
      } else if (!this.form.secondCategoryId) {
        callback(new Error('请选择二级分类！'));
      } else {
        callback();
      }
    },
    /**
     * 选择服务分类
     */
    handleSelectServiceCategory(val) {
      this.form.firstCategoryId = val[0] || '';
      this.form.secondCategoryId = val[1] || '';
    },
    /**
     * 选择商品分类失焦事件
     */
    handleBlur() {
      this.$refs.form.validateField('class');
    },
    /**
     * 多选关联品名
     */
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        let data = { name: query };
        getProductName(data).then((res) => {
          this.loading = false;
          this.productNamesList = res.records;
        });
      } else {
        this.productNamesList = [];
      }
    },
    /**
     * 限制输入数字
     */
    validateInput(val) {
      console.log('this.form', this.form);
      let num = 2;
      this.form[val] = this.form[val].replace(/[^0-9.]/g, '');
      const decimalParts = this.form[val].split('.');
      if (val == 'serviceTime') {
        if (decimalParts.length > 2) {
          Number((this.form[val] = decimalParts[0] + '.' + decimalParts[1]));
        } else if (decimalParts[1] && decimalParts[1].length > num) {
          this.form[val] = Number(
            decimalParts[0] + '.' + decimalParts[1].slice(0, num)
          );
        }
      }
    },
    /**
     * 关闭/取消
     */
    handleClose() {
      this.$emit('update:showServiceManagementDialog', false);
    },
    /**
     * 保存前校验
     */
    verifyBeforeSave() {
      console.log('this.form', this.form);
      this.$refs.form.validate((result, object) => {
        if (result) {
          this.doSave();
        } else {
          this.$message.warning('请填写必填项');
          console.log(result, object);
        }
      });
    },
    /**
     * 保存
     */
    async doSave() {
      this.form.price = parseFloat(
        (this.form.unitPrice * this.form.serviceTime).toFixed(2)
      );
      // 编辑
      if (this.id) {
        this.form.serviceTime = Number(this.form.serviceTime);
        console.log('this.form', this.form);
        if (this.form.isActive == undefined) {
          this.form.isActive = false;
        }
        editServiceItem(this.form).then((res) => {
          this.$message.success('保存成功');
          this.$emit('save');
          this.handleClose();
          // if (res.code === 200) {
          //   this.$message.success('保存成功');
          //   this.$emit('save');
          //   this.handleClose();
          // }
        });
      }
      // 新增
      else {
        this.form.serviceTime = Number(this.form.serviceTime);
        addServiceItem(this.form).then((res) => {
          this.$message.success('保存成功');
          // this.$emit('save', res.data);
          this.handleClose();
          // if (res.code === 200) {

          // }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
::v-deep input[type='number'] {
  -moz-appearance: textfield !important;
}

::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

.el-form-item {
  .el-date-editor,
  .el-select {
    width: 100%;
  }
}
.dialogSize {
  height: 800px;
}

.rows-tip {
  color: #999;
  line-height: 18px;
}
.img-note {
  color: #999;
  font-size: 14px;
}
.car-imgs {
  display: flex;
}
.block-title {
  margin: 30px 0;
  padding-bottom: 12px;
  border-bottom: 1px solid #e8e8e8;
  color: #333;
  font-size: 16px;
  font-weight: 600;
}
.client-list {
  .client {
    display: flex;
    align-items: center;
    > .icon_delete {
      font-size: 18px;
      color: #ff9200;
      margin-right: 15px;
    }
    .client-info {
      width: 95%;
      padding: 12px 18px;
      background: #f5f7fa;
      border-radius: 4px;
      > div + div {
        margin-top: 10px;
      }
      i {
        margin: 0 5px;
      }
      .icon_person {
        color: $blue;
      }
      .icon_sex {
        color: $blue;
      }
      .icon_grade {
        color: $blue;
      }
      .grow-icon_girl {
        color: #f48080;
      }
      .icon_wechat {
        color: #999;
      }
      .icon_wechat_active {
        color: #00c800;
      }
      .icon_phone {
        color: #42bbb8;
      }
      .icon_vip {
        color: $orange;
      }
      .icon_sex,
      .icon_grade,
      .icon_wechat,
      .icon_wechat_active,
      .icon_vip {
        font-size: 14px;
      }
    }
  }
}
</style>
