var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-container", [
    _c(
      "div",
      { staticClass: "form-top" },
      [
        _c(
          "el-form",
          { attrs: { model: _vm.form, "label-width": "100px", size: "small" } },
          [
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 6 } },
                  [
                    _c(
                      "el-form-item",
                      [
                        _c("template", { slot: "label" }, [
                          _c(
                            "div",
                            [
                              _vm._v(" 操作门店 "),
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    effect: "light",
                                    content:
                                      "接车单的操作门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)",
                                    placement: "bottom-start",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "iconfont grow-icon_doubt",
                                    staticStyle: { color: "#9a9a9a" },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "el-select",
                          {
                            attrs: {
                              size: "small",
                              filterable: "",
                              clearable: "",
                              remote: "",
                              placeholder: "搜索车店名称、编号",
                              "remote-method": _vm.searchStore,
                              loading: _vm.searchStoreLoading,
                            },
                            on: {
                              change: _vm.selectStore,
                              clear: _vm.deleteStore,
                            },
                            model: {
                              value: _vm.form.companyId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "companyId", $$v)
                              },
                              expression: "form.companyId",
                            },
                          },
                          _vm._l(_vm.storeList, function (item) {
                            return _c(
                              "el-option",
                              {
                                key: item.companyId,
                                attrs: {
                                  label: item.companyName,
                                  value: item.companyId,
                                },
                              },
                              [
                                _c("span", { staticStyle: { float: "left" } }, [
                                  _vm._v(_vm._s(item.companyName)),
                                ]),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "查询日期" } },
                      [
                        _c("el-date-picker", {
                          staticClass: "w100",
                          attrs: {
                            type: "daterange",
                            "unlink-panels": "",
                            "range-separator": "至",
                            "start-placeholder": "起始时间",
                            "end-placeholder": "截止时间",
                            "value-format": "yyyy-MM-dd",
                            clearable: false,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.dateRangeChange()
                            },
                          },
                          model: {
                            value: _vm.form.times,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "times", $$v)
                            },
                            expression: "form.times",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("el-col", { attrs: { span: 12 } }, [
                  _c(
                    "div",
                    {
                      staticClass: "el-form-item--small",
                      staticStyle: { "margin-left": "20%" },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "btn_search",
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.getList()
                            },
                          },
                        },
                        [_vm._v("查询")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "btn_search",
                          attrs: { size: "small" },
                          on: { click: _vm.nowDate },
                        },
                        [_vm._v("今日")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "btn_search",
                          attrs: { size: "small" },
                          on: { click: _vm.nowMonth },
                        },
                        [_vm._v("本月")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "btn_search",
                          attrs: { size: "small" },
                          on: { click: _vm.empty },
                        },
                        [_vm._v("置空")]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "pay-type" }, [
          _c("div", { staticClass: "pay-type-list" }, [
            _c("div", { staticClass: "type-incon" }, [
              _c("i", { staticClass: "iconfont grow-icon orange" }),
            ]),
            _c("div", { staticClass: "type-word" }, [
              _c("p", { staticClass: "orange" }, [_vm._v("收入")]),
              _c("p", { staticClass: "orange number-word" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("toThousands")(
                        _vm.$lodash.get(_vm.list, "incomeTotal", 0)
                      )
                    ) +
                    " "
                ),
              ]),
              _c("p", [_vm._v("营业、其他收入")]),
            ]),
          ]),
          _c("div", { staticClass: "pay-type-list" }, [
            _c("div", { staticClass: "type-incon" }, [
              _c("i", { staticClass: "iconfont grow-icon2 primary" }),
            ]),
            _c("div", { staticClass: "type-word" }, [
              _c("p", { staticClass: "primary" }, [_vm._v("支出")]),
              _c("p", { staticClass: "primary number-word" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("toThousands")(
                        _vm.$lodash.get(_vm.list, "expenseTotal", 0)
                      )
                    ) +
                    " "
                ),
              ]),
              _c("p", [_vm._v("采购、退货款等支出")]),
            ]),
          ]),
          _c("div", { staticClass: "pay-type-list" }, [
            _c("div", { staticClass: "type-incon" }, [
              _c("i", { staticClass: "iconfont grow-icon3 blue" }),
            ]),
            _c("div", { staticClass: "type-word" }, [
              _c("p", { staticClass: "blue" }, [_vm._v("结余")]),
              _c("p", { staticClass: "blue number-word" }, [
                _vm._v(
                  " " + _vm._s(_vm._f("toThousands")(_vm.balanceTotal)) + " "
                ),
              ]),
              _c("p", [_vm._v("店内总收入减去总支出后结余")]),
            ]),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass: "orange infor-bt",
            staticStyle: { background: "#fff4e5", "margin-top": "60px" },
          },
          [_vm._v(" 自费 ")]
        ),
        _c("div", { staticClass: "source-number" }, [
          _c("div", [
            _c("p", [_vm._v("营业总额")]),
            _c("p", { staticStyle: { color: "#333", "font-size": "30px" } }, [
              _c("span", { staticStyle: { "font-size": "20px" } }, [
                _vm._v("￥"),
              ]),
              _vm._v(
                _vm._s(
                  _vm._f("toThousands")(
                    _vm.$lodash.get(_vm.totalList[0], "selfTotal", 0)
                  )
                ) + " "
              ),
            ]),
          ]),
          _c("div", [
            _c("p", [_vm._v("服务营业额")]),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm._f("toThousands")(
                    _vm.$lodash.get(
                      _vm.list,
                      "selfTotalBusinessTurnover.serviceTurnover",
                      0
                    )
                  )
                )
              ),
            ]),
          ]),
          _c("div", [
            _c("p", [_vm._v("商品营业额")]),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm._f("toThousands")(
                    _vm.$lodash.get(
                      _vm.list,
                      "selfTotalBusinessTurnover.goodTurnover",
                      0
                    )
                  )
                )
              ),
            ]),
          ]),
          _c("div", [
            _c("p", [_vm._v("提车单量")]),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm._f("toThousands")(
                    _vm.$lodash.get(
                      _vm.list,
                      "selfTotalBusinessTurnover.takeCarBillNum",
                      0
                    )
                  )
                )
              ),
            ]),
          ]),
          _c("div", [
            _c("p", [_vm._v("进店单量")]),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm._f("toThousands")(
                    _vm.$lodash.get(
                      _vm.list,
                      "selfTotalBusinessTurnover.intoBillNum",
                      0
                    )
                  )
                )
              ),
            ]),
          ]),
          _c("div", [
            _c("p", [_vm._v("结账单量")]),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm._f("toThousands")(
                    _vm.$lodash.get(
                      _vm.list,
                      "selfTotalBusinessTurnover.settleAccountsBillNum",
                      0
                    )
                  )
                )
              ),
            ]),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass: "primary infor-bt",
            staticStyle: { background: "#e8f3ee" },
          },
          [_vm._v("包干")]
        ),
        _c("div", { staticClass: "source-number" }, [
          _c("div", [
            _c("p", [_vm._v("营业总额")]),
            _c("p", { staticStyle: { color: "#333", "font-size": "30px" } }, [
              _c("span", { staticStyle: { "font-size": "20px" } }, [
                _vm._v("￥"),
              ]),
              _vm._v(
                _vm._s(
                  _vm._f("toThousands")(
                    _vm.$lodash.get(_vm.totalList[0], "contractTotal", 0)
                  )
                ) + " "
              ),
            ]),
          ]),
          _c("div", [
            _c("p", [_vm._v("服务营业额")]),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm._f("toThousands")(
                    _vm.$lodash.get(
                      _vm.list,
                      "contractTotalBusinessTurnover.serviceTurnover",
                      0
                    )
                  )
                )
              ),
            ]),
          ]),
          _c("div", [
            _c("p", [_vm._v("商品营业额")]),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm._f("toThousands")(
                    _vm.$lodash.get(
                      _vm.list,
                      "contractTotalBusinessTurnover.goodTurnover",
                      0
                    )
                  )
                )
              ),
            ]),
          ]),
          _c("div", [
            _c("p", [_vm._v("提车单量")]),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm._f("toThousands")(
                    _vm.$lodash.get(
                      _vm.list,
                      "contractTotalBusinessTurnover.takeCarBillNum",
                      0
                    )
                  )
                )
              ),
            ]),
          ]),
          _c("div", [
            _c("p", [_vm._v("进店单量")]),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm._f("toThousands")(
                    _vm.$lodash.get(
                      _vm.list,
                      "contractTotalBusinessTurnover.intoBillNum",
                      0
                    )
                  )
                )
              ),
            ]),
          ]),
          _c("div", [
            _c("p", [_vm._v("结账单量")]),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm._f("toThousands")(
                    _vm.$lodash.get(
                      _vm.list,
                      "contractTotalBusinessTurnover.settleAccountsBillNum",
                      0
                    )
                  )
                )
              ),
            ]),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass: "blue infor-bt",
            staticStyle: { background: "#ecf7fc" },
          },
          [_vm._v("索赔")]
        ),
        _c("div", { staticClass: "source-number" }, [
          _c("div", [
            _c("p", [_vm._v("营业总额")]),
            _c("p", { staticStyle: { color: "#333", "font-size": "30px" } }, [
              _c("span", { staticStyle: { "font-size": "20px" } }, [
                _vm._v("￥"),
              ]),
              _vm._v(
                _vm._s(
                  _vm._f("toThousands")(
                    _vm.$lodash.get(
                      _vm.totalList[0],
                      "claimForCompensationTotal",
                      0
                    )
                  )
                ) + " "
              ),
            ]),
          ]),
          _c("div", [
            _c("p", [_vm._v("服务营业额")]),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm._f("toThousands")(
                    _vm.$lodash.get(
                      _vm.list,
                      "claimForCompensationTotalBusinessTurnover.serviceTurnover",
                      0
                    )
                  )
                )
              ),
            ]),
          ]),
          _c("div", [
            _c("p", [_vm._v("商品营业额")]),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm._f("toThousands")(
                    _vm.$lodash.get(
                      _vm.list,
                      "claimForCompensationTotalBusinessTurnover.goodTurnover",
                      0
                    )
                  )
                )
              ),
            ]),
          ]),
          _c("div", [
            _c("p", [_vm._v("提车单量")]),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm._f("toThousands")(
                    _vm.$lodash.get(
                      _vm.list,
                      "claimForCompensationTotalBusinessTurnover.takeCarBillNum",
                      0
                    )
                  )
                )
              ),
            ]),
          ]),
          _c("div", [
            _c("p", [_vm._v("进店单量")]),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm._f("toThousands")(
                    _vm.$lodash.get(
                      _vm.list,
                      "claimForCompensationTotalBusinessTurnover.intoBillNum",
                      0
                    )
                  )
                )
              ),
            ]),
          ]),
          _c("div", [
            _c("p", [_vm._v("结账单量")]),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm._f("toThousands")(
                    _vm.$lodash.get(
                      _vm.list,
                      "claimForCompensationTotalBusinessTurnover.settleAccountsBillNum",
                      0
                    )
                  )
                )
              ),
            ]),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass: "second infor-bt",
            staticStyle: { background: "#ecf8f8" },
          },
          [_vm._v("理赔")]
        ),
        _c("div", { staticClass: "source-number" }, [
          _c("div", [
            _c("p", [_vm._v("营业总额")]),
            _c("p", { staticStyle: { color: "#333", "font-size": "30px" } }, [
              _c("span", { staticStyle: { "font-size": "20px" } }, [
                _vm._v("￥"),
              ]),
              _vm._v(
                _vm._s(
                  _vm._f("toThousands")(
                    _vm.$lodash.get(_vm.totalList[0], "claimSettlementTotal", 0)
                  )
                ) + " "
              ),
            ]),
          ]),
          _c("div", [
            _c("p", [_vm._v("服务营业额")]),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm._f("toThousands")(
                    _vm.$lodash.get(
                      _vm.list,
                      "claimSettlementTotalBusinessTurnover.serviceTurnover",
                      0
                    )
                  )
                )
              ),
            ]),
          ]),
          _c("div", [
            _c("p", [_vm._v("商品营业额")]),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm._f("toThousands")(
                    _vm.$lodash.get(
                      _vm.list,
                      "claimSettlementTotalBusinessTurnover.goodTurnover",
                      0
                    )
                  )
                )
              ),
            ]),
          ]),
          _c("div", [
            _c("p", [_vm._v("提车单量")]),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm._f("toThousands")(
                    _vm.$lodash.get(
                      _vm.list,
                      "claimSettlementTotalBusinessTurnover.takeCarBillNum",
                      0
                    )
                  )
                )
              ),
            ]),
          ]),
          _c("div", [
            _c("p", [_vm._v("进店单量")]),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm._f("toThousands")(
                    _vm.$lodash.get(
                      _vm.list,
                      "claimSettlementTotalBusinessTurnover.intoBillNum",
                      0
                    )
                  )
                )
              ),
            ]),
          ]),
          _c("div", [
            _c("p", [_vm._v("结账单量")]),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm._f("toThousands")(
                    _vm.$lodash.get(
                      _vm.list,
                      "claimSettlementTotalBusinessTurnover.settleAccountsBillNum",
                      0
                    )
                  )
                )
              ),
            ]),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }