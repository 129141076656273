<template>
  <el-cascader
    ref="cascader"
    v-model="companyIds"
    :options="companyTree"
    @change="handleChange"
    clearable
    :size="size"
    :show-all-levels="false"
    :props="{
      value: 'id',
      label: 'name',
      children: 'nextGroup',
      checkStrictly: true,
    }"
    filterable
    :placeholder="placeholder"
  ></el-cascader>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      default: 'small',
    },
    operateCompanyUrl: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '搜索车店名称/编号',
    },
  },
  watch: {
    value: {
      handler: function (val) {
        // console.log('value:', val);
        if (
          !val?.length ||
          val.toString() === this.companyTree?.at(-1)?.companyIds?.toString()
        ) {
          this.companyIds = val;
        }
      },
      immediate: true,
    },
    operateCompanyUrl: {
      handler: function (val) {
        // console.log('operateCompanyUrl:', val);
        // this.$refs.cascader.$refs.panel.clearCheckedNodes();
        if (val?.length) this.companyIds = val;
        // this.$set(this, 'companyIds', val);
        // this.$nextTick().then(() => {});
      },
      immediate: true,
    },
  },
  data() {
    return {
      companyIds: [],
    };
  },
  computed: {
    ...mapState({
      companyTree: (state) => state.user.companyTree,
    }),
  },
  created() {
    // this.companyIds = this.value;
  },
  methods: {
    handleChange(value) {
      console.log(value);
      let companyIds = [];
      if (value.length) {
        let curr = { nextGroup: this.companyTree };
        for (let i = 0; i < this.companyIds.length; i++) {
          curr = curr.nextGroup.find((item) => item.id === this.companyIds[i]);
        }
        companyIds = curr.companyIds || [curr.id];
      }
      this.$emit('input', companyIds || []);
      this.$emit('update:operateCompanyUrl', value);
    },
  },
};
</script>
<style lang="scss" scoped>
.el-cascader {
  width: 180px !important;
}
</style>
