<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <el-form
        class="filter-form"
        :model="form"
        label-width="100px"
        size="small"
      >
        <div class="flex-x-between">
          <div class="flex flex-shrink0">
            <el-form-item label="反馈人">
              <el-select
                v-model="form.userId"
                filterable
                clearable
                remote
                placeholder="搜索姓名/电话"
                :remote-method="searchUserList"
                :loading="searchUserLoading"
              >
                <el-option
                  v-for="item in feedbackUserList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                  {{ item.label }}
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="签约客户">
              <el-select
                v-model="form.clientId"
                filterable
                clearable
                remote
                placeholder="搜索名称"
                :remote-method="searchClient"
                :loading="searchClientLoading"
              >
                <el-option
                  v-for="item in customList"
                  :key="item.id"
                  :label="item.clientName"
                  :value="item.id"
                >
                  {{ item.clientName }}
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="反馈时间">
              <el-date-picker
                v-model="form.checkTimes"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="截止时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </div>
          <div class="el-form-item--small">
            <div class="flex">
              <el-button
                class="btn_search"
                type="primary"
                size="small"
                @click="getDataList()"
                >查询</el-button
              >
              <el-button class="btn_search" size="small" @click="empty"
                >置空</el-button
              >
            </div>
          </div>
        </div>
      </el-form>
    </template>

    <template>
      <div style="margin-bottom: 15px" class="btns flex-x-end">
        <el-button
          v-auth="608"
          size="small"
          type="text"
          class="blue"
          @click="showExportDialog = true"
          >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
        ></el-button>
      </div>
      <el-table
        class="custom-table"
        :data="tableData"
        border
        v-fixedScrollBar="$refs.baseContainer"
      >
        <el-table-column type="index" width="60" label="序号">
        </el-table-column>
        <el-table-column prop="feedbackDateTime" label="反馈时间" width="160">
        </el-table-column>
        <el-table-column prop="userName" label="反馈人" width="120">
        </el-table-column>
        <el-table-column label="反馈电话" width="120" prop="phone">
        </el-table-column>
        <el-table-column prop="clientName" label="所属签约客户" width="120">
          <template slot-scope="scope">
            <span
              class="pointer text_operation blue"
              @click="goDetail(scope.row)"
              >{{ scope.row.clientName }}</span
            >
          </template>
        </el-table-column>
        <el-table-column label="图片 | 视频" width="200" header-align="center">
          <template #default="{ row }">
            <div
              class="flex-y-center"
              style="padding: 10px 0; justify-content: space-evenly"
            >
              <span
                v-if="!row.picList.length"
                style="width: 32px; text-align: center"
                >无</span
              >

              <el-popover v-else placement="top" trigger="hover">
                <upload-file-list
                  disabled
                  :value="row.picList"
                ></upload-file-list>
                <div class="video-list"></div>
                <template slot="reference">
                  <el-badge
                    style="margin-right: 8px"
                    :value="row.picList.length"
                  >
                    <img class="thumbnail-img" :src="row.picList[0]" />
                  </el-badge>
                </template>
              </el-popover>

              <div
                style="
                  width: 2px;
                  height: 32px;
                  margin: 0 8px;
                  background: #ebeef5;
                "
              ></div>

              <span
                v-if="!$lodash.get(row, 'videoList.length', 0)"
                style="width: 32px; text-align: center"
                >无</span
              >
              <el-popover v-else placement="top" trigger="hover">
                <div class="video-list">
                  <video
                    class="thumbnail-video"
                    preload="auto"
                    v-for="(src, index) in row.videoList"
                    :key="index"
                    :src="src"
                    @click="handleClickVideo($event, row.videoList, index)"
                  >
                    <source :src="src" type="video/mp4" />
                    您的设备不支持 video 标签。
                  </video>
                </div>
                <template slot="reference">
                  <el-badge
                    style="margin-right: 8px"
                    :value="row.videoList.length"
                  >
                    <img class="thumbnail-img" :src="playImg" />
                  </el-badge>
                </template>
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="反馈内容"
          min-width="120"
          prop="feedbackContent"
        >
        </el-table-column>
      </el-table>
      <export-dialog
        :visibility.sync="showExportDialog"
        :total="total"
        :pageOption="pageOption"
        :filterForm="form"
        :url="exportUrl"
      ></export-dialog>
      <videoPlayerDialog
        v-if="showVideoPlayerDialog"
        :visibility.sync="showVideoPlayerDialog"
        :videos="previewVideos"
        :index="previewVideoIndex"
        :modal="false"
      ></videoPlayerDialog>
    </template>

    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
  </base-container>
</template>

<script>
import pagination from '@/components/Pagination.vue';
import ExportDialog from '@/components/ExportDialog';
import uploadFileList from '@/components/uploadFileList';
import videoPlayerDialog from '@/components/videoPlayerDialog.vue';
import playImg from '@/assets/img/play.jpeg';

import {
  searchFeedbackUser,
  exportFeedbackUrl,
  getFeedbackList,
} from '@/api/VMfeedback';
import { getCustomerList } from '@/api/customer/customer';
import { downloadFile } from '@/utils/fileApi';
import { searchClient } from '@/api/customer/customer';
import { mapState, mapGetters, mapMutations } from 'vuex';

// 工具
import Big from 'big.js';

export default {
  name: 'VMfeedback',
  components: { pagination, ExportDialog, uploadFileList, videoPlayerDialog },
  data() {
    return {
      form: {},
      showAddCusterDialog: false,
      showExportDialog: false,

      startPage: 1,
      endPage: 1,
      tableData: [],
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      searchClientLoading: false,
      searchUserLoading: false,
      feedbackUserList: [],
      dialogTableVisible: false,
      customList: [],
      exportUrl: exportFeedbackUrl,
      showVideoPlayerDialog: false,
      previewVideos: [],
      previewVideoIndex: 0,
      playImg,
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  created() {
    this.getDataList();
  },
  methods: {
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getDataList(false);
    },
    /**
     * 加载数据
     */
    getDataList(reset = true) {
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      var times = {
        feedbackBeginDate: this.form.checkTimes?.[0]
          ? this.form.checkTimes?.[0]
          : '',
        feedbackEndDate: this.form.checkTimes ? this.form.checkTimes?.[1] : '',
      };
      if (this.form.userId) {
        this.form.userId = Number(this.form.userId);
      }
      let data = Object.assign({}, this.form, this.pageOption, times);
      getFeedbackList(data).then((res) => {
        // 修改总条数

        this.total = res.total;
        this.tableData = res.records;
        this.tableData.forEach((item) => {
          item.picList = [];
          item.videoList = [];
          this.videoCover = [];
          item.attachments.forEach((media) => {
            if (media.attachmentType == 'images') {
              item.picList.push(media.attachmentUrl);
            }
            if (media.attachmentType == 'video') {
              item.videoList.push(media.attachmentUrl);
            }
          });
        });
      });
    },
    /**
     * 远程搜索反馈人列表
     */
    searchUserList(keyWord) {
      if (keyWord) {
        this.searchUserLoading = true;
        searchFeedbackUser(keyWord)
          .then((res) => {
            // this.storeList = res;
            this.feedbackUserList = res;
            console.log(res, 'res');
          })
          .finally(() => {
            this.searchUserLoading = false;
          });
      }
    },
    /**
     * 远程搜索客户列表
     */
    searchClient(keyWord = '') {
      this.searchClientLoading = true;
      searchClient(keyWord)
        .then((res) => {
          this.customList = res.records;
        })
        .finally(() => {
          this.searchClientLoading = false;
        });
    },
    /**
     * 跳转详情页
     */
    goDetail(row) {
      this.$router.push({
        name: 'customerDetails',
        query: { id: row.clientId },
      });
    },
    handleClickVideo(e, list, index) {
      e.preventDefault();
      this.previewVideos = list;
      this.previewVideoIndex = index;
      this.showVideoPlayerDialog = true;
    },
    /**
     * 置空输入框数据
     */
    empty() {
      this.form = {};
    },
  },
};
</script>
<style lang="scss" scoped>
.form-top {
  height: 28px;
}
.form-item__customlabel {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > i:last-child {
    margin-left: 5px;
  }
}
.primary {
  color: $primary;
}
.btn_search {
  width: 76px;
}
.thumbnail-img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 4px;
  border: 1px dashed #d9d9d9;
}
.video-list {
  display: inline-flex;
  .thumbnail-video {
    margin-left: 10px;
    width: 60px;
    height: 60px;
    cursor: pointer;
  }
}
</style>
