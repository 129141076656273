var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { ref: "baseContainer", attrs: { paddingTop: "" } },
    [
      [
        _c(
          "el-form",
          {
            staticClass: "filter-form",
            attrs: { model: _vm.form, "label-width": "100px", size: "small" },
          },
          [
            _c("div", { staticClass: "flex-x-between" }, [
              _c(
                "div",
                { staticClass: "flex flex-shrink0" },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c(
                          "div",
                          [
                            _vm._v(" 结算门店 "),
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  effect: "light",
                                  content:
                                    "门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)",
                                  placement: "bottom-start",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "iconfont grow-icon_doubt",
                                  staticStyle: { color: "#9a9a9a" },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("operateCompanyCascader", {
                        attrs: {
                          operateCompanyUrl: _vm.form.operateCompanyUrl,
                        },
                        model: {
                          value: _vm.form.operateCompanyId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "operateCompanyId", $$v)
                          },
                          expression: "form.operateCompanyId",
                        },
                      }),
                    ],
                    2
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "车牌" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入车牌", clearable: "" },
                        on: {
                          input: function ($event) {
                            return _vm.dateRangeChange()
                          },
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.getData.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.form.carNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "carNumber", $$v)
                          },
                          expression: "form.carNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "车单号" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入车单号", clearable: "" },
                        on: {
                          input: function ($event) {
                            return _vm.dateRangeChange()
                          },
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.getData.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.form.billCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "billCode", $$v)
                          },
                          expression: "form.billCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "el-form-item--small" }, [
                _c(
                  "div",
                  {
                    staticClass: "el-form-item__label pointer",
                    on: {
                      click: function ($event) {
                        _vm.showMore = !_vm.showMore
                      },
                    },
                  },
                  [
                    _vm._v(" 更多筛选 "),
                    _c("i", {
                      class: {
                        primary: _vm.showMore,
                        "el-icon-arrow-right": !_vm.showMore,
                        "el-icon-arrow-down": _vm.showMore,
                      },
                    }),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "auth",
                            rawName: "v-auth",
                            value: 659,
                            expression: "659",
                          },
                        ],
                        staticClass: "btn_search",
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.getData },
                      },
                      [_vm._v("查询")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn_search",
                        attrs: { size: "small" },
                        on: { click: _vm.empty },
                      },
                      [_vm._v("置空")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showMore,
                    expression: "showMore",
                  },
                ],
                staticClass: "flex-warp",
              },
              [
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "回款主体", prop: "invoicingEntity" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: "请选择回款主体",
                          },
                          model: {
                            value: _vm.form.invoicingEntity,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "invoicingEntity", $$v)
                            },
                            expression: "form.invoicingEntity",
                          },
                        },
                        _vm._l(_vm.invoicingEntities, function (item) {
                          return _c("el-option", {
                            key: item,
                            attrs: { label: item, value: item },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "认领时间" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "w100",
                        attrs: {
                          type: "daterange",
                          "unlink-panels": "",
                          "range-separator": "至",
                          "start-placeholder": "起始时间",
                          "end-placeholder": "截止时间",
                          "value-format": "yyyy-MM-dd",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.dateRangeChange()
                          },
                        },
                        model: {
                          value: _vm.form.claimDateTimes,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "claimDateTimes", $$v)
                          },
                          expression: "form.claimDateTimes",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "结账时间" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "w100",
                        attrs: {
                          type: "daterange",
                          "unlink-panels": "",
                          "range-separator": "至",
                          "start-placeholder": "起始时间",
                          "end-placeholder": "截止时间",
                          "value-format": "yyyy-MM-dd",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.dateRangeChange()
                          },
                        },
                        model: {
                          value: _vm.form.checkDateTimes,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "checkDateTimes", $$v)
                          },
                          expression: "form.checkDateTimes",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "提单状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择提单状态" },
                          model: {
                            value: _vm.form.submitStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "submitStatus", $$v)
                            },
                            expression: "form.submitStatus",
                          },
                        },
                        _vm._l(_vm.submitStatusEnum, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.desc, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "提单时间" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "w100",
                        attrs: {
                          type: "daterange",
                          "unlink-panels": "",
                          "range-separator": "至",
                          "start-placeholder": "起始时间",
                          "end-placeholder": "截止时间",
                          "value-format": "yyyy-MM-dd",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.dateRangeChange()
                          },
                        },
                        model: {
                          value: _vm.form.submitDateTimes,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "submitDateTimes", $$v)
                          },
                          expression: "form.submitDateTimes",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "流程单号" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入内部提单流程单号",
                          clearable: "",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.dateRangeChange()
                          },
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.getData.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.form.trackingSeq,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "trackingSeq", $$v)
                          },
                          expression: "form.trackingSeq",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "撤销提单" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择提单状态" },
                          model: {
                            value: _vm.form.revokeStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "revokeStatus", $$v)
                            },
                            expression: "form.revokeStatus",
                          },
                        },
                        _vm._l(_vm.revokeStatusEnum, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.desc, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        ),
      ],
      [
        _c(
          "div",
          {
            staticClass: "btns flex-x-between",
            staticStyle: { margin: "30px 0 14px 0" },
          },
          [
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "auth",
                        rawName: "v-auth",
                        value: 661,
                        expression: "661",
                      },
                    ],
                    staticClass: "btn_insert",
                    staticStyle: { height: "35px" },
                    attrs: { size: "small" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.batchOperation(2, "批量提单")
                      },
                    },
                  },
                  [_vm._v("批量提单")]
                ),
                _vm.showSelecedClaimAmount
                  ? _c(
                      "span",
                      { staticStyle: { "margin-left": "15px", color: "red" } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("toThousands")(_vm.selecedClaimAmount)
                            )
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "auth",
                        rawName: "v-auth",
                        value: 660,
                        expression: "660",
                      },
                    ],
                    staticClass: "blue",
                    attrs: { size: "small", type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.downloadAllRecords()
                      },
                    },
                  },
                  [
                    _vm._v("导出列表"),
                    _c("i", {
                      staticClass: "iconfont grow-icon_daochu el-icon--right",
                    }),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "fixedScrollBar",
                rawName: "v-fixedScrollBar",
                value: _vm.$refs.baseContainer,
                expression: "$refs.baseContainer",
              },
            ],
            staticClass: "custom-table",
            attrs: { data: _vm.listData, border: "" },
            on: { "selection-change": _vm.handleSelectionChange },
          },
          [
            _c("el-table-column", {
              attrs: {
                type: "selection",
                width: "60",
                prop: "selection",
                align: "center",
                label: "选择",
              },
            }),
            _c("el-table-column", {
              attrs: { type: "index", label: "序号", width: "60" },
            }),
            _c("el-table-column", {
              attrs: { label: "操作", width: "200" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "auth",
                              rawName: "v-auth",
                              value: 681,
                              expression: "681",
                            },
                          ],
                          staticClass: "text_operation blue",
                          staticStyle: { "margin-right": "10px" },
                          on: {
                            click: function ($event) {
                              return _vm.handleUpdateRemark(scope.row)
                            },
                          },
                        },
                        [_vm._v("更新备注")]
                      ),
                      scope.row.hasSubmitTask === "未提单"
                        ? _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "auth",
                                  rawName: "v-auth",
                                  value: 661,
                                  expression: "661",
                                },
                              ],
                              staticClass: "text_operation blue",
                              staticStyle: { "margin-right": "10px" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleSubmit(scope.row)
                                },
                              },
                            },
                            [_vm._v("提单")]
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "提单状态", prop: "hasSubmitTask" },
            }),
            _c("el-table-column", {
              attrs: { width: "200", label: "认领人", prop: "claimOperator" },
            }),
            _c("el-table-column", {
              attrs: { width: "200", label: "认领时间", prop: "claimDateTime" },
            }),
            _c("el-table-column", {
              attrs: {
                width: "200",
                label: "结算门店",
                prop: "settlementCompany",
              },
            }),
            _c("el-table-column", {
              attrs: { width: "200", label: "车单号", prop: "billCode" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "blue pointer",
                          on: {
                            click: function ($event) {
                              return _vm.goBillDetail(row.billId)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(row.billCode) + " ")]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "140", label: "车牌", prop: "carNumber" },
            }),
            _c("el-table-column", {
              attrs: {
                width: "140",
                label: "业务来源",
                prop: "billSourceTypeName",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "140",
                label: "已认领金额（元）",
                prop: "claimAmount",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "140",
                label: "车单金额(元)",
                prop: "billAmount",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "200",
                label: "系统流水编号",
                prop: "sysStatementSeqCode",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "200",
                label: "回款主体",
                prop: "invoicingEntity",
              },
            }),
            _c("el-table-column", {
              attrs: { width: "200", label: "银行账号", prop: "bankAccount" },
            }),
            _c("el-table-column", {
              attrs: { width: "200", label: "开户行", prop: "depositBank" },
            }),
            _c("el-table-column", {
              attrs: {
                width: "200",
                label: "回款时间",
                prop: "receivingDateTime",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "200",
                label: "付款主体",
                prop: "insuranceCompanyName",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "200",
                label: "付款账号",
                prop: "paymentAccount",
              },
            }),
            _c("el-table-column", {
              attrs: {
                "min-width": "200",
                label: "银行摘要",
                prop: "bankSummary",
                "show-overflow-tooltip": "",
              },
            }),
            _c("el-table-column", {
              attrs: { width: "140", label: "金额(元)", prop: "amount" },
            }),
            _c("el-table-column", {
              attrs: {
                width: "140",
                label: "资金流水号",
                prop: "transactionSeq",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "200",
                label: "提单时间",
                prop: "submitWorkDateTime",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "200",
                label: "完成确认提单时间",
                prop: "confirmCompleteDateTime",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "140",
                label: "内部结算流程单号",
                prop: "internalSettTrackingSeq",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "140",
                label: "提单备注",
                prop: "remark",
                "show-overflow-tooltip": "",
              },
            }),
          ],
          1
        ),
        _vm.showEditRemarkDialog
          ? _c("editSubmitWorkRemarkDialog", {
              attrs: {
                visibility: _vm.showEditRemarkDialog,
                id: _vm.submitEditId,
                "edit-type": 1,
                submitRemark: _vm.submitRemark,
              },
              on: {
                "update:visibility": function ($event) {
                  _vm.showEditRemarkDialog = $event
                },
                save: function ($event) {
                  return _vm.getData(false)
                },
              },
            })
          : _vm._e(),
        _vm.showEditDialog
          ? _c("editSubmitWorkDialog", {
              attrs: {
                visibility: _vm.showEditDialog,
                ids: _vm.submitEditIds,
                submitInternalSettTrackingSeq:
                  _vm.submitInternalSettTrackingSeq,
                submitRemark: _vm.submitRemark,
              },
              on: {
                "update:visibility": function ($event) {
                  _vm.showEditDialog = $event
                },
                save: function ($event) {
                  return _vm.getData(false)
                },
              },
            })
          : _vm._e(),
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c("pagination", {
            attrs: { total: _vm.total },
            on: { pageChange: _vm.changePage },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }