<template>
  <div ref="printDom" class="print-template">
    <div ref="print">
      <div class="print-template__header">
        <h1 class="print-template__header__title">
          {{ billMessage.operateCompanyName }}
        </h1>
        <p style="margin-top: 5px; font-size: 18px; text-align: center">
          {{ config.title }}
        </p>
        <img
          class="print-template__header__lt-img"
          v-if="config.leftImagePath"
          :src="config.leftImagePath"
        />
        <img
          class="print-template__header__rt-img"
          v-if="config.rightImagePath"
          :src="config.rightImagePath"
        />
      </div>
      <div class="print-template__date" style="font-size: 12px">
        打印时间：{{ printTime }}
      </div>
      <div class="print-template__user">
        <h3 ref="clientName">客户：{{ carInfo.clientName }}</h3>
        <h3>接车单号：{{ billMessage.billCode }}</h3>
      </div>
      <div class="print-template__box__border">
        <table class="print-template__box__border__table">
          <tbody>
            <tr>
              <td style="width: 85px; text-align: left">送修人</td>
              <td style="width: 165px; text-align: left">
                {{ billMessage.customerName }}
              </td>
              <td style="width: 100px; text-align: left">送修人电话</td>
              <td style="width: 179px; text-align: left">
                {{ billMessage.customerPhone }}
              </td>
              <td style="width: 85px; text-align: left">车牌号</td>
              <td style="width: 162px; text-align: left">
                {{ carInfo.carNumber }}
              </td>
            </tr>

            <tr>
              <td style="width: 85px; text-align: left">车型</td>
              <td style="width: 165px; text-align: left">
                {{ carInfo.brandName }}
              </td>
              <td style="width: 90px; text-align: left">车架号</td>
              <td style="width: 179px; text-align: left">
                {{ carInfo.uniqueId }}
              </td>
              <td style="width: 85px; text-align: left">施工类型</td>
              <td style="width: 162px; text-align: left">
                {{ billMessage.difficulty | dict('difficulty') }}
              </td>
              <!-- <td style="width: 85px; text-align: left">发动机号</td>
              <td style="width: 162px; text-align: left">
                {{ carInfo.engineNumber }}
              </td> -->
            </tr>

            <tr>
              <td style="width: 85px; text-align: left">接车员</td>
              <td style="width: 165px; text-align: left">
                {{ billMessage.pickUpUserName }}
              </td>
              <td style="width: 90px; text-align: left">进店时间</td>
              <td style="width: 179px; text-align: left">
                {{ billMessage.dateCreated }}
              </td>
              <td style="width: 85px; text-align: left">进店里程</td>
              <td style="width: 162px; text-align: left">
                {{ billMessage.mileage ? billMessage.mileage + 'km' : '' }}
              </td>
            </tr>

            <tr>
              <td style="width: 85px; text-align: left">电量</td>
              <td style="width: 165px; text-align: left">
                {{ billMessage.oilMeter | dict('energyPercentage') }}
              </td>
              <td style="width: 90px; text-align: left">预交车时间</td>
              <td colspan="3" style="width: 179px; text-align: left">
                {{
                  billMessage.undetermined ? '待定' : billMessage.planFinishTime
                }}
              </td>
            </tr>
            <tr>
              <td style="width: 85px; text-align: left">车单标签</td>
              <td colspan="5">
                {{ label }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        style="
          margin-top: 30px;
          padding: 5px;
          min-height: 260px;
          border: 1px solid #000;
        "
        class="print-template__remark"
      >
        <h3 style="margin-bottom: 10px; font-weight: 600; font-size: 16px">
          初诊与备注：
        </h3>
        <p style="text-indent: 2em; line-height: 1.5">
          {{ billMessage.firstVisitRemark }}
        </p>
      </div>
      <div
        style="
          min-height: 260px;
          padding: 5px;
          border: 1px solid #000;
          border-top: none;
        "
        class="print-template__remark"
      >
        <h3 style="margin-bottom: 10px; font-weight: 600; font-size: 16px">
          车主要求：
        </h3>
        <p style="text-indent: 2em; line-height: 1.5">
          {{ billMessage.remark }}
        </p>
      </div>

      <div style="margin-top: 30px; font-size: 14px; font-weight: 600">
        <p>1.本人同意贵司检查以上项目。</p>
        <p style="margin-top: 15px">
          2.维修完成后，客户凭此单取车，请妥善保管。
        </p>
        <p style="margin-top: 15px">
          3.下车请带走贵重物品，如有丢失，本店概不负责。
        </p>
      </div>

      <div style="display: flex; align-item: center; margin-top: 40px">
        <h3
          style="
            display: flex;
            align-items: center;
            margin-right: 150px;
            font-weight: 600;
            font-size: 16px;
          "
        >
          客户签名：
          <img
            style="width: 200px; max-height: 96px"
            :src="billMessage.electronicSignaturePic"
            alt=""
          />
        </h3>
        <h3
          style="
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 16px;
          "
        >
          日期：
        </h3>
      </div>
      <div style="display: flex; margin-top: 40px">
        <h3 style="margin-right: 317px; font-weight: 600; font-size: 16px">
          服务顾问签名：
        </h3>
        <h3 style="font-weight: 600; font-size: 16px">日期：</h3>
      </div>
    </div>
  </div>
</template>
<script>
import { Print } from '@/utils/print';
export default {
  name: 'customerConfirmPrintTemplate',
  data() {
    return {
      printTime: new Date().Format('yyyy-MM-dd hh:mm'),
      config: {
        title: '接待单',
        leftImagePath: require('@/assets/slogan.png'),
      },
      // 单据信息
      billMessage: {},
      // 车辆信息
      carInfo: {},
      // 车单标签
      label: '',
    };
  },
  created() {},
  methods: {
    doPrint(data) {
      this.printTime = new Date().Format('yyyy-MM-dd hh:mm');
      Object.keys(data).forEach((key) => {
        // this[key] = data[key];
        this.$set(this, key, data[key]);
      });

      this.$nextTick().then(() => {
        Print(this.$refs.printDom);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.print-template {
  width: 800px;
  // box-shadow: 0px 0px 10px 0px rgba(71, 71, 71, 0.1);
  padding: 12px;
  background: white;

  .fontsize {
    font-size: 14px;
  }

  .text-left {
    text-align: left;
  }
  .text-center {
    text-align: center;
  }
  .text-right {
    text-align: right;
  }

  &__title {
    justify-content: center;
    margin: 7px 0 1px;
    font-weight: 600;
  }

  &__header {
    width: 100%;
    height: 75px;
  }

  &__user {
    margin-bottom: 4px;
  }

  &__user {
    justify-content: space-between;
  }

  &__date,
  &__user,
  &__main__ul,
  &__common,
  &__title {
    display: flex;
  }

  &__header {
    position: relative;

    &__title {
      text-align: center;
      margin: 0 150px;
      font-size: 26px;
      font-weight: normal;
    }

    &__lt-img,
    &__rt-img {
      min-width: 75px;
      max-width: 300px;
      height: 75px;
      position: absolute;
      top: 2px;
    }

    &__lt-img {
      left: 0;
    }

    &__rt-img {
      right: 0;
    }
  }

  &__header__lt-img > img,
  &__header__rt-img > img {
    min-width: 75px;
    max-width: 300px;
    height: 75px;
  }

  &__date {
    justify-content: flex-end;
    margin: 8px 0 7px 0;
  }

  &__box__border {
    &__table {
      width: 100%;
      font-weight: normal;
      &:last-child {
        border-bottom: 1px solid #000 !important;
      }
      th,
      td {
        vertical-align: middle;
        padding: 2px;
        border-top: 1px solid #000 !important;
        border-left: 1px solid #000 !important;
        word-break: break-all;
        font-weight: normal;
      }
      th:last-child,
      td:last-child {
        border-right: 1px solid #000 !important;
      }
      &__total {
        width: 200px;
        display: inline-block;
      }
    }
  }

  &__common {
    justify-content: space-between;
    &__column {
      width: 45%;
    }
  }

  &__line {
    border-bottom: 1px dashed #000;
  }
}
</style>
