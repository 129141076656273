var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-cascader", {
    ref: "cascader",
    attrs: {
      options: _vm.categoryTree,
      clearable: "",
      size: _vm.size,
      "show-all-levels": "",
      props: {
        value: "categoryId",
        label: "name",
        children: "subCategories",
        checkStrictly: _vm.checkStrictly,
      },
      filterable: "",
    },
    on: { change: _vm.handleChange, blur: _vm.handleBlur },
    model: {
      value: _vm.category,
      callback: function ($$v) {
        _vm.category = $$v
      },
      expression: "category",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }