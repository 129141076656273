var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-drawer",
    {
      attrs: {
        title: "流程详情",
        visible: _vm.visibility,
        size: "60%",
        "append-to-body": true,
        "before-close": _vm.handleClose,
        modal: "",
        "close-on-press-escape": "",
        wrapperClosable: "",
      },
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-table",
            { attrs: { data: _vm.controlsList, border: "" } },
            [
              _c("el-table-column", {
                attrs: { type: "index", label: "序号", width: "60" },
              }),
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  label: "操作类型",
                  prop: "operationType",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("dict")(row.operationType, "operationType")
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { width: "120", label: "操作人", prop: "operatorName" },
              }),
              _c("el-table-column", {
                attrs: { width: "150", label: "操作时间", prop: "dateCreated" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作详情", prop: "description" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-tooltip",
                          { attrs: { content: row.description } },
                          [
                            _c(
                              "div",
                              { staticClass: "text-ellipsis pointer" },
                              [_vm._v(" " + _vm._s(row.description) + " ")]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { width: "110", label: "明细" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "blue pointer",
                            on: {
                              click: function ($event) {
                                return _vm.openDetail(row.billLogId)
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  [13, 14, 31].includes(row.operationType)
                                    ? "明细"
                                    : ""
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            size: "50%",
            title: "明细",
            visible: _vm.detailDrawer,
            "append-to-body": true,
            modal: "",
            wrapperClosable: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.detailDrawer = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { margin: "0 30px" } },
            [
              _c(
                "el-table",
                {
                  staticClass: "service-table",
                  staticStyle: { width: "100%" },
                  attrs: {
                    border: "",
                    data: _vm.serviceTableData,
                    "default-expand-all": "",
                    "row-key": "id",
                    "tree-props": {
                      children: "billGoods",
                      hasChildren: "hasChildren",
                    },
                    "span-method": _vm.spanMethod,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { width: "60", label: "序号" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("div", [_vm._v(" " + _vm._s(row.title) + " ")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "项目名称",
                      "min-width": "160",
                      prop: "name",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            row.name
                              ? _c(
                                  "span",
                                  { class: { orange: row.emptyService } },
                                  [_vm._v(" " + _vm._s(row.name))]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "price", label: "单价", width: "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("span", { key: _vm.tableKey + 1 }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("toThousands")(row.price)) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "discount", label: "折扣", width: "60" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("span", { key: _vm.tableKey + 2 }, [
                              _vm._v(" " + _vm._s(row.discount || "无") + " "),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "date", label: "数量", width: "70" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("span", { key: _vm.tableKey + 3 }, [
                              row.showAllNum
                                ? _c("span", { staticClass: "orange" }, [
                                    _c("p", [_vm._v("报价" + _vm._s(row.num))]),
                                    row.waitPickNum
                                      ? _c("p", [
                                          _vm._v(
                                            "待领" + _vm._s(row.waitPickNum)
                                          ),
                                        ])
                                      : _vm._e(),
                                    row.pickedNum
                                      ? _c("p", [
                                          _vm._v(
                                            "已领" + _vm._s(row.pickedNum)
                                          ),
                                        ])
                                      : _vm._e(),
                                  ])
                                : _c("span", [_vm._v(_vm._s(row.num))]),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "date", label: "小计", width: "90" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("div", [
                              _c("span", { key: _vm.tableKey + 4 }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm._f("toThousands")(row.total)) +
                                    " "
                                ),
                              ]),
                              ![0, "", undefined, null].includes(
                                row.billDiscount
                              )
                                ? _c("div", [
                                    _c("span", [_vm._v("整单折扣后: ")]),
                                    _c("span", [
                                      _vm._v(
                                        "¥" +
                                          _vm._s(
                                            _vm._f("toThousands")(
                                              row.billDiscount
                                            )
                                          )
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "项目合计", width: "90" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            [0, "", undefined].includes(row.billDiscount)
                              ? _c("div", [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("toThousands")(
                                          row.itemTotalBefore
                                        )
                                      )
                                    ),
                                  ]),
                                ])
                              : _c("div", [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("toThousands")(
                                          row.itemTotalAfter
                                        )
                                      )
                                    ),
                                  ]),
                                  _c("del", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("toThousands")(
                                          row.itemTotalBefore
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "施工技师", width: "90" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("div", { key: _vm.tableKey + 5 }, [
                              _vm.$lodash.get(
                                row,
                                "technicianInfoVos.length",
                                false
                              )
                                ? _c("div", [
                                    _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$lodash
                                              .get(row, "technicianInfoVos", [])
                                              .map(
                                                (item) => item.technicianName
                                              )
                                              .join("、")
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }