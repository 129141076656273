// 获取所有cookie
function getCookie() {
  var cookieString = document.cookie.split('; ');
  var cookieObj = {};
  for (var i = 0; i < cookieString.length; i++) {
    var arr = cookieString[i].split('=');
    cookieObj[arr[0]] = arr[1];
  }
  return cookieObj;
}

// 获取单个cookie
function getCookieByName(cname) {
  var cookieObj = getCookie();
  return cookieObj[cname] || '';
}

//设置cookie
function setCookie(cname, value, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + value + '; ' + expires;
}

// 删除cookie
function clearCookie(cname) {
  setCookie(cname, '', -1);
}

export default {
  getCookie,
  getCookieByName,
  setCookie,
  clearCookie,
};
