<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <el-form
        class="filter-form"
        :model="form"
        label-width="100px"
        size="small"
      >
        <div class="flex-x-between">
          <div class="flex-shrink0 flex">
            <el-form-item label="协议客户">
              <el-input
                v-model="form.phoneOrClientName"
                placeholder="请输入客户名称"
                clearable
                @keyup.enter.native="doSearch"
              ></el-input>
            </el-form-item>
            <el-form-item label="协议编号">
              <el-input
                v-model="form.agreeNum"
                placeholder="请输入"
                clearable
                @keyup.enter.native="doSearch"
              ></el-input>
            </el-form-item>
            <el-form-item label="包干协议">
              <el-select
                v-model="form.beContract"
                placeholder="请选择"
                clearable
              >
                <el-option label="是" :value="1"> </el-option>
                <el-option label="否" :value="0"> </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="el-form-item--small">
            <div
              @click="showMore = !showMore"
              class="el-form-item__label pointer"
            >
              更多筛选
              <i
                :class="{
                  primary: showMore,
                  'el-icon-arrow-right': !showMore,
                  'el-icon-arrow-down': showMore,
                }"
              ></i>
            </div>
            <div class="flex">
              <el-button
                class="btn_search"
                type="primary"
                size="small"
                @click="doSearch"
                >查询</el-button
              >
              <el-button class="btn_search" size="small" @click="empty"
                >置空</el-button
              >
            </div>
          </div>
        </div>
        <template v-if="showMore">
          <div class="flex-warp">
            <el-form-item>
              <template slot="label">
                <div>
                  适用门店
                  <el-tooltip
                    effect="light"
                    content="客户协议的适用门店,可查当前登录门店和登录用户所属管辖组内其他门店"
                    placement="bottom-start"
                  >
                    <i
                      class="iconfont grow-icon_doubt"
                      style="color: #9a9a9a"
                    ></i>
                  </el-tooltip>
                </div>
              </template>
              <el-select
                v-model="form.enableStoreNameOrNum"
                filterable
                clearable
                remote
                placeholder="搜索车店名称、编号"
                :remote-method="searchStore"
                :loading="searchStoreLoading"
              >
                <el-option
                  v-for="item in storeList"
                  :key="item.companyId"
                  :label="item.companyName"
                  :value="item.companyId"
                >
                  <span style="float: left">{{ item.companyId }}</span>
                  <span style="float: right; color: #8492a6">{{
                    item.companyName
                  }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <template slot="label">
                <div>
                  签约门店
                  <el-tooltip
                    effect="light"
                    content="客户协议的签约门店,可查当前登录门店和登录用户所属管辖组内其他门店"
                    placement="bottom-start"
                  >
                    <i
                      class="iconfont grow-icon_doubt"
                      style="color: #9a9a9a"
                    ></i>
                  </el-tooltip>
                </div>
              </template>
              <el-select
                v-model="form.agreedStoreNameOrNum"
                filterable
                clearable
                remote
                placeholder="搜索车店名称、编号"
                :remote-method="searchStore"
                :loading="searchStoreLoading"
              >
                <el-option
                  v-for="item in storeList"
                  :key="item.companyId"
                  :label="item.companyName"
                  :value="item.companyId"
                >
                  <span style="float: left">{{ item.companyId }}</span>
                  <span style="float: right; color: #8492a6">{{
                    item.companyName
                  }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <template slot="label">
                <div>
                  开票主体
                  <el-tooltip
                    effect="light"
                    content="开票主体。即为协议客户、保险公司和三包厂家的应收账款的回款管理门店(保险公司的开票主体也是定损主体),客户协议的开票主体,可查已成为客户协议开票主体的全部门店"
                    placement="bottom-start"
                  >
                    <i
                      class="iconfont grow-icon_doubt"
                      style="color: #9a9a9a"
                    ></i>
                  </el-tooltip>
                </div>
              </template>
              <el-select
                v-model="form.invoiceStoreNameOrNum"
                filterable
                clearable
                remote
                placeholder="搜索开票主体名称"
                :remote-method="searchInvoice"
                :loading="searchInvoiceLoading"
              >
                <el-option
                  v-for="item in invoiceList"
                  :key="item.companyId"
                  :label="item.invoicingEntity"
                  :value="item.companyId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="生效日期">
              <el-date-picker
                v-model="form.start"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="截止时间"
                class="w100"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="到期日期">
              <el-date-picker
                v-model="form.end"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="截止时间"
                class="w100"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </div>
        </template>
      </el-form>
    </template>

    <template>
      <div style="margin-bottom: 14px" class="btns flex-x-between">
        <el-button v-auth="475" size="small" class="btn_insert" @click="add"
          >新增协议</el-button
        >
        <el-button
          v-auth="478"
          size="small"
          type="text"
          class="blue"
          @click="showExportDialog = true"
          >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
        ></el-button>
      </div>

      <div class="flex" style="height: 100%; overflow: auto">
        <div style="flex: 1; overflow: auto">
          <el-table
            class="custom-table"
            :data="listData"
            border
            @row-dblclick="goDetail"
            v-fixedScrollBar="$refs.baseContainer"
          >
            <el-table-column type="index" label="序号" width="60">
            </el-table-column>
            <el-table-column label="操作" width="80">
              <template slot-scope="scope">
                <span
                  v-auth="476"
                  class="text_operation blue"
                  @click="edit(scope.row)"
                  v-if="['正常有效', '待生效'].includes(scope.row.status)"
                  >编辑</span
                >
              </template>
            </el-table-column>
            <el-table-column width="180" label="协议编号">
              <template slot-scope="scope">
                <span
                  class="text_operation blue"
                  @click="goDetail(scope.row)"
                  >{{ scope.row.contractNumber }}</span
                >
              </template>
            </el-table-column>
            <el-table-column width="100" label="包干协议">
              <template #default="{ row }">
                {{ row.beContract ? '是' : '否' }}
              </template>
            </el-table-column>
            <el-table-column width="180" label="协议状态">
              <template #default="{ row }">
                <p>{{ row.status }}</p>
                <p
                  v-if="
                    (Date.parse(row.endDate) - Date.now()) /
                      (1 * 24 * 60 * 60 * 1000) <=
                      30 &&
                    (row.status == '正常有效' || row.status == '待生效')
                  "
                  class="orange"
                >
                  即将过期
                </p>
              </template>
            </el-table-column>
            <el-table-column width="180" label="协议客户">
              <template slot-scope="scope">
                <span
                  class="text_operation blue"
                  @click="
                    goClientDetail($lodash.get(scope.row, 'agreeClient.id', ''))
                  "
                  >{{ $lodash.get(scope.row, 'agreeClient.name', '') }}</span
                >
              </template>
            </el-table-column>
            <el-table-column width="180" label="联系人/联系电话">
              <template slot-scope="scope">
                <div>{{ scope.row.contact }}</div>
                <div>{{ scope.row.phone }}</div>
              </template>
            </el-table-column>
            <el-table-column width="200" label="有效期限">
              <template slot-scope="scope">
                <span
                  >{{ scope.row.startDate | delhms }} 至
                  {{ scope.row.endDate | delhms }}</span
                >
              </template>
            </el-table-column>
            <el-table-column width="180" label="账期">
              <template #default="{ row }"
                >{{ row.paymentPeriod }}个自然月</template
              >
            </el-table-column>
            <el-table-column width="180" prop="invoiceCompanyName">
              <template slot="header">
                <div>
                  开票主体
                  <el-tooltip
                    effect="light"
                    content="开票主体，即为协议客户、保险公司和三包厂家的应收账款的回款管理门店(保险公司的开票主体也是定损主体)"
                    placement="top"
                  >
                    <i
                      class="iconfont grow-icon_doubt"
                      style="color: #9a9a9a"
                    ></i>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
            <el-table-column width="180" label="自费业务适用门店">
              <template slot-scope="scope">
                <el-popover
                  v-if="scope.row.hasSelfPaying"
                  placement="bottom"
                  width="450"
                  trigger="click"
                  @show="getStoreList(scope.row.id)"
                >
                  <p>
                    部分门店（{{ scope.row.selfPayingCount }}）,
                    <span>{{
                      scope.row.selfPayingSelfContained == 0
                        ? '不包含本店'
                        : '包含本店'
                    }}</span>
                  </p>
                  <el-table max-height="400px" :data="applyStores.selfPaying">
                    <el-table-column
                      type="index"
                      label="序号"
                      width="60"
                    ></el-table-column>
                    <el-table-column
                      width="120"
                      prop="companyId"
                      label="车店编号"
                    ></el-table-column>
                    <el-table-column
                      width="150"
                      prop="companyName"
                      label="车店名称"
                    ></el-table-column>
                    <el-table-column width="100" prop="type" label="连锁类型">
                      <template slot-scope="scope">{{
                        scope.row.chainCompanyType | dict('chainCompanyType')
                      }}</template>
                    </el-table-column>
                  </el-table>
                  <p class="pointer blue" slot="reference">
                    部分门店（{{ scope.row.selfPayingCount }}）,
                    <span>{{
                      scope.row.selfPayingSelfContained == 0
                        ? '不包含本店'
                        : '包含本店'
                    }}</span>
                  </p>
                </el-popover>
                <p v-else>全部门店</p>
              </template>
            </el-table-column>
            <el-table-column width="180" label="包干业务适用门店">
              <template slot-scope="scope">
                <el-popover
                  v-if="scope.row.hasContractFor"
                  placement="bottom"
                  width="450"
                  trigger="click"
                  @show="getStoreList(scope.row.id)"
                >
                  <p>
                    部分门店（{{ scope.row.contractForCount }}）,
                    <span>{{
                      scope.row.contractForSelfContained == 0
                        ? '不包含本店'
                        : '包含本店'
                    }}</span>
                  </p>
                  <el-table max-height="400px" :data="applyStores.contractFor">
                    <el-table-column
                      type="index"
                      label="序号"
                      width="60"
                    ></el-table-column>
                    <el-table-column
                      width="120"
                      prop="companyId"
                      label="车店编号"
                    ></el-table-column>
                    <el-table-column
                      width="150"
                      prop="companyName"
                      label="车店名称"
                    ></el-table-column>
                    <el-table-column width="100" prop="type" label="连锁类型">
                      <template slot-scope="scope">{{
                        scope.row.chainCompanyType | dict('chainCompanyType')
                      }}</template>
                    </el-table-column>
                  </el-table>
                  <p class="pointer blue" slot="reference">
                    部分门店（{{ scope.row.contractForCount }}）,
                    <span>{{
                      scope.row.contractForSelfContained == 0
                        ? '不包含本店'
                        : '包含本店'
                    }}</span>
                  </p>
                </el-popover>
                <p v-else>全部门店</p>
              </template>
            </el-table-column>
            <el-table-column width="180" label="理赔业务适用门店">
              <template slot-scope="scope">
                <el-popover
                  v-if="scope.row.hasClaimSettlement"
                  placement="bottom"
                  width="450"
                  trigger="click"
                  @show="getStoreList(scope.row.id)"
                >
                  <p>
                    部分门店（{{ scope.row.claimSettlementCount }}）,
                    <span>{{
                      scope.row.claimSettlementSelfContained == 0
                        ? '不包含本店'
                        : '包含本店'
                    }}</span>
                  </p>
                  <el-table
                    max-height="400px"
                    :data="applyStores.claimSettlement"
                  >
                    <el-table-column
                      type="index"
                      label="序号"
                      width="60"
                    ></el-table-column>
                    <el-table-column
                      width="120"
                      prop="companyId"
                      label="车店编号"
                    ></el-table-column>
                    <el-table-column
                      width="150"
                      prop="companyName"
                      label="车店名称"
                    ></el-table-column>
                    <el-table-column width="100" prop="type" label="连锁类型">
                      <template slot-scope="scope">{{
                        scope.row.chainCompanyType | dict('chainCompanyType')
                      }}</template>
                    </el-table-column>
                  </el-table>
                  <p class="pointer blue" slot="reference">
                    部分门店（{{ scope.row.claimSettlementCount }}）,
                    <span>{{
                      scope.row.claimSettlementSelfContained == 0
                        ? '不包含本店'
                        : '包含本店'
                    }}</span>
                  </p>
                </el-popover>
                <p v-else>全部门店</p>
              </template>
            </el-table-column>
            <el-table-column width="180" label="索赔业务适用门店">
              <template slot-scope="scope">
                <el-popover
                  v-if="scope.row.hasClaimForCompensation"
                  placement="bottom"
                  width="450"
                  trigger="click"
                  @show="getStoreList(scope.row.id)"
                >
                  <p>
                    部分门店（{{ scope.row.claimForCompensationCount }}）,
                    <span>{{
                      scope.row.claimForCompensationSelfContained == 0
                        ? '不包含本店'
                        : '包含本店'
                    }}</span>
                  </p>
                  <el-table
                    max-height="400px"
                    :data="applyStores.claimForCompensation"
                  >
                    <el-table-column
                      type="index"
                      label="序号"
                      width="60"
                    ></el-table-column>
                    <el-table-column
                      width="120"
                      prop="companyId"
                      label="车店编号"
                    ></el-table-column>
                    <el-table-column
                      width="150"
                      prop="companyName"
                      label="车店名称"
                    ></el-table-column>
                    <el-table-column width="100" prop="type" label="连锁类型">
                      <template slot-scope="scope">{{
                        scope.row.chainCompanyType | dict('chainCompanyType')
                      }}</template>
                    </el-table-column>
                  </el-table>
                  <p class="pointer blue" slot="reference">
                    部分门店（{{ scope.row.claimForCompensationCount }}）,
                    <span>{{
                      scope.row.claimForCompensationSelfContained == 0
                        ? '不包含本店'
                        : '包含本店'
                    }}</span>
                  </p>
                </el-popover>
                <p v-else>全部门店</p>
              </template>
            </el-table-column>
            <el-table-column
              width="180"
              label="备注"
              prop="remark"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
              width="180"
              label="签约门店"
              prop="signCompanyName"
            ></el-table-column>
            <el-table-column width="100" label="建档日期">
              <template #default="{ row }">
                {{ row.createTime.substring(0, 10) }}
              </template>
            </el-table-column>
            <el-table-column
              width="100"
              label="建档人"
              prop="creator"
            ></el-table-column>
            <el-table-column
              width="180"
              label="建档门店"
              prop="createCompanyName"
            ></el-table-column>
          </el-table>
        </div>
        <div class="class-box">
          <p class="title">状态与统计（数量）</p>
          <el-tree
            class="custom-tree"
            :data="treeData"
            ref="my-tree"
            default-expand-all
            :props="treeProp"
            highlight-current
            node-key="id"
            :current-node-key="nodeKey"
            @node-click="onNodeClick"
          >
            <template #default="{ data }">
              <div class="tree-node">
                <span>{{ data.name }}</span>
                <span>({{ data.count }})</span>
              </div>
            </template>
          </el-tree>
        </div>
      </div>
      <export-dialog
        :visibility.sync="showExportDialog"
        :total="total"
        :pageOption="pageOption"
        :filterForm="lastParams"
        :url="exportUrl"
      ></export-dialog>
    </template>

    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
  </base-container>
</template>

<script>
import pagination from '@/components/Pagination.vue';
import ExportDialog from '@/components/ExportDialog';

import {
  getAgreementClientList,
  getAgreementStatistic,
  getAgreementStoreList,
  exportAgreementListUrl,
  getAgreementStore,
} from '@/api/customer/agreement';
import { searchInvoice } from '@/api/storeManagement/index';
import { searchStore } from '@/api/customer/store';
import { downloadFile } from '@/utils/fileApi';
import { mapState, mapGetters, mapMutations } from 'vuex';
// 工具
import Big from 'big.js';
// 权限
import { verifyAuth } from '@/utils/permissions';
export default {
  name: 'agreementList',
  components: { pagination, ExportDialog },
  data() {
    return {
      gender: 0,
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      form: {
        phoneOrClientName: '',
      },
      // 展示更多筛选
      showMore: false,

      // 搜索门店
      storeList: [],
      searchStoreLoading: false,

      // 开票主体
      invoiceList: [],
      searchInvoiceLoading: false,

      // 协议列表数据
      listData: [],

      // 适用门店
      applyStores: {},

      treeData: [
        {
          id: 1,
          name: '全部',
          count: 0,
        },
        {
          id: 2,
          name: '待生效',
          count: 0,
        },
        {
          id: 3,
          name: '正常有效',
          count: 0,
          children: [
            {
              id: 31,
              name: '即将过期',
              count: 0,
            },
          ],
        },
        {
          id: 4,
          name: '已过期',
          count: 0,
        },
        {
          id: 5,
          name: '已作废',
          count: 0,
        },
      ],
      treeProp: {
        children: 'children',
        label: 'name',
      },

      showExportDialog: false,
      exportUrl: exportAgreementListUrl,

      nodeKey: 3,
      defaultKeys: [],

      init: false,

      lastParams: {},
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  watch: {
    nodeKey(id) {
      //设置选中的车单状态
      if (typeof id == 'number') {
        this.$refs['my-tree'].setCurrentKey(id);
      } else {
        this.$refs['my-tree'].setCurrentKey('');
      }
    },
  },
  activated() {
    // 从其他页面跳转过来时附带了查询条件
    if (Object.keys(this.$route.params).length) {
      let date = new Date();
      if (this.$route.params.nodeKey == 1) {
        this.empty();
        this.nodeKey = 31;
        this.pageOption.current = 1;
        this.onNodeClick({ id: 31 });
        // this.form.end = [
        //   date.Format('yyyy-MM-dd'),
        //   new Date(date.getTime() + 864e5 * 30).Format('yyyy-MM-dd'),
        // ];
        // this.form.beCancel = 0;
      } else if (this.$route.params.nodeKey == 2) {
        this.empty();
        this.nodeKey = 4;
        this.pageOption.current = 1;
        this.onNodeClick({ id: 4 });
        // this.form.beCancel = 0;
        // this.form.endTimeOver = new Date(date.getTime() - 864e5 * 1).Format(
        //   'yyyy-MM-dd  00:00:00'
        // );
      }
      //  else if (this.$route.params.nodeKey == 3) {
      //   this.empty();
      //   this.nodeKey = 4;
      //   this.defaultKeys = [4];
      //   this.form.phoneOrClientName = this.$route.params.form.phoneOrClientName;
      //   this.form.beCancel = 0;
      //   this.pageOption.current = 1;
      // }
      // this.getData();
    } else if (!this.init) {
      // this.getData();
      this.onNodeClick({ id: 3 });
    }
    this.init = true;
  },
  created() {
    this.searchStore();
    this.searchInvoice();
  },
  methods: {
    /**
     * 查询列表
     */
    doSearch() {
      this.pageOption.current = 1;
      // this.getData();
      //注释：2023/07/25修改把点击查询时跟状态联动，数据可能在选中生效日期时出错，暂时修改，不符合再去掉以下代码
      let parameter;
      let date = new Date();
      switch (this.nodeKey) {
        // 全部
        case 1:
          parameter = false;
          break;
        case 2:
          parameter = {
            // 明天
            startBegin: new Date(date.setHours(0, 0, 0, 0) + 864e5).Format(
              'yyyy-MM-dd hh:mm:ss'
            ),
            beCancel: 0,
          };
          break;
        case 3:
          parameter = {
            startOver: date.Format('yyyy-MM-dd hh:mm:ss'),
            endBegin: date.Format('yyyy-MM-dd hh:mm:ss'),
            beCancel: 0,
          };
          break;
        case 31:
          parameter = {
            endBegin: date.Format('yyyy-MM-dd hh:mm:ss'),
            endOver: new Date(date.getTime() + 864e5 * 30).Format(
              'yyyy-MM-dd hh:mm:ss'
            ),
            beCancel: 0,
          };
          break;
        case 4:
          parameter = {
            endOver: date.Format('yyyy-MM-dd hh:mm:ss'),
            beCancel: 0,
          };
          break;
        case 5:
          parameter = {
            beCancel: 1,
          };
          break;
      }
      this.getData(parameter);
    },
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getData(this.lastParams);
    },
    /**
     * 点击节点时
     */
    onNodeClick(node) {
      this.nodeKey = node.id;
      this.defaultKeys = [node.id];
      console.log(node);
      let params;
      let date = new Date();
      switch (node.id) {
        // 全部
        case 1:
          params = false;
          break;
        case 2:
          params = {
            // 明天
            startBegin: new Date(date.setHours(0, 0, 0, 0) + 864e5).Format(
              'yyyy-MM-dd hh:mm:ss'
            ),
            beCancel: 0,
          };
          break;
        case 3:
          params = {
            startOver: date.Format('yyyy-MM-dd hh:mm:ss'),
            endBegin: date.Format('yyyy-MM-dd hh:mm:ss'),
            beCancel: 0,
          };
          break;
        case 31:
          params = {
            endBegin: date.Format('yyyy-MM-dd hh:mm:ss'),
            endOver: new Date(date.getTime() + 864e5 * 30).Format(
              'yyyy-MM-dd hh:mm:ss'
            ),
            beCancel: 0,
          };
          break;
        case 4:
          params = {
            endOver: date.Format('yyyy-MM-dd hh:mm:ss'),
            beCancel: 0,
          };
          break;
        case 5:
          params = {
            beCancel: 1,
          };
          break;
      }
      this.getData(params);
    },
    /**
     * 加载数据
     * @param params 右侧状态条件
     */
    getData(params) {
      let data = {};
      // if (params) {
      //   this.form.startTimeBegin = '';
      //   this.form.startTimeOver = '';
      //   this.form.endTimeBegin = '';
      //   this.form.endTimeOver = '';
      //   this.$set(this.form, 'start', []);
      //   this.$set(this.form, 'end', []);
      // } else {
      if (this.form.start?.length) {
        data.startTimeBegin = this.form.start[0] + ' 00:00:00';
        data.startTimeOver = this.form.start[1] + ' 23:59:59';
      }
      if (this.form.end?.length) {
        data.endTimeBegin = this.form.end[0] + ' 00:00:00';
        data.endTimeOver = this.form.end[1] + ' 23:59:59';
      }
      // }
      data = Object.assign({}, data, this.form, params, this.pageOption);
      this.lastParams = data;

      getAgreementClientList(data).then((res) => {
        console.log(res);
        // 修改总条数
        this.total = res.total;
        // 赋值records
        this.listData = res.records;
      });
      // if (params) return;
      getAgreementStatistic(data).then((res) => {
        console.log(res);
        this.treeData[0].count = res.allCount;
        this.treeData[1].count = res.toBeCurrCount;
        this.treeData[2].count = res.currCount;
        this.treeData[2].children[0].count = res.toBeDueCount;
        this.treeData[3].count = res.hasDueCount;
        this.treeData[4].count = res.cancelCount;
      });
    },
    /**
     * 远程搜索门店列表
     */
    storeKeyWordChange(keyWord) {
      if (keyWord) {
        this.searchStore(keyWord);
      }
    },
    searchStore(keyWord = '') {
      this.searchStoreLoading = true;
      searchStore(keyWord, this.companyId)
        .then((res) => {
          this.storeList = res;
        })
        .finally(() => {
          this.searchStoreLoading = false;
        });
    },
    /**
     * 远程搜索开票主体列表
     */
    searchInvoice(keyWord = '') {
      this.searchInvoiceLoading = true;
      searchInvoice(keyWord, this.companyId)
        .then((res) => {
          this.invoiceList = res;
        })
        .finally(() => {
          this.searchInvoiceLoading = false;
        });
    },

    /**
     * 跳转详情页
     */
    goDetail(row) {
      this.$router.push({
        name: 'agreementDetail',
        // path?: string
        query: { id: row.id },
      });
    },
    /**
     * 跳转新增页
     */
    add() {
      this.$router.push({
        name: 'addAgreement',
      });
    },
    /**
     * 点击编辑跳到新增协议页面
     */
    edit(row) {
      this.$router.push({
        name: 'addAgreement',
        query: { id: row.id },
      });
    },
    /**
     * 列表中适用门店数据请求
     */
    getStoreList(id) {
      this.applyStores = '';
      getAgreementStore(id).then((res) => {
        this.applyStores = res;
        console.log('this.applyStores', this.applyStores);
      });
    },
    /**
     * 置空输入框数据
     */
    empty() {
      this.form = {};
    },
    /**
     * 跳转客户详情
     */
    async goClientDetail(id) {
      await verifyAuth(107);
      this.$router.push({
        name: 'customerDetails',
        query: {
          id,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.class-box {
  width: 180px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  box-sizing: border-box;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  .el-tree {
    overflow: auto;
  }
}
.class-box .title {
  flex-shrink: 0;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #e4e4e4;
  background: #fafafa;
  color: #888;
  font-size: 14px;
}
.tree-node {
  color: #888;
  font-size: 14px;
}
</style>
