var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { ref: "baseContainer", attrs: { paddingTop: "" } },
    [
      [
        _c(
          "div",
          [
            _c(
              "el-form",
              {
                staticClass: "filter-form",
                attrs: {
                  model: _vm.form,
                  "label-width": "100px",
                  size: "small",
                },
              },
              [
                _c("div", { staticClass: "flex-x-between" }, [
                  _c(
                    "div",
                    { staticClass: "flex-warp flex-shrink0" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "车牌号" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入", clearable: "" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.doSearch.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.form.carNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "carNumber", $$v)
                              },
                              expression: "form.carNumber",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c("template", { slot: "label" }, [
                            _c(
                              "div",
                              [
                                _vm._v(" 操作门店 "),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "light",
                                      content:
                                        "接车单的操作门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)",
                                      placement: "bottom-start",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "iconfont grow-icon_doubt",
                                      staticStyle: { color: "#9a9a9a" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("operateCompanyCascader", {
                            attrs: {
                              operateCompanyUrl: _vm.form.operateCompanyUrl,
                            },
                            model: {
                              value: _vm.form.operateCompanyId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "operateCompanyId", $$v)
                              },
                              expression: "form.operateCompanyId",
                            },
                          }),
                        ],
                        2
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "接车进店日期" } },
                        [
                          _c("el-date-picker", {
                            staticClass: "w100",
                            attrs: {
                              type: "daterange",
                              "unlink-panels": "",
                              "range-separator": "至",
                              "start-placeholder": "起始时间",
                              "end-placeholder": "截止时间",
                              "value-format": "yyyy-MM-dd",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.dateRangeChange()
                              },
                            },
                            model: {
                              value: _vm.form.intoStoreTimes,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "intoStoreTimes", $$v)
                              },
                              expression: "form.intoStoreTimes",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "flex" }, [
                    _c("div", { staticClass: "el-form-item--small" }, [
                      _c(
                        "div",
                        {
                          staticClass: "el-form-item__label pointer",
                          on: {
                            click: function ($event) {
                              _vm.showMore = !_vm.showMore
                            },
                          },
                        },
                        [
                          _vm._v(" 更多筛选 "),
                          _c("i", {
                            class: {
                              primary: true,
                              "el-icon-arrow-right": !_vm.showMore,
                              "el-icon-arrow-down": _vm.showMore,
                            },
                          }),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "btn_search",
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.doSearch },
                            },
                            [_vm._v("查询")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "btn_search",
                              attrs: { size: "small" },
                              on: { click: _vm.empty },
                            },
                            [_vm._v("置空")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "flex-warp" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "接车员" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              filterable: "",
                              clearable: "",
                              remote: "",
                              placeholder: "搜索姓名、手机号",
                              "remote-method": _vm.searchAdminJobListUser,
                              loading: _vm.searchAdminJobListUserLoading,
                            },
                            on: { change: _vm.selectAdminJobListUser },
                            model: {
                              value: _vm.form.pickUpUserId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "pickUpUserId", $$v)
                              },
                              expression: "form.pickUpUserId",
                            },
                          },
                          _vm._l(_vm.adminJobListUserList, function (item) {
                            return _c(
                              "el-option",
                              {
                                key: item.userId,
                                attrs: {
                                  label: item.realName,
                                  value: item.userId,
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      float: "left",
                                      color: "#8492a6",
                                      "font-size": "13px",
                                    },
                                  },
                                  [_vm._v(_vm._s(item.realName))]
                                ),
                                _c(
                                  "span",
                                  { staticStyle: { float: "right" } },
                                  [_vm._v(_vm._s(item.phone))]
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "签约客户" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              filterable: "",
                              clearable: "",
                              remote: "",
                              placeholder: "请输入客户名称",
                              "remote-method": _vm.searchClient,
                              loading: _vm.searchClientLoading,
                            },
                            on: { change: _vm.selectClient },
                            model: {
                              value: _vm.form.clientId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "clientId", $$v)
                              },
                              expression: "form.clientId",
                            },
                          },
                          _vm._l(_vm.customList, function (item) {
                            return _c(
                              "el-option",
                              {
                                key: item.id,
                                attrs: {
                                  label: item.clientName,
                                  value: item.id,
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      float: "left",
                                      color: "#8492a6",
                                      "font-size": "13px",
                                    },
                                  },
                                  [_vm._v(_vm._s(item.clientName))]
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "业务类型" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              multiple: "",
                              placeholder: "全部 支持多选",
                            },
                            model: {
                              value: _vm.form.billType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "billType", $$v)
                              },
                              expression: "form.billType",
                            },
                          },
                          _vm._l(_vm.billType, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "车单状态" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择" },
                            model: {
                              value: _vm.form.billState,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "billState", $$v)
                              },
                              expression: "form.billState",
                            },
                          },
                          _vm._l(_vm.billStateList, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.showMore
                      ? [
                          _c(
                            "el-form-item",
                            { attrs: { label: "事故责任" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    multiple: "",
                                    placeholder: "搜索事故责任",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.dutyType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "dutyType", $$v)
                                    },
                                    expression: "form.dutyType",
                                  },
                                },
                                _vm._l(_vm.dutyType, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "施工技师" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    placeholder: "搜索姓名、手机号",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.technicianId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "technicianId", $$v)
                                    },
                                    expression: "form.technicianId",
                                  },
                                },
                                _vm._l(_vm.techniciansList, function (item) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: item.userId,
                                      attrs: {
                                        label: item.realName,
                                        value: item.userId,
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            float: "left",
                                            color: "#8492a6",
                                            "font-size": "13px",
                                          },
                                        },
                                        [_vm._v(_vm._s(item.realName))]
                                      ),
                                      _c(
                                        "span",
                                        { staticStyle: { float: "right" } },
                                        [_vm._v(_vm._s(item.phone))]
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            ),
          ],
          1
        ),
      ],
      [
        _c(
          "div",
          { staticStyle: { "margin-bottom": "15px", "font-size": "14px" } },
          [
            _c("i", {
              staticClass: "pointer iconfont grow-icon_warn_32",
              staticStyle: {
                color: "#ff9200",
                "font-size": "15px",
                margin: "5px 10px",
              },
            }),
            _c("span", { staticStyle: { color: "#909399" } }, [
              _vm._v(
                "今日在场只统计【服务中】【挂单中未提车】【待结账未提车】【已结账未提车】的车辆"
              ),
            ]),
          ]
        ),
        _c("div", { staticClass: "btns flex-x-between" }, [
          _c("div", [
            _c("div", { staticClass: "custom-tabs nopadding" }, [
              _c(
                "div",
                {
                  class: {
                    "custom-tab-item": true,
                    active: _vm.status == -1,
                  },
                  on: {
                    click: function ($event) {
                      _vm.status = -1
                    },
                  },
                },
                [
                  _c("p", [
                    _vm._v(
                      "全部(" +
                        _vm._s(_vm.carBillPaidStatistic.carBillNum) +
                        ")"
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  class: {
                    "custom-tab-item": true,
                    active: _vm.status == 0,
                  },
                  on: {
                    click: function ($event) {
                      _vm.status = 0
                    },
                  },
                },
                [
                  _c("p", [
                    _vm._v(
                      "自费(" +
                        _vm._s(_vm.carBillPaidStatistic.selfPayBillNum) +
                        ")"
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  class: {
                    "custom-tab-item": true,
                    active: _vm.status == 1,
                  },
                  on: {
                    click: function ($event) {
                      _vm.status = 1
                    },
                  },
                },
                [
                  _c("p", [
                    _vm._v(
                      "包干(" +
                        _vm._s(_vm.carBillPaidStatistic.contractForBillNum) +
                        ")"
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  class: {
                    "custom-tab-item": true,
                    active: _vm.status == 2,
                  },
                  on: {
                    click: function ($event) {
                      _vm.status = 2
                    },
                  },
                },
                [
                  _c("p", [
                    _vm._v(
                      "理赔(" +
                        _vm._s(
                          _vm.carBillPaidStatistic.claimSettlementBillNum
                        ) +
                        ")"
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  class: {
                    "custom-tab-item": true,
                    active: _vm.status == 3,
                  },
                  on: {
                    click: function ($event) {
                      _vm.status = 3
                    },
                  },
                },
                [
                  _c("p", [
                    _vm._v(
                      " 索赔(" +
                        _vm._s(
                          _vm.carBillPaidStatistic.claimForCompensationBillNum
                        ) +
                        ") "
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "auth",
                      rawName: "v-auth",
                      value: 449,
                      expression: "449",
                    },
                  ],
                  staticClass: "blue",
                  attrs: { size: "small", type: "text" },
                  on: {
                    click: function ($event) {
                      _vm.showExportDialog = true
                    },
                  },
                },
                [
                  _vm._v("导出列表"),
                  _c("i", {
                    staticClass: "iconfont grow-icon_daochu el-icon--right",
                  }),
                ]
              ),
            ],
            1
          ),
        ]),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "fixedScrollBar",
                rawName: "v-fixedScrollBar",
                value: _vm.$refs.baseContainer,
                expression: "$refs.baseContainer",
              },
            ],
            staticClass: "custom-table",
            attrs: { data: _vm.carBillList, border: "" },
            on: {
              "row-dblclick": function ($event) {
                return _vm.goDetail($event.billId)
              },
            },
          },
          [
            _c("el-table-column", {
              attrs: { type: "index", label: "序号", width: "60" },
            }),
            _c("el-table-column", {
              attrs: { width: "150", label: "操作门店", prop: "companyName" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c(
                        "el-tooltip",
                        { attrs: { content: row.companyName } },
                        [
                          _c("div", { staticClass: "text-ellipsis-2-row" }, [
                            _vm._v(" " + _vm._s(row.companyName) + " "),
                          ]),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "操作", prop: "carNumber" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "auth",
                              rawName: "v-auth",
                              value: 451,
                              expression: "451",
                            },
                          ],
                          staticClass: "detail-btn flex-center blue pointer",
                          on: {
                            click: function ($event) {
                              return _vm.handleEdit(scope.row)
                            },
                          },
                        },
                        [_vm._v(" 滞留说明 ")]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "140", label: "车牌号", prop: "carNumber" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _vm._v(" " + _vm._s(row.carNumber) + " "),
                      row.manyBill == 1
                        ? _c(
                            "span",
                            [
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    effect: "light",
                                    content: "该车辆存在多张未完成单据",
                                    placement: "bottom-start",
                                  },
                                },
                                [
                                  _c("img", {
                                    staticClass: "primary",
                                    staticStyle: { "vertical-align": "middle" },
                                    attrs: { src: require("@/assets/duo.png") },
                                  }),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "170", label: "车单号/业务类型" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "blue pointer",
                          on: {
                            click: function ($event) {
                              return _vm.goDetail(row.billId)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(row.billCode) + " ")]
                      ),
                      row.billType !== 0
                        ? _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("dict")(row.billType, "carBillType")
                                ) +
                                " "
                            ),
                          ])
                        : _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  row.isOthersSelfPayState == 0
                                    ? "自费"
                                    : "他人自费"
                                ) +
                                " "
                            ),
                          ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                width: "180",
                label: "车型/车架号",
                "show-overflow-tooltip": "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      row.carModelName
                        ? _c("div", { staticClass: "text-ellipsis" }, [
                            _vm._v(" " + _vm._s(row.carModelName) + " "),
                          ])
                        : _vm._e(),
                      row.uniqueId
                        ? _c("div", { staticClass: "text-ellipsis" }, [
                            _vm._v(" " + _vm._s(row.uniqueId) + " "),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                width: "120",
                label: "签约客户",
                "show-overflow-tooltip": "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      row.ownerName
                        ? _c("div", [
                            _c("div", { staticClass: "text-ellipsis" }, [
                              _vm._v(_vm._s(row.ownerName)),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "进店时间", prop: "intoStoreTime" },
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "预交车时间" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      row.undetermined
                        ? _c("div", [_vm._v("待定")])
                        : _c("div", [
                            _c("p", [_vm._v(_vm._s(row.planFinishTime))]),
                            _c("p", [
                              _vm._v(_vm._s(row.waitInStore ? "在店等" : "")),
                            ]),
                          ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "140", label: "车单状态" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("div", [
                        _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("dict")(row.state, "carBillState")
                              ) +
                              " "
                          ),
                          row.billOtherState
                            ? _c("span", { staticClass: "red" }, [
                                _vm._v("(" + _vm._s(row.billOtherState) + ")"),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "flex color626",
                          staticStyle: { "font-size": "12px" },
                        },
                        [
                          row.dispatchState !== undefined
                            ? _c("div", [
                                _c("span", [_vm._v(" /")]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("dict")(
                                        row.dispatchState,
                                        "dispatchState"
                                      )
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "接车员", prop: "pickUpCarName" },
            }),
            _c("el-table-column", {
              attrs: { width: "130", prop: "paid" },
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c("el-tooltip", { attrs: { content: "单据金额" } }, [
                            _c("span", { staticClass: "text-ellipsis" }, [
                              _vm._v("单据金额"),
                            ]),
                          ]),
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                effect: "light",
                                placement: "bottom-start",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    color: "#666666",
                                    "font-size": "13px",
                                    "line-height": "16px",
                                    "text-indent": "15px",
                                  },
                                  attrs: { slot: "content" },
                                  slot: "content",
                                },
                                [
                                  _vm._v(
                                    " 包干业务车单的包干金额,结账当月显示为，查询时"
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "点的预分摊金额,结账次月显示为实际分摊金额 "
                                  ),
                                ]
                              ),
                              _c("i", {
                                staticClass: "iconfont grow-icon_doubt",
                                staticStyle: { "margin-left": "5px" },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      row.billType == 1 && row.state === 1
                        ? _c("span", [
                            !row.takeTime
                              ? _c("div", { staticClass: "color666" }, [
                                  _c("p", [_vm._v(_vm._s(row.paid))]),
                                  _c("div", [
                                    _vm._v(" 包干: "),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "auth",
                                            rawName: "v-auth",
                                            value: {
                                              code: 473,
                                              handle: "dataMarking",
                                            },
                                            expression:
                                              "{ code: 473, handle: 'dataMarking' }",
                                          },
                                        ],
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("toThousands")(
                                              row.contractAmount
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                ])
                              : _c("div", [
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "auth",
                                          rawName: "v-auth",
                                          value: {
                                            code: 473,
                                            handle: "dataMarking",
                                          },
                                          expression:
                                            "{ code: 473, handle: 'dataMarking' }",
                                        },
                                      ],
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("toThousands")(
                                            row.contractAmount
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                          ])
                        : _c("div", [
                            _vm._v(_vm._s(_vm._f("toThousands")(row.paid))),
                          ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "进店里程", prop: "mileage" },
            }),
            _c("el-table-column", {
              attrs: {
                width: "100",
                label: "初诊与备注",
                prop: "firstVisitRemark",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c(
                        "el-tooltip",
                        { attrs: { content: row.firstVisitRemark } },
                        [
                          _c("div", { staticClass: "text-ellipsis-2-row" }, [
                            _vm._v(" " + _vm._s(row.firstVisitRemark) + " "),
                          ]),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                width: "100",
                label: "施工技师",
                prop: "technicianNames",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c(
                        "el-tooltip",
                        { attrs: { content: row.technicianNames } },
                        [
                          _c("div", { staticClass: "text-ellipsis-2-row" }, [
                            _vm._v(" " + _vm._s(row.technicianNames) + " "),
                          ]),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                width: "150",
                label: "在店时长",
                prop: "billInStoreTime",
              },
            }),
            _c("el-table-column", {
              attrs: {
                "min-width": "120",
                label: "滞留|中断原因",
                prop: "strandedReason",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c(
                        "el-tooltip",
                        { attrs: { content: row.strandedReason } },
                        [
                          _c("div", { staticClass: "text-ellipsis-2-row" }, [
                            _vm._v(" " + _vm._s(row.strandedReason) + " "),
                          ]),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c(
              "el-table-column",
              {
                attrs: { width: "120", prop: "planCompletionTime" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("div", [_vm._v(_vm._s(row.planCompletionTime))]),
                      ]
                    },
                  },
                ]),
              },
              [
                _c("template", { slot: "header" }, [
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c("el-tooltip", { attrs: { content: "卡点解决时间" } }, [
                        _c("span", { staticClass: "text-ellipsis" }, [
                          _vm._v("卡点解决时间"),
                        ]),
                      ]),
                      _c(
                        "el-tooltip",
                        {
                          attrs: { effect: "light", placement: "bottom-start" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                color: "#666666",
                                "font-size": "13px",
                                "line-height": "16px",
                                "text-indent": "15px",
                              },
                              attrs: { slot: "content" },
                              slot: "content",
                            },
                            [_vm._v(" 当前阶段预计完成时间 ")]
                          ),
                          _c("i", {
                            staticClass: "iconfont grow-icon_doubt",
                            staticStyle: { "margin-left": "5px" },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
            _c("el-table-column", {
              attrs: {
                width: "100",
                label: "滞留报告人",
                prop: "reporterName",
              },
            }),
            _c(
              "el-table-column",
              { attrs: { width: "100", prop: "lastUpdated" } },
              [
                _c(
                  "template",
                  { slot: "header" },
                  [
                    _c("el-tooltip", { attrs: { content: "滞留更新时间" } }, [
                      _c("span", { staticClass: "text-ellipsis" }, [
                        _vm._v("滞留更新时间"),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
        _c("export-dialog", {
          attrs: {
            visibility: _vm.showExportDialog,
            total: _vm.total,
            pageOption: _vm.pageOption,
            filterForm: _vm.form,
            url: _vm.exportUrl,
          },
          on: {
            "update:visibility": function ($event) {
              _vm.showExportDialog = $event
            },
          },
        }),
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c("pagination", {
            attrs: { total: _vm.total },
            on: { pageChange: _vm.changePage },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "滞留说明",
            visible: _vm.dialogVisible,
            width: "200",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "tarry-dialog flex" },
            [
              _c(
                "span",
                { staticClass: "red", staticStyle: { "margin-top": "10px" } },
                [_vm._v("*")]
              ),
              _c("span", { staticClass: "title" }, [_vm._v("滞留原因")]),
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 8,
                  placeholder: "请填写车辆滞留在场原因或维修中断原因（必填）",
                  maxlength: "50",
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.editInfo.strandedReason,
                  callback: function ($$v) {
                    _vm.$set(_vm.editInfo, "strandedReason", $$v)
                  },
                  expression: "editInfo.strandedReason",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "tarry-dialog" }, [
            _c(
              "div",
              { staticClass: "flex" },
              [
                _c("p", { staticClass: "title" }, [
                  _c(
                    "span",
                    {
                      staticClass: "red",
                      staticStyle: { "margin-top": "10px" },
                    },
                    [_vm._v("*")]
                  ),
                  _vm._v("卡点解决时间 "),
                ]),
                _c("el-date-picker", {
                  attrs: {
                    type: "date",
                    placeholder: "请选择日期",
                    size: "small",
                    "value-format": "yyyy-MM-dd",
                  },
                  model: {
                    value: _vm.editInfo.planCompletionTime,
                    callback: function ($$v) {
                      _vm.$set(_vm.editInfo, "planCompletionTime", $$v)
                    },
                    expression: "editInfo.planCompletionTime",
                  },
                }),
              ],
              1
            ),
            _c(
              "p",
              {
                staticStyle: {
                  color: "#84878c",
                  "margin-left": "98px",
                  "margin-top": "10px",
                },
              },
              [_vm._v(" 设置当前阶段预计完成时间 ")]
            ),
          ]),
          _c(
            "div",
            { staticClass: "tarry-dialog flex" },
            [
              _c("p", { staticClass: "title" }, [
                _c(
                  "span",
                  { staticClass: "red", staticStyle: { "margin-top": "10px" } },
                  [_vm._v("*")]
                ),
                _vm._v("预交车时间 "),
              ]),
              _c("el-date-picker", {
                attrs: {
                  type: "datetime",
                  placeholder: _vm.finishPlaceholder,
                  size: "small",
                  disabled: _vm.planFinishDisabled,
                },
                on: { focus: _vm.changes },
                model: {
                  value: _vm.editInfo.planFinishTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.editInfo, "planFinishTime", $$v)
                  },
                  expression: "editInfo.planFinishTime",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.editDetention },
                },
                [_vm._v(" 确 定 ")]
              ),
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.cancel } },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }