<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <el-form
        class="filter-form"
        :model="form"
        label-width="120px"
        size="small"
      >
        <div class="flex-x-between">
          <div class="flex flex-shrink0">
            <el-form-item label="实收主体" prop="invoicingEntity">
              <el-select
                v-model="form.invoicingEntity"
                clearable
                filterable
                placeholder="请选择实收主体"
              >
                <el-option
                  v-for="item in invoicingEntities"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="回款保险公司" prop="insuranceCompanyId">
              <el-select
                v-model="form.insuranceCompanyId"
                clearable
                filterable
                placeholder="请选择回款保险公司"
              >
                <el-option
                  v-for="item in insuranceCompanyList"
                  :key="item.id"
                  :label="item.insuranceName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="车单号">
              <el-input
                v-model="form.billCode"
                placeholder="请输入车单号"
                @input="dateRangeChange()"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
          </div>
          <div class="el-form-item--small">
            <div
              @click="showMore = !showMore"
              class="el-form-item__label pointer"
            >
              更多筛选
              <i
                :class="{
                  primary: showMore,
                  'el-icon-arrow-right': !showMore,
                  'el-icon-arrow-down': showMore,
                }"
              ></i>
            </div>
            <div class="flex">
              <el-button
                v-auth="683"
                class="btn_search"
                type="primary"
                size="small"
                @click="getData"
                >查询</el-button
              >
              <el-button class="btn_search" size="small" @click="empty"
                >置空</el-button
              >
            </div>
          </div>
        </div>
        <div class="flex-warp" v-show="showMore">
          <template>
            <el-form-item label="业务来源" prop="billSourceTypeId">
              <selectBillSourcePopover
                v-model="form.billSourceTypeId"
                :searchKeyWord="form.billSourceTypeName"
                :isCarBill="true"
              ></selectBillSourcePopover>
            </el-form-item>
            <el-form-item label="回款核销时间">
              <el-date-picker
                class="w100"
                v-model="form.paymentDateTimes"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="截止时间"
                value-format="yyyy-MM-dd"
                @input="dateRangeChange()"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="认领时间">
              <el-date-picker
                class="w100"
                v-model="form.claimDateTimes"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="截止时间"
                value-format="yyyy-MM-dd"
                @input="dateRangeChange()"
              >
              </el-date-picker>
            </el-form-item>
          </template>
        </div>
      </el-form>
    </template>

    <template>
      <div style="margin: 30px 0 14px 0" class="btns flex-x-between">
        <div></div>
        <div>
          <el-button
            v-auth="684"
            size="small"
            type="text"
            class="blue"
            @click="downloadAllRecords()"
            >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
          ></el-button>
        </div>
      </div>

      <el-table
        class="custom-table"
        :data="listData"
        border
        v-fixedScrollBar="$refs.baseContainer"
      >
        <el-table-column type="index" label="序号" width="60">
        </el-table-column>
        <el-table-column
          width="200"
          label="回款核销日期"
          prop="actualPaymentDateTime"
        >
        </el-table-column>
        <el-table-column width="260" label="实收主体" prop="invoicingEntity">
        </el-table-column>
        <el-table-column
          width="260"
          label="回款保险公司"
          prop="insuranceCompanyName"
        >
        </el-table-column>
        <el-table-column width="260" label="实收门店" prop="billCompanyName">
        </el-table-column>
        <el-table-column width="200" label="车单号" prop="billCode">
          <template #default="{ row }">
            <div class="blue pointer" @click="goBillDetail(row.billId)">
              {{ row.billCode }}
            </div>
          </template>
        </el-table-column>
        <el-table-column width="140" label="车牌" prop="carNumber">
        </el-table-column>
        <el-table-column width="140" label="业务来源" prop="billSourceName">
        </el-table-column>
        <el-table-column width="140" label="单据类型" prop="billType">
        </el-table-column>
        <el-table-column width="140" label="金额" prop="amount">
        </el-table-column>
        <el-table-column width="140" label="手续费" prop="charges">
        </el-table-column>
        <el-table-column width="140" label="实际金额" prop="actualAmount">
        </el-table-column>
        <el-table-column
          width="200"
          label="内部流程单号"
          prop="internalSettTrackingSeq"
        >
        </el-table-column>
        <el-table-column
          width="300"
          label="付款日期/付款主体"
          prop="paymentRemark"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column width="200" label="实际认领日期" prop="claimDateTime">
        </el-table-column>
      </el-table>
    </template>

    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
  </base-container>
</template>

<script>
// 组件
import pagination from '@/components/Pagination.vue';
import selectBillSourcePopover from '@/components/businessComponent/selectBillSourcePopover.vue';
// 接口
import { downloadFile } from '@/utils/fileApi';
import {
  transactionClaimWriteOffListDetailList,
  transactionClaimWriteOffListDetailListExportUrl,
  getPreLoadSaveOrUpdateDropListData,
} from '@/api/financial/financialOver';
import { hasAuth } from '@/utils/permissions';

import { mapState } from 'vuex';

export default {
  name: 'transaction_submitwork_detail',
  components: {
    pagination,
    selectBillSourcePopover,
  },
  data() {
    return {
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      startPage: 1,
      endPage: 1,
      exportUrl: transactionClaimWriteOffListDetailListExportUrl,
      form: {
        billCode: '',
      },
      // 展示更多筛选
      showMore: true,
      listData: [],
      insuranceCompanyList: [],
      invoicingEntities: [],
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.user,
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  activated() {},
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getInsuranceCompanyListAndInvoicingEntities();
      this.getData(false);
    },
    /**
     * 查询体系内的门店列表
     */
    getInsuranceCompanyListAndInvoicingEntities() {
      const _this = this;
      getPreLoadSaveOrUpdateDropListData().then((res) => {
        const { insuranceCompanyList, invoicingEntities } = res;
        _this.insuranceCompanyList = insuranceCompanyList;
        _this.invoicingEntities = invoicingEntities;
      });
    },
    //下面是解决设置日期默认值时重新选择无法刷新日期的方法
    dateRangeChange() {
      let _this = this;
      _this.$nextTick(() => {
        _this.$forceUpdate();
      });
    },
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getData(false);
    },
    /**
     * 加载数据
     */
    getData(reset = true) {
      var res = hasAuth(683);
      if (!res) {
        return;
      }
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      const data = this.buildRequestBody();
      transactionClaimWriteOffListDetailList(data).then((res) => {
        // 修改总条数
        this.total = res.total;
        // 赋值records
        this.listData = res.records;
      });
    },
    buildRequestBody() {
      var times = {
        claimBeginDateTime: this.form.claimDateTimes?.[0]
          ? this.form.claimDateTimes?.[0] + ' 00:00:00'
          : '',
        claimEndDateTime: this.form.claimDateTimes?.[1]
          ? this.form.claimDateTimes?.[1] + ' 23:59:59'
          : '',
        paymentBeginDateTime: this.form.paymentDateTimes?.[0]
          ? this.form.paymentDateTimes?.[0] + ' 00:00:00'
          : '',
        paymentEndDateTime: this.form.paymentDateTimes?.[1]
          ? this.form.paymentDateTimes?.[1] + ' 23:59:59'
          : '',
      };
      var data = Object.assign({}, this.form, this.pageOption, times);
      delete data.claimDateTimes;
      return data;
    },
    /**
     * 跳转到车单详情页面
     * @param billId 车单ID
     */
    goBillDetail(billId) {
      this.$router.push({
        name: 'carBillDetail',
        query: {
          id: billId,
          t: new Date().getTime(),
        },
      });
    },
    downloadAllRecords() {
      const dataBody = this.buildRequestBody();
      downloadFile({
        url: this.exportUrl,
        data: dataBody,
      });
    },
    /**
     * 置空输入框数据
     */
    empty() {
      this.form = {
        billCode: '',
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.el-form {
  width: 100%;
}
</style>
