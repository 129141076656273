var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "homePage_container" },
    [
      _c(
        "section",
        { staticClass: "homePage-section_container" },
        [
          _c("new-sidebar", {
            attrs: { num: _vm.num },
            on: { permissionChangeCount: _vm.permissionChangeCount },
          }),
          _vm.showNotificationDialog
            ? _c("NotificationBox", {
                attrs: {
                  visibility: _vm.showNotificationDialog,
                  newTodoList: _vm.newTodoList,
                  flag: _vm.flag,
                },
                on: {
                  "update:visibility": function ($event) {
                    _vm.showNotificationDialog = $event
                  },
                  goDetail: _vm.goDetail,
                },
              })
            : _vm._e(),
          _c(
            "main",
            { staticClass: "homePage-main" },
            [_c("home-tags"), _c("main-view")],
            1
          ),
        ],
        1
      ),
      _vm.lossRecordDrawer
        ? _c("car-loss-record-drawer", {
            attrs: {
              visibility: _vm.lossRecordDrawer,
              carLossDeterminationBillId: this.ids,
              billId: this.billId,
            },
            on: {
              "update:visibility": function ($event) {
                _vm.lossRecordDrawer = $event
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }