import axios from './axios';
import { newServerUrl } from '@/config/compatibilityOlderSystems';
import { filterBlankSpace } from '@/utils/function';

function get(url, config = {}) {
  let prefix = config.prefix || newServerUrl;
  return axios.get(prefix + url, config);
}

function post(url, data = {}, config = {}) {
  // 过滤掉参数中的首尾空格
  data = filterBlankSpace(data);
  let prefix = config.prefix || newServerUrl;
  return axios.post(prefix + url, data, config);
}

function put(url, data = {}, config = {}) {
  let prefix = config.prefix || newServerUrl;
  return axios.put(prefix + url, data, config);
}

function request(config) {
  let { url = '' } = config;
  let prefix = config.prefix || newServerUrl;
  url = prefix + url;
  return axios(Object.assign(config, { url }));
}

function requestNative(config = {}) {
  return axios(config);
}

function upload(url, data) {
  return request({
    method: 'post',
    url,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  });
}

export default {
  get,
  post,
  put,
  upload,
  request,
  requestNative,
};
