<template>
  <div ref="printDom" class="print-template">
    <div ref="print">
      <div class="print-template__header">
        <h1 class="print-template__header__title">{{ company.companyName }}</h1>
        <p style="margin-top: 5px; font-size: 18px; text-align: center">
          {{ config.title }}
        </p>
        <img
          class="print-template__header__lt-img"
          v-if="config.leftImagePath"
          :src="config.leftImagePath"
        />
        <img
          class="print-template__header__rt-img"
          v-if="config.rightImagePath"
          :src="config.rightImagePath"
        />
      </div>

      <table class="table-base-info">
        <tr>
          <td>单据号: {{ billMessage.billCode }}</td>
          <td>车牌号: {{ carInfo.carNumber }}</td>
          <td>车型: {{ carInfo.brandName }}</td>
        </tr>
        <tr>
          <td>车架号: {{ carInfo.uniqueId }}</td>
          <td>接车员: {{ billMessage.pickUpUserName }}</td>
          <td>进店时间: {{ billMessage.dateCreated }}</td>
        </tr>
        <tr>
          <td>
            进店里程:
            {{ billMessage.mileage ? billMessage.mileage + 'km' : '' }}
          </td>
          <td colspan="2">
            预交车时间:
            {{ billMessage.undetermined ? '待定' : billMessage.planFinishTime }}
          </td>
        </tr>
        <tr>
          <td colspan="3" style="padding-bottom: 5px">
            车主要求: {{ billMessage.remark }}
          </td>
        </tr>
      </table>

      <div>
        <table
          style="margin-top: 20px"
          class="print-template__box__border__table"
        >
          <thead>
            <tr>
              <th class="text-center" style="width: 50px">序号</th>
              <th class="text-center" style="min-width: 200px">服务名称</th>
              <th class="text-center" style="width: 120px">技师</th>
              <th class="text-center" style="width: 50px">序号</th>
              <th class="text-center" style="min-width: 200px">维修配件</th>
              <th class="text-center" style="width: 120px">编码</th>
              <th class="text-center" style="width: 100px">数量</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in serviceAndGoodsList" :key="index">
              <td style="text-align: center">
                {{ index + 1 }}
              </td>
              <td class="text-left">{{ item.name }}</td>
              <td class="text-center">
                {{ item.technicians }}
              </td>
              <td class="text-center">
                {{ index + 1 }}
              </td>
              <td class="text-left">
                {{ item.goods.name }}
              </td>
              <td class="text-center">
                {{ item.goods.code }}
              </td>
              <td class="text-center">
                {{ item.goods.num }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div>
        <table
          style="margin: 10px 0"
          class="print-template__box__border__table"
        >
          <tr>
            <td style="width: 50%">打印人: {{ userName }}</td>
            <td>打印时间: {{ printTime }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { Print } from '@/utils/print';
export default {
  name: 'dispatchPrintTemplate',
  data() {
    return {
      printTime: new Date().Format('yyyy-MM-dd hh:mm'),
      config: {
        title: '派工单',
        leftImagePath: require('@/assets/slogan.png'),
      },
      company: {
        companyName: '',
      },
      userName: '',
      billMessage: {},
      carInfo: {},
      serviceList: [],
      goodsList: [],
      serviceAndGoodsList: [],
    };
  },
  methods: {
    doPrint(data) {
      this.printTime = new Date().Format('yyyy-MM-dd hh:mm');
      Object.keys(data).forEach((key) => {
        this.$set(this, key, data[key]);
      });
      // 合并服务项和商品
      let length = Math.max(this.serviceList?.length, this.goodsList?.length);
      let serviceAndGoodsList = [];
      for (let i = 0; i < length; i++) {
        serviceAndGoodsList.push({
          ...(this.serviceList[i] || {}),
          goods: this.goodsList[i] || {},
        });
      }
      this.serviceAndGoodsList = serviceAndGoodsList;
      this.$nextTick().then(() => {
        Print(this.$refs.printDom);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.print-template {
  width: 800px;
  // box-shadow: 0px 0px 10px 0px rgba(71, 71, 71, 0.1);
  padding: 12px;
  background: white;

  .fontsize {
    font-size: 14px;
  }

  .text-left {
    text-align: left;
  }
  .text-center {
    text-align: center;
  }
  .text-right {
    text-align: right;
  }

  .table-base-info {
    margin-top: 5px;
    width: 100%;
    border: 1px solid #000;
    td {
      padding-top: 5px;
    }
  }

  &__title {
    justify-content: center;
    margin: 7px 0 1px;
    font-weight: 600;
  }

  &__header {
    width: 100%;
    height: 75px;
  }

  &__user {
    margin-bottom: 4px;
  }

  &__user {
    justify-content: space-between;
  }

  &__date,
  &__user,
  &__main__ul,
  &__signature,
  &__contact,
  &__common,
  &__title {
    display: flex;
  }

  &__header {
    position: relative;

    &__title {
      text-align: center;
      margin: 0 150px;
      font-size: 26px;
      font-weight: normal;
    }

    &__lt-img,
    &__rt-img {
      min-width: 75px;
      max-width: 300px;
      height: 75px;
      position: absolute;
      top: 2px;
    }

    &__lt-img {
      left: 0;
    }

    &__rt-img {
      right: 0;
    }
  }

  &__header__lt-img > img,
  &__header__rt-img > img {
    min-width: 75px;
    max-width: 300px;
    height: 75px;
  }

  &__date {
    justify-content: flex-end;
    margin: 8px 0 7px 0;
  }

  &__box__border {
    &__table {
      width: 100%;
      font-weight: normal;
      &:last-child {
        border-bottom: 1px solid #000 !important;
      }
      th,
      td {
        vertical-align: middle;
        padding: 2px;
        border-top: 1px solid #000 !important;
        border-left: 1px solid #000 !important;
        word-break: break-all;
        font-weight: normal;
      }
      th:last-child,
      td:last-child {
        border-right: 1px solid #000 !important;
      }
      &__total {
        width: 200px;
        display: inline-block;
      }
    }
  }

  &__common {
    justify-content: space-between;
    &__column {
      width: 45%;
    }
  }

  &__line {
    border-bottom: 1px dashed #000;
  }

  &__signature {
    justify-content: space-between;
  }

  &__contact {
    margin-top: 15px;
    flex-wrap: wrap;
  }

  &__signature {
    padding: 15px 0 15px;
    border-bottom: 1px solid #000 !important;
    b {
      justify-content: initial;
      padding-right: 280px;
    }
  }

  &__contact__item {
    width: 39%;
  }
}
</style>
