var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "box" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c("div", { staticClass: "avatar" }, [
          _c("img", { attrs: { src: _vm.avatar, alt: "头像" } }),
        ]),
        _c(
          "div",
          { staticClass: "info" },
          [
            _c(
              "el-row",
              { attrs: { gutter: 20 } },
              [
                _c("el-col", { attrs: { span: 6, offset: 0 } }, [
                  _c("span", { staticClass: "user-name" }, [
                    _vm._v(_vm._s(_vm.userInfo.realName)),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "el-row",
              { attrs: { gutter: 20 } },
              [
                _c("el-col", { attrs: { span: 6, offset: 0 } }, [
                  _vm._v("手机号："),
                ]),
                _c("el-col", { attrs: { span: 10, offset: 0 } }, [
                  _vm._v(_vm._s(_vm.userInfo.phone)),
                ]),
                _c("el-col", { attrs: { span: 8, offset: 0 } }, [
                  _c(
                    "span",
                    {
                      staticClass: "pointer blue",
                      on: {
                        click: function ($event) {
                          _vm.changePhoneNumberDialogVisibility = true
                        },
                      },
                    },
                    [_vm._v("变更手机号")]
                  ),
                ]),
              ],
              1
            ),
            _c(
              "el-row",
              { attrs: { gutter: 20 } },
              [
                _c("el-col", { attrs: { span: 6, offset: 0 } }, [
                  _vm._v("性别："),
                ]),
                _c("el-col", { attrs: { span: 10, offset: 0 } }, [
                  _vm._v(_vm._s(_vm._f("dict")(_vm.userInfo.gender, "gender"))),
                ]),
              ],
              1
            ),
            _c(
              "el-row",
              { attrs: { type: "flex", justify: "space-between", gutter: 20 } },
              [
                _c("el-col", { attrs: { span: 6, offset: 0 } }, [
                  _vm._v("生日："),
                ]),
                _c("el-col", { attrs: { span: 10, offset: 0 } }, [
                  _vm._v(_vm._s(_vm.userInfo.birthDay)),
                ]),
                _c("el-col", { attrs: { span: 8, offset: 0 } }, [
                  _c(
                    "span",
                    {
                      staticClass: "pointer blue",
                      on: {
                        click: function ($event) {
                          _vm.changePasswordDialogVisibility = true
                        },
                      },
                    },
                    [_vm._v("修改密码")]
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _vm.changePhoneNumberDialogVisibility
          ? _c("change-phone-number-dialog", {
              attrs: {
                visibility: _vm.changePhoneNumberDialogVisibility,
                accountId: _vm.userInfo.accountId,
              },
              on: {
                "update:visibility": function ($event) {
                  _vm.changePhoneNumberDialogVisibility = $event
                },
              },
            })
          : _vm._e(),
        _vm.changePasswordDialogVisibility
          ? _c("change-password-dialog", {
              attrs: {
                visibility: _vm.changePasswordDialogVisibility,
                needOldPassword: true,
                phoneNumber: _vm.userInfo.phone,
              },
              on: {
                "update:visibility": function ($event) {
                  _vm.changePasswordDialogVisibility = $event
                },
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }