<template>
  <div>
    <el-dialog
      width="50%"
      :visible="visibility"
      :before-close="handleClose"
      :close-on-click-modal="false"
      append-to-body
    >
      <template slot="title">{{ otherTitle }}</template>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="类型" prop="type">
          <el-select
            v-model="ruleForm.type"
            placeholder="请选择"
            style="width: 100%"
          >
            <el-option
              v-for="type in receptionType"
              :key="type.value"
              :value="type.value"
              :label="type.label"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="故障现象" prop="wzAppearanceName">
          <el-autocomplete
            v-model="ruleForm.wzAppearanceName"
            :fetch-suggestions="remoteMethod"
            placeholder="请输入故障现象"
            :trigger-on-focus="false"
            :disabled="!!clickAppearanceName"
            style="width: 100%"
            @select="handleSelect"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item label="故障原因" prop="wzCauseName">
          <el-autocomplete
            v-model="ruleForm.wzCauseName"
            :fetch-suggestions="remoteMethodCause"
            placeholder="请输入故障原因"
            :trigger-on-focus="false"
            :disabled="!!clickCauseName"
            style="width: 100%"
            @select="handleChange"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item label="维修方案" prop="wzServiceId">
          <el-popover
            placement="bottom"
            trigger="click"
            v-model="selectServicePopoverVisible"
          >
            <el-table
              :data="serviceList"
              class="customer-table"
              :max-height="400"
              border
              @current-change="handleCurrentChange"
              v-loading="isLoading"
            >
              <el-table-column
                type="index"
                label="序号"
                width="60"
              ></el-table-column>
              <el-table-column
                width="180"
                prop="name"
                label="服务项目"
              ></el-table-column>
              <el-table-column
                width="120"
                prop="serviceItemCode"
                label="服务编码"
              ></el-table-column>
            </el-table>
            <div
              v-if="hasMore"
              class="load-more-btn"
              @click="getServiceData(false, true)"
            >
              加载更多数据
            </div>
            <el-input
              slot="reference"
              v-model="ruleForm.wzServiceName"
              style="width: 100%"
              size="small"
              placeholder="请搜索服务项目"
              @input="getServiceData(true)"
              @clear="handleClear"
              clearable
            ></el-input>
          </el-popover>
        </el-form-item>
        <el-form-item label="所属系统" prop="wzSysId">
          <el-select
            v-model="ruleForm.wzSysId"
            placeholder="请选择"
            style="width: 100%"
          >
            <el-option
              v-for="type in owningSystem"
              :key="type.value"
              :value="type.sysId"
              :label="type.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="适用车型" prop="isGeneral">
          <el-radio-group v-model="ruleForm.isGeneral">
            <el-radio :label="1">通用</el-radio>
            <el-radio :label="0">部分车型适用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="ruleForm.isGeneral === 0">
          <el-select
            v-model="ruleForm.carName"
            filterable
            remote
            clearable
            placeholder="搜索车型"
            :remote-method="searchModel"
            :loading="searchModelLoading"
            @clear="clearCarModel"
          >
            <div class="itemStyle">
              <el-option
                v-for="item in models"
                :key="$lodash.get(item, 'series.id')"
                :value="
                  $lodash.get(
                    item,
                    'series.name',
                    $lodash.get(
                      item,
                      'brand.name',
                      $lodash.get(item, 'logo.name')
                    )
                  )
                "
                @click.native="changeCarModel(item)"
              >
                <span v-html="item.markedRedName"></span>
              </el-option>
            </div>
          </el-select>
          <span
            class="text_operation blue"
            style="margin-left: 10px"
            @click="showCarModelsDialog = true"
            >选择车型</span
          >

          <div v-for="(item, index) in carList" :key="index">
            <i
              class="iconfont grow-icon_delete3 icon_delete pointer orange"
              style="margin-right: 5px"
              @click="deleteCar(index)"
            ></i>
            <span>{{ item.modelDetailSeriesName }}</span>
          </div>
        </el-form-item>
      </el-form>
      <template slot="footer">
        <div class="flex-x-between">
          <p
            v-auth="559"
            class="red pointer"
            style="margin-top: 5px"
            @click="detele"
            v-if="showContent || editChange"
          >
            删除
          </p>
          <p></p>
          <div>
            <el-button
              type="primary"
              size="small"
              @click="submitForm('ruleForm')"
              >确 定</el-button
            >
            <el-button @click="handleClose" size="small">取 消</el-button>
          </div>
        </div>
      </template>
    </el-dialog>
    <!-- 提示框 -->
    <el-dialog
      title="提示"
      :visible.sync="promptDialog"
      width="25%"
      top="15%"
      :before-close="handleClosePrompt"
      :close-on-click-modal="false"
      modal
    >
      <p>
        系统已存在“{{
          promptTitle
        }}”问诊模型，无须重复新增。如需修改请点击“去编辑”
      </p>
      <template slot="footer">
        <div style="text-align: center">
          <el-button @click="promptDialog = false" size="small"
            >取 消</el-button
          >
          <el-button type="primary" size="small" @click="edit"
            >去编辑</el-button
          >
        </div>
      </template>
    </el-dialog>
    <car-models-dialog
      v-if="showCarModelsDialog"
      :showCarModelsDialog.sync="showCarModelsDialog"
      @carModel="selectCarModel"
    ></car-models-dialog>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
//字典
import { receptionType } from '@/service/dict/dictData';
import { searchServiceForCreateCarBill } from '@/api/carBill';
import lodash from 'lodash';
import { searchCarModel } from '@/api/customer/car';
import carModelsDialog from '@/views/customerManagement/car/addCar/carModelsDialog.vue';
import {
  addWzModel,
  deleteWzModel,
  getAppearanceByPage,
  getCauseByPage,
  getWzModelDetail,
  editWzModel,
} from '@/api/system/inquiryModel';
import { getWzGuideSystemList } from '@/api/technologyCenter';
export default {
  name: 'dispatchingDialog',
  components: { carModelsDialog },
  props: {
    visibility: { type: Boolean, default: false },
    showContent: { type: Boolean, default: false },
    title: {
      type: [Number, String],
    },
    modelId: {
      type: [Number, String],
    },
    clickAppearanceName: {
      type: [Number, String],
    },
    clickCauseName: {
      type: [Number, String],
    },
    rowData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      otherTitle: '',
      //所属系统
      owningSystem: [],
      receptionType: receptionType,
      ruleForm: {
        type: 1,
        isGeneral: 1,
        carTypeList: [],
        wzAppearanceName: '',
        wzCauseName: '',
      },
      rules: {
        type: [{ required: true, message: '请选择', trigger: 'change' }],
        wzAppearanceName: [
          { required: true, message: '请输入故障现象', trigger: 'blur' },
        ],
        wzCauseName: [
          { required: true, message: '请输入故障原因', trigger: 'blur' },
        ],
        wzServiceId: [
          {
            validator: (rule, value, callback) => {
              setTimeout(() => {
                if (!this.ruleForm.wzServiceId) {
                  callback(new Error('请选择服务项目'));
                } else {
                  callback();
                }
              }, 1e2);
            },
            // message: '请输入故障现象名称',
            trigger: 'blur',
            required: true,
          },
        ],
        wzSysId: [{ required: true, message: '请选择', trigger: 'change' }],
        isGeneral: [{ required: true, message: '请选择', trigger: 'blur' }],
      },
      //选择服务弹窗可见性
      selectServicePopoverVisible: false,
      // 是否未加载完所有数据
      hasMore: true,
      // 分页所在页数
      pageCurrent: 0,
      // 是否首次加载
      isFirstLoad: true,
      // 是否正在加载
      isLoading: false,
      //弹窗数据
      serviceList: [],
      carList: [],
      //搜索车型
      searchModelLoading: false,
      models: [],
      //选择车型弹窗可见性
      showCarModelsDialog: false,
      //提示弹窗可见性
      promptDialog: false,
      promptTitle: '',

      restaurants: [],
      //故障现象模糊查询分页
      phenonPageCurrent: 0,
      // 是否首次加载
      isPhenonFirstLoad: true,
      // 是否正在加载
      isPhenonLoading: false,
      // 是否未加载完所有数据
      hasPhenonMore: false,
      phenonList: [],
      reqLoading: false,

      //故障原因模糊查询分页
      causePageCurrent: 0,
      // 是否首次加载
      isCauseFirstLoad: true,
      // 是否正在加载
      isCauseLoading: false,
      // 是否未加载完所有数据
      hasCauseMore: false,
      causeList: [],
      reqCauseLoading: false,
      editModelId: '',
      editChange: false,

      oldWzAppearanceName: '',
      oldWzCauseName: '',
      appearanceResults: [],
      causeResults: [],
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
      companyId: (state) => state.user.companyId,
    }),
    ...mapGetters(['isHeadquarters']),
    pagination() {
      return { size: 5, current: this.pageCurrent };
    },
    phenonPagination() {
      return { size: 5, current: this.phenonPageCurrent };
    },
    causePagination() {
      return { size: 5, current: this.causePageCurrent };
    },
  },
  mounted() {},
  created() {
    this.otherTitle = this.title;
    this.getSysList();
    this.getServiceData();
    // this.getPhenonData();
    // this.getCauseData();
    if (this.modelId) {
      this.getInfo(this.modelId);
    }
    if (Object.keys(this.rowData).length) {
      this.ruleForm.wzServiceName =
        this.rowData?.serviceName || this.rowData?.wzServiceName;
      this.ruleForm.wzCauseName = this.rowData?.wzCauseName;
      this.ruleForm.wzAppearanceName = this.rowData?.wzAppearanceName;
      this.ruleForm.wzServiceId = this.rowData?.wzServiceId;
      this.ruleForm.wzDifficultId = this.rowData.id;
    }
    if (this.clickAppearanceName) {
      this.ruleForm.wzAppearanceName = this.clickAppearanceName;
    }
    if (this.clickCauseName) {
      this.ruleForm.wzCauseName = this.clickCauseName;
    }
  },
  methods: {
    //查询问诊模型详情
    getInfo(modelId) {
      getWzModelDetail(modelId).then((res) => {
        this.ruleForm = res;
        this.carList = res.carTypeList || [];
        this.oldWzAppearanceName = res.wzAppearanceName;
        this.oldWzCauseName = res.wzCauseName;
      });
    },
    //系统列表
    getSysList() {
      getWzGuideSystemList().then((res) => {
        this.owningSystem = res;
      });
    },
    /**
     * 关闭弹窗
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
    handleClosePrompt() {
      this.promptDialog = false;
    },
    edit() {
      this.promptDialog = false;
      this.otherTitle = '编辑问诊模型';
      this.getInfo(this.editModelId);
      this.$emit('update:visibility', true);
      this.editChange = true;
    },
    //搜索服务列表-实现翻页
    getServiceData(reset, loadMore) {
      this.ruleForm.wzServiceId = '';
      this.debounceGetData(reset, loadMore);
    },
    debounceGetData: lodash.debounce(function (reset, loadMore) {
      if (!this.isFirstLoad) this.isLoading = true;
      if (loadMore) this.current++;
      // 重置分页，清空已有数据
      if (reset) {
        this.pageCurrent = 1;
        //  滚动到头部
        // this.hasMore = true;
      } else {
        this.pageCurrent++;
      }
      searchServiceForCreateCarBill({
        ...this.pagination,
        serviceMessage: this.ruleForm.wzServiceName,
        companyId: this.companyId,
      })
        .then((res) => {
          if (reset) this.serviceList = [];
          this.serviceList.push(...res.records);
          this.hasMore = this.pageCurrent < res.pages;
        })
        .finally(() => {
          this.isLoading = false;
          this.isFirstLoad = false;
        });
    }, 4e2),
    //搜索故障现象列表-实现翻页
    getPhenonData(reset, loadMore) {
      this.phenonGetData(reset, loadMore);
    },
    phenonGetData: lodash.debounce(function (reset, loadMore) {
      if (!this.isPhenonFirstLoad) this.isPhenonLoading = true;
      if (loadMore) this.current++;
      // 重置分页，清空已有数据
      if (reset) {
        this.phenonPageCurrent = 1;
        //  滚动到头部
      } else {
        this.phenonPageCurrent++;
      }
      getAppearanceByPage(
        {
          ...this.phenonPagination,
          appearanceName: this.ruleForm.wzAppearanceName,
        },
        { noShowWaiting: true }
      )
        .then((res) => {
          if (reset) this.restaurants = [];
          this.restaurants.push(...res.records);
          this.hasPhenonMore = this.phenonPageCurrent < res.pages;
        })
        .finally(() => {
          this.isPhenonLoading = false;
          this.isPhenonFirstLoad = false;
        });
    }, 4e2),

    //搜索故障原因列表-实现翻页
    getCauseData(reset, loadMore) {
      this.causeGetData(reset, loadMore);
    },
    causeGetData: lodash.debounce(function (reset, loadMore) {
      if (!this.isCauseFirstLoad) this.isCauseLoading = true;
      if (loadMore) this.current++;
      // 重置分页，清空已有数据
      if (reset) {
        this.causePageCurrent = 1;
        //  滚动到头部
        // this.hasMore = true;
      } else {
        this.causePageCurrent++;
      }
      getCauseByPage(
        {
          ...this.causePagination,
          causeName: this.ruleForm.wzCauseName,
        },
        { noShowWaiting: true }
      )
        .then((res) => {
          if (reset) this.causeList = [];
          this.causeList.push(...res.records);
          this.hasCauseMore = this.causePageCurrent < res.pages;
        })
        .finally(() => {
          this.isCauseLoading = false;
          this.isCauseFirstLoad = false;
        });
    }, 5e2),
    //故障现象
    async remoteMethod(queryString, cb) {
      // 调用 callback 返回建议列表的数据
      let res = await getAppearanceByPage(
        {
          size: 10,
          current: 1,
          appearanceName: queryString,
        },
        { noShowWaiting: true }
      );
      this.appearanceResults =
        res?.records?.map((item) => ({ value: item.name, id: item.id })) || [];
      console.log(this.appearanceResults);
      cb(this.appearanceResults);
    },
    handleSelect(val) {
      this.ruleForm.wzAppearanceId = val.id;
    },
    //故障原因
    async remoteMethodCause(queryString, cb) {
      // 调用 callback 返回建议列表的数据
      let res = await getCauseByPage(
        {
          size: 10,
          current: 1,
          causeName: queryString,
        },
        { noShowWaiting: true }
      );
      this.causeResults =
        res?.records?.map((item) => ({ value: item.name, id: item.id })) || [];
      console.log(this.causeResults);
      cb(this.causeResults);
    },
    handleChange(val) {
      this.ruleForm.wzCauseId = val.id;
    },
    /**
     * 搜索车型方法
     */
    searchModel(key) {
      this.searchModelLoading = true;
      searchCarModel(key)
        .then((res) => {
          this.models = res.records || [];
        })
        .finally(() => {
          this.searchModelLoading = false;
        });
    },
    /**
     * 清空车型
     */
    clearCarModel() {
      delete this.ruleForm.carName;
    },
    /**
     * 选择车型
     */
    changeCarModel(value) {
      if (['', undefined].includes(value.series)) {
        this.$message.warning('请选择具体车型!');
        this.ruleForm.carName = '';
        return;
      } else {
        this.carList.push({
          modelDetailLogoId: value.logo?.id,
          modelDetailBrandId: value.brand?.id,
          modelDetailSeriesId: value.series?.id,
          modelDetailSeriesName: value.series?.name,
        });
      }
    },
    /**
     * 选择车型
     */
    selectCarModel(value) {
      this.carList.push({
        modelDetailLogoId: value.modelDetailLogoId,
        modelDetailBrandId: value.modelDetailBrandId,
        modelDetailSeriesId: value.modelDetailSeriesId,
        modelDetailSeriesName: value.modelDetailSeriesName,
      });
    },
    deleteCar(index) {
      this.carList.splice(index, 1);
    },
    /**
     * 清空
     */
    handleClear() {
      this.selectServicePopoverVisible = false;
      if (this.ruleForm.wzServiceName) this.ruleForm.wzServiceName = '';
      if (this.ruleForm.wzServiceId) this.ruleForm.wzServiceId = '';
    },
    /**
     * 选中
     */
    handleCurrentChange(val) {
      if (val) {
        this.ruleForm.wzServiceName = val.name;
        this.ruleForm.wzServiceId = val.serviceItemId;
      }
      this.selectServicePopoverVisible = false;
    },
    submitForm(formName) {
      this.ruleForm.carTypeList = this.carList;
      if (this.ruleForm.isGeneral == 0 && this.carList.length == 0) {
        return this.$message.warning('请选择车型');
      }

      if (this.ruleForm.wzAppearanceName != this.oldWzAppearanceName) {
        this.ruleForm.wzAppearanceId = '';
      }
      if (this.ruleForm.wzCauseName != this.oldWzCauseName) {
        this.ruleForm.wzCauseId = '';
      }
      if (this.appearanceResults.length == 1) {
        this.ruleForm.wzAppearanceId = this.appearanceResults[0].id;
      }
      if (this.causeResults.length == 1) {
        this.ruleForm.wzCauseId = this.causeResults[0].id;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.showContent || this.editChange) {
            editWzModel({ ...this.ruleForm }).then((res) => {
              if (res.code == 200) {
                this.$message.success('操作成功！');
                this.$emit('update:visibility', false);
                this.$emit('updateData');
              }
            });
          } else {
            addWzModel({ ...this.ruleForm }).then((res) => {
              if (res.data == undefined) {
                this.$message.success('操作成功！');
                this.$emit('update:visibility', false);
                this.$emit('updateData');
                this.$emit('upList');
              } else {
                this.editModelId = res.data;
                this.promptTitle =
                  this.ruleForm.wzAppearanceName +
                  this.ruleForm.wzCauseName +
                  this.ruleForm.wzServiceName;

                this.promptDialog = true;
              }
            });
          }
        } else {
          this.$message.warning('请填写必填项!');
          return false;
        }
      });
    },
    //删除
    detele() {
      // this.$alert('删除后该问诊模型将无法恢复，请谨慎操作', '提示', {
      //   confirmButtonText: '确定',
      //   callback: (action) => {
      //     deleteWzModel(this.modelId).then((res) => {
      //       if (res.code == 200) {
      //         this.$message.success('操作成功！');
      //         this.$emit('updateData');
      //         this.$emit('update:visibility', false);
      //       }
      //     });
      //   },
      // });
      this.$confirm('删除后该问诊模型将无法恢复，请谨慎操作', '提示', {
        showCancelButton: false,
        confirmButtonText: '确定',
      })
        .then(() => {
          deleteWzModel(this.modelId).then((res) => {
            if (res.code == 200) {
              this.$message.success('操作成功！');
              this.$emit('updateData');
              this.$emit('update:visibility', false);
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.load-more-btn {
  text-align: center;
  line-height: 50px;
  color: #3eb1e0;
  cursor: pointer;
  &:hover {
    color: #14a3db;
  }
}
.customer-table ::v-deep .el-table__body-wrapper {
  overflow: auto;
}
::v-deep.el-table__body tr.current-row > td {
  background: #d0d3d9 !important;
}

.itemStyle {
  height: 100px !important;
}
</style>
