<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <el-form
        class="filter-form"
        :model="form"
        label-width="100px"
        size="small"
      >
        <div class="flex-x-between">
          <div class="flex flex-shrink0">
            <el-form-item>
              <template slot="label">
                <div>
                  操作门店
                  <el-tooltip
                    effect="light"
                    content="接车单的操作门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)"
                    placement="bottom-start"
                  >
                    <i
                      class="iconfont grow-icon_doubt"
                      style="color: #9a9a9a"
                    ></i>
                  </el-tooltip>
                </div>
              </template>
              <operateCompanyCascader
                v-model="form.companyId"
                :operateCompanyUrl="form.operateCompanyUrl"
              ></operateCompanyCascader>
            </el-form-item>
            <el-form-item label="商品名称">
              <el-input
                v-model="form.goodsName"
                placeholder="请输入商品名称"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
            <el-form-item label="商品编码">
              <el-input
                v-model="form.goodsCode"
                placeholder="请输入商品编码"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
          </div>
          <div class="el-form-item--small">
            <div class="flex">
              <el-button
                v-auth="629"
                class="btn_search"
                type="primary"
                size="small"
                @click="getData"
                >查询</el-button
              >
              <el-button class="btn_search" size="small" @click="empty"
                >置空</el-button
              >
            </div>
          </div>
        </div>
        <div class="flex-warp">
          <template>
            <el-form-item label="库存状态">
              <el-select
                v-model="form.inventoryStatus"
                placeholder="请选择库存状态"
              >
                <el-option label="全部" value=""> </el-option>
                <el-option label="低于安全库存" :value="-1"> </el-option>
                <el-option label="正常" :value="0"> </el-option>
                <el-option label="高于安全库存" :value="1"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="供应商">
              <el-select
                v-model="form.supplierId"
                filterable
                clearable
                remote
                :remote-method="searchSupplierList"
                :loading="searchSupplierListLoading"
                placeholder="请选择供应商"
              >
                <el-option
                  v-for="(item, index) in supplierList"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </template>
        </div>
      </el-form>
    </template>

    <template>
      <div style="margin: 30px 0 14px 0" class="btns flex-x-between">
        <div class="supplier-management__btn">
          <el-button
            size="small"
            class="btn_insert"
            style="height: 35px"
            v-auth="129"
            @click.native="batchOperation(2, '批量转采购单')"
            >批量转采购单</el-button
          >
        </div>
        <el-button
          v-auth="630"
          size="small"
          type="text"
          class="blue"
          @click="showExportDialog = true"
          >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
        ></el-button>
      </div>
      <el-table
        class="custom-table"
        :data="listData"
        border
        :style="{ height: baseContainerHeigth }"
        ref="tableDataRef"
        v-fixedScrollBar="$refs.baseContainer"
        show-overflow-tooltip
        scrollbar-always-on
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          fixed
          type="selection"
          width="40"
          prop="selection"
          align="center"
        >
        </el-table-column>
        <el-table-column
          type="index"
          label="序号"
          :fixed="isManagerOrSupplyChainManager"
          width="60"
          align="center"
        >
        </el-table-column>
        <el-table-column
          width="100"
          label="门店"
          :fixed="isManagerOrSupplyChainManager"
          prop="companyName"
          v-if="isManagerOrSupplyChainManager"
        >
        </el-table-column>
        <el-table-column
          width="100"
          label="商品ID"
          :fixed="isManagerOrSupplyChainManager"
          prop="goodsId"
        >
        </el-table-column>
        <el-table-column
          width="180"
          label="商品名称"
          :fixed="isManagerOrSupplyChainManager"
          prop="goodName"
        >
        </el-table-column>
        <el-table-column
          width="180"
          label="是否禁止采购"
          prop="disablePurchaseStr"
        >
        </el-table-column>
        <el-table-column
          width="180"
          label="商品分类"
          prop="goodCategory"
          v-if="isManagerOrSupplyChainManager"
        >
        </el-table-column>
        <el-table-column
          width="130"
          label="商品品牌"
          prop="goodBrand"
          v-if="isManagerOrSupplyChainManager"
        >
        </el-table-column>
        <el-table-column
          width="130"
          label="商品编码"
          prop="goodCode"
          v-if="isManagerOrSupplyChainManager"
        >
        </el-table-column>
        <el-table-column
          width="90"
          label="单位"
          prop="unit"
          v-if="isManagerOrSupplyChainManager"
        >
        </el-table-column>
        <el-table-column
          width="120"
          label="近一个月销量"
          prop="lastMonthSalesVol"
          v-if="isManagerOrSupplyChainManager"
        >
        </el-table-column>
        <el-table-column
          width="140"
          label="前3个月月均销量"
          prop="firstThreeAverageSalesVol"
          v-if="isManagerOrSupplyChainManager"
        >
        </el-table-column>
        <el-table-column
          width="120"
          label="当前库存数"
          prop="currInventory"
          v-if="isManagerOrSupplyChainManager"
        >
        </el-table-column>
        <el-table-column
          width="120"
          label="占用库存"
          prop="takeUpInventory"
          v-if="isManagerOrSupplyChainManager"
        >
          <template slot="header">
            <div class="flex">
              <el-tooltip content="维修待领料+调拨出库（待出库）">
                <span class="text-ellipsis">占用库存</span>
              </el-tooltip>
              <el-tooltip effect="light" placement="bottom-start">
                <div
                  slot="content"
                  style="
                    color: #666666;
                    font-size: 13px;
                    line-height: 16px;
                    text-indent: 15px;
                  "
                >
                  维修待领料+调拨出库（待出库）
                </div>
                <i
                  class="iconfont grow-icon_doubt"
                  style="margin-left: 5px"
                ></i>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          label="采购单待入库数量"
          prop="purchaseWaitInventoryIn"
          v-if="isManagerOrSupplyChainManager"
        >
        </el-table-column>
        <el-table-column
          width="120"
          label="调拨待入库数量"
          prop="allocateWaitInventoryIn"
          v-if="isManagerOrSupplyChainManager"
        >
        </el-table-column>
        <el-table-column width="180" label="安全库存" prop="safeStock">
        </el-table-column>
        <el-table-column
          width="100"
          label="建议补货数"
          prop="recommendReplenish"
        >
        </el-table-column>
        <el-table-column width="120" label="库存状态" prop="inventoryStatus">
        </el-table-column>
        <el-table-column
          width="160"
          label="上一次采购时间"
          prop="lastPurchaseDate"
        >
        </el-table-column>
        <el-table-column
          width="160"
          label="上一次采购供应商"
          prop="lastSupplier"
        >
        </el-table-column>
        <el-table-column
          width="140"
          label="上一次采购单价"
          prop="lastCostPrice"
        >
        </el-table-column>
        <el-table-column
          width="160"
          label="近3个月最低采购单价"
          prop="lastThreeMonthLowestPurchasePrice"
          v-if="isManagerOrSupplyChainManager"
        >
        </el-table-column>
        <el-table-column
          width="160"
          label="近3个月最低采购单价所属供应商"
          prop="lastThreeMonthLowestPurchasePriceSupplier"
          v-if="isManagerOrSupplyChainManager"
        >
        </el-table-column>
        <el-table-column
          width="160"
          label="其它门店上一次最低采购价"
          prop="otherCompanyLastLowestPurchasePrice"
          v-if="isManagerOrSupplyChainManager"
        >
        </el-table-column>
        <el-table-column
          width="160"
          label="其它门店上一次最低采购价的供应商"
          prop="otherCompanyLastLowestPurchasePriceSupplier"
          v-if="isManagerOrSupplyChainManager"
        >
        </el-table-column>
        <el-table-column label="是否转采购单" width="120">
          <!-- 判断是否有采购入库的菜单和权限 -->
          <template slot-scope="scope">
            <span
              v-auth="129"
              class="text_operation blue"
              @click="handleToOldSysPurchasePageEdit(scope.row)"
              >是</span
            >
          </template>
        </el-table-column>
      </el-table>
      <export-dialog
        :visibility.sync="showExportDialog"
        :total="total"
        :pageOption="pageOption"
        :filterForm="form"
        :url="exportUrl"
      ></export-dialog>
    </template>
    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
  </base-container>
</template>

<script>
// 组件
import pagination from '@/components/Pagination.vue';
import ExportDialog from '@/components/ExportDialog';
import operateCompanyCascader from '@/components/businessComponent/operateCompanyCascader';
// 接口
import {
  admin1PagePrefix,
  admin2PagePrefix,
} from '@/config/compatibilityOlderSystems';
import { searchStoreRoomByName } from '@/api/stock/inventoryWarning/index';
import {
  getReplenishmentList,
  supplierSearch,
  postAddPurchase,
  exportUrl,
} from '@/api/stock/inventoryReplenishment/index';
import { hasAuth } from '@/utils/permissions';

import { mapState } from 'vuex';

export default {
  name: 'inventoryReplenishment',
  components: {
    pagination,
    ExportDialog,
    operateCompanyCascader,
  },
  data() {
    return {
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      baseContainerHeigth: '100%',
      showExportDialog: false,
      startPage: 1,
      endPage: 1,
      exportUrl: exportUrl,
      supplierList: [],
      searchSupplierListLoading: false,
      searchSupplierTimer: undefined,
      storeRoomList: [],
      searchStoreRoomLoading: false,
      isManagerOrSupplyChainManager: false,

      form: {},
      listData: [],
      selectData: [],
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.user,
      companyList: (state) => state.user.companyList,
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  activated() {},
  created() {
    this.init();
    this.form.companyId = [this.companyId];
    this.getData();
  },
  methods: {
    init() {
      let authMark = sessionStorage.getItem('authMark');
      if (authMark != undefined && authMark.length > 0) {
        let authMarkObj = JSON.parse(authMark);
        this.userRoles = authMarkObj.roles;
      }
      if (this.userRoles != undefined) {
        if (this.userRoles.indexOf('超级用户') != -1) {
          this.isManagerOrSupplyChainManager = true;
        } else if (this.userRoles.indexOf('供应链-总') != -1) {
          this.isManagerOrSupplyChainManager = true;
        }
      }
    },
    searchSupplierList(keyWord) {
      this.searchSupplierTimer != undefined &&
        window.clearTimeout(this.searchSupplierTimer);

      this.searchSupplierTimer = window.setTimeout(function () {
        this.searchSupplierListLoading = true;
        supplierSearch({ key: keyWord || '' }, { noShowWaiting: true })
          .then((res) => {
            this.supplierList = res || [];
          })
          .finally(() => {
            this.searchSupplierListLoading = false;
          });
      }, 3000);
    },
    handleSelectionChange(items, row) {
      this.selectData = [];
      items.forEach((item) => {
        this.selectData.push(item);
      });
    },
    /**
     * 根据仓库名称搜索仓库列表
     * @param {String} storeRoomName 仓库名称
     */
    searchStoreRoom(storeRoomName) {
      this.searchStoreRoomLoading = true;
      const data = {
        storeRoomName: storeRoomName,
        current: 1,
        size: 200000,
      };
      searchStoreRoomByName(data)
        .then((res) => {
          this.storeRoomList = res.records;
        })
        .finally(() => {
          this.searchStoreRoomLoading = false;
        });
    },
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getData(false);
    },
    /**
     * 加载数据
     */
    getData(reset = true) {
      var res = hasAuth(629);
      if (!res) {
        return;
      }
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }

      var data = Object.assign({}, this.form, this.pageOption);
      getReplenishmentList(data).then((res) => {
        // 修改总条数
        this.total = res.total;
        // 赋值records
        this.listData = res.records;
        if (this.isManagerOrSupplyChainManager) {
          // 固定列不上下滚动，增加容器高度，让外部容器来滚动
          // 行数*90px + 其它固定的
          var size = res.records.length;
          // this.baseContainerHeigth = '100%';
          if (size == 0) {
            this.baseContainerHeigth = '100%';
          } else if (size < 10) {
            this.baseContainerHeigth = 900 + 10 + 'px';
          } else {
            this.baseContainerHeigth = 90 * size + 120 + 'px';
          }
          // 另外一种表头固定，内容滚动方式，需要2边的行高度一致，不然还是会有差异的 参考 calc(100vh - 30vh)
          // 参考 https://blog.csdn.net/ProgrammingWhite/article/details/120336740
          this.$nextTick(() => {
            this.$refs.tableDataRef.doLayout();
          });
        }
      });
    },
    /**
     * 置空输入框数据
     */
    empty() {
      this.form = {
        isActive: true,
      };
    },
    /**
     * 校验当前用户门店列表内是否包含该车单开单门店
     */
    checkCurrentUserStore(row) {
      if (
        !this.companyList
          .map((el) => el.company[0].companyId)
          .includes(row.companyId)
      ) {
        this.$message.error(
          `该库存补货记录所属门店“${row.companyName}”，你暂无该门店操作权限，请联系管理员`
        );
        return false;
      } else {
        // 检查当前门店和会话所在门店是否一致， 不然跳转过去会提示切换门店，导致数据清空
        const localCompanyId = localStorage.getItem('companyId') || '';
        if (localCompanyId != row.companyId) {
          this.$message.error(
            `该库存补货记录所属门店“${row.companyName}”，和当前门店不一致，无法跳转`
          );
          return false;
        }
        return true;
      }
    },
    /**
     * 批量操作
     * @param {Integer} status
     * @param {String} str
     */
    batchOperation(status, str) {
      if (this.selectData.length === 0) {
        return this.$message({
          type: 'warning',
          message: '请选择库存补货商品数据后操作',
        });
      }
      let firstCompanyId = this.selectData[0].companyId;
      for (let i = 0; i < this.selectData.length; i++) {
        // 如果门店都不一样，直接提示
        if (firstCompanyId !== this.selectData[i].companyId) {
          this.$message.error(
            '选择的库存补货商品不是同一个门店的，无法进行批量操作'
          );
          return;
        }
      }
      for (let i = 0; i < this.selectData.length; i++) {
        var checkResult = this.checkCurrentUserStore(this.selectData[i]);
        if (!checkResult) {
          return;
        }
      }
      // 判断最近采购供应商是否是同一个
      let baseLastSupplierId = this.selectData[0].lastSupplierId || 0;
      let someSupplier = true;
      for (let i = 1; i < this.selectData.length; i++) {
        if (baseLastSupplierId !== this.selectData[i].lastSupplierId) {
          someSupplier = false;
          break;
        }
      }
      if (someSupplier) {
        // 直接生成采购单
        let firstRow = this.selectData[0];
        let purchaseBillGoods = [];
        let paid = 0;
        let goodsTotalNum = 0;
        let total = 0;
        for (let i = 0; i < this.selectData.length; i++) {
          const row = this.selectData[i];
          if ((row.recommendReplenish || 0) <= 0) {
            this.$message.error(
              `选择的库存补货商品【${row.goodName}】补货数量小于等于0或者为空，不符合采购入库要求`
            );
            return;
          }
          purchaseBillGoods.push({
            goodsId: row.goodsId,
            num: row.recommendReplenish || 0,
            price: row.lastCostPrice || 0,
          });
          paid += (row.lastCostPrice || 0) * (row.recommendReplenish || 0);
          goodsTotalNum += row.recommendReplenish || 0;
          total += (row.lastCostPrice || 0) * (row.recommendReplenish || 0);
        }
        let data = {
          purchaseTime: new Date().Format('yyyy-MM-dd hh:mm:ss'),
          supplierId: firstRow.lastSupplierId,
          purchaseUserId: this.user.userId,
          remark: '库存补货自动创建单据',
          purchaseType: 1, //其他采购
          paid: paid,
          goodsTotalNum: goodsTotalNum,
          total: total,
          purchaseBillGoods: purchaseBillGoods,
        };
        postAddPurchase(data)
          .then((res) => {
            let resp = res.data;
            if (resp.code == 'ISM00000') {
              this.$message.success(
                `新增供应商【${firstRow.lastSupplier}】采购单成功`
              );
              this.getData(false);
            } else {
              this.$message.error(
                resp.msg || `新增供应商【${firstRow.lastSupplier}】采购单失败`
              );
            }
          })
          .catch((err) => {
            this.$message.error(
              err || `新增供应商【${firstRow.lastSupplier}】采购单失败`
            );
          });
        return;
      } else {
        // 采购供应商不一样，跳转过去，让仓管去选择供应商
        let purchasingBill = [];
        // 以下来自ng旧系统逻辑 c.reception2 :1565
        for (let i = 0; i < this.selectData.length; i++) {
          let row = this.selectData[i];
          var needGoods = {};
          needGoods.goods = {};
          needGoods.goods.goodsId = row.goodsId;
          needGoods.goods.goodsName = row.goodName;
          needGoods.goods.goodsCode = row.goodCode;
          needGoods.goods.unit = row.unit;

          needGoods.goodsId = row.goodsId;
          needGoods.num = row.recommendReplenish || 0;
          needGoods.price = 0;
          purchasingBill.push(needGoods);
        }
        localStorage.setItem('purchasingBill', JSON.stringify(purchasingBill));
        sessionStorage.setItem('companyId', firstCompanyId);
        localStorage.setItem('companyId', firstCompanyId);
        window.open(
          admin2PagePrefix +
            '#/stock/purchaseEdit?purchaseType=1&keepRoute=true',
          '_blank'
        );
      }
    },
    /**
     * 编辑
     */
    handleToOldSysPurchasePageEdit(row) {
      if ((row.recommendReplenish || 0) <= 0) {
        this.$message.error(
          `选择的库存补货商品【${row.goodName}】补货数量小于等于0或者为空，不符合采购入库要求`
        );
        return;
      }
      // 校验不通过的，让仓管去自己去选择&输入
      if (row.recommendReplenish == undefined) {
        //this.$message.error(`库存商品【${row.goodName}】没有建议补货数`);
        this.jumpToOldSysPurchasePageEdit(row);
        return;
      }
      if (row.lastSupplierId == undefined) {
        //this.$message.error(`库存商品【${row.goodName}】没有供应商`);
        this.jumpToOldSysPurchasePageEdit(row);
        return;
      }
      if (row.lastCostPrice == undefined) {
        //.$message.error(`库存商品【${row.goodName}】没有采购价`);
        this.jumpToOldSysPurchasePageEdit(row);
        return;
      }
      var bool = this.checkCurrentUserStore(row);
      if (!bool) {
        return;
      }
      // 直接生成采购单
      let data = {
        purchaseTime: new Date().Format('yyyy-MM-dd hh:mm:ss'),
        supplierId: row.lastSupplierId,
        purchaseUserId: this.user.userId,
        remark: '库存补货自动创建单据',
        purchaseType: 1, //其他采购
        paid: row.lastCostPrice * row.recommendReplenish,
        goodsTotalNum: row.recommendReplenish,
        total: row.lastCostPrice * row.recommendReplenish,
        purchaseBillGoods: [
          {
            goodsId: row.goodsId,
            num: row.recommendReplenish,
            price: row.lastCostPrice,
          },
        ],
      };
      postAddPurchase(data).then((res) => {
        let resp = res.data;
        if (resp.code == 'ISM00000') {
          this.$message.success(`新增供应商【${row.lastSupplier}】采购单成功`);
          this.getData(false);
        } else {
          this.$message.error(
            resp.msg || `新增供应商【${row.lastSupplier}】采购单失败`
          );
        }
      });
    },
    jumpToOldSysPurchasePageEdit(row) {
      // 库存补货门店和该门店
      /*
      var bool = this.checkCurrentUserStore(row);
      if (!bool) {
        return;
      }
      */
      let purchasingBill = [];
      // 以下来自ng旧系统逻辑 c.reception2 :1565
      var needGoods = {};
      needGoods.goods = {};
      needGoods.goods.goodsId = row.goodsId;
      needGoods.goods.goodsName = row.goodName;
      needGoods.goods.goodsCode = row.goodCode;
      needGoods.goods.unit = row.unit;

      needGoods.goodsId = row.goodsId;
      needGoods.num = row.recommendReplenish || 0;
      needGoods.price = 0;
      purchasingBill.push(needGoods);
      localStorage.setItem('purchasingBill', JSON.stringify(purchasingBill));
      sessionStorage.setItem('companyId', row.companyId);
      localStorage.setItem('companyId', row.companyId);
      window.open(
        admin2PagePrefix + '#/stock/purchaseEdit?purchaseType=1&keepRoute=true',
        '_blank'
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.el-form {
  width: 100%;
}
.text_operation {
  text-align: center;
  display: block;
}
.custom-table {
  .el-table__body-wrapper {
    height: calc(100vh - 30vh); // 一个是正常表格项固定高度，自己设
    overflow-y: auto;
    z-index: 1;
  }
  .el-table__fixed-body-wrapper {
    height: calc(100vh - 30vh); // 一个是固定列表格项固定高度，自己设
    overflow-y: auto;
    pointer-events: none; // 禁止左侧固定列滑动
    cursor: default;
  }
}
</style>
