import http from '@/service/http';
import router from '@/router';
import { MessageBox } from 'element-ui';

const defaultListOption = {
  current: 1,
  size: 10,
};

// const prefix = '/commandCenterServer';
const prefix = '/mapper';
/**
 * 查看所有管控门店营业额目标
 */
export function getAllControlCompanyTarget(data = {}) {
  return http.post('/commandCenter/getAllControlCompanyTarget', data, {
    prefix: prefix,
    errorCallback: handleError,
  });
}

/**
 * 查看门店营业额目标
 */
export function getCompanyTurnoverTarget(data = {}, config = {}) {
  return http.post(
    '/commandCenter/getCompanyTurnoverTarget',
    data,
    Object.assign(
      {
        prefix: prefix,
        errorCallback: handleError,
      },
      config
    )
  );
}

/**
 * 查看单个门店营业额目标
 */
export function selectOneCompanyTurnoverTarget(data = {}) {
  return http.post('/commandCenter/selectOneCompanyTurnoverTarget', data, {
    prefix: prefix,
    errorCallback: handleError,
  });
}

/**
 * 设置门店营业额目标
 */
export function setCompanyTurnoverTarget(data = {}) {
  return http.post('/commandCenter/installCompanyTurnoverTarget', data, {
    prefix: prefix,
    errorCallback: handleError,
  });
}

/**
 * 查看管控门店
 */
export function getControlCompany(data = {}) {
  return http.post('/commandCenter/getControlCompany', data, {
    prefix: prefix,
    errorCallback: handleError,
  });
}

/**
 * 设置管控门店清单
 */
export function setControlCompany(data = {}) {
  return http.post('/commandCenter/installControlCompany', data, {
    prefix: prefix,
    errorCallback: handleError,
  });
}

/**
 * 查看非管控门店分页
 */
export function getNotControlCompanyPage(data = {}) {
  return http.post('/commandCenter/getNotControlCompanyPage', data, {
    noShowWaiting: true,
    prefix: prefix,
    errorCallback: handleError,
  });
}

/**
 * 查看总负责人
 */
export function getHeadResponsible(data = {}) {
  return http.post('/commandCenter/getHeadResponsible', data, {
    prefix: prefix,
    errorCallback: handleError,
  });
}

/**
 * 设置总负责人
 */
export function setHeadResponsible(data = {}) {
  return http.post('/commandCenter/installHeadResponsible', data, {
    prefix: prefix,
    errorCallback: handleError,
  });
}

/**
 * 查看门店排行榜
 */
export function getCompanyRanked(data = {}) {
  return http.post('/commandCenter/getCompanyRanked', data, {
    prefix: prefix,
    errorCallback: handleError,
    noShowWaiting: true,
  });
}

/**
 * 演示版-查看本月目标
 */
export function getCurrentMonthTarget(data = {}) {
  return http.post('/commandCenter/getCurrentMonthTarget', data, {
    prefix: prefix,
    errorCallback: handleError,
    noShowWaiting: true,
  });
}

/**
 * 演示版-查看门店分布
 */
export function getCompanyCountryDistribution(data = {}) {
  return http.post('/commandCenter/getCompanyCountryDistribution', data, {
    prefix: prefix,
    errorCallback: handleError,
    noShowWaiting: true,
  });
}

/**
 * 演示版-查看服务品牌
 */
export function getServiceBrand(data = {}) {
  return http.post('/commandCenter/getServiceBrand', data, {
    prefix: prefix,
    errorCallback: handleError,
    noShowWaiting: true,
  });
}

/**
 * 演示版-查看经营数据
 */
export function getManageData(data = {}) {
  return http.post('/commandCenter/getManageData', data, {
    prefix: prefix,
    errorCallback: handleError,
    noShowWaiting: true,
  });
}

/**
 * 演示版-查看本月营业毛利率
 */
export function getCurrentMonthGrossMargin(data = {}) {
  return http.post('/commandCenter/getCurrentMonthGrossMargin', data, {
    prefix: prefix,
    errorCallback: handleError,
    noShowWaiting: true,
  });
}

/**
 * 演示版-查看事故进场
 */
export function getAccidentIntoFactory(data = {}) {
  return http.post('/commandCenter/getAccidentIntoFactory', data, {
    prefix: prefix,
    errorCallback: handleError,
    noShowWaiting: true,
  });
}

/**
 * 演示版-查看门店明细毛利率
 */
export function getAllCompanyGrossMargin(data = {}) {
  return http.post('/commandCenter/getAllCompanyGrossMargin', data, {
    prefix: prefix,
    errorCallback: handleError,
    noShowWaiting: true,
  });
}

/**
 *  查看全省市门店分布
 */
export function getCompanyProvinceDistribution(data = {}) {
  return http.post('/commandCenter/getCompanyProvinceDistribution', data, {
    prefix: prefix,
    errorCallback: handleError,
    noShowWaiting: true,
  });
}

/**
 *  查看全市门店分布情况
 */
export function getCompanyCityDistribution(data = {}) {
  return http.post('/commandCenter/getCompanyCityDistribution', data, {
    prefix: prefix,
    errorCallback: handleError,
    noShowWaiting: true,
  });
}

/**
 *  查看毛利率标准
 */
export function getGrossMarginStandard(data = {}) {
  return http.post('/commandCenter/getGrossMarginStandard', data, {
    prefix: prefix,
    errorCallback: handleError,
  });
}

/**
 *  设置毛利率标准
 */
export function setGrossMarginStandard(data = {}) {
  return http.post('/commandCenter/installGrossMarginStandard', data, {
    prefix: prefix,
    errorCallback: handleError,
  });
}

/**
 * 选择用户
 */
export function findUserByPage(data = {}) {
  return http.post('/admin/job/findUserByPage', data, {
    noShowWaiting: true,
  });
}

/**
 * 查看全国每个省门店数量信息
 */
export function getProvinceCompanyNum(data = {}) {
  return http.post('/commandCenter/getProvinceCompanyNum', data, {
    prefix: prefix,
    errorCallback: handleError,
    noShowWaiting: true,
  });
}

/**
 * 查看全省每个市门店数量信息
 */
export function getCityCompanyNum(data = {}) {
  return http.post('/commandCenter/getCityCompanyNum', data, {
    prefix: prefix,
    errorCallback: handleError,
    noShowWaiting: true,
  });
}

/**
 * 查看区内门店详情
 */
export function getDistrictCompanyInfo(data = {}) {
  return http.post('/commandCenter/getDistrictCompanyInfo', data, {
    prefix: prefix,
    errorCallback: handleError,
    noShowWaiting: true,
  });
}

function handleError() {
  MessageBox.close();
  router.push('/login');
}
