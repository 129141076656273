var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { ref: "baseContainer", attrs: { paddingTop: "" } },
    [
      [
        _c(
          "div",
          [
            _c(
              "el-form",
              {
                staticClass: "filter-form",
                attrs: {
                  model: _vm.form,
                  "label-width": "100px",
                  size: "small",
                },
              },
              [
                _c("div", { staticClass: "flex-x-between" }, [
                  _c(
                    "div",
                    { staticClass: "flex-warp flex-shrink0" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "车牌号" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入车牌号",
                              clearable: "",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.doSearch.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.form.carNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "carNumber", $$v)
                              },
                              expression: "form.carNumber",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c("template", { slot: "label" }, [
                            _c(
                              "div",
                              [
                                _vm._v(" 操作门店 "),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "light",
                                      content:
                                        "接车单的操作门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)",
                                      placement: "bottom-start",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "iconfont grow-icon_doubt",
                                      staticStyle: { color: "#9a9a9a" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("operateCompanyCascader", {
                            attrs: {
                              operateCompanyUrl: _vm.form.operateCompanyUrl,
                            },
                            model: {
                              value: _vm.form.operateCompanyId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "operateCompanyId", $$v)
                              },
                              expression: "form.operateCompanyId",
                            },
                          }),
                        ],
                        2
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "车单结账日期" } },
                        [
                          _c("el-date-picker", {
                            staticClass: "w100",
                            attrs: {
                              type: "daterange",
                              "unlink-panels": "",
                              "range-separator": "至",
                              "start-placeholder": "起始时间",
                              "end-placeholder": "截止时间",
                              "value-format": "yyyy-MM-dd",
                            },
                            model: {
                              value: _vm.form.checkStartTimes,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "checkStartTimes", $$v)
                              },
                              expression: "form.checkStartTimes",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "flex" }, [
                    _c("div", { staticClass: "el-form-item--small" }, [
                      _c(
                        "div",
                        {
                          staticClass: "el-form-item__label pointer",
                          on: {
                            click: function ($event) {
                              _vm.showMore = !_vm.showMore
                            },
                          },
                        },
                        [
                          _vm._v(" 更多筛选 "),
                          _c("i", {
                            class: {
                              primary: true,
                              "el-icon-arrow-right": !_vm.showMore,
                              "el-icon-arrow-down": _vm.showMore,
                            },
                          }),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "btn_search",
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.doSearch },
                            },
                            [_vm._v("查询")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "btn_search",
                              attrs: { size: "small" },
                              on: { click: _vm.empty },
                            },
                            [_vm._v("置空")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "flex-warp" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "签约客户" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              filterable: "",
                              clearable: "",
                              remote: "",
                              placeholder: "请输入客户名称搜索",
                              "remote-method": _vm.searchClient,
                              loading: _vm.searchClientLoading,
                            },
                            on: { change: _vm.selectClient },
                            model: {
                              value: _vm.form.clientId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "clientId", $$v)
                              },
                              expression: "form.clientId",
                            },
                          },
                          _vm._l(_vm.customList, function (item) {
                            return _c(
                              "el-option",
                              {
                                key: item.id,
                                attrs: {
                                  label: item.clientName,
                                  value: item.id,
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "#8492a6",
                                      "font-size": "13px",
                                    },
                                  },
                                  [_vm._v(_vm._s(item.clientName))]
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "接车单号" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入接车单号",
                            clearable: "",
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.doSearch.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.form.billCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "billCode", $$v)
                            },
                            expression: "form.billCode",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "接车进店日期" } },
                      [
                        _c("el-date-picker", {
                          staticClass: "w100",
                          attrs: {
                            type: "daterange",
                            "unlink-panels": "",
                            "range-separator": "至",
                            "start-placeholder": "起始时间",
                            "end-placeholder": "截止时间",
                            "value-format": "yyyy-MM-dd",
                          },
                          model: {
                            value: _vm.form.intoStoreTimes,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "intoStoreTimes", $$v)
                            },
                            expression: "form.intoStoreTimes",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "业务来源" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              filterable: "",
                              clearable: "",
                              remote: "",
                              placeholder: "搜索业务来源",
                              "remote-method": _vm.searchBillSource,
                              loading: _vm.searchBillSourceLoading,
                            },
                            on: {
                              change: _vm.selectBillSource,
                              clear: _vm.deleteBillSource,
                            },
                            model: {
                              value: _vm.form.billSourceTypeId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "billSourceTypeId", $$v)
                              },
                              expression: "form.billSourceTypeId",
                            },
                          },
                          _vm._l(_vm.billSourceList, function (item) {
                            return _c("el-option", {
                              key: item.billSourceTypeId,
                              attrs: {
                                label: item.name,
                                value: item.billSourceTypeId,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.showMore
                      ? [
                          _c(
                            "el-form-item",
                            [
                              _c("template", { slot: "label" }, [
                                _c(
                                  "div",
                                  [
                                    _vm._v(" 消费客户 "),
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "light",
                                          placement: "bottom-start",
                                        },
                                      },
                                      [
                                        _c("template", { slot: "content" }, [
                                          _c("p", [
                                            _vm._v(
                                              "自费/包干客户：输入客户名称"
                                            ),
                                          ]),
                                          _c("p", [
                                            _vm._v(
                                              "保险公司：输入公司名称、编码"
                                            ),
                                          ]),
                                          _c("p", [
                                            _vm._v(
                                              "三包厂家：输入厂家名称、编码"
                                            ),
                                          ]),
                                        ]),
                                        _c("i", {
                                          staticClass:
                                            "iconfont grow-icon_doubt",
                                          staticStyle: { color: "#9a9a9a" },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-input", {
                                attrs: { placeholder: "请输入", clearable: "" },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.doSearch.apply(null, arguments)
                                  },
                                },
                                model: {
                                  value: _vm.form.consumerMsg,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "consumerMsg", $$v)
                                  },
                                  expression: "form.consumerMsg",
                                },
                              }),
                            ],
                            2
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "事故责任" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    multiple: "",
                                    placeholder: "筛选（支持多选）",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.dutyType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "dutyType", $$v)
                                    },
                                    expression: "form.dutyType",
                                  },
                                },
                                _vm._l(_vm.dutyTypeList, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            ),
          ],
          1
        ),
      ],
      [
        _c("div", { staticClass: "btns flex-x-between" }, [
          _c("div", [
            _c("div", { staticClass: "custom-tabs nopadding" }, [
              _c(
                "div",
                {
                  class: {
                    "custom-tab-item": true,
                    active: _vm.status == -1,
                  },
                  on: {
                    click: function ($event) {
                      _vm.status = -1
                    },
                  },
                },
                [_c("p", [_vm._v("全部")])]
              ),
              _c(
                "div",
                {
                  class: {
                    "custom-tab-item": true,
                    active: _vm.status == 0,
                  },
                  on: {
                    click: function ($event) {
                      _vm.status = 0
                    },
                  },
                },
                [_c("p", [_vm._v("自费")])]
              ),
              _c(
                "div",
                {
                  class: {
                    "custom-tab-item": true,
                    active: _vm.status == 1,
                  },
                  on: {
                    click: function ($event) {
                      _vm.status = 1
                    },
                  },
                },
                [_c("p", [_vm._v("包干")])]
              ),
              _c(
                "div",
                {
                  class: {
                    "custom-tab-item": true,
                    active: _vm.status == 2,
                  },
                  on: {
                    click: function ($event) {
                      _vm.status = 2
                    },
                  },
                },
                [_c("p", [_vm._v("理赔")])]
              ),
              _c(
                "div",
                {
                  class: {
                    "custom-tab-item": true,
                    active: _vm.status == 3,
                  },
                  on: {
                    click: function ($event) {
                      _vm.status = 3
                    },
                  },
                },
                [_c("p", [_vm._v("索赔")])]
              ),
            ]),
          ]),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "auth",
                      rawName: "v-auth",
                      value: 528,
                      expression: "528",
                    },
                  ],
                  staticClass: "blue",
                  attrs: { size: "small", type: "text" },
                  on: {
                    click: function ($event) {
                      _vm.showExportDialog = true
                    },
                  },
                },
                [
                  _vm._v("导出列表"),
                  _c("i", {
                    staticClass: "iconfont grow-icon_daochu el-icon--right",
                  }),
                ]
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          {
            staticClass: "flex h100",
            staticStyle: { flex: "1", overflow: "auto" },
          },
          [
            _c(
              "div",
              { staticStyle: { flex: "1", overflow: "auto" } },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "fixedScrollBar",
                        rawName: "v-fixedScrollBar",
                        value: _vm.$refs.baseContainer,
                        expression: "$refs.baseContainer",
                      },
                    ],
                    staticClass: "custom-table summary",
                    attrs: {
                      data: _vm.carBillList,
                      border: "",
                      "show-summary": "",
                      "span-method": _vm.objectSpanMethod,
                      "summary-method": _vm.getSummaries,
                    },
                    on: {
                      "row-dblclick": function ($event) {
                        return _vm.goDetail($event.billId)
                      },
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { label: "序号", width: "60", prop: "sequence" },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "120",
                        label: "操作门店",
                        prop: "companyName",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "el-tooltip",
                                { attrs: { content: row.companyName } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "text-ellipsis-2-row" },
                                    [
                                      _vm._v(
                                        " " + _vm._s(row.companyName) + " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { width: "170", label: "车单号/业务类型" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "blue pointer",
                                  on: {
                                    click: function ($event) {
                                      return _vm.goDetail(row.billId)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(row.billCode) + " ")]
                              ),
                              row.billType !== 0
                                ? _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("dict")(
                                            row.billType,
                                            "carBillType"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          row.isOthersSelfPayState == 0
                                            ? "自费"
                                            : "他人自费"
                                        ) +
                                        " "
                                    ),
                                  ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "100",
                        label: "进店时间",
                        prop: "dateCreated",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "100",
                        label: "结账时间",
                        prop: "checkDate",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "100",
                        label: "车牌号",
                        prop: "carNumber",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        "min-width": "120",
                        label: "车架号",
                        prop: "uniqueId",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        "min-width": "120",
                        label: "车型",
                        prop: "carModel",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "120",
                        label: "签约客户",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              row.ownerName
                                ? _c("div", [
                                    _c(
                                      "div",
                                      { staticClass: "text-ellipsis" },
                                      [_vm._v(_vm._s(row.ownerName))]
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "120",
                        label: "消费客户",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              row.consumerName
                                ? _c("div", [
                                    _c(
                                      "div",
                                      { staticClass: "text-ellipsis" },
                                      [
                                        _vm._v(
                                          " " + _vm._s(row.consumerName) + " "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "100",
                        label: "业务来源",
                        prop: "source",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "el-tooltip",
                                { attrs: { content: row.source } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "text-ellipsis-2-row" },
                                    [_vm._v(" " + _vm._s(row.source) + " ")]
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "100",
                        label: "进店里程",
                        prop: "mileage",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "170",
                        label: "项目名称",
                        prop: "billServiceName",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "170",
                        label: "项目明细",
                        prop: "itemName",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { width: "80", label: "数量", prop: "serviceNum" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              row.serviceNum
                                ? _c("p", [_vm._v(_vm._s(row.serviceNum))])
                                : _c("p", [_vm._v("-")]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "100",
                        label: "工时报价",
                        prop: "servicePrice",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              row.servicePrice
                                ? _c("p", [_vm._v(_vm._s(row.servicePrice))])
                                : _c("p", [_vm._v("-")]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { width: "80", label: "数量", prop: "goodsNum" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              row.goodsNum
                                ? _c("p", [_vm._v(_vm._s(row.goodsNum))])
                                : _c("p", [_vm._v("-")]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "100",
                        label: "配件报价",
                        prop: "goodsPrice",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              row.goodsPrice
                                ? _c("p", [_vm._v(_vm._s(row.goodsPrice))])
                                : _c("p", [_vm._v("-")]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "100",
                        label: "项目小计",
                        prop: "itemTotal",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              row.itemTotal
                                ? _c("p", [_vm._v(_vm._s(row.itemTotal))])
                                : _c("p", [_vm._v("-")]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { width: "100", label: "单据金额" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              row.paid
                                ? _c("p", [_vm._v(_vm._s(row.paid))])
                                : _c("p", [_vm._v("-")]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "100",
                        label: "已收金额",
                        prop: "alreadyPaid",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              row.alreadyPaid
                                ? _c("p", [_vm._v(_vm._s(row.alreadyPaid))])
                                : _c("p", [_vm._v("-")]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "100",
                        label: "待收金额",
                        prop: "unclearedDebt",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              row.unclearedDebt
                                ? _c("p", [_vm._v(_vm._s(row.unclearedDebt))])
                                : _c("p", [_vm._v("-")]),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
        _c("export-dialog", {
          attrs: {
            visibility: _vm.showExportDialog,
            total: _vm.total,
            pageOption: _vm.pageOption,
            filterForm: _vm.form,
            url: _vm.exportUrl,
          },
          on: {
            "update:visibility": function ($event) {
              _vm.showExportDialog = $event
            },
          },
        }),
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c("pagination", {
            attrs: { total: _vm.total },
            on: { pageChange: _vm.changePage },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }