var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: `查看视频(${_vm.targetIndex + 1}/${_vm.videos.length})`,
        width: "60%",
        visible: _vm.visibility,
        center: "",
        "before-close": _vm.handleClose,
        modal: _vm.modal,
      },
    },
    [
      _c(
        "div",
        { staticClass: "video-player-box" },
        [
          _c("videoPlayer", {
            staticClass: "video-player",
            attrs: { url: _vm.url, options: _vm.options },
          }),
          _c("i", {
            staticClass: "el-icon-arrow-left",
            on: { click: _vm.prev },
          }),
          _c("i", {
            staticClass: "el-icon-arrow-right",
            on: { click: _vm.next },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }