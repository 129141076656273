var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { ref: "baseContainer", attrs: { paddingTop: "" } },
    [
      [
        _c(
          "div",
          [
            _c(
              "el-form",
              {
                attrs: {
                  model: _vm.filterForm,
                  "label-width": "100px",
                  size: "small",
                },
              },
              [
                _c("div", { staticClass: "flex-x-between" }, [
                  _c(
                    "div",
                    { staticClass: "flex-warp flex-shrink0" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "批次时间" } },
                        [
                          _c("el-date-picker", {
                            attrs: { type: "month", placeholder: "选择时间" },
                            model: {
                              value: _vm.times,
                              callback: function ($$v) {
                                _vm.times = $$v
                              },
                              expression: "times",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "包干客户" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入客户名称",
                              clearable: "",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.getList.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.filterForm.clientNameOrPhoneOrNum,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.filterForm,
                                  "clientNameOrPhoneOrNum",
                                  $$v
                                )
                              },
                              expression: "filterForm.clientNameOrPhoneOrNum",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "el-form-item--small" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "btn_search",
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.getList()
                            },
                          },
                        },
                        [_vm._v("查询")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "btn_search",
                          attrs: { size: "small" },
                          on: { click: _vm.resetFilterForm },
                        },
                        [_vm._v("置空")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ],
          1
        ),
      ],
      [
        _c(
          "div",
          { staticClass: "flex-x-end" },
          [
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 544,
                    expression: "544",
                  },
                ],
                staticClass: "blue",
                attrs: { size: "small", type: "text" },
                on: {
                  click: function ($event) {
                    _vm.showExportDialog = true
                  },
                },
              },
              [
                _vm._v("导出列表"),
                _c("i", {
                  staticClass: "iconfont grow-icon_daochu el-icon--right",
                }),
              ]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "content-container" }, [
          _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                flex: "1",
                overflow: "auto",
                display: "flex",
              },
            },
            [
              _c(
                "el-table",
                {
                  staticClass: "custom-table",
                  attrs: { data: _vm.tableData, border: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "index", label: "序号", width: "60" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "auth",
                                    rawName: "v-auth",
                                    value: 445,
                                    expression: "445",
                                  },
                                ],
                                staticClass: "heavy-btn",
                                attrs: {
                                  disabled: _vm.disabled,
                                  type: _vm.types,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleHeavyRun(scope.row)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(_vm.words) + " ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "batchCode",
                      label: "批次号",
                      "min-width": "180",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "p",
                              {
                                staticClass: "blue pointer",
                                on: {
                                  click: function ($event) {
                                    return _vm.goCarBillList(scope.row)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(scope.row.batchCode) + " ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "batchDate",
                      label: "批次生成时间",
                      "min-width": "180",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "updateTime",
                      label: "最后更新时间",
                      "min-width": "180",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "contractClientName",
                      label: "包干客户",
                      "min-width": "150",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "contractTypeChar",
                      label: "包干方式",
                      "min-width": "120",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "billPaids",
                      label: "单据金额",
                      "min-width": "120",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "billCosts",
                      label: "单据成本",
                      "min-width": "120",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "contractTotal",
                      label: "包干金额",
                      "min-width": "100",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "carBillCount",
                      label: "车单数",
                      "min-width": "100",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "storeCount",
                      label: "门店数",
                      "min-width": "100",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "contractState",
                      label: "状态",
                      "min-width": "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                _vm._f("dict")(
                                  scope.row.contractState,
                                  "contractState"
                                )
                              )
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "备注", "min-width": "200" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.contractState == 2
                              ? _c("p", [
                                  _vm._v(" " + _vm._s(scope.row.remark) + " "),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c(
          "template",
          { slot: "footer" },
          [
            _c("pagination", {
              attrs: { total: _vm.total },
              on: { pageChange: _vm.changePage },
            }),
          ],
          1
        ),
      ],
      _c("export-dialog", {
        attrs: {
          visibility: _vm.showExportDialog,
          total: _vm.total,
          pageOption: _vm.pageOption,
          filterForm: _vm.filterForm,
          url: _vm.exportUrl,
        },
        on: {
          "update:visibility": function ($event) {
            _vm.showExportDialog = $event
          },
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }