var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "progress-box",
      style: { height: _vm.width + "px", width: _vm.width + "px" },
    },
    [
      _c("svg", { attrs: { viewBox: "0 0 100 100" } }, [
        _c("path", {
          staticClass: "el-progress-circle__track",
          style: _vm.trailPathStyle,
          attrs: {
            d: _vm.trackPath,
            stroke: _vm.defineBackColor,
            "stroke-width": _vm.relativeStrokeWidth,
            fill: "none",
          },
        }),
        _c("path", {
          staticClass: "el-progress-circle__path",
          style: _vm.circlePathStyle,
          attrs: {
            d: _vm.trackPath,
            stroke: _vm.stroke,
            fill: "none",
            "stroke-linecap": _vm.strokeLinecap,
            "stroke-width": _vm.percentage ? _vm.relativeStrokeWidth : 0,
          },
        }),
      ]),
      _c(
        "div",
        {
          staticClass: "content",
          style: {
            width: _vm.width - _vm.strokeWidth * 2 + "px",
            height: _vm.width - _vm.strokeWidth * 2 + "px",
          },
        },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }