var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        "append-to-body": true,
        visible: _vm.dialogTableVisible,
        "before-close": _vm.handleClose,
        "close-on-click-modal": false,
      },
    },
    [
      _c(
        "base-container",
        [
          _c(
            "template",
            { slot: "header" },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    "label-width": "110px",
                    size: "small",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "三包厂家名称:",
                                prop: "undertakeName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入",
                                  disabled: _vm.inputTypes,
                                },
                                model: {
                                  value: _vm.form.undertakeName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "undertakeName", $$v)
                                  },
                                  expression: "form.undertakeName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "三包厂家编码:" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入",
                                  disabled: _vm.inputTypes,
                                },
                                model: {
                                  value: _vm.form.undertakeNum,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "undertakeNum", $$v)
                                  },
                                  expression: "form.undertakeNum",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              directives: [
                                {
                                  name: "auth",
                                  rawName: "v-auth",
                                  value: 491,
                                  expression: "491",
                                },
                              ],
                              attrs: { label: "启用状态:" },
                            },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-color": "#33ab79",
                                  "inactive-color": "#999999",
                                },
                                model: {
                                  value: _vm.chioceType,
                                  callback: function ($$v) {
                                    _vm.chioceType = $$v
                                  },
                                  expression: "chioceType",
                                },
                              }),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    color: "#999999",
                                    "margin-left": "5px",
                                    "font-size": "10px",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.chioceType
                                          ? "(已选择启用本保险公司)"
                                          : "(已选择禁用本保险公司)"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "联系电话:" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输固话或手机号",
                                  maxlength: "11",
                                  "show-word-limit": "",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.phoneCofime(
                                      $event,
                                      "undertakePhone"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.form.undertakePhone,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "undertakePhone", $$v)
                                  },
                                  expression: "form.undertakePhone",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "联系人:" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入" },
                                model: {
                                  value: _vm.form.undertakeContact,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "undertakeContact", $$v)
                                  },
                                  expression: "form.undertakeContact",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "备用电话:" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入",
                                  maxlength: "11",
                                  "show-word-limit": "",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.phoneCofime(
                                      $event,
                                      "undertakeAlternatePhone"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.form.undertakeAlternatePhone,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "undertakeAlternatePhone",
                                      $$v
                                    )
                                  },
                                  expression: "form.undertakeAlternatePhone",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "传真:" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入" },
                                model: {
                                  value: _vm.form.undertakeFax,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "undertakeFax", $$v)
                                  },
                                  expression: "form.undertakeFax",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "联系地址:" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入",
                                  maxlength: "100",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.form.undertakeAddress,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "undertakeAddress", $$v)
                                  },
                                  expression: "form.undertakeAddress",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "备注:" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: 5,
                                  placeholder: "请输入",
                                  maxlength: "100",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.form.undertakeRemark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "undertakeRemark", $$v)
                                  },
                                  expression: "form.undertakeRemark",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c("div", { staticClass: "add_more" }, [
                          _c(
                            "div",
                            { staticClass: "flex flex-x-center" },
                            [
                              _vm._v(" 开票主体 "),
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    effect: "light",
                                    placement: "bottom-start",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "#666666",
                                        "font-size": "13px",
                                        "line-height": "20px",
                                        "text-indent": "15px",
                                      },
                                      attrs: { slot: "content" },
                                      slot: "content",
                                    },
                                    [
                                      _vm._v(
                                        " 三包厂家的开票主体，即为索赔回款管理门店。 "
                                      ),
                                    ]
                                  ),
                                  _c("i", {
                                    staticClass: "iconfont grow-icon_doubt",
                                    staticStyle: { color: "#9a9a9a" },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "add_mores",
                              on: {
                                click: function ($event) {
                                  return _vm.addMore()
                                },
                              },
                            },
                            [_vm._v("添加更多")]
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._l(_vm.invoices, function (item, index) {
                    return _c("el-row", { key: index }, [
                      _c(
                        "div",
                        { staticClass: "table_date" },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-top": "2px",
                                "margin-right": "5px",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "iconfont grow-icon_delete3",
                                staticStyle: {
                                  color: "#ff9200",
                                  "font-size": "22px",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteInvoices(index)
                                  },
                                },
                              }),
                            ]
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 11 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "100",
                                    prop: "companyId-" + index,
                                    rules: [
                                      {
                                        trigger: "blur",
                                        validator: _vm.valiCompany,
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c("template", { slot: "label" }, [
                                    _c("span", [_vm._v("开票主体")]),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#f00" } },
                                      [_vm._v("*")]
                                    ),
                                    _vm._v(": "),
                                  ]),
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        filterable: "",
                                        remote: "",
                                        placeholder: "搜索开票主体名称",
                                        "remote-method": _vm.searchInvoice,
                                        loading: _vm.searchInvoiceLoading,
                                      },
                                      on: { change: _vm.selectInvoice },
                                      model: {
                                        value: item.companyId,
                                        callback: function ($$v) {
                                          _vm.$set(item, "companyId", $$v)
                                        },
                                        expression: "item.companyId",
                                      },
                                    },
                                    _vm._l(
                                      _vm.undertakeListOptions,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.companyId,
                                          attrs: {
                                            label: item.invoicingEntity,
                                            value: item.companyId,
                                            disabled: item.disabled,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  }),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c("div", { staticClass: "add_more" }, [
                          _c("div", { staticClass: "flex flex-x-center" }, [
                            _vm._v("适用门店"),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c("p", { staticClass: "describe" }, [
                    _vm._v(
                      " 适用门店说明：设置适用门店后，只有适用门店才能选择本三包厂家进行索赔业务接待开单 "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "o_radio" },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 0 },
                          model: {
                            value: _vm.form.hasAppliedStore,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "hasAppliedStore", $$v)
                            },
                            expression: "form.hasAppliedStore",
                          },
                        },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v("无限制，全部门店"),
                          ]),
                        ]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 1 },
                          model: {
                            value: _vm.form.hasAppliedStore,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "hasAppliedStore", $$v)
                            },
                            expression: "form.hasAppliedStore",
                          },
                        },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v("有限制，部分门店"),
                          ]),
                        ]
                      ),
                      _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.form.hasAppliedStore == 1,
                              expression: "form.hasAppliedStore == 1",
                            },
                          ],
                          staticClass: "buttons",
                          on: {
                            click: function ($event) {
                              _vm.storeDrawerVisibility = true
                            },
                          },
                        },
                        [_vm._v(" 选择门店 ")]
                      ),
                      _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.form.hasAppliedStore == 0,
                              expression: "form.hasAppliedStore == 0",
                            },
                          ],
                          staticClass: "descri_word",
                        },
                        [
                          _vm._v(
                            " (已选择全部门店，适用门店是不固定，后续新增的门店将自动适用) "
                          ),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.form.hasAppliedStore == 1,
                              expression: "form.hasAppliedStore == 1",
                            },
                          ],
                          staticClass: "descri_word_t",
                        },
                        [
                          _vm._v(
                            " (已选择部分门店，适用门店是固定的，新增的门店需要更新设置) "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm.tableData.length != 0
                    ? _c(
                        "div",
                        { staticStyle: { "margin-top": "10px" } },
                        [
                          _c(
                            "el-table",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                data: _vm.tableData,
                                "max-height": "250",
                                border: "",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: { label: "操作", width: "80" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "text",
                                                size: "small",
                                              },
                                              nativeOn: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.deleteRow(
                                                    scope.$index,
                                                    _vm.tableData
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "iconfont grow-icon_delete3",
                                                staticStyle: {
                                                  color: "#ff9200",
                                                  "font-size": "22px",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  930586893
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  type: "index",
                                  label: "序号",
                                  width: "80",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "companyId",
                                  label: "车店编号",
                                  "min-width": "120",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "companyName",
                                  label: "车店名称",
                                  "min-width": "120",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "chainCompanyType",
                                  label: "连锁类型",
                                  "min-width": "120",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("dict")(
                                                  scope.row.chainCompanyType,
                                                  "chainCompanyType"
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2662557993
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
              _vm.storeDrawerVisibility
                ? _c("select-store", {
                    attrs: {
                      storeDrawerVisibility: _vm.storeDrawerVisibility,
                      stores: _vm.storeslist,
                    },
                    on: {
                      "update:storeDrawerVisibility": function ($event) {
                        _vm.storeDrawerVisibility = $event
                      },
                      "update:store-drawer-visibility": function ($event) {
                        _vm.storeDrawerVisibility = $event
                      },
                      select: _vm.storeIds,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          { staticClass: "flex-x-end foot-btn" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.saveList },
              },
              [_vm._v("确认保存")]
            ),
            _c(
              "el-button",
              {
                staticStyle: { background: "#f0f2f5" },
                attrs: { size: "small" },
                on: { click: _vm.handleClose },
              },
              [_vm._v("取消")]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }