<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <div>
        <el-form
          class="filter-form"
          :model="form"
          label-width="100px"
          size="small"
        >
          <div class="flex-x-between">
            <div class="flex-warp flex-shrink0">
              <el-form-item label="选择日期">
                <el-date-picker
                  class="w100"
                  v-model="form.startDateTimes"
                  type="daterange"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="起始时间"
                  end-placeholder="截止时间"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="接车单号">
                <el-input
                  v-model="form.billCode"
                  placeholder="请输入接车单号"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="返修项目">
                <el-input
                  v-model="form.servName"
                  placeholder="请输入返修项目"
                  clearable
                ></el-input>
              </el-form-item>
            </div>
            <div class="flex">
              <div class="el-form-item--small">
                <div
                  @click="showMore = !showMore"
                  class="el-form-item__label pointer"
                >
                  更多筛选
                  <i
                    :class="{
                      primary: true,
                      'el-icon-arrow-right': !showMore,
                      'el-icon-arrow-down': showMore,
                    }"
                  ></i>
                </div>
                <div class="flex">
                  <el-button
                    v-auth="670"
                    class="btn_search"
                    type="primary"
                    size="small"
                    @click="doSearch"
                    >查询</el-button
                  >
                  <el-button class="btn_search" size="small" @click="empty"
                    >置空</el-button
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="flex-warp" v-show="showMore">
            <el-form-item>
              <template slot="label">
                <div>
                  返修门店
                  <el-tooltip
                    effect="light"
                    content="返修门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)"
                    placement="bottom-start"
                  >
                    <i
                      class="iconfont grow-icon_doubt"
                      style="color: #9a9a9a"
                    ></i>
                  </el-tooltip>
                </div>
              </template>
              <operateCompanyCascader
                v-model="form.repairCompanyIds"
              ></operateCompanyCascader>
            </el-form-item>
            <el-form-item>
              <template slot="label">
                <div>
                  接待门店
                  <el-tooltip
                    effect="light"
                    content="原接待门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)"
                    placement="bottom-start"
                  >
                    <i
                      class="iconfont grow-icon_doubt"
                      style="color: #9a9a9a"
                    ></i>
                  </el-tooltip>
                </div>
              </template>
              <operateCompanyCascader
                v-model="form.sourceCompanyIds"
              ></operateCompanyCascader>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </template>
    <!-- 以下是表格 -->
    <template>
      <div class="btns flex-x-between">
        <div></div>
        <div>
          <el-button
            v-auth="669"
            size="small"
            type="text"
            class="blue"
            @click="showExportDialog = true"
            >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
          ></el-button>
        </div>
      </div>
      <div class="flex h100" style="flex: 1; overflow: auto">
        <div class="h100" style="flex: 1; overflow: auto">
          <el-table
            class="custom-table"
            :data="recordTableData"
            border
            :header-cell-style="headerCellStyle"
            v-fixedScrollBar="$refs.baseContainer"
          >
            <el-table-column type="index" label="序号" width="80">
            </el-table-column>
            <el-table-column width="180" label="操作">
              <template #default="{ row }">
                <span
                  v-auth="671"
                  class="text_operation blue"
                  style="margin-right: 10px"
                  @click="openRemarkEditDialog(row)"
                  >备注编辑</span
                >
              </template>
            </el-table-column>
            <el-table-column width="180" label="创建时间" prop="dateCreated">
            </el-table-column>
            <el-table-column width="180" label="车牌号" prop="carNumber">
            </el-table-column>
            <el-table-column
              width="180"
              label="返修门店"
              prop="repairCompanyName"
            >
            </el-table-column>
            <el-table-column width="180" label="返修车单" prop="repairBillCode">
              <template #default="{ row }">
                <span
                  class="text_operation blue"
                  @click="goCarBillDetail(row.pairBillId)"
                  >{{ row.repairBillCode }}</span
                >
              </template>
            </el-table-column>
            <el-table-column
              width="180"
              label="返修接待时间"
              prop="repairBillDateCreated"
            >
            </el-table-column>
            <el-table-column width="180" label="返修项目" prop="repairServItem">
            </el-table-column>
            <el-table-column
              width="180"
              label="返修技师"
              prop="repairServTechnician"
            >
            </el-table-column>
            <el-table-column
              width="180"
              label="原接待门店"
              prop="sourceCompanyName"
            >
            </el-table-column>
            <el-table-column width="180" label="原接车单号">
              <template #default="{ row }">
                <span
                  class="text_operation blue"
                  @click="goCarBillDetail(row.sourceBillId)"
                  >{{ row.sourceBillCode }}</span
                >
              </template>
            </el-table-column>
            <el-table-column
              width="180"
              label="原接待时间"
              prop="sourceBillDateCreated"
            >
            </el-table-column>
            <el-table-column width="180" label="原项目" prop="sourceServItem">
            </el-table-column>
            <el-table-column
              width="180"
              label="原技师"
              prop="sourceServTechnician"
            >
            </el-table-column>
            <el-table-column mix-width="100" label="备注" prop="remark">
              <template #default="{ row }">
                <el-tooltip :content="row.remark">
                  <div class="text-ellipsis-2-row">
                    {{ row.remark }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <export-dialog
        :visibility.sync="showExportDialog"
        :total="total"
        :pageOption="pageOption"
        :filterForm="form"
        :url="exportUrl"
      ></export-dialog>
      <el-dialog
        :close-on-click-modal="false"
        title="疑似返修备注编辑"
        :visible.sync="showRemarkEditDialog"
        width="30%"
      >
        <p>点击“确定”，更新疑似返修备注信息。</p>
        <div>
          <el-row>
            <el-col :span="24">
              <el-input
                maxlength="250"
                type="textarea"
                class="textarea"
                :rows="3"
                resize="none"
                placeholder="请输入疑似返修备注"
                v-model="repairDiscoverRemark"
              ></el-input>
            </el-col>
          </el-row>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showRemarkEditDialog = false">取 消</el-button>
          <el-button type="primary" @click="confirmRemarkUpdate"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </template>

    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
  </base-container>
</template>
<script>
// 分页组件
import pagination from '@/components/Pagination.vue';
import ExportDialog from '@/components/ExportDialog';
import operateCompanyCascader from '@/components/businessComponent/operateCompanyCascader';

//接口
import {
  getRepairDiscoverList,
  updateRepairDiscoverRemark,
  exportRepairDiscoverListUrl,
} from '@/api/carBill/repairDiscoverRecord';
import { hasAuth } from '@/utils/permissions';
// 工具
import { mapState } from 'vuex';
export default {
  name: 'repairDiscoverRecordList',
  components: { pagination, ExportDialog, operateCompanyCascader },
  data() {
    return {
      form: {},
      // 展示更多筛选
      showMore: true,
      //导出的页面设置
      exportUrl: exportRepairDiscoverListUrl,

      //分页组件
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      showExportDialog: false,
      // 列表数据
      recordTableData: [],
      editRow: {},
      repairDiscoverRemark: '',
      showRemarkEditDialog: false,
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  created() {
    this.init();
    this.getList();
  },
  mounted() {},
  methods: {
    init() {
      this.form.repairCompanyIds = [this.companyId];
      this.form.sourceCompanyIds = [this.companyId];
    },
    //置空输入框数据
    empty() {
      this.form = {
        repairCompanyIds: [this.companyId],
        sourceCompanyIds: [this.companyId],
      };
    },
    // 更改页码
    changePage(e) {
      this.pageOption = e;
      this.getList(false);
    },
    /**
     * 点击查询
     */
    doSearch() {
      this.pageOption = {
        size: 10,
        current: 1,
      };
      this.getList();
    },
    //获取列表数据
    getList(reset = true) {
      if (!hasAuth(670)) {
        return;
      }
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      var otherConditions = {
        startDateTime: this.form.startDateTimes?.[0]
          ? this.form.operationTime?.[0]
          : '',
        endDateTime: this.form.startDateTimes?.[1]
          ? this.form.operationTime?.[1]
          : '',
      };
      var data = Object.assign(this.form, this.pageOption, otherConditions);
      delete data.startDateTimes;

      getRepairDiscoverList(data).then((res) => {
        this.total = res.total;
        this.recordTableData = res.records;
      });
    },
    /**
     * 跳转车单详情页
     */
    goCarBillDetail(id) {
      this.$router.push({
        name: 'carBillDetail',
        query: {
          id: id,
          t: new Date().getTime(),
        },
      });
    },
    /**
     * 打开返修备注
     * @param row 列表行数据
     */
    openRemarkEditDialog(row) {
      this.editRow = row;
      this.repairDiscoverRemark = row.remark || '';
      this.showRemarkEditDialog = true;
    },
    /**
     * 确认更新备注
     */
    confirmRemarkUpdate() {
      if (this.repairDiscoverRemark === '') {
        this.$message.error('疑似返修备注不能为空');
        return;
      }
      const data = {
        discoverId: this.editRow.id,
        remark: this.repairDiscoverRemark,
      };
      updateRepairDiscoverRemark(data)
        .then((res) => {
          this.$message.success('更新成功');
          this.getList(false);
          this.showRemarkEditDialog = false;
        })
        .catch((err) => {
          this.$message.error(err.message || err || '更新失败');
        });
    },
  },
};
</script>
<style lang="scss" scoped>
//状态与统计的样式
.class-box {
  width: 15%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  box-sizing: border-box;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  .title {
    flex-shrink: 0;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-bottom: 1px solid #e4e4e4;
    background: #fafafa;
    color: #888;
    font-size: 14px;
  }
}
.tree-node {
  color: #888;
  font-size: 14px;
}
</style>
