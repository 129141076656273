import http from '@/service/http';
import store from '@/store';
const defaultListOption = {
  current: 1,
  size: 15,
};
// 业务来源列表
export function getBillSourceTypeList(
  data = defaultListOption,
  config = { noShowWaiting: true }
) {
  data = Object.assign(
    {
      companyId: store?.state?.user?.userInfo?.companyId,
      isActive: true,
    },
    defaultListOption,
    data || {}
  );
  return http.post('/admin/car/billSourceType/list', data, config);
}

// 检索用户列表
export function searchClient(
  keyWord = '',
  size = 10,
  current = 1,
  config = { noShowWaiting: true }
) {
  return http.post(
    '/admin/client/getClientBySearch',
    {
      clientMsg: keyWord,
      size,
      current,
      companyId: store?.state?.user?.userInfo?.companyId,
    },
    config
  );
}

//开单、客户协议选择客户中的弹窗要有翻页
export function getClientBySearch(data) {
  return http.post('/admin/client/getClientBySearch', data, {
    noShowWaiting: true,
  });
}

//新增
export function saveBillSource(data) {
  return http.post('/admin/car/billSourceType/save', data);
}

//编辑
export function updateBillSource(data) {
  return http.post('/admin/car/billSourceType/update', data);
}

//删除
export function delectBillSource(id, currentCompanyId) {
  return http.post('/admin/car/billSourceType/deleteById', {
    id,
    currentCompanyId,
  });
}
