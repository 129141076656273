import http from '@/service/http';

const defaultListOption = {
  current: 1,
  size: 10,
};

// 车型列表
export function getModelList(data = defaultListOption) {
  return http.post('/admin/car/model/abbreviated/page', data);
}

// 新增车型
export function addCarModel(data) {
  return http.post('/admin/car/model/add', data);
}

// 编辑车型
export function editCarModel(data) {
  return http.post('/admin/car/model/update', data);
}

// 搜索车品牌 分页
export function searchCarLogo(data = defaultListOption, config = {}) {
  return http.post('/admin/car/model/getCarLogoPage', data, config);
}

// 搜索车系 分页
export function searchCarSeries(data = defaultListOption, config = {}) {
  return http.post('/admin/car/model/getCarSeriesPage', data, config);
}

// 新增品牌
export function addCarLogo(data) {
  return http.post('/admin/car/logo/add', data);
}

// 编辑品牌
export function updateCarLogo(data) {
  return http.post('/admin/car/logo/update', data);
}

// 品牌详情
export function getCarLogoDetail(data) {
  return http.post('/admin/car/logo/detail', data);
}

// 编辑车系
export function updateCarSeries(data) {
  return http.post('/admin/car/series/update', data);
}

// 车系详情
export function getCarSeriesDetail(data) {
  return http.post('/admin/car/series/detail', data);
}

// 查询车型有无关联
export function queryModelIsBindingCar(data) {
  return http.post('/admin/car/model/query', data);
}

// 删除车型
export function deleteCarModel(data) {
  return http.post('/admin/car/model/delete', data);
}
