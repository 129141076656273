var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visibility,
        width: "900px",
        "before-close": _vm.handleClose,
        "close-on-click-modal": false,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "editForm",
          staticStyle: { "margin-top": "18px" },
          attrs: {
            size: "small",
            model: _vm.editForm,
            rules: _vm.rules,
            "label-width": "110px",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c("el-form-item", { attrs: { label: "录入门店:" } }, [
                    _vm._v(
                      " " + _vm._s(_vm.editForm.company.companyName) + " "
                    ),
                  ]),
                  _c("el-form-item", { attrs: { label: "商品名称:" } }, [
                    _vm._v(" " + _vm._s(_vm.editForm.goods.name) + " "),
                  ]),
                  _c("el-form-item", { attrs: { label: "商品标准名称:" } }, [
                    _vm._v(" " + _vm._s(_vm.editForm.goods.standardName) + " "),
                  ]),
                  _c("el-form-item", { attrs: { label: "商品品牌:" } }, [
                    _vm._v(" " + _vm._s(_vm.editForm.goods.brandName) + " "),
                  ]),
                  _c("el-form-item", { attrs: { label: "商品编号:" } }, [
                    _vm._v(" " + _vm._s(_vm.editForm.goods.goodsCode) + " "),
                  ]),
                  _c("el-form-item", { attrs: { label: "单位:" } }, [
                    _vm._v(" " + _vm._s(_vm.editForm.goods.unit) + " "),
                  ]),
                  _c("el-form-item", { attrs: { label: "售价:" } }, [
                    _vm._v(" " + _vm._s(_vm.editForm.goods.price) + " "),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否启用", prop: "isActive" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-text": _vm.editForm.isActive ? "是" : "否",
                          "active-value": 1,
                          "inactive-value": 0,
                        },
                        model: {
                          value: _vm.editForm.isActive,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "isActive", $$v)
                          },
                          expression: "editForm.isActive",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否共享", prop: "isShare" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-text": _vm.editForm.isShare ? "是" : "否",
                          "active-value": 1,
                          "inactive-value": 0,
                        },
                        model: {
                          value: _vm.editForm.isShare,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "isShare", $$v)
                          },
                          expression: "editForm.isShare",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-col", { attrs: { span: 12, offset: 0 } }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-checkbox",
                      {
                        attrs: {
                          size: "large",
                          value: "0",
                          indeterminate: _vm.isIndeterminate,
                        },
                        on: { change: _vm.handleSelectDisableAllModule },
                        model: {
                          value: _vm.editForm.disableAllModule,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "disableAllModule", $$v)
                          },
                          expression: "editForm.disableAllModule",
                        },
                      },
                      [_vm._v("全部禁用")]
                    ),
                    _c("div", { staticStyle: { height: "10px" } }),
                    _c(
                      "el-checkbox-group",
                      {
                        on: { change: _vm.handleSelectDisableModule },
                        model: {
                          value: _vm.editForm.disableModule,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "disableModule", $$v)
                          },
                          expression: "editForm.disableModule",
                        },
                      },
                      _vm._l(
                        _vm.inventoryGoodsControlModuleArray,
                        function (config) {
                          return _c(
                            "el-checkbox",
                            {
                              key: config.value,
                              staticStyle: {
                                margin: "10px 10px 10px 0px",
                                width: "100px",
                              },
                              attrs: { label: config.value, size: "large" },
                            },
                            [_vm._v(_vm._s(config.desc) + " ")]
                          )
                        }
                      ),
                      1
                    ),
                  ],
                  1
                ),
                _c("div", { staticStyle: { margin: "40px 0px 20px" } }, [
                  _c(
                    "div",
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 1 },
                          model: {
                            value: _vm.editForm.disableAllCompany,
                            callback: function ($$v) {
                              _vm.$set(_vm.editForm, "disableAllCompany", $$v)
                            },
                            expression: "editForm.disableAllCompany",
                          },
                        },
                        [
                          _c("span", { staticStyle: { color: "#333" } }, [
                            _vm._v("全部门店"),
                          ]),
                        ]
                      ),
                      _c(
                        "el-radio",
                        {
                          staticStyle: { "margin-left": "30px" },
                          attrs: { label: 0 },
                          model: {
                            value: _vm.editForm.disableAllCompany,
                            callback: function ($$v) {
                              _vm.$set(_vm.editForm, "disableAllCompany", $$v)
                            },
                            expression: "editForm.disableAllCompany",
                          },
                        },
                        [
                          _c("span", { staticStyle: { color: "#333" } }, [
                            _vm._v("部分门店"),
                          ]),
                        ]
                      ),
                      _vm.editForm.disableAllCompany == 0
                        ? _c(
                            "el-button",
                            {
                              staticClass: "btn_insert",
                              attrs: { size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.addStore("disablePartCompanyIds")
                                },
                              },
                            },
                            [_vm._v("选择门店")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "table-box" },
                    [
                      _vm.editForm.disableAllCompany == 0
                        ? _c(
                            "el-table",
                            {
                              attrs: {
                                border: "",
                                data: _vm.editForm.disablePartCompanyIds,
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: { label: "操作", width: "80" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ $index }) {
                                        return [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "text",
                                                size: "small",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteRow(
                                                    _vm.editForm
                                                      .disablePartCompanyIds,
                                                    $index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "iconfont grow-icon_delete3",
                                                staticStyle: {
                                                  color: "#ff9200",
                                                  "font-size": "22px",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3721780471
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  type: "index",
                                  label: "序号",
                                  width: "60",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  width: "120",
                                  prop: "companyId",
                                  label: "车店编号",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "companyName",
                                  label: "车店名称",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  width: "100",
                                  prop: "type",
                                  label: "连锁类型",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("dict")(
                                                scope.row.chainCompanyType,
                                                "chainCompanyType"
                                              )
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3868694802
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.storeDrawerVisibility
        ? _c("select-store", {
            attrs: {
              storeDrawerVisibility: _vm.storeDrawerVisibility,
              stores: _vm.addStoreData,
            },
            on: {
              "update:storeDrawerVisibility": function ($event) {
                _vm.storeDrawerVisibility = $event
              },
              "update:store-drawer-visibility": function ($event) {
                _vm.storeDrawerVisibility = $event
              },
              select: _vm.handleSelectStore,
            },
          })
        : _vm._e(),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.save },
            },
            [_vm._v(" 确 定 ")]
          ),
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.handleClose } },
            [_vm._v(" 取 消 ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }