<template>
  <div>
    <el-dialog
      title="终检详情"
      width="60%"
      :visible="visibility"
      :before-close="handleClose"
      :close-on-click-modal="false"
      append-to-body
    >
      <div class="quality-testing_box">
        <div class="bill-info">
          <div class="row flex-x-between">
            <div class="col flex">
              <div class="label">接车单：</div>
              <div class="value">
                <span
                  class="blue pointer"
                  @click="goCarBillDetail(detailData.billId)"
                  >{{ detailData.billCode }}</span
                >
                <span v-if="detailData.billType == 0" class="color999">
                  (
                  {{
                    detailData.isOthersSelfPayState ? '他人自费' : '车主自费'
                  }})
                </span>
                <span v-else class="color999">
                  ( {{ detailData.billType | dict('carBillType') }})
                </span>
              </div>
            </div>
            <div class="col flex">
              <div class="label">预交车时间：</div>
              <div class="value">
                <span v-if="detailData.planFinishTime">{{
                  detailData.planFinishTime
                }}</span>
                <span v-if="detailData.waitInStore" class="color999"
                  >( 在店等)
                </span>
                <span v-if="detailData.undetermined"> 待定 </span>
              </div>
            </div>
          </div>
          <div style="margin-top: 20px" class="row flex-x-between">
            <div class="col flex">
              <div class="label">车牌号：</div>
              <div class="value">
                <span>{{ detailData.carNumber }}</span>
                <span v-if="detailData.vehicleType !== undefined">
                  ({{ detailData.vehicleType }})
                </span>
              </div>
            </div>
            <div class="col flex">
              <div class="label">车型/车架号：</div>
              <div class="value">
                <span>{{ detailData.carModel }}</span>
                <span
                  style="display: block; margin-top: 10px"
                  v-if="detailData.uniqueId"
                >
                  VIN: {{ detailData.uniqueId }}
                </span>
              </div>
            </div>
          </div>
          <div style="margin-top: 20px" class="row flex-x-between">
            <div class="col flex">
              <div class="label">最后质检时间：</div>
              <div class="value">
                <span>{{ detailData.taskFinishTime }}</span>
              </div>
            </div>
            <div class="col flex">
              <div class="label">最后质检员：</div>
              <div class="value">
                <span>{{ detailData.taskFinishUserName }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="title">本次检车问题及所报项目</div>
        <div class="list">
          <div class="item">
            <div class="flex-y-center">
              <div class="cell" style="width: 120px; text-align: center">
                <span>1.前保险杠</span>
                <br />
                <span class="blue pointer">检车图片(3)</span>
              </div>
              <div style="width: 260px" class="cell">
                <p class="p">
                  问题详情说明xxxxxxxxxxxxxxxxxxxx， 最多可三行显示，超长 ...
                  标识，鼠标悬触冒泡全显
                </p>
              </div>
              <div class="cell">
                <div class="flex-col flex-y-center">
                  <el-switch :value="true"></el-switch>
                  <br />
                  <span>已修复</span>
                </div>
                <el-input type="textarea" :rows="4" placeholder="请输入内容">
                </el-input>
                <upload-file-list></upload-file-list>
              </div>
            </div>
            <el-table border :data="qualityList" style="width: 100%">
              <el-table-column type="index" label="序号" width="80">
              </el-table-column>
              <el-table-column label="报价项目" width="180">
                <template #default="{ row }">
                  <span>{{ row.itemName }}</span>
                  <span>编码：{{ row.itemCode }}</span>
                </template>
              </el-table-column>
              <el-table-column width="110" prop="tags" label="内部标签">
              </el-table-column>
              <el-table-column width="110" label="施工技师">
                <template #default="{ row }">
                  <div>{{ row.uname }}</div>
                  <span>({{ row.dept }})</span>
                </template>
              </el-table-column>
              <el-table-column width="110" prop="rework" label="返工次数">
              </el-table-column>
              <el-table-column width="110" prop="finishDate" label="完工时间">
              </el-table-column>
              <el-table-column header-align="center" label="历史返工详情">
                <el-table-column width="110" label="质检时间" prop="date">
                </el-table-column>
                <el-table-column width="110" label="质检员" prop="employee">
                </el-table-column>
                <el-table-column label="返工原因" prop="reason">
                </el-table-column>
              </el-table-column>
            </el-table>
            <div class="split-row"></div>
          </div>
          <div class="item">
            <div class="flex-y-center">
              <div style="width: 120px" class="cell">2.空调不制冷</div>
              <div style="width: 260px" class="cell">
                <p class="p">
                  问题详情说明xxxxxxxxxxxxxxxxxxxx， 最多可三行显示，超长 ...
                  标识，鼠标悬触冒泡全显
                </p>
                <span class="flex w100 blue" style="margin-left: 5px"
                  >检车图片</span
                >
              </div>
              <div style="width: 260px" class="cell">
                <p class="p">
                  问题详情说明xxxxxxxxxxxxxxxxxxxx， 最多可三行显示，超长 ...
                  标识，鼠标悬触冒泡全显
                </p>
                <span class="flex w100 blue" style="margin-left: 5px"
                  >质检图片</span
                >
              </div>
              <div class="cell" style="text-align: center">
                <el-switch :value="true"></el-switch>
                <br />
                <span>已修复</span>
              </div>
            </div>
            <el-table border :data="qualityList" style="width: 100%">
              <el-table-column type="index" label="序号" width="80">
              </el-table-column>
              <el-table-column label="报价项目" width="180">
                <template #default="{ row }">
                  <span>{{ row.itemName }}</span>
                  <span>编码：{{ row.itemCode }}</span>
                </template>
              </el-table-column>
              <el-table-column width="110" prop="tags" label="内部标签">
              </el-table-column>
              <el-table-column width="110" label="施工技师">
                <template #default="{ row }">
                  <div>{{ row.uname }}</div>
                  <span>({{ row.dept }})</span>
                </template>
              </el-table-column>
              <el-table-column width="110" prop="rework" label="返工次数">
              </el-table-column>
              <el-table-column width="110" prop="finishDate" label="完工时间">
              </el-table-column>
              <el-table-column header-align="center" label="历史返工详情">
                <el-table-column width="110" label="质检时间" prop="date">
                </el-table-column>
                <el-table-column width="110" label="质检员" prop="employee">
                </el-table-column>
                <el-table-column label="返工原因" prop="reason">
                </el-table-column>
              </el-table-column>
            </el-table>
          </div>
        </div> -->
        <!-- bug764隐藏 -->
        <!-- <div class="title">非检车问题所报项目</div> -->
        <el-table
          border
          :data="taskList"
          style="width: 100%"
          :span-method="spanMethod"
        >
          <el-table-column label="序号" width="80" prop="index">
          </el-table-column>
          <el-table-column label="报价项目" width="180">
            <template #default="{ row }">
              <p>{{ row.serviceItemName }}</p>
              <span v-if="row.serviceItemCode"
                >编码：{{ row.serviceItemCode }}</span
              >
            </template>
          </el-table-column>
          <el-table-column width="110" prop="tags" label="内部标签">
          </el-table-column>
          <el-table-column width="110" label="施工技师">
            <template #default="{ row }">
              <div>{{ row.technicianNames }}</div>
              <!-- <span>({{ row.dept }})</span> -->
            </template>
          </el-table-column>
          <el-table-column width="110" prop="reworkTimes" label="返工次数">
          </el-table-column>
          <el-table-column width="160" prop="updateTime" label="最后完工时间">
          </el-table-column>
          <el-table-column header-align="center" label="历史返工详情">
            <el-table-column
              width="140"
              label="质检时间"
              prop="qualityInspectionTime"
            >
            </el-table-column>
            <el-table-column
              width="110"
              label="质检员"
              prop="qualityInspectionUserName"
            >
            </el-table-column>
            <el-table-column label="返工原因" prop="reworkReason">
            </el-table-column>
          </el-table-column>
        </el-table>
      </div>
      <template slot="footer">
        <el-button size="small" @click="handleClose">取 消</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import {
  getForDispatchDetailsById,
  completionQualityInspection,
} from '@/api/carBill';
export default {
  name: 'qualityTestResultDialog',
  props: {
    visibility: { type: Boolean, default: false },
    id: {
      type: [Number, String],
    },
    dispatchType: {
      type: [Number, String],
    },
  },
  data() {
    return {
      detailData: {},
      // 列表
      taskList: [],
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.companyId,
      userId: (state) => state.user.userInfo?.userId,
    }),
  },
  created() {
    this.getData();
  },
  methods: {
    /**
     * getData()
     */
    async getData() {
      let res = await getForDispatchDetailsById({
        id: this.id,
        dispatchType: this.dispatchType,
      });

      let { taskBillServiceItems = [], ...data } = res;

      let rowIndex = 0;

      //给返回的数据重新组合
      taskBillServiceItems.forEach((item, index) => {
        if (item.taskBillReworkDetails?.length) {
          item.rowspan = item.taskBillReworkDetails.length;
          item.taskBillReworkDetails.forEach((el, index) => {
            if (index === 0) {
              el.index = ++rowIndex;
              el.isParent = true;
              el.rowspan = item.taskBillReworkDetails.length;
            } else {
              el.isChild = true;
            }
            this.taskList.push(
              Object.assign(this.$lodash.cloneDeep(el), {
                billServiceId: item.billServiceId,
                reworkTimes: item.reworkTimes,
                serviceItemName: item.serviceItemName,
                taskBillId: item.taskBillId,
                taskBillState: item.taskBillState,
                technicianNames: item.technicianNames,
                updateTime: item.updateTime,
                qualityInspectionTime: el.qualityInspectionTime,
                qualityInspectionUserId: el.qualityInspectionUserId,
                qualityInspectionUserName: el.qualityInspectionUserName,
                reworkReason: el.reworkReason,
                reworkPics: el.reworkPics,
              })
            );
          });
        } else {
          item.index = ++rowIndex;
          this.taskList.push(item);
        }
      });
      this.detailData = data;
    },
    /**
     * 表格合并方法
     */
    spanMethod({ row, column, rowIndex, columnIndex }) {
      if ([0, 1, 2, 3, 4, 5].includes(columnIndex)) {
        if (row.isParent) {
          return {
            rowspan: row.rowspan,
            colspan: 1,
          };
        } else if (row.isChild) {
          return [0, 0];
        } else {
          return [1, 1];
        }
      }
    },

    /**
     * 关闭弹窗
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
    /**
     * 跳转详情页
     */
    goCarBillDetail(id) {
      this.handleClose();
      this.$router.push({
        name: 'carBillDetail',
        query: {
          id,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.quality-testing_box {
  height: 60vh;
  overflow: auto;
  padding: 30px 0 60px;
  .bill-info {
    padding-bottom: 60px;
    .row {
      display: flex;
      .col {
        width: 50%;
        .label {
          margin-right: 10px;
        }
        // &:first-child {
        // }
      }
      & + .row {
        margin-top: 14px;
      }
    }
  }
  .title {
    margin: 14px;
    color: #333;
    font-size: 16px;
    font-weight: bold;
  }
  .list {
    .item {
      border-left: 1px solid #eee;
      border-top: 1px solid #eee;
      border-right: 1px solid #eee;
      border-radius: 4px 4px 0 0;
      .cell {
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        box-sizing: border-box;
        .p {
          line-height: 18px;
          // 三行
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          word-break: break-all;
          word-wrap: break-word;
          text-overflow: ellipsis;
        }
        & + .cell {
          border-left: 1px solid #eee;
        }
      }
    }
    .split-row {
      height: 30px;
      border-left: 1px solid #eee;
      border-right: 1px solid #eee;
    }
  }
}
</style>
