import { welTag } from './config';

export default function (options = {}) {
  return Object.assign(
    {
      // 标签列表
      tagList: [welTag],
      // 激活的标签
      tag: welTag,
      // 首页
      tagWel: '/wel/',
    },
    options
  );
}
