<template>
  <div>
    <el-dialog
      title="温馨提示"
      :visible="invoicingDialog"
      width="50%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <div v-if="step === 1">
        <p class="tip-word" v-if="inconsistentQuantityList.length">
          当前接车单存在商品报价数量与出库数量不一致情况（标红商品），请确认是否继续提交结账
        </p>
        <div>
          <el-table
            v-if="inconsistentQuantityList.length"
            :data="inconsistentQuantityList"
            style="width: 100%"
            class="service-table"
            border
            default-expand-all
            row-key="id"
            :tree-props="{
              children: 'billGoods',
              hasChildren: 'hasChildren',
            }"
            :key="1"
          >
            <el-table-column prop="title" width="80" label="序号">
            </el-table-column>
            <el-table-column prop="name" label="报价项目" width="180">
              <template #default="{ row }">
                <div :class="{ red: row.type === 'goods' }">{{ row.name }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="code" label="报价项目编码" width="180">
              <template #default="{ row }">
                <div :class="{ red: row.type === 'goods' }">{{ row.code }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="num" label="报价数量" min-width="120">
              <template #default="{ row }">
                <div :class="{ red: row.type === 'goods' }">{{ row.num }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="num" label="出库数量" min-width="120">
              <template #default="{ row }">
                <div :class="{ red: row.type === 'goods' }">
                  {{ row.pickedNum }}
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <p class="tip-word" v-if="inconsistentPricesList.length">
          当前接车单存在商品报价小于成本价（标红商品），请确认是否继续提交结账
        </p>
        <el-table
          v-if="inconsistentPricesList.length"
          :data="inconsistentPricesList"
          style="width: 100%; margin-bottom: 40px"
          class="service-table"
          default-expand-all
          row-key="id"
          :tree-props="{ children: 'billGoods', hasChildren: 'hasChildren' }"
          :key="2"
        >
          <el-table-column prop="title" width="80" label="序号">
          </el-table-column>
          <el-table-column prop="name" label="报价项目" width="180">
            <template #default="{ row }">
              <div :class="{ red: row.type === 'goods' }">{{ row.name }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="itemCode" label="报价项目编码" width="180">
            <template #default="{ row }">
              <div :class="{ red: row.type === 'goods' }">{{ row.code }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="num" label="报价价格" min-width="120">
            <template #default="{ row }">
              <div :class="{ red: row.type === 'goods' }">{{ row.total }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="num" label="成本价格" min-width="120">
            <template #default="{ row }">
              <div :class="{ red: row.type === 'goods' }">
                {{ row.totalCostPrice || '' }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div v-else>
        <p style="margin-bottom: 20px">是否确认提交结账?</p>
        <el-form size="small">
          <el-form-item label="提车状态" required>
            <el-radio-group v-model="takeState">
              <el-radio :label="1">已提车</el-radio>
              <el-radio :label="0">未提车</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <template slot="footer">
        <template v-if="step === 1">
          <span v-if="inconsistentQuantityList.length !== 0" class="red">
            商品报价数量与出库数量不一致，不允许提交结账
          </span>
          <el-button
            size="small"
            type="primary"
            :disabled="inconsistentQuantityList.length !== 0"
            @click="nextStep"
            >提交结账</el-button
          >
          <el-button size="small" v-auth="4" @click="editCarBill"
            >编辑车单</el-button
          >
          <!-- <el-button v-auth="125" @click="editMaterialBill">编辑用料</el-button> -->
        </template>

        <template v-else>
          <el-button size="small" @click="handleClose">取 消</el-button>
          <el-button size="small" type="primary" @click="confirm"
            >确 定</el-button
          >
        </template>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { getCarBillMaterialInfo, carBillCheckOut } from '@/api/carBill';
import { mapState } from 'vuex';
import Big from 'big.js';

export default {
  props: {
    invoicingDialog: { type: Boolean, default: false },
    billId: { type: [Number, String], default: '' },
    billType: { type: [Number, String], default: '' },
    customer: { type: Object, default: () => ({}) },
    money: { type: [Number, String], default: '' },
    serviceTableData: { type: Array, default: () => [] },
    takeCar: { type: Boolean, default: false },
  },
  data() {
    return {
      // 数量不一致
      inconsistentQuantityList: [],
      // 价格不一致
      inconsistentPricesList: [],
      // 是否存在异常
      error: false,

      // 步骤
      step: 1,

      // 提车状态
      takeState: '',
    };
  },
  computed: {
    ...mapState({
      companyName: (state) => state.user.companyName,
    }),
  },
  created() {
    this.init();
    if (this.takeCar) {
      this.takeState = 1;
    }
  },
  methods: {
    /**
     * 初始化
     */
    init() {
      let list = this.$lodash.cloneDeep(this.serviceTableData);
      let dict = {};
      list.forEach((s) => {
        if (s.emptyService) s.name = '';
        let { billGoods, ...other } = s;
        delete other.goodsInfoVos;
        billGoods?.forEach((g) => {
          g.type = 'goods';
          if (!dict[g.materialGoodsId]) {
            dict[g.materialGoodsId] = [{ ...other, billGoods: [g] }];
          } else {
            dict[g.materialGoodsId].push({ ...other, billGoods: [g] });
          }
        });
      });

      console.log(dict);

      // 数量异常
      let inconsistentQuantityList = [];
      // 成本异常
      let inconsistentPricesList = [];

      Object.values(dict).forEach((arr) => {
        let total = Big(0);
        let pickedTotal = Big(0);

        let priceTotal = 0;
        let costPriceTotal = 0;

        // 1. 已出库但报价项目中不存在此商品
        if (arr.some((s) => !s.billServiceId)) {
          // 报价数量修改为0
          arr.forEach((s) => {
            s.billGoods?.forEach((g) => (g.num = 0));
          });
          inconsistentQuantityList.push(...arr);
          return;
        }

        arr.forEach((s) => {
          let g = s.billGoods[0];
          //total += +g.num;
          //pickedTotal += +g.pickedNum || 0;
          total = total.plus(g.num);
          pickedTotal = pickedTotal.plus(g.pickedNum || 0);
          priceTotal += +g.total;
          costPriceTotal += +g.totalCostPrice || 0;
        });

        // 2. 出库数量不一致
        console.log(
          '出库数量是否不一致',
          total.toFixed(2),
          pickedTotal.toFixed(2)
        );
        if (total.toFixed(2) !== pickedTotal.toFixed(2)) {
          inconsistentQuantityList.push(...arr);
        }

        // 3. 报价小于成本
        if (priceTotal < costPriceTotal) {
          inconsistentPricesList.push(...arr);
        }
      });

      // 排序
      inconsistentQuantityList.sort((a, b) => +a.title - +b.title);
      inconsistentPricesList.sort((a, b) => +a.title - +b.title);

      // 拷贝 防止后续操作服务项时影响
      inconsistentQuantityList = this.$lodash.cloneDeep(
        inconsistentQuantityList
      );
      inconsistentPricesList = this.$lodash.cloneDeep(inconsistentPricesList);

      // 合并相同服务
      let merge = (arr) => {
        let templateDict = {};
        for (var i = 0; i < arr.length; ) {
          let id = arr[i].id;
          if (templateDict[id] === undefined) {
            templateDict[id] = i;
            i++;
          } else {
            arr[templateDict[id]].billGoods?.push(...arr[i].billGoods);
            arr.splice(i, 1);
          }
        }
      };
      merge(inconsistentQuantityList);
      merge(inconsistentPricesList);

      console.log('出库异常', inconsistentQuantityList);
      console.log('成本异常', inconsistentPricesList);

      // this.handleList(inconsistentQuantityList);
      // this.handleList(inconsistentPricesList);

      this.inconsistentQuantityList = inconsistentQuantityList;
      this.inconsistentPricesList = inconsistentPricesList;

      // 如果没有异常数据，则进入下一步
      if (
        !this.inconsistentQuantityList.length &&
        !this.inconsistentPricesList.length
      ) {
        this.error = false;
        this.nextStep();
      } else {
        this.error = true;
      }
    },

    /**
     * 处理列表数据
     */
    handleList(list) {
      var index = 0;
      list.forEach((s) => {
        // s.title = s.sequence + 1 || '';
        s.id = ++index;
        s.name = s.serviceItemName;
        s.code = s.serviceItemCode;
        s.type = 'service';
        if (s?.materialGoods?.length) {
          s.materialGoods?.forEach((g) => {
            // g.title =
            //   s.sequence !== undefined
            //     ? `${s.sequence + 1}.${g.sequence + 1}`
            //     : '';
            g.id = ++index;
            g.name = g.goodsName;
            g.type = 'goods';
          });
        }
      });
      // this.setIndex(list);
    },

    /**
     * 设置序号
     */
    setIndex(arr, indexArr = [], children = 'materialGoods') {
      arr.forEach((item, index) => {
        let cloneArr = [];
        if (indexArr.length) {
          cloneArr = indexArr.concat(index + 1);
        } else {
          cloneArr.push(index + 1);
        }
        item.title = cloneArr.join('.');
        if (item[children]?.length) {
          this.setIndex(item[children], cloneArr);
        }
      });
    },

    /**
     * 关闭弹窗
     */
    handleClose() {
      this.$emit('update:invoicingDialog', false);
    },

    /**
     * 编辑车单
     */
    editCarBill() {
      this.$emit('update:invoicingDialog', false);
      this.$router.replace({
        name: 'editCarBill',
        query: {
          type: this.billType,
          id: this.billId,
        },
      });
    },

    /**
     * 编辑物料单
     */
    editMaterialBill() {
      this.$emit('editMaterialBill');
      this.$emit('update:invoicingDialog', false);
    },

    /**
     * 进入下一步骤 - 提交结账
     */
    nextStep() {
      this.step = 2;
    },

    /**
     * 确认提交结账
     */
    confirm() {
      if (this.takeState === '') {
        this.$message.warning('请选择提车状态！');
        return;
      }
      var data = {
        billId: this.billId,
        takeState: this.takeState,
      };
      carBillCheckOut(data)
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '车单进入待结账状态!',
            });
            this.$emit('confirm');
          }
        })
        .finally(() => {
          this.$emit('update:invoicingDialog', false);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.red {
  color: #e84931;
}

::v-deep.el-input-group--append .el-input__inner {
  width: 160px !important;
}

.tip-word {
  margin: 24px 0px 15px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: $orange;
}
.tip-words {
  margin: 20px 0px 15px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.chioce-switch {
  display: flex;
  margin-top: 35px;
  p {
    margin: 5px 30px 0px 0px;
    width: 200px;
    text-align: end;
    span {
      margin-right: 20px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
  }
}
.footer {
  margin-top: 77px;
  padding-bottom: 40px;
  justify-content: end;
}
.info-name {
  font-size: 14px;
  font-weight: 600;
}
::v-deep .el-popover__title {
  font-weight: 600 !important;
}
::v-deep .el-divider--horizontal {
  margin: 15px 0px !important;
}
.info-title {
  font-size: 12px;
}
.info-sty {
  margin: 10px 0px;
}
.service-table.el-table ::v-deep .el-table__row--level-1 {
  background: #f5f7fa;
}
// 不显示收起图标
.service-table.el-table ::v-deep .el-table__expand-icon {
  display: none;
}
.service-table.el-table ::v-deep .el-table__indent {
  padding: 0 !important;
}
.service-table.el-table ::v-deep .el-table__placeholder {
  width: 0 !important;
}
</style>
