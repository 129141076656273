var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visibility,
        width: "500px",
        "before-close": _vm.handleClose,
        "close-on-click-modal": false,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticStyle: { "margin-top": "18px" },
          attrs: {
            size: "small",
            model: _vm.editForm,
            rules: _vm.rules,
            "label-width": "90px",
          },
        },
        [
          !_vm.confirmState
            ? _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "协议编号", prop: "agreementNum" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "200",
                              clearable: "",
                              placeholder: "请输入协议编号，并回车",
                            },
                            on: {
                              clear: function ($event) {
                                return _vm.agreementNumInputClear()
                              },
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.doSearch.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.editForm.agreementNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.editForm, "agreementNum", $$v)
                              },
                              expression: "editForm.agreementNum",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      !_vm.confirmState
        ? _c(
            "div",
            [
              _vm.agreementData
                ? _c(
                    "el-row",
                    { staticClass: "myRow", attrs: { gutter: 10 } },
                    [
                      _c("el-col", { attrs: { span: 8, offset: 0 } }, [
                        _vm._v(" 协议编号 "),
                      ]),
                      _c("el-col", { attrs: { span: 16, offset: 0 } }, [
                        _vm._v(_vm._s(_vm.agreementData.agreementNumber)),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.agreementData
                ? _c(
                    "el-row",
                    { staticClass: "myRow", attrs: { gutter: 10 } },
                    [
                      _c("el-col", { attrs: { span: 8, offset: 0 } }, [
                        _vm._v(" 协议客户 "),
                      ]),
                      _c("el-col", { attrs: { span: 16, offset: 0 } }, [
                        _vm._v(_vm._s(_vm.agreementData.agreementClientName)),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.agreementData
                ? _c(
                    "el-row",
                    { staticClass: "myRow", attrs: { gutter: 10 } },
                    [
                      _c("el-col", { attrs: { span: 8, offset: 0 } }, [
                        _vm._v(" 有效期 "),
                      ]),
                      _c("el-col", { attrs: { span: 16, offset: 0 } }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.agreementData.startDate) +
                            " 至 " +
                            _vm._s(_vm.agreementData.endDate) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.agreementData
                ? _c(
                    "el-row",
                    { staticClass: "myRow", attrs: { gutter: 10 } },
                    [
                      _c("el-col", { attrs: { span: 8, offset: 0 } }, [
                        _vm._v(" 已配置协议价 "),
                      ]),
                      _c("el-col", { attrs: { span: 16, offset: 0 } }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.agreementData.agreementPriceCount) +
                            "个 "
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.confirmState
        ? _c(
            "div",
            [
              _c("p", { staticClass: "myTitle" }, [_vm._v("被选择协议")]),
              _vm.extendAgreementData
                ? _c(
                    "el-row",
                    { staticClass: "myRow", attrs: { gutter: 10 } },
                    [
                      _c("el-col", { attrs: { span: 8, offset: 0 } }, [
                        _vm._v(" 协议编号 "),
                      ]),
                      _c("el-col", { attrs: { span: 16, offset: 0 } }, [
                        _vm._v(_vm._s(_vm.extendAgreementData.agreementNumber)),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.extendAgreementData
                ? _c(
                    "el-row",
                    { staticClass: "myRow", attrs: { gutter: 10 } },
                    [
                      _c("el-col", { attrs: { span: 8, offset: 0 } }, [
                        _vm._v(" 协议客户 "),
                      ]),
                      _c("el-col", { attrs: { span: 16, offset: 0 } }, [
                        _vm._v(
                          _vm._s(_vm.extendAgreementData.agreementClientName)
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.extendAgreementData
                ? _c(
                    "el-row",
                    { staticClass: "myRow", attrs: { gutter: 10 } },
                    [
                      _c("el-col", { attrs: { span: 8, offset: 0 } }, [
                        _vm._v(" 有效期 "),
                      ]),
                      _c("el-col", { attrs: { span: 16, offset: 0 } }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.extendAgreementData.startDate) +
                            " 至 " +
                            _vm._s(_vm.extendAgreementData.endDate) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.extendAgreementData
                ? _c(
                    "el-row",
                    { staticClass: "myRow", attrs: { gutter: 10 } },
                    [
                      _c("el-col", { attrs: { span: 8, offset: 0 } }, [
                        _vm._v(" 已配置协议价 "),
                      ]),
                      _c("el-col", { attrs: { span: 16, offset: 0 } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.extendAgreementData.agreementPriceCount
                            ) +
                            "个 "
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _c("el-divider", { attrs: { "content-position": "left" } }, [
                _c("i", { staticClass: "el-icon-arrow-down" }),
              ]),
              _c("p", { staticClass: "myTitle" }, [
                _vm._v(" 以下协议生效 "),
                _c("span", { staticClass: "mySubTitle" }, [
                  _vm._v("(生效协议将按照被选择的协议的协议价进行)"),
                ]),
              ]),
              _vm.agreementData
                ? _c(
                    "el-row",
                    { staticClass: "myRow", attrs: { gutter: 10 } },
                    [
                      _c("el-col", { attrs: { span: 8, offset: 0 } }, [
                        _vm._v(" 协议编号 "),
                      ]),
                      _c("el-col", { attrs: { span: 16, offset: 0 } }, [
                        _vm._v(_vm._s(_vm.agreementData.agreementNumber)),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.agreementData
                ? _c(
                    "el-row",
                    { staticClass: "myRow", attrs: { gutter: 10 } },
                    [
                      _c("el-col", { attrs: { span: 8, offset: 0 } }, [
                        _vm._v(" 协议客户 "),
                      ]),
                      _c("el-col", { attrs: { span: 16, offset: 0 } }, [
                        _vm._v(_vm._s(_vm.agreementData.agreementClientName)),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.agreementData
                ? _c(
                    "el-row",
                    { staticClass: "myRow", attrs: { gutter: 10 } },
                    [
                      _c("el-col", { attrs: { span: 8, offset: 0 } }, [
                        _vm._v(" 有效期 "),
                      ]),
                      _c("el-col", { attrs: { span: 16, offset: 0 } }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.agreementData.startDate) +
                            " 至 " +
                            _vm._s(_vm.agreementData.endDate) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.agreementData
                ? _c(
                    "el-row",
                    { staticClass: "myRow", attrs: { gutter: 10 } },
                    [
                      _c("el-col", { attrs: { span: 8, offset: 0 } }, [
                        _vm._v(" 已配置协议价 "),
                      ]),
                      _c("el-col", { attrs: { span: 16, offset: 0 } }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.agreementData.agreementPriceCount) +
                            "个 "
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "template",
        { slot: "footer" },
        [
          !_vm.confirmState
            ? _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.confirmFun },
                },
                [_vm._v(" 确 定 ")]
              )
            : _vm._e(),
          _vm.confirmState
            ? _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.saveFun },
                },
                [_vm._v(" 确 定 ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }