var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "div",
        { staticClass: "table-wrap" },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.tabChange },
              model: {
                value: _vm.tabIndex,
                callback: function ($$v) {
                  _vm.tabIndex = $$v
                },
                expression: "tabIndex",
              },
            },
            [
              _c("el-tab-pane", {
                attrs: { label: "索赔应收汇总", name: "0" },
              }),
              _c("el-tab-pane", {
                attrs: { label: "索赔应收配置", name: "1" },
              }),
            ],
            1
          ),
          _vm.tabIndex == 0
            ? _c(
                "div",
                { ref: "exportRef", staticClass: "export-btn-wrap" },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "auth",
                          rawName: "v-auth",
                          value: 798,
                          expression: "798",
                        },
                      ],
                      staticClass: "export-btn",
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.showExportDialog = true
                        },
                      },
                    },
                    [_vm._v("导出")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.tabIndex == 0
            ? _c(
                "el-table",
                {
                  staticClass: "new-table",
                  attrs: {
                    data: _vm.tableData,
                    height: _vm.tableHei,
                    "header-cell-style": {
                      height: "32px",
                      padding: "0!important",
                    },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      fixed: "",
                      label: "品牌",
                      prop: "brandName",
                      "show-overflow-tooltip": "",
                      width: "220",
                    },
                  }),
                  _c(
                    "el-table-column",
                    { attrs: { align: "center", label: "1个月以内" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "单量",
                          prop: "withinOneMonthBillCount",
                          width: "100",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "金额",
                          prop: "withinOneMonthBillAmount",
                          width: "100",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-table-column",
                    { attrs: { align: "center", label: "1-3个月" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "单量",
                          prop: "withinRangeOneToThreeMonthBillCount",
                          width: "100",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "金额",
                          prop: "withinRangeOneToThreeMonthBillAmount",
                          width: "100",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-table-column",
                    { attrs: { align: "center", label: "3-6个月" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "单量",
                          prop: "withinRangeThreeToSixMonthBillCount",
                          width: "100",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "金额",
                          prop: "withinRangeThreeToSixMonthBillAmount",
                          width: "100",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-table-column",
                    { attrs: { align: "center", label: "6-12个月" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "单量",
                          prop: "withinRangeSixToTwelveMonthBillCount",
                          width: "100",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "金额",
                          prop: "withinRangeSixToTwelveMonthBillAmount",
                          width: "100",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-table-column",
                    { attrs: { align: "center", label: "1年以上" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "单量",
                          prop: "moreThanOneYearBillCount",
                          width: "100",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "金额",
                          prop: "moreThanOneYearBillAmount",
                          width: "100",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-table-column",
                    { attrs: { align: "center", label: "小计" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "单量",
                          prop: "subTotalBillCount",
                          width: "100",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "金额",
                          prop: "subTotalBillAmount",
                          width: "100",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-table-column",
                    { attrs: { align: "center", label: "占比" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "单量",
                          prop: "subTotalBillCountRate",
                          width: "100",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "金额",
                          prop: "subTotalBillAmountRate",
                          width: "100",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.tabIndex == 1
            ? _c(
                "div",
                { staticClass: "settings-wrap" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "formRef",
                      staticClass: "demo-form-inline",
                      attrs: { inline: true, model: _vm.searchForm },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "",
                            "label-width": "70px",
                            prop: "BusinessSource",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "请输入业务来源",
                            },
                            on: { clear: _vm.onSubmit },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.onSubmit.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.searchForm.BusinessSource,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "BusinessSource", $$v)
                              },
                              expression: "searchForm.BusinessSource",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                icon: "el-icon-search",
                                type: "primary",
                              },
                              on: { click: _vm.onSubmit },
                            },
                            [_vm._v("查询")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    {
                      staticClass: "new-table",
                      attrs: { data: _vm.tableData, height: _vm.tableHei },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "ID",
                          prop: "billSourceTypeId",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "业务来源",
                          prop: "billSourceTypeName",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "是否显示",
                          prop: "isStatShow",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function () {
                                return [
                                  _c("div", { staticClass: "col-head" }, [
                                    _c("span", { staticClass: "col-text" }, [
                                      _vm._v("*"),
                                    ]),
                                    _vm._v(" 是否显示 "),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "default",
                              fn: function ({ row }) {
                                return [
                                  _c("el-switch", {
                                    attrs: { disabled: row.switchShow },
                                    model: {
                                      value: row.isStatShow,
                                      callback: function ($$v) {
                                        _vm.$set(row, "isStatShow", $$v)
                                      },
                                      expression: "row.isStatShow",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          67363713
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "显示名称（品牌）",
                          prop: "showBrandName",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function () {
                                return [
                                  _c("div", { staticClass: "col-head" }, [
                                    _c("span", { staticClass: "col-text" }, [
                                      _vm._v("*"),
                                    ]),
                                    _vm._v(" 显示名称（品牌） "),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "default",
                              fn: function ({ row }) {
                                return [
                                  row.switchShow
                                    ? _c("span", [
                                        _vm._v(_vm._s(row.showBrandName)),
                                      ])
                                    : _c("el-input", {
                                        attrs: {
                                          clearable: "",
                                          placeholder: "请输入",
                                        },
                                        model: {
                                          value: row.showBrandName,
                                          callback: function ($$v) {
                                            _vm.$set(row, "showBrandName", $$v)
                                          },
                                          expression: "row.showBrandName",
                                        },
                                      }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3998267423
                        ),
                      }),
                      _c("el-table-column", {
                        directives: [
                          {
                            name: "auth",
                            rawName: "v-auth",
                            value: 810,
                            expression: "810",
                          },
                        ],
                        attrs: { align: "center", label: "操作", width: "100" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ row }) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "operate-center" },
                                    [
                                      _c(
                                        "el-link",
                                        {
                                          staticClass: "table-text",
                                          attrs: {
                                            underline: false,
                                            type: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.editData(
                                                row,
                                                row.switchShow ? 0 : 1
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                row.switchShow ? "编辑" : "保存"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2555547216
                        ),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "pagination-wrap" },
                    [
                      _c("div", { staticClass: "total-text" }, [
                        _vm._v("共" + _vm._s(_vm.total) + "条"),
                      ]),
                      _c("el-pagination", {
                        attrs: {
                          "current-page": _vm.currentPage,
                          "page-size": _vm.pageSize,
                          "page-sizes": [20, 30, 50, 100],
                          total: _vm.total,
                          layout: "->, sizes, prev, pager, next, jumper",
                        },
                        on: {
                          "size-change": _vm.handleSizeChange,
                          "current-change": _vm.handleCurrentChange,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("export-dialog", {
        attrs: {
          filterForm: _vm.form,
          pageOption: _vm.pageOption,
          setWid: "30%",
          total: _vm.total,
          url: _vm.exportUrl,
          visibility: _vm.showExportDialog,
        },
        on: {
          "update:visibility": function ($event) {
            _vm.showExportDialog = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }