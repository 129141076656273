var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { ref: "baseContainer", attrs: { paddingTop: "" } },
    [
      [
        _c(
          "div",
          {
            staticClass: "flex h100",
            staticStyle: { flex: "1", overflow: "auto", background: "#f1f7fa" },
          },
          [
            _c("div", { staticClass: "left_side" }, [
              _c("div", { staticClass: "brand_num" }, [
                _c("h2", { staticStyle: { "font-weight": "600" } }, [
                  _vm._v("品牌车型"),
                ]),
                _c("p", [
                  _vm._v("已上架问诊库车型(" + _vm._s(_vm.totalCount) + ")"),
                ]),
              ]),
              _c(
                "ul",
                {
                  staticClass: "sidebar-menu-second_ul",
                  staticStyle: { "margin-top": "16px" },
                },
                [
                  _c("li", { staticClass: "sidebar-menu-second_li" }, [
                    _c(
                      "div",
                      {
                        staticClass: "sidebar-menu-second_btn",
                        class: {
                          "has-children": false,
                          active: _vm.activeId == 0,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleThirdMenuClick(
                              _vm.carModeList[0].inquiryCarSeriesList[0],
                              1
                            )
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "newicon grow-icon_ok_shiwu",
                          staticStyle: {
                            "margin-right": "5px",
                            scale: "1.2",
                            "font-size": "24px",
                            color: "#666",
                          },
                        }),
                        _c(
                          "span",
                          { staticClass: "sidebar-menu-second_text" },
                          [
                            _vm._v("通用问诊库 "),
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  effect: "light",
                                  placement: "bottom-start",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "#666666",
                                      "font-size": "13px",
                                      "line-height": "16px",
                                      "text-indent": "15px",
                                    },
                                    attrs: { slot: "content" },
                                    slot: "content",
                                  },
                                  [
                                    _vm._v(
                                      " 未在上架车型列表的车辆进店将使用通用问诊库数据 "
                                    ),
                                  ]
                                ),
                                _c("i", {
                                  staticClass: "iconfont grow-icon_doubt",
                                  staticStyle: { "margin-left": "14px" },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                  _vm._l(
                    _vm.carModeList.slice(1),
                    function (secondMenu, index) {
                      return _c(
                        "li",
                        { key: index, staticClass: "sidebar-menu-second_li" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "sidebar-menu-second_btn",
                              class: {
                                "has-children": secondMenu.inquiryCarSeriesList,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleSecondMenuClick(secondMenu)
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticStyle: { "margin-right": "5px" },
                                attrs: { src: secondMenu.logoImagePath },
                              }),
                              _c(
                                "span",
                                { staticClass: "sidebar-menu-second_text" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(secondMenu.logoName) +
                                      " (" +
                                      _vm._s(secondMenu.carSeriesKindNum) +
                                      ") "
                                  ),
                                ]
                              ),
                              _c("i", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: secondMenu.inquiryCarSeriesList,
                                    expression:
                                      "secondMenu.inquiryCarSeriesList",
                                  },
                                ],
                                staticClass:
                                  "sidebar-menu-expand_icon newicon grow-icon-arrow-right",
                                class: { active: secondMenu.showChild },
                              }),
                            ]
                          ),
                          _c(
                            "ul",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: secondMenu.showChild,
                                  expression: "secondMenu.showChild",
                                },
                              ],
                              staticClass: "sidebar-menu-third_ul",
                            },
                            _vm._l(
                              secondMenu.inquiryCarSeriesList,
                              function (thirdMenu, index) {
                                return _c(
                                  "li",
                                  {
                                    key: index,
                                    class: {
                                      "sidebar-menu-third_li": true,
                                      active:
                                        _vm.activeId ==
                                        thirdMenu.modelDetailSeriesId,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleThirdMenuClick(
                                          thirdMenu
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(thirdMenu.seriesName) + " "
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]
                      )
                    }
                  ),
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "right_side" }, [
              _c(
                "div",
                {
                  staticClass: "flex",
                  staticStyle: { margin: "20px 0px 29px 16px" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "150px", "margin-right": "20px" },
                    attrs: { placeholder: "搜索故障现象", clearable: "" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.doSeach.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.form.appearanceName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "appearanceName", $$v)
                      },
                      expression: "form.appearanceName",
                    },
                  }),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.doSeach },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "flex h100" }, [
                _c(
                  "div",
                  {
                    staticClass: "h100",
                    staticStyle: { width: "34%", "margin-left": "16px" },
                  },
                  [
                    _c("div", { staticClass: "flex-x-between table_top" }, [
                      _c("span", [_vm._v("故障现象")]),
                      _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "auth",
                              rawName: "v-auth",
                              value: 557,
                              expression: "557",
                            },
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.addDate(1)
                            },
                          },
                        },
                        [_vm._v("新增现象")]
                      ),
                    ]),
                    _c(
                      "el-table",
                      {
                        ref: "singleTable",
                        staticClass: "customer-table",
                        attrs: {
                          height: "83%",
                          data: _vm.faultPhenomenonData,
                          border: "",
                          "highlight-current-row": "",
                        },
                        on: { "row-click": _vm.clickPhenomenonRow },
                      },
                      [
                        _c("el-table-column", {
                          attrs: { type: "index", width: "80", label: "序号" },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            property: "appearanceName",
                            label: "故障现象",
                          },
                        }),
                      ],
                      1
                    ),
                    [
                      _c("pagination", {
                        attrs: {
                          total: _vm.totalPhenomenon,
                          type: "mini",
                          typeStyle: _vm.typeStyle,
                        },
                        on: { pageChange: _vm.changePagePhenomenon },
                      }),
                    ],
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticStyle: { width: "34%", margin: "0px 16px" } },
                  [
                    _c("div", { staticClass: "flex-x-between table_top" }, [
                      _c("span", [_vm._v("故障原因")]),
                      _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "auth",
                              rawName: "v-auth",
                              value: 557,
                              expression: "557",
                            },
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.addDate(2)
                            },
                          },
                        },
                        [_vm._v("新增原因")]
                      ),
                    ]),
                    _c(
                      "el-table",
                      {
                        ref: "singleTable",
                        staticClass: "customer-table",
                        attrs: {
                          height: "83%",
                          data: _vm.faultCauseData,
                          border: "",
                          "highlight-current-row": "",
                          "empty-text": "请先选择故障现象",
                        },
                        on: { "row-click": _vm.clickCauseRow },
                      },
                      [
                        _c("el-table-column", {
                          attrs: { type: "index", width: "80", label: "序号" },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            property: "sysName",
                            label: "系统",
                            width: "120",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: { property: "causeName", label: "故障原因" },
                        }),
                      ],
                      1
                    ),
                    [
                      _c("pagination", {
                        attrs: {
                          total: _vm.totalCause,
                          type: "mini",
                          typeStyle: _vm.typeStyle,
                        },
                        on: { pageChange: _vm.changePageCause },
                      }),
                    ],
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticStyle: { width: "30%", "margin-right": "16px" } },
                  [
                    _c("div", { staticClass: "flex-x-between table_top" }, [
                      _c("span", [_vm._v("维修方案")]),
                      _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "auth",
                              rawName: "v-auth",
                              value: 557,
                              expression: "557",
                            },
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.addDate(3)
                            },
                          },
                        },
                        [_vm._v("新增方案")]
                      ),
                    ]),
                    _c(
                      "el-table",
                      {
                        ref: "singleTable",
                        attrs: {
                          height: "83%",
                          data: _vm.WzServiceData,
                          border: "",
                          "empty-text": "请先选择故障原因",
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: { type: "index", width: "80", label: "序号" },
                        }),
                        _c("el-table-column", {
                          attrs: { label: "操作", width: "100" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ row }) {
                                return [
                                  _c(
                                    "p",
                                    {
                                      directives: [
                                        {
                                          name: "auth",
                                          rawName: "v-auth",
                                          value: 558,
                                          expression: "558",
                                        },
                                      ],
                                      staticClass: "blue pointer",
                                      on: {
                                        click: function ($event) {
                                          return _vm.editEvent(row)
                                        },
                                      },
                                    },
                                    [_vm._v(" 编辑 ")]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: { property: "serviceName", label: "维修方案" },
                        }),
                      ],
                      1
                    ),
                    [
                      _c("pagination", {
                        attrs: {
                          total: _vm.totalSolution,
                          type: "mini",
                          typeStyle: _vm.typeStyle,
                        },
                        on: { pageChange: _vm.changePageSolution },
                      }),
                    ],
                  ],
                  2
                ),
              ]),
            ]),
          ]
        ),
        _vm.editDialog
          ? _c("consultation-dialog", {
              attrs: {
                visibility: _vm.editDialog,
                title: _vm.title,
                modelId: _vm.modelId,
                showContent: _vm.showContent,
                clickAppearanceName: _vm.clickAppearanceName,
                clickCauseName: _vm.clickCauseName,
              },
              on: {
                "update:visibility": function ($event) {
                  _vm.editDialog = $event
                },
                updateData: _vm.deteleUpdate,
              },
            })
          : _vm._e(),
      ],
      _vm.showCarModelsDialog
        ? _c("car-models-dialog", {
            attrs: { showCarModelsDialog: _vm.showCarModelsDialog },
            on: {
              "update:showCarModelsDialog": function ($event) {
                _vm.showCarModelsDialog = $event
              },
              "update:show-car-models-dialog": function ($event) {
                _vm.showCarModelsDialog = $event
              },
              carModel: _vm.selectCarModel,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }