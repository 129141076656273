var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    {
      attrs: {
        filterable: "",
        clearable: "",
        remote: "",
        placeholder: "搜索业务来源",
        "remote-method": _vm.getData,
        loading: _vm.isLoading,
      },
      on: { change: _vm.onChange, clear: _vm.handleClear },
      model: {
        value: _vm.id,
        callback: function ($$v) {
          _vm.id = $$v
        },
        expression: "id",
      },
    },
    _vm._l(_vm.billSourceList, function (item) {
      return _c("el-option", {
        key: item.billSourceTypeId,
        attrs: { label: item.name, value: item.billSourceTypeId },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }