<template>
  <base-container>
    <template slot="header">
      <el-form :model="form" label-width="100px" size="small">
        <el-col :span="5">
          <el-form-item>
            <template slot="label">
              <div>
                消费门店
                <el-tooltip
                  effect="light"
                  content="车辆的消费门店，可查连锁范围内全部门店"
                  placement="bottom-start"
                >
                  <i
                    class="iconfont grow-icon_doubt"
                    style="color: #9a9a9a"
                  ></i>
                </el-tooltip>
              </div>
            </template>
            <el-select
              size="small"
              v-model="form.consumeCompanyId"
              @change="selectStore"
              @clear="deleteStore"
              filterable
              clearable
              remote
              placeholder="搜索车店名称、编号"
              :remote-method="searchStore"
              :loading="searchStoreLoading"
            >
              <el-option
                v-for="item in storeList"
                :key="item.companyId"
                :label="item.companyName"
                :value="item.companyId"
              >
                <span style="float: left">{{ item.companyName }}</span>
                <span style="float: right">{{ item.companyId }}</span>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="车牌号">
            <el-select
              size="small"
              v-model="form.carId"
              filterable
              clearable
              placeholder="名下全部车辆"
            >
              <el-option
                v-for="item in carList"
                :key="item.carId"
                :label="item.carNumber"
                :value="item.carId"
              >
                <span style="float: left">{{ item.carNumber }}</span>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="项目名称">
            <el-input
              v-model="form.billServiceMsg"
              placeholder="输入服务、商品项目名称"
              clearable
              @keyup.enter.native="getDataList"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="车架号">
            <el-input
              v-model="form.uniqueId"
              placeholder="请输入车架号"
              clearable
              @keyup.enter.native="getDataList"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-button
            class="btn_search"
            type="primary"
            size="small"
            @click="getDataList"
            >查询</el-button
          ></el-col
        >
      </el-form>
    </template>
    <template>
      <ul class="header-ul_menus">
        <li
          class="header-li_menu"
          :class="{ selected: checkedIndex == 0 }"
          @click="handleChange(0)"
        >
          <el-badge :value="total" class="item">
            <h3 class="header-h3_title">
              消费记录(￥{{ totalConsumptionRecord }})
            </h3>
          </el-badge>
        </li>
        <!-- bug764隐藏 -->
        <!-- <li
          class="header-li_menu"
          :class="{ selected: checkedIndex == 1 }"
          @click="handleChange(1)"
        >
          <el-badge :value="0" class="item">
            <h3 class="header-h3_title">消费记录(导入）(￥0)</h3>
          </el-badge>
        </li> -->
      </ul>
      <el-table
        v-show="record1"
        :data="carListData"
        border
        class="custom-table"
        style="height: calc(63vh)"
      >
        <el-table-column type="index" label="序号" width="60">
        </el-table-column>
        <el-table-column label="消费门店" width="120" prop="consumeCompanyName">
        </el-table-column>
        <el-table-column min-width="180" label="单据号">
          <template slot-scope="scope">
            <p class="pointer blue" @click="goDetail(scope.row.billId)">
              {{ scope.row.billCode }}
            </p>

            <p v-if="scope.row.billType != 0">
              {{ scope.row.billType | dict('carBillType') }}
            </p>
            <p v-else>
              {{
                scope.row.isOthersSelfPayState | dict('isOthersSelfPayState')
              }}
            </p>
          </template>
        </el-table-column>
        <el-table-column min-width="180" label="车架号" prop="uniqueId">
        </el-table-column>
        <el-table-column min-width="180" label="车牌号" prop="carNumber">
        </el-table-column>
        <el-table-column min-width="180" label="消费客户">
          <template #default="{ row }">
            <p v-if="row.consumerName">客户名称：{{ row.consumerName }}</p>
          </template>
        </el-table-column>
        <el-table-column
          min-width="180"
          label="进店时间"
          prop="intoStoreStartTime"
        >
        </el-table-column>
        <el-table-column
          min-width="180"
          label="进店里程"
          prop="mileage"
        ></el-table-column>
        <el-table-column
          min-width="200"
          label="服务"
          prop="service"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <p v-if="scope.row.billServiceNames">
              {{
                scope.row.billServiceNames
                  .map((i) => {
                    return i;
                  })
                  .join()
              }}
            </p>
          </template></el-table-column
        >
        <el-table-column
          min-width="180"
          label="商品"
          prop="goods"
          show-overflow-tooltip
          ><template slot-scope="scope">
            <p v-if="scope.row.billGoodsNames">
              {{
                scope.row.billGoodsNames
                  .map((i) => {
                    return i;
                  })
                  .join()
              }}
            </p>
            <!-- <el-popover trigger="hover" placement="bottom">
              <p>{{ scope.row.goods }}</p>
              <div slot="reference" class="name-wrapper">
                <el-tag size="medium">{{ scope.row.goods }}</el-tag>
              </div>
            </el-popover> -->
          </template></el-table-column
        >
        <el-table-column min-width="180" label="单据金额" prop="total">
        </el-table-column>
        <el-table-column
          min-width="180"
          label="实收金额"
          prop="paid"
        ></el-table-column>
        <el-table-column min-width="180" label="结账备注" show-overflow-tooltip>
          <template #default="{ row }">
            {{ row.billCheckRemark }}
          </template>
        </el-table-column>
      </el-table>
      <el-table
        :data="tableData2"
        border
        style="width: 100%"
        height="600px"
        :header-cell-style="{
          background: '#FAFAFA',
          color: '#000000',
          fontSize: '14px',
          fontWeight: '500',
        }"
        v-show="record2"
      >
        <el-table-column type="index" width="60" label="序号">
        </el-table-column>
        <el-table-column prop="documentDoc" label="单据号" min-width="180">
        </el-table-column>
        <el-table-column prop="licenseNum" label="车牌号" min-width="180">
        </el-table-column>
        <el-table-column prop="deliveryDate" label="提车日期" min-width="180">
        </el-table-column>
        <el-table-column prop="intMileage" label="进店里程" width="180">
        </el-table-column>
        <el-table-column prop="service" label="服务" min-width="180">
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="bottom">
              <p>{{ scope.row.service }}</p>
              <div slot="reference" class="name-wrapper">
                <el-tag size="medium">{{ scope.row.service }}</el-tag>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="agoods" label="商品"> </el-table-column>
        <el-table-column prop="amountDoc" label="单据金额" min-width="180">
        </el-table-column>
        <el-table-column prop="salesman" label="业务员" min-width="180">
        </el-table-column>
        <el-table-column prop="markst" label="备注" min-width="140">
        </el-table-column>
        <el-table-column prop="beforeStore" label="所属门店" min-width="180">
        </el-table-column>
      </el-table>
    </template>
    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
    <details-list
      v-if="billId"
      :billId.sync="billId"
      :dialogTableVisible.sync="dialogTableVisible"
    >
    </details-list>
  </base-container>
</template>

<script>
import DetailsList from '@/views/customerManagement/detailsList.vue';

// 分页组件
import pagination from '@/components/Pagination.vue';
import { mapState } from 'vuex';

//接口
import {
  clientGetClientConsumptionRecord,
  clientGetClientCarsByClientId,
} from '@/api/customer/customer';
import { searchStore } from '@/api/customer/store';

export default {
  name: 'customerRecord',
  components: { pagination, DetailsList },
  props: {
    clientId: { type: [Number, String], default: '' },
  },
  data() {
    return {
      form: {},

      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },

      carListData: [],
      activeName: 'first',
      checkedIndex: 0,
      record1: true,
      record2: false,
      tableData2: [],
      //消费门店
      searchStoreLoading: false,
      storeList: [],
      //名下车辆
      carList: [],
      totalConsumptionRecord: '',

      billId: '',
      dialogTableVisible: false,
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  created() {
    this.searchStore();
    this.getDataList();
    this.searchCar();
  },
  methods: {
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getDataList(false);
    },
    /**
     * 加载数据
     */
    getDataList(reset = true) {
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      var clientId = {
        clientId: this.clientId,
      };
      var data = Object.assign(this.form, clientId, this.pageOption);
      clientGetClientConsumptionRecord(data).then((res) => {
        this.total = res.recordVos?.total || 0;
        this.carListData = res.recordVos?.records || [];
        this.totalConsumptionRecord = res?.totalAmount || 0;
      });
    },
    /**
     * 远程请求门店数据方法
     */
    remoteMethod() {},
    /**
     * 跳转详情页
     */
    goDetail(id) {
      this.billId = id; // 车单ID
      this.dialogTableVisible = true;
    },
    //远程搜索消费门店列表
    searchStore(keyWord = '') {
      this.searchStoreLoading = true;
      searchStore(keyWord, this.companyId)
        .then((res) => {
          this.storeList = res;
        })
        .finally(() => {
          this.searchStoreLoading = false;
        });
    },
    // 选择消费门店
    selectStore(storeId) {},
    //删除消费门店
    deleteStore() {},
    //远程搜索名下车辆列表
    searchCar() {
      var data = {
        id: this.clientId,
      };
      clientGetClientCarsByClientId(data)
        .then((res) => {
          this.carList = res;
        })
        .finally(() => {});
    },
    handleChange(value) {
      if (value == 0) {
        this.checkedIndex = 0;
        this.record1 = true;
        this.record2 = false;
      } else {
        this.checkedIndex = 1;
        this.record1 = false;
        this.record2 = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep.base-container.default-padding > .header[data-v-1883acb0],
.base-container.default-padding > .main[data-v-1883acb0] {
  padding: 0px 10px !important;
}
::v-deep.base-container.default-padding > .main[data-v-1883acb0] {
  padding: 0 10px;
}
.form-top {
  height: 28px;
}
.btn_search {
  width: 76px;
  margin-left: 50px;
}
.base-container {
  padding: 30px 0px;
}
.content-container {
  height: 100%;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: auto;
}
.span6_input {
  width: 100%;
}
.fade-enter-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
// 修改表格中鼠标悬停效果样式
.el-tag {
  border: none;
  background: #ffffff;
  color: #606266;
  font-size: 14px;
}
.header-sty {
  display: flex;
  height: 70px;
  line-height: 37px;
  margin-top: 10px;
}
.header-ul_menus {
  display: flex;
  margin-top: 30px;
}
.header-li_menu {
  height: 49px;
  width: 244px;
  text-align: center;
  line-height: 41px;
  border: 1px solid #e8e8e8;
  color: #999999;
  font-size: 16px;
  margin-right: 16px;
  border-radius: 4px 4px 0px 0px;
  padding-top: 12px;
}
.selected {
  background: rgba(85, 183, 142, 0.1);
  color: $primary;
}
.choice_type {
  height: 30px;
  border-bottom: 3px solid #e6e6e6;
}
.choice_type ul {
  display: flex;
}
.choice_type ul li {
  width: 70px;
  margin-right: 20px;
}
.selected {
  background: rgba(85, 183, 142, 0.1);
  color: $primary;
}
.page-footer {
  position: fixed;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
}
</style>
