var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-drawer",
    {
      attrs: {
        title: _vm.title,
        size: "55%",
        visible: _vm.addWarrantyDialog,
        wrapperClosable: true,
        "before-close": _vm.handleClose,
        modal: true,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticStyle: {
            height: "80vh",
            "overflow-y": "scroll",
            margin: "0 20px",
          },
          attrs: {
            model: _vm.form,
            "label-width": "100px",
            size: "small",
            rules: _vm.rules,
          },
        },
        [
          _c("div", { staticClass: "flex-x-between" }, [
            _c(
              "div",
              { staticClass: "flex-shrink0" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "品牌", prop: "name" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          filterable: "",
                          remote: "",
                          placeholder: "请搜索或选择车型",
                          "remote-method": _vm.searchModel,
                          loading: _vm.searchModelLoading,
                          clearable: "",
                        },
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      },
                      _vm._l(_vm.carModelList, function (item) {
                        return _c(
                          "el-option",
                          {
                            key: _vm.$lodash.get(item, "series.id"),
                            attrs: {
                              value: _vm.$lodash.get(
                                item,
                                "series.name",
                                _vm.$lodash.get(
                                  item,
                                  "brand.name",
                                  _vm.$lodash.get(item, "logo.name")
                                )
                              ),
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.selectCarModel(item)
                              },
                            },
                          },
                          [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(item.markedRedName),
                              },
                            }),
                          ]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    staticStyle: { margin: "28px 0" },
                    attrs: { label: "车辆所有人" },
                  },
                  [
                    _c("el-input", {
                      staticStyle: { width: "32%" },
                      attrs: { placeholder: "请输入车辆所有人", clearable: "" },
                      model: {
                        value: _vm.form.vehicleOwner,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "vehicleOwner", $$v)
                        },
                        expression: "form.vehicleOwner",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "整车质保" } },
                  _vm._l(_vm.vehicleList, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "flex spacing" },
                      [
                        _c("number-input", {
                          attrs: {
                            placeholder: "请输入年限，必填",
                            clearable: "",
                          },
                          model: {
                            value: item.month,
                            callback: function ($$v) {
                              _vm.$set(item, "month", $$v)
                            },
                            expression: "item.month",
                          },
                        }),
                        _c("number-input", {
                          staticStyle: { margin: "0px 10px" },
                          attrs: {
                            decimalDigits: "2",
                            placeholder: "请输入里程，必填",
                          },
                          model: {
                            value: item.km,
                            callback: function ($$v) {
                              _vm.$set(item, "km", $$v)
                            },
                            expression: "item.km",
                          },
                        }),
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入质保内容",
                            clearable: "",
                          },
                          model: {
                            value: item.content,
                            callback: function ($$v) {
                              _vm.$set(item, "content", $$v)
                            },
                            expression: "item.content",
                          },
                        }),
                        index == 0
                          ? _c("i", {
                              staticClass:
                                "pointer orange el-icon-circle-plus-outline",
                              staticStyle: {
                                color: "#ff9200",
                                "font-size": "24px",
                                margin: "4px 10px",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.addList("vehicleList")
                                },
                              },
                            })
                          : _c("i", {
                              staticClass: "pointer iconfont grow-icon_delete3",
                              staticStyle: {
                                color: "#ff9200",
                                "font-size": "24px",
                                margin: "0px 10px",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteList("vehicleList", index)
                                },
                              },
                            }),
                      ],
                      1
                    )
                  }),
                  0
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "三电质保" } },
                  _vm._l(_vm.threePowerList, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "flex spacing" },
                      [
                        _c("number-input", {
                          attrs: {
                            placeholder: "请输入年限，必填",
                            clearable: "",
                          },
                          model: {
                            value: item.month,
                            callback: function ($$v) {
                              _vm.$set(item, "month", $$v)
                            },
                            expression: "item.month",
                          },
                        }),
                        _c("number-input", {
                          staticStyle: { margin: "0px 10px" },
                          attrs: {
                            decimalDigits: "2",
                            placeholder: "请输入里程，必填",
                          },
                          model: {
                            value: item.km,
                            callback: function ($$v) {
                              _vm.$set(item, "km", $$v)
                            },
                            expression: "item.km",
                          },
                        }),
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入质保内容",
                            clearable: "",
                          },
                          model: {
                            value: item.content,
                            callback: function ($$v) {
                              _vm.$set(item, "content", $$v)
                            },
                            expression: "item.content",
                          },
                        }),
                        index == 0
                          ? _c("i", {
                              staticClass:
                                "pointer orange el-icon-circle-plus-outline",
                              staticStyle: {
                                color: "#ff9200",
                                "font-size": "24px",
                                margin: "4px 10px",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.addList("threePowerList")
                                },
                              },
                            })
                          : _c("i", {
                              staticClass: "pointer iconfont grow-icon_delete3",
                              staticStyle: {
                                color: "#ff9200",
                                "font-size": "24px",
                                margin: "0px 10px",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteList("threePowerList", index)
                                },
                              },
                            }),
                      ],
                      1
                    )
                  }),
                  0
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "动力电池质保" } },
                  _vm._l(_vm.powerBatteryList, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "flex spacing" },
                      [
                        _c("number-input", {
                          attrs: {
                            placeholder: "请输入年限，必填",
                            clearable: "",
                          },
                          model: {
                            value: item.month,
                            callback: function ($$v) {
                              _vm.$set(item, "month", $$v)
                            },
                            expression: "item.month",
                          },
                        }),
                        _c("number-input", {
                          staticStyle: { margin: "0px 10px" },
                          attrs: {
                            decimalDigits: "2",
                            placeholder: "请输入里程，必填",
                          },
                          model: {
                            value: item.km,
                            callback: function ($$v) {
                              _vm.$set(item, "km", $$v)
                            },
                            expression: "item.km",
                          },
                        }),
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入质保内容",
                            clearable: "",
                          },
                          model: {
                            value: item.content,
                            callback: function ($$v) {
                              _vm.$set(item, "content", $$v)
                            },
                            expression: "item.content",
                          },
                        }),
                        index == 0
                          ? _c("i", {
                              staticClass:
                                "pointer orange el-icon-circle-plus-outline",
                              staticStyle: {
                                color: "#ff9200",
                                "font-size": "24px",
                                margin: "4px 10px",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.addList("powerBatteryList")
                                },
                              },
                            })
                          : _c("i", {
                              staticClass: "pointer iconfont grow-icon_delete3",
                              staticStyle: {
                                color: "#ff9200",
                                "font-size": "24px",
                                margin: "0px 10px",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteList(
                                    "powerBatteryList",
                                    index
                                  )
                                },
                              },
                            }),
                      ],
                      1
                    )
                  }),
                  0
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "电芯质保" } },
                  _vm._l(_vm.batteryList, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "flex spacing" },
                      [
                        _c("number-input", {
                          attrs: {
                            placeholder: "请输入年限，必填",
                            clearable: "",
                          },
                          model: {
                            value: item.month,
                            callback: function ($$v) {
                              _vm.$set(item, "month", $$v)
                            },
                            expression: "item.month",
                          },
                        }),
                        _c("number-input", {
                          staticStyle: { margin: "0px 10px" },
                          attrs: {
                            decimalDigits: "2",
                            placeholder: "请输入里程，必填",
                          },
                          model: {
                            value: item.km,
                            callback: function ($$v) {
                              _vm.$set(item, "km", $$v)
                            },
                            expression: "item.km",
                          },
                        }),
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入质保内容",
                            clearable: "",
                          },
                          model: {
                            value: item.content,
                            callback: function ($$v) {
                              _vm.$set(item, "content", $$v)
                            },
                            expression: "item.content",
                          },
                        }),
                        index == 0
                          ? _c("i", {
                              staticClass:
                                "pointer orange el-icon-circle-plus-outline",
                              staticStyle: {
                                color: "#ff9200",
                                "font-size": "24px",
                                margin: "4px 10px",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.addList("batteryList")
                                },
                              },
                            })
                          : _c("i", {
                              staticClass: "pointer iconfont grow-icon_delete3",
                              staticStyle: {
                                color: "#ff9200",
                                "font-size": "24px",
                                margin: "0px 10px",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteList("batteryList", index)
                                },
                              },
                            }),
                      ],
                      1
                    )
                  }),
                  0
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "易损件质保" } },
                  _vm._l(_vm.wearingPartsList, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "flex spacing" },
                      [
                        _c("number-input", {
                          attrs: {
                            placeholder: "请输入年限，必填",
                            clearable: "",
                          },
                          model: {
                            value: item.month,
                            callback: function ($$v) {
                              _vm.$set(item, "month", $$v)
                            },
                            expression: "item.month",
                          },
                        }),
                        _c("number-input", {
                          staticStyle: { margin: "0px 10px" },
                          attrs: {
                            decimalDigits: "2",
                            placeholder: "请输入里程，必填",
                          },
                          model: {
                            value: item.km,
                            callback: function ($$v) {
                              _vm.$set(item, "km", $$v)
                            },
                            expression: "item.km",
                          },
                        }),
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入质保内容",
                            clearable: "",
                          },
                          model: {
                            value: item.content,
                            callback: function ($$v) {
                              _vm.$set(item, "content", $$v)
                            },
                            expression: "item.content",
                          },
                        }),
                        index == 0
                          ? _c("i", {
                              staticClass:
                                "pointer orange el-icon-circle-plus-outline",
                              staticStyle: {
                                color: "#ff9200",
                                "font-size": "24px",
                                margin: "4px 10px",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.addList("wearingPartsList")
                                },
                              },
                            })
                          : _c("i", {
                              staticClass: "pointer iconfont grow-icon_delete3",
                              staticStyle: {
                                color: "#ff9200",
                                "font-size": "24px",
                                margin: "0px 10px",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteList(
                                    "wearingPartsList",
                                    index
                                  )
                                },
                              },
                            }),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.determine("form")
                },
              },
            },
            [_vm._v("确定")]
          ),
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.handleClose } },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }