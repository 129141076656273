var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "lie_one" }, [
      _c("p", { staticClass: "title_word" }, [_vm._v("允许一车多单")]),
      _c(
        "div",
        { staticClass: "switch" },
        [
          _c("el-switch", {
            attrs: { "active-color": "#33ab79", "inactive-color": "#999999" },
            model: {
              value: _vm.value1,
              callback: function ($$v) {
                _vm.value1 = $$v
              },
              expression: "value1",
            },
          }),
        ],
        1
      ),
      _c("p", { staticClass: "tip_word" }, [_vm._v("允许一台车开多张单")]),
    ]),
    _c("div", { staticClass: "all_liet" }, [
      _c("div", { staticClass: "lie_two" }, [
        _c("p", { staticClass: "title_word" }, [_vm._v("使用云服务")]),
        _c(
          "div",
          { staticClass: "switch" },
          [
            _c("el-switch", {
              attrs: { "active-color": "#33ab79", "inactive-color": "#999999" },
              on: {
                change: function ($event) {
                  return _vm.chice1(_vm.value2)
                },
              },
              model: {
                value: _vm.value2,
                callback: function ($$v) {
                  _vm.value2 = $$v
                },
                expression: "value2",
              },
            }),
          ],
          1
        ),
        _c("p", { staticClass: "tip_word" }, [
          _vm._v(" 开启时，接车单添加服务使用系统自带云服务，系统匹配车况 "),
        ]),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.chioce1,
              expression: "chioce1",
            },
          ],
          staticClass: "chice_word",
        },
        [
          _c("p", [_vm._v("服务默认所属分类")]),
          _c("input", { attrs: { placeholder: "  大类+细类" } }),
          _c("p", [
            _vm._v(
              " 下载使用云服务时，默认下载并设置到所属云分类，无默认时使用设置的分类 "
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "lie_two lie_two_st" }, [
        _c("p", { staticClass: "title_word" }, [_vm._v("使用云商品")]),
        _c(
          "div",
          { staticClass: "switch" },
          [
            _c("el-switch", {
              attrs: { "active-color": "#33ab79", "inactive-color": "#999999" },
              on: {
                change: function ($event) {
                  return _vm.chice2(_vm.value3)
                },
              },
              model: {
                value: _vm.value3,
                callback: function ($$v) {
                  _vm.value3 = $$v
                },
                expression: "value3",
              },
            }),
          ],
          1
        ),
        _c("p", { staticClass: "tip_word" }, [
          _vm._v(" 开启时，接车单添加商品使用系统自带云商品，系统匹配车况 "),
        ]),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.chioce2,
              expression: "chioce2",
            },
          ],
          staticClass: "chice_word",
        },
        [
          _c("p", [_vm._v("商品默认所属分类")]),
          _c("input", { attrs: { placeholder: "  大类+细类" } }),
          _c("p", [
            _vm._v(
              " 下载使用云商品时，默认下载并设置到所属云分类，无默认时使用设置的分类 "
            ),
          ]),
        ]
      ),
    ]),
    _c("div", { staticClass: "all_lie_th" }, [
      _c("div", { staticClass: "lie_two" }, [
        _c("p", { staticClass: "title_word" }, [_vm._v("开放车单反结算")]),
        _c(
          "div",
          { staticClass: "switch" },
          [
            _c("el-switch", {
              attrs: { "active-color": "#33ab79", "inactive-color": "#999999" },
              on: {
                change: function ($event) {
                  return _vm.chice3(_vm.value4)
                },
              },
              model: {
                value: _vm.value4,
                callback: function ($$v) {
                  _vm.value4 = $$v
                },
                expression: "value4",
              },
            }),
          ],
          1
        ),
        _c("p", { staticClass: "tip_word" }, [
          _vm._v(
            " 开启时，允许接车单在结账后，操作反结算，回到待结账状态，只能操作一个月内的车单 "
          ),
        ]),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.chioce3,
              expression: "chioce3",
            },
          ],
          staticClass: "chice_word other_st",
        },
        [
          _c("p", [_vm._v("车单反结算期限")]),
          _c("input", { attrs: { placeholder: "  一个月" } }),
          _c("p", [_vm._v("车单可在操作结账的日期后一个月内操作反结算")]),
        ]
      ),
    ]),
    _c("div", { staticClass: "all_lie_th" }, [
      _c("div", { staticClass: "lie_two" }, [
        _c("p", { staticClass: "title_word" }, [_vm._v("接车单自动扣减库存")]),
        _c(
          "div",
          { staticClass: "switch" },
          [
            _c("el-switch", {
              attrs: { "active-color": "#33ab79", "inactive-color": "#999999" },
              on: {
                change: function ($event) {
                  return _vm.chice4(_vm.value5)
                },
              },
              model: {
                value: _vm.value5,
                callback: function ($$v) {
                  _vm.value5 = $$v
                },
                expression: "value5",
              },
            }),
          ],
          1
        ),
        _c("p", { staticClass: "tip_word" }, [
          _vm._v(
            " 已开启自动扣减，并允许负库存出库，接车单结账后将自动扣减库存 "
          ),
        ]),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.chioce4,
              expression: "chioce4",
            },
          ],
          staticStyle: { "margin-top": "17px" },
        },
        [
          _c("div", { staticClass: "lie_two" }, [
            _c(
              "p",
              { staticClass: "title_word", staticStyle: { color: "#999999" } },
              [_vm._v("允许负库存出库")]
            ),
            _c(
              "div",
              { staticClass: "switch" },
              [
                _c("el-switch", {
                  attrs: {
                    "active-color": "#33ab79",
                    "inactive-color": "#999999",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.chice5(_vm.value6)
                    },
                  },
                  model: {
                    value: _vm.value6,
                    callback: function ($$v) {
                      _vm.value6 = $$v
                    },
                    expression: "value6",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._m(0),
        ]
      ),
    ]),
    _vm._m(1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "footer" }, [
      _c("p", [_vm._v("默认出库仓库")]),
      _c("input", { attrs: { placeholder: "  选择出库仓库" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "footer_but" }, [
      _c("button", [_vm._v("保存")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }