var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      attrs: {
        placement: "bottom-end",
        trigger: "manual",
        "popper-class": "popper-class",
      },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
          staticClass: "bill-table",
          attrs: { "max-height": 400, border: "", data: _vm.listData },
          on: { "row-click": _vm.handleClick },
        },
        [
          _c("el-table-column", {
            attrs: { type: "index", width: "60", label: "序号" },
          }),
          _c("el-table-column", {
            attrs: { width: "120", label: "车牌", prop: "carNumber" },
          }),
          _c("el-table-column", {
            attrs: { width: "170", label: "单据号", prop: "billCode" },
          }),
          _c("el-table-column", {
            attrs: { width: "60", label: "类型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("dict")(row.billType, "carBillType")) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              width: "100",
              label: "业务来源",
              prop: "source",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: { width: "175", label: "车架号", prop: "uniqueId" },
          }),
          _c("el-table-column", {
            attrs: { width: "100", label: "车单状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("dict")(row.billState, "carBillState")) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "160", label: "进店时间", prop: "dateCreated" },
          }),
          _c("el-table-column", {
            attrs: { width: "80", label: "接车员", prop: "creator.userName" },
          }),
          _c("el-table-column", {
            attrs: {
              "min-width": "140",
              label: "操作门店",
              prop: "operateCompanyName",
            },
          }),
          _c("template", { slot: "append" }, [
            _vm.hasMore
              ? _c(
                  "div",
                  {
                    staticClass: "load-more-btn",
                    on: {
                      click: function ($event) {
                        return _vm.getData(false, true)
                      },
                    },
                  },
                  [_vm._v(" 加载更多数据 ")]
                )
              : _vm._e(),
          ]),
        ],
        2
      ),
      _c(
        "template",
        { slot: "reference" },
        [
          _c(
            "el-input",
            {
              ref: "keyWordInput",
              staticStyle: { width: "160px" },
              attrs: { size: "small", placeholder: "搜索车牌", clearable: "" },
              on: {
                focus: function ($event) {
                  _vm.listData.length && (_vm.visible = true)
                },
                clear: _vm.handleClear,
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.getData(true)
                },
              },
              model: {
                value: _vm.keyWord,
                callback: function ($$v) {
                  _vm.keyWord = $$v
                },
                expression: "keyWord",
              },
            },
            [
              _c("i", {
                staticClass: "el-input__icon el-icon-search pointer",
                attrs: { slot: "suffix" },
                on: {
                  click: function ($event) {
                    return _vm.getData(true)
                  },
                },
                slot: "suffix",
              }),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }