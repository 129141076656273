<template>
  <div>
    <el-dialog
      title="温馨提示"
      :visible="delectDialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div class="content">
        <span>确定要删除选中的业务来源？</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="commit">确 定</el-button>
        <el-button @click="handleClose">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    delectDialogVisible: Boolean,
  },
  data() {
    return {
      //   delectDialogVisible: false,
    };
  },
  methods: {
    commit() {
      this.$emit('delect', true);
    },
    handleClose() {
      this.$emit('close', false);
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
