<template>
  <base-container ref="baseContainer" paddingTop>
    <div style="height: 92%">
      <p class="red" style="font-size: 14px; margin-bottom: 20px">
        注：无解绑车辆权限的点击保存后仅能保存未绑定客户的车辆，请注意操作
      </p>
      <el-table
        style="width: 100%"
        class="custom-table"
        :data="carTableData"
        border
        v-fixedScrollBar="$refs.baseContainer"
      >
        <el-table-column width="150" label="车牌" prop="carNumber">
        </el-table-column>
        <el-table-column min-width="140" v-if="showCustomer">
          <template slot="header">
            <span>签约客户</span>
            <i
              style="font-size: 14px"
              class="iconfont grow-a-icon_fillout color666 el-icon--right pointer"
              @click="openChioce(1)"
            ></i>
          </template>
          <template #default="{ row }">
            <el-select
              v-model="row.clientMessage"
              @change="selectClient"
              filterable
              clearable
              remote
              size="small"
              placeholder="请输入客户名称搜索"
              :remote-method="searchClient"
              :loading="searchClientLoading"
            >
              <el-option
                v-for="item in custlist"
                :key="item.id"
                :label="item.clientName"
                :value="item.id"
              >
                <span style="float: left; color: #8492a6; font-size: 13px">{{
                  item.clientName
                }}</span>
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column min-width="150" prop="carModelName">
          <template #header>
            <span>车型</span>
            <i
              style="font-size: 14px"
              class="iconfont grow-a-icon_fillout color666 el-icon--right pointer"
              @click="openChioce(2)"
            ></i>
          </template>
          <template #default="{ row }">
            <el-select
              v-model="row.carModelName"
              filterable
              remote
              size="small"
              placeholder="搜索车型"
              :remote-method="searchModel"
              :loading="searchModelLoading"
              clearable
            >
              <el-option
                v-for="item in models"
                :key="$lodash.get(item, 'series.id')"
                :value="
                  $lodash.get(
                    item,
                    'series.name',
                    $lodash.get(
                      item,
                      'brand.name',
                      $lodash.get(item, 'logo.name')
                    )
                  )
                "
                @click.native="selectCarModel(item, row)"
              >
                <span v-html="item.markedRedName"></span>
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column min-width="160" prop="annualSurveyCarType">
          <template #header>
            <span>年审类型</span>
            <i
              style="font-size: 14px"
              class="iconfont grow-a-icon_fillout color666 el-icon--right pointer"
              @click="openChioce(3)"
            ></i>
          </template>
          <template #default="{ row }">
            <el-select
              v-model="row.annualSurveyCarType"
              placeholder="请选择年审类型"
              filterable
              remote
              clearable
              size="small"
              style="width: 90%"
            >
              <el-option
                v-for="type in annualVerificationTypes"
                :key="type.value"
                :value="type.value"
                :label="type.label"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <!--
        <el-table-column width="120" prop="state">
          <template slot="header">
            <span>车辆状态</span>
            <i
              style="font-size: 14px"
              class="iconfont grow-a-icon_fillout color666 el-icon--right pointer"
              @click="openChioce(4)"
            ></i>
          </template>
          <template #default="{ row }">
            <el-select
              v-model="row.state"
              placeholder="请选择"
              filterable
              remote
              clearable
              size="small"
            >
              <el-option
                v-for="type in carState"
                :key="type.value"
                :value="type.value"
                :label="type.label"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        -->
        <el-table-column min-width="150" prop="remark">
          <template #header>
            <span>备注</span>
            <i
              style="font-size: 14px"
              class="iconfont grow-a-icon_fillout color666 el-icon--right pointer"
              @click="openChioce(5)"
            ></i>
          </template>
          <template #default="{ row }">
            <el-input
              type="textarea"
              :rows="1"
              placeholder="请输入车辆备注"
              v-model="row.remark"
              size="small"
              clearable
              maxlength="100"
              show-word-limit
            ></el-input>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <template slot="footer">
      <div class="flex-x-end foot-btn">
        <el-button v-auth="53" type="primary" @click="save">保存</el-button>
        <el-button v-auth="53" @click="cancel">取消</el-button>
      </div>
    </template>

    <!-- 批量弹窗 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <template slot="title">
        {{ dialogVisibleTitel }}
      </template>
      <div v-if="type == 1" class="select-sty">
        <el-select
          v-model="clientName"
          @change="selectClient"
          filterable
          clearable
          remote
          size="small"
          style="width: 80%"
          placeholder="请输入姓名"
          :remote-method="searchClient"
          :loading="searchClientLoading"
        >
          <el-option
            v-for="item in custlist"
            :key="item.id"
            :label="item.clientName"
            :value="item.id"
          >
            <span style="float: left; color: #8492a6; font-size: 13px">{{
              item.clientName
            }}</span>
          </el-option>
        </el-select>
      </div>
      <div v-if="type == 2" class="select-sty">
        <el-select
          v-model="carModelName"
          filterable
          remote
          size="small"
          style="width: 80%"
          placeholder="搜索车型"
          :remote-method="searchModel"
          :loading="searchModelLoading"
          clearable
        >
          <el-option
            v-for="item in models"
            :key="$lodash.get(item, 'series.id')"
            :value="
              $lodash.get(
                item,
                'series.name',
                $lodash.get(item, 'brand.name', $lodash.get(item, 'logo.name'))
              )
            "
            @click.native="selectCarModel(item)"
          >
            <span v-html="item.markedRedName"></span>
          </el-option>
        </el-select>
      </div>
      <div v-if="type == 3" class="select-sty">
        <el-select
          v-model="annualSurveyCarType"
          placeholder="请选择年审类型"
          filterable
          remote
          clearable
          size="small"
          style="width: 80%"
        >
          <el-option
            v-for="type in annualVerificationTypes"
            :key="type.value"
            :value="type.value"
            :label="type.label"
          >
          </el-option>
        </el-select>
      </div>
      <div v-if="type == 4" class="select-sty">
        <el-select
          v-model="carStates"
          placeholder="请选择"
          filterable
          remote
          clearable
          size="small"
          style="width: 80%"
        >
          <el-option
            v-for="type in carState"
            :key="type.value"
            :value="type.value"
            :label="type.label"
          >
          </el-option>
        </el-select>
      </div>
      <div v-if="type == 5" class="select-sty">
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4 }"
          placeholder="请输入车辆备注"
          v-model="remark"
          size="small"
          style="width: 80%"
          clearable
          maxlength="100"
          show-word-limit
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine">确 定</el-button>
      </span>
    </el-dialog>
  </base-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { hasAuth } from '@/utils/permissions';
//接口
import { searchClient } from '@/api/customer/customer';
import {
  searchCarModel,
  carQueryCarListByIds,
  caraBtchUpdateCar,
} from '@/api/customer/car';

// 字典
import { annualSurveyCarType, carState } from '@/service/dict/dictData';
import { number } from 'echarts';
export default {
  name: 'carEditor',
  components: {},
  data() {
    return {
      carTableData: [],
      ids: [],
      //签约客户
      custlist: [],
      searchClientLoading: false,
      //搜索车型信息列表字段
      searchModelLoading: false,
      models: [],
      // 年审类型
      annualVerificationTypes: annualSurveyCarType,
      //车辆状态
      carState: carState,
      //弹窗可见性
      dialogVisible: false,
      type: '',
      //客户
      clientName: '',
      //车型
      carModelName: '',
      //年审类型
      annualSurveyCarType: '',
      //车辆状态
      carStates: '',
      //备注
      remark: '',
      dialogVisibleTitel: '批量选择',

      showCustomer: false, //根据权限判断是否显示车主客户那一列数据
    };
  },
  activated() {
    if (this.$route.params.ids != undefined) {
      this.ids = this.$lodash.cloneDeep(this.$route.params.ids);
      // this.$lodash.cloneDeep(this.$route.params.ids).map((item) => {
      //   this.ids.push(item);
      // });
      // // 处理数组去重
      // this.ids = this.$lodash.unionBy(this.ids);
      // this.$nextTick().then(() => {
      this.getCarDater();
      // });
    }
    // else {
    //   this.ids == this.ids;
    // }
  },
  created() {
    this.searchModel();
    this.searchClient();
    // if (!this.$route.params.forms) {
    //   this.getCarDater();
    // }
  },
  computed: {
    ...mapGetters(['activeTagIndex']),
  },
  methods: {
    ...mapMutations({
      closeTag: 'DEL_TAG',
    }),

    //取消关闭页面
    cancel() {
      this.closeTag({ index: this.activeTagIndex });
    },
    /**
     * 远程搜索车主客户列表
     */
    clientKeyWordChange(keyword) {
      if (keyword) {
        this.searchClient(keyword);
      }
    },
    searchClient(keyWord = '') {
      this.searchClientLoading = true;
      searchClient(keyWord)
        .then((res) => {
          this.custlist = res.records;
        })
        .finally(() => {
          this.searchClientLoading = false;
        });
    },
    /**
     * 选择车主客户
     */
    selectClient(clientId) {},

    //远程搜索车型信息列表
    searchModel(key = '') {
      searchCarModel(key).then((res) => {
        this.models = res.records || [];
        this.models = this.models.filter((item) => item.brand !== undefined);
      });
    },
    selectCarModel(val, row) {
      if (row != undefined) {
        row.other = val;
      } else {
        this.carTableData = this.$lodash
          .cloneDeep(this.carTableData)
          .map((item) => ({
            other: val,
            annualSurveyCarType: item.annualSurveyCarType,
            carId: item.carId,
            carModelName: item.carModelName,
            carNumber: item.carNumber,
            clientId: item.clientId,
            clientMessage: item.clientMessage,
            clientName: item.clientName,
            clientPhone: item.clientPhone,
            levelId: item.levelId,
            // modelDetailBrandId: val.brand.id,
            // modelDetailBrandName: val.brand.name,
            // modelDetailLogoId: val.logo.id,
            // modelDetailLogoName: val.logo.name,
            // modelDetailSeriesId: val.series.id,
            // modelDetailSeriesName: val.series.name,
            remark: item.remark,
            state: item.state,
            type: item.type,
          }));
      }

      // this.$lodash.get(
      //   val,
      //   'series.name',
      //   this.$lodash.get(val, 'brand.name', this.$lodash.get(val, 'logo.name'))
      // );
    },
    //查看车辆详情请求
    getCarDater() {
      carQueryCarListByIds({ ids: this.ids }).then((res) => {
        let showAuth = hasAuth(63);
        if (showAuth) {
          this.showCustomer = true;
        } else {
          this.showCustomer = false;
        }
        this.carTableData = res;
        this.carTableData = this.carTableData.map((item) => ({
          annualSurveyCarType: item.annualSurveyCarType,
          carId: item.carId,
          carModelName: item.carModelName,
          carNumber: item.carNumber,
          clientId: item.clientId,
          clientMessage: item.clientName != undefined ? item.clientName : '',
          clientName: item.clientName,
          clientPhone: item.clientPhone,
          levelId: item.levelId,
          modelDetailBrandId: item.modelDetailBrandId,
          modelDetailBrandName: item.modelDetailBrandName,
          modelDetailLogoId: item.modelDetailLogoId,
          modelDetailLogoName: item.modelDetailLogoName,
          modelDetailSeriesId: item.modelDetailSeriesId,
          modelDetailSeriesName: item.modelDetailSeriesName,
          remark: item.remark,
          state: item.state,
          type: item.type,
        }));
        // this.carTableData = res;
      });
    },
    //关闭弹窗
    handleClose() {
      this.dialogVisible = false;
    },
    openChioce(val) {
      if (val == 5) {
        this.dialogVisibleTitel = '批量输入备注';
      } else {
        this.dialogVisibleTitel = '批量选择';
      }
      this.type = val;
      this.dialogVisible = true;
    },
    //批量选择点击确定
    determine() {
      this.carTableData.forEach((item) => {
        if (this.type == 2) {
          this.$set(item, 'carModelName', this.carModelName);
        } else if (this.type == 3) {
          this.$set(item, 'annualSurveyCarType', this.annualSurveyCarType);
        } else if (this.type == 4) {
          this.$set(item, 'state', this.carStates);
        } else if (this.type == 5) {
          this.$set(item, 'remark', this.remark);
        } else if (this.type == 1) {
          this.$set(item, 'clientMessage', this.clientName);
        }
      });
      this.$set(this, 'carTableData', this.carTableData);
      this.dialogVisible = false;
    },

    //保存修改
    save() {
      this.carTableData = this.carTableData.map((item) => ({
        clientId:
          item.clientMessage === ''
            ? ''
            : Object.prototype.toString.call(item.clientMessage) ===
              '[object Number]'
            ? item.clientMessage
            : item.clientId,
        clientMessage: item.clientMessage,
        carModelName: item.carModelName,
        annualSurveyCarType: item.annualSurveyCarType,
        carId: item.carId,
        carNumber: item.carNumber,
        state: item.state,
        remark: item.remark,
        levelId: '',
        other: item.other,
        modelDetailBrandId:
          item.carModelName === ''
            ? ''
            : item.other !== undefined
            ? item.other.brand.id
            : item.modelDetailLogoId,
        modelDetailBrandName:
          item.carModelName === ''
            ? ''
            : item.other !== undefined
            ? item.other.brand.name
            : item.modelDetailBrandName,
        modelDetailLogoId:
          item.carModelName === ''
            ? ''
            : item.other !== undefined
            ? item.other.logo.id
            : item.modelDetailLogoId,
        modelDetailLogoName:
          item.carModelName === ''
            ? ''
            : item.other !== undefined
            ? item.other.logo.name
            : item.modelDetailLogoName,
        modelDetailSeriesId:
          item.carModelName === ''
            ? ''
            : item.other !== undefined
            ? item.other.series.id
            : item.modelDetailSeriesId,
        modelDetailSeriesName:
          item.carModelName === ''
            ? ''
            : item.other !== undefined
            ? item.other.series.name
            : item.modelDetailSeriesName,
        // modelDetailLogoId:
        //   Object.prototype.toString.call(item.carModelName) ===
        //   '[object Object]'
        //     ? item.carModelName.logo.id
        //     : item.modelDetailLogoId, //LogoId
        //   modelDetailLogoName:
        //     Object.prototype.toString.call(item.carModelName) ===
        //     '[object Object]'
        //       ? item.carModelName.logo.name
        //       : item.modelDetailLogoName, //Logo名称
        //   modelDetailBrandId:
        //     Object.prototype.toString.call(item.carModelName) ===
        //     '[object Object]'
        //       ? item.carModelName.brand.id
        //       : item.modelDetailBrandId, //品牌Id
        //   modelDetailBrandName:
        //     Object.prototype.toString.call(item.carModelName) ===
        //     '[object Object]'
        //       ? item.carModelName.brand.name
        //       : item.modelDetailBrandName, //品牌名称
        //   modelDetailSeriesId:
        //     Object.prototype.toString.call(item.carModelName) ===
        //     '[object Object]'
        //       ? item.carModelName.series.id
        //       : item.modelDetailSeriesId, //车系ID
        //   modelDetailSeriesName:
        //     Object.prototype.toString.call(item.carModelName) ===
        //     '[object Object]'
        //       ? item.carModelName.series.name
        //       : item.modelDetailSeriesName, //车系名称
      }));
      var data = {
        type: 0,
        carList: this.carTableData,
      };
      caraBtchUpdateCar(data).then((res) => {
        if (res.type != 0) {
          this.$confirm(res.message, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(() => {
            var datas = {
              type: res.type,
              carList: this.carTableData,
            };
            caraBtchUpdateCar(datas).then((res) => {
              if (res.type == 0) {
                this.$message.success('修改成功');
                this.closeTag({ index: this.activeTagIndex });
              }
            });
          });
        } else if (res.type == 0) {
          this.$message.success('修改成功');
          this.closeTag({ index: this.activeTagIndex });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.foot-btn {
  padding: 10px 20px;
  box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.1);
  .el-dropdown {
    margin-left: 24px;
  }
  .el-dropdown-item ::v-deep .el-dropdown-menu {
    padding: 0;
  }
}
.select-sty {
  text-align: center;
  margin: 20px;
}
</style>
