var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-drawer",
        {
          attrs: {
            title: "施工项目",
            visible: _vm.visibility,
            size: "50%",
            "append-to-body": "",
            "before-close": _vm.handleClose,
          },
        },
        [
          _c(
            "div",
            { staticClass: "update-fix-items_box" },
            [
              _c("div", { staticClass: "bill-info" }, [
                _c("div", { staticClass: "row flex-x-between" }, [
                  _c("div", { staticClass: "col flex" }, [
                    _c("div", { staticClass: "label" }, [_vm._v("接车单：")]),
                    _c("div", { staticClass: "value" }, [
                      _c(
                        "span",
                        {
                          staticClass: "blue pointer",
                          on: {
                            click: function ($event) {
                              return _vm.goCarBillDetail(_vm.detailData.billId)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.detailData.billCode))]
                      ),
                      _vm.detailData.isOthersSelfPayState
                        ? _c("span", [_vm._v(" (他人自费) ")])
                        : _vm._e(),
                    ]),
                  ]),
                  _c("div", { staticClass: "col flex" }, [
                    _c("div", { staticClass: "label" }, [
                      _vm._v("预交车时间："),
                    ]),
                    _c("div", { staticClass: "value" }, [
                      _vm.detailData.planFinishTime
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.detailData.planFinishTime)),
                          ])
                        : _vm._e(),
                      _vm.detailData.waitInStore
                        ? _c("span", [_vm._v(" （在店等） ")])
                        : _vm._e(),
                      _vm.detailData.undetermined
                        ? _c("span", [_vm._v(" 待定 ")])
                        : _vm._e(),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "row flex-x-between",
                    staticStyle: { "margin-top": "20px" },
                  },
                  [
                    _c("div", { staticClass: "col flex" }, [
                      _c("div", { staticClass: "label" }, [_vm._v("车牌号：")]),
                      _c("div", { staticClass: "value" }, [
                        _c("span", [_vm._v(_vm._s(_vm.detailData.carNumber))]),
                        _vm.detailData.carType !== undefined
                          ? _c("span", [
                              _vm._v(
                                " (" + _vm._s(_vm.detailData.vehicleType) + ") "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                    _c("div", { staticClass: "col" }, [
                      _c("div", { staticClass: "label" }, [
                        _vm._v(" 车型/车架号："),
                        _c("span", [_vm._v(_vm._s(_vm.detailData.carModel))]),
                      ]),
                      _vm._v(" s "),
                      _c(
                        "div",
                        {
                          staticClass: "value",
                          staticStyle: { "margin-top": "10px" },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              " VIN: " + _vm._s(_vm.detailData.uniqueId) + " "
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "btns" }, [
                _c("span", [_vm._v("勾选施工中项目，操作：")]),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "auth",
                        rawName: "v-auth",
                        value: 454,
                        expression: "454",
                      },
                    ],
                    staticClass: "pointer orange",
                    on: {
                      click: function ($event) {
                        return _vm.verifyNotEmpty("workFinishedDialogVisible")
                      },
                    },
                  },
                  [_vm._v("项目完工")]
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "auth",
                        rawName: "v-auth",
                        value: 453,
                        expression: "453",
                      },
                    ],
                    staticClass: "pointer blue",
                    on: {
                      click: function ($event) {
                        return _vm.verifyNotEmpty("submitChangeDialogVisible")
                      },
                    },
                  },
                  [_vm._v("提交改派")]
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "auth",
                        rawName: "v-auth",
                        value: 96,
                        expression: "96",
                      },
                    ],
                    staticClass: "pointer second",
                    on: {
                      click: function ($event) {
                        return _vm.verifyNotEmpty("dispatchingDialogVisible")
                      },
                    },
                  },
                  [_vm._v("施工改派")]
                ),
              ]),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%", margin: "10px 0" },
                  attrs: {
                    border: "",
                    data: _vm.taskList,
                    "span-method": _vm.spanMethod,
                  },
                  on: { "selection-change": _vm.selectionChange },
                },
                [
                  _c("el-table-column", { attrs: { type: "selection" } }),
                  _c("el-table-column", {
                    attrs: { type: "index", label: "序号", width: "60" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "项目名称",
                      width: "200",
                      prop: "serviceItemName",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "项目编码",
                      width: "120",
                      prop: "serviceItemCode",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "内部标签", width: "140", prop: "tags" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "施工技师" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _vm._v(" " + _vm._s(row.technicianNames) + " "),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "reworkTimes", label: "返工次数" },
                  }),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        label: "历史返工详情",
                        "header-align": "center",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          width: "140",
                          label: "质检时间",
                          prop: "qualityInspectionTime",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          width: "120",
                          label: "质检员",
                          prop: "qualityInspectionUserName",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          width: "180",
                          label: "返工原因",
                          prop: "reworkReason",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c("p", [_vm._v(_vm._s(row.reworkReason))]),
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "top",
                                      width: "450",
                                      trigger: "click",
                                    },
                                  },
                                  [
                                    _c("upload-file-list", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: row.reworkPics,
                                        callback: function ($$v) {
                                          _vm.$set(row, "reworkPics", $$v)
                                        },
                                        expression: "row.reworkPics",
                                      },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "pointer blue",
                                        attrs: { slot: "reference" },
                                        slot: "reference",
                                      },
                                      [
                                        _vm._v(
                                          "质检图片(" +
                                            _vm._s(
                                              _vm.$lodash
                                                .get(row, "reworkPics", "")
                                                .split(",")
                                                .filter((item) => item).length
                                            ) +
                                            ")"
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("p", { staticClass: "color333" }, [
                _vm._v("已完工或免施工项目"),
              ]),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%", margin: "10px 0" },
                  attrs: {
                    border: "",
                    data: _vm.finishedList,
                    "span-method": _vm.spanMethod,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "index", label: "序号", width: "60" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "项目名称",
                      width: "200",
                      prop: "serviceItemName",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "项目编码",
                      width: "120",
                      prop: "serviceItemCode",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "内部标签", width: "140", prop: "tags" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "施工技师" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _vm._v(" " + _vm._s(row.technicianNames) + " "),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "reworkTimes", label: "返工次数" },
                  }),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        label: "历史返工详情",
                        "header-align": "center",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          width: "140",
                          label: "质检时间",
                          prop: "qualityInspectionTime",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          width: "120",
                          label: "质检员",
                          prop: "qualityInspectionUserName",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          width: "180",
                          label: "返工原因",
                          prop: "reworkReason",
                          "show-overflow-tooltip": "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "项目完工",
            visible: _vm.workFinishedDialogVisible,
            width: "35%",
            "close-on-click-modal": false,
            "before-close": () => {
              _vm.workFinishedDialogVisible = false
            },
            center: "",
          },
        },
        [
          _c("div", { staticClass: "work-finished-dialog" }, [
            _c("p", [
              _vm._v(
                " 确定已选择的施工项目（数量 " +
                  _vm._s(_vm.selectedList.length) +
                  "）已完成施工？ "
              ),
            ]),
            _c("p", [
              _c("i", { staticClass: "el-icon-warning orange" }),
              _vm._v(
                " 注：如本车单需施工的项目已全部完工，“确定”成功后系统将自动提交质检，"
              ),
              _c("br"),
              _vm._v("且有【完工质检】通知推送给有接收通知权限的终检员 "),
            ]),
          ]),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.workFinishedDialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.confirmFinished },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提交改派",
            width: "35%",
            visible: _vm.submitChangeDialogVisible,
            "close-on-click-modal": false,
            "before-close": () => {
              _vm.submitChangeDialogVisible = false
            },
            center: "",
          },
        },
        [
          _c("div", { staticClass: "work-finished-dialog" }, [
            _c("p", [
              _vm._v(
                " 确定要将已选择的施工项目（数量 " +
                  _vm._s(_vm.selectedList.length) +
                  "）提交给车间主管，改派其他班组施工？ "
              ),
            ]),
            _c("p", [
              _c("i", { staticClass: "el-icon-warning orange" }),
              _vm._v(
                " 注：操作成功，有【提交施工】通知推送给有接收权限的员工 "
              ),
            ]),
          ]),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.submitChangeDialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.confirmChange },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm.dispatchingDialogVisible
        ? _c("dispatching-dialog", {
            attrs: {
              visibility: _vm.dispatchingDialogVisible,
              id: _vm.id,
              dispatchType: _vm.dispatchType,
              billData: _vm.detailData,
              list: _vm.selectedList,
            },
            on: {
              "update:visibility": function ($event) {
                _vm.dispatchingDialogVisible = $event
              },
              finish: _vm.handleClose,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }