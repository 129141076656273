var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visibility,
        width: "800px",
        "before-close": _vm.handleClose,
        "close-on-click-modal": false,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticStyle: { "margin-top": "18px" },
          attrs: {
            size: "small",
            model: _vm.editForm,
            rules: _vm.rules,
            "label-width": "90px",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 8, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "适用车型", prop: "carModel" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { change: _vm.handleIsGeneralChange },
                          model: {
                            value: _vm.editForm.isGeneral,
                            callback: function ($$v) {
                              _vm.$set(_vm.editForm, "isGeneral", $$v)
                            },
                            expression: "editForm.isGeneral",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("通用"),
                          ]),
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("车型件"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-col", { attrs: { span: 12, offset: 0 } }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.editForm.isGeneral === 0,
                        expression: "editForm.isGeneral === 0",
                      },
                    ],
                  },
                  [
                    _c(
                      "el-select",
                      {
                        staticClass: "w100",
                        attrs: {
                          filterable: "",
                          remote: "",
                          placeholder: "搜索车型",
                          "remote-method": _vm.searchCarModelList,
                          loading: _vm.searchCarModelListLoading,
                          "value-key": "id",
                          size: "small",
                        },
                        model: {
                          value: _vm.selectedCarModel,
                          callback: function ($$v) {
                            _vm.selectedCarModel = $$v
                          },
                          expression: "selectedCarModel",
                        },
                      },
                      _vm._l(_vm.carModelData, function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.name, value: item },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.handleAddCarModel(item)
                            },
                          },
                        })
                      }),
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "flex",
                        staticStyle: { "margin-bottom": "10px" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "flex-shirk": "0",
                              width: "42px",
                              "margin-top": "16px",
                              "white-space": "nowrap",
                            },
                          },
                          [_vm._v(" 已选： ")]
                        ),
                        _c(
                          "div",
                          { staticClass: "selected-car-model-list" },
                          _vm._l(_vm.carModelList, function (tag, i) {
                            return _c(
                              "el-tag",
                              {
                                key: tag.seriesId,
                                attrs: {
                                  type: "info",
                                  size: "mini",
                                  closable: "",
                                },
                                on: {
                                  close: function ($event) {
                                    return _vm.handleDeleteCarModel(i)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(tag.name) + " ")]
                            )
                          }),
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c("el-col", { attrs: { span: 4, offset: 0 } }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.editForm.isGeneral === 0,
                        expression: "editForm.isGeneral === 0",
                      },
                    ],
                    staticClass: "blue pointer flex-y-center",
                    staticStyle: { height: "32px" },
                    on: {
                      click: function ($event) {
                        _vm.selectCarModelDialogVisibility = true
                      },
                    },
                  },
                  [_vm._v(" 选择车型 ")]
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "品牌", prop: "brandName" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "50", placeholder: "请输入品牌" },
                        model: {
                          value: _vm.editForm.brandName,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "brandName", $$v)
                          },
                          expression: "editForm.brandName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "品名", prop: "productId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            clearable: "",
                            remote: "",
                            placeholder: "请输入品名查询",
                            "remote-method": _vm.searchProductList,
                            loading: _vm.searchProductListLoading,
                          },
                          on: {
                            change: _vm.handleProductIdChange,
                            clear: _vm.handleProductClear,
                            blur: _vm.handleProductBlur,
                          },
                          model: {
                            value: _vm.editForm.productId,
                            callback: function ($$v) {
                              _vm.$set(_vm.editForm, "productId", $$v)
                            },
                            expression: "editForm.productId",
                          },
                        },
                        _vm._l(_vm.productList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "规格", prop: "goodsFormat" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "200", placeholder: "请输入规格" },
                        model: {
                          value: _vm.editForm.goodsFormat,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "goodsFormat", $$v)
                          },
                          expression: "editForm.goodsFormat",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "特征", prop: "feature" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "200", placeholder: "请输入特征" },
                        model: {
                          value: _vm.editForm.feature,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "feature", $$v)
                          },
                          expression: "editForm.feature",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "品质", prop: "manufactoryType" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择", clearable: "" },
                          model: {
                            value: _vm.editForm.manufactoryType,
                            callback: function ($$v) {
                              _vm.$set(_vm.editForm, "manufactoryType", $$v)
                            },
                            expression: "editForm.manufactoryType",
                          },
                        },
                        _vm._l(_vm.manufactoryTypeDict, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "原厂编码", prop: "goodsCode" } },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "200",
                          placeholder: "请输入原厂编码",
                        },
                        model: {
                          value: _vm.editForm.goodsCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "goodsCode", $$v)
                          },
                          expression: "editForm.goodsCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "单位", prop: "unit" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "200", placeholder: "请输入单位" },
                        model: {
                          value: _vm.editForm.unit,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "unit", $$v)
                          },
                          expression: "editForm.unit",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品备注", prop: "remark" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入商品备注",
                          maxlength: "100",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.editForm.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "remark", $$v)
                          },
                          expression: "editForm.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c("el-form-item", { attrs: { label: "商品分类" } }, [
                    _vm._v(" " + _vm._s(_vm.className) + " "),
                  ]),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [_c("el-form-item", { attrs: { label: "索赔属性" } })],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "库存预警值", prop: "alarmNum" } },
                    [
                      _c("el-input-number", {
                        attrs: {
                          maxlength: "200",
                          min: 0,
                          placeholder: "请输入库存预警值",
                          clearable: "",
                        },
                        model: {
                          value: _vm.editForm.alarmNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "alarmNum", $$v)
                          },
                          expression: "editForm.alarmNum",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c("el-form-item", { attrs: { label: "商品标准名" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.standardName))]),
                  ]),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c("el-form-item", { attrs: { label: "商品名称" } }, [
                    _vm._v(_vm._s(_vm.goodsFullName)),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c("el-form-item", { attrs: { label: "商品图片" } }, [
                    _c(
                      "div",
                      [
                        _c(
                          "p",
                          {
                            staticStyle: {
                              "font-size": "12px",
                              color: "#84878c",
                            },
                          },
                          [_vm._v("（最多上传3张）")]
                        ),
                        _c("upload-file-list", {
                          attrs: { max: 3 },
                          model: {
                            value: _vm.editForm.images,
                            callback: function ($$v) {
                              _vm.$set(_vm.editForm, "images", $$v)
                            },
                            expression: "editForm.images",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "启用状态" } },
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.editForm.isActive,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "isActive", $$v)
                          },
                          expression: "editForm.isActive",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c("el-col", { attrs: { span: 12, offset: 0 } }, [
                _vm.editForm.oldName
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-left": "22px",
                          height: "32px",
                          "line-height": "32px",
                        },
                      },
                      [
                        _vm._v(
                          " 原系统商品名称: " +
                            _vm._s(_vm.editForm.oldName) +
                            " "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.selectCarModelDialogVisibility
        ? _c("select-car-model-dialog", {
            attrs: {
              visibility: _vm.selectCarModelDialogVisibility,
              multiple: "",
              value: _vm.carModelList,
            },
            on: {
              "update:visibility": function ($event) {
                _vm.selectCarModelDialogVisibility = $event
              },
              select: _vm.handleSelectCarModel,
            },
          })
        : _vm._e(),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.save },
            },
            [_vm._v(" 确 定 ")]
          ),
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.handleClose } },
            [_vm._v(" 取 消 ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }