var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visibility,
        width: "510px",
        "before-close": _vm.handleClose,
        "close-on-click-modal": false,
      },
    },
    [
      _c(
        "el-table",
        {
          staticClass: "custom-table",
          attrs: { data: _vm.tableData },
          on: { "row-dblclick": _vm.confirmSelect },
        },
        [
          _c("el-table-column", {
            attrs: { type: "index", label: "序号", width: "60" },
          }),
          _c("el-table-column", {
            attrs: { width: "200", label: "活动名称", prop: "name" },
          }),
          _c("el-table-column", {
            attrs: { width: "200", label: "说明" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function () {
                  return [_vm._v(" 双击直接添加项目 ")]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          { staticStyle: { display: "block", "text-align": "center" } },
          [
            _c(
              "el-button",
              { attrs: { size: "small" }, on: { click: _vm.handleClose } },
              [_vm._v(" 关 闭 ")]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }