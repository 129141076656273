var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { ref: "baseContainer", attrs: { paddingTop: "" } },
    [
      [
        _c(
          "div",
          { staticClass: "form-top" },
          [
            _c(
              "el-form",
              {
                attrs: {
                  model: _vm.form,
                  "label-width": "100px",
                  size: "small",
                },
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 5 } },
                      [
                        _c(
                          "el-form-item",
                          [
                            _c("template", { slot: "label" }, [
                              _c(
                                "div",
                                [
                                  _vm._v(" 操作门店 "),
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        effect: "light",
                                        content:
                                          "接车单的操作门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)",
                                        placement: "bottom-start",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "iconfont grow-icon_doubt",
                                        staticStyle: { color: "#9a9a9a" },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("operateCompanyCascader", {
                              model: {
                                value: _vm.form.operateCompanyId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "operateCompanyId", $$v)
                                },
                                expression: "form.operateCompanyId",
                              },
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 5 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "车牌号" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入", clearable: "" },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.getList.apply(null, arguments)
                                },
                              },
                              model: {
                                value: _vm.form.carNumber,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "carNumber", $$v)
                                },
                                expression: "form.carNumber",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 7 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "车单结账日期" } },
                          [
                            _c("el-date-picker", {
                              staticClass: "w100",
                              attrs: {
                                type: "daterange",
                                "unlink-panels": "",
                                "range-separator": "至",
                                "start-placeholder": "起始时间",
                                "end-placeholder": "截止时间",
                                "value-format": "yyyy-MM-dd",
                                clearable: false,
                              },
                              model: {
                                value: _vm.form.times,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "times", $$v)
                                },
                                expression: "form.times",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("el-col", { attrs: { span: 7 } }, [
                      _c(
                        "div",
                        {
                          staticClass: "el-form-item--small",
                          staticStyle: { "margin-left": "20%" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "el-form-item__label pointer",
                              on: {
                                click: function ($event) {
                                  _vm.showMore = !_vm.showMore
                                },
                              },
                            },
                            [
                              _vm._v(" 更多筛选 "),
                              _c("i", {
                                class: {
                                  primary: true,
                                  "el-icon-arrow-right": !_vm.showMore,
                                  "el-icon-arrow-down": _vm.showMore,
                                },
                              }),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "flex" },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "btn_search",
                                  attrs: { type: "primary", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getList()
                                    },
                                  },
                                },
                                [_vm._v("查询")]
                              ),
                              _c(
                                "el-button",
                                {
                                  staticClass: "btn_search",
                                  attrs: { size: "small" },
                                  on: { click: _vm.empty },
                                },
                                [_vm._v("置空")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 5 } },
                      [
                        _c(
                          "el-form-item",
                          [
                            _c("template", { slot: "label" }, [
                              _c(
                                "div",
                                [
                                  _vm._v(" 消费客户 "),
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        effect: "light",
                                        placement: "bottom-start",
                                      },
                                    },
                                    [
                                      _c("template", { slot: "content" }, [
                                        _c("p", [
                                          _vm._v("自费/包干客户：输入客户名称"),
                                        ]),
                                        _c("p", [
                                          _vm._v(
                                            "保险公司：输入公司名称、编码"
                                          ),
                                        ]),
                                        _c("p", [
                                          _vm._v(
                                            "三包厂家：输入厂家名称、编码"
                                          ),
                                        ]),
                                      ]),
                                      _c("i", {
                                        staticClass: "iconfont grow-icon_doubt",
                                        staticStyle: { color: "#9a9a9a" },
                                      }),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("el-input", {
                              attrs: { placeholder: "请输入", clearable: "" },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.getList.apply(null, arguments)
                                },
                              },
                              model: {
                                value: _vm.form.consumerNameOrPhoneOrNum,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "consumerNameOrPhoneOrNum",
                                    $$v
                                  )
                                },
                                expression: "form.consumerNameOrPhoneOrNum",
                              },
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm.showMore ? _c("el-divider") : _vm._e(),
        _c("transition", { attrs: { name: "fade" } }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showMore,
                  expression: "showMore",
                },
              ],
            },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    model: _vm.form,
                    "label-width": "100px",
                    size: "small",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 7 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "接车进店日期" } },
                            [
                              _c("el-date-picker", {
                                staticClass: "w100",
                                attrs: {
                                  type: "daterange",
                                  "unlink-panels": "",
                                  "range-separator": "至",
                                  "start-placeholder": "起始时间",
                                  "end-placeholder": "截止时间",
                                  "value-format": "yyyy-MM-dd",
                                  clearable: false,
                                },
                                model: {
                                  value: _vm.form.carTimes,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "carTimes", $$v)
                                  },
                                  expression: "form.carTimes",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c("template", { slot: "label" }, [
                                _c(
                                  "div",
                                  { staticClass: "flex flex-x-center" },
                                  [
                                    _vm._v(" 开票主体 "),
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "light",
                                          placement: "bottom-start",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              color: "#666666",
                                              "font-size": "13px",
                                              "line-height": "20px",
                                              "text-indent": "15px",
                                            },
                                            attrs: { slot: "content" },
                                            slot: "content",
                                          },
                                          [
                                            _vm._v(
                                              " 开票主体，即为协议客户、保险公司和三包厂家的应收账款"
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              " 的回款管理门店(保险公司的开票主体也是定损主体)。保险公司"
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              "管理的开票主体，可查已成为保险公司开票 主体的全部门店。 "
                                            ),
                                          ]
                                        ),
                                        _c("i", {
                                          staticClass:
                                            "iconfont grow-icon_doubt",
                                          staticStyle: { color: "#9a9a9a" },
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    clearable: "",
                                    remote: "",
                                    placeholder: "搜索开票主体名称",
                                    "remote-method": _vm.searchInvoice,
                                    loading: _vm.searchinvoiceLoading,
                                  },
                                  on: {
                                    change: _vm.selectInvoice,
                                    clear: _vm.deleteInvoice,
                                  },
                                  model: {
                                    value: _vm.form.invoiceName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "invoiceName", $$v)
                                    },
                                    expression: "form.invoiceName",
                                  },
                                },
                                _vm._l(_vm.invoiceList, function (item) {
                                  return _c("el-option", {
                                    key: item.companyId,
                                    attrs: {
                                      label: item.invoicingEntity,
                                      value: item.companyId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "车型信息" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    remote: "",
                                    placeholder: "搜索车型",
                                    "remote-method": _vm.searchModel,
                                    loading: _vm.searchModelLoading,
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.carInfo,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "carInfo", $$v)
                                    },
                                    expression: "form.carInfo",
                                  },
                                },
                                _vm._l(_vm.models, function (item) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: _vm.$lodash.get(item, "series.id"),
                                      attrs: {
                                        value: _vm.$lodash.get(
                                          item,
                                          "series.name"
                                        ),
                                      },
                                    },
                                    [
                                      _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(item.markedRedName),
                                        },
                                      }),
                                    ]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 5 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "支付方式" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    placeholder: "选择支付方式",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.payType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "payType", $$v)
                                    },
                                    expression: "form.payType",
                                  },
                                },
                                _vm._l(_vm.payType, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 7 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "签约客户" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入客户名称",
                                  clearable: "",
                                },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.getList.apply(null, arguments)
                                  },
                                },
                                model: {
                                  value: _vm.form.carOwnerOrPhone,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "carOwnerOrPhone", $$v)
                                  },
                                  expression: "form.carOwnerOrPhone",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "接车单号" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入", clearable: "" },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.getList.apply(null, arguments)
                                  },
                                },
                                model: {
                                  value: _vm.form.billCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "billCode", $$v)
                                  },
                                  expression: "form.billCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "业务来源" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    clearable: "",
                                    remote: "",
                                    placeholder: "搜索业务来源",
                                    "remote-method": _vm.searchBillSource,
                                    loading: _vm.searchBillSourceLoading,
                                  },
                                  on: {
                                    change: _vm.selectBillSource,
                                    clear: _vm.deleteBillSource,
                                  },
                                  model: {
                                    value: _vm.form.sourceId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "sourceId", $$v)
                                    },
                                    expression: "form.sourceId",
                                  },
                                },
                                _vm._l(_vm.billSourceList, function (item) {
                                  return _c("el-option", {
                                    key: item.billSourceTypeId,
                                    attrs: {
                                      label: item.name,
                                      value: item.billSourceTypeId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 5 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "开票状态" },
                              model: {
                                value: _vm.form.hasInvoice,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "hasInvoice", $$v)
                                },
                                expression: "form.hasInvoice",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.hasInvoice,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "hasInvoice", $$v)
                                    },
                                    expression: "form.hasInvoice",
                                  },
                                },
                                _vm._l(_vm.hasInvoiceType, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 7 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "接车员" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    clearable: "",
                                    remote: "",
                                    placeholder: "搜索姓名、手机号",
                                    "remote-method": _vm.searchAdminJobListUsre,
                                    loading: _vm.searchAdminJobListUsrLoading,
                                  },
                                  on: { change: _vm.selectAdminJobListUsre },
                                  model: {
                                    value: _vm.form.receiver,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "receiver", $$v)
                                    },
                                    expression: "form.receiver",
                                  },
                                },
                                _vm._l(
                                  _vm.AdminJobListUsrList,
                                  function (item) {
                                    return _c(
                                      "el-option",
                                      {
                                        key: item.userId,
                                        attrs: {
                                          label: item.realName,
                                          value: item.userId,
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              float: "left",
                                              color: "#8492a6",
                                              "font-size": "13px",
                                            },
                                          },
                                          [_vm._v(_vm._s(item.realName))]
                                        ),
                                        _c(
                                          "span",
                                          { staticStyle: { float: "right" } },
                                          [_vm._v(_vm._s(item.phone))]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "业务员" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    size: "small",
                                    filterable: "",
                                    clearable: "",
                                    remote: "",
                                    placeholder: "搜索姓名、手机号",
                                    "remote-method": _vm.searchClient,
                                    loading: _vm.searchClientLoading,
                                  },
                                  model: {
                                    value: _vm.form.salesmanId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "salesmanId", $$v)
                                    },
                                    expression: "form.salesmanId",
                                  },
                                },
                                _vm._l(_vm.clientList, function (item) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: item.id,
                                      attrs: {
                                        label: item.clientName,
                                        value: item.id,
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { float: "left" } },
                                        [_vm._v(_vm._s(item.clientName))]
                                      ),
                                      _c(
                                        "span",
                                        { staticStyle: { float: "right" } },
                                        [_vm._v(_vm._s(item.clientPhone))]
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "施工技师" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    placeholder: "搜索姓名、电话",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.technician,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "technician", $$v)
                                    },
                                    expression: "form.technician",
                                  },
                                },
                                _vm._l(_vm.techniciansList, function (item) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: item.userId,
                                      attrs: {
                                        label: item.realName,
                                        value: item.userId,
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            float: "left",
                                            color: "#8492a6",
                                            "font-size": "13px",
                                          },
                                        },
                                        [_vm._v(_vm._s(item.realName))]
                                      ),
                                      _c(
                                        "span",
                                        { staticStyle: { float: "right" } },
                                        [_vm._v(_vm._s(item.phone))]
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 5 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "施工班组" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入" },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.getList.apply(null, arguments)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 7 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "结账收款备注" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入", clearable: "" },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.getList.apply(null, arguments)
                                  },
                                },
                                model: {
                                  value: _vm.form.checkRemark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "checkRemark", $$v)
                                  },
                                  expression: "form.checkRemark",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "事故责任" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    placeholder: "搜索事故责任",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.dutyType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "dutyType", $$v)
                                    },
                                    expression: "form.dutyType",
                                  },
                                },
                                _vm._l(_vm.dutyTypeList, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "车单标签" } },
                            [
                              _vm.checkedLabels.length == 0
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "chioce-sty",
                                      on: {
                                        click: function ($event) {
                                          return _vm.openLabelDialog()
                                        },
                                      },
                                    },
                                    [
                                      _c("span", { staticClass: "pointer" }, [
                                        _vm._v("点击选择"),
                                      ]),
                                    ]
                                  )
                                : _c(
                                    "el-popover",
                                    {
                                      staticStyle: { "text-align": "center" },
                                      attrs: {
                                        placement: "bottom",
                                        width: "200",
                                        trigger: "hover",
                                      },
                                    },
                                    [
                                      _vm._l(
                                        _vm.checkedLabels,
                                        function (item, index) {
                                          return _c("div", { key: index }, [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: { color: "#666" },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.name) +
                                                    "  /" +
                                                    _vm._s(item.billLabelId) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ])
                                        }
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "chioce-sty",
                                          staticStyle: { position: "relative" },
                                          attrs: { slot: "reference" },
                                          slot: "reference",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "pointer",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openLabelDialog()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "已选择(" +
                                                  _vm._s(
                                                    _vm.checkedLabels.length
                                                  ) +
                                                  ")"
                                              ),
                                            ]
                                          ),
                                          _c("i", {
                                            staticClass:
                                              "iconfont grow-a-icon_tabbar_close icon-sty",
                                            staticStyle: { color: "#42bbb8" },
                                            on: {
                                              click: function ($event) {
                                                _vm.checkedLabels = []
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    2
                                  ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ],
      [
        _c("div", { staticClass: "content-container" }, [
          _c("div", { staticClass: "btns flex-x-between" }, [
            _c("div", [
              _c("div", { staticClass: "custom-tabs nopadding" }, [
                _c(
                  "div",
                  {
                    class: {
                      "custom-tab-item": true,
                      active: _vm.status == -1,
                    },
                    on: {
                      click: function ($event) {
                        _vm.status = -1
                      },
                    },
                  },
                  [_vm._v(" 全部 ")]
                ),
                _c(
                  "div",
                  {
                    class: {
                      "custom-tab-item": true,
                      active: _vm.status == 0,
                    },
                    on: {
                      click: function ($event) {
                        _vm.status = 0
                      },
                    },
                  },
                  [_vm._v(" 自费业务 ")]
                ),
                _c(
                  "div",
                  {
                    class: {
                      "custom-tab-item": true,
                      active: _vm.status == 1,
                    },
                    on: {
                      click: function ($event) {
                        _vm.status = 1
                      },
                    },
                  },
                  [_vm._v(" 包干业务 ")]
                ),
                _c(
                  "div",
                  {
                    class: {
                      "custom-tab-item": true,
                      active: _vm.status == 2,
                    },
                    on: {
                      click: function ($event) {
                        _vm.status = 2
                      },
                    },
                  },
                  [_vm._v(" 理赔业务 ")]
                ),
                _c(
                  "div",
                  {
                    class: {
                      "custom-tab-item": true,
                      active: _vm.status == 3,
                    },
                    on: {
                      click: function ($event) {
                        _vm.status = 3
                      },
                    },
                  },
                  [_vm._v(" 索赔业务 ")]
                ),
              ]),
            ]),
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "auth",
                        rawName: "v-auth",
                        value: 160,
                        expression: "160",
                      },
                    ],
                    staticClass: "blue",
                    attrs: { size: "small", type: "text" },
                    on: {
                      click: function ($event) {
                        _vm.showExportDialog = true
                      },
                    },
                  },
                  [
                    _vm._v("导出列表"),
                    _c("i", {
                      staticClass: "iconfont grow-icon_daochu el-icon--right",
                    }),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "content-box" },
            [
              _c(
                "el-table",
                {
                  staticClass: "data-table",
                  attrs: {
                    data: _vm.carBillList,
                    border: "",
                    "show-summary": "",
                    "summary-method": _vm.getSummaries,
                    "header-cell-style": _vm.headerCellStyle,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "index", label: "序号", width: "80" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      width: "180",
                      label: "操作门店",
                      prop: "operateCompany",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { "min-width": "200", label: "接车单号" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "blue pointer",
                                on: {
                                  click: function ($event) {
                                    return _vm.goDetail(row.id)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(row.billCode) + " ")]
                            ),
                            _c("div", [
                              row.billType == 0
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("dict")(
                                            row.isOthersSelfPayState,
                                            "isOthersSelfPayState"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("dict")(
                                            row.billType,
                                            "carBillType"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      width: "100",
                      label: "结账时间",
                      prop: "checkTime",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      width: "100",
                      label: "进店时间",
                      prop: "intoTime",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "160",
                      label: "车牌号",
                      prop: "plateNum",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { "min-width": "250", label: "车型/车架号" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("div", [_vm._v("车型:" + _vm._s(row.carModel))]),
                            _c("div", { staticClass: "color999" }, [
                              _vm._v("VIN:" + _vm._s(row.carVIN)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { "min-width": "180", label: "签约客户" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            row.carOwner
                              ? _c("div", [
                                  _c("div", [
                                    _vm._v(
                                      "客户名:" + _vm._s(row.carOwner.ownerName)
                                    ),
                                  ]),
                                  _c("div", { staticClass: "color999" }, [
                                    _vm._v(_vm._s(row.carOwner.ownerPhone)),
                                  ]),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { width: "180", label: "消费客户" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            row.consumer
                              ? _c("div", [
                                  _c("div", [
                                    _vm._v(
                                      "客户名:" +
                                        _vm._s(row.consumer.consumerName)
                                    ),
                                  ]),
                                  _c("div", { staticClass: "color999" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(row.consumer.consumerPhone) +
                                        " "
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { "min-width": "180", label: "开票主体" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  row.invoiceName
                                    .map((i) => {
                                      return i
                                    })
                                    .join()
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      width: "140",
                      label: "业务来源",
                      prop: "businessSource",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { width: "140", label: "事故责任" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("dict")(row.dutyType, "dutyType")
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { width: "100", label: "接车员", prop: "receiver" },
                  }),
                  _c("el-table-column", {
                    attrs: { width: "100", label: "业务员", prop: "salesman" },
                  }),
                  _c("el-table-column", {
                    attrs: { width: "100", label: "施工技师" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            row.constructionTechnician != null
                              ? _c("span", { staticClass: "pointer" }, [
                                  _vm._v(
                                    _vm._s(
                                      row.constructionTechnician
                                        .map((i) => {
                                          return i
                                        })
                                        .join()
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c(
                    "el-table-column",
                    {
                      staticStyle: { "text-align": "center" },
                      attrs: { label: "报价信息" },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          width: "180",
                          label: "服务",
                          prop: "quotedPriceInfo.serviceTotal",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          width: "180",
                          label: "商品",
                          prop: "quotedPriceInfo.goodTotal",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          width: "180",
                          label: "其他",
                          prop: "quotedPriceInfo.otherTotal",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("el-table-column", {
                    attrs: {
                      width: "100",
                      label: "报价合计",
                      prop: "billTotal",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      width: "180",
                      label: "整单折扣优惠",
                      prop: "discount",
                    },
                  }),
                  _c(
                    "el-table-column",
                    { attrs: { width: "100", prop: "billTotal" } },
                    [
                      _c("template", { slot: "header" }, [
                        _c(
                          "div",
                          { staticClass: "flex flex-x-center" },
                          [
                            _vm._v(" 单据金额 "),
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  effect: "light",
                                  placement: "bottom-start",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "#666666",
                                      "font-size": "13px",
                                      "line-height": "20px",
                                      "text-indent": "15px",
                                    },
                                    attrs: { slot: "content" },
                                    slot: "content",
                                  },
                                  [_vm._v(" 单据金额=报价金额-整单折扣优惠 ")]
                                ),
                                _c("i", {
                                  staticClass: "iconfont grow-icon_doubt",
                                  staticStyle: {
                                    color: "#9a9a9a",
                                    "margin-left": "5px",
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "el-table-column",
                    { attrs: { width: "100", prop: "contractTotal" } },
                    [
                      _c("template", { slot: "header" }, [
                        _c(
                          "div",
                          { staticClass: "flex flex-x-center" },
                          [
                            _vm._v(" 包干金额 "),
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  effect: "light",
                                  placement: "bottom-start",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "#666666",
                                      "font-size": "13px",
                                      "line-height": "20px",
                                      "text-indent": "15px",
                                    },
                                    attrs: { slot: "content" },
                                    slot: "content",
                                  },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        " 包干业务才有包干金额，结账当月显示为查询时点的预分摊金额， "
                                      ),
                                    ]),
                                    _c("p", [
                                      _vm._v("结账次月显示为实际分摊金额"),
                                    ]),
                                  ]
                                ),
                                _c("i", {
                                  staticClass: "iconfont grow-icon_doubt",
                                  staticStyle: {
                                    color: "#9a9a9a",
                                    "margin-left": "5px",
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                  _c("el-table-column", {
                    attrs: {
                      width: "100",
                      label: "优惠券",
                      prop: "discountCoupon",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { width: "100", label: "免账", prop: "freeAccount" },
                  }),
                  _c(
                    "el-table-column",
                    { attrs: { width: "100", prop: "moneyReceived" } },
                    [
                      _c("template", { slot: "header" }, [
                        _c(
                          "div",
                          { staticClass: "flex flex-x-center" },
                          [
                            _vm._v(" 实收金额 "),
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  effect: "light",
                                  placement: "bottom-start",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "#666666",
                                      "font-size": "13px",
                                      "line-height": "20px",
                                      "text-indent": "15px",
                                    },
                                    attrs: { slot: "content" },
                                    slot: "content",
                                  },
                                  [
                                    _c("p", [
                                      _vm._v("实收金额=单据金额-优惠券-免账；"),
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        "包干业务的实收金额=包干金额-优惠券-免账"
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("i", {
                                  staticClass: "iconfont grow-icon_doubt",
                                  staticStyle: {
                                    color: "#9a9a9a",
                                    "margin-left": "5px",
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "el-table-column",
                    {
                      staticStyle: { "text-align": "center" },
                      attrs: { label: "实收明细" },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          width: "180",
                          label: "定金抵扣",
                          prop: "receiverDetail.depositDeduction",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          width: "180",
                          label: "分红抵扣",
                          prop: "receiverDetail.bonusDeduction",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          width: "180",
                          label: "股本抵扣",
                          prop: "receiverDetail.capitalDeduction",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          width: "180",
                          label: "现金",
                          prop: "receiverDetail.cash",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          width: "180",
                          label: "会员卡",
                          prop: "receiverDetail.memberCard",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          width: "180",
                          label: "挂账",
                          prop: "receiverDetail.hang",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          width: "180",
                          label: "微信",
                          prop: "receiverDetail.wechat",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          width: "180",
                          label: "本时段发生过的其他支付方式",
                          prop: "receiverDetail.total",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "120",
                      label: "材料成本合计",
                      prop: "materialCost",
                    },
                  }),
                  _c(
                    "el-table-column",
                    { attrs: { width: "100", prop: "marginTotal" } },
                    [
                      _c("template", { slot: "header" }, [
                        _c(
                          "div",
                          { staticClass: "flex flex-x-center" },
                          [
                            _vm._v(" 整单毛利 "),
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  effect: "light",
                                  placement: "bottom-start",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "#666666",
                                      "font-size": "13px",
                                      "line-height": "20px",
                                      "text-indent": "15px",
                                    },
                                    attrs: { slot: "content" },
                                    slot: "content",
                                  },
                                  [
                                    _c("p", [
                                      _vm._v("整单毛利=实收金额-材料成本合计"),
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        "整单毛利不含卡内项目的金额(已在开卡或充值时收取)"
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("i", {
                                  staticClass: "iconfont grow-icon_doubt",
                                  staticStyle: {
                                    color: "#9a9a9a",
                                    "margin-left": "5px",
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                  _c("el-table-column", {
                    attrs: { width: "150", label: "结算人", prop: "cashier" },
                  }),
                  _c("el-table-column", {
                    attrs: { width: "100", label: "开票状态" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            row.invoiceNum
                              ? _c("p", [
                                  _c("span", { staticClass: "blue" }, [
                                    _vm._v(_vm._s(row.invoiceNum)),
                                  ]),
                                  _c("br"),
                                  _c("span", [_vm._v("已开票")]),
                                ])
                              : _c("p", [_vm._v("未开票")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      width: "180",
                      label: "(结账)收账备注",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c(
                              "span",
                              { staticClass: "pointer text_operation" },
                              [_vm._v(" " + _vm._s(row.checkRemark))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c("export-dialog", {
          attrs: {
            visibility: _vm.showExportDialog,
            total: _vm.total,
            pageOption: _vm.pageOption,
            filterForm: _vm.form,
            url: _vm.exportUrl,
          },
          on: {
            "update:visibility": function ($event) {
              _vm.showExportDialog = $event
            },
          },
        }),
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c("pagination", {
            attrs: { total: _vm.total },
            on: { pageChange: _vm.changePage },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.labelDialogVisible, width: "40%" },
          on: {
            "update:visible": function ($event) {
              _vm.labelDialogVisible = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c("div", { staticClass: "bill-dialog" }, [
              _vm._v(" 选择项目标签"),
              _c("span", [_vm._v("（可双击直接单选，或单击勾选再确定选择）")]),
            ]),
          ]),
          _c("div", { staticClass: "billLabel" }, [
            _c(
              "div",
              { staticClass: "artisan-list" },
              _vm._l(_vm.labelList, function (label) {
                return _c(
                  "div",
                  {
                    key: label.id,
                    class: { label: true, active: label.checked },
                    on: {
                      click: function ($event) {
                        return _vm.handleArtisanChecked(label)
                      },
                      dblclick: function ($event) {
                        return _vm.confirmLabel(label)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(label.name) + " ")]
                )
              }),
              0
            ),
          ]),
          _c("div", { staticClass: "chekced" }, [
            _c("span", [_vm._v("已选择：")]),
            _c("span", { staticClass: "primary" }, [
              _vm._v(
                _vm._s(_vm.checkedLabels.map((item) => item.name).join("、"))
              ),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.labelDialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-right": "60px" },
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.confirmLabel()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }