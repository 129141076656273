<template>
  <el-dialog
    title="导出提示"
    :width="setWid"
    :visible="visibility"
    center
    :before-close="handleClose"
  >
    <div class="export-dialog__body">
      <div class="export-dialog-page__input">
        <span>导出范围：第</span>
        <el-input-number
          size="small"
          v-model="startPage"
          controls-position="right"
          :min="1"
          :max="totalPageNumber"
          @blur="changeExportNum('start')"
          @change="changeExportNum('start')"
        ></el-input-number>
        <span>至</span>
        <el-input-number
          size="small"
          v-model="endPage"
          controls-position="right"
          :min="1"
          :max="maxEndPage"
          @blur="changeExportNum"
          @change="changeExportNum"
        ></el-input-number>
      </div>
      <p class="export-dialog__tip">
        共{{ Math.ceil(total / pageOption.size) }}页，一次最多导出{{
          maxExportNum
        }}条数据
      </p>
    </div>
    <div slot="footer">
      <el-button size="small" @click="handleClose">取 消</el-button>
      <el-button size="small" type="primary" @click="doExport">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import Big from "big.js";
import { downloadFile } from "@/utils/fileApi";
export default {
  props: {
    visibility: Boolean,
    total: {
      type: [Number, String],
      default: 0,
    },
    pageOption: {
      type: Object,
      default: () => ({
        size: 10,
        current: 1,
      }),
    },
    filterForm: {
      type: Object,
      default: () => ({}),
    },
    url: {
      type: String,
      default: "",
    },
    /**
     * 导出的记录数大小，默认是10000
     */
    maxExportNum: {
      type: Number,
      default: 1e4,
    },
    setWid: {
      type: String,
      default: "50%",
    },
  },
  data() {
    return {
      startPage: 0,
      endPage: 0,
    };
  },
  watch: {
    visibility() {
      const _maxExportNum = this.maxExportNum;
      this.endPage = Math.ceil(
        (this.total > _maxExportNum ? _maxExportNum : this.total) /
          this.pageOption.size
      );
      this.startPage = 1;
    },
  },
  computed: {
    maxPage() {
      const _maxExportNum = this.maxExportNum;
      return Math.ceil(
        (this.total > _maxExportNum ? _maxExportNum : this.total) /
          this.pageOption.size
      );
    },
    maxEndPage() {
      return Math.min(this.maxPage + this.startPage - 1, this.totalPageNumber);
    },
    totalPageNumber() {
      return Math.ceil(this.total / this.pageOption.size);
    },
  },
  created() {},
  methods: {
    /**
     * 修改导出页码
     */
    changeExportNum(type) {
      if (type === "start") {
        if (this.startPage > this.endPage) {
          this.endPage = this.maxEndPage;
        }
        if (!this.startPage) {
          this.$nextTick().then(() => {
            this.startPage = 1;
          });
        }
      } else {
        if (this.endPage < this.startPage || !this.endPage) {
          this.$nextTick().then(() => {
            this.endPage = this.startPage;
          });
          this.$message.warning("结束页码不能小于开始页码");
        }
      }
      if (this.endPage > this.maxEndPage) {
        this.$message.warning(`最多只能导出${this.maxPage}页`);
        this.$nextTick().then(() => {
          this.endPage = this.maxEndPage;
        });
      }
    },
    /**
     * 导出
     */
    doExport() {
      let exportFormData = Object.assign({}, this.filterForm, {
        exportStartNum: Big(this.startPage - 1).times(this.pageOption.size),
        exportEndNum: Big(this.endPage).times(this.pageOption.size),
      });
      delete exportFormData.current;
      delete exportFormData.size;
      downloadFile({
        url: this.url,
        data: exportFormData,
        config: {
          timeout: 6e5,
        },
      });
      this.handleClose();
    },
    /**
     * 关闭/取消
     */
    handleClose() {
      this.$emit("update:visibility", false);
    },
  },
};
</script>
<style lang="scss" scoped>
.el-input-number {
  margin: 0 15px;
}
.export-dialog__body {
  margin: 20px 0;
  text-align: center;
  .export-dialog-page__input {
    display: flex;
    justify-content: center;
    align-items: center;
    .el-input {
      width: 140px;
      margin: 0 15px;
    }
  }
  .export-dialog__tip {
    margin-top: 20px;
  }
}
</style>
