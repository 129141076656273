<template>
  <el-dialog
    :title="title"
    :visible="visibility"
    width="90%"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <el-form
      ref="form"
      size="small"
      :model="queryForm"
      :rules="rules"
      label-width="90px"
      style="margin-top: 18px"
    >
      <el-row :gutter="10">
        <el-col :span="8" :offset="0">
          <el-form-item label="保险公司" prop="insuranceCompanyId">
            <el-select
              v-model="queryForm.insuranceCompanyId"
              clearable
              filterable
              placeholder="请选择保险公司"
            >
              <el-option
                v-for="item in insuranceCompanyList"
                :key="item.id"
                :label="item.insuranceName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8" :offset="0">
          <el-form-item label="回款门店" prop="receivingCompanyId">
            <el-select
              v-model="queryForm.receivingCompanyId"
              filterable
              clearable
              placeholder="请输入门店名称搜索选择"
            >
              <el-option
                v-for="item in companyList"
                :key="item.companyId"
                :label="item.companyName"
                :value="item.companyId"
              >
                <span style="float: left; color: #8492a6; font-size: 13px">{{
                  item.companyName
                }}</span>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8" :offset="0">
          <el-form-item label="回款主体" prop="invoicingEntity">
            <el-select
              v-model="queryForm.invoicingEntity"
              clearable
              filterable
              placeholder="请选择回款主体"
            >
              <el-option
                v-for="item in invoicingEntities"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="6" :offset="0">
          <el-form-item label="回款时间" prop="receivingDateTimes">
            <el-date-picker
              class="w100"
              v-model="queryForm.receivingDateTimes"
              type="daterange"
              unlink-panels
              range-separator="至"
              start-placeholder="起始时间"
              end-placeholder="截止时间"
              value-format="yyyy-MM-dd"
              @input="dateRangeChange()"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6" :offset="0">
          <el-form-item label="金额" prop="amount">
            <number-input
              v-model="queryForm.amount"
              decimalDigits="2"
              :max="999999.99"
              placeholder="请输入金额"
              clearable
              @blur="doVerify('amount')"
            >
            </number-input>
          </el-form-item>
        </el-col>
        <el-col :span="6" :offset="0">
          <el-form-item label="银行摘要" prop="bankSummary">
            <el-input
              v-model="queryForm.bankSummary"
              placeholder="请输入银行摘要"
              clearable
              @input="dateRangeChange()"
              @keyup.enter.native="getListData"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6" :offset="0">
          <el-form-item>
            <el-button size="small" type="primary" @click="getListData">
              查 询
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-table class="custom-table" ref="queryDataTable" :data="listData" border>
      <el-table-column width="80" label="操作" prop="status">
        <template #default="{ row }">
          <el-checkbox
            :value="isRowCheckboxSelected(row)"
            @change="(val, event) => selectRowItem(val, event, row)"
          ></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column width="200" label="回款主体" prop="invoicingEntity">
      </el-table-column>
      <el-table-column width="200" label="保险公司" prop="insuranceCompanyName">
      </el-table-column>
      <el-table-column width="162" label="回款时间" prop="receivingDatetime">
      </el-table-column>
      <el-table-column
        min-width="300"
        label="银行摘要"
        prop="bankSummary"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column width="140" label="金额" prop="amount">
      </el-table-column>
      <el-table-column width="140" label="已认领金额" prop="claimAmount">
      </el-table-column>
      <el-table-column width="140" label="可认领金额" prop="unClaimAmount">
      </el-table-column>
      <el-table-column width="200" label="回款门店" prop="receivingCompanyName">
      </el-table-column>
      <el-table-column
        width="200"
        label="系统流水编号"
        prop="sysStatementSeqCode"
      >
      </el-table-column>
      <el-table-column width="200" label="银行账号" prop="bankAccount">
      </el-table-column>
      <el-table-column width="200" label="开户行" prop="depositBank">
      </el-table-column>
      <el-table-column width="200" label="付款账号" prop="paymentAccount">
      </el-table-column>
      <el-table-column width="200" label="资金流水号" prop="transactionSeq">
      </el-table-column>
    </el-table>
    <pagination @pageChange="changePage" :total="total"></pagination>
    <p style="margin: 20px 0px; font-size: 20px">已选</p>
    <el-table
      class="custom-table"
      :data="selectedTransationData"
      empty-text=" "
      border
    >
      <el-table-column width="80" label="操作" prop="status">
        <template #default="{ row }">
          <el-button
            type="danger"
            icon="el-icon-delete"
            title="移除认领银行流水"
            circle
            @click="removeRowItem(row)"
          ></el-button>
        </template>
      </el-table-column>
      <el-table-column width="200" label="回款主体" prop="invoicingEntity">
      </el-table-column>
      <el-table-column width="200" label="保险公司" prop="insuranceCompanyName">
      </el-table-column>
      <el-table-column width="162" label="回款时间" prop="receivingDatetime">
      </el-table-column>
      <el-table-column min-width="140" label="银行摘要" prop="bankSummary">
        <template #default="{ row }">
          <el-tooltip :content="row.bankSummary">
            <div class="text-ellipsis-2-row">
              {{ row.bankSummary }}
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column width="140" label="金额" prop="amount">
      </el-table-column>
      <el-table-column width="140" label="已认领金额" prop="claimAmount">
      </el-table-column>
      <el-table-column width="140" label="可认领金额" prop="unClaimAmount">
      </el-table-column>
      <el-table-column width="220" label="认领金额" prop="customeClaimAmount">
        <template #default="{ row }">
          <el-input-number
            v-model="row.customeClaimAmount"
            :min="0"
            :precision="2"
            :disabled="row.writeOffStatus && row.writeOffStatus === 1"
            @change="(cv, ov) => handleBillClaimDetailAmountChange(cv, ov, row)"
          />
        </template>
      </el-table-column>
      <el-table-column width="220" label="退款金额" prop="customeRefundAmount">
        <template #default="{ row }">
          <el-input-number
            v-model="row.customeRefundAmount"
            :min="0"
            :precision="2"
            :disabled="row.writeOffStatus && row.writeOffStatus === 1"
            @change="
              (cv, ov) => handleBillCustomeRefundAmountChange(cv, ov, row)
            "
          />
        </template>
      </el-table-column>
      <el-table-column width="220" label="业务冲抵费" prop="customeOffsetFee">
        <template #default="{ row }">
          <el-input-number
            v-model="row.customeOffsetFee"
            :min="0"
            :precision="2"
            :disabled="row.writeOffStatus && row.writeOffStatus === 1"
            @change="(cv, ov) => handleBillCustomeOffsetFeeChange(cv, ov, row)"
          />
        </template>
      </el-table-column>
      <el-table-column width="200" label="回款门店" prop="receivingCompanyName">
      </el-table-column>
      <el-table-column
        width="200"
        label="系统流水编号"
        prop="sysStatementSeqCode"
      >
      </el-table-column>
      <el-table-column width="200" label="银行账号" prop="bankAccount">
      </el-table-column>
      <el-table-column width="200" label="开户行" prop="depositBank">
      </el-table-column>
      <el-table-column width="200" label="付款账号" prop="paymentAccount">
      </el-table-column>
      <el-table-column width="200" label="资金流水号" prop="transactionSeq">
      </el-table-column>
    </el-table>
    <p style="margin: 20px 0px; font-size: 20px">备注</p>
    <el-input
      type="textarea"
      v-model="editForm.remark"
      maxlength="200"
      show-word-limit
      placeholder="请输入认领资金流水备注"
    >
    </el-input>
    <template slot="footer">
      <span style="margin: 20px 0px; font-size: 15px; margin-right: 100px">
        <span> 车单金额：{{ totalAmount | toThousands }} </span>
        <span style="margin-left: 20px">
          已认领：{{ claimAmount | toThousands }}
        </span>
      </span>
      <el-button size="small" type="primary" @click="save"> 确 定 </el-button>
      <el-button size="small" @click="handleClose"> 取 消 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex';

// 组件
import pagination from '@/components/Pagination.vue';

// 接口
import {
  transactionClaimDetail,
  saveOrUpdateListTransaction,
  transactionClaimSaveOrUpdate,
} from '@/api/financial/financialOver';
import { hasAuth } from '@/utils/permissions';

export default {
  name: 'ClaimBankTransactionDialog',
  components: {
    pagination,
  },
  props: {
    visibility: Boolean,
    billId: [Number, String],
    id: [Number, String],
    rowEntityData: [Object],
    companyList: {
      type: Object,
      default: () => {},
    },
    insuranceCompanyList: {
      type: Object,
      default: () => {},
    },
    invoicingEntities: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // 表单信息
      queryForm: {},
      editForm: {
        remark: '',
      },
      // 表单规则
      rules: {},
      selectedTransationData: [],
      claimRecordDetails: [],
      listData: [],
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      totalAmount: 0,
      claimAmount: 0,
      unClaimAmount: 0,
      detailData: {},
      showUnClaimAmountTypeDialog: false,
      unClaimAmountType: undefined,
    };
  },
  computed: {
    title() {
      return this.id ? '编辑回款银行流水认领' : '新增回款银行流水认领';
    },
  },
  created() {
    this.queryForm.bankSummary = this.rowEntityData?.carNumber || '';
    if (this.billId) {
      this.getData();
    }
    this.getListData(false);
  },
  methods: {
    /**
     * 校验某个字段
     */
    doVerify(prop) {
      this.$refs.form.validateField(prop);
    },
    /**
     * 判断复选框是否选择
     * @param row 行
     */
    isRowCheckboxSelected(row) {
      for (let index = 0; index < this.selectedTransationData.length; index++) {
        const element = this.selectedTransationData[index];
        if (element.id === row.id) {
          return true;
        }
      }
      return false;
    },
    /**
     * 根据选中或者未选中来移除流水
     */
    selectRowItem(val, event, row) {
      if (val) {
        // 如果已经选中过了，就不处理
        for (
          let index = 0;
          index < this.selectedTransationData.length;
          index++
        ) {
          const element = this.selectedTransationData[index];
          if (element.id === row.id) {
            return;
          }
        }
        const cloneRow = this.$lodash.cloneDeep(row);
        // 添加认领明细还能认领的金额
        if (cloneRow.customeClaimAmount !== undefined) {
          if (cloneRow.billClaimDetailAmount !== undefined) {
            cloneRow.customeClaimAmount =
              cloneRow.unClaimAmount + cloneRow.billClaimDetailAmount;
          } else {
            cloneRow.customeClaimAmount = cloneRow.unClaimAmount;
          }
        }
        // 默认是等于未认领金额
        if (cloneRow.customeClaimAmount === undefined) {
          cloneRow.customeClaimAmount = cloneRow.unClaimAmount || 0;
        }
        this.selectedTransationData.push(cloneRow);
        this.calcClaimAmount();
        row.check = true;
      } else {
        for (
          let index = 0;
          index < this.selectedTransationData.length;
          index++
        ) {
          const element = this.selectedTransationData[index];
          if (element.id === row.id) {
            this.selectedTransationData.splice(index, 1);
            this.calcClaimAmount();
            row.check = false;
            break;
          }
        }
      }
    },
    /**
     * 删除选择的流水
     * @param row 已经选择的行
     */
    removeRowItem(row) {
      const _this = this;
      // 判断是否有核销标志，如果有，则不允许删除
      if (row.writeOffStatus === 1) {
        this.$message.warning(
          '不能删除：已经核销了，如需要删除，请先进行反核销'
        );
        return;
      }
      for (let index = 0; index < this.selectedTransationData.length; index++) {
        const element = this.selectedTransationData[index];
        if (element.id === row.id) {
          this.selectedTransationData.splice(index, 1);
          this.calcClaimAmount2();
          break;
        }
      }
    },
    /**
     * 变更认领流水数据
     * @param cv 当前输入的认领金额
     * @param ov 旧的认领金额
     * @param row 认领流水行
     */
    handleBillClaimDetailAmountChange(cv, ov, row) {
      row.customeClaimAmount = cv;
      this.calcClaimAmount();
    },
    /**
     * 变更认领流水数据
     * @param cv 当前输入的认领金额
     * @param ov 旧的认领金额
     * @param row 认领流水行
     */
    handleBillCustomeRefundAmountChange(cv, ov, row) {
      row.customeRefundAmount = cv;
      this.calcClaimAmount();
    },
    /**
     * 变更认领流水业务冲抵费
     * @param cv 当前输入的认领金额
     * @param ov 旧的认领金额
     * @param row 认领流水行
     */
    handleBillCustomeOffsetFeeChange(cv, ov, row) {
      row.customeOffsetFee = cv;
      this.calcClaimAmount();
    },
    /**
     * 重新计算 已领和认领金额
     */
    calcClaimAmount() {
      let tempAmount = 0;
      for (let index = 0; index < this.selectedTransationData.length; index++) {
        const element = this.selectedTransationData[index];
        tempAmount += element.customeClaimAmount;
      }
      this.claimAmount = tempAmount;
    },
    /**
     * 已选移除后重新计算认领金额和未认领金额
     */
    calcClaimAmount2() {
      this.calcClaimAmount();
    },
    /**
     * 重新计算 已领和认领金额
     */
    calcClaimAmount1() {
      let tempAmount = 0;
      for (let index = 0; index < this.claimRecordDetails.length; index++) {
        const element = this.claimRecordDetails[index];
        tempAmount += element.claimAmount;
      }
      this.claimAmount = tempAmount;
    },
    //下面是解决设置日期默认值时重新选择无法刷新日期的方法
    dateRangeChange() {
      let _this = this;
      _this.$nextTick(() => {
        _this.$forceUpdate();
      });
    },
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getListData(false);
    },
    /**
     * 查询银行流水数据，以便能够认领
     * @param reset 是否重置查询条件
     */
    getListData(reset) {
      var res = hasAuth(656);
      if (!res) {
        return;
      }
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      var times = {
        receivingBeginDateTime: this.queryForm.receivingDateTimes?.[0]
          ? this.queryForm.receivingDateTimes?.[0] + ' 00:00:00'
          : '',
        receivingEndDateTime: this.queryForm.receivingDateTimes?.[1]
          ? this.queryForm.receivingDateTimes?.[1] + ' 23:59:59'
          : '',
        billId: this.billId,
        claimId: this.id || 0,
      };
      var data = Object.assign({}, this.queryForm, this.pageOption, times);
      delete data.receivingDateTimes;
      saveOrUpdateListTransaction(data).then((res) => {
        // 修改总条数
        this.total = res.total;
        // 赋值records
        this.listData = res.records;
      });
    },
    /**
     * 获取数据
     */
    getData() {
      transactionClaimDetail({ id: this.billId }).then((res) => {
        let { carBill, transactionBillList, detail, claimRecordDetails } =
          res || {};
        this.selectedTransationData = transactionBillList;
        this.selectedTransationData.forEach((c) => {
          c.customeClaimAmount = c.billClaimDetailAmount;
          c.customeRefundAmount = c.refundAmount;
          c.customeOffsetFee = c.offsetFee;
        });
        this.detailData = detail || {};
        this.editForm.remark = detail?.claimRemark;
        // 已结算的，未结算的
        if (carBill.state === 1) {
          this.totalAmount = carBill.unclearedDebtFixed;
        } else {
          this.totalAmount = carBill.total;
        }
        this.claimRecordDetails = claimRecordDetails || [];
        this.calcClaimAmount1();
      });
    },
    /**
     * 保存
     */
    save() {
      const transactionClaimList = [];
      let allRefundAmount = 0;
      for (let index = 0; index < this.selectedTransationData.length; index++) {
        const element = this.selectedTransationData[index];
        transactionClaimList.push({
          transactionId: element.id,
          claimAmount: element.customeClaimAmount,
          refundAmount: element.customeRefundAmount,
          offsetFee: element.customeOffsetFee,
        });
        if (element.customeRefundAmount) {
          allRefundAmount = allRefundAmount + element.customeRefundAmount;
        }
      }
      /*
      if (transactionClaimList.length === 0) {
        this.$message.warning('请选择需要认领的银行流水！');
        return;
      }
      */
      // 如果有退款金额，则备注必填
      if (allRefundAmount > 0 && this.editForm.remark === '') {
        this.$message.warning(
          '检测到有退款金额，请在回款认领流水备注中填写退款说明'
        );
        return;
      }
      var body = {
        id: this.id,
        billId: this.billId,
        claimRemark: this.editForm.remark,
        transactionClaimList: transactionClaimList,
      };
      transactionClaimSaveOrUpdate(body).then((res) => {
        this.$message.success(res || '保存成功！');
        this.$emit('save');
        this.handleClose();
      });
    },

    /**
     * 关闭抽屉
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item {
  .el-date-editor,
  .el-select {
    width: 100%;
  }
}
.selected-car-model-list {
  margin: 6px 0 12px 0;
  .el-tag {
    margin-top: 6px;
    margin-left: 6px;
  }
}
</style>
