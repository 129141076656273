import http from '@/service/http';

// 检索门店列表
export function searchStore(
  keyWord = '',
  currCompanyId = '',
  size = 10,
  current = 1,
  config = { noShowWaiting: true }
) {
  return http.post(
    '/admin/company/fuzzy',
    {
      companyIdOrCompanyName: keyWord,
      currCompanyId: currCompanyId,
      size,
      current,
    },
    config
  );
}

// 检索门店列表 带管辖组权限
export function searchStoreWithJurisdiction(keyWord = '') {
  return http.post(
    '/admin/user/company/getCurrentUserManageCompanies',
    { companyName: keyWord, size: 100 },
    { noShowWaiting: true }
  );
}

// 操作门店 树
export function getCompanyTree() {
  return http.post(
    '/admin/user/company/companyGroup',
    {},
    { noShowWaiting: true }
  );
}
