<template>
  <el-dialog
    :title="`查看视频(${targetIndex + 1}/${videos.length})`"
    width="60%"
    :visible="visibility"
    center
    :before-close="handleClose"
    :modal="modal"
  >
    <div class="video-player-box">
      <videoPlayer
        class="video-player"
        :url="url"
        :options="options"
      ></videoPlayer>
      <i class="el-icon-arrow-left" @click="prev"></i>
      <i class="el-icon-arrow-right" @click="next"></i>
    </div>
  </el-dialog>
</template>
<script>
import videoPlayer from '@/components/videoPlayer';
export default {
  name: 'videoPlayerDialog',
  components: {
    videoPlayer,
  },
  props: {
    visibility: Boolean,
    videos: {
      type: Array,
      default: () => [],
    },
    index: {
      type: Number,
      default: 0,
    },
    autoPlay: {
      type: Boolean,
      default: true,
    },
    modal: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      targetIndex: 0,
      options: {
        autoplay: true,
      },
    };
  },
  mounted() {
    this.targetIndex = this.index;
  },
  computed: {
    url() {
      return this.videos[this.targetIndex];
    },
  },
  methods: {
    prev() {
      if (this.targetIndex > 0) this.targetIndex--;
    },
    next() {
      if (this.targetIndex < this.videos.length - 1) this.targetIndex++;
    },
    handleClose() {
      this.$emit('update:visibility', false);
    },
  },
};
</script>
<style lang="scss" scoped>
.video-player-box {
  position: relative;
  > i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 32px;
    color: #ddd;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.2);
    padding: 4px;
    border-radius: 4px;
    &:first-child {
      left: 0;
    }
    &:last-child {
      right: 0;
    }
    &:hover {
      background: rgba(0, 0, 0, 0.4);
    }
  }
}
.video-player {
  padding-bottom: 10px;
}
</style>
