<template>
  <div class="page-container tabs-sty">
    <el-tabs v-model="activeTabOrderRecordType">
      <el-tab-pane label="现金流表" name="first">
        <balance-water-table></balance-water-table>
      </el-tab-pane>
      <!-- <el-tab-pane label="收支流水" name="second">
        <cash-flow-table></cash-flow-table>
      </el-tab-pane>
      <el-tab-pane label="趋势分析" name="third">
        <trend-analysis-table
          v-if="activeTabOrderRecordType === 'third'"
        ></trend-analysis-table>
      </el-tab-pane> -->
    </el-tabs>
  </div>
</template>
<script>
import cashFlowTable from '@/views/financialManagement/paymentDetails/cashFlowTable.vue';
import balanceWaterTable from '@/views/financialManagement/paymentDetails/balanceWaterTable.vue';
import trendAnalysisTable from '@/views/financialManagement/paymentDetails/trendAnalysisTable.vue';
export default {
  name: 'paymentDetailsList',
  components: { balanceWaterTable },
  data() {
    return {
      //标签页切换
      activeTabOrderRecordType: 'first',
    };
  },

  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.tabs-sty {
  width: 96%;
  margin-left: 2%;
  margin-top: 30px;
}
</style>
