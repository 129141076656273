var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { ref: "baseContainer", attrs: { paddingTop: "" } },
    [
      _c(
        "div",
        { staticClass: "mainContainer" },
        [
          _c("companyOverviewItem", {
            attrs: {
              difficultyType: 0,
              companyId: _vm.companyId,
              itemName: "保养车",
            },
          }),
          _c("companyOverviewItem", {
            attrs: {
              difficultyType: 1,
              companyId: _vm.companyId,
              itemName: "故障车",
            },
          }),
          _c("companyOverviewItem", {
            attrs: {
              difficultyType: 2,
              companyId: _vm.companyId,
              itemName: "事故车",
            },
          }),
          _c("companyOverviewItem", {
            attrs: {
              difficultyType: 4,
              companyId: _vm.companyId,
              itemName: "其它车",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }