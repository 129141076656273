import http from '@/service/http';

const defaultListOption = {
  current: 1,
  size: 10,
};

/**
 * 疑似待退料入库列表
 */
export function waitReturnInInventoryList(data = defaultListOption) {
  return http.post('/admin/material/bill/waitReturnInInventoryList', data);
}

/**
 * 疑似待退料入库列表导出
 */
export const waitReturnInInventoryListExportUrl =
  '/admin/material/bill/waitReturnInInventoryList/export';
