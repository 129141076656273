var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._m(0),
      _c("div", { staticClass: "table-title" }, [
        _c("span", [_vm._v("自费业务： ")]),
        _vm.$lodash.get(_vm.detail, "hasSelfPaying")
          ? _c("span", [
              _vm._v(
                " 有限制，部分门店适用 (" +
                  _vm._s(_vm.$lodash.get(_vm.detailData, "selfPaying.length")) +
                  ") " +
                  _vm._s(
                    _vm.$lodash
                      .get(_vm.detailData, "selfPaying", [])
                      .find((item) => item.companyId == _vm.companyId)
                      ? "，含本门店"
                      : "，不包含本店"
                  ) +
                  " "
              ),
            ])
          : _c("span", [_vm._v("无限制，所有门店适用")]),
      ]),
      _vm.$lodash.get(_vm.detailData, "selfPaying.length")
        ? _c(
            "el-table",
            {
              staticClass: "table",
              attrs: { data: _vm.detailData.selfPaying, border: "" },
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", label: "序号", width: "60" },
              }),
              _c("el-table-column", {
                attrs: { prop: "companyId", label: "车店编号", width: "160" },
              }),
              _c("el-table-column", {
                attrs: { prop: "companyName", label: "车店名称" },
              }),
              _c("el-table-column", {
                attrs: { label: "连锁类型", width: "160" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("dict")(
                                  row.chainCompanyType,
                                  "chainCompanyType"
                                )
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1376723440
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "table-title" }, [
        _c("span", [_vm._v("包干业务： ")]),
        _vm.$lodash.get(_vm.detail, "hasContractFor")
          ? _c("span", [
              _vm._v(
                " 有限制，部分门店适用 (" +
                  _vm._s(
                    _vm.$lodash.get(_vm.detailData, "contractFor.length")
                  ) +
                  ") " +
                  _vm._s(
                    _vm.$lodash
                      .get(_vm.detailData, "contractFor", [])
                      .find((item) => item.companyId == _vm.companyId)
                      ? "，含本门店"
                      : "，不包含本店"
                  )
              ),
            ])
          : _c("span", [_vm._v("无限制，所有门店适用")]),
      ]),
      _vm.$lodash.get(_vm.detailData, "contractFor.length")
        ? _c(
            "el-table",
            {
              staticClass: "table",
              attrs: { data: _vm.detailData.contractFor, border: "" },
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", label: "序号", width: "60" },
              }),
              _c("el-table-column", {
                attrs: { prop: "companyId", label: "车店编号", width: "160" },
              }),
              _c("el-table-column", {
                attrs: { prop: "companyName", label: "车店名称" },
              }),
              _c("el-table-column", {
                attrs: { label: "连锁类型", width: "160" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("dict")(
                                  row.chainCompanyType,
                                  "chainCompanyType"
                                )
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1376723440
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "table-title" }, [
        _c("span", [_vm._v("理赔业务： ")]),
        _vm.$lodash.get(_vm.detail, "hasClaimSettlement")
          ? _c("span", [
              _vm._v(
                " 有限制，部分门店适用 (" +
                  _vm._s(
                    _vm.$lodash.get(_vm.detailData, "claimSettlement.length")
                  ) +
                  ") " +
                  _vm._s(
                    _vm.$lodash
                      .get(_vm.detailData, "claimSettlement", [])
                      .find((item) => item.companyId == _vm.companyId)
                      ? "，含本门店"
                      : "，不包含本店"
                  ) +
                  " "
              ),
            ])
          : _c("span", [_vm._v("无限制，所有门店适用")]),
      ]),
      _vm.$lodash.get(_vm.detailData, "claimSettlement.length")
        ? _c(
            "el-table",
            {
              staticClass: "table",
              attrs: { data: _vm.detailData.claimSettlement, border: "" },
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", label: "序号", width: "60" },
              }),
              _c("el-table-column", {
                attrs: { prop: "companyId", label: "车店编号", width: "160" },
              }),
              _c("el-table-column", {
                attrs: { prop: "companyName", label: "车店名称" },
              }),
              _c("el-table-column", {
                attrs: { label: "连锁类型", width: "160" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("dict")(
                                  row.chainCompanyType,
                                  "chainCompanyType"
                                )
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1376723440
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "table-title" }, [
        _c("span", [_vm._v("索赔业务： ")]),
        _vm.$lodash.get(_vm.detail, "hasClaimForCompensation")
          ? _c("span", [
              _vm._v(
                " 有限制，部分门店适用 (" +
                  _vm._s(
                    _vm.$lodash.get(
                      _vm.detailData,
                      "claimForCompensation.length"
                    )
                  ) +
                  ") " +
                  _vm._s(
                    _vm.$lodash
                      .get(_vm.detailData, "claimForCompensation", [])
                      .find((item) => item.companyId == _vm.companyId)
                      ? "，含本门店"
                      : "，不包含本店"
                  ) +
                  " "
              ),
            ])
          : _c("span", [_vm._v("无限制，所有门店适用")]),
      ]),
      _vm.$lodash.get(_vm.detailData, "claimForCompensation.length")
        ? _c(
            "el-table",
            {
              staticClass: "table",
              attrs: { data: _vm.detailData.claimForCompensation, border: "" },
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", label: "序号", width: "60" },
              }),
              _c("el-table-column", {
                attrs: { prop: "companyId", label: "车店编号", width: "160" },
              }),
              _c("el-table-column", {
                attrs: { prop: "companyName", label: "车店名称" },
              }),
              _c("el-table-column", {
                attrs: { label: "连锁类型", width: "160" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("dict")(
                                  row.chainCompanyType,
                                  "chainCompanyType"
                                )
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1376723440
                ),
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex note" }, [
      _c("p", [_vm._v("适用门店说明：")]),
      _c("div", [
        _c("p", [
          _vm._v(
            " 客户协议设置适用门店后，协议信息将在适用门店内共享。对于加盟店，协议客户的客户、车辆等信息也将一并共享给加盟店。 "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }