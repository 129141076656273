var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { ref: "baseContainer", attrs: { paddingTop: "" } },
    [
      [
        _c(
          "el-form",
          {
            staticClass: "filter-form",
            attrs: { model: _vm.form, "label-width": "100px", size: "small" },
          },
          [
            _c("div", { staticClass: "flex-x-between" }, [
              _c(
                "div",
                { staticClass: "flex flex-shrink0" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品名称" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入商品名称", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.getData.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.form.goodsName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "goodsName", $$v)
                          },
                          expression: "form.goodsName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "原厂编码" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入原厂编码", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.getData.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.form.goodsCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "goodsCode", $$v)
                          },
                          expression: "form.goodsCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c("span", [_vm._v("他店库存")]),
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    content:
                                      "需要查询其他连锁门店库存余量时，请打开开关",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "iconfont grow-icon_doubt",
                                    staticStyle: { color: "#9a9a9a" },
                                  }),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.form.includeOtherCompany,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "includeOtherCompany", $$v)
                          },
                          expression: "form.includeOtherCompany",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "库龄" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择库龄",
                            multiple: "",
                            clearable: "",
                          },
                          model: {
                            value: _vm.form.stockAges,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "stockAges", $$v)
                            },
                            expression: "form.stockAges",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "90天以内", value: 1 },
                          }),
                          _c("el-option", {
                            attrs: { label: "90-180天", value: 2 },
                          }),
                          _c("el-option", {
                            attrs: { label: "180-360天", value: 3 },
                          }),
                          _c("el-option", {
                            attrs: { label: "360天以上", value: 4 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "el-form-item--small" }, [
                _c(
                  "div",
                  {
                    staticClass: "el-form-item__label pointer",
                    on: {
                      click: function ($event) {
                        _vm.showMore = !_vm.showMore
                      },
                    },
                  },
                  [
                    _vm._v(" 更多筛选 "),
                    _c("i", {
                      class: {
                        primary: _vm.showMore,
                        "el-icon-arrow-right": !_vm.showMore,
                        "el-icon-arrow-down": _vm.showMore,
                      },
                    }),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "btn_search",
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.getData },
                      },
                      [_vm._v("查询")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn_search",
                        attrs: { size: "small" },
                        on: { click: _vm.empty },
                      },
                      [_vm._v("置空")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "flex-warp" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "自编码" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入自编码", clearable: "" },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.getData.apply(null, arguments)
                        },
                      },
                      model: {
                        value: _vm.form.ownCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "ownCode", $$v)
                        },
                        expression: "form.ownCode",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "商品标准名" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入商品标准名", clearable: "" },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.getData.apply(null, arguments)
                        },
                      },
                      model: {
                        value: _vm.form.standardName,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "standardName", $$v)
                        },
                        expression: "form.standardName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "适用车型" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          filterable: "",
                          remote: "",
                          placeholder: "搜索车型",
                          "remote-method": _vm.searchCarModelList,
                          loading: _vm.searchCarModelListLoading,
                          clearable: "",
                        },
                        model: {
                          value: _vm.form.matchCarType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "matchCarType", $$v)
                          },
                          expression: "form.matchCarType",
                        },
                      },
                      _vm._l(_vm.carModelData, function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.name, value: item.name },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "库存余量" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择库存余量", clearable: "" },
                        model: {
                          value: _vm.form.inventoryFilterType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "inventoryFilterType", $$v)
                          },
                          expression: "form.inventoryFilterType",
                        },
                      },
                      [
                        _c("el-option", { attrs: { label: "全部", value: 0 } }),
                        _c("el-option", {
                          attrs: { label: "库存大于0", value: 1 },
                        }),
                        _c("el-option", {
                          attrs: { label: "库存为0", value: 4 },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.showMore
                  ? [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品分类" } },
                        [
                          _c("select-goods-class-cascader", {
                            ref: "goodsClass",
                            attrs: {
                              value: [
                                _vm.form.firstCategoryId,
                                _vm.form.secondCategoryId,
                              ],
                              checkStrictly: "",
                            },
                            on: { select: _vm.handleSelectGoodsClass },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "品质" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择", clearable: "" },
                              model: {
                                value: _vm.form.manufactoryType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "manufactoryType", $$v)
                                },
                                expression: "form.manufactoryType",
                              },
                            },
                            _vm._l(_vm.manufactoryTypeDict, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]
        ),
      ],
      [
        _c(
          "div",
          { staticClass: "btns flex-x-between" },
          [
            _c("span"),
            _c(
              "el-button",
              {
                staticClass: "blue",
                attrs: { size: "small", type: "text" },
                on: {
                  click: function ($event) {
                    _vm.showExportDialog = true
                  },
                },
              },
              [
                _vm._v("导出列表"),
                _c("i", {
                  staticClass: "iconfont grow-icon_daochu el-icon--right",
                }),
              ]
            ),
          ],
          1
        ),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "fixedScrollBar",
                rawName: "v-fixedScrollBar",
                value: _vm.$refs.baseContainer,
                expression: "$refs.baseContainer",
              },
            ],
            staticClass: "custom-table",
            attrs: {
              data: _vm.listData,
              border: "",
              "show-summary": "",
              "summary-method": _vm.getSummaries,
            },
          },
          [
            _c("el-table-column", {
              attrs: { type: "index", label: "序号", width: "60" },
            }),
            _c("el-table-column", {
              attrs: { label: "操作", width: "90" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c(
                        "span",
                        {
                          staticClass: "text_operation blue",
                          on: {
                            click: function ($event) {
                              return _vm.handleClick(row)
                            },
                          },
                        },
                        [_vm._v("收发流水")]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                width: "120",
                label: "操作门店",
                prop: "company.companyName",
              },
            }),
            _c("el-table-column", {
              attrs: { width: "120", label: "商品ID", prop: "goods.goodsId" },
            }),
            _c("el-table-column", {
              attrs: {
                width: "140",
                label: "适用车型",
                prop: "goods.matchCarType",
              },
            }),
            _c("el-table-column", {
              attrs: { "min-width": "180", label: "商品标准名" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c(
                        "span",
                        {
                          staticClass: "blue pointer",
                          on: {
                            click: function ($event) {
                              return _vm.handleDetail(row)
                            },
                          },
                        },
                        [_vm._v(_vm._s(row.goods.standardName))]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "60", label: "品质" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("dict")(
                              row.goods.manufactoryType,
                              "manufactoryType"
                            )
                          )
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                width: "100",
                label: "原厂编码",
                prop: "goods.goodsCode",
              },
            }),
            _c("el-table-column", {
              attrs: { "min-width": "180", label: "商品名称" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c(
                        "span",
                        {
                          staticClass: "blue pointer",
                          on: {
                            click: function ($event) {
                              return _vm.handleDetail(row)
                            },
                          },
                        },
                        [_vm._v(_vm._s(row.goods.goodsName))]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "单位", prop: "goods.unit" },
            }),
            _c("el-table-column", {
              attrs: { width: "160", label: "商品分类" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$lodash.get(
                              row,
                              "goods.firstCategory.categoryName"
                            )
                          )
                        ),
                      ]),
                      _vm.$lodash.get(row, "goods.secondCategory.categoryName")
                        ? _c("span", [
                            _vm._v(
                              "/" +
                                _vm._s(
                                  _vm.$lodash.get(
                                    row,
                                    "goods.secondCategory.categoryName"
                                  )
                                )
                            ),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "上次采购价" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      row.goods.lastPurchasePrice !== undefined
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("toThousands")(
                                  row.goods.lastPurchasePrice
                                )
                              )
                            ),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                width: "160",
                label: "上次采购时间",
                prop: "lastPurchaseReceiptDate",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "130",
                label: "上次采购供应商",
                prop: "lastPurchaseSupplierName",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "90",
                label: "库龄/天",
                prop: "diffLastReceiptDateDays",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "160",
                label: "上次领料出库时间",
                prop: "lastMaterialOutboundDate",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "130",
                label: "在途库存余量",
                prop: "onWayInventoryTotalNum",
              },
            }),
            _c("el-table-column", {
              attrs: { width: "130", label: "在途库存成本" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("toThousands")(row.onWayInventoryTotalPrice)
                          )
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                width: "180",
                label: "库存余量(不含在途)",
                prop: "inventoryTotalNum",
              },
            }),
            _c("el-table-column", {
              attrs: { width: "180", label: "总成本(不含在途)" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm._f("toThousands")(row.inventoryTotalPrice))
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "140", label: "库位（余量）" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return _vm._l(row.goodsInventories, function (item, index) {
                      return _c("div", { key: index }, [
                        _c("span", [
                          _vm._v(_vm._s(item.storeRoom.storeRoomName)),
                        ]),
                        _vm.$lodash.get(item, "location.locationName")
                          ? _c("span", [
                              _vm._v("-" + _vm._s(item.location.locationName)),
                            ])
                          : _vm._e(),
                        _c("span", [
                          _vm._v("(" + _vm._s(item.inventoryNum) + ")"),
                        ]),
                      ])
                    })
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c("export-dialog", {
          attrs: {
            visibility: _vm.showExportDialog,
            total: _vm.total,
            pageOption: _vm.pageOption,
            filterForm: _vm.form,
            url: _vm.localExportUrl,
          },
          on: {
            "update:visibility": function ($event) {
              _vm.showExportDialog = $event
            },
          },
        }),
        _vm.showInventoryDetailDrawer
          ? _c("inventoryDetailDrawer", {
              attrs: {
                visibility: _vm.showInventoryDetailDrawer,
                id: _vm.selectedGoodsId,
                companyId: _vm.selectedCompanyId,
              },
              on: {
                "update:visibility": function ($event) {
                  _vm.showInventoryDetailDrawer = $event
                },
              },
            })
          : _vm._e(),
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c("pagination", {
            attrs: { total: _vm.total },
            on: { pageChange: _vm.changePage },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }