var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        top: "5vh",
        width: "60%",
        "append-to-body": true,
        visible: _vm.showAddCarDialog,
        "before-close": _vm.handleClose,
        "close-on-click-modal": false,
      },
    },
    [
      _c(
        "div",
        { staticStyle: { height: "70vh", overflow: "auto" } },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "110px",
                size: "small",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "车牌号", prop: "carNumber" } },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "请输入车牌号",
                            },
                            model: {
                              value: _vm.form.carNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "carNumber", $$v)
                              },
                              expression: "form.carNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10, offset: 1 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "车架号", prop: "uniqueId" } },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "请输入车架号",
                              maxlength: "17",
                              "show-word-limit": "",
                            },
                            on: { input: _vm.inputUniqueId },
                            model: {
                              value: _vm.form.uniqueId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "uniqueId", $$v)
                              },
                              expression: "form.uniqueId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.vehicleList.length,
                            expression: "vehicleList.length",
                          },
                        ],
                        staticClass: "flex-y-center",
                        staticStyle: { height: "32px", "margin-left": "15px" },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "text_operation blue",
                            on: {
                              click: function ($event) {
                                _vm.showCarConfigDialog = true
                              },
                            },
                          },
                          [_vm._v("配置详情")]
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "电机号/发动机号",
                            prop: "engineNumber",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入电机号/发动机号",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.engineNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "engineNumber", $$v)
                              },
                              expression: "form.engineNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10, offset: 1 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "车型", prop: "type" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                remote: "",
                                clearable: "",
                                placeholder: "搜索车型",
                                "remote-method": _vm.searchModel,
                                loading: _vm.searchModelLoading,
                                "value-key": "id",
                              },
                              on: { clear: _vm.clearCarModel },
                              model: {
                                value: _vm.currentCarModel,
                                callback: function ($$v) {
                                  _vm.currentCarModel = $$v
                                },
                                expression: "currentCarModel",
                              },
                            },
                            _vm._l(_vm.carModelData, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.changeCarModel(item)
                                  },
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "flex-y-center",
                        staticStyle: { height: "32px", "margin-left": "15px" },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "text_operation blue",
                            on: {
                              click: function ($event) {
                                _vm.selectCarModelDialogVisibility = true
                              },
                            },
                          },
                          [_vm._v("选择车型")]
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "车辆所有人", prop: "vehicleOwner" },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入车辆所有人",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.vehicleOwner,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "vehicleOwner", $$v)
                              },
                              expression: "form.vehicleOwner",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10, offset: 1 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "动力类型", prop: "fuelType" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                placeholder: "请选择动力类型",
                              },
                              model: {
                                value: _vm.form.fuelType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "fuelType", $$v)
                                },
                                expression: "form.fuelType",
                              },
                            },
                            _vm._l(_vm.fuelTypes, function (type) {
                              return _c("el-option", {
                                key: type.value,
                                attrs: { value: type.value, label: type.label },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "使用性质",
                            prop: "annualSurveyCarType",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择使用性质",
                                clearable: "",
                              },
                              model: {
                                value: _vm.form.annualSurveyCarType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "annualSurveyCarType", $$v)
                                },
                                expression: "form.annualSurveyCarType",
                              },
                            },
                            _vm._l(
                              _vm.annualVerificationTypes,
                              function (type) {
                                return _c("el-option", {
                                  key: type.value,
                                  attrs: {
                                    value: type.value,
                                    label: type.label,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10, offset: 1 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { "margin-bottom": "0" },
                          attrs: { label: "注册时间", prop: "registerDate" },
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "date",
                              "value-format": "yyyy-MM-dd",
                              placeholder: "请选择注册时间",
                              "picker-options": _vm.registerDatePickerOptions,
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.registerDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "registerDate", $$v)
                              },
                              expression: "form.registerDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "真实续航", prop: "trueEndurance" } },
                        [
                          _c("number-input", {
                            attrs: {
                              placeholder: "请输入真实续航",
                              enabledDecimals: false,
                              enabledZero: false,
                              max: 99999,
                            },
                            model: {
                              value: _vm.form.trueEndurance,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "trueEndurance", $$v)
                              },
                              expression: "form.trueEndurance",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10, offset: 1 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "新车续航",
                            prop: "newVehicleMileage",
                          },
                        },
                        [
                          _c("number-input", {
                            attrs: {
                              placeholder: "请输入新车续航",
                              enabledDecimals: false,
                              enabledZero: false,
                              max: 99999,
                            },
                            model: {
                              value: _vm.form.newVehicleMileage,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "newVehicleMileage", $$v)
                              },
                              expression: "form.newVehicleMileage",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "在租状态", prop: "underLeaseState" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择在租状态",
                                clearable: "",
                              },
                              model: {
                                value: _vm.form.underLeaseState,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "underLeaseState", $$v)
                                },
                                expression: "form.underLeaseState",
                              },
                            },
                            _vm._l(_vm.underLeaseState, function (type) {
                              return _c("el-option", {
                                key: type.value,
                                attrs: { value: type.value, label: type.label },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10, offset: 1 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "是否风控车",
                            prop: "isRiskControlCar",
                          },
                        },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-text": _vm.form.isRiskControlCar
                                ? "是"
                                : "否",
                              "active-value": 1,
                              "inactive-value": 0,
                            },
                            model: {
                              value: _vm.form.isRiskControlCar,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "isRiskControlCar", $$v)
                              },
                              expression: "form.isRiskControlCar",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 20 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注", prop: "remark" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              autosize: { minRows: 2, maxRows: 4 },
                              placeholder: "请输入车辆备注",
                              maxlength: "100",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.form.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "remark", $$v)
                              },
                              expression: "form.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 20 } },
                    [
                      _c("el-form-item", { attrs: { label: "车辆图片" } }, [
                        _c("p", { staticClass: "img-note" }, [
                          _vm._v(
                            " （最多可上传12张图片，建议每张图片大小不超过1M） "
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "car-imgs" },
                          [
                            _c("upload-file", {
                              attrs: {
                                imagesForPreview: _vm.allPictures,
                                title: "行驶证",
                              },
                              on: { input: _vm.handlerAllPictures },
                              model: {
                                value: _vm.form.runningLicenceImg,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "runningLicenceImg", $$v)
                                },
                                expression: "form.runningLicenceImg",
                              },
                            }),
                            _c("upload-file", {
                              attrs: {
                                imagesForPreview: _vm.allPictures,
                                title: "商业险保单",
                              },
                              on: { input: _vm.handlerAllPictures },
                              model: {
                                value: _vm.form.vciGuaranteeSlipImg,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "vciGuaranteeSlipImg", $$v)
                                },
                                expression: "form.vciGuaranteeSlipImg",
                              },
                            }),
                            _c("upload-file", {
                              attrs: {
                                imagesForPreview: _vm.allPictures,
                                title: "交强险保单",
                              },
                              on: { input: _vm.handlerAllPictures },
                              model: {
                                value: _vm.form.tciGuaranteeSlipImg,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "tciGuaranteeSlipImg", $$v)
                                },
                                expression: "form.tciGuaranteeSlipImg",
                              },
                            }),
                            _c("upload-file", {
                              attrs: {
                                imagesForPreview: _vm.allPictures,
                                title: "承运险保单",
                              },
                              on: { input: _vm.handlerAllPictures },
                              model: {
                                value: _vm.form.viGuaranteeSlipImg,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "viGuaranteeSlipImg", $$v)
                                },
                                expression: "form.viGuaranteeSlipImg",
                              },
                            }),
                            _c("upload-file", {
                              attrs: {
                                imagesForPreview: _vm.allPictures,
                                title: "车辆铭牌",
                              },
                              on: { input: _vm.handlerAllPictures },
                              model: {
                                value: _vm.form.vehicleNameplateImg,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "vehicleNameplateImg", $$v)
                                },
                                expression: "form.vehicleNameplateImg",
                              },
                            }),
                            _c("upload-file", {
                              attrs: {
                                imagesForPreview: _vm.allPictures,
                                title: "电池铭牌",
                              },
                              on: { input: _vm.handlerAllPictures },
                              model: {
                                value: _vm.form.batteryNameplateImg,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "batteryNameplateImg", $$v)
                                },
                                expression: "form.batteryNameplateImg",
                              },
                            }),
                            _c("upload-file-list", {
                              attrs: {
                                imagesForPreview: _vm.allPictures,
                                max: 6,
                              },
                              on: { input: _vm.handlerAllPictures },
                              model: {
                                value: _vm.form.picOther,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "picOther", $$v)
                                },
                                expression: "form.picOther",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c("div", { staticClass: "block-title" }, [
                      _vm._v("客户信息"),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    {
                      directives: [
                        {
                          name: "auth",
                          rawName: "v-auth",
                          value: 63,
                          expression: "63",
                        },
                      ],
                      attrs: { span: 24 },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "选择已有客户" } },
                        [
                          _c("select-client-popover", {
                            attrs: {
                              clearable: false,
                              searchKeyWord: _vm.clientBriefMessage.clientName,
                            },
                            on: { select: _vm.handleSelectClient },
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "auth",
                                  rawName: "v-auth",
                                  value: 61,
                                  expression: "61",
                                },
                              ],
                              staticStyle: { margin: "0 15px" },
                            },
                            [_vm._v("或")]
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "auth",
                                  rawName: "v-auth",
                                  value: 61,
                                  expression: "61",
                                },
                              ],
                              staticClass: "orange text_operation",
                              on: {
                                click: function ($event) {
                                  _vm.showAddCusterDialog = true
                                },
                              },
                            },
                            [_vm._v("新增客户")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 23, offset: 1 } }, [
                    _c("div", { staticClass: "client-list" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.form.clientId,
                              expression: "form.clientId",
                            },
                          ],
                          staticClass: "client",
                        },
                        [
                          _c("i", {
                            directives: [
                              {
                                name: "auth",
                                rawName: "v-auth",
                                value: 64,
                                expression: "64",
                              },
                            ],
                            staticClass:
                              "iconfont grow-icon_delete3 icon_delete pointer",
                            on: { click: _vm.deleteClient },
                          }),
                          _c("div", { staticClass: "client-info" }, [
                            _c("div", { staticClass: "flex" }, [
                              _c("i", {
                                staticClass: "iconfont icon_grade",
                                class:
                                  _vm.levelIconsOther[
                                    _vm.clientBriefMessage.clientLevel
                                  ],
                                attrs: {
                                  title:
                                    [_vm.clientBriefMessage.clientLevel] || "",
                                },
                              }),
                              _c(
                                "span",
                                { staticStyle: { "margin-right": "5px" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.clientBriefMessage.clientName)
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c("div", { staticClass: "block-title" }, [
                      _vm._v("运营信息"),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "车辆来源", prop: "vehicleSource" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入车辆来源",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.vehicleSource,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "vehicleSource", $$v)
                              },
                              expression: "form.vehicleSource",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10, offset: 1 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "发车模式", prop: "departureMode" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入发车模式",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.departureMode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "departureMode", $$v)
                              },
                              expression: "form.departureMode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c("div", { staticClass: "block-title" }, [
                      _vm._v("保险信息"),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 10, offset: 2 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "车损险起保日",
                            prop: "insuranceValidDate",
                          },
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              "value-format": "yyyy-MM-dd",
                              type: "date",
                              placeholder: "请选择车损险起保日",
                            },
                            model: {
                              value: _vm.form.insuranceValidDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "insuranceValidDate", $$v)
                              },
                              expression: "form.insuranceValidDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "承保公司",
                            prop: "insuranceCompany",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                clearable: "",
                                remote: "",
                                placeholder: "请输入承保公司名称查询",
                                "remote-method": _vm.searchInsuranceCompany,
                                loading: _vm.searchInsuranceCompanyLoading,
                              },
                              model: {
                                value: _vm.form.insuranceCompany,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "insuranceCompany", $$v)
                                },
                                expression: "form.insuranceCompany",
                              },
                            },
                            _vm._l(_vm.insuranceCompanyList, function (item) {
                              return _c(
                                "el-option",
                                {
                                  key: item.id,
                                  attrs: {
                                    label: item.insuranceName,
                                    value: item.id,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { float: "left" } },
                                    [_vm._v(_vm._s(item.insuranceName))]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        float: "right",
                                        color: "#8492a6",
                                        "font-size": "13px",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.insuranceNum))]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 10, offset: 2 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "三者险起保日",
                            prop: "thirdPartyInsuranceValidDate",
                          },
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              "value-format": "yyyy-MM-dd",
                              type: "date",
                              placeholder: "请选择三者险起保日",
                            },
                            model: {
                              value: _vm.form.thirdPartyInsuranceValidDate,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "thirdPartyInsuranceValidDate",
                                  $$v
                                )
                              },
                              expression: "form.thirdPartyInsuranceValidDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "承保公司",
                            prop: "thirdPartyInsuranceCompanyId",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                clearable: "",
                                remote: "",
                                placeholder: "请输入承保公司名称查询",
                                "remote-method": _vm.searchInsuranceCompany,
                                loading: _vm.searchInsuranceCompanyLoading,
                              },
                              model: {
                                value: _vm.form.thirdPartyInsuranceCompanyId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "thirdPartyInsuranceCompanyId",
                                    $$v
                                  )
                                },
                                expression: "form.thirdPartyInsuranceCompanyId",
                              },
                            },
                            _vm._l(_vm.insuranceCompanyList, function (item) {
                              return _c(
                                "el-option",
                                {
                                  key: item.id,
                                  attrs: {
                                    label: item.insuranceName,
                                    value: item.id,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { float: "left" } },
                                    [_vm._v(_vm._s(item.insuranceName))]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        float: "right",
                                        color: "#8492a6",
                                        "font-size": "13px",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.insuranceNum))]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 10, offset: 2 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "座位险起保日",
                            prop: "seatInsuranceValidDate",
                          },
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              "value-format": "yyyy-MM-dd",
                              type: "date",
                              placeholder: "请选择座位险起保日",
                            },
                            model: {
                              value: _vm.form.seatInsuranceValidDate,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "seatInsuranceValidDate",
                                  $$v
                                )
                              },
                              expression: "form.seatInsuranceValidDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "承保公司",
                            prop: "seatInsuranceCompanyId",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                clearable: "",
                                remote: "",
                                placeholder: "请输入承保公司名称查询",
                                "remote-method": _vm.searchInsuranceCompany,
                                loading: _vm.searchInsuranceCompanyLoading,
                              },
                              model: {
                                value: _vm.form.seatInsuranceCompanyId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "seatInsuranceCompanyId",
                                    $$v
                                  )
                                },
                                expression: "form.seatInsuranceCompanyId",
                              },
                            },
                            _vm._l(_vm.insuranceCompanyList, function (item) {
                              return _c(
                                "el-option",
                                {
                                  key: item.id,
                                  attrs: {
                                    label: item.insuranceName,
                                    value: item.id,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { float: "left" } },
                                    [_vm._v(_vm._s(item.insuranceName))]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        float: "right",
                                        color: "#8492a6",
                                        "font-size": "13px",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.insuranceNum))]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 10, offset: 2 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "交强险起保日",
                            prop: "tcInsuranceValidDate",
                          },
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              "value-format": "yyyy-MM-dd",
                              type: "date",
                              placeholder: "请选择交强险起保日",
                            },
                            model: {
                              value: _vm.form.tcInsuranceValidDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "tcInsuranceValidDate", $$v)
                              },
                              expression: "form.tcInsuranceValidDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "承保公司",
                            prop: "tcInsuranceCompany",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                clearable: "",
                                remote: "",
                                placeholder: "请输入承保公司名称查询",
                                "remote-method": _vm.searchInsuranceCompany,
                                loading: _vm.searchInsuranceCompanyLoading,
                              },
                              model: {
                                value: _vm.form.tcInsuranceCompany,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "tcInsuranceCompany", $$v)
                                },
                                expression: "form.tcInsuranceCompany",
                              },
                            },
                            _vm._l(_vm.insuranceCompanyList, function (item) {
                              return _c(
                                "el-option",
                                {
                                  key: item.id,
                                  attrs: {
                                    label: item.insuranceName,
                                    value: item.id,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { float: "left" } },
                                    [_vm._v(_vm._s(item.insuranceName))]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        float: "right",
                                        color: "#8492a6",
                                        "font-size": "13px",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.insuranceNum))]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 10, offset: 2 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "承运险起保日",
                            prop: "vehicleInsuranceValidDate",
                          },
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              "value-format": "yyyy-MM-dd",
                              type: "date",
                              placeholder: "请选择承运险起保日",
                            },
                            model: {
                              value: _vm.form.vehicleInsuranceValidDate,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "vehicleInsuranceValidDate",
                                  $$v
                                )
                              },
                              expression: "form.vehicleInsuranceValidDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "承保公司",
                            prop: "vehicleInsuranceCompany",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                clearable: "",
                                remote: "",
                                placeholder: "请输入承保公司名称查询",
                                "remote-method": _vm.searchInsuranceCompany,
                                loading: _vm.searchInsuranceCompanyLoading,
                              },
                              model: {
                                value: _vm.form.vehicleInsuranceCompany,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "vehicleInsuranceCompany",
                                    $$v
                                  )
                                },
                                expression: "form.vehicleInsuranceCompany",
                              },
                            },
                            _vm._l(_vm.insuranceCompanyList, function (item) {
                              return _c(
                                "el-option",
                                {
                                  key: item.id,
                                  attrs: {
                                    label: item.insuranceName,
                                    value: item.id,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { float: "left" } },
                                    [_vm._v(_vm._s(item.insuranceName))]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        float: "right",
                                        color: "#8492a6",
                                        "font-size": "13px",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.insuranceNum))]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 10, offset: 2 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "驾乘险起保日",
                            prop: "drivingInsuranceValidDate",
                          },
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              "value-format": "yyyy-MM-dd",
                              type: "date",
                              placeholder: "请选择驾乘险起保日",
                            },
                            model: {
                              value: _vm.form.drivingInsuranceValidDate,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "drivingInsuranceValidDate",
                                  $$v
                                )
                              },
                              expression: "form.drivingInsuranceValidDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "承保公司",
                            prop: "drivingInsuranceCompanyId",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                clearable: "",
                                remote: "",
                                placeholder: "请输入承保公司名称查询",
                                "remote-method": _vm.searchInsuranceCompany,
                                loading: _vm.searchInsuranceCompanyLoading,
                              },
                              model: {
                                value: _vm.form.drivingInsuranceCompanyId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "drivingInsuranceCompanyId",
                                    $$v
                                  )
                                },
                                expression: "form.drivingInsuranceCompanyId",
                              },
                            },
                            _vm._l(_vm.insuranceCompanyList, function (item) {
                              return _c(
                                "el-option",
                                {
                                  key: item.id,
                                  attrs: {
                                    label: item.insuranceName,
                                    value: item.id,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { float: "left" } },
                                    [_vm._v(_vm._s(item.insuranceName))]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        float: "right",
                                        color: "#8492a6",
                                        "font-size": "13px",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.insuranceNum))]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 10, offset: 2 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "格悦出单" } },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-text": _vm.form.geyueIssuer ? "是" : "否",
                              "active-value": 1,
                              "inactive-value": 0,
                            },
                            model: {
                              value: _vm.form.geyueIssuer,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "geyueIssuer", $$v)
                              },
                              expression: "form.geyueIssuer",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 5 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "团车政策" } },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-text": _vm.form.groupCarPolicy
                                ? "是"
                                : "否",
                              "active-value": 1,
                              "inactive-value": 0,
                            },
                            on: { change: _vm.groupCarPolicyChange },
                            model: {
                              value: _vm.form.groupCarPolicy,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "groupCarPolicy", $$v)
                              },
                              expression: "form.groupCarPolicy",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 5 } },
                    [
                      _vm.form.groupCarPolicy
                        ? _c("el-input", {
                            attrs: {
                              size: "small",
                              placeholder: "请输入团车代码",
                            },
                            model: {
                              value: _vm.form.groupCarCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "groupCarCode", $$v)
                              },
                              expression: "form.groupCarCode",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 20, offset: 2 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "承保特殊要求" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              rows: 3,
                              placeholder: "请输入",
                              maxlength: "100",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.form.insureSpecialAsk,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "insureSpecialAsk", $$v)
                              },
                              expression: "form.insureSpecialAsk",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.selectCarModelDialogVisibility
            ? _c("select-car-model-dialog", {
                attrs: { visibility: _vm.selectCarModelDialogVisibility },
                on: {
                  "update:visibility": function ($event) {
                    _vm.selectCarModelDialogVisibility = $event
                  },
                  select: _vm.selectCarModel,
                },
              })
            : _vm._e(),
          _vm.showCarConfigDialog
            ? _c("car-config-detail-dialog", {
                attrs: {
                  showCarConfigDialog: _vm.showCarConfigDialog,
                  vehicleList: _vm.vehicleList,
                  uniqueId: _vm.form.uniqueId,
                  levelId: _vm.levelId,
                },
                on: {
                  "update:showCarConfigDialog": function ($event) {
                    _vm.showCarConfigDialog = $event
                  },
                  "update:show-car-config-dialog": function ($event) {
                    _vm.showCarConfigDialog = $event
                  },
                  selectedVehicle: _vm.selectedVehicle,
                },
              })
            : _vm._e(),
          _vm.showAddCusterDialog
            ? _c("add-customer-dialog", {
                attrs: { showAddCusterDialog: _vm.showAddCusterDialog },
                on: {
                  "update:showAddCusterDialog": function ($event) {
                    _vm.showAddCusterDialog = $event
                  },
                  "update:show-add-custer-dialog": function ($event) {
                    _vm.showAddCusterDialog = $event
                  },
                  custerInforma: _vm.custerInforma,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.verifyBeforeSave },
              },
              [_vm._v("确定保存")]
            ),
            _c(
              "el-button",
              { attrs: { size: "small" }, on: { click: _vm.handleClose } },
              [_vm._v("取 消")]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }