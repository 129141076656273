import http from '@/service/http';

const defaultListOption = {
  current: 1,
  size: 10,
};

// 获取客户协议列表
export function getAgreementClientList(data = defaultListOption) {
  return http.post('/admin/agreementClient/list', data);
}

// 获取客户协议列表 右侧统计数据
export function getAgreementStatistic(data = {}) {
  return http.post('/admin/agreementClient/statistic', data);
}

// 获取客户协议包含门店
export function getAgreementStoreList(id) {
  return http.post('/admin/agreement/store/list', { clientAgreementId: id });
}

// 导出车辆列表
export const exportAgreementListUrl = '/admin/agreementClient/export';

// 获取客户协议详情
export function getAgreementDetail(id) {
  return http.post('/admin/agreementClient/detail', { clientAgreementId: id });
}

// 新增客户协议
export function insertAgreement(data = {}, config = { onlyReturnData: false }) {
  return http.post('/admin/agreementClient', data, config);
}

// 更新客户协议
export function updateAgreement(data = {}, config = { onlyReturnData: false }) {
  return http.put('/admin/agreementClient', data, config);
}

// 获取客户协议详情-折扣信息
export function getAgreementDiscount(companyId, clientAgreementId) {
  return http.post('/admin/agreement/discount/list', {
    clientAgreementId,
    companyId,
  });
}

// 获取客户协议详情-包干协议
export function getAgreementUndertake(clientAgreementId) {
  return http.post('/admin/agreement/undertake/list', { clientAgreementId });
}

// 获取客户协议详情-适用门店
export function getAgreementStore(clientAgreementId) {
  return http.post('/admin/agreement/store/list', { clientAgreementId });
}

// 选择门店服务列表
export function storesServiceList(
  data = {},
  config = { onlyReturnData: false }
) {
  return http.post('/admin/service/list', data, config);
}

// 选择门店服务统计
export function storesServiceStatisticalList(
  data = {},
  config = { onlyReturnData: false }
) {
  return http.post('/admin/service/statistic', data, config);
}

// 选择门店商品列表
export function storesGoodsList(data = {}, config = { onlyReturnData: false }) {
  return http.post('/admin/good/list', data, config);
}

// 选择门店商品统计
export function storesGoodsStatisticalList(
  data = {},
  config = { onlyReturnData: false }
) {
  return http.post('/admin/good/statistic', data, config);
}

/**
 * 获取客户协议价列表
 */
export function getAgreementPriceList(data) {
  return http.post('/admin/agreementClient/priceList', data);
}

//============================客户协议价和客户协议价明细================================
/**
 * 获取客户协议价有效分页列表
 */
export function getValidClientAgreementPriceList(data) {
  return http.post('/admin/agreement/price/validClientAgreementList', data);
}
/**
 * 有效期客户协议列表导出URL
 */
export const validClientAgreementListExportUrl =
  '/admin/agreement/price/validClientAgreementList/export';

/**
 * 客户协议价导入
 */
export function clientAgreementPriceImport(file, agreementId) {
  let formData = new FormData();
  formData.append('file', file);
  formData.append('agreementId', agreementId);

  return http.request({
    method: 'post',
    url: '/admin/agreement/price/import',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
    timeout: 18e5,
  });
}
/**
 * 客户协议价导入失败结果列表
 */
export function getClientAgreementPriceImportResult(data) {
  return http.post('/admin/agreement/price/importResult', data);
}
/**
 * 客户协议价导入失败列表导出URL
 */
export const clientAgreementPriceImportFailedExportUrl =
  '/admin/agreement/price/importFailed/export';

/**
 * 获取客户协议价明细分页列表
 */
export function getValidClientAgreementPriceDetailList(data) {
  return http.post('/admin/agreement/price/detailList', data);
}
/**
 * 有效期客户协议明细列表导出URL
 */
export const validClientAgreementDetailListExportUrl =
  '/admin/agreement/price/detailList/export';

/**
 * 获取客户协议价明细详情
 */
export function clientAgreementPriceInfo(data) {
  return http.post('/admin/agreement/price/info', data);
}
/**
 * 保存客户协议价明细
 */
export function clientAgreementPriceSave(data) {
  return http.post('/admin/agreement/price/save', data);
}
/**
 * 更新客户协议价明细
 */
export function clientAgreementPriceUpdate(data) {
  return http.post('/admin/agreement/price/update', data);
}
/**
 * 批量删除客户协议价明细
 */
export function clientAgreementPriceBatchDelete(data) {
  return http.post('/admin/agreement/price/deleteBatch', data);
}
/**
 * 根据协议编号查询生效协议
 */
export function searchByAgreementNumber(data) {
  return http.post('/admin/agreement/price/searchByAgreementNumber', data);
}
/**
 * 根据协议编号查询生效协议
 */
export function getByAgreementId(data) {
  return http.post('/admin/agreement/price/getByAgreementId', data);
}
/**
 * 继承协议价
 */
export function extendAgreementPrice(data) {
  return http.post('/admin/agreement/price/extendAgreementPrice', data);
}
//============================客户协议价和客户协议价明细================================
