<template>
  <base-container paddingTop>
    <!-- 基本信息 -->
    <div class="client_name">
      <i
        style="margin: 0px 5px 0px 16px; color: #3fa1e6"
        class="iconfont icon_grade"
        :class="levelIcons[detailData.clientLevel]"
        :title="levelIconsWord[detailData.clientLevel]"
      ></i>
      <span style="color: #303133">{{ detailData.clientName }}</span>
      <span
        class="primary pointer"
        @click="clientCommandDrawerVisibility = true"
        style="
          padding-bottom: 2px;
          border-bottom: 1px solid #33ab79;
          margin-left: 16px;
        "
        v-auth="546"
        >客户指令</span
      >
      <div class="client_remark">
        <p class="pre-wrap" v-if="detailData.remark">
          {{ detailData.remark }}
        </p>
        <p v-else>暂无备注</p>
      </div>
    </div>
    <el-tabs class="tabs" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="基本信息" name="first" v-auth="576">
        <div class="description_word">基本信息</div>
        <!-- 单位 -->
        <el-descriptions
          :column="3"
          border
          labelClassName="table-label"
          :contentStyle="{ verticalAlign: 'middle' }"
          :labelStyle="{ 'text-align': 'left !important' }"
          v-if="detailData.clientGenderAndType == '单位'"
        >
          <el-descriptions-item label="公司全称">
            {{ detailData.companyFullName }}
          </el-descriptions-item>
          <el-descriptions-item label="法人信息">
            {{ detailData.legalPerson }}
          </el-descriptions-item>
          <el-descriptions-item label="成立时间">
            {{ detailData.establishedTime }}
          </el-descriptions-item>
          <el-descriptions-item label="股东机构">
            {{ detailData.shareholderStructure }}
          </el-descriptions-item>
          <el-descriptions-item label="公司性质">
            {{ detailData.companyNature }}
          </el-descriptions-item>
          <el-descriptions-item label="发车模式">
            {{ detailData.departureMode }}
          </el-descriptions-item>
          <el-descriptions-item label="车辆来源">
            {{ detailData.vehicleSource }}
          </el-descriptions-item>
          <el-descriptions-item label="车队管理系统">
            {{ detailData.fleetManageSystem }}
          </el-descriptions-item>
          <el-descriptions-item label="社会信用代码">
            <template>
              <span style="word-break: break-word">
                {{ detailData.idNum }}
              </span>
            </template>
          </el-descriptions-item>
          <el-descriptions-item label="专属客户经理">
            {{ detailData.exclusiveCustomerManagerName }}
            {{ detailData.exclusiveCustomerManagerPhone }}
          </el-descriptions-item>
          <el-descriptions-item label="办公地址" :span="3">
            {{ detailData.address }}
          </el-descriptions-item>
          <el-descriptions-item label="双系客户" :span="3">
            {{ detailData.isDualSystem === true ? '是' : '否' }}
          </el-descriptions-item>
          <el-descriptions-item label="客户图片" :span="3">
            <div class="flex" v-if="detailData.picClientLists.length != 0">
              <upload-file
                v-for="(item, index) in detailData.picClientLists"
                :key="index"
                disabled
                :title="item.picTypeName"
                :value="item.picUrlName"
                :imagesForPreview="
                  detailData.picClientLists.map((img) => img.picUrlName)
                "
              ></upload-file>
            </div>
            <div v-else class="color999">无附件图片</div>
          </el-descriptions-item>
        </el-descriptions>
        <!-- 个人 -->
        <el-descriptions
          :column="3"
          border
          labelClassName="table-label"
          :contentStyle="{ verticalAlign: 'middle' }"
          :labelStyle="{ 'text-align': 'left !important' }"
          v-else
        >
          <el-descriptions-item label="身份证号">
            <template>
              <span style="word-break: break-word">
                {{ detailData.idNum }}
              </span>
            </template>
          </el-descriptions-item>
          <el-descriptions-item label="其他证件">
            {{
              detailData.otherCertificateMsg == 'null'
                ? ''
                : detailData.otherCertificateMsg
            }}
          </el-descriptions-item>
          <el-descriptions-item label="客户生日">
            {{ detailData.birthday }}
          </el-descriptions-item>
          <el-descriptions-item label="联系地址">
            {{ detailData.address }}
          </el-descriptions-item>
          <el-descriptions-item label="所在单位">
            {{ detailData.clientUnit }}
          </el-descriptions-item>
          <el-descriptions-item> </el-descriptions-item>
          <el-descriptions-item label="客户图片" :span="3">
            <div class="flex" v-if="detailData.picClientLists.length != 0">
              <upload-file
                v-for="(item, index) in detailData.picClientLists"
                :key="index"
                disabled
                :title="item.picTypeName"
                :value="item.picUrlName"
                :imagesForPreview="
                  detailData.picClientLists.map((img) => img.picUrlName)
                "
              ></upload-file>
            </div>
            <div v-else class="color999">无附件图片</div>
          </el-descriptions-item>
        </el-descriptions>
        <!--其他联系人 -->
        <div class="description_word" v-if="this.otherContactsLength > 0">
          其他联系人
        </div>
        <el-descriptions
          :column="3"
          border
          labelClassName="table-label"
          :contentStyle="{ verticalAlign: 'middle', width: '22.5%' }"
        >
          <el-descriptions-item
            v-for="(item, index) in detailData.otherContacts"
            :label="item.position | dict('position')"
            :key="index"
          >
            <p style="color: #303133">
              {{ item.contactsName }}{{ item.contactsPhone }}
            </p>
            <p style="color: #999; word-break: break-word">{{ item.remark }}</p>
          </el-descriptions-item>
          <el-descriptions-item label="" v-if="redundant == 1">
          </el-descriptions-item>
          <el-descriptions-item label="" v-if="redundant == 2">
          </el-descriptions-item>
          <el-descriptions-item label="" v-if="redundant == 3">
          </el-descriptions-item>
          <el-descriptions-item label="" v-if="redundant == 3">
          </el-descriptions-item>
        </el-descriptions>
        <!-- 客户协议 -->
        <div class="description_word">
          客户协议
          <span
            class="red text_operation"
            style="font-size: 12px"
            @click="goAgreementList"
            v-if="![0, undefined].includes(detailData.hasDueCount)"
            v-auth="474"
            >过期协议：{{ detailData.hasDueCount }}</span
          >
        </div>
        <el-table :data="agreementTable" border style="width: 100%">
          <el-table-column label="协议编号" mix-width="120">
            <template slot-scope="scope">
              <span
                class="pointer text_operation blue"
                @click="goAgreementDetail(scope.row.clientAgreementId)"
                >{{ scope.row.contractNumber }}</span
              >
            </template>
          </el-table-column>
          <el-table-column label="包干协议" mix-width="120">
            <template #default="{ row }">
              {{ row.beContract ? '是' : '否' }}
            </template>
          </el-table-column>
          <el-table-column prop="startDate" label="生效时间" mix-width="150">
          </el-table-column>
          <el-table-column prop="endDate" label="有效期至" mix-width="100">
          </el-table-column>
          <el-table-column label="协议状态" mix-width="180">
            <template #default="{ row }">
              {{
                row.agreementState == '未生效' ? '待生效' : row.agreementState
              }}
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="车辆情况" name="second" v-auth="577">
        <!-- 车辆情况 -->
        <div class="description_word">车辆情况</div>
        <div class="flex flex-x-between">
          <!-- 车辆环形情况 -->
          <div class="chart-style">
            <div id="vehicle-brand-chart"></div>
          </div>
          <div style="width: 80%; overflow-x: auto">
            <el-table :data="carScale" border style="width: 100%">
              <el-table-column prop="vehicleScale" mix-width="100">
                <template slot="header">
                  <div>
                    车辆规模
                    <el-tooltip
                      effect="light"
                      content="该客户拥有的车辆数"
                      placement="bottom-start"
                    >
                      <i
                        class="iconfont grow-icon_doubt"
                        style="color: #9a9a9a"
                      ></i>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="cooperationNumber" mix-width="100">
                <template slot="header">
                  <div>
                    合作台数
                    <el-tooltip
                      effect="light"
                      content="该客户目前在系统绑定的车辆数 占比计算为【合作台数/车辆规模】"
                      placement="bottom-start"
                    >
                      <i
                        class="iconfont grow-icon_doubt"
                        style="color: #9a9a9a"
                      ></i>
                    </el-tooltip>
                  </div>
                </template>
                <template #default="{ row }">
                  {{ row.cooperationNumber }} （{{ row.cooperationRate }}）
                </template>
              </el-table-column>
              <el-table-column prop="actualVehicle" mix-width="100">
                <template slot="header">
                  <div>
                    实际运营
                    <el-tooltip
                      effect="light"
                      content="该客户实际运营的车辆数 占比计算为【实际运营/合作台数】"
                      placement="bottom-start"
                    >
                      <i
                        class="iconfont grow-icon_doubt"
                        style="color: #9a9a9a"
                      ></i>
                    </el-tooltip>
                  </div>
                </template>
                <template #default="{ row }">
                  {{ row.actualVehicle }} （{{ row.operatingRate }}）
                </template>
              </el-table-column>
              <el-table-column prop="actualShop" mix-width="100">
                <template slot="header">
                  <div>
                    实际进店
                    <el-tooltip
                      effect="light"
                      content="该客户名下车辆从今日起前三个月有存在消费记录的车辆统计 占比计算为（实际进店/合作台数）"
                      placement="bottom-start"
                    >
                      <i
                        class="iconfont grow-icon_doubt"
                        style="color: #9a9a9a"
                      ></i>
                    </el-tooltip>
                  </div>
                </template>
                <template #default="{ row }">
                  {{ row.actualShop }} （{{ row.admissionRate }}）
                </template>
              </el-table-column>
            </el-table>
            <!-- 里程区间 -->
            <el-table
              :data="clientCarMileageSectionVo"
              border
              style="width: 100%; margin-top: 44px"
            >
              <el-table-column label="里程区间" mix-width="120">
                <template>
                  <span>台数</span>
                </template>
              </el-table-column>
              <el-table-column
                label="0-10万"
                mix-width="100"
                prop="oneSectionCarNum"
              >
              </el-table-column>
              <el-table-column
                label="10-20万"
                mix-width="100"
                prop="twoSectionCarNum"
              >
              </el-table-column>
              <el-table-column
                label="20-30万"
                mix-width="100"
                prop="threeSectionCarNum"
              >
              </el-table-column>
              <el-table-column
                label="30-40万"
                mix-width="100"
                prop="fourSectionCarNum"
              >
              </el-table-column>
              <el-table-column
                label="40-50万"
                mix-width="100"
                prop="fiveSectionCarNum"
              >
              </el-table-column>
              <el-table-column
                label="50-60万"
                mix-width="100"
                prop="sixSectionCarNum"
              >
              </el-table-column>
              <el-table-column
                label="60万以上"
                mix-width="130"
                prop="sevenSectionCarNum"
              >
              </el-table-column>
              <el-table-column
                label="未知"
                mix-width="100"
                prop="unknownSectionCarNum"
              >
              </el-table-column>
            </el-table>
            <!-- 运营年限 -->
            <el-table
              :data="clientCarOperatingLifeSectionVo"
              border
              style="width: 100%; margin-top: 44px"
            >
              <el-table-column label="运营年限" mix-width="120">
                <template>
                  <span>台数</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="oneSectionCarNum"
                label="1年内"
                mix-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="twoSectionCarNum"
                label="1-2年内"
                mix-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="threeSectionCarNum"
                label="2-3年内"
                mix-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="fourSectionCarNum"
                label="3-4年内"
                mix-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="fiveSectionCarNum"
                label="4-5年内"
                mix-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="sixSectionCarNum"
                label="5-6年内"
                mix-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="sevenSectionCarNum"
                label="6年以上"
                mix-width="130"
              >
              </el-table-column>
              <el-table-column
                prop="unknownSectionCarNum"
                label="未知"
                mix-width="100"
              >
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="业务信息" name="third" v-auth="578">
        <!-- 业务信息 -->
        <div class="description_word">业务信息</div>
        <el-descriptions
          :column="3"
          border
          labelClassName="table-label"
          :contentStyle="{ verticalAlign: 'middle' }"
          :labelStyle="{ 'text-align': 'left !important' }"
        >
          <el-descriptions-item label="合作模式">
            {{ clientBusinessMessageDto.cooperationModel }}
          </el-descriptions-item>
          <el-descriptions-item label="合作时间">
            {{ clientBusinessMessageDto.cooperationTime }}
          </el-descriptions-item>
          <el-descriptions-item label="客户来源">
            {{
              clientBusinessMessageDto.customerSource | dict('customerSource')
            }}
          </el-descriptions-item>
          <el-descriptions-item label="自费维保">
            {{ clientBusinessMessageDto.selfPayingMaintenance }}
          </el-descriptions-item>
          <el-descriptions-item label="事故维修">
            {{ clientBusinessMessageDto.accidentMaintenance }}
          </el-descriptions-item>
          <el-descriptions-item label="退还车/整备">
            {{ clientBusinessMessageDto.returnCar }}
          </el-descriptions-item>
          <el-descriptions-item label="车辆保险">
            {{ clientBusinessMessageDto.carInsurance }}
          </el-descriptions-item>
          <el-descriptions-item label="强收车">
            {{ clientBusinessMessageDto.forcedRetracting }}
          </el-descriptions-item>
          <el-descriptions-item label="停车场">
            {{ clientBusinessMessageDto.parkingLot }}
          </el-descriptions-item>
          <el-descriptions-item label="车务代办">
            {{ clientBusinessMessageDto.vehicleAgent }}
          </el-descriptions-item>
          <el-descriptions-item label="保费分期">
            {{ clientBusinessMessageDto.premiumInstalment }}
          </el-descriptions-item>
          <el-descriptions-item label="车辆代发">
            {{ clientBusinessMessageDto.carDispatch }}
          </el-descriptions-item>
          <el-descriptions-item label="车管报表">
            {{ clientBusinessMessageDto.carReport }}
          </el-descriptions-item>
          <el-descriptions-item label="金融服务">
            {{ clientBusinessMessageDto.financialServices }}
          </el-descriptions-item>
          <el-descriptions-item label="新车采购">
            {{ clientBusinessMessageDto.newCarPurchase }}
          </el-descriptions-item>
          <el-descriptions-item label="二手车销售">
            {{ clientBusinessMessageDto.secondHandCarSales }}
          </el-descriptions-item>
          <el-descriptions-item label="电池回收">
            {{ clientBusinessMessageDto.batteryRecycling }}
          </el-descriptions-item>
          <el-descriptions-item> </el-descriptions-item>
        </el-descriptions>
        <div class="description_word">事故信息</div>
        <div class="descriptions-title">
          <p>出险推送方式</p>
        </div>
        <el-descriptions
          :column="3"
          border
          labelClassName="table-label"
          :contentStyle="{ verticalAlign: 'middle' }"
          :labelStyle="{ 'text-align': 'left !important' }"
          style="margin-bottom: 16px"
        >
          <el-descriptions-item>
            <template slot="label">
              <el-tooltip effect="light" placement="bottom">
                <div slot="content" class="rows-tip">
                  事故信息保险公司推送接收人号码
                </div>
                <i class="iconfont grow-icon_doubt" style="color: #9a9a9a"></i>
              </el-tooltip>
              信息接收人
            </template>
            {{ clientBusinessMessageDto.messageReceivingNumber }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <el-tooltip effect="light" placement="bottom">
                <div slot="content" class="rows-tip">
                  事故消息保险公司推送格悦的方式
                </div>
                <i class="iconfont grow-icon_doubt" style="color: #9a9a9a"></i>
              </el-tooltip>
              保司推送方式
            </template>
            {{ clientBusinessMessageDto.insuranceCompanyPush }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <el-tooltip effect="light" placement="bottom">
                <div slot="content" class="rows-tip">
                  事故信息车队推送格悦的方式
                </div>
                <i class="iconfont grow-icon_doubt" style="color: #9a9a9a"></i>
              </el-tooltip>
              车队推送方式
            </template>
            {{ clientBusinessMessageDto.fleetPush }}
          </el-descriptions-item>
        </el-descriptions>
        <div class="descriptions-title">
          <p>车队与司机的合同约定</p>
        </div>
        <el-descriptions
          :column="2"
          border
          labelClassName="table-label"
          :contentStyle="{ verticalAlign: 'middle' }"
          :labelStyle="{ 'text-align': 'left !important' }"
        >
          <el-descriptions-item
            :labelStyle="{ width: '11.5%', 'text-align': 'left !important' }"
          >
            <template slot="label">
              <el-tooltip effect="light" placement="bottom">
                <div slot="content" class="rows-tip">
                  与司机合同约定有责事故联系方式
                </div>
                <i class="iconfont grow-icon_doubt" style="color: #9a9a9a"></i>
              </el-tooltip>
              有责事故约定
            </template>
            {{ clientBusinessMessageDto.responsibleAccidentAgreement }}
          </el-descriptions-item>
          <el-descriptions-item
            :labelStyle="{ width: '11.5%', 'text-align': 'left !important' }"
          >
            <template slot="label">
              <el-tooltip effect="light" placement="bottom">
                <div slot="content" class="rows-tip">
                  与司机合同约定无责事故联系方式
                </div>
                <i class="iconfont grow-icon_doubt" style="color: #9a9a9a"></i>
              </el-tooltip>
              无责事故约定
            </template>
            {{ clientBusinessMessageDto.noResponsibleAccidentAgreement }}
          </el-descriptions-item>
        </el-descriptions>
      </el-tab-pane>
      <el-tab-pane v-auth="337" label="消费记录" name="four">
        <customer-record :clientId="this.$route.query.id"></customer-record
      ></el-tab-pane>
    </el-tabs>

    <template slot="footer">
      <div class="flex-x-end foot-btn">
        <el-button
          v-auth="62"
          type="primary"
          @click="showAddCusterDialog = true"
          >编辑客户</el-button
        >
        <el-dropdown
          v-auth="335"
          size="small"
          trigger="click"
          placement="top"
          v-if="agreementTable.length == 0"
        >
          <el-button>
            更多操作<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown" style="width: 110px">
            <el-dropdown-item
              icon="el-icon-delete"
              @click.native="del"
              class="btn-dropdown"
              >删除</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </template>
    <add-customer-dialog
      v-if="showAddCusterDialog"
      :showAddCusterDialog.sync="showAddCusterDialog"
      :id="$route.query.id"
      @update="addUpdate"
    ></add-customer-dialog>
    <!-- 客户指令 -->
    <client-command-drawer
      v-if="clientCommandDrawerVisibility"
      :visibility.sync="clientCommandDrawerVisibility"
      :clientId="Number($route.query.id)"
      :defaultActiveName="selfPaying"
    >
    </client-command-drawer>
  </base-container>
</template>

<script>
import { previewImage as previewImageApi } from '@/utils/fileApi';
import customerRecord from '@/views/customerManagement/customerRecord.vue';
import {
  getCustomerDetail,
  delCuster,
  getClientBusinessMessage,
  getClientCarMessageBySection,
  getCarBrandByClientId,
} from '@/api/customer/customer';
import UploadFile from '@/components/uploadFile.vue';
import { mapGetters, mapMutations } from 'vuex';
import { getAgreementStore } from '@/api/customer/agreement';
import addCustomerDialog from '@/views/customerManagement/addCustomer.vue';
import { levelIcons, levelIconsWord } from '@/const/client';
import { clientLevel } from '@/service/dict/dictData';
import clientCommandDrawer from '@/components/businessComponent/clientCommandDrawer';
import * as echarts from 'echarts';
import { verifyAuth } from '@/utils/permissions';
import { hasAuth } from '@/utils/permissions';

export default {
  name: 'customerDetails',
  components: {
    customerRecord,
    UploadFile,
    addCustomerDialog,
    clientCommandDrawer,
  },
  data() {
    return {
      showAddCusterDialog: false,
      activeName: 'first',
      clientCarDetails: {},
      // 客户的信息展示数据
      detailData: {
        id: '',
        clientName: '',
        clientPhone: '',
        clientGenderAndType: '',
        clientLevel: '',
        clientIdCard: '',
        birthday: '',
        clientSource: '',
        sourceClient: '',
        address: '',
        remark: '',
        clientUnit: '',
        picClientLists: {},
      },
      // 客户其他信息的表格数据
      agreementTable: [],

      // 适用门店
      applyStores: {},
      //有效协议
      agreementClientTotal: [],
      //客户级别
      clientLevel: clientLevel,
      // 客户等级对应图标
      levelIcons,
      levelIconsWord,
      //业务信息
      clientBusinessMessageDto: {},
      //里程区间表
      clientCarMileageSectionVo: [],
      //运营年限表
      clientCarOperatingLifeSectionVo: [],
      //车辆规模、进店数据
      carScale: [],
      //车辆品牌图表
      vehicleBrandChart: null,
      // 服务品牌数据
      vehicleBrandData: [{}, {}, {}, {}],
      //客户指令抽屉可见性
      clientCommandDrawerVisibility: false,
      otherContactsLength: '',
      selfPaying: 'selfPaying',
      redundant: '',
      carTotal: '',
      LS: {
        'word-break': 'keep-all',
      },
      CS: {
        'max-width': '300px',
        'word-break': 'break-all',
      },
    };
  },
  activated() {
    this.getData();
    this.authorityControl();
  },
  computed: {
    ...mapGetters(['activeTagIndex']),
  },
  methods: {
    ...mapMutations({
      closeTag: 'DEL_TAG',
    }),
    handleClick(tab, event) {
      console.log(tab.label, event);
      if (tab.label == '车辆情况') {
        this.getCarData();
        this.getVehicleBrandData();
      } else if (tab.label == '业务信息') {
        this.getClientData();
      }
    },
    /**
     * 查询客户详情
     */
    getData() {
      this.detailData.otherContacts = [];
      getCustomerDetail(this.$route.query.id).then((res) => {
        this.detailData = res;
        this.agreementTable = res.agreementClient;
        //有效协议的数量
        this.agreementClientTotal = res.agreementClient.filter(
          (item) => item.agreementState == '正常有效'
        );
        //判断是否存在其他联系人
        this.otherContactsLength = this.detailData.otherContacts.length;
        this.redundant;
        if (this.otherContactsLength == 1) {
          this.redundant = 3;
          return;
        } else if (
          this.otherContactsLength % 2 == 0 &&
          this.otherContactsLength % 3 == 0
        ) {
          this.redundant = 0;
          return;
        } else if (
          this.otherContactsLength % 2 != 0 &&
          this.otherContactsLength % 3 != 0
        ) {
          this.redundant = 2;
          return;
        } else if (this.otherContactsLength % 3 == 0) {
          this.redundant = 0;
          return;
        } else if (this.otherContactsLength % 2 == 0) {
          this.redundant = 1;
          return;
        }
      });
    },
    authorityControl() {
      if (hasAuth(576)) {
        this.activeName = 'first';
      } else if (hasAuth(577) && !hasAuth(576)) {
        this.activeName = 'second';
        this.getCarData();
        this.getVehicleBrandData();
      } else if (hasAuth(578) && !hasAuth(576) && !hasAuth(577)) {
        this.activeName = 'third';
        this.getClientData();
      } else if (!hasAuth(578) && !hasAuth(576) && !hasAuth(577)) {
        this.activeName = 'four';
      }
    },
    //查询客户业务信息
    getClientData() {
      getClientBusinessMessage(this.$route.query.id).then((res) => {
        this.clientBusinessMessageDto = res;
      });
    },
    //查询客户车辆区间信息
    getCarData() {
      getClientCarMessageBySection(this.$route.query.id).then((res) => {
        let {
          clientCarMileageSectionVo,
          clientCarOperatingLifeSectionVo,
          ...otherData
        } = res;
        console.log('###', otherData);
        this.clientCarMileageSectionVo = [
          {
            fiveSectionCarNum: clientCarMileageSectionVo.fiveSectionCarNum,
            fourSectionCarNum: clientCarMileageSectionVo.fourSectionCarNum,
            oneSectionCarNum: clientCarMileageSectionVo.oneSectionCarNum,
            sevenSectionCarNum: clientCarMileageSectionVo.sevenSectionCarNum,
            sixSectionCarNum: clientCarMileageSectionVo.sixSectionCarNum,
            threeSectionCarNum: clientCarMileageSectionVo.threeSectionCarNum,
            twoSectionCarNum: clientCarMileageSectionVo.twoSectionCarNum,
            unknownSectionCarNum:
              clientCarMileageSectionVo.unknownSectionCarNum,
          },
        ];
        this.clientCarOperatingLifeSectionVo = [
          {
            oneSectionCarNum: clientCarOperatingLifeSectionVo.oneSectionCarNum,
            twoSectionCarNum: clientCarOperatingLifeSectionVo.twoSectionCarNum,
            threeSectionCarNum:
              clientCarOperatingLifeSectionVo.threeSectionCarNum,
            fourSectionCarNum:
              clientCarOperatingLifeSectionVo.fourSectionCarNum,
            fiveSectionCarNum:
              clientCarOperatingLifeSectionVo.fiveSectionCarNum,
            sixSectionCarNum: clientCarOperatingLifeSectionVo.sixSectionCarNum,
            sevenSectionCarNum:
              clientCarOperatingLifeSectionVo.sevenSectionCarNum,
            unknownSectionCarNum:
              clientCarOperatingLifeSectionVo.unknownSectionCarNum,
          },
        ];
        // this.carScale = otherData;
        //合作台数率
        if (otherData.cooperationNumber != 0 && otherData.vehicleScale != 0) {
          var cooperationRate =
            Math.round(
              (otherData.cooperationNumber / otherData.vehicleScale) * 100
            ) + '%';
        } else {
          cooperationRate = 0;
        }
        //实际运营率
        if (otherData.actualVehicle != 0 && otherData.cooperationNumber != 0) {
          var operatingRate =
            Math.round(
              (otherData.actualVehicle / otherData.cooperationNumber) * 100
            ) + '%';
        } else {
          operatingRate = 0;
        }
        //实际进店率
        if (otherData.actualShop != 0 && otherData.cooperationNumber != 0) {
          var admissionRate =
            Math.round(
              (otherData.actualShop / otherData.cooperationNumber) * 100
            ) + '%';
        } else {
          admissionRate = 0;
        }
        this.carScale = [
          {
            cooperationRate: cooperationRate,
            operatingRate: operatingRate,
            admissionRate: admissionRate,
            vehicleScale: otherData.vehicleScale,
            cooperationNumber: otherData.cooperationNumber,
            actualVehicle: otherData.actualVehicle,
            actualShop: otherData.actualShop,
          },
        ];
      });
    },

    // 点击查看更多车辆按钮事件跳转到车辆档案
    addCar() {
      this.$router.push({
        name: 'carList',
        params: {
          form: {
            clientMsg: this.detailData.clientName,
          },
        },
      });
    },
    // 客户信息里的图片展示
    previewImage(imgs, index) {
      previewImageApi({
        images: imgs.map((item) => item.picUrlName),
        options: {
          initialViewIndex: index,
        },
      });
    },
    del() {
      var title = '';
      if (this.carTotal != 0) {
        title = '客户已绑定车辆，确定要解除绑定关系并删除该客户?';
      } else {
        title = '确认要删除该客户?';
      }
      this.$confirm(title, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        delCuster({ id: this.$route.query.id }).then((res) => {
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: '删除成功!',
            });
            this.closeTag({ index: this.activeTagIndex });
          }
        });
      });
    },

    //客户其他信息里协议编号跳转协议详情
    async goAgreementDetail(id) {
      await verifyAuth(474);
      this.$router.push({
        name: 'agreementDetail',
        query: { id: id },
      });
    },
    //跳转到车辆档案详情
    goCarDetail(row) {
      this.$router.push({
        name: 'carDetail',
        // path?: string
        query: { id: row.carId },
      });
    },
    /**
     * 列表中适用门店数据请求
     */
    getStoreList(id) {
      this.applyStores = '';
      getAgreementStore(id).then((res) => {
        this.applyStores = res;
      });
    },
    // 过期协议跳转到客户协议列表
    goAgreementList() {
      this.$router.push({
        name: 'agreementList',
        params: {
          nodeKey: 3,
          form: {
            phoneOrClientName: this.detailData.clientName,
          },
        },
      });
    },
    //获取车辆品牌环形数据
    getVehicleBrandData() {
      getCarBrandByClientId(this.$route.query.id).then((res) => {
        var colors = ['#576be8', '#ee8f33', '#1e86eb', '#33ab79'];
        var data = (this.vehicleBrandData = res.map((item, index) =>
          Object.assign(
            {
              value: item.carNum,
              name: item.carLogoName,
            },
            { label: { color: colors[index] } }
          )
        ));
        this.vehicleBrandChart = echarts.init(
          document.getElementById('vehicle-brand-chart')
        );
        //总数量
        this.carTotal = this.vehicleBrandData.reduce((prev, item) => {
          return prev + +item.value || 0;
        }, 0);
        var option = {
          color: colors,
          legend: {
            top: '10%',
            left: 'center',
          },
          tooltip: {
            trigger: 'item',
          },
          title: {
            text: this.carTotal + '台',
            top: '160px',
            left: '49%',
            textAlign: 'center',
            textStyle: {
              color: '#292a2b',
              fontSize: '16px',
              lineHeight: 30,
            },
          },
          series: [
            {
              type: 'pie',
              radius: [50, 70],
              center: ['50%', '60%'],
              label: {
                show: true,
                position: 'outside',
                formatter: (params) => {
                  return `${params.value}`;
                },
                fontSize: '0.8rem',
                // fontFamily: 'MiSans',
              },
              data,
            },
          ],
        };
        this.vehicleBrandChart.setOption(option);
        this.vehicleBrandChart.resize();
      });
    },
    //编辑客户保存成功之后刷新
    addUpdate() {
      this.getData();
      this.getClientData();
      this.getCarData();
      this.getVehicleBrandData();
      this.getCarData();
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  margin: 0 0 60px 0;
}
.foot-btn {
  padding: 10px 20px;
  box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.1);
  .el-dropdown {
    margin-left: 24px;
  }
  .el-dropdown-item ::v-deep .el-dropdown-menu {
    padding: 0;
  }
}
.active {
  color: $primary;
}
.description_word {
  font-size: 16px;
  color: #333333;
  margin-bottom: 15px;
  margin-top: 30px;
}
::v-deep .el-table thead {
  color: #000000 !important;
}
.imgs {
  display: flex;
  > img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    & + img {
      margin-left: 10px;
    }
  }
}
.btn-dropdown:focus,
.btn-dropdown:hover {
  color: #606266;
  background: #f5f7fa;
  border-color: #f5f7fa;
}

//改版之后客户档案的样式-需求61
::v-deep .el-tabs__nav-wrap::after {
  width: 0% !important;
}
.client_name {
  background: #f5f7fa;
  border-radius: 4px;
  width: 100%;
  padding: 20px 0px;
  margin-bottom: 24px;
  .client_remark {
    margin: 12px 0 0 18px;
    color: #999;
  }
}
.chart-style {
  max-width: 450px;
  height: 300px;
  #vehicle-brand-chart {
    width: 400px;
    height: 100%;
  }
}
.descriptions-title {
  height: 49px;
  background: #f5f7fa;
  color: #666;
  font-size: 14px;
  line-height: 49px;
  border: 1px solid #ebeef5;
  border-bottom: 0px;
  p {
    padding-left: 10px;
  }
}
</style>
