var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visibility,
        width: "800px",
        "before-close": _vm.handleClose,
        "close-on-click-modal": false,
        "append-to-body": "",
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.save },
                },
                [_vm._v(" 确 定 ")]
              ),
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.handleClose } },
                [_vm._v(" 取 消 ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticStyle: { "margin-top": "18px" },
          attrs: {
            size: "small",
            model: _vm.editForm,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex", staticStyle: { "margin-bottom": "30px" } },
            [
              _c("i", { staticClass: "el-icon-warning orange el-icon--left" }),
              _c("p", { staticStyle: { "font-size": "14px" } }, [
                _vm._v("点击确定将定损单退回上一个状态"),
              ]),
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "跟进人", prop: "followUpPeople" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    clearable: "",
                    remote: "",
                    placeholder: "搜索姓名、手机号",
                    "remote-method": _vm.searchAllUser,
                    loading: _vm.searchAllUserLoading,
                  },
                  model: {
                    value: _vm.editForm.followUpPeople,
                    callback: function ($$v) {
                      _vm.$set(_vm.editForm, "followUpPeople", $$v)
                    },
                    expression: "editForm.followUpPeople",
                  },
                },
                _vm._l(_vm.allUserList, function (item) {
                  return _c("el-option", {
                    key: item.userId,
                    attrs: { label: item.realName, value: item.userId },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "跟进进展", prop: "followUpState" } },
            [
              _c(
                "el-radio-group",
                {
                  staticStyle: { "line-height": "1.4rem" },
                  model: {
                    value: _vm.editForm.followUpState,
                    callback: function ($$v) {
                      _vm.$set(_vm.editForm, "followUpState", $$v)
                    },
                    expression: "editForm.followUpState",
                  },
                },
                _vm._l(_vm.followUpStateList, function (option) {
                  return _c(
                    "el-radio",
                    {
                      key: option.value,
                      attrs: { label: option.value },
                      nativeOn: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.handleClickFollowUpState(option.value)
                        },
                      },
                    },
                    [
                      _c("div", { style: option.iconStyle }, [
                        _vm._v(_vm._s(option.iconText)),
                      ]),
                      _c("span", { staticStyle: { "margin-left": "5px" } }, [
                        _vm._v(_vm._s(option.label)),
                      ]),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "当次留言", prop: "message" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  size: "small",
                  rows: 2,
                  maxlength: "300",
                  "show-word-limit": "",
                  placeholder: "请输入当次留言，最多300个字符",
                },
                model: {
                  value: _vm.editForm.message,
                  callback: function ($$v) {
                    _vm.$set(_vm.editForm, "message", $$v)
                  },
                  expression: "editForm.message",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }