var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.showSearch
      ? _c("div", { staticClass: "flex-x-between" }, [
          _c(
            "div",
            { staticClass: "flex-y-center" },
            [
              _c(
                "div",
                { staticStyle: { "flex-shrink": "0", "margin-right": "10px" } },
                [_vm._v("服务项目")]
              ),
              _c("el-input", {
                attrs: {
                  size: "small",
                  placeholder: "搜索服务名称",
                  clearable: "",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.getData.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.serviceNameKeyword,
                  callback: function ($$v) {
                    _vm.serviceNameKeyword = $$v
                  },
                  expression: "serviceNameKeyword",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.getData },
                },
                [_vm._v("查询")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.serviceNameKeyword = ""
                    },
                  },
                },
                [_vm._v("置空")]
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm.showTips
      ? _c(
          "div",
          {
            staticStyle: {
              margin: "20px 0 14px 0",
              color: "#999",
              "font-size": "14px",
            },
          },
          [_c("p", [_vm._v("项目折扣说明：1、仅适用自费、包干业务报价；")])]
        )
      : _vm._e(),
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "left" }, [
        _c(
          "ul",
          {
            staticClass: "car-brand-tree_ul",
            staticStyle: { "margin-top": "16px" },
          },
          [
            _c("div", { staticClass: "title" }, [_vm._v("品牌车型")]),
            _vm._l(_vm.treeData, function (lv1, index) {
              return _c(
                "li",
                { key: index, staticClass: "car-brand-item_li" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "car-brand-name_div",
                      class: {
                        "has-children": lv1.series.length,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleLv1Click(lv1)
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticStyle: { "margin-right": "5px" },
                        attrs: { src: lv1.logoImage },
                      }),
                      _c("span", { staticClass: "car-brand-name_text" }, [
                        _vm._v(
                          " " +
                            _vm._s(lv1.logoName) +
                            " (" +
                            _vm._s(lv1.series.length) +
                            ") "
                        ),
                      ]),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: lv1.series,
                            expression: "lv1.series",
                          },
                        ],
                        staticClass:
                          "item-expand_icon newicon grow-icon-arrow-right",
                        class: { active: lv1.showChild },
                      }),
                    ]
                  ),
                  _c(
                    "ul",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: lv1.showChild,
                          expression: "lv1.showChild",
                        },
                      ],
                      staticClass: "tree-item_ul",
                    },
                    _vm._l(lv1.series, function (lv2, index) {
                      return _c(
                        "li",
                        { key: index, staticClass: "tree-item_li" },
                        [
                          _c(
                            "div",
                            {
                              class: {
                                "tree-item-name_div": true,
                                active: _vm.lv2ActiveId == lv2.seriesId,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleLv2Click(lv2)
                                },
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(" " + _vm._s(lv2.seriesName) + " "),
                              ]),
                              _c("i", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.$lodash.get(
                                      lv2.modelNumbers,
                                      "length",
                                      0
                                    ),
                                    expression:
                                      "$lodash.get(lv2.modelNumbers, 'length', 0)",
                                  },
                                ],
                                staticClass:
                                  "item-expand_icon newicon grow-icon-arrow-right",
                                class: { active: lv2.showChild },
                              }),
                            ]
                          ),
                          _c(
                            "ul",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: lv2.showChild,
                                  expression: "lv2.showChild",
                                },
                              ],
                              staticClass: "tree-item_ul",
                            },
                            _vm._l(lv2.modelNumbers, function (lv3, index) {
                              return _c(
                                "li",
                                { key: index, staticClass: "tree-item_li" },
                                [
                                  _c(
                                    "div",
                                    {
                                      class: {
                                        "tree-item-name_div": true,
                                        active:
                                          _vm.lv3ActiveId == lv3.modelNumberId,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleLv3Click(lv3)
                                        },
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(lv3.modelNumberName) +
                                            " "
                                        ),
                                      ]),
                                      _c("i", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.$lodash.get(
                                              lv3.modelYears,
                                              "length",
                                              0
                                            ),
                                            expression:
                                              "$lodash.get(lv3.modelYears, 'length', 0)",
                                          },
                                        ],
                                        staticClass:
                                          "item-expand_icon newicon grow-icon-arrow-right",
                                        class: { active: lv3.showChild },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "ul",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: lv3.showChild,
                                          expression: "lv3.showChild",
                                        },
                                      ],
                                      staticClass: "tree-item_ul",
                                    },
                                    _vm._l(
                                      lv3.modelYears,
                                      function (lv4, index) {
                                        return _c(
                                          "li",
                                          {
                                            key: index,
                                            staticClass: "tree-item_li",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                class: {
                                                  "tree-item-name_div": true,
                                                  "last-level": true,
                                                  active:
                                                    _vm.lv4ActiveId ==
                                                    lv4.modelYearId,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleLv4Click(
                                                      lv4
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(lv4.modelYearName) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              )
            }),
          ],
          2
        ),
      ]),
      _c("div", { staticClass: "right" }, [
        _c(
          "div",
          { staticClass: "right-content" },
          [
            _c(
              "el-table",
              {
                staticStyle: { height: "50vh" },
                attrs: { data: _vm.listData, border: "", "max-height": "80vh" },
              },
              [
                _c(
                  "el-table-column",
                  { attrs: { label: _vm.tableTitle, align: "center" } },
                  [
                    _c("el-table-column", {
                      attrs: { type: "index", label: "序号", width: "60" },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        "min-width": "125",
                        label: "服务项目",
                        prop: "serviceName",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "100",
                        label: "服务时效（H）",
                        prop: "serviceTime",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "100",
                        label: "工时价（元）",
                        prop: "agreePrice",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        "min-width": "170",
                        label: "商品标准名",
                        prop: "standardName",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        "min-width": "100",
                        label: "原厂编码",
                        prop: "goodsCode",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "60",
                        label: "数量",
                        prop: "num",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "60",
                        label: "单位",
                        prop: "unit",
                        align: "center",
                      },
                    }),
                    _c(
                      "el-table-column",
                      {
                        attrs: {
                          width: "200",
                          label: "商品价（元）",
                          align: "center",
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            width: "100",
                            label: "品牌",
                            prop: "brandPrice",
                            align: "center",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            width: "100",
                            label: "原厂",
                            prop: "factoryPrice",
                            align: "center",
                          },
                        }),
                        _vm.showOfficialCost
                          ? _c("el-table-column", {
                              attrs: {
                                width: "100",
                                label: "正厂",
                                prop: "officialPrice",
                                align: "center",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "el-table-column",
                      {
                        attrs: {
                          width: "200",
                          label: "结算价（服务+商品）",
                          align: "center",
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            width: "100",
                            label: "品牌",
                            prop: "brandCheckPrice",
                            align: "center",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            width: "100",
                            label: "原厂",
                            prop: "factoryCheckPrice",
                            align: "center",
                          },
                        }),
                        _vm.showOfficialCost
                          ? _c("el-table-column", {
                              attrs: {
                                width: "100",
                                label: "正厂",
                                prop: "officialCheckPrice",
                                align: "center",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { attrs: { id: "watermarkBox" } }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }