import http from '@/service/http';

const defaultListOption = {
  current: 1,
  size: 10,
};

// 新增、编辑 指南素材 上下架
export function addAndEditMaterial(data, config = {}) {
  return http.post('/admin/wzGuideInfo/addAndEditMaterial', data, config);
}

// 指南草稿箱列表
export function wzGuideInfoDrafts(data = {}) {
  return http.post('/admin/wzGuideInfo/drafts', data);
}

// 指南素材列表
export function getMaterialPage(data = {}) {
  return http.post('/admin/wzGuideInfo/getMaterialPage', data);
}

// 指南素材详情
export function getMaterialInfo(id) {
  return http.post('/admin/wzGuideInfo/getMaterialInfo', { guideId: id });
}

// 删除指南素材
export function deleteMaterial(id, config = { onlyReturnData: false }) {
  return http.post(
    '/admin/wzGuideInfo/deleteMaterial',
    { guideId: id },
    config
  );
}

// 获取问诊系统选项列表
export function getWzGuideSystemList() {
  return http.post('/admin/wzGuideInfo/sysList', {});
}

// 检索车型
export function newSearchCarModel(key = '') {
  return http.post(
    '/admin/car/queryCarSeriesTreeBySearch',
    {
      key,
      current: 1,
      size: 100,
    },
    { noShowWaiting: true }
  );
}
