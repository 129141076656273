var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "温馨提示",
            visible: _vm.delectDialogVisible,
            width: "30%",
            "before-close": _vm.handleClose,
          },
        },
        [
          _c("div", { staticClass: "content" }, [
            _c("span", [_vm._v("确定要删除选中的业务来源？")]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.commit } },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }