var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { ref: "baseContainer", attrs: { paddingTop: "" } },
    [
      [
        _c("div", { staticClass: "mainTitle" }, [
          _vm._v("门店未结算单据汇总 截至" + _vm._s(_vm.statDayTime) + "(含)"),
        ]),
      ],
      [
        _c(
          "div",
          { staticStyle: { display: "flex", "justify-content": "end" } },
          [
            _c("div"),
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 761,
                    expression: "761",
                  },
                ],
                staticClass: "blue",
                attrs: { size: "small", type: "text" },
                on: {
                  click: function ($event) {
                    return _vm.downloadAllRecords()
                  },
                },
              },
              [
                _vm._v("导出汇总"),
                _c("i", {
                  staticClass: "iconfont grow-icon_daochu el-icon--right",
                }),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "flex h100" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "fixedScrollBar",
                    rawName: "v-fixedScrollBar",
                    value: _vm.$refs.baseContainer,
                    expression: "$refs.baseContainer",
                  },
                ],
                staticClass: "custom-table",
                attrs: {
                  data: _vm.tableData,
                  "show-summary": "",
                  "summary-method": _vm.getSummaries,
                  border: "",
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    type: "index",
                    label: "序号",
                    width: "80",
                    "header-align": "center",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "210",
                    label: "操作门店",
                    prop: "companyName",
                    "header-align": "center",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "180",
                    label: "理赔",
                    prop: "claimSettlementCount",
                    "header-align": "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "blue pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.viewDetail(row, 0)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(row.claimSettlementCount) + " "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "180",
                    label: "索赔",
                    prop: "claimForCompensationCount",
                    "header-align": "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "blue pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.viewDetail(row, 1)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(row.claimForCompensationCount) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c(
                  "el-table-column",
                  {
                    attrs: {
                      width: "150",
                      label: "客户单据",
                      "header-align": "center",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        width: "180",
                        label: "自费在修单据",
                        prop: "selfPayingRepairingCount",
                        "header-align": "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "blue pointer",
                                  on: {
                                    click: function ($event) {
                                      return _vm.viewDetail(row, 2)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(row.selfPayingRepairingCount) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "180",
                        label: "正常时效单据",
                        prop: "selfPayingNormalValidCount",
                        "header-align": "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "blue pointer",
                                  on: {
                                    click: function ($event) {
                                      return _vm.viewDetail(row, 3)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(row.selfPayingNormalValidCount) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "180",
                        label: "滞留单据",
                        prop: "selfPayingStrandedCount",
                        "header-align": "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "warnBillCount pointer",
                                  on: {
                                    click: function ($event) {
                                      return _vm.viewDetail(row, 4)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(row.selfPayingStrandedCount) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "180",
                        label: "合计",
                        prop: "selfPayingAllCount",
                        "header-align": "center",
                      },
                    }),
                  ],
                  1
                ),
                _c("el-table-column", {
                  attrs: {
                    width: "200",
                    label: "未结算单据总计",
                    prop: "unSettledCount",
                    "header-align": "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c("span", { staticClass: "warnBillCount" }, [
                            _vm._v(" " + _vm._s(row.unSettledCount) + " "),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c(
                  "el-table-column",
                  {
                    attrs: {
                      width: "150",
                      label: "数据对比",
                      "header-align": "center",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        width: "180",
                        label: "日均建单量",
                        prop: "dailyAvgEntryCount",
                        "header-align": "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "180",
                        label: "未结算率",
                        prop: "unSettledRate",
                        "header-align": "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _vm._v(" " + _vm._s(row.unSettledRate) + "% "),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("el-table-column", {
                  attrs: {
                    width: "200",
                    label: "滞留车台次",
                    prop: "carStrandedCount",
                    "header-align": "center",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "200",
                    label: "说明",
                    prop: "remark",
                    "header-align": "center",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }