<template>
  <div>
    <el-dialog
      title="施工派工"
      width="50%"
      :visible="visibility"
      :before-close="handleClose"
      :close-on-click-modal="false"
      append-to-body
    >
      <div class="update-fix-items_box">
        <div class="bill-info">
          <div class="row flex-x-between">
            <div class="col flex">
              <div class="label">接车单：</div>
              <div class="value">
                <span
                  class="blue pointer"
                  @click="goCarBillDetail(detailData.billId)"
                  >{{ detailData.billCode }}</span
                >
                <span v-if="detailData.isOthersSelfPayState" class="color999">
                  (他人自费)
                </span>
              </div>
            </div>
            <div class="col flex">
              <div class="label">预交车时间：</div>
              <div class="value">
                <span v-if="detailData.planFinishTime"
                  >{{ detailData.planFinishTime }}
                </span>
                <span v-if="detailData.waitInStore" class="color999"
                  >( 在店等)
                </span>
                <span v-if="detailData.undetermined"> 待定 </span>
              </div>
            </div>
          </div>
          <div style="margin-top: 20px" class="row flex-x-between">
            <div class="col flex">
              <div class="label">车牌号：</div>
              <div class="value">
                <span>{{ detailData.carNumber }}</span>
                <span v-if="detailData.carType !== undefined">
                  ({{ detailData.vehicleType }})
                </span>
              </div>
            </div>
            <div class="col flex">
              <div class="label">车型/车架号：</div>
              <div class="value">
                <span>{{ detailData.carModel }}</span>
                <span style="display: block; margin-top: 10px">
                  VIN: {{ detailData.uniqueId }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="flex">
          请设置服务项目的施工技师（班组），并确认派工
          <p style="margin-left: 15px; font-size: 13px; color: #999">
            <i class="el-icon-warning orange"></i
            >注：操作成功，有【施工派工】通知推送给项目的施工技师
          </p>
        </div>
        <el-table :data="taskList" style="width: 100%; margin: 10px 0" border>
          <el-table-column type="index" label="序号" width="60">
          </el-table-column>
          <el-table-column label="项目名称" width="200" prop="serviceItemName">
          </el-table-column>
          <el-table-column label="项目编码" width="120" prop="serviceItemCode">
          </el-table-column>
          <el-table-column label="内部标签" width="140" prop="tags">
          </el-table-column>
          <el-table-column label="施工技师">
            <template slot="header">
              <span>施工技师</span>
              <i
                style="font-size: 14px"
                class="iconfont grow-a-icon_fillout color666 el-icon--right pointer"
                @click="showSelectTechniciansDialogForAllItem"
              ></i>
            </template>
            <template #default="{ row, $index }">
              <div :key="cellKey">
                <span v-if="row.technicianList">
                  {{
                    row.technicianList.map((item) => item.realName).join('、')
                  }}
                </span>
                <span v-if="row.isConstructionFree">免施工</span>
                <span
                  v-if="!row.technicianList.length && !row.isConstructionFree"
                  class="blue pointer"
                  @click="showSetDialog($index)"
                  >未设置，点击设置</span
                >
                <i
                  v-else
                  class="iconfont grow-a-icon_fillout color666 el-icon--right pointer"
                  @click="showSetDialog($index)"
                ></i>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer">
        <el-button size="small" @click="handleClose">取 消</el-button>
        <el-button size="small" type="primary" @click="handleConfirm"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 选择技师弹窗 -->
    <select-technicians-dialog
      v-if="selectTechniciansDialogVisibility"
      :visibility.sync="selectTechniciansDialogVisibility"
      @select="handleSelect"
    ></select-technicians-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import {
  getForDispatchDetailsById,
  submitForDispatch,
  constructionReassignment,
} from '@/api/carBill';
import selectTechniciansDialog from '@/components/businessComponent/selectTechniciansDialog.vue';
export default {
  name: 'dispatchingDialog',
  components: {
    selectTechniciansDialog,
  },
  props: {
    visibility: { type: Boolean, default: false },
    id: {
      type: [Number, String],
    },
    dispatchType: {
      type: [Number, String],
    },
    billData: {
      type: Object,
      default: () => ({}),
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      detailData: {},

      // 列表
      taskList: [],

      // 正在编辑下标
      editIndex: 0,

      // 选择技师弹窗可见性
      selectTechniciansDialogVisibility: false,

      cellKey: 0,
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.companyId,
      userId: (state) => state.user.userInfo?.userId,
    }),
  },
  created() {
    if (!this.list.length) {
      this.getData();
    } else {
      this.detailData = this.billData;
      this.taskList = this.list.map((item) => {
        item.technicianList = [];
        return item;
      });
    }
  },
  methods: {
    /**
     * getData()
     */
    async getData() {
      let res = await getForDispatchDetailsById({
        id: this.id,
        dispatchType: this.dispatchType,
      });
      if (res?.taskBillServiceItems?.length) {
        res.taskBillServiceItems.forEach((item) => {
          item.technicianList = [];
        });
      }
      let { taskBillServiceItems = [], ...data } = res;
      this.taskList = taskBillServiceItems;
      this.detailData = data;
    },
    /**
     * 显示选择技师弹窗
     */
    showSelectTechniciansDialogForAllItem() {
      this.editIndex = undefined;
      this.selectTechniciansDialogVisibility = true;
    },
    /**
     * 显示选择技师弹窗
     */
    showSetDialog(index) {
      this.editIndex = index;
      this.selectTechniciansDialogVisibility = true;
    },
    /**
     * 选择技师
     */
    handleSelect(res) {
      if (this.editIndex === undefined) {
        if (!res) {
          this.taskList.forEach((row) => {
            row.isConstructionFree = 1;
            row.technicianList = [];
            row.technicianIds = [];
          });
        } else {
          this.taskList.forEach((row) => {
            row.isConstructionFree = 0;
            row.technicianList = res;
            row.technicianIds = res.map((item) => item.userId);
          });
        }
      } else {
        let row = this.taskList[this.editIndex];
        if (!res) {
          row.isConstructionFree = 1;
          row.technicianList = [];
          row.technicianIds = [];
        } else if (Array.isArray(res)) {
          row.isConstructionFree = 0;
          row.technicianList = res;
          row.technicianIds = res.map((item) => item.userId);
        }
      }
      this.cellKey = Math.random();
    },
    /**
     * 确认派工
     */
    handleConfirm() {
      let tasks = this.taskList.filter(
        (item) => item.isConstructionFree || item.technicianIds?.length
      );
      let forDispatchSubmitServices = tasks.map((item) => ({
        isConstructionFree: item.isConstructionFree,
        billServiceId: item.billServiceId,
        taskBillId: item.taskBillId,
        technicianIds: item.technicianIds,
      }));
      if (!this.list.length) {
        let body = {
          companyId: this.companyId,
          operateType: 0, //操作类型(0,1 >>> 派工,领工)
          dispatchBillId: this.id,
          forDispatchSubmitServices,
        };
        submitForDispatch(body).then(() => {
          this.$emit('finish');
          this.$message.success('派工成功！');
          this.handleClose();
        });
      } else {
        //判断是否有选择施工技师
        if (forDispatchSubmitServices.length == 0) {
          return this.$message.warning('未选择施工技师');
        }
        // 改派
        let body = {
          companyId: this.companyId,
          dispatchBillId: this.id,
          dispatchConstructionReassignmentServiceDto: forDispatchSubmitServices,
        };
        constructionReassignment(body).then(() => {
          this.$emit('finish');
          this.$message.success('操作成功！');
          this.handleClose();
        });
      }
    },
    /**
     * 关闭弹窗
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
    /**
     * 跳转详情页
     */
    goCarBillDetail(id) {
      this.handleClose();
      this.$router.push({
        name: 'carBillDetail',
        query: {
          id,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.update-fix-items_box {
  .bill-info {
    margin: 30px 30px 60px;
    .row {
      color: #333;
      .col {
        width: 50%;
        .label {
          flex-shrink: 0;
        }
        // .value {
        // }
      }
    }
  }
}
</style>
