var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-drawer",
    {
      attrs: {
        title: "销账收款详情",
        visible: _vm.chargeDrawerVisibility,
        size: "50%",
        "append-to-body": true,
        "before-close": _vm.handleClose,
        modal: "",
        "close-on-press-escape": "",
        wrapperClosable: "",
      },
    },
    [
      _c("div", { staticClass: "infor" }, [
        _c("p", [_vm._v("单据号:" + _vm._s(_vm.list.billCode))]),
      ]),
      _c("div", { staticClass: "infor" }, [
        _c("p", [_vm._v("协议客户：" + _vm._s(_vm.list.clientName))]),
        _c("p", [
          _vm._v(" 单据状态:"),
          _c("span", { staticClass: "primary" }, [
            _vm._v(_vm._s(_vm.list.status == "0" ? "正常" : "作废")),
          ]),
        ]),
      ]),
      _c(
        "el-table",
        {
          staticStyle: { width: "95%", "margin-left": "2.5%" },
          attrs: { data: _vm.tableData, border: "" },
        },
        [
          _c("el-table-column", {
            attrs: { type: "index", label: "序号", width: "80" },
          }),
          _c("el-table-column", {
            attrs: { prop: "billCode", label: "单据号", "min-width": "180" },
          }),
          _c("el-table-column", {
            attrs: { prop: "billCode", label: "车牌号", "min-width": "180" },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "checkTime",
              label: "结账(挂账）时间",
              "min-width": "180",
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "billTotal", label: "单据金额", "min-width": "90" },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "chargeTotal",
              label: "挂账金额",
              "min-width": "90",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "receivedTotal",
              label: "本次收款",
              "min-width": "90",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "receivableTotal",
              label: "应收结余",
              "min-width": "90",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "footer-table" }, [
        _c("p", [
          _vm._v(
            "金额大写：" + _vm._s(_vm._f("amountToChinese")(_vm.moneyTotal))
          ),
        ]),
        _c("p", [
          _vm._v(" 收款合计："),
          _c("span", { staticClass: "orange" }, [
            _vm._v(_vm._s(_vm.moneyTotal)),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "footer-infor" }, [
        _c(
          "div",
          [
            _c("p", [_vm._v("收款方式:")]),
            _vm._l(_vm.list.payTypeDetails, function (item, index) {
              return _c(
                "p",
                {
                  key: index,
                  staticStyle: { width: "17%", "text-align": "inherit" },
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(item.name) + "(" + _vm._s(item.paid) + ")"),
                  ]),
                ]
              )
            }),
          ],
          2
        ),
        _c("div", [
          _c("p", [_vm._v("收款时间:")]),
          _vm._v(" " + _vm._s(_vm.list.checkDate) + " "),
        ]),
      ]),
      _c("div", { staticClass: "footer-infor" }, [
        _c("div", [
          _c("p", [_vm._v("收款人:")]),
          _vm._v(" " + _vm._s(_vm.list.creatorName) + " "),
        ]),
        _c("div", [
          _c("p", [_vm._v("备注:")]),
          _vm._v(" " + _vm._s(_vm.list.remark) + " "),
        ]),
      ]),
      _c(
        "div",
        { staticStyle: { width: "95%", "margin-left": "2.5%" } },
        [_c("el-divider")],
        1
      ),
      _vm.list.status == 1
        ? _c("div", [
            _c("div", { staticClass: "footer-infor" }, [
              _c("div", [
                _c("p", [_vm._v("作废人:")]),
                _vm._v(" " + _vm._s(_vm.list.creatorName) + " "),
              ]),
              _c("div", [
                _c("p", [_vm._v("作废时间:")]),
                _vm._v(" " + _vm._s(_vm.list.cancelTime) + " "),
              ]),
            ]),
            _c("div", { staticClass: "invalid" }, [
              _c("p", [_vm._v("作废原因:")]),
              _vm._v(" " + _vm._s(_vm.list.cancelReason) + " "),
            ]),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _vm.list.status == 1
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "auth",
                      rawName: "v-auth",
                      value: 217,
                      expression: "217",
                    },
                  ],
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.invalidDialogVisible = true
                    },
                  },
                },
                [_vm._v("作废")]
              )
            : _vm._e(),
          _c("el-button", { attrs: { size: "small" } }, [_vm._v("打印")]),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "作废",
            visible: _vm.invalidDialogVisible,
            width: "30%",
            "before-close": _vm.handleCloseinvalid,
            "append-to-body": true,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.invalidDialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticStyle: { margin: "11px 20px 5px 20px" } }, [
            _vm._v("请输入作废原因，并确认作废"),
          ]),
          _c("el-input", {
            staticStyle: { margin: "10px 20px", width: "95%" },
            attrs: {
              type: "textarea",
              rows: 3,
              placeholder: "请输入作废原因（必填）",
            },
            model: {
              value: _vm.textarea,
              callback: function ($$v) {
                _vm.textarea = $$v
              },
              expression: "textarea",
            },
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.invalidDialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.cancellation()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }