var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "滞留说明",
        visible: _vm.visibility,
        width: "200",
        "before-close": _vm.handleClose,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "form",
          attrs: {
            model: _vm.editInfo,
            rules: _vm.rules,
            "label-width": "120px",
            size: "small",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "滞留原因", prop: "strandedReason" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 6,
                  placeholder: "请填写车辆滞留在场原因或维修中断原因（必填）",
                  maxlength: "50",
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.editInfo.strandedReason,
                  callback: function ($$v) {
                    _vm.$set(_vm.editInfo, "strandedReason", $$v)
                  },
                  expression: "editInfo.strandedReason",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "卡点解决时间", prop: "planCompletionTime" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "date",
                  placeholder: "请选择日期",
                  size: "small",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.editInfo.planCompletionTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.editInfo, "planCompletionTime", $$v)
                  },
                  expression: "editInfo.planCompletionTime",
                },
              }),
            ],
            1
          ),
          _c(
            "p",
            { staticStyle: { color: "#909399", margin: "-2px 0 10px 120px" } },
            [_vm._v(" 设置当前阶段预计完成时间 ")]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "预交车时间", prop: "planFinishTime" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "datetime",
                  placeholder: _vm.finishPlaceholder,
                  size: "small",
                  disabled: _vm.planFinishDisabled,
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                },
                on: { focus: _vm.verifyPermission },
                model: {
                  value: _vm.editInfo.planFinishTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.editInfo, "planFinishTime", $$v)
                  },
                  expression: "editInfo.planFinishTime",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.handleClose } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.save },
            },
            [_vm._v(" 确 定 ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }