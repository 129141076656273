var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { ref: "baseContainer", attrs: { paddingTop: "" } },
    [
      [
        _c(
          "el-form",
          {
            staticClass: "filter-form",
            attrs: { model: _vm.form, "label-width": "100px", size: "small" },
          },
          [
            _c("div", { staticClass: "flex-x-between" }, [
              _c(
                "div",
                { staticClass: "flex-warp flex-shrink0" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "车牌号" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入车牌号", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.getData.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.form.carNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "carNumber", $$v)
                          },
                          expression: "form.carNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c(
                          "div",
                          [
                            _vm._v(" 操作门店 "),
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  effect: "light",
                                  content:
                                    "接车单的操作门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)",
                                  placement: "bottom-start",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "iconfont grow-icon_doubt",
                                  staticStyle: { color: "#9a9a9a" },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("operateCompanyCascader", {
                        model: {
                          value: _vm.form.operateCompanyId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "operateCompanyId", $$v)
                          },
                          expression: "form.operateCompanyId",
                        },
                      }),
                    ],
                    2
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "接车进店日期" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          "unlink-panels": "",
                          "range-separator": "至",
                          "start-placeholder": "起始时间",
                          "end-placeholder": "截止时间",
                          "value-format": "yyyy-MM-dd",
                        },
                        model: {
                          value: _vm.form.intoStoreDateRange,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "intoStoreDateRange", $$v)
                          },
                          expression: "form.intoStoreDateRange",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "flex" }, [
                _c("div", { staticClass: "el-form-item--small" }, [
                  _c(
                    "div",
                    {
                      staticClass: "el-form-item__label pointer",
                      on: {
                        click: function ($event) {
                          _vm.showMore = !_vm.showMore
                        },
                      },
                    },
                    [
                      _vm._v(" 更多筛选 "),
                      _c("i", {
                        class: {
                          primary: true,
                          "el-icon-arrow-right": !_vm.showMore,
                          "el-icon-arrow-down": _vm.showMore,
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "btn_search",
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.getData },
                        },
                        [_vm._v("查询")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "btn_search",
                          attrs: { size: "small" },
                          on: { click: _vm.resetFilterForm },
                        },
                        [_vm._v("置空")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "flex-warp" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "接车员" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          filterable: "",
                          clearable: "",
                          remote: "",
                          placeholder: "搜索姓名、手机号",
                          "remote-method": _vm.searchAdminJobListUser,
                          loading: _vm.searchAdminJobListUserLoading,
                        },
                        model: {
                          value: _vm.form.pickUpUserId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "pickUpUserId", $$v)
                          },
                          expression: "form.pickUpUserId",
                        },
                      },
                      _vm._l(_vm.adminJobListUserList, function (item) {
                        return _c(
                          "el-option",
                          {
                            key: item.userId,
                            attrs: { label: item.realName, value: item.userId },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  float: "left",
                                  color: "#8492a6",
                                  "font-size": "13px",
                                },
                              },
                              [_vm._v(_vm._s(item.realName))]
                            ),
                            _c("span", { staticStyle: { float: "right" } }, [
                              _vm._v(_vm._s(item.phone)),
                            ]),
                          ]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "接车单号" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入", clearable: "" },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.getData.apply(null, arguments)
                        },
                      },
                      model: {
                        value: _vm.form.billCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "billCode", $$v)
                        },
                        expression: "form.billCode",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "车单结账日期" } },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "daterange",
                        "unlink-panels": "",
                        "range-separator": "至",
                        "start-placeholder": "起始时间",
                        "end-placeholder": "截止时间",
                        "value-format": "yyyy-MM-dd",
                      },
                      model: {
                        value: _vm.form.checkDateRange,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "checkDateRange", $$v)
                        },
                        expression: "form.checkDateRange",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "车架号" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入", clearable: "" },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.getData.apply(null, arguments)
                        },
                      },
                      model: {
                        value: _vm.form.uniqueId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "uniqueId", $$v)
                        },
                        expression: "form.uniqueId",
                      },
                    }),
                  ],
                  1
                ),
                _vm.showMore
                  ? [
                      _c(
                        "el-form-item",
                        { attrs: { label: "业务来源" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                clearable: "",
                                remote: "",
                                placeholder: "搜索业务来源",
                                "remote-method": _vm.searchBillSource,
                                loading: _vm.searchBillSourceLoading,
                              },
                              model: {
                                value: _vm.form.billSourceTypeId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "billSourceTypeId", $$v)
                                },
                                expression: "form.billSourceTypeId",
                              },
                            },
                            _vm._l(_vm.billSourceList, function (item) {
                              return _c("el-option", {
                                key: item.billSourceTypeId,
                                attrs: {
                                  label: item.name,
                                  value: item.billSourceTypeId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "签约客户" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                clearable: "",
                                remote: "",
                                placeholder: "请输入客户名称",
                                "remote-method": _vm.searchClient,
                                loading: _vm.searchClientLoading,
                              },
                              model: {
                                value: _vm.form.clientId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "clientId", $$v)
                                },
                                expression: "form.clientId",
                              },
                            },
                            _vm._l(_vm.clientList, function (item) {
                              return _c(
                                "el-option",
                                {
                                  key: item.id,
                                  attrs: {
                                    label: item.clientName,
                                    value: item.id,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "#8492a6",
                                        "font-size": "13px",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.clientName))]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "车型信息" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                remote: "",
                                clearable: "",
                                placeholder: "搜索车型",
                                "remote-method": _vm.searchCarModel,
                                loading: _vm.searchCarModelLoading,
                              },
                              model: {
                                value: _vm.form.carModel,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "carModel", $$v)
                                },
                                expression: "form.carModel",
                              },
                            },
                            _vm._l(_vm.carModelList, function (item) {
                              return _c(
                                "el-option",
                                {
                                  key: _vm.$lodash.get(item, "series.id"),
                                  attrs: {
                                    value: _vm.$lodash.get(
                                      item,
                                      "series.name",
                                      _vm.$lodash.get(
                                        item,
                                        "brand.name",
                                        _vm.$lodash.get(item, "logo.name", "")
                                      )
                                    ),
                                  },
                                },
                                [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(item.markedRedName),
                                    },
                                  }),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "施工技师" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                placeholder: "搜索姓名、手机号",
                                clearable: "",
                              },
                              model: {
                                value: _vm.form.technician,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "technician", $$v)
                                },
                                expression: "form.technician",
                              },
                            },
                            _vm._l(_vm.techniciansList, function (item) {
                              return _c(
                                "el-option",
                                {
                                  key: item.userId,
                                  attrs: {
                                    label: item.realName,
                                    value: item.userId,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        float: "left",
                                        color: "#8492a6",
                                        "font-size": "13px",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.realName))]
                                  ),
                                  _c(
                                    "span",
                                    { staticStyle: { float: "right" } },
                                    [_vm._v(_vm._s(item.phone))]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "施工班组" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入", clearable: "" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.getData.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.form.filed1,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "filed1", $$v)
                              },
                              expression: "form.filed1",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "业务员" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                placeholder: "搜索姓名、手机号",
                                clearable: "",
                              },
                              model: {
                                value: _vm.form.sellerId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "sellerId", $$v)
                                },
                                expression: "form.sellerId",
                              },
                            },
                            _vm._l(_vm.salesmanList, function (item) {
                              return _c(
                                "el-option",
                                {
                                  key: item.userId,
                                  attrs: {
                                    label: item.realName,
                                    value: item.userId,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        float: "left",
                                        color: "#8492a6",
                                        "font-size": "13px",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.realName))]
                                  ),
                                  _c(
                                    "span",
                                    { staticStyle: { float: "right" } },
                                    [_vm._v(_vm._s(item.phone))]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c("template", { slot: "label" }, [
                            _c(
                              "div",
                              [
                                _vm._v(" 消费客户 "),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "light",
                                      placement: "bottom-start",
                                    },
                                  },
                                  [
                                    _c("template", { slot: "content" }, [
                                      _c("p", [
                                        _vm._v("自费/包干客户：输入客户名称"),
                                      ]),
                                      _c("p", [
                                        _vm._v("保险公司：输入公司名称、编码"),
                                      ]),
                                      _c("p", [
                                        _vm._v("三包厂家：输入厂家名称、编码"),
                                      ]),
                                    ]),
                                    _c("i", {
                                      staticClass: "iconfont grow-icon_doubt",
                                      staticStyle: { color: "#9a9a9a" },
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("el-input", {
                            attrs: { placeholder: "请输入", clearable: "" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.getData.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.form.consumerMsg,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "consumerMsg", $$v)
                              },
                              expression: "form.consumerMsg",
                            },
                          }),
                        ],
                        2
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c("template", { slot: "label" }, [
                            _c(
                              "div",
                              { staticClass: "flex flex-x-center" },
                              [
                                _vm._v(" 开票主体 "),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "light",
                                      placement: "bottom-start",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          color: "#666666",
                                          "font-size": "13px",
                                          "line-height": "20px",
                                          "text-indent": "15px",
                                        },
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _vm._v(
                                          " 开票主体，即为协议客户、保险公司和三包厂家的应收账款"
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          " 的回款管理门店(保险公司的开票主体也是定损主体)。保险公司"
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "管理的开票主体，可查已成为保险公司开票 主体的全部门店。 "
                                        ),
                                      ]
                                    ),
                                    _c("i", {
                                      staticClass: "iconfont grow-icon_doubt",
                                      staticStyle: { color: "#9a9a9a" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                clearable: "",
                                remote: "",
                                placeholder: "搜索开票主体名称",
                                "remote-method": _vm.searchInvoice,
                                loading: _vm.searchInvoiceLoading,
                              },
                              model: {
                                value: _vm.form.invoiceId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "invoiceId", $$v)
                                },
                                expression: "form.invoiceId",
                              },
                            },
                            _vm._l(_vm.invoiceList, function (item) {
                              return _c("el-option", {
                                key: item.companyId,
                                attrs: {
                                  label: item.invoicingEntity,
                                  value: item.companyId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        2
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "服务分类" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                clearable: "",
                                placeholder: "请选择或搜索分类",
                              },
                              on: {
                                clear: function ($event) {
                                  _vm.form.serviceFirstCategoryId = null
                                },
                              },
                              model: {
                                value: _vm.form.serviceFirstCategoryId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "serviceFirstCategoryId",
                                    $$v
                                  )
                                },
                                expression: "form.serviceFirstCategoryId",
                              },
                            },
                            _vm._l(_vm.serviceClassList, function (item) {
                              return _c(
                                "el-option",
                                {
                                  key: item.categoryId,
                                  attrs: {
                                    label: item.name,
                                    value: item.categoryId,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        float: "left",
                                        color: "#8492a6",
                                        "font-size": "13px",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  ),
                                  _c(
                                    "span",
                                    { staticStyle: { float: "right" } },
                                    [_vm._v(_vm._s(item.categoryId))]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "服务项目" } },
                        [
                          _vm.selectedServices.length == 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "choice-btn service",
                                  on: {
                                    click: function ($event) {
                                      _vm.multipleSelectServicesDrawerVisibility = true
                                    },
                                  },
                                },
                                [
                                  _c("span", { staticClass: "pointer" }, [
                                    _vm._v(" 点击选择"),
                                  ]),
                                ]
                              )
                            : _c(
                                "el-popover",
                                {
                                  staticStyle: { "text-align": "center" },
                                  attrs: {
                                    placement: "bottom",
                                    width: "400",
                                    trigger: "hover",
                                  },
                                },
                                [
                                  _c(
                                    "el-table",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        border: "",
                                        "max-height": "400",
                                        data: _vm.selectedServices,
                                      },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "name",
                                          label: "服务名称",
                                          "min-width": "160",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "serviceItemCode",
                                          label: "服务编码",
                                          "min-width": "100",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "choice-btn service pointer",
                                      staticStyle: { position: "relative" },
                                      attrs: { slot: "reference" },
                                      slot: "reference",
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              _vm.multipleSelectServicesDrawerVisibility = true
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " 已选择(" +
                                              _vm._s(
                                                _vm.selectedServices.length
                                              ) +
                                              ")"
                                          ),
                                        ]
                                      ),
                                      _c("i", {
                                        staticClass:
                                          "iconfont grow-a-icon_tabbar_close del-icon",
                                        staticStyle: { color: "#ff9200" },
                                        on: { click: _vm.clearSelectedService },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]
        ),
      ],
      [
        _c(
          "div",
          { staticClass: "flex-x-between" },
          [
            _c("div", { staticClass: "custom-tabs nopadding" }, [
              _c(
                "div",
                {
                  class: {
                    "custom-tab-item": true,
                    active: _vm.selectedBillType === "",
                  },
                  on: {
                    click: function ($event) {
                      _vm.selectedBillType = ""
                    },
                  },
                },
                [_vm._v(" 全部 ")]
              ),
              _c(
                "div",
                {
                  class: {
                    "custom-tab-item": true,
                    active: _vm.selectedBillType === 0,
                  },
                  on: {
                    click: function ($event) {
                      _vm.selectedBillType = 0
                    },
                  },
                },
                [_vm._v(" 自费业务 ")]
              ),
              _c(
                "div",
                {
                  class: {
                    "custom-tab-item": true,
                    active: _vm.selectedBillType === 1,
                  },
                  on: {
                    click: function ($event) {
                      _vm.selectedBillType = 1
                    },
                  },
                },
                [_vm._v(" 包干业务 ")]
              ),
              _c(
                "div",
                {
                  class: {
                    "custom-tab-item": true,
                    active: _vm.selectedBillType === 2,
                  },
                  on: {
                    click: function ($event) {
                      _vm.selectedBillType = 2
                    },
                  },
                },
                [_vm._v(" 理赔业务 ")]
              ),
              _c(
                "div",
                {
                  class: {
                    "custom-tab-item": true,
                    active: _vm.selectedBillType === 3,
                  },
                  on: {
                    click: function ($event) {
                      _vm.selectedBillType = 3
                    },
                  },
                },
                [_vm._v(" 索赔业务 ")]
              ),
            ]),
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 510,
                    expression: "510",
                  },
                ],
                staticClass: "blue",
                attrs: { size: "small", type: "text" },
                on: {
                  click: function ($event) {
                    _vm.showExportDialog = true
                  },
                },
              },
              [
                _vm._v("导出列表"),
                _c("i", {
                  staticClass: "iconfont grow-icon_daochu el-icon--right",
                }),
              ]
            ),
          ],
          1
        ),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "fixedScrollBar",
                rawName: "v-fixedScrollBar",
                value: _vm.$refs.baseContainer,
                expression: "$refs.baseContainer",
              },
            ],
            staticClass: "custom-table summary",
            attrs: {
              id: "customTable",
              data: _vm.tableData,
              border: "",
              "show-summary": "",
              "summary-method": _vm.getSummaries,
            },
            on: { "row-dblclick": _vm.goDetail },
          },
          [
            _c("el-table-column", {
              attrs: { type: "index", label: "序号", width: "60" },
            }),
            _c("el-table-column", {
              attrs: {
                width: "180",
                label: "操作门店",
                prop: "operateCompany",
              },
            }),
            _c("el-table-column", {
              attrs: { width: "200", label: "接车单号", prop: "billCode" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "blue pointer",
                          on: {
                            click: function ($event) {
                              return _vm.goDetail(row)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(row.billCode) + " ")]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "160", label: "结账时间", prop: "checkTime" },
            }),
            _c("el-table-column", {
              attrs: { width: "160", label: "进店时间", prop: "intoTime" },
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "车牌号", prop: "carNumber" },
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "车型", prop: "carModel" },
            }),
            _c("el-table-column", {
              attrs: { width: "180", label: "车架号", prop: "carVIN" },
            }),
            _c("el-table-column", {
              attrs: { width: "140", label: "签约客户" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [_c("div", [_vm._v(_vm._s(row.ownerName))])]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "160", label: "消费客户" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("div", [_vm._v(" " + _vm._s(row.consumerName) + " ")]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "180", label: "开票主体", prop: "invoiceName" },
            }),
            _c("el-table-column", {
              attrs: { width: "180", label: "业务类型" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      row.billType !== 0
                        ? _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("dict")(row.billType, "carBillType")
                                ) +
                                " "
                            ),
                          ])
                        : _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("dict")(
                                    row.isOthersSelfPayState,
                                    "isOthersSelfPayState"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                width: "100",
                label: "业务来源",
                prop: "businessSource",
              },
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "进店里程", prop: "mileage" },
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "接车员", prop: "receiver" },
            }),
            _c("el-table-column", {
              attrs: {
                width: "100",
                label: "项目大类",
                prop: "firstCategoryName",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "100",
                label: "项目细类",
                prop: "secondCategoryName",
              },
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "项目名称", prop: "pjName" },
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "项目小计" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      row.total !== undefined
                        ? _c("span", [
                            _vm._v(_vm._s(_vm._f("toThousands")(row.total))),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "成本小计" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _vm._v(_vm._s(_vm._f("toThousands")(row.inventoryTotal))),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "项目毛利" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      row.marginTotal !== undefined
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm._f("toThousands")(row.marginTotal))
                            ),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "包干金额" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      row.contractTotal !== undefined
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm._f("toThousands")(row.contractTotal))
                            ),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "业务员", prop: "salesman" },
            }),
            _c("el-table-column", {
              attrs: {
                width: "100",
                label: "施工技师",
                prop: "constructionTechnician",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "100",
                label: "施工班组",
                prop: "technicianTeamNames",
              },
            }),
            _c("el-table-column", {
              attrs: { "min-width": "180", label: "项目明细" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c(
                        "el-tooltip",
                        { attrs: { content: row.projectDetail } },
                        [
                          _c("div", { staticClass: "text-ellipsis-2-row" }, [
                            _vm._v(" " + _vm._s(row.projectDetail) + " "),
                          ]),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm.multipleSelectServicesDrawerVisibility
          ? _c("select-services-car-bill", {
              attrs: {
                oldServicesData: _vm.selectedServices || [],
                visibility: _vm.multipleSelectServicesDrawerVisibility,
              },
              on: {
                select: _vm.selectServicesList,
                "update:visibility": function ($event) {
                  _vm.multipleSelectServicesDrawerVisibility = $event
                },
              },
            })
          : _vm._e(),
        _vm.showExportDialog
          ? _c("export-dialog", {
              attrs: {
                visibility: _vm.showExportDialog,
                total: _vm.total,
                pageOption: _vm.pageOption,
                filterForm: _vm.exportForm,
                url: _vm.exportUrl,
              },
              on: {
                "update:visibility": function ($event) {
                  _vm.showExportDialog = $event
                },
              },
            })
          : _vm._e(),
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c("pagination", {
            attrs: { total: _vm.total },
            on: { pageChange: _vm.changePage },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }