<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <div>
        <el-form
          class="filter-form"
          :model="form"
          label-width="100px"
          size="small"
        >
          <div class="flex-x-between">
            <div class="flex-warp flex-shrink0">
              <el-form-item label="车牌号">
                <el-input
                  v-model="form.carNumber"
                  placeholder="请输入车牌号"
                  clearable
                  @keyup.enter.native="doSearch"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <template slot="label">
                  <div>
                    操作门店
                    <el-tooltip
                      effect="light"
                      content="接车单的操作门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)"
                      placement="bottom-start"
                    >
                      <i
                        class="iconfont grow-icon_doubt"
                        style="color: #9a9a9a"
                      ></i>
                    </el-tooltip>
                  </div>
                </template>
                <operateCompanyCascader
                  v-model="form.operateCompanyId"
                  :operateCompanyUrl="form.operateCompanyUrl"
                ></operateCompanyCascader>
              </el-form-item>
              <el-form-item label="车单结账日期">
                <el-date-picker
                  class="w100"
                  v-model="form.checkStartTimes"
                  type="daterange"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="起始时间"
                  end-placeholder="截止时间"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </div>
            <div class="flex">
              <div class="el-form-item--small">
                <div
                  @click="showMore = !showMore"
                  class="el-form-item__label pointer"
                >
                  更多筛选
                  <i
                    :class="{
                      primary: true,
                      'el-icon-arrow-right': !showMore,
                      'el-icon-arrow-down': showMore,
                    }"
                  ></i>
                </div>
                <div class="flex">
                  <el-button
                    class="btn_search"
                    type="primary"
                    size="small"
                    @click="doSearch"
                    >查询</el-button
                  >
                  <el-button class="btn_search" size="small" @click="empty"
                    >置空</el-button
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="flex-warp">
            <el-form-item label="签约客户">
              <el-select
                v-model="form.clientId"
                @change="selectClient"
                filterable
                clearable
                remote
                placeholder="请输入客户名称搜索"
                :remote-method="searchClient"
                :loading="searchClientLoading"
              >
                <el-option
                  v-for="item in customList"
                  :key="item.id"
                  :label="item.clientName"
                  :value="item.id"
                >
                  <span style="color: #8492a6; font-size: 13px">{{
                    item.clientName
                  }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="接车单号">
              <el-input
                v-model="form.billCode"
                placeholder="请输入接车单号"
                clearable
                @keyup.enter.native="doSearch"
              ></el-input>
            </el-form-item>
            <el-form-item label="接车进店日期">
              <el-date-picker
                class="w100"
                v-model="form.intoStoreTimes"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="截止时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="业务来源">
              <el-select
                v-model="form.billSourceTypeId"
                @change="selectBillSource"
                @clear="deleteBillSource"
                filterable
                clearable
                remote
                placeholder="搜索业务来源"
                :remote-method="searchBillSource"
                :loading="searchBillSourceLoading"
              >
                <el-option
                  v-for="item in billSourceList"
                  :key="item.billSourceTypeId"
                  :label="item.name"
                  :value="item.billSourceTypeId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <template v-if="showMore">
              <el-form-item>
                <template slot="label">
                  <div>
                    消费客户
                    <el-tooltip effect="light" placement="bottom-start">
                      <template slot="content">
                        <p>自费/包干客户：输入客户名称</p>
                        <p>保险公司：输入公司名称、编码</p>
                        <p>三包厂家：输入厂家名称、编码</p>
                      </template>
                      <i
                        class="iconfont grow-icon_doubt"
                        style="color: #9a9a9a"
                      ></i>
                    </el-tooltip>
                  </div>
                </template>
                <el-input
                  v-model="form.consumerMsg"
                  placeholder="请输入"
                  clearable
                  @keyup.enter.native="doSearch"
                ></el-input>
              </el-form-item>
              <el-form-item label="事故责任">
                <el-select
                  v-model="form.dutyType"
                  filterable
                  multiple
                  placeholder="筛选（支持多选）"
                  clearable
                >
                  <el-option
                    v-for="item in dutyTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
          </div>
        </el-form>
      </div>
    </template>
    <!-- 以下是表格 -->
    <template>
      <div class="btns flex-x-between">
        <div>
          <div class="custom-tabs nopadding">
            <div
              @click="status = -1"
              :class="{
                'custom-tab-item': true,
                active: status == -1,
              }"
            >
              <p>全部</p>
            </div>
            <div
              @click="status = 0"
              :class="{
                'custom-tab-item': true,
                active: status == 0,
              }"
            >
              <p>自费</p>
            </div>
            <div
              @click="status = 1"
              :class="{
                'custom-tab-item': true,
                active: status == 1,
              }"
            >
              <p>包干</p>
            </div>
            <div
              @click="status = 2"
              :class="{
                'custom-tab-item': true,
                active: status == 2,
              }"
            >
              <p>理赔</p>
            </div>
            <div
              @click="status = 3"
              :class="{
                'custom-tab-item': true,
                active: status == 3,
              }"
            >
              <p>索赔</p>
            </div>
          </div>
        </div>
        <div>
          <el-button
            v-auth="528"
            size="small"
            type="text"
            class="blue"
            @click="showExportDialog = true"
            >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
          ></el-button>
        </div>
      </div>
      <div class="flex h100" style="flex: 1; overflow: auto">
        <div style="flex: 1; overflow: auto">
          <el-table
            class="custom-table summary"
            :data="carBillList"
            border
            show-summary
            v-fixedScrollBar="$refs.baseContainer"
            :span-method="objectSpanMethod"
            :summary-method="getSummaries"
            @row-dblclick="goDetail($event.billId)"
          >
            <el-table-column label="序号" width="60" prop="sequence">
            </el-table-column>
            <el-table-column width="120" label="操作门店" prop="companyName">
              <template #default="{ row }">
                <el-tooltip :content="row.companyName">
                  <div class="text-ellipsis-2-row">
                    {{ row.companyName }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column width="170" label="车单号/业务类型">
              <template #default="{ row }">
                <div class="blue pointer" @click="goDetail(row.billId)">
                  {{ row.billCode }}
                </div>
                <div v-if="row.billType !== 0">
                  {{ row.billType | dict('carBillType') }}
                </div>
                <div v-else>
                  {{ row.isOthersSelfPayState == 0 ? '自费' : '他人自费' }}
                </div>
              </template>
            </el-table-column>
            <el-table-column width="100" label="进店时间" prop="dateCreated">
            </el-table-column>
            <el-table-column width="100" label="结账时间" prop="checkDate">
            </el-table-column>
            <el-table-column width="100" label="车牌号" prop="carNumber">
            </el-table-column>
            <el-table-column min-width="120" label="车架号" prop="uniqueId">
            </el-table-column>
            <el-table-column min-width="120" label="车型" prop="carModel">
            </el-table-column>
            <el-table-column width="120" label="签约客户" show-overflow-tooltip>
              <template #default="{ row }">
                <div v-if="row.ownerName">
                  <div class="text-ellipsis">{{ row.ownerName }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column width="120" label="消费客户" show-overflow-tooltip>
              <template #default="{ row }">
                <div v-if="row.consumerName">
                  <div class="text-ellipsis">
                    {{ row.consumerName }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column width="100" label="业务来源" prop="source">
              <template #default="{ row }">
                <el-tooltip :content="row.source">
                  <div class="text-ellipsis-2-row">
                    {{ row.source }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column width="100" label="进店里程" prop="mileage">
            </el-table-column>
            <el-table-column
              width="170"
              label="项目名称"
              prop="billServiceName"
            >
            </el-table-column>
            <el-table-column width="170" label="项目明细" prop="itemName">
            </el-table-column>
            <el-table-column width="80" label="数量" prop="serviceNum">
              <template #default="{ row }">
                <p v-if="row.serviceNum">{{ row.serviceNum }}</p>
                <p v-else>-</p>
              </template>
            </el-table-column>
            <el-table-column width="100" label="工时报价" prop="servicePrice">
              <template #default="{ row }">
                <p v-if="row.servicePrice">{{ row.servicePrice }}</p>
                <p v-else>-</p>
              </template>
            </el-table-column>
            <el-table-column width="80" label="数量" prop="goodsNum">
              <template #default="{ row }">
                <p v-if="row.goodsNum">{{ row.goodsNum }}</p>
                <p v-else>-</p>
              </template>
            </el-table-column>
            <el-table-column width="100" label="配件报价" prop="goodsPrice">
              <template #default="{ row }">
                <p v-if="row.goodsPrice">{{ row.goodsPrice }}</p>
                <p v-else>-</p>
              </template>
            </el-table-column>
            <el-table-column width="100" label="项目小计" prop="itemTotal">
              <template #default="{ row }">
                <p v-if="row.itemTotal">{{ row.itemTotal }}</p>
                <p v-else>-</p>
              </template>
            </el-table-column>
            <el-table-column width="100" label="单据金额">
              <template #default="{ row }">
                <p v-if="row.paid">{{ row.paid }}</p>
                <p v-else>-</p>
              </template>
            </el-table-column>
            <el-table-column width="100" label="已收金额" prop="alreadyPaid">
              <template #default="{ row }">
                <p v-if="row.alreadyPaid">{{ row.alreadyPaid }}</p>
                <p v-else>-</p>
              </template>
            </el-table-column>
            <el-table-column width="100" label="待收金额" prop="unclearedDebt">
              <template #default="{ row }">
                <p v-if="row.unclearedDebt">{{ row.unclearedDebt }}</p>
                <p v-else>-</p>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <export-dialog
        :visibility.sync="showExportDialog"
        :total="total"
        :pageOption="pageOption"
        :filterForm="form"
        :url="exportUrl"
      ></export-dialog>
    </template>

    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
  </base-container>
</template>
<script>
// 分页组件
import pagination from '@/components/Pagination.vue';
import ExportDialog from '@/components/ExportDialog';
import { downloadFile } from '@/utils/fileApi';
//模糊搜索接口
import { getBillSourceTypeList } from '@/api/billSource';
//字典
import { dutyType } from '@/service/dict/dictData';

//接口
import { searchClient } from '@/api/customer/customer';
import {
  getCustomerReconciliation,
  getCustomerReconciliationStatistics,
  exportCustomerReconciliationUrl,
} from '@/api/financial/financialOver';

//组件
import operateCompanyCascader from '@/components/businessComponent/operateCompanyCascader';

// 工具
import Big from 'big.js';
import { mapState } from 'vuex';

export default {
  name: 'customerReconciliation',
  components: {
    pagination,
    ExportDialog,
    operateCompanyCascader,
  },
  data() {
    return {
      form: {
        checkStartTimes: [
          new Date().Format('yyyy-MM-dd'),
          new Date().Format('yyyy-MM-dd'),
        ],
        intoStoreTimes: [],
        dutyType: [],
      },
      // 展示更多筛选
      showMore: false,
      //导出的页面设置
      startPage: 1,
      endPage: 1,
      exportUrl: exportCustomerReconciliationUrl,
      //分页组件
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      showExportDialog: false,
      status: -1,
      // 车单列表数据
      carBillList: [],
      //搜索业务来源字段
      searchBillSourceLoading: false,
      billSourceList: [],
      //事故责任字段
      dutyTypeList: dutyType,
      //签约客户
      customList: [],
      searchClientLoading: false,

      totalPaid: '', //合计单据金额
      totalAlreadyPaid: '', //合计已收金额
      totalUnclearedDebt: '', //合计待收金额
      statisData: {},
    };
  },
  watch: {
    status() {
      this.getList();
    },
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  activated() {},
  created() {
    if (!this.$route.params.forms) {
      this.form.operateCompanyId = [this.companyId];
      this.getList();
      this.getStatis();
    }
  },
  mounted() {},
  methods: {
    // 置空输入框数据
    empty() {
      this.form = { checkStartTimes: [], intoStoreTimes: [], dutyType: [] };
    },
    //更改页码
    changePage(e) {
      this.pageOption = e;
      this.getList(false);
    },
    //远程搜索业务来源列表
    searchBillSource(keyWord = '') {
      this.searchBillSourceLoading = true;
      getBillSourceTypeList({ name: keyWord })
        .then((res) => {
          this.billSourceList = res.records;
        })
        .finally(() => {
          this.searchBillSourceLoading = false;
        });
    },
    // 选择业务来源
    selectBillSource(billSourceId) {},
    //删除业务来源
    deleteBillSource() {},
    /**
     * 远程搜索车主客户列表
     */
    clientKeyWordChange(keyword) {
      if (keyword) {
        this.searchClient(keyword);
      }
    },
    searchClient(keyWord = '') {
      this.searchClientLoading = true;
      searchClient(keyWord)
        .then((res) => {
          this.customList = res.records;
        })
        .finally(() => {
          this.searchClientLoading = false;
        });
    },
    /**
     * 选择车主客户
     */
    selectClient(clientId) {},

    /**
     * 删除车主客户
     */
    deleteClient() {},
    /**
     * 点击查询
     */
    async doSearch() {
      await this.verifyFilterCondition();
    },
    //获取列表数据
    getList(reset = true) {
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      var times = {
        intoStoreStartTime: this.form.intoStoreTimes?.[0]
          ? this.form.intoStoreTimes?.[0]
          : '',
        intoStoreEndTime: this.form.intoStoreTimes?.[1]
          ? this.form.intoStoreTimes?.[1]
          : '',
        checkStartTime: this.form.checkStartTimes?.[0]
          ? this.form.checkStartTimes?.[0]
          : '',
        checkEndTime: this.form.checkStartTimes?.[1]
          ? this.form.checkStartTimes?.[1]
          : '',
        billType: this.status == -1 ? '' : this.status,
      };
      var data = Object.assign(this.form, this.pageOption, times);
      this.statisData = Object.assign(this.form, this.pageOption, times);
      getCustomerReconciliation(data).then((res) => {
        this.carBillList = res.records;
        this.total = res.total;
        //表格数据处理，同一个车单ID相同的前面车单信息合并
        this.rowspan(this.carBillList);
        // this.calcSummaries();
      });
    },
    //合计表的统计数据
    getStatis() {
      getCustomerReconciliationStatistics(this.statisData).then((res) => {
        this.totalPaid = res.paid;
        this.totalAlreadyPaid = res.alreadyPaid;
        this.totalUnclearedDebt = res.unclearedDebt;
      });
    },
    //表格中对数据进行处理
    rowspan(arr) {
      let billMap = {};
      let serviceMap = {};
      arr.forEach((item) => {
        if (billMap[item.billId]) {
          billMap[item.billId]++;
        } else {
          billMap[item.billId] = 1;
        }
        if (serviceMap[item.billServiceId]) {
          serviceMap[item.billServiceId]++;
        } else {
          serviceMap[item.billServiceId] = 1;
        }

        item.sequence = Object.keys(billMap).length;
      });
      console.log(billMap);
      console.log(serviceMap);

      arr.forEach((item, index) => {
        if (index === 0 || item.billId != arr[index - 1].billId) {
          item.billSpan = billMap[item.billId];
        }
        if (index === 0 || item.billServiceId != arr[index - 1].billServiceId) {
          item.serviceSpan = serviceMap[item.billServiceId];
        }
      });

      let table = arr.map((item) => ({
        billId: item.billId,
        billSpan: item.billSpan,
        billServiceId: item.billServiceId,
        serviceSpan: item.serviceSpan,
      }));
      console.table(table);
    },
    calcSummaries() {
      //过滤重复车单,计算当页合计
      var newList = this.$lodash.cloneDeep(this.carBillList);
      var list = this.$lodash.uniqBy(newList, 'billId');
      this.totalPaid = list
        .reduce((prev, item) => {
          return prev + +item.paid || 0;
        }, 0)
        .toFixed(2);
      this.totalAlreadyPaid = list
        .reduce((prev, item) => {
          return prev + +item.alreadyPaid || 0;
        }, 0)
        .toFixed(2);
      this.totalUnclearedDebt = list
        .reduce((prev, item) => {
          return prev + +item.unclearedDebt || 0;
        }, 0)
        .toFixed(2);
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 19, 20, 21].includes(columnIndex)
      ) {
        return {
          rowspan: row.billSpan || 0, //行
          colspan: row.billSpan ? 1 : 0, //列
        };
      }
      if ([12, 18].includes(columnIndex)) {
        return {
          rowspan: row.serviceSpan || 0, //行
          colspan: row.serviceSpan ? 1 : 0, //列
        };
      }
    },
    // 表格自定义合计方法
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 1) {
          sums[index] = '合计';
          return;
        }
        if (index > 1) {
          switch (index) {
            case 19:
              sums[index] = this.totalPaid;
              break;
            case 20:
              sums[index] = this.totalAlreadyPaid;
              break;
            case 21:
              sums[index] = this.totalUnclearedDebt;
              break;
          }
        }
      });
      return sums;
    },
    /**
     * 跳转详情页
     */
    goDetail(id) {
      this.$router.push({
        name: 'carBillDetail',
        query: {
          id,
        },
      });
    },
    /**
     * 校验筛选条件是否可以进行搜索
     * @return {Promise}
     */
    verifyFilterCondition() {
      return new Promise((resolve, reject) => {
        //设置当筛选条件中除了操作门店之前其他所有的条件为空时点击全部、已结账就弹出提示
        let hasTime =
          !this.form.checkStartTimes?.length != 0 &&
          !this.form.intoStoreTimes?.length != 0 &&
          !this.form.dutyType?.length != 0;
        let res = [
          'carNumber',
          'billCode',
          'clientId',
          'billSourceTypeId',
          'consumerMsg',
        ].every((item) => {
          return !this.form[item];
        });
        if (hasTime && res) {
          reject();
          return this.$alert(
            '查询已结账状态单据数据量较大，将为你筛选本月结账的单据，如需查询更多请手动选择时间范围',
            '提示',
            {
              confirmButtonText: '确定',
              callback: (action) => {
                this.form.checkStartTimes = [
                  new Date(new Date().setDate(1)).Format('yyyy-MM-dd'),
                  new Date().Format('yyyy-MM-dd'),
                ];
                this.getList();
                this.getStatis();
              },
            }
          );
        } else {
          this.getList();
          this.getStatis();
        }
        resolve();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-tabs {
  display: flex;
  .custom-tab-item {
    padding: 10px 16px;
    border-radius: 4px 4px 0 0;
    border: 1px solid #eee;
    border-bottom: none;
    text-align: center;
    cursor: pointer;
    &.active,
    &:hover {
      color: $primary;
      background: $bg;
    }
    p:nth-child(2) {
      font-size: 14px;
      margin-top: 3px;
    }
  }
  &.nopadding .custom-tab-item {
    margin-right: 10px;
  }
}
// 隐藏滚动条
.custom-table ::v-deep .el-table__body-wrapper {
  &::-webkit-scrollbar {
    display: none;
  }
  // IE
  -ms-overflow-style: none;
}
</style>
