var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-drawer",
    {
      attrs: {
        title: "定损单详情",
        visible: _vm.visibility,
        size: "62%",
        "append-to-body": true,
        "before-close": _vm.handleClose,
        modal: "",
        "close-on-press-escape": "",
        wrapperClosable: true,
      },
    },
    [
      _c(
        "div",
        { staticStyle: { margin: "0 8px 40px 24px", overflow: "scroll" } },
        [
          _c("div", { staticClass: "report-sty" }, [
            _c("div", { staticClass: "flex" }, [
              _c("div", { staticClass: "car-logo flex-shrink0" }, [
                _vm.carVo.carLogoIcon
                  ? _c("img", { attrs: { src: _vm.carVo.carLogoIcon } })
                  : _c("img", {
                      attrs: { src: require("@/assets/img/carLogo.png") },
                    }),
              ]),
              _c(
                "div",
                { staticStyle: { "margin-top": "3px", "margin-left": "16px" } },
                [
                  _c("div", { staticClass: "car-info" }, [
                    _c("span", { staticClass: "car-number" }, [
                      _vm._v(_vm._s(_vm.carVo.carNumber)),
                    ]),
                    _vm.carVo.brandName
                      ? _c("span", { staticClass: "car-info-tag car-brand" }, [
                          _vm._v(_vm._s(_vm.carVo.brandName)),
                        ])
                      : _vm._e(),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "flex",
                      staticStyle: { "margin-top": "16px" },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "text-decoration": "underline",
                            color: "#303133",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.billVo.clientName) + " ")]
                      ),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            margin: "0 16px",
                            "font-weight": "600",
                          },
                        },
                        [
                          _vm._v(
                            " 跟进人：" +
                              _vm._s(_vm.infoVo.followUpPeopleName) +
                              " "
                          ),
                        ]
                      ),
                      _vm.infoVo.remind != 0
                        ? _c(
                            "el-tag",
                            { attrs: { size: "mini", type: "danger" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("dict")(_vm.infoVo.remind, "remind")
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _c("i", {
                class: ["newicon", _vm.stateIcon],
                staticStyle: {
                  "font-size": "60px",
                  "vertical-align": "middle",
                  "margin-left": "17px",
                },
              }),
            ]),
            _c(
              "div",
              { staticClass: "flex-y-center" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small" },
                    on: {
                      click: function ($event) {
                        _vm.operatingRecordDrawerVisibility = true
                      },
                    },
                  },
                  [_vm._v("流程详情")]
                ),
              ],
              1
            ),
          ]),
          _c(
            "el-descriptions",
            {
              attrs: {
                border: "",
                labelClassName: "table-label",
                contentStyle: { verticalAlign: "middle" },
              },
            },
            [
              _c(
                "el-descriptions-item",
                { attrs: { label: "操作门店", span: 1 } },
                [_vm._v(" " + _vm._s(_vm.infoVo.companyName) + " ")]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "进店时间", span: 1 } },
                [_vm._v(" " + _vm._s(_vm.billVo.dateCreated) + " ")]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "单据号", span: 1 } },
                [
                  [
                    _c(
                      "span",
                      {
                        staticClass: "blue pointer",
                        staticStyle: { "word-break": "break-word" },
                        on: {
                          click: function ($event) {
                            return _vm.goDetail(_vm.billVo.billId)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.billVo.billCode))]
                    ),
                  ],
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "单据金额", span: 1 } },
                [_vm._v(" " + _vm._s(_vm.billVo.documentAmount) + " ")]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "送修人", span: 1 } },
                [_vm._v(" " + _vm._s(_vm.billVo.contactName) + " ")]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "送修人电话", span: 1 } },
                [_vm._v(" " + _vm._s(_vm.billVo.contactPhone) + " ")]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "报案号", span: 1 } },
                [
                  _c("p", { staticStyle: { "word-break": "break-word" } }, [
                    _vm._v(_vm._s(_vm.billVo.caseNum)),
                  ]),
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { span: 1 } },
                [
                  _c(
                    "template",
                    { slot: "label" },
                    [
                      _vm._v(" 保险公司 "),
                      _c(
                        "el-tooltip",
                        { attrs: { content: "商业险保险公司" } },
                        [
                          _c("i", {
                            staticClass: "iconfont grow-icon_doubt color626",
                            staticStyle: { "margin-left": "5px" },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" " + _vm._s(_vm.billVo.insuranceName) + " "),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "开票主体", span: 1 } },
                [_vm._v(" " + _vm._s(_vm.billVo.invoice) + " ")]
              ),
              _vm.infoVo.tcCaseNum
                ? [
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "交强报案号", span: 1 } },
                      [
                        _c(
                          "p",
                          { staticStyle: { "word-break": "break-word" } },
                          [_vm._v(_vm._s(_vm.infoVo.tcCaseNum))]
                        ),
                      ]
                    ),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "交强保险公司", span: 2 } },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.infoVo.tcInsuranceCompany) + " "
                        ),
                      ]
                    ),
                  ]
                : _vm._e(),
              _c(
                "el-descriptions-item",
                { attrs: { label: "事故责任", span: 1 } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("dict")(_vm.billVo.dutyType, "dutyType")) +
                      " "
                  ),
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "车单备注", span: 2 } },
                [_vm._v(" " + _vm._s(_vm.billVo.companyRemark) + " ")]
              ),
            ],
            2
          ),
          _c(
            "el-descriptions",
            {
              staticStyle: { "margin-top": "32px" },
              attrs: {
                border: "",
                labelClassName: "table-label",
                contentStyle: { verticalAlign: "middle" },
              },
            },
            [
              _c(
                "el-descriptions-item",
                { attrs: { label: "报案日期", span: 1 } },
                [_vm._v(" " + _vm._s(_vm.infoVo.reportingTime) + " ")]
              ),
              _c(
                "el-descriptions-item",
                {
                  staticStyle: { width: "450px" },
                  attrs: { label: "定损员", span: 1 },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.infoVo.lossDeterminationPersonName) + " "
                  ),
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "联系电话", span: 1 } },
                [
                  _vm._v(
                    " " + _vm._s(_vm.infoVo.lossDeterminationPersonPhone) + " "
                  ),
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "拍照要求", span: 2 } },
                [_vm._v(" " + _vm._s(_vm.infoVo.photoRequest) + " ")]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "风险评估", span: 1 } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        [true, false].includes(_vm.infoVo.riskAssessment)
                          ? _vm.infoVo.riskAssessment
                            ? "有风险"
                            : "无风险"
                          : ""
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "拖车费", span: 1 } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("toThousands")(_vm.infoVo.towingCharge)) +
                      " "
                  ),
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "车损金额", span: 1 } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("toThousands")(_vm.infoVo.carDamageAmount)
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "预估金额", span: 1 } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("toThousands")(_vm.infoVo.estimatedAmount)
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "核损金额", span: 1 } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("toThousands")(_vm.infoVo.lossAssessmentAmount)
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "定损金额", span: 1 } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("toThousands")(_vm.infoVo.lossAmount)) +
                      " "
                  ),
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "最终定损金额", span: 1 } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("toThousands")(_vm.infoVo.finalLossAmount)
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "跟进进展", span: 1 } },
                [
                  _c(
                    "div",
                    {
                      style: _vm.$lodash.get(
                        _vm.followUpStateList.find(
                          (item) => item.value === _vm.infoVo.followUpState
                        ),
                        "iconStyle",
                        ""
                      ),
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("dict")(
                              _vm.infoVo.followUpState,
                              "followUpStateList",
                              "iconText"
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("span", { staticStyle: { "margin-left": "5px" } }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("dict")(
                          _vm.infoVo.followUpState,
                          "followUpStateList"
                        )
                      )
                    ),
                  ]),
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "收旧情况", span: 1 } },
                [
                  ![0, 2].includes(_vm.infoVo.recoveryState)
                    ? _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("dict")(
                                _vm.infoVo.recoveryState,
                                "recoveryState"
                              )
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                  _vm.infoVo.recoveryState == 2
                    ? _c("p", { staticClass: "red" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("dict")(
                                _vm.infoVo.recoveryState,
                                "recoveryState"
                              )
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "复勘情况", span: 1 } },
                [
                  ![0, 2].includes(_vm.infoVo.investigationState)
                    ? _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("dict")(
                                _vm.infoVo.investigationState,
                                "investigationState"
                              )
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                  _vm.infoVo.investigationState == 2
                    ? _c("p", { staticClass: "red" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("dict")(
                                _vm.infoVo.investigationState,
                                "investigationState"
                              )
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "回款状态", span: 1 } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.infoVo.collectionState == 0 ? "未回款" : "已回款"
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "备注", span: 3 } },
                [_vm._v(" " + _vm._s(_vm.infoVo.remark) + " ")]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "是否有免赔", span: 1 } },
                [_vm._v(" " + _vm._s(_vm.infoVo.exemptionPay2000Str) + " ")]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "免赔金额", span: 1 } },
                [
                  [1].includes(_vm.infoVo.isExemptionPay2000)
                    ? _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("toThousands")(
                                _vm.infoVo.exemptionPayMoney
                              )
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "免赔支付方", span: 1 } },
                [
                  _vm._v(" " + _vm._s(_vm.infoVo.settlementMethodStr) + " "),
                  [0].includes(_vm.infoVo.settlementMethod) &&
                  [1].includes(_vm.infoVo.isExemptionPay2000)
                    ? _c("span", [
                        _vm._v(
                          " (" + _vm._s(_vm.infoVo.settlementClientName) + ") "
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-descriptions",
            {
              staticStyle: { "margin-top": "32px" },
              attrs: {
                border: "",
                labelClassName: "table-label",
                contentStyle: { verticalAlign: "middle" },
              },
            },
            [
              _c(
                "el-descriptions-item",
                { attrs: { label: "车单状态", span: 1 } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("dict")(_vm.billVo.billState, "carBillState")
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "单据金额", span: 1 } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("toThousands")(_vm.billVo.documentAmount)) +
                      " "
                  ),
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "认领状态", span: 1 } },
                [_vm._v(" " + _vm._s(_vm.claimVo.claimStatusStr) + " ")]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "回款金额", span: 1 } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("toThousands")(_vm.claimVo.claimAmount)) +
                      " "
                  ),
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "挂账车队金额", span: 1 } },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm._f("toThousands")(_vm.infoVo.pendingAmount))
                    ),
                  ]),
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "挂账车队账单确认", span: 1 } },
                [
                  _vm.infoVo.receivingAmountAudit !== undefined &&
                  _vm.infoVo.billConfirm != undefined
                    ? _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.infoVo.billConfirm === true ? "是" : "否"
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "坏账金额", span: 1 } },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm._f("toThousands")(_vm.infoVo.badDebtsAmount))
                    ),
                  ]),
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "坏账处理", span: 1 } },
                [
                  _vm.infoVo.receivingAmountAudit !== undefined &&
                  _vm.infoVo.includeBadDebts === true
                    ? _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.infoVo.includeBadDebts === true ? "是" : "否"
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "坏账处理人", span: 1 } },
                [
                  _vm.infoVo.receivingAmountAudit !== undefined &&
                  _vm.infoVo.badDebtsUserName !== undefined
                    ? _c("span", [
                        _vm._v(" " + _vm._s(_vm.infoVo.badDebtsUserName) + " "),
                      ])
                    : _vm._e(),
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "回款认领备注", span: 3 } },
                [_vm._v(" " + _vm._s(_vm.claimVo.claimRemark) + " ")]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "坏账审核通过", span: 1 } },
                [
                  _vm.infoVo.receivingAmountAudit !== undefined &&
                  _vm.infoVo.includeBadDebts === true &&
                  _vm.infoVo.badDebtsConfirm !== undefined
                    ? _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.infoVo.badDebtsConfirm === true ? "是" : "否"
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "审核留言", span: 3 } },
                [
                  _vm.infoVo.receivingAmountAuditComment !== undefined &&
                  _vm.infoVo.receivingAmountAudit !== undefined
                    ? _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.infoVo.receivingAmountAuditComment) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "car-imgs",
              staticStyle: { "padding-bottom": "30px" },
            },
            [
              _c("p", [_vm._v("图片：")]),
              !_vm.picVo.runningLicenceImg &&
              !_vm.picVo.picDrivingLicence &&
              !_vm.picVo.feeListPicA &&
              !_vm.picVo.feeListPicB &&
              !_vm.picVo.feeListPicC &&
              !_vm.feeListPicOthersList.length &&
              !_vm.carBillImages.length
                ? _c("span", { staticClass: "color999" }, [
                    _vm._v("暂无附件图片"),
                  ])
                : _c("div", { staticClass: "flex" }, [
                    _vm.carBillImages.length
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-badge",
                              { attrs: { value: _vm.carBillImages.length } },
                              [
                                _c("img", {
                                  staticClass: "thumbnail-img",
                                  attrs: { src: _vm.carBillImages[0] },
                                  on: {
                                    click: function ($event) {
                                      return _vm.previewImgs(_vm.carBillImages)
                                    },
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "p",
                              {
                                staticStyle: {
                                  "margin-top": "5px",
                                  "text-align": "center",
                                },
                              },
                              [_vm._v("环检图")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "flex" },
                      [
                        _c("upload-file", {
                          attrs: {
                            value: _vm.picVo.runningLicenceImg,
                            disabled: "",
                            title: "行驶证",
                          },
                        }),
                        _c("upload-file", {
                          attrs: {
                            value: _vm.picVo.picDrivingLicence,
                            disabled: "",
                            title: "驾驶证",
                          },
                        }),
                        _c("upload-file", {
                          attrs: {
                            value: _vm.picVo.feeListPicA,
                            disabled: "",
                            title: "定损单1",
                          },
                        }),
                        _c("upload-file", {
                          attrs: {
                            value: _vm.picVo.feeListPicB,
                            disabled: "",
                            title: "定损单2",
                          },
                        }),
                        _c("upload-file", {
                          attrs: {
                            value: _vm.picVo.feeListPicC,
                            disabled: "",
                            title: "定损单3",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "flex" },
                      [
                        _c("upload-file-list", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.picVo.feeListPicOthersList,
                            callback: function ($$v) {
                              _vm.$set(_vm.picVo, "feeListPicOthersList", $$v)
                            },
                            expression: "picVo.feeListPicOthersList",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
            ]
          ),
          _c(
            "div",
            [
              _c(
                "el-table",
                {
                  attrs: { data: _vm.messageList, border: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "append",
                      fn: function () {
                        return [
                          _c(
                            "p",
                            {
                              staticStyle: {
                                "text-align": "center",
                                padding: "10px",
                              },
                            },
                            [
                              _vm.hasMoreMessage
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "pointer blue",
                                      on: {
                                        click: function ($event) {
                                          return _vm.getMessageRecord(false)
                                        },
                                      },
                                    },
                                    [_vm._v("加载更多")]
                                  )
                                : _c("span", [_vm._v("已加载全部")]),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("el-table-column", {
                    attrs: { width: "100", label: "操作", prop: "type" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("dict")(
                                    row.type,
                                    "carLossDeterminationBillOperationType"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      width: "160",
                      label: "操作时间",
                      prop: "lastUpdated",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { width: "120", label: "操作人", prop: "userName" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "100",
                      label: "当次留言",
                      prop: "message",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticStyle: { "padding-top": "30px" } }, [
            _c("p", [
              _vm._v(" 附件： "),
              _c(
                "span",
                { staticStyle: { color: "#909399", "font-size": "12px" } },
                [_vm._v(" 只支持上传zip文件，最多5个 ")]
              ),
            ]),
            _vm.attachmentList.length < 5
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-left": "20px",
                      "margin-top": "10px",
                      "margin-bottom": "5px",
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small" },
                        on: { click: _vm.chooseAttachmentFile },
                      },
                      [_vm._v(" 选择文件 ")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "flex-y",
                staticStyle: { margin: "10px 0 0 20px" },
              },
              _vm._l(_vm.attachmentList, function (item, i) {
                return _c(
                  "div",
                  { key: i, staticStyle: { "margin-bottom": "10px" } },
                  [
                    _c("i", {
                      staticClass:
                        "iconfont grow-icon_delete3 orange el-icon--left pointer",
                      on: {
                        click: function ($event) {
                          return _vm.handleAttachmentDelete(item, i)
                        },
                      },
                    }),
                    _c(
                      "span",
                      {
                        staticClass: "blue pointer",
                        staticStyle: {
                          "text-decoration": "none",
                          "font-size": "14px",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleDownloadFile(item)
                          },
                        },
                      },
                      [
                        _vm._v(_vm._s(item.name) + " "),
                        _c("i", { staticClass: "iconfont grow-icon_download" }),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _vm.infoVo.lossDeterminationState == 0
            ? [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "auth",
                        rawName: "v-auth",
                        value: 592,
                        expression: "592",
                      },
                    ],
                    attrs: { size: "small", type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.nextStep(0)
                      },
                    },
                  },
                  [_vm._v("完成报损")]
                ),
              ]
            : _vm._e(),
          _vm.infoVo.lossDeterminationState == 7
            ? [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "auth",
                        rawName: "v-auth",
                        value: 594,
                        expression: "594",
                      },
                    ],
                    attrs: { size: "small", type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.nextStep(2)
                      },
                    },
                  },
                  [_vm._v("完成调度")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "auth",
                        rawName: "v-auth",
                        value: 593,
                        expression: "593",
                      },
                    ],
                    attrs: { size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.previousStep(1)
                      },
                    },
                  },
                  [_vm._v("撤销报损")]
                ),
              ]
            : _vm._e(),
          _vm.infoVo.lossDeterminationState == 4
            ? [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "auth",
                        rawName: "v-auth",
                        value: 517,
                        expression: "517",
                      },
                    ],
                    attrs: { size: "small", type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.nextStep(4)
                      },
                    },
                  },
                  [_vm._v("完成对件")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "auth",
                        rawName: "v-auth",
                        value: 595,
                        expression: "595",
                      },
                    ],
                    attrs: { size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.previousStep(3)
                      },
                    },
                  },
                  [_vm._v("撤销调度")]
                ),
              ]
            : _vm._e(),
          _vm.infoVo.lossDeterminationState == 8
            ? [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "auth",
                        rawName: "v-auth",
                        value: 459,
                        expression: "459",
                      },
                    ],
                    attrs: { size: "small", type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.nextStep(6)
                      },
                    },
                  },
                  [_vm._v("提交定损")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "auth",
                        rawName: "v-auth",
                        value: 520,
                        expression: "520",
                      },
                    ],
                    attrs: { size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.previousStep(5)
                      },
                    },
                  },
                  [_vm._v("撤销对件")]
                ),
              ]
            : _vm._e(),
          _vm.infoVo.lossDeterminationState == 1
            ? [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "auth",
                        rawName: "v-auth",
                        value: 460,
                        expression: "460",
                      },
                    ],
                    attrs: { size: "small", type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.nextStep(8)
                      },
                    },
                  },
                  [_vm._v("已出定损")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "auth",
                        rawName: "v-auth",
                        value: 597,
                        expression: "597",
                      },
                    ],
                    attrs: { size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.previousStep(7)
                      },
                    },
                  },
                  [_vm._v("撤销提损")]
                ),
              ]
            : _vm._e(),
          _vm.infoVo.lossDeterminationState == 2 &&
          _vm.infoVo.investigationState == 2
            ? [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "auth",
                        rawName: "v-auth",
                        value: 598,
                        expression: "598",
                      },
                    ],
                    attrs: { size: "small", type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.secondEdit("完成复勘")
                      },
                    },
                  },
                  [_vm._v("完成复勘")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "auth",
                        rawName: "v-auth",
                        value: 463,
                        expression: "463",
                      },
                    ],
                    attrs: { size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.previousStep(9)
                      },
                    },
                  },
                  [_vm._v("撤销出损")]
                ),
              ]
            : _vm.infoVo.lossDeterminationState == 2 &&
              _vm.infoVo.recoveryState == 2
            ? [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "auth",
                        rawName: "v-auth",
                        value: 599,
                        expression: "599",
                      },
                    ],
                    attrs: { size: "small", type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.secondEdit("完成收旧")
                      },
                    },
                  },
                  [_vm._v("完成收旧")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "auth",
                        rawName: "v-auth",
                        value: 463,
                        expression: "463",
                      },
                    ],
                    attrs: { size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.previousStep(9)
                      },
                    },
                  },
                  [_vm._v("撤销出损")]
                ),
              ]
            : _vm.infoVo.lossDeterminationState == 2
            ? [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "auth",
                        rawName: "v-auth",
                        value: 518,
                        expression: "518",
                      },
                    ],
                    attrs: { size: "small", type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.lastStep(12)
                      },
                    },
                  },
                  [_vm._v("已提资料")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "auth",
                        rawName: "v-auth",
                        value: 463,
                        expression: "463",
                      },
                    ],
                    attrs: { size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.previousStep(9)
                      },
                    },
                  },
                  [_vm._v("撤销出损")]
                ),
              ]
            : _vm._e(),
          _vm.infoVo.lossDeterminationState == 5
            ? [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "auth",
                        rawName: "v-auth",
                        value: 462,
                        expression: "462",
                      },
                    ],
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.gotoPaymentReceivedDialog },
                  },
                  [_vm._v("回款认领")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "auth",
                        rawName: "v-auth",
                        value: 519,
                        expression: "519",
                      },
                    ],
                    attrs: { size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.previousStep(13)
                      },
                    },
                  },
                  [_vm._v("撤销提交")]
                ),
              ]
            : _vm._e(),
          _vm.infoVo.lossDeterminationState === 3100 ||
          _vm.infoVo.lossDeterminationState === 3101
            ? [
                _vm.isShowGotoPaymentReceivedDialog()
                  ? _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "auth",
                            rawName: "v-auth",
                            value: 462,
                            expression: "462",
                          },
                        ],
                        attrs: { size: "small", type: "primary" },
                        on: { click: _vm.gotoPaymentReceivedDialog },
                      },
                      [_vm._v("回款认领")]
                    )
                  : _vm._e(),
                _vm.infoVo.lossDeterminationState === 3100 &&
                _vm.infoVo.forwardToAudited === true
                  ? _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "auth",
                            rawName: "v-auth",
                            value: 666,
                            expression: "666",
                          },
                        ],
                        attrs: { size: "small", type: "primary" },
                        on: { click: _vm.gotoReceivingAmountAuditDialog },
                      },
                      [_vm._v("审核处理")]
                    )
                  : _vm._e(),
                _vm.infoVo.lossDeterminationState === 3100 ||
                _vm.infoVo.lossDeterminationState === 3101
                  ? _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "auth",
                            rawName: "v-auth",
                            value: 464,
                            expression: "464",
                          },
                        ],
                        attrs: { size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.previousStep(15)
                          },
                        },
                      },
                      [_vm._v("撤销认领")]
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "auth",
                  rawName: "v-auth",
                  value: 696,
                  expression: "696",
                },
              ],
              attrs: { size: "small" },
              on: { click: _vm.gotoExemptionDialog },
            },
            [_vm._v("调整免赔")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  _vm.messageDialogVisibility = true
                },
              },
            },
            [_vm._v("留言")]
          ),
        ],
        2
      ),
      _vm.operatingRecordDrawerVisibility
        ? _c("operating-record-drawer", {
            attrs: {
              visibility: _vm.operatingRecordDrawerVisibility,
              id: _vm.carLossDeterminationBillId,
            },
            on: {
              "update:visibility": function ($event) {
                _vm.operatingRecordDrawerVisibility = $event
              },
            },
          })
        : _vm._e(),
      _vm.messageDialogVisibility
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.messageDialogVisibility,
                title: "留言",
                width: "40%",
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.messageDialogVisibility = $event
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "footer",
                    fn: function () {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.saveMessage },
                          },
                          [_vm._v("确 定")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small" },
                            on: {
                              click: function ($event) {
                                _vm.messageDialogVisibility = false
                              },
                            },
                          },
                          [_vm._v("取 消")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1868245270
              ),
            },
            [
              _c(
                "div",
                [
                  _c("p", { staticStyle: { "margin-bottom": "10px" } }, [
                    _vm._v("当次留言："),
                  ]),
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      size: "small",
                      rows: 2,
                      maxlength: "300",
                      "show-word-limit": "",
                      placeholder: "请输入当次留言，最多300个字符",
                    },
                    model: {
                      value: _vm.messageContent,
                      callback: function ($$v) {
                        _vm.messageContent = $$v
                      },
                      expression: "messageContent",
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm.editExemptionDialogVisibility
        ? _c("EditExemptionDialog", {
            attrs: {
              visibility: _vm.editExemptionDialogVisibility,
              id: _vm.carLossDeterminationBillId,
              infoVoData: _vm.infoVo,
              title: "调整免赔信息",
            },
            on: {
              "update:visibility": function ($event) {
                _vm.editExemptionDialogVisibility = $event
              },
              save: _vm.init,
            },
          })
        : _vm._e(),
      _vm.paymentReceivedDialogVisibility
        ? _c("PaymentReceivedDialog", {
            attrs: {
              visibility: _vm.paymentReceivedDialogVisibility,
              id: _vm.carLossDeterminationBillId,
              billVoData: _vm.billVo,
              carVoData: _vm.carVo,
              infoVoData: _vm.infoVo,
              picVoData: _vm.picVo,
              title: "认领回款",
              lastName: _vm.infoVo.previousStateFollowUpPeopleName,
              lastId: _vm.infoVo.previousStateFollowUpPeople,
            },
            on: {
              "update:visibility": function ($event) {
                _vm.paymentReceivedDialogVisibility = $event
              },
              gotoClaimDetailPage: _vm.handleClose,
              save: _vm.init,
            },
          })
        : _vm._e(),
      _vm.receivingAmountAuditDialogVisibility
        ? _c("AuditingEditDialog", {
            attrs: {
              visibility: _vm.receivingAmountAuditDialogVisibility,
              id: _vm.carLossDeterminationBillId,
              billVoData: _vm.billVo,
              carVoData: _vm.carVo,
              infoVoData: _vm.infoVo,
              picVoData: _vm.picVo,
              title: "审核处理",
              lastName: _vm.infoVo.previousStateFollowUpPeopleName,
              lastId: _vm.infoVo.previousStateFollowUpPeople,
            },
            on: {
              "update:visibility": function ($event) {
                _vm.receivingAmountAuditDialogVisibility = $event
              },
              save: _vm.init,
            },
          })
        : _vm._e(),
      _vm.previousDialogVisibility
        ? _c("reversal-dialog", {
            attrs: {
              visibility: _vm.previousDialogVisibility,
              id: _vm.carLossDeterminationBillId,
              type: _vm.previousType,
              title: _vm.previousDialogTitle,
              lastName: _vm.infoVo.previousStateFollowUpPeopleName,
              lastId: _vm.infoVo.previousStateFollowUpPeople,
            },
            on: {
              "update:visibility": function ($event) {
                _vm.previousDialogVisibility = $event
              },
              save: _vm.init,
            },
          })
        : _vm._e(),
      _vm.editDialogVisibility
        ? _c("edit-dialog", {
            attrs: {
              visibility: _vm.editDialogVisibility,
              id: _vm.carLossDeterminationBillId,
              type: _vm.editType,
              title: _vm.editDialogTitle,
              form: _vm.editForm,
            },
            on: {
              "update:visibility": function ($event) {
                _vm.editDialogVisibility = $event
              },
              save: _vm.init,
            },
          })
        : _vm._e(),
      _vm.secondEditDialogVisibility
        ? _c("secondEditDialog", {
            attrs: {
              visibility: _vm.secondEditDialogVisibility,
              id: _vm.carLossDeterminationBillId,
              title: _vm.secondEditDialogTitle,
              form: _vm.secondEditForm,
            },
            on: {
              "update:visibility": function ($event) {
                _vm.secondEditDialogVisibility = $event
              },
              save: _vm.init,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }