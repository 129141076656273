var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "施工派工",
            width: "50%",
            visible: _vm.visibility,
            "before-close": _vm.handleClose,
            "close-on-click-modal": false,
            "append-to-body": "",
          },
        },
        [
          _c(
            "div",
            { staticClass: "update-fix-items_box" },
            [
              _c("div", { staticClass: "bill-info" }, [
                _c("div", { staticClass: "row flex-x-between" }, [
                  _c("div", { staticClass: "col flex" }, [
                    _c("div", { staticClass: "label" }, [_vm._v("接车单：")]),
                    _c("div", { staticClass: "value" }, [
                      _c(
                        "span",
                        {
                          staticClass: "blue pointer",
                          on: {
                            click: function ($event) {
                              return _vm.goCarBillDetail(_vm.detailData.billId)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.detailData.billCode))]
                      ),
                      _vm.detailData.isOthersSelfPayState
                        ? _c("span", { staticClass: "color999" }, [
                            _vm._v(" (他人自费) "),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _c("div", { staticClass: "col flex" }, [
                    _c("div", { staticClass: "label" }, [
                      _vm._v("预交车时间："),
                    ]),
                    _c("div", { staticClass: "value" }, [
                      _vm.detailData.planFinishTime
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.detailData.planFinishTime) + " "),
                          ])
                        : _vm._e(),
                      _vm.detailData.waitInStore
                        ? _c("span", { staticClass: "color999" }, [
                            _vm._v("( 在店等) "),
                          ])
                        : _vm._e(),
                      _vm.detailData.undetermined
                        ? _c("span", [_vm._v(" 待定 ")])
                        : _vm._e(),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "row flex-x-between",
                    staticStyle: { "margin-top": "20px" },
                  },
                  [
                    _c("div", { staticClass: "col flex" }, [
                      _c("div", { staticClass: "label" }, [_vm._v("车牌号：")]),
                      _c("div", { staticClass: "value" }, [
                        _c("span", [_vm._v(_vm._s(_vm.detailData.carNumber))]),
                        _vm.detailData.carType !== undefined
                          ? _c("span", [
                              _vm._v(
                                " (" + _vm._s(_vm.detailData.vehicleType) + ") "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                    _c("div", { staticClass: "col flex" }, [
                      _c("div", { staticClass: "label" }, [
                        _vm._v("车型/车架号："),
                      ]),
                      _c("div", { staticClass: "value" }, [
                        _c("span", [_vm._v(_vm._s(_vm.detailData.carModel))]),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              display: "block",
                              "margin-top": "10px",
                            },
                          },
                          [
                            _vm._v(
                              " VIN: " + _vm._s(_vm.detailData.uniqueId) + " "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "flex" }, [
                _vm._v(" 请设置服务项目的施工技师（班组），并确认派工 "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "margin-left": "15px",
                      "font-size": "13px",
                      color: "#999",
                    },
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning orange" }),
                    _vm._v(
                      "注：操作成功，有【施工派工】通知推送给项目的施工技师 "
                    ),
                  ]
                ),
              ]),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%", margin: "10px 0" },
                  attrs: { data: _vm.taskList, border: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "index", label: "序号", width: "60" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "项目名称",
                      width: "200",
                      prop: "serviceItemName",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "项目编码",
                      width: "120",
                      prop: "serviceItemCode",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "内部标签", width: "140", prop: "tags" },
                  }),
                  _c(
                    "el-table-column",
                    {
                      attrs: { label: "施工技师" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row, $index }) {
                            return [
                              _c("div", { key: _vm.cellKey }, [
                                row.technicianList
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            row.technicianList
                                              .map((item) => item.realName)
                                              .join("、")
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                                row.isConstructionFree
                                  ? _c("span", [_vm._v("免施工")])
                                  : _vm._e(),
                                !row.technicianList.length &&
                                !row.isConstructionFree
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "blue pointer",
                                        on: {
                                          click: function ($event) {
                                            return _vm.showSetDialog($index)
                                          },
                                        },
                                      },
                                      [_vm._v("未设置，点击设置")]
                                    )
                                  : _c("i", {
                                      staticClass:
                                        "iconfont grow-a-icon_fillout color666 el-icon--right pointer",
                                      on: {
                                        click: function ($event) {
                                          return _vm.showSetDialog($index)
                                        },
                                      },
                                    }),
                              ]),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c("span", [_vm._v("施工技师")]),
                        _c("i", {
                          staticClass:
                            "iconfont grow-a-icon_fillout color666 el-icon--right pointer",
                          staticStyle: { "font-size": "14px" },
                          on: {
                            click: _vm.showSelectTechniciansDialogForAllItem,
                          },
                        }),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.handleClose } },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleConfirm },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm.selectTechniciansDialogVisibility
        ? _c("select-technicians-dialog", {
            attrs: { visibility: _vm.selectTechniciansDialogVisibility },
            on: {
              "update:visibility": function ($event) {
                _vm.selectTechniciansDialogVisibility = $event
              },
              select: _vm.handleSelect,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }