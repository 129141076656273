var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "bg" }, [
    _c(
      "div",
      { staticClass: "box filter" },
      [
        _c(
          "el-form",
          [
            _c(
              "el-form-item",
              { attrs: { label: "统计周期" } },
              [
                _c("el-date-picker", {
                  attrs: {
                    type: "month",
                    placeholder: "请选择统计周期",
                    clearable: false,
                    "value-format": "yyyy-MM",
                  },
                  model: {
                    value: _vm.form.statisticsPeriod,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "statisticsPeriod", $$v)
                    },
                    expression: "form.statisticsPeriod",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "业务类型" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      "value-key": "value",
                      placeholder: "请选择",
                      filterable: "",
                    },
                    on: { change: _vm.typeChange },
                    model: {
                      value: _vm.form.billType,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "billType", $$v)
                      },
                      expression: "form.billType",
                    },
                  },
                  _vm._l(_vm.typeList, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "el-form-item--small" },
          [
            _c(
              "el-button",
              {
                attrs: { size: "small", icon: "el-icon-camera-solid" },
                on: { click: _vm.getScreen },
              },
              [_vm._v("截图")]
            ),
            _c(
              "el-button",
              {
                staticClass: "btn_search",
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.doSearch },
              },
              [_vm._v("查询")]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { ref: "printDom" }, [
      _c("div", { staticClass: "box chart" }, [
        _c("div", { staticClass: "left" }, [
          _vm.storeList.length
            ? _c("div", { staticClass: "first" }, [
                _c("div", { staticClass: "company-name" }, [
                  _vm._v(
                    " " + _vm._s(_vm.singleTypeRankList[0].companyName) + " "
                  ),
                ]),
                _c("div", { staticClass: "value" }, [
                  _vm._v(
                    " 毛利率：" +
                      _vm._s(_vm.singleTypeRankList[0].grossProfitRate) +
                      "% "
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.storeList.length
            ? _c("div", { staticClass: "last" }, [
                _c("div", { staticClass: "company-name" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.singleTypeRankList.at(-1).companyName) +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "value" }, [
                  _vm._v(
                    " 毛利率：" +
                      _vm._s(_vm.singleTypeRankList.at(-1).grossProfitRate) +
                      "% "
                  ),
                ]),
              ])
            : _vm._e(),
        ]),
        _c("div", { staticClass: "right" }, [
          _c("div", { staticClass: "title" }, [_vm._v("月度毛利率")]),
          _c("div", {
            ref: "chart",
            staticStyle: { width: "100%", height: "200px" },
          }),
        ]),
      ]),
      _c("div", { staticClass: "box list" }, [
        _c("div", { staticClass: "top" }, [
          _c("div", { staticClass: "title" }, [_vm._v("门店毛利明细")]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.showAll,
                  expression: "!showAll",
                },
              ],
              staticClass: "legend",
            },
            [_c("div", { staticClass: "icon" }), _c("span", [_vm._v("毛利率")])]
          ),
        ]),
        _c(
          "div",
          { staticClass: "content" },
          [
            _vm.showAll
              ? _c(
                  "el-table",
                  {
                    attrs: {
                      border: "",
                      data: _vm.storeList,
                      "show-summary": "",
                      "summary-method": _vm.getSummaries,
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { label: "排名", width: "70", align: "center" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                row.totalRanking === 1
                                  ? _c("img", {
                                      staticClass: "table-img",
                                      attrs: {
                                        src: require("@/assets/img/icon_win.png"),
                                      },
                                    })
                                  : row.totalRanking === _vm.storeList.length
                                  ? _c("img", {
                                      staticClass: "table-img",
                                      attrs: {
                                        src: require("@/assets/img/icon_lose.png"),
                                      },
                                    })
                                  : _c("span", [
                                      _vm._v(_vm._s(row.totalRanking)),
                                    ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        568408427
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "门店",
                        prop: "companyName",
                        "min-width": "140",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass: "blue pointer",
                                    on: {
                                      click: function ($event) {
                                        return _vm.navGrossProfitDetail(row)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(row.companyName))]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1085364426
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "综合毛利",
                        prop: "totalGrossProfitRate",
                        "min-width": "100",
                        formatter: _vm.addPercent,
                      },
                    }),
                    _c(
                      "el-table-column",
                      { attrs: { label: "自费", align: "center" } },
                      [
                        _c("el-table-column", {
                          attrs: {
                            label: "毛利率",
                            prop: "selfPayGrossProfitRate",
                            "min-width": "80",
                            formatter: _vm.addPercent,
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "排名",
                            prop: "selfPayRanking",
                            "min-width": "80",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    row.selfPayRanking === 1
                                      ? _c("img", {
                                          staticClass: "table-img",
                                          attrs: {
                                            src: require("@/assets/img/icon_win.png"),
                                          },
                                        })
                                      : row.selfPayRanking ===
                                        _vm.storeList.length
                                      ? _c("img", {
                                          staticClass: "table-img",
                                          attrs: {
                                            src: require("@/assets/img/icon_lose.png"),
                                          },
                                        })
                                      : _c("span", [
                                          _vm._v(_vm._s(row.selfPayRanking)),
                                        ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            533266269
                          ),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-table-column",
                      { attrs: { label: "包干", align: "center" } },
                      [
                        _c("el-table-column", {
                          attrs: {
                            label: "毛利率",
                            prop: "contractForGrossProfitRate",
                            "min-width": "80",
                            formatter: _vm.addPercent,
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "排名",
                            prop: "contractForRanking",
                            "min-width": "80",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    row.contractForRanking === 1
                                      ? _c("img", {
                                          staticClass: "table-img",
                                          attrs: {
                                            src: require("@/assets/img/icon_win.png"),
                                          },
                                        })
                                      : row.contractForRanking ===
                                        _vm.storeList.length
                                      ? _c("img", {
                                          staticClass: "table-img",
                                          attrs: {
                                            src: require("@/assets/img/icon_lose.png"),
                                          },
                                        })
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(row.contractForRanking)
                                          ),
                                        ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1129313088
                          ),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-table-column",
                      { attrs: { label: "理赔", align: "center" } },
                      [
                        _c("el-table-column", {
                          attrs: {
                            label: "毛利率",
                            prop: "claimSettlementGrossProfitRate",
                            "min-width": "80",
                            formatter: _vm.addPercent,
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "排名",
                            prop: "claimSettlementRanking",
                            "min-width": "80",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    row.claimSettlementRanking === 1
                                      ? _c("img", {
                                          staticClass: "table-img",
                                          attrs: {
                                            src: require("@/assets/img/icon_win.png"),
                                          },
                                        })
                                      : row.claimSettlementRanking ===
                                        _vm.storeList.length
                                      ? _c("img", {
                                          staticClass: "table-img",
                                          attrs: {
                                            src: require("@/assets/img/icon_lose.png"),
                                          },
                                        })
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(row.claimSettlementRanking)
                                          ),
                                        ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3477609038
                          ),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-table-column",
                      { attrs: { label: "索赔", align: "center" } },
                      [
                        _c("el-table-column", {
                          attrs: {
                            label: "毛利率",
                            prop: "claimForCompensationGrossProfitRate",
                            "min-width": "80",
                            formatter: _vm.addPercent,
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "排名",
                            prop: "claimForCompensationRanking",
                            "min-width": "80",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    row.claimForCompensationRanking === 1
                                      ? _c("img", {
                                          staticClass: "table-img",
                                          attrs: {
                                            src: require("@/assets/img/icon_win.png"),
                                          },
                                        })
                                      : row.claimForCompensationRanking ===
                                        _vm.storeList.length
                                      ? _c("img", {
                                          staticClass: "table-img",
                                          attrs: {
                                            src: require("@/assets/img/icon_lose.png"),
                                          },
                                        })
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              row.claimForCompensationRanking
                                            )
                                          ),
                                        ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1577324396
                          ),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "list" },
                  _vm._l(_vm.singleTypeRankList, function (item, index) {
                    return _c("div", { key: index, staticClass: "item" }, [
                      _c("div", { staticClass: "index" }, [
                        index === 0
                          ? _c("img", {
                              staticClass: "table-img",
                              attrs: {
                                src: require("@/assets/img/icon_win.png"),
                              },
                            })
                          : index === _vm.singleTypeRankList.length - 1
                          ? _c("img", {
                              staticClass: "table-img",
                              attrs: {
                                src: require("@/assets/img/icon_lose.png"),
                              },
                            })
                          : _c("span", [_vm._v(_vm._s(index + 1))]),
                      ]),
                      _c("div", { staticClass: "company-name text-ellipsis" }, [
                        _vm._v(" " + _vm._s(item.companyName) + " "),
                      ]),
                      _c("div", { staticClass: "progress-box" }, [
                        _c("div", {
                          staticClass: "progress",
                          style: {
                            width: Math.max(item.grossProfitRate || 0, 0) + "%",
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "value" }, [
                        _vm._v(_vm._s(item.grossProfitRate || 0) + "%"),
                      ]),
                    ])
                  }),
                  0
                ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }