import Vue from 'vue';
import Vuex from 'vuex';

// modules
import tags from '@/store/modules/tags';
import user from '@/store/modules/user';

import tagsState from '@/store/modules/tags/defaultState';
import userState from '@/store/modules/user/defaultState';

const globalDefaultState = () => ({
  tags: tagsState(),
  user: userState(),
});

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {
    RESET_STATE(state) {
      Object.assign(state, globalDefaultState());
    },
  },
  actions: {},
  modules: {
    tags,
    user,
  },
});
