var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "span",
        {
          class: [_vm.pointerShow ? "pointer blue" : ""],
          on: { click: _vm.goBillDetail },
        },
        [_vm._v(" " + _vm._s(_vm.billCode) + " ")]
      ),
      _vm._t("append"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }