var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "upload-list_box" },
    _vm._l(_vm.fileList, function (item, index) {
      return _c("upload-file", {
        key: index,
        attrs: {
          index: index,
          disabled: _vm.disabled,
          customDelImg: _vm.handleDelImg,
          imagesForPreview: _vm.imagesForPreview.length
            ? _vm.imagesForPreview
            : _vm.imgs,
        },
        on: { upload: _vm.handleUpload },
        model: {
          value: item.value,
          callback: function ($$v) {
            _vm.$set(item, "value", $$v)
          },
          expression: "item.value",
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }