var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { ref: "baseContainer", attrs: { paddingTop: "" } },
    [
      [
        _c(
          "el-form",
          {
            staticClass: "filter-form",
            attrs: { model: _vm.form, "label-width": "100px", size: "small" },
          },
          [
            _c("div", { staticClass: "flex-x-between" }, [
              _c(
                "div",
                { staticClass: "flex flex-shrink0" },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c(
                          "div",
                          [
                            _vm._v(" 操作门店 "),
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  effect: "light",
                                  content:
                                    "接车单的操作门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)",
                                  placement: "bottom-start",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "iconfont grow-icon_doubt",
                                  staticStyle: { color: "#9a9a9a" },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("operateCompanyCascader", {
                        attrs: {
                          operateCompanyUrl: _vm.form.operateCompanyUrl,
                        },
                        model: {
                          value: _vm.form.companyIds,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "companyIds", $$v)
                          },
                          expression: "form.companyIds",
                        },
                      }),
                    ],
                    2
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "供应商" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            clearable: "",
                            remote: "",
                            "remote-method": _vm.searchSupplierList,
                            loading: _vm.searchSupplierListLoading,
                            placeholder: "请选择供应商",
                          },
                          model: {
                            value: _vm.form.supplierId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "supplierId", $$v)
                            },
                            expression: "form.supplierId",
                          },
                        },
                        _vm._l(_vm.supplierList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "单据号" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入单据号", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.getData.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.form.inventoryInAndOutBillCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "inventoryInAndOutBillCode", $$v)
                          },
                          expression: "form.inventoryInAndOutBillCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "el-form-item--small" }, [
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "auth",
                            rawName: "v-auth",
                            value: 629,
                            expression: "629",
                          },
                        ],
                        staticClass: "btn_search",
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.getData },
                      },
                      [_vm._v("查询")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn_search",
                        attrs: { size: "small" },
                        on: { click: _vm.empty },
                      },
                      [_vm._v("置空")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "flex-warp" },
              [
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品标准名" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入商品标准名",
                          clearable: "",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.getData.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.form.goodsStandardName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "goodsStandardName", $$v)
                          },
                          expression: "form.goodsStandardName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "创建时间" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "w100",
                        attrs: {
                          type: "daterange",
                          "unlink-panels": "",
                          "range-separator": "至",
                          "start-placeholder": "起始时间",
                          "end-placeholder": "截止时间",
                          "value-format": "yyyy-MM-dd",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.dateRangeChange()
                          },
                        },
                        model: {
                          value: _vm.form.dateCreateds,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "dateCreateds", $$v)
                          },
                          expression: "form.dateCreateds",
                        },
                      }),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        ),
      ],
      [
        _c(
          "div",
          {
            staticClass: "btns flex-x-between",
            staticStyle: { margin: "30px 0 14px 0" },
          },
          [
            _c("div"),
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 713,
                    expression: "713",
                  },
                ],
                staticClass: "blue",
                attrs: { size: "small", type: "text" },
                on: {
                  click: function ($event) {
                    _vm.showExportDialog = true
                  },
                },
              },
              [
                _vm._v("导出列表"),
                _c("i", {
                  staticClass: "iconfont grow-icon_daochu el-icon--right",
                }),
              ]
            ),
          ],
          1
        ),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "fixedScrollBar",
                rawName: "v-fixedScrollBar",
                value: _vm.$refs.baseContainer,
                expression: "$refs.baseContainer",
              },
            ],
            staticClass: "custom-table",
            attrs: { data: _vm.listData, border: "" },
          },
          [
            _c("el-table-column", {
              attrs: {
                type: "index",
                label: "序号",
                width: "60",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: { width: "200", label: "操作门店", prop: "companyName" },
            }),
            _c("el-table-column", {
              attrs: {
                width: "150",
                label: "单据号",
                prop: "purchaseBillCode",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "100",
                label: "单据类型",
                prop: "purchaseBillTypeDesc",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "150",
                label: "供应商名称",
                prop: "supplierName",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "150",
                label: "供货单号",
                prop: "supplyBillCode",
              },
            }),
            _c("el-table-column", {
              attrs: { width: "200", label: "创建时间", prop: "dateCreated" },
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "商品ID", prop: "goodsId" },
            }),
            _c("el-table-column", {
              attrs: {
                width: "200",
                label: "商品标准名",
                prop: "goodsStandardName",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "100",
                label: "品质",
                prop: "manufactoryTypeDesc",
              },
            }),
            _c("el-table-column", {
              attrs: { width: "150", label: "原厂编号", prop: "goodsCode" },
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "单位", prop: "unit" },
            }),
            _c("el-table-column", {
              attrs: {
                width: "100",
                label: "当前采购价",
                prop: "purchasePrice",
              },
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "数量", prop: "purchaseNum" },
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "金额合计", prop: "totalAmount" },
            }),
            _c("el-table-column", {
              attrs: {
                width: "100",
                label: "采购上限价",
                prop: "purchaseCeilingPrice",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "100",
                label: "采购价备注",
                prop: "purchasePriceRemark",
              },
            }),
          ],
          1
        ),
        _c("export-dialog", {
          attrs: {
            visibility: _vm.showExportDialog,
            total: _vm.total,
            pageOption: _vm.pageOption,
            filterForm: _vm.exportForm,
            url: _vm.exportUrl,
          },
          on: {
            "update:visibility": function ($event) {
              _vm.showExportDialog = $event
            },
          },
        }),
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c("pagination", {
            attrs: { total: _vm.total },
            on: { pageChange: _vm.changePage },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }