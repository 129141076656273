var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    [
      _c(
        "div",
        { staticClass: "flex" },
        [
          _c(
            "el-table",
            {
              staticClass: "custom-table",
              attrs: { data: _vm.lossWaringDate, border: "" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  width: "60",
                  label: "序号",
                  type: "index",
                  index: _vm.indexMethod,
                },
              }),
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  label: "定损状态",
                  prop: "lossReportedState",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("div", [
                          _c("span", [_vm._v(_vm._s(row.lossReportedState))]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { width: "120", label: "提醒文案", prop: "remind" },
              }),
              _c("el-table-column", {
                attrs: { width: "330", label: "提醒设置" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "div",
                          { staticClass: "flex" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "flex",
                                staticStyle: {
                                  "margin-top": "10px",
                                  "margin-right": "20px",
                                },
                              },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: "0" },
                                    model: {
                                      value: row.state,
                                      callback: function ($$v) {
                                        _vm.$set(row, "state", $$v)
                                      },
                                      expression: "row.state",
                                    },
                                  },
                                  [_vm._v("不提醒")]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: "1" },
                                    model: {
                                      value: row.state,
                                      callback: function ($$v) {
                                        _vm.$set(row, "state", $$v)
                                      },
                                      expression: "row.state",
                                    },
                                  },
                                  [_vm._v("提醒")]
                                ),
                              ],
                              1
                            ),
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入整数",
                                maxlength: 6,
                              },
                              model: {
                                value: row.time,
                                callback: function ($$v) {
                                  _vm.$set(row, "time", _vm._n($$v))
                                },
                                expression: "row.time",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  width: "120",
                  label: "提醒节点",
                  prop: "reminderNode",
                },
              }),
              _c("el-table-column", {
                attrs: { width: "90", label: "时间", prop: "timeUnit" },
              }),
              _c("el-table-column", {
                attrs: { "mix-width": "280", label: "备注", prop: "remark" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("div", [
                          row.stateNum == 0
                            ? _c("p", [
                                _vm._v(
                                  " 进店 " +
                                    _vm._s(row.time) +
                                    " 分钟后仍为“待报损”状态将提示“提报超期” "
                                ),
                              ])
                            : _vm._e(),
                          row.stateNum == 1
                            ? _c("p", [
                                _vm._v(
                                  " 报损 " +
                                    _vm._s(row.time) +
                                    " 分钟后仍为“待调度”状态将提示“调度超期” "
                                ),
                              ])
                            : _vm._e(),
                          row.stateNum == 2
                            ? _c("p", [
                                _vm._v(
                                  " 进店 " +
                                    _vm._s(row.time) +
                                    " 天后仍为“待拍照对件”状态将提示“对件超期” "
                                ),
                              ])
                            : _vm._e(),
                          row.stateNum == 3
                            ? _c("p", [
                                _vm._v(
                                  " 拍照对件 " +
                                    _vm._s(row.time) +
                                    " 小时后仍为“待提定损“状态将提示”定损超期“ "
                                ),
                              ])
                            : _vm._e(),
                          row.stateNum == 4
                            ? _c("p", [
                                _vm._v(
                                  " 进店 " +
                                    _vm._s(row.time) +
                                    " 天后仍为“待出定损”状态将提示“出损超期” "
                                ),
                              ])
                            : _vm._e(),
                          row.stateNum == 5
                            ? _c("p", [
                                _vm._v(
                                  " 进店 " +
                                    _vm._s(row.time) +
                                    " 天后仍为“已出定损”状态将提示“回款超期” "
                                ),
                              ])
                            : _vm._e(),
                          row.stateNum == 6
                            ? _c("p", [
                                _vm._v(
                                  " 已出定损 " +
                                    _vm._s(row.time) +
                                    " 小时后仍为“待复勘”状态将提示“复勘超期” "
                                ),
                              ])
                            : _vm._e(),
                          row.stateNum == 7
                            ? _c("p", [
                                _vm._v(
                                  " 已出定损 " +
                                    _vm._s(row.time) +
                                    " 小时后仍为“待收旧”状态将提示“收旧超期” "
                                ),
                              ])
                            : _vm._e(),
                          row.stateNum == 8
                            ? _c("p", [
                                _vm._v(
                                  " 进店 " +
                                    _vm._s(row.time) +
                                    " 天后不在“全部回款”状态将提示“回款超期” "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "当日开单计时",
                  prop: "dailyBillingTiming",
                  width: "280",
                  align: "center",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          { staticClass: "flex-x-end foot-btn" },
          [
            _c(
              "el-button",
              {
                staticStyle: { "margin-right": "20px" },
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.save },
              },
              [_vm._v("保存")]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }