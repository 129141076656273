import http from "@/service/http";

const defaultListOption = {
  current: 1,
  size: 10,
};

/**
 * 毛利明细排行榜
 * @param {*} data
 */
export function grossProfitRankingList(data = {}) {
  return http.post("/admin/data/board/grossProfitRankingList", data);
}

/**
 * 月度毛利率趋势
 * @param {*} data
 */
export function monthlyGrossMarginTrend(data = {}) {
  return http.post("/admin/data/board/monthlyGrossMarginTrend", data);
}

/**
 * 月度综合毛利率
 * @param {*} data
 */
export function monthlyConsolidatedGrossMargin(data = {}) {
  return http.post("/admin/data/board/monthlyConsolidatedGrossMargin", data);
}

/**
 * 故障分诊明细
 * @param {*} data
 */
export function getFaultDiagnosisDetail(data = {}) {
  return http.post("/admin/data/board/faultDiagnosisDetail", data);
}

/**
 * 故障分诊明细导出
 */
export const faultDiagnosisDetailExport = "/admin/data/board/faultDiagnosisDetail/export";

/**
 * 理赔应收汇总列表
 * @param {*} data
 */
export function getAccountsSummaryList(data = {}) {
  return http.post("/admin/data/board/summaryOfClaimsFollowup/list", data);
}

/**
 * 理赔应收汇总导出
 * @param {*} data
 */
export const getAccountsSummaryExportUrl = "/admin/data/board/summaryOfClaimsFollowup/list/export";

/**
 * 索赔应收汇总列表
 * @param {*} data
 */
export function getClaimsSummaryList(data = {}) {
  return http.post("/admin/data/board/summaryOfCompensationFollowup/list", data);
}

/**
 * 索赔应收汇总导出
 * @param {*} data
 */
export const getClaimsSummaryExportUrl = "/admin/data/board/summaryOfCompensationFollowup/list/export";

/**
 * 索赔应收汇总配置列表
 * @param {*} data
 */
export function getClaimsSummarySettingList(data = {}) {
  return http.post("/admin/data/board/summaryOfCompensationFollowup/settings/list", data);
}

/**
 * 索赔应收汇总配置列表编辑
 * @param {*} data
 */
export function getClaimsSummarySettingEdit(data = {}) {
  return http.post("/admin/data/board/summaryOfCompensationFollowup/settings/update", data);
}
