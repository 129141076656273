var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("p", { staticClass: "undertake-title" }, [
      _vm._v(" 包干金额分摊规则说明（仅适用“固定金额”、“固定单价”模式）： "),
    ]),
    _vm._m(0),
    _vm._m(1),
    _c(
      "div",
      {
        staticClass: "flex-y-center",
        staticStyle: { height: "32px", margin: "30px 0" },
      },
      [
        _c("el-switch", { attrs: { value: _vm.detail.beContract } }),
        _c("p", { staticStyle: { "margin-left": "15px", color: "#999" } }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.detail.beContract ? "已启用包干业务" : "未启用包干业务"
              ) +
              " "
          ),
        ]),
      ],
      1
    ),
    _vm.detail.undertakeType == 0
      ? _c("div", [
          _c("div", { staticClass: "title" }, [_vm._v("固定金额")]),
          _c("div", { staticClass: "title" }, [
            _vm._v("包干金额每月固定，不限车辆台数"),
          ]),
          _c("div", { staticClass: "type1" }, [
            _c("div", [
              _vm._v(
                "首月包干金额：" +
                  _vm._s(_vm._f("toThousands")(_vm.detail.firstMonthPayment))
              ),
            ]),
            _c("div", [
              _vm._v(
                "中间月包干金额：" +
                  _vm._s(_vm._f("toThousands")(_vm.detail.middMonthPayment))
              ),
            ]),
            _c("div", [
              _vm._v(
                "末月包干金额：" +
                  _vm._s(_vm._f("toThousands")(_vm.detail.endMonthPayment))
              ),
            ]),
          ]),
        ])
      : _vm.detail.undertakeType == 1
      ? _c(
          "div",
          [
            _c("div", { staticClass: "title" }, [_vm._v("固定单价")]),
            _c("div", { staticClass: "flex-x-between flex-y-center" }, [
              _c("div", { staticClass: "title" }, [
                _vm._v(
                  " 包干金额每月非固定，每月包干金额 = 每月包干车辆台数 x 包干单价 "
                ),
              ]),
              _c("div", { staticClass: "title price" }, [
                _vm._v(
                  " 包干单价: " +
                    _vm._s(
                      _vm._f("toThousands")(
                        _vm.$lodash.get(_vm.tableData, "[0].moneyAmout", 0)
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c(
              "el-table",
              { attrs: { data: _vm.tableData, border: "" } },
              [
                _c("el-table-column", {
                  attrs: { type: "index", label: "序号", width: "100" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "f1", label: "月份" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [_vm._v(_vm._s(row.title))]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { prop: "carAmount", label: "包干车辆（台）" },
                }),
                _c("el-table-column", {
                  attrs: { label: "包干单价" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _vm._v(_vm._s(_vm._f("toThousands")(row.moneyAmout))),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "包干金额" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _vm._v(
                            _vm._s(
                              _vm._f("toThousands")(
                                row.carAmount * row.moneyAmout
                              )
                            )
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        )
      : _vm.detail.undertakeType == 2
      ? _c("div", [
          _c("div", { staticClass: "title" }, [_vm._v("单据金额比例")]),
          _c("div", { staticClass: "title" }, [
            _vm._v(
              " 包干金额每月按单据金额百分比，每月包干金额=每月包干单据金额 x 比例 "
            ),
          ]),
          _c("div", { staticClass: "type1" }, [
            _vm._v("比例（%）：" + _vm._s(_vm.detail.contractRatio)),
          ]),
        ])
      : _vm.detail.undertakeType == 3
      ? _c("div", [
          _c("div", { staticClass: "title" }, [_vm._v("理赔返点比例")]),
          _c("div", { staticClass: "title" }, [
            _vm._v("包干金额 = 当月已结账理赔单据金额 * 比例"),
          ]),
          _c("div", { staticClass: "type1" }, [
            _vm._v("比例（%）：" + _vm._s(_vm.detail.returnPoint)),
          ]),
        ])
      : _vm._e(),
    [0, 1].includes(_vm.detail.vehicleRange)
      ? _c("div", { staticClass: "title" }, [_vm._v(" 车辆范围 ")])
      : _vm._e(),
    [0, 1].includes(_vm.detail.vehicleRange)
      ? _c(
          "div",
          { staticClass: "type1" },
          [
            _vm._v(
              " " +
                _vm._s(_vm.detail.vehicleRange ? "部分车辆" : "全部车辆") +
                " "
            ),
            _vm.detail.beContract && _vm.detail.vehicleRange
              ? _c(
                  "el-button",
                  {
                    staticStyle: { "margin-left": "10px" },
                    attrs: { type: "primary" },
                    on: { click: _vm.exportContractCarList },
                  },
                  [_vm._v("导出车辆清单")]
                )
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
    _vm.carScopeImportResult
      ? _c("div", [
          _c("p", { staticClass: "result" }, [
            _vm._v(" 成功"),
            _c("span", { staticClass: "primary" }, [
              _vm._v(_vm._s(_vm.carScopeImportResult.importSuccessNum) + "条"),
            ]),
            _vm._v("，失败"),
            _c("span", { staticClass: "red" }, [
              _vm._v(_vm._s(_vm.carScopeImportResult.importFailNum) + "条"),
            ]),
            _vm._v("； "),
          ]),
          _c("p", { staticClass: "result" }, [
            _vm._v(
              " 操作人：" +
                _vm._s(_vm.carScopeImportResult.operator) +
                "，操作时间：" +
                _vm._s(_vm.carScopeImportResult.operateDate) +
                " "
            ),
            _vm.carScopeImportResult.importFailNum
              ? _c(
                  "span",
                  {
                    staticClass: "blue pointer",
                    on: { click: _vm.downloadCarScopeImportResult },
                  },
                  [_vm._v("查看详情")]
                )
              : _vm._e(),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tip-block" }, [
      _c("p", { staticClass: "tip-title" }, [_vm._v("1、结账当月预分摊制：")]),
      _c("p", { staticClass: "tip-line" }, [
        _vm._v("M日预分摊金额 = 包干总额 ÷ 当月天数 × M"),
      ]),
      _c("p", { staticClass: "tip-line" }, [
        _vm._v(
          " 单笔分摊金额=单笔结算金额 ×（M日预分摊金额÷M日累计结算金额） "
        ),
      ]),
      _c("p", { staticClass: "tip-line" }, [
        _vm._v(" 单项分摊金额=单项结算总额 ×（单笔分摊金额÷单笔结算金额） "),
      ]),
      _c("p", { staticClass: "tip-line" }, [
        _vm._v(" 注：仅M日累计结算金额大于M日预分摊金额时才进行预分摊 "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tip-block" }, [
      _c("p", { staticClass: "tip-title" }, [
        _vm._v("2、结账次月开始正式分摊制："),
      ]),
      _c("p", { staticClass: "tip-line" }, [
        _vm._v(
          " 结账次月，自动跑批，形成正式分摊，本月分摊金额完结，次月结算的单据纳入下个月的包干分摊金额计算。 "
        ),
      ]),
      _c("p", { staticClass: "tip-line" }, [
        _vm._v("注：仅累计结算金额大于包干总额时才进行分摊"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }