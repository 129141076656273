var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { ref: "baseContainer", attrs: { paddingTop: "" } },
    [
      [
        _c(
          "div",
          [
            _c(
              "el-form",
              {
                staticClass: "filter-form",
                attrs: {
                  model: _vm.form,
                  "label-width": "100px",
                  size: "small",
                },
              },
              [
                _c("div", { staticClass: "flex-x-between" }, [
                  _c(
                    "div",
                    { staticClass: "flex-warp flex-shrink0" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "车牌号" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入", clearable: "" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.doSearch.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.form.carNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "carNumber", $$v)
                              },
                              expression: "form.carNumber",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c("template", { slot: "label" }, [
                            _c(
                              "div",
                              [
                                _vm._v(" 操作门店 "),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "light",
                                      content:
                                        "接车单的操作门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)",
                                      placement: "bottom-start",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "iconfont grow-icon_doubt",
                                      staticStyle: { color: "#9a9a9a" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("operateCompanyCascader", {
                            attrs: {
                              operateCompanyUrl: _vm.form.operateCompanyUrl,
                            },
                            model: {
                              value: _vm.form.operateCompanyId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "operateCompanyId", $$v)
                              },
                              expression: "form.operateCompanyId",
                            },
                          }),
                        ],
                        2
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "接车进店日期" } },
                        [
                          _c("el-date-picker", {
                            staticClass: "w100",
                            attrs: {
                              type: "daterange",
                              "unlink-panels": "",
                              "range-separator": "至",
                              "start-placeholder": "起始时间",
                              "end-placeholder": "截止时间",
                              "value-format": "yyyy-MM-dd",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.dateRangeChange()
                              },
                            },
                            model: {
                              value: _vm.form.intoStoreTimes,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "intoStoreTimes", $$v)
                              },
                              expression: "form.intoStoreTimes",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "flex" }, [
                    _c("div", { staticClass: "el-form-item--small" }, [
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "btn_search",
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.doSearch },
                            },
                            [_vm._v("查询")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "btn_search",
                              attrs: { size: "small" },
                              on: { click: _vm.empty },
                            },
                            [_vm._v("置空")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "flex-warp" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "接车员" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              filterable: "",
                              clearable: "",
                              remote: "",
                              placeholder: "搜索姓名、手机号",
                              "remote-method": _vm.searchAdminJobListUser,
                              loading: _vm.searchAdminJobListUserLoading,
                            },
                            on: { change: _vm.selectAdminJobListUser },
                            model: {
                              value: _vm.form.pickUpUserId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "pickUpUserId", $$v)
                              },
                              expression: "form.pickUpUserId",
                            },
                          },
                          _vm._l(_vm.adminJobListUserList, function (item) {
                            return _c(
                              "el-option",
                              {
                                key: item.userId,
                                attrs: {
                                  label: item.realName,
                                  value: item.userId,
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      float: "left",
                                      color: "#8492a6",
                                      "font-size": "13px",
                                    },
                                  },
                                  [_vm._v(_vm._s(item.realName))]
                                ),
                                _c(
                                  "span",
                                  { staticStyle: { float: "right" } },
                                  [_vm._v(_vm._s(item.phone))]
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "签约客户" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              filterable: "",
                              clearable: "",
                              remote: "",
                              placeholder: "请输入客户名称",
                              "remote-method": _vm.searchClient,
                              loading: _vm.searchClientLoading,
                            },
                            on: { change: _vm.selectClient },
                            model: {
                              value: _vm.form.clientId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "clientId", $$v)
                              },
                              expression: "form.clientId",
                            },
                          },
                          _vm._l(_vm.customList, function (item) {
                            return _c(
                              "el-option",
                              {
                                key: item.id,
                                attrs: {
                                  label: item.clientName,
                                  value: item.id,
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      float: "left",
                                      color: "#8492a6",
                                      "font-size": "13px",
                                    },
                                  },
                                  [_vm._v(_vm._s(item.clientName))]
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "滞留日期" } },
                      [
                        _c("el-date-picker", {
                          staticClass: "w100",
                          attrs: {
                            type: "daterange",
                            "unlink-panels": "",
                            "range-separator": "至",
                            "start-placeholder": "起始时间",
                            "end-placeholder": "截止时间",
                            "value-format": "yyyy-MM-dd",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.dateRangeChange()
                            },
                          },
                          model: {
                            value: _vm.form.retentionStartTimes,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "retentionStartTimes", $$v)
                            },
                            expression: "form.retentionStartTimes",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ],
      [
        _c("div", { staticClass: "btns flex-x-between" }, [
          _c(
            "div",
            { staticStyle: { "margin-bottom": "15px", "font-size": "14px" } },
            [
              _c("i", {
                staticClass: "pointer iconfont grow-icon_warn_32",
                staticStyle: {
                  color: "#ff9200",
                  "font-size": "15px",
                  margin: "5px 10px",
                },
              }),
              _c("span", { staticStyle: { color: "#909399" } }, [
                _vm._v("每日零点系统将统计记录前一天车辆的滞留快照情况"),
              ]),
            ]
          ),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "auth",
                      rawName: "v-auth",
                      value: 450,
                      expression: "450",
                    },
                  ],
                  staticClass: "blue",
                  attrs: { size: "small", type: "text" },
                  on: {
                    click: function ($event) {
                      _vm.showExportDialog = true
                    },
                  },
                },
                [
                  _vm._v("导出列表"),
                  _c("i", {
                    staticClass: "iconfont grow-icon_daochu el-icon--right",
                  }),
                ]
              ),
            ],
            1
          ),
        ]),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "fixedScrollBar",
                rawName: "v-fixedScrollBar",
                value: _vm.$refs.baseContainer,
                expression: "$refs.baseContainer",
              },
            ],
            staticClass: "custom-table",
            attrs: { data: _vm.carBillList, border: "" },
            on: {
              "row-dblclick": function ($event) {
                return _vm.goDetail($event.billId)
              },
            },
          },
          [
            _c("el-table-column", {
              attrs: { type: "index", label: "序号", width: "60" },
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "操作", prop: "carNumber" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "auth",
                              rawName: "v-auth",
                              value: 451,
                              expression: "451",
                            },
                          ],
                          staticClass: "detail-btn flex-center blue pointer",
                          on: {
                            click: function ($event) {
                              return _vm.handleEdit(scope.row)
                            },
                          },
                        },
                        [_vm._v(" 滞留详情 ")]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "120", label: "操作门店", prop: "companyName" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c(
                        "el-tooltip",
                        { attrs: { content: row.companyName } },
                        [
                          _c("div", { staticClass: "text-ellipsis-2-row" }, [
                            _vm._v(" " + _vm._s(row.companyName) + " "),
                          ]),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "车牌号", prop: "carNumber" },
            }),
            _c("el-table-column", {
              attrs: { width: "170", label: "车单号" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "blue pointer",
                          on: {
                            click: function ($event) {
                              return _vm.goDetail(row.billId)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(row.billCode) + " ")]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                width: "180",
                label: "车型/车架号",
                "show-overflow-tooltip": "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      row.carModelName
                        ? _c("div", { staticClass: "text-ellipsis" }, [
                            _vm._v(" " + _vm._s(row.carModelName) + " "),
                          ])
                        : _vm._e(),
                      row.carUniqueId
                        ? _c("div", { staticClass: "text-ellipsis" }, [
                            _vm._v(" " + _vm._s(row.carUniqueId) + " "),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                width: "120",
                label: "签约客户",
                "show-overflow-tooltip": "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("div", [
                        _c("div", { staticClass: "text-ellipsis" }, [
                          _vm._v(_vm._s(row.ownerName)),
                        ]),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "进店时间", prop: "intoStoreTime" },
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "接车员", prop: "pickUpCarName" },
            }),
            _c("el-table-column", {
              attrs: { "min-width": "120", label: "滞留时业务类型" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      row.billType !== 0
                        ? _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("dict")(row.billType, "carBillType")
                                ) +
                                " "
                            ),
                          ])
                        : _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  row.billSelfpayType == 0 ? "自费" : "他人自费"
                                ) +
                                " "
                            ),
                          ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { "min-width": "150", label: "滞留时预交车时间" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      row.undetermined == 1
                        ? _c("div", [_vm._v("待定")])
                        : _c("div", [
                            _c("p", [_vm._v(_vm._s(row.planFinishTime))]),
                            _c("p", [
                              _vm._v(_vm._s(row.waitInStore ? "在店等" : "")),
                            ]),
                          ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "140", label: "滞留时车单状态" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("div", [
                        _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("dict")(row.billState, "carBillState")
                              ) +
                              " "
                          ),
                          row.billOtherState
                            ? _c("span", { staticClass: "red" }, [
                                _vm._v("(" + _vm._s(row.billOtherState) + ")"),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "flex color626",
                          staticStyle: { "font-size": "12px" },
                        },
                        [
                          row.dispatchState !== undefined
                            ? _c("div", [
                                _c("span", [_vm._v(" /")]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("dict")(
                                        row.dispatchState,
                                        "dispatchState"
                                      )
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                "min-width": "120",
                label: "滞留|中断原因",
                prop: "strandedReason",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c(
                        "el-tooltip",
                        { attrs: { content: row.strandedReason } },
                        [
                          _c("div", { staticClass: "text-ellipsis-2-row" }, [
                            _vm._v(" " + _vm._s(row.strandedReason) + " "),
                          ]),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c(
              "el-table-column",
              {
                attrs: { width: "120", prop: "planCompletionTime" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("div", [_vm._v(_vm._s(row.planCompletionTime))]),
                      ]
                    },
                  },
                ]),
              },
              [
                _c("template", { slot: "header" }, [
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c("el-tooltip", { attrs: { content: "卡点解决时间" } }, [
                        _c("span", { staticClass: "text-ellipsis" }, [
                          _vm._v("卡点解决时间"),
                        ]),
                      ]),
                      _c(
                        "el-tooltip",
                        {
                          attrs: { effect: "light", placement: "bottom-start" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                color: "#666666",
                                "font-size": "13px",
                                "line-height": "16px",
                                "text-indent": "15px",
                              },
                              attrs: { slot: "content" },
                              slot: "content",
                            },
                            [_vm._v(" 当前阶段预计完成时间 ")]
                          ),
                          _c("i", {
                            staticClass: "iconfont grow-icon_doubt",
                            staticStyle: { "margin-left": "5px" },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
            _c("el-table-column", {
              attrs: {
                width: "100",
                label: "滞留报告人",
                prop: "reporterName",
              },
            }),
            _c(
              "el-table-column",
              { attrs: { width: "100", prop: "lastUpdated" } },
              [
                _c(
                  "template",
                  { slot: "header" },
                  [
                    _c("el-tooltip", { attrs: { content: "滞留更新时间" } }, [
                      _c("span", { staticClass: "text-ellipsis" }, [
                        _vm._v("滞留更新时间"),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              2
            ),
            _c("el-table-column", {
              attrs: { width: "150", label: "滞留日期", prop: "dateCreated" },
            }),
          ],
          1
        ),
        _c("export-dialog", {
          attrs: {
            visibility: _vm.showExportDialog,
            total: _vm.total,
            pageOption: _vm.pageOption,
            filterForm: _vm.form,
            url: _vm.exportUrl,
          },
          on: {
            "update:visibility": function ($event) {
              _vm.showExportDialog = $event
            },
          },
        }),
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c("pagination", {
            attrs: { total: _vm.total },
            on: { pageChange: _vm.changePage },
          }),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "车辆滞留详情",
            visible: _vm.stayDetailsDrawer,
            size: "60%",
            "append-to-body": true,
            "before-close": _vm.handleClose,
            modal: "",
            "close-on-press-escape": "",
            wrapperClosable: true,
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                "margin-left": "24px",
                width: "96%",
                overflow: "scroll",
                height: "100%",
              },
            },
            [
              _c("div", { staticClass: "report-sty" }, [
                _c("div", { staticClass: "flex" }, [
                  _c("div", { staticClass: "car-logo flex-shrink0" }, [
                    _vm.carInfo.carLogoIcon
                      ? _c("img", { attrs: { src: _vm.carInfo.carLogoIcon } })
                      : _c("img", {
                          attrs: { src: require("@/assets/img/carLogo.png") },
                        }),
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "margin-top": "3px",
                        "margin-left": "16px",
                      },
                    },
                    [
                      _c("div", { staticClass: "car-info" }, [
                        _c("span", { staticClass: "car-number" }, [
                          _vm._v(_vm._s(_vm.carInfo.carNumber)),
                        ]),
                        _vm.carInfo.carModelName
                          ? _c(
                              "span",
                              { staticClass: "car-info-tag car-brand" },
                              [_vm._v(_vm._s(_vm.carInfo.carModelName))]
                            )
                          : _vm._e(),
                      ]),
                      _c(
                        "p",
                        {
                          staticStyle: {
                            "margin-top": "16px",
                            "text-decoration": "underline",
                            color: "#303133",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.carInfo.ownerName) + " ")]
                      ),
                    ]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "billInfo flex-x-between" }, [
                _c("p", [_vm._v("单据号：" + _vm._s(_vm.carInfo.billCode))]),
                _c("p", [
                  _vm._v("进店时间：" + _vm._s(_vm.carInfo.intoStoreTime)),
                ]),
                _c("p", [
                  _vm._v("接车员：" + _vm._s(_vm.carInfo.pickUpCarName)),
                ]),
              ]),
              _c(
                "el-table",
                {
                  staticClass: "custom-table stay-table",
                  attrs: { data: _vm.detailedList.records, border: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      width: "100",
                      label: "滞留日期",
                      prop: "dateCreated",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      width: "100",
                      label: "滞留报告人",
                      prop: "reporterName",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      width: "160",
                      label: "滞留更新时间",
                      prop: "lastUpdated",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "140",
                      label: "滞留|中断原因",
                      prop: "strandedReason",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c(
                              "el-tooltip",
                              { attrs: { content: row.strandedReason } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "text-ellipsis-2-row" },
                                  [
                                    _vm._v(
                                      " " + _vm._s(row.strandedReason) + " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      width: "160",
                      label: "预计完成时间",
                      prop: "planCompletionTime",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      width: "160",
                      label: "滞留时预交车时间",
                      prop: "planFinishTime",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            row.undetermined == 1
                              ? _c("div", [_vm._v("待定")])
                              : _c("div", [
                                  _c("p", [_vm._v(_vm._s(row.planFinishTime))]),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(row.waitInStore ? "在店等" : "")
                                    ),
                                  ]),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      width: "130",
                      label: "滞留时业务类型",
                      prop: "billType",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            row.billType !== 0
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("dict")(
                                          row.billType,
                                          "carBillType"
                                        )
                                      ) +
                                      " "
                                  ),
                                ])
                              : _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        row.billSelfpayType == 0
                                          ? "自费"
                                          : "他人自费"
                                      ) +
                                      " "
                                  ),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      width: "130",
                      label: "滞留时车单状态",
                      prop: "billState",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("dict")(
                                      row.billState,
                                      "carBillState"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "footer" },
                [
                  _c("pagination", {
                    attrs: { total: _vm.detailTotal },
                    on: { pageChange: _vm.detailChangePage },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }