<template>
  <base-container :defaultPadding="false" :paddingTop="false" :customMainStyle="{'overflow':'hidden'}">
    <div class="flex flex-x-between">
      <div class="subTitle">{{ itemTitle }}({{ total }})</div>
      <div>
        <el-link type="primary" :disabled="previousDisabled" @click="changeCurrentPage('previous')">上一页</el-link>|
        <el-link type="primary" :disabled="nextDisabled" @click="changeCurrentPage('next')">下一页</el-link>
      </div>
    </div>
    <el-divider class="my-el-divider"></el-divider>
    <div class="itemContainer" v-if="total > 0">
      <ul class="ulContainer">
        <li v-for="(item, index) in listData" :key="index" @click="itemClick(item)" :class="[
          item.state !== 1 && difficultyType === 0 && item.inStoreOverTwoHours?'warnLiItem':'',
          item.state == 1 && item?.takeTime === undefined?'finishLiItem':'',
          'liItem',
          [0,4].includes(difficultyType)?'notAccident':''
        ]">
          <div class="carNumber">{{ item.carNumber }}</div>
          <div class="flex-x-between dispatchLine" v-if="difficultyType !== 2">
            <div class="shigu">{{ item.difficultyDesc }}</div>
            <div class="dispatchState">{{ item.dispatchStateDesc }}</div>
          </div>
          <div v-if="difficultyType === 1" class="carDetection">
            <div :title="item.carDetectionItemName">{{ item.carDetectionItemName }}</div>
          </div>
          <div class="flex" v-if="difficultyType === 2">
            <div class="shigu2">
              {{ item.difficultyDesc }}
            </div>
            <div class="accidentState">
              <div class="dispatchState">{{ item.dispatchStateDesc }}</div>
              <div class="carLossStateDesc">{{ item.lossDeterminationStateMergeDesc }}</div>
            </div>
          </div>
          <div class="itemFooterContainer">
            <p v-if="item.state === 1" class="waitTime">
              <template v-if="item.inStoreTodayTime">
                {{ item.dateCreated|delYmdAndSecond }}
              </template>
              <template v-else>
                {{ item.dateCreated|delSecond }}
              </template>
              <template v-if="item.finishTimeTodayTime">
                {{ item.finishTime|delYmdAndSecond }}
              </template>
              <template v-else>
                {{ item.finishTime|delSecond }}
              </template>
              ({{ item.inStoreWaitTimeStr }})
            </p>
            <p v-else-if="item.state !== 1" :class="[item.state !== 1 && difficultyType === 0 && item.isInStoreOverOneHalfHours?'warnWaitTime':'','waitTime']">
              <template v-if="item.inStoreTodayTime">
                {{ item.dateCreated|delYmdAndSecond }}
              </template>
              <template v-else>
                {{ item.dateCreated|delSecond }}
              </template>
              ({{ item.inStoreWaitTimeStr }})
            </p>
            <p class="pickUpUser">接待：{{ item.pickUpUserName }}</p>
          </div>
        </li>
      </ul>
    </div>
    <div style="text-align: center" v-else>
      没有数据
    </div>
  </base-container>
</template>

<script>
// 接口
import { getCompanyOverviewData } from '@/api/storeManagement';
import { hasAuth } from '@/utils/permissions';

export default {
  name: 'companyOverviewItem',
  components: {},
  props: {
    difficultyType: [Number, String],
    companyId: [Number, String],
    itemName: [String],
  },
  data() {
    return {
      itemTitle: '2',
      total: 0,
      listData: [],
      current: 1,
      size: 5,
    };
  },
  watch: {
    totalPage: function () {
      this.current = 1;
    },
  },
  computed: {
    totalPage: function () {
      const tp = Math.ceil(this.total / this.size);
      console.log('总页', this.itemName, tp);
      return tp;
    },
    previousDisabled: {
      get() {
        return this.current === 1;
      },
      set() {},
    },
    nextDisabled: {
      get() {
        return this.current === this.totalPage || this.total == 0;
      },
      set() {},
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.itemTitle = this.itemName;
      this.getData(this.current, this.size);
    },
    changeCurrentPage(p) {
      if (p == 'previous') {
        if (this.current === 1) {
          this.previousDisabled = true;
          return;
        }
        this.nextDisabled = true;
        this.current -= 1;
        this.emitChange();
      }
      if (p == 'next') {
        if (this.current >= this.totalPage) {
          this.nextDisabled = true;
          return;
        }
        this.previousDisabled = true;
        this.current += 1;
        this.emitChange();
      }
    },
    emitChange() {
      this.getData(this.current, this.size);
    },
    /**
     * 保存
     */
    getData(current = 1, size = 10) {
      const data = {
        companyId: this.companyId,
        difficulty: this.difficultyType,
        current: current,
        size: size,
      };
      getCompanyOverviewData(data).then((res) => {
        // 修改总条数
        this.total = res.total;
        // 赋值records
        this.listData = res.records;
        if (res.total > 10) {
          this.nextDisabled = false;
        }
      });
    },
    itemClick(item) {
      if (item.state == 1) {
        // 结算了，不操作
        return;
      }
      if (hasAuth(35) === false) {
        this.$message.warning('没有查看接车单详情权限');
        return;
      }
      this.$router.push({
        name: 'carBillDetail',
        query: {
          id: item.billId,
          t: new Date().getTime(),
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-x-between {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.ulContainer {
  display: flex;
}
.dispatchLine {  
  height: 40px;
  line-height: 40px;
}
.carNumber {
  border-bottom: 1px solid green;
  height: 40px;
  line-height: 40px;
}
.itemFooterContainer {
  border-top: 1px solid green;
  padding: 5px;
  height: 40px;
  vertical-align: middle;
  line-height: 15px;
}
.carLossStateDesc {
  border-top: 1px solid green;
}
.carDetection {
  border-top: 1px solid green;
  height: 40px;
  line-height: 40px;
  overflow: hidden;
}
.shigu {
  border-right: 1px solid green;
  width: 80px;
}
.dispatchState {
  width: 85px;
  height: 40px;
  line-height: 40px;
}
.shigu2 {
  border-right: 1px solid green;
  width: 80px;
  height: 80px;
  line-height: 80px;
}
.accidentState {
  width: 85px;
  height: 80px;
  line-height: 80px;
}
.accidentState .carLossStateDesc {
  height: 40px;
  line-height: 40px;
}
.subTitle {
  color: #39961e;
  font-weight: bold;
}
.my-el-divider {
  background-color: #04f359;
}
.liItem {
  height: 180px;
  width: 165px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 10px;
  background: #f8fcfa;
  -webkit-box-shadow: 0px -1px 10px 0px #f1faf6;
  box-shadow: 0px -1px 10px 0px #f1faf6;
  border: 1px solid green;
  position: relative;
  cursor: pointer;
  text-align: center;
  &.finishLiItem {    
    border: 1px solid gray;
    > .carNumber {
      border-bottom: 1px solid gray;
    }
    > .carLossStateDesc {
      border-top: 1px solid gray;
    }
    > .carDetection {
      border-top: 1px solid gray;
    }
    > .shigu,.shigu2 {
      border-right: 1px solid gray;
    }
    > .itemFooterContainer {
      border-top: 1px solid gray;
    }
  }
}
.notAccident {  
  height: 140px;
}
.warnLiItem {
  background-color: red;
  color: white;
}
.finishLiItem {
  background-color: white;
  color: gray;
}
.waitTime {
  font-size: 10px;
}
.pickUpUser {
  font-size: 10px;
  margin-top: 5px;
}
.warnWaitTime {
  background-color: red;
  color: white;
  cursor: none;
}
.el-form-item {
  .el-date-editor,
  .el-select {
    width: 100%;
  }
  .el-form-item__label {
    width: 100px;
  }
  .el-form-item__content {
    margin-left: 100px;
  }
}
.selected-car-model-list {
  margin: 6px 0 12px 0;
  .el-tag {
    margin-top: 6px;
    margin-left: 6px;
  }
}
</style>
