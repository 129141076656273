var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "配置详情",
        top: "5vh",
        width: "50%",
        visible: _vm.showCarConfigDialog,
        "before-close": _vm.handleClose,
        "append-to-body": "",
      },
    },
    [
      _c("div", { staticStyle: { height: "70vh", overflow: "auto" } }, [
        _c("div", { staticClass: "title" }, [
          _vm._v("车架号：" + _vm._s(_vm.uniqueId)),
        ]),
        _c("div", { staticClass: "content-box" }, [
          _c(
            "div",
            { staticClass: "list" },
            _vm._l(_vm.vehicleList, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "item pointer",
                  class: { active: index == _vm.selectedIndex },
                  on: {
                    click: function ($event) {
                      _vm.selectedIndex = index
                      _vm.selectedVehicle = item
                    },
                  },
                },
                [
                  _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(item.brandName) +
                        " " +
                        _vm._s(item.yearPattern) +
                        " " +
                        _vm._s(item.pl) +
                        " "
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(item.gearboxName) + " " + _vm._s(item.gearNum)
                    ),
                  ]),
                  _c("p", [_vm._v(_vm._s(item.vehicleName))]),
                ]
              )
            }),
            0
          ),
          _c("div", { staticClass: "detail" }, [
            _c("table", { staticClass: "border-table" }, [
              _c("tr", [
                _c("td", { staticClass: "td-title", attrs: { colspan: "2" } }, [
                  _vm._v("基本信息"),
                ]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "table-label" }, [_vm._v("车型名称")]),
                _c("td", [_vm._v(_vm._s(_vm.selectedVehicle.vehicleName))]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "table-label" }, [_vm._v("车型类别")]),
                _c("td", [_vm._v(_vm._s(_vm.selectedVehicle.vehicleType))]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "table-label" }, [_vm._v("品牌")]),
                _c("td", [_vm._v(_vm._s(_vm.selectedVehicle.brandName))]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "table-label" }, [_vm._v("车系")]),
                _c("td", [_vm._v(_vm._s(_vm.selectedVehicle.familyName))]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "table-label" }, [_vm._v("排量（L）")]),
                _c("td", [_vm._v(_vm._s(_vm.selectedVehicle.pl))]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "table-label" }, [_vm._v("燃料类型")]),
                _c("td", [_vm._v(_vm._s(_vm.selectedVehicle.fuelJetType))]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "table-label" }, [
                  _vm._v("变速箱描述"),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.selectedVehicle.gearboxName))]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "table-label" }, [
                  _vm._v("变速器档数"),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.selectedVehicle.gearNum))]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "table-label" }, [_vm._v("年款")]),
                _c("td", [_vm._v(_vm._s(_vm.selectedVehicle.yearPattern))]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "table-label" }, [_vm._v("配置级别")]),
                _c("td", [_vm._v(_vm._s(_vm.selectedVehicle.cfgLevel))]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "table-label" }, [_vm._v("指导价")]),
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm._f("toThousands")(_vm.selectedVehicle.guidingPrice, 3)
                    ) + "万"
                  ),
                ]),
              ]),
              _c("tr", { staticClass: "split-tr" }),
              _c("tr", [
                _c("td", { staticClass: "td-title", attrs: { colspan: "2" } }, [
                  _vm._v("更多参数"),
                ]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "table-label" }, [_vm._v("厂家")]),
                _c("td", [_vm._v(_vm._s(_vm.selectedVehicle.manufacturers))]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "table-label" }, [
                  _vm._v("发动机型号"),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.selectedVehicle.engineModel))]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "table-label" }, [
                  _vm._v("发动机描述"),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.selectedVehicle.engineDesc))]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "table-label" }, [_vm._v("底盘号")]),
                _c("td", [_vm._v(_vm._s(_vm.selectedVehicle.chassisCode))]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "table-label" }, [
                  _vm._v("底气缸容积"),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.selectedVehicle.cylinderVolume))]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "table-label" }, [_vm._v("进气形式")]),
                _c("td", [_vm._v(_vm._s(_vm.selectedVehicle.inairform))]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "table-label" }, [_vm._v("供油方式")]),
                _c("td", [_vm._v(_vm._s(_vm.selectedVehicle.supplyOil))]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "table-label" }, [
                  _vm._v("气缸排列形式"),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.selectedVehicle.arrayType))]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "table-label" }, [_vm._v("气缸数")]),
                _c("td", [_vm._v(_vm._s(_vm.selectedVehicle.cylinders))]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "table-label" }, [
                  _vm._v("每气缸门数"),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.selectedVehicle.valveNum))]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "table-label" }, [
                  _vm._v("最大功率（KW）"),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.selectedVehicle.powerKw))]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "table-label" }, [_vm._v("排放标准")]),
                _c("td", [
                  _vm._v(_vm._s(_vm.selectedVehicle.emissionStandard)),
                ]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "table-label" }, [
                  _vm._v("前制动器类型"),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.selectedVehicle.frontBrake))]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "table-label" }, [
                  _vm._v("后制动器类型"),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.selectedVehicle.rearBrake))]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "table-label" }, [_vm._v("驱动形式")]),
                _c("td", [_vm._v(_vm._s(_vm.selectedVehicle.drivenType))]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "table-label" }, [
                  _vm._v("轴距（mm）"),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.selectedVehicle.wheelbase))]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "table-label" }, [
                  _vm._v("车门数（个）"),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.selectedVehicle.doorNum))]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "table-label" }, [_vm._v("座位数")]),
                _c("td", [_vm._v(_vm._s(_vm.selectedVehicle.seats))]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "table-label" }, [
                  _vm._v("前轮胎规格"),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.selectedVehicle.frontTyre))]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "table-label" }, [
                  _vm._v("后轮胎规格"),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.selectedVehicle.rearTyre))]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "table-label" }, [_vm._v("备胎规格")]),
                _c("td", [_vm._v(_vm._s(_vm.selectedVehicle.spareWheel))]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.handleClose },
              },
              [_vm._v("确 定")]
            ),
            _c(
              "el-button",
              { attrs: { size: "small" }, on: { click: _vm.handleClose } },
              [_vm._v("取 消")]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }