var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visibility,
        width: "45%",
        "close-on-click-modal": false,
        "before-close": _vm.handleClose,
      },
    },
    [
      _c("template", { slot: "title" }, [
        _c("div", { staticClass: "flex-y-center custom-dialog-title" }, [
          _c("h2", [_vm._v("选择技师")]),
          _c("span", [_vm._v("（可双击直接单选，或单击批量选择再确定选择）")]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "appoint-dialog" },
        [
          _vm._t("header", function () {
            return [
              _c("p", [
                _vm._v(
                  " 可选在职岗位含“技师”的员工，施工技师只能选择(可多选)同一个班组内的技师 "
                ),
              ]),
            ]
          }),
          _c("div", { staticClass: "content-box flex" }, [
            _c("div", { staticClass: "artisan-box" }, [
              _c(
                "div",
                { staticClass: "artisan-list" },
                _vm._l(_vm.technicianList, function (artisan) {
                  return _c(
                    "div",
                    {
                      key: artisan.userId,
                      class: {
                        artisan: true,
                        active: artisan.checked,
                        disabled: _vm.isConstructionFree,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleArtisanChecked(artisan)
                        },
                        dblclick: function ($event) {
                          return _vm.confirmArtisan(artisan)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(artisan.realName) + " ")]
                  )
                }),
                0
              ),
            ]),
            _c("div", { staticClass: "dept-tree" }, [
              _c(
                "div",
                { staticClass: "class-box" },
                [
                  _c("p", { staticClass: "title" }, [_vm._v("部门")]),
                  _c("el-tree", {
                    staticClass: "custom-tree",
                    attrs: {
                      data: _vm.treeData,
                      props: _vm.treeProp,
                      "highlight-current": "",
                      "node-key": "categoryId",
                    },
                    on: { "node-click": _vm.onNodeClick },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("div", [
            _c("span", [_vm._v("已选择：")]),
            _c("span", { staticClass: "primary" }, [
              _vm._v(
                _vm._s(
                  _vm.checkedArtisans.map((item) => item.realName).join("、")
                )
              ),
            ]),
          ]),
        ],
        2
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.handleClose } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.selectArtisan },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }