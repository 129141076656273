<template>
  <el-dialog
    :title="title"
    :visible="visibility"
    width="800px"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <el-form
      ref="form"
      size="small"
      :model="editForm"
      :rules="rules"
      label-width="90px"
      style="margin-top: 18px"
    >
      <el-row :gutter="10">
        <el-col :span="12" :offset="0">
          <el-form-item label="回款门店" prop="receivingCompanyId">
            <el-select
              v-model="editForm.receivingCompanyId"
              filterable
              clearable
              placeholder="请输入门店名称搜索选择"
            >
              <el-option
                v-for="item in companyList"
                :key="item.companyId"
                :label="item.companyName"
                :value="item.companyId"
              >
                <span style="float: left; color: #8492a6; font-size: 13px">{{
                  item.companyName
                }}</span>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="流水编号" prop="sysStatementSeqCode">
            <el-input
              v-model="editForm.sysStatementSeqCode"
              disabled
              maxlength="200"
              placeholder="系统流水编号系统自动填充"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12" :offset="0">
          <el-form-item label="回款主体" prop="invoicingEntity">
            <el-select
              v-model="editForm.invoicingEntity"
              clearable
              filterable
              placeholder="请选择回款主体"
            >
              <el-option
                v-for="item in invoicingEntities"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="开户行" prop="depositBank">
            <el-input
              v-model="editForm.depositBank"
              maxlength="200"
              placeholder="请输入开户行"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12" :offset="0">
          <el-form-item label="银行账号" prop="bankAccount">
            <el-input
              v-model="editForm.bankAccount"
              maxlength="200"
              placeholder="请输入银行账号"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="付款主体" prop="insuranceCompanyId">
            <el-select
              v-model="editForm.insuranceCompanyId"
              clearable
              filterable
              placeholder="请选择付款主体"
            >
              <el-option
                v-for="item in insuranceCompanyList"
                :key="item.id"
                :label="item.insuranceName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24" :offset="0">
          <el-form-item label="付款账号" prop="paymentAccount">
            <el-input
              v-model="editForm.paymentAccount"
              maxlength="200"
              placeholder="请输入银行账号"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12" :offset="0">
          <el-form-item label="回款时间" prop="receivingDatetime">
            <el-date-picker
              class="w100"
              type="datetime"
              v-model="editForm.receivingDatetime"
              placeholder="请选择回款时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              @input="dateRangeChange()"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="金额" prop="amount">
            <el-input-number
              v-model="editForm.amount"
              maxlength="200"
              :min="0"
              show-word-limit
              placeholder="请输入金额"
              clearable
            >
            </el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24" :offset="0">
          <el-form-item label="银行摘要" prop="bankSummary">
            <el-input
              type="textarea"
              :rows="3"
              v-model="editForm.bankSummary"
              maxlength="200"
              placeholder="请输入银行摘要"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24" :offset="0">
          <el-form-item label="流水号" prop="transactionSeq">
            <el-input
              v-model="editForm.transactionSeq"
              maxlength="200"
              placeholder="请输入资金流水号"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button size="small" type="primary" @click="save"> 确 定 </el-button>
      <el-button size="small" @click="handleClose"> 取 消 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex';

// 组件

// 接口
import {
  saveTransactionList,
  updateTransactionList,
  detailTransactionList,
} from '@/api/financial/financialOver';

// 字典数据
import { manufactoryType } from '@/service/dict/dictData';
export default {
  name: 'editBankTransactionDialog',
  components: {},
  props: {
    visibility: Boolean,
    id: [Number, String],
    rowEntityData: [Object],
    companyList: {
      type: Object,
      default: () => {},
    },
    insuranceCompanyList: {
      type: Object,
      default: () => {},
    },
    invoicingEntities: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // 表单信息
      editForm: {
        receivingDatetime: new Date(),
      },
      // 表单规则
      rules: {
        receivingCompanyId: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入门店名称搜索选择！',
          },
        ],
        invoicingEntity: [
          {
            required: true,
            trigger: 'blur',
            message: '请选择回款主体！',
          },
        ],
        bankAccount: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入银行账号！',
          },
        ],
        insuranceCompanyId: [
          {
            required: true,
            trigger: 'blur',
            message: '请选择付款主体！',
          },
        ],
        paymentAccount: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入银行账号！',
          },
        ],
        receivingDatetime: [
          {
            required: true,
            trigger: 'blur',
            message: '请选择回款时间！',
          },
        ],
        amount: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入金额！',
          },
        ],
        transactionSeq: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入资金流水号',
          },
        ],
      },
    };
  },
  computed: {
    title() {
      return this.id ? '编辑流水' : '新增流水';
    },
  },
  created() {
    if (this.id) {
      this.getData();
    }
  },
  methods: {
    //下面是解决设置日期默认值时重新选择无法刷新日期的方法
    dateRangeChange() {
      let _this = this;
      _this.$nextTick(() => {
        _this.$forceUpdate();
      });
    },
    /**
     * 获取数据
     */
    getData() {
      detailTransactionList({ id: this.id }).then((res) => {
        this.editForm = res || {};
      });
    },
    /**
     * 保存
     */
    save() {
      this.$refs.form
        .validate()
        .then((result, object) => {
          // 车型件
          var api = this.id ? updateTransactionList : saveTransactionList;
          let tempTimeStr = '';
          var tempTime = this.editForm.receivingDatetime;
          if (tempTime instanceof Date) {
            tempTimeStr = tempTime.Format('yyyy-MM-dd hh:mm:ss');
          } else {
            tempTimeStr = tempTime;
          }
          var body = Object.assign({}, this.editForm, {
            receivingDatetime: tempTimeStr,
          });
          api(body).then((res) => {
            this.$message.success('保存成功！');
            this.$emit('save');
            this.handleClose();
          });
        })
        .catch((error) => {
          console.log(error);
          var msg =
            '<p>' +
            Object.values(error)
              .map((el) => el.map((v) => v.message))
              .join('<br />') +
            '</p>';
          this.$message({
            type: 'warning',
            dangerouslyUseHTMLString: true,
            message: msg,
          });
        });
    },

    /**
     * 关闭抽屉
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item {
  .el-date-editor,
  .el-select {
    width: 100%;
  }
  .el-form-item__label {
    width: 100px;
  }
  .el-form-item__content {
    margin-left: 100px;
  }
}
.selected-car-model-list {
  margin: 6px 0 12px 0;
  .el-tag {
    margin-top: 6px;
    margin-left: 6px;
  }
}
</style>
