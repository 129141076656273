var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "预约完成",
        top: "5vh",
        width: "40%",
        "append-to-body": true,
        visible: _vm.visibility,
        "before-close": _vm.handleClose,
        "close-on-click-modal": false,
      },
    },
    [
      _c(
        "div",
        { staticStyle: { height: "150px", overflow: "auto" } },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "100px",
                size: "small",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 20 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "车单号", prop: "billCode" } },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "请输入车单号",
                            },
                            model: {
                              value: _vm.form.billCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "billCode", $$v)
                              },
                              expression: "form.billCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 20 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注", prop: "remark" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              autosize: { minRows: 2, maxRows: 4 },
                              placeholder: "请输入备注",
                              maxlength: "100",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.form.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "remark", $$v)
                              },
                              expression: "form.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          [
            _c(
              "el-button",
              { attrs: { size: "small" }, on: { click: _vm.handleClose } },
              [_vm._v("取 消")]
            ),
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.verifyBeforeSave },
              },
              [_vm._v("确认")]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }