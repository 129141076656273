<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <el-form
        class="filter-form"
        :model="form"
        label-width="100px"
        size="small"
      >
        <div class="flex-x-between">
          <div class="flex-warp flex-shrink0">
            <el-form-item label="签约客户">
              <el-select
                v-model="form.clientId"
                filterable
                clearable
                remote
                placeholder="请输入客户名称"
                :remote-method="searchClient"
                :loading="searchClientLoading"
              >
                <el-option
                  v-for="item in clientList"
                  :key="item.id"
                  :label="item.clientName"
                  :value="item.id"
                >
                  <span style="float: left; color: #8492a6; font-size: 13px">{{
                    item.clientName
                  }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <template slot="label">
                <div>
                  操作门店
                  <el-tooltip
                    effect="light"
                    content="接车单的操作门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)"
                    placement="bottom-start"
                  >
                    <i
                      class="iconfont grow-icon_doubt"
                      style="color: #9a9a9a"
                    ></i>
                  </el-tooltip>
                </div>
              </template>
              <operateCompanyCascader
                v-model="form.operateCompanyId"
                :operateCompanyUrl.sync="form.operateCompanyUrl"
              ></operateCompanyCascader>
            </el-form-item>
            <el-form-item label="接车进店日期">
              <el-date-picker
                v-model="form.intoStoreDateRange"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="截止时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </div>
          <div class="flex">
            <div class="el-form-item--small">
              <!-- <div
                @click="showMore = !showMore"
                class="el-form-item__label pointer"
              >
                更多筛选
                <i
                  :class="{
                    primary: true,
                    'el-icon-arrow-right': !showMore,
                    'el-icon-arrow-down': showMore,
                  }"
                ></i>
              </div> -->
              <div class="flex">
                <el-button
                  class="btn_search"
                  type="primary"
                  size="small"
                  @click="getData"
                  >查询</el-button
                >
                <el-button
                  class="btn_search"
                  size="small"
                  @click="resetFilterForm"
                  >置空</el-button
                >
              </div>
            </div>
          </div>
        </div>
        <div class="flex-warp">
          <el-form-item label="业务类型">
            <el-select
              v-model="form.billType"
              multiple
              clearable
              placeholder="全部 支持多选"
            >
              <el-option
                v-for="item in businessType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="事故责任">
            <el-select
              v-model="form.dutyType"
              multiple
              clearable
              placeholder="全部 支持多选"
            >
              <el-option
                v-for="item in dutyType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="客户等级">
            <el-select
              v-model="form.clientLevel"
              clearable
              placeholder="请选择事故责任"
            >
              <el-option
                v-for="item in clientLevel"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <!-- <template v-if="showMore"></template> -->
        </div>
      </el-form>
    </template>

    <template>
      <div class="flex-x-between">
        <div style="margin-bottom: 15px; font-size: 14px">
          <i
            class="pointer iconfont grow-icon_warn_32"
            style="color: #ff9200; font-size: 15px; margin: 5px 10px"
          ></i
          ><span style="color: #909399"
            >今日在场只统计【服务中】【挂单中未提车】【待结账未提车】【已结账未提车】的车辆</span
          >
        </div>

        <el-button
          v-auth="522"
          size="small"
          type="text"
          class="blue"
          @click="showExportDialog = true"
          >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
        ></el-button>
      </div>

      <el-table
        id="customTable"
        class="custom-table summary"
        :data="tableData"
        border
        show-summary
        :summary-method="getSummaries"
        v-fixedScrollBar="$refs.baseContainer"
      >
        <el-table-column type="index" label="序号" width="60">
        </el-table-column>
        <el-table-column
          header-align="center"
          min-width="120"
          label="签约客户"
          prop="clientName"
        >
        </el-table-column>
        <el-table-column min-width="120">
          <template slot="header">
            <div style="text-align: center">在场车辆<br />（台）</div>
          </template>
          <template #default="{ row }">
            <span class="blue pointer" @click="jump(row, 1)">{{
              row.carSum
            }}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="120">
          <template slot="header">
            <div style="text-align: center">滞留超7天<br />（台）</div>
          </template>
          <template #default="{ row }">
            <span class="blue pointer" @click="jump(row, 2)">{{
              row.carStrandedWithinSevenDayNum
            }}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="120">
          <template slot="header">
            <div style="text-align: center">今日进店<br />（台）</div>
          </template>
          <template #default="{ row }">
            <span class="blue pointer" @click="jump(row, 3)">{{
              row.todayCarNum
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="服务中" header-align="center">
          <el-table-column min-width="80">
            <template slot="header">
              <span>合计</span>
              <el-tooltip effect="light" placement="bottom-start">
                <div
                  slot="content"
                  style="
                    color: #666;
                    font-size: 13px;
                    line-height: 16px;
                    text-indent: 15px;
                  "
                >
                  服务中（在修）车辆台数，已去重
                </div>
                <i
                  class="iconfont grow-icon_doubt"
                  style="margin-left: 5px"
                ></i>
              </el-tooltip>
            </template>
            <template #default="{ row }">
              <span class="blue pointer" @click="jump(row, 4)">{{
                row.inServiceBillTotal
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            min-width="80"
            label="自费(单)"
            header-align="center"
          >
            <template #default="{ row }">
              <span class="blue pointer" @click="jump(row, 5)">{{
                row.inServiceSelfPayBillNum
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            min-width="80"
            label="包干(单)"
            header-align="center"
          >
            <template #default="{ row }">
              <span class="blue pointer" @click="jump(row, 6)">{{
                row.inServiceContractForBillNum
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            min-width="80"
            label="理赔(单)"
            header-align="center"
          >
            <template #default="{ row }">
              <span class="blue pointer" @click="jump(row, 7)">{{
                row.inServiceClaimSettlementBillNum
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            min-width="80"
            label="索赔(单)"
            header-align="center"
          >
            <template #default="{ row }">
              <span class="blue pointer" @click="jump(row, 8)">{{
                row.inServiceClaimForCompensationBillNum
              }}</span>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column label="挂单中（未提车）" header-align="center">
          <el-table-column min-width="80">
            <template slot="header">
              <span>合计</span>
              <el-tooltip effect="light" placement="bottom-start">
                <div
                  slot="content"
                  style="
                    color: #666;
                    font-size: 13px;
                    line-height: 16px;
                    text-indent: 15px;
                  "
                >
                  挂单中（未提车）车辆台数，已去重
                </div>
                <i
                  class="iconfont grow-icon_doubt"
                  style="margin-left: 5px"
                ></i>
              </el-tooltip>
            </template>
            <template #default="{ row }">
              <span class="blue pointer" @click="jump(row, 14)">{{
                row.registrationNoTakeCarBillTotal
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            min-width="80"
            label="自费(单)"
            header-align="center"
          >
            <template #default="{ row }">
              <span class="blue pointer" @click="jump(row, 15)">{{
                row.registrationNoTakeCarSelfPayBillNum
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            min-width="80"
            label="包干(单)"
            header-align="center"
          >
            <template #default="{ row }">
              <span class="blue pointer" @click="jump(row, 16)">{{
                row.registrationNoTakeCarContractForBillNum
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            min-width="80"
            label="理赔(单)"
            header-align="center"
          >
            <template #default="{ row }">
              <span class="blue pointer" @click="jump(row, 17)">{{
                row.registrationNoTakeCarClaimSettlementBillNum
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            min-width="80"
            label="索赔(单)"
            header-align="center"
          >
            <template #default="{ row }">
              <span class="blue pointer" @click="jump(row, 18)">{{
                row.registrationNoTakeCarClaimForCompensationBillNum
              }}</span>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column label="待结账（未提车）" header-align="center">
          <el-table-column min-width="80">
            <template slot="header">
              <span>合计</span>
              <el-tooltip effect="light" placement="bottom-start">
                <div
                  slot="content"
                  style="
                    color: #666;
                    font-size: 13px;
                    line-height: 16px;
                    text-indent: 15px;
                  "
                >
                  待结账（完工待提）车辆台数，已去重
                </div>
                <i
                  class="iconfont grow-icon_doubt"
                  style="margin-left: 5px"
                ></i>
              </el-tooltip>
            </template>
            <template #default="{ row }">
              <span class="blue pointer" @click="jump(row, 9)">{{
                row.toPayNoTakeCarBillTotal
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            min-width="80"
            label="自费(单)"
            header-align="center"
          >
            <template #default="{ row }">
              <span class="blue pointer" @click="jump(row, 10)">{{
                row.toPayNoTakeCarSelfPayBillNum
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            min-width="80"
            label="包干(单)"
            header-align="center"
          >
            <template #default="{ row }">
              <span class="blue pointer" @click="jump(row, 11)">{{
                row.toPayNoTakeCarContractForBillNum
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            min-width="80"
            label="理赔(单)"
            header-align="center"
          >
            <template #default="{ row }">
              <span class="blue pointer" @click="jump(row, 12)">{{
                row.toPayNoTakeCarClaimSettlementBillNum
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            min-width="80"
            label="索赔(单)"
            header-align="center"
          >
            <template #default="{ row }">
              <span class="blue pointer" @click="jump(row, 13)">{{
                row.toPayNoTakeCarClaimForCompensationBillNum
              }}</span>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column label="已结账（未提车）" header-align="center">
          <el-table-column min-width="80">
            <template slot="header">
              <span>合计</span>
              <el-tooltip effect="light" placement="bottom-start">
                <div
                  slot="content"
                  style="
                    color: #666;
                    font-size: 13px;
                    line-height: 16px;
                    text-indent: 15px;
                  "
                >
                  已结账（完工待提）车辆台数，已去重
                </div>
                <i
                  class="iconfont grow-icon_doubt"
                  style="margin-left: 5px"
                ></i>
              </el-tooltip>
            </template>
            <template #default="{ row }">
              <span class="blue pointer" @click="jump(row, 19)">{{
                row.checkoutNoTakeCarBillTotal
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            min-width="80"
            label="自费(单)"
            header-align="center"
          >
            <template #default="{ row }">
              <span class="blue pointer" @click="jump(row, 20)">{{
                row.checkoutNoTakeCarSelfPayBillNum
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            min-width="80"
            label="包干(单)"
            header-align="center"
          >
            <template #default="{ row }">
              <span class="blue pointer" @click="jump(row, 21)">{{
                row.checkoutNoTakeCarContractForBillNum
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            min-width="80"
            label="理赔(单)"
            header-align="center"
          >
            <template #default="{ row }">
              <span class="blue pointer" @click="jump(row, 22)">{{
                row.checkoutNoTakeCarClaimSettlementBillNum
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            min-width="80"
            label="索赔(单)"
            header-align="center"
          >
            <template #default="{ row }">
              <span class="blue pointer" @click="jump(row, 23)">{{
                row.checkoutNoTakeCarClaimForCompensationBillNum
              }}</span>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>

      <!-- 导出弹窗 -->
      <export-dialog
        v-if="showExportDialog"
        :visibility.sync="showExportDialog"
        :total="total"
        :pageOption="pageOption"
        :filterForm="exportForm"
        :url="exportUrl"
      ></export-dialog>
    </template>

    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
  </base-container>
</template>

<script>
// 组件
import pagination from '@/components/Pagination';
import ExportDialog from '@/components/ExportDialog';
import operateCompanyCascader from '@/components/businessComponent/operateCompanyCascader';
// 接口
import { searchClient } from '@/api/customer/customer';

import {
  getCarStrandedStatistics,
  getCarStrandedStatisticsTotal,
  exportCarStrandedStatisticsUrl,
} from '@/api/carBill/strandedCar';

import { mapState, mapGetters, mapMutations } from 'vuex';

import { toThousands } from '@/utils/filters/number';
// 字典数据
import { carBillType, dutyType, clientLevel } from '@/service/dict/dictData';
export default {
  name: 'strandedCarStatistics',
  components: {
    pagination,
    ExportDialog,
    operateCompanyCascader,
  },
  data() {
    return {
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },

      form: {},

      // 展示更多筛选
      // showMore: false,

      //业务类型
      businessType: carBillType,
      dutyType: dutyType,
      clientLevel: clientLevel,

      // 搜索用户
      clientList: [],
      searchClientLoading: false,

      // 列表数据
      tableData: [],

      // 统计数据
      statisticData: {},

      // 导出弹窗可见性
      showExportDialog: false,
      // 导出路径
      exportUrl: exportCarStrandedStatisticsUrl,
      exportForm: {},
    };
  },
  watch: {
    showExportDialog(val) {
      if (val) {
        let merge = {
          intoStoreStartTime: this.form?.intoStoreDateRange?.[0] || '',
          intoStoreEndTime: this.form?.intoStoreDateRange?.[1] || '',
        };
        this.exportForm = Object.assign({}, this.form, merge);
      }
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      companyId: (state) => state.user.companyId,
    }),
  },

  created() {
    this.initFilterForm();
    this.getData();
  },
  methods: {
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getData(false);
    },

    /**
     * 加载数据
     */
    getData(reset = true) {
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      let merge = {
        intoStoreStartTime: this.form?.intoStoreDateRange?.[0] || '',
        intoStoreEndTime: this.form?.intoStoreDateRange?.[1] || '',
      };
      let data = Object.assign({}, this.pageOption, this.form, merge);
      getCarStrandedStatistics(data).then((res) => {
        console.log(res);
        // 修改总条数
        this.total = res.total;
        // 赋值records
        this.tableData = res.records;
      });
      getCarStrandedStatisticsTotal(data).then((res) => {
        this.statisticData = res;
      });
    },
    // 表格自定义合计方法
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 1) {
          sums[index] = '合计';
          return;
        }
        if (index > 1) {
          switch (index) {
            case 2:
              sums[index] = this.statisticData.carSum;
              break;
            case 3:
              sums[index] = this.statisticData.carStrandedWithinSevenDayNum;
              break;
            case 4:
              sums[index] = this.statisticData.todayCarNum;
              break;
            case 5:
              sums[index] = this.statisticData.inServiceBillTotal;
              break;
            case 6:
              sums[index] = this.statisticData.inServiceSelfPayBillNum;
              break;
            case 7:
              sums[index] = this.statisticData.inServiceContractForBillNum;
              break;
            case 8:
              sums[index] = this.statisticData.inServiceClaimSettlementBillNum;
              break;
            case 9:
              sums[index] =
                this.statisticData.inServiceClaimForCompensationBillNum;
              break;
            case 10:
              sums[index] = this.statisticData.registrationNoTakeCarBillTotal;
              break;
            case 11:
              sums[index] =
                this.statisticData.registrationNoTakeCarSelfPayBillNum;
              break;
            case 12:
              sums[index] =
                this.statisticData.registrationNoTakeCarContractForBillNum;
              break;
            case 13:
              sums[index] =
                this.statisticData.registrationNoTakeCarClaimSettlementBillNum;
              break;
            case 14:
              sums[index] =
                this.statisticData.registrationNoTakeCarClaimForCompensationBillNum;
              break;
            case 15:
              sums[index] = this.statisticData.toPayNoTakeCarBillTotal;
              break;
            case 16:
              sums[index] = this.statisticData.toPayNoTakeCarSelfPayBillNum;
              break;
            case 17:
              sums[index] = this.statisticData.toPayNoTakeCarContractForBillNum;
              break;
            case 18:
              sums[index] =
                this.statisticData.toPayNoTakeCarClaimSettlementBillNum;
              break;
            case 19:
              sums[index] =
                this.statisticData.toPayNoTakeCarClaimForCompensationBillNum;
              break;
            case 20:
              sums[index] = this.statisticData.checkoutNoTakeCarBillTotal;
              break;
            case 21:
              sums[index] = this.statisticData.checkoutNoTakeCarSelfPayBillNum;
              break;
            case 22:
              sums[index] =
                this.statisticData.checkoutNoTakeCarContractForBillNum;
              break;
            case 23:
              sums[index] =
                this.statisticData.checkoutNoTakeCarClaimSettlementBillNum;
              break;
            case 24:
              sums[index] =
                this.statisticData.checkoutNoTakeCarClaimForCompensationBillNum;
              break;
          }
        }
      });
      return sums;
    },

    // 搜索客户列表
    searchClient(keyWord = '') {
      this.searchClientLoading = true;
      searchClient(keyWord)
        .then((res) => {
          this.clientList = res.records;
        })
        .finally(() => {
          this.searchClientLoading = false;
        });
    },

    /**
     * 初始化表单
     */
    initFilterForm() {
      this.form = {
        // 操作门店
        operateCompanyId: [this.companyId],
        operateCompanyUrl: [this.companyId],
        // 进店日期
        intoStoreDateRange: [
          // new Date().Format('yyyy-MM-dd'),
          // new Date().Format('yyyy-MM-dd'),
        ],
        // billType: [0, 1, 2, 3],
        // dutyType: [0, 1, 2, 3, 4],
      };
      // this.form.operateCompanyId = [this.companyId];
    },
    /**
     * 清除筛选表单
     */
    resetFilterForm() {
      this.form = {
        // 操作门店
        operateCompanyId: [],
        // 进店日期
        intoStoreDateRange: [],
      };
    },
    /**
     * 跳转到今日在场明细标签页
     */
    jump(value, number) {
      var billState = -1;
      var hasStay = false;
      var billType;
      switch (number) {
        case 1:
          billState = '';
          break;
        case 2:
          var currentDate = new Date();
          var before7Daysdate = new Date(
            currentDate.setDate(currentDate.getDate() - 7)
          );
          billState = '';
          hasStay = true;
          if (
            !this.form.intoStoreDateRange &&
            this.form.intoStoreDateRange !=
              [
                before7Daysdate.Format('yyyy-MM-dd'),
                new Date().Format('yyyy-MM-dd'),
              ]
          ) {
            this.form.intoStoreDateRange = [
              before7Daysdate.Format('yyyy-MM-dd'),
              new Date().Format('yyyy-MM-dd'),
            ];
          }
          break;
        case 3:
          this.form.intoStoreDateRange = [
            new Date().Format('yyyy-MM-dd'),
            new Date().Format('yyyy-MM-dd'),
          ];
          break;
        case 4:
          billState = 0;
          break;
        case 5:
          billState = 0;
          billType = [0];
          break;
        case 6:
          billState = 0;
          billType = [1];
          break;
        case 7:
          billState = 0;
          billType = [2];
          break;
        case 8:
          billState = 0;
          billType = [3];
          break;
        case 9:
          billState = 2;
          break;
        case 10:
          billState = 2;
          billType = [0];
          break;
        case 11:
          billState = 2;
          billType = [1];
          break;
        case 12:
          billState = 2;
          billType = [2];
          break;
        case 13:
          billState = 2;
          billType = [3];
          break;
        case 14:
          billState = 4;
          break;
        case 15:
          billState = 4;
          billType = [0];
          break;
        case 16:
          billState = 4;
          billType = [1];
          break;
        case 17:
          billState = 4;
          billType = [2];
          break;
        case 18:
          billState = 4;
          billType = [3];
          break;
        case 19:
          billState = 1;
          break;
        case 20:
          billState = 1;
          billType = [0];
          break;
        case 21:
          billState = 1;
          billType = [1];
          break;
        case 22:
          billState = 1;
          billType = [2];
          break;
        case 23:
          billState = 1;
          billType = [3];
          break;
      }
      this.$router.push({
        name: 'presentCar',
        params: {
          filterForm: {
            operateCompanyId: this.form.operateCompanyId,
            operateCompanyUrl: this.form.operateCompanyUrl,
            intoStoreTimes: this.form.intoStoreDateRange,
            billType: [
              5, 6, 7, 8, 10, 11, 12, 13, 15, 16, 17, 18, 20, 21, 22, 23,
            ].includes(number)
              ? billType
              : this.form.billType,
            dutyType: this.form.dutyType,
            billState: billState,
            clientId: value.clientId || 0,
            clientName: value.clientName || '',
            hasStay: hasStay,
          },
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
// 隐藏滚动条
.custom-table ::v-deep .el-table__body-wrapper {
  &::-webkit-scrollbar {
    display: none;
  }
  // IE
  -ms-overflow-style: none;
}
</style>
