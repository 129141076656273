var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    {
      attrs: {
        defaultPadding: false,
        paddingTop: false,
        customMainStyle: { overflow: "hidden" },
      },
    },
    [
      _c("div", { staticClass: "flex flex-x-between" }, [
        _c("div", { staticClass: "subTitle" }, [
          _vm._v(_vm._s(_vm.itemTitle) + "(" + _vm._s(_vm.total) + ")"),
        ]),
        _c(
          "div",
          [
            _c(
              "el-link",
              {
                attrs: { type: "primary", disabled: _vm.previousDisabled },
                on: {
                  click: function ($event) {
                    return _vm.changeCurrentPage("previous")
                  },
                },
              },
              [_vm._v("上一页")]
            ),
            _vm._v("| "),
            _c(
              "el-link",
              {
                attrs: { type: "primary", disabled: _vm.nextDisabled },
                on: {
                  click: function ($event) {
                    return _vm.changeCurrentPage("next")
                  },
                },
              },
              [_vm._v("下一页")]
            ),
          ],
          1
        ),
      ]),
      _c("el-divider", { staticClass: "my-el-divider" }),
      _vm.total > 0
        ? _c("div", { staticClass: "itemContainer" }, [
            _c(
              "ul",
              { staticClass: "ulContainer" },
              _vm._l(_vm.listData, function (item, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    class: [
                      item.state !== 1 &&
                      _vm.difficultyType === 0 &&
                      item.inStoreOverTwoHours
                        ? "warnLiItem"
                        : "",
                      item.state == 1 && item?.takeTime === undefined
                        ? "finishLiItem"
                        : "",
                      "liItem",
                      [0, 4].includes(_vm.difficultyType) ? "notAccident" : "",
                    ],
                    on: {
                      click: function ($event) {
                        return _vm.itemClick(item)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "carNumber" }, [
                      _vm._v(_vm._s(item.carNumber)),
                    ]),
                    _vm.difficultyType !== 2
                      ? _c(
                          "div",
                          { staticClass: "flex-x-between dispatchLine" },
                          [
                            _c("div", { staticClass: "shigu" }, [
                              _vm._v(_vm._s(item.difficultyDesc)),
                            ]),
                            _c("div", { staticClass: "dispatchState" }, [
                              _vm._v(_vm._s(item.dispatchStateDesc)),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm.difficultyType === 1
                      ? _c("div", { staticClass: "carDetection" }, [
                          _c(
                            "div",
                            { attrs: { title: item.carDetectionItemName } },
                            [_vm._v(_vm._s(item.carDetectionItemName))]
                          ),
                        ])
                      : _vm._e(),
                    _vm.difficultyType === 2
                      ? _c("div", { staticClass: "flex" }, [
                          _c("div", { staticClass: "shigu2" }, [
                            _vm._v(" " + _vm._s(item.difficultyDesc) + " "),
                          ]),
                          _c("div", { staticClass: "accidentState" }, [
                            _c("div", { staticClass: "dispatchState" }, [
                              _vm._v(_vm._s(item.dispatchStateDesc)),
                            ]),
                            _c("div", { staticClass: "carLossStateDesc" }, [
                              _vm._v(
                                _vm._s(item.lossDeterminationStateMergeDesc)
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "itemFooterContainer" }, [
                      item.state === 1
                        ? _c(
                            "p",
                            { staticClass: "waitTime" },
                            [
                              item.inStoreTodayTime
                                ? [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("delYmdAndSecond")(
                                            item.dateCreated
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                : [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("delSecond")(item.dateCreated)
                                        ) +
                                        " "
                                    ),
                                  ],
                              item.finishTimeTodayTime
                                ? [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("delYmdAndSecond")(
                                            item.finishTime
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                : [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("delSecond")(item.finishTime)
                                        ) +
                                        " "
                                    ),
                                  ],
                              _vm._v(
                                " (" + _vm._s(item.inStoreWaitTimeStr) + ") "
                              ),
                            ],
                            2
                          )
                        : item.state !== 1
                        ? _c(
                            "p",
                            {
                              class: [
                                item.state !== 1 &&
                                _vm.difficultyType === 0 &&
                                item.isInStoreOverOneHalfHours
                                  ? "warnWaitTime"
                                  : "",
                                "waitTime",
                              ],
                            },
                            [
                              item.inStoreTodayTime
                                ? [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("delYmdAndSecond")(
                                            item.dateCreated
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                : [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("delSecond")(item.dateCreated)
                                        ) +
                                        " "
                                    ),
                                  ],
                              _vm._v(
                                " (" + _vm._s(item.inStoreWaitTimeStr) + ") "
                              ),
                            ],
                            2
                          )
                        : _vm._e(),
                      _c("p", { staticClass: "pickUpUser" }, [
                        _vm._v("接待：" + _vm._s(item.pickUpUserName)),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            ),
          ])
        : _c("div", { staticStyle: { "text-align": "center" } }, [
            _vm._v(" 没有数据 "),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }