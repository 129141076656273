var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "div" },
    [
      false ? _c("batchWord2RichText") : _vm._e(),
      false ? _c("AMap") : _vm._e(),
      _c("changePasswordDialog", { attrs: { visibility: _vm.bool } }),
      _c("changePhoneNumberDialog", { attrs: { visibility: _vm.bool2 } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }