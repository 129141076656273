var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visibility,
        width: "50%",
        "before-close": _vm.handleClose,
        "close-on-click-modal": false,
        "append-to-body": "",
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.save },
                },
                [_vm._v(" 确 定 ")]
              ),
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.handleClose } },
                [_vm._v(" 取 消 ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticStyle: { "margin-top": "18px" },
          attrs: {
            size: "small",
            model: _vm.editForm,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "flex",
              staticStyle: { "margin-bottom": "30px", "margin-left": "40px" },
            },
            [
              _c("i", { staticClass: "el-icon-warning orange el-icon--left" }),
              _c("p", { staticStyle: { "font-size": "14px" } }, [
                _vm._v(
                  " 点击确定" +
                    _vm._s(_vm.title) +
                    "，提交到“" +
                    _vm._s(_vm.nextState[_vm.title]) +
                    "”状态 "
                ),
              ]),
            ]
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "跟进人", prop: "followUpPeople" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            clearable: "",
                            remote: "",
                            placeholder: "搜索姓名、手机号",
                            "remote-method": _vm.searchAllUser,
                            loading: _vm.searchAllUserLoading,
                          },
                          on: {
                            select: function ($event) {
                              return _vm.doVerify("followUpPeople")
                            },
                          },
                          model: {
                            value: _vm.editForm.followUpPeople,
                            callback: function ($$v) {
                              _vm.$set(_vm.editForm, "followUpPeople", $$v)
                            },
                            expression: "editForm.followUpPeople",
                          },
                        },
                        _vm._l(_vm.allUserList, function (item) {
                          return _c("el-option", {
                            key: item.userId,
                            attrs: { label: item.realName, value: item.userId },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "拖车费", prop: "towingCharge" } },
                    [
                      _c("number-input", {
                        attrs: {
                          decimalDigits: "2",
                          max: 999999.99,
                          placeholder: "请输入",
                          clearable: "",
                        },
                        on: {
                          blur: function ($event) {
                            return _vm.doVerify("towingCharge")
                          },
                        },
                        model: {
                          value: _vm.editForm.towingCharge,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "towingCharge", $$v)
                          },
                          expression: "editForm.towingCharge",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "定损员",
                        prop: "lossDeterminationPersonName",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入2-10字符",
                          clearable: "",
                          maxlength: "10",
                        },
                        model: {
                          value: _vm.editForm.lossDeterminationPersonName,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.editForm,
                              "lossDeterminationPersonName",
                              $$v
                            )
                          },
                          expression: "editForm.lossDeterminationPersonName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "联系电话",
                        prop: "lossDeterminationPersonPhone",
                      },
                    },
                    [
                      _c("number-input", {
                        attrs: {
                          placeholder: "请输入",
                          maxlength: 11,
                          enabledDecimals: false,
                          negativeNumber: false,
                        },
                        on: {
                          blur: function ($event) {
                            return _vm.doVerify("lossDeterminationPersonPhone")
                          },
                        },
                        model: {
                          value: _vm.editForm.lossDeterminationPersonPhone,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.editForm,
                              "lossDeterminationPersonPhone",
                              $$v
                            )
                          },
                          expression: "editForm.lossDeterminationPersonPhone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "拍照要求", prop: "photoRequest" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          size: "small",
                          rows: 1,
                          maxlength: "50",
                          placeholder: "请输入拍照要求，最多50个字符",
                        },
                        model: {
                          value: _vm.editForm.photoRequest,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "photoRequest", $$v)
                          },
                          expression: "editForm.photoRequest",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "报案时间", prop: "reportingTime" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "date",
                          "value-format": "yyyy-MM-dd",
                          placeholder: "请选择",
                        },
                        model: {
                          value: _vm.editForm.reportingTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "reportingTime", $$v)
                          },
                          expression: "editForm.reportingTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "车损金额", prop: "carDamageAmount" } },
                    [
                      _c("number-input", {
                        attrs: {
                          max: 999999.99,
                          decimalDigits: "2",
                          placeholder: "请输入",
                          clearable: "",
                        },
                        on: {
                          blur: function ($event) {
                            return _vm.doVerify("carDamageAmount")
                          },
                        },
                        model: {
                          value: _vm.editForm.carDamageAmount,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "carDamageAmount", $$v)
                          },
                          expression: "editForm.carDamageAmount",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "风险评估", prop: "riskAssessment" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.editForm.riskAssessment,
                            callback: function ($$v) {
                              _vm.$set(_vm.editForm, "riskAssessment", $$v)
                            },
                            expression: "editForm.riskAssessment",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("无风险"),
                          ]),
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("有风险"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "预估金额", prop: "estimatedAmount" } },
                    [
                      _c("number-input", {
                        attrs: {
                          max: 999999.99,
                          decimalDigits: "2",
                          placeholder: "请输入",
                          clearable: "",
                        },
                        on: {
                          blur: function ($event) {
                            return _vm.doVerify("estimatedAmount")
                          },
                        },
                        model: {
                          value: _vm.editForm.estimatedAmount,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "estimatedAmount", $$v)
                          },
                          expression: "editForm.estimatedAmount",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "核损金额",
                        prop: "lossAssessmentAmount",
                      },
                    },
                    [
                      _c("number-input", {
                        attrs: {
                          max: 999999.99,
                          decimalDigits: "2",
                          placeholder: "请输入",
                          clearable: "",
                        },
                        on: {
                          blur: function ($event) {
                            return _vm.doVerify("lossAssessmentAmount")
                          },
                        },
                        model: {
                          value: _vm.editForm.lossAssessmentAmount,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "lossAssessmentAmount", $$v)
                          },
                          expression: "editForm.lossAssessmentAmount",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "定损金额", prop: "lossAmount" } },
                    [
                      _c("number-input", {
                        attrs: {
                          max: 999999.99,
                          decimalDigits: "2",
                          placeholder: "请输入",
                          clearable: "",
                        },
                        on: {
                          blur: function ($event) {
                            return _vm.doVerify("lossAmount")
                          },
                        },
                        model: {
                          value: _vm.editForm.lossAmount,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "lossAmount", $$v)
                          },
                          expression: "editForm.lossAmount",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "收旧情况", prop: "recoveryState" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.editForm.recoveryState,
                            callback: function ($$v) {
                              _vm.$set(_vm.editForm, "recoveryState", $$v)
                            },
                            expression: "editForm.recoveryState",
                          },
                        },
                        _vm._l(_vm.recoveryState, function (type) {
                          return _c("el-option", {
                            key: type.value,
                            attrs: { value: type.value, label: type.label },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "复勘情况", prop: "investigationState" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.editForm.investigationState,
                            callback: function ($$v) {
                              _vm.$set(_vm.editForm, "investigationState", $$v)
                            },
                            expression: "editForm.investigationState",
                          },
                        },
                        _vm._l(_vm.investigationState, function (type) {
                          return _c("el-option", {
                            key: type.value,
                            attrs: { value: type.value, label: type.label },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "是否有免赔",
                        prop: "isExemptionPay2000",
                      },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { disabled: !_vm.localAllowExemptionFill },
                          model: {
                            value: _vm.editForm.isExemptionPay2000,
                            callback: function ($$v) {
                              _vm.$set(_vm.editForm, "isExemptionPay2000", $$v)
                            },
                            expression: "editForm.isExemptionPay2000",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 0 },
                              nativeOn: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.handleClickChangeIsExemptionPay2000(
                                    0
                                  )
                                },
                              },
                            },
                            [_vm._v("无")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 1 },
                              nativeOn: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.handleClickChangeIsExemptionPay2000(
                                    1
                                  )
                                },
                              },
                            },
                            [_vm._v("有")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "免赔金额", prop: "exemptionPayMoney" } },
                    [
                      _c("number-input", {
                        attrs: {
                          max: 999999.99,
                          decimalDigits: "2",
                          placeholder: "请输入免赔金额",
                          disabled: !_vm.localIsExemptionPay2000,
                          clearable: "",
                        },
                        on: {
                          blur: function ($event) {
                            return _vm.doVerify("exemptionPayMoney")
                          },
                        },
                        model: {
                          value: _vm.editForm.exemptionPayMoney,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "exemptionPayMoney", $$v)
                          },
                          expression: "editForm.exemptionPayMoney",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "免赔支付方", prop: "settlementMethod" },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { disabled: !_vm.localIsExemptionPay2000 },
                          model: {
                            value: _vm.editForm.settlementMethod,
                            callback: function ($$v) {
                              _vm.$set(_vm.editForm, "settlementMethod", $$v)
                            },
                            expression: "editForm.settlementMethod",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 0 },
                              nativeOn: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.handleClickChangeSettlementMethod(
                                    0
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "挂账车队(" +
                                  _vm._s(_vm.editForm.settlementClientName) +
                                  ")"
                              ),
                            ]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 1 },
                              nativeOn: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.handleClickChangeSettlementMethod(
                                    1
                                  )
                                },
                              },
                            },
                            [_vm._v("司机现结")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "跟进进展", prop: "followUpState" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticStyle: { "line-height": "1.4rem" },
                          model: {
                            value: _vm.editForm.followUpState,
                            callback: function ($$v) {
                              _vm.$set(_vm.editForm, "followUpState", $$v)
                            },
                            expression: "editForm.followUpState",
                          },
                        },
                        _vm._l(_vm.followUpStateList, function (option) {
                          return _c(
                            "el-radio",
                            {
                              key: option.value,
                              attrs: { label: option.value },
                              nativeOn: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.handleClickFollowUpState(
                                    option.value
                                  )
                                },
                              },
                            },
                            [
                              _c("div", { style: option.iconStyle }, [
                                _vm._v(_vm._s(option.iconText)),
                              ]),
                              _c(
                                "span",
                                { staticStyle: { "margin-left": "5px" } },
                                [_vm._v(_vm._s(option.label))]
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注", prop: "remark" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "请输入备注，最多300个字符",
                          maxlength: "300",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.editForm.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "remark", $$v)
                          },
                          expression: "editForm.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "当次留言", prop: "remark" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          maxlength: "300",
                          "show-word-limit": "",
                          placeholder: "请输入当次留言，最多300个字符",
                        },
                        model: {
                          value: _vm.editForm.message,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "message", $$v)
                          },
                          expression: "editForm.message",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "图片", prop: "feeListPicA" } },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#909399",
                            "font-size": "12px",
                          },
                        },
                        [
                          _vm._v(
                            "支持上传 10 张图片，建议每张图片大小不超过 2 MB"
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        [
                          _c("upload-file", {
                            attrs: { title: "行驶证" },
                            model: {
                              value: _vm.editForm.runningLicenceImg,
                              callback: function ($$v) {
                                _vm.$set(_vm.editForm, "runningLicenceImg", $$v)
                              },
                              expression: "editForm.runningLicenceImg",
                            },
                          }),
                          _c("upload-file", {
                            attrs: { title: "驾驶证" },
                            model: {
                              value: _vm.editForm.picDrivingLicence,
                              callback: function ($$v) {
                                _vm.$set(_vm.editForm, "picDrivingLicence", $$v)
                              },
                              expression: "editForm.picDrivingLicence",
                            },
                          }),
                          _c("upload-file", {
                            attrs: {
                              title: "定损单1",
                              requiredIcon: _vm.type >= 8,
                            },
                            model: {
                              value: _vm.editForm.feeListPicA,
                              callback: function ($$v) {
                                _vm.$set(_vm.editForm, "feeListPicA", $$v)
                              },
                              expression: "editForm.feeListPicA",
                            },
                          }),
                          _c("upload-file", {
                            attrs: { title: "定损单2" },
                            model: {
                              value: _vm.editForm.feeListPicB,
                              callback: function ($$v) {
                                _vm.$set(_vm.editForm, "feeListPicB", $$v)
                              },
                              expression: "editForm.feeListPicB",
                            },
                          }),
                          _c("upload-file", {
                            attrs: { title: "定损单3" },
                            model: {
                              value: _vm.editForm.feeListPicC,
                              callback: function ($$v) {
                                _vm.$set(_vm.editForm, "feeListPicC", $$v)
                              },
                              expression: "editForm.feeListPicC",
                            },
                          }),
                          _c("upload-file-list", {
                            attrs: { max: 5 },
                            model: {
                              value: _vm.editForm.feeListPicOthersList,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.editForm,
                                  "feeListPicOthersList",
                                  $$v
                                )
                              },
                              expression: "editForm.feeListPicOthersList",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "附件", prop: "attachment" } },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#909399",
                            "font-size": "12px",
                          },
                        },
                        [_vm._v("只支持上传zip文件，最多5个")]
                      ),
                      _c("div", { staticClass: "flex" }, [
                        _c(
                          "ul",
                          { staticStyle: { "max-width": "300px" } },
                          _vm._l(_vm.attachmentList, function (item, i) {
                            return _c("li", { key: i }, [
                              _c("i", {
                                staticClass:
                                  "iconfont grow-icon_delete3 orange el-icon--left pointer",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete(i)
                                  },
                                },
                              }),
                              _c("span", [_vm._v(_vm._s(item.name))]),
                            ])
                          }),
                          0
                        ),
                        _vm.attachmentList.length < 5
                          ? _c(
                              "div",
                              { staticStyle: { "margin-left": "20px" } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "small" },
                                    on: { click: _vm.chooseFile },
                                  },
                                  [_vm._v("选择文件")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }