var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-drawer",
    {
      attrs: {
        title: "行驶证年审业务详情",
        visible: _vm.visibility,
        size: "62%",
        "append-to-body": true,
        "before-close": _vm.handleClose,
        modal: "",
        "close-on-press-escape": "",
        wrapperClosable: true,
      },
    },
    [
      _c(
        "div",
        { staticStyle: { margin: "0 8px 40px 24px", overflow: "scroll" } },
        [
          _c(
            "el-descriptions",
            {
              attrs: {
                border: "",
                column: 2,
                labelClassName: "table-label",
                contentStyle: { verticalAlign: "middle" },
              },
            },
            [
              _c(
                "el-descriptions-item",
                { attrs: { label: "客户名称", span: 1 } },
                [_vm._v(" " + _vm._s(_vm.infoVo.clientName) + " ")]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "联系电话", span: 1 } },
                [_vm._v(" " + _vm._s(_vm.infoVo.bookingPhone) + " ")]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "预约地点", span: 2 } },
                [_vm._v(" " + _vm._s(_vm.infoVo.bookingPosition) + " ")]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "预约时间", span: 1 } },
                [_vm._v(" " + _vm._s(_vm.infoVo.bookingDate) + " ")]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "车队名称", span: 1 } },
                [_vm._v(" " + _vm._s(_vm.infoVo.fleetName) + " ")]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "上传照片", span: 1 } },
                [
                  _vm.infoVo.vehicleLicenseImageUrl &&
                  _vm.infoVo.vehicleLicenseImageUrl !== ""
                    ? _c("img", {
                        staticClass: "thumbnail-img",
                        attrs: { src: _vm.infoVo.vehicleLicenseImageUrl },
                        on: {
                          click: function ($event) {
                            return _vm.previewImgs(
                              _vm.infoVo.vehicleLicenseImageUrl
                            )
                          },
                        },
                      })
                    : _c("span", [_vm._v("没有行驶证图片")]),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-descriptions",
            {
              staticStyle: { "margin-top": "32px" },
              attrs: {
                border: "",
                column: 2,
                labelClassName: "table-label",
                contentStyle: { verticalAlign: "middle" },
              },
            },
            [
              _c(
                "el-descriptions-item",
                { attrs: { label: "车牌号码", span: 1 } },
                [_vm._v(" " + _vm._s(_vm.infoVo.carNumber) + " ")]
              ),
              _c(
                "el-descriptions-item",
                {
                  staticStyle: { width: "450px" },
                  attrs: { label: "车辆类型", span: 1 },
                },
                [_vm._v(" " + _vm._s(_vm.infoVo.carType) + " ")]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "所有人", span: 2 } },
                [_vm._v(" " + _vm._s(_vm.infoVo.ownerName) + " ")]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "住址", span: 2 } },
                [_vm._v(" " + _vm._s(_vm.infoVo.address) + " ")]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "使用性质", span: 1 } },
                [_vm._v(" " + _vm._s(_vm.infoVo.useCase) + " ")]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "品牌型号", span: 1 } },
                [_vm._v(" " + _vm._s(_vm.infoVo.model) + " ")]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "车辆识别代码", span: 1 } },
                [_vm._v(" " + _vm._s(_vm.infoVo.carVim) + " ")]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "发动机号码", span: 1 } },
                [_vm._v(" " + _vm._s(_vm.infoVo.engineNumber) + " ")]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "注册日期", span: 1 } },
                [_vm._v(" " + _vm._s(_vm.infoVo.carRegisterDate) + " ")]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "发证日期", span: 1 } },
                [_vm._v(" " + _vm._s(_vm.infoVo.carIssueDate) + " ")]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "备注", span: 1 } },
                [_vm._v(" " + _vm._s(_vm.infoVo.remark) + " ")]
              ),
            ],
            1
          ),
          _vm.infoVo.state === 2
            ? _c("div", { staticClass: "car-imgs" }, [
                _c("p", [
                  _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
                  _vm._v("上传回传图片（最多两张）"),
                ]),
              ])
            : _vm._e(),
          _vm.infoVo.state === 2 ||
          _vm.infoVo.state === 4 ||
          _vm.infoVo.state === 5 ||
          _vm.infoVo.state === 6
            ? _c(
                "div",
                {
                  staticClass: "car-imgs",
                  staticStyle: { "padding-bottom": "30px" },
                },
                [
                  _c("p", [_vm._v("回传图片")]),
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c("upload-file-list", {
                        attrs: {
                          max: 2,
                          disabled: _vm.infoVo.state !== 2,
                          title: "回传图片",
                        },
                        model: {
                          value: _vm.infoVo.returnImageUrls,
                          callback: function ($$v) {
                            _vm.$set(_vm.infoVo, "returnImageUrls", $$v)
                          },
                          expression: "infoVo.returnImageUrls",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _vm.infoVo.state == 0
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "auth",
                      rawName: "v-auth",
                      value: 702,
                      expression: "702",
                    },
                  ],
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.nextStep(1)
                    },
                  },
                },
                [_vm._v("接单")]
              )
            : _vm._e(),
          _vm.infoVo.state == 1
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "auth",
                      rawName: "v-auth",
                      value: 703,
                      expression: "703",
                    },
                  ],
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.nextStep(2)
                    },
                  },
                },
                [_vm._v("已预约")]
              )
            : _vm._e(),
          _vm.infoVo.state == 2 || _vm.infoVo.state == 3
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "auth",
                      rawName: "v-auth",
                      value: 704,
                      expression: "704",
                    },
                  ],
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.nextStep(4)
                    },
                  },
                },
                [_vm._v("存在违法")]
              )
            : _vm._e(),
          _vm.infoVo.state == 2 || _vm.infoVo.state == 3
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "auth",
                      rawName: "v-auth",
                      value: 705,
                      expression: "705",
                    },
                  ],
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.nextStep(5)
                    },
                  },
                },
                [_vm._v("未到期")]
              )
            : _vm._e(),
          _vm.infoVo.state == 2 || _vm.infoVo.state == 3
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "auth",
                      rawName: "v-auth",
                      value: 706,
                      expression: "706",
                    },
                  ],
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.nextStep(6)
                    },
                  },
                },
                [_vm._v("完成")]
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }