var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { ref: "baseContainer", attrs: { paddingTop: "" } },
    [
      [
        _c(
          "el-form",
          {
            staticClass: "filter-form",
            attrs: { model: _vm.form, "label-width": "100px", size: "small" },
          },
          [
            _c("div", { staticClass: "flex-x-between" }, [
              _c(
                "div",
                { staticClass: "flex flex-shrink0" },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c(
                          "div",
                          [
                            _vm._v(" 操作门店 "),
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  effect: "light",
                                  content:
                                    "接车单的操作门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)",
                                  placement: "bottom-start",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "iconfont grow-icon_doubt",
                                  staticStyle: { color: "#9a9a9a" },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("operateCompanyCascader", {
                        attrs: {
                          operateCompanyUrl: _vm.form.operateCompanyUrl,
                        },
                        model: {
                          value: _vm.form.companyId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "companyId", $$v)
                          },
                          expression: "form.companyId",
                        },
                      }),
                    ],
                    2
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品名称" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入商品名称", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.getData.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.form.goodsName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "goodsName", $$v)
                          },
                          expression: "form.goodsName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品编码" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入商品编码", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.getData.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.form.goodsCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "goodsCode", $$v)
                          },
                          expression: "form.goodsCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "el-form-item--small" }, [
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "auth",
                            rawName: "v-auth",
                            value: 629,
                            expression: "629",
                          },
                        ],
                        staticClass: "btn_search",
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.getData },
                      },
                      [_vm._v("查询")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn_search",
                        attrs: { size: "small" },
                        on: { click: _vm.empty },
                      },
                      [_vm._v("置空")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "flex-warp" },
              [
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "库存状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择库存状态" },
                          model: {
                            value: _vm.form.inventoryStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "inventoryStatus", $$v)
                            },
                            expression: "form.inventoryStatus",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: "" },
                          }),
                          _c("el-option", {
                            attrs: { label: "低于安全库存", value: -1 },
                          }),
                          _c("el-option", {
                            attrs: { label: "正常", value: 0 },
                          }),
                          _c("el-option", {
                            attrs: { label: "高于安全库存", value: 1 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "供应商" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            clearable: "",
                            remote: "",
                            "remote-method": _vm.searchSupplierList,
                            loading: _vm.searchSupplierListLoading,
                            placeholder: "请选择供应商",
                          },
                          model: {
                            value: _vm.form.supplierId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "supplierId", $$v)
                            },
                            expression: "form.supplierId",
                          },
                        },
                        _vm._l(_vm.supplierList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        ),
      ],
      [
        _c(
          "div",
          {
            staticClass: "btns flex-x-between",
            staticStyle: { margin: "30px 0 14px 0" },
          },
          [
            _c(
              "div",
              { staticClass: "supplier-management__btn" },
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "auth",
                        rawName: "v-auth",
                        value: 129,
                        expression: "129",
                      },
                    ],
                    staticClass: "btn_insert",
                    staticStyle: { height: "35px" },
                    attrs: { size: "small" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.batchOperation(2, "批量转采购单")
                      },
                    },
                  },
                  [_vm._v("批量转采购单")]
                ),
              ],
              1
            ),
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 630,
                    expression: "630",
                  },
                ],
                staticClass: "blue",
                attrs: { size: "small", type: "text" },
                on: {
                  click: function ($event) {
                    _vm.showExportDialog = true
                  },
                },
              },
              [
                _vm._v("导出列表"),
                _c("i", {
                  staticClass: "iconfont grow-icon_daochu el-icon--right",
                }),
              ]
            ),
          ],
          1
        ),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "fixedScrollBar",
                rawName: "v-fixedScrollBar",
                value: _vm.$refs.baseContainer,
                expression: "$refs.baseContainer",
              },
            ],
            ref: "tableDataRef",
            staticClass: "custom-table",
            style: { height: _vm.baseContainerHeigth },
            attrs: {
              data: _vm.listData,
              border: "",
              "show-overflow-tooltip": "",
              "scrollbar-always-on": "",
            },
            on: { "selection-change": _vm.handleSelectionChange },
          },
          [
            _c("el-table-column", {
              attrs: {
                fixed: "",
                type: "selection",
                width: "40",
                prop: "selection",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                type: "index",
                label: "序号",
                fixed: _vm.isManagerOrSupplyChainManager,
                width: "60",
                align: "center",
              },
            }),
            _vm.isManagerOrSupplyChainManager
              ? _c("el-table-column", {
                  attrs: {
                    width: "100",
                    label: "门店",
                    fixed: _vm.isManagerOrSupplyChainManager,
                    prop: "companyName",
                  },
                })
              : _vm._e(),
            _c("el-table-column", {
              attrs: {
                width: "100",
                label: "商品ID",
                fixed: _vm.isManagerOrSupplyChainManager,
                prop: "goodsId",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "180",
                label: "商品名称",
                fixed: _vm.isManagerOrSupplyChainManager,
                prop: "goodName",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "180",
                label: "是否禁止采购",
                prop: "disablePurchaseStr",
              },
            }),
            _vm.isManagerOrSupplyChainManager
              ? _c("el-table-column", {
                  attrs: {
                    width: "180",
                    label: "商品分类",
                    prop: "goodCategory",
                  },
                })
              : _vm._e(),
            _vm.isManagerOrSupplyChainManager
              ? _c("el-table-column", {
                  attrs: { width: "130", label: "商品品牌", prop: "goodBrand" },
                })
              : _vm._e(),
            _vm.isManagerOrSupplyChainManager
              ? _c("el-table-column", {
                  attrs: { width: "130", label: "商品编码", prop: "goodCode" },
                })
              : _vm._e(),
            _vm.isManagerOrSupplyChainManager
              ? _c("el-table-column", {
                  attrs: { width: "90", label: "单位", prop: "unit" },
                })
              : _vm._e(),
            _vm.isManagerOrSupplyChainManager
              ? _c("el-table-column", {
                  attrs: {
                    width: "120",
                    label: "近一个月销量",
                    prop: "lastMonthSalesVol",
                  },
                })
              : _vm._e(),
            _vm.isManagerOrSupplyChainManager
              ? _c("el-table-column", {
                  attrs: {
                    width: "140",
                    label: "前3个月月均销量",
                    prop: "firstThreeAverageSalesVol",
                  },
                })
              : _vm._e(),
            _vm.isManagerOrSupplyChainManager
              ? _c("el-table-column", {
                  attrs: {
                    width: "120",
                    label: "当前库存数",
                    prop: "currInventory",
                  },
                })
              : _vm._e(),
            _vm.isManagerOrSupplyChainManager
              ? _c(
                  "el-table-column",
                  {
                    attrs: {
                      width: "120",
                      label: "占用库存",
                      prop: "takeUpInventory",
                    },
                  },
                  [
                    _c("template", { slot: "header" }, [
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: "维修待领料+调拨出库（待出库）",
                              },
                            },
                            [
                              _c("span", { staticClass: "text-ellipsis" }, [
                                _vm._v("占用库存"),
                              ]),
                            ]
                          ),
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                effect: "light",
                                placement: "bottom-start",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    color: "#666666",
                                    "font-size": "13px",
                                    "line-height": "16px",
                                    "text-indent": "15px",
                                  },
                                  attrs: { slot: "content" },
                                  slot: "content",
                                },
                                [_vm._v(" 维修待领料+调拨出库（待出库） ")]
                              ),
                              _c("i", {
                                staticClass: "iconfont grow-icon_doubt",
                                staticStyle: { "margin-left": "5px" },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                )
              : _vm._e(),
            _vm.isManagerOrSupplyChainManager
              ? _c("el-table-column", {
                  attrs: {
                    width: "120",
                    label: "采购单待入库数量",
                    prop: "purchaseWaitInventoryIn",
                  },
                })
              : _vm._e(),
            _vm.isManagerOrSupplyChainManager
              ? _c("el-table-column", {
                  attrs: {
                    width: "120",
                    label: "调拨待入库数量",
                    prop: "allocateWaitInventoryIn",
                  },
                })
              : _vm._e(),
            _c("el-table-column", {
              attrs: { width: "180", label: "安全库存", prop: "safeStock" },
            }),
            _c("el-table-column", {
              attrs: {
                width: "100",
                label: "建议补货数",
                prop: "recommendReplenish",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "120",
                label: "库存状态",
                prop: "inventoryStatus",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "160",
                label: "上一次采购时间",
                prop: "lastPurchaseDate",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "160",
                label: "上一次采购供应商",
                prop: "lastSupplier",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "140",
                label: "上一次采购单价",
                prop: "lastCostPrice",
              },
            }),
            _vm.isManagerOrSupplyChainManager
              ? _c("el-table-column", {
                  attrs: {
                    width: "160",
                    label: "近3个月最低采购单价",
                    prop: "lastThreeMonthLowestPurchasePrice",
                  },
                })
              : _vm._e(),
            _vm.isManagerOrSupplyChainManager
              ? _c("el-table-column", {
                  attrs: {
                    width: "160",
                    label: "近3个月最低采购单价所属供应商",
                    prop: "lastThreeMonthLowestPurchasePriceSupplier",
                  },
                })
              : _vm._e(),
            _vm.isManagerOrSupplyChainManager
              ? _c("el-table-column", {
                  attrs: {
                    width: "160",
                    label: "其它门店上一次最低采购价",
                    prop: "otherCompanyLastLowestPurchasePrice",
                  },
                })
              : _vm._e(),
            _vm.isManagerOrSupplyChainManager
              ? _c("el-table-column", {
                  attrs: {
                    width: "160",
                    label: "其它门店上一次最低采购价的供应商",
                    prop: "otherCompanyLastLowestPurchasePriceSupplier",
                  },
                })
              : _vm._e(),
            _c("el-table-column", {
              attrs: { label: "是否转采购单", width: "120" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "auth",
                              rawName: "v-auth",
                              value: 129,
                              expression: "129",
                            },
                          ],
                          staticClass: "text_operation blue",
                          on: {
                            click: function ($event) {
                              return _vm.handleToOldSysPurchasePageEdit(
                                scope.row
                              )
                            },
                          },
                        },
                        [_vm._v("是")]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c("export-dialog", {
          attrs: {
            visibility: _vm.showExportDialog,
            total: _vm.total,
            pageOption: _vm.pageOption,
            filterForm: _vm.form,
            url: _vm.exportUrl,
          },
          on: {
            "update:visibility": function ($event) {
              _vm.showExportDialog = $event
            },
          },
        }),
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c("pagination", {
            attrs: { total: _vm.total },
            on: { pageChange: _vm.changePage },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }