var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { ref: "baseContainer", attrs: { paddingTop: "" } },
    [
      [
        _c(
          "div",
          [
            _c(
              "el-form",
              {
                staticClass: "filter-form",
                attrs: {
                  model: _vm.form,
                  "label-width": "100px",
                  size: "small",
                },
              },
              [
                _c("div", { staticClass: "flex-x-between" }, [
                  _c(
                    "div",
                    { staticClass: "flex-warp flex-shrink0" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "素材标题" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "搜索标题/编号",
                              clearable: "",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.doSearch.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.form.titleAndCodeMsg,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "titleAndCodeMsg", $$v)
                              },
                              expression: "form.titleAndCodeMsg",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "素材状态" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择", clearable: "" },
                              model: {
                                value: _vm.form.state,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "state", $$v)
                                },
                                expression: "form.state",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "已下架", value: 4 },
                              }),
                              _c("el-option", {
                                attrs: { label: "已上架", value: 3 },
                              }),
                              _c("el-option", {
                                attrs: { label: "待审核", value: 1 },
                              }),
                              _c("el-option", {
                                attrs: { label: "不通过", value: 2 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "操作时间" } },
                        [
                          _c("el-date-picker", {
                            staticClass: "w100",
                            attrs: {
                              type: "daterange",
                              "unlink-panels": "",
                              "range-separator": "至",
                              "start-placeholder": "起始时间",
                              "end-placeholder": "截止时间",
                              "value-format": "yyyy-MM-dd",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.dateRangeChange()
                              },
                            },
                            model: {
                              value: _vm.form.operateTimes,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "operateTimes", $$v)
                              },
                              expression: "form.operateTimes",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "flex" }, [
                    _c("div", { staticClass: "el-form-item--small" }, [
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "btn_search",
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.doSearch },
                            },
                            [_vm._v("查询")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "btn_search",
                              attrs: { size: "small" },
                              on: { click: _vm.empty },
                            },
                            [_vm._v("置空")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "flex-warp" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "供稿门店" } },
                      [
                        _c("operateCompanyCascader", {
                          attrs: {
                            operateCompanyUrl: _vm.form.operateCompanyUrl,
                          },
                          model: {
                            value: _vm.form.contributeCompanyId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "contributeCompanyId", $$v)
                            },
                            expression: "form.contributeCompanyId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "审稿人" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              filterable: "",
                              clearable: "",
                              remote: "",
                              placeholder: "搜索姓名、手机号",
                              "remote-method": _vm.searchAdminJobListUser,
                              loading: _vm.searchAdminJobListUserLoading,
                            },
                            model: {
                              value: _vm.form.verifierId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "verifierId", $$v)
                              },
                              expression: "form.verifierId",
                            },
                          },
                          _vm._l(
                            _vm.adminJobListUserList,
                            function (item, index) {
                              return _c(
                                "el-option",
                                {
                                  key: index,
                                  attrs: {
                                    label: item.realName,
                                    value: item.userIds,
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.selectVerifierId(item)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        float: "left",
                                        color: "#8492a6",
                                        "font-size": "13px",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.realName))]
                                  ),
                                  _c(
                                    "span",
                                    { staticStyle: { float: "right" } },
                                    [_vm._v(_vm._s(item.phone))]
                                  ),
                                ]
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "供稿时间" } },
                      [
                        _c("el-date-picker", {
                          staticClass: "w100",
                          attrs: {
                            type: "daterange",
                            "unlink-panels": "",
                            "range-separator": "至",
                            "start-placeholder": "起始时间",
                            "end-placeholder": "截止时间",
                            "value-format": "yyyy-MM-dd",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.dateRangeChange()
                            },
                          },
                          model: {
                            value: _vm.form.contributeTimes,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "contributeTimes", $$v)
                            },
                            expression: "form.contributeTimes",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "供稿人" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              filterable: "",
                              clearable: "",
                              remote: "",
                              placeholder: "搜索姓名、手机号",
                              "remote-method": _vm.searchAdminJobListUser,
                              loading: _vm.searchAdminJobListUserLoading,
                              disabled: _vm.disabledCreatorId,
                            },
                            on: { clear: _vm.delValue },
                            model: {
                              value: _vm.form.creatorId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "creatorId", $$v)
                              },
                              expression: "form.creatorId",
                            },
                          },
                          _vm._l(
                            _vm.adminJobListUserList,
                            function (item, index) {
                              return _c(
                                "el-option",
                                {
                                  key: index,
                                  attrs: {
                                    label: item.realName,
                                    value: item.userIds,
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.selectCreatorId(item)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        float: "left",
                                        color: "#8492a6",
                                        "font-size": "13px",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.realName))]
                                  ),
                                  _c(
                                    "span",
                                    { staticStyle: { float: "right" } },
                                    [_vm._v(_vm._s(item.phone))]
                                  ),
                                ]
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ],
      [
        _c("div", { staticClass: "btns flex-x-between" }, [
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  staticClass: "btn_search",
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.materialEdit },
                },
                [_vm._v("投稿")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "btn_search",
                  attrs: { size: "small" },
                  on: { click: _vm.draftBox },
                },
                [_vm._v("草稿箱")]
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          {
            staticClass: "flex h100",
            staticStyle: { flex: "1", overflow: "auto", "margin-top": "20px" },
          },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "fixedScrollBar",
                    rawName: "v-fixedScrollBar",
                    value: _vm.$refs.baseContainer,
                    expression: "$refs.baseContainer",
                  },
                ],
                staticClass: "custom-table",
                attrs: { data: _vm.materialList, border: "" },
              },
              [
                _c("el-table-column", {
                  attrs: { type: "index", label: "序号", width: "60" },
                }),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "120",
                    label: "素材标题",
                    prop: "title",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          row.state == 3
                            ? _c(
                                "p",
                                {
                                  staticClass: "blue pointer",
                                  on: {
                                    click: function ($event) {
                                      return _vm.openInnerDrawer(2, row.guideId)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(row.title) + " ")]
                              )
                            : _vm._e(),
                          row.state == 4
                            ? _c(
                                "p",
                                {
                                  staticClass: "blue pointer",
                                  on: {
                                    click: function ($event) {
                                      return _vm.openInnerDrawer(3, row.guideId)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(row.title) + " ")]
                              )
                            : _vm._e(),
                          row.state == 1
                            ? _c(
                                "p",
                                {
                                  staticClass: "blue pointer",
                                  on: {
                                    click: function ($event) {
                                      return _vm.openInnerDrawer(4, row.guideId)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(row.title) + " ")]
                              )
                            : _vm._e(),
                          row.state == 2
                            ? _c(
                                "p",
                                {
                                  staticClass: "blue pointer",
                                  on: {
                                    click: function ($event) {
                                      return _vm.openInnerDrawer(5, row.guideId)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(row.title) + " ")]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "120",
                    label: "编号",
                    prop: "guideCode",
                  },
                }),
                _c("el-table-column", {
                  attrs: { "min-width": "100", label: "状态", prop: "state" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          row.state == 3
                            ? _c("div", { staticClass: "flex" }, [
                                _c("p", {
                                  staticClass: "dot",
                                  staticStyle: { background: "#33ab79" },
                                }),
                                _c("p", [_vm._v("已上架")]),
                              ])
                            : _vm._e(),
                          row.state == 4
                            ? _c("div", { staticClass: "flex" }, [
                                _c("p", {
                                  staticClass: "dot",
                                  staticStyle: { background: "#d0d3d9" },
                                }),
                                _c("p", { staticStyle: { color: "#84878c" } }, [
                                  _vm._v("已下架"),
                                ]),
                              ])
                            : _vm._e(),
                          row.state == 2
                            ? _c("div", { staticClass: "flex" }, [
                                _c("p", {
                                  staticClass: "dot",
                                  staticStyle: { background: "#e84c4c" },
                                }),
                                _c("p", [_vm._v("不通过")]),
                              ])
                            : _vm._e(),
                          row.state == 1
                            ? _c("div", { staticClass: "flex" }, [
                                _c("p", {
                                  staticClass: "dot",
                                  staticStyle: { background: "#ee8f33" },
                                }),
                                _c("p", [_vm._v("待审核")]),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "100",
                    label: "素材类型",
                    prop: "type",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _vm._v(
                            " " + _vm._s(row.type == 0 ? "技术案例" : "") + " "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "100",
                    label: "供稿时间",
                    prop: "offerTime",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "100",
                    label: "供稿人",
                    prop: "creatorName",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "160",
                    label: "供稿门店",
                    prop: "companyName",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "100",
                    label: "操作时间",
                    prop: "lastUpdated",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "100",
                    label: "审稿人",
                    prop: "verifierName",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "120",
                    label: "原因",
                    prop: "noPassReason",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          row.state == 2
                            ? _c(
                                "el-tooltip",
                                { attrs: { content: row.noPassReason } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "text-ellipsis-2-row" },
                                    [
                                      _vm._v(
                                        " " + _vm._s(row.noPassReason) + " "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c("pagination", {
            attrs: { total: _vm.total },
            on: { pageChange: _vm.changePage },
          }),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "草稿箱",
            visible: _vm.draftFirstDrawer,
            size: "55%",
            "before-close": _vm.handleClose,
            modal: "",
            wrapperClosable: "",
            "close-on-press-escape": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.draftFirstDrawer = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { margin: "0 8px 0 24px", overflow: "scroll" } },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "fixedScrollBar",
                      rawName: "v-fixedScrollBar",
                      value: _vm.$refs.baseContainer,
                      expression: "$refs.baseContainer",
                    },
                  ],
                  attrs: { data: _vm.draftList, border: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "index", label: "序号", width: "60" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "120",
                      label: "素材标题",
                      prop: "title",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c(
                              "p",
                              {
                                staticClass: "blue pointer",
                                on: {
                                  click: function ($event) {
                                    return _vm.openInnerDrawer(1, row.guideId)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(row.title) + " ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "100",
                      label: "更新时间",
                      prop: "lastUpdated",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "drawer-footer" },
            [
              _c("pagination", {
                attrs: { total: _vm.totalDrawer },
                on: { pageChange: _vm.changeDrawerPage },
              }),
            ],
            1
          ),
        ]
      ),
      _vm.innerDrawer
        ? _c("draft-drawer", {
            attrs: {
              innerDrawer: _vm.innerDrawer,
              detailType: _vm.detailType,
              guideId: _vm.guideId,
            },
            on: {
              "update:innerDrawer": function ($event) {
                _vm.innerDrawer = $event
              },
              "update:inner-drawer": function ($event) {
                _vm.innerDrawer = $event
              },
              updateData: _vm.doSearch,
              upDraft: _vm.getDrafts,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }