<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <el-form
        class="filter-form"
        :model="form"
        label-width="100px"
        size="small"
      >
        <div class="flex-x-between">
          <div class="flex flex-shrink0">
            <el-form-item>
              <template slot="label">
                <div>
                  回款门店
                  <el-tooltip
                    effect="light"
                    content="回款门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)"
                    placement="bottom-start"
                  >
                    <i
                      class="iconfont grow-icon_doubt"
                      style="color: #9a9a9a"
                    ></i>
                  </el-tooltip>
                </div>
              </template>
              <operateCompanyCascader
                v-model="form.operateCompanyId"
                :operateCompanyUrl="form.operateCompanyUrl"
              ></operateCompanyCascader>
            </el-form-item>
            <el-form-item label="回款主体" prop="invoicingEntity">
              <el-select
                v-model="form.invoicingEntity"
                clearable
                filterable
                placeholder="请选择回款主体"
              >
                <el-option
                  v-for="item in invoicingEntities"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="保险公司" prop="insuranceCompanyId">
              <el-select
                v-model="form.insuranceCompanyId"
                clearable
                filterable
                placeholder="请选择保险公司"
              >
                <el-option
                  v-for="item in insuranceCompanyList"
                  :key="item.id"
                  :label="item.insuranceName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="el-form-item--small">
            <div
              @click="showMore = !showMore"
              class="el-form-item__label pointer"
            >
              更多筛选
              <i
                :class="{
                  primary: showMore,
                  'el-icon-arrow-right': !showMore,
                  'el-icon-arrow-down': showMore,
                }"
              ></i>
            </div>
            <div class="flex">
              <el-button
                v-auth="648"
                class="btn_search"
                type="primary"
                size="small"
                @click="getData"
                >查询</el-button
              >
              <el-button class="btn_search" size="small" @click="empty"
                >置空</el-button
              >
            </div>
          </div>
        </div>
        <div class="flex-warp" v-show="showMore">
          <template>
            <el-form-item label="回款时间">
              <el-date-picker
                class="w100"
                v-model="form.receivingDateTimes"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="截止时间"
                value-format="yyyy-MM-dd"
                @input="dateRangeChange()"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="银行摘要">
              <el-input
                v-model="form.bankSummary"
                placeholder="请输入银行摘要"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
            <el-form-item label="资金流水号">
              <el-input
                v-model="form.transactionSeq"
                placeholder="请输入资金流水号"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
            <el-form-item label="回款状况">
              <el-select
                v-model="form.receivingStatus"
                placeholder="请选择回款状况"
              >
                <el-option
                  v-for="item in receivingStatusEnum"
                  :key="item.value"
                  :label="item.desc"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </template>
        </div>
      </el-form>
    </template>

    <template>
      <div style="margin: 30px 0 14px 0" class="btns flex-x-between">
        <div>
          <el-button v-auth="650" size="small" class="btn_insert" @click="add"
            >新增流水</el-button
          >
          <el-button
            size="small"
            v-auth="663"
            style="margin-left: 10px"
            @click="openImportDialog()"
          >
            导入流水<i class="el-icon-arrow-right"></i>
          </el-button>
        </div>
        <div>
          <el-button
            v-auth="649"
            size="small"
            type="text"
            class="blue"
            @click="downloadAllRecords()"
            >导出流水<i class="iconfont grow-icon_daochu el-icon--right"></i
          ></el-button>
        </div>
      </div>

      <el-table
        class="custom-table"
        :data="listData"
        border
        v-fixedScrollBar="$refs.baseContainer"
      >
        <el-table-column type="index" label="序号" width="60">
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <span
              v-auth="651"
              class="text_operation blue"
              style="margin-right: 10px"
              @click="handleEdit(scope.row)"
              >编辑流水</span
            >
            <span
              v-auth="652"
              class="text_operation blue"
              style="margin-right: 10px"
              @click="handleDelete(scope.row)"
              >删除流水</span
            >
          </template>
        </el-table-column>
        <el-table-column
          width="200"
          label="回款门店"
          prop="receivingCompanyName"
        >
        </el-table-column>
        <el-table-column
          width="200"
          label="系统流水编号"
          prop="sysStatementSeqCode"
        >
        </el-table-column>
        <el-table-column width="200" label="回款主体" prop="invoicingEntity">
        </el-table-column>
        <el-table-column width="200" label="银行账号" prop="bankAccount">
        </el-table-column>
        <el-table-column width="200" label="开户行" prop="depositBank">
        </el-table-column>
        <el-table-column width="162" label="回款时间" prop="receivingDatetime">
        </el-table-column>
        <el-table-column
          width="200"
          label="付款主体"
          prop="insuranceCompanyName"
        >
        </el-table-column>
        <el-table-column width="200" label="付款账户" prop="paymentAccount">
        </el-table-column>
        <el-table-column min-width="140" label="银行摘要" prop="bankSummary">
          <template #default="{ row }">
            <el-tooltip :content="row.bankSummary">
              <div class="text-ellipsis-2-row">
                {{ row.bankSummary }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column width="140" label="金额" prop="amount">
        </el-table-column>
        <el-table-column width="200" label="资金流水号" prop="transactionSeq">
        </el-table-column>
        <el-table-column width="140" label="已认领金额" prop="claimAmount">
        </el-table-column>
        <el-table-column width="140" label="可认领金额" prop="unClaimAmount">
        </el-table-column>
        <el-table-column width="140" label="操作时间" prop="operateDateTime">
        </el-table-column>
        <el-table-column width="140" label="操作人" prop="operator">
        </el-table-column>
      </el-table>
      <editBankTransactionDialog
        v-if="showEditDialog"
        :visibility.sync="showEditDialog"
        :id="editId"
        :entityData="rowEntityData"
        :companyList="companyList"
        :insuranceCompanyList="insuranceCompanyList"
        :invoicingEntities="invoicingEntities"
        @save="getData(false)"
      ></editBankTransactionDialog>

      <!-- 导入弹窗 -->
      <import-dialog
        v-if="importDialogVisibility"
        :visibility.sync="importDialogVisibility"
        :showTip="false"
        title="银行流水导入"
        templateFileUrl="https://growxny-dev-center.oss-cn-guangzhou.aliyuncs.com/template/%E7%90%86%E8%B5%94%E8%AE%A4%E9%A2%86%E6%A0%B8%E9%94%80%E9%93%B6%E8%A1%8C%E6%B5%81%E6%B0%B4%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF_20240527.xlsx"
        :uploadFileApi="importApi"
        :getLastResultApi="importResultApi"
        :importFailDetail="importMessageExportUrlApi"
      ></import-dialog>
    </template>

    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
  </base-container>
</template>

<script>
// 组件
import pagination from '@/components/Pagination.vue';
import editBankTransactionDialog from './components/editBankTransactionDialog.vue';
import importDialog from '@/components/businessComponent/importDialog.vue';
import operateCompanyCascader from '@/components/businessComponent/operateCompanyCascader';
// 接口
import { downloadFile } from '@/utils/fileApi';
import {
  getTransactionList,
  deleteTransaction,
  transactionListExportUrl,
  getPreLoadSaveOrUpdateDropListData,
  importTransactionList,
  importTransactionListResult,
  importTransactionListMessageExportUrl,
} from '@/api/financial/financialOver';
import { hasAuth } from '@/utils/permissions';
import { postGetAllCompanyList } from '@/api/stock/inventoryWarning/index';

import { mapState } from 'vuex';

export default {
  name: 'transaction_bank',
  components: {
    pagination,
    editBankTransactionDialog,
    importDialog,
    operateCompanyCascader,
  },
  data() {
    return {
      importApi: importTransactionList,
      importMessageExportUrlApi: importTransactionListMessageExportUrl,
      importResultApi: importTransactionListResult,

      editId: '',
      rowEntityData: {},
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },

      showEditDialog: false,
      startPage: 1,
      endPage: 1,
      exportUrl: transactionListExportUrl,

      form: {},

      receivingStatusEnum: [],
      // 展示更多筛选
      showMore: true,
      listData: [],
      companyList: [],
      insuranceCompanyList: [],
      invoicingEntities: [],
      // 导入弹窗可见性
      importDialogVisibility: false,
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.user,
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  activated() {},
  created() {
    this.init();
    // 拉取用户的门店列表，以便给用户新增、编辑使用
    this.preLoadSaveOrUpdateDropListData();
    this.getData();
  },
  methods: {
    init() {
      this.form.operateCompanyId = [this.companyId];
      this.receivingStatusEnum.push({
        value: '',
        desc: '全部',
      });
      this.receivingStatusEnum.push({
        value: 0,
        desc: '部分回款',
      });
      this.receivingStatusEnum.push({
        value: 1,
        desc: '全部回款',
      });
    },
    //下面是解决设置日期默认值时重新选择无法刷新日期的方法
    dateRangeChange() {
      let _this = this;
      _this.$nextTick(() => {
        _this.$forceUpdate();
      });
    },
    /**
     * 预先加载新增/编辑的下拉框数据
     */
    preLoadSaveOrUpdateDropListData() {
      if (!(hasAuth(650) || hasAuth(651))) {
        return;
      }
      this.getAllCompanyList();
      this.getInsuranceCompanyListAndInvoicingEntities();
    },
    /**
     * 查询体系内的门店列表
     */
    async getInsuranceCompanyListAndInvoicingEntities() {
      const _this = this;
      getPreLoadSaveOrUpdateDropListData().then((res) => {
        const { insuranceCompanyList, invoicingEntities } = res;
        _this.insuranceCompanyList = insuranceCompanyList;
        _this.invoicingEntities = invoicingEntities;
      });
    },
    /**
     * 查询体系内的门店列表
     */
    async getAllCompanyList() {
      const data = {};
      postGetAllCompanyList(data).then((res) => {
        this.companyList = [];
        const { company, companyChildren } = res.data.resultObject || {};
        if (companyChildren && companyChildren.length > 0) {
          companyChildren.forEach((com) => {
            this.companyList.push({
              companyId: com.companyId,
              companyName: com.companyName,
              shortName: com.shortName,
            });
          });
        } else {
          if (company) {
            this.companyList.push({
              companyId: company.companyId,
              companyName: company.companyName,
              shortName: company.shortName,
            });
          }
        }
      });
    },
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getData(false);
    },
    openImportDialog() {
      if (!hasAuth(663)) {
        this.$message.warning('没有权限');
      }
      this.importDialogVisibility = true;
    },
    /**
     * 加载数据
     */
    getData(reset = true) {
      var res = hasAuth(648);
      if (!res) {
        return;
      }
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      var times = {
        receivingBeginDateTime: this.form.receivingDateTimes?.[0]
          ? this.form.receivingDateTimes?.[0] + ' 00:00:00'
          : '',
        receivingEndDateTime: this.form.receivingDateTimes?.[1]
          ? this.form.receivingDateTimes?.[1] + ' 23:59:59'
          : '',
      };

      var data = Object.assign({}, this.form, this.pageOption, times);
      delete data.receivingDateTimes;
      getTransactionList(data).then((res) => {
        // 修改总条数
        this.total = res.total;
        // 赋值records
        this.listData = res.records;
      });
    },
    downloadAllRecords() {
      downloadFile({
        url: this.exportUrl,
        data: Object.assign(this.form, {}),
      });
    },
    /**
     * 新增
     */
    add() {
      this.editId = '';
      this.rowEntityData = {};
      this.$nextTick().then(() => {
        this.showEditDialog = true;
      });
    },
    /**
     * 编辑
     */
    handleEdit(row) {
      this.editId = row.id;
      this.rowEntityData = row;
      this.$nextTick().then(() => {
        this.showEditDialog = true;
      });
    },
    /**
     * 编辑
     */
    async handleDelete(row) {
      if (row.claimAmount > 0) {
        this.$alert('已经有认领记录了，不能删除');
        return;
      }
      let res = await this.$confirm('确定要删除这个流水吗?', {
        title: '提示',
        type: 'warning',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      });
      if (res === 'confirm') {
        const bodyData = {
          id: row.id,
        };
        deleteTransaction(bodyData).then((res) => {
          this.$message.success(res || '删除成功！');
          this.getData(false);
        });
      }
    },
    /**
     * 置空输入框数据
     */
    empty() {
      this.form = {
        operateCompanyId: [this.companyId],
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.el-form {
  width: 100%;
}
</style>
