var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "温馨提示",
        visible: _vm.visibility,
        width: "50%",
        "close-on-click-modal": false,
        "before-close": _vm.handleClose,
      },
    },
    [
      _c("p", { staticClass: "tip-word" }, [
        _c("i", { staticClass: "el-icon-warning el-icon__left" }),
        _vm._v(
          " 当前车单消费客户“" +
            _vm._s(_vm.customerName) +
            "”车单项目（标红项）报价与协议价存在不一致 "
        ),
      ]),
      _c(
        "el-table",
        {
          staticClass: "service-table",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            "default-expand-all": "",
            "row-key": "id",
            "tree-props": {
              children: "goods",
            },
          },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "title", width: "80", label: "序号" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("div", { class: { red: !row.isConsistent } }, [
                      _vm._v(_vm._s(row.title)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "itemName", label: "项目名称", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("div", { class: { red: !row.isConsistent } }, [
                      _vm._v(_vm._s(row.itemName)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "itemCode", label: "项目编码", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("div", { class: { red: !row.isConsistent } }, [
                      _vm._v(_vm._s(row.itemCode)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "price", label: "车单报价", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("div", { class: { red: !row.isConsistent } }, [
                      _vm._v(_vm._s(row.price)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "agreePrice", label: "协议价", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("div", { class: { red: !row.isConsistent } }, [
                      _vm._v(" " + _vm._s(row.agreePrice) + " "),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("p", { staticClass: "tip-word" }, [
            _vm._v("点击确定返回修改标红项的报价为协议价"),
          ]),
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.handleClose } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.confirm },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }