<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <div>
        <el-form
          class="filter-form"
          :model="form"
          label-width="100px"
          size="small"
        >
          <div class="flex-x-between">
            <div class="flex-warp flex-shrink0">
              <el-form-item>
                <template slot="label">
                  <div>
                    操作门店
                    <el-tooltip
                      effect="light"
                      content="定损单的操作门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)"
                      placement="bottom-start"
                    >
                      <i
                        class="iconfont grow-icon_doubt"
                        style="color: #9a9a9a"
                      ></i>
                    </el-tooltip>
                  </div>
                </template>
                <operateCompanyCascader
                  v-model="form.operateCompanyId"
                  :operateCompanyUrl="form.operateCompanyUrl"
                ></operateCompanyCascader>
              </el-form-item>
              <el-form-item label="车牌号">
                <el-input
                  v-model="form.carNumber"
                  placeholder="请输入"
                  clearable
                  @keyup.enter.native="doSearch"
                ></el-input>
              </el-form-item>
              <el-form-item label="报案号">
                <el-input
                  v-model="form.caseNum"
                  placeholder="请输入"
                  clearable
                  @keyup.enter.native="doSearch"
                ></el-input>
              </el-form-item>
            </div>
            <div class="flex">
              <div class="el-form-item--small">
                <div
                  @click="showMore = !showMore"
                  class="el-form-item__label pointer"
                >
                  更多筛选
                  <i
                    :class="{
                      primary: true,
                      'el-icon-arrow-right': !showMore,
                      'el-icon-arrow-down': showMore,
                    }"
                  ></i>
                </div>
                <div class="flex">
                  <el-button
                    class="btn_search"
                    type="primary"
                    size="small"
                    @click="doSearch"
                    >查询</el-button
                  >
                  <el-button class="btn_search" size="small" @click="empty"
                    >置空</el-button
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="flex-warp">
            <el-form-item label="接车单号">
              <el-input
                v-model="form.billCode"
                placeholder="请输入"
                clearable
                @keyup.enter.native="doSearch"
              ></el-input>
            </el-form-item>
            <el-form-item label="签约客户">
              <el-select
                v-model="form.clientMessage"
                filterable
                clearable
                remote
                placeholder="请输入姓名"
                :remote-method="searchClient"
                :loading="searchClientLoading"
              >
                <el-option
                  v-for="item in clientList"
                  :key="item.id"
                  :label="item.clientName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="车单状态">
              <el-select
                v-model="form.billState"
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in carBillState"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="接车进店日期">
              <el-date-picker
                class="w100"
                v-model="form.intoStoreTime"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="截止时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <template v-if="showMore">
              <el-form-item label="回款认领日期">
                <el-date-picker
                  class="w100"
                  v-model="form.paymentCollectionTime"
                  type="daterange"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="起始时间"
                  end-placeholder="截止时间"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="出损日期">
                <el-date-picker
                  class="w100"
                  v-model="form.damageOfTime"
                  type="daterange"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="起始时间"
                  end-placeholder="截止时间"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="提交定损日期">
                <el-date-picker
                  class="w100"
                  v-model="form.breakageTime"
                  type="daterange"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="起始时间"
                  end-placeholder="截止时间"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </template>
          </div>
        </el-form>
      </div>
    </template>
    <!-- 以下是表格 -->
    <template>
      <div style="display: flex; justify-content: end">
        <el-button
          v-auth="606"
          size="small"
          type="text"
          class="blue"
          @click="showExportDialog = true"
          >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
        ></el-button>
      </div>
      <div class="flex h100">
        <el-table
          class="custom-table"
          :data="tableData"
          border
          v-fixedScrollBar="$refs.baseContainer"
        >
          <el-table-column type="index" label="序号" width="80">
          </el-table-column>
          <el-table-column
            width="180"
            label="操作门店"
            prop="operateCompanyName"
          >
          </el-table-column>
          <el-table-column width="180" label="报案号" prop="caseNum">
          </el-table-column>
          <el-table-column width="180" label="车牌号" prop="carNumber">
          </el-table-column>
          <el-table-column width="150" label="签约客户" prop="clientName">
          </el-table-column>
          <el-table-column width="200" label="进店时间" prop="intoStoreTime">
          </el-table-column>
          <el-table-column width="200" label="接车单号" prop="billCode">
          </el-table-column>
          <el-table-column width="100" label="定损单状态">
            <template #default="{ row }">
              <p>
                <template
                  v-if="
                    row.lossDeterminationState === 2 &&
                    row.investigationState === 2
                  "
                  >待复勘</template
                >
                <template
                  v-else-if="
                    row.lossDeterminationState === 2 && row.recoveryState === 2
                  "
                  >待收旧</template
                >
                <template v-else>
                  {{
                    row.lossDeterminationState | dict('lossDeterminationState')
                  }}
                </template>
              </p>
            </template>
          </el-table-column>
          <el-table-column width="100" label="车单状态">
            <template #default="{ row }">
              <!-- 车单状态 -->
              <p>
                {{ row.billState | dict('carBillState') }}
                <span class="red" v-if="row.billOtherState"
                  >({{ row.billOtherState }})</span
                >
              </p>
              <p class="color999">
                <!-- 报价审核状态 -->
                <!-- {{ row.offerReviewState== 0 ? '/待审核' : '/报价已审' }} -->
                <!-- 检车状态 -->
                <!-- {{ row.detectionState | dict('detectionState') }} -->
              </p>
              <!-- 施工状态字段 -->
              <div v-if="row.dispatchState !== undefined">
                <span> /</span>
                {{ row.dispatchState | dict('dispatchState') }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            width="180"
            label="报损时间"
            prop="reportingLossesTime"
          >
          </el-table-column>
          <el-table-column width="180" label="调度时间" prop="dispatchTime">
          </el-table-column>
          <el-table-column
            width="180"
            label="拍照对件时间"
            prop="photographTime"
          >
          </el-table-column>
          <el-table-column width="180" label="提交定损时间" prop="breakageTime">
          </el-table-column>
          <el-table-column width="180" label="出损时间" prop="damageOfTime">
          </el-table-column>
          <el-table-column
            width="180"
            label="复勘时间"
            prop="investigationTime"
          >
          </el-table-column>
          <el-table-column width="180" label="收旧时间" prop="recoveryTime">
          </el-table-column>
          <el-table-column
            width="180"
            label="提交资料时间"
            prop="submissionTime"
          >
          </el-table-column>
          <el-table-column
            width="200"
            label="回款时间"
            prop="paymentCollectionTime"
          >
          </el-table-column>
          <el-table-column width="100" label="车损金额">
            <template #default="{ row }">
              {{ row.carDamageAmount | toThousands }}
            </template>
          </el-table-column>
          <el-table-column width="100" label="预估金额">
            <template #default="{ row }">
              {{ row.estimatedAmount | toThousands }}
            </template>
          </el-table-column>
          <el-table-column width="100" label="核损金额">
            <template #default="{ row }">
              {{ row.lossAssessmentAmount | toThousands }}
            </template>
          </el-table-column>
          <el-table-column width="100" label="定损金额">
            <template #default="{ row }">
              {{ row.lossAmount | toThousands }}
            </template>
          </el-table-column>
          <el-table-column width="100" label="单据金额">
            <template #default="{ row }">
              {{ row.documentAmount | toThousands }}
            </template>
          </el-table-column>
          <el-table-column width="100" label="毛利率" prop="grossProfitRate">
            <template #default="{ row }">
              {{ row.grossProfitRate }}
              <span v-if="row.grossProfitRate !== undefined">%</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <export-dialog
        :visibility.sync="showExportDialog"
        :total="total"
        :pageOption="pageOption"
        :filterForm="form"
        :url="exportUrl"
      ></export-dialog>
    </template>
    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
  </base-container>
</template>
<script>
// 组件
import pagination from '@/components/Pagination.vue';
import ExportDialog from '@/components/ExportDialog';
import operateCompanyCascader from '@/components/businessComponent/operateCompanyCascader.vue';

// 模糊搜索接口
import { searchClient } from '@/api/customer/customer';

// 列表接口
// import { exportCarLossDeterminationBillListUrl } from '@/api/financial/pickSingle';
import {
  carLossDeterminationDetailedList,
  carLossDeterminationDetailedListExport,
} from '@/api/carBill/carLossDeterminationBill';

// 字典数据
import { carLossBillState, followUpStateList } from '@/service/dict/dictData';

// 工具
import Big from 'big.js';
import { mapState } from 'vuex';

export default {
  name: 'lossAssessmentDetailList',
  components: {
    pagination,
    ExportDialog,
    operateCompanyCascader,
  },
  data() {
    return {
      form: {},
      // 展示更多筛选
      showMore: false,
      // 导出设置
      exportUrl: carLossDeterminationDetailedListExport,
      showExportDialog: false,

      //分页组件
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },

      // 列表数据
      tableData: [],

      // 车单状态字段
      carBillState: carLossBillState,
      followUpStateList,

      clientList: [],
      searchClientLoading: false,
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  watch: {},
  activated() {},
  created() {
    this.form.operateCompanyId = [this.companyId];
    this.form.operateCompanyUrl = [this.companyId];
    this.$set(this.form, 'intoStoreTime', [
      new Date().Format('yyyy-MM-dd'),
      new Date().Format('yyyy-MM-dd'),
    ]);
    this.getData();
    this.searchClient();
  },
  mounted() {},
  methods: {
    /**
     * 清空查询条件
     */
    empty() {
      this.form = {};
    },

    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getData(false);
    },

    /**
     * 远程搜索车主客户列表
     */
    searchClient(keyWord = '') {
      this.searchClientLoading = true;
      searchClient(keyWord)
        .then((res) => {
          this.clientList = res.records;
        })
        .finally(() => {
          this.searchClientLoading = false;
        });
    },

    /**
     * 点击查询
     */
    doSearch() {
      this.getData();
    },

    /**
     * 获取数据
     */
    getData(reset = true) {
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      var allTimes = {
        intoStoreStartTime: this.form.intoStoreTime?.[0]
          ? this.form.intoStoreTime?.[0]
          : '',
        intoStoreEndTime: this.form.intoStoreTime?.[1]
          ? this.form.intoStoreTime?.[1]
          : '',
        paymentCollectionStartTime: this.form.paymentCollectionTime?.[0]
          ? this.form.paymentCollectionTime?.[0]
          : '',
        paymentCollectionEndTime: this.form.paymentCollectionTime?.[1]
          ? this.form.paymentCollectionTime?.[1]
          : '',
        damageOfStartTime: this.form.damageOfTime?.[0]
          ? this.form.damageOfTime?.[0]
          : '',
        damageOfEndTime: this.form.damageOfTime?.[1]
          ? this.form.damageOfTime?.[1]
          : '',
        breakageStartTime: this.form.breakageTime?.[0]
          ? this.form.breakageTime?.[0]
          : '',
        breakageEndTime: this.form.breakageTime?.[1]
          ? this.form.breakageTime?.[1]
          : '',
      };
      var data = Object.assign(this.form, allTimes, this.pageOption);
      carLossDeterminationDetailedList(data).then((res) => {
        this.total = res.total;
        this.tableData = res.records;
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
