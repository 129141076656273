<template>
  <div>
    <el-dialog
      :visible="visibility"
      width="60%"
      title="编辑客户指令"
      :close-on-click-modal="false"
      :before-close="handleClose"
      append-to-body
    >
      <div class="container">
        <table class="border-table">
          <thead>
            <tr>
              <th>项目</th>
              <th>自费</th>
              <th>退还车</th>
              <th>包干</th>
              <th>理赔</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>核心要求</td>
              <td>
                <el-input
                  v-model="selfPaying.coreAsk"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="returnCar.coreAsk"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="contractFor.coreAsk"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="claimSettlement.coreAsk"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
            </tr>
            <tr>
              <td>专项要求</td>
              <td>
                <el-input
                  v-model="selfPaying.specialAsk"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="returnCar.specialAsk"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="contractFor.specialAsk"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="claimSettlement.specialAsk"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
            </tr>
            <tr>
              <td>进场要求</td>
              <td>
                <el-input
                  v-model="selfPaying.enterAsk"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="returnCar.enterAsk"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="contractFor.enterAsk"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="claimSettlement.enterAsk"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
            </tr>
            <tr>
              <td>环检要求</td>
              <td>
                <el-input
                  v-model="selfPaying.ringAsk"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="returnCar.ringAsk"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="contractFor.ringAsk"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="claimSettlement.ringAsk"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
            </tr>
            <tr>
              <td>报备/审批要求</td>
              <td>
                <el-input
                  v-model="selfPaying.reportAsk"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="returnCar.reportAsk"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="contractFor.reportAsk"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="claimSettlement.reportAsk"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
            </tr>

            <tr>
              <td>故障照片/视频</td>
              <td>
                <el-input
                  v-model="selfPaying.faultPicsVideo"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="returnCar.faultPicsVideo"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="contractFor.faultPicsVideo"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="claimSettlement.faultPicsVideo"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
            </tr>
            <tr>
              <td>出厂要求</td>
              <td>
                <el-input
                  v-model="selfPaying.factoryAsk"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="returnCar.factoryAsk"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="contractFor.factoryAsk"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="claimSettlement.factoryAsk"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
            </tr>
            <tr>
              <td>保养要求</td>
              <td>
                <el-input
                  v-model="selfPaying.maintenanceAsk"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="returnCar.maintenanceAsk"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="contractFor.maintenanceAsk"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="claimSettlement.maintenanceAsk"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
            </tr>
            <tr>
              <td>轮胎更换要求</td>
              <td>
                <el-input
                  v-model="selfPaying.tireReplaceAsk"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="returnCar.tireReplaceAsk"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="contractFor.tireReplaceAsk"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="claimSettlement.tireReplaceAsk"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
            </tr>

            <tr>
              <td>司机管理要求</td>
              <td>
                <el-input
                  v-model="selfPaying.driverManageAsk"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="returnCar.driverManageAsk"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="contractFor.driverManageAsk"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="claimSettlement.driverManageAsk"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
            </tr>
            <tr>
              <td>停运补偿标准</td>
              <td>
                <el-input
                  v-model="selfPaying.outageSubsidyAsk"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="returnCar.outageSubsidyAsk"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="contractFor.outageSubsidyAsk"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="claimSettlement.outageSubsidyAsk"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
            </tr>
            <tr>
              <td>开具维修证明标准</td>
              <td>
                <el-input
                  v-model="selfPaying.issueRepairProve"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="returnCar.issueRepairProve"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="contractFor.issueRepairProve"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="claimSettlement.issueRepairProve"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
            </tr>
            <tr>
              <td>费用收取要求</td>
              <td>
                <el-input
                  v-model="selfPaying.costCollectAsk"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="returnCar.costCollectAsk"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="contractFor.costCollectAsk"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="claimSettlement.costCollectAsk"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
            </tr>
            <tr>
              <td>标的规则</td>
              <td>
                <el-input
                  v-model="selfPaying.insuredRule"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="returnCar.insuredRule"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="contractFor.insuredRule"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="claimSettlement.insuredRule"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
            </tr>
            <tr>
              <td>三者规则</td>
              <td>
                <el-input
                  v-model="selfPaying.tripartiteRule"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="returnCar.tripartiteRule"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="contractFor.tripartiteRule"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="claimSettlement.tripartiteRule"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
            </tr>
            <tr>
              <td>保险信息</td>
              <td>
                <el-input
                  v-model="selfPaying.insuranceInfo"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="returnCar.insuranceInfo"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="contractFor.insuranceInfo"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="claimSettlement.insuranceInfo"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
            </tr>
            <tr>
              <td>司机出险管理</td>
              <td>
                <el-input
                  v-model="selfPaying.driverDangerManage"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="returnCar.driverDangerManage"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="contractFor.driverDangerManage"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
              <td>
                <el-input
                  v-model="claimSettlement.driverDangerManage"
                  type="textarea"
                  autosize
                  :rows="1"
                  placeholder="请输入"
                >
                </el-input>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <template slot="footer">
        <el-button size="small" type="primary" @click="handleSave"
          >确定保存</el-button
        >
        <el-button size="small" @click="handleClose">取 消</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getClientCommand, updateClientCommand } from '@/api/customer/customer';

export default {
  name: 'editClientCommandDialog',
  props: {
    visibility: { type: Boolean, default: false },
    clientId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selfPaying: {},
      returnCar: {},
      contractFor: {},
      claimSettlement: {},
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getData();
  },
  methods: {
    /**
     * 获取数据
     */
    getData() {
      getClientCommand(this.clientId).then((res) => {
        console.log(res);
        this.selfPaying = res.selfPaying;
        this.returnCar = res.returnCar;
        this.contractFor = res.contractFor;
        this.claimSettlement = res.claimSettlement;
      });
    },
    /**
     * 保存
     */
    handleSave() {
      let body = {
        id: this.clientId,
        selfPaying: this.selfPaying,
        returnCar: this.returnCar,
        contractFor: this.contractFor,
        claimSettlement: this.claimSettlement,
      };
      updateClientCommand(body).then((res) => {
        console.log(res);
        this.$message.success('保存成功！');
        this.$emit('save');
        this.handleClose();
      });
    },

    /**
     * 关闭抽屉
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 60vh;
  overflow: auto;
}
.border-table {
  border-collapse: collapse;
  width: 100%;
  font-size: 14px;

  th,
  tr {
    line-height: 18px;
    font-size: 14px;
  }
  th,
  td {
    box-sizing: border-box;
    border: 1px solid #ebeef5;
    padding: 8px;
    white-space: pre-wrap;
    color: #3c3d40;
    font-weight: 400;
  }

  th {
    padding: 10px 8px;
    background: #f5f7fa;
    font-weight: 500;
    color: #242526;
  }
  td {
    height: 1px;
    vertical-align: top;
    &:first-child {
      width: 140px;
      vertical-align: middle;
      background: #f5f7fa;
      color: #242526;
      font-weight: 400;
    }
  }
  .el-textarea {
    height: 100%;
    ::v-deep .el-textarea__inner {
      min-height: 100% !important;
    }
  }
}
</style>
