import http from '@/service/http';
import store from '@/store';
const defaultListOption = {
  current: 1,
  size: 10,
};
export function batchDeleteApi(data = {}) {
  return http.post('/admin/car/factory/compensation/activity/delete', data);
}
export function preListApi() {
  return http.post('/admin/car/factory/compensation/activity/preList');
}
export function listApi(data) {
  return http.post('/admin/car/factory/compensation/activity/list', data);
}
export function batchImportByFileApi(file) {
  let formData = new FormData();
  formData.append('file', file);

  return http.request({
    method: 'post',
    url: '/admin/car/factory/compensation/activity/import',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
    timeout: 18e5,
  });
}
export function batchImportByFileResultApi() {
  return http.post('/admin/car/factory/compensation/activity/importResult');
}

export const listExportUrl =
  '/admin/car/factory/compensation/activity/list/export';

export const batchImportByFileFailedExportUrl =
  '/admin/car/factory/compensation/activity/importFailMessage/export';
