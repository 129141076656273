<template>
  <base-container :defaultPadding="false">
    <el-form class="filter-form" :model="form" label-width="100px" size="small">
      <div class="flex-x-between" style="width: 100%">
        <div class="flex flex-shrink0">
          <el-form-item v-if="isCenterCompany">
            <template slot="label">
              <div>
                预约门店
                <el-tooltip
                  effect="light"
                  content="预约门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)"
                  placement="bottom-start"
                >
                  <i
                    class="iconfont grow-icon_doubt"
                    style="color: #9a9a9a"
                  ></i>
                </el-tooltip>
              </div>
            </template>
            <operateCompanyCascader
              v-model="form.operateCompanyId"
              :operateCompanyUrl="form.operateCompanyUrl"
            ></operateCompanyCascader>
          </el-form-item>
          <el-form-item label="车牌号">
            <el-input
              v-model="form.carNumber"
              placeholder="请输入车牌号"
              clearable
              @keyup.enter.native="getDataList"
            ></el-input>
          </el-form-item>
          <el-form-item label="预约时间">
            <el-date-picker
              class="w100"
              v-model="form.bookingTimes"
              type="daterange"
              unlink-panels
              range-separator="至"
              start-placeholder="起始时间"
              end-placeholder="截止时间"
              value-format="yyyy-MM-dd"
              @input="dateRangeChange()"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-select
              v-model="form.status"
              clearable
              filterable
              placeholder="请选择状态"
            >
              <el-option
                v-for="item in statusEnums"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="el-form-item--small">
          <div class="flex">
            <el-button
              v-auth="692"
              class="btn_search"
              type="primary"
              size="small"
              @click="getDataList"
              >查询</el-button
            >
            <el-button class="btn_search" size="small" @click="empty"
              >置空</el-button
            >
          </div>
        </div>
      </div>
      <div class="flex-warp" v-show="showMore">
        <template></template>
      </div>
    </el-form>
    <div class="btns flex-x-between">
      <div></div>
      <div>
        <el-button
          v-auth="693"
          size="small"
          type="text"
          class="blue"
          @click="downloadAllRecords"
          >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
        ></el-button>
      </div>
    </div>
    <el-table
      :data="tableData"
      border
      class="custom-table"
      style="height: calc(63vh)"
    >
      <el-table-column type="index" label="序号" width="60"></el-table-column>
      <el-table-column width="180" label="操作" prop="operationAction">
        <template #default="{ row }">
          <el-button
            v-auth="694"
            v-if="row.status === 0"
            @click="completeBooking(row)"
            >预约开单</el-button
          >
        </template>
      </el-table-column>
      <!--
      <el-table-column width="220" label="状态" prop="statusDesc">
      </el-table-column>
      -->
      <el-table-column width="220" label="预约门店" prop="companyName">
      </el-table-column>
      <el-table-column width="180" label="预约日期" prop="bookingDate">
      </el-table-column>
      <el-table-column width="180" label="预约时间段" prop="bookedTimePhase">
      </el-table-column>
      <el-table-column width="180" label="预约服务" prop="serviceTypeDesc">
      </el-table-column>
      <el-table-column width="180" label="车牌号" prop="carNumber">
      </el-table-column>
      <el-table-column
        width="180"
        label="车型"
        prop="carModel"
        class-name="carModelPreLine"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column width="180" label="车队" prop="clientName">
      </el-table-column>
      <el-table-column width="180" label="送修人" prop="customerUserName">
      </el-table-column>
      <el-table-column width="180" label="送修人电话" prop="customerPhone">
      </el-table-column>
      <el-table-column width="180" label="创建时间" prop="dateCreated">
      </el-table-column>
      <el-table-column width="180" label="车单号" prop="billCode">
        <template #default="{ row }">
          <div class="blue pointer" @click="goDetail(row.billId || 0)">
            {{ row.billCode }}
          </div>
        </template>
      </el-table-column>
      <el-table-column width="180" label="送修人备注" prop="repairRemark">
      </el-table-column>
      <el-table-column width="180" label="服务备注" prop="serviceRemark">
      </el-table-column>
    </el-table>
    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
    <!-- 预约完成弹出框 -->
    <completeBookingDialog
      v-if="showCompleteBookingDialog"
      :visibility.sync="showCompleteBookingDialog"
      :id="completeBookingId"
      @save="getDataList(false)"
    >
    </completeBookingDialog>
  </base-container>
</template>

<script>
import pagination from '@/components/Pagination.vue';
import completeBookingDialog from './completeBookingDialog.vue';
import operateCompanyCascader from '@/components/businessComponent/operateCompanyCascader';
//接口
import { downloadFile } from '@/utils/fileApi';
import {
  miniProgramBookingPreList,
  miniProgramBookingList,
  miniProgramBookingListExport,
} from '@/api/carBill/miniProgramBooking';
import { hasAuth } from '@/utils/permissions';
import Big from 'big.js';
import { mapState } from 'vuex';
export default {
  name: 'miniProgramBookingRecords',
  components: { pagination, completeBookingDialog, operateCompanyCascader },
  props: {},
  data() {
    return {
      showCompleteBookingDialog: false,
      completeBookingId: 0,
      exportUrl: miniProgramBookingListExport,
      showMore: false,
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      form: {
        operateCompanyId: [],
      },
      tableData: [],
      statusEnums: [],
      isCenterCompany: false,
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.empty();
      let currentCompany = window.localStorage.getItem('company');
      if (currentCompany !== undefined) {
        let currentCompanyObj = JSON.parse(currentCompany);
        this.isCenterCompany = (currentCompanyObj?.isParent || false) == true;
      }
      miniProgramBookingPreList({}).then((res) => {
        this.statusEnums = res;
      });
      this.getDataList();
    },
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getDataList(false);
    },
    /**
     * 加载数据
     */
    getDataList(reset = true) {
      var res = hasAuth(692);
      if (!res) {
        return;
      }
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      var data = this.getFormDataBody();
      miniProgramBookingList(data).then((res) => {
        this.total = res.total || 0;
        this.tableData = res.records || [];
      });
    },
    /**
     * 置空输入框数据
     */
    empty() {
      this.form = {
        operateCompanyId: [this.companyId],
      };
    },
    getFormDataBody() {
      var times = {
        bookingBeginTime: this.form.bookingTimes?.[0]
          ? this.form.bookingTimes?.[0] + ' 00:00:00'
          : '',
        bookingEndTime: this.form.bookingTimes?.[1]
          ? this.form.bookingTimes?.[1] + ' 23:59:59'
          : '',
      };
      var data = Object.assign({}, this.form, this.pageOption, times);
      return data;
    },
    downloadAllRecords() {
      var data = this.getFormDataBody();
      downloadFile({
        url: this.exportUrl,
        data: data,
      });
    },
    /**
     * 跳转详情页
     */
    goDetail(id) {
      if (id === undefined || id <= 0) {
        return;
      }
      this.$router.push({
        name: 'carBillDetail',
        query: {
          id: id,
          t: new Date().getTime(),
        },
      });
    },
    /**
     * 弹出完成预约框
     * @param {Object} row 行记录
     */
    completeBooking(row) {
      // 跳转到开单页面，并且把当前预约ID和类型传递过去
      this.$router.push({
        name: 'editCarBill',
        query: {
          businessType: 'miniBooking',
          businessTypeId: row.id,
          carNumber: row.carNumber,
          carId: row.carId,
          type: 0,
          contact: row.customerUserName,
          contactPhone: row.customerPhone,
          repairType: row.serviceType,
          firstVisitRemark: row.repairRemark,
          t: new Date().getTime(),
        },
      });
      //this.completeBookingId = row.id;
      //this.showCompleteBookingDialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form {
  width: 100%;
  display: inline-flex;
}
.custom-tabs {
  ::v-deep .el-tabs__header {
    margin: 0px 0px 0px !important;
    border-bottom: none;
    .el-tabs__nav {
      // border-bottom: 1px solid #e4e7ed;
    }
    .el-tabs__item {
      height: auto;
      border-bottom: 1px solid #e4e7ed;
      &.is-active {
        background: rgba(51, 171, 121, 0.1);
      }
    }
  }
}
.el-table .cell {
  white-space: pre-line !important;
}
</style>
