import http from '@/service/http';
import axios from 'axios';

import { oldServerUrl } from '@/config/compatibilityOlderSystems';

const defaultListOption = {
  current: 1,
  size: 10,
};

// 库存补货列表查询
export function getReplenishmentList(data = defaultListOption) {
  return http.post('/admin/inventory/replenishmentList', data);
}
export function supplierSearch(data = defaultListOption) {
  return http.post('/admin/supplier/search', data);
}

/**
 * 添加采购单
 * @param {Object} data 采购单数据
 * @returns 处理结果
 */
export function postAddPurchase(data) {
  return axios.post(oldServerUrl + '/purchase/add', data, {
    noHandleError: true,
    noShowWaiting: false,
  });
}
// 导出
export const exportUrl = '/admin/data/inventory/replenishmentList/export';
