var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visibility,
        width: "50%",
        "before-close": _vm.handleClose,
        "close-on-click-modal": false,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticStyle: { "margin-top": "18px" },
          attrs: {
            size: "small",
            model: _vm.editForm,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "车型简称", prop: "abbreviatedName" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入车型简称" },
                        model: {
                          value: _vm.editForm.abbreviatedName,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "abbreviatedName", $$v)
                          },
                          expression: "editForm.abbreviatedName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 20 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "品牌", prop: "logoId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "w100",
                          attrs: {
                            filterable: "",
                            remote: "",
                            placeholder: "搜索品牌",
                            "remote-method": _vm.searchCarLogo,
                            loading: _vm.searchCarLogoLoading,
                            size: "small",
                          },
                          on: { change: _vm.handleCarLogoChange },
                          model: {
                            value: _vm.editForm.logoId,
                            callback: function ($$v) {
                              _vm.$set(_vm.editForm, "logoId", $$v)
                            },
                            expression: "editForm.logoId",
                          },
                        },
                        _vm._l(_vm.carLogoList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.logoName, value: item.logoId },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "auth",
                          rawName: "v-auth",
                          value: 587,
                          expression: "587",
                        },
                      ],
                      staticClass: "w100",
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.showCarLogoEditDialog = true
                        },
                      },
                    },
                    [_vm._v("新增品牌")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "车系", prop: "seriesId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "w100",
                          attrs: {
                            filterable: "",
                            "allow-create": "",
                            remote: "",
                            placeholder: _vm.editForm.logoId
                              ? "搜索车系"
                              : "请先选择品牌",
                            disabled: !_vm.editForm.logoId,
                            "remote-method": _vm.searchCarSeries,
                            loading: _vm.searchCarSeriesLoading,
                            size: "small",
                          },
                          on: { change: _vm.handleSelectSeries },
                          model: {
                            value: _vm.editForm.seriesId,
                            callback: function ($$v) {
                              _vm.$set(_vm.editForm, "seriesId", $$v)
                            },
                            expression: "editForm.seriesId",
                          },
                        },
                        _vm._l(_vm.carSeriesList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              label: item.seriesName,
                              value: item.seriesId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "型号", prop: "modelNumberName" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入型号" },
                        model: {
                          value: _vm.editForm.modelNumberName,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "modelNumberName", $$v)
                          },
                          expression: "editForm.modelNumberName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "年份", prop: "modelYearName" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入年份（如2022）" },
                        model: {
                          value: _vm.editForm.modelYearName,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "modelYearName", $$v)
                          },
                          expression: "editForm.modelYearName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c("el-form-item", { attrs: { label: "标准名称" } }, [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.editForm.logoName) +
                          _vm._s(_vm.editForm.seriesName) +
                          " " +
                          _vm._s(_vm.editForm.modelNumberName) +
                          _vm._s(_vm.editForm.modelYearName)
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("div", { staticClass: "flex-x-between flex-y-center" }, [
            _c("div", [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "auth",
                      rawName: "v-auth",
                      value: {
                        code: 589,
                        additionalConditions: !!_vm.detail.logoId,
                      },
                      expression:
                        "{\n            code: 589,\n            additionalConditions: !!detail.logoId,\n          }",
                    },
                  ],
                  staticClass: "red pointer",
                  staticStyle: { "font-size": "12px" },
                  on: { click: _vm.deleteModel },
                },
                [_vm._v("删除车型")]
              ),
            ]),
            _c(
              "div",
              [
                _c(
                  "el-button",
                  { attrs: { size: "small" }, on: { click: _vm.handleClose } },
                  [_vm._v(" 取 消 ")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.save },
                  },
                  [_vm._v(" 确 定 ")]
                ),
              ],
              1
            ),
          ]),
          _vm.showCarLogoEditDialog
            ? _c("edit-car-brand-dialog", {
                attrs: { visibility: _vm.showCarLogoEditDialog },
                on: {
                  "update:visibility": function ($event) {
                    _vm.showCarLogoEditDialog = $event
                  },
                  save: _vm.handleAddLogo,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }