var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "notification-box" }, [
    _vm.visibility
      ? _c("div", [
          _c(
            "div",
            {
              staticClass: "box",
              style: { zIndex: _vm.calculateZIndex(_vm.index) },
            },
            [
              _c("div", { staticClass: "box-top" }, [
                _c("p", [_vm._v("你有一条待办")]),
                _c(
                  "span",
                  { staticClass: "cursor", on: { click: _vm.handleClose } },
                  [_vm._v("x")]
                ),
              ]),
              _c("div", { staticClass: "info" }, [
                _c(
                  "div",
                  {
                    staticClass: "notification-text-ellipsis",
                    attrs: { title: _vm.list.title },
                  },
                  [_vm._v(" " + _vm._s(_vm.list.title) + " ")]
                ),
                _c("div", [_vm._v(_vm._s(_vm.list.reminderDateTime))]),
              ]),
              _c(
                "div",
                { staticClass: "dialog-footer" },
                [
                  _c("el-button", { on: { click: _vm.handleClose } }, [
                    _vm._v("知道了"),
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        disabled: _vm.list.funType !== 0,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.Detail(_vm.list)
                        },
                      },
                    },
                    [_vm._v("查看详情")]
                  ),
                ],
                1
              ),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }