<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <el-form
        class="filter-form"
        :model="form"
        label-width="100px"
        size="small"
      >
        <div class="flex-x-between">
          <div class="flex flex-shrink0">
            <el-form-item label="客户信息">
              <el-input
                v-model="form.clientMsg"
                placeholder="请输入姓名/手机/车牌"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
            <el-form-item label="车架号">
              <el-input
                v-model="form.uniqueId"
                @input="inputUniqueId"
                placeholder="请输入"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
            <el-form-item label="注册日期">
              <el-date-picker
                v-model="form.registerTime"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="截止时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </div>
          <div class="el-form-item--small">
            <div
              @click="showMore = !showMore"
              class="el-form-item__label pointer"
            >
              更多筛选
              <i
                :class="{
                  primary: showMore,
                  'el-icon-arrow-right': !showMore,
                  'el-icon-arrow-down': showMore,
                }"
              ></i>
            </div>
            <div class="flex">
              <el-button
                class="btn_search"
                type="primary"
                size="small"
                @click="getData"
                >查询</el-button
              >
              <el-button class="btn_search" size="small" @click="empty"
                >置空</el-button
              >
            </div>
          </div>
        </div>
        <div class="flex-warp">
          <el-form-item label="车型">
            <el-select
              v-model="form.carModel"
              filterable
              allow-create
              remote
              placeholder="请选择"
              :remote-method="searchModel"
              :loading="searchModelLoading"
              clearable
            >
              <el-option
                v-for="item in models"
                :key="$lodash.get(item, 'series.id')"
                :value="
                  $lodash.get(
                    item,
                    'series.name',
                    $lodash.get(
                      item,
                      'brand.name',
                      $lodash.get(item, 'logo.name')
                    )
                  )
                "
                @click.native="selectCarModel(item)"
              >
                <span v-html="item.markedRedName"></span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="车辆状态">
            <el-select v-model="form.state" placeholder="请选择" clearable>
              <el-option
                v-for="item in carState"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="在保状态">
            <el-select
              v-model="form.underWarrantyState"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in underWarrantyState"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="在租状态">
            <el-select
              v-model="form.underLeaseState"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in underLeaseState"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <template v-if="showMore">
            <el-form-item label="动力类型">
              <el-select v-model="form.fuelType" placeholder="请选择" clearable>
                <el-option
                  v-for="item in fuelType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="车辆所有人">
              <el-input
                v-model="form.vehicleOwner"
                clearable
                placeholder="请输入内容"
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
            <el-form-item label="保险起保时间">
              <el-date-picker
                v-model="form.insuranceDueTime"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="截止时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </template>
        </div>
      </el-form>
    </template>

    <template>
      <div style="margin: 0px 0 14px 0" class="btns flex-x-end">
        <el-button
          v-auth="526"
          size="small"
          type="text"
          class="blue"
          @click="showExportDialog = true"
          >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
        ></el-button>
      </div>

      <el-table
        class="custom-table"
        :data="carListData"
        border
        @selection-change="handleSelectionChange"
        v-fixedScrollBar="$refs.baseContainer"
      >
        <el-table-column type="index" label="序号" width="60">
        </el-table-column>
        <el-table-column width="100" label="车牌号">
          <template slot-scope="scope">
            <span>{{ scope.row.carNumber }}</span>
          </template>
        </el-table-column>
        <el-table-column width="180" label="车架号" prop="uniqueId">
          <template #default="{ row }">
            <el-tooltip :content="row.carModelName">
              <p class="text-ellipsis">{{ row.carModelName }}</p>
            </el-tooltip>
            <p>{{ row.uniqueId }}</p>
          </template>
        </el-table-column>
        <!-- <el-table-column width="100" label="车型" prop="carModelName">
        </el-table-column> -->
        <el-table-column width="120" label="签约客户" prop="clientName">
          <template #default="{ row }">
            <p>{{ row.clientName }}</p>
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          label="最后进店里程"
          prop="lastIntoStoreMileage"
        ></el-table-column>
        <el-table-column
          width="120"
          label="最后进店时间"
          prop="lastIntoStoreDate"
        ></el-table-column>
        <el-table-column
          width="120"
          label="注册时间"
          prop="registerDate"
        ></el-table-column>
        <el-table-column width="120" label="整车质保">
          <template #default="{ row }">
            <span v-if="row.hasVehicleWarranty">
              整车质保
              <el-tooltip
                effect="light"
                :content="row.vehicleWarrantyContent"
                placement="bottom-start"
              >
                <i class="iconfont grow-icon_doubt" style="color: #9a9a9a"></i>
              </el-tooltip>
            </span>
            <span v-else-if="row.hasVehicleWarranty === 0">-</span>
          </template>
        </el-table-column>
        <el-table-column width="120" label="三电质保">
          <template #default="{ row }">
            <span v-if="row.hasThreePowerWarranty">
              三电质保
              <el-tooltip
                effect="light"
                :content="row.threePowerWarrantyContent"
                placement="bottom-start"
              >
                <i class="iconfont grow-icon_doubt" style="color: #9a9a9a"></i>
              </el-tooltip>
            </span>
            <span v-else-if="row.hasThreePowerWarranty === 0">-</span>
          </template>
        </el-table-column>
        <el-table-column width="120" label="动力电池质保">
          <template #default="{ row }">
            <span v-if="row.hasPowerBatteryWarranty">
              动力电池质保
              <el-tooltip
                effect="light"
                :content="row.powerBatteryWarrantyContent"
                placement="bottom-start"
              >
                <i class="iconfont grow-icon_doubt" style="color: #9a9a9a"></i>
              </el-tooltip>
            </span>
            <span v-else-if="row.hasPowerBatteryWarranty === 0">-</span>
          </template>
        </el-table-column>
        <el-table-column width="120" label="电芯质保">
          <template #default="{ row }">
            <span v-if="row.hasBatteryWarranty">
              电芯质保
              <el-tooltip
                effect="light"
                :content="row.batteryWarrantyContent"
                placement="bottom-start"
              >
                <i class="iconfont grow-icon_doubt" style="color: #9a9a9a"></i>
              </el-tooltip>
            </span>
            <span v-else-if="row.hasBatteryWarranty === 0">-</span>
          </template>
        </el-table-column>
        <el-table-column width="120" label="易损件质保">
          <template #default="{ row }">
            <span v-if="row.hasWarrantyWearingParts"
              >易损件质保
              <el-tooltip
                effect="light"
                :content="row.warrantyWearingPartsContent"
                placement="bottom-start"
              >
                <i class="iconfont grow-icon_doubt" style="color: #9a9a9a"></i>
              </el-tooltip>
            </span>
            <span v-else-if="row.hasWarrantyWearingParts === 0">-</span>
          </template>
        </el-table-column>
        <el-table-column width="100" label="车辆状态">
          <template #default="{ row }">
            <span>{{ row.state | dict('carState') }}</span>
          </template>
        </el-table-column>
        <el-table-column width="100" label="在租状态">
          <template #default="{ row }">
            <span>{{ row.underLeaseState | dict('underLeaseState') }}</span>
          </template>
        </el-table-column>
        <el-table-column width="120" label="车辆所有人"
          ><template #default="{ row }">
            <el-tooltip :content="row.vehicleOwner">
              <div class="text-ellipsis-2-row">
                {{ row.vehicleOwner }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column width="100" label="电机号/发动机号">
          <template #default="{ row }">
            <el-tooltip :content="row.engineNumber">
              <div class="text-ellipsis-2-row">
                {{ row.engineNumber }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          label="新车续航"
          prop="newVehicleMileage"
        ></el-table-column>
        <el-table-column
          width="120"
          label="真实续航"
          prop="trueEndurance"
        ></el-table-column>
        <el-table-column width="120" label="动力类型">
          <template #default="{ row }">
            <span>{{ row.fuelType }}</span>
          </template>
        </el-table-column>
        <el-table-column width="120" label="车损险">
          <template #default="{ row }">
            <p>{{ row.insuranceCompanyName }}</p>
            <p v-if="row.insuranceValidDate">
              {{ row.insuranceValidDate }}起保
            </p>
          </template>
        </el-table-column>
        <el-table-column width="120" label="交强险">
          <template #default="{ row }">
            <p>{{ row.tcInsuranceCompanyName }}</p>
            <p v-if="row.tcInsuranceValidDate">
              <!-- <span
                :class="
                  (Date.parse(row.tcInsuranceValidDate) - Date.now()) /
                    (1 * 24 * 60 * 60 * 1000) <=
                  30
                    ? 'red'
                    : ''
                "
              >
                {{ row.tcInsuranceValidDate }}到期</span
              > -->
              {{ row.tcInsuranceValidDate }}起保
            </p>
          </template>
        </el-table-column>
        <el-table-column width="120" label="驾乘险">
          <template #default="{ row }">
            <p>{{ row.drivingInsuranceCompanyName }}</p>
            <p v-if="row.drivingInsuranceValidDate">
              {{ row.drivingInsuranceValidDate }}起保
            </p>
          </template>
        </el-table-column>
        <el-table-column width="120" label="三者险">
          <template #default="{ row }">
            <p>{{ row.thirdPartyInsuranceCompanyName }}</p>
            <p v-if="row.thirdPartyInsuranceValidDate">
              {{ row.thirdPartyInsuranceValidDate }}起保
            </p>
          </template>
        </el-table-column>
        <el-table-column width="120" label="座位险">
          <template #default="{ row }">
            <p>{{ row.seatInsuranceCompanyName }}</p>
            <p v-if="row.seatInsuranceValidDate">
              {{ row.seatInsuranceValidDate }}起保
            </p>
          </template>
        </el-table-column>
      </el-table>
      <export-dialog
        :visibility.sync="showExportDialog"
        :total="total"
        :pageOption="pageOption"
        :filterForm="form"
        :url="exportUrl"
      ></export-dialog>
    </template>

    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
  </base-container>
</template>

<script>
// 组件
import pagination from '@/components/Pagination.vue';
import ExportDialog from '@/components/ExportDialog';

// 接口
import {
  exportCarInventoryTableUrl,
  searchCarModel,
  getCarInventoryTableList,
} from '@/api/customer/car';
import { searchClient } from '@/api/customer/customer';
import { searchStore } from '@/api/customer/store';

// 字典数据
import {
  fuelType,
  carState,
  insuranceCompany,
  underWarrantyState,
  underLeaseState,
} from '@/service/dict/dictData';
import { downloadFile } from '@/utils/fileApi';

import { mapState, mapGetters, mapMutations } from 'vuex';

// 工具
import Big from 'big.js';

export default {
  name: 'carInventory',
  components: { pagination, ExportDialog },
  data() {
    return {
      editCarId: '',

      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      showExportDialog: false,
      exportUrl: exportCarInventoryTableUrl,

      form: {
        filingCompanyId: '',
      },

      // 搜索用户
      clientList: [],
      searchClientLoading: false,
      //搜索车型信息列表字段
      searchModelLoading: false,
      models: [],

      // 展示更多筛选
      showMore: false,

      fuelType: fuelType,
      carState: carState,
      insurance: insuranceCompany,
      //在保状态
      underWarrantyState: underWarrantyState,
      //在租状态
      underLeaseState: underLeaseState,

      // 车辆列表数据
      carListData: [],

      cloneForm: null,
      //选中列表数据
      handleSelectCar: [],
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  activated() {
    if (
      this.$route.params.form &&
      !this.$lodash.isEqual(this.$route.params.form, this.cloneForm)
    ) {
      this.form = this.$lodash.cloneDeep(this.$route.params.form);
      this.cloneForm = this.$lodash.cloneDeep(this.$route.params.form);
      this.getData();
    }
  },
  created() {
    // 从其他页面跳转过来时附带了查询条件
    if (this.$route.params.form) {
      this.form = this.$route.params.form;
    }
    // this.form.filingCompanyId = this.user?.companyId;
    // this.storeList.unshift({
    //   companyId: this.user?.companyId,
    //   companyName: this.user?.companyName,
    // });
    this.getData();
    this.searchModel();
  },
  methods: {
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getData(false);
    },
    /**
     * 加载数据
     */
    getData(reset = true) {
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      var times = {
        companyId: this.user.companyId,
        //注册日期
        registerStartTime: this.form.registerTime?.[0]
          ? this.form.registerTime?.[0]
          : '',
        registerEndTime: this.form.registerTime?.[1]
          ? this.form.registerTime?.[1]
          : '',
        //保险到期时间
        insuranceDueStartTime: this.form.insuranceDueTime?.[0]
          ? this.form.insuranceDueTime?.[0]
          : '',
        insuranceDueEndTime: this.form.insuranceDueTime?.[1]
          ? this.form.insuranceDueTime?.[1]
          : '',
      };
      var data = Object.assign(this.form, this.pageOption, times);
      getCarInventoryTableList(data).then((res) => {
        console.log(res);
        // 修改总条数
        this.total = res.total;
        // 赋值records
        this.carListData = res.records;
      });
    },
    /**
     * 远程搜索客户列表
     */
    searchClient(keyWord) {
      if (keyWord) {
        this.searchClientLoading = true;
        searchClient(keyWord)
          .then((res) => {
            this.clientList = res.records;
          })
          .finally(() => {
            this.searchClientLoading = false;
          });
      }
    },
    // 车架号输入事件
    inputUniqueId(w) {
      var reg = /[^A-Za-z0-9]/;
      this.form.uniqueId = w.replace(reg, '');
    },
    /**
     * 置空输入框数据
     */
    empty() {
      this.form = {};
    },
    selectCarModel(val) {
      // this.carLogoName = val.logo.name;
      // this.carBrandName = val.brand.name;
      // this.carSeriesName = val.series.name;
      console.log('val', val);
    },
    //远程搜索车型信息列表
    searchModel(key = '') {
      this.searchModelLoading = true;
      searchCarModel(key)
        .then((res) => {
          this.models = res?.records || [];
        })
        .finally(() => {
          this.searchModelLoading = false;
        });
    },
    //选中的列表数据
    handleSelectionChange(val) {
      this.handleSelectCar = val.map((item) => {
        return item.carId;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.el-form {
  width: 100%;
}

.fade-enter-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.export-dialog__body {
  margin: 20px 0;
  text-align: center;
  .export-dialog-page__input {
    display: flex;
    justify-content: center;
    align-items: center;
    .el-input {
      width: 140px;
      margin: 0 15px;
    }
  }
  .export-dialog__tip {
    margin-top: 20px;
  }
}
</style>
