var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visibility,
        width: "50%",
        "before-close": _vm.handleClose,
        "close-on-click-modal": false,
        "append-to-body": "",
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.save },
                },
                [_vm._v(" 确 定 ")]
              ),
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.handleClose } },
                [_vm._v(" 取 消 ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticStyle: { "margin-top": "18px" },
          attrs: {
            size: "small",
            model: _vm.editForm,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "挂账金额：", prop: "pendingAmount" } },
                    [_vm._v(" " + _vm._s(_vm.editForm.pendingAmount) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "账单确认：", prop: "billConfirm" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "ml-4",
                          model: {
                            value: _vm.editForm.billConfirm,
                            callback: function ($$v) {
                              _vm.$set(_vm.editForm, "billConfirm", $$v)
                            },
                            expression: "editForm.billConfirm",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            { attrs: { label: true, size: "large" } },
                            [_vm._v("是")]
                          ),
                          _c(
                            "el-radio",
                            { attrs: { label: false, size: "large" } },
                            [_vm._v("否")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.infoVo.includeBadDebts === true
            ? _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "坏账金额：",
                            prop: "badDebtsAmount",
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.editForm.badDebtsAmount) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.infoVo.includeBadDebts === true
            ? _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "坏账审核：",
                            prop: "badDebtsConfirm",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "ml-4",
                              model: {
                                value: _vm.editForm.badDebtsConfirm,
                                callback: function ($$v) {
                                  _vm.$set(_vm.editForm, "badDebtsConfirm", $$v)
                                },
                                expression: "editForm.badDebtsConfirm",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                { attrs: { label: true, size: "large" } },
                                [_vm._v("是")]
                              ),
                              _c(
                                "el-radio",
                                { attrs: { label: false, size: "large" } },
                                [_vm._v("否")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "坏账处理人：",
                            prop: "badDebtsUserId",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                clearable: "",
                                remote: "",
                                placeholder: "搜索姓名、手机号",
                                "remote-method": _vm.searchAllUser,
                                loading: _vm.searchAllUserLoading,
                              },
                              on: {
                                select: function ($event) {
                                  return _vm.doVerify("badDebtsUserId")
                                },
                              },
                              model: {
                                value: _vm.editForm.badDebtsUserId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.editForm, "badDebtsUserId", $$v)
                                },
                                expression: "editForm.badDebtsUserId",
                              },
                            },
                            _vm._l(_vm.allUserList, function (item) {
                              return _c("el-option", {
                                key: item.userId,
                                attrs: {
                                  label: item.realName,
                                  value: item.userId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "当次留言：", prop: "message" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          maxlength: "300",
                          "show-word-limit": "",
                          placeholder: "请输入当次留言，最多300个字符",
                        },
                        model: {
                          value: _vm.editForm.message,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "message", $$v)
                          },
                          expression: "editForm.message",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "图片", prop: "feeListPicA" } },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#909399",
                            "font-size": "12px",
                          },
                        },
                        [
                          _vm._v(
                            "支持上传 10 张图片，建议每张图片大小不超过 2 MB"
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        [
                          _c("upload-file", {
                            attrs: { title: "行驶证" },
                            model: {
                              value: _vm.editForm.runningLicenceImg,
                              callback: function ($$v) {
                                _vm.$set(_vm.editForm, "runningLicenceImg", $$v)
                              },
                              expression: "editForm.runningLicenceImg",
                            },
                          }),
                          _c("upload-file", {
                            attrs: { title: "驾驶证" },
                            model: {
                              value: _vm.editForm.picDrivingLicence,
                              callback: function ($$v) {
                                _vm.$set(_vm.editForm, "picDrivingLicence", $$v)
                              },
                              expression: "editForm.picDrivingLicence",
                            },
                          }),
                          _c("upload-file", {
                            attrs: {
                              title: "定损单1",
                              requiredIcon: _vm.type >= 8,
                            },
                            model: {
                              value: _vm.editForm.feeListPicA,
                              callback: function ($$v) {
                                _vm.$set(_vm.editForm, "feeListPicA", $$v)
                              },
                              expression: "editForm.feeListPicA",
                            },
                          }),
                          _c("upload-file", {
                            attrs: { title: "定损单2" },
                            model: {
                              value: _vm.editForm.feeListPicB,
                              callback: function ($$v) {
                                _vm.$set(_vm.editForm, "feeListPicB", $$v)
                              },
                              expression: "editForm.feeListPicB",
                            },
                          }),
                          _c("upload-file", {
                            attrs: { title: "定损单3" },
                            model: {
                              value: _vm.editForm.feeListPicC,
                              callback: function ($$v) {
                                _vm.$set(_vm.editForm, "feeListPicC", $$v)
                              },
                              expression: "editForm.feeListPicC",
                            },
                          }),
                          _c("upload-file-list", {
                            attrs: { max: 5 },
                            model: {
                              value: _vm.editForm.feeListPicOthersList,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.editForm,
                                  "feeListPicOthersList",
                                  $$v
                                )
                              },
                              expression: "editForm.feeListPicOthersList",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "附件", prop: "attachment" } },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#909399",
                            "font-size": "12px",
                          },
                        },
                        [_vm._v("只支持上传zip文件，最多5个")]
                      ),
                      _c("div", { staticClass: "flex" }, [
                        _c(
                          "ul",
                          { staticStyle: { "max-width": "300px" } },
                          _vm._l(_vm.attachmentList, function (item, i) {
                            return _c("li", { key: i }, [
                              _c("i", {
                                staticClass:
                                  "iconfont grow-icon_delete3 orange el-icon--left pointer",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete(i)
                                  },
                                },
                              }),
                              _c("span", [_vm._v(_vm._s(item.name))]),
                            ])
                          }),
                          0
                        ),
                        _vm.attachmentList.length < 5
                          ? _c(
                              "div",
                              { staticStyle: { "margin-left": "20px" } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "small" },
                                    on: { click: _vm.chooseFile },
                                  },
                                  [_vm._v("选择文件")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }