var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { ref: "baseContainer", attrs: { paddingTop: "" } },
    [
      [
        _c(
          "div",
          [
            _c(
              "el-form",
              {
                staticClass: "filter-form",
                attrs: {
                  model: _vm.form,
                  "label-width": "100px",
                  size: "small",
                },
              },
              [
                _c("div", { staticClass: "flex-x-between" }, [
                  _c(
                    "div",
                    { staticClass: "flex-warp flex-shrink0" },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c("template", { slot: "label" }, [
                            _c(
                              "div",
                              [
                                _vm._v(" 操作门店 "),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "light",
                                      content:
                                        "定损单的操作门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)",
                                      placement: "bottom-start",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "iconfont grow-icon_doubt",
                                      staticStyle: { color: "#9a9a9a" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("operateCompanyCascader", {
                            attrs: {
                              operateCompanyUrl: _vm.form.operateCompanyUrl,
                            },
                            model: {
                              value: _vm.form.operateCompanyId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "operateCompanyId", $$v)
                              },
                              expression: "form.operateCompanyId",
                            },
                          }),
                        ],
                        2
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "车牌号" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入", clearable: "" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.doSearch.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.form.carNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "carNumber", $$v)
                              },
                              expression: "form.carNumber",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "跟进人" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                clearable: "",
                                remote: "",
                                placeholder: "搜索姓名、手机号",
                                "remote-method": _vm.searchAllUser,
                                loading: _vm.searchAllUserLoading,
                              },
                              model: {
                                value: _vm.form.followUpPeople,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "followUpPeople", $$v)
                                },
                                expression: "form.followUpPeople",
                              },
                            },
                            _vm._l(_vm.allUserList, function (item) {
                              return _c("el-option", {
                                key: item.userId,
                                attrs: {
                                  label: item.realName,
                                  value: item.userId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "flex" }, [
                    _c("div", { staticClass: "el-form-item--small" }, [
                      _c(
                        "div",
                        {
                          staticClass: "el-form-item__label pointer",
                          on: {
                            click: function ($event) {
                              _vm.showMore = !_vm.showMore
                            },
                          },
                        },
                        [
                          _vm._v(" 更多筛选 "),
                          _c("i", {
                            class: {
                              primary: true,
                              "el-icon-arrow-right": !_vm.showMore,
                              "el-icon-arrow-down": _vm.showMore,
                            },
                          }),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "btn_search",
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.doSearch },
                            },
                            [_vm._v("查询")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "btn_search",
                              attrs: { size: "small" },
                              on: { click: _vm.empty },
                            },
                            [_vm._v("置空")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "flex-warp" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "跟进进展" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              filterable: "",
                              placeholder: "请选择",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.followUpState,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "followUpState", $$v)
                              },
                              expression: "form.followUpState",
                            },
                          },
                          _vm._l(_vm.followUpStateList, function (item) {
                            return _c(
                              "el-option",
                              {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "flex-y-center",
                                    staticStyle: {
                                      width: "100%",
                                      height: "100%",
                                    },
                                  },
                                  [
                                    _c("div", { style: item.iconStyle }, [
                                      _vm._v(_vm._s(item.iconText)),
                                    ]),
                                    _c(
                                      "span",
                                      { staticStyle: { "margin-left": "5px" } },
                                      [_vm._v(_vm._s(item.label))]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "提醒" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              multiple: "",
                              clearable: "",
                              placeholder: "筛选提醒（支持多选）",
                            },
                            model: {
                              value: _vm.form.remind,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "remind", $$v)
                              },
                              expression: "form.remind",
                            },
                          },
                          _vm._l(_vm.remindList, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "保险公司" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              filterable: "",
                              clearable: "",
                              remote: "",
                              placeholder: "请输入公司名称、编号",
                              "remote-method": _vm.searchInsurance,
                              loading: _vm.searchInsuranceLoading,
                            },
                            model: {
                              value: _vm.form.insuranceCompanyName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "insuranceCompanyName", $$v)
                              },
                              expression: "form.insuranceCompanyName",
                            },
                          },
                          _vm._l(_vm.insuranceList, function (item) {
                            return _c(
                              "el-option",
                              {
                                key: item.id,
                                attrs: {
                                  label: item.insuranceName,
                                  value: item.id,
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      float: "left",
                                      color: "#8492a6",
                                      "font-size": "13px",
                                    },
                                  },
                                  [_vm._v(_vm._s(item.insuranceName))]
                                ),
                                _c(
                                  "span",
                                  { staticStyle: { float: "right" } },
                                  [_vm._v(_vm._s(item.insuranceNum))]
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "进店日期" } },
                      [
                        _c("el-date-picker", {
                          staticClass: "w100",
                          attrs: {
                            type: "daterange",
                            "unlink-panels": "",
                            "range-separator": "至",
                            "start-placeholder": "起始时间",
                            "end-placeholder": "截止时间",
                            "value-format": "yyyy-MM-dd",
                          },
                          model: {
                            value: _vm.form.intoStoreTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "intoStoreTime", $$v)
                            },
                            expression: "form.intoStoreTime",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.showMore
                      ? [
                          _c(
                            "el-form-item",
                            { attrs: { label: "接车单号" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入", clearable: "" },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.doSearch.apply(null, arguments)
                                  },
                                },
                                model: {
                                  value: _vm.form.billCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "billCode", $$v)
                                  },
                                  expression: "form.billCode",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "回款状态" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择",
                                  },
                                  model: {
                                    value: _vm.form.collectionState,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "collectionState", $$v)
                                    },
                                    expression: "form.collectionState",
                                  },
                                },
                                _vm._l(_vm.collectionState, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "车单状态" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择",
                                  },
                                  model: {
                                    value: _vm.form.billState,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "billState", $$v)
                                    },
                                    expression: "form.billState",
                                  },
                                },
                                _vm._l(_vm.carBillState, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "提交定损日期" } },
                            [
                              _c("el-date-picker", {
                                staticClass: "w100",
                                attrs: {
                                  type: "daterange",
                                  "unlink-panels": "",
                                  "range-separator": "至",
                                  "start-placeholder": "起始时间",
                                  "end-placeholder": "截止时间",
                                  "value-format": "yyyy-MM-dd",
                                },
                                model: {
                                  value: _vm.form.breakageTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "breakageTime", $$v)
                                  },
                                  expression: "form.breakageTime",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "报案号" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入", clearable: "" },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.doSearch.apply(null, arguments)
                                  },
                                },
                                model: {
                                  value: _vm.form.caseNum,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "caseNum", $$v)
                                  },
                                  expression: "form.caseNum",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "签约客户" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    clearable: "",
                                    remote: "",
                                    placeholder: "请输入姓名",
                                    "remote-method": _vm.searchClient,
                                    loading: _vm.searchClientLoading,
                                  },
                                  model: {
                                    value: _vm.form.clientMessage,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "clientMessage", $$v)
                                    },
                                    expression: "form.clientMessage",
                                  },
                                },
                                _vm._l(_vm.customList, function (item) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: item.id,
                                      attrs: {
                                        label: item.clientName,
                                        value: item.id,
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            float: "left",
                                            color: "#8492a6",
                                            "font-size": "13px",
                                          },
                                        },
                                        [_vm._v(_vm._s(item.clientName))]
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            [
                              _c("template", { slot: "label" }, [
                                _c(
                                  "div",
                                  { staticClass: "flex flex-x-center" },
                                  [
                                    _vm._v(" 开票主体 "),
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "light",
                                          placement: "bottom-start",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              color: "#666666",
                                              "font-size": "13px",
                                              "line-height": "20px",
                                              "text-indent": "15px",
                                            },
                                            attrs: { slot: "content" },
                                            slot: "content",
                                          },
                                          [
                                            _vm._v(
                                              " 开票主体，即为协议客户、保险公司和三包厂家的应收账款"
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              " 的回款管理门店(保险公司的开票主体也是定损主体)。保险公司"
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              "管理的开票主体，可查已成为保险公司开票 主体的全部门店。 "
                                            ),
                                          ]
                                        ),
                                        _c("i", {
                                          staticClass:
                                            "iconfont grow-icon_doubt",
                                          staticStyle: { color: "#9a9a9a" },
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    clearable: "",
                                    remote: "",
                                    placeholder: "搜索开票主体名称",
                                    "remote-method": _vm.searchInvoice,
                                    loading: _vm.searchInvoiceLoading,
                                  },
                                  model: {
                                    value: _vm.form.insuranceId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "insuranceId", $$v)
                                    },
                                    expression: "form.insuranceId",
                                  },
                                },
                                _vm._l(_vm.invoiceList, function (item) {
                                  return _c("el-option", {
                                    key: item.companyId,
                                    attrs: {
                                      label: item.invoicingEntity,
                                      value: item.companyId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            2
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "定损金额" } },
                            [
                              _c("number-input", {
                                staticStyle: { width: "84px" },
                                attrs: {
                                  decimalDigits: "2",
                                  placeholder: "开始金额",
                                },
                                model: {
                                  value: _vm.form.startLossAmount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "startLossAmount", $$v)
                                  },
                                  expression: "form.startLossAmount",
                                },
                              }),
                              _vm._v(" - "),
                              _c("number-input", {
                                staticStyle: { width: "84px" },
                                attrs: {
                                  decimalDigits: "2",
                                  placeholder: "截止金额",
                                },
                                model: {
                                  value: _vm.form.endLossAmount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "endLossAmount", $$v)
                                  },
                                  expression: "form.endLossAmount",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "回款认领日期" } },
                            [
                              _c("el-date-picker", {
                                staticClass: "w100",
                                attrs: {
                                  type: "daterange",
                                  "unlink-panels": "",
                                  "range-separator": "至",
                                  "start-placeholder": "起始时间",
                                  "end-placeholder": "截止时间",
                                  "value-format": "yyyy-MM-dd",
                                },
                                model: {
                                  value: _vm.form.paymentCollectionTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "paymentCollectionTime",
                                      $$v
                                    )
                                  },
                                  expression: "form.paymentCollectionTime",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "出损日期" } },
                            [
                              _c("el-date-picker", {
                                staticClass: "w100",
                                attrs: {
                                  type: "daterange",
                                  "unlink-panels": "",
                                  "range-separator": "至",
                                  "start-placeholder": "起始时间",
                                  "end-placeholder": "截止时间",
                                  "value-format": "yyyy-MM-dd",
                                },
                                model: {
                                  value: _vm.form.damageOfTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "damageOfTime", $$v)
                                  },
                                  expression: "form.damageOfTime",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "事故责任" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择事故责任",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.dutyType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "dutyType", $$v)
                                    },
                                    expression: "form.dutyType",
                                  },
                                },
                                _vm._l(_vm.dutyTypeList, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "已结未出损" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.finishedNoLossState,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "finishedNoLossState",
                                        $$v
                                      )
                                    },
                                    expression: "form.finishedNoLossState",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "是", value: 1 },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            ),
          ],
          1
        ),
      ],
      [
        _c(
          "div",
          { staticStyle: { display: "flex", "justify-content": "end" } },
          [
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 465,
                    expression: "465",
                  },
                ],
                staticClass: "blue",
                attrs: { size: "small", type: "text" },
                on: {
                  click: function ($event) {
                    _vm.showExportDialog = true
                  },
                },
              },
              [
                _vm._v("导出列表"),
                _c("i", {
                  staticClass: "iconfont grow-icon_daochu el-icon--right",
                }),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "flex h100" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "fixedScrollBar",
                    rawName: "v-fixedScrollBar",
                    value: _vm.$refs.baseContainer,
                    expression: "$refs.baseContainer",
                  },
                ],
                staticClass: "custom-table",
                attrs: { data: _vm.carBillList, border: "" },
                on: {
                  "row-dblclick": function ($event) {
                    return _vm.goCarBillInfo($event)
                  },
                },
              },
              [
                _c("el-table-column", {
                  attrs: { type: "index", label: "序号", width: "80" },
                }),
                _c("el-table-column", {
                  attrs: { width: "100", label: "提醒", prop: "remind" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          row.remind != 0
                            ? _c("p", { staticClass: "red" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("dict")(row.remind, "remind")
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { width: "100", label: "跟进进展", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: _vm.$lodash.get(
                                  _vm.followUpStateList.find(
                                    (item) => item.value === row.followUpState
                                  ),
                                  "tooltipText",
                                  ""
                                ),
                                placement: "top-start",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "pointer",
                                  style: _vm.$lodash.get(
                                    _vm.followUpStateList.find(
                                      (item) => item.value === row.followUpState
                                    ),
                                    "iconStyle",
                                    ""
                                  ),
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("dict")(
                                          row.followUpState,
                                          "followUpStateList",
                                          "iconText"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "180",
                    label: "操作门店",
                    prop: "operateCompanyName",
                  },
                }),
                _c("el-table-column", {
                  attrs: { width: "180", label: "报案号" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          !["", undefined, null].includes(scope.row.caseNum)
                            ? _c(
                                "span",
                                {
                                  staticClass: "pointer text_operation blue",
                                  on: {
                                    click: function ($event) {
                                      return _vm.goCarBillInfo(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(scope.row.caseNum))]
                              )
                            : _c("span", { staticClass: "orange pointer" }, [
                                _vm._v("鼠标双击打开详情"),
                              ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { width: "180", label: "车牌号", prop: "carNumber" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "p",
                            [
                              _vm._v(" " + _vm._s(row.carNumber) + " "),
                              row.isSupplementaryOrder !== undefined &&
                              row.isSupplementaryOrder === 1
                                ? _c("el-tag", { attrs: { size: "small" } }, [
                                    _vm._v("补单"),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "150",
                    label: "客户名称",
                    prop: "clientName",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "100",
                    label: "跟进人",
                    prop: "followUpPeopleName",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "200",
                    label: "进店时间",
                    prop: "intoStoreTime",
                  },
                }),
                _c("el-table-column", {
                  attrs: { width: "150", prop: "insuranceName" },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function () {
                        return [
                          _c("span", [_vm._v("保险公司")]),
                          _c(
                            "el-tooltip",
                            { attrs: { content: "商业险保险公司" } },
                            [
                              _c("i", {
                                staticClass:
                                  "iconfont grow-icon_doubt color626",
                              }),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { width: "100", label: "事故责任", prop: "dutyType" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm._f("dict")(row.dutyType, "dutyType"))
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { width: "100", label: "定损状态" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "p",
                            [
                              row.lossDeterminationState === 2 &&
                              row.investigationState === 2
                                ? [_vm._v("待复勘")]
                                : row.lossDeterminationState === 2 &&
                                  row.recoveryState === 2
                                ? [_vm._v("待收旧")]
                                : [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("dict")(
                                            row.lossDeterminationState,
                                            "lossDeterminationState"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ],
                            ],
                            2
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "180",
                    label: "开票(定损)主体",
                    prop: "invoice",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "180",
                    label: "定损金额",
                    prop: "lossAmount",
                  },
                }),
                _c("el-table-column", {
                  attrs: { width: "180", label: "备注", prop: "remark" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c("el-tooltip", { attrs: { content: row.remark } }, [
                            _c("div", { staticClass: "text-ellipsis-2-row" }, [
                              _vm._v(" " + _vm._s(row.remark) + " "),
                            ]),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "180",
                    label: "报案日期",
                    prop: "reportingTime",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "120",
                    label: "定损员",
                    prop: "lossDeterminationPersonName",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "120",
                    label: "旧件情况",
                    prop: "recoveryState",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          row.recoveryState != 0
                            ? _c("p", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("dict")(
                                        row.recoveryState,
                                        "recoveryState"
                                      )
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "120",
                    label: "复勘",
                    prop: "investigationState",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          row.investigationState != 0
                            ? _c("p", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("dict")(
                                        row.investigationState,
                                        "investigationState"
                                      )
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { width: "200", label: "接车单号" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "pointer text_operation blue",
                              on: {
                                click: function ($event) {
                                  return _vm.goDetail(scope.row.billId)
                                },
                              },
                            },
                            [_vm._v(_vm._s(scope.row.billCode))]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { width: "100", label: "车单状态" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("dict")(row.billState, "carBillState")
                                ) +
                                " "
                            ),
                            row.billOtherState
                              ? _c("span", { staticClass: "red" }, [
                                  _vm._v(
                                    "(" + _vm._s(row.billOtherState) + ")"
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _c("p", { staticClass: "color999" }),
                          row.dispatchState !== undefined
                            ? _c("div", [
                                _c("span", [_vm._v(" /")]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("dict")(
                                        row.dispatchState,
                                        "dispatchState"
                                      )
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "120",
                    label: "回款状态",
                    prop: "collectionState",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                row.collectionState == 0 ? "未回款" : "已回款"
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "120",
                    label: "回款认领状态",
                    prop: "receivingAmountClaimState",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "180",
                    label: "报损时间",
                    prop: "reportingLossesTime",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "180",
                    label: "调度时间",
                    prop: "dispatchTime",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "180",
                    label: "拍照对件时间",
                    prop: "photographTime",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "180",
                    label: "提交定损时间",
                    prop: "breakageTime",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "180",
                    label: "出损时间",
                    prop: "damageOfTime",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "180",
                    label: "复勘时间",
                    prop: "investigationTime",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "180",
                    label: "收旧时间",
                    prop: "recoveryTime",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "180",
                    label: "提交资料时间",
                    prop: "submissionTime",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "200",
                    label: "部分回款时间",
                    prop: "partPaymentTime",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "200",
                    label: "全部回款时间",
                    prop: "allPaymentTime",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "120",
                    label: "最终定损金额",
                    prop: "finalLossAmount",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "100",
                    label: "单据金额",
                    prop: "documentAmount",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "100",
                    label: "待收金额",
                    prop: "accountsReceivable",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "150",
                    label: "回款认领金额",
                    prop: "receivingAmount",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "150",
                    label: "挂账车队金额",
                    prop: "pendingAmount",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "100",
                    label: "坏账金额",
                    prop: "badDebtsAmount",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "150",
                    label: "回款认领备注",
                    prop: "claimRemark",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "el-tooltip",
                            { attrs: { content: row.claimRemark } },
                            [
                              _c(
                                "div",
                                { staticClass: "text-ellipsis-2-row" },
                                [_vm._v(" " + _vm._s(row.claimRemark) + " ")]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "200",
                    label: "车单备注",
                    prop: "companyRemark",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "el-tooltip",
                            { attrs: { content: row.companyRemark } },
                            [
                              _c(
                                "div",
                                { staticClass: "text-ellipsis-2-row" },
                                [_vm._v(" " + _vm._s(row.companyRemark) + " ")]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "class-box" },
              [
                _c("p", { staticClass: "state-title" }, [
                  _vm._v("定损状态(单数)"),
                ]),
                _c("el-tree", {
                  ref: "my-tree",
                  staticClass: "custom-tree",
                  attrs: {
                    data: _vm.countTableData,
                    props: _vm.treeProp,
                    "highlight-current": "",
                    "node-key": "id",
                    "default-expanded-keys": _vm.defaultKeys,
                    "current-node-key": _vm.nodeKey,
                  },
                  on: { "node-click": _vm.onNodeClick },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ data }) {
                        return [
                          _c("div", { staticClass: "tree-node" }, [
                            _c("span", [_vm._v(_vm._s(data.name))]),
                            _c("span", [
                              _vm._v("(" + _vm._s(data.number) + ")"),
                            ]),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c("export-dialog", {
          attrs: {
            visibility: _vm.showExportDialog,
            total: _vm.total,
            pageOption: _vm.pageOption,
            filterForm: _vm.form,
            url: _vm.exportUrl,
          },
          on: {
            "update:visibility": function ($event) {
              _vm.showExportDialog = $event
            },
          },
        }),
      ],
      _vm.lossRecordDrawer
        ? _c("car-loss-record-drawer", {
            attrs: {
              visibility: _vm.lossRecordDrawer,
              carLossDeterminationBillId: this.ids,
              billId: this.billId,
            },
            on: {
              "update:visibility": function ($event) {
                _vm.lossRecordDrawer = $event
              },
              updateData: _vm.doSearch,
            },
          })
        : _vm._e(),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("pagination", {
            attrs: { total: _vm.total },
            on: { pageChange: _vm.changePage },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }