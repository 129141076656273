<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <div>
        <el-form
          class="filter-form"
          :model="form"
          label-width="100px"
          size="small"
        >
          <div class="flex-x-between">
            <div class="flex-warp flex-shrink0">
              <el-form-item label="车牌号">
                <el-input
                  v-model="form.carNumber"
                  placeholder="请输入车牌号"
                  clearable
                  @clear="carNumberInputClear()"
                  @keyup.enter.native="doSearch"
                ></el-input>
              </el-form-item>
              <el-form-item label="车架号">
                <el-input
                  v-model="form.carVim"
                  placeholder="请输入车架号"
                  clearable
                  @keyup.enter.native="doSearch"
                ></el-input>
              </el-form-item>
              <el-form-item label="客户">
                <el-input
                  v-model="form.clientName"
                  placeholder="请输入客户名称"
                  clearable
                  @keyup.enter.native="doSearch"
                ></el-input>
              </el-form-item>
            </div>
            <div class="flex">
              <div class="el-form-item--small">
                <div
                  @click="showMore = !showMore"
                  class="el-form-item__label pointer"
                >
                  更多筛选
                  <i
                    :class="{
                      primary: true,
                      'el-icon-arrow-right': !showMore,
                      'el-icon-arrow-down': showMore,
                    }"
                  ></i>
                </div>
                <div class="flex">
                  <el-button
                    v-auth="739"
                    class="btn_search"
                    type="primary"
                    size="small"
                    @click="doSearch"
                    >查询</el-button
                  >
                  <el-button class="btn_search" size="small" @click="empty"
                    >置空</el-button
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="flex-warp" v-if="showMore">
            <el-form-item label="状态">
              <el-select
                v-model="form.state"
                placeholder="搜索状态"
                filterable
                clearable
              >
                <el-option
                  v-for="item in stateEnumList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="活动名称">
              <el-input
                v-model="form.activityServiceItemName"
                placeholder="请输入活动名称"
                clearable
                @keyup.enter.native="doSearch"
              ></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </template>
    <!-- 以下是表格 -->
    <template>
      <div style="margin: 30px 0 14px 0" class="btns flex-x-between">
        <div>
          <el-button
            v-auth="741"
            size="small"
            type="primary"
            class="btn_insert"
            @click="batchDeleteHandler"
            >删除
          </el-button>
          <span style="color: red; margin-left: 15px"
            >注：客户是根据车架号车辆进行识别，活动名称必须为当前系统存在的项目名称</span
          >
        </div>
        <div>
          <el-button
            v-auth="742"
            size="small"
            type="primary"
            class="btn_insert"
            @click="importDialogVisibility = true"
            >导入
          </el-button>
          <el-button
            v-auth="740"
            size="small"
            type="text"
            class="blue"
            style="margin-left: 10px"
            @click="showExportDialog = true"
            >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
          ></el-button>
        </div>
      </div>
      <div class="flex h100" style="flex: 1; overflow: auto">
        <div style="flex: 1; overflow: auto">
          <el-table
            class="custom-table"
            :data="dataList"
            border
            @selection-change="handleSelectionChange"
            v-fixedScrollBar="$refs.baseContainer"
          >
            <el-table-column
              type="selection"
              width="60"
              prop="selection"
              align="center"
            >
            </el-table-column>
            <el-table-column type="index" label="序号" width="60">
            </el-table-column>
            <el-table-column width="200" label="车牌号" prop="carNumber">
            </el-table-column>
            <el-table-column width="200" label="车架号" prop="carVim">
            </el-table-column>
            <el-table-column width="200" label="客户" prop="clientName">
            </el-table-column>
            <el-table-column
              width="200"
              label="活动名称"
              prop="activityServiceItemName"
            >
            </el-table-column>
            <el-table-column width="200" label="活动编号" prop="activityNo">
            </el-table-column>
            <el-table-column width="160" label="服务状态" prop="stateDesc">
            </el-table-column>
            <el-table-column width="160" label="录入时间" prop="dateCreated">
            </el-table-column>
            <el-table-column width="100" label="录入人" prop="creator">
            </el-table-column>
            <el-table-column
              width="200"
              label="服务门店"
              prop="billServiceCompanyName"
            >
            </el-table-column>
            <el-table-column width="200" label="车单号" prop="billCode">
            </el-table-column>
          </el-table>
        </div>
      </div>

      <!-- 导入弹窗 -->
      <import-dialog
        v-if="importDialogVisibility"
        :visibility.sync="importDialogVisibility"
        :showTip="false"
        title="厂家批量活动导入"
        templateFileUrl="https://growxny-dev-center.oss-cn-guangzhou.aliyuncs.com/template/20240926/%E7%B4%A2%E8%B5%94%E5%8E%82%E5%AE%B6%E6%B4%BB%E5%8A%A8%E5%BD%95%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx"
        :uploadFileApi="importApi"
        :getLastResultApi="importResultApi"
        :importFailDetail="importMessageExportUrlApi"
      ></import-dialog>
      <!-- 导出弹窗 -->
      <export-dialog
        :visibility.sync="showExportDialog"
        :total="total"
        :pageOption="pageOption"
        :filterForm="form"
        :maxExportNum="20000"
        :url="exportUrl"
      ></export-dialog>
    </template>
    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
  </base-container>
</template>
<script>
// 分页组件
import pagination from '@/components/Pagination.vue';
import importDialog from '@/components/businessComponent/importDialog.vue';
import ExportDialog from '@/components/ExportDialog';

//接口
import {
  batchDeleteApi,
  preListApi,
  listApi,
  listExportUrl,
  batchImportByFileApi,
  batchImportByFileResultApi,
  batchImportByFileFailedExportUrl,
} from '@/api/customer/carFactoryCompensationActivity';

import { hasAuth } from '@/utils/permissions';

export default {
  name: 'carFactoryCompensationActivity',
  components: {
    pagination,
    importDialog,
    ExportDialog,
  },
  data() {
    return {
      form: {
        clientNameOrPhone: '',
        carNumber: '',
        carVim: '',
      },
      // 展示更多筛选
      showMore: false,
      exportUrl: listExportUrl,
      showExportDialog: false,
      importDialogVisibility: false,
      importApi: batchImportByFileApi,
      importResultApi: batchImportByFileResultApi,
      importMessageExportUrlApi: batchImportByFileFailedExportUrl,
      //分页组件
      total: 0,
      dataList: [],
      pageOption: {
        size: 10,
        current: 1,
      },
      selectData: [],
      // 进度状况列表
      stateEnumList: [],
    };
  },
  computed: {},
  activated() {},
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      const _this = this;
      preListApi().then((res) => {
        _this.stateEnumList = res || [];
      });
      this.getList();
    },
    setCurrentKey(key) {},
    //下面是解决设置日期默认值时重新选择无法刷新日期的方法
    dateRangeChange() {
      let _this = this;
      _this.$nextTick(() => {
        _this.$forceUpdate();
      });
    },
    carNumberInputClear() {
      this.form.carNumber = '';
      this.$forceUpdate();
    },
    // 置空输入框数据
    empty() {
      this.form = {};
    },
    //更改页码
    changePage(e) {
      this.pageOption = e;
      this.getList(false);
    },
    /**
     * 点击查询
     */
    doSearch() {
      this.getList();
    },
    getFormData() {
      const _this = this;
      var times = {};
      var data = Object.assign(_this.form, _this.pageOption, times);
      return data;
    },
    //获取列表数据
    getList(reset = true) {
      var res = hasAuth(739);
      if (!res) {
        return;
      }
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      const formData = this.getFormData();
      listApi(formData).then((res) => {
        this.total = res.total;
        this.dataList = res.records;
      });
    },
    handleSelectionChange(items, row) {
      this.selectData = [];
      items.forEach((item) => {
        this.selectData.push(item);
      });
    },
    batchDeleteHandler() {
      const _this = this;
      if (_this.selectData.length === 0) {
        _this.$message({
          type: 'warning',
          message: '请先选择要删除的记录',
        });
        return;
      }
      this.$confirm('确定要删除选中的记录吗?', {
        title: '提示',
        cancelButtonText: '确定',
        confirmButtonText: '取消',
        distinguishCancelAndClose: true,
        closeOnClickModal: false,
        type: 'warning',
      }).catch((err) => {
        if (err === 'cancel') {
          const ids = [];
          _this.selectData.forEach((item) => {
            ids.push(item.id);
          });
          const data = {
            ids: ids,
          };
          batchDeleteApi(data).then((res) => {
            _this.$message({
              type: 'success',
              message: '删除成功',
            });
            _this.getList(false);
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
//取消内容部分的内边距30px
// ::v-deep.base-container .main {
//   padding: 0px !important;
// }
//修改选择时间框长度
.el-form {
  width: 100%;
  .el-form-item__content > div {
    width: 100%;
  }
}

.choice-sty {
  width: 80px !important;
  height: 31px;
  background: rgba(66, 187, 184, 0.1);
  border-radius: 4px;
  font-size: 12px;
  color: #42bbb8;
  text-align: center;
}
.goods {
  background: rgba(85, 183, 142, 0.11);
  color: #55b78e;
}
.service {
  background: rgba(255, 146, 0, 0.1);
  color: #ff9200;
}

.custom-tabs {
  display: flex;
  .custom-tab-item {
    padding: 10px 16px;
    border-radius: 4px 4px 0 0;
    border: 1px solid #eee;
    border-bottom: none;
    text-align: center;
    cursor: pointer;
    &.active,
    &:hover {
      color: $primary;
      background: $bg;
    }
    p:nth-child(2) {
      font-size: 14px;
      margin-top: 3px;
    }
  }
  &.nopadding .custom-tab-item {
    margin-right: 10px;
  }
}
.bill-dialog {
  color: #333;
  font-size: 20px span {
    font-size: 14px;
    color: #999;
  }
}
.billLabel {
  height: 440px;
  overflow-y: scroll;
}
.dialog-footer {
  box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.1);
  height: 109px;
  margin-top: 40px;
  line-height: 109px;
  button {
    height: 30px;
  }
}
.artisan-list {
  margin-left: 5px;
  display: flex;
  flex-wrap: wrap;
  .label {
    margin-top: 10px;
    margin-right: 15px;
    padding: 10px 15px;
    border-radius: 4px;
    border: 1px solid #eee;
    cursor: pointer;
    &.active {
      background: $bg;
      color: $primary;
    }
  }
}
.icon-sty {
  position: absolute;
  top: -8px;
  right: 0px;
}
//状态与统计的样式
.class-box {
  width: 180px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  box-sizing: border-box;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  .title {
    flex-shrink: 0;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-bottom: 1px solid #e4e4e4;
    background: #fafafa;
    color: #888;
    font-size: 14px;
  }
}
.tree-node {
  color: #888;
  font-size: 14px;
}
.tags {
  .el-tag {
    margin-left: 5px;
    margin-top: 5px;
  }
}
</style>
