var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visibility,
        width: "400px",
        title: "毛利率标准",
        "close-on-click-modal": false,
        "before-close": _vm.handleClose,
      },
    },
    [
      _c("div", { staticClass: "container" }, [
        _c("p", { staticClass: "tip-word" }, [
          _c("i", { staticClass: "el-icon-warning orange" }),
          _vm._v(" 设置各项毛利率标准，低于设置值时将预警 "),
        ]),
        _c("table", { staticClass: "set-table" }, [
          _c("thead", [
            _c("tr", [
              _c("th", [_vm._v("业务类型")]),
              _c("th", [_vm._v("毛利率(%)")]),
            ]),
          ]),
          _c("tbody", [
            _c("tr", [
              _c("td", [_vm._v("自费")]),
              _c(
                "td",
                [
                  _c("number-input", {
                    attrs: {
                      min: 0,
                      max: 100,
                      enabledDecimals: false,
                      placeholder: "请输入0-100的正整数",
                    },
                    model: {
                      value: _vm.selfPayGrossProfitRate,
                      callback: function ($$v) {
                        _vm.selfPayGrossProfitRate = $$v
                      },
                      expression: "selfPayGrossProfitRate",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", [_vm._v("包干")]),
              _c(
                "td",
                [
                  _c("number-input", {
                    attrs: {
                      min: 0,
                      max: 100,
                      enabledDecimals: false,
                      placeholder: "请输入0-100的正整数",
                    },
                    model: {
                      value: _vm.contractForGrossProfitRate,
                      callback: function ($$v) {
                        _vm.contractForGrossProfitRate = $$v
                      },
                      expression: "contractForGrossProfitRate",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", [_vm._v("理赔")]),
              _c(
                "td",
                [
                  _c("number-input", {
                    attrs: {
                      min: 0,
                      max: 100,
                      enabledDecimals: false,
                      placeholder: "请输入0-100的正整数",
                    },
                    model: {
                      value: _vm.claimSettlementGrossProfitRate,
                      callback: function ($$v) {
                        _vm.claimSettlementGrossProfitRate = $$v
                      },
                      expression: "claimSettlementGrossProfitRate",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", [_vm._v("索赔")]),
              _c(
                "td",
                [
                  _c("number-input", {
                    attrs: {
                      min: 0,
                      max: 100,
                      enabledDecimals: false,
                      placeholder: "请输入0-100的正整数",
                    },
                    model: {
                      value: _vm.claimForCompensationGrossProfitRate,
                      callback: function ($$v) {
                        _vm.claimForCompensationGrossProfitRate = $$v
                      },
                      expression: "claimForCompensationGrossProfitRate",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", [_vm._v("合计")]),
              _c("td", [
                _vm._v(
                  _vm._s(_vm.totalGrossProfitRate || "请先设置各类型毛利率标准")
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.handleClose } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.handleSave },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }