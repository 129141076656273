var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { attrs: { defaultPadding: false } },
    [
      _c(
        "el-table",
        {
          staticClass: "custom-table",
          staticStyle: { height: "calc(63vh)" },
          attrs: { data: _vm.tableData, border: "" },
        },
        [
          _c("el-table-column", {
            attrs: { type: "index", label: "序号", width: "60" },
          }),
          _c("el-table-column", {
            attrs: { width: "180", label: "修改时间", prop: "lastUpdated" },
          }),
          _c("el-table-column", {
            attrs: { width: "180", label: "修改人", prop: "updater" },
          }),
          _c("el-table-column", {
            attrs: { width: "180", label: "车牌", prop: "carNumber" },
          }),
          _c("el-table-column", {
            attrs: { width: "180", label: "车架号", prop: "uniqueId" },
          }),
          _c("el-table-column", {
            attrs: {
              width: "180",
              label: "电机号/发动机号",
              prop: "engineNumber",
            },
          }),
          _c("el-table-column", {
            attrs: { width: "180", label: "车型", prop: "carModel" },
          }),
          _c("el-table-column", {
            attrs: { width: "180", label: "车辆所有人", prop: "ownerName" },
          }),
          _c("el-table-column", {
            attrs: { width: "180", label: "动力类型", prop: "fuelType" },
          }),
          _c("el-table-column", {
            attrs: {
              width: "180",
              label: "使用性质",
              prop: "annualSurveyCarTypeStr",
            },
          }),
          _c("el-table-column", {
            attrs: { width: "180", label: "注册时间", prop: "registerDate" },
          }),
          _c("el-table-column", {
            attrs: { width: "180", label: "真实续航", prop: "trueEndurance" },
          }),
          _c("el-table-column", {
            attrs: {
              width: "180",
              label: "新车里程",
              prop: "newVehicleMileage",
            },
          }),
          _c("el-table-column", {
            attrs: {
              width: "180",
              label: "在租状态",
              prop: "underLeaseStateStr",
            },
          }),
          _c("el-table-column", {
            attrs: { width: "180", label: "备注", prop: "remark" },
          }),
          _c("el-table-column", {
            attrs: { width: "180", label: "变更备注", prop: "changeRemark" },
          }),
          _c("el-table-column", {
            attrs: { width: "180", label: "客户", prop: "clientName" },
          }),
          _c("el-table-column", {
            attrs: { width: "180", label: "车辆来源", prop: "vehicleSource" },
          }),
          _c("el-table-column", {
            attrs: { width: "180", label: "发车模式", prop: "departureMode" },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("pagination", {
            attrs: { total: _vm.total },
            on: { pageChange: _vm.changePage },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }