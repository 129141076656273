<template>
  <!-- <el-popover
    placement="bottom-start"
    trigger="manual"
    v-model="selectBillSourcePopoverVisible"
    popper-class="popper-class"
  >
    <div class="custom-select">
      <ul class="list">
        <li
          class="item pointer"
          v-for="item in billSourceList"
          :key="item.billSourceTypeId"
          @click="handleSelect(item)"
        >
          {{ item.name }}
        </li>
        <li class="load-more" v-if="hasMore" @click="getData(false, true)">
          加载更多
        </li>
      </ul>
    </div>
    <el-input
      ref="keyWordInput"
      slot="reference"
      v-model="keyWord"
      size="small"
      placeholder="搜索业务来源"
      @input="getData(true)"
      @focus="selectBillSourcePopoverVisible = true"
      @clear="handleClear"
      clearable
    ></el-input>
  </el-popover> -->
  <el-select
    v-model="id"
    @change="onChange"
    @clear="handleClear"
    filterable
    clearable
    remote
    placeholder="搜索业务来源"
    :remote-method="getData"
    :loading="isLoading"
  >
    <el-option
      v-for="item in billSourceList"
      :key="item.billSourceTypeId"
      :label="item.name"
      :value="item.billSourceTypeId"
    >
    </el-option>
  </el-select>
</template>

<script>
import { getBillSourceTypeList, getListForBill } from '@/api/billSource';
import lodash from 'lodash';
export default {
  name: 'selectBillSourcePopover',
  props: {
    value: {
      type: [Number, String, undefined],
    },
    searchKeyWord: {
      type: String,
    },
    isCarBill: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      //选择业务来源弹出层可见性
      // selectBillSourcePopoverVisible: false,

      id: '',

      // 列表数据
      billSourceList: [],

      // 搜索关键字
      keyWord: '',

      // 分页所在页数
      pageCurrent: 0,

      // 是否未加载完所有数据
      hasMore: true,

      // 是否正在加载
      isLoading: false,

      // 是否首次加载
      isFirstLoad: true,
      // 首次传入有ID
      isId: false,
    };
  },
  watch: {
    value(val) {
      this.id = val;
    },
  },
  computed: {
    pagination() {
      return { size: 15, current: this.pageCurrent };
    },
  },
  created() {
    this.keyWord = this.searchKeyWord;
    this.id = this.value;
    if (this.id) this.isId = true;
    this.getData();
  },
  mounted() {
    // document.addEventListener('click', this.handleDocumentClick);
  },
  destroyed() {
    // document.removeEventListener('click', this.handleDocumentClick);
  },
  methods: {
    /**
     * 输入框失焦事件
     */
    inputBlur() {},
    /**
     * 加载数据
     */
    getData(e) {
      this.debounceGetData(e);
    },
    debounceGetData: lodash.debounce(function (e) {
      if (this.isFirstLoad) e = this.keyWord;
      // if (!this.isFirstLoad) this.isLoading = true;
      // if (loadMore) this.current++;

      console.log(e);
      this.pageCurrent = 1;

      // 重置分页，清空已有数据
      // if (reset) {
      //   this.pageCurrent = 1;

      //   this.hasMore = true;
      // } else {
      //   this.pageCurrent++;
      // }
      if (!this.isCarBill) {
        getBillSourceTypeList(
          {
            ...this.pagination,
            name: e,
            // isShare: false,
          },
          { noShowWaiting: true }
        )
          .then((res) => {
            // if (reset)
            this.billSourceList = [];
            console.log(res);
            this.billSourceList.push(...res.records);
            this.hasMore = this.pageCurrent < res.pages;
          })
          .finally(() => {
            this.isLoading = false;
            this.isFirstLoad = false;
            // if (loadMore) this.$refs.keyWordInput.focus();
          });
      } else {
        let bol = true;
        this.billSourceList.forEach((item) => {
          if (item.billSourceTypeId == this.id) bol = false;
        });
        if (this.isId && bol) {
          this.billSourceList = [];
          this.billSourceList.push({
            billSourceTypeId: this.id,
            name: e,
          });
          this.isId = false;
        } else {
          getListForBill(
            {
              ...this.pagination,
              name: e,
              // isShare: false,
            },
            { noShowWaiting: true }
          )
            .then((res) => {
              // if (reset)
              this.billSourceList = [];
              console.log(res);

              this.billSourceList.push(...res.records);
              this.hasMore = this.pageCurrent < res.pages;
            })
            .finally(() => {
              this.isLoading = false;
              this.isFirstLoad = false;
              // if (loadMore) this.$refs.keyWordInput.focus();
            });
        }
      }
    }, 1e2),
    /**
     * 选中
     */
    onChange() {
      this.$emit('input', this.id);
    },
    handleSelect(val) {
      console.log('val', val);
      // this.keyWord = val.name;
      // this.selectBillSourcePopoverVisible = false;
      // this.$emit('select', val);
    },
    /**
     * 清空
     */
    handleClear() {
      this.getData('');
      // this.selectBillSourcePopoverVisible = false;
      // this.$emit('clear');
    },

    // handleDocumentClick(e) {
    //   if (!this.$el || this.$el.contains(e.target) || !e.target.className) {
    //     return;
    //   }
    //   this.selectBillSourcePopoverVisible = false;
    // },
  },
};
</script>

<!--
<style>
.popper-class {
  padding: 0 !important;
}
</style>
-->
<style lang="scss" scoped>
.load-more-btn {
  text-align: center;
  line-height: 50px;
  color: #3eb1e0;
  cursor: pointer;
  &:hover {
    color: #14a3db;
  }
}
.list {
  max-height: 30vh;
  overflow: auto;
  padding: 12px 0;
  .item {
    max-width: 300px;
    padding: 6px 20px;
    color: #666;
    &:hover {
      color: $primary;
      background: $bg;
    }
  }
}
.add {
  padding: 7px 0;
  cursor: pointer;
  border-top: 1px solid #e4e7ed;
  color: $primary;
  text-align: center;
  &:hover {
    background: #fdfdfd;
  }
}
.load-more {
  text-align: center;
  font-size: 14px;
  color: $blue;
  cursor: pointer;
}
</style>
