var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-drawer",
    {
      attrs: {
        title: "选择商品",
        visible: _vm.visibility,
        size: "60%",
        "append-to-body": true,
        "before-close": _vm.handleClose,
      },
    },
    [
      _c(
        "div",
        { staticStyle: { height: "80%", padding: "0 30px" } },
        [
          _c(
            "base-container",
            { attrs: { "default-padding": false } },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c("el-input", {
                            attrs: {
                              size: "small",
                              placeholder: "请输入商品名称",
                              clearable: "",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.getListData.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.goodsName,
                              callback: function ($$v) {
                                _vm.goodsName = $$v
                              },
                              expression: "goodsName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c("el-input", {
                            attrs: {
                              size: "small",
                              placeholder: "请输入商品编码",
                              clearable: "",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.getListData.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.goodsCode,
                              callback: function ($$v) {
                                _vm.goodsCode = $$v
                              },
                              expression: "goodsCode",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "primary" },
                              on: { click: _vm.getListData },
                            },
                            [_vm._v("查询")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "content-box" },
                [
                  _c(
                    "el-table",
                    {
                      ref: "multipleTable",
                      staticClass: "data-table",
                      attrs: { data: _vm.tableData, border: "" },
                      on: {
                        "selection-change": _vm.handleSelectionChange,
                        select: _vm.selectedData,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "selection", width: "40" },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "序号", type: "index", width: "60" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "goodsName",
                          label: "商品项目",
                          width: "110",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "goodsCode",
                          label: "编码",
                          width: "100",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "本店库存/单位", "min-width": "120" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    " " + _vm._s(row.inventoryTotalNum) + " "
                                  ),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(row.unit ? `(${row.unit})` : "") +
                                      " "
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "售价", "min-width": "100" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm._f("toThousands")(row.price)) +
                                      " "
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "matchCarType",
                          label: "适用车型",
                          width: "110",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "min-width": "110",
                          prop: "categoryName",
                          label: "所属分类",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "class-box" },
                    [
                      _c("p", { staticClass: "title" }, [_vm._v("服务分类")]),
                      _c("el-tree", {
                        staticClass: "custom-tree",
                        attrs: {
                          data: _vm.treeData,
                          props: _vm.treeProp,
                          "highlight-current": "",
                          "node-key": "categoryId",
                        },
                        on: { "node-click": _vm.onNodeClick },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "template",
                { slot: "footer" },
                [
                  _c("pagination", {
                    attrs: { total: _vm.total },
                    on: { pageChange: _vm.changePage },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c("p", [
            _c("i", { staticClass: "iconfont grow-icon_warn_32 orange" }),
            _c("span", [
              _vm._v(
                "共选择商品" +
                  _vm._s(_vm.totalLength) +
                  "，本次新增选择" +
                  _vm._s(_vm.addLength)
              ),
            ]),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.confirmSelect },
            },
            [_vm._v("确 定")]
          ),
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.handleClose } },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }