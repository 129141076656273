<template>
  <div class="container">
    <div class="tip color626">
      <p>
        1.导入指定客户指令，请填写签约客户名称，系统不存在的“签约客户”将无法导入；如系统存在多个名称相同的“签约客户”，则不允许导入；
      </p>
      <p>2.项目列限定可设置的指令类型。不支持导入不在列表的项目指令；</p>
      <p>3.更多导入规则，请查看模板文件</p>
      <h3 class="warning">为保证导入速率，请于19:00——次日7:00期间进行导入</h3>
    </div>
    <div class="step-list">
      <div class="step">
        <div class="flex-y-center">
          <div class="index flex-center">1</div>
          <div class="title">第一步: 下载模板文件</div>
        </div>
        <div class="content">
          <a
            style="text-decoration: none; font-size: 14px"
            class="blue pointer"
            href="https://growxny-dev-center.oss-cn-guangzhou.aliyuncs.com/template/20230922/%E5%AE%A2%E6%88%B7%E6%8C%87%E4%BB%A4%E5%AF%BC%E5%85%A5%E6%A8%A1%E7%89%88.xlsx"
            download
            >下载模板 <i class="iconfont grow-icon_download"></i
          ></a>
        </div>
      </div>
      <div class="step">
        <div class="flex-y-center">
          <div class="index flex-center">2</div>
          <div class="title">第二步：选择Excel文件</div>
        </div>
        <div class="content">
          <el-button class="btn-choice" type="primary" @click="chooseFile"
            >选择文件</el-button
          >
          <p
            class="color666"
            style="margin-top: 16px; font-size: 14px"
            v-show="file"
          >
            已选择: {{ $lodash.get(file, 'name') }}
          </p>
        </div>
      </div>
      <div class="step">
        <div class="flex-y-center">
          <div class="index flex-center">3</div>
          <div class="title">第三步：导入数据</div>
        </div>
        <div class="content">
          <el-button
            v-auth="547"
            type="primary"
            @click="upload"
            class="btn-choice"
            >导 入</el-button
          >
          <p class="result" v-if="result">
            成功<span class="primary">{{ result.importSuccessNum }}条</span
            >，失败<span class="red">{{ result.importFailNum }}条</span>；
          </p>
          <p class="result" v-if="result">
            操作人：{{ result.operator }}，操作时间：{{ result.operateDate }}
            <span
              v-if="result.importFailNum"
              class="blue pointer"
              @click="downloadResult"
              >查看详情</span
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { chooseFile, downloadFile } from '@/utils/fileApi';
import {
  clientInstructImport,
  clientInstructImportDetail,
  clientInstructImportResult,
} from '@/api/system/dataImport';
export default {
  name: 'clientCommandImport',
  data() {
    return {
      file: null,
      result: null,
    };
  },
  created() {
    this.checkImportResult();
  },
  methods: {
    chooseFile() {
      chooseFile(
        'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ).then((file) => {
        this.file = file[0];
      });
    },
    upload() {
      if (!this.file) {
        // 上传
        return this.$message.warning('请先选择文件！');
      }
      // 为保证导入速率，请在19点至7点期间进行导入
      let now = new Date().getHours();
      // if (now < 7 || now >= 19) {
      clientInstructImport(this.file).then((res) => {
        console.log(res);
        this.result = res;
      });
      // } else {
      //   this.$message.warning('为保证导入速率，请在19点至7点期间进行导入');
      // }
    },
    downloadResult() {
      downloadFile({ url: clientInstructImportDetail, data: {} });
    },
    //车辆信息导入结果
    checkImportResult() {
      clientInstructImportResult().then((res) => {
        this.result = res;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  padding: 16px;
  background: #fff;
}
.tip {
  padding: 24px;
  background: #f5f7fa;
  font-size: 14px;
  p + p {
    margin-top: 14px;
  }
  .warning {
    margin-top: 24px;
    font-size: 20px;
    font-weight: 500;
    color: #303133;
  }
}
.step-list {
  margin-top: 24px;
  .step {
    & + .step {
      margin-top: 16px;
    }
    .index {
      width: 32px;
      height: 32px;
      border: 2px solid #c0c4cc;
      border-radius: 50%;
      font-size: 20px;
      font-weight: 500;
      color: #c0c4cc;
    }
    .title {
      margin-left: 24px;
      font-size: 16px;
      color: #303133;
      font-weight: 500;
    }
    .content {
      margin: 20px 0 0 120px;
      .result {
        margin-top: 16px;
        font-size: 14px;
        color: #666;
      }
    }
  }
}
.btn-choice {
  width: 98px;
  height: 32px !important;
  line-height: 9px;
  margin-top: -10px;
}
</style>
