<template>
  <div>
    <div class="calendar">
      <div class="calendarHeader">
        <div class="cursor" @click="goPreviousYear()">
          <!-- <div class="lineleft"></div>
            <div class="lineleft"></div> -->
          <i class="el-icon-d-arrow-left"></i>
        </div>
        <i
          class="el-icon-arrow-left cursor min-arrow-left"
          @click="goPreviousMonth()"
        ></i>
        <b class="currentTime">
          {{ currentMonth }}
        </b>
        <i
          class="el-icon-arrow-right cursor min-arrow-right"
          @click="goNextMonth()"
        ></i>
        <div class="cursor" @click="goNextYear()">
          <!-- <div class="lineright"></div>
            <div class="lineright"></div> -->
          <i class="el-icon-d-arrow-right"></i>
        </div>
      </div>
      <div class="calendarMain">
        <div class="calendarMainHeader">
          <span v-for="(item, index) in weekDay" :key="index" class="weekItem">
            {{ item.name }}
          </span>
        </div>
        <div class="calendarMainBody">
          <span
            v-for="(item, index) in dateList"
            :key="index"
            class="dataItem cursor"
            @click="handleClick(item, $event)"
          >
            <div v-for="(data, index) in todoDayItemList" :key="index">
              <div
                class="itemBadge"
                v-if="`${item.year}-${item.month}-${item.day}` == data.day"
              >
                {{ data.num > 99 ? '99+' : data.num }}
              </div>
            </div>
            <!-- {{ item }} -->
            <span
              class="hoverStyle"
              :class="[
                { color666: item.type !== 'current' },
                {
                  checkStyle:
                    selectTime === `${item.year}-${item.month}-${item.day}`,
                },
                { color666: disable },
                { color666: ableClick(item) },
                {
                  selected:
                    selectedDate === `${item.year}-${item.month}-${item.day}`,
                },
              ]"
              >{{ item.value }}</span
            >
          </span>
        </div>
        <div class="today cursor" @click="goNowTime">今天</div>
      </div>
    </div>
  </div>
</template>

<script>
import { statisticByDay } from '@/api/unTreated';
// import WebsocketMixin from '@/service/websocket/WebsocketMixin';
export default {
  // mixins: [WebsocketMixin],
  props: {
    time: {}, //(yyyy-mm) 当前显示月份（初始化一次 不做后期同步）
    selectTime: {}, // (yyyy-mm-dd) 当前选中的日期（单向数据流）
    disable: {
      //是否禁止选择日期 （用于起始 结束日期的禁止联动）
      type: Boolean,
      default: false,
    },
    isdataCheck: {
      //是否禁止某个日期以前的时间不可选（与CheckData参数搭配使用）
      type: Boolean,
      default: false,
    },
    CheckData: {
      //yyyy-mm-dd）禁止某个日期以前的时间不可选的日期 （isdataCheck为true时生效）
      type: String,
      default: '',
    },
    operationalState: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      newTime: this.time,
      selectValue: this.selectTime,
      weekDay: [
        {
          name: '一',
          code: 'Monday',
        },
        {
          name: '二',
          code: 'Tuesday',
        },
        {
          name: '三',
          code: 'Wednesday',
        },
        {
          name: '四',
          code: 'Thursday',
        },
        {
          name: '五',
          code: 'Friday',
        },
        {
          name: '六',
          code: 'Saturday',
        },
        {
          name: '日',
          code: 'Sunday',
        },
      ],
      dateList: [],
      selectedDate: '',
      data: {
        beginDay: '', //开始时间
        endDay: '', //结束时间
      },
      todoDayItemList: [],
    };
  },
  computed: {
    currentMonth() {
      return `${this.newTime.split('-')[0]}年${this.newTime.split('-')[1]}月`;
    },
  },
  methods: {
    // handleMessageReceived(message) {
    //   if (message.action === 'todoMsg') {
    //     console.log('收到消息  ws:', JSON.parse(message.content));
    //   }
    // },
    goPreviousYear() {
      let a = this.newTime.split('-');
      a[0] = a[0] - 1;
      this.newTime = a.join('-');
      this.dataListNew(this.newTime, this.newTime);
      this.getStatisticByDay(this.data);
      this.$emit('changeTime', this.newTime);
    },
    goNextYear() {
      let a = this.newTime.split('-');
      a[0] = a[0] * 1 + 1;
      this.newTime = a.join('-');
      this.dataListNew(this.newTime, this.newTime);
      this.getStatisticByDay(this.data);
      this.$emit('changeTime', this.newTime);
    },
    goPreviousMonth() {
      this.newTime = this.previousMonth(this.newTime);
      this.dataListNew(this.newTime, this.newTime);
      this.getStatisticByDay(this.data);
      this.$emit('changeTime', this.newTime);
    },
    goNextMonth() {
      this.newTime = this.nextMonth(this.newTime);
      this.dataListNew(this.newTime, this.newTime);
      this.getStatisticByDay(this.data);
      this.$emit('changeTime', this.newTime);
    },
    goNowTime() {
      this.newTime = this.getTime();
      this.dataListNew(this.newTime, this.newTime);
      this.selectedDate = this.newTime;
      this.getStatisticByDay(this.data);
      this.$emit('goNowTime', this.newTime, 'nowTime');
    },
    getTime() {
      let today = new Date();
      let year = today.getFullYear();
      let month = today.getMonth() + 1;
      let days = today.getDate();
      let newTime = `${year}-${this.formateDate(month)}-${this.formateDate(
        days
      )}`;
      return newTime;
    },
    formateDate(str) {
      return str < 10 ? '0' + str : str;
    },
    // 生成当月长度数组
    listNew(time, type) {
      let x = time.split('-');
      let a = new Date(x[0], x[1], 0).getDate();
      let b = Array.from(new Array(a).keys());
      let c = [];
      b.forEach((item) => {
        c.push({
          value: item + 1,
          type: type,
          year: x[0],
          month: x[1],
          day: (item + 1 + '').padStart(2, '0'),
          select: false,
        });
      });
      return c;
    },
    // 计算上个月日期
    previousMonth(time) {
      let a = time.split('-');
      if (a[1] == '01') {
        a[0] = a[0] - 1;
        a[1] = '12';
      } else {
        a[1] = (a[1] - 1 + '').padStart(2, '0');
      }
      return a.join('-');
    },
    // 计算下个月日期
    nextMonth(time) {
      let a = time.split('-');
      if (a[1] == '12') {
        a[0] = a[0] * 1 + 1;
        a[1] = '01';
      } else {
        a[1] = (a[1] * 1 + 1 + '').padStart(2, '0');
      }
      return a.join('-');
    },
    // 汇总数据 生成日期数组
    dataListNew(startTime, endTime) {
      this.dateList = [];
      // 开始时间
      let a = startTime.split('-');
      // 结束时间
      let b = endTime.split('-');
      // 上个月的日期
      let c = this.previousMonth(startTime).split('-');
      let d = this.listNew(this.previousMonth(startTime), 'previous');
      c[2] = d[d.length - 1].value;
      let J = new Date(c.join('-')).getDay();
      let k = [];
      if (J !== 0) {
        k = d.splice(J * -1);
      }
      // 下个月的日期
      let e = this.nextMonth(endTime).split('-');
      let f = this.listNew(this.nextMonth(endTime), 'next');
      e[2] = f[0].value;
      let L = new Date(e.join('-')).getDay();
      let G = [];
      G = f.splice(0, 8 - L);
      // 拼接上个月的数据
      this.dateList = this.dateList.concat(k);
      // 拼接当前月的数据
      this.dateList = this.dateList.concat(
        this.listNew(a.join('-'), 'current')
      );
      // 拼接间隔月的数据
      while (a.join('-') !== b.join('-')) {
        if (a[1] == '12') {
          a[0] = a[0] * 1 + 1;
          a[1] = '01';
        } else {
          a[1] = (a[1] * 1 + 1 + '').padStart(2, '0');
        }
        // 拼接下个月的数据
        this.dateList = this.dateList.concat(this.listNew(a.join('-')));
      }
      this.dateList = this.dateList.concat(G);
      let temp = this.dateList;
      if (temp.length > 35) {
        this.dateList = temp.splice(0, 35);
      }
      this.data.beginDay =
        this.dateList[0].year +
        '-' +
        this.dateList[0].month +
        '-' +
        this.dateList[0].day;
      this.data.endDay =
        this.dateList[this.dateList.length - 1].year +
        '-' +
        this.dateList[this.dateList.length - 1].month +
        '-' +
        this.dateList[this.dateList.length - 1].day;
    },
    handleClick(item, e) {
      // if (e.target.className.indexOf('color666') > 0) {
      //   return;
      // }
      this.selectedDate = `${item.year}-${item.month}-${item.day}`;
      this.$emit('startDataHandle', `${item.year}-${item.month}-${item.day}`);
    },
    ableClick(item) {
      if (this.isdataCheck) {
        return (
          Date.parse(new Date(`${item.year}-${item.month}-${item.day}`)) <
          Date.parse(new Date(this.CheckData))
        );
      } else {
        return false;
      }
    },
    // 接口返回日历待办
    getStatisticByDay(data) {
      this.todoDayItemList = [];
      statisticByDay(data).then((res) => {
        this.todoDayItemList = res.todoDayItemList;
        // this.todoDayItemList = [
        //   {
        //     day: '2023-12-12',
        //     num: 10,
        //   },
        //   {
        //     day: '2023-12-13',
        //     num: 100,
        //   },
        // ];
      });
    },
  },
  created() {
    this.newTime = this.getTime();
    this.dataListNew(this.newTime, this.newTime);
    this.selectedDate = this.newTime;
    this.getStatisticByDay(this.data);
  },
  watch: {
    time: function (newValue) {
      this.selectValue = newValue;
    },
    disable: function (newValue) {
      //console.log(newValue);
    },
    operationalState: function (newValue, oldValue) {
      console.log(newValue, oldValue);
      if (newValue) {
        setTimeout(() => {
          this.getStatisticByDay(this.data);
        }, 50);
      }
    },
  },
};
</script>

<style scoped="scoped" lang="scss">
.calendar {
  width: 500px;
  height: 475px;
  border: 1px solid #edeef1;
  //   margin-left: 1px;
  background: #ffffff;
}
.currentTime {
  margin: 0px px;
  display: inline-block;
  color: #333;
  /* font-weight: 900; */
}

.min-arrow-left {
  margin-right: 90px;
}

.min-arrow-right {
  margin-left: 90px;
}

.today {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  border-top: 1px solid #edeef1;
  margin-top: 25px;
}

.calendarHeader {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 500px;
  height: 65px;
  /* line-height: 50px; */
  /* text-align: center; */
  padding: 0px 5px;
  box-sizing: border-box;
  border-bottom: 1px solid #edeef1;
  margin-bottom: 25px;
  font-size: 20px;
}

.calendarMain {
  width: 500px;
  height: 395px;
  box-sizing: border-box;
  font-size: 14px;
}

.lineright {
  width: 5px;
  height: 5px;
  transform: rotate(45deg);
  display: inline-block;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
}

.lineleft {
  width: 5px;
  height: 5px;
  transform: rotate(-135deg);
  display: inline-block;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
}

.calendarMainHeader {
  /* display: flex; */
  /* background: #f0f8ff; */
  /* color: #1e9bff; */
}

.weekItem {
  display: inline-block;
  width: calc(100% / 7 - 6px);
  height: 34.85px;
  line-height: 34.85px;
  margin: 0px 3px;
  text-align: center;
  box-sizing: border-box;
}

.calendarMainBody {
  //   padding: 10px;
  /* display: flex;
          flex-wrap: wrap; */
}

.dataItem {
  position: relative;
  display: inline-block;
  width: calc(100% / 7 - 6px);
  height: 55px;
  line-height: 50px;
  margin: 0px 3px;
  text-align: center;
  font-size: 18px;
  .itemBadge {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    // text-align: center;
    border-radius: 50%;
    background-color: red;
    top: 0px;
    right: 6px;
    font-size: 12px;
    color: #ffffff;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 20px;
      width: 22px;
      height: 22px;
    }
  }
}

.color666 {
  color: #ccc;
  /* cursor: not-allowed !important; */
}

.cursor {
  cursor: pointer;
}

.checkStyle {
  border-radius: 50%;
  background-color: #1e9bff;
  color: white;
  display: inline-block;
  height: 25px;
  width: 25px;
  line-height: 25px;
}

.hoverStyle:hover {
  text-align: center;
  border-radius: 50%;
  background-color: #33ab79;
  color: white;
  display: inline-block;
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 50%;
}
.selected {
  text-align: center;
  border-radius: 50%;
  background-color: #33ab79;
  color: white;
  display: inline-block;
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 50%;
}
</style>
