<template>
  <base-container
    ref="baseContainer"
    customStyle="background: #f1f7fa;"
    customMainStyle="margin-bottom: 16px;"
    :defaultPadding="false"
  >
    <div class="box">
      <div class="calendar">
        <customCalendar
          class="customCalendar"
          @goNowTime="goNowTime"
          @startDataHandle="startDataHandle"
          :operationalState="operationalState"
        ></customCalendar>
      </div>
      <div class="table">
        <div class="right-content">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="当日待办" name="notFinished"></el-tab-pane>
            <el-tab-pane label="已办" name="finished"></el-tab-pane>
          </el-tabs>
          <el-table
            ref="tableRef"
            :data="listData"
            :row-key="getRowKeys"
            @selection-change="handleChange"
            border
          >
            <el-table-column type="index" label="序号" width="60">
            </el-table-column>
            <el-table-column label="状态" prop="status">
              <!-- <template #default="{ row }">
                <el-checkbox
                  v-model="row.check"
                  @change="selectItem(row)"
                ></el-checkbox>
              </template> -->
              <el-table-column
                :reserve-selection="true"
                type="selection"
                fixed="left"
                width="80"
              />
            </el-table-column>
            <el-table-column label="提醒" width="120" prop="overTime">
              <p></p>
              <template #default="{ row }">
                <p class="red">
                  {{ row.overTime }}
                </p>
              </template>
            </el-table-column>
            <el-table-column label="分类" width="80" prop="funType.description">
              <template #default="{ row }">
                <span v-if="row.funType == 0">定损单</span>
                <span v-if="row.funType == 1">车单</span>
                <span v-if="row.funType == 2">车单返修追溯</span>
                <span v-if="row.funType == 3">派工单</span>
                <span v-if="row.funType == -1">未知待办类型</span>
              </template>
            </el-table-column>
            <el-table-column label="待办内容" min-width="200" prop="title">
              <template #default="{ row }">
                <span
                  class="pointer text_operation blue"
                  @click="goCarBillInfo(row)"
                  v-if="
                    !['', undefined, null].includes(row.title) &&
                    row.funType == 0
                  "
                  >{{ row.title }}</span
                >
                <span
                  class="blue"
                  v-else-if="
                    !['', undefined, null].includes(row.title) &&
                    row.funType == 2
                  "
                  >{{ row.title }}</span
                >
              </template>
            </el-table-column>
            <el-table-column label="创建时间" width="200" prop="dateCreated">
            </el-table-column>
            <el-table-column
              label="截止时间"
              width="200"
              prop="deadlineDateTime"
            >
            </el-table-column>
          </el-table>
        </div>
        <pagination
          class="pagination-box"
          @pageChange="changePage"
          :total="total"
        ></pagination>
      </div>
      <!-- 定损单详情弹窗 -->
      <car-loss-record-drawer
        v-if="lossRecordDrawer"
        :visibility.sync="lossRecordDrawer"
        :carLossDeterminationBillId="this.id"
        @updateData="doSearch"
      >
      </car-loss-record-drawer>
    </div>
    <el-dialog
      title="操作提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <span>点击确定把当前事项设置为{{ dialogText }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit">确 定</el-button>
        <el-button @click="handleClose">取 消</el-button>
      </span>
    </el-dialog>
  </base-container>
</template>

<script>
import customCalendar from '@/components/businessComponent/customCalendar.vue';
import pagination from '@/components/Pagination.vue';
import {
  getToDoList,
  changeToCompleted,
  getCompletedList,
  changeToUnCompleted,
} from '@/api/unTreated';
import { mapMutations } from 'vuex';
import carLossRecordDrawer from '@/views/carBill/carLossDeterminationBill/carLossDeterminationBillDetailDrawer.vue';
export default {
  name: 'unTreated',
  components: { customCalendar, pagination, carLossRecordDrawer },
  data() {
    return {
      activeName: 'notFinished',
      listData: [],
      dialogVisible: false,
      //分页组件
      total: 0,
      pageOption: {
        current: 1,
        size: 10,
      },
      getToDoListData: {
        beginDateTime: '',
        endDateTime: '',
      },
      lossRecordDrawer: false,
      //定损单id
      id: '',
      dialogText: '已办',
      //选中的定损单数组
      ids: [],
      // 当前操作了待办或者已办
      operationalState: 0,
      unTreatedState: 0,
    };
  },
  created() {
    this.getToDoList();
  },
  methods: {
    ...mapMutations({
      globalchangeUnTreatedState: 'changeUnTreatedState',
    }),
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      // 待办
      if (this.activeName == 'notFinished') {
        this.dialogText = '已办';
        this.getToDoList();
      }
      //已办
      if (this.activeName == 'finished') {
        this.dialogText = '待办';
        this.getCompletedList();
      }
    },
    // tab切换
    handleClick(tab, event) {
      this.pageOption = {
        current: 1,
        size: 10,
      };
      // 待办
      if (this.activeName == 'notFinished') {
        this.dialogText = '已办';
        this.getToDoList();
      }
      //已办
      if (this.activeName == 'finished') {
        this.dialogText = '待办';
        this.getCompletedList();
      }
    },
    handleChange(row) {
      if (row.length) {
        this.ids = [];
        this.dialogVisible = true;
        this.ids.push(row[0].id);
      }
    },
    handleClose() {
      this.$refs.tableRef.clearSelection();
      this.dialogVisible = false;
    },
    submit() {
      if (this.activeName == 'notFinished') {
        changeToCompleted(this.ids).then((res) => {
          this.$bus.$emit('changeUnTreatedStateEvent', {});
          this.getToDoList();
          this.globalchangeUnTreatedState(this.unTreatedState);
          this.unTreatedState++;
        });
      }

      if (this.activeName == 'finished') {
        changeToUnCompleted(this.ids).then((res) => {
          this.$bus.$emit('changeUnTreatedStateEvent', {});
          this.getCompletedList();
          this.globalchangeUnTreatedState(this.unTreatedState);
          this.unTreatedState++;
        });
      }
      this.operationalState = this.operationalState + 1;
      this.$refs.tableRef.clearSelection();
      this.dialogVisible = false;
    },
    //日历组件-选中今天
    goNowTime(val) {
      this.getToDoListData.beginDateTime = val;
      this.getToDoListData.endDateTime = val;
      if (this.activeName == 'notFinished') {
        this.dialogText = '已办';
        this.getToDoList();
      }
      //已办
      if (this.activeName == 'finished') {
        this.dialogText = '待办';
        this.getCompletedList();
      }
    },
    //日历组件-选中某天
    startDataHandle(val) {
      this.getToDoListData.beginDateTime = val;
      this.getToDoListData.endDateTime = val;
      if (this.activeName == 'notFinished') {
        this.dialogText = '已办';
        this.getToDoList();
      }
      //已办
      if (this.activeName == 'finished') {
        this.dialogText = '待办';
        this.getCompletedList();
      }
    },
    getRowKeys(row) {
      return row.id;
    },
    // 定损记录详情抽屉弹出
    goCarBillInfo(row) {
      this.id = row.businessKey;
      if (0 == row.funType) {
        this.lossRecordDrawer = true;
      }
      // this.billId = row.billId;
      // this.getDialogList();
      // this.searchInsuranceCompany();
    },
    doSearch() {},
    // 待办列表
    getToDoList() {
      let data = Object.assign(this.getToDoListData, this.pageOption);
      getToDoList(data).then((res) => {
        this.listData = [];
        this.listData = res.records;
        this.total = res.total;
      });
    },
    // 已办列表
    getCompletedList() {
      let data = Object.assign(this.getToDoListData, this.pageOption);
      getCompletedList(data).then((res) => {
        this.listData = [];
        this.listData = res.records;
        this.total = res.total;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-form {
  border-radius: 4px;
  padding: 16px 16px 24px 16px;
  ::v-deep .el-form-item--small.el-form-item {
    margin-bottom: 0;
  }
}
// .main-view {
//   background: #f1f7fa !important;
// }

.box {
  width: 100%;
  height: calc(100% - 89px);
  display: flex;
  padding-top: 16px;
  box-sizing: border-box;
  // background-color: #f1f7fa;
  // height: 80%;
  .calendar {
    height: calc(100% + 32px);
    background-color: #ffffff;
    border-radius: 4px;
    .customCalendar {
      margin: 16px 24px;
    }
  }
  .table {
    flex: 1;
    min-width: 0;
    display: flex;
    flex-direction: column;
    padding: 16px 24px;
    background: #fff;
    margin-left: 16px;
    border-radius: 4px;
    position: relative;
    height: 100%;
    .right-content {
      flex: 1;
      // height: 100%;
      // margin-left: 16px;
      // padding: 8px;
      // background: #fff;
      // min-width: 0;
      padding-bottom: calc(4vh + 32px);
      overflow: auto;
    }
    .pagination-box {
      width: calc(100% - 48px);
      background: #fff;
      box-sizing: border-box;
      position: absolute;
      bottom: 0;
      text-align: center;
      z-index: 2;
    }
  }
  ::v-deep .el-tabs__nav-wrap::after {
    position: static !important;
  }
}
// > tr:nth-child(2) .cell
::v-deep
  .el-table__header-wrapper
  .el-table__header
  .el-table-column--selection {
  // background-color: red !important;
  display: none !important;
}
</style>
