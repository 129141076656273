var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { ref: "baseContainer", attrs: { paddingTop: "" } },
    [
      [
        _c(
          "div",
          [
            _c(
              "el-form",
              {
                staticClass: "filter-form",
                attrs: {
                  model: _vm.form,
                  "label-width": "100px",
                  size: "small",
                },
              },
              [
                _c("div", { staticClass: "flex-x-between" }, [
                  _c(
                    "div",
                    { staticClass: "flex-warp flex-shrink0" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "车牌号" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入", clearable: "" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.doSearch.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.form.carNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "carNumber", $$v)
                              },
                              expression: "form.carNumber",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c("template", { slot: "label" }, [
                            _c(
                              "div",
                              [
                                _vm._v(" 操作门店 "),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "light",
                                      content:
                                        "接车单的操作门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)",
                                      placement: "bottom-start",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "iconfont grow-icon_doubt",
                                      staticStyle: { color: "#9a9a9a" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("operateCompanyCascader", {
                            attrs: {
                              operateCompanyUrl: _vm.form.operateCompanyUrl,
                            },
                            model: {
                              value: _vm.form.operateCompanyId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "operateCompanyId", $$v)
                              },
                              expression: "form.operateCompanyId",
                            },
                          }),
                        ],
                        2
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "接车进店日期" } },
                        [
                          _c("el-date-picker", {
                            staticClass: "w100",
                            attrs: {
                              type: "daterange",
                              "unlink-panels": "",
                              "range-separator": "至",
                              "start-placeholder": "起始时间",
                              "end-placeholder": "截止时间",
                              "value-format": "yyyy-MM-dd",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.dateRangeChange()
                              },
                            },
                            model: {
                              value: _vm.form.intoStoreTimes,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "intoStoreTimes", $$v)
                              },
                              expression: "form.intoStoreTimes",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "flex" }, [
                    _c("div", { staticClass: "el-form-item--small" }, [
                      _c(
                        "div",
                        {
                          staticClass: "el-form-item__label pointer",
                          on: {
                            click: function ($event) {
                              _vm.showMore = !_vm.showMore
                            },
                          },
                        },
                        [
                          _vm._v(" 更多筛选 "),
                          _c("i", {
                            class: {
                              primary: true,
                              "el-icon-arrow-right": !_vm.showMore,
                              "el-icon-arrow-down": _vm.showMore,
                            },
                          }),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "btn_search",
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.doSearch },
                            },
                            [_vm._v("查询")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "btn_search",
                              attrs: { size: "small" },
                              on: { click: _vm.empty },
                            },
                            [_vm._v("置空")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "flex-warp" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "评价意见" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { clearable: "", placeholder: "请选择" },
                            on: { change: _vm.selectAdminJobListUser },
                            model: {
                              value: _vm.form.appraiseLevel,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "appraiseLevel", $$v)
                              },
                              expression: "form.appraiseLevel",
                            },
                          },
                          _vm._l(_vm.appraiseList, function (item) {
                            return _c("el-option", {
                              key: item.level,
                              attrs: {
                                label: item.appraise,
                                value: item.level,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "接车单号" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入接车单号",
                            clearable: "",
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.doSearch.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.form.billCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "billCode", $$v)
                            },
                            expression: "form.billCode",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "评价日期" } },
                      [
                        _c("el-date-picker", {
                          staticClass: "w100",
                          attrs: {
                            type: "daterange",
                            "unlink-panels": "",
                            "range-separator": "至",
                            "start-placeholder": "起始时间",
                            "end-placeholder": "截止时间",
                            "value-format": "yyyy-MM-dd",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.dateRangeChange()
                            },
                          },
                          model: {
                            value: _vm.form.appraiseTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "appraiseTime", $$v)
                            },
                            expression: "form.appraiseTime",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "评价标签" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              filterable: "",
                              clearable: "",
                              remote: "",
                              placeholder: "请选择",
                              "remote-method": _vm.getAppraiseTags,
                              loading: _vm.searchAppraiseTagsLoading,
                            },
                            model: {
                              value: _vm.form.appraiseTag,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "appraiseTag", $$v)
                              },
                              expression: "form.appraiseTag",
                            },
                          },
                          _vm._l(_vm.appraiseTagList, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item, value: item },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.showMore
                      ? [
                          _c(
                            "el-form-item",
                            { attrs: { label: "接车员" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    clearable: "",
                                    remote: "",
                                    placeholder: "请选择",
                                    "remote-method": _vm.searchAdminJobListUser,
                                    loading: _vm.searchAdminJobListUserLoading,
                                  },
                                  on: { change: _vm.selectAdminJobListUser },
                                  model: {
                                    value: _vm.form.pickUpUserId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "pickUpUserId", $$v)
                                    },
                                    expression: "form.pickUpUserId",
                                  },
                                },
                                _vm._l(
                                  _vm.adminJobListUserList,
                                  function (item) {
                                    return _c(
                                      "el-option",
                                      {
                                        key: item.userId,
                                        attrs: {
                                          label: item.realName,
                                          value: item.userId,
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              float: "left",
                                              color: "#8492a6",
                                              "font-size": "13px",
                                            },
                                          },
                                          [_vm._v(_vm._s(item.realName))]
                                        ),
                                        _c(
                                          "span",
                                          { staticStyle: { float: "right" } },
                                          [_vm._v(_vm._s(item.phone))]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "签约客户" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    clearable: "",
                                    remote: "",
                                    placeholder: "请输入姓名",
                                    "remote-method": _vm.searchClient,
                                    loading: _vm.searchClientLoading,
                                  },
                                  on: { change: _vm.selectClient },
                                  model: {
                                    value: _vm.form.clientId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "clientId", $$v)
                                    },
                                    expression: "form.clientId",
                                  },
                                },
                                _vm._l(_vm.customList, function (item) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: item.id,
                                      attrs: {
                                        label: item.clientName,
                                        value: item.id,
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            float: "left",
                                            color: "#8492a6",
                                            "font-size": "13px",
                                          },
                                        },
                                        [_vm._v(_vm._s(item.clientName))]
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "车单完工日期" } },
                            [
                              _c("el-date-picker", {
                                staticClass: "w100",
                                attrs: {
                                  type: "daterange",
                                  "unlink-panels": "",
                                  "range-separator": "至",
                                  "start-placeholder": "起始时间",
                                  "end-placeholder": "截止时间",
                                  "value-format": "yyyy-MM-dd",
                                },
                                model: {
                                  value: _vm.form.finishTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "finishTime", $$v)
                                  },
                                  expression: "form.finishTime",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "车架号" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入", clearable: "" },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.doSearch.apply(null, arguments)
                                  },
                                },
                                model: {
                                  value: _vm.form.uniqueId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "uniqueId", $$v)
                                  },
                                  expression: "form.uniqueId",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            ),
          ],
          1
        ),
      ],
      [
        _c(
          "div",
          { staticStyle: { display: "flex", "justify-content": "end" } },
          [
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 494,
                    expression: "494",
                  },
                ],
                staticClass: "blue",
                attrs: { size: "small", type: "text" },
                on: {
                  click: function ($event) {
                    _vm.showExportDialog = true
                  },
                },
              },
              [
                _vm._v("导出列表"),
                _c("i", {
                  staticClass: "iconfont grow-icon_daochu el-icon--right",
                }),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "flex h100",
            staticStyle: { flex: "1", overflow: "auto" },
          },
          [
            _c(
              "div",
              { staticStyle: { flex: "1", overflow: "auto" } },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "fixedScrollBar",
                        rawName: "v-fixedScrollBar",
                        value: _vm.$refs.baseContainer,
                        expression: "$refs.baseContainer",
                      },
                    ],
                    staticClass: "custom-table",
                    attrs: { data: _vm.appraisList, border: "" },
                    on: {
                      "row-dblclick": function ($event) {
                        return _vm.goDetail($event.billId)
                      },
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { type: "index", label: "序号", width: "60" },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "120",
                        label: "操作门店",
                        prop: "operateCompanyName",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "el-tooltip",
                                { attrs: { content: row.company.companyName } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "text-ellipsis-2-row" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(row.company.companyName) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "100",
                        label: "车牌号",
                        prop: "carBillDetailsVo.carNumber",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { width: "190", label: "车单号/车单标签" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "blue pointer",
                                  on: {
                                    click: function ($event) {
                                      return _vm.goDetail(row.billId)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(row.carBillDetailsVo.billCode) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "tags" },
                                [
                                  row.carBillDetailsVo.returnCarOption.value
                                    ? _c(
                                        "el-tag",
                                        { attrs: { size: "small" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              row.carBillDetailsVo
                                                .returnCarOption.desc
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  row.carBillDetailsVo.repairOption.value
                                    ? _c(
                                        "el-tag",
                                        { attrs: { size: "small" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              row.carBillDetailsVo.repairOption
                                                .desc
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  row.carBillDetailsVo.supplementaryOrderOption
                                    .value
                                    ? _c(
                                        "el-tag",
                                        { attrs: { size: "small" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              row.carBillDetailsVo
                                                .supplementaryOrderOption.desc
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  row.carBillDetailsVo.claimUnderWarrantyOption
                                    .desc
                                    ? _c(
                                        "el-tag",
                                        { attrs: { size: "small" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              row.carBillDetailsVo
                                                .claimUnderWarrantyOption.desc
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  row.carBillDetailsVo.claimActivityOption.desc
                                    ? _c(
                                        "el-tag",
                                        { attrs: { size: "small" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              row.carBillDetailsVo
                                                .claimActivityOption.desc
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "100",
                        label: "接车员",
                        prop: "carBillDetailsVo.pickUpUserName",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "100",
                        label: "送修人",
                        prop: "carBillDetailsVo.contactName",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "120",
                        label: "送修手机号码",
                        prop: "carBillDetailsVo.contactPhone",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "160",
                        label: "进店时间",
                        prop: "carBillDetailsVo.dateCreated",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "160",
                        label: "完工时间",
                        prop: "finishTime",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "160",
                        label: "评价时间",
                        prop: "dateCreated",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "120",
                        label: "评价人手机号",
                        prop: "phone",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "100",
                        label: "评价意见",
                        prop: "appraiseLevelStr",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        "min-width": "150",
                        label: "评价标签",
                        prop: "appraiseTags",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        "min-width": "100",
                        label: "其他",
                        prop: "appraiseSuggestion",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "120",
                        label: "消费客户",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              row.consumer
                                ? _c("div", [
                                    _c(
                                      "div",
                                      { staticClass: "text-ellipsis" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(row.consumer.clientName) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "120",
                        label: "签约客户",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              row.owner
                                ? _c("div", [
                                    _c(
                                      "div",
                                      { staticClass: "text-ellipsis" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(row.owner.clientName) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
        _c(
          "template",
          { slot: "footer" },
          [
            _c("pagination", {
              attrs: { total: _vm.total },
              on: { pageChange: _vm.changePage },
            }),
          ],
          1
        ),
        _c("export-dialog", {
          attrs: {
            visibility: _vm.showExportDialog,
            total: _vm.total,
            pageOption: _vm.pageOption,
            filterForm: _vm.form,
            url: _vm.exportUrl,
          },
          on: {
            "update:visibility": function ($event) {
              _vm.showExportDialog = $event
            },
          },
        }),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }