<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <div>
        <el-form
          class="filter-form"
          :model="form"
          label-width="100px"
          size="small"
        >
          <div class="flex-x-between">
            <div class="flex-warp flex-shrink0">
              <el-form-item label="车牌号">
                <el-input
                  v-model="form.carNumber"
                  placeholder="请输入"
                  clearable
                  @keyup.enter.native="doSearch"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <template slot="label">
                  <div>
                    操作门店
                    <el-tooltip
                      effect="light"
                      content="接车单的操作门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)"
                      placement="bottom-start"
                    >
                      <i
                        class="iconfont grow-icon_doubt"
                        style="color: #9a9a9a"
                      ></i>
                    </el-tooltip>
                  </div>
                </template>
                <operateCompanyCascader
                  v-model="form.operateCompanyId"
                  :operateCompanyUrl="form.operateCompanyUrl"
                ></operateCompanyCascader>
              </el-form-item>
              <el-form-item label="接车进店日期">
                <el-date-picker
                  class="w100"
                  v-model="form.intoStoreTimes"
                  type="daterange"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="起始时间"
                  end-placeholder="截止时间"
                  value-format="yyyy-MM-dd"
                  @input="dateRangeChange()"
                >
                </el-date-picker>
              </el-form-item>
            </div>
            <div class="flex">
              <div class="el-form-item--small">
                <div class="flex">
                  <el-button
                    class="btn_search"
                    type="primary"
                    size="small"
                    @click="doSearch"
                    >查询</el-button
                  >
                  <el-button class="btn_search" size="small" @click="empty"
                    >置空</el-button
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="flex-warp">
            <el-form-item label="接车员">
              <el-select
                v-model="form.pickUpUserId"
                @change="selectAdminJobListUser"
                filterable
                clearable
                remote
                placeholder="搜索姓名、手机号"
                :remote-method="searchAdminJobListUser"
                :loading="searchAdminJobListUserLoading"
              >
                <el-option
                  v-for="item in adminJobListUserList"
                  :key="item.userId"
                  :label="item.realName"
                  :value="item.userId"
                >
                  <span style="float: left; color: #8492a6; font-size: 13px">{{
                    item.realName
                  }}</span>
                  <span style="float: right">{{ item.phone }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="签约客户">
              <el-select
                v-model="form.clientId"
                @change="selectClient"
                filterable
                clearable
                remote
                placeholder="请输入客户名称"
                :remote-method="searchClient"
                :loading="searchClientLoading"
              >
                <el-option
                  v-for="item in customList"
                  :key="item.id"
                  :label="item.clientName"
                  :value="item.id"
                >
                  <span style="float: left; color: #8492a6; font-size: 13px">{{
                    item.clientName
                  }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="滞留日期">
              <el-date-picker
                class="w100"
                v-model="form.retentionStartTimes"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="截止时间"
                value-format="yyyy-MM-dd"
                @input="dateRangeChange()"
              >
              </el-date-picker>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </template>
    <!-- 以下是表格 -->
    <template>
      <div class="btns flex-x-between">
        <div style="margin-bottom: 15px; font-size: 14px">
          <i
            class="pointer iconfont grow-icon_warn_32"
            style="color: #ff9200; font-size: 15px; margin: 5px 10px"
          ></i
          ><span style="color: #909399"
            >每日零点系统将统计记录前一天车辆的滞留快照情况</span
          >
        </div>
        <div>
          <el-button
            v-auth="450"
            size="small"
            type="text"
            class="blue"
            @click="showExportDialog = true"
            >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
          ></el-button>
        </div>
      </div>

      <el-table
        class="custom-table"
        :data="carBillList"
        border
        @row-dblclick="goDetail($event.billId)"
        v-fixedScrollBar="$refs.baseContainer"
      >
        <el-table-column type="index" label="序号" width="60">
        </el-table-column>
        <el-table-column width="100" label="操作" prop="carNumber">
          <template slot-scope="scope">
            <div
              v-auth="451"
              class="detail-btn flex-center blue pointer"
              @click="handleEdit(scope.row)"
            >
              滞留详情
            </div>
          </template>
        </el-table-column>
        <el-table-column width="120" label="操作门店" prop="companyName">
          <template #default="{ row }">
            <el-tooltip :content="row.companyName">
              <div class="text-ellipsis-2-row">
                {{ row.companyName }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>

        <el-table-column width="100" label="车牌号" prop="carNumber">
        </el-table-column>
        <el-table-column width="170" label="车单号">
          <template #default="{ row }">
            <div class="blue pointer" @click="goDetail(row.billId)">
              {{ row.billCode }}
            </div>
          </template>
        </el-table-column>
        <el-table-column width="180" label="车型/车架号" show-overflow-tooltip>
          <template #default="{ row }">
            <div class="text-ellipsis" v-if="row.carModelName">
              {{ row.carModelName }}
            </div>
            <div class="text-ellipsis" v-if="row.carUniqueId">
              {{ row.carUniqueId }}
            </div>
          </template>
        </el-table-column>
        <el-table-column width="120" label="签约客户" show-overflow-tooltip>
          <template #default="{ row }">
            <div>
              <div class="text-ellipsis">{{ row.ownerName }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="100" label="进店时间" prop="intoStoreTime">
        </el-table-column>
        <el-table-column width="100" label="接车员" prop="pickUpCarName">
        </el-table-column>
        <el-table-column min-width="120" label="滞留时业务类型">
          <template #default="{ row }">
            <div v-if="row.billType !== 0">
              {{ row.billType | dict('carBillType') }}
            </div>
            <div v-else>
              {{ row.billSelfpayType == 0 ? '自费' : '他人自费' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column min-width="150" label="滞留时预交车时间">
          <template #default="{ row }">
            <!-- <div v-if="row.waitInStore">{{ row.planFinishTime }}</div>
                  <div class="color999">
                    {{ row.waitInStore ? '在店等' : '待定' }}
                  </div> -->
            <div v-if="row.undetermined == 1">待定</div>
            <div v-else>
              <p>{{ row.planFinishTime }}</p>
              <p>{{ row.waitInStore ? '在店等' : '' }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="140" label="滞留时车单状态">
          <template #default="{ row }">
            <div>
              <p>
                {{ row.billState | dict('carBillState') }}
                <span class="red" v-if="row.billOtherState"
                  >({{ row.billOtherState }})</span
                >
              </p>
            </div>
            <div style="font-size: 12px" class="flex color626">
              <!-- 检车状态字段 -->
              <!-- {{ row.detectionState | dict('detectionState') }} -->

              <!-- 报价审核状态字段 -->
              <!-- bug764隐藏 -->
              <!-- {{ row.quotationReviewState == 0 ? '/待审核' : '/已审核' }}  注释时间2022年8月1日-->
              <!-- 施工状态字段 -->
              <div v-if="row.dispatchState !== undefined">
                <span> /</span>
                {{ row.dispatchState | dict('dispatchState') }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          min-width="120"
          label="滞留|中断原因"
          prop="strandedReason"
        >
          <template #default="{ row }">
            <el-tooltip :content="row.strandedReason">
              <div class="text-ellipsis-2-row">
                {{ row.strandedReason }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column width="120" prop="planCompletionTime">
          <template slot="header">
            <div class="flex">
              <el-tooltip content="卡点解决时间">
                <span class="text-ellipsis">卡点解决时间</span>
              </el-tooltip>
              <el-tooltip effect="light" placement="bottom-start">
                <div
                  slot="content"
                  style="
                    color: #666666;
                    font-size: 13px;
                    line-height: 16px;
                    text-indent: 15px;
                  "
                >
                  当前阶段预计完成时间
                </div>
                <i
                  class="iconfont grow-icon_doubt"
                  style="margin-left: 5px"
                ></i>
              </el-tooltip>
            </div>
          </template>
          <template #default="{ row }">
            <div>{{ row.planCompletionTime }}</div>
          </template>
        </el-table-column>
        <el-table-column
          width="100"
          label="滞留报告人"
          prop="reporterName"
        ></el-table-column>
        <el-table-column width="100" prop="lastUpdated">
          <template slot="header">
            <el-tooltip content="滞留更新时间">
              <span class="text-ellipsis">滞留更新时间</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          width="150"
          label="滞留日期"
          prop="dateCreated"
        ></el-table-column>
      </el-table>

      <export-dialog
        :visibility.sync="showExportDialog"
        :total="total"
        :pageOption="pageOption"
        :filterForm="form"
        :url="exportUrl"
      ></export-dialog>
    </template>

    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
    <!-- 滞留详情抽屉 -->
    <el-drawer
      title="车辆滞留详情"
      :visible="stayDetailsDrawer"
      size="60%"
      :append-to-body="true"
      :before-close="handleClose"
      modal
      close-on-press-escape
      :wrapperClosable="true"
    >
      <div
        style="margin-left: 24px; width: 96%; overflow: scroll; height: 100%"
      >
        <div class="report-sty">
          <div class="flex">
            <div class="car-logo flex-shrink0">
              <img v-if="carInfo.carLogoIcon" :src="carInfo.carLogoIcon" />
              <img v-else src="@/assets/img/carLogo.png" />
            </div>
            <div style="margin-top: 3px; margin-left: 16px">
              <div class="car-info">
                <span class="car-number">{{ carInfo.carNumber }}</span>
                <span
                  v-if="carInfo.carModelName"
                  class="car-info-tag car-brand"
                  >{{ carInfo.carModelName }}</span
                >
              </div>
              <p
                style="
                  margin-top: 16px;
                  text-decoration: underline;
                  color: #303133;
                "
              >
                {{ carInfo.ownerName }}
              </p>
            </div>
          </div>
        </div>
        <!-- 单据信息 -->
        <div class="billInfo flex-x-between">
          <p>单据号：{{ carInfo.billCode }}</p>
          <p>进店时间：{{ carInfo.intoStoreTime }}</p>
          <p>接车员：{{ carInfo.pickUpCarName }}</p>
        </div>
        <!-- 滞留表格 -->
        <el-table
          class="custom-table stay-table"
          :data="detailedList.records"
          border
        >
          <el-table-column width="100" label="滞留日期" prop="dateCreated">
          </el-table-column>
          <el-table-column width="100" label="滞留报告人" prop="reporterName">
          </el-table-column>
          <el-table-column width="160" label="滞留更新时间" prop="lastUpdated">
          </el-table-column>
          <el-table-column
            min-width="140"
            label="滞留|中断原因"
            prop="strandedReason"
          >
            <template #default="{ row }">
              <el-tooltip :content="row.strandedReason">
                <div class="text-ellipsis-2-row">
                  {{ row.strandedReason }}
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            width="160"
            label="预计完成时间"
            prop="planCompletionTime"
          >
          </el-table-column>
          <el-table-column
            width="160"
            label="滞留时预交车时间"
            prop="planFinishTime"
          >
            <template #default="{ row }">
              <div v-if="row.undetermined == 1">待定</div>
              <div v-else>
                <p>{{ row.planFinishTime }}</p>
                <p>{{ row.waitInStore ? '在店等' : '' }}</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column width="130" label="滞留时业务类型" prop="billType">
            <template #default="{ row }">
              <div v-if="row.billType !== 0">
                {{ row.billType | dict('carBillType') }}
              </div>
              <div v-else>
                {{ row.billSelfpayType == 0 ? '自费' : '他人自费' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column width="130" label="滞留时车单状态" prop="billState">
            <template #default="{ row }">
              <div>
                {{ row.billState | dict('carBillState') }}
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="footer">
          <pagination
            @pageChange="detailChangePage"
            :total="detailTotal"
          ></pagination>
        </div>
      </div>
    </el-drawer>
  </base-container>
</template>
<script>
// 分页组件
import pagination from '@/components/Pagination.vue';
import ExportDialog from '@/components/ExportDialog';
import { downloadFile } from '@/utils/fileApi';

//接口
import { exportCarBillListUrl } from '@/api/financial/pickSingle';
import { getUserList } from '@/api/employee';
import {
  getCarStrandedRetention,
  getCarStrandedRetentionDetailed,
  exportCarStrandedRetentionListUrl,
} from '@/api/carBill/strandedCar';

import { searchClient } from '@/api/customer/customer';

//组件
import operateCompanyCascader from '@/components/businessComponent/operateCompanyCascader';
// 字典
import { carBillType } from '@/service/dict/dictData';

// 工具
import Big from 'big.js';
import { mapState } from 'vuex';

export default {
  name: 'strandedCar',
  components: {
    pagination,
    ExportDialog,
    operateCompanyCascader,
  },
  data() {
    return {
      form: {
        intoStoreTimes: [],
      },
      //导出的页面设置
      exportUrl: exportCarStrandedRetentionListUrl,
      //分页组件
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      showExportDialog: false,
      status: -1,
      // 车单列表数据
      carBillList: [],
      //搜索接车员列表字段
      searchAdminJobListUserLoading: false,
      adminJobListUserList: [],
      //签约客户
      customList: [],
      searchClientLoading: false,
      //滞留详情抽屉可见性
      stayDetailsDrawer: false,
      //详情车辆信息
      carInfo: {},
      //详情列表数据
      detailedList: {},
      retentionBillId: '',
      //抽屉中的分页
      detailTotal: 0,
      detailPageOption: {
        size: 10,
        current: 1,
      },
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  activated() {},
  created() {
    this.form.operateCompanyId = [this.companyId];
    this.nowDate();
    this.getList();
  },
  mounted() {},
  methods: {
    //下面是解决设置日期默认值时重新选择无法刷新日期的方法
    dateRangeChange() {
      let _this = this;
      _this.$nextTick(() => {
        _this.$forceUpdate();
      });
    },
    //设置滞留日期默认值为当天
    nowDate() {
      this.form.retentionStartTimes = [
        new Date().Format('yyyy-MM-dd'),
        new Date().Format('yyyy-MM-dd'),
      ];
    },
    // 置空输入框数据
    empty() {
      this.form = { intoStoreTimes: [] };
    },
    //更改页码
    changePage(e) {
      this.pageOption = e;
      this.getList(false);
    },
    //抽屉的更改页面
    detailChangePage(e) {
      this.detailPageOption = e;
      this.getRetentionDetailed();
    },
    //远程搜索接车员列表
    adminJobUsrKeyWordChange(keyWord) {
      if (keyWord) {
        this.searchAdminJobListUser(keyWord);
      }
    },
    searchAdminJobListUser(keyWord = '') {
      this.searchAdminJobListUserLoading = true;
      getUserList(keyWord)
        .then((res) => {
          this.adminJobListUserList = res.records || [];
        })
        .finally(() => {
          this.searchAdminJobListUserLoading = false;
        });
    },
    //选中接车员
    selectAdminJobListUser() {},
    /**
     * 远程搜索车主客户列表
     */
    clientKeyWordChange(keyword) {
      if (keyword) {
        this.searchClient(keyword);
      }
    },
    searchClient(keyWord = '') {
      this.searchClientLoading = true;
      searchClient(keyWord)
        .then((res) => {
          this.customList = res.records;
        })
        .finally(() => {
          this.searchClientLoading = false;
        });
    },
    /**
     * 选择车主客户
     */
    selectClient(clientId) {},

    /**
     * 删除车主客户
     */
    deleteClient() {},
    /**
     * 点击查询
     */
    doSearch() {
      this.getList();
    },
    //获取列表数据
    getList(reset = true) {
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      var times = {
        intoStoreStartTime: this.form.intoStoreTimes?.[0]
          ? this.form.intoStoreTimes?.[0]
          : '',
        intoStoreEndTime: this.form.intoStoreTimes?.[1]
          ? this.form.intoStoreTimes?.[1]
          : '',
        retentionStartTime: this.form.retentionStartTimes?.[0]
          ? this.form.retentionStartTimes?.[0]
          : '',
        retentionEndTime: this.form.retentionStartTimes?.[1]
          ? this.form.retentionStartTimes?.[1]
          : '',
      };
      var data = Object.assign(this.form, this.pageOption, times);
      getCarStrandedRetention(data).then((res) => {
        this.total = res.data.total;
        this.carBillList = res.data.records;
      });
    },
    handleClose() {
      this.stayDetailsDrawer = false;
    },
    handleEdit(value) {
      this.stayDetailsDrawer = true;
      this.retentionBillId = value.billId;
      this.getRetentionDetailed();
    },
    //请求滞留详情
    getRetentionDetailed() {
      var billId = {
        billId: this.retentionBillId,
      };
      var data = Object.assign(billId, this.detailPageOption);
      getCarStrandedRetentionDetailed(data).then((res) => {
        let { info, ...otherData } = res;
        this.carInfo = otherData;
        this.detailedList = info;
        this.detailTotal = info.total;
      });
    },
    /**
     * 跳转详情页
     */
    goDetail(id) {
      this.$router.push({
        name: 'carBillDetail',
        query: {
          id,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.report-sty {
  display: flex;
  margin: 3px 0px 16px 0px;
  justify-content: space-between;
  span {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
  }
}
.car-logo {
  width: 64px;
  height: 64px;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.car-info {
  .car-number {
    color: #303133;
    font-size: 20px;
    font-weight: 500;
  }

  .car-info-tag {
    display: inline-block;
    margin-left: 16px;
    padding: 4px 6px;
    border-radius: 2px;
    border: 1px solid #ebeef5;
    font-size: 12px;
    &.car-brand {
      color: #606266;
    }
    &.history {
      color: $primary;
      background: #eef7f3;
    }
  }
}
.billInfo {
  height: 76px;
  background: #f0f2f5;
  border-radius: 4px;
  line-height: 76px;
  color: #303133;
  padding: 0px 16px;
  margin-bottom: 16px;
}
.footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 50px;
  z-index: 99;
}
.stay-table {
  // height: 50% !important;
  height: calc(100% - 400px);
}
</style>
