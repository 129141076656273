<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <el-form
        class="filter-form"
        :model="form"
        label-width="100px"
        size="small"
      >
        <div class="flex-x-between">
          <div class="flex-warp flex-shrink0">
            <el-form-item label="车牌号">
              <el-input
                v-model="form.carNumber"
                placeholder="请输入车牌号"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <template slot="label">
                <div>
                  操作门店
                  <el-tooltip
                    effect="light"
                    content="接车单的操作门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)"
                    placement="bottom-start"
                  >
                    <i
                      class="iconfont grow-icon_doubt"
                      style="color: #9a9a9a"
                    ></i>
                  </el-tooltip>
                </div>
              </template>
              <operateCompanyCascader
                v-model="form.operateCompanyId"
              ></operateCompanyCascader>
            </el-form-item>
            <el-form-item label="接车进店日期">
              <el-date-picker
                v-model="form.intoStoreDateRange"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="截止时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </div>
          <div class="flex">
            <div class="el-form-item--small">
              <div
                @click="showMore = !showMore"
                class="el-form-item__label pointer"
              >
                更多筛选
                <i
                  :class="{
                    primary: true,
                    'el-icon-arrow-right': !showMore,
                    'el-icon-arrow-down': showMore,
                  }"
                ></i>
              </div>
              <div class="flex">
                <el-button
                  class="btn_search"
                  type="primary"
                  size="small"
                  @click="getData"
                  >查询</el-button
                >
                <el-button
                  class="btn_search"
                  size="small"
                  @click="resetFilterForm"
                  >置空</el-button
                >
              </div>
            </div>
          </div>
        </div>
        <div class="flex-warp">
          <el-form-item label="接车员">
            <el-select
              v-model="form.pickUpUserId"
              filterable
              clearable
              remote
              placeholder="搜索姓名、手机号"
              :remote-method="searchAdminJobListUser"
              :loading="searchAdminJobListUserLoading"
            >
              <el-option
                v-for="item in adminJobListUserList"
                :key="item.userId"
                :label="item.realName"
                :value="item.userId"
              >
                <span style="float: left; color: #8492a6; font-size: 13px">{{
                  item.realName
                }}</span>
                <span style="float: right">{{ item.phone }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="接车单号">
            <el-input
              v-model="form.billCode"
              placeholder="请输入"
              clearable
              @keyup.enter.native="getData"
            ></el-input>
          </el-form-item>
          <el-form-item label="车单结账日期">
            <el-date-picker
              v-model="form.checkDateRange"
              type="daterange"
              unlink-panels
              range-separator="至"
              start-placeholder="起始时间"
              end-placeholder="截止时间"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="车架号">
            <el-input
              v-model="form.uniqueId"
              placeholder="请输入"
              clearable
              @keyup.enter.native="getData"
            ></el-input>
          </el-form-item>
          <template v-if="showMore">
            <el-form-item label="业务来源">
              <el-select
                v-model="form.billSourceTypeId"
                filterable
                clearable
                remote
                placeholder="搜索业务来源"
                :remote-method="searchBillSource"
                :loading="searchBillSourceLoading"
              >
                <el-option
                  v-for="item in billSourceList"
                  :key="item.billSourceTypeId"
                  :label="item.name"
                  :value="item.billSourceTypeId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="签约客户">
              <el-select
                v-model="form.clientId"
                filterable
                clearable
                remote
                placeholder="请输入客户名称"
                :remote-method="searchClient"
                :loading="searchClientLoading"
              >
                <el-option
                  v-for="item in clientList"
                  :key="item.id"
                  :label="item.clientName"
                  :value="item.id"
                >
                  <span style="color: #8492a6; font-size: 13px">{{
                    item.clientName
                  }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="车型信息">
              <el-select
                v-model="form.carModel"
                filterable
                remote
                clearable
                placeholder="搜索车型"
                :remote-method="searchCarModel"
                :loading="searchCarModelLoading"
              >
                <el-option
                  v-for="item in carModelList"
                  :key="$lodash.get(item, 'series.id')"
                  :value="
                    $lodash.get(
                      item,
                      'series.name',
                      $lodash.get(
                        item,
                        'brand.name',
                        $lodash.get(item, 'logo.name', '')
                      )
                    )
                  "
                >
                  <span v-html="item.markedRedName"></span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="施工技师">
              <el-select
                v-model="form.technician"
                filterable
                placeholder="搜索姓名、手机号"
                clearable
              >
                <el-option
                  v-for="item in techniciansList"
                  :key="item.userId"
                  :label="item.realName"
                  :value="item.userId"
                >
                  <span style="float: left; color: #8492a6; font-size: 13px">{{
                    item.realName
                  }}</span>
                  <span style="float: right">{{ item.phone }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="施工班组">
              <el-input
                placeholder="请输入"
                v-model="form.filed1"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
            <el-form-item label="业务员">
              <el-select
                v-model="form.sellerId"
                filterable
                placeholder="搜索姓名、手机号"
                clearable
              >
                <el-option
                  v-for="item in salesmanList"
                  :key="item.userId"
                  :label="item.realName"
                  :value="item.userId"
                >
                  <span style="float: left; color: #8492a6; font-size: 13px">{{
                    item.realName
                  }}</span>
                  <span style="float: right">{{ item.phone }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <template slot="label">
                <div>
                  消费客户
                  <el-tooltip effect="light" placement="bottom-start">
                    <template slot="content">
                      <p>自费/包干客户：输入客户名称</p>
                      <p>保险公司：输入公司名称、编码</p>
                      <p>三包厂家：输入厂家名称、编码</p>
                    </template>
                    <i
                      class="iconfont grow-icon_doubt"
                      style="color: #9a9a9a"
                    ></i>
                  </el-tooltip>
                </div>
              </template>
              <el-input
                v-model="form.consumerMsg"
                placeholder="请输入"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>

            <el-form-item>
              <template slot="label">
                <div class="flex flex-x-center">
                  开票主体
                  <el-tooltip effect="light" placement="bottom-start">
                    <div
                      slot="content"
                      style="
                        color: #666666;
                        font-size: 13px;
                        line-height: 20px;
                        text-indent: 15px;
                      "
                    >
                      开票主体，即为协议客户、保险公司和三包厂家的应收账款<br />
                      的回款管理门店(保险公司的开票主体也是定损主体)。保险公司<br />管理的开票主体，可查已成为保险公司开票
                      主体的全部门店。
                    </div>
                    <i
                      class="iconfont grow-icon_doubt"
                      style="color: #9a9a9a"
                    ></i>
                  </el-tooltip>
                </div>
              </template>
              <el-select
                v-model="form.invoiceId"
                filterable
                clearable
                remote
                placeholder="搜索开票主体名称"
                :remote-method="searchInvoice"
                :loading="searchInvoiceLoading"
              >
                <el-option
                  v-for="item in invoiceList"
                  :key="item.companyId"
                  :label="item.invoicingEntity"
                  :value="item.companyId"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="服务分类">
              <el-select
                v-model="form.serviceFirstCategoryId"
                filterable
                clearable
                @clear="form.serviceFirstCategoryId = null"
                placeholder="请选择或搜索分类"
              >
                <el-option
                  v-for="item in serviceClassList"
                  :key="item.categoryId"
                  :label="item.name"
                  :value="item.categoryId"
                >
                  <span style="float: left; color: #8492a6; font-size: 13px">{{
                    item.name
                  }}</span>
                  <span style="float: right">{{ item.categoryId }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="服务项目">
              <div
                v-if="selectedServices.length == 0"
                class="choice-btn service"
                @click="multipleSelectServicesDrawerVisibility = true"
              >
                <span class="pointer"> 点击选择</span>
              </div>
              <el-popover
                placement="bottom"
                width="400"
                trigger="hover"
                v-else
                style="text-align: center"
              >
                <el-table
                  style="width: 100%"
                  border
                  max-height="400"
                  :data="selectedServices"
                >
                  <el-table-column prop="name" label="服务名称" min-width="160">
                  </el-table-column>
                  <el-table-column
                    prop="serviceItemCode"
                    label="服务编码"
                    min-width="100"
                  >
                  </el-table-column>
                </el-table>
                <div
                  slot="reference"
                  class="choice-btn service pointer"
                  style="position: relative"
                >
                  <span @click="multipleSelectServicesDrawerVisibility = true">
                    已选择({{ selectedServices.length }})</span
                  >
                  <i
                    class="iconfont grow-a-icon_tabbar_close del-icon"
                    style="color: #ff9200"
                    @click="clearSelectedService"
                  ></i>
                </div>
              </el-popover>
            </el-form-item>
          </template>
        </div>
      </el-form>
    </template>

    <template>
      <div class="flex-x-between">
        <div class="custom-tabs nopadding">
          <div
            @click="selectedBillType = ''"
            :class="{
              'custom-tab-item': true,
              active: selectedBillType === '',
            }"
          >
            全部
          </div>
          <div
            @click="selectedBillType = 0"
            :class="{
              'custom-tab-item': true,
              active: selectedBillType === 0,
            }"
          >
            自费业务
          </div>
          <div
            @click="selectedBillType = 1"
            :class="{
              'custom-tab-item': true,
              active: selectedBillType === 1,
            }"
          >
            包干业务
          </div>
          <div
            @click="selectedBillType = 2"
            :class="{
              'custom-tab-item': true,
              active: selectedBillType === 2,
            }"
          >
            理赔业务
          </div>
          <div
            @click="selectedBillType = 3"
            :class="{
              'custom-tab-item': true,
              active: selectedBillType === 3,
            }"
          >
            索赔业务
          </div>
        </div>

        <el-button
          v-auth="510"
          size="small"
          type="text"
          class="blue"
          @click="showExportDialog = true"
          >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
        ></el-button>
      </div>

      <el-table
        id="customTable"
        class="custom-table summary"
        :data="tableData"
        border
        show-summary
        :summary-method="getSummaries"
        @row-dblclick="goDetail"
        v-fixedScrollBar="$refs.baseContainer"
      >
        <el-table-column type="index" label="序号" width="60">
        </el-table-column>
        <el-table-column width="180" label="操作门店" prop="operateCompany">
        </el-table-column>
        <el-table-column width="200" label="接车单号" prop="billCode">
          <template #default="{ row }">
            <div class="blue pointer" @click="goDetail(row)">
              {{ row.billCode }}
            </div>
          </template>
        </el-table-column>
        <el-table-column width="160" label="结账时间" prop="checkTime">
        </el-table-column>
        <el-table-column width="160" label="进店时间" prop="intoTime">
        </el-table-column>
        <el-table-column width="100" label="车牌号" prop="carNumber">
        </el-table-column>
        <el-table-column width="100" label="车型" prop="carModel">
        </el-table-column>
        <el-table-column width="180" label="车架号" prop="carVIN">
        </el-table-column>
        <el-table-column width="140" label="签约客户">
          <template #default="{ row }">
            <div>{{ row.ownerName }}</div>
          </template>
        </el-table-column>
        <el-table-column width="160" label="消费客户">
          <template #default="{ row }">
            <div>
              {{ row.consumerName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column width="180" label="开票主体" prop="invoiceName">
        </el-table-column>
        <el-table-column width="180" label="业务类型">
          <template #default="{ row }">
            <div v-if="row.billType !== 0">
              {{ row.billType | dict('carBillType') }}
            </div>
            <div v-else>
              {{ row.isOthersSelfPayState | dict('isOthersSelfPayState') }}
            </div>
          </template>
        </el-table-column>
        <el-table-column width="100" label="业务来源" prop="businessSource">
        </el-table-column>
        <el-table-column width="100" label="进店里程" prop="mileage">
        </el-table-column>
        <el-table-column width="100" label="接车员" prop="receiver">
        </el-table-column>
        <el-table-column width="100" label="项目大类" prop="firstCategoryName">
        </el-table-column>
        <el-table-column width="100" label="项目细类" prop="secondCategoryName">
        </el-table-column>
        <el-table-column width="100" label="项目名称" prop="pjName">
        </el-table-column>
        <el-table-column width="100" label="项目小计">
          <template #default="{ row }">
            <span v-if="row.total !== undefined">{{
              row.total | toThousands
            }}</span>
          </template>
        </el-table-column>
        <el-table-column width="100" label="成本小计">
          <template #default="{ row }">{{
            row.inventoryTotal | toThousands
          }}</template>
        </el-table-column>
        <el-table-column width="100" label="项目毛利">
          <template #default="{ row }">
            <span v-if="row.marginTotal !== undefined">{{
              row.marginTotal | toThousands
            }}</span>
          </template>
        </el-table-column>
        <el-table-column width="100" label="包干金额">
          <template #default="{ row }">
            <span v-if="row.contractTotal !== undefined">{{
              row.contractTotal | toThousands
            }}</span>
          </template>
        </el-table-column>
        <el-table-column width="100" label="业务员" prop="salesman">
        </el-table-column>
        <el-table-column
          width="100"
          label="施工技师"
          prop="constructionTechnician"
        >
        </el-table-column>
        <el-table-column
          width="100"
          label="施工班组"
          prop="technicianTeamNames"
        >
        </el-table-column>
        <el-table-column min-width="180" label="项目明细">
          <template #default="{ row }">
            <el-tooltip :content="row.projectDetail">
              <div class="text-ellipsis-2-row">
                {{ row.projectDetail }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>

      <!-- 选择服务抽屉 -->
      <select-services-car-bill
        v-if="multipleSelectServicesDrawerVisibility"
        :oldServicesData="selectedServices || []"
        @select="selectServicesList"
        :visibility.sync="multipleSelectServicesDrawerVisibility"
      ></select-services-car-bill>
      <!-- 导出弹窗 -->
      <export-dialog
        v-if="showExportDialog"
        :visibility.sync="showExportDialog"
        :total="total"
        :pageOption="pageOption"
        :filterForm="exportForm"
        :url="exportUrl"
      ></export-dialog>
    </template>

    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
  </base-container>
</template>

<script>
// 组件
import pagination from '@/components/Pagination';
import ExportDialog from '@/components/ExportDialog';
import SelectServicesCarBill from '@/views/carBill/selectServicesCarBill';
// 接口
import { searchClient } from '@/api/customer/customer';
import { getBillSourceTypeList } from '@/api/billSource';
import { getUserList, getTechniciansList } from '@/api/employee';
import { searchCarModel } from '@/api/customer/car';
import { getServicesClasses } from '@/api/carBill';
import { getSalesmanList } from '@/api/employee';
import { searchInvoice } from '@/api/storeManagement/index';
import {
  itemGrossProfitList,
  itemGrossProfitTotal,
  exportItemGrossProfitListUrl,
} from '@/api/financial/financialOver';
import operateCompanyCascader from '@/components/businessComponent/operateCompanyCascader';

import { mapState, mapGetters, mapMutations } from 'vuex';

import { toThousands } from '@/utils/filters/number';
export default {
  name: 'itemGrossProfitList',
  components: {
    pagination,
    ExportDialog,
    SelectServicesCarBill,
    operateCompanyCascader,
  },
  data() {
    return {
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },

      form: {},

      // 展示更多筛选
      showMore: false,

      // 接车员列表
      adminJobListUserList: [],
      searchAdminJobListUserLoading: false,

      // 搜索业务来源
      billSourceList: [],
      searchBillSourceLoading: false,

      // 搜索用户
      clientList: [],
      searchClientLoading: false,

      // 搜索车型列表
      carModelList: [],
      searchCarModelLoading: false,

      // 施工技师列表
      techniciansList: [],

      // 业务员列表
      salesmanList: [],

      // 搜索开票主体
      invoiceList: [],
      searchInvoiceLoading: false,

      // 服务分类列表
      serviceClassList: [],

      //批量添加服务抽屉可见性
      multipleSelectServicesDrawerVisibility: false,
      // 已选择服务
      selectedServices: [],

      // 单据类型
      selectedBillType: '',

      // 列表数据
      tableData: [],

      // 统计数据
      statisticData: {},

      // 导出弹窗可见性
      showExportDialog: false,
      // 导出路径
      exportUrl: exportItemGrossProfitListUrl,

      exportForm: {},
    };
  },
  watch: {
    selectedBillType() {
      this.getData();
    },
    showExportDialog(val) {
      if (val) {
        let merge = {
          billType: this.selectedBillType,
          checkStartTime: this.form?.checkDateRange?.[0] || '',
          checkEndTime: this.form?.checkDateRange?.[1] || '',
          intoStartDate: this.form?.intoStoreDateRange?.[0] || '',
          intoEndDate: this.form?.intoStoreDateRange?.[1] || '',
        };

        this.exportForm = Object.assign({}, this.form, merge);
      }
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      companyId: (state) => state.user.companyId,
    }),
  },

  created() {
    this.initFilterForm();
    this.getTechniciansList();
    this.getSalesmanList();
    this.searchInvoice();
    this.getServiceClassList();
    this.getData();
  },
  methods: {
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getData(false);
    },

    /**
     * 加载数据
     */
    getData(reset = true) {
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      let merge = {
        billType: this.selectedBillType,
        checkStartTime: this.form?.checkDateRange?.[0] || '',
        checkEndTime: this.form?.checkDateRange?.[1] || '',
        intoStartDate: this.form?.intoStoreDateRange?.[0] || '',
        intoEndDate: this.form?.intoStoreDateRange?.[1] || '',
      };
      let data = Object.assign({}, this.pageOption, this.form, merge);
      itemGrossProfitList(data).then((res) => {
        console.log(res);
        // 修改总条数
        this.total = res.marginDetailOnProjectVo?.total;
        // 赋值records
        this.tableData = res.marginDetailOnProjectVo?.records;
      });
      itemGrossProfitTotal(data).then((res) => {
        this.statisticData = res;
      });
    },
    // 表格自定义合计方法
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 17) {
          sums[index] = '合计';
          return;
        }
        if ([18, 19, 20, 21].includes(index)) {
          switch (index) {
            case 18:
              sums[index] = toThousands(this.statisticData.projectTotals);
              break;
            case 19:
              sums[index] = toThousands(this.statisticData.costTotals);
              break;
            case 20:
              sums[index] = toThousands(this.statisticData.marginTotal);
              break;
            case 21:
              sums[index] = toThousands(this.statisticData.contractTotal);
              break;
          }
        }
      });

      return sums;
    },

    // 搜索接车员列表
    searchAdminJobListUser(keyWord = '') {
      this.searchAdminJobListUserLoading = true;
      getUserList(keyWord)
        .then((res) => {
          this.adminJobListUserList = res.records || [];
        })
        .finally(() => {
          this.searchAdminJobListUserLoading = false;
        });
    },

    // 搜索业务来源列表
    searchBillSource(keyWord = '') {
      this.searchBillSourceLoading = true;
      getBillSourceTypeList({ name: keyWord })
        .then((res) => {
          this.billSourceList = res.records;
        })
        .finally(() => {
          this.searchBillSourceLoading = false;
        });
    },

    // 搜索客户列表
    searchClient(keyWord = '') {
      this.searchClientLoading = true;
      searchClient(keyWord)
        .then((res) => {
          this.clientList = res.records;
        })
        .finally(() => {
          this.searchClientLoading = false;
        });
    },

    // 搜索车型列表
    searchCarModel(key = '') {
      this.searchCarModelLoading = true;
      searchCarModel(key)
        .then((res) => {
          this.carModelList = res.records || [];
        })
        .finally(() => {
          this.searchCarModelLoading = false;
        });
    },

    // 获取服务分类列表
    getServiceClassList() {
      getServicesClasses(this.companyId, { noShowWaiting: true }).then(
        (res) => {
          this.serviceClassList = res || [];
        }
      );
    },

    // 获取技师列表
    getTechniciansList() {
      getTechniciansList(this.companyId).then((res) => {
        this.techniciansList = res || [];
      });
    },

    // 获取业务员列表
    getSalesmanList() {
      getSalesmanList(this.companyId).then((res) => {
        this.salesmanList = res || [];
      });
    },

    // 搜索开票主体门店列表
    searchInvoice(keyWord = '') {
      this.searchInvoiceLoading = true;
      searchInvoice(keyWord, this.companyId)
        .then((res) => {
          this.invoiceList = res;
        })
        .finally(() => {
          this.searchInvoiceLoading = false;
        });
    },

    // 选择服务项目
    selectServicesList(value) {
      this.selectedServices = value;
      this.form.serviceItemsIds = this.selectedServices.map(
        (item) => item.serviceItemId
      );
    },
    // 清空已选择服务
    clearSelectedService() {
      this.selectedServices = [];
      this.form.serviceItemsIds = [];
    },
    /**
     * 跳转详情页
     */
    goDetail(row) {
      this.$router.push({
        name: 'carBillDetail',
        query: { id: row.billId },
      });
    },
    /**
     * 初始化表单
     */
    initFilterForm() {
      this.form = {
        // 操作门店
        // operateCompanyId: [this.companyId],
        // 进店日期
        intoStoreDateRange: [],
        // 车单结账日期
        checkDateRange: [
          new Date().Format('yyyy-MM-dd'),
          new Date().Format('yyyy-MM-dd'),
        ],
      };
      this.form.operateCompanyId = [this.companyId];
    },
    /**
     * 清除筛选表单
     */
    resetFilterForm() {
      this.form = {
        // 操作门店
        operateCompanyId: [],
        // 进店日期
        intoStoreDateRange: [],
        // 车单结账日期
        checkDateRange: [],
      };
      this.selectedServices = [];
    },
  },
};
</script>
<style lang="scss" scoped>
// 隐藏滚动条
.custom-table ::v-deep .el-table__body-wrapper {
  &::-webkit-scrollbar {
    display: none;
  }
  // IE
  -ms-overflow-style: none;
}
.choice-btn {
  width: 80px;
  height: 31px;
  background: rgba(66, 187, 184, 0.1);
  border-radius: 4px;
  font-size: 12px;
  color: #42bbb8;
  text-align: center;
  .del-icon {
    position: absolute;
    top: -8px;
    right: 0px;
  }
}

.service {
  background: rgba(255, 146, 0, 0.1);
  color: $orange;
}

.custom-tabs {
  display: flex;
  .custom-tab-item {
    padding: 12px 16px;
    border-radius: 4px 4px 0 0;
    border: 1px solid #eee;
    border-bottom: none;
    cursor: pointer;
    &.active,
    &:hover {
      color: $primary;
      background: $bg;
    }
  }
  &.nopadding .custom-tab-item {
    margin-right: 10px;
  }
}
</style>
