<template>
  <div id="img">
    <img
      v-if="base64"
      style="width: 300px; height: 150px; z-index: -1"
      :src="base64"
    />
  </div>
</template>
<script>
import { handleSave } from '@/utils/fileApi';

export default {
  name: 'sealDemo',
  components: {},
  props: {
    businessSeal: {
      type: [Number, String],
      default: '',
    },
    companyName: {
      type: String,
      default: '',
    },
    tableList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      base64: '',
      defaultCompanyName: 'XXX有限公司',
      name: 'XXX有限公司',
    };
  },
  watch: {
    companyName() {
      this.handleWatchValuChange();
    },
    businessSeal() {
      this.handleWatchValuChange();
    },
  },
  created() {},
  methods: {
    handleWatchValuChange() {
      if (this.businessSeal == 0) {
        this.base64 = '';
      } else if (this.businessSeal == 1) {
        this.createSeal(this.name);
      } else {
        if (this.companyName == '') {
          this.createSeal(this.defaultCompanyName);
        } else {
          this.createSeal(this.companyName);
        }
      }
    },
    createSeal(name) {
      var canvas = document.createElement('canvas');
      var context = canvas.getContext('2d');

      var text = '业务专用章';
      var companyName = name;

      // 绘制印章边框
      var width = canvas.width / 2;
      var height = canvas.height / 2;
      context.lineWidth = 3;
      context.strokeStyle = '#f00';
      context.beginPath();
      context.arc(width, height, 70, 0, Math.PI * 2); //宽、高、半径
      context.stroke();

      //画五角星
      create5star(context, width, height, 15, 'rgba(255,0,0,0.7)', 0); //控制五角星大小

      // 绘制印章名称
      context.font = '12px 宋体';
      context.textBaseline = 'middle'; //设置文本的垂直对齐方式
      context.textAlign = 'center'; //设置文本的水平对对齐方式
      context.lineWidth = 1;
      context.strokeStyle = '#f00';
      context.strokeText(text, width, height + 40);

      // 绘制印章单位
      var count = companyName.length; // 字数
      context.translate(width, height); // 平移到此位置,
      if (count > 15) {
        context.font = '15px 宋体';
      } else {
        context.font = '20px 宋体';
      }
      var angle = (4 * Math.PI) / (3 * (count - 1)); // 字间角度
      var chars = companyName.split('');
      var c;
      for (var i = 0; i < count; i++) {
        c = chars[i]; // 需要绘制的字符
        if (i == 0) {
          context.rotate((5 * Math.PI) / 6);
        } else {
          context.rotate(angle);
        }

        context.save();
        context.translate(55, 0); // 平移到此位置,此时字和x轴垂直，公司名称和最外圈的距离
        context.rotate(Math.PI / 2); // 旋转90度,让字平行于x轴
        context.strokeText(c, 0, 0); // 此点为字的中心点
        context.restore();
      }

      //绘制五角星
      function create5star(context, sx, sy, radius, color, rotato) {
        context.save();
        context.fillStyle = color;
        context.translate(sx, sy); //移动坐标原点
        context.rotate(Math.PI + rotato); //旋转
        context.beginPath(); //创建路径
        var x = Math.sin(0);
        var y = Math.cos(0);
        var dig = (Math.PI / 5) * 4;
        for (var i = 0; i < 5; i++) {
          //画五角星的五条边
          x = Math.sin(i * dig);
          y = Math.cos(i * dig);
          context.lineTo(x * radius, y * radius);
        }
        context.closePath();
        context.stroke();
        context.fill();
        context.restore();
      }
      this.base64 = canvas.toDataURL('image/png', 1);
    },
  },
};
</script>
<style lang="scss" scoped></style>
