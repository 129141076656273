<template>
  <div class="container">
    <div class="left">
      <div class="content">
        <el-form :model="form" label-width="100px" size="small">
          <el-row>
            <el-col>
              <el-form-item>
                <template slot="label">
                  <div>
                    操作门店
                    <el-tooltip
                      effect="light"
                      content="接车单的操作门店,查看范围(当前登录门店+管辖组)"
                      placement="bottom-start"
                    >
                      <i
                        class="iconfont grow-icon_doubt"
                        style="color: #9a9a9a"
                      ></i>
                    </el-tooltip>
                  </div>
                </template>
                <operateCompanyCascader
                  v-model="form.operateCompanyId"
                  :operateCompanyUrl.sync="form.operateCompanyUrl"
                  @input="change"
                ></operateCompanyCascader>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="flex" style="margin-top: 10px; width: 100%">
          <!-- 左边据 -->
          <div class="process-sty" style="width: 84%">
            <div class="flex index-path">
              <div class="path-item">
                <div class="flex">
                  <el-button class="editCar">开单</el-button>
                  <div class="path-arr"></div>
                </div>
                <div class="path-tip">
                  <p>
                    今日进店： {{ allDetail.todayEnterSum }}台 &nbsp;&nbsp;{{
                      allDetail.todayBillSum
                    }}单
                  </p>
                </div>
              </div>
              <div class="path-item">
                <div class="flex">
                  <el-button @click="goCarList(2)"> 报价</el-button>
                  <div class="path-arr"></div>
                </div>

                <div class="path-tip">
                  <p class="path-tip-left">
                    服务中：{{ allDetail.serviceSum }} &nbsp;

                    <!--报价待审： {{ offerDetail.reportedUnauditedSum }} -->
                  </p>
                  <!-- <p>
                  报价已审：
                  {{ offerDetail.lossReportedSum }}
                </p> -->
                </div>
              </div>
              <div class="path-item">
                <div class="flex">
                  <el-button @click="goCarList(3)"> 提交施工</el-button>
                  <div class="path-arr"></div>
                </div>

                <div class="path-tip">
                  <p class="path-tip-left">
                    待提交：{{ allDetail.noConstructionSum }}
                    <!-- 未提车： {{ allDetail.notExtractSum }}-->
                  </p>
                </div>
              </div>
              <div class="path-item">
                <div class="flex">
                  <el-button @click="goCarList(4)">结账</el-button>
                  <div class="path-arr"></div>
                </div>
                <div class="path-tip">
                  <p>
                    待结账：{{ allDetail.toPaySum }} &nbsp; 提前提车：{{
                      allDetail.alreadyExtractSum
                    }}
                  </p>
                </div>
              </div>
              <div class="path-item">
                <div>
                  <el-button @click="goCarList(5)">提车</el-button>
                </div>
                <div class="path-tip">
                  <p class="path-tip-left">
                    今日结账：{{ allDetail.todayFinishedSum }}
                  </p>
                </div>
              </div>
            </div>
            <div style="width: 100%" class="flex">
              <div style="width: 60%; margin-left: 4.2%; height: 20px">
                <p class="process-line-two"></p>
                <p class="process-line-three"></p>
                <p class="process-arrow"></p>
                <p class="process-line-one"></p>
              </div>
            </div>
            <div class="flex index-path">
              <div class="path-item">
                <div class="flex">
                  <el-button @click="goWorkshopBill(1)"> 派工 </el-button>
                  <div class="path-arr"></div>
                </div>
                <div class="path-tip">
                  <p class="path-tip-left">
                    待派工：{{ constructionDetail.staySendSum }}
                  </p>
                </div>
              </div>
              <div class="path-item">
                <div class="flex">
                  <el-button @click="goOldPage(1)"> 领料 </el-button>
                  <div class="path-arr"></div>
                </div>
                <div class="path-tip">
                  <p class="path-tip-left">
                    待领料：{{ billOtherCount.waitPicking }}
                  </p>
                </div>
              </div>
              <div class="path-item">
                <div class="flex">
                  <el-button @click="goWorkshopBill(3)"> 项目完工 </el-button>
                  <div class="path-arr"></div>
                </div>
                <div class="path-tip">
                  <p class="path-tip-left">
                    施工中：{{ constructionDetail.inConstructionSum }}
                  </p>
                </div>
              </div>
              <div class="path-item">
                <div class="flex">
                  <el-button @click="goWorkshopBill(4)"> 质检 </el-button>
                  <div class="path-arr"></div>
                </div>
                <div class="path-tip">
                  <p class="path-tip-left">
                    质检中: {{ constructionDetail.qualitySum }}
                  </p>
                </div>
              </div>
              <div class="path-item">
                <div>
                  <el-button @click="goWorkshopBill(5)"> 车间完工 </el-button>
                </div>
                <div class="path-tip">
                  <p class="path-tip-left">
                    今日完工：{{ constructionDetail.todayCompletedSum }}
                  </p>
                </div>
              </div>
            </div>
            <div class="flex">
              <div style="width: 100%" class="flex">
                <div style="width: 20%; margin-left: 24%">
                  <p class="process-arrow1" style="margin-left: -4px"></p>
                  <p
                    class="process-line-one"
                    style="height: 10px; margin-left: 1px; margin-top: 0px"
                  ></p>
                  <p
                    class="process-line-two"
                    style="margin-left: 1px; width: 98%; margin-top: 0px"
                  ></p>
                  <p
                    class="process-line-three"
                    style="
                      margin-left: 98%;
                      height: 10px;
                      margin-top: 0px;
                      width: 1.5px;
                    "
                  ></p>
                </div>
              </div>
            </div>
            <div class="flex index-path">
              <div class="path-item">
                <div class="flex">
                  <el-button @click="goOldPage(2)"> 采购 </el-button>
                  <div class="path-arr"></div>
                </div>
                <div class="path-tip">
                  <p class="path-tip-left">
                    待采购：
                    {{ billOtherCount.waitPurchase }}
                  </p>
                </div>
              </div>
              <div class="path-item">
                <div class="flex">
                  <el-button @click="goOldPage(3)"> 入库 </el-button>
                  <div class="path-arr"></div>
                </div>
                <div class="path-tip">
                  <p class="path-tip-left">
                    待入库：
                    {{ billOtherCount.waitInventoryIn }}
                  </p>
                </div>
              </div>
              <div class="path-item">
                <div class="flex">
                  <el-button @click="goOldPage(4)"> 库存 </el-button>
                </div>
              </div>

              <div style="width: 43%"></div>
            </div>
          </div>
          <!-- 右边数据 -->
          <div class="state-but" style="width: 16%">
            <div>
              <p class="btn-sty hover" @click="goCarList(6)">
                已逾交车 {{ allDetail.overdueCarSum }}
              </p>
              <p class="btn-tip" @click="goCarList(7)">
                今日预交 {{ allDetail.todayPaySum }}
              </p>
            </div>
            <div>
              <p class="btn-sty hover" @click="goPresentCar(1)">
                今日在场 {{ todayPresent.todaySum }}
              </p>
              <p class="btn-tip">
                滞留7天以上：{{ todayPresent.retentionSum }}
              </p>
            </div>
            <div @click="goCarList(8)" style="line-height: 56px">
              <p class="hover">挂单中 {{ allDetail.pendingOrderSum }}</p>
            </div>
            <div v-if="isHeadquarters">
              <p class="btn-sty hover" @click="goAgreementList(1)">
                临期协议 {{ agreementDetail.toBeDueCount }}
              </p>
              <p class="btn-tip" @click="goAgreementList(2)">
                已过期协议：{{ agreementDetail.hasDueCount }}
              </p>
            </div>
            <!-- <div @click="goPresentCar(2)">
            滞留车辆 {{ todayPresent.yesterdayStrandedNums }}
          </div>
          <div @click="goCarList(5)">今日预交 {{ allDetail.todayPaySum }}</div>
          <div @click="goCarList(6)">
            已逾交车 {{ allDetail.overdueCarSum }}
          </div>
          <div @click="goCarList(7)">
            挂单中 {{ allDetail.pendingOrderSum }}
          </div>
          <div @click="goAgreementList" v-if="isHeadquarters">
            临期协议 {{ agreementDetail.toBeDueCount }}
          </div> -->
          </div>
        </div>
      </div>
      <div class="turnoverTarget">
        <div class="title flex-x-between">
          <div class="flex">
            营业额
            <p class="tip-word color666">
              <i
                class="el-icon-warning orange"
                style="font-size: 14px; margin-right: 5px"
              ></i>
              数据更新于{{ new Date().Format('yyyy/MM/dd hh:mm:ss') }}
            </p>
          </div>
          <div>
            <el-button
              v-auth="537"
              type="text"
              class="btn-view-target"
              @click="viewTarget"
              >查看目标
            </el-button>
          </div>
        </div>
        <div class="target-box">
          <div class="target-list">
            <div class="target-item">
              <div class="top">
                <div class="label">总营业额</div>
                <div class="flex-center">
                  <div
                    :class="{
                      rate: true,
                      waring: turnoverData.totalTurnoverWarning,
                    }"
                  >
                    {{ turnoverData.totalPercentageComplete }}%
                  </div>
                  <circularProgressBar
                    :percentage="
                      Math.min(turnoverData.totalPercentageComplete, 100)
                    "
                    :width="28"
                    :strokeWidth="2"
                    :color="
                      turnoverData.totalTurnoverWarning ? '#E84C4C' : '#33AB79'
                    "
                  >
                    <img
                      :src="turnoverData.totalResponsiblePic || defaultAvatar"
                    />
                  </circularProgressBar>
                </div>
              </div>
              <div class="money">
                {{ turnoverData.totalTurnover | toThousands }}
              </div>
              <div class="target-number">
                目标：
                <template
                  v-if="![null, undefined].includes(turnoverData.totalTarget)"
                  >{{
                    (turnoverData.totalTarget / 10000) | toThousands(4)
                  }}万元</template
                >
                <template v-else>暂未设置</template>
              </div>
              <div class="target-number">
                毛利率：
                <span
                  :style="{
                    color: turnoverData.totalGrossProfitRateWarning
                      ? '#E84C4C'
                      : '',
                  }"
                  >{{ turnoverData.totalGrossProfitRate }}%</span
                >
              </div>
            </div>
            <div class="target-item">
              <div class="top">
                <div class="label" style="color: #ee8f33">自费</div>
                <div class="flex-center">
                  <div
                    :class="{
                      rate: true,
                      waring: turnoverData.selfPayTurnoverWarning,
                    }"
                  >
                    {{ turnoverData.selfPayPercentageComplete }}%
                  </div>
                  <circularProgressBar
                    :percentage="
                      Math.min(turnoverData.selfPayPercentageComplete, 100)
                    "
                    :width="28"
                    :strokeWidth="2"
                    :color="
                      turnoverData.selfPayTurnoverWarning
                        ? '#E84C4C'
                        : '#33AB79'
                    "
                  >
                    <img
                      :src="turnoverData.selfPayResponsiblePic || defaultAvatar"
                    />
                  </circularProgressBar>
                </div>
              </div>
              <div class="money">
                {{ turnoverData.selfPayTurnover | toThousands }}
              </div>
              <div class="target-number">
                目标：<template
                  v-if="![null, undefined].includes(turnoverData.selfPayTarget)"
                  >{{
                    (turnoverData.selfPayTarget / 10000) | toThousands(4)
                  }}万元</template
                >
                <template v-else>暂未设置</template>
              </div>
              <div class="target-number">
                毛利率：
                <span
                  :style="{
                    color: turnoverData.selfPayGrossProfitRateWarning
                      ? '#E84C4C'
                      : '',
                  }"
                  >{{ turnoverData.selfPayGrossProfitRate }}%</span
                >
              </div>
            </div>
            <div class="target-item">
              <div class="top">
                <div class="label" style="color: #13afc4">
                  包干
                  <el-tooltip
                    effect="light"
                    content="包干金额为分摊后实际产值金额，非单据金额"
                    placement="bottom-start"
                  >
                    <i
                      class="iconfont grow-icon_doubt"
                      style="color: #9a9a9a"
                    ></i>
                  </el-tooltip>
                </div>
                <div class="flex-center">
                  <div
                    :class="{
                      rate: true,
                      waring: turnoverData.contractForTurnoverWarning,
                    }"
                  >
                    {{ turnoverData.contractForPercentageComplete }}%
                  </div>
                  <circularProgressBar
                    :percentage="
                      Math.min(turnoverData.contractForPercentageComplete, 100)
                    "
                    :width="28"
                    :strokeWidth="2"
                    :color="
                      turnoverData.contractForTurnoverWarning
                        ? '#E84C4C'
                        : '#33AB79'
                    "
                  >
                    <img
                      :src="
                        turnoverData.contractForResponsiblePic || defaultAvatar
                      "
                    />
                  </circularProgressBar>
                </div>
              </div>
              <div class="money">
                {{ turnoverData.contractForTurnover | toThousands }}
              </div>
              <div class="target-number">
                目标：<template
                  v-if="
                    ![null, undefined].includes(turnoverData.contractForTarget)
                  "
                  >{{
                    (turnoverData.contractForTarget / 10000) | toThousands(4)
                  }}万元</template
                >
                <template v-else>暂未设置</template>
              </div>
              <div class="target-number">
                毛利率：
                <span
                  :style="{
                    color: turnoverData.contractForGrossProfitRateWarning
                      ? '#E84C4C'
                      : '',
                  }"
                  >{{ turnoverData.contractForGrossProfitRate }}%</span
                >
              </div>
            </div>
            <div class="target-item">
              <div class="top">
                <div class="label" style="color: #1e86eb">理赔</div>
                <div class="flex-center">
                  <div
                    :class="{
                      rate: true,
                      waring: turnoverData.claimSettlementTurnoverWarning,
                    }"
                  >
                    {{ turnoverData.claimSettlementPercentageComplete }}%
                  </div>
                  <circularProgressBar
                    :percentage="
                      Math.min(
                        turnoverData.claimSettlementPercentageComplete,
                        100
                      )
                    "
                    :width="28"
                    :strokeWidth="2"
                    :color="
                      turnoverData.claimSettlementTurnoverWarning
                        ? '#E84C4C'
                        : '#33AB79'
                    "
                  >
                    <img
                      :src="
                        turnoverData.claimSettlementResponsiblePic ||
                        defaultAvatar
                      "
                    />
                  </circularProgressBar>
                </div>
              </div>
              <div class="money">
                {{ turnoverData.claimSettlementTurnover | toThousands }}
              </div>
              <div class="target-number">
                目标：<template
                  v-if="
                    ![null, undefined].includes(
                      turnoverData.claimSettlementTarget
                    )
                  "
                  >{{
                    (turnoverData.claimSettlementTarget / 10000)
                      | toThousands(4)
                  }}万元</template
                >
                <template v-else>暂未设置</template>
              </div>
              <div class="target-number">
                毛利率：
                <span
                  :style="{
                    color: turnoverData.claimSettlementGrossProfitRateWarning
                      ? '#E84C4C'
                      : '',
                  }"
                  >{{ turnoverData.claimSettlementGrossProfitRate }}%</span
                >
              </div>
            </div>
            <div class="target-item">
              <div class="top">
                <div class="label" style="color: #1c0d8e">索赔</div>
                <div class="flex-center">
                  <div
                    :class="{
                      rate: true,
                      waring: turnoverData.claimForCompensationTurnoverWarning,
                    }"
                  >
                    {{ turnoverData.claimForCompensationPercentageComplete }}%
                  </div>
                  <circularProgressBar
                    :percentage="
                      Math.min(
                        turnoverData.claimForCompensationPercentageComplete,
                        100
                      )
                    "
                    :width="28"
                    :strokeWidth="2"
                    :color="
                      turnoverData.claimForCompensationTurnoverWarning
                        ? '#E84C4C'
                        : '#33AB79'
                    "
                  >
                    <img
                      :src="
                        turnoverData.claimForCompensationResponsiblePic ||
                        defaultAvatar
                      "
                    />
                  </circularProgressBar>
                </div>
              </div>
              <div class="money">
                {{ turnoverData.claimForCompensationTurnover | toThousands }}
              </div>
              <div class="target-number">
                目标：<template
                  v-if="
                    ![null, undefined].includes(
                      turnoverData.claimForCompensationTarget
                    )
                  "
                  >{{
                    (turnoverData.claimForCompensationTarget / 10000)
                      | toThousands(4)
                  }}万元</template
                >
                <template v-else>暂未设置</template>
              </div>
              <div class="target-number">
                毛利率：
                <span
                  :style="{
                    color:
                      turnoverData.claimForCompensationGrossProfitRateWarning
                        ? '#E84C4C'
                        : '',
                  }"
                  >{{ turnoverData.claimForCompensationGrossProfitRate }}%</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 公告 -->
    <div class="right announcement">
      <div class="common">
        <div class="operation-sty" style="min-height: 189px">
          <p class="title" style="text-indent: 16px">常用操作</p>
          <div style="margin: 10px 10px 0 18px; display: flex; flex-wrap: wrap">
            <div class="commonly-btn" @click="editCarBill(1)">自费开单</div>
            <div class="commonly-btn" @click="editCarBill(2)">包干开单</div>
            <div class="commonly-btn" @click="editCarBill(3)">理赔开单</div>
            <div class="commonly-btn" @click="editCarBill(4)">索赔开单</div>
          </div>
        </div>
        <div class="operation-sty" style="flex: 1">
          <div class="flex-x-between title">
            <p style="text-indent: 16px; margin-top: 16px">定损单</p>
            <p style="width: 55%; margin-right: 5px; margin-top: 16px">
              <operateCompanyCascader
                v-model="operateCompanyId"
                :operateCompanyUrl.sync="operateCompanyUrl"
                @input="getCarLossPageStatistics"
                style="width: 100% !important"
              ></operateCompanyCascader>
            </p>
          </div>
          <div style="margin: 20px 10px 0 18px; display: flex; flex-wrap: wrap">
            <div class="stateNum" @click="goCarLossList(1)">
              <p>{{ carLossPageStates.overdueSubmissionSum }}</p>
              <p>提报超期</p>
            </div>
            <div class="stateNum" @click="goCarLossList(2)">
              <p>{{ carLossPageStates.overduePartsSum }}</p>
              <p>对件超期</p>
            </div>
            <div class="stateNum" @click="goCarLossList(3)">
              <p>{{ carLossPageStates.overdueLossSum }}</p>
              <p>定损超期</p>
            </div>
            <div class="stateNum" @click="goCarLossList(4)">
              <p>{{ carLossPageStates.overdueCollectionSum }}</p>
              <p>回款超期</p>
            </div>
          </div>
          <div class="follow-progress-box">
            <div
              v-for="(item, index) in followUpStateList"
              :key="item.value"
              class="follow-progress-item"
              @click="goCarLossListByStatus(index)"
            >
              <el-tooltip :content="item.tooltipText" placement="top">
                <div :style="item.iconStyle">{{ item.iconText }}</div>
              </el-tooltip>
              <span
                :style="{
                  color: item.iconStyle.background,
                  marginLeft: '5px',
                }"
                >{{
                  carLossPageStates[
                    [
                      'fastDisposeLossNum',
                      'secondaryDisposeLossNum',
                      'toBeFollowedLossNum',
                      'insuranceCompanyToBeFollowedLossNum',
                    ][index]
                  ]
                }}</span
              >
            </div>
            <div>
              <el-tooltip
                effect="light"
                content="当接车单状态为【已结账】对应定损单状态为【待报损、拍照对件、提交定损】则为已结未出损"
                placement="top-start"
              >
                <i class="iconfont grow-icon_doubt" style="color: #9a9a9a"></i>
              </el-tooltip>
              <span style="font-size: 14px; margin-left: 5px"
                >已结未出损：</span
              >
              <span
                style="
                  margin-left: 5px;
                  font-size: 14px;
                  color: #1e86eb;
                  cursor: pointer;
                "
                @click="goCarLossListByStatus"
                >{{ carLossPageStates.finishedNoLossNum }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="loss-sty"></div> -->
    </div>

    <set-turnover-target-dialog
      v-if="setTurnoverDialog"
      :visibility.sync="setTurnoverDialog"
      :companyId="companyId"
      :companyName="companyName"
      :isEdit="editTurnoverTarget"
      :lastMonth="emptyTarget"
      @change="changeTurnoverTarget"
    />
    <el-dialog
      title="查看营业额目标"
      :visible.sync="showNotSetDialog"
      width="400"
    >
      <p style="height: 100px; text-align: center; padding-top: 16px">
        当月目标暂未设置，请先设置
      </p>
      <template slot="footer">
        <el-button size="small" @click="showNotSetDialog = false"
          >取 消</el-button
        >
        <el-button
          size="small"
          v-auth="538"
          type="primary"
          @click="handleSetTarget"
          >设置目标</el-button
        >
      </template>
    </el-dialog>
  </div>
</template>

<script>
//接口
import {
  billCarBillStatistic,
  carLossDeterminationBill,
  agreementClient,
  carStatistics,
  carBillOtherCount,
  dispatchPageStatistics,
  pageStatistics,
} from '@/api/wel/index';
import { selectOneCompanyTurnoverTarget } from '@/api/commandCenter';
import { getCarBillStatistic } from '@/api/financial/pickSingle';
import { getCompanyTurnoverTarget } from '@/api/commandCenter';

//旧系统请求前缀
import { oldServerUrl } from '@/config/compatibilityOlderSystems';
import {
  admin1PagePrefix,
  admin2PagePrefix,
} from '@/config/compatibilityOlderSystems';

import { hasAuth } from '@/utils/permissions';
import { MessageBox } from 'element-ui';
import { mapState, mapGetters } from 'vuex';
import axios from 'axios';
import bus from '@/service/bus';

// 字典
import { followUpStateList } from '@/service/dict/dictData';

// 组件
import operateCompanyCascader from '@/components/businessComponent/operateCompanyCascader';
import setTurnoverTargetDialog from '@/components/businessComponent/setTurnoverTargetDialog.vue';
import circularProgressBar from '@/components/circularProgressBar.vue';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'wel',
  components: {
    operateCompanyCascader,
    setTurnoverTargetDialog,
    circularProgressBar,
  },
  data() {
    return {
      form: {},
      allDetail: {},
      offerDetail: {},
      agreementDetail: {},
      todayPresent: {},
      billOtherCount: {},
      //旧系统的数据
      waitPicking: '',
      waitPurchase: '',
      waitInventoryIn: '',
      constructionDetail: [], // 施工流程的数据

      /*  营业额目标相关模块 */
      // 弹窗显示
      setTurnoverDialog: false,

      setStoreScopeDialog: false,

      // 营业目标数据
      turnoverData: {},
      // 默认头像
      defaultAvatar: require('@/assets/img/defaultAvatar.png'),

      // 未设置目标弹窗
      showNotSetDialog: false,
      // 是否编辑营业额
      editTurnoverTarget: false,
      // 是否未设置目标
      emptyTarget: false,
      //定损单模块的门店
      operateCompanyId: [],
      operateCompanyUrl: [],
      carLossPageStates: {},
      // 跟进进展字典
      followUpStateList,
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.companyId,
      companyName: (state) => state.user.companyName,
    }),
    ...mapGetters(['isHeadquarters']),
  },
  watch: {
    companyId() {
      this.getCompanyTurnoverTarget();
    },
  },
  created() {
    bus.$on('switchCompany', this.init);
  },
  beforeDestroy() {
    bus.$off('switchCompany', this.init);
  },
  mounted() {
    this.init();
  },
  methods: {
    change(val) {
      this.getList();
    },
    init() {
      this.form.operateCompanyId = [this.companyId];
      this.form.operateCompanyUrl = [this.companyId];
      this.operateCompanyId = [this.companyId];
      this.operateCompanyUrl = [this.companyId];
      this.getList();
      this.getCompanyTurnoverTarget();
      this.getCarLossPageStatistics();
    },
    //获取数据
    getList() {
      let date = new Date();
      var data = {
        operateCompanyId: this.form.operateCompanyId,
        startTime: '2020-01-01 00:00:00',
        endTime: date.Format('yyyy-MM-dd 00:00:00'),
        dispatchStartTime: new Date(new Date().setDate(1)).Format('yyyy-MM-dd'),
        dispatchEndTime: new Date().Format('yyyy-MM-dd'),
      };
      billCarBillStatistic(data).then((res) => {
        this.allDetail = res;
      });
      // carLossDeterminationBill(data).then((res) => {
      //   this.offerDetail = res;
      // });
      agreementClient(data).then((res) => {
        this.agreementDetail = res;
      });
      carStatistics(data).then((res) => {
        this.todayPresent = res;
      });
      carBillOtherCount(data).then((res) => {
        this.billOtherCount = res;
      });
      dispatchPageStatistics(data).then((res) => {
        this.constructionDetail = res;
      });
      // getCarBillStatistic(data).then((res) => {
      //   let { ...otherData } = res;
      //   this.constructionDetail = otherData;
      // });
      //旧系统接口请求
      // this.oldTotalData();
    },
    //点击接待流程的权限判断事件
    goCarList(val) {
      let billState = ' ';
      let dispatchState = '';
      let nodeKey = '';
      let date = new Date();
      if (val == 1) {
        // 开单  (存在当前操作门店+进店日期为今日  状态是全部)
        billState = 22;
        dispatchState = '';
        nodeKey = 22;
      } else if (val == 2) {
        //报价 (存在当前操作门店  状态是服务中)
        billState = 0;
        dispatchState = '';
        nodeKey = 0;
      } else if (val == 3) {
        //提交施工  (存在当前操作门店  状态是待结账)
        billState = -1;
        dispatchState = 4;
        nodeKey = 3;
      } else if (val == 4) {
        //结账  (存在当前操作门店+结账日期为今日  状态是待结账)
        billState = 2;
        dispatchState = '';
        nodeKey = 2;
      } else if (val == 5) {
        //提车  (存在当前操作门店  状态是已结账)
        billState = 1;
        dispatchState = '';
        nodeKey = 1;
      } else if (val == 6) {
        //已逾交车  (存在当前操作门店+预交车时间  状态是服务中)
        billState = 33;
        dispatchState = '';
        nodeKey = 33;
      } else if (val == 7) {
        //今日预交
        billState = 44;
        dispatchState = '';
        nodeKey = '';
      } else if (val == 8) {
        //挂单中  (存在当前操作门店  状态是挂单中)
        billState = 4;
        dispatchState = '';
        nodeKey = 4;
      }
      let result = hasAuth(35);
      if (!result) {
        MessageBox.alert(
          '无车单记录>接车单的权限，请联系超级用户设置角色权限！',
          '提示'
        );
        return;
      } else {
        this.$router.push({
          name: 'carBillList',
          params: {
            forms: {
              operateCompanyId: this.form.operateCompanyId,
              operateCompanyUrl: this.form.operateCompanyUrl,
              intoStoreTimes: [
                new Date().Format('yyyy-MM-dd'),
                new Date().Format('yyyy-MM-dd'),
              ],
              billState: billState,
              dispatchState: dispatchState,
              nodeKey: nodeKey,
              checkStartTimes: [
                new Date().Format('yyyy-MM-dd'),
                new Date().Format('yyyy-MM-dd'),
              ],
              // acceptingTheCartTime: [
              //   new Date(date.getTime() - 864e5 * 30).Format(
              //     'yyyy-MM-dd hh:mm:ss'
              //   ),
              //   new Date(date.getTime() - 864e5 * 1).Format(
              //     'yyyy-MM-dd  23:59:59'
              //   ),
              // ],
            },
          },
        });
      }
    },
    //点击施工流程的权限判断事件
    goWorkshopBill(val) {
      let dispatchType = '';
      if (val == 1) {
        dispatchType = 4;
      } else if (val == 3) {
        dispatchType = 0;
      } else if (val == 4) {
        dispatchType = 5;
      } else if (val == 5) {
        dispatchType = 1;
      }
      let result = hasAuth(94);
      if (!result) {
        MessageBox.alert(
          '无车间工单>施工单的权限，请联系超级用户设置角色权限！',
          '提示'
        );
        return;
      } else {
        this.$router.push({
          name: 'constructionBill',
          params: {
            dispatchType: dispatchType,
            form: {
              operateCompanyId: this.form.operateCompanyId,
              operateCompanyUrl: this.form.operateCompanyUrl,
            },
          },
        });
      }
    },
    //今日在场、滞留车辆权限判断
    goPresentCar() {
      let result = hasAuth(447);
      if (!result) {
        MessageBox.alert(
          '无在场车辆>今日在场车辆的权限，请联系超级用户设置角色权限！',
          '提示'
        );
        return;
      } else {
        this.$router.push({
          name: 'presentCar',
          params: {
            filterForm: {
              operateCompanyId: this.form.operateCompanyId,
              operateCompanyUrl: this.form.operateCompanyUrl,
            },
          },
        });
      }
    },
    //临期协议权限判断
    goAgreementList(val) {
      let result = hasAuth(474);
      if (!result) {
        MessageBox.alert(
          '无客户管理>自费（包干）协议的权限，请联系超级用户设置角色权限！',
          '提示'
        );
        return;
      } else {
        let date = new Date();
        let nodeKey;
        if (val == 1) {
          nodeKey = 1;
        } else if (val == 2) {
          nodeKey = 2;
        }
        this.$router.push({
          name: 'agreementList',
          params: {
            nodeKey: nodeKey,
            form: {
              // end: [
              //   date.Format('yyyy-MM-dd'),
              //   new Date(date.getTime() + 864e5 * 30).Format('yyyy-MM-dd'),
              // ],
            },
          },
        });
      }
    },
    //请求旧系统数据（待领料）
    oldTotalData() {
      axios
        .post(
          oldServerUrl + '/inventory/mainBoard',
          {},
          {
            noHandleError: true,
            noShowWaiting: true,
          }
        )
        .then((res) => {
          var oldData = res.data.resultObject;
          this.waitPicking = oldData.waitInventoryIn.num;
          this.waitPurchase = oldData.waitPurchase.num;
          this.waitInventoryIn = oldData.waitInventoryIn.num;
        });
    },
    //跳转老系统页面
    goOldPage(val) {
      //跳转到旧系统维修领料页面
      if (val == 1) {
        let path = `${admin2PagePrefix}#/stock/outOfStock/repairPicking?keepRoute=true`;
        window.open(path);
      } else if (val == 2) {
        let path = `${admin2PagePrefix}#/stock/purchaseWait?keepRoute=true`;
        window.open(path);
      } else if (val == 3) {
        let path = `${admin2PagePrefix}#/stock/purchaseList?keepRoute=true`;
        window.open(path);
      } else if (val == 4) {
        let path = `${admin2PagePrefix}#/stock/query/inventorySummary?keepRoute=true`;
        window.open(path);
      }
    },

    //常用操作
    editCarBill(val) {
      let result;
      var title = '';
      var path = '';
      if (val == 1) {
        result = hasAuth(466);
        title = '自费';
        path = '/carBill/editCarBill?type=0';
      } else if (val == 2) {
        result = hasAuth(467);
        title = '包干';
        path = '/carBill/editCarBill?type=1';
      } else if (val == 3) {
        result = hasAuth(468);
        title = '理赔';
        path = '/carBill/editCarBill?type=2';
      } else if (val == 4) {
        result = hasAuth(469);
        title = '索赔';
        path = '/carBill/editCarBill?type=3';
      }
      if (!result) {
        MessageBox.alert(
          '没有' + title + '开单的权限，请联系超级用户设置角色权限！',
          '提示'
        );
        return;
      } else {
        //跳转到自费开单页面
        this.$router.push({
          path: path,
        });
      }
    },

    /**
     * 获取营业额数据
     */
    getCompanyTurnoverTarget() {
      getCompanyTurnoverTarget(
        {
          targetType: 0,
          companyId: this.companyId || localStorage.getItem('companyId'),
          statisticsPeriod: new Date().Format('yyyy/MM'),
        },
        {
          noShowWaiting: true,
        }
      ).then((res) => {
        this.turnoverData = res || {};
      });
    },
    /**
     * 查看目标
     */
    viewTarget() {
      selectOneCompanyTurnoverTarget({
        companyId: this.companyId,
        statisticsPeriod: new Date().Format('yyyy/MM'),
      }).then((res) => {
        if ([undefined, null, ''].includes(res.totalTarget)) {
          this.emptyTarget = true;
          this.showNotSetDialog = true;
        } else {
          this.setTurnoverDialog = true;
        }
      });
    },
    /**
     * 处理设置目标
     */
    handleSetTarget() {
      this.showNotSetDialog = false;
      this.editTurnoverTarget = true;
      this.setTurnoverDialog = true;
    },
    /**
     * 设置营业目标
     */
    changeTurnoverTarget() {
      this.editTurnoverTarget = false;
      this.emptyTarget = false;
      this.getCompanyTurnoverTarget();
    },
    // 定损单接口
    getCarLossPageStatistics() {
      var data = {
        operateCompanyId: this.operateCompanyId,
      };
      pageStatistics(data).then((res) => {
        this.carLossPageStates = res;
      });
    },
    //跳转到定损单列表
    goCarLossList(value) {
      this.$router.push({
        name: 'carLossDeterminationBillList',
        params: {
          nodeKey: '',
          showMore: false,
          form: {
            operateCompanyId: this.operateCompanyId,
            operateCompanyUrl: this.operateCompanyUrl,
            remind: [value],
          },
        },
      });
    },
    /**
     * 跳转定损单列表 根据跟进进展及异常状态查询
     * @param {number} index
     */
    goCarLossListByStatus(index) {
      var extra = {};
      var showMore = false;
      if (index < 4) {
        extra = {
          followUpState: index,
        };
      } else {
        showMore = true;
        extra = {
          finishedNoLossState: 1,
        };
      }
      var form = this.$lodash.cloneDeep(
        Object.assign(
          {},
          {
            operateCompanyId: this.operateCompanyId,
            operateCompanyUrl: this.operateCompanyUrl,
          },
          extra
        )
      );
      this.$router.push({
        name: 'carLossDeterminationBillList',
        params: {
          nodeKey: '',
          showMore,
          form,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
// div {
//   height: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   img {
//     width: 30%;
//   }
// }
.container {
  display: flex;
  height: 100%;
  background: #f1f7fa;
  .left {
    width: 80%;
    display: flex;
    flex-direction: column;
    .content {
      // min-height: 35.7%;
      padding: 16px 30px 15px 30px;
      margin-right: 20px;
      background: #fff;
      border-radius: 4px 4px 4px 4px;
    }
    .turnoverTarget {
      flex: 1;
      margin: 16px 20px 16px 0;
      background: #fff;
      border-radius: 4px 4px 0px 0px;
      .title {
        height: 40px;
        // box-shadow: 0px 1px 0px 0px #dcdfe6;
        line-height: 40px;
        text-indent: 16px;
        display: flex;
        // font-size: 14px;
        // color: #242526;
      }
      .tip-word {
        font-size: 12px;
        color: #3c3d40;
      }
      .btn-view-target {
        font-size: 14px;
        font-weight: 400;
        color: #1e86eb;
        line-height: 20px;
        margin-right: 20px;
      }
      .target-box {
        padding: 16px;
      }
      .target-list {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
        .target-item {
          width: 19%;
          height: 100px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 12px;
          border-radius: 4px;
          border: 1px solid #f0f2f5;
          background: #ffffff;

          .top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .label {
              font-size: 14px;
              color: #3c3d40;
              font-weight: 500;
            }
            .rate {
              margin-right: 20px;
              font-weight: 400;
              font-size: 12px;
              color: #33ab79;
              &.waring {
                color: #ee8f33;
              }
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 50%;
            }
          }
          .money {
            font-size: 16px;
            font-family: Helvetica-Bold, Helvetica;
            font-weight: bold;
            color: #000000;
            line-height: 19px;
          }
          .target-number {
            font-weight: 400;
            color: #606266;
            font-size: 12px;
            line-height: 17px;
          }
        }
      }
    }
  }
  .announcement {
    width: 20%;
    display: flex;
    flex-direction: column;
    .operation-sty {
      background: #ffffff;
      // height: 198px;
      margin-bottom: 16px;
      border-radius: 4px 4px 4px 4px;
    }
    .common {
      height: 100%;
      display: flex;
      flex-direction: column;
      .title {
        width: 100%;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
      }
      .stateNum {
        width: 100%;
        max-width: 83px;
        height: 56px;
        margin-bottom: 16px;
        margin-right: 8px;
        border-radius: 4px;
        border: 1px solid #ebeef5;
        text-align: center;
        line-height: 25px;
        color: #18181a;
        font-size: 14px;
        font-weight: 400;
        &:hover {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #33ab79;
          cursor: pointer;
        }
      }
      .commonly-btn {
        width: 100%;
        max-width: 83px;
        height: 44px;
        margin: 0 8px 16px 0;
        border-radius: 4px;
        border: 1px solid #ebeef5;
        text-align: center;
        line-height: 44px;
        color: #18181a;
        font-size: 14px;
        font-weight: 400;
        &:hover {
          color: #33ab79;
          border: 1px solid #33ab79;
          cursor: pointer;
        }
      }
    }
  }
}

::v-deep.el-form-item--small.el-form-item {
  margin-left: -15px;
}

.process-sty {
  font-size: 14px;
  .process-line-one {
    height: 10px;
    width: 1px;
    background: #cccccc;
    margin-left: 65.9%;
    margin-top: -30px;
  }
  .process-line-two {
    height: 1px;
    width: 65.1%;
    background: #cccccc;
    margin-top: -5px;
    margin-left: 6px;
  }
  .process-line-three {
    height: 10px;
    width: 1px;
    background: #cccccc;
    margin-left: 6px;
  }
  .process-arrow {
    margin-left: 1px;
    width: 0;
    height: 0;
    border-top: 10px solid #cccccc;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
  }
  .process-arrow1 {
    margin-left: 54px;
    margin-top: -9px;
    width: 0;
    height: 0;
    border-bottom: 10px solid #cccccc;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
  }
}
.state-but {
  text-align: right;
  div {
    float: right;
    width: 100%;
    height: 56px;
    text-align: center;
    margin-bottom: 16px;
    // line-height: 44px;
    background: linear-gradient(180deg, #ffffff 0%, #e9f0fb 100%);
    border-radius: 4px;
    border: 1px solid #ebeef5;
    backdrop-filter: blur(28px);
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    white-space: nowrap;
    color: #303133;
    margin-bottom: 14px;

    .hover {
      &:hover {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #33ab79;
        cursor: pointer;
      }
    }
  }
  .btn-sty {
    margin-top: 10px;
  }
  .btn-tip {
    margin-top: 10px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #606266;
    &:hover {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #33ab79;
      cursor: pointer;
    }
  }
}

.line-arrow {
  margin: 20px 5px;
  div:nth-child(1) {
    width: 3vh;
    height: 1px;
    margin-top: 4px;
    background: #cccccc;
  }
  div:nth-child(2) {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 0 5px 10px;
    border-color: transparent transparent transparent #cccccc;
  }
}

//流程样式
.index-path {
  margin-bottom: 20px;
  overflow: hidden;
  .path-item {
    width: 22%;
    float: left;
    button {
      text-align: center !important;
      width: 80%;
      max-width: 92px;
      margin: 0;
      height: 46px;
      text-align: inherit;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #303133;
      &:hover {
        color: $primary;
        border: 1px solid $primary;
        cursor: pointer;
        background: #fff !important;
      }
    }
    .editCar {
      &:hover {
        color: #303133;
        border: 1px solid #dcdfe6;
        cursor: text;
        background: #fff !important;
      }
    }
  }
  .path-arr {
    flex-grow: 1;
    height: 1px;
    margin: 22px 5px;
    position: relative;
    background: #ccc;
    &:after {
      content: '';
      position: absolute;
      right: -3px;
      top: -4px;
      transform: rotate(91deg);
      width: 0;
      height: 0;
      border-bottom: 10px solid #cccccc;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
    }
  }
  .path-tip {
    display: flex;
    p {
      margin-top: 10px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #909399;
    }
  }
  .path-tip-left {
    margin-left: 5.4%;
  }
}
// .loss-sty {
//   border-radius: 4px 4px 4px 4px;
//   flex: 1;
//   background: #fff;
//   margin: 4px 0 16px 0;
// }
.follow-progress-box {
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px 10px 18px;
  line-height: 30px;
  .follow-progress-item {
    min-width: 60px;
    cursor: pointer;
  }
}
</style>
