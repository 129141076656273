var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-drawer",
    {
      attrs: {
        title: "注意事项",
        visible: _vm.remarksDrawerVisibility,
        size: "50%",
        "append-to-body": true,
        "before-close": _vm.handleClose,
        modal: "",
        "close-on-press-escape": "",
        wrapperClosable: "",
      },
    },
    [
      _c("div", { staticClass: "content" }, [
        _c("p", { staticClass: "pre-wrap" }, [
          _vm._v(_vm._s(_vm.clientRemark)),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }