import http from '@/service/http';

const defaultListOption = {
  current: 1,
  size: 10,
};

// 车单评价列表
export function getAppraisList(data = defaultListOption) {
  return http.post('/admin/car/bill/appraise/list', data);
}

// 导出
export const exportAppraiseUrl = '/admin/car/bill/appraise/export';

// 搜索评价标签
export function searchAppraiseTags(searchTag) {
  return http.post(
    '/admin/car/bill/appraise/searchAppraiseTags',
    {
      searchTag,
    },
    {
      noShowWaiting: true,
    }
  );
}
