<template>
  <div class="wrapper">
    <div class="table-wrap">
      <div class="export-btn-wrap" ref="exportRef">
        <el-button v-auth="798" class="export-btn" type="primary" @click="showExportDialog = true">导出</el-button>
      </div>
      <el-table :data="tableData" :height="tableHei" :header-cell-style="{ height: '32px', padding: '0!important' }" class="new-table">
        <el-table-column align="center" fixed label="操作门店" prop="companyName" show-overflow-tooltip width="220" />
        <el-table-column align="center" label="1个月以内">
          <el-table-column align="center" label="单量" prop="withinOneMonthBillCount" width="100" />
          <el-table-column align="center" label="金额" prop="withinOneMonthBillAmount" width="100" />
        </el-table-column>
        <el-table-column align="center" label="1-3个月">
          <el-table-column align="center" label="单量" prop="withinRangeOneToThreeMonthBillCount" width="100" />
          <el-table-column align="center" label="金额" prop="withinRangeOneToThreeMonthBillAmount" width="100" />
        </el-table-column>
        <el-table-column align="center" label="3-6个月">
          <el-table-column align="center" label="单量" prop="withinRangeThreeToSixMonthBillCount" width="100" />
          <el-table-column align="center" label="金额" prop="withinRangeThreeToSixMonthBillAmount" width="100" />
        </el-table-column>
        <el-table-column align="center" label="6-12个月">
          <el-table-column align="center" label="单量" prop="withinRangeSixToTwelveMonthBillCount" width="100" />
          <el-table-column align="center" label="金额" prop="withinRangeSixToTwelveMonthBillAmount" width="100" />
        </el-table-column>
        <el-table-column align="center" label="1年以上">
          <el-table-column align="center" label="单量" prop="moreThanOneYearBillCount" width="100" />
          <el-table-column align="center" label="金额" prop="moreThanOneYearBillAmount" width="100" />
        </el-table-column>
        <el-table-column align="center" label="小计">
          <el-table-column align="center" label="单量" prop="subTotalBillCount" width="100" />
          <el-table-column align="center" label="金额" prop="subTotalBillAmount" width="100" />
        </el-table-column>
        <el-table-column align="center" label="占比">
          <el-table-column align="center" label="单量" prop="subTotalBillCountRate" width="100" />
          <el-table-column align="center" label="金额" prop="subTotalBillAmountRate" width="100" />
        </el-table-column>
      </el-table>
    </div>

    <export-dialog
      :filterForm="form"
      :pageOption="pageOption"
      :setWid="'30%'"
      :total="total"
      :url="exportUrl"
      :visibility.sync="showExportDialog" />
  </div>
</template>

<script>
import { getAccountsSummaryExportUrl, getAccountsSummaryList } from "@/api/dataBoard";
import ExportDialog from "@/components/ExportDialog.vue";

export default {
  name: "AccountsSummary",
  components: { ExportDialog },
  data() {
    return {
      tableHei: "50vh",
      tableData: [],
      showExportDialog: false,
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      form: {},
      exportUrl: getAccountsSummaryExportUrl,
    };
  },
  created() {
    this.getListData();
  },
  mounted() {
    this.setTableHei();
  },
  methods: {
    /** 动态控制表格高度 **/
    setTableHei(){
      this.$nextTick(()=>{
        let windowsHei = document.documentElement.clientHeight;
        let exportHei = this.$refs.exportRef.offsetHeight;
        this.tableHei = windowsHei - exportHei - 140
      })
    },
    /** 获取列表数据 **/
    getListData() {
      getAccountsSummaryList().then(res => {
        console.log("res--->>", res);
        this.tableData = [];
        res.statItems?.forEach(item => {
          item.subTotalBillCountRate += "%";
          item.subTotalBillAmountRate += "%";
          this.tableData.push(item);
        });
        this.tableData.push(res.sumStatItem);
        Object.keys(res.rateStatItem).forEach(key => {
          if (key != "companyName") {
            res.rateStatItem[key] += "%";
          }
        });
        this.tableData.push(res.rateStatItem);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  .table-wrap {
    padding: 14px;

    .export-btn-wrap {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 12px;
    }
  }
}
</style>
