<template>
  <div class="notification-box">
    <!-- <el-dialog
      title="你有一条代办"
      :visible="visibility"
      width="300px"
      :modal="false"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :lock-scroll="false"
    > -->
    <div v-if="visibility">
      <!-- v-for="(item, index) in list"
        :key="index" -->
      <div class="box" :style="{ zIndex: calculateZIndex(index) }">
        <div class="box-top">
          <p>你有一条待办</p>
          <span class="cursor" @click="handleClose">x</span>
        </div>
        <div class="info">
          <div class="notification-text-ellipsis" :title="list.title">
            {{ list.title }}
          </div>
          <div>{{ list.reminderDateTime }}</div>
        </div>
        <div class="dialog-footer">
          <el-button @click="handleClose">知道了</el-button>
          <el-button
            type="primary"
            @click="Detail(list)"
            :disabled="list.funType !== 0"
            >查看详情</el-button
          >
        </div>
      </div>
    </div>
    <!-- </el-dialog> -->
  </div>
</template>
<script>
export default {
  name: 'notificationBox',
  props: {
    visibility: {
      type: Boolean,
      detail: { type: Object, default: () => ({}) },
    },
    newTodoList: {
      type: Array,
      detail: { type: Array, default: () => [] },
    },
    flag: {
      type: Number,
    },
  },
  data() {
    return {
      list: [],
      index: 0,
      tempList: [],
    };
  },
  watch: {
    newTodoList(newVal) {
      if (this.flag == 1) {
        this.tempList.unshift(...newVal);
        this.list = this.tempList[0];
      } else if (this.flag == 2 && this.newTodoList.length > 2) {
        this.tempList.unshift(...newVal);
        this.list = this.tempList[0];
      }
    },
    tempList() {
      this.list = this.tempList[0];
    },
  },
  created() {
    this.tempList = this.newTodoList;
    this.list = this.tempList[0];
  },
  methods: {
    calculateZIndex(index) {
      // 计算 z-index，使得弹框按照逆序叠加
      return 1000 - index;
    },
    handleClose() {
      if (this.tempList.length > 1) {
        this.tempList.splice(0, 1);
      } else {
        this.$emit('update:visibility', false);
      }
    },
    Detail(row) {
      const businessKey = row.businessKey;
      const id = row.id;
      if (this.tempList.length > 1) {
        this.tempList.splice(0, 1);
        if (row.funType == 0) {
          // 定损单
          this.$emit('goDetail', businessKey, id);
        }
      } else {
        if (row.funType == 0) {
          // 定损单
          this.$emit('goDetail', businessKey, id);
        }
        this.$emit('update:visibility', false);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.notification-box {
  position: relative;
}
.notification-text-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  word-break: break-all;
  word-wrap: break-word;
  text-overflow: ellipsis;
}
.box {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  width: 300px;
  height: 170px;
  background-color: #ffffff;
  border: 1px solid #ebeef5;
  bottom: 5vh;
  left: 0px;
  z-index: 999;
  padding: 15px;
  box-sizing: border-box;
  .box-top {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .cursor {
    cursor: pointer;
  }
  .dialog-footer {
    margin-top: 15px;
  }
}
.info {
  color: #000000;
  div {
    margin-top: 15px;
  }
}
// ::v-deep .el-dialog__footer {
//   display: flex !important;
// }
// .el-button + .el-button {
//   margin-left: 32px;
// }
// ::v-deep .el-dialog {
//   margin: 0 !important;
//   // bottom: 20vh;
//   position: absolute;
//   bottom: 10vh;
//   left: 160px;
// }
</style>
