import http from '@/service/http';

//在场车辆-今日在场车辆-在场车辆统计
export function getCountCarStranded(data) {
  // return http.post('/admin/carStranded/countCarStranded', data);  2022-10-13  更换接口名
  return http.post('/admin/carStranded/countCarStrandedStatistics', data);
}
//在场车辆-今日在场车辆-表格列表数据
export function getCarStrandedList(data) {
  return http.post('/admin/carStranded/list', data);
}
//在场车辆-今日在场车辆-客户滞留数据统计
export function getClientCarStrandedCountst(data) {
  return http.post('/admin/carStranded/getClientCarStrandedCounts', data);
}
//在场车辆-历史滞留车辆-表格列表数据
export function getHistoryPageList(data) {
  return http.post('/admin/carStranded/historyPage', data);
}
//批量滞留车辆
export function getCarBatchStranded(data) {
  return http.post('/admin/carStranded/batchStranded', data, {
    onlyReturnData: false,
  });
}

//车间工单-检车单列表
export function getCarDetectionList(data) {
  return http.post('/admin/car/detection/list', data);
}

//今日在场车辆导出车单列表
export const exportCarStrandedListUrl = '/admin/carStranded/export';

//车辆滞留详情
export function getCarStrandedDetail(data) {
  return http.post('/admin/carStranded/carStrandedDetail', data);
}

//历史滞留车辆导出
export const exportCarStrandedHistoryUrl = '/admin/carStrandedHistory/export';

/**
 * 改版今日在场、历史滞留新接口
 */
//今日在场车辆明细列表
export function getCarStrandedDetailed(data) {
  return http.post('/admin/carStranded/detailed', data, {
    onlyReturnData: false,
  });
}
//今日在场车辆状态统计数
export function getCarStrandedDetailedStatistics(data) {
  return http.post('/admin/carStranded/detailedStatistics', data);
}
//车辆滞留说明
export function editDetentionInstructions(data) {
  return http.post('/admin/carStranded/editDetentionInstructions', data, {
    onlyReturnData: false,
  });
}
//车辆滞留导出列表
export const exportCarStrandedDetailedListUrl =
  '/admin/carStranded/detailed/export';
//历史滞留快照列表
export function getCarStrandedRetention(data) {
  return http.post('/admin/carStranded/retention', data, {
    onlyReturnData: false,
  });
}
//历史滞留详情
export function getCarStrandedRetentionDetailed(data) {
  return http.post('/admin/carStranded/retentionDetailed', data);
}
//历史滞留导出
export const exportCarStrandedRetentionListUrl =
  '/admin/carStranded/retention/export';
// 今日在场统计
export function getCarStrandedStatistics(data) {
  return http.post('/admin/carStranded/carStrandedStatisticsPage', data);
}
// 今日在场统计合计
export function getCarStrandedStatisticsTotal(data) {
  return http.post('/admin/carStranded/carStrandedStatisticsTotal', data);
}
// 今日在场统计列表导出
export const exportCarStrandedStatisticsUrl =
  '/admin/carStrandedStatistics/export';

/**
 * 查询车单滞留信息
 * @params data {billId:number}
 */
export function getCarBillStrandedInfo(data) {
  return http.post('/admin/carStranded/information', data);
}
