var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { ref: "baseContainer", attrs: { paddingTop: "" } },
    [
      [
        _c(
          "el-form",
          {
            staticClass: "filter-form",
            attrs: { model: _vm.form, "label-width": "100px", size: "small" },
          },
          [
            _c("div", { staticClass: "flex-x-between" }, [
              _c(
                "div",
                { staticClass: "flex flex-shrink0" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "滞留类型", prop: "strandedType" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            disabled: "",
                            placeholder: "请选择滞留类型",
                          },
                          model: {
                            value: _vm.form.strandedType,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "strandedType", $$v)
                            },
                            expression: "form.strandedType",
                          },
                        },
                        _vm._l(_vm.strandedTypeEnumList, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "解决方案", prop: "solutionType" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: "请选择解决方案",
                          },
                          on: { change: _vm.solutionTypeChange },
                          model: {
                            value: _vm.form.solutionType,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "solutionType", $$v)
                            },
                            expression: "form.solutionType",
                          },
                        },
                        _vm._l(_vm.solutionTypeEnumList, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "el-form-item--small" }, [
                _c("div"),
                _c("div", { staticClass: "flex" }),
              ]),
            ]),
          ]
        ),
      ],
      [
        _c(
          "div",
          {
            staticClass: "btns flex-x-between",
            staticStyle: { margin: "30px 0 14px 0" },
          },
          [
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "auth",
                        rawName: "v-auth",
                        value: 769,
                        expression: "769",
                      },
                    ],
                    attrs: { size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.openImportDialog()
                      },
                    },
                  },
                  [
                    _vm._v(" 导入滞留单解决方案"),
                    _c("i", { staticClass: "el-icon-arrow-right" }),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "auth",
                        rawName: "v-auth",
                        value: 649,
                        expression: "649",
                      },
                    ],
                    staticClass: "blue",
                    attrs: { size: "small", type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.downloadAllRecords()
                      },
                    },
                  },
                  [
                    _vm._v("导出列表"),
                    _c("i", {
                      staticClass: "iconfont grow-icon_daochu el-icon--right",
                    }),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "fixedScrollBar",
                rawName: "v-fixedScrollBar",
                value: _vm.$refs.baseContainer,
                expression: "$refs.baseContainer",
              },
            ],
            staticClass: "custom-table",
            attrs: { data: _vm.listData, border: "" },
          },
          [
            _c("el-table-column", {
              attrs: { type: "index", label: "序号", width: "60" },
            }),
            _c("el-table-column", {
              attrs: { label: "操作", width: "80" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "auth",
                              rawName: "v-auth",
                              value: 768,
                              expression: "768",
                            },
                          ],
                          staticClass: "text_operation blue",
                          staticStyle: { "margin-right": "10px" },
                          on: {
                            click: function ($event) {
                              return _vm.handleEdit(scope.row)
                            },
                          },
                        },
                        [_vm._v("编辑")]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "200", label: "操作门店", prop: "companyName" },
            }),
            _c("el-table-column", {
              attrs: { width: "150", label: "车牌号", prop: "carNumber" },
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "客户", prop: "clientName" },
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "送修人", prop: "customerName" },
            }),
            _c("el-table-column", {
              attrs: { width: "200", label: "车单单号", prop: "billCode" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("gotoCarDetail", {
                        attrs: { billCode: row.billCode, billId: row.billId },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "140", label: "单据类型", prop: "billTypeDesc" },
            }),
            _c("el-table-column", {
              attrs: {
                width: "200",
                label: "业务来源",
                prop: "billSourceTypeName",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "140",
                label: "施工类型",
                prop: "difficultyDesc",
              },
            }),
            _c("el-table-column", {
              attrs: { width: "200", label: "进店时间", prop: "dateCreated" },
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "进店里程", prop: "mileage" },
            }),
            _c("el-table-column", {
              attrs: { width: "140", label: "单据金额", prop: "paid" },
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "单据状态", prop: "billStateDesc" },
            }),
            _c("el-table-column", {
              attrs: {
                width: "100",
                label: "施工状态",
                prop: "dispatchStateDesc",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "200",
                label: "完工时间",
                prop: "dispatchCompletionTime",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "100",
                label: "滞留原因",
                prop: "strandedReason",
                "show-overflow-tooltip": "",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "100",
                label: "卡点责任人",
                prop: "stuckPointResponsibleUserName",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "100",
                label: "清单责任人",
                prop: "detailListResponsibleUserName",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "200",
                label: "完成时间",
                prop: "strandedCompletionTime",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "150",
                label: "解决方案",
                prop: "solutionTypeDesc",
              },
            }),
          ],
          1
        ),
        _vm.showEditDialog
          ? _c("editCarStrandedDailyStatDetailDialog", {
              attrs: {
                visibility: _vm.showEditDialog,
                id: _vm.editId,
                rowEntityData: _vm.rowEntityData,
                solutionTypeEnumList: _vm.solutionTypeEnumList,
              },
              on: {
                "update:visibility": function ($event) {
                  _vm.showEditDialog = $event
                },
                save: function ($event) {
                  return _vm.getData(false)
                },
              },
            })
          : _vm._e(),
        _vm.importDialogVisibility
          ? _c("import-dialog", {
              attrs: {
                visibility: _vm.importDialogVisibility,
                showTip: false,
                title: "滞留单据解决方案批量导入",
                templateFileUrl:
                  "https://growxny-dev-center.oss-cn-guangzhou.aliyuncs.com/template/%E6%BB%9E%E7%95%99%E5%8D%95%E6%8D%AE%E6%97%A5%E6%8A%A5%E6%98%8E%E7%BB%86%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF_20241027.xlsx",
                uploadFileApi: _vm.importApi,
                getLastResultApi: _vm.importResultApi,
                importFailDetail: _vm.importMessageExportUrlApi,
              },
              on: {
                "update:visibility": function ($event) {
                  _vm.importDialogVisibility = $event
                },
              },
            })
          : _vm._e(),
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c("pagination", {
            attrs: { total: _vm.total },
            on: { pageChange: _vm.changePage },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }