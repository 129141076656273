var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    {
      ref: "baseContainer",
      attrs: {
        customStyle: "background: #f1f7fa;",
        customMainStyle: "margin-bottom: 16px;",
        defaultPadding: false,
      },
    },
    [
      [
        _c(
          "el-form",
          {
            staticClass: "filter-form",
            staticStyle: { background: "#fff" },
            attrs: { "label-width": "120px", size: "small" },
          },
          [
            _c("div", { staticClass: "flex-x-between" }, [
              _c(
                "div",
                { staticClass: "flex flex-shrink0" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "签约客户" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            clearable: "",
                            remote: "",
                            placeholder: "请输入姓名",
                            "remote-method": _vm.searchClient,
                            loading: _vm.searchClientLoading,
                          },
                          model: {
                            value: _vm.form.clientId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "clientId", $$v)
                            },
                            expression: "form.clientId",
                          },
                        },
                        _vm._l(_vm.clientList, function (item) {
                          return _c(
                            "el-option",
                            {
                              key: item.id,
                              attrs: { label: item.clientName, value: item.id },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    float: "left",
                                    color: "#8492a6",
                                    "font-size": "13px",
                                  },
                                },
                                [_vm._v(_vm._s(item.clientName))]
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c(
                          "div",
                          [
                            _vm._v(" 操作门店 "),
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  effect: "light",
                                  content:
                                    "定损单的操作门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)",
                                  placement: "bottom-start",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "iconfont grow-icon_doubt",
                                  staticStyle: { color: "#9a9a9a" },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("operateCompanyCascader", {
                        model: {
                          value: _vm.form.operateCompanyId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "operateCompanyId", $$v)
                          },
                          expression: "form.operateCompanyId",
                        },
                      }),
                    ],
                    2
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "接车进店日期" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "w100",
                        attrs: {
                          type: "daterange",
                          "unlink-panels": "",
                          "range-separator": "至",
                          "start-placeholder": "起始时间",
                          "end-placeholder": "截止时间",
                          "value-format": "yyyy-MM-dd",
                        },
                        on: { input: _vm.dateRangeChange },
                        model: {
                          value: _vm.form.intoStoreTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "intoStoreTime", $$v)
                          },
                          expression: "form.intoStoreTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "el-form-item--small" }, [
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "btn_search",
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.getData },
                      },
                      [_vm._v("查询")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn_search",
                        attrs: { size: "small" },
                        on: { click: _vm.empty },
                      },
                      [_vm._v("置空")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        ),
      ],
      [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "title flex-y-center" }, [
            _c("div", {
              staticClass: "line",
              staticStyle: { background: "#33ab78" },
            }),
            _c("div", { staticClass: "text" }, [_vm._v("出定损")]),
          ]),
          _c("ul", { staticClass: "list first" }, [
            _c("li", { staticClass: "item" }, [
              _c("div", { staticClass: "left" }, [
                _c(
                  "span",
                  { staticClass: "item-name" },
                  [
                    _vm._v("进店理赔（台次） "),
                    _c(
                      "el-tooltip",
                      {
                        staticStyle: { "margin-left": "5px" },
                        attrs: {
                          content: "查询时间段内理赔开单量（不含补单、已取消）",
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "iconfont grow-icon_doubt",
                          staticStyle: {
                            color: "#9a9a9a",
                            "font-size": "18px",
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _c("span", { staticClass: "item-num" }, [
                  _vm._v(_vm._s(_vm.tableData.claimSettlementNum)),
                ]),
              ]),
              _c("img", {
                staticClass: "item-icon",
                attrs: {
                  src: require("@/assets/img/loss_assessment/进店理赔.png"),
                  alt: "进店理赔",
                },
              }),
            ]),
            _c("li", { staticClass: "item" }, [
              _c("div", { staticClass: "left" }, [
                _c(
                  "span",
                  { staticClass: "item-name" },
                  [
                    _vm._v("已提定损（台次） "),
                    _c(
                      "el-tooltip",
                      {
                        staticStyle: { "margin-left": "5px" },
                        attrs: {
                          content:
                            "查询时间段内已提定损的定损单（不含待报损、待调度、待拍照对件、待提定损）",
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "iconfont grow-icon_doubt",
                          staticStyle: {
                            color: "#9a9a9a",
                            "font-size": "18px",
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _c("span", { staticClass: "item-num" }, [
                  _vm._v(_vm._s(_vm.tableData.submitLossDeterminationNum)),
                ]),
              ]),
              _c("img", {
                staticClass: "item-icon",
                attrs: {
                  src: require("@/assets/img/loss_assessment/已提定损.png"),
                  alt: "已提定损",
                },
              }),
            ]),
            _c("li", { staticClass: "item" }, [
              _c("div", { staticClass: "left" }, [
                _c(
                  "span",
                  { staticClass: "item-name" },
                  [
                    _vm._v("已出损（台次） "),
                    _c(
                      "el-tooltip",
                      {
                        staticStyle: { "margin-left": "5px" },
                        attrs: {
                          content:
                            "查询时间段内已出损的定损单（含已出定损、待复勘、待收旧、已提资料、已回款）",
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "iconfont grow-icon_doubt",
                          staticStyle: {
                            color: "#9a9a9a",
                            "font-size": "18px",
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _c("span", { staticClass: "item-num" }, [
                  _vm._v(_vm._s(_vm.tableData.issueLossDeterminationNum)),
                ]),
              ]),
              _c("img", {
                staticClass: "item-icon",
                attrs: {
                  src: require("@/assets/img/loss_assessment/已出损.png"),
                  alt: "已出损",
                },
              }),
            ]),
            _c("li", { staticClass: "item" }, [
              _c("div", { staticClass: "left" }, [
                _c(
                  "span",
                  { staticClass: "item-name" },
                  [
                    _vm._v("待出定损（台次） "),
                    _c(
                      "el-tooltip",
                      {
                        staticStyle: { "margin-left": "5px" },
                        attrs: { content: "查询时间段待出定损状态的定损单" },
                      },
                      [
                        _c("i", {
                          staticClass: "iconfont grow-icon_doubt",
                          staticStyle: {
                            color: "#9a9a9a",
                            "font-size": "18px",
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _c("span", { staticClass: "item-num" }, [
                  _vm._v(_vm._s(_vm.tableData.breakageNum)),
                ]),
              ]),
              _c("img", {
                staticClass: "item-icon",
                attrs: {
                  src: require("@/assets/img/loss_assessment/待出定损.png"),
                  alt: "待出定损",
                },
              }),
            ]),
            _c("li", { staticClass: "item" }, [
              _c("div", { staticClass: "left" }, [
                _c(
                  "span",
                  { staticClass: "item-name" },
                  [
                    _vm._v("超期未出损（台次） "),
                    _c(
                      "el-tooltip",
                      {
                        staticStyle: { "margin-left": "5px" },
                        attrs: { content: "当前出损超期的定损单" },
                      },
                      [
                        _c("i", {
                          staticClass: "iconfont grow-icon_doubt",
                          staticStyle: {
                            color: "#9a9a9a",
                            "font-size": "18px",
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _c("span", { staticClass: "item-num" }, [
                  _vm._v(_vm._s(_vm.tableData.overdueLossNum)),
                ]),
              ]),
              _c("img", {
                staticClass: "item-icon",
                attrs: {
                  src: require("@/assets/img/loss_assessment/超期未出损.png"),
                  alt: "超期未出损",
                },
              }),
            ]),
            _c("li", { staticClass: "item" }, [
              _c("div", { staticClass: "left" }, [
                _c(
                  "span",
                  { staticClass: "item-name" },
                  [
                    _vm._v("出损率 "),
                    _c(
                      "el-tooltip",
                      {
                        staticStyle: { "margin-left": "5px" },
                        attrs: { content: "=已出损/已提定损" },
                      },
                      [
                        _c("i", {
                          staticClass: "iconfont grow-icon_doubt",
                          staticStyle: {
                            color: "#9a9a9a",
                            "font-size": "18px",
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _c("span", { staticClass: "item-num" }, [
                  _vm._v(
                    _vm._s(_vm.tableData.issueLossDeterminationRate) + " "
                  ),
                  _c("sub", [_vm._v("%")]),
                ]),
              ]),
              _c("img", {
                staticClass: "item-icon",
                attrs: {
                  src: require("@/assets/img/loss_assessment/出损率.png"),
                  alt: "出损率",
                },
              }),
            ]),
            _c("li", { staticClass: "item" }, [
              _c("div", { staticClass: "left" }, [
                _c(
                  "span",
                  { staticClass: "item-name" },
                  [
                    _vm._v("定损超期率 "),
                    _c(
                      "el-tooltip",
                      {
                        staticStyle: { "margin-left": "5px" },
                        attrs: { content: "=超时未出损/已提定损" },
                      },
                      [
                        _c("i", {
                          staticClass: "iconfont grow-icon_doubt",
                          staticStyle: {
                            color: "#9a9a9a",
                            "font-size": "18px",
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _c("span", { staticClass: "item-num" }, [
                  _vm._v(_vm._s(_vm.tableData.overdueLossRate) + " "),
                  _c("sub", [_vm._v("%")]),
                ]),
              ]),
              _c("img", {
                staticClass: "item-icon",
                attrs: {
                  src: require("@/assets/img/loss_assessment/定损超期率.png"),
                  alt: "定损超期率",
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "title flex-y-center" }, [
            _c("div", {
              staticClass: "line",
              staticStyle: { background: "#4973e1" },
            }),
            _c("div", { staticClass: "text" }, [_vm._v("催回款")]),
          ]),
          _c("ul", { staticClass: "list second" }, [
            _c("li", { staticClass: "item" }, [
              _c("div", { staticClass: "left" }, [
                _c(
                  "span",
                  { staticClass: "item-name" },
                  [
                    _vm._v("已回款（台次） "),
                    _c(
                      "el-tooltip",
                      {
                        staticStyle: { "margin-left": "5px" },
                        attrs: { content: "查询时间段内已回款的定损单" },
                      },
                      [
                        _c("i", {
                          staticClass: "iconfont grow-icon_doubt",
                          staticStyle: {
                            color: "#9a9a9a",
                            "font-size": "18px",
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _c("span", { staticClass: "item-num" }, [
                  _vm._v(_vm._s(_vm.tableData.paymentCollectionNum)),
                ]),
              ]),
              _c("img", {
                staticClass: "item-icon",
                attrs: {
                  src: require("@/assets/img/loss_assessment/已回款.png"),
                  alt: "已回款",
                },
              }),
            ]),
            _c("li", { staticClass: "item" }, [
              _c("div", { staticClass: "left" }, [
                _c(
                  "span",
                  { staticClass: "item-name" },
                  [
                    _vm._v("未回款（台次） "),
                    _c(
                      "el-tooltip",
                      {
                        staticStyle: { "margin-left": "5px" },
                        attrs: {
                          content: "查询时间段内已提定损，未回款的定损单",
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "iconfont grow-icon_doubt",
                          staticStyle: {
                            color: "#9a9a9a",
                            "font-size": "18px",
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _c("span", { staticClass: "item-num" }, [
                  _vm._v(_vm._s(_vm.tableData.noPaymentCollectionNum)),
                ]),
              ]),
              _c("img", {
                staticClass: "item-icon",
                attrs: {
                  src: require("@/assets/img/loss_assessment/未回款.png"),
                  alt: "未回款",
                },
              }),
            ]),
            _c("li", { staticClass: "item" }, [
              _c("div", { staticClass: "left" }, [
                _c(
                  "span",
                  { staticClass: "item-name" },
                  [
                    _vm._v("超期未回款（台次） "),
                    _c(
                      "el-tooltip",
                      {
                        staticStyle: { "margin-left": "5px" },
                        attrs: { content: "当前提示回款超期的定损单" },
                      },
                      [
                        _c("i", {
                          staticClass: "iconfont grow-icon_doubt",
                          staticStyle: {
                            color: "#9a9a9a",
                            "font-size": "18px",
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _c("span", { staticClass: "item-num" }, [
                  _vm._v(_vm._s(_vm.tableData.overdueCollectionNum)),
                ]),
              ]),
              _c("img", {
                staticClass: "item-icon",
                attrs: {
                  src: require("@/assets/img/loss_assessment/超期未回款.png"),
                  alt: "超期未回款",
                },
              }),
            ]),
            _c("li", { staticClass: "item" }, [
              _c("div", { staticClass: "left" }, [
                _c(
                  "span",
                  { staticClass: "item-name" },
                  [
                    _vm._v("回款率 "),
                    _c(
                      "el-tooltip",
                      {
                        staticStyle: { "margin-left": "5px" },
                        attrs: { content: "=已回款/已提定损" },
                      },
                      [
                        _c("i", {
                          staticClass: "iconfont grow-icon_doubt",
                          staticStyle: {
                            color: "#9a9a9a",
                            "font-size": "18px",
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _c("span", { staticClass: "item-num" }, [
                  _vm._v(_vm._s(_vm.tableData.paymentCollectionRate) + " "),
                  _c("sub", [_vm._v("%")]),
                ]),
              ]),
              _c("img", {
                staticClass: "item-icon",
                attrs: {
                  src: require("@/assets/img/loss_assessment/回款率.png"),
                  alt: "回款率",
                },
              }),
            ]),
            _c("li", { staticClass: "item" }, [
              _c("div", { staticClass: "left" }, [
                _c(
                  "span",
                  { staticClass: "item-name" },
                  [
                    _vm._v("回款超期率 "),
                    _c(
                      "el-tooltip",
                      {
                        staticStyle: { "margin-left": "5px" },
                        attrs: { content: "=超期未回款/已提定损" },
                      },
                      [
                        _c("i", {
                          staticClass: "iconfont grow-icon_doubt",
                          staticStyle: {
                            color: "#9a9a9a",
                            "font-size": "18px",
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _c("span", { staticClass: "item-num" }, [
                  _vm._v(_vm._s(_vm.tableData.overdueCollectionRate) + " "),
                  _c("sub", [_vm._v("%")]),
                ]),
              ]),
              _c("img", {
                staticClass: "item-icon",
                attrs: {
                  src: require("@/assets/img/loss_assessment/回款超期率.png"),
                  alt: "回款超期率",
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "title flex-y-center" }, [
            _c("div", {
              staticClass: "line",
              staticStyle: { background: "#f6bf39" },
            }),
            _c("div", { staticClass: "text" }, [_vm._v("提结算")]),
          ]),
          _c("ul", { staticClass: "list third" }, [
            _c("li", { staticClass: "item" }, [
              _c("div", { staticClass: "left" }, [
                _c(
                  "span",
                  { staticClass: "item-name" },
                  [
                    _vm._v("定损金额 "),
                    _c(
                      "el-tooltip",
                      {
                        staticStyle: { "margin-left": "5px" },
                        attrs: {
                          content: "查询时间段内已出定损台次的定损金额",
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "iconfont grow-icon_doubt",
                          staticStyle: {
                            color: "#9a9a9a",
                            "font-size": "18px",
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _c("span", { staticClass: "item-num" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("toThousands")(
                        _vm.tableData.issueLossDeterminationAmount
                      )
                    )
                  ),
                ]),
              ]),
              _c("img", {
                staticClass: "item-icon",
                attrs: {
                  src: require("@/assets/img/loss_assessment/定损金额.png"),
                  alt: "定损金额",
                },
              }),
            ]),
            _c("li", { staticClass: "item" }, [
              _c("div", { staticClass: "left" }, [
                _c(
                  "span",
                  { staticClass: "item-name" },
                  [
                    _vm._v("已回款金额 "),
                    _c(
                      "el-tooltip",
                      {
                        staticStyle: { "margin-left": "5px" },
                        attrs: { content: "查询时间段内已回款台次的定损金额" },
                      },
                      [
                        _c("i", {
                          staticClass: "iconfont grow-icon_doubt",
                          staticStyle: {
                            color: "#9a9a9a",
                            "font-size": "18px",
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _c("span", { staticClass: "item-num" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("toThousands")(
                        _vm.tableData.paymentCollectionAmount
                      )
                    )
                  ),
                ]),
              ]),
              _c("img", {
                staticClass: "item-icon",
                attrs: {
                  src: require("@/assets/img/loss_assessment/已回款金额.png"),
                  alt: "已回款金额",
                },
              }),
            ]),
            _c("li", { staticClass: "item" }, [
              _c("div", { staticClass: "left" }, [
                _c(
                  "span",
                  { staticClass: "item-name" },
                  [
                    _vm._v("未回款金额 "),
                    _c(
                      "el-tooltip",
                      {
                        staticStyle: { "margin-left": "5px" },
                        attrs: { content: "=定损金额-已回款金额" },
                      },
                      [
                        _c("i", {
                          staticClass: "iconfont grow-icon_doubt",
                          staticStyle: {
                            color: "#9a9a9a",
                            "font-size": "18px",
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _c("span", { staticClass: "item-num" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("toThousands")(
                        _vm.tableData.noPaymentCollectionAmount
                      )
                    )
                  ),
                ]),
              ]),
              _c("img", {
                staticClass: "item-icon",
                attrs: {
                  src: require("@/assets/img/loss_assessment/未回款金额.png"),
                  alt: "未回款金额",
                },
              }),
            ]),
            _c("li", { staticClass: "item" }, [
              _c("div", { staticClass: "left" }, [
                _c(
                  "span",
                  { staticClass: "item-name" },
                  [
                    _vm._v("结算金额 "),
                    _c(
                      "el-tooltip",
                      {
                        staticStyle: { "margin-left": "5px" },
                        attrs: {
                          content:
                            "查询时间段内已结账状态的理赔单单据金额（不含补单）",
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "iconfont grow-icon_doubt",
                          staticStyle: {
                            color: "#9a9a9a",
                            "font-size": "18px",
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _c("span", { staticClass: "item-num" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("toThousands")(
                        _vm.tableData.claimSettlementFinishedAmount
                      )
                    )
                  ),
                ]),
              ]),
              _c("img", {
                staticClass: "item-icon",
                attrs: {
                  src: require("@/assets/img/loss_assessment/结算金额.png"),
                  alt: "结算金额",
                },
              }),
            ]),
            _c("li", { staticClass: "item" }, [
              _c("div", { staticClass: "left" }, [
                _c(
                  "span",
                  { staticClass: "item-name" },
                  [
                    _vm._v("毛利率 "),
                    _c(
                      "el-tooltip",
                      {
                        staticStyle: { "margin-left": "5px" },
                        attrs: {
                          content:
                            "查询时间段内已结账状态的理赔单毛利率 （1-车单成本/结算金额）*100%",
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "iconfont grow-icon_doubt",
                          staticStyle: {
                            color: "#9a9a9a",
                            "font-size": "18px",
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _c("span", { staticClass: "item-num" }, [
                  _vm._v(_vm._s(_vm.tableData.grossProfitRate) + " "),
                  _c("sub", [_vm._v("%")]),
                ]),
              ]),
              _c("img", {
                staticClass: "item-icon",
                attrs: {
                  src: require("@/assets/img/loss_assessment/毛利率.png"),
                  alt: "毛利率",
                },
              }),
            ]),
            _c("li", { staticClass: "item" }, [
              _c("div", { staticClass: "left" }, [
                _c(
                  "span",
                  { staticClass: "item-name" },
                  [
                    _vm._v("单车产值 "),
                    _c(
                      "el-tooltip",
                      {
                        staticStyle: { "margin-left": "5px" },
                        attrs: { content: "=结算金额/已结账台次" },
                      },
                      [
                        _c("i", {
                          staticClass: "iconfont grow-icon_doubt",
                          staticStyle: {
                            color: "#9a9a9a",
                            "font-size": "18px",
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _c("span", { staticClass: "item-num" }, [
                  _vm._v(_vm._s(_vm.tableData.carOutputValue)),
                ]),
              ]),
              _c("img", {
                staticClass: "item-icon",
                attrs: {
                  src: require("@/assets/img/loss_assessment/单车产值.png"),
                  alt: "单车产值",
                },
              }),
            ]),
          ]),
        ]),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }