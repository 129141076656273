var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { ref: "baseContainer", attrs: { paddingTop: "" } },
    [
      [
        _c(
          "el-form",
          {
            staticClass: "filter-form",
            attrs: { model: _vm.form, "label-width": "100px", size: "small" },
          },
          [
            _c("div", { staticClass: "flex-x-between" }, [
              _c(
                "div",
                { staticClass: "flex flex-shrink0" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户信息" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入姓名/车牌",
                          clearable: "",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.getData.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.form.clientMsg,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "clientMsg", $$v)
                          },
                          expression: "form.clientMsg",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "车架号" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入", clearable: "" },
                        on: { input: _vm.inputUniqueId },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.getData.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.form.uniqueId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "uniqueId", $$v)
                          },
                          expression: "form.uniqueId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c(
                          "div",
                          [
                            _vm._v(" 建档门店 "),
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  effect: "light",
                                  content:
                                    "车辆档案的建档门店,可查连锁范围内全部门店",
                                  placement: "bottom-start",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "iconfont grow-icon_doubt",
                                  staticStyle: { color: "#9a9a9a" },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            clearable: "",
                            remote: "",
                            placeholder: "搜索车店名称、编号",
                            "remote-method": _vm.searchStore,
                            loading: _vm.searchStoreLoading,
                          },
                          model: {
                            value: _vm.form.filingCompanyId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "filingCompanyId", $$v)
                            },
                            expression: "form.filingCompanyId",
                          },
                        },
                        _vm._l(_vm.storeList, function (item) {
                          return _c(
                            "el-option",
                            {
                              key: item.companyId,
                              attrs: {
                                label: item.companyName,
                                value: item.companyId,
                              },
                            },
                            [
                              _c("span", { staticStyle: { float: "left" } }, [
                                _vm._v(_vm._s(item.companyId)),
                              ]),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    float: "right",
                                    color: "#8492a6",
                                  },
                                },
                                [_vm._v(_vm._s(item.companyName))]
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "el-form-item--small" }, [
                _c(
                  "div",
                  {
                    staticClass: "el-form-item__label pointer",
                    on: {
                      click: function ($event) {
                        _vm.showMore = !_vm.showMore
                      },
                    },
                  },
                  [
                    _vm._v(" 更多筛选 "),
                    _c("i", {
                      class: {
                        primary: _vm.showMore,
                        "el-icon-arrow-right": !_vm.showMore,
                        "el-icon-arrow-down": _vm.showMore,
                      },
                    }),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "btn_search",
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.getData },
                      },
                      [_vm._v("查询")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn_search",
                        attrs: { size: "small" },
                        on: { click: _vm.empty },
                      },
                      [_vm._v("置空")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "flex-warp" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "车型" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          filterable: "",
                          "allow-create": "",
                          remote: "",
                          placeholder: "请选择",
                          "remote-method": _vm.searchModel,
                          loading: _vm.searchModelLoading,
                          clearable: "",
                        },
                        model: {
                          value: _vm.form.carModel,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "carModel", $$v)
                          },
                          expression: "form.carModel",
                        },
                      },
                      _vm._l(_vm.models, function (item) {
                        return _c(
                          "el-option",
                          {
                            key: _vm.$lodash.get(item, "series.id"),
                            attrs: {
                              value: _vm.$lodash.get(
                                item,
                                "series.name",
                                _vm.$lodash.get(
                                  item,
                                  "brand.name",
                                  _vm.$lodash.get(item, "logo.name")
                                )
                              ),
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.selectCarModel(item)
                              },
                            },
                          },
                          [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(item.markedRedName),
                              },
                            }),
                          ]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "车辆状态" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择", clearable: "" },
                        model: {
                          value: _vm.form.state,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "state", $$v)
                          },
                          expression: "form.state",
                        },
                      },
                      _vm._l(_vm.carState, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "在保状态" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择", clearable: "" },
                        model: {
                          value: _vm.form.underWarrantyState,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "underWarrantyState", $$v)
                          },
                          expression: "form.underWarrantyState",
                        },
                      },
                      _vm._l(_vm.underWarrantyState, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "注册日期" } },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "daterange",
                        "unlink-panels": "",
                        "range-separator": "至",
                        "start-placeholder": "起始时间",
                        "end-placeholder": "截止时间",
                        "value-format": "yyyy-MM-dd",
                      },
                      model: {
                        value: _vm.form.registerTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "registerTime", $$v)
                        },
                        expression: "form.registerTime",
                      },
                    }),
                  ],
                  1
                ),
                _vm.showMore
                  ? [
                      _c(
                        "el-form-item",
                        { attrs: { label: "在租状态" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择", clearable: "" },
                              model: {
                                value: _vm.form.underLeaseState,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "underLeaseState", $$v)
                                },
                                expression: "form.underLeaseState",
                              },
                            },
                            _vm._l(_vm.underLeaseState, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "车辆所有人" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入车辆所有人名称",
                              clearable: "",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.getData.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.form.vehicleOwner,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "vehicleOwner", $$v)
                              },
                              expression: "form.vehicleOwner",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "保险起保时间" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "daterange",
                              "unlink-panels": "",
                              "range-separator": "至",
                              "start-placeholder": "起始时间",
                              "end-placeholder": "截止时间",
                              "value-format": "yyyy-MM-dd",
                            },
                            model: {
                              value: _vm.form.insuranceDueTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "insuranceDueTime", $$v)
                              },
                              expression: "form.insuranceDueTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]
        ),
      ],
      [
        _c(
          "div",
          {
            staticClass: "btns flex-x-between",
            staticStyle: { margin: "30px 0 14px 0" },
          },
          [
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "auth",
                        rawName: "v-auth",
                        value: 54,
                        expression: "54",
                      },
                    ],
                    staticClass: "btn_insert",
                    attrs: { size: "small" },
                    on: { click: _vm.addCar },
                  },
                  [_vm._v("新增")]
                ),
                _c(
                  "el-dropdown",
                  {
                    staticStyle: { "margin-left": "10px" },
                    attrs: {
                      size: "small",
                      trigger: "click",
                      placement: "top",
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "auth",
                            rawName: "v-auth",
                            value: 53,
                            expression: "53",
                          },
                        ],
                        staticStyle: { padding: "8px 28px !important" },
                      },
                      [
                        _vm._v(" 批量"),
                        _c("i", { staticClass: "el-icon-arrow-right" }),
                      ]
                    ),
                    _c(
                      "el-dropdown-menu",
                      {
                        staticStyle: { width: "90px" },
                        attrs: { slot: "dropdown" },
                        slot: "dropdown",
                      },
                      [
                        _c(
                          "el-dropdown-item",
                          {
                            staticClass: "btn-dropdown",
                            nativeOn: {
                              click: function ($event) {
                                return _vm.editor.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 283,
                    expression: "283",
                  },
                ],
                staticClass: "blue",
                attrs: { size: "small", type: "text" },
                on: {
                  click: function ($event) {
                    _vm.showExportDialog = true
                  },
                },
              },
              [
                _vm._v("导出列表"),
                _c("i", {
                  staticClass: "iconfont grow-icon_daochu el-icon--right",
                }),
              ]
            ),
          ],
          1
        ),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "fixedScrollBar",
                rawName: "v-fixedScrollBar",
                value: _vm.$refs.baseContainer,
                expression: "$refs.baseContainer",
              },
            ],
            staticClass: "custom-table",
            attrs: { data: _vm.carListData, border: "" },
            on: {
              "selection-change": _vm.handleSelectionChange,
              "row-dblclick": _vm.goDetail,
            },
          },
          [
            _c("el-table-column", {
              attrs: { type: "selection", width: "55" },
            }),
            _c("el-table-column", {
              attrs: { type: "index", label: "序号", width: "60" },
            }),
            _c("el-table-column", {
              attrs: { label: "操作", width: "60" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "auth",
                              rawName: "v-auth",
                              value: 53,
                              expression: "53",
                            },
                          ],
                          staticClass: "text_operation blue",
                          on: {
                            click: function ($event) {
                              return _vm.handleEdit(scope.row)
                            },
                          },
                        },
                        [_vm._v("编辑")]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "车牌号" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "span",
                        {
                          staticClass: "text_operation blue",
                          on: {
                            click: function ($event) {
                              return _vm.goDetail(scope.row)
                            },
                          },
                        },
                        [_vm._v(_vm._s(scope.row.carNumber))]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "180", label: "车型/车架号", prop: "uniqueId" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c(
                        "el-tooltip",
                        { attrs: { content: row.carModelName } },
                        [
                          _c("p", { staticClass: "text-ellipsis" }, [
                            _vm._v(_vm._s(row.carModelName)),
                          ]),
                        ]
                      ),
                      _c("p", [_vm._v(_vm._s(row.uniqueId))]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "120", label: "签约客户", prop: "clientName" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [_c("p", [_vm._v(_vm._s(row.clientName))])]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                width: "120",
                label: "最后到店里程",
                prop: "lastIntoStoreMileage",
              },
            }),
            _c("el-table-column", {
              attrs: {
                width: "120",
                label: "最后到店时间",
                prop: "lastIntoStoreDate",
              },
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "注册时间", prop: "registerDate" },
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "整车质保" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      row.hasVehicleWarranty
                        ? _c(
                            "span",
                            [
                              _vm._v(" 整车质保 "),
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    effect: "light",
                                    content: row.vehicleWarrantyContent,
                                    placement: "bottom-start",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "iconfont grow-icon_doubt",
                                    staticStyle: { color: "#9a9a9a" },
                                  }),
                                ]
                              ),
                            ],
                            1
                          )
                        : row.hasVehicleWarranty === 0
                        ? _c("span", [_vm._v("-")])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "三电质保" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      row.hasThreePowerWarranty
                        ? _c(
                            "span",
                            [
                              _vm._v(" 三电质保 "),
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    effect: "light",
                                    content: row.threePowerWarrantyContent,
                                    placement: "bottom-start",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "iconfont grow-icon_doubt",
                                    staticStyle: { color: "#9a9a9a" },
                                  }),
                                ]
                              ),
                            ],
                            1
                          )
                        : row.hasThreePowerWarranty === 0
                        ? _c("span", [_vm._v("-")])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "动力电池质保" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      row.hasPowerBatteryWarranty
                        ? _c(
                            "span",
                            [
                              _vm._v(" 动力电池质保 "),
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    effect: "light",
                                    content: row.powerBatteryWarrantyContent,
                                    placement: "bottom-start",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "iconfont grow-icon_doubt",
                                    staticStyle: { color: "#9a9a9a" },
                                  }),
                                ]
                              ),
                            ],
                            1
                          )
                        : row.hasPowerBatteryWarranty === 0
                        ? _c("span", [_vm._v("-")])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "电芯质保" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      row.hasBatteryWarranty
                        ? _c(
                            "span",
                            [
                              _vm._v(" 动力电池质保 "),
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    effect: "light",
                                    content: row.batteryWarrantyContent,
                                    placement: "bottom-start",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "iconfont grow-icon_doubt",
                                    staticStyle: { color: "#9a9a9a" },
                                  }),
                                ]
                              ),
                            ],
                            1
                          )
                        : row.hasBatteryWarranty === 0
                        ? _c("span", [_vm._v("-")])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "易损件质保" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      row.hasWarrantyWearingParts
                        ? _c(
                            "span",
                            [
                              _vm._v("易损件质保 "),
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    effect: "light",
                                    content: row.warrantyWearingPartsContent,
                                    placement: "bottom-start",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "iconfont grow-icon_doubt",
                                    staticStyle: { color: "#9a9a9a" },
                                  }),
                                ]
                              ),
                            ],
                            1
                          )
                        : row.hasWarrantyWearingParts === 0
                        ? _c("span", [_vm._v("-")])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "车辆状态" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(_vm._f("dict")(row.state, "carState"))),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "在租状态" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("dict")(
                              row.underLeaseState,
                              "underLeaseState"
                            )
                          )
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                width: "180",
                label: "到店详情",
                prop: "intoStoreDetails",
              },
            }),
            _c("el-table-column", {
              attrs: { width: "160", label: "车辆所有人" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c(
                        "el-tooltip",
                        { attrs: { content: row.vehicleOwner } },
                        [
                          _c("div", { staticClass: "text-ellipsis-2-row" }, [
                            _vm._v(" " + _vm._s(row.vehicleOwner) + " "),
                          ]),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "160", label: "电动机/发动机号" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c(
                        "el-tooltip",
                        { attrs: { content: row.engineNumber } },
                        [
                          _c("div", { staticClass: "text-ellipsis-2-row" }, [
                            _vm._v(" " + _vm._s(row.engineNumber) + " "),
                          ]),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                width: "100",
                label: "新车续航",
                prop: "newVehicleMileage",
              },
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "真实续航", prop: "trueEndurance" },
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "动力类型" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [_c("span", [_vm._v(_vm._s(row.fuelType))])]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "车辆来源" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [_c("span", [_vm._v(_vm._s(row.vehicleSource))])]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "发车模式" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [_c("span", [_vm._v(_vm._s(row.departureMode))])]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "120", label: "车损险" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("p", [_vm._v(_vm._s(row.insuranceCompanyName))]),
                      row.insuranceValidDate
                        ? _c("p", [
                            _c("span", [
                              _vm._v(_vm._s(row.insuranceValidDate) + "起保"),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "120", label: "三者险" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("p", [
                        _vm._v(_vm._s(row.thirdPartyInsuranceCompanyName)),
                      ]),
                      row.thirdPartyInsuranceValidDate
                        ? _c("p", [
                            _c("span", [
                              _vm._v(
                                _vm._s(row.thirdPartyInsuranceValidDate) +
                                  "起保"
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "120", label: "座位险" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("p", [_vm._v(_vm._s(row.seatInsuranceCompanyName))]),
                      row.seatInsuranceValidDate
                        ? _c("p", [
                            _c("span", [
                              _vm._v(
                                _vm._s(row.seatInsuranceValidDate) + "起保"
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "120", label: "交强险" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("p", [_vm._v(_vm._s(row.tcInsuranceCompanyName))]),
                      row.tcInsuranceValidDate
                        ? _c("p", [
                            _c("span", [
                              _vm._v(_vm._s(row.tcInsuranceValidDate) + "起保"),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "120", label: "承运险" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("p", [
                        _vm._v(_vm._s(row.vehicleInsuranceCompanyName)),
                      ]),
                      row.vehicleInsuranceValidDate
                        ? _c("p", [
                            _c("span", [
                              _vm._v(
                                _vm._s(row.vehicleInsuranceValidDate) + "起保"
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "120", label: "驾乘险" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("p", [
                        _vm._v(_vm._s(row.drivingInsuranceCompanyName)),
                      ]),
                      row.drivingInsuranceValidDate
                        ? _c("p", [
                            _c("span", [
                              _vm._v(
                                _vm._s(row.drivingInsuranceValidDate) + "起保"
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "格悦出单" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      row.geyueIssuer || row.geyueIssuer == 0
                        ? _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(row.geyueIssuer == 0 ? "否" : "是") +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "团车政策" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      row.groupCarPolicy || row.groupCarPolicy == 0
                        ? _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(row.groupCarPolicy == 0 ? "否" : "是") +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "团车代码", prop: "groupCarCode" },
            }),
            _c("el-table-column", {
              attrs: {
                width: "150",
                label: "承保特殊要求",
                "show-overflow-tooltip": "",
                prop: "insureSpecialAsk",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("span", { staticClass: "pointer" }, [
                        _vm._v(_vm._s(row.insureSpecialAsk)),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                width: "180",
                label: "备注",
                prop: "remark",
                "show-overflow-tooltip": "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", { staticClass: "pointer" }, [
                        _vm._v(_vm._s(scope.row.remark)),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "150", label: "建档人/建档时间" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("p", [_vm._v(_vm._s(row.createOwnerName))]),
                      _c("p", [_vm._v(_vm._s(row.dateCreated))]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "180", label: "建档门店", prop: "companyName" },
            }),
          ],
          1
        ),
        _vm.showAddCarDialog
          ? _c("add-car-dialog", {
              attrs: {
                showAddCarDialog: _vm.showAddCarDialog,
                id: _vm.editCarId,
              },
              on: {
                "update:showAddCarDialog": function ($event) {
                  _vm.showAddCarDialog = $event
                },
                "update:show-add-car-dialog": function ($event) {
                  _vm.showAddCarDialog = $event
                },
                save: _vm.getData,
              },
            })
          : _vm._e(),
        _c("export-dialog", {
          attrs: {
            visibility: _vm.showExportDialog,
            total: _vm.total,
            pageOption: _vm.pageOption,
            filterForm: _vm.form,
            url: _vm.exportUrl,
          },
          on: {
            "update:visibility": function ($event) {
              _vm.showExportDialog = $event
            },
          },
        }),
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c("pagination", {
            attrs: { total: _vm.total },
            on: { pageChange: _vm.changePage },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }