import http from '@/service/http';

const defaultListOption = {
  current: 1,
  size: 10,
};

// 获取报价车型树
export function getCarModelTree(data = {}) {
  return http.post('/admin/usualQuote/carModel/list', data);
}

// 常用报价列表
export function getUsualQuoteList(data = defaultListOption) {
  return http.post('/admin/usualQuote/list', data);
}
