var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { ref: "baseContainer", attrs: { paddingTop: "" } },
    [
      [
        _c(
          "el-form",
          {
            staticClass: "filter-form",
            attrs: { model: _vm.form, "label-width": "100px", size: "small" },
          },
          [
            _c("div", { staticClass: "flex-x-between" }, [
              _c(
                "div",
                { staticClass: "flex-shrink0 flex" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "服务名称" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入服务名称", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.doSearch.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.form.serviceItemName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "serviceItemName", $$v)
                          },
                          expression: "form.serviceItemName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品名称" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入商品名称", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.doSearch.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.form.goodsName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "goodsName", $$v)
                          },
                          expression: "form.goodsName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "品牌" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入品牌", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.doSearch.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.form.brandName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "brandName", $$v)
                          },
                          expression: "form.brandName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "el-form-item--small" }, [
                _c(
                  "div",
                  {
                    staticClass: "el-form-item__label pointer",
                    on: {
                      click: function ($event) {
                        _vm.showMore = !_vm.showMore
                      },
                    },
                  },
                  [
                    _vm._v(" 更多筛选 "),
                    _c("i", {
                      class: {
                        primary: _vm.showMore,
                        "el-icon-arrow-right": !_vm.showMore,
                        "el-icon-arrow-down": _vm.showMore,
                      },
                    }),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "auth",
                            rawName: "v-auth",
                            value: 727,
                            expression: "727",
                          },
                        ],
                        staticClass: "btn_search",
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.doSearch },
                      },
                      [_vm._v("查询")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn_search",
                        attrs: { size: "small" },
                        on: { click: _vm.empty },
                      },
                      [_vm._v("置空")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _vm.showMore
              ? [
                  _c(
                    "div",
                    { staticClass: "flex-warp" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "车系" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入车系", clearable: "" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.doSearch.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.form.seriesName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "seriesName", $$v)
                              },
                              expression: "form.seriesName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              : _vm._e(),
          ],
          2
        ),
      ],
      [
        _c(
          "div",
          {
            staticClass: "btns flex-x-between",
            staticStyle: { "margin-bottom": "14px" },
          },
          [
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "auth",
                        rawName: "v-auth",
                        value: 732,
                        expression: "732",
                      },
                    ],
                    staticClass: "btn_insert",
                    staticStyle: { "margin-right": "10px" },
                    attrs: { size: "small" },
                    on: { click: _vm.add },
                  },
                  [_vm._v("新增")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "auth",
                        rawName: "v-auth",
                        value: 734,
                        expression: "734",
                      },
                    ],
                    staticClass: "btn_insert",
                    attrs: { size: "small" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.batchOperation(2, "批量删除")
                      },
                    },
                  },
                  [_vm._v("批量删除")]
                ),
              ],
              1
            ),
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 729,
                    expression: "729",
                  },
                ],
                staticClass: "blue",
                attrs: { size: "small", type: "text" },
                on: {
                  click: function ($event) {
                    return _vm.downloadAllRecords()
                  },
                },
              },
              [
                _vm._v("导出列表"),
                _c("i", {
                  staticClass: "iconfont grow-icon_daochu el-icon--right",
                }),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "flex",
            staticStyle: { height: "100%", overflow: "auto" },
          },
          [
            _c(
              "div",
              { staticStyle: { flex: "1", overflow: "auto" } },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "fixedScrollBar",
                        rawName: "v-fixedScrollBar",
                        value: _vm.$refs.baseContainer,
                        expression: "$refs.baseContainer",
                      },
                    ],
                    staticClass: "custom-table",
                    attrs: { data: _vm.listData, border: "" },
                    on: { "selection-change": _vm.handleSelectionChange },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "selection",
                        width: "40",
                        prop: "selection",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { type: "index", label: "序号", width: "60" },
                    }),
                    _c("el-table-column", {
                      attrs: { label: "操作", width: "80" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "auth",
                                      rawName: "v-auth",
                                      value: 733,
                                      expression: "733",
                                    },
                                  ],
                                  staticClass: "text_operation blue",
                                  on: {
                                    click: function ($event) {
                                      return _vm.edit(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "180",
                        label: "协议客户",
                        prop: "agreementClientName",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "180",
                        label: "服务协议项目",
                        prop: "serviceItemName",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "180",
                        label: "服务价",
                        prop: "servicePrice",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "180",
                        label: "服务协议价",
                        prop: "serviceAgreementPrice",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "180",
                        label: "商品标准名",
                        prop: "goodsStandardName",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "180",
                        label: "商品名称",
                        prop: "goodsName",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "180",
                        label: "原厂编码",
                        prop: "goodsCode",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "180",
                        label: "商品售价",
                        prop: "goodsPrice",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "180",
                        label: "商品协议价",
                        prop: "goodsAgreementPrice",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "100",
                        label: "品质",
                        prop: "manufactoryTypeDesc",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { width: "180", label: "品牌", prop: "logoName" },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "180",
                        label: "车系",
                        prop: "seriesName",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { width: "180", label: "型号", prop: "modelName" },
                    }),
                    _c("el-table-column", {
                      attrs: { width: "180", label: "年款", prop: "yearName" },
                    }),
                    _c("el-table-column", {
                      attrs: { width: "100", label: "建档人", prop: "creator" },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "180",
                        label: "更新时间",
                        prop: "lastUpdated",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
        _vm.showAddOrEditAgreementPriceDialog
          ? _c("addOrEditAgreementPriceDialog", {
              attrs: {
                visibility: _vm.showAddOrEditAgreementPriceDialog,
                agreementId: _vm.editAgreementId,
                usualQuoteId: _vm.editUsualQuoteId,
                agreementServicePriceId: _vm.editAgreementServicePriceId,
                agreementGoodsPriceId: _vm.editAgreementGoodsPriceId,
              },
              on: {
                "update:visibility": function ($event) {
                  _vm.showAddOrEditAgreementPriceDialog = $event
                },
                save: function ($event) {
                  return _vm.getData(false)
                },
              },
            })
          : _vm._e(),
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c("pagination", {
            attrs: { total: _vm.total },
            on: { pageChange: _vm.changePage },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }