import http from '@/service/http';

//日历待办查询(日历上的红点)
export function statisticByDay(data) {
  return http.post('/admin/user/todo/statisticByDay', data);
}

//日历待办查询(日历上的红点)
export function statisticNum(dateTime) {
  return http.post('/admin/user/todo/statisticNum', { dateTime });
}

//待办列表查询
export function getToDoList(data) {
  return http.post('/admin/user/todo/list', data);
}

//待办变更为已办更新
export function changeToCompleted(ids) {
  return http.post('/admin/user/todo/changeToCompleted', { ids });
}

//已办列表查询
export function getCompletedList(data) {
  return http.post('/admin/user/todo/completedList', data);
}

//已办变更为待办更新
export function changeToUnCompleted(ids) {
  return http.post('/admin/user/todo/changeToUnCompleted', { ids });
}
