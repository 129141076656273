<template>
  <base-container>
    <div class="form-top">
      <el-form :model="form" label-width="100px" size="small">
        <el-row>
          <el-col :span="6">
            <el-form-item>
              <template slot="label">
                <div>
                  操作门店
                  <el-tooltip
                    effect="light"
                    content="接车单的操作门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)"
                    placement="bottom-start"
                  >
                    <i
                      class="iconfont grow-icon_doubt"
                      style="color: #9a9a9a"
                    ></i>
                  </el-tooltip>
                </div>
              </template>
              <el-select
                size="small"
                v-model="form.companyId"
                @change="selectStore"
                @clear="deleteStore"
                filterable
                clearable
                remote
                placeholder="搜索车店名称、编号"
                :remote-method="searchStore"
                :loading="searchStoreLoading"
              >
                <el-option
                  v-for="item in storeList"
                  :key="item.companyId"
                  :label="item.companyName"
                  :value="item.companyId"
                >
                  <span style="float: left">{{ item.companyName }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="查询日期">
              <el-date-picker
                class="w100"
                v-model="form.times"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="截止时间"
                value-format="yyyy-MM-dd"
                :clearable="false"
                @input="dateRangeChange()"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <div class="el-form-item--small" style="margin-left: 20%">
              <el-button
                class="btn_search"
                type="primary"
                size="small"
                @click="getList()"
                >查询</el-button
              >
              <el-button class="btn_search" size="small" @click="nowDate"
                >今日</el-button
              >
              <el-button class="btn_search" size="small" @click="nowMonth"
                >本月</el-button
              >
              <el-button class="btn_search" size="small" @click="empty"
                >置空</el-button
              >
            </div>
          </el-col>
        </el-row>
      </el-form>
      <div class="pay-type">
        <div class="pay-type-list">
          <div class="type-incon">
            <i class="iconfont grow-icon orange"></i>
          </div>
          <div class="type-word">
            <p class="orange">收入</p>
            <p class="orange number-word">
              {{ $lodash.get(list, 'incomeTotal', 0) | toThousands }}
            </p>
            <p>营业、其他收入</p>
          </div>
        </div>
        <div class="pay-type-list">
          <div class="type-incon">
            <i class="iconfont grow-icon2 primary"></i>
          </div>
          <div class="type-word">
            <p class="primary">支出</p>
            <p class="primary number-word">
              {{ $lodash.get(list, 'expenseTotal', 0) | toThousands }}
            </p>
            <p>采购、退货款等支出</p>
          </div>
        </div>
        <div class="pay-type-list">
          <div class="type-incon">
            <i class="iconfont grow-icon3 blue"></i>
          </div>
          <div class="type-word">
            <p class="blue">结余</p>
            <p class="blue number-word">
              {{ balanceTotal | toThousands }}
            </p>
            <p>店内总收入减去总支出后结余</p>
          </div>
        </div>
      </div>
      <div
        class="orange infor-bt"
        style="background: #fff4e5; margin-top: 60px"
      >
        自费
      </div>
      <div class="source-number">
        <div>
          <p>营业总额</p>
          <p style="color: #333; font-size: 30px">
            <span style="font-size: 20px">￥</span
            >{{ $lodash.get(totalList[0], 'selfTotal', 0) | toThousands }}
          </p>
        </div>
        <div>
          <p>服务营业额</p>
          <span>{{
            $lodash.get(list, 'selfTotalBusinessTurnover.serviceTurnover', 0)
              | toThousands
          }}</span>
        </div>
        <div>
          <p>商品营业额</p>
          <span>{{
            $lodash.get(list, 'selfTotalBusinessTurnover.goodTurnover', 0)
              | toThousands
          }}</span>
        </div>
        <div>
          <p>提车单量</p>
          <span>{{
            $lodash.get(list, 'selfTotalBusinessTurnover.takeCarBillNum', 0)
              | toThousands
          }}</span>
        </div>
        <div>
          <p>进店单量</p>
          <span>{{
            $lodash.get(list, 'selfTotalBusinessTurnover.intoBillNum', 0)
              | toThousands
          }}</span>
        </div>
        <div>
          <p>结账单量</p>
          <span>{{
            $lodash.get(
              list,
              'selfTotalBusinessTurnover.settleAccountsBillNum',
              0
            ) | toThousands
          }}</span>
        </div>
      </div>
      <div class="primary infor-bt" style="background: #e8f3ee">包干</div>
      <div class="source-number">
        <div>
          <p>营业总额</p>
          <p style="color: #333; font-size: 30px">
            <span style="font-size: 20px">￥</span
            >{{ $lodash.get(totalList[0], 'contractTotal', 0) | toThousands }}
          </p>
        </div>
        <div>
          <p>服务营业额</p>
          <span>{{
            $lodash.get(
              list,
              'contractTotalBusinessTurnover.serviceTurnover',
              0
            ) | toThousands
          }}</span>
        </div>
        <div>
          <p>商品营业额</p>
          <span>{{
            $lodash.get(list, 'contractTotalBusinessTurnover.goodTurnover', 0)
              | toThousands
          }}</span>
        </div>
        <div>
          <p>提车单量</p>
          <span>{{
            $lodash.get(list, 'contractTotalBusinessTurnover.takeCarBillNum', 0)
              | toThousands
          }}</span>
        </div>
        <div>
          <p>进店单量</p>
          <span>{{
            $lodash.get(list, 'contractTotalBusinessTurnover.intoBillNum', 0)
              | toThousands
          }}</span>
        </div>
        <div>
          <p>结账单量</p>
          <span>{{
            $lodash.get(
              list,
              'contractTotalBusinessTurnover.settleAccountsBillNum',
              0
            ) | toThousands
          }}</span>
        </div>
      </div>
      <div class="blue infor-bt" style="background: #ecf7fc">索赔</div>
      <div class="source-number">
        <div>
          <p>营业总额</p>
          <p style="color: #333; font-size: 30px">
            <span style="font-size: 20px">￥</span
            >{{
              $lodash.get(totalList[0], 'claimForCompensationTotal', 0)
                | toThousands
            }}
          </p>
        </div>
        <div>
          <p>服务营业额</p>
          <span>{{
            $lodash.get(
              list,
              'claimForCompensationTotalBusinessTurnover.serviceTurnover',
              0
            ) | toThousands
          }}</span>
        </div>
        <div>
          <p>商品营业额</p>
          <span>{{
            $lodash.get(
              list,
              'claimForCompensationTotalBusinessTurnover.goodTurnover',
              0
            ) | toThousands
          }}</span>
        </div>
        <div>
          <p>提车单量</p>
          <span>{{
            $lodash.get(
              list,
              'claimForCompensationTotalBusinessTurnover.takeCarBillNum',
              0
            ) | toThousands
          }}</span>
        </div>
        <div>
          <p>进店单量</p>
          <span>{{
            $lodash.get(
              list,
              'claimForCompensationTotalBusinessTurnover.intoBillNum',
              0
            ) | toThousands
          }}</span>
        </div>
        <div>
          <p>结账单量</p>
          <span>{{
            $lodash.get(
              list,
              'claimForCompensationTotalBusinessTurnover.settleAccountsBillNum',
              0
            ) | toThousands
          }}</span>
        </div>
      </div>
      <div class="second infor-bt" style="background: #ecf8f8">理赔</div>
      <div class="source-number">
        <div>
          <p>营业总额</p>
          <p style="color: #333; font-size: 30px">
            <span style="font-size: 20px">￥</span
            >{{
              $lodash.get(totalList[0], 'claimSettlementTotal', 0) | toThousands
            }}
          </p>
        </div>
        <div>
          <p>服务营业额</p>
          <span>{{
            $lodash.get(
              list,
              'claimSettlementTotalBusinessTurnover.serviceTurnover',
              0
            ) | toThousands
          }}</span>
        </div>
        <div>
          <p>商品营业额</p>
          <span>{{
            $lodash.get(
              list,
              'claimSettlementTotalBusinessTurnover.goodTurnover',
              0
            ) | toThousands
          }}</span>
        </div>
        <div>
          <p>提车单量</p>
          <span>{{
            $lodash.get(
              list,
              'claimSettlementTotalBusinessTurnover.takeCarBillNum',
              0
            ) | toThousands
          }}</span>
        </div>
        <div>
          <p>进店单量</p>
          <span>{{
            $lodash.get(
              list,
              'claimSettlementTotalBusinessTurnover.intoBillNum',
              0
            ) | toThousands
          }}</span>
        </div>
        <div>
          <p>结账单量</p>
          <span>{{
            $lodash.get(
              list,
              'claimSettlementTotalBusinessTurnover.settleAccountsBillNum',
              0
            ) | toThousands
          }}</span>
        </div>
      </div>
      <!-- 这个柱状图位置 -->
      <!-- <div class="chart-table">
        <div class="chart-table-list">
          <div class="buttons-sty">
            <el-button
              @click="status = 'status0'"
              :class="{
                active: status == 'status0',
              }"
              >收入明细</el-button
            >
            <el-button
              @click="status = 'status1'"
              :class="{
                active: status == 'status1',
              }"
              >支出明细</el-button
            >
          </div>
          <div ref="chart1" style="width: 100%; height: 450px"></div>
        </div>

        <div class="chart-table-number">
          <p>收入明细详情</p>
          <div>
            <p>营业收入</p>
            <p class="blue">￥1，11999.00 33%</p>
          </div>
          <div>
            <p>会员收入</p>
            <p class="blue">￥1,22.301 33%</p>
          </div>
          <div>
            <p>卖废品</p>
            <p class="blue">￥1,785123.00 33%</p>
          </div>
          <div>
            <p>保险公司返利</p>
            <p class="blue">￥1,52364.00 33%</p>
          </div>
          <div>
            <p>挂账收入</p>
            <p class="blue">￥1,2364 33%</p>
          </div>
          <div>
            <p>股东押金</p>
            <p class="blue">￥1,2035548 33%</p>
          </div>
          <div>
            <p>定金收款</p>
            <p class="blue">￥1,52635 33%</p>
          </div>
        </div>
      </div>
      <el-table :data="tableData" style="width: 100%; margin: 28px 0px">
        <el-table-column prop="date" label="支付方式"> </el-table-column>
        <el-table-column prop="name" label="收入"> </el-table-column>
        <el-table-column prop="address" label="支出"> </el-table-column>
        <el-table-column prop="address" label="结余"> </el-table-column>
      </el-table> -->
    </div>
  </base-container>
</template>
<script>
// 工具
import * as echarts from 'echarts';
import { mapState } from 'vuex';
//现金流表接口
import { getBalanceList } from '@/api/financial/financialOver';
import { searchStore } from '@/api/customer/store';
export default {
  name: 'balanceWaterTable',
  data() {
    return {
      form: {},
      //搜索操作门店字段
      searchStoreLoading: false,
      storeList: [],
      //返回数据
      list: [],
      balanceTotal: '', //结余总额
      //营业总额字段
      totalList: [],
      tableData: [
        {
          date: '现金',
          name: '￥2000.3',
          address: '￥300.3',
        },
        {
          date: '现金',
          name: '￥2000.3',
          address: '￥300.3',
        },
        {
          date: '现金',
          name: '￥2000.3',
          address: '￥300.3',
        },
      ],
      status: 'status0',
      //柱状图
      chart1: '',
      chartOption1: {
        xAxis: {
          type: 'category',
          data: [
            '营业收入',
            '会员收入',
            '卖废品',
            '保险公司返利',
            '挂账收入',
            '股东押金',
            '定金收款',
          ],
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            data: [120, 200, 150, 80, 70, 110, 130],
            type: 'bar',
            barWidth: 40, //柱图宽度
          },
        ],
        color: ['#33ab79'],
      },
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  created() {
    this.pickTimes();
    this.getList();
    this.searchStore();
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.chart1 = echarts.init(this.$refs.chart1);
    //   this.chart1.setOption(this.chartOption1);
    // });
  },
  methods: {
    //置空输入框数据
    empty() {
      this.form.companyId = '';
    },
    //设置查询日期的默认时间
    pickTimes() {
      // const end = new Date();
      // const start = new Date();
      // start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      // this.form.times = [start, end];
      var tYear = new Date().getFullYear();
      var tMonth = new Date().getMonth();
      tMonth = tYear + '-' + this.doHandleZero(tMonth + 1) + '-' + '01';
      this.form.times = [tMonth, new Date().Format('yyyy-MM-dd')];
    },
    //下面是解决设置日期默认值时重新选择无法刷新日期的方法
    dateRangeChange() {
      let _this = this;
      _this.$nextTick(() => {
        _this.$forceUpdate();
      });
    },
    nowDate() {
      this.form.times = [
        new Date().Format('yyyy-MM-dd '),
        new Date().Format('yyyy-MM-dd '),
      ];
      this.dateRangeChange();
      this.getList();
    },
    // 补零函数
    doHandleZero(zero) {
      var date = zero;
      if (zero.toString().length == 1) {
        date = '0' + zero;
      }
      console.log(date);
      return date;
    },
    nowMonth() {
      var tYear = new Date().getFullYear();
      var tMonth = new Date().getMonth();
      tMonth = tYear + '-' + this.doHandleZero(tMonth + 1) + '-' + '01 ';
      this.form.times = [tMonth, new Date().Format('yyyy-MM-dd')];
      this.dateRangeChange();
      this.getList();
    },
    //远程搜索门店列表
    searchStore(keyWord = '') {
      this.searchStoreLoading = true;
      searchStore(keyWord, this.companyId)
        .then((res) => {
          this.storeList = res;
        })
        .finally(() => {
          this.searchStoreLoading = false;
        });
    },
    // 选择门店
    selectStore(storeId) {},
    //删除门店
    deleteStore() {},
    //获取数据
    getList() {
      this.totalList = [];
      var data = {
        companyId: this.form.companyId || this.companyId,
        startDate: this.form.times?.[0]
          ? this.form.times?.[0] + ' 00:00:00'
          : '',
        endDate: this.form.times?.[1] ? this.form.times?.[1] + ' 23:59:59' : '',
      };
      getBalanceList(data).then((res) => {
        this.list = res;
        this.balanceTotal =
          this.$lodash.cloneDeep(res.incomeTotal) -
          this.$lodash.cloneDeep(res.expenseTotal);
        //自费、包干、索赔、理赔的营业总额
        this.totalList.push({
          selfTotal:
            res.selfTotalBusinessTurnover.serviceTurnover +
            res.selfTotalBusinessTurnover.goodTurnover,
          contractTotal:
            res.contractTotalBusinessTurnover.serviceTurnover +
            res.contractTotalBusinessTurnover.goodTurnover,
          claimSettlementTotal:
            res.claimSettlementTotalBusinessTurnover.serviceTurnover +
            res.claimSettlementTotalBusinessTurnover.goodTurnover,
          claimForCompensationTotal:
            res.claimForCompensationTotalBusinessTurnover.serviceTurnover +
            res.claimForCompensationTotalBusinessTurnover.goodTurnover,
        });
        console.log('res', res);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep.base-container.default-padding > .main {
  padding: 30px 0px 0px !important;
}
//修改选择时间框长度
.el-form {
  width: 100%;
  .el-form-item__content > div {
    width: 100%;
  }
}
//改变base-container头部间距
.base-container.default-padding {
  padding: 10px 0px !important;
}
.last-type-sty {
  display: flex;
  .el-input {
    margin-left: 20px;
  }
}
.pay-type {
  width: 98%;
  margin: 20px 1%;
  display: flex;
  justify-content: space-between;
  .pay-type-list {
    display: flex;
    width: 30%;
    height: 136px;
    background: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(102, 102, 102, 0.1);
    border-radius: 4px;
    .type-incon {
      width: 30%;
      text-align: center;
      line-height: 136px;
      i {
        font-size: 60px;
        opacity: 0.1;
      }
    }
    .type-word {
      margin-top: 25px;
      width: 80%;
      p {
        font-size: 28px;
        &:first-child {
          font-size: 18px;
          margin-bottom: 13px;
        }
        &:last-child {
          color: #999999;
          font-size: 12px;
          margin-top: 8px;
        }
      }
      .number-word {
        font-family: Helvetica-Bold, Helvetica;
        font-weight: bold;
      }
    }
  }
}
.infor-bt {
  width: 87px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin-left: 1%;
  border: 0px;
  border-radius: 4px 4px 0px 0px;
}
.source-number {
  width: 98%;
  margin: 0px 1% 30px;
  display: flex;
  padding: 40px 0px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(153, 153, 153, 0.1);
  border-radius: 2px;
  div {
    width: 16%;
    &:first-child {
      width: 14%;
      border-right: 1px solid #e8e8e8;
      margin: 0px 24px;
    }
    p {
      line-height: 50px;
      color: #333;
    }
    span {
      font-size: 26px;
      color: #666666;
    }
    & + div p {
      color: #666;
    }
  }
}
.chart-table {
  width: 100%;
  height: 500px;
  display: flex;
  .chart-table-list {
    width: 80%;
    height: 500px;
    box-shadow: 0px 0px 10px 0px rgba(153, 153, 153, 0.1);
    .buttons-sty {
      text-align: center;
      margin: 10px 45%;
      width: 210px;
      height: 42px;
      background: #fcfeff;
      box-shadow: inset 0px 0px 1px 1px #f5f8fa, inset 1px 1px 1px 0px #ededed;
      border-radius: 100px;
      button {
        margin-top: 4px;
        width: 96px;
        height: 34px;
        border: 0px;
        border-radius: 17px;
        text-align: center;
        line-height: 12px;
      }
      .active {
        color: $primary;
        background: rgba(85, 183, 142, 0.1);
        border-radius: 17px;
      }
    }
  }
  .chart-table-number {
    width: 18%;
    height: 500px;
    margin-left: 1%;
    box-shadow: 0px 0px 10px 0px rgba(153, 153, 153, 0.1);
    P {
      text-align: center;
      line-height: 50px;
    }
    div {
      display: flex;
      width: 94%;
      margin-left: 3%;
      justify-content: space-between;
      border-bottom: 1px solid #d8d8d8;
      p:first-child {
        font-size: 14px;
        color: #666666;
      }
    }
  }
}
</style>
