var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-drawer",
    {
      attrs: {
        title: "检车单",
        visible: _vm.visibility,
        size: "60%",
        "append-to-body": true,
        "before-close": _vm.handleClose,
        modal: "",
        "close-on-press-escape": "",
        wrapperClosable: "",
      },
    },
    [
      _c("div", { staticClass: "content" }, [
        _vm.list.length > 0
          ? _c(
              "div",
              [
                _vm.showButton
                  ? _c(
                      "p",
                      {
                        staticStyle: {
                          "margin-bottom": "10px",
                          "font-size": "12px",
                          "font-family": "PingFangSC-Regular, PingFang SC",
                          "font-weight": "400",
                          color: "#606266",
                          "line-height": "17px",
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "iconfont grow-icon_warn_32 orange",
                          staticStyle: { "margin-right": "5px" },
                        }),
                        _vm._v("有检车项目暂未添加至报价单，请确认 "),
                      ]
                    )
                  : _vm._e(),
                _vm._l(_vm.list, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "flex-x-between listbox" },
                    [
                      _vm.billState == 0 && item.state != 0
                        ? _c("div", { staticClass: "icon-sty pointer" }, [
                            _c("i", {
                              staticClass: "icon newicon grow-icon-close",
                              on: {
                                click: function ($event) {
                                  return _vm.deleteEvent(index)
                                },
                              },
                            }),
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        [
                          _c("p", [
                            _vm._v(" " + _vm._s(item.serviceItemName)),
                            item.state == 0
                              ? _c("span", { staticClass: "primary" }, [
                                  _vm._v("(已报价)"),
                                ])
                              : _vm._e(),
                            item.state == 1
                              ? _c("span", { staticClass: "red" }, [
                                  _vm._v("(未报价)"),
                                ])
                              : _vm._e(),
                          ]),
                          _c(
                            "el-tooltip",
                            { attrs: { content: item.faultName } },
                            [
                              _c("p", [
                                _vm._v("故障:" + _vm._s(item.faultName)),
                              ]),
                            ]
                          ),
                          _c("p", [
                            _vm._v(
                              "检车人:" +
                                _vm._s(item.operatorName) +
                                " " +
                                _vm._s(item.lastUpdated)
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "flex-y-center",
                          staticStyle: { padding: "11px 39px 5px 0px" },
                        },
                        [
                          !_vm.$lodash.get(item, "faultPicList.length", 0)
                            ? _c(
                                "span",
                                {
                                  staticStyle: {
                                    width: "32px",
                                    "text-align": "center",
                                    color: "#606266",
                                  },
                                },
                                [_vm._v("无")]
                              )
                            : _c(
                                "el-popover",
                                {
                                  attrs: { placement: "top", trigger: "hover" },
                                },
                                [
                                  _c("upload-file-list", {
                                    attrs: {
                                      disabled: "",
                                      value: item.preImages,
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "video-list" },
                                    _vm._l(
                                      item.preVideos,
                                      function (src, index) {
                                        return _c(
                                          "video",
                                          {
                                            key: index,
                                            staticClass: "thumbnail-video",
                                            attrs: {
                                              preload: "auto",
                                              src: src,
                                              poster: _vm.playImg,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleClickVideo(
                                                  $event,
                                                  item.preVideos,
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("source", {
                                              attrs: {
                                                src: src,
                                                type: "video/mp4",
                                              },
                                            }),
                                            _vm._v(
                                              " 您的设备不支持 video 标签。 "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                  _c(
                                    "template",
                                    { slot: "reference" },
                                    [
                                      _c(
                                        "el-badge",
                                        {
                                          staticStyle: {
                                            "margin-right": "8px",
                                          },
                                          attrs: {
                                            value: item.faultPicList.length,
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "thumbnail-img",
                                            attrs: { src: item.prePoster },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                _vm.billState == 0 && _vm.showButton
                  ? _c(
                      "div",
                      { staticClass: "footer" },
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "auth",
                                rawName: "v-auth",
                                value: 4,
                                expression: "4",
                              },
                            ],
                            attrs: { size: "small", type: "primary" },
                            on: { click: _vm.editCarBill },
                          },
                          [_vm._v("确定报价")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              2
            )
          : _c("div", [
              _c("p", { staticClass: "flex-x-center" }, [
                _c("img", {
                  attrs: { src: require("@/assets/img/noCarInspection.png") },
                }),
              ]),
              _c(
                "p",
                {
                  staticClass: "flex-x-center",
                  staticStyle: {
                    "font-size": "14px",
                    "font-family": "PingFangSC-Regular, PingFang SC",
                    "font-weight": "400",
                    color: "#84878c",
                    "line-height": "20px",
                    "letter-spacing": "2px",
                  },
                },
                [_vm._v(" 暂无检车项～ ")]
              ),
            ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.deleteDialog,
            width: "25%",
            top: "15%",
            "before-close": _vm.handleCloseDelete,
            "close-on-click-modal": false,
            modal: false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.deleteDialog = $event
            },
          },
        },
        [
          _c("div", { staticClass: "prompt-sty" }, [
            _c("p", [_vm._v("确认删除此项")]),
          ]),
          _c("template", { slot: "footer" }, [
            _c(
              "div",
              { staticStyle: { "text-align": "center" } },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small" },
                    on: {
                      click: function ($event) {
                        _vm.deleteDialog = false
                      },
                    },
                  },
                  [_vm._v("取 消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: { click: _vm.determine },
                  },
                  [_vm._v("确 定")]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _vm.showVideoPlayerDialog
        ? _c("videoPlayerDialog", {
            attrs: {
              visibility: _vm.showVideoPlayerDialog,
              videos: _vm.previewVideos,
              index: _vm.previewVideoIndex,
              modal: false,
            },
            on: {
              "update:visibility": function ($event) {
                _vm.showVideoPlayerDialog = $event
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }