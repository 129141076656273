var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { ref: "baseContainer", attrs: { paddingTop: "" } },
    [
      [
        _c(
          "el-form",
          {
            staticClass: "filter-form",
            attrs: { model: _vm.form, "label-width": "100px", size: "small" },
          },
          [
            _c("div", { staticClass: "flex-x-between" }, [
              _c(
                "div",
                { staticClass: "flex flex-shrink0" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "服务名称" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入服务名称", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.getData.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "服务分类" } },
                    [
                      _c("select-service-category", {
                        attrs: {
                          value: [
                            _vm.form.firstCategoryId,
                            _vm.form.secondCategoryId,
                          ],
                          clearBol: _vm.clearBol,
                        },
                        on: {
                          select: _vm.handleSelectServiceCategory,
                          clearBol: _vm.clearBolSon,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "启用状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择启用状态" },
                          model: {
                            value: _vm.form.isActive,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "isActive", $$v)
                            },
                            expression: "form.isActive",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "已启用", value: true },
                          }),
                          _c("el-option", {
                            attrs: { label: "未启用", value: false },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "el-form-item--small" }, [
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "btn_search",
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.getData },
                      },
                      [_vm._v("查询")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn_search",
                        attrs: { size: "small" },
                        on: { click: _vm.empty },
                      },
                      [_vm._v("置空")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        ),
      ],
      [
        _c(
          "div",
          {
            staticClass: "btns flex",
            staticStyle: { margin: "10px 0 14px 0" },
          },
          [
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 54,
                    expression: "54",
                  },
                ],
                staticClass: "btn_insert",
                attrs: { size: "small" },
                on: { click: _vm.addServiceManagement },
              },
              [_vm._v(" 新增 ")]
            ),
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 54,
                    expression: "54",
                  },
                ],
                attrs: { size: "small" },
                on: { click: _vm.batchImport },
              },
              [
                _vm._v(" 批量导入"),
                _c("i", {
                  staticClass: "el-icon-arrow-right",
                  staticStyle: { "margin-left": "10px" },
                }),
              ]
            ),
          ],
          1
        ),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "fixedScrollBar",
                rawName: "v-fixedScrollBar",
                value: _vm.$refs.baseContainer,
                expression: "$refs.baseContainer",
              },
            ],
            staticClass: "custom-table",
            attrs: { data: _vm.serviceListData, border: "" },
            on: {
              "selection-change": _vm.handleSelectionChange,
              "row-dblclick": _vm.handleEdit,
            },
          },
          [
            _c("el-table-column", {
              attrs: { type: "selection", width: "55" },
            }),
            _c("el-table-column", {
              attrs: { type: "index", label: "序号", width: "60" },
            }),
            _c("el-table-column", {
              attrs: { label: "操作", width: "60" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "auth",
                              rawName: "v-auth",
                              value: 53,
                              expression: "53",
                            },
                          ],
                          staticClass: "text_operation blue",
                          on: {
                            click: function ($event) {
                              return _vm.handleEdit(scope.row)
                            },
                          },
                        },
                        [_vm._v("编辑")]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "170", label: "服务名称", prop: "name" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [_c("p", [_vm._v(_vm._s(row.name))])]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                width: "130",
                label: "服务编码",
                prop: "serviceItemCode",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [_c("p", [_vm._v(_vm._s(row.serviceItemCode))])]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                width: "170",
                label: "关联商品品名",
                prop: "productNames",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [_c("p", [_vm._v(_vm._s(row.productNames))])]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                width: "120",
                label: "服务时效(H)",
                prop: "serviceTime",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [_c("p", [_vm._v(_vm._s(row.serviceTime))])]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "120", label: "单价(元/H)", prop: "unitPrice" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [_c("p", [_vm._v(_vm._s(row.unitPrice))])]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "120", label: "标准价(元)", prop: "price" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [_c("p", [_vm._v(_vm._s(row.price))])]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "120", label: "人力成本", prop: "humanCost" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [_c("p", [_vm._v(_vm._s(row.humanCost))])]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                width: "80",
                label: "工种",
                prop: "constructionTypeEnumOption",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("p", [
                        _vm._v(_vm._s(row.constructionTypeEnumOption.desc)),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                width: "120",
                label: "施工难度",
                prop: "constructionDifficultyOption",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("p", [
                        _vm._v(_vm._s(row.constructionDifficultyOption.desc)),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                width: "120",
                label: "施工工时费用",
                prop: "constructionTimePrice",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("p", [_vm._v(_vm._s(row.constructionTimePrice))]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "150", label: "服务分类" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("p", [
                        _vm._v(
                          _vm._s(row.firstCategoryName) +
                            " / " +
                            _vm._s(row.secondCategoryName)
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { "min-width": "160", label: "备注", prop: "description" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [_c("p", [_vm._v(_vm._s(row.description))])]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                "min-width": "200",
                label: "创建时间",
                prop: "dateCreated",
              },
            }),
            _c("el-table-column", {
              attrs: {
                "min-width": "200",
                label: "更新时间",
                prop: "lastUpdated",
              },
            }),
            _c("el-table-column", {
              attrs: { "min-width": "160", label: "创建人", prop: "creator" },
            }),
            _c("el-table-column", {
              attrs: { "min-width": "160", label: "更新人", prop: "updater" },
            }),
          ],
          1
        ),
        _vm.showServiceManagementDialog
          ? _c("service-management-dialog", {
              attrs: {
                showServiceManagementDialog: _vm.showServiceManagementDialog,
                id: _vm.editId,
                detail: _vm.editDetail,
              },
              on: {
                "update:showServiceManagementDialog": function ($event) {
                  _vm.showServiceManagementDialog = $event
                },
                "update:show-service-management-dialog": function ($event) {
                  _vm.showServiceManagementDialog = $event
                },
                save: _vm.getData,
              },
            })
          : _vm._e(),
        _vm.showImportDialog
          ? _c("import-dialog", {
              attrs: {
                visibility: _vm.showImportDialog,
                title: "服务导入",
                uploadFileApi: _vm.importDataApi,
                templateFileUrl: _vm.templateFileUrl,
                importResult: _vm.importResult,
                importFailDetail: _vm.serviceItemImportMessage,
              },
              on: {
                "update:visibility": function ($event) {
                  _vm.showImportDialog = $event
                },
              },
            })
          : _vm._e(),
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c("pagination", {
            attrs: { total: _vm.total },
            on: { pageChange: _vm.changePage },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }