var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "printDom", staticClass: "print-template" }, [
    _c("div", { ref: "print" }, [
      _c("div", { staticClass: "print-template__header" }, [
        _c("h1", { staticClass: "print-template__header__title" }, [
          _vm._v(" " + _vm._s(_vm.billMessage.operateCompanyName) + " "),
        ]),
        _c(
          "p",
          {
            staticStyle: {
              "margin-top": "5px",
              "font-size": "18px",
              "text-align": "center",
            },
          },
          [_vm._v(" " + _vm._s(_vm.config.title) + " ")]
        ),
        _vm.config.leftImagePath
          ? _c("img", {
              staticClass: "print-template__header__lt-img",
              attrs: { src: _vm.config.leftImagePath },
            })
          : _vm._e(),
        _vm.config.rightImagePath
          ? _c("img", {
              staticClass: "print-template__header__rt-img",
              attrs: { src: _vm.config.rightImagePath },
            })
          : _vm._e(),
      ]),
      _c(
        "div",
        {
          staticClass: "print-template__date",
          staticStyle: { "font-size": "12px" },
        },
        [_vm._v(" 打印时间：" + _vm._s(_vm.printTime) + " ")]
      ),
      _c("div", { staticClass: "print-template__user" }, [
        _c("h3", { ref: "clientName" }, [
          _vm._v("客户：" + _vm._s(_vm.carInfo.clientName)),
        ]),
        _c("h3", [_vm._v("接车单号：" + _vm._s(_vm.billMessage.billCode))]),
      ]),
      _c("div", { staticClass: "print-template__box__border" }, [
        _c("table", { staticClass: "print-template__box__border__table" }, [
          _c("tbody", [
            _vm.billType === 2
              ? _c("tr", [
                  _c(
                    "td",
                    { staticStyle: { width: "85px", "text-align": "left" } },
                    [_vm._v("消费客户")]
                  ),
                  _c("td", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.carBillInsuranceInfo.claimsInsuranceCompanyName
                        ) +
                        " "
                    ),
                  ]),
                  _c(
                    "td",
                    { staticStyle: { width: "85px", "text-align": "left" } },
                    [_vm._v("报案号")]
                  ),
                  _c("td", [
                    _vm._v(
                      " " + _vm._s(_vm.carBillInsuranceInfo.caseNum) + " "
                    ),
                  ]),
                  _c(
                    "td",
                    { staticStyle: { width: "85px", "text-align": "left" } },
                    [_vm._v("事故责任")]
                  ),
                  _c("td", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("dict")(
                            _vm.carBillInsuranceInfo.dutyType,
                            "dutyType"
                          )
                        ) +
                        " "
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.billType === 3
              ? _c("tr", [
                  _c(
                    "td",
                    { staticStyle: { width: "85px", "text-align": "left" } },
                    [_vm._v("消费客户")]
                  ),
                  _c("td", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.carBillUndertakeInfo.undertakeInsuranceCompanyName
                        ) +
                        " "
                    ),
                  ]),
                  _c(
                    "td",
                    { staticStyle: { width: "85px", "text-align": "left" } },
                    [_vm._v("索赔单号")]
                  ),
                  _c("td", { attrs: { colspan: "3" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.carBillUndertakeInfo.claimsNum.join("、")) +
                        " "
                    ),
                  ]),
                ])
              : _vm._e(),
            _c("tr", [
              _c(
                "td",
                { staticStyle: { width: "85px", "text-align": "left" } },
                [_vm._v("送修司机")]
              ),
              _c(
                "td",
                { staticStyle: { width: "165px", "text-align": "left" } },
                [_vm._v(" " + _vm._s(_vm.billMessage.customerName) + " ")]
              ),
              _c(
                "td",
                { staticStyle: { width: "100px", "text-align": "left" } },
                [_vm._v("送修司机电话")]
              ),
              _c(
                "td",
                { staticStyle: { width: "179px", "text-align": "left" } },
                [_vm._v(" " + _vm._s(_vm.billMessage.customerPhone) + " ")]
              ),
              _c(
                "td",
                { staticStyle: { width: "85px", "text-align": "left" } },
                [_vm._v("车牌号")]
              ),
              _c(
                "td",
                { staticStyle: { width: "162px", "text-align": "left" } },
                [_vm._v(" " + _vm._s(_vm.carInfo.carNumber) + " ")]
              ),
            ]),
            _c("tr", [
              _c(
                "td",
                { staticStyle: { width: "85px", "text-align": "left" } },
                [_vm._v("车型")]
              ),
              _c(
                "td",
                { staticStyle: { width: "165px", "text-align": "left" } },
                [_vm._v(" " + _vm._s(_vm.carInfo.brandName) + " ")]
              ),
              _c(
                "td",
                { staticStyle: { width: "90px", "text-align": "left" } },
                [_vm._v("车架号")]
              ),
              _c(
                "td",
                { staticStyle: { width: "179px", "text-align": "left" } },
                [_vm._v(" " + _vm._s(_vm.carInfo.uniqueId) + " ")]
              ),
              _c(
                "td",
                { staticStyle: { width: "85px", "text-align": "left" } },
                [_vm._v("发动机号")]
              ),
              _c(
                "td",
                { staticStyle: { width: "162px", "text-align": "left" } },
                [_vm._v(" " + _vm._s(_vm.carInfo.engineNumber) + " ")]
              ),
            ]),
            _c("tr", [
              _c(
                "td",
                { staticStyle: { width: "85px", "text-align": "left" } },
                [_vm._v("接车员")]
              ),
              _c(
                "td",
                { staticStyle: { width: "165px", "text-align": "left" } },
                [_vm._v(" " + _vm._s(_vm.billMessage.pickUpUserName) + " ")]
              ),
              _c(
                "td",
                { staticStyle: { width: "90px", "text-align": "left" } },
                [_vm._v("进店时间")]
              ),
              _c(
                "td",
                { staticStyle: { width: "179px", "text-align": "left" } },
                [_vm._v(" " + _vm._s(_vm.billMessage.dateCreated) + " ")]
              ),
              _c(
                "td",
                { staticStyle: { width: "85px", "text-align": "left" } },
                [_vm._v("进店里程")]
              ),
              _c(
                "td",
                { staticStyle: { width: "162px", "text-align": "left" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.billMessage.mileage
                          ? _vm.billMessage.mileage + "km"
                          : ""
                      ) +
                      " "
                  ),
                ]
              ),
            ]),
            _c("tr", [
              _c(
                "td",
                { staticStyle: { width: "85px", "text-align": "left" } },
                [_vm._v("结账收银员")]
              ),
              _c(
                "td",
                { staticStyle: { width: "165px", "text-align": "left" } },
                [_vm._v(" " + _vm._s(_vm.cashier) + " ")]
              ),
              _c(
                "td",
                { staticStyle: { width: "90px", "text-align": "left" } },
                [_vm._v("结账时间")]
              ),
              _c("td", { staticStyle: { "text-align": "left" } }, [
                _vm._v(" " + _vm._s(_vm.billMessage.checkDate) + " "),
              ]),
              _c(
                "td",
                { staticStyle: { width: "90px", "text-align": "left" } },
                [_vm._v("提车时间")]
              ),
              _c("td", { staticStyle: { "text-align": "left" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.billMessage.takeTime || _vm.billMessage.checkDate
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm.serviceList && _vm.serviceList.length
        ? _c("div", { staticClass: "print-template__box__border" }, [
            _c("h3", { staticClass: "print-template__title" }, [
              _vm._v("服务明细"),
            ]),
            _c("table", { staticClass: "print-template__box__border__table" }, [
              _vm._m(0),
              _c(
                "tbody",
                [
                  _vm._l(_vm.serviceList, function (item, index) {
                    return _c("tr", { key: index }, [
                      _c("td", { staticStyle: { "text-align": "center" } }, [
                        _vm._v(" " + _vm._s(index + 1) + " "),
                      ]),
                      _c("td", { staticStyle: { "text-align": "left" } }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                      _c("td", { staticStyle: { "text-align": "right" } }, [
                        _vm._v(" " + _vm._s(item.num) + " "),
                      ]),
                      _c("td", { staticStyle: { "text-align": "right" } }, [
                        _vm._v(
                          " " + _vm._s(_vm._f("toThousands")(item.price)) + " "
                        ),
                      ]),
                      _c("td", { staticStyle: { "text-align": "right" } }, [
                        _vm._v(
                          " " +
                            _vm._s(item.discount ? item.discount : "无") +
                            " "
                        ),
                      ]),
                      _c("td", { staticStyle: { "text-align": "right" } }, [
                        _vm._v(
                          " " + _vm._s(_vm._f("toThousands")(item.total)) + " "
                        ),
                      ]),
                    ])
                  }),
                  _c("tr", { staticStyle: { "text-align": "right" } }, [
                    _c(
                      "td",
                      {
                        staticStyle: { padding: "4px" },
                        attrs: { colspan: 6 },
                      },
                      [
                        _c(
                          "b",
                          {
                            staticClass:
                              "print-template__box__border__table__total text-right",
                            staticStyle: { "font-weight": "600" },
                          },
                          [
                            _vm._v(
                              "折前服务费合计：" +
                                _vm._s(
                                  _vm._f("toThousands")(
                                    _vm.beforeTotalServicePrice
                                  )
                                )
                            ),
                          ]
                        ),
                        _c(
                          "b",
                          {
                            staticClass:
                              "print-template__box__border__table__total text-right",
                            staticStyle: { "font-weight": "600" },
                          },
                          [
                            _vm._v(
                              "折后服务费合计：" +
                                _vm._s(
                                  _vm._f("toThousands")(
                                    _vm.afterTotalServicePrice
                                  )
                                )
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ],
                2
              ),
            ]),
          ])
        : _vm._e(),
      _vm.goodsList && _vm.goodsList.length
        ? _c("div", { staticClass: "print-template__box__border" }, [
            _c(
              "h3",
              { ref: "materialTitle", staticClass: "print-template__title" },
              [_vm._v("材料明细")]
            ),
            _c(
              "table",
              {
                ref: "materialTable",
                staticClass: "print-template__box__border__table",
              },
              [
                _c("thead", [
                  _c("tr", { ref: "goodTr" }, [
                    _c(
                      "th",
                      {
                        staticStyle: { width: "32px", "text-align": "center" },
                      },
                      [_vm._v("序号")]
                    ),
                    _c(
                      "th",
                      {
                        staticStyle: {
                          "min-width": "176px",
                          "text-align": "center",
                        },
                      },
                      [_vm._v("商品名称")]
                    ),
                    _c(
                      "th",
                      {
                        staticStyle: { width: "75px", "text-align": "center" },
                      },
                      [_vm._v("数量")]
                    ),
                    _c(
                      "th",
                      {
                        staticStyle: { width: "70px", "text-align": "center" },
                      },
                      [_vm._v("单价")]
                    ),
                    _c(
                      "th",
                      {
                        staticStyle: { width: "40px", "text-align": "center" },
                      },
                      [_vm._v("折扣")]
                    ),
                    _c(
                      "th",
                      {
                        staticStyle: { width: "75px", "text-align": "center" },
                      },
                      [_vm._v("小计")]
                    ),
                  ]),
                ]),
                _c(
                  "tbody",
                  [
                    _vm._l(_vm.goodsList, function (item, index) {
                      return _c("tr", { key: index }, [
                        _c("td", { staticStyle: { "text-align": "center" } }, [
                          _vm._v(" " + _vm._s(index + 1) + " "),
                        ]),
                        _c("td", { staticStyle: { "text-align": "left" } }, [
                          _vm._v(_vm._s(item.name)),
                        ]),
                        _c("td", { staticStyle: { "text-align": "right" } }, [
                          _vm._v(_vm._s(item.num)),
                        ]),
                        _c("td", { staticStyle: { "text-align": "right" } }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("toThousands")(item.price)) +
                              " "
                          ),
                        ]),
                        _c("td", { staticStyle: { "text-align": "right" } }, [
                          _vm._v(
                            " " +
                              _vm._s(item.discount ? item.discount : "无") +
                              " "
                          ),
                        ]),
                        _c("td", { staticStyle: { "text-align": "right" } }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("toThousands")(item.total)) +
                              " "
                          ),
                        ]),
                      ])
                    }),
                    _c("tr", { staticStyle: { "text-align": "right" } }, [
                      _c(
                        "td",
                        {
                          staticStyle: { padding: "4px" },
                          attrs: { colspan: 6 },
                        },
                        [
                          _c(
                            "b",
                            {
                              staticClass:
                                "print-template__box__border__table__total",
                              staticStyle: { "font-weight": "600" },
                            },
                            [
                              _vm._v(
                                "折前材料费合计：" +
                                  _vm._s(
                                    _vm._f("toThousands")(
                                      _vm.beforeTotalGoodsPrice
                                    )
                                  )
                              ),
                            ]
                          ),
                          _c(
                            "b",
                            {
                              staticClass:
                                "print-template__box__border__table__total",
                              staticStyle: { "font-weight": "600" },
                            },
                            [
                              _vm._v(
                                "折后材料费合计：" +
                                  _vm._s(
                                    _vm._f("toThousands")(
                                      _vm.afterTotalGoodsPrice
                                    )
                                  )
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ],
                  2
                ),
              ]
            ),
          ])
        : _vm._e(),
      _c("div", { staticClass: "print-template__common" }, [
        _c(
          "div",
          {
            staticClass:
              "print-template__box__border print-template__common__column",
          },
          [
            _c(
              "h3",
              { ref: "receivableTitle", staticClass: "print-template__title" },
              [_vm._v("应收明细")]
            ),
            _c(
              "table",
              {
                ref: "receivableTable",
                staticClass: "print-template__box__border__table",
              },
              [
                _vm._m(1),
                _c("tbody", [
                  _c("tr", [
                    _c("td", { staticStyle: { "text-align": "center" } }, [
                      _vm._v("1"),
                    ]),
                    _c("td", { staticStyle: { "text-align": "left" } }, [
                      _vm._v("工时费"),
                    ]),
                    _c("td", { staticStyle: { "text-align": "right" } }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("toThousands")(_vm.serviceTotalPrice)) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", { staticStyle: { "text-align": "center" } }, [
                      _vm._v("2"),
                    ]),
                    _c("td", { staticStyle: { "text-align": "left" } }, [
                      _vm._v("材料费"),
                    ]),
                    _c("td", { staticStyle: { "text-align": "right" } }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("toThousands")(_vm.goodsTotalPrice)) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", { staticStyle: { "text-align": "center" } }, [
                      _vm._v("3"),
                    ]),
                    _c("td", { staticStyle: { "text-align": "left" } }, [
                      _vm._v("优惠金额"),
                    ]),
                    _c("td", { staticStyle: { "text-align": "right" } }, [
                      _vm._v(
                        " -" +
                          _vm._s(_vm._f("toThousands")(_vm.discountAmount)) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      {
                        staticStyle: {
                          "text-align": "right",
                          "font-weight": "600",
                        },
                        attrs: { colspan: 3 },
                      },
                      [
                        _vm._v(
                          " 应收合计(大写): " +
                            _vm._s(_vm._f("toThousands")(_vm.needPay)) +
                            " (" +
                            _vm._s(_vm._f("amountToChinese")(_vm.needPay)) +
                            ") "
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "print-template__box__border",
            staticStyle: { "border-bottom": "none", width: "45%" },
          },
          [
            _c(
              "h3",
              { ref: "chargeTitle", staticClass: "print-template__title" },
              [_vm._v("收费结算")]
            ),
            _c(
              "table",
              {
                ref: "chargeTable",
                staticClass: "print-template__box__border__table",
              },
              [
                _c(
                  "tbody",
                  [
                    _vm._m(2),
                    _vm._l(_vm.paidList, function (item, index) {
                      return _c("tr", { key: index }, [
                        _c("td", { staticStyle: { "text-align": "center" } }, [
                          _vm._v(_vm._s(index + 1)),
                        ]),
                        _c("td", { staticStyle: { "text-align": "left" } }, [
                          _vm._v(_vm._s(item.payType)),
                        ]),
                        _c("td", { staticStyle: { "text-align": "right" } }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("toThousands")(item.money)) +
                              " "
                          ),
                        ]),
                      ])
                    }),
                    _c("tr", [
                      _c(
                        "td",
                        {
                          staticStyle: {
                            "text-align": "right",
                            "font-weight": "600",
                          },
                          attrs: { colspan: 3 },
                        },
                        [
                          _vm._v(
                            " 实收合计(大写): " +
                              _vm._s(_vm._f("toThousands")(_vm.paidTotal)) +
                              " (" +
                              _vm._s(_vm._f("amountToChinese")(_vm.paidTotal)) +
                              ") "
                          ),
                        ]
                      ),
                    ]),
                    _c("tr", [
                      _c(
                        "td",
                        {
                          staticStyle: {
                            "text-align": "right",
                            "font-weight": "600",
                          },
                          attrs: { colspan: 3 },
                        },
                        [
                          _vm._v(
                            " 欠收合计(大写): " +
                              _vm._s(_vm._f("toThousands")(_vm.needPaidTotal)) +
                              " (" +
                              _vm._s(
                                _vm._f("amountToChinese")(_vm.needPaidTotal)
                              ) +
                              ") "
                          ),
                        ]
                      ),
                    ]),
                  ],
                  2
                ),
              ]
            ),
          ]
        ),
      ]),
      _c(
        "div",
        { ref: "signature", staticClass: "print-template__signature" },
        [
          _c("b", { staticClass: "print-template__title" }, [
            _vm._v("服务顾问签名："),
          ]),
          _c("b", { staticClass: "print-template__title" }, [
            _vm._v("客户签名："),
          ]),
        ]
      ),
      _c("div", { staticClass: "print-template__contact" }, [
        _c(
          "div",
          {
            staticClass: "print-template__contact__item fontsize",
            staticStyle: { width: "60%" },
          },
          [_vm._v(" 联系地址：" + _vm._s(_vm.billMessage.companyAddress) + " ")]
        ),
        _c("div", { staticClass: "print-template__contact__item fontsize" }, [
          _vm._v(" 联系电话：" + _vm._s(_vm.billMessage.telephone) + " "),
          _c("span", { staticStyle: { "margin-left": "30px" } }, [
            _vm._v(_vm._s(_vm.billMessage.alternativeTelephone)),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "32px", "text-align": "center" } }, [
          _vm._v("序号"),
        ]),
        _c(
          "th",
          { staticStyle: { "min-width": "130px", "text-align": "center" } },
          [_vm._v("服务名称")]
        ),
        _c("th", { staticStyle: { width: "65px", "text-align": "center" } }, [
          _vm._v("工时"),
        ]),
        _c("th", { staticStyle: { width: "70px", "text-align": "center" } }, [
          _vm._v("单价"),
        ]),
        _c("th", { staticStyle: { width: "40px", "text-align": "center" } }, [
          _vm._v("折扣"),
        ]),
        _c("th", { staticStyle: { width: "75px", "text-align": "center" } }, [
          _vm._v("小计"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "32px", "text-align": "center" } }, [
          _vm._v("序号"),
        ]),
        _c(
          "th",
          { staticStyle: { "min-width": "180px", "text-align": "center" } },
          [_vm._v("名称")]
        ),
        _c("th", { staticStyle: { width: "74px", "text-align": "center" } }, [
          _vm._v("金额"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", { staticStyle: { width: "32px", "text-align": "center" } }, [
        _vm._v("序号"),
      ]),
      _c(
        "th",
        { staticStyle: { "min-width": "180px", "text-align": "center" } },
        [_vm._v("支付方式")]
      ),
      _c("th", { staticStyle: { "text-align": "center", width: "74px" } }, [
        _vm._v("金额"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }