var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "编辑车单",
        visible: _vm.visibility,
        width: "200",
        "before-close": _vm.handleClose,
        "append-to-body": "",
        "close-on-click-modal": false,
      },
    },
    [
      _c(
        "el-form",
        { attrs: { "label-width": "120px", size: "small" } },
        [
          _c("el-form-item", { attrs: { label: "消费客户" } }, [
            _c("div", { staticClass: "color626" }, [
              _c("span", [_vm._v(_vm._s(_vm.pageClientInfo.consumerName))]),
              _c("span", { staticClass: "el-icon--right" }, [
                _vm._v(_vm._s(_vm.pageClientInfo.consumerPhone)),
              ]),
              _vm.billType === 0
                ? _c(
                    "span",
                    {
                      staticClass: "pointer blue el-icon--right",
                      on: {
                        click: function ($event) {
                          _vm.selectOtherPayClientDialogVisibility = true
                        },
                      },
                    },
                    [_vm._v("重选")]
                  )
                : _vm._e(),
            ]),
          ]),
          _c(
            "el-form-item",
            [
              _c("template", { slot: "label" }, [
                _c("div", { staticStyle: { "line-height": "18px" } }, [
                  _c("div", [_vm._v("车单备注")]),
                  _c(
                    "span",
                    {
                      staticClass: "color626",
                      staticStyle: { "font-size": "12px" },
                    },
                    [_vm._v("(客户不可见)")]
                  ),
                ]),
              ]),
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 4,
                  placeholder: "请输入车单备注",
                },
                model: {
                  value: _vm.pageRemark,
                  callback: function ($$v) {
                    _vm.pageRemark = $$v
                  },
                  expression: "pageRemark",
                },
              }),
            ],
            2
          ),
          _c(
            "el-form-item",
            { attrs: { label: "进店里程", prop: "mileage" } },
            [
              _c(
                "number-input",
                {
                  attrs: {
                    max: 999999,
                    enabledZero: false,
                    enabledDecimals: false,
                    clearable: "",
                  },
                  model: {
                    value: _vm.form.mileage,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "mileage", $$v)
                    },
                    expression: "form.mileage",
                  },
                },
                [_c("template", { slot: "append" }, [_vm._v("KM")])],
                2
              ),
            ],
            1
          ),
          _vm.isSpecificClient
            ? _c(
                "el-form-item",
                { attrs: { label: "录单情况", prop: "doubleSysInputState" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "双系统录单情况" },
                      model: {
                        value: _vm.form.doubleSysInputState,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "doubleSysInputState", $$v)
                        },
                        expression: "form.doubleSysInputState",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "双系统待录单", value: 1 },
                      }),
                      _c("el-option", {
                        attrs: { label: "双系统已录单", value: 2 },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isSpecificClient
            ? _c(
                "el-form-item",
                { attrs: { label: "双系统单号" } },
                _vm._l(_vm.doubleSysNumArr, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "double-num-item" },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            clearable: "",
                            placeholder: "请输入双系统单号",
                            size: "small",
                          },
                          model: {
                            value: item.value,
                            callback: function ($$v) {
                              _vm.$set(item, "value", $$v)
                            },
                            expression: "item.value",
                          },
                        },
                        [
                          _c("template", { slot: "append" }, [
                            index === 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "blue pointer",
                                    on: { click: _vm.addDoubleSysNum },
                                  },
                                  [_vm._v(" +添加更多 ")]
                                )
                              : _vm._e(),
                            index !== 0
                              ? _c("i", {
                                  staticClass:
                                    "iconfont grow-icon_delete3 pointer orange",
                                  staticStyle: { "margin-right": "12px" },
                                  attrs: { title: "点击删除" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.delDoubleSysNum(index)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.handleClose } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.save },
            },
            [_vm._v(" 确 定 ")]
          ),
        ],
        1
      ),
      _vm.selectOtherPayClientDialogVisibility
        ? _c("select-other-pay-client-dialog", {
            attrs: { visibility: _vm.selectOtherPayClientDialogVisibility },
            on: {
              "update:visibility": function ($event) {
                _vm.selectOtherPayClientDialogVisibility = $event
              },
              select: _vm.handleSelectClient,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }