import { render, staticRenderFns } from "./HomeView.vue?vue&type=template&id=0643b98d&scoped=true"
import script from "./HomeView.vue?vue&type=script&lang=js"
export * from "./HomeView.vue?vue&type=script&lang=js"
import style0 from "./HomeView.vue?vue&type=style&index=0&id=0643b98d&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0643b98d",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/jiaxu/Documents/code/geyue-back-end-management-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0643b98d')) {
      api.createRecord('0643b98d', component.options)
    } else {
      api.reload('0643b98d', component.options)
    }
    module.hot.accept("./HomeView.vue?vue&type=template&id=0643b98d&scoped=true", function () {
      api.rerender('0643b98d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/page/home/HomeView.vue"
export default component.exports