<template>
  <el-dialog
    top="5vh"
    width="60%"
    :visible="showCarModelsDialog"
    :before-close="handleClose"
    append-to-body
  >
    <ul class="first-letter">
      <li
        v-for="item in Object.keys(carList)"
        :key="item"
        :class="{ active: item == selectedLetter }"
        @click="selectLetter(item)"
      >
        {{ item }}
      </li>
    </ul>
    <div class="carlist-st">
      <ul>
        <li
          v-for="(item, index) in carLogoList"
          :key="index"
          @click="selectCarLogo(item)"
          class="pointer"
        >
          <img :src="item.imagePath" /><span>{{ item.name }}</span>
        </li>
      </ul>
    </div>

    <div style="height: 50vh; overflow: auto; margin-top: 30px">
      <ul>
        <li
          v-for="(series, index) in carBrandList"
          :key="series.id"
          style="margin-bottom: 30px"
          :name="series.name"
          :index="index"
          ref="seriesNameRef"
          @click="selectCar(index)"
        >
          <span style="font-weight: 600">{{ series.name }}</span>
          <hr />
          <ul class="model-lise">
            <li
              v-for="(model, index) in series.children"
              :key="model.id"
              @click="selectedEvent(series, model, index)"
              @dblclick="confirmSelect"
              :class="{ actives: model.id == selectedModel }"
            >
              {{ model.name }}
            </li>
          </ul>
          <div style="display: block" v-if="index == liIndex">
            <ul>
              <li
                v-for="carModel in carDetailList"
                :key="carModel.id"
                style="margin-bottom: 30px"
              >
                <span style="font-weight: 600">{{ carModel.name }}</span>
                <hr />
                <ul class="model-lise">
                  <li
                    v-for="years in carModel.years"
                    :key="years.modelYearId"
                    @click="selectedCarDetail(carModel, years)"
                    @dblclick="confirmSelect"
                    :class="{ actives: years.modelYearId == selsectYears }"
                  >
                    {{ years.name }}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
    <template slot="title">
      <p>
        选择车型&nbsp;&nbsp;<span style="font-size: 12px; color: #999"
          >(可双击选中)</span
        >
      </p>
    </template>
    <template slot="footer">
      <div class="footer-sty">
        <div class="car-tip">
          已选：{{ modelDetailLogoName }}&nbsp;&nbsp;
          {{ modelDetailSeriesName }}&nbsp;&nbsp;
          {{ modelDetailModelNumberName }}&nbsp;&nbsp;
          {{ modelDetailModelYearName }}
        </div>
        <el-button size="small" type="primary" @click="confirmSelect"
          >确 定</el-button
        >
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { carModelsList, getModelAndYearBySeries } from '@/api/customer/car';
export default {
  props: {
    showCarModelsDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedLetter: 'A',
      carList: [],
      carLogoList: [],
      carBrandList: [],
      carDetailList: [],
      selectedModel: '',
      selsectYears: '',
      modelDetailLogoId: '', //CloudLogoId
      modelDetailLogoName: '', //CloudLogo名称
      modelDetailBrandId: '', //Cloud品牌Id
      modelDetailBrandName: '', //Cloud品牌名称
      modelDetailSeriesId: '', //Cloud车系ID
      modelDetailSeriesName: '', //Cloud车系名称
      modelDetailList: {
        modelDetailLogoId: '', //CloudLogoId
        modelDetailLogoName: '', //CloudLogo名称
        modelDetailBrandId: '', //Cloud品牌Id
        modelDetailBrandName: '', //Cloud品牌名称
        modelDetailSeriesId: '', //Cloud车系ID
        modelDetailSeriesName: '', //Cloud车系名称
      },
      modelDetailModelNumberId: '', //车型号ID
      modelDetailModelNumberName: '', //车型号名
      modelDetailModelYearId: '', //年款ID
      modelDetailModelYearName: '', //年款名
      modelOrYearsList: {
        modelDetailModelNumberId: '',
        modelDetailModelNumberName: '',
        modelDetailModelYearId: '',
        modelDetailModelYearName: '',
      },
      seriesName: '',
      liIndex: 0,
    };
  },
  computed: {},
  created() {
    this.getCarModelsList();
  },
  methods: {
    selectCar(index) {
      this.liIndex = index;
    },
    async selectedEvent(series, model, index) {
      this.selectedModel = model.id;
      this.modelDetailBrandId = series.id;
      this.modelDetailBrandName = series.name;
      this.modelDetailSeriesId = model.id;
      this.modelDetailSeriesName = model.name;
      await getModelAndYearBySeries(this.modelDetailSeriesId).then((res) => {
        this.carDetailList = res;
      });
      this.modelDetailList = {
        modelDetailLogoId: this.modelDetailLogoId,
        modelDetailLogoName: this.modelDetailLogoName,
        modelDetailBrandId: this.modelDetailBrandId,
        modelDetailBrandName: this.modelDetailBrandName,
        modelDetailSeriesId: this.modelDetailSeriesId,
        modelDetailSeriesName: this.modelDetailSeriesName,
      };
    },
    selectedCarDetail(model, years) {
      this.selsectYears = years.modelYearId;
      console.log('this.selsectYears ', years);
      this.modelDetailModelNumberId = model.modelNumberId;
      this.modelDetailModelNumberName = model.name;
      this.modelDetailModelYearId = years.modelYearId;
      this.modelDetailModelYearName = years.name;
      this.modelOrYearsList = {
        modelDetailModelNumberId: this.modelDetailModelNumberId,
        modelDetailModelNumberName: this.modelDetailModelNumberName,
        modelDetailModelYearId: this.modelDetailModelYearId,
        modelDetailModelYearName: this.modelDetailModelYearName,
      };
      this.modelDetailList = {
        ...this.modelDetailList,
        ...this.modelOrYearsList,
      };
    },
    selectLetter(letter) {
      this.selectedLetter = letter;
      this.carLogoList = this.carList[this.selectedLetter] || [];
      this.selectCarLogo(this.carLogoList[0]);
    },
    selectCarLogo(item) {
      this.carBrandList = item.children;
      this.selectedModel = ''; //把选中的置空
      this.modelDetailSeriesId = '';
      this.modelDetailSeriesName = ''; //把之前选中的置空
      this.selsectYears = '';
      this.carDetailList = [];
      this.modelOrYearsList = {};
      // 存品牌
      this.modelDetailLogoId = item.id;
      this.modelDetailLogoName = item.name;
      this.modelDetailList = {
        modelDetailLogoId: this.modelDetailLogoId,
        modelDetailLogoName: this.modelDetailLogoName,
        modelDetailBrandId: this.modelDetailBrandId,
        modelDetailBrandName: this.modelDetailBrandName,
        modelDetailSeriesId: this.modelDetailSeriesId,
        modelDetailSeriesName: this.modelDetailSeriesName,
      };
    },
    /**
     * 车型数据请求
     */
    getCarModelsList() {
      carModelsList().then((res) => {
        this.carList = this.$lodash.groupBy(
          res.data.letterList,
          'prefixLetter'
        );
        this.selectedLetter = Object.keys(this.carList)[0];
        this.selectLetter(this.selectedLetter);
      });
    },
    confirmSelect() {
      // 调用自定义事件，传入参数
      this.$emit('carModel', this.modelDetailList);
      this.$emit('update:showCarModelsDialog', false);
    },
    handleClose() {
      this.$emit('update:showCarModelsDialog', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.first-letter {
  display: flex;
  margin-bottom: 20px;
  li {
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    margin-left: 5px;
    font-size: 16px;

    &.active {
      background: $primary;
      color: #fff;
      border-radius: 3px;
    }
  }
}
.carlist-st {
  word-break: break-all;
  width: 100%;
  ul {
    display: flex;
    flex-wrap: wrap;
  }
  span {
    margin-right: 20px;
  }
}
img {
  width: 35px;
  height: 35px;
  display: inline-block;
  vertical-align: middle;
}
.model-lise {
  display: flex;
  flex-wrap: wrap;
  li {
    padding: 10px;
    border: 1px solid #e4e4e4;
    margin: 0px 15px 10px;
  }
}
.footer-sty {
  text-align: center;
  border-top: 2px solid #f2f2f2;
  padding-top: 10px;
  button {
    margin-top: 10px;
    width: 110px;
  }
}
ul {
  cursor: pointer;
}
.actives {
  background-color: $primary;
  color: #ffffff;
}
.car-tip {
  font-size: 13px;
  color: #333;
}
</style>
