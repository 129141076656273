<template>
  <el-popover
    placement="bottom"
    style="width: 50vw"
    trigger="manual"
    v-model="visible"
  >
    <el-table
      max-height="400"
      border
      :data="listData"
      :span-method="spanMethod"
      @row-click="handleClick"
    >
      <el-table-column width="140" label="保险公司">
        <template #default="{ row }">
          <div>{{ row.insuranceName }}</div>
        </template>
      </el-table-column>
      <el-table-column width="140" label="编码" prop="insuranceNum">
      </el-table-column>
      <template slot="append">
        <div v-if="hasMore" class="load-more-btn" @click="getData(false, true)">
          加载更多数据
        </div>
      </template>
    </el-table>
    <el-input
      ref="keyWordInput"
      slot="reference"
      v-model="keyWord"
      size="small"
      placeholder="搜索公司名称、编号"
      @input="getData(true)"
      @focus="visible = true"
      clearable
      @clear="handleClear"
    ></el-input>
  </el-popover>
</template>

<script>
import { searchInsuranceCompanyWithInvoiceAndPaging } from '@/api/customer/sysmanent';
import lodash from 'lodash';
export default {
  name: 'selectInsuranceCompanyPopover',
  props: {
    searchKeyWord: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      //可见性
      visible: false,

      // 列表数据
      listData: [],

      // 搜索关键字
      keyWord: '',

      // 分页所在页数
      pageCurrent: 0,

      // 是否未加载完所有数据
      hasMore: false,

      // 是否正在加载
      isLoading: false,
    };
  },
  computed: {},
  created() {
    this.keyWord = this.searchKeyWord || '';
    this.getData();
  },
  mounted() {
    document.addEventListener('click', this.handleDocumentClick);
  },
  destroyed() {
    document.removeEventListener('click', this.handleDocumentClick);
  },
  methods: {
    /**
     * 加载数据
     */
    getData(reset, loadMore) {
      this.isLoading = true;
      this.debounceGetData(reset, loadMore);
    },
    debounceGetData: lodash.debounce(function (reset, loadMore) {
      // 重置分页，清空已有数据
      if (reset) {
        this.pageCurrent = 1;

        this.hasMore = true;

        this.visible = true;
      } else {
        this.pageCurrent++;
      }
      searchInsuranceCompanyWithInvoiceAndPaging(
        this.keyWord,
        this.$store.state.user.companyId,
        this.size,
        this.pageCurrent
      )
        .then((res) => {
          if (reset) this.listData = [];
          console.log(res);
          this.listData.push(...res.records);
          this.hasMore = this.pageCurrent < res.pages;
        })
        .finally(() => {
          this.isLoading = false;
          if (loadMore) this.$refs.keyWordInput.focus();
        });
    }, 5e2),
    /**
     * 表格合并方法
     */
    spanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 1 || columnIndex == 0) {
        if (row.isParent) {
          return {
            rowspan: row.rowspan,
            colspan: 1,
          };
        } else if (row.isChild) {
          return [0, 0];
        } else {
          return [1, 1];
        }
      }
    },
    /**
     * 选中行
     */
    handleClick(val) {
      this.keyWord = val.insuranceName;
      this.visible = false;
      this.$emit('select', val);
    },
    /**
     * 清空
     */
    handleClear() {
      // this.visible = false;
      this.$emit('clear');
    },
    handleDocumentClick(e) {
      if (!this.$el || this.$el.contains(e.target) || !e.target.className) {
        return;
      }
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.load-more-btn {
  text-align: center;
  line-height: 50px;
  color: #3eb1e0;
  cursor: pointer;
  &:hover {
    color: #14a3db;
  }
}
</style>
