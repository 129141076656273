var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visibility,
        width: "50%",
        "before-close": _vm.handleClose,
        "close-on-click-modal": false,
        "append-to-body": "",
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticStyle: { "margin-top": "18px" },
          attrs: {
            size: "small",
            model: _vm.editForm,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "品牌名称", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入品牌名称" },
                        model: {
                          value: _vm.editForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "name", $$v)
                          },
                          expression: "editForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "首字母", prop: "prefixLetter" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入首字母", maxlength: "1" },
                        on: { input: _vm.handlePrefixLetterInput },
                        model: {
                          value: _vm.editForm.prefixLetter,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "prefixLetter", $$v)
                          },
                          expression: "editForm.prefixLetter",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "品牌LOGO", prop: "imagePath" } },
                    [
                      _c("p", { staticStyle: { "font-size": "12px" } }, [
                        _vm._v(
                          " 上传该品牌logo，需正方形，建议尺寸大小 80*80 "
                        ),
                      ]),
                      _c(
                        "div",
                        [
                          _c("upload-file", {
                            model: {
                              value: _vm.editForm.imagePath,
                              callback: function ($$v) {
                                _vm.$set(_vm.editForm, "imagePath", $$v)
                              },
                              expression: "editForm.imagePath",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.handleClose } },
            [_vm._v(" 取 消 ")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.save },
            },
            [_vm._v(" 确 定 ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }