var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "施工领工",
            width: "50%",
            visible: _vm.visibility,
            "before-close": _vm.handleClose,
            "close-on-click-modal": false,
            "append-to-body": "",
          },
        },
        [
          _c(
            "div",
            { staticClass: "update-fix-items_box" },
            [
              _c("div", { staticClass: "bill-info" }, [
                _c("div", { staticClass: "row flex-x-between" }, [
                  _c("div", { staticClass: "col flex" }, [
                    _c("div", { staticClass: "label" }, [_vm._v("接车单：")]),
                    _c("div", { staticClass: "value" }, [
                      _c(
                        "span",
                        {
                          staticClass: "blue pointer",
                          on: {
                            click: function ($event) {
                              return _vm.goCarBillDetail(_vm.detailData.billId)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.detailData.billCode))]
                      ),
                      _vm.detailData.isOthersSelfPayState
                        ? _c("span", [_vm._v(" (他人自费) ")])
                        : _vm._e(),
                    ]),
                  ]),
                  _c("div", { staticClass: "col flex" }, [
                    _c("div", { staticClass: "label" }, [
                      _vm._v("预交车时间："),
                    ]),
                    _c("div", { staticClass: "value" }, [
                      _vm.detailData.planFinishTime
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.detailData.planFinishTime)),
                          ])
                        : _vm.detailData.waitInStore
                        ? _c("span", [_vm._v(" 在店等 ")])
                        : _vm._e(),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "row flex-x-between",
                    staticStyle: { "margin-top": "20px" },
                  },
                  [
                    _c("div", { staticClass: "col flex" }, [
                      _c("div", { staticClass: "label" }, [_vm._v("车牌号：")]),
                      _c("div", { staticClass: "value" }, [
                        _c("span", [_vm._v(_vm._s(_vm.detailData.carNumber))]),
                        _vm.detailData.carType !== undefined
                          ? _c("span", [
                              _vm._v(
                                " (" + _vm._s(_vm.detailData.vehicleType) + ") "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                    _c("div", { staticClass: "col flex" }, [
                      _c("div", { staticClass: "label" }, [
                        _vm._v("车型/车架号："),
                      ]),
                      _c("div", { staticClass: "value" }, [
                        _c("span", [_vm._v(_vm._s(_vm.detailData.carModel))]),
                        _c("span", [
                          _vm._v(
                            " VIN: " + _vm._s(_vm.detailData.uniqueId) + " "
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]),
              _c("div", [
                _vm._v(
                  " 您当前归属【一级班组名称XXX 或 一级班组名称 / 二级班组名称XXX】部门班组，请选择要领取施工的待派工项目，并“确认”完成领工。 "
                ),
              ]),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%", margin: "10px 0" },
                  attrs: { border: "", data: _vm.taskList },
                  on: { "selection-change": _vm.selectionChange },
                },
                [
                  _c("el-table-column", { attrs: { type: "selection" } }),
                  _c("el-table-column", {
                    attrs: { type: "index", label: "序号", width: "60" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "项目名称",
                      width: "200",
                      prop: "serviceItemName",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "项目编码",
                      width: "120",
                      prop: "serviceItemCode",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "内部标签", width: "140", prop: "tags" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "施工技师" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _vm._v(" " + _vm._s(row.technicianNames) + " "),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("template", { slot: "append" }, [
                    _c(
                      "div",
                      { staticClass: "blue", staticStyle: { margin: "10px" } },
                      [
                        _vm._v(" 已选择项目"),
                        _c("span", { staticClass: "el-icon--right" }, [
                          _vm._v(_vm._s(_vm.selectedList.length)),
                        ]),
                      ]
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.handleClose } },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleConfirm },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }