var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { ref: "baseContainer", attrs: { paddingTop: "" } },
    [
      _c(
        "div",
        { staticClass: "form-top" },
        [
          _c(
            "el-form",
            {
              attrs: { model: _vm.form, "label-width": "100px", size: "small" },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c("template", { slot: "label" }, [
                            _c(
                              "div",
                              [
                                _vm._v(" 操作门店 "),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "light",
                                      placement: "bottom-start",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _vm._v(
                                          " 调拨账款的操作门店，即调出应收的收款(调出)门店、调入应收的付款(调入)门店，"
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          " 要么只查当前登录门店，要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时) "
                                        ),
                                      ]
                                    ),
                                    _c("i", {
                                      staticClass: "iconfont grow-icon_doubt",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("operateCompanyCascader", {
                            model: {
                              value: _vm.form.operateCompanyId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "operateCompanyId", $$v)
                              },
                              expression: "form.operateCompanyId",
                            },
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "查询日期" } },
                        [
                          _c("el-date-picker", {
                            staticClass: "w100",
                            attrs: {
                              type: "daterange",
                              "unlink-panels": "",
                              "range-separator": "至",
                              "start-placeholder": "起始时间",
                              "end-placeholder": "截止时间",
                              "value-format": "yyyy-MM-dd",
                              clearable: false,
                            },
                            model: {
                              value: _vm.form.times,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "times", $$v)
                              },
                              expression: "form.times",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 9 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "el-form-item--small",
                        staticStyle: { "margin-left": "20%" },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "btn_search",
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.query },
                          },
                          [_vm._v("查询")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "btn_search",
                            attrs: { size: "small" },
                            on: { click: _vm.nowDate },
                          },
                          [_vm._v("今日")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "btn_search",
                            attrs: { size: "small" },
                            on: { click: _vm.nowMonth },
                          },
                          [_vm._v("本月")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "btn_search",
                            attrs: { size: "small" },
                            on: { click: _vm.empty },
                          },
                          [_vm._v("置空")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "end",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "blue",
                            attrs: { size: "small", type: "text" },
                            on: { click: _vm.doExport },
                          },
                          [
                            _vm._v("导出列表"),
                            _c("i", {
                              staticClass:
                                "iconfont grow-icon_daochu el-icon--right",
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "pay-type" }, [
            _c("div", { staticClass: "pay-type-list" }, [
              _c(
                "div",
                { staticClass: "type-incon" },
                [
                  _vm._v(" 营业总额 "),
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        effect: "light",
                        placement: "bottom-start",
                        content: "服务营业额合计+商品营业额合计-整单折扣",
                      },
                    },
                    [_c("i", { staticClass: "iconfont grow-icon_doubt" })]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "type-word" }, [
                _c("span", [_vm._v("￥")]),
                _c("span", [
                  _vm._v(
                    " " + _vm._s(_vm._f("toThousands")(_vm.totalTurnover)) + " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " 整单折扣￥ " +
                      _vm._s(
                        _vm._f("toThousands")(
                          _vm.$lodash.get(
                            _vm.businessList,
                            "turnover.totalDiscount",
                            0
                          )
                        )
                      ) +
                      " "
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "pay-type-list" }, [
              _c(
                "div",
                { staticClass: "type-incon" },
                [
                  _vm._v(" 服务营业额 "),
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        effect: "light",
                        placement: "bottom-start",
                        content: "接车单中服务项目实收小计之和",
                      },
                    },
                    [_c("i", { staticClass: "iconfont grow-icon_doubt" })]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "type-word" }, [
                _c("span", [_vm._v("￥")]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm._f("toThousands")(
                        _vm.$lodash.get(
                          _vm.businessList,
                          "serviceCategory.total",
                          0
                        )
                      )
                    )
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("toThousands")(
                          _vm.$lodash.get(
                            _vm.businessList,
                            "serviceCategory.num",
                            0
                          )
                        )
                      ) +
                      "次 "
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "pay-type-list" }, [
              _c(
                "div",
                { staticClass: "type-incon" },
                [
                  _vm._v(" 商品营业额 "),
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        effect: "light",
                        placement: "bottom-start",
                        content: "接车单中商品项目实收小计之和",
                      },
                    },
                    [_c("i", { staticClass: "iconfont grow-icon_doubt" })]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "type-word" }, [
                _c("span", [_vm._v("￥")]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm._f("toThousands")(
                        _vm.$lodash.get(
                          _vm.businessList,
                          "goodCategory.total",
                          0
                        )
                      )
                    )
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("toThousands")(
                          _vm.$lodash.get(
                            _vm.businessList,
                            "goodCategory.num",
                            0
                          )
                        )
                      ) +
                      "次 "
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "number-type-list" }, [
            _c("div", { staticClass: "number-type" }, [
              _c("div", { staticClass: "number-type-name" }, [
                _vm._v("提车台次"),
              ]),
              _c("div", { staticClass: "number-type-number" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("toThousands")(
                        _vm.$lodash.get(
                          _vm.businessList,
                          "carHandleStatistics.takeCarNum",
                          0
                        )
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("div", { staticClass: "number-type" }, [
              _c("div", { staticClass: "number-type-name" }, [
                _vm._v("进店台次"),
              ]),
              _c("div", { staticClass: "number-type-number" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("toThousands")(
                        _vm.$lodash.get(
                          _vm.businessList,
                          "intoHandleStatistics.intoNum",
                          0
                        )
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("div", { staticClass: "number-type" }, [
              _c("div", { staticClass: "number-type-name" }, [
                _vm._v("进店单量"),
              ]),
              _c("div", { staticClass: "number-type-number" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("toThousands")(
                        _vm.$lodash.get(
                          _vm.businessList,
                          "intoHandleStatistics.intoBillNum",
                          0
                        )
                      )
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "customer-type" }, [
                _c("p", [
                  _vm._v(
                    " 会员 " +
                      _vm._s(
                        _vm._f("toThousands")(
                          _vm.$lodash.get(
                            _vm.businessList,
                            "intoHandleStatistics.memberNum",
                            0
                          )
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " 散客 " +
                      _vm._s(
                        _vm._f("toThousands")(
                          _vm.$lodash.get(
                            _vm.businessList,
                            "intoHandleStatistics.travelerNum",
                            0
                          )
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " 协议 " +
                      _vm._s(
                        _vm._f("toThousands")(
                          _vm.$lodash.get(
                            _vm.businessList,
                            "intoHandleStatistics.agreeNum",
                            0
                          )
                        )
                      ) +
                      " "
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "number-type" }, [
              _c("div", { staticClass: "number-type-name" }, [
                _vm._v("提车单量"),
              ]),
              _c("div", { staticClass: "number-type-number" }, [
                _vm._v(
                  " " + _vm._s(_vm._f("toThousands")(_vm.takeCarBillNum)) + " "
                ),
              ]),
              _c("div", { staticClass: "customer-type" }, [
                _c("p", [
                  _vm._v(
                    " 会员 " +
                      _vm._s(
                        _vm._f("toThousands")(
                          _vm.$lodash.get(
                            _vm.businessList,
                            "carHandleStatistics.memberNum",
                            0
                          )
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " 散客 " +
                      _vm._s(
                        _vm._f("toThousands")(
                          _vm.$lodash.get(
                            _vm.businessList,
                            "carHandleStatistics.travelerNum",
                            0
                          )
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " 协议 " +
                      _vm._s(
                        _vm._f("toThousands")(
                          _vm.$lodash.get(
                            _vm.businessList,
                            "carHandleStatistics.agreeNum",
                            0
                          )
                        )
                      ) +
                      " "
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "number-type" }, [
              _c("div", { staticClass: "number-type-name" }, [
                _vm._v("结账单量"),
              ]),
              _c("div", { staticClass: "number-type-number" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm._f("toThousands")(_vm.settleAccountsBillNum)) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "customer-type" }, [
                _c("p", [
                  _vm._v(
                    " 会员 " +
                      _vm._s(
                        _vm._f("toThousands")(
                          _vm.$lodash.get(
                            _vm.businessList,
                            "settleAccountsHandleStatistics.memberNum",
                            0
                          )
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " 散客 " +
                      _vm._s(
                        _vm._f("toThousands")(
                          _vm.$lodash.get(
                            _vm.businessList,
                            "settleAccountsHandleStatistics.travelerNum",
                            0
                          )
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " 协议 " +
                      _vm._s(
                        _vm._f("toThousands")(
                          _vm.$lodash.get(
                            _vm.businessList,
                            "settleAccountsHandleStatistics.agreeNum",
                            0
                          )
                        )
                      ) +
                      " "
                  ),
                ]),
              ]),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "orange infor-bt",
              staticStyle: { background: "#fff4e5", "margin-top": "60px" },
            },
            [_vm._v(" 自费 ")]
          ),
          _c("div", { staticClass: "source-number" }, [
            _c("div", [
              _c("p", [_vm._v("营业总额")]),
              _c("span", { staticStyle: { color: "#333" } }, [
                _vm._v(
                  "￥" +
                    _vm._s(
                      _vm._f("toThousands")(
                        _vm.$lodash.get(_vm.totalList[0], "selfTotal", 0)
                      )
                    )
                ),
              ]),
            ]),
            _c("div", [
              _c("p", [_vm._v("服务营业额")]),
              _c("span", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("toThousands")(
                        _vm.$lodash.get(
                          _vm.businessList,
                          "selfTotalBusinessTurnover.serviceTurnover",
                          0
                        )
                      )
                    )
                ),
              ]),
            ]),
            _c("div", [
              _c("p", [_vm._v("商品营业额")]),
              _c("span", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("toThousands")(
                        _vm.$lodash.get(
                          _vm.businessList,
                          "selfTotalBusinessTurnover.goodTurnover",
                          0
                        )
                      )
                    )
                ),
              ]),
            ]),
            _c("div", [
              _c("p", [_vm._v("提车单量")]),
              _c("span", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("toThousands")(
                        _vm.$lodash.get(
                          _vm.businessList,
                          "selfTotalBusinessTurnover.takeCarBillNum",
                          0
                        )
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("div", [
              _c("p", [_vm._v("进店单量")]),
              _c("span", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("toThousands")(
                        _vm.$lodash.get(
                          _vm.businessList,
                          "selfTotalBusinessTurnover.intoBillNum",
                          0
                        )
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("div", [
              _c("p", [_vm._v("结账单量")]),
              _c("span", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("toThousands")(
                        _vm.$lodash.get(
                          _vm.businessList,
                          "selfTotalBusinessTurnover.settleAccountsBillNum",
                          0
                        )
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "primary infor-bt",
              staticStyle: { background: "#e8f3ee" },
            },
            [_vm._v("包干")]
          ),
          _c("div", { staticClass: "source-number" }, [
            _c("div", [
              _c("p", [_vm._v("营业总额")]),
              _c("span", { staticStyle: { color: "#333" } }, [
                _vm._v(
                  "￥" +
                    _vm._s(
                      _vm._f("toThousands")(
                        _vm.$lodash.get(_vm.totalList[0], "contractTotal", 0)
                      )
                    )
                ),
              ]),
            ]),
            _c("div", [
              _c("p", [_vm._v("服务营业额")]),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm._f("toThousands")(
                      _vm.$lodash.get(
                        _vm.businessList,
                        "contractTotalBusinessTurnover.serviceTurnover",
                        0
                      )
                    )
                  )
                ),
              ]),
            ]),
            _c("div", [
              _c("p", [_vm._v("商品营业额")]),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm._f("toThousands")(
                      _vm.$lodash.get(
                        _vm.businessList,
                        "contractTotalBusinessTurnover.goodTurnover",
                        0
                      )
                    )
                  )
                ),
              ]),
            ]),
            _c("div", [
              _c("p", [_vm._v("提车单量")]),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm._f("toThousands")(
                      _vm.$lodash.get(
                        _vm.businessList,
                        "contractTotalBusinessTurnover.takeCarBillNum",
                        0
                      )
                    )
                  )
                ),
              ]),
            ]),
            _c("div", [
              _c("p", [_vm._v("进店单量")]),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm._f("toThousands")(
                      _vm.$lodash.get(
                        _vm.businessList,
                        "contractTotalBusinessTurnover.intoBillNum",
                        0
                      )
                    )
                  )
                ),
              ]),
            ]),
            _c("div", [
              _c("p", [_vm._v("结账单量")]),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm._f("toThousands")(
                      _vm.$lodash.get(
                        _vm.businessList,
                        "contractTotalBusinessTurnover.settleAccountsBillNum",
                        0
                      )
                    )
                  )
                ),
              ]),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "second infor-bt",
              staticStyle: { background: "#ecf8f8" },
            },
            [_vm._v("理赔")]
          ),
          _c("div", { staticClass: "source-number" }, [
            _c("div", [
              _c("p", [_vm._v("营业总额")]),
              _c("span", { staticStyle: { color: "#333" } }, [
                _vm._v(
                  "￥" +
                    _vm._s(
                      _vm._f("toThousands")(
                        _vm.$lodash.get(
                          _vm.totalList[0],
                          "claimSettlementTotal",
                          0
                        )
                      )
                    )
                ),
              ]),
            ]),
            _c("div", [
              _c("p", [_vm._v("服务营业额")]),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm._f("toThousands")(
                      _vm.$lodash.get(
                        _vm.businessList,
                        "claimSettlementTotalBusinessTurnover.serviceTurnover",
                        0
                      )
                    )
                  )
                ),
              ]),
            ]),
            _c("div", [
              _c("p", [_vm._v("商品营业额")]),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm._f("toThousands")(
                      _vm.$lodash.get(
                        _vm.businessList,
                        "claimSettlementTotalBusinessTurnover.goodTurnover",
                        0
                      )
                    )
                  )
                ),
              ]),
            ]),
            _c("div", [
              _c("p", [_vm._v("提车单量")]),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm._f("toThousands")(
                      _vm.$lodash.get(
                        _vm.businessList,
                        "claimSettlementTotalBusinessTurnover.takeCarBillNum",
                        0
                      )
                    )
                  )
                ),
              ]),
            ]),
            _c("div", [
              _c("p", [_vm._v("进店单量")]),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm._f("toThousands")(
                      _vm.$lodash.get(
                        _vm.businessList,
                        "claimSettlementTotalBusinessTurnover.intoBillNum",
                        0
                      )
                    )
                  )
                ),
              ]),
            ]),
            _c("div", [
              _c("p", [_vm._v("结账单量")]),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm._f("toThousands")(
                      _vm.$lodash.get(
                        _vm.businessList,
                        "claimSettlementTotalBusinessTurnover.settleAccountsBillNum",
                        0
                      )
                    )
                  )
                ),
              ]),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "blue infor-bt",
              staticStyle: { background: "#ecf7fc" },
            },
            [_vm._v("索赔")]
          ),
          _c("div", { staticClass: "source-number" }, [
            _c("div", [
              _c("p", [_vm._v("营业总额")]),
              _c("span", { staticStyle: { color: "#333" } }, [
                _vm._v(
                  "￥" +
                    _vm._s(
                      _vm._f("toThousands")(
                        _vm.$lodash.get(
                          _vm.totalList[0],
                          "claimForCompensationTotal",
                          0
                        )
                      )
                    )
                ),
              ]),
            ]),
            _c("div", [
              _c("p", [_vm._v("服务营业额")]),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm._f("toThousands")(
                      _vm.$lodash.get(
                        _vm.businessList,
                        "claimForCompensationTotalBusinessTurnover.serviceTurnover",
                        0
                      )
                    )
                  )
                ),
              ]),
            ]),
            _c("div", [
              _c("p", [_vm._v("商品营业额")]),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm._f("toThousands")(
                      _vm.$lodash.get(
                        _vm.businessList,
                        "claimForCompensationTotalBusinessTurnover.goodTurnover",
                        0
                      )
                    )
                  )
                ),
              ]),
            ]),
            _c("div", [
              _c("p", [_vm._v("提车单量")]),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm._f("toThousands")(
                      _vm.$lodash.get(
                        _vm.businessList,
                        "claimForCompensationTotalBusinessTurnover.takeCarBillNum",
                        0
                      )
                    )
                  )
                ),
              ]),
            ]),
            _c("div", [
              _c("p", [_vm._v("进店单量")]),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm._f("toThousands")(
                      _vm.$lodash.get(
                        _vm.businessList,
                        "claimForCompensationTotalBusinessTurnover.intoBillNum",
                        0
                      )
                    )
                  )
                ),
              ]),
            ]),
            _c("div", [
              _c("p", [_vm._v("结账单量")]),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm._f("toThousands")(
                      _vm.$lodash.get(
                        _vm.businessList,
                        "claimForCompensationTotalBusinessTurnover.settleAccountsBillNum",
                        0
                      )
                    )
                  )
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "custom-tabs" }, [
            _c(
              "div",
              {
                class: {
                  "custom-tab-item": true,
                  active: _vm.status == -1,
                },
                on: {
                  click: function ($event) {
                    _vm.status = -1
                  },
                },
              },
              [_vm._v(" 全部 ")]
            ),
            _c(
              "div",
              {
                class: {
                  "custom-tab-item": true,
                  active: _vm.status == 0,
                },
                on: {
                  click: function ($event) {
                    _vm.status = 0
                  },
                },
              },
              [_vm._v(" 自费 ")]
            ),
            _c(
              "div",
              {
                class: {
                  "custom-tab-item": true,
                  active: _vm.status == 1,
                },
                on: {
                  click: function ($event) {
                    _vm.status = 1
                  },
                },
              },
              [_vm._v(" 包干 ")]
            ),
            _c(
              "div",
              {
                class: {
                  "custom-tab-item": true,
                  active: _vm.status == 2,
                },
                on: {
                  click: function ($event) {
                    _vm.status = 2
                  },
                },
              },
              [_vm._v(" 理赔 ")]
            ),
            _c(
              "div",
              {
                class: {
                  "custom-tab-item": true,
                  active: _vm.status == 3,
                },
                on: {
                  click: function ($event) {
                    _vm.status = 3
                  },
                },
              },
              [_vm._v(" 索赔 ")]
            ),
            _c("p", [
              _vm._v("注：下方柱状图与数据展示区根据所选择的业务进行展示"),
            ]),
          ]),
          _c("div", { staticClass: "chart-table" }, [
            _c("div", { staticClass: "chart-table-list" }, [
              _c("div", { staticClass: "chart-table-tip" }, [
                _c("p", [_vm._v("汇总收入明细")]),
                _c("p", [_vm._v("商品服务统计已提车单据记录，未统计整单折扣")]),
              ]),
              _c("div", {
                ref: "chart1",
                staticStyle: { width: "100%", height: "450px" },
              }),
            ]),
          ]),
          _c("div", { staticClass: "footer-table" }, [
            _c(
              "div",
              { staticClass: "serve-table" },
              [
                _c(
                  "el-table",
                  {
                    staticClass: "service-table",
                    staticStyle: {
                      width: "100%",
                      "border-radius": "4px 4px 0px 0px",
                      "border-bottom": "0px",
                    },
                    attrs: {
                      data: _vm.tableData1,
                      border: "",
                      "summary-method": _vm.getServiceSummaries,
                      "span-method": _vm.serviceTableSpanMethod,
                      "show-summary": "",
                    },
                  },
                  [
                    _vm._v(" > "),
                    _c("el-table-column", {
                      attrs: { label: "分类", width: "100" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _vm._v(
                                  " " + _vm._s(scope.row.fathername) + " "
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { type: "expand" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "el-table",
                                {
                                  staticStyle: {
                                    width: "96%",
                                    "margin-left": "4%",
                                  },
                                  attrs: {
                                    data: row.secondServiceCategories,
                                    border: "",
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "firstCategoryName",
                                      label: "细类",
                                      "min-width": "200",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "num",
                                      label: "总数量",
                                      width: "120",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "memberCardPayNum",
                                      label: "含卡扣数",
                                      width: "120",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "cost",
                                      label: "出库成本",
                                      width: "120",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "total",
                                      label: "项目小计",
                                      width: "120",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "pointer text_operation blue",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(scope.row.total)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "firstCategoryName",
                        label: "大类",
                        "min-width": "200",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "num", label: "总数量", width: "120" },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "memberCardPayNum",
                        label: "含卡扣数",
                        width: "120",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "cost", label: "出库成本", width: "120" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "total", label: "项目小计", width: "120" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                { staticClass: "pointer text_operation blue" },
                                [_vm._v(_vm._s(scope.row.total))]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    staticStyle: {
                      width: "100%",
                      "border-radius": "0px",
                      "border-bottom": "0px",
                    },
                    attrs: {
                      "show-header": false,
                      data: _vm.goodsTableList,
                      border: "",
                      "span-method": _vm.goodsTableSpanMethod,
                      "summary-method": _vm.getGoodsSummaries,
                      "show-summary": "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { label: "分类", width: "100" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _vm._v(
                                  " " + _vm._s(scope.row.fathername) + " "
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { type: "expand" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "el-table",
                                {
                                  staticStyle: {
                                    width: "96%",
                                    "margin-left": "4%",
                                  },
                                  attrs: {
                                    data: row.secondServiceCategories,
                                    border: "",
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "firstCategoryName",
                                      label: "细类",
                                      "min-width": "200",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "num",
                                      label: "总数量",
                                      width: "120",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "memberCardPayNum",
                                      label: "含卡扣数",
                                      width: "120",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "出库成本",
                                      width: "120",
                                      prop: "cost",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "total",
                                      label: "项目小计",
                                      width: "120",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "pointer text_operation blue",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(scope.row.total)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "firstCategoryName",
                        label: "大类",
                        "min-width": "200",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "num", label: "总数量", width: "120" },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "memberCardPayNum",
                        label: "含卡扣数",
                        width: "120",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "cost", label: "出库成本", width: "120" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "total", label: "项目小计", width: "120" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                { staticClass: "pointer text_operation blue" },
                                [_vm._v(_vm._s(scope.row.total))]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "statistical-style flex" }, [
                  _c(
                    "p",
                    [
                      _vm._v(" 整单折扣 "),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "light",
                            placement: "bottom-start",
                            content: "接车单中订单总折扣金额",
                          },
                        },
                        [_c("i", { staticClass: "iconfont grow-icon_doubt" })]
                      ),
                    ],
                    1
                  ),
                  _c("p", [
                    _c("span", { staticStyle: { "margin-left": "10px" } }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("toThousands")(
                              _vm.$lodash.get(
                                _vm.businessList,
                                "turnover.totalDiscount",
                                0
                              )
                            )
                          )
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "statistical-style flex" }, [
                  _c(
                    "p",
                    [
                      _vm._v(" 营业总额 "),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "light",
                            placement: "bottom-start",
                            content:
                              "服务营业额合计 + 商品营业额合计 -整单折扣",
                          },
                        },
                        [_c("i", { staticClass: "iconfont grow-icon_doubt" })]
                      ),
                    ],
                    1
                  ),
                  _c("p", [
                    _c("span", { staticStyle: { "margin-left": "10px" } }, [
                      _vm._v(_vm._s(_vm._f("toThousands")(_vm.totalTurnover))),
                    ]),
                  ]),
                ]),
                _c(
                  "el-table",
                  {
                    staticStyle: {
                      width: "100%",
                      "border-radius": "0px 0px 4px 4px",
                    },
                    attrs: {
                      "show-header": false,
                      data: _vm.otherBusinessTableList,
                      border: "",
                      "span-method": _vm.objectSpanMethod,
                      "summary-method": _vm.getOtherSummaries,
                      "show-summary": "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { label: "分类", width: "100" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _vm._v(
                                  " " + _vm._s(scope.row.fathername) + " "
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "firstCategoryName",
                        label: "大类-细类",
                        "min-width": "200",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "num", label: "总数量", width: "120" },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "memberCardPayNum",
                        label: "含卡扣数",
                        width: "120",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "cost", label: "出库成本", width: "120" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "total", label: "项目小计", width: "120" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                { staticClass: "pointer text_operation blue" },
                                [_vm._v(_vm._s(scope.row.total))]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "statistical-style flex",
                    staticStyle: { "background-color": "#f5f7fa" },
                  },
                  [
                    _c(
                      "p",
                      [
                        _vm._v(" 收入合计 "),
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              effect: "light",
                              placement: "bottom-start",
                              content: "营业总额 + 其他业务合计",
                            },
                          },
                          [_c("i", { staticClass: "iconfont grow-icon_doubt" })]
                        ),
                      ],
                      1
                    ),
                    _c("p", [
                      _c("span", { staticStyle: { "margin-left": "10px" } }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("toThousands")(
                              _vm.totalTurnover + _vm.otherBusinessTotal
                            )
                          )
                        ),
                      ]),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "pay-card-table" },
              [
                _c(
                  "el-table",
                  {
                    attrs: {
                      data: _vm.tableData2,
                      border: "",
                      "header-cell-style": {
                        background: "#FAFAFA",
                        color: "#000000",
                        fontSize: "14px",
                        fontWeight: "500",
                      },
                      "span-method": _vm.objectSpanMethod,
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "fathername", "mix-width": "50" },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "payName",
                        label: "收款方式",
                        "mix-width": "180",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "total",
                        label: "收款金额",
                        "mix-width": "100",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "pointer text_operation blue",
                                  staticStyle: { float: "right" },
                                },
                                [_vm._v(_vm._s(scope.row.total))]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "合计", "mix-width": "100" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", { staticStyle: { float: "right" } }, [
                                _vm._v(_vm._s(scope.row.alltotal)),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("template", { slot: "append" }, [
                      _c("div", { staticClass: "table_footer" }, [
                        _c("p", { staticClass: "table_footer_deripto" }, [
                          _vm._v("收入合计"),
                        ]),
                        _c("p", { staticClass: "table_footer_deriptt" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("toThousands")(_vm.combinedTotal)) +
                              " "
                          ),
                        ]),
                      ]),
                    ]),
                  ],
                  2
                ),
                _c(
                  "el-table",
                  {
                    staticStyle: { "margin-top": "10px" },
                    attrs: { data: _vm.memberCardBill, border: "" },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "name",
                        label: "会员卡",
                        "mix-width": "100",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "num", label: "数量", "mix-width": "100" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                { staticClass: "pointer text_operation blue" },
                                [_vm._v(_vm._s(scope.row.num))]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "total",
                        label: "金额",
                        "mix-width": "100",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "导出提示",
            visible: _vm.showExportDialog,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showExportDialog = $event
            },
          },
        },
        [
          _c("div", { staticClass: "export-dialog__body" }, [
            _c(
              "div",
              { staticClass: "export-dialog-page__input" },
              [
                _c("span", [_vm._v("导出范围：第")]),
                _c("el-input", {
                  attrs: {
                    type: "number",
                    size: "small",
                    max: Math.ceil(_vm.total / _vm.pageOption.size),
                    min: 1,
                  },
                  model: {
                    value: _vm.startPage,
                    callback: function ($$v) {
                      _vm.startPage = $$v
                    },
                    expression: "startPage",
                  },
                }),
                _c("span", [_vm._v("至")]),
                _c("el-input", {
                  attrs: {
                    type: "number",
                    size: "small",
                    max: Math.ceil(_vm.total / _vm.pageOption.size),
                    min: 1,
                  },
                  model: {
                    value: _vm.endPage,
                    callback: function ($$v) {
                      _vm.endPage = $$v
                    },
                    expression: "endPage",
                  },
                }),
              ],
              1
            ),
            _c("p", { staticClass: "export-dialog__tip" }, [
              _vm._v(
                " 共" +
                  _vm._s(Math.ceil(_vm.total / _vm.pageOption.size)) +
                  "页，一次最多导出10000条数据 "
              ),
            ]),
          ]),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showExportDialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.doExport } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }