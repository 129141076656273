var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { ref: "baseContainer", attrs: { paddingTop: "" } },
    [
      [
        _c(
          "div",
          [
            _c(
              "el-form",
              {
                staticClass: "filter-form",
                attrs: {
                  model: _vm.filterForm,
                  "label-width": "100px",
                  size: "small",
                },
              },
              [
                _c("div", { staticClass: "flex-x-between" }, [
                  _c(
                    "div",
                    { staticClass: "flex-warp flex-shrink0" },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c("template", { slot: "label" }, [
                            _c(
                              "div",
                              [
                                _vm._v(" 操作门店 "),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "light",
                                      content:
                                        "接车单的操作门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)",
                                      placement: "bottom-start",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "iconfont grow-icon_doubt",
                                      staticStyle: { color: "#9a9a9a" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("operateCompanyCascader", {
                            attrs: {
                              operateCompanyUrl:
                                _vm.filterForm.operateCompanyUrl,
                            },
                            model: {
                              value: _vm.filterForm.operateCompanyId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.filterForm,
                                  "operateCompanyId",
                                  $$v
                                )
                              },
                              expression: "filterForm.operateCompanyId",
                            },
                          }),
                        ],
                        2
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "车牌号" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入", clearable: "" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.getList.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.filterForm.carNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.filterForm, "carNumber", $$v)
                              },
                              expression: "filterForm.carNumber",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "签约客户" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                size: "small",
                                filterable: "",
                                clearable: "",
                                remote: "",
                                placeholder: "请输入客户名称搜索",
                                "remote-method": _vm.searchClient,
                                loading: _vm.searchClientLoading,
                              },
                              model: {
                                value: _vm.filterForm.clientId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filterForm, "clientId", $$v)
                                },
                                expression: "filterForm.clientId",
                              },
                            },
                            _vm._l(_vm.clientList, function (item) {
                              return _c(
                                "el-option",
                                {
                                  key: item.id,
                                  attrs: {
                                    label: item.clientName,
                                    value: item.id,
                                  },
                                },
                                [_c("span", [_vm._v(_vm._s(item.clientName))])]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "flex" }, [
                    _c("div", { staticClass: "el-form-item--small" }, [
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "btn_search",
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.getList },
                            },
                            [_vm._v("查询")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "btn_search",
                              attrs: { size: "small" },
                              on: { click: _vm.resetFilterForm },
                            },
                            [_vm._v("置空")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "flex-warp" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "业务类型" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "w100",
                            attrs: { placeholder: "请选择", clearable: "" },
                            model: {
                              value: _vm.filterForm.billType,
                              callback: function ($$v) {
                                _vm.$set(_vm.filterForm, "billType", $$v)
                              },
                              expression: "filterForm.billType",
                            },
                          },
                          _vm._l(_vm.businessType, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "车间班组" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "" },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.getList.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.filterForm.dept,
                            callback: function ($$v) {
                              _vm.$set(_vm.filterForm, "dept", $$v)
                            },
                            expression: "filterForm.dept",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "车架号" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入", clearable: "" },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.getList.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.filterForm.uniqueId,
                            callback: function ($$v) {
                              _vm.$set(_vm.filterForm, "uniqueId", $$v)
                            },
                            expression: "filterForm.uniqueId",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c("div", { staticClass: "flex h100" }, [
              _c("div", { staticClass: "custom-tabs" }, [
                _c(
                  "div",
                  {
                    class: {
                      "custom-tab-item": true,
                      active: _vm.dispatchType == 4,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.changeDispatchType(4)
                      },
                    },
                  },
                  [_vm._v(" 待派工 ")]
                ),
                _c(
                  "div",
                  {
                    class: {
                      "custom-tab-item": true,
                      active: _vm.dispatchType == 0,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.changeDispatchType(0)
                      },
                    },
                  },
                  [_vm._v(" 施工中 ")]
                ),
                _c(
                  "div",
                  {
                    class: {
                      "custom-tab-item": true,
                      active: _vm.dispatchType == 5,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.changeDispatchType(5)
                      },
                    },
                  },
                  [_vm._v(" 质检中 ")]
                ),
                _c(
                  "div",
                  {
                    class: {
                      "custom-tab-item": true,
                      active: _vm.dispatchType == 1,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.changeDispatchType(1)
                      },
                    },
                  },
                  [_vm._v(" 车间完工 ")]
                ),
              ]),
            ]),
          ],
          1
        ),
      ],
      _c(
        "div",
        { staticClass: "flex h100" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "fixedScrollBar",
                  rawName: "v-fixedScrollBar",
                  value: _vm.$refs.baseContainer,
                  expression: "$refs.baseContainer",
                },
              ],
              staticClass: "custom-table",
              attrs: { data: _vm.carBillList, border: "" },
              on: {
                "row-dblclick": function ($event) {
                  return _vm.goCarBillDetail($event.billId)
                },
              },
            },
            [
              _c("el-table-column", {
                key: 1,
                attrs: { type: "index", label: "序号", width: "80" },
              }),
              _c("el-table-column", {
                key: 2,
                attrs: { label: "操作", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("div", { staticClass: "flex-x-center" }, [
                          _vm.dispatchType == 4
                            ? _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "auth",
                                      rawName: "v-auth",
                                      value: 95,
                                      expression: "95",
                                    },
                                  ],
                                  staticClass: "detail-btn flex-center",
                                  on: {
                                    click: function ($event) {
                                      return _vm.dispatching(row.dispatchBillId)
                                    },
                                  },
                                },
                                [_vm._v(" 派工 ")]
                              )
                            : _vm._e(),
                          _vm.dispatchType == 4
                            ? _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "auth",
                                      rawName: "v-auth",
                                      value: 452,
                                      expression: "452",
                                    },
                                  ],
                                  staticClass: "detail-btn flex-center",
                                  staticStyle: { "margin-left": "5px" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getTask(row.dispatchBillId)
                                    },
                                  },
                                },
                                [_vm._v(" 领工 ")]
                              )
                            : _vm._e(),
                          _vm.dispatchType == 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "detail-btn flex-center",
                                  staticStyle: { "margin-left": "5px" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showUpdateFixItemDrawer(
                                        row.dispatchBillId
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" 施工项目 ")]
                              )
                            : _vm._e(),
                          _vm.dispatchType == 5
                            ? _c(
                                "div",
                                {
                                  staticClass: "detail-btn flex-center",
                                  staticStyle: { "margin-left": "5px" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showQualityTestingDialog(
                                        row.dispatchBillId
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" 完工质检 ")]
                              )
                            : _vm._e(),
                          _vm.dispatchType == 1
                            ? _c(
                                "div",
                                {
                                  staticClass: "detail-btn flex-center",
                                  staticStyle: { "margin-left": "5px" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showQualityTestResultDialog(
                                        row.dispatchBillId
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" 终检详情 ")]
                              )
                            : _vm._e(),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                key: 3,
                attrs: { width: "180", label: "操作门店", prop: "companyName" },
              }),
              _c("el-table-column", {
                key: 4,
                attrs: { width: "100", label: "车牌号", prop: "carNumber" },
              }),
              _c("el-table-column", {
                key: 5,
                attrs: { width: "200", label: "车型/车架号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.carType
                          ? _c("div", [_vm._v("车型:" + _vm._s(row.carType))])
                          : _vm._e(),
                        row.uniqueId
                          ? _c("div", [_vm._v("VIN:" + _vm._s(row.uniqueId))])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                key: 6,
                attrs: { width: "180", label: "签约客户" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [_c("div", [_vm._v(_vm._s(row.clientName))])]
                    },
                  },
                ]),
              }),
              [4].includes(_vm.dispatchType)
                ? _c("el-table-column", {
                    key: 7,
                    attrs: { width: "180", label: "本次提交派工时间/提交人" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c("div", [
                                _vm._v(_vm._s(row.dispatchUpdatedTime)),
                              ]),
                              _c("div", [_vm._v(_vm._s(row.submitterName))]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2080985650
                    ),
                  })
                : _vm._e(),
              [1, 5].includes(_vm.dispatchType)
                ? _c("el-table-column", {
                    key: 8,
                    attrs: { width: "180", label: "本次提交终检时间/提交人" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c("div", [
                                _vm._v(_vm._s(row.dispatchUpdatedTime)),
                              ]),
                              _c("div", [_vm._v(_vm._s(row.submitterName))]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2080985650
                    ),
                  })
                : _vm._e(),
              _vm.dispatchType == 0
                ? _c("el-table-column", {
                    key: 9,
                    attrs: { width: "180", label: "最后派工时间/派工人" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c("div", [
                                _vm._v(_vm._s(row.dispatchUpdatedTime)),
                              ]),
                              _c("div", [_vm._v(_vm._s(row.submitterName))]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2080985650
                    ),
                  })
                : _vm._e(),
              _c("el-table-column", {
                key: 10,
                attrs: { width: "190", label: "接车单" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "pointer blue",
                            on: {
                              click: function ($event) {
                                return _vm.goCarBillDetail(row.billId)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(row.billCode) + " ")]
                        ),
                        row.billType !== 0
                          ? _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("dict")(row.billType, "carBillType")
                                  ) +
                                  " "
                              ),
                            ])
                          : _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("dict")(
                                      row.isOthersSelfPayState,
                                      "isOthersSelfPayState"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                key: 11,
                attrs: { width: "180", label: "车单状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("div", [
                          _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("dict")(row.billState, "carBillState")
                                ) +
                                " "
                            ),
                            row.billOtherState
                              ? _c("span", { staticClass: "red" }, [
                                  _vm._v(
                                    "(" + _vm._s(row.billOtherState) + ")"
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "flex",
                            staticStyle: { "font-size": "12px" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("dict")(
                                    row.detectionState,
                                    "detectionState"
                                  )
                                ) +
                                " "
                            ),
                            row.detectionState !== undefined
                              ? _c("span", [_vm._v(" /")])
                              : _vm._e(),
                            row.dispatchState !== undefined
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("dict")(
                                          row.dispatchState,
                                          "dispatchState"
                                        )
                                      ) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                key: 12,
                attrs: { width: "180", label: "进店时间/接车员" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("div", [_vm._v(_vm._s(row.intoStoreTime))]),
                        _c("div", [_vm._v(_vm._s(row.pickUpUserName))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                key: 13,
                attrs: { width: "150", label: "进店里程", prop: "mileage" },
              }),
              _c("el-table-column", {
                key: 14,
                attrs: { width: "180", label: "预交车时间" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.undetermined
                          ? _c("div", [_vm._v("待定")])
                          : _c("div", [
                              _c("p", [_vm._v(_vm._s(row.planFinishTime))]),
                              _c("p", [
                                _vm._v(_vm._s(row.waitInStore ? "在店等" : "")),
                              ]),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                key: 15,
                attrs: { width: "180", label: "施工类型" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("dict")(row.difficulty, "difficulty")
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                key: 16,
                attrs: { width: "180", label: "服务类别" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$lodash.get(row, "categoryName", []).join()
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              [0, 1, 5].includes(_vm.dispatchType)
                ? _c("el-table-column", {
                    key: 17,
                    attrs: { width: "150", label: "施工技师/班组" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _vm._v(" " + _vm._s(row.technicianNames) + " "),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1671169267
                    ),
                  })
                : _vm._e(),
              _c(
                "el-table-column",
                {
                  key: 18,
                  attrs: {
                    label: "车辆当前滞留情况",
                    "header-align": "center",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      width: "180",
                      label: "更新日期",
                      prop: "billUpdatedTime",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { width: "180", label: "需要协助" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            row.needHelp !== undefined
                              ? _c("p", [
                                  _vm._v(
                                    " " +
                                      _vm._s(row.needHelp == 0 ? "否" : "是") +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      width: "180",
                      label: "报告人",
                      prop: "reporterName",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": "",
                      label: "滞留原因",
                      prop: "strandedReason",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          [0, 5].includes(_vm.dispatchType)
            ? _c(
                "div",
                {
                  staticStyle: {
                    "margin-left": "30px",
                    width: "150px",
                    height: "100%",
                  },
                },
                [
                  _c(
                    "el-table",
                    {
                      ref: "singleTable",
                      attrs: {
                        height: "100%",
                        data: _vm.countTableData,
                        border: "",
                        "row-key": "departmentId",
                        "default-expand-all": "",
                        "tree-props": { children: "subDepartments" },
                        "highlight-current-row": "",
                      },
                      on: { "current-change": _vm.handleCurrentChange },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { label: "施工班组统计(车单数)" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ row }) {
                                return [
                                  _c("span", [_vm._v(_vm._s(row.name))]),
                                  _c("span", [
                                    _vm._v(
                                      "(" + _vm._s(row.statisticsSum) + ")"
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4201705498
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.dispatchingDialogVisible
        ? _c("dispatching-dialog", {
            attrs: {
              visibility: _vm.dispatchingDialogVisible,
              id: _vm.editId,
              dispatchType: _vm.dispatchType,
            },
            on: {
              "update:visibility": function ($event) {
                _vm.dispatchingDialogVisible = $event
              },
              finish: _vm.doRefresh,
            },
          })
        : _vm._e(),
      _vm.getTaskDialogVisible
        ? _c("get-task-dialog", {
            attrs: {
              visibility: _vm.getTaskDialogVisible,
              id: _vm.editId,
              dispatchType: _vm.dispatchType,
            },
            on: {
              "update:visibility": function ($event) {
                _vm.getTaskDialogVisible = $event
              },
              finish: _vm.doRefresh,
            },
          })
        : _vm._e(),
      _vm.updateFixItemDrawerVisible
        ? _c("update-fix-item-drawer", {
            attrs: {
              visibility: _vm.updateFixItemDrawerVisible,
              id: _vm.editId,
              dispatchType: _vm.dispatchType,
            },
            on: {
              "update:visibility": function ($event) {
                _vm.updateFixItemDrawerVisible = $event
              },
              finish: _vm.doRefresh,
            },
          })
        : _vm._e(),
      _vm.qualityTestingDialogVisible
        ? _c("quality-testing-dialog", {
            attrs: {
              visibility: _vm.qualityTestingDialogVisible,
              id: _vm.editId,
              dispatchType: _vm.dispatchType,
            },
            on: {
              "update:visibility": function ($event) {
                _vm.qualityTestingDialogVisible = $event
              },
              finish: _vm.doRefresh,
            },
          })
        : _vm._e(),
      _vm.qualityTestResultDialogVisible
        ? _c("quality-test-result-dialog", {
            attrs: {
              visibility: _vm.qualityTestResultDialogVisible,
              id: _vm.editId,
              dispatchType: _vm.dispatchType,
            },
            on: {
              "update:visibility": function ($event) {
                _vm.qualityTestResultDialogVisible = $event
              },
            },
          })
        : _vm._e(),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("pagination", {
            attrs: { total: _vm.total },
            on: { pageChange: _vm.changePage },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }