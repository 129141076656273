<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <el-form
        class="filter-form"
        :model="form"
        label-width="100px"
        size="small"
      >
        <div class="flex-x-between">
          <div class="flex-shrink0 flex">
            <el-form-item label="服务名称">
              <el-input
                v-model="form.serviceItemName"
                placeholder="请输入服务名称"
                clearable
                @keyup.enter.native="doSearch"
              ></el-input>
            </el-form-item>
            <el-form-item label="商品名称">
              <el-input
                v-model="form.goodsName"
                placeholder="请输入商品名称"
                clearable
                @keyup.enter.native="doSearch"
              ></el-input>
            </el-form-item>
            <el-form-item label="品牌">
              <el-input
                v-model="form.brandName"
                placeholder="请输入品牌"
                clearable
                @keyup.enter.native="doSearch"
              ></el-input>
            </el-form-item>
          </div>
          <div class="el-form-item--small">
            <div
              @click="showMore = !showMore"
              class="el-form-item__label pointer"
            >
              更多筛选
              <i
                :class="{
                  primary: showMore,
                  'el-icon-arrow-right': !showMore,
                  'el-icon-arrow-down': showMore,
                }"
              ></i>
            </div>
            <div class="flex">
              <el-button
                v-auth="727"
                class="btn_search"
                type="primary"
                size="small"
                @click="doSearch"
                >查询</el-button
              >
              <el-button class="btn_search" size="small" @click="empty"
                >置空</el-button
              >
            </div>
          </div>
        </div>
        <template v-if="showMore">
          <div class="flex-warp">
            <el-form-item label="车系">
              <el-input
                v-model="form.seriesName"
                placeholder="请输入车系"
                clearable
                @keyup.enter.native="doSearch"
              ></el-input>
            </el-form-item>
          </div>
        </template>
      </el-form>
    </template>

    <template>
      <div style="margin-bottom: 14px" class="btns flex-x-between">
        <div>
          <el-button
            v-auth="732"
            size="small"
            class="btn_insert"
            style="margin-right: 10px"
            @click="add"
            >新增</el-button
          ><el-button
            v-auth="734"
            size="small"
            class="btn_insert"
            @click.native="batchOperation(2, '批量删除')"
            >批量删除</el-button
          >
        </div>
        <el-button
          v-auth="729"
          size="small"
          type="text"
          class="blue"
          @click="downloadAllRecords()"
          >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
        ></el-button>
      </div>

      <div class="flex" style="height: 100%; overflow: auto">
        <div style="flex: 1; overflow: auto">
          <el-table
            class="custom-table"
            :data="listData"
            border
            v-fixedScrollBar="$refs.baseContainer"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              type="selection"
              width="40"
              prop="selection"
              align="center"
            >
            </el-table-column>
            <el-table-column type="index" label="序号" width="60">
            </el-table-column>
            <el-table-column label="操作" width="80">
              <template slot-scope="scope">
                <span
                  v-auth="733"
                  class="text_operation blue"
                  @click="edit(scope.row)"
                  >编辑</span
                >
              </template>
            </el-table-column>
            <el-table-column
              width="180"
              label="协议客户"
              prop="agreementClientName"
            >
            </el-table-column>
            <el-table-column
              width="180"
              label="服务协议项目"
              prop="serviceItemName"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column width="180" label="服务价" prop="servicePrice">
            </el-table-column>
            <el-table-column
              width="180"
              label="服务协议价"
              prop="serviceAgreementPrice"
            >
            </el-table-column>
            <el-table-column
              width="180"
              label="商品标准名"
              prop="goodsStandardName"
            >
            </el-table-column>
            <el-table-column width="180" label="商品名称" prop="goodsName">
            </el-table-column>
            <el-table-column width="180" label="原厂编码" prop="goodsCode">
            </el-table-column>
            <el-table-column width="180" label="商品售价" prop="goodsPrice">
            </el-table-column>
            <el-table-column
              width="180"
              label="商品协议价"
              prop="goodsAgreementPrice"
            >
            </el-table-column>
            <el-table-column
              width="100"
              label="品质"
              prop="manufactoryTypeDesc"
            >
            </el-table-column>
            <el-table-column width="180" label="品牌" prop="logoName">
            </el-table-column>
            <el-table-column width="180" label="车系" prop="seriesName">
            </el-table-column>
            <el-table-column width="180" label="型号" prop="modelName">
            </el-table-column>
            <el-table-column width="180" label="年款" prop="yearName">
            </el-table-column>
            <el-table-column
              width="100"
              label="建档人"
              prop="creator"
            ></el-table-column>
            <el-table-column
              width="180"
              label="更新时间"
              prop="lastUpdated"
            ></el-table-column>
          </el-table>
        </div>
      </div>
      <addOrEditAgreementPriceDialog
        v-if="showAddOrEditAgreementPriceDialog"
        :visibility.sync="showAddOrEditAgreementPriceDialog"
        :agreementId="editAgreementId"
        :usualQuoteId="editUsualQuoteId"
        :agreementServicePriceId="editAgreementServicePriceId"
        :agreementGoodsPriceId="editAgreementGoodsPriceId"
        @save="getData(false)"
      >
      </addOrEditAgreementPriceDialog>
    </template>

    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
  </base-container>
</template>

<script>
import pagination from '@/components/Pagination.vue';
import addOrEditAgreementPriceDialog from './components/addOrEditAgreementPriceDialog.vue';

import {
  getValidClientAgreementPriceList,
  validClientAgreementListExportUrl,
  clientAgreementPriceImport,
  getClientAgreementPriceImportResult,
  clientAgreementPriceImportFailedExportUrl,
  getValidClientAgreementPriceDetailList,
  validClientAgreementDetailListExportUrl,
  clientAgreementPriceInfo,
  clientAgreementPriceSave,
  clientAgreementPriceUpdate,
  clientAgreementPriceBatchDelete,
} from '@/api/customer/agreement';
import { downloadFile } from '@/utils/fileApi';
import { mapState } from 'vuex';
// 权限
import { hasAuth } from '@/utils/permissions';
export default {
  name: 'agreementPriceDetailList',
  components: { pagination, addOrEditAgreementPriceDialog },
  data() {
    return {
      total: 0,
      // 协议列表数据
      listData: [],
      pageOption: {
        size: 10,
        current: 1,
      },
      form: {},
      // 从协议价页面跳转过来的， 必传
      agreementId: null,
      // 批量删除选中的记录
      selectData: [],
      // 展示更多筛选
      showMore: false,
      exportUrl: validClientAgreementDetailListExportUrl,
      // 新增/编辑参数
      showAddOrEditAgreementPriceDialog: false,
      editAgreementId: '',
      editUsualQuoteId: '',
      editAgreementServicePriceId: '',
      editAgreementGoodsPriceId: '',
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  activated() {},
  created() {
    this.init();
  },
  methods: {
    init() {
      this.empty();
      this.agreementId = this.$route.query.agreementId;
      this.getData();
    },
    /**
     * 查询列表
     */
    doSearch() {
      this.getData(true);
    },
    handleSelectionChange(items, row) {
      this.selectData = items;
    },
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getData(false);
    },
    /**
     * 加载数据
     * @param params 右侧状态条件
     */
    getData(reset = true) {
      var res = hasAuth(708);
      if (!res) {
        return;
      }
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      var preData = this.buildQueryData();
      var data = Object.assign({}, preData, this.pageOption);

      getValidClientAgreementPriceDetailList(data).then((res) => {
        this.total = res.total;
        this.listData = res.records;
      });
    },
    buildQueryData() {
      var times = {
        agreementId: this.agreementId,
      };
      return Object.assign({}, this.form, times);
    },
    downloadAllRecords() {
      downloadFile({
        url: this.exportUrl,
        data: Object.assign({}, this.buildQueryData()),
      });
    },
    /**
     * 跳转新增页
     */
    add() {
      this.showAddOrEditAgreementPriceDialog = true;
      this.editAgreementId = this.agreementId;
      this.editUsualQuoteId = '';
      this.editAgreementServicePriceId = '';
      this.editAgreementGoodsPriceId = '';
    },
    /**
     * 点击编辑跳到新增协议页面
     */
    edit(row) {
      this.showAddOrEditAgreementPriceDialog = true;
      this.editAgreementId = row.agreementId;
      this.editUsualQuoteId = row.usualQuoteId;
      this.editAgreementServicePriceId = row.servicePriceId;
      this.editAgreementGoodsPriceId = row.goodsPriceId || '';
    },
    /**
     * 批量操作
     * @param {Integer} status
     * @param {String} str
     */
    batchOperation(status, str) {
      if (this.selectData.length === 0) {
        return this.$message({
          type: 'warning',
          message: '请选择数据后操作',
        });
      }
      let txt = `确定要${str}选中的记录吗？`;
      this.$confirm(txt, '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const body = {
          batchDelItems: [],
        };
        this.selectData.forEach((item) => {
          body.batchDelItems.push({
            agreementId: item.agreementId,
            usualQuoteId: item.usualQuoteId,
            agreementServicePriceId: item.servicePriceId,
            agreementGoodsPriceId: item.goodsPriceId,
          });
        });
        clientAgreementPriceBatchDelete(body).then((res) => {
          this.$message({
            type: 'success',
            message: '批量删除成功',
          });
          this.getData(false);
        });
      });
    },
    /**
     * 置空输入框数据
     */
    empty() {
      this.form = {};
    },
  },
};
</script>
<style lang="scss" scoped>
.class-box {
  width: 180px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  box-sizing: border-box;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  .el-tree {
    overflow: auto;
  }
}
.class-box .title {
  flex-shrink: 0;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #e4e4e4;
  background: #fafafa;
  color: #888;
  font-size: 14px;
}
.tree-node {
  color: #888;
  font-size: 14px;
}
</style>
