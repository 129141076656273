var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { ref: "baseContainer", attrs: { paddingTop: "" } },
    [
      [
        _c(
          "el-form",
          {
            staticClass: "filter-form",
            attrs: { model: _vm.form, "label-width": "100px", size: "small" },
          },
          [
            _c("div", { staticClass: "flex-x-between" }, [
              _c(
                "div",
                { staticClass: "flex flex-shrink0" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "反馈人" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            clearable: "",
                            remote: "",
                            placeholder: "搜索姓名/电话",
                            "remote-method": _vm.searchUserList,
                            loading: _vm.searchUserLoading,
                          },
                          model: {
                            value: _vm.form.userId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "userId", $$v)
                            },
                            expression: "form.userId",
                          },
                        },
                        _vm._l(_vm.feedbackUserList, function (item) {
                          return _c(
                            "el-option",
                            {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            },
                            [_vm._v(" " + _vm._s(item.label) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "签约客户" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            clearable: "",
                            remote: "",
                            placeholder: "搜索名称",
                            "remote-method": _vm.searchClient,
                            loading: _vm.searchClientLoading,
                          },
                          model: {
                            value: _vm.form.clientId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "clientId", $$v)
                            },
                            expression: "form.clientId",
                          },
                        },
                        _vm._l(_vm.customList, function (item) {
                          return _c(
                            "el-option",
                            {
                              key: item.id,
                              attrs: { label: item.clientName, value: item.id },
                            },
                            [_vm._v(" " + _vm._s(item.clientName) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "反馈时间" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          "unlink-panels": "",
                          "range-separator": "至",
                          "start-placeholder": "起始时间",
                          "end-placeholder": "截止时间",
                          "value-format": "yyyy-MM-dd",
                        },
                        model: {
                          value: _vm.form.checkTimes,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "checkTimes", $$v)
                          },
                          expression: "form.checkTimes",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "el-form-item--small" }, [
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "btn_search",
                        attrs: { type: "primary", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.getDataList()
                          },
                        },
                      },
                      [_vm._v("查询")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn_search",
                        attrs: { size: "small" },
                        on: { click: _vm.empty },
                      },
                      [_vm._v("置空")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        ),
      ],
      [
        _c(
          "div",
          {
            staticClass: "btns flex-x-end",
            staticStyle: { "margin-bottom": "15px" },
          },
          [
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 608,
                    expression: "608",
                  },
                ],
                staticClass: "blue",
                attrs: { size: "small", type: "text" },
                on: {
                  click: function ($event) {
                    _vm.showExportDialog = true
                  },
                },
              },
              [
                _vm._v("导出列表"),
                _c("i", {
                  staticClass: "iconfont grow-icon_daochu el-icon--right",
                }),
              ]
            ),
          ],
          1
        ),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "fixedScrollBar",
                rawName: "v-fixedScrollBar",
                value: _vm.$refs.baseContainer,
                expression: "$refs.baseContainer",
              },
            ],
            staticClass: "custom-table",
            attrs: { data: _vm.tableData, border: "" },
          },
          [
            _c("el-table-column", {
              attrs: { type: "index", width: "60", label: "序号" },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "feedbackDateTime",
                label: "反馈时间",
                width: "160",
              },
            }),
            _c("el-table-column", {
              attrs: { prop: "userName", label: "反馈人", width: "120" },
            }),
            _c("el-table-column", {
              attrs: { label: "反馈电话", width: "120", prop: "phone" },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "clientName",
                label: "所属签约客户",
                width: "120",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "span",
                        {
                          staticClass: "pointer text_operation blue",
                          on: {
                            click: function ($event) {
                              return _vm.goDetail(scope.row)
                            },
                          },
                        },
                        [_vm._v(_vm._s(scope.row.clientName))]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                label: "图片 | 视频",
                width: "200",
                "header-align": "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "flex-y-center",
                          staticStyle: {
                            padding: "10px 0",
                            "justify-content": "space-evenly",
                          },
                        },
                        [
                          !row.picList.length
                            ? _c(
                                "span",
                                {
                                  staticStyle: {
                                    width: "32px",
                                    "text-align": "center",
                                  },
                                },
                                [_vm._v("无")]
                              )
                            : _c(
                                "el-popover",
                                {
                                  attrs: { placement: "top", trigger: "hover" },
                                },
                                [
                                  _c("upload-file-list", {
                                    attrs: { disabled: "", value: row.picList },
                                  }),
                                  _c("div", { staticClass: "video-list" }),
                                  _c(
                                    "template",
                                    { slot: "reference" },
                                    [
                                      _c(
                                        "el-badge",
                                        {
                                          staticStyle: {
                                            "margin-right": "8px",
                                          },
                                          attrs: { value: row.picList.length },
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "thumbnail-img",
                                            attrs: { src: row.picList[0] },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                          _c("div", {
                            staticStyle: {
                              width: "2px",
                              height: "32px",
                              margin: "0 8px",
                              background: "#ebeef5",
                            },
                          }),
                          !_vm.$lodash.get(row, "videoList.length", 0)
                            ? _c(
                                "span",
                                {
                                  staticStyle: {
                                    width: "32px",
                                    "text-align": "center",
                                  },
                                },
                                [_vm._v("无")]
                              )
                            : _c(
                                "el-popover",
                                {
                                  attrs: { placement: "top", trigger: "hover" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "video-list" },
                                    _vm._l(
                                      row.videoList,
                                      function (src, index) {
                                        return _c(
                                          "video",
                                          {
                                            key: index,
                                            staticClass: "thumbnail-video",
                                            attrs: {
                                              preload: "auto",
                                              src: src,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleClickVideo(
                                                  $event,
                                                  row.videoList,
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("source", {
                                              attrs: {
                                                src: src,
                                                type: "video/mp4",
                                              },
                                            }),
                                            _vm._v(
                                              " 您的设备不支持 video 标签。 "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                  _c(
                                    "template",
                                    { slot: "reference" },
                                    [
                                      _c(
                                        "el-badge",
                                        {
                                          staticStyle: {
                                            "margin-right": "8px",
                                          },
                                          attrs: {
                                            value: row.videoList.length,
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "thumbnail-img",
                                            attrs: { src: _vm.playImg },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                label: "反馈内容",
                "min-width": "120",
                prop: "feedbackContent",
              },
            }),
          ],
          1
        ),
        _c("export-dialog", {
          attrs: {
            visibility: _vm.showExportDialog,
            total: _vm.total,
            pageOption: _vm.pageOption,
            filterForm: _vm.form,
            url: _vm.exportUrl,
          },
          on: {
            "update:visibility": function ($event) {
              _vm.showExportDialog = $event
            },
          },
        }),
        _vm.showVideoPlayerDialog
          ? _c("videoPlayerDialog", {
              attrs: {
                visibility: _vm.showVideoPlayerDialog,
                videos: _vm.previewVideos,
                index: _vm.previewVideoIndex,
                modal: false,
              },
              on: {
                "update:visibility": function ($event) {
                  _vm.showVideoPlayerDialog = $event
                },
              },
            })
          : _vm._e(),
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c("pagination", {
            attrs: { total: _vm.total },
            on: { pageChange: _vm.changePage },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }