var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "终检详情",
            width: "60%",
            visible: _vm.visibility,
            "before-close": _vm.handleClose,
            "close-on-click-modal": false,
            "append-to-body": "",
          },
        },
        [
          _c(
            "div",
            { staticClass: "quality-testing_box" },
            [
              _c("div", { staticClass: "bill-info" }, [
                _c("div", { staticClass: "row flex-x-between" }, [
                  _c("div", { staticClass: "col flex" }, [
                    _c("div", { staticClass: "label" }, [_vm._v("接车单：")]),
                    _c("div", { staticClass: "value" }, [
                      _c(
                        "span",
                        {
                          staticClass: "blue pointer",
                          on: {
                            click: function ($event) {
                              return _vm.goCarBillDetail(_vm.detailData.billId)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.detailData.billCode))]
                      ),
                      _vm.detailData.billType == 0
                        ? _c("span", { staticClass: "color999" }, [
                            _vm._v(
                              " ( " +
                                _vm._s(
                                  _vm.detailData.isOthersSelfPayState
                                    ? "他人自费"
                                    : "车主自费"
                                ) +
                                ") "
                            ),
                          ])
                        : _c("span", { staticClass: "color999" }, [
                            _vm._v(
                              " ( " +
                                _vm._s(
                                  _vm._f("dict")(
                                    _vm.detailData.billType,
                                    "carBillType"
                                  )
                                ) +
                                ") "
                            ),
                          ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "col flex" }, [
                    _c("div", { staticClass: "label" }, [
                      _vm._v("预交车时间："),
                    ]),
                    _c("div", { staticClass: "value" }, [
                      _vm.detailData.planFinishTime
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.detailData.planFinishTime)),
                          ])
                        : _vm._e(),
                      _vm.detailData.waitInStore
                        ? _c("span", { staticClass: "color999" }, [
                            _vm._v("( 在店等) "),
                          ])
                        : _vm._e(),
                      _vm.detailData.undetermined
                        ? _c("span", [_vm._v(" 待定 ")])
                        : _vm._e(),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "row flex-x-between",
                    staticStyle: { "margin-top": "20px" },
                  },
                  [
                    _c("div", { staticClass: "col flex" }, [
                      _c("div", { staticClass: "label" }, [_vm._v("车牌号：")]),
                      _c("div", { staticClass: "value" }, [
                        _c("span", [_vm._v(_vm._s(_vm.detailData.carNumber))]),
                        _vm.detailData.vehicleType !== undefined
                          ? _c("span", [
                              _vm._v(
                                " (" + _vm._s(_vm.detailData.vehicleType) + ") "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                    _c("div", { staticClass: "col flex" }, [
                      _c("div", { staticClass: "label" }, [
                        _vm._v("车型/车架号："),
                      ]),
                      _c("div", { staticClass: "value" }, [
                        _c("span", [_vm._v(_vm._s(_vm.detailData.carModel))]),
                        _vm.detailData.uniqueId
                          ? _c(
                              "span",
                              {
                                staticStyle: {
                                  display: "block",
                                  "margin-top": "10px",
                                },
                              },
                              [
                                _vm._v(
                                  " VIN: " +
                                    _vm._s(_vm.detailData.uniqueId) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "row flex-x-between",
                    staticStyle: { "margin-top": "20px" },
                  },
                  [
                    _c("div", { staticClass: "col flex" }, [
                      _c("div", { staticClass: "label" }, [
                        _vm._v("最后质检时间："),
                      ]),
                      _c("div", { staticClass: "value" }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.detailData.taskFinishTime)),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "col flex" }, [
                      _c("div", { staticClass: "label" }, [
                        _vm._v("最后质检员："),
                      ]),
                      _c("div", { staticClass: "value" }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.detailData.taskFinishUserName)),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    border: "",
                    data: _vm.taskList,
                    "span-method": _vm.spanMethod,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "序号", width: "80", prop: "index" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "报价项目", width: "180" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("p", [_vm._v(_vm._s(row.serviceItemName))]),
                            row.serviceItemCode
                              ? _c("span", [
                                  _vm._v(
                                    "编码：" + _vm._s(row.serviceItemCode)
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { width: "110", prop: "tags", label: "内部标签" },
                  }),
                  _c("el-table-column", {
                    attrs: { width: "110", label: "施工技师" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("div", [_vm._v(_vm._s(row.technicianNames))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      width: "110",
                      prop: "reworkTimes",
                      label: "返工次数",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      width: "160",
                      prop: "updateTime",
                      label: "最后完工时间",
                    },
                  }),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        "header-align": "center",
                        label: "历史返工详情",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          width: "140",
                          label: "质检时间",
                          prop: "qualityInspectionTime",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          width: "110",
                          label: "质检员",
                          prop: "qualityInspectionUserName",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "返工原因", prop: "reworkReason" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.handleClose } },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }