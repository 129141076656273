var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { attrs: { paddingTop: "" } },
    [
      _c(
        "el-tabs",
        {
          staticClass: "tabs",
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "车辆信息", name: "first" } },
            [
              _c(
                "el-descriptions",
                {
                  staticStyle: { "margin-bottom": "60px" },
                  attrs: {
                    column: 3,
                    border: "",
                    labelClassName: "table-label",
                    contentStyle: { verticalAlign: "middle" },
                  },
                },
                [
                  _c("el-descriptions-item", { attrs: { label: "车牌号" } }, [
                    _vm._v(_vm._s(_vm.detailData.carNumber)),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "车型" } }, [
                    _vm._v(" " + _vm._s(_vm.detailData.type) + " "),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "车架号" } }, [
                    _vm._v(" " + _vm._s(_vm.detailData.uniqueId) + " "),
                    _vm.detailData.uniqueId
                      ? _c(
                          "span",
                          {
                            staticClass: "text_operation blue",
                            on: {
                              click: function ($event) {
                                _vm.showCarConfigDialog = true
                              },
                            },
                          },
                          [_vm._v("配置信息")]
                        )
                      : _vm._e(),
                  ]),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "车辆所有人" } },
                    [_vm._v(" " + _vm._s(_vm.detailData.vehicleOwner) + " ")]
                  ),
                  _c("el-descriptions-item", { attrs: { label: "签约客户" } }, [
                    _vm.$lodash.get(_vm.detailData, "clientBriefMessage.id", "")
                      ? _c("div", { staticClass: "client" }, [
                          _c("div", { staticClass: "client-info" }, [
                            _c(
                              "div",
                              { staticClass: "flex-x-between flex-y-center" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "blue pointer",
                                    on: { click: _vm.goClientDetail },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$lodash.get(
                                          _vm.detailData,
                                          "clientBriefMessage.clientName",
                                          ""
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      position: "relative",
                                      "min-width": "20px",
                                      "min-height": "16px",
                                      "line-height": "16px",
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$lodash.get(
                                            _vm.detailData,
                                            "clientBriefMessage.settlementType",
                                            ""
                                          )
                                            ? "月结"
                                            : "现结"
                                        )
                                      ),
                                    ]),
                                    _c("i", {
                                      staticClass: "iconfont icon_grade",
                                      class:
                                        _vm.levelIconsOther[
                                          _vm.$lodash.get(
                                            _vm.detailData,
                                            "clientBriefMessage.clientLevel",
                                            ""
                                          )
                                        ],
                                      attrs: {
                                        title:
                                          [
                                            _vm.$lodash.get(
                                              _vm.detailData,
                                              "clientBriefMessage.clientLevel",
                                              ""
                                            ),
                                          ] || "",
                                      },
                                    }),
                                    _vm.$lodash.get(
                                      _vm.detailData,
                                      "clientBriefMessage.hasClientAgreement",
                                      ""
                                    )
                                      ? _c("span", [
                                          _vm._v(" 协议客户 "),
                                          _vm.$lodash.get(
                                            _vm.detailData,
                                            "clientBriefMessage.beContract",
                                            ""
                                          )
                                            ? _c("span", [_vm._v("（包干）")])
                                            : _vm._e(),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ])
                      : _c("div", { staticClass: "color999" }, [
                          _vm._v("车辆暂未绑定客户"),
                        ]),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "注册时间" } }, [
                    _vm._v(_vm._s(_vm.detailData.registerDate) + " "),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "在租状态" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("dict")(
                            _vm.detailData.underLeaseState,
                            "underLeaseState"
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "真实续航" } }, [
                    _vm._v(" " + _vm._s(_vm.detailData.trueEndurance) + " "),
                    _vm.detailData.newVehicleMileage
                      ? _c("span", { staticClass: "color666" }, [
                          _vm._v(
                            " （新车续航:" +
                              _vm._s(_vm.detailData.newVehicleMileage) +
                              "） "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "使用性质" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("dict")(
                            _vm.detailData.annualSurveyCarType,
                            "annualSurveyCarType"
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "动力类型" } }, [
                    _vm._v(" " + _vm._s(_vm.detailData.fuelTypeValue) + " "),
                  ]),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "电机号/发动机号", span: 2 } },
                    [_vm._v(" " + _vm._s(_vm.detailData.engineNumber) + " ")]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "备注", span: 3 } },
                    [
                      _c("p", { staticClass: "pre-wrap" }, [
                        _vm._v(_vm._s(_vm.detailData.remark)),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { span: 3, label: "车辆图片" } },
                    [
                      _vm.$lodash.get(
                        _vm.detailData,
                        "picCarLists.length",
                        false
                      )
                        ? _c(
                            "div",
                            { staticClass: "flex" },
                            _vm._l(
                              _vm.detailData.picCarLists,
                              function (item, index) {
                                return _c("upload-file", {
                                  key: index,
                                  attrs: {
                                    disabled: "",
                                    title: item.picTypeName,
                                    value: item.picUrlName,
                                    imagesForPreview:
                                      _vm.detailData.picCarLists.map(
                                        (img) => img.picUrlName
                                      ),
                                  },
                                })
                              }
                            ),
                            1
                          )
                        : _c("div", { staticClass: "color999" }, [
                            _vm._v("无附件图片"),
                          ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "box" },
                [
                  _c("div", { staticClass: "title" }, [_vm._v("在保状况")]),
                  _c(
                    "el-descriptions",
                    {
                      attrs: {
                        column: 5,
                        direction: "vertical",
                        border: "",
                        labelClassName: "table-label",
                        contentStyle: { verticalAlign: "middle", padding: 0 },
                      },
                    },
                    [
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "整车质保" } },
                        [
                          _c("div", { staticClass: "flex-y-center" }, [
                            _c("span", { staticClass: "cell-left" }, [
                              _vm.detailData.hasVehicleWarranty
                                ? _c("span", [_vm._v(" 在保 ")])
                                : _vm.detailData.hasVehicleWarranty === 0
                                ? _c("span", [_vm._v("过保")])
                                : _c("span", [_vm._v("未知")]),
                            ]),
                            _c("span", { staticClass: "cell-right" }, [
                              _vm._v(
                                _vm._s(_vm.detailData.vehicleWarrantyContent)
                              ),
                            ]),
                          ]),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "三电质保" } },
                        [
                          _c("div", { staticClass: "flex-y-center" }, [
                            _c("span", { staticClass: "cell-left" }, [
                              _vm.detailData.hasThreePowerWarranty
                                ? _c("span", [_vm._v(" 在保 ")])
                                : _vm.detailData.hasThreePowerWarranty === 0
                                ? _c("span", [_vm._v("过保")])
                                : _c("span", [_vm._v("未知")]),
                            ]),
                            _c("span", { staticClass: "cell-right" }, [
                              _vm._v(
                                _vm._s(_vm.detailData.threePowerWarrantyContent)
                              ),
                            ]),
                          ]),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "动力电池质保" } },
                        [
                          _c("div", { staticClass: "flex-y-center" }, [
                            _c("span", { staticClass: "cell-left" }, [
                              _vm.detailData.hasPowerBatteryWarranty
                                ? _c("span", [_vm._v(" 在保 ")])
                                : _vm.detailData.hasPowerBatteryWarranty === 0
                                ? _c("span", [_vm._v("过保")])
                                : _c("span", [_vm._v("未知")]),
                            ]),
                            _c("span", { staticClass: "cell-right" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.detailData.powerBatteryWarrantyContent
                                )
                              ),
                            ]),
                          ]),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "电芯质保" } },
                        [
                          _c("div", { staticClass: "flex-y-center" }, [
                            _c("span", { staticClass: "cell-left" }, [
                              _vm.detailData.hasBatteryWarranty
                                ? _c("span", [_vm._v(" 在保 ")])
                                : _vm.detailData.hasBatteryWarranty === 0
                                ? _c("span", [_vm._v("过保")])
                                : _c("span", [_vm._v("未知")]),
                            ]),
                            _c("span", { staticClass: "cell-right" }, [
                              _vm._v(
                                _vm._s(_vm.detailData.batteryWarrantyContent)
                              ),
                            ]),
                          ]),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "易损件质保" } },
                        [
                          _c("div", { staticClass: "flex-y-center" }, [
                            _c("span", { staticClass: "cell-left" }, [
                              _vm.detailData.hasWarrantyWearingParts
                                ? _c("span", [_vm._v(" 在保 ")])
                                : _vm.detailData.hasWarrantyWearingParts === 0
                                ? _c("span", [_vm._v("过保")])
                                : _c("span", [_vm._v("未知")]),
                            ]),
                            _c("span", { staticClass: "cell-right" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.detailData.warrantyWearingPartsContent
                                )
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "title" }, [_vm._v("消费情况")]),
                  _c(
                    "el-descriptions",
                    {
                      attrs: {
                        column: 3,
                        direction: "vertical",
                        border: "",
                        labelClassName: "table-label",
                        contentStyle: { verticalAlign: "middle" },
                      },
                    },
                    [
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "最后到店时间" } },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.detailData.lastIntoStoreDate) + " "
                          ),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "最后到店里程" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.detailData.lastIntoStoreMileage) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "到店详情" } },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.detailData.intoStoreDetails) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "title" }, [_vm._v("运营情况")]),
                  _c(
                    "el-descriptions",
                    {
                      attrs: {
                        column: 3,
                        direction: "vertical",
                        border: "",
                        labelClassName: "table-label",
                        contentStyle: { verticalAlign: "middle" },
                      },
                    },
                    [
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "车辆来源" } },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.detailData.vehicleSource) + " "
                          ),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "发车模式" } },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.detailData.departureMode) + " "
                          ),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "车辆状态" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("dict")(_vm.detailData.state, "carState")
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "title" }, [_vm._v("保险信息")]),
                  _c(
                    "el-descriptions",
                    {
                      staticStyle: { "margin-bottom": "60px" },
                      attrs: {
                        column: 3,
                        border: "",
                        labelClassName: "table-label",
                        contentStyle: { verticalAlign: "middle" },
                      },
                    },
                    [
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "车损险" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.detailData.insuranceCompanyLabel) +
                              " "
                          ),
                          _vm.detailData.insuranceValidDate
                            ? _c("span", [
                                _vm._v(
                                  "/ " +
                                    _vm._s(_vm.detailData.insuranceValidDate) +
                                    "起保"
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "三者险" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.detailData.thirdPartyInsuranceCompanyName
                              ) +
                              " "
                          ),
                          _vm.detailData.thirdPartyInsuranceValidDate
                            ? _c("span", [
                                _vm._v(
                                  "/ " +
                                    _vm._s(
                                      _vm.detailData
                                        .thirdPartyInsuranceValidDate
                                    ) +
                                    "起保"
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "座位险" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.detailData.seatInsuranceCompanyName) +
                              " "
                          ),
                          _vm.detailData.seatInsuranceValidDate
                            ? _c("span", [
                                _vm._v(
                                  "/ " +
                                    _vm._s(
                                      _vm.detailData.seatInsuranceValidDate
                                    ) +
                                    "起保"
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "交强险" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.detailData.tcInsuranceCompanyLabel) +
                              " "
                          ),
                          _vm.detailData.tcInsuranceValidDate
                            ? _c("span", [
                                _vm._v(
                                  "/ " +
                                    _vm._s(
                                      _vm.detailData.tcInsuranceValidDate
                                    ) +
                                    "起保"
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "承运险" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.detailData.vehicleInsuranceCompanyLabel
                              ) +
                              " "
                          ),
                          _vm.detailData.vehicleInsuranceValidDate
                            ? _c("span", [
                                _vm._v(
                                  "/ " +
                                    _vm._s(
                                      _vm.detailData.vehicleInsuranceValidDate
                                    ) +
                                    "起保"
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "驾乘险" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.detailData.drivingInsuranceCompanyName
                              ) +
                              " "
                          ),
                          _vm.detailData.drivingInsuranceValidDate
                            ? _c("span", [
                                _vm._v(
                                  "/ " +
                                    _vm._s(
                                      _vm.detailData.drivingInsuranceValidDate
                                    ) +
                                    "起保"
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "格悦出单" } },
                        [
                          _vm.detailData.geyueIssuer ||
                          _vm.detailData.geyueIssuer == 0
                            ? _c("p", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.detailData.geyueIssuer == 0
                                        ? "否"
                                        : "是"
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "团车政策" } },
                        [
                          _vm.detailData.groupCarPolicy ||
                          _vm.detailData.groupCarPolicy == 0
                            ? _c("p", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.detailData.groupCarPolicy == 0
                                        ? "否"
                                        : "是"
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "团车代码" } },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.detailData.groupCarCode) + " "
                          ),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "承保特殊要求", span: 3 } },
                        [
                          _c("p", { staticClass: "pre-wrap" }, [
                            _vm._v(_vm._s(_vm.detailData.insureSpecialAsk)),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            {
              directives: [
                {
                  name: "auth",
                  rawName: "v-auth",
                  value: 337,
                  expression: "337",
                },
              ],
              attrs: { label: "消费记录", name: "second" },
            },
            [
              _c("consumption-records", {
                attrs: { carId: _vm.$route.query.id },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            {
              directives: [
                {
                  name: "auth",
                  rawName: "v-auth",
                  value: 667,
                  expression: "667",
                },
              ],
              attrs: { label: "修改日志", name: "third" },
            },
            [
              _c("carChangeLogRecords", {
                attrs: { carId: _vm.$route.query.id },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          { staticClass: "flex-x-end foot-btn" },
          [
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 53,
                    expression: "53",
                  },
                ],
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    _vm.showAddCarDialog = true
                  },
                },
              },
              [_vm._v("编辑车辆")]
            ),
            _c(
              "el-dropdown",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 336,
                    expression: "336",
                  },
                ],
                attrs: { size: "small", trigger: "click", placement: "top" },
              },
              [
                _c("el-button", [
                  _vm._v(" 更多操作"),
                  _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
                ]),
                _c(
                  "el-dropdown-menu",
                  {
                    staticStyle: { width: "110px" },
                    attrs: { slot: "dropdown" },
                    slot: "dropdown",
                  },
                  [
                    _c(
                      "el-dropdown-item",
                      {
                        staticClass: "btn-dropdown",
                        attrs: { icon: "el-icon-delete" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.del.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm.showAddCarDialog
        ? _c("add-car-dialog", {
            attrs: {
              showAddCarDialog: _vm.showAddCarDialog,
              id: _vm.$route.query.id,
            },
            on: {
              "update:showAddCarDialog": function ($event) {
                _vm.showAddCarDialog = $event
              },
              "update:show-add-car-dialog": function ($event) {
                _vm.showAddCarDialog = $event
              },
              save: _vm.refresh,
            },
          })
        : _vm._e(),
      _vm.showCarConfigDialog
        ? _c("car-config-detail-dialog", {
            attrs: {
              showCarConfigDialog: _vm.showCarConfigDialog,
              vehicleList: _vm.vehicleList,
              uniqueId: _vm.detailData.uniqueId,
            },
            on: {
              "update:showCarConfigDialog": function ($event) {
                _vm.showCarConfigDialog = $event
              },
              "update:show-car-config-dialog": function ($event) {
                _vm.showCarConfigDialog = $event
              },
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }