<template>
  <base-container
    customStyle="background: #f1f7fa;"
    customMainStyle="margin-bottom: 16px;"
    :defaultPadding="false"
  >
    <div style="height: 100%" class="flex-x-between">
      <div class="preview">
        <div class="material-info">
          <!-- <div class="title">{{ title }}</div> -->
          <!-- <p>技术案例：{{ title }}</p> -->
          <div class="flex">
            <div class="tag">技术案例</div>
            <!-- <el-tag type="info">技术案例</el-tag> -->
            <div class="title">{{ title }}</div>
          </div>
          <p>供稿：{{ companyName }} {{ creatorName }}</p>
        </div>
        <div class="preview-html-box not-apply-reset" v-html="html"></div>
      </div>
      <div class="material-box">
        <el-row class="flex-y-center">
          <el-col :span="21">
            <div class="flex-y-center">
              <span style="margin: 0 5px; color: #e84c4c">*</span>
              <span
                style="
                  margin-right: 10px;
                  flex-shrink: 0;
                  font-size: 14px;
                  color: #242526;
                "
                >标题</span
              >
              <el-input
                v-model="title"
                placeholder="请填写"
                maxlength="255"
                show-word-limit
              ></el-input>
            </div>
          </el-col>
          <el-col :span="2" :offset="1">
            <el-button @click="handleUseTemplate">使用模板</el-button>
          </el-col>
        </el-row>
        <p style="margin: 12px 0 20px 0; font-size: 12px; color: #606266">
          <i
            class="el-icon-warning orange"
            style="font-size: 14px; margin-right: 5px"
          ></i>
          注意：标题请按模板填写：车型-故障现象-故障原因
        </p>
        <div class="editor-container">
          <Toolbar
            style="border-bottom: 1px solid #dcdfe6"
            :editor="editor"
            :defaultConfig="toolbarConfig"
            :mode="mode"
          />
          <Editor
            v-loading="showLoading"
            style="flex: 1; height: 100%; overflow-y: hidden"
            class="not-apply-reset"
            v-model="html"
            :defaultConfig="editorConfig"
            :mode="mode"
            @onCreated="onCreated"
          />
        </div>
      </div>
    </div>
    <template slot="footer">
      <div class="footer-box">
        <div>
          <span style="margin-right: 5px; font-size: 14px; color: #242526"
            >供稿人</span
          >
          <searchEmployeePopover
            :clearable="false"
            :searchKeyWord="creatorName"
            @select="handleSelectEmployee"
            @clear="handleClearEmployee"
          ></searchEmployeePopover>
        </div>
        <div class="flex">
          <template v-if="auth">
            <el-button type="primary" size="small" @click="save(1)"
              >提交上架</el-button
            >
          </template>
          <template v-else>
            <el-button type="primary" size="small" @click="save(2)"
              >提交审核</el-button
            >
          </template>
          <el-button
            v-if="![1, 4].includes(state)"
            type="primary"
            plain
            size="small"
            @click="save(3)"
            >存为草稿</el-button
          >
          <el-button size="small" @click="closePage">取 消</el-button>
        </div>
      </div>
    </template>

    <!-- 上架弹窗 -->
    <el-dialog
      title="通过"
      :visible.sync="submitDialogVisible"
      width="30%"
      top="20vh"
    >
      <el-form size="small">
        <el-form-item label="车型信息" label-width="80px" required>
          <el-select
            class="w100"
            v-model="carModel"
            filterable
            remote
            placeholder="搜索车型"
            :remote-method="searchModel"
            :loading="searchModelLoading"
            clearable
          >
            <el-option
              v-for="item in models"
              :key="$lodash.get(item, 'series.id')"
              :value="
                $lodash.get(
                  item,
                  'series.name',
                  $lodash.get(
                    item,
                    'brand.name',
                    $lodash.get(item, 'logo.name')
                  )
                )
              "
              @click.native="selectCarModel(item)"
            >
              <span v-html="item.markedRedName"></span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属系统" label-width="80px" required>
          <el-select
            class="w100"
            v-model="guideSysId"
            placeholder="请选择"
            filterable
          >
            <el-option
              v-for="item in systemList"
              :key="item.sysId"
              :label="item.name"
              :value="item.sysId"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template slot="footer">
        <el-button size="small" type="primary" @click="submit(true)"
          >上 架</el-button
        >
        <el-button v-if="state != 4" size="small" @click="submit(false)"
          >暂不上架</el-button
        >
      </template>
    </el-dialog>
  </base-container>
</template>

<script>
import bus from '@/service/bus';
import mammoth from 'mammoth/mammoth.browser.min.js';

import { Editor, Toolbar } from '@wangeditor/editor-for-vue';
import {
  IButtonMenu,
  DomEditor,
  Boot,
  SlateTransforms,
} from '@wangeditor/editor';

import { mapGetters, mapState, mapMutations } from 'vuex';

// 接口
import {
  getMaterialInfo,
  addAndEditMaterial,
  getWzGuideSystemList,
  newSearchCarModel,
} from '@/api/technologyCenter';

// 文件工具
import {
  chooseFile,
  uploadImage,
  uploadVideo,
  base64ToFile,
} from '@/utils/fileApi';

import { hasAuth } from '@/utils/permissions';

import searchEmployeePopover from '@/components/businessComponent/searchEmployeePopover.vue';

export default {
  name: 'materialEdit',
  components: { Editor, Toolbar, searchEmployeePopover },
  data() {
    return {
      editor: null,
      toolbarConfig: {
        insertKeys: {
          index: 22, // 插入的位置，基于当前的 toolbarKeys
          keys: ['uploadVideo'],
        },
        excludeKeys: ['insertVideo', 'insertTable', 'codeBlock'],
      },
      editorConfig: {
        placeholder: '请输入内容...',
        MENU_CONF: {
          fontSize: {
            fontSizeList: [
              // 元素支持两种形式
              //   1. 字符串；
              //   2. { name: 'xxx', value: 'xxx' }

              '12px',
              '13px',
              '14px',
              '15px',
              '16px',
              '17px',
              '18px',
              '19px',
              '20px',
              '21px',
              '22px',
              '23px',
              '24px',
              '25px',
              '26px',
              '27px',
              '28px',
              '29px',
              '30px',
              '31px',
              '32px',
              '33px',
              '34px',
              '35px',
              '36px',
              '37px',
              '38px',
              '39px',
              '40px',
              '41px',
              '42px',
              '43px',
              '44px',
              '45px',
              '46px',
              '47px',
              '48px',
            ],
          },
        },
      },
      mode: 'simple', // 'default' or 'simple'

      // 指南id
      id: '',
      // 状态
      state: '',
      // 标题
      title: '',
      // 投稿人Id
      creatorId: '',
      // 投稿人名字
      creatorName: '',
      // 投稿门店Id
      companyId: '',
      // 投稿门店名称
      companyName: '',
      // 审稿人名字
      verifierName: '',
      // 审稿门店
      verifierCompany: '',
      // 投稿时间
      dateCreated: '',
      // html
      html: '',

      // 模板内容
      templateContent:
        '<h3>一、车辆信息</h3><p><span style="color: rgb(85, 85, 85);">车型：</span></p><p><span style="color: rgb(85, 85, 85);">行驶里程：</span></p><h3>二、故障现象</h3><p><br></p><h3>三、故障可能原因分析</h3><p><br></p><h3>四、检修过程</h3><p><br></p><h3>五：修理结果与心得</h3><p><br></p><p><br></p><p><br></p>',

      // 提交上架弹窗
      submitDialogVisible: false,

      // 车型信息
      searchModelLoading: false,
      models: [],
      carModel: '',
      modelDetailLogoId: '',
      modelDetailBrandId: '',
      modelDetailSeriesId: '',

      // 所属系统
      systemList: [],
      guideSysId: '',

      // 加载
      showLoading: false,
    };
  },

  created() {
    this.id = this.$route.query?.id;
    // 配置自定义上传图片、视频、扩展菜单
    this.customConfig();
    if (!this.id) {
      this.html = this.templateContent;
    }
    bus.$on('importWordEvent', this.handleImportWord);
  },
  mounted() {
    if (this.id) {
      this.getData();
    } else {
      console.log(this.$store.state.user);
      this.companyId = this.companyId_g;
      this.companyName = this.companyName_g;
      this.creatorId = this.userId;
      this.creatorName = this.realName;
    }
    this.searchModel();
    this.getWzGuideSystemList();
  },
  computed: {
    ...mapGetters(['activeTagIndex']),
    ...mapState({
      companyId_g: (state) => state.user.companyId,
      companyName_g: (state) => state.user.companyName,
      realName: (state) => state.user.userInfo.realName,
      userId: (state) => state.user.userInfo.userId,
    }),

    // 是否使用模板
    isUseTemplate() {
      return String.prototype.startsWith.call(this.html, this.templateContent);
    },

    auth() {
      return hasAuth(550);
    },
  },
  methods: {
    ...mapMutations({
      closeTag: 'DEL_TAG',
    }),
    /**
     * 配置自定义上传图片视频
     */
    customConfig() {
      // 自定义上传图片
      this.editorConfig.MENU_CONF['uploadImage'] = {
        async customUpload(file, insertFn) {
          uploadImage(file).then((res) => {
            const { imageUrl, originalName } = res;
            insertFn(imageUrl, originalName, imageUrl);
          });
        },
      };
      // 自定义上传视频
      this.editorConfig.MENU_CONF['uploadVideo'] = {
        async customUpload(file, insertFn) {
          uploadVideo(file).then((res) => {
            const { imageUrl, originalName, imageThumbnailUrl } = res;
            insertFn(imageUrl, imageUrl);
          });
        },
      };

      if (!this.id) {
        this.toolbarConfig.insertKeys.keys.push('word');
      } else {
        var keys = this.toolbarConfig.insertKeys.keys;
        var index = keys.findIndex((el) => el === 'word');
        if (index !== -1) {
          keys.splice(index, 1);
        }
      }
    },
    /**
     * editor创建回调函数
     */
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
      var keys = this.editor.getAllMenuKeys();
      // console.log(keys);
      // 注册导入word菜单
      if (!keys.includes('word')) {
        class importWordButton {
          constructor() {
            this.title = '导入word'; // 自定义菜单标题
            // this.iconSvg = '<svg>...</svg>' // 可选
            this.tag = 'button';
          }

          // 获取菜单执行时的 value ，用不到则返回空 字符串或 false
          getValue(editor) {
            return '';
          }

          // 菜单是否需要激活（如选中加粗文本，“加粗”菜单会激活），用不到则返回 false
          isActive(editor) {
            return false;
          }

          // 菜单是否需要禁用（如选中 H1 ，“引用”菜单被禁用），用不到则返回 false
          isDisabled(editor) {
            // JS 语法
            return false;
          }

          // 点击菜单时触发的函数
          exec(editor, value) {
            if (this.isDisabled(editor)) return;
            bus.$emit('importWordEvent');
          }
        }

        const menu1Conf = {
          key: 'word', // 定义 menu key ：要保证唯一、不重复（重要）
          factory() {
            return new importWordButton();
          },
        };

        Boot.registerMenu(menu1Conf);
      }

      // 注册旋转图片菜单
      if (!keys.includes('rotateImage')) {
        var that = this;
        class rotateImage {
          constructor() {
            this.title = '旋转图片'; // 自定义菜单标题
            this.iconSvg =
              '<svg t="1685438151271" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2849" width="14" height="14"><path d="M934.101333 416.426667h-220.373333c-26.752 0-47.232-20.48-47.232-47.232s20.48-47.232 47.232-47.232h173.141333V148.821333c0-26.752 20.48-47.232 47.232-47.232S981.333333 122.069333 981.333333 148.821333v220.373334c0 25.173333-20.48 47.232-47.232 47.232z" fill="#595959" p-id="2850"></path><path d="M509.098667 978.389333C249.344 978.389333 38.4 767.530667 38.4 507.733333 38.4 247.978667 249.344 37.034667 509.098667 37.034667A472.32 472.32 0 0 1 943.530667 325.12c9.472 23.637333-1.536 51.968-25.173334 61.44-23.594667 9.386667-51.925333-1.621333-61.397333-25.216a377.898667 377.898667 0 0 0-347.861333-229.845333A376.234667 376.234667 0 0 0 132.864 507.733333a376.234667 376.234667 0 0 0 376.234667 376.234667c190.464 0 351.018667-141.653333 373.077333-329.002667 3.114667-25.173333 26.752-44.074667 51.925333-40.96 25.173333 3.157333 44.074667 26.794667 40.96 51.968-28.373333 234.538667-228.266667 412.416-465.962666 412.416z" fill="#595959" p-id="2851"></path></svg>';
            this.tag = 'button';
          }

          // 获取菜单执行时的 value ，用不到则返回空 字符串或 false
          getValue(editor) {
            return '';
          }

          // 菜单是否需要激活（如选中加粗文本，“加粗”菜单会激活），用不到则返回 false
          isActive(editor) {
            return false;
          }

          // 菜单是否需要禁用（如选中 H1 ，“引用”菜单被禁用），用不到则返回 false
          isDisabled(editor) {
            return false;
          }

          // 点击菜单时触发的函数
          async exec(editor, value) {
            if (this.isDisabled(editor)) return;

            // 旋转图片
            function rotateImg(imageUrl) {
              return new Promise((resolve, reject) => {
                // 判断图片是否是阿里oss图片
                if (imageUrl.startsWith('https://grow')) {
                  imageUrl = imageUrl.split('/').at(-1);
                  imageUrl = '/osspath/' + imageUrl;
                }
                // else {
                //   reject(new Error('图片不是阿里oss图片'));
                //   that.$message.error('图片不是系统内部图片');
                //   return;
                // }

                // 创建一个 Image 对象
                const image = new Image();
                image.crossOrigin = '*';

                // 设置图片加载完成后的回调函数
                image.onload = function () {
                  // 创建一个 Canvas 元素
                  const canvas = document.createElement('canvas');
                  const context = canvas.getContext('2d');

                  // 设置 Canvas 的宽高和图片的宽高一致
                  canvas.width = image.height;
                  canvas.height = image.width;

                  // 旋转并绘制图片
                  context.rotate(Math.PI / 2);
                  context.drawImage(image, 0, -canvas.width);
                  let base64 = canvas.toDataURL('image/png', 1);
                  resolve(base64);
                };

                image.onerror = function () {
                  console.log('图片加载失败');
                  that.$message.error('图片加载失败');
                  reject(new Error('图片加载失败'));
                };

                // 加载图片
                image.src = imageUrl;
              });
            }

            if (this.isDisabled(editor)) return;

            const imageNode = DomEditor.getSelectedNodeByType(editor, 'image');
            if (imageNode == null) return;

            // console.log('imageNode:', imageNode);

            // 隐藏 hoverbar
            const hoverbar = DomEditor.getHoverbar(editor);
            if (hoverbar) hoverbar.hideAndClean();

            // 获取图片原src
            const { src, style = {} } = imageNode;

            var base64;
            // 将图片转换为base64
            try {
              base64 = await rotateImg(src);
            } catch (error) {
              console.log(error);
            }

            // console.log('base64:', base64);

            // 转换成File
            const file = base64ToFile(base64);
            // 上传图片
            let res = await uploadImage(file, 1, { noShowWaiting: true });

            // 更新图片的src属性
            const newSrc = res.imageUrl;

            // 修改图片
            const nodeProps = {
              src: newSrc,
              alt: newSrc,
              href: newSrc,
              style: {
                height: '',
                width: '',
              },
            };
            SlateTransforms.setNodes(editor, nodeProps, {
              match: (n) => DomEditor.checkNodeType(n, 'image'),
            });
          }
        }

        const menu1Conf = {
          key: 'rotateImage', // 定义 menu key ：要保证唯一、不重复（重要）
          factory() {
            return new rotateImage();
          },
        };
        Boot.registerMenu(menu1Conf);

        // 获取 editorConfig 对象
        var config = editor.getConfig();

        // 获取 hoverbarKeys 对象
        const hoverbarKeys = config.hoverbarKeys;

        // 在图片菜单项的 menuKeys 数组中添加自定义操作的键名
        hoverbarKeys.image.menuKeys.push('rotateImage');

        // 在 editorConfig 中应用修改后的 hoverbarKeys 对象
        config.hoverbarKeys = hoverbarKeys;
      }
    },
    /**
     * 导入word
     */
    async handleImportWord() {
      if (!this.editor.isEmpty()) {
        var res = await this.$confirm(
          '导入内容将覆盖下方文本编辑框的内容，是否导入？',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        );
        if (res !== 'confirm') {
          return;
        }
      }
      chooseFile('.docx').then((file) => {
        console.log(file);
        this.showLoading = true;
        this.word2html(file[0])
          .then((res) => {
            this.html = res;
            // this.editor.setHtml(res);
            console.log('转换成功', this.html);
          })
          .finally(() => {
            this.showLoading = false;
          });
      });
    },
    /**
     * word转html
     */
    async word2html(file) {
      var arrayBuffer = await this.file2ArrayBuffer(file);

      return new Promise((resolve, reject) => {
        var options = {
          // styleMap: [
          //   "p[style-name='Heading 1'] => p",
          //   "p[style-name='Heading 2'] => p",
          //   "p[style-name='Heading 3'] => p",
          //   "p[style-name='Heading 4'] => p",
          //   "p[style-name='Heading 5'] => p",
          //   "p[style-name='Heading 6'] => p",
          //   "p[style-name='Heading 7'] => p",
          //   "p[style-name='Heading 8'] => p",
          //   "p[style-name='Heading 9'] => p",
          // ],
          includeDefaultStyleMap: false,
          convertImage: mammoth.images.imgElement((image) => {
            return image.read('base64').then(async (imageBuffer) => {
              let base64 =
                'data:' + image.contentType + ';base64,' + imageBuffer;

              let file = base64ToFile(base64);
              let res = await uploadImage(file, 1, { noShowWaiting: true });
              return {
                src: res.imageUrl,
              };
            });
          }),
        };
        // Convert docx to HTML using Mammoth.js
        mammoth
          .convertToHtml({ arrayBuffer: arrayBuffer }, options)
          .then((result) => {
            console.log('转换完成:', result);
            var html = result.value;
            // 去除开头标题投稿人信息
            const regex = /(<[^/>]+>)*一、车辆基本信息/;
            const match = regex.exec(html);
            if (match) {
              const position = match.index;
              html = html.substring(position);
            }

            resolve(html);
          })
          .catch((e) => {
            console.log('转换失败:', e);
            reject(e);
          })
          .done();
      });
    },
    file2ArrayBuffer(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          resolve(data);
        };
        reader.onerror = (e) => {
          reject(e);
        };
      });
    },
    /**
     * 获取数据
     */
    getData() {
      getMaterialInfo(this.id).then((res) => {
        var {
          // 指南id
          guideId,
          // 状态
          state,
          // 标题
          title,
          // 投稿人Id
          creatorId,
          // 投稿人名字
          creatorName,
          // 投稿门店Id
          companyId,
          // 投稿门店名称
          companyName,
          // 审稿人名字
          verifierName,
          // 审稿门店
          verifierCompany,
          // 投稿时间
          dateCreated,
          // html
          html,

          // 所属系统id
          guideSysId,
          // 车型
          carSeriesName,
          modelDetailLogoId,
          modelDetailBrandId,
          modelDetailSeriesId,
        } = res;

        this.id = guideId;
        this.state = state;
        this.title = title;
        this.creatorId = creatorId;
        this.creatorName = creatorName;
        this.companyId = companyId;
        this.companyName = companyName;
        this.verifierName = verifierName;
        this.verifierCompany = verifierCompany;
        this.dateCreated = dateCreated;
        this.html = html;

        this.guideSysId = guideSysId;
        // 车型
        this.carModel = carSeriesName;
        this.modelDetailLogoId = modelDetailLogoId;
        this.modelDetailBrandId = modelDetailBrandId;
        this.modelDetailSeriesId = modelDetailSeriesId;
      });
    },
    /**
     * 选择供稿人
     */
    handleSelectEmployee(e) {
      this.companyId = e.companyId;
      this.companyName = e.companyName;
      this.creatorId = e.userId;
      this.creatorName = e.realName;
    },
    /**
     * 清除供稿人
     */
    handleClearEmployee() {
      this.companyId = '';
      this.companyName = '';
      this.creatorId = '';
      this.creatorName = '';
    },
    /**
     * 保存前校验
     */
    beforeSave() {
      return new Promise((resolve, reject) => {
        // 校验必填
        if (!this.title) {
          this.$message.warning('请输入标题');
          reject('请输入标题');
          return;
        }

        resolve();
      });
    },
    /**
     * 整合参数
     */
    mergeParams() {
      // const regex =
      //   /(<img[^>]*src="([^"]*)"[^>]*>)|(<video[^>]*src="([^"]*)"[^>]*>)/gi;

      // let match;
      // var srcList = [];
      // while ((match = regex.exec(this.html)) !== null) {
      //   console.log(match);
      //   const src = match[2] || match[4];
      //   srcList.push(src);
      // }

      const regex =
        /<img[^>]*src="([^"]*)"[^>]*>|<video[^>]*>\s*<source[^>]*src="([^"]*)"[^>]*>\s*<\/video>/gi;

      const srcList = Array.from(
        this.html.matchAll(regex),
        (m) => m[1] || m[2]
      );

      // 设置视频标签中的controlList
      this.html = this.html.replace(
        /controls="true"/gi,
        'controls="controls" controlsList="nodownload"'
      );

      let params = {
        title: this.title,
        html: this.html,
        picVideo: srcList.join(','),
        companyId: this.companyId,
        creatorId: this.creatorId,
      };
      if (this.id) {
        params.guideId = this.id;
      }
      return params;
    },
    /**
     * 保存
     */
    async save(type) {
      await this.beforeSave();
      let params = this.mergeParams();
      switch (type) {
        // 提交上架
        case 1:
          this.submitDialogVisible = true;
          break;

        // 提交审核
        case 2:
          this.$confirm('确定提交审核？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            // type: 'warning',
          }).then(() => {
            // 待审核状态
            params.state = 1;
            addAndEditMaterial(params).then((res) => {
              this.$message.success('提交审核成功');
              this.backListPage();
            });
          });
          break;

        // 保存草稿
        case 3:
          // 草稿状态
          params.state = 0;
          addAndEditMaterial(params).then((res) => {
            this.$message.success('保存草稿成功');
            this.backListPage();
          });
          break;
      }
    },
    /**
     * 提交
     */
    async submit(isSubmit) {
      if (!this.carModel) {
        this.$message.warning('请选择车型');
        return;
      }
      if (!this.guideSysId) {
        this.$message.warning('请选择所属系统');
        return;
      }

      await this.$confirm('确定操作？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      });

      let params = this.mergeParams();
      // 状态
      params.state = isSubmit ? 3 : 4;
      // 车型
      params.modelDetailLogoId = this.modelDetailLogoId;
      params.modelDetailBrandId = this.modelDetailBrandId;
      params.modelDetailSeriesId = this.modelDetailSeriesId;
      // 所属系统
      params.guideSysId = this.guideSysId;

      addAndEditMaterial(params).then((res) => {
        this.$message.success('操作成功');
        this.backListPage();
      });
    },

    /**
     * 使用模板
     */
    handleUseTemplate() {
      if (this.isUseTemplate) {
        this.$confirm('当前你已添加了模板，是否重复添加？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.html = this.templateContent + this.html;
          })
          .catch(() => {});
      } else {
        this.html = this.templateContent + this.html;
      }
    },
    // 远程搜索车型信息列表
    searchModel(key = '') {
      this.searchModelLoading = true;
      newSearchCarModel(key)
        .then((res) => {
          this.models = res?.records || [];
        })
        .finally(() => {
          this.searchModelLoading = false;
        });
    },
    // 选择车型
    selectCarModel(val) {
      this.modelDetailLogoId = val?.logo?.id;
      this.modelDetailBrandId = val?.brand?.id;
      this.modelDetailSeriesId = val?.series?.id;
    },
    // 获取所属系统选项列表
    getWzGuideSystemList() {
      getWzGuideSystemList().then((res) => {
        this.systemList = res || [];
      });
    },

    /**
     * 取消
     */
    closePage() {
      this.$confirm('是否关闭页面？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.closeTag({ index: this.activeTagIndex });
        })
        .catch(() => {});
    },
    /**
     * 返回列表页
     */
    backListPage() {
      this.closeTag({ index: this.activeTagIndex });
      this.$router.push({
        name: 'materialList',
        params: {
          refresh: true,
        },
      });
    },
    // ================= methods end =====================
  },

  beforeDestroy() {
    bus.$off('importWordEvent');
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
};
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="scss" scoped>
.preview {
  width: 375px;
  height: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;

  padding: 8px;
  background: #fff;
  box-sizing: border-box;
  .material-info {
    margin-bottom: 10px;
    border-radius: 4px;
    background: #f5f7fa;
    padding: 16px;
    color: #3c3d40;
    font-size: 12px;
    font-weight: 400;
    .tag {
      width: 64px;
      height: 24px;
      margin-right: 5px;
      // padding: 4px 8px;
      // box-sizing: border-box;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid #dcdfe6;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .title {
      margin-top: 2px;
      color: #242526;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }

    p {
      margin-top: 8px;
      line-height: 17px;
    }
  }
  .preview-html-box {
    flex: 1;
    height: 100%;
    overflow-y: auto;
    img {
      max-width: 100%;
    }
  }
}
.material-box {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  padding: 16px;
  background: #fff;
  box-sizing: border-box;
  overflow: auto;
  .editor-container {
    flex: 1;
    height: calc(100% - 86px);
    border: 1px solid #dcdfe6;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    z-index: 2;
    // ::v-deep .w-e-text-container {
    ::v-deep #w-e-textarea-3 {
      min-height: 98%;
    }
  }
}
.footer-box {
  height: 56px;
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 4px 4px;
}
</style>
