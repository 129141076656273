var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { ref: "baseContainer", attrs: { paddingTop: "" } },
    [
      [
        _c(
          "div",
          { staticClass: "form-top", staticStyle: { "margin-left": "-30px" } },
          [
            _c(
              "el-form",
              {
                staticClass: "filter-form",
                attrs: {
                  model: _vm.form,
                  "label-width": "100px",
                  size: "small",
                },
              },
              [
                _c("div", { staticClass: "flex-x-between" }, [
                  _c(
                    "div",
                    { staticClass: "flex-warp" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "三包厂家" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                clearable: "",
                                remote: "",
                                placeholder: "请输入厂家名称、编码",
                                "remote-method": _vm.searchUndertake,
                                loading: _vm.searchUndertakeLoading,
                              },
                              on: {
                                change: _vm.selectUndertake,
                                clear: _vm.deleteUndertake,
                              },
                              model: {
                                value: _vm.form.undertakeNameOrNum,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "undertakeNameOrNum", $$v)
                                },
                                expression: "form.undertakeNameOrNum",
                              },
                            },
                            _vm._l(_vm.undertakeList, function (item) {
                              return _c(
                                "el-option",
                                {
                                  key: item.id,
                                  attrs: {
                                    label: item.undertakeName,
                                    value: item.undertakeName,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        float: "left",
                                        color: "#8492a6",
                                        "font-size": "13px",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.undertakeName))]
                                  ),
                                  _c(
                                    "span",
                                    { staticStyle: { float: "right" } },
                                    [_vm._v(_vm._s(item.undertakeNum))]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c("template", { slot: "label" }, [
                            _c(
                              "div",
                              { staticClass: "flex flex-x-center" },
                              [
                                _vm._v(" 适用门店 "),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "light",
                                      placement: "bottom-start",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          color: "#666666",
                                          "font-size": "13px",
                                          "line-height": "20px",
                                          "text-indent": "15px",
                                        },
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _vm._v(
                                          " 适用门店，可查当前登录门店和当前登录用户 "
                                        ),
                                        _c("br"),
                                        _vm._v("所属管辖组内其他门店 "),
                                      ]
                                    ),
                                    _c("i", {
                                      staticClass: "iconfont grow-icon_doubt",
                                      staticStyle: { color: "#9a9a9a" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "el-select",
                            {
                              staticClass: "w100",
                              attrs: {
                                filterable: "",
                                clearable: "",
                                remote: "",
                                placeholder: "搜索车店名称、编号",
                                "remote-method": _vm.searchStore,
                                loading: _vm.searchStoreLoading,
                              },
                              model: {
                                value: _vm.form.enableStoreNameOrNum,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "enableStoreNameOrNum",
                                    $$v
                                  )
                                },
                                expression: "form.enableStoreNameOrNum",
                              },
                            },
                            _vm._l(_vm.storeList, function (item) {
                              return _c(
                                "el-option",
                                {
                                  key: item.companyId,
                                  attrs: {
                                    label: item.companyName,
                                    value: item.companyId,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        float: "left",
                                        color: "#8492a6",
                                        "font-size": "13px",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.companyName))]
                                  ),
                                  _c(
                                    "span",
                                    { staticStyle: { float: "right" } },
                                    [_vm._v(_vm._s(item.companyId))]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        2
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c("template", { slot: "label" }, [
                            _c(
                              "div",
                              { staticClass: "flex flex-x-center" },
                              [
                                _vm._v(" 开票主体 "),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "light",
                                      placement: "bottom-start",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          color: "#666666",
                                          "font-size": "13px",
                                          "line-height": "20px",
                                          "text-indent": "15px",
                                        },
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _vm._v(
                                          " 开票主体，即为协议客户、保险公司和三包厂家的应收账款"
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          " 的回款管理门店(保险公司的开票主体也是定损主体)。三包厂"
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          " 家管理的开票主体，可查已成为三包厂家开票主体的全部门店 "
                                        ),
                                      ]
                                    ),
                                    _c("i", {
                                      staticClass: "iconfont grow-icon_doubt",
                                      staticStyle: { color: "#9a9a9a" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                clearable: "",
                                remote: "",
                                placeholder: "搜索开票主体名称",
                                "remote-method": _vm.searchInvoice,
                                loading: _vm.searchInvoiceLoading,
                              },
                              on: {
                                change: _vm.selectInvoice,
                                clear: _vm.deleteInvoice,
                              },
                              model: {
                                value: _vm.form.invoiceStoreNameOrNum,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "invoiceStoreNameOrNum",
                                    $$v
                                  )
                                },
                                expression: "form.invoiceStoreNameOrNum",
                              },
                            },
                            _vm._l(_vm.invoiceList, function (item) {
                              return _c("el-option", {
                                key: item.companyId,
                                attrs: {
                                  label: item.invoicingEntity,
                                  value: item.companyId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "flex", staticStyle: { height: "100%" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "btn_search",
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.getDataList },
                        },
                        [_vm._v("查询")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "btn_search",
                          attrs: { size: "small" },
                          on: { click: _vm.empty },
                        },
                        [_vm._v("置空")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ],
          1
        ),
      ],
      [
        _c(
          "div",
          { staticStyle: { display: "flex" } },
          [
            _c(
              "el-form",
              {
                staticStyle: { "margin-top": "20px", "margin-left": "-96px" },
                attrs: {
                  model: _vm.form,
                  "label-width": "100px",
                  size: "small",
                },
              },
              [
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "120px" },
                        attrs: { placeholder: "批量操作" },
                        on: { change: _vm.changeType },
                        model: {
                          value: _vm.form.menberType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "menberType", $$v)
                          },
                          expression: "form.menberType",
                        },
                      },
                      [
                        _vm.nums != 0
                          ? _c("el-option", {
                              directives: [
                                {
                                  name: "auth",
                                  rawName: "v-auth",
                                  value: 491,
                                  expression: "491",
                                },
                              ],
                              attrs: { label: "启用", value: 0 },
                            })
                          : _vm._e(),
                        _vm.nums != 1
                          ? _c("el-option", {
                              directives: [
                                {
                                  name: "auth",
                                  rawName: "v-auth",
                                  value: 491,
                                  expression: "491",
                                },
                              ],
                              attrs: { label: "禁用", value: 1 },
                            })
                          : _vm._e(),
                        _c("el-option", {
                          directives: [
                            {
                              name: "auth",
                              rawName: "v-auth",
                              value: 489,
                              expression: "489",
                            },
                          ],
                          attrs: { label: "删除", value: 2 },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 487,
                    expression: "487",
                  },
                ],
                staticClass: "btn_add",
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.addManu },
              },
              [_vm._v("新增三包厂家")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "btns flex-x-between" },
          [
            _c(
              "ul",
              { staticClass: "chioce_type" },
              _vm._l(_vm.chang_list, function (item, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    class: { active: _vm.nums == index },
                    on: {
                      click: function ($event) {
                        return _vm.change_type(index)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(item.name) + " ")]
                )
              }),
              0
            ),
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 492,
                    expression: "492",
                  },
                ],
                staticClass: "blue",
                attrs: { size: "small", type: "text" },
                on: {
                  click: function ($event) {
                    _vm.showExportDialog = true
                  },
                },
              },
              [
                _vm._v("导出列表"),
                _c("i", {
                  staticClass: "iconfont grow-icon_daochu el-icon--right",
                }),
              ]
            ),
          ],
          1
        ),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "fixedScrollBar",
                rawName: "v-fixedScrollBar",
                value: _vm.$refs.baseContainer,
                expression: "$refs.baseContainer",
              },
            ],
            ref: "multipleTable",
            staticClass: "custom-table",
            attrs: {
              data: _vm.tableData,
              border: "",
              "tooltip-effect": "dark",
              "span-method": _vm.spanMethod,
            },
            on: { "selection-change": _vm.handleSelectionChange },
          },
          [
            _c("el-table-column", {
              attrs: { type: "selection", width: "60" },
            }),
            _c("el-table-column", {
              attrs: { label: "序号", width: "60", prop: "index" },
            }),
            _c("el-table-column", {
              attrs: { prop: "operation", label: "操作", width: "70" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "auth",
                              rawName: "v-auth",
                              value: 488,
                              expression: "488",
                            },
                          ],
                          staticClass: "text_operation orange",
                          on: {
                            click: function ($event) {
                              return _vm.getUpdate(scope.$index, scope.row)
                            },
                          },
                        },
                        [_vm._v("编辑")]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "undertakeName",
                label: "三包厂家名称",
                "min-width": "120",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "undertakeNum",
                label: "厂家编码",
                "min-width": "150",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "hasAppliedStore",
                label: "适用门店",
                "min-width": "180",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.hasAppliedStore == false
                        ? _c("div", [_vm._v("全部门店")])
                        : _c(
                            "div",
                            [
                              _c(
                                "el-popover",
                                {
                                  ref: "popover-" + scope.row.id,
                                  attrs: {
                                    placement: "top",
                                    trigger: "click",
                                    width: "400",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "header_title" }, [
                                    _c("span", [
                                      _vm._v(
                                        "部分门店(" +
                                          _vm._s(scope.row.storeCount) +
                                          "),"
                                      ),
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          scope.row.selfContained == 0
                                            ? "不包含本店"
                                            : "包含本店"
                                        )
                                      ),
                                    ]),
                                    _c("i", {
                                      staticClass:
                                        "iconfont grow-icon_close_24",
                                      staticStyle: { color: "#666666" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.pClose(scope.row.id)
                                        },
                                      },
                                    }),
                                  ]),
                                  _c(
                                    "el-table",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        data: _vm.applyStoresTable,
                                        border: "",
                                      },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          type: "index",
                                          label: "序号",
                                          width: "60",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "companyId",
                                          label: "车店编号",
                                          width: "130",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "companyName",
                                          label: "车店名称",
                                          width: "120",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "chainCompanyType",
                                          label: "连锁类型",
                                          width: "90",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("dict")(
                                                          scope.row
                                                            .chainCompanyType,
                                                          "chainCompanyType"
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "p",
                                    {
                                      class: scope.row.hasAppliedStore
                                        ? "pointer blue"
                                        : "",
                                      attrs: { slot: "reference" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.goStores(scope.row.id)
                                        },
                                      },
                                      slot: "reference",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            "部分门店（" +
                                              scope.row.storeCount +
                                              "）"
                                          ) +
                                          ", "
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            scope.row.selfContained == 0
                                              ? "不包含本店"
                                              : "包含本店"
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                    ]
                  },
                },
              ]),
            }),
            _c(
              "el-table-column",
              {
                attrs: { prop: "invoiceCompanyName", "min-width": "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [_vm._v(" " + _vm._s(row.invoices) + " ")]
                    },
                  },
                ]),
              },
              [
                _c("template", { slot: "header" }, [
                  _c(
                    "div",
                    { staticClass: "flex flex-x-center" },
                    [
                      _vm._v(" 开票主体 "),
                      _c(
                        "el-tooltip",
                        {
                          attrs: { effect: "light", placement: "bottom-start" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                color: "#666666",
                                "font-size": "13px",
                                "line-height": "20px",
                                "text-indent": "15px",
                              },
                              attrs: { slot: "content" },
                              slot: "content",
                            },
                            [
                              _vm._v(
                                " 开票主体，即为协议客户、保险公司和三包厂家的应收账款"
                              ),
                              _c("br"),
                              _vm._v(
                                " 的回款管理门店(保险公司的开票主体也是定损主体)。 "
                              ),
                            ]
                          ),
                          _c("i", {
                            staticClass: "iconfont grow-icon_doubt",
                            staticStyle: { "margin-left": "5px" },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
            _c("el-table-column", {
              attrs: {
                prop: "undertakeContact",
                label: "联系人",
                "min-width": "120",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "undertakePhone",
                label: "联系电话",
                "min-width": "140",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "undertakeAlternatePhone",
                label: "备用电话",
                "min-width": "140",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "undertakeFax",
                label: "传真",
                "min-width": "100",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "undertakeAddress",
                label: "联系地址",
                "min-width": "120",
                "show-overflow-tooltip": "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", { staticClass: "pointer text_operation" }, [
                        _vm._v(_vm._s(scope.row.undertakeAddress)),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "undertakeRemark",
                label: "备注",
                "min-width": "120",
                "show-overflow-tooltip": "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", { staticClass: "pointer text_operation" }, [
                        _vm._v(_vm._s(scope.row.undertakeRemark)),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm.dialogTableVisible
          ? _c("add-manufacturer", {
              attrs: {
                dialogTableVisible: _vm.dialogTableVisible,
                id: _vm.editCarId,
              },
              on: {
                "update:dialogTableVisible": function ($event) {
                  _vm.dialogTableVisible = $event
                },
                "update:dialog-table-visible": function ($event) {
                  _vm.dialogTableVisible = $event
                },
                update: _vm.getDataList,
              },
            })
          : _vm._e(),
        _c("export-dialog", {
          attrs: {
            visibility: _vm.showExportDialog,
            total: _vm.total,
            pageOption: _vm.pageOption,
            filterForm: _vm.form,
            url: _vm.exportUrl,
          },
          on: {
            "update:visibility": function ($event) {
              _vm.showExportDialog = $event
            },
          },
        }),
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c("pagination", {
            attrs: { total: _vm.total },
            on: { pageChange: _vm.changePage },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }