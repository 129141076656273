var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "printDom", staticClass: "print-template" }, [
    _c("div", { ref: "print" }, [
      _c("div", { staticClass: "print-template__header" }, [
        _c("h1", { staticClass: "print-template__header__title" }, [
          _vm._v(" " + _vm._s(_vm.billMessage.operateCompanyName) + " "),
        ]),
        _c(
          "p",
          {
            staticStyle: {
              "margin-top": "5px",
              "font-size": "18px",
              "text-align": "center",
            },
          },
          [_vm._v(" " + _vm._s(_vm.config.title) + " ")]
        ),
        _vm.config.leftImagePath
          ? _c("img", {
              staticClass: "print-template__header__lt-img",
              attrs: { src: _vm.config.leftImagePath },
            })
          : _vm._e(),
        _vm.config.rightImagePath
          ? _c("img", {
              staticClass: "print-template__header__rt-img",
              attrs: { src: _vm.config.rightImagePath },
            })
          : _vm._e(),
      ]),
      _c(
        "div",
        {
          staticClass: "print-template__date",
          staticStyle: { "font-size": "12px" },
        },
        [_vm._v(" 打印时间：" + _vm._s(_vm.printTime) + " ")]
      ),
      _c("div", { staticClass: "print-template__user" }, [
        _c("h3", { ref: "clientName" }, [
          _vm._v("客户：" + _vm._s(_vm.carInfo.clientName)),
        ]),
        _c("h3", [_vm._v("接车单号：" + _vm._s(_vm.billMessage.billCode))]),
      ]),
      _c("div", { staticClass: "print-template__box__border" }, [
        _c("table", { staticClass: "print-template__box__border__table" }, [
          _c("tbody", [
            _c("tr", [
              _c(
                "td",
                { staticStyle: { width: "85px", "text-align": "left" } },
                [_vm._v("送修人")]
              ),
              _c(
                "td",
                { staticStyle: { width: "165px", "text-align": "left" } },
                [_vm._v(" " + _vm._s(_vm.billMessage.customerName) + " ")]
              ),
              _c(
                "td",
                { staticStyle: { width: "100px", "text-align": "left" } },
                [_vm._v("送修人电话")]
              ),
              _c(
                "td",
                { staticStyle: { width: "179px", "text-align": "left" } },
                [_vm._v(" " + _vm._s(_vm.billMessage.customerPhone) + " ")]
              ),
              _c(
                "td",
                { staticStyle: { width: "85px", "text-align": "left" } },
                [_vm._v("车牌号")]
              ),
              _c(
                "td",
                { staticStyle: { width: "162px", "text-align": "left" } },
                [_vm._v(" " + _vm._s(_vm.carInfo.carNumber) + " ")]
              ),
            ]),
            _c("tr", [
              _c(
                "td",
                { staticStyle: { width: "85px", "text-align": "left" } },
                [_vm._v("车型")]
              ),
              _c(
                "td",
                { staticStyle: { width: "165px", "text-align": "left" } },
                [_vm._v(" " + _vm._s(_vm.carInfo.brandName) + " ")]
              ),
              _c(
                "td",
                { staticStyle: { width: "90px", "text-align": "left" } },
                [_vm._v("车架号")]
              ),
              _c(
                "td",
                { staticStyle: { width: "179px", "text-align": "left" } },
                [_vm._v(" " + _vm._s(_vm.carInfo.uniqueId) + " ")]
              ),
              _c(
                "td",
                { staticStyle: { width: "85px", "text-align": "left" } },
                [_vm._v("施工类型")]
              ),
              _c(
                "td",
                { staticStyle: { width: "162px", "text-align": "left" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("dict")(_vm.billMessage.difficulty, "difficulty")
                      ) +
                      " "
                  ),
                ]
              ),
            ]),
            _c("tr", [
              _c(
                "td",
                { staticStyle: { width: "85px", "text-align": "left" } },
                [_vm._v("接车员")]
              ),
              _c(
                "td",
                { staticStyle: { width: "165px", "text-align": "left" } },
                [_vm._v(" " + _vm._s(_vm.billMessage.pickUpUserName) + " ")]
              ),
              _c(
                "td",
                { staticStyle: { width: "90px", "text-align": "left" } },
                [_vm._v("进店时间")]
              ),
              _c(
                "td",
                { staticStyle: { width: "179px", "text-align": "left" } },
                [_vm._v(" " + _vm._s(_vm.billMessage.dateCreated) + " ")]
              ),
              _c(
                "td",
                { staticStyle: { width: "85px", "text-align": "left" } },
                [_vm._v("进店里程")]
              ),
              _c(
                "td",
                { staticStyle: { width: "162px", "text-align": "left" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.billMessage.mileage
                          ? _vm.billMessage.mileage + "km"
                          : ""
                      ) +
                      " "
                  ),
                ]
              ),
            ]),
            _c("tr", [
              _c(
                "td",
                { staticStyle: { width: "85px", "text-align": "left" } },
                [_vm._v("电量")]
              ),
              _c(
                "td",
                { staticStyle: { width: "165px", "text-align": "left" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("dict")(
                          _vm.billMessage.oilMeter,
                          "energyPercentage"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "td",
                { staticStyle: { width: "90px", "text-align": "left" } },
                [_vm._v("预交车时间")]
              ),
              _c(
                "td",
                {
                  staticStyle: { width: "179px", "text-align": "left" },
                  attrs: { colspan: "3" },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.billMessage.undetermined
                          ? "待定"
                          : _vm.billMessage.planFinishTime
                      ) +
                      " "
                  ),
                ]
              ),
            ]),
            _c("tr", [
              _c(
                "td",
                { staticStyle: { width: "85px", "text-align": "left" } },
                [_vm._v("车单标签")]
              ),
              _c("td", { attrs: { colspan: "5" } }, [
                _vm._v(" " + _vm._s(_vm.label) + " "),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "print-template__remark",
          staticStyle: {
            "margin-top": "30px",
            padding: "5px",
            "min-height": "260px",
            border: "1px solid #000",
          },
        },
        [
          _c(
            "h3",
            {
              staticStyle: {
                "margin-bottom": "10px",
                "font-weight": "600",
                "font-size": "16px",
              },
            },
            [_vm._v(" 初诊与备注： ")]
          ),
          _c(
            "p",
            { staticStyle: { "text-indent": "2em", "line-height": "1.5" } },
            [_vm._v(" " + _vm._s(_vm.billMessage.firstVisitRemark) + " ")]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "print-template__remark",
          staticStyle: {
            "min-height": "260px",
            padding: "5px",
            border: "1px solid #000",
            "border-top": "none",
          },
        },
        [
          _c(
            "h3",
            {
              staticStyle: {
                "margin-bottom": "10px",
                "font-weight": "600",
                "font-size": "16px",
              },
            },
            [_vm._v(" 车主要求： ")]
          ),
          _c(
            "p",
            { staticStyle: { "text-indent": "2em", "line-height": "1.5" } },
            [_vm._v(" " + _vm._s(_vm.billMessage.remark) + " ")]
          ),
        ]
      ),
      _vm._m(0),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "align-item": "center",
            "margin-top": "40px",
          },
        },
        [
          _c(
            "h3",
            {
              staticStyle: {
                display: "flex",
                "align-items": "center",
                "margin-right": "150px",
                "font-weight": "600",
                "font-size": "16px",
              },
            },
            [
              _vm._v(" 客户签名： "),
              _c("img", {
                staticStyle: { width: "200px", "max-height": "96px" },
                attrs: { src: _vm.billMessage.electronicSignaturePic, alt: "" },
              }),
            ]
          ),
          _c(
            "h3",
            {
              staticStyle: {
                display: "flex",
                "align-items": "center",
                "font-weight": "600",
                "font-size": "16px",
              },
            },
            [_vm._v(" 日期： ")]
          ),
        ]
      ),
      _vm._m(1),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticStyle: {
          "margin-top": "30px",
          "font-size": "14px",
          "font-weight": "600",
        },
      },
      [
        _c("p", [_vm._v("1.本人同意贵司检查以上项目。")]),
        _c("p", { staticStyle: { "margin-top": "15px" } }, [
          _vm._v(" 2.维修完成后，客户凭此单取车，请妥善保管。 "),
        ]),
        _c("p", { staticStyle: { "margin-top": "15px" } }, [
          _vm._v(" 3.下车请带走贵重物品，如有丢失，本店概不负责。 "),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticStyle: { display: "flex", "margin-top": "40px" } },
      [
        _c(
          "h3",
          {
            staticStyle: {
              "margin-right": "317px",
              "font-weight": "600",
              "font-size": "16px",
            },
          },
          [_vm._v(" 服务顾问签名： ")]
        ),
        _c(
          "h3",
          { staticStyle: { "font-weight": "600", "font-size": "16px" } },
          [_vm._v("日期：")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }