<template>
  <el-cascader
    ref="cascader"
    v-model="category"
    :options="categoryTree"
    @change="handleChange"
    clearable
    :size="size"
    show-all-levels
    :props="{
      value: 'categoryId',
      label: 'name',
      children: 'subCategories',
      checkStrictly: checkStrictly,
    }"
    filterable
    placeholder="搜索商品分类"
    @blur="handleBlur"
  ></el-cascader>
</template>

<script>
import { mapState } from 'vuex';
import { getGoodsClasses } from '@/api/carBill';
import { getShareGoodsClasses } from '@/api/stock/goodsSetting';
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      default: 'small',
    },
    checkStrictly: {
      type: Boolean,
      default: false,
    },
    /**
     * 共享接口
     */
    share: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    // value: {
    //   handler: function (val) {
    //     console.log('value:', val);
    //   },
    //   immediate: true,
    // },
  },
  data() {
    return {
      category: [],
      categoryTree: [],
    };
  },
  computed: {},
  created() {
    this.getData();
    this.category = this.value || [];
  },
  methods: {
    getData() {
      if (this.share) {
        getShareGoodsClasses().then((res) => {
          this.categoryTree = res;
        });
      } else {
        getGoodsClasses(this.$store.state.user.companyId).then((res) => {
          this.categoryTree = res;
        });
      }
    },
    handleChange(value) {
      console.log(value);
      this.$emit('select', value);
    },
    handleBlur() {
      this.$emit('blur');
    },
    clear() {
      this.category = [];
    },
  },
};
</script>
<style lang="scss" scoped>
.el-cascader {
  width: 100% !important;
}
</style>
