<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <el-form
        class="filter-form"
        :model="form"
        label-width="100px"
        size="small"
      >
        <div class="flex-x-between">
          <div class="flex flex-shrink0">
            <el-form-item>
              <template slot="label">
                <div>
                  操作门店
                  <el-tooltip
                    effect="light"
                    content="接车单的操作门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)"
                    placement="bottom-start"
                  >
                    <i
                      class="iconfont grow-icon_doubt"
                      style="color: #9a9a9a"
                    ></i>
                  </el-tooltip>
                </div>
              </template>
              <el-select
                :key="selectKey"
                size="small"
                v-model="form.operateCompanyId"
                @change="selectStore"
                @clear="deleteStore"
                filterable
                clearable
                remote
                placeholder="搜索车店名称、编号"
                :remote-method="searchStore"
                :loading="searchStoreLoading"
              >
                <el-option
                  v-for="item in storeList"
                  :key="item.companyId"
                  :label="item.companyName"
                  :value="item.companyId"
                >
                  <span style="float: left">{{ item.companyName }}</span>
                  <span style="float: right">{{ item.companyId }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="消费客户">
              <el-input
                v-model="form.consumerMsg"
                placeholder="请输入客户名称"
                clearable
                @keyup.enter.native="doSearch"
              ></el-input>
            </el-form-item>
            <el-form-item label="车单结账日期">
              <el-date-picker
                class="w100"
                v-model="form.checkStartTimes"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="截止时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </div>
          <div class="el-form-item--small">
            <div class="flex">
              <el-button
                class="btn_search"
                type="primary"
                size="small"
                @click="doSearch"
                >查询</el-button
              >
              <el-button class="btn_search" size="small" @click="empty"
                >置空</el-button
              >
            </div>
          </div>
        </div>
        <div class="flex-warp">
          <template>
            <el-form-item label="签约客户">
              <el-select
                v-model="form.clientId"
                @change="selectClient"
                filterable
                clearable
                remote
                placeholder="请输入客户名称"
                :remote-method="searchClient"
                :loading="searchClientLoading"
              >
                <el-option
                  v-for="item in clientList"
                  :key="item.id"
                  :label="item.clientName"
                  :value="item.id"
                >
                  <span style="float: left; color: #8492a6; font-size: 13px">{{
                    item.clientName
                  }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="业务来源">
              <el-select
                v-model="form.billSourceTypeId"
                @change="selectBillSource"
                @clear="deleteBillSource"
                filterable
                clearable
                remote
                placeholder="搜索业务来源"
                :remote-method="searchBillSource"
                :loading="searchBillSourceLoading"
              >
                <el-option
                  v-for="item in billSourceList"
                  :key="item.billSourceTypeId"
                  :label="item.name"
                  :value="item.billSourceTypeId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="业务类型">
              <el-select
                class="w100"
                v-model="form.billType"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in businessType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="接车进店日期">
              <el-date-picker
                v-model="form.intoStoreTime"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="截止时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="接车单号">
              <el-input
                v-model="form.billCode"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="单据状态">
              <el-select v-model="form.billState">
                <el-option label="全部" :value="''"> </el-option>
                <el-option label="服务中" :value="0"> </el-option>
                <el-option label="挂单中" :value="4"> </el-option>
                <el-option label="待结账" :value="2"> </el-option>
                <el-option label="已结账" :value="1"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="单据金额">
              <el-select v-model="form.paid">
                <el-option label="全部" :value="2"> </el-option>
                <el-option label="大于0" :value="1"> </el-option>
                <el-option label="等于0" :value="0"> </el-option>
              </el-select>
            </el-form-item>
          </template>
        </div>
      </el-form>
    </template>
    <template>
      <div class="tip_word red" v-if="showQueryResult">
        <p>已找到{{ queryTotal }}条数据</p>
        <!-- <p>注：仅能查找打印已结账单据</p> -->
      </div>
      <div class="seal color333">
        <div style="width: 33%">
          <div v-auth="506">
            <p style="margin-bottom: 8px">盖章设置</p>
            业务章：
            <el-radio-group
              v-model="businessSeal"
              @change="handleChangeBusinessSeal"
            >
              <!-- <el-radio
                :label="0"
                @click.native.prevent="handleChangeitem(0)"
                style="margin: 10px"
                >不盖章</el-radio
              > -->
              <el-radio label="0" style="margin: 10px">不盖章</el-radio>
              <el-radio label="1" style="margin: 10px">按单据开票主体</el-radio>
              <el-radio label="2" style="margin: 0 10px">
                固定章
                <el-select
                  size="small"
                  v-model="invoiceId"
                  @change="selectInvoice"
                  @clear="deleteInvoice"
                  filterable
                  clearable
                  remote
                  placeholder="搜索开票主体"
                  :remote-method="searchInvoice"
                  :loading="searchInvoiceLoading"
                >
                  <el-option
                    v-for="item in invoiceList"
                    :key="item.companyId"
                    :label="item.invoicingEntity"
                    :value="item.companyId"
                  >
                  </el-option>
                </el-select>
              </el-radio>
            </el-radio-group>
          </div>
          <div v-if="showPrintProcess" class="flex" style="margin: 15px 0px">
            <p>操作人：{{ userName }}</p>
            <p style="margin-left: 5%">操作时间：{{ operatingTime }}</p>
          </div>
          <div class="flex red">
            <p v-if="showPrintProcess">
              总计{{ notPrintTotal }}条
              <span v-if="preparedTotal != ''"
                >已准备{{ preparedTotal }}条</span
              >
            </p>
            <p v-if="showPrintProcess">
              <span v-if="noPrint">（未完成打印）</span>
              <span v-if="inPrint">(打印中)</span>
              <span v-if="endPrint">(打印成功)</span>
            </p>
            <p
              class="blue pointer"
              style="margin-left: 10%"
              @click="continuePrint"
              v-if="notPrintNum > 0"
            >
              继续打印
            </p>
          </div>
        </div>
        <div style="width: 67%">
          <printed-page
            style="position: fixed; left: -999px"
            :carBillData="carBillData"
            :businessSeal="businessSeal"
            :invoiceName="invoiceName"
            :printPageCurr="printPageCurr"
            :printPageSize="printPageSize"
            :count="billIds.length"
          ></printed-page>

          <!-- 打印预览页面 -->
          <div ref="printDom" class="print-template">
            <div ref="print">
              <div class="print-template__header">
                <h1 class="print-template__header__title">
                  {{ company.companyName }}
                </h1>
                <p style="margin-top: 5px; font-size: 18px; text-align: center">
                  {{ config.title }}
                </p>
                <img
                  class="print-template__header__lt-img"
                  v-if="config.leftImagePath"
                  :src="config.leftImagePath"
                />
                <img
                  class="print-template__header__rt-img"
                  v-if="config.rightImagePath"
                  :src="config.rightImagePath"
                />
              </div>
              <div class="print-template__date" style="font-size: 12px">
                打印时间：{{ printTime }}
              </div>
              <div class="print-template__user">
                <h3 ref="clientName">客户：{{ carInfo.clientName }}</h3>
                <h3>接车单号：{{ billMessage.billCode }}</h3>
              </div>
              <div class="print-template__box__border">
                <table class="print-template__box__border__table">
                  <tbody>
                    <!-- 理赔单  -->
                    <tr v-if="billType === 2">
                      <td style="width: 85px; text-align: left">消费客户</td>
                      <td>
                        {{ carBillInsuranceInfo.claimsInsuranceCompanyName }}
                      </td>
                      <td style="width: 85px; text-align: left">报案号</td>
                      <td>
                        {{ carBillInsuranceInfo.caseNum }}
                      </td>
                      <td style="width: 85px; text-align: left">事故责任</td>
                      <td>
                        {{ carBillInsuranceInfo.dutyType | dict('dutyType') }}
                      </td>
                    </tr>

                    <!-- 索赔单 -->
                    <tr v-if="billType === 3">
                      <td style="width: 85px; text-align: left">消费客户</td>
                      <td>
                        {{ carBillUndertakeInfo.undertakeInsuranceCompanyName }}
                      </td>
                      <td style="width: 85px; text-align: left">索赔单号</td>
                      <td colspan="3">
                        {{ carBillUndertakeInfo.claimsNum.join('、') }}
                      </td>
                    </tr>

                    <tr>
                      <td style="width: 85px; text-align: left">送修司机</td>
                      <td style="width: 165px; text-align: left">
                        {{ billMessage.customerName }}
                      </td>
                      <td style="width: 100px; text-align: left">
                        送修司机电话
                      </td>
                      <td style="width: 179px; text-align: left">
                        {{ billMessage.customerPhone }}
                      </td>
                      <td style="width: 85px; text-align: left">车牌号</td>
                      <td style="width: 162px; text-align: left">
                        {{ carInfo.carNumber }}
                      </td>
                    </tr>

                    <tr>
                      <td style="width: 85px; text-align: left">车型</td>
                      <td style="width: 165px; text-align: left">
                        {{ carInfo.brandName }}
                      </td>
                      <td style="width: 90px; text-align: left">车架号</td>
                      <td style="width: 179px; text-align: left">
                        {{ carInfo.uniqueId }}
                      </td>
                      <td style="width: 85px; text-align: left">发动机号</td>
                      <td style="width: 162px; text-align: left">
                        {{ carInfo.engineNumber }}
                      </td>
                    </tr>

                    <tr>
                      <td style="width: 85px; text-align: left">接车员</td>
                      <td style="width: 165px; text-align: left">
                        {{ billMessage.pickUpUserName }}
                      </td>
                      <td style="width: 90px; text-align: left">进店时间</td>
                      <td style="width: 179px; text-align: left">
                        {{ billMessage.dateCreated }}
                      </td>
                      <td style="width: 85px; text-align: left">进店里程</td>
                      <td style="width: 162px; text-align: left">
                        {{
                          billMessage.mileage ? billMessage.mileage + 'km' : ''
                        }}
                      </td>
                    </tr>

                    <tr>
                      <td style="width: 85px; text-align: left">结账收银员</td>
                      <td style="width: 165px; text-align: left">
                        {{ cashier }}
                      </td>
                      <td style="width: 90px; text-align: left">结账时间</td>
                      <td style="text-align: left">
                        {{ billMessage.checkDate }}
                      </td>
                      <td style="width: 90px; text-align: left">提车时间</td>
                      <td style="text-align: left">
                        {{ billMessage.takeTime || billMessage.checkDate }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div
                class="print-template__box__border"
                v-if="serviceList && serviceList.length"
              >
                <h3 class="print-template__title">服务明细</h3>
                <table class="print-template__box__border__table">
                  <thead>
                    <tr>
                      <th style="width: 32px; text-align: center">序号</th>
                      <th style="min-width: 130px; text-align: center">
                        服务名称
                      </th>
                      <th style="width: 65px; text-align: center">工时</th>
                      <th style="width: 70px; text-align: center">单价</th>
                      <th style="width: 40px; text-align: center">折扣</th>
                      <th style="width: 75px; text-align: center">小计</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in serviceList" :key="index">
                      <td style="text-align: center">
                        {{ index + 1 }}
                      </td>
                      <td style="text-align: left">{{ item.name }}</td>
                      <td style="text-align: right">
                        {{ item.num }}
                      </td>
                      <td style="text-align: right">
                        {{ item.price | toThousands }}
                      </td>
                      <td style="text-align: right">
                        {{ item.discount ? item.discount : '无' }}
                      </td>
                      <td style="text-align: right">
                        {{ item.total | toThousands }}
                      </td>
                    </tr>
                    <tr style="text-align: right">
                      <td :colspan="6" style="padding: 4px">
                        <b
                          style="font-weight: 600"
                          class="print-template__box__border__table__total text-right"
                          >折前服务费合计：{{
                            beforeTotalServicePrice | toThousands
                          }}</b
                        >
                        <b
                          style="font-weight: 600"
                          class="print-template__box__border__table__total text-right"
                          >折后服务费合计：{{
                            afterTotalServicePrice | toThousands
                          }}</b
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div
                class="print-template__box__border"
                v-if="goodsList && goodsList.length"
              >
                <h3 class="print-template__title" ref="materialTitle">
                  材料明细
                </h3>
                <table
                  class="print-template__box__border__table"
                  ref="materialTable"
                >
                  <thead>
                    <tr ref="goodTr">
                      <th style="width: 32px; text-align: center">序号</th>
                      <th style="min-width: 176px; text-align: center">
                        商品名称
                      </th>
                      <th style="width: 75px; text-align: center">数量</th>
                      <th style="width: 70px; text-align: center">单价</th>
                      <th style="width: 40px; text-align: center">折扣</th>
                      <th style="width: 75px; text-align: center">小计</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in goodsList" :key="index">
                      <td style="text-align: center">
                        {{ index + 1 }}
                      </td>
                      <td style="text-align: left">{{ item.name }}</td>
                      <td style="text-align: right">{{ item.num }}</td>
                      <td style="text-align: right">
                        {{ item.price | toThousands }}
                      </td>
                      <td style="text-align: right">
                        {{ item.discount ? item.discount : '无' }}
                      </td>
                      <td style="text-align: right">
                        {{ item.total | toThousands }}
                      </td>
                    </tr>
                    <tr style="text-align: right">
                      <td :colspan="6" style="padding: 4px">
                        <b
                          style="font-weight: 600"
                          class="print-template__box__border__table__total"
                          >折前材料费合计：{{
                            beforeTotalGoodsPrice | toThousands
                          }}</b
                        >
                        <b
                          style="font-weight: 600"
                          class="print-template__box__border__table__total"
                          >折后材料费合计：{{
                            afterTotalGoodsPrice | toThousands
                          }}</b
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="print-template__common">
                <div
                  class="print-template__box__border print-template__common__column"
                >
                  <h3 class="print-template__title" ref="receivableTitle">
                    应收明细
                  </h3>
                  <table
                    class="print-template__box__border__table"
                    ref="receivableTable"
                  >
                    <thead>
                      <tr>
                        <th style="width: 32px; text-align: center">序号</th>
                        <th style="min-width: 180px; text-align: center">
                          名称
                        </th>
                        <th style="width: 74px; text-align: center">金额</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style="text-align: center">1</td>
                        <td style="text-align: left">工时费</td>
                        <td style="text-align: right">
                          {{ serviceTotalPrice | toThousands }}
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: center">2</td>
                        <td style="text-align: left">材料费</td>
                        <td style="text-align: right">
                          {{ goodsTotalPrice | toThousands }}
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: center">3</td>
                        <td style="text-align: left">优惠金额</td>
                        <td style="text-align: right">
                          -{{ discountAmount | toThousands }}
                        </td>
                      </tr>
                      <tr>
                        <td
                          :colspan="3"
                          style="text-align: right; font-weight: 600"
                        >
                          应收合计(大写): {{ needPay | toThousands }} ({{
                            needPay | amountToChinese
                          }})
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  class="print-template__box__border"
                  style="border-bottom: none; width: 45%"
                >
                  <h3 class="print-template__title" ref="chargeTitle">
                    收费结算
                  </h3>

                  <table
                    class="print-template__box__border__table"
                    ref="chargeTable"
                  >
                    <tbody>
                      <tr>
                        <th style="width: 32px; text-align: center">序号</th>
                        <th style="min-width: 180px; text-align: center">
                          支付方式
                        </th>
                        <th style="text-align: center; width: 74px">金额</th>
                      </tr>
                      <tr v-for="(item, index) in paidList" :key="index">
                        <td style="text-align: center">{{ index + 1 }}</td>
                        <td style="text-align: left">{{ item.payType }}</td>
                        <td style="text-align: right">
                          {{ item.money | toThousands }}
                        </td>
                      </tr>
                      <tr>
                        <td
                          :colspan="3"
                          style="text-align: right; font-weight: 600"
                        >
                          实收合计(大写): {{ paidTotal | toThousands }} ({{
                            paidTotal | amountToChinese
                          }})
                        </td>
                      </tr>
                      <tr>
                        <td
                          :colspan="3"
                          style="text-align: right; font-weight: 600"
                        >
                          欠收合计(大写): {{ needPaidTotal | toThousands }} ({{
                            needPaidTotal | amountToChinese
                          }})
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="print-template__signature" ref="signature">
                <b class="print-template__title">服务顾问签名：</b>
                <b class="print-template__title">客户签名：</b>
              </div>
              <div class="print-template__contact" style="position: relative">
                <div
                  class="print-template__contact__item fontsize"
                  style="width: 60%"
                >
                  联系地址：{{ company.address }}
                </div>
                <div class="print-template__contact__item fontsize">
                  联系电话：{{ company.telephone }}
                  <span style="margin-left: 30px">{{
                    company.alternativeTelephone
                  }}</span>
                </div>
                <div style="position: absolute; left: 30px; top: -70px">
                  <seal-demo
                    :businessSeal="businessSeal"
                    :companyName="selectInvoiceCompanyName"
                  ></seal-demo>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="flex-x-end foot-btn">
        <el-button type="primary" @click="handlePrint">打印</el-button>
      </div>
    </template>
    <!-- <div>
        <i style="color:#f90;font-size:25px;" class="el-icon-warning"></i>
        <span style="margin-left:10px;font-size:16px;line-height:30px;font-weight:600;vertical-align:top;">
          温馨提示
        </span>
        <p style="margin:0px 0 0 10px;">
          打印数量过大可能会产生系统反应迟钝请耐心等待，在打印成功前，请勿关闭本页面。
        </p>
      </div> -->
  </base-container>
</template>
<script>
// 字典数据
import { carBillType } from '@/service/dict/dictData';
//模糊搜索接口
import { searchStoreWithJurisdiction } from '@/api/customer/store';
import { getBillSourceTypeList } from '@/api/billSource';
import { searchInvoice } from '@/api/storeManagement/index';
import { searchClient } from '@/api/customer/customer';

//打印页面
import printedPage from '@/views/carBill/carBillList/batchPrint/printedPage.vue';
//印章页面
import sealDemo from '@/views/carBill/carBillList/batchPrint/sealDemo.vue';
import getLodop from '@/utils/plugins/LodopFuncs';

//接口
import {
  printNotPrint,
  getBatchBillPrintResults,
  getBatchBillDetails,
  printBatchPrintAdd,
  printPrintFinish,
} from '@/api/carBill/index';

// 工具
import { mapState } from 'vuex';
export default {
  name: 'batchPrint',
  components: { printedPage, sealDemo },
  data() {
    return {
      form: {
        operateCompanyId: '',
        checkStartTimes: [],
        paid: 1, // 单据金额 默认大于0
        billState: 1, // 单据状态 默认已结账
      },
      invoiceId: '',
      //搜索操作门店字段
      searchStoreLoading: false,
      storeList: [],
      selectKey: 1,
      //搜索业务来源字段
      searchBillSourceLoading: false,
      billSourceList: [],
      //业务类型
      businessType: carBillType,
      //搜索开票主体
      searchInvoiceLoading: false,
      invoiceList: [],
      invoiceName: '',
      //签约客户
      clientList: [],
      searchClientLoading: false,
      //单选框
      businessSeal: '0',
      // 选择的印章名称
      selectInvoiceCompanyName: '',
      //操作人
      userName: '',
      //操作时间
      operatingTime: '',

      // 查询结果 车单ID
      billIds: [],
      // 生成批次号的车单ID
      hasCodeBillIds: [],
      // 打印批次号
      printCode: '',
      // 未打印总条数
      notPrintTotal: 0,
      preparedTotal: '', //准备条数
      notPrintNum: '', //未打印数量

      printPageCount: 0, // 打印分页数量
      printPageSize: 10, // 打印分页数量
      printPageCurr: 1, // 打印所在页

      // 车单详情数据
      carBillData: [],

      showPrintProcess: false, // 是否显示打印状态
      noPrint: false, //未完成打印提示
      inPrint: false, //打印中提示
      endPrint: false, //成功提示
      showQueryResult: false, //点击查询时候出现
      queryTotal: '',

      //打印预览
      billType: 0,
      printTime: new Date().Format('yyyy-MM-dd hh:mm'),
      config: {
        title: '出厂结算单',
        leftImagePath: require('@/assets/slogan.png'),
      },
      // 门店信息
      company: {
        companyName: 'XXX有限公司',
        address: '广东省广州市番禺区南村旗舰店',
        telephone: '020-456258',
        alternativeTelephone: '17524623589',
      },
      // 单据信息
      billMessage: {
        billCode: 'JS51265778232212', //车单号
        customerName: '张三', //送修人姓名
        customerPhone: '52415122', //送修人电话
        pickUpUserName: '李勇', //接车员姓名
        dateCreated: '2022-05-22', //接待开单时间-进店时间
        mileage: '130', //提车时间
        checkDate: '2022-10-20', //提车时间
        invoice: 'xxx有限公司', //开票主体
      },
      // 车辆信息
      carInfo: {
        clientName: '李四', //客户姓名
        carNumber: '粤A.14562', //车牌号
        brandName: '奔驰', //车型
        uniqueId: 'UI5145244', //车架号
        engineNumber: '2456', //发动机号
      },
      // 客户信息
      payerInfo: {},
      // 理赔信息
      carBillInsuranceInfo: {},
      // 索赔信息
      carBillUndertakeInfo: {},
      // 服务项
      serviceList: [
        {
          name: '保养',
          num: 10,
          price: 100,
          discount: 0.9,
          total: 1000,
        },
        {
          name: '洗车',
          num: 20,
          price: 90,
          discount: 0.9,
          total: 600,
        },
      ],
      beforeTotalServicePrice: 16000,
      afterTotalServicePrice: 14400,
      // 商品
      goodsList: [
        {
          name: '刹车',
          num: 60,
          price: 300,
          discount: 0.8,
          total: 18000,
        },
        {
          name: '罗盘',
          num: 30,
          price: 900,
          discount: 0.5,
          total: 2001,
        },
      ],
      beforeTotalGoodsPrice: 20001,
      afterTotalGoodsPrice: 16300.5,

      serviceTotalPrice: 5000,
      goodsTotalPrice: 5000,

      discountAmount: 0.8,

      needPay: 10000,
      // 结账收银员
      cashier: '小刘',
      // 支付明细
      paidList: [
        {
          payType: '现金',
          money: 10000,
          paidTotal: 10000,
        },
        {
          payType: '微信',
          money: 220,
          paidTotal: 350,
        },
      ],
      paidTotal: 1000,
      needPaidTotal: 2000,
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
      user: (state) => state.user,
    }),
  },
  created() {
    //设置车单结账日期默认时间，上个月
    this.$set(this.form, 'checkStartTimes', [
      new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1).Format(
        'yyyy-MM-dd'
      ),
      new Date(new Date().setDate(0)).Format('yyyy-MM-dd'),
    ]);
    this.searchStore();
    this.searchBillSource();
    this.searchInvoice();
    //未打印结算单
    this.notPrint();
  },
  methods: {
    // 置空输入框数据
    empty() {
      this.form = {
        operateCompanyId: '',
        checkStartTimes: [],
        paid: 1, // 单据金额 默认大于0
        billState: 1, // 单据状态 默认已结账
      };
    },
    //远程搜索操作门店列表
    searchStore(keyWord = '') {
      this.searchStoreLoading = true;
      searchStoreWithJurisdiction(keyWord)
        .then((res) => {
          this.storeList = res;
        })
        .finally(() => {
          this.searchStoreLoading = false;
        });
    },
    // 选择操作门店
    selectStore(storeId) {
      this.selectKey = Math.random();
    },
    //删除操作门店
    deleteStore() {},
    //远程搜索业务来源列表
    searchBillSource(keyWord = '') {
      this.searchBillSourceLoading = true;
      getBillSourceTypeList({ name: keyWord })
        .then((res) => {
          this.billSourceList = res.records;
        })
        .finally(() => {
          this.searchBillSourceLoading = false;
        });
    },
    // 选择业务来源
    selectBillSource(billSourceId) {},
    //删除业务来源
    deleteBillSource() {},
    // 远程搜索开票主体列表
    invoiceKeyWordChange(keyword) {
      if (keyword) {
        this.searchInvoice(keyword);
      }
    },
    /**
     * 处理印章值变更
     * @param val 印章的值
     */
    handleChangeBusinessSeal(val) {
      let _selectInvoiceCompanyName = '';
      if (val === '2') {
        const client = this.invoiceList.find(
          (item) => item.companyId === this.invoiceId
        );
        if (!['', undefined].includes(client)) {
          _selectInvoiceCompanyName = client.invoicingEntity;
        }
      }
      this.selectInvoiceCompanyName = _selectInvoiceCompanyName;
      this.$forceUpdate();
    },
    searchInvoice(keyWord = '') {
      this.searchClientLoading = true;
      searchInvoice(keyWord, this.companyId)
        .then((res) => {
          this.invoiceList = res;
        })
        .finally(() => {
          this.searchClientLoading = false;
        });
    },
    //选择开票主体
    selectInvoice(invoiceId) {
      const client = this.invoiceList.find(
        (item) => item.companyId === invoiceId
      );
      if (!['', undefined].includes(client)) {
        this.invoiceName = client.invoicingEntity;
      }
      this.selectInvoiceCompanyName = this.invoiceName || '';
    },
    // 删除开票主体
    deleteInvoice() {
      this.invoiceName = '';
    },
    //远程搜索车主客户列表
    clientKeyWordChange(keyword) {
      if (keyword) {
        this.searchClient(keyword);
      }
    },
    searchClient(keyWord = '') {
      this.searchClientLoading = true;
      searchClient(keyWord)
        .then((res) => {
          this.clientList = res.records;
        })
        .finally(() => {
          this.searchClientLoading = false;
        });
    },
    // 选择车主客户
    selectClient(clientId) {},
    // 删除车主客户
    deleteClient() {},
    //单选框设置事件
    // handleChangeitem(e) {
    //   // 第一次: 绑定的值为空，点击，传入的值与绑定的data值不相等，所以=>this.businessSeal = e。选中
    //   // 第二次：再点击，传入的值与绑定的值相同，将绑定的data值设置为空。取消选中
    //   // 第三次：点击，绑定的值为空，传入的值与绑定的值不同。将传入的值设置为绑定的值。选中
    //   console.log('v-model绑定的值：' + this.businessSeal, '传进来的值' + e);
    //   e === this.businessSeal
    //     ? (this.businessSeal = '')
    //     : (this.businessSeal = e);
    // },

    //未打印结算单-页面一创建就请求
    notPrint() {
      printNotPrint({
        printCode: '',
      }).then((res) => {
        if (!res) return;
        this.printCode = res.printCode;
        this.notPrintTotal = res.total;
        this.userName = res.operationName;
        this.operatingTime = res.lastUpdated;
        this.billIds = res.data.map((item) => {
          return item.billId;
        });
        this.businessSeal = res.businessSeal || '0';
        this.invoiceId = res.invoiceId;
        this.notPrintNum = res.notPrintTotal;
        if (res.notPrintTotal > 0) {
          this.showPrintProcess = true;
          this.noPrint = true;
        }
        if (res.notPrintTotal > this.printPageSize) {
          this.preparedTotal = this.printPageSize;
        } else {
          this.preparedTotal = res.notPrintTotal;
        }
      });
    },
    //点击查询
    doSearch() {
      this.userName = this.user.userInfo.realName; //操作人
      this.operatingTime = new Date().Format('yyyy-MM-dd hh:mm:ss'); //操作时间

      var times = {
        checkStartTime: this.form.checkStartTimes?.[0]
          ? this.form.checkStartTimes?.[0]
          : '',
        checkEndTime: this.form.checkStartTimes
          ? this.form.checkStartTimes?.[1]
          : '',
        intoStoreStartTime: this.form.intoStoreTime
          ? this.form.intoStoreTime?.[0]
          : '',
        intoStoreEndTime: this.form.intoStoreTime
          ? this.form.intoStoreTime?.[1]
          : '',
      };
      var data = Object.assign(this.form, times);
      getBatchBillPrintResults(data).then((res) => {
        this.printPageCount = Math.ceil(res.total / this.printPageSize);
        this.notPrintTotal = res.total;
        this.queryTotal = res.total;
        this.showQueryResult = true;
        if (res.total > this.printPageSize) {
          this.preparedTotal = this.printPageSize;
        } else {
          this.preparedTotal = res.total;
        }
        this.billIds = res.data.map((item) => {
          return item.billId;
        });
        //查询成功之后把继续打印隐蔽
        this.notPrintNum = 0;
        this.showPrintProcess = false;
      });
    },

    /**
     * 校验打印控制是否准备好
     */
    verifyLodop() {
      return new Promise((resolve, reject) => {
        const LODOP = getLodop();
        //判断是否安装打印控件时候的弹窗提示
        if (!LODOP) {
          const h = this.$createElement;
          this.$confirm('', {
            message: h('div', null, [
              h('i', {
                class: 'el-icon-warning',
                style: 'color:#f90;font-size:25px;',
              }),
              h(
                'span',
                {
                  style:
                    'margin-left:10px;font-size:16px;line-height:30px;font-weight:600;vertical-align:top;',
                },
                '温馨提示'
              ),
              h(
                'p',
                { style: 'margin:0px 0 0 10px;' },
                '无法连接打印软件，请检查页面左上角提示 ，按指引完成安装或重新启动打印服务后再尝试进行打印。'
              ),
            ]),
            confirmButtonText: '确定',
            showCancelButton: false,
            closeOnClickModal: false, //不可通过点击遮罩关闭 MessageBox
            closeOnPressEscape: false, //不可通过按下ESC键关闭MessageBox
            showClose: false, //不显示MessageBox右上角关闭按钮
          });
          reject();
        } else {
          resolve();
        }
      });
    },
    //打印
    async handlePrint() {
      await this.verifyLodop();

      if (this.businessSeal == 2 && ['', undefined].includes(this.invoiceId)) {
        this.$message({
          message: '选择固定章一定要选择开票主体',
          type: 'warning',
        });
        return;
      }
      if (
        this.businessSeal == 2 &&
        !['', undefined].includes(this.invoiceId) &&
        ['', undefined].includes(this.invoiceName)
      ) {
        // 设置下开票主体， 主要是刚进入页面的时候，设置了个默认的invoiceId， 但是没有触发选择，导致为空
        const client = this.invoiceList.find(
          (item) => item.companyId === this.invoiceId
        );
        if (!['', undefined].includes(client)) {
          this.invoiceName = client.invoicingEntity;
        }
      }
      const s = this.$createElement;

      this.$confirm('', {
        message: s('div', null, [
          s('i', {
            class: 'el-icon-warning',
            style: 'color:#f90;font-size:25px;',
          }),
          s(
            'span',
            {
              style:
                'margin-left:10px;font-size:16px;line-height:30px;font-weight:600;vertical-align:top;',
            },
            '温馨提示'
          ),
          s(
            'p',
            { style: 'margin:0px 0 0 10px;' },
            '打印数量过大可能会产生系统反应迟钝请耐心等待，在打印成功前，请勿关闭本页面。'
          ),
        ]),
        showCancelButton: false, //不显示取消按
        showConfirmButton: false, //不显示确定按钮
        closeOnClickModal: false, //不可通过点击遮罩关闭 MessageBox
        closeOnPressEscape: false, //不可通过按下ESC键关闭MessageBox
      }).catch(() => {});

      if (!this.billIds?.length) {
        this.$alert('打印内容不能为空！', '提示');
        return;
      }
      this.printPageCurr = 1;

      this.showPrintProcess = true;
      this.inPrint = true; //打印中提示
      this.noPrint = false; //未完成打印提示
      this.endPrint = false; //成功提示
      // 新增打印批次
      var data = {
        printCode: '', //打印批次号
        billIds: this.billIds, //打印单号
        businessSeal: this.businessSeal, //业务章
        invoiceId: this.invoiceId, //开票主体id
      };
      printBatchPrintAdd(data).then((res) => {
        this.hasCodeBillIds = res.billIds;
        this.printCode = res.printCode;
        this.getDetails(1);
      });
    },
    /**
     * 获取车单详情数据并传递给打印模板组件
     * @param index{number} 页码
     */
    getDetails() {
      // 请求详情
      getBatchBillDetails(
        this.hasCodeBillIds.slice(
          (this.printPageCurr - 1) * this.printPageSize,
          this.printPageCurr * this.printPageSize
        )
      ).then((res) => {
        this.carBillData = res;
        //在调用完打印控件之后请求一次打印完成接口
        var data = {
          printCode: this.printCode,
          billIds: this.hasCodeBillIds.slice(
            (this.printPageCurr - 1) * this.printPageSize,
            this.printPageCurr * this.printPageSize
          ),
        };
        printPrintFinish(data).then(() => {
          this.preparedTotal =
            this.printPageSize * this.printPageCurr > this.billIds.length
              ? this.billIds.length
              : this.printPageSize * this.printPageCurr;
          this.printPageCurr++;
          if (this.printPageCurr <= this.printPageCount) {
            setTimeout(() => {
              this.getDetails(this.printPageCurr);
            }, 3e3);
          } else {
            this.showPrintProcess = true;
            this.inPrint = false; //打印中提示
            this.noPrint = false; //未完成打印提示
            this.endPrint = true; //成功提示
          }
        });
      });
    },
    //继续打印
    continuePrint() {
      this.verifyLodop().then(() => {
        this.printPageCount = Math.ceil(this.notPrintNum / this.printPageSize);
        //继续打印-取页面一进来就请求的未打印结算单里面的打印批次号
        var data = {
          printCode: this.printCode, //打印批次号
          billIds: this.billIds, //打印单号
          businessSeal: this.businessSeal, //业务章
          invoiceId: this.invoiceId, //开票主体id
        };
        printBatchPrintAdd(data).then((res) => {
          this.hasCodeBillIds = res.billIds;
          this.printCode = res.printCode;
          this.getDetails(1);
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.tip_word {
  font-size: 14px;
  display: flex;
  p {
    margin-bottom: 10px;
    margin-right: 30px;
  }
}
.foot-btn {
  padding: 10px 20px;
  box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.1);
}
.seal {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}

//打印预览样式
.print-template {
  width: 800px;
  padding: 12px;
  background: white;

  .fontsize {
    font-size: 14px;
  }

  .text-left {
    text-align: left;
  }
  .text-center {
    text-align: center;
  }
  .text-right {
    text-align: right;
  }

  &__title {
    justify-content: center;
    margin: 7px 0 1px;
    font-weight: 600;
  }

  &__header {
    width: 100%;
    height: 75px;
  }

  &__user {
    margin-bottom: 4px;
  }

  &__user {
    justify-content: space-between;
  }

  &__date,
  &__user,
  &__main__ul,
  &__signature,
  &__contact,
  &__common,
  &__title {
    display: flex;
  }

  &__header {
    position: relative;

    &__title {
      text-align: center;
      margin: 0 150px;
      font-size: 26px;
      font-weight: normal;
    }

    &__lt-img,
    &__rt-img {
      min-width: 75px;
      max-width: 300px;
      height: 75px;
      position: absolute;
      top: 2px;
    }

    &__lt-img {
      left: 0;
    }

    &__rt-img {
      right: 0;
    }
  }

  &__header__lt-img > img,
  &__header__rt-img > img {
    min-width: 75px;
    max-width: 300px;
    height: 75px;
  }

  &__date {
    justify-content: flex-end;
    margin: 8px 0 7px 0;
  }

  &__box__border {
    &__table {
      width: 100%;
      font-weight: normal;
      &:last-child {
        border-bottom: 1px solid #000 !important;
      }
      th,
      td {
        vertical-align: middle;
        padding: 2px;
        border-top: 1px solid #000 !important;
        border-left: 1px solid #000 !important;
        word-break: break-all;
        font-weight: normal;
      }
      th:last-child,
      td:last-child {
        border-right: 1px solid #000 !important;
      }
      &__total {
        width: 200px;
        display: inline-block;
      }
    }
  }

  &__common {
    justify-content: space-between;
    &__column {
      width: 45%;
    }
  }

  &__line {
    border-bottom: 1px dashed #000;
  }

  &__signature {
    justify-content: space-between;
  }

  &__contact {
    margin-top: 15px;
    flex-wrap: wrap;
  }

  &__signature {
    padding: 20px 0;
    border-bottom: 1px solid #000 !important;
    b {
      justify-content: initial;
      padding-right: 280px;
    }
  }

  &__contact__item {
    width: 39%;
  }
}
</style>
